import { orderBy } from 'lodash';
import {
  ADD_AI_TOOL,
  DELETE_AI_TOOL,
  EDIT_AI_TOOL,
  SET_AI_TOOLS,
} from 'store/action';
import { getNodesFromArray } from 'utils';
import { extractAIToolData } from '../helpers/bot/aiTools';

export const aiToolsReducer = (state, action) => {
  switch (action.type) {
    case SET_AI_TOOLS: {
      const toolNodes = getNodesFromArray(action.payload);
      const aiTools = orderBy(
        extractAIToolData(toolNodes),
        'lastUpdatedAt',
        'desc'
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          aiTools,
        },
      };
    }

    case ADD_AI_TOOL: {
      const toolNodes = getNodesFromArray(action.payload);
      const newTool = extractAIToolData(toolNodes);

      return {
        ...state,
        bot: {
          ...state.bot,
          aiTools: [...newTool, ...state.bot.aiTools],
        },
      };
    }

    case DELETE_AI_TOOL: {
      const toolId = action.payload;
      const updatedTools = state.bot.aiTools.filter(
        ({ jid }) => jid !== toolId
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          aiTools: updatedTools,
        },
      };
    }

    case EDIT_AI_TOOL: {
      const toolNodes = getNodesFromArray(action.payload);
      const newTool = extractAIToolData(toolNodes);

      const updatedTools = state.bot.aiTools.map(tool => {
        if (newTool[0]?.jid && tool.jid === newTool[0].jid) {
          return newTool[0];
        }
        return tool;
      });

      return {
        ...state,
        bot: {
          ...state.bot,
          aiTools: updatedTools,
        },
      };
    }

    default:
      return state;
  }
};
