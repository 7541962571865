import React from 'react';
import {
  Checkbox,
  Popover,
  Tag,
  Switch,
  Tooltip,
  Tabs,
  Skeleton,
  Table,
} from 'antd';
import PropTypes from 'prop-types';
import {
  CaretDownFilled,
  CaretRightFilled,
  CheckCircleOutlined,
  DislikeTwoTone,
  EditOutlined,
  FileTextOutlined,
  LikeTwoTone,
  PlusOutlined,
  SaveOutlined,
  SyncOutlined,
  WarningFilled,
} from '@ant-design/icons';
import moment from 'moment';

import {
  CategoryFieldWrapper,
  StyledAnswerEditor,
  StyledAddVariantButton,
  StyledSectionContent,
  StyledErrorMsg,
  StyledQuickBtnToggleOption,
  StyledQuestionsWrapper,
} from './AnswerEditor.styles';
import Modal from 'components/Modals/GenericModal';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import CategorySelect from 'components/CategorySelect';
import {
  ANSWER_TIP,
  DISPLAY_ANSWER_TIP,
  DEFAULT_ANSWER_VERSION,
  MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION,
} from 'constants/answerbank/defaults';
import useAnswerEditor from './hooks';
import Input from 'components/Input';
import { ChromePicker } from 'react-color';
import QuickReply from './QuickRelpy/QuickReply';
import TitleToolTip from 'components/ToolTips/TitleToolTip';
import ToggleFullScreenButton from 'components/Button/ToggleFullScreenButton';
import Variants from './Variant/Variants';
import HRWithCenteredText from 'components/HR/HRWithCenteredText';
import { cssVariables } from 'styles/root';
import { StyledAnswersItem } from '../ImportAnswerModal/ImportAnswerModal.style';
import { StyledAnswers } from 'pages/BotDetails/AnswerBank/SimilarAnswerModal/SimilarAnswerModal.style';
import {
  FlexRowSpaceBetweenWrapper,
  FlexRowWrapper,
} from './Variant/Variant.styles';
import Question from 'components/Question';
import {
  StyledFlexColumn,
  StyledFlexRowCenter,
  StyledFlexRowLeft,
  StyledSpacEvenlyFlexRow,
  StyledSpaceBetweenFlexCenter,
  StyledSpaceBetweenFlexColumn,
  StyledWarningBox,
} from 'styles/GenericStyledComponents';
import { useMemo } from 'react';
import Alert from 'components/Alert';
import ToolTip from 'components/ToolTips/BaseToolTip';
import { TOOLTIPS } from 'constants/analytics';
import {
  ChartWrapper,
  DataSummaryCard,
  StyledColumnChart,
  StyledQuestion,
} from 'pages/BotDetails/Analytics/Analytics.styles';
import { UTCToLocal, getLocalTimeString } from 'utils/dates';
import DateFilter from 'components/DateFilter';
import EditorAndViewer from 'components/EditorAndViewer/EditorAndViewer';

const showAnswerVariant = process.env.REACT_APP_SHOW_ANSWER_VARIANT;
const smallFont = { fontSize: 12 };
const largeFont = { fontSize: 20 };

const HIGH_SIMILARITY_ANSWER_TOOLTIP_TITLE =
  'This answer has high similarity score to the current answer';
const HIGH_SIMILARITY_SCORE_TOOLTIP_TITLE = 'High similarity score';
const HIGH_SIMILARITY_TOGGLE_TOOLTIP_TITLE =
  'Similar answer with high similary score detected. Please EDIT anchor answer or save answer as DRAFT';
const { TabPane } = Tabs;

const AnswerEditor = props => {
  const {
    isTemp,
    title,
    answerType,
    onAddAnswer,
    onAddDraftAnswer,
    setNewAnswer,
    pageLoading,
    ...rest
  } = props;
  const {
    answer,
    categoryColor,
    confirmLoading,
    editedHTML,
    editedRichTextAnswer,
    editedStringAnswer,
    editor,
    error,
    jid,
    quickReplies,
    requestAgent,
    selectedCategory,
    showAdvanceSettings,
    addRow,
    deleteRow,
    handleAddAnswerVariant,
    handleClose,
    handleDrag,
    handleDrop,
    handleUpdateVariantAnswer,
    isAddRowDisabled,
    isFullScreen,
    isRemoveRowDisabled,
    isTextCategory,
    handleOnUpdateAnswer,
    handleQuickReplyChange,
    handleSelectCategory,
    handleChangeRequestAgentCheckbox,
    setCategoryColor,
    setEditor,
    setFullScreen,
    setIsTextCategory,
    setQuickReplies,
    setRequestAgent,
    setShowAdvanceSettings,
    setShowColorPicker,
    setTextCategoryValue,
    showColorPicker,
    updateEditedStringAnswer,
    updateFormattedAnswer,
    textCategoryValue,
    handleChangeSwitch,
    answerVariants,
    answerEditorAnswer,
    allCategories,
    getSimilarAnswers,
    handleOnAddAnswer,
    handleChangeTabView,
    handleChangeDateRange,
    handleTableChange,
    handleFetchInsightFromDateFilter,
    sessionFilteredInfo,
    setSessionFilteredInfo,
    answerEditorQuestions,
    answerEditorInsights,
    dateFilter,
    similarAnswers,
    tabView,
    isOpen,
    shouldUseSkeletonComponent,
    isCreateMode,
    parsedAnswer,
  } = useAnswerEditor({
    onAddDraftAnswer,
    setNewAnswer,
  });
  const isFinalAnswer =
    answerEditorAnswer?.version === 'final'
      ? true
      : false || answer?.version === DEFAULT_ANSWER_VERSION;

  const isCloneAnswer = answer?.clone;
  const isAnswerView = (tabView && tabView === 'answer') || !tabView;
  const draftToFinalAnswerDisabled =
    !isFinalAnswer && answerEditorAnswer.isVersionToggleDisabled ? true : false;
  const skipSimilarityCheck = useMemo(() => {
    // add new answer:
    // always check for similarity
    // when answer in edited
    // call similarity check
    if (editedStringAnswer?.length && editedStringAnswer !== answer?.text) {
      return false;
    }
    // if similar answers is found
    // and answer inside textarea is not change
    // skip similarity check
    if (
      similarAnswers?.length &&
      (editedStringAnswer === answer?.text ||
        (!editedStringAnswer && answer.text))
    ) {
      return true;
    }
    // if similar answers is found
    // and answer inside textarea has changed
    // call similarity check
    else if (similarAnswers?.length && editedStringAnswer !== answer?.text) {
      return false;
    }
    // if no similar answers found
    // skip similarity check
    else if (!similarAnswers?.length) {
      return true;
    }
    // always call similarity check for all
    // other scenarios
    return false;
  }, [similarAnswers?.length, editedStringAnswer, answer?.text]);

  const renderVariants = () => {
    return (
      <StyledSectionContent>
        <TitleToolTip title="Variant Display Answer" tip={''} />
        {answerVariants.map((i, idx) => {
          return (
            <>
              <HRWithCenteredText text={`Variant ${idx + 1}`} />
              <Variants
                key={idx}
                editor={i.editor}
                editedHTML={i.displayAnswer.html}
                editedRichTextAnswer={i.displayAnswer.rce}
                answerId={jid}
                formFields={i.forms}
                variant={i}
                onUpdateVariant={handleUpdateVariantAnswer}
              />
            </>
          );
        })}
      </StyledSectionContent>
    );
  };
  const renderModalTitle = () => (
    <>
      <span>
        {isCloneAnswer ? 'Clone Answer' : title || 'Edit Your Answer'}
        <Tag
          style={{ marginLeft: 20, fontSize: 14 }}
          color={isFinalAnswer ? 'green' : 'red'}
        >
          {isFinalAnswer ? 'Final' : 'Draft'}{' '}
          {isFinalAnswer ? <CheckCircleOutlined /> : <EditOutlined />}
        </Tag>
      </span>
      <ToggleFullScreenButton
        isFullScreen={isFullScreen}
        setFullScreen={setFullScreen}
      />
    </>
  );

  const renderQuestionsTab = () => {
    if (shouldUseSkeletonComponent) {
      return <Skeleton active />;
    }
    return (
      <>
        {!answerEditorQuestions?.length ? (
          <span>{'No linked questions found'}</span>
        ) : (
          <StyledQuestionsWrapper>
            <h3>{'Linked Questions:'}</h3>
            <ul>
              {answerEditorQuestions.map(q => {
                return (
                  <Question
                    item={{
                      question: q,
                      answer,
                    }}
                    key={q.jid}
                    deleteOnly
                  />
                );
              })}
            </ul>
          </StyledQuestionsWrapper>
        )}
      </>
    );
  };

  const allQuestionsTableData = [
    {
      title: 'Sessions',
      align: 'center',
      dataIndex: 'sessionID',
      width: '10%',
      sorter: {},
      filteredValue: sessionFilteredInfo || null,
      onFilter: (value, row) => {
        if (value === 'undefined') {
          return !row.sessionID;
        } else {
          return row.sessionID && row.sessionID.includes(value);
        }
      },
      render: (sessionID, row) => {
        if (sessionID) {
          return (
            <Button value="View Session" variant="link" style={smallFont} />
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      title: `Question (${
        answerEditorInsights?.table?.allQuestions?.length || 0
      })`,
      dataIndex: 'question',
      width: '45%',
      render: (question, row) => {
        return (
          <StyledQuestion>
            <span>{question}</span>
          </StyledQuestion>
        );
      },
    },
    {
      title: 'Visitor ID',
      align: 'center',
      dataIndex: 'visitorID',
      sorter: {},
      render: visitorID => (
        <div style={smallFont}>{!!visitorID ? visitorID : 'N/A'}</div>
      ),
    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      align: 'center',
      width: '10%',
      render: feedback => {
        if (feedback === 1) {
          return <LikeTwoTone style={largeFont} />;
        } else if (feedback === 0) {
          return <DislikeTwoTone style={largeFont} twoToneColor="red" />;
        } else {
          return null;
        }
      },
    },
    {
      title: 'Date',
      align: 'center',
      dataIndex: 'date',
      width: '20%',
      defaultSortOrder: 'ascend',
      sorter: {},
      render: date => {
        const localDate = UTCToLocal(new Date(date));
        return <div style={smallFont}>{getLocalTimeString(localDate)}</div>;
      },
    },
  ];

  const graphConfig = useMemo(() => {
    return {
      data: answerEditorInsights?.graph || [],
      xField: 'date',
      yField: 'count',
      xAxis: {
        label: {
          autoRotate: false,
        },
      },
    };
  }, [answerEditorInsights?.graph]);

  const renderInsightsTab = () => {
    if (shouldUseSkeletonComponent || !answerEditorInsights) {
      return <Skeleton active />;
    }
    return (
      <StyledFlexColumn>
        <StyledFlexRowLeft style={{ marginBottom: 10 }}>
          <DateFilter onChange={handleChangeDateRange} />
          <Button
            value={'Go'}
            onClick={handleFetchInsightFromDateFilter}
            size="medium"
          />
        </StyledFlexRowLeft>
        {answerEditorInsights?.aggregations?.totalQuestions ? (
          <>
            <StyledSpacEvenlyFlexRow>
              <ToolTip>
                <DataSummaryCard modalCard noFooter>
                  <div>{`# of sessions`}</div>
                  <h1>
                    {answerEditorInsights?.aggregations?.sessionCount || 0}
                  </h1>
                  <div></div>
                </DataSummaryCard>
              </ToolTip>

              <ToolTip title={TOOLTIPS.SESSIONS}>
                <DataSummaryCard modalCard noFooter>
                  <div>{`# of visitors`}</div>
                  <h1>
                    {answerEditorInsights?.aggregations?.visitorCount || 0}
                  </h1>
                  <div></div>
                </DataSummaryCard>
              </ToolTip>

              <ToolTip title={TOOLTIPS.QUESTIONS_ASKED}>
                <DataSummaryCard modalCard noFooter>
                  <div>{`# of questions asked`}</div>
                  <h1>
                    {answerEditorInsights?.aggregations?.totalQuestions || 0}
                  </h1>
                  <div></div>
                </DataSummaryCard>
              </ToolTip>
            </StyledSpacEvenlyFlexRow>

            <StyledFlexRowCenter>
              <ChartWrapper>
                <h4>{`Total questions asked from ${moment(
                  dateFilter.startDate
                ).format('MMMM D, YYYY')} - ${moment(dateFilter.endDate).format(
                  'MMMM D, YYYY'
                )}`}</h4>
                <StyledColumnChart {...graphConfig} />
              </ChartWrapper>
            </StyledFlexRowCenter>
            <StyledFlexColumn>
              <Table
                style={{ width: '100%' }}
                columns={allQuestionsTableData}
                dataSource={answerEditorInsights?.table?.allQuestions || []}
                onChange={handleTableChange}
                pagination={false}
                showSorterTooltip={{
                  title: 'Click to sort',
                  placement: 'topRight',
                }}
              />
            </StyledFlexColumn>
          </>
        ) : (
          <StyledFlexRowLeft>{'No interaction found.'}</StyledFlexRowLeft>
        )}
      </StyledFlexColumn>
    );
  };

  const renderAdvanceSettings = () => {
    if (showAdvanceSettings && !isCreateMode) {
      return (
        <StyledFlexColumn>
          <StyledFlexColumn>
            <StyledSpaceBetweenFlexCenter>
              <TitleToolTip title="Display Answer" tip={DISPLAY_ANSWER_TIP} />
            </StyledSpaceBetweenFlexCenter>
          </StyledFlexColumn>
          <EditorAndViewer
            editor={editor}
            editedHTML={editedHTML}
            editedRichTextAnswer={editedRichTextAnswer}
            answerId={jid}
            updateFormattedAnswer={updateFormattedAnswer}
            updateTabs={setEditor}
            isModal
          />
          <StyledSpaceBetweenFlexCenter>
            <StyledQuickBtnToggleOption>
              <Checkbox
                checked={quickReplies.quickReply}
                onChange={() => {
                  setQuickReplies({
                    ...quickReplies,
                    quickReply: !quickReplies.quickReply,
                  });
                  setRequestAgent(false);
                }}
              />{' '}
              &nbsp;
              {`Quick Reply`}
            </StyledQuickBtnToggleOption>
            <StyledQuickBtnToggleOption>
              <Checkbox
                checked={requestAgent}
                onChange={handleChangeRequestAgentCheckbox}
              />{' '}
              &nbsp;
              {`Request Callback`}
            </StyledQuickBtnToggleOption>
          </StyledSpaceBetweenFlexCenter>
          {quickReplies.quickReply ? (
            <QuickReply
              addRow={addRow}
              deleteRow={deleteRow}
              handleQuickReplyChange={handleQuickReplyChange}
              isRemoveRowDisabled={isRemoveRowDisabled}
              isAddRowDisabled={isAddRowDisabled()}
              quickReplies={quickReplies}
            />
          ) : null}
          {answerVariants && answerVariants.length ? renderVariants() : null}
          {showAnswerVariant && showAnswerVariant !== 'false' ? (
            <StyledAddVariantButton
              size={'medium'}
              onClick={handleAddAnswerVariant}
              variant="gray"
              value={
                <>
                  <span>{'Add Variant'}</span>
                  <PlusOutlined />
                </>
              }
            />
          ) : null}
        </StyledFlexColumn>
      );
    }

    return <div></div>;
  };

  const renderAnswerTab = () => {
    return (
      <StyledFlexColumn>
        <StyledSpaceBetweenFlexColumn>
          {draftToFinalAnswerDisabled ? (
            <StyledFlexColumn>
              <Alert
                message="We have found similar answer(s) and set this answer as draft."
                type="warning"
                closable
              />
            </StyledFlexColumn>
          ) : null}
          <StyledSpaceBetweenFlexCenter>
            <TitleToolTip title="Anchor Answer" tip={ANSWER_TIP} />
            {answer?.lastEdited && (
              <span>
                <strong>Last updated: </strong>
                {answer?.lastEdited}
              </span>
            )}
          </StyledSpaceBetweenFlexCenter>
          {!!error && <StyledErrorMsg type="error" message={error} />}
          <StyledFlexRowLeft style={{ padding: 1 }}>
            <TextArea
              defaultValue={answerEditorAnswer?.text}
              onChange={updateEditedStringAnswer}
              size="large"
              id="anchor-answer"
              full
            />
          </StyledFlexRowLeft>

          {answerEditorAnswer?.text !== editedStringAnswer && !isCreateMode ? (
            <span>
              <WarningFilled style={{ color: cssVariables.warning }} />
              {` Anchor answer will not be directly reflected as the answer to the clients. Updating "Display Answer" will do.`}
            </span>
          ) : null}
          {!shouldUseSkeletonComponent && similarAnswers?.length ? (
            <StyledWarningBox
              style={{
                border: `1px solid ${cssVariables.warning}`,
                padding: 10,
              }}
            >
              <FlexRowSpaceBetweenWrapper>
                <h4>Similar Answers:</h4>
              </FlexRowSpaceBetweenWrapper>
              {similarAnswers.map(item => (
                <FlexRowWrapper>
                  <Tooltip
                    title={
                      item.score > MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION
                        ? HIGH_SIMILARITY_SCORE_TOOLTIP_TITLE
                        : 'Similarity Score'
                    }
                  >
                    <Tag
                      color={
                        item.score > MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION
                          ? cssVariables.red10
                          : null
                      }
                    >
                      {item.score?.toFixed(3)}
                    </Tag>
                  </Tooltip>
                  <StyledAnswersItem
                    key={item.answer.jid}
                    style={{
                      width: '100%',
                    }}
                  >
                    {item.score > MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION ? (
                      <Tooltip title={HIGH_SIMILARITY_ANSWER_TOOLTIP_TITLE}>
                        <StyledAnswers>
                          {item.answer.context.text}
                        </StyledAnswers>
                      </Tooltip>
                    ) : (
                      <StyledAnswers>{item.answer.context.text}</StyledAnswers>
                    )}
                  </StyledAnswersItem>
                </FlexRowWrapper>
              ))}
            </StyledWarningBox>
          ) : shouldUseSkeletonComponent ? (
            <Skeleton active />
          ) : null}
        </StyledSpaceBetweenFlexColumn>
        {isTemp && !isCreateMode ? (
          <CategoryFieldWrapper>
            <Input
              size="small"
              label="Category Name"
              placeholder="Enter category name"
              defaultValue={answerEditorAnswer.category}
              onChange={e => setTextCategoryValue(e.target.value)}
              full
            />
            <Popover
              content={
                <div
                  style={{
                    position: 'relative',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                  onDoubleClick={() => setShowColorPicker(false)}
                >
                  <ChromePicker
                    color={answerEditorAnswer.category_color}
                    onChangeComplete={color => setCategoryColor(color.hex)}
                  />
                </div>
              }
              trigger="click"
              visible={showColorPicker}
              onVisibleChange={() => setShowColorPicker(!showColorPicker)}
            >
              <Input
                full
                size="small"
                label="Category Color"
                value={categoryColor || answerEditorAnswer.category_color}
                placeholder={`Pick your category color (e.g. #D3D3D3)`}
              />
            </Popover>
          </CategoryFieldWrapper>
        ) : null}
        <StyledSectionContent>
          {allCategories.length && !isCreateMode ? (
            <StyledFlexColumn>
              <CategorySelect
                label="Category"
                placeholder="Select Category"
                selectedCategory={selectedCategory}
                categories={allCategories}
                handleChange={handleSelectCategory}
              />
            </StyledFlexColumn>
          ) : null}
          {!isCreateMode && (
            <StyledFlexRowLeft>
              <Button
                startIcon={
                  !showAdvanceSettings ? (
                    <CaretRightFilled />
                  ) : (
                    <CaretDownFilled />
                  )
                }
                variant="link"
                size="medium"
                value={
                  !showAdvanceSettings
                    ? 'Show advanced settings'
                    : 'Hide advanced settings'
                }
                onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
              />
            </StyledFlexRowLeft>
          )}
        </StyledSectionContent>
        {renderAdvanceSettings()}
      </StyledFlexColumn>
    );
  };

  return (
    <Modal
      title={renderModalTitle()}
      visible={isOpen}
      onCancel={handleClose}
      destroyOnClose={true}
      onOk={() =>
        isCreateMode
          ? handleOnAddAnswer(skipSimilarityCheck)
          : isAnswerView
          ? handleOnUpdateAnswer(isTemp)
          : handleClose()
      }
      confirmLoading={confirmLoading}
      width={isFullScreen && '100%'}
      isFullScreen={isFullScreen}
      isFormModal
      spinning={confirmLoading}
      okText={
        !isAnswerView ? (
          <>
            <span>{'OK'}</span>
          </>
        ) : undefined
      }
      footer={
        isAnswerView ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <StyledFlexRowLeft style={{ width: '50%' }}>
              <Tooltip
                title={
                  draftToFinalAnswerDisabled
                    ? HIGH_SIMILARITY_TOGGLE_TOOLTIP_TITLE
                    : null
                }
              >
                <Switch
                  style={{ alignSelf: 'center' }}
                  checkedChildren={'Final'}
                  unCheckedChildren={'Draft'}
                  defaultChecked={isFinalAnswer}
                  size="default"
                  onChange={handleChangeSwitch}
                  loading={shouldUseSkeletonComponent || confirmLoading}
                  disabled={draftToFinalAnswerDisabled}
                />
              </Tooltip>
              <Button
                hidden={tabView !== null && tabView !== 'answer'}
                startIcon={<SyncOutlined />}
                value={'Revalidate Answer'}
                variant="link"
                size="small"
                disabled={shouldUseSkeletonComponent || confirmLoading}
                onClick={() => getSimilarAnswers(answer?.version)}
              />
            </StyledFlexRowLeft>

            <div>
              <Button
                disabled={shouldUseSkeletonComponent || confirmLoading}
                value={'Cancel'}
                onClick={handleClose}
                variant="link"
              />
              <Button
                loading={confirmLoading}
                disabled={shouldUseSkeletonComponent || confirmLoading}
                variant={!isFinalAnswer ? 'gray' : 'primary'}
                value={
                  !isFinalAnswer
                    ? 'Save as Draft'
                    : skipSimilarityCheck && similarAnswers?.length
                    ? 'Ignore similar answers & Save'
                    : 'Save'
                }
                endIcon={
                  isFinalAnswer ? <SaveOutlined /> : <FileTextOutlined />
                }
                onClick={() =>
                  isCreateMode
                    ? handleOnAddAnswer(skipSimilarityCheck)
                    : handleOnUpdateAnswer(isTemp)
                }
              />
            </div>
          </div>
        ) : undefined
      }
    >
      <StyledAnswerEditor>
        {isCreateMode ? (
          renderAnswerTab()
        ) : (
          <>
            <Tabs
              defaultActiveKey="answer"
              type="card"
              onChange={handleChangeTabView}
            >
              <TabPane tab="Answer" key="answer">
                {renderAnswerTab()}
              </TabPane>
              <TabPane tab="Linked Questions" key="question">
                {renderQuestionsTab()}
              </TabPane>
              <TabPane tab="Insights" key="insights">
                {renderInsightsTab()}
              </TabPane>
            </Tabs>
          </>
        )}
      </StyledAnswerEditor>
    </Modal>
  );
};

AnswerEditor.propTypes = {
  onUpdateAnswer: PropTypes.func,
  answerEditorAnswer: PropTypes.object,
  isTemp: PropTypes.bool,
  onAddAnswer: PropTypes.func,
  onAddDraftAnswer: PropTypes.func,
  setNewAnswer: PropTypes.func,
};

export default AnswerEditor;
