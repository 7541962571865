export const MIN_WIDGET_HEIGHT = 250;
export const MAX_WIDGET_HEIGHT = 600;
export const BG_OPTIONS = [
  {
    label: 'Dark',
    value: 'dark',
  },
  {
    label: 'Light',
    value: 'light',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];
export const TEXT_COLOR_LIST = [
  {
    label: 'Default',
    value: '#fff',
  },
  {
    label: 'Black',
    value: '#000',
  },
];
