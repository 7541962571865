import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import styled, { css } from 'styled-components';

import { ZSB_PRICING_PAGE } from 'constants/outboundLinks';
import { cssVariables } from 'styles/root';

const StyledTag = styled(Tag)`
  ${props => {
    switch (props.size) {
      case 'large':
      case 'lg':
        return css`
          height: 40px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        `;

      case 'medium':
      case 'md':
      case 'middle':
        return css`
          height: 35px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        `;

      default:
        return css``;
    }
  }}
`;

const UpgradeTag = props => {
  const { children, size, ...rest } = props;
  return (
    <StyledTag {...rest} size={size}>
      <a
        style={{ color: cssVariables.primaryBlue, cursor: 'pointer' }}
        href={ZSB_PRICING_PAGE}
        alt="Upgrade plan"
      >
        {children || 'Upgrade'}
      </a>
    </StyledTag>
  );
};

UpgradeTag.propTypes = {
  children: PropTypes.any,
  size: PropTypes.oneOf([
    'large',
    'default',
    'medium',
    'small',
    'lg',
    'sm, middle',
    'md',
  ]),
};

export default UpgradeTag;
