import ROUTES from 'constants/routes';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isExternalPageSelector } from 'selectors/user';
import { apiService } from 'services/api.service';
import useSelector from 'store/useSelector';

const useVerifyEmail = () => {
  const { search, state } = useLocation();
  // `ROUTES.VERIFY_EMAIL = /activation`
  // finds and get the value the query (after `?`)
  const query = new URLSearchParams(search);

  // gets and stores the value of `key` query
  const key = query.get('key');
  const history = useHistory();
  const [code, setCode] = useState(key);
  const [error, setError] = useState(null);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const isExternalPage = useSelector(isExternalPageSelector);

  useEffect(() => {
    if ((!state || !state.email) && !code) {
      history.push(ROUTES.HOME);
    }

    if (key) {
      handleVerify(null, key);
    }
  }, []);

  const handleChangeCode = e => {
    setCode(e.target.value);
  };

  const handleVerify = async e => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    const response = await apiService.userActivate(code);
    if (response.status === 200) {
      setVerified(true);
      setError(null);
    } else {
      setVerified(false);
      setError(`${response.status.statusText + '.' || ''} Please try again.`);
    }
    return setLoading(false);
  };

  return {
    error,
    key,
    history,
    loading,
    verified,
    state,
    handleChangeCode,
    handleVerify,
    setCode,
    isExternalPage,
  };
};

export default useVerifyEmail;
