/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import {
  CaretRightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ForwardOutlined,
  PlusOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons';
import { Checkbox, Col, Dropdown, Menu, Row, Spin, Tooltip } from 'antd';
import Joyride from 'react-joyride';

import Button from 'components/Button';
import Input from 'components/Input';
import Modal from 'components/Modals/GenericModal';
import TestCaseModal from 'components/Modals/TestCaseModal';
import TestSuite from 'components/TestSuite';
import Title from 'components/Title';
import CreateTestsuiteModal from 'components/Modals/CreateTestsuiteModal';
import ValidationQuestionModal from 'components/Modals/ValidationQuestionModal';
import { stringLocaleCompare } from 'utils/stringManipulation';
import { getLocalTimeString, getTimeDifference } from 'utils/dates';
import useValidation from './hooks';
import {
  StyledToolTip,
  NoTestGroup,
  StyledIconWrapper,
  StyledValidationTable,
  StyledLinkColumn,
  TestStatusWrapper,
  StyledTestStatus,
  TestCaseHeader,
  StyledRoot,
  StyledNoTestGroupText,
  StyledTestCases,
  StyledTestCasesHeader,
  StyledTestCasesAction,
  StyledTestCaseSearchInput,
  StyledIconSearch,
} from './Validation.styles';
import LimitationBanner from 'components/LimitationBanner';
import LimitationTooltip from 'components/ToolTips/LimitationToolTip';
import {
  FIRST_SUITE,
  VALIDATION_WELCOME_STEPS,
} from 'constants/joyride_steps/validation_page';
import JoyrideTooltip from 'components/ToolTips/JoyrideToolTip';
import BackButton from 'components/BackButton';
import { cssVariables } from 'styles/root';
import { default as ToolTip } from 'components/ToolTips/BaseToolTip';
import MoreMenu from 'components/MoreMenu';
import { TEST_STATUS } from 'constants/testSuite';

const Validation = ({ loading }) => {
  // Alphabetical order of variables
  // to trace easily
  const {
    actualAnswer,
    name,
    expectedAnswerToEdit,
    filteredTestCases,
    isMaxTestCases,
    isMaxTestSuites,
    importExportEnabled,
    max_test_cases,
    max_test_suite,
    modalLoading,
    onClickClose,
    pageLoading,
    plan_type,
    responseCount,
    responses,
    runFirstSuiteTour,
    runTour,
    stepIndex,
    setFirstSuiteStepIndex,
    onClickCallback,
    selectedAnswer,
    selectedResponse,
    selectedTestCase,
    selectedTestSuite,
    showCreateSuiteModal,
    showEditSuiteModal,
    showTestCaseDetailsModal,
    showTestCases,
    showTestStatus,
    showValidationQuestionModal,
    testCases,
    testCaseSearchKey,
    testCaseToEdit,
    testLastRanTime,
    testStatus,
    testSuites,
    testSuiteToUpdate,
    handleAddQuestion,
    handleBackToTestSuites,
    handleChangeTestCaseQuestion,
    handleChangeTestSuiteName,
    handleCloseCreateSuiteModal,
    handleCloseEditSuiteModal,
    handleCloseValidationQuestionModal,
    handleCloseTestCaseDetailsModal,
    handleCreateNewSuite,
    handleDeleteTestCase,
    handleDeleteTestSuite,
    handleDownloadTestCases,
    handleEditTestCase,
    handleRunAllTests,
    handleRunTestSuite,
    handleSearchTestCases,
    handleSelectResponse,
    handleShowCreateSuite,
    handleShowEditTestSuiteModal,
    handleShowResponses,
    handleShowTestCase,
    handleShowTestCases,
    handleShowValidationQuestionModal,
    handleUpdateTestCase,
    handleUpdateTestSuite,
    handleToggleUseDraft,
    handleToggleAllSuitesUseDraft,
    allSuitesUseDraft,
    useDraft,
    isMobileView,
  } = useValidation();

  const MaxReachedToolTip = ({ type }) => {
    return (
      <StyledToolTip>
        <strong>{`${plan_type.toUpperCase()} Plan has a limit of ${
          type === 'suites' ? max_test_suite : max_test_cases
        } Test ${type.charAt(0).toUpperCase() + type.slice(1)}`}</strong>
        <span>{`Upgrade to add more`}</span>
      </StyledToolTip>
    );
  };

  const getTestResultIcon = result => {
    const { EDITED, PASSED, FAILED, UNTESTED } = TEST_STATUS;
    switch (result) {
      case PASSED:
        return (
          <StyledIconWrapper>
            <CheckCircleFilled style={{ color: cssVariables.success }} />
            <span>{PASSED}</span>
          </StyledIconWrapper>
        );
      case FAILED:
        return (
          <StyledIconWrapper>
            <CloseCircleFilled style={{ color: cssVariables.red10 }} />
            <span>{FAILED}</span>
          </StyledIconWrapper>
        );
      case EDITED:
        return (
          <StyledIconWrapper>
            <EditOutlined style={{ color: cssVariables.warning }} />
            <span>{EDITED}</span>
          </StyledIconWrapper>
        );
      case UNTESTED:
        return (
          <StyledIconWrapper>
            <QuestionCircleFilled style={{ color: cssVariables.gray0 }} />
            <span>{UNTESTED}</span>
          </StyledIconWrapper>
        );
      default:
        return <QuestionCircleFilled style={{ color: cssVariables.gray0 }} />;
    }
  };

  const moreMenu = testCase => {
    return (
      <Menu>
        <Menu.Item
          key={1}
          icon={<EditOutlined />}
          onClick={() => handleEditTestCase(testCase)}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key={2}
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteTestCase(testCase)}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  const TestCasesList = [
    {
      title: 'Test Question',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.question, b.question),
      },
      render: testCase => (
        <StyledLinkColumn onClick={() => handleShowTestCase(testCase)}>
          {testCase.question}
        </StyledLinkColumn>
      ),
    },
    {
      title: 'Result',
      align: 'center',
      dataIndex: 'testResult',
      sorter: (a, b) => stringLocaleCompare(a.testResult, b.testResult),
      render: result => (
        <ToolTip style={{ fontSize: '.8rem' }} title={result}>
          {getTestResultIcon(result)}
        </ToolTip>
      ),
    },
    {
      title: 'Last Updated',
      align: 'center',
      dataIndex: 'lastUpdated',
      defaultSortOrder: 'descend',
      sorter: (a, b) => stringLocaleCompare(a.lastUpdate, b.lastUpdated),
      render: date => (
        <span style={{ fontSize: '.8rem' }}>
          {getLocalTimeString(new Date(date))}
        </span>
      ),
      responsive: ['md'],
    },
    {
      title: 'Action',
      align: 'center',
      render: testCase => (
        <Dropdown
          overlay={moreMenu(testCase)}
          placement="bottomLeft"
          trigger={['click']}
          style={{ fontSize: '.8rem' }}
        >
          <MoreMenu testcase={testCase} />
        </Dropdown>
      ),
    },
  ];

  MaxReachedToolTip.propTypes = {
    type: PropTypes.oneOf(['cases', 'suites']).isRequired,
  };

  const MaxReachedNotice = ({ type }) => {
    return (
      <LimitationBanner
        title={`Test ${
          type.charAt(0).toUpperCase() + type.slice(1)
        } Limit Reached`}
        subtitle={`Upgrade and add more Test ${
          type.charAt(0).toUpperCase() + type.slice(1)
        } to your bot.`}
      />
    );
  };

  MaxReachedNotice.propTypes = {
    type: PropTypes.oneOf(['cases', 'suites']).isRequired,
  };

  const TestSuites = () => {
    return (
      <div>
        {testSuites?.map((testSuite, idx) => (
          <TestSuite
            runFirstSuiteTour={runFirstSuiteTour}
            idx={idx}
            key={testSuite.jid}
            testSuite={testSuite}
            handleShowTestCases={handleShowTestCases}
            handleEditTestSuite={handleShowEditTestSuiteModal}
            handleDeleteTestSuite={handleDeleteTestSuite}
            isMobileView={isMobileView}
          />
        ))}
        {isMaxTestSuites && <MaxReachedNotice type="suites" />}
      </div>
    );
  };

  const renderEditSuiteModal = () => (
    <Modal
      visible={showEditSuiteModal}
      title="Edit Test Suite"
      onOk={handleUpdateTestSuite}
      onCancel={handleCloseEditSuiteModal}
      confirmLoading={modalLoading}
    >
      <form onSubmit={handleUpdateTestSuite}>
        <Input
          label="Name:"
          placeholder="Enter Suite Name"
          value={testSuiteToUpdate ? testSuiteToUpdate.name : null}
          onChange={handleChangeTestSuiteName}
        />
      </form>
    </Modal>
  );

  const RenderTestCaseDetailsModal = () => (
    <TestCaseModal
      actualAnswer={actualAnswer}
      botName={name}
      handleEditTestCase={handleEditTestCase}
      onOkModal={handleCloseTestCaseDetailsModal}
      modalLoading={modalLoading}
      selectedAnswer={selectedAnswer}
      selectedTestCase={selectedTestCase}
      visible={showTestCaseDetailsModal}
    />
  );

  return (
    <Spin spinning={loading || pageLoading} tip="Just a moment...">
      <StyledRoot>
        <Joyride
          run={runTour}
          steps={VALIDATION_WELCOME_STEPS}
          tooltipComponent={JoyrideTooltip}
          stepIndex={stepIndex}
          continuous
          debug
          spotlightClicks
          showProgress={true}
          showSkipButton={true}
          callback={onClickClose}
        />
        <Joyride
          run={runFirstSuiteTour}
          steps={FIRST_SUITE}
          stepIndex={setFirstSuiteStepIndex}
          spotlightClicks
          debug
          tooltipComponent={JoyrideTooltip}
          callback={onClickCallback}
          scrollToFirstStep={true}
          disableScrollParentFix={true}
          continuous={true}
        />
        {!showTestCases ? (
          <>
            <Row style={{ paddingBottom: '2%' }} gutter={[16, 16]}>
              <Col
                xs={{ span: 21 }}
                md={{ span: 13 }}
                lg={{ span: 13 }}
                xl={{ span: 14 }}
              >
                <Title
                  text={`Automation Testing (${
                    testSuites?.length || 0
                  }/${max_test_suite})`}
                  type="responsive-text"
                />
              </Col>

              {testSuites?.length ? (
                <Col style={{ marginTop: '1%' }}>
                  <Checkbox
                    onChange={handleToggleAllSuitesUseDraft}
                    defaultChecked={allSuitesUseDraft}
                  >
                    <Tooltip title="Include draft answers">
                      {'Use draft'}
                    </Tooltip>
                  </Checkbox>
                </Col>
              ) : null}

              {testSuites?.length ? (
                <Col
                  xs={{ span: 23 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                >
                  <Button
                    onClick={handleRunAllTests}
                    startIcon={<ForwardOutlined />}
                    value="Run"
                    variant="success"
                    style={{ width: '100%' }}
                  />
                </Col>
              ) : (
                <Col
                  xs={{ span: 1 }}
                  md={{ span: 7 }}
                  lg={{ span: 7 }}
                  xl={{ span: 5 }}
                />
              )}
              {isMaxTestSuites ? (
                <Col
                  xs={{ span: 23 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                  xl={{ span: 4 }}
                >
                  <LimitationTooltip
                    title={<MaxReachedToolTip type="suites" />}
                    disabledFeat={`+ New Suite`}
                    isAnElement={true}
                  />
                </Col>
              ) : (
                <Col
                  xs={{ span: 23 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                  xl={{ span: 4 }}
                >
                  {testSuites?.length ? (
                    <Button
                      variant="primary-btn-v2"
                      onClick={handleShowCreateSuite}
                      value={`+ New Suite`}
                      style={{ width: '100%' }}
                    />
                  ) : null}
                </Col>
              )}
            </Row>
            {testSuites?.length ? (
              <TestSuites />
            ) : (
              <NoTestGroup>
                <StyledNoTestGroupText>
                  You have no Test Group
                </StyledNoTestGroupText>
                <Button
                  disabled={isMaxTestSuites}
                  variant="primary-btn-v2"
                  onClick={handleShowCreateSuite}
                  value={`New Suite`}
                  startIcon={<PlusOutlined />}
                  className="new-suite-btn"
                />
              </NoTestGroup>
            )}
            {showCreateSuiteModal && (
              <CreateTestsuiteModal
                modalLoading={modalLoading}
                showCreateSuiteModal={showCreateSuiteModal}
                handleCreateNewSuite={handleCreateNewSuite}
                handleCloseCreateSuiteModal={handleCloseCreateSuiteModal}
              />
            )}
            {showEditSuiteModal && renderEditSuiteModal()}
          </>
        ) : (
          <>
            <BackButton
              prevPageTitle="Test Suites"
              onClick={handleBackToTestSuites}
            />
            <TestCaseHeader>
              <Col
                xs={{ span: 21 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
                xl={{ span: 19 }}
              >
                <Title
                  text={`${selectedTestSuite.name} (${testCases.length}/${max_test_cases})`}
                  type="responsive-text"
                />
              </Col>

              {testCases.length ? (
                <>
                  <Col>
                    <Checkbox
                      onChange={handleToggleUseDraft}
                      defaultChecked={useDraft}
                    >
                      <Tooltip title="Include draft answers">
                        {'Use draft'}
                      </Tooltip>
                    </Checkbox>
                  </Col>
                  <Col
                    xs={{ span: 23 }}
                    md={{ span: 3 }}
                    lg={{ span: 3 }}
                    xl={{ span: 2 }}
                  >
                    <Button
                      onClick={handleRunTestSuite}
                      value={`Run`}
                      startIcon={<CaretRightOutlined />}
                      variant="success"
                      full
                    />
                  </Col>
                </>
              ) : null}
            </TestCaseHeader>

            <Row>
              <Col
                xs={{ span: 23 }}
                md={{ span: 23 }}
                lg={{ span: 3 }}
                xl={{ span: 3 }}
              >{`Total Test: ${testCases.length}`}</Col>
              <Col
                xs={{ span: 23 }}
                md={{ span: 23 }}
                lg={{ span: 5 }}
                xl={{ span: 5 }}
              >
                {`Last modified: ${getTimeDifference(
                  selectedTestSuite.lastModified
                )}`}
              </Col>
              <Col
                xs={{ span: 23 }}
                md={{ span: 23 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                {`Last Run/Tested: ${
                  getTimeDifference(testLastRanTime) || 'N/A'
                }`}
              </Col>
            </Row>

            {showTestStatus && (
              <TestStatusWrapper gutter={[8, 8]}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 6 }}
                >
                  <StyledTestStatus color={cssVariables.success}>
                    <div>{testStatus.success}</div>
                    <div>Tests Passed</div>
                  </StyledTestStatus>{' '}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 6 }}
                >
                  <StyledTestStatus color={cssVariables.red10}>
                    <div>{testStatus.fail}</div>
                    <div>Failed Tests</div>
                  </StyledTestStatus>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 6 }}
                >
                  <StyledTestStatus color={cssVariables.warning}>
                    <div>{testStatus.outdated}</div>
                    <div>Edited Tests</div>
                  </StyledTestStatus>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 6 }}
                >
                  <StyledTestStatus color={cssVariables.gray0}>
                    <div>{testStatus.unknown}</div>
                    <div>Untested</div>
                  </StyledTestStatus>
                </Col>
              </TestStatusWrapper>
            )}

            <StyledTestCases>
              <StyledTestCasesHeader>
                <StyledTestCasesAction>
                  <Col
                    xs={{ span: 20 }}
                    md={{ span: 16 }}
                    lg={{ span: 14 }}
                    xl={{ span: 14 }}
                  >
                    <StyledTestCaseSearchInput
                      type="search"
                      prefix={<StyledIconSearch />}
                      placeholder="Type to search test cases"
                      value={testCaseSearchKey}
                      onChange={handleSearchTestCases}
                    />
                  </Col>
                  <Col
                    xs={{ span: 2 }}
                    md={{ span: 8 }}
                    lg={{ span: 10 }}
                    xl={{ span: 10 }}
                  >
                    <Menu
                      mode="horizontal"
                      className={isMobileView ? 'add-question-btn' : ''}
                    >
                      {!importExportEnabled ? (
                        <Menu.Item key="export">
                          <LimitationTooltip
                            title="Upgrade to export test cases"
                            disabledFeat={
                              <>
                                {`Export`}
                                <DownloadOutlined />
                              </>
                            }
                          />
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          key="exportEnabled"
                          onClick={handleDownloadTestCases}
                        >
                          {' '}
                          <Button
                            disabled={
                              !filteredTestCases.length || !importExportEnabled
                            }
                            variant="link"
                            value={`Export`}
                            endIcon={<DownloadOutlined />}
                            full
                          />{' '}
                        </Menu.Item>
                      )}
                      {isMaxTestCases ? (
                        <Menu.Item key="addTest">
                          <LimitationTooltip
                            title={<MaxReachedToolTip type="cases" />}
                            disabledFeat={`Add Test`}
                            style={{ whiteSpace: 'nowrap' }}
                            isAnElement={true}
                          />
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          key="addTestEnabled"
                          onClick={() =>
                            handleShowValidationQuestionModal(null)
                          }
                        >
                          {' '}
                          <Button
                            className={isMobileView ? '' : 'add-question-btn'}
                            // style={{ width: 200 }}
                            variant="primary-btn-v2"
                            value={`Add Test`}
                            full
                          />
                        </Menu.Item>
                      )}
                    </Menu>
                  </Col>
                </StyledTestCasesAction>
              </StyledTestCasesHeader>

              <StyledValidationTable
                dataSource={filteredTestCases}
                pagination={{ pageSize: 50 }}
                columns={TestCasesList}
              />
            </StyledTestCases>

            {isMaxTestCases && <MaxReachedNotice type="cases" />}
            {showTestCaseDetailsModal && <RenderTestCaseDetailsModal />}
            {showValidationQuestionModal && (
              <ValidationQuestionModal
                answer={expectedAnswerToEdit ? expectedAnswerToEdit.text : ''}
                handleSelect={handleSelectResponse}
                handleShowResponses={handleShowResponses}
                handleAddQuestion={handleAddQuestion}
                handleUpdateTestCase={handleUpdateTestCase}
                modalLoading={modalLoading}
                mode={testCaseToEdit && testCaseToEdit.answer ? 'Edit' : 'Add'}
                onCloseModal={handleCloseValidationQuestionModal}
                onChangeText={handleChangeTestCaseQuestion}
                responseCount={responseCount}
                responses={responses}
                selectedResponse={selectedResponse}
                testCase={testCaseToEdit}
                totalTestCases={testCases.length}
                visible={showValidationQuestionModal}
              />
            )}
          </>
        )}
      </StyledRoot>
    </Spin>
  );
};

export default Validation;
