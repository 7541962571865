import { message } from 'antd';
import ROUTES from 'constants/routes';
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';
import _ from 'lodash';
import moment from 'moment';

import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { GET_DATA_ERROR } from 'constants/error';
import { escapeCSV, filterObjectKeys, stripUUID, withPrefixUUID } from 'utils';
import { getCurrentBotDetailsPage } from 'utils/bot';
import { fetchSessionCallback } from 'services/callback.service';
import {
  CLEAR_CALLBACK_MODAL_SESSION_DETAILS,
  SET_CALLBACK_LOGS_TABLE_DATA,
  SET_CALLBACK_MODAL_SESSION_DETAILS,
} from 'store/action';
import useSelector from 'store/useSelector';
import {
  callbackLogsTableDataSelector,
  callbackSessionFiltersSelector,
} from 'selectors/bot/callbackLogs';
import { isPageReadySelector } from 'selectors/bot';
import { isMobileViewSelector } from 'selectors/layout';

const EXCLUDED_CALLBACK_COLUMNS = [
  'date',
  'email',
  'mobile',
  'name',
  'phoneNumber',
  'sessionID',
];

const useCallbackLog = () => {
  const { search, pathname } = useLocation();
  const [state, dispatch] = useContext(Context);
  const tableData = useSelector(callbackLogsTableDataSelector);
  const callbackSessionFilters = useSelector(callbackSessionFiltersSelector);
  const isPageReady = useSelector(isPageReadySelector);
  const isMobileView = useSelector(isMobileViewSelector);

  const {
    bot: { jid },
    sentinel,
    token,
  } = state;
  const query = new URLSearchParams(search);
  const sessionIDParam = query.get('sessionid');
  const [pageLoading, setPageLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selectedSessionID, setSelectedSessionID] = useState(null);
  const [callbackInteractionIDs, setCallbackInteractionIDs] = useState([]);
  const [sessionFilteredInfo, setSessionFilteredInfo] = useState([
    sessionIDParam || [],
  ]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCallbackLog, setSelectedCallBackLog] = useState(null);
  const startOfMonthInMS = new Date().setDate(new Date().getDate() - 30);
  const startOfMonth = new Date(startOfMonthInMS).toISOString();
  const todayInMS = new Date();
  const todayISO = new Date(todayInMS).toISOString();
  const [dateFilter, setDateFilter] = useState({
    startDate: startOfMonth,
    endDate: todayISO,
  });
  const STRIPPED_BOT_JID = stripUUID(withPrefixUUID(jid || pathname));
  const withPrefixBotJID = withPrefixUUID(withPrefixUUID(jid || pathname));
  const CALLBACK_LOGS_PAGE = getCurrentBotDetailsPage(
    STRIPPED_BOT_JID,
    ROUTES.CALLBACK_LOGS
  );
  const isCurrentPage = pathname === CALLBACK_LOGS_PAGE;

  const fetchCallbackByDateRange = async (start_date, end_date) => {
    try {
      setPageLoading(true);
      const endDate = moment(end_date).isSame(todayISO, 'day')
        ? todayISO
        : end_date;
      const res = await apiService.getCallbackByDateRange(
        sentinel,
        withPrefixBotJID,
        start_date,
        endDate,
        token
      );
      dispatch({
        type: SET_CALLBACK_LOGS_TABLE_DATA,
        payload: { data: res.data.report },
      });

      setPageLoading(false);
    } catch (err) {
      setPageLoading(false);
      return message.error(err.message || GET_DATA_ERROR);
    }
  };

  const handleDateFilterChange = (start_date, end_date) => {
    const startDateConverted = new Date(start_date);
    const endDate = new Date(end_date);
    setDateFilter({
      startDate: startDateConverted,
      endDate: endDate,
    });
  };

  const handleClickDateFilterButton = () => {
    const { startDate, endDate } = dateFilter;
    return fetchCallbackByDateRange(startDate, endDate, true);
  };

  const handleShowSessionCallbacks = async row => {
    setSelectedCallBackLog({
      email: row.email,
      mobile: row.mobile,
    });
    setSelectedSessionID(row.sessionID);
    setShowModal(true);
    try {
      setModalLoading(true);
      const res = await fetchSessionCallback(
        row.sessionID,
        sentinel,
        withPrefixBotJID,
        token
      );
      dispatch({
        type: SET_CALLBACK_MODAL_SESSION_DETAILS,
        payload: {
          data: res,
          sessionId: row.sessionID,
          callbackInteractionIds: row.interactionID,
          isFromAPIResponse: true,
        },
      });
      setModalLoading(false);
    } catch (error) {
      setModalLoading(false);
      message.error(error);
    }
  };

  const handleCloseSessionCallbacks = () => {
    dispatch({
      type: CLEAR_CALLBACK_MODAL_SESSION_DETAILS,
    });
    setShowModal(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSessionFilteredInfo(filters.sessionID);
  };

  const handleXLSXDownloadQlogs = () => {
    const xlsxData = tableData
      .map(item => {
        item['sessionID'] = !item?.sessionID ? 'N/A' : item.sessionID;
        return item;
      })
      // rename keys
      .map(i => {
        // `Others` column will have a comma after each item
        // e.g. `{item},`

        // FIX ME: allow SheetJS to add newline after each item
        // to have a similar experience with CSV download :)
        const othersCol = i?.others
          ? Object.entries(
              filterObjectKeys(i.others, EXCLUDED_CALLBACK_COLUMNS)
            ).map(key =>
              escapeCSV(`${_.startCase(key[0]) || 'N/A'}: ${key[1] || 'N/A'}`)
            )
          : 'N/A';

        return {
          Date: i.date,
          'Session ID': i.sessionID,
          Name: i.name,
          Email: i.email,
          'Phone Number': i.mobile || 'N/A',
          Others: othersCol.toString().replaceAll(/["']/g, ''),
        };
      });

    const worksheet = XLSX.utils.json_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ZSB Callback Logs');
    XLSX.writeFile(workbook, 'zsb-callback-logs.xlsx');
  };

  const handleCSVDownloadQlogs = () => {
    const titleColumn = [
      'Date,',
      'Session ID,',
      'Name,',
      'Email,',
      'Phone Number,',
      'Others',
      '\n',
    ];
    const fileData = tableData.map(item => {
      const sessionID = !item?.sessionID ? 'N/A' : item.sessionID;
      const phoneNumber = !item?.mobile ? 'N/A' : item.mobile;
      // `Others` column will output newline after each item
      // e.g. `{item} \n`
      const othersCol = item?.others
        ? Object.entries(
            filterObjectKeys(item.others, EXCLUDED_CALLBACK_COLUMNS)
          ).map(i =>
            escapeCSV(`${_.startCase(i[0]) || 'N/A'}: ${i[1] || 'N/A'} \n`)
          )
        : 'N/A';

      return (
        item.date +
        ',' +
        sessionID +
        ',' +
        item.name +
        ',' +
        item.email +
        ',' +
        phoneNumber +
        ',' +
        escapeCSV(othersCol.toString().replaceAll(/["',]/g, '')) +
        '\n'
      );
    });
    const blob = new Blob(['\ufeff', ...titleColumn, ...fileData], {
      type: 'text/csv',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'zsb-callback-logs.csv';
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (sentinel && isPageReady && isCurrentPage) {
      fetchCallbackByDateRange(startOfMonth, todayISO);
    }
  }, [sentinel, isPageReady]);

  return {
    tableData,
    EXCLUDED_CALLBACK_COLUMNS,
    handleClickDateFilterButton,
    handleCloseSessionCallbacks,
    handleTableChange,
    handleDateFilterChange,
    handleShowSessionCallbacks,
    handleCSVDownloadQlogs,
    handleXLSXDownloadQlogs,
    modalLoading,
    pageLoading,
    questions,
    selectedSessionID,
    callbackInteractionIDs,
    callbackSessionFilters,
    sessionFilteredInfo,
    showModal,
    isPageReady,
    isMobileView,
    selectedCallbackLog,
  };
};

export default useCallbackLog;
