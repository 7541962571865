import { Layout, Row } from 'antd';
import styled from 'styled-components';

export const StyledJiraColumn = styled.div`
  display: flex;
  max-width: 100%;
  padding: 10px 0;
  flex-direction: column;
  margin-left: 10px;
`;

export const StyledSplittedRows = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin-left: 10px;

  .ant-upload {
    cursor: pointer;
  }

  > span:first-child {
    padding: 0;
  }
`;

export const StyledJiraForm = styled.form`
  > * {
    margin-bottom: 10px;
  }
`;

export const StyledPopoverLayout = styled(Layout)`
  width: 800px;
  padding: 16px;
  background: none;

  @media (max-width: 480px) {
    width: 320px;
    padding: 5px;
  }

  @media (max-width: 600px) {
    width: 440px;
    padding: 5px;
  }

  @media (max-width: 800px) {
    width: 600px;
  }
`;

export const StyledResponsiveRow = styled(Row)`
  display: flex;
  justify-content: space-between;

  .ant-upload {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      width: 30%;
    }
  }
`;
