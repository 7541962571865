import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import {
  StyledWrapper,
  StyledBot,
  StyledLargeText,
  StyledMessage,
} from './EmptyAnswer.style';
import FadedRobot from 'assets/images/robot-icon-faded-small.svg';
import { Context } from 'store/store';
import ROUTES from 'constants/routes';
import Button from 'components/Button';
import { getBotJIDFromPath } from 'utils/bot';
import { parseBoolean } from 'utils/dataTypes';

const NO_DATA_MESSAGE = `No answer found. 
  Add your answer for him to show up ;)`;
const LARGE_TEXT = `It's empty here...`;
const NO_BOT_MESSAGE = `No bot ID found.`;

const EmptyData = ({ subtitle, loading }) => {
  const [state] = useContext(Context);
  const {
    bot: { jid },
  } = state;
  const { search } = useLocation();
  const botJID = getBotJIDFromPath(jid, search);
  const history = useHistory();
  return (
    <Spin spinning={parseBoolean(loading)} tip="Just a moment...">
      <StyledWrapper>
        <StyledBot src={FadedRobot} alt="Faded robot" />
        <StyledLargeText>{LARGE_TEXT}</StyledLargeText>
        {botJID ? (
          <StyledMessage>{subtitle || NO_DATA_MESSAGE}</StyledMessage>
        ) : (
          <>
            <StyledMessage>{NO_BOT_MESSAGE}</StyledMessage>
            <Button
              variant={'link'}
              onClick={() => history.push(ROUTES.BOTS_PAGE)}
              value={'Go back to bots page'}
            />
          </>
        )}
      </StyledWrapper>
    </Spin>
  );
};

EmptyData.propTypes = {
  loading: PropTypes.bool,
  subtitle: PropTypes.string,
};

export default EmptyData;
