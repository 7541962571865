import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledResetPassword = styled.div`
  display: block;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 120px;
  flex-direction: column;
  max-width: 472px;
`;

export const StyledHeaderTitle = styled.div`
  margin-bottom: 40px;
`;

export const StyledResetPasswordFields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &label {
    font-weight: ${cssVariables.font.normal};
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  padding: 0 10px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
