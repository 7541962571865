import React from 'react';
import PropTypes from 'prop-types';
import { WarningOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

import useSelector from 'store/useSelector';
import {
  widgetAvatarResponseSelector,
  widgetAvatarResponseTypeSelector,
} from 'selectors/bot/integration/settings';
import {
  StyledBotCustomComponent,
  StyledHeaderrw,
  StyledParsedAnswer,
  StyledResponse,
  StyledTime,
} from './BotCustomizer.styles';
import { AnswerRenderer } from 'components/AnswerRenderer';
import { generateIcon } from './iconUtils';
import { currentIntegrationSettingsSettingPropSelector } from 'selectors/bot/integration';

const V1CustomComponent = ({ message, time, hasError }) => {
  const { fontSize, iconColor, color } = useSelector(
    currentIntegrationSettingsSettingPropSelector
  );
  const responseAvatar = useSelector(widgetAvatarResponseSelector);
  const responseAvatarType = useSelector(widgetAvatarResponseTypeSelector);
  return responseAvatar &&
    responseAvatarType &&
    responseAvatarType !== 'none' ? (
    <StyledHeaderrw>
      {
        <Avatar
          style={{
            backgroundColor: color,
          }}
          src={
            responseAvatarType === 'custom'
              ? responseAvatar
              : generateIcon(
                  responseAvatar,
                  iconColor,
                  '25',
                  '30',
                  responseAvatar === 'icon2'
                    ? '-.5 -1.6 25 25'
                    : responseAvatar === 'icon3'
                    ? '-.5 0 25 25'
                    : '-.7 -.5 25 25'
                )
          }
        />
      }
      <StyledResponse>
        <StyledBotCustomComponent
          className={`${hasError ? 'error' : ''}`}
          style={{
            fontSize,
          }}
        >
          {hasError ? (
            <>
              <WarningOutlined />{' '}
              <AnswerRenderer answer={message} showDisplayAnswer={true} />
            </>
          ) : (
            <AnswerRenderer
              isWidgetAnswer
              answer={message}
              showDisplayAnswer={true}
            />
          )}
        </StyledBotCustomComponent>
        <StyledTime>{time}</StyledTime>
      </StyledResponse>
    </StyledHeaderrw>
  ) : (
    <div>
      <StyledBotCustomComponent
        className={`${hasError && 'error'}`}
        style={{
          fontSize,
        }}
      >
        {hasError ? (
          <>
            <WarningOutlined />{' '}
            <AnswerRenderer answer={message} showDisplayAnswer={true} />
          </>
        ) : (
          <StyledParsedAnswer
            className="parsedAnswer"
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        )}
      </StyledBotCustomComponent>
      <StyledTime>{time}</StyledTime>
    </div>
  );
};

V1CustomComponent.propTypes = {
  message: PropTypes.string,
  time: PropTypes.string,
  hasError: PropTypes.bool,
};

export default V1CustomComponent;
