import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { StyledActionBtns } from './CustomizeBot.style';
import Button from 'components/Button';
import useSelector from 'store/useSelector';
import {
  isAppearancePanelSettingsUnchangedSelector,
  isConfigPanelSettingsUnchangedSelector,
  isPlacementPanelSettingsUnchangedSelector,
} from 'selectors/bot/integration';
import { Context } from 'store/store';
import {
  RESET_APPEARANCE_PANEL_SETTINGS,
  RESET_CONFIG_PANEL_SETTINGS,
  RESET_PLACEMENT_PANEL_SETTINGS,
} from 'store/action';

const PanelActionButtons = ({
  disabled,
  onPanelChange,
  nextPanelKey,
  isLastPanel,
  panelName,
}) => {
  const [, dispatch] = useContext(Context);
  const isConfigPanelSettingsUnchanged = useSelector(
    isConfigPanelSettingsUnchangedSelector
  );
  const isAppearancePanelSettingsUnchanged = useSelector(
    isAppearancePanelSettingsUnchangedSelector
  );
  const isPlacementPanelSettingsUnchanged = useSelector(
    isPlacementPanelSettingsUnchangedSelector
  );

  const handleClickNext = () => {
    if (!Boolean(isLastPanel)) {
      return onPanelChange(nextPanelKey);
    }
  };

  const shouldHideSubmit = () => {
    switch (panelName) {
      case 'config':
        return isConfigPanelSettingsUnchanged;
      case 'appearance':
        return isAppearancePanelSettingsUnchanged;
      case 'placement':
        return isPlacementPanelSettingsUnchanged;
      default:
        return false;
    }
  };

  const handleResetPanelFields = () => {
    switch (panelName) {
      case 'config':
        dispatch({ type: RESET_CONFIG_PANEL_SETTINGS });
        break;
      case 'appearance':
        dispatch({ type: RESET_APPEARANCE_PANEL_SETTINGS });
        break;
      case 'placement':
        dispatch({ type: RESET_PLACEMENT_PANEL_SETTINGS });
        break;
      default:
        break;
    }
  };

  return (
    <StyledActionBtns hidden={shouldHideSubmit()}>
      <Button
        type={isLastPanel ? 'submit' : 'button'}
        variant="primary"
        value={isLastPanel ? 'Done' : 'Next'}
        onClick={handleClickNext}
        disabled={disabled}
      />
      <Button
        type="button"
        value="Reset"
        variant="secondary"
        onClick={handleResetPanelFields}
      />
    </StyledActionBtns>
  );
};

PanelActionButtons.propTypes = {
  disabled: PropTypes.bool,
  onPanelChange: PropTypes.func.isRequired,
  nextPanelKey: PropTypes.string,
  isLastPanel: PropTypes.bool,
  panelName: PropTypes.oneOf(['config', 'appearance', 'placement']).isRequired,
};

export default PanelActionButtons;
