import { EditTwoTone } from '@ant-design/icons';
import { cssVariables } from 'styles/root';

export const ANSWER_SORTING_LIST = [
  {
    key: 0,
    label: 'Date Modified (desc)',
    sortBy: 'lastEdited',
  },
  {
    key: 1,
    label: 'Date Modified (asc)',
    sortBy: 'lastEdited',
  },
  {
    key: 2,
    label: 'Length (desc)',
    sortBy: 'answerLength',
  },
  {
    key: 3,
    label: 'Length (asc)',
    sortBy: 'answerLength',
  },
  {
    key: 4,
    label: 'Popularity (desc)',
    sortBy: 'hitcount',
  },
  {
    key: 5,
    label: 'Popularity (asc)',
    sortBy: 'hitcount',
  },
];

export const ANSWER_VERSION_LIST = [
  {
    label: <span style={{ color: cssVariables.red10 }}>{'Draft'}</span>,
    key: 'draft',
    icon: <EditTwoTone />,
    value: 'draft',
  },
  {
    label: <span style={{ color: cssVariables.success }}>{'Final'}</span>,
    key: 'final',
    value: 'final',
  },
];
