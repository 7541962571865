import moment from 'moment';

export const getLastWeeksDate = () => {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
};

export const getLastWeekISODate = () => {
  const now = new Date();

  return new Date(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).setHours(
      0,
      0,
      0,
      0
    )
  ).toISOString();
};

export const UTCToLocal = date => {
  const newDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000
  );
  return newDate;
};

export const getLocalTimeString = date =>
  date.toLocaleString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

export const getTimeDifference = (
  startDate,
  endDate = new Date(startDate + 'Z'),
  differenceBy = 'day'
) => {
  const timeDiff = differenceBy.toLowerCase().replace(/s$/, '');
  if (!startDate) {
    return null;
  }

  const now = new Date();
  const time = endDate;
  const diff = (now.getTime() - time.getTime()) / 1000;
  const diffMin = Math.round(diff / 60);
  const diffHours = Math.round(diff / 3600);
  const diffDays = Math.round(diff / (3600 * 24));

  if (timeDiff.toLowerCase() === 'second' || diff < 60) {
    return `seconds ago`;
  } else if (timeDiff.toLowerCase() === 'minute' || diffMin < 60) {
    return `${diffMin}m ago`;
  } else if (timeDiff.toLowerCase() === 'hour' || diffHours < 24) {
    return `${diffHours}h ago`;
  } else {
    return `${diffDays}d ago`;
  }
};

export const isDateValid = input => {
  try {
    if (moment(input).isValid()) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const convertToEndOfDay = date => {
  if (!moment(date).isValid()) {
    return 'Invalid Date';
  }
  return new Date(new Date(date).setHours(23, 59, 59, 59)).toISOString();
};

export const convertToStartOfDay = date => {
  if (!moment(date).isValid()) {
    return 'Invalid Date';
  }
  return new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString();
};
