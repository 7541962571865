import { useState, useEffect, useContext } from 'react';
import { message } from 'antd';

import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { strippedString } from 'utils/stringManipulation';
import { isAnObject } from 'utils/dataTypes';
import {
  DEFAULT_EDITOR,
  MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION,
  ZSB_CHAT_BREAKER_ENCONDING,
} from 'constants/answerbank/defaults';
import {
  ADD_ANSWER,
  CLOSE_ANSWER_EDITOR,
  SET_ANSWER_EDITOR_CARD_INSIGHTS,
  SET_ANSWER_EDITOR_GRAPH_INSIGHTS,
  SET_ANSWER_EDITOR_LINKED_QUESTIONS,
  SET_ANSWER_EDITOR_TABLE_INSIGHTS,
  UPDATE_ANSWER,
  UPDATE_ANSWER_EDITOR_ANSWER,
} from 'store/action';
import {
  DEFAULT_ANSWER_VERSION,
  CREATE_ANSWER_MANUAL_SOURCE,
  CREATE_ANSWER_MANUAL_SOURCE_TYPE,
} from 'constants/answerbank/defaults';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import {
  answerEditorAnswerSelector,
  answerEditorQuestionsSelector,
  answerEditorSimilarAnswerSelector,
  allAnswersSelector,
  isAnswerEditorOpenSelector,
  isCreateModeAnswerEditorSelector,
  isMaxAnswersSelector,
  answerEditorInsightsSelector,
} from 'selectors/bot/answers';
import { allCategoriesSelector } from 'selectors/bot/categories';
import {
  getGraphTimeInterval,
  isElasticAggregationsEmpty,
} from 'utils/analytics';
import useSelector from 'store/useSelector';
import { convertToEndOfDay, convertToStartOfDay } from 'utils/dates';

const MAX_QUICK_REPLY_ROWS = 3;
const MIN_QUICK_REPLY_ROWS = 0;
const initialQuickReplies = {
  quickReply: false,
  quickReplyOptions: [
    {
      label: '',
      question: '',
      editableQuestion: false,
      answer: '',
      key: 0,
    },
  ],
};

const useAnswerEditor = () => {
  const [state, dispatch] = useContext(Context);
  const allAnswers = useSelector(allAnswersSelector);
  const allCategories = useSelector(allCategoriesSelector);
  const answerEditorAnswer = useSelector(answerEditorAnswerSelector);
  const answerEditorQuestions = useSelector(answerEditorQuestionsSelector);
  const answerEditorInsights = useSelector(answerEditorInsightsSelector);
  const isOpen = useSelector(isAnswerEditorOpenSelector);
  const isCreateMode = useSelector(isCreateModeAnswerEditorSelector);
  const similarAnswers = useSelector(answerEditorSimilarAnswerSelector);
  const limitReached = useSelector(isMaxAnswersSelector);
  const {
    sentinel,
    token,
    bot: { jid },
  } = state;

  const [answer, setAnswer] = useState(answerEditorAnswer);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [tabView, setTabView] = useState(null);
  const [editor, setEditor] = useState(answerEditorAnswer?.editor);
  const [error, setError] = useState(null);
  const [editedHTML, setEditedHTML] = useState(answerEditorAnswer?.html);
  const [editedRichTextAnswer, setEditedRichTextAnswer] = useState(
    answerEditorAnswer?.rich_text
  );
  const [editedStringAnswer, setEditedStringAnswer] = useState(
    answerEditorAnswer?.text
  );
  const [selectedCategory, setSelectedCategory] = useState(
    answerEditorAnswer?.categoryId || null
  );
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(true);
  const [dragId, setDragId] = useState(null);
  const [isTextCategory, setIsTextCategory] = useState(false);
  const [textCategoryValue, setTextCategoryValue] = useState(null);
  const [categoryColor, setCategoryColor] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [quickReplies, setQuickReplies] = useState(initialQuickReplies);
  const [isFullScreen, setFullScreen] = useState(false);
  const [answerVariants, setAnswerVariants] = useState([]);
  const [successMessage, setSuccssMessage] = useState(null);
  const [shouldUseSkeletonComponent, setShouldUseSkeletonComponent] =
    useState(false);
  const DEFAULT_ANSWER_VARIANT = {
    forms: [{ key: 1, select1: '', select2: '', select3: [''] }],
    displayAnswer: {
      html: '',
      rce: '',
    },
    editor: DEFAULT_EDITOR,
    key: answerVariants ? answerVariants.length + 1 : 1,
  };
  const startDateInMS = new Date().setDate(new Date().getDate() - 7);
  const startDate = new Date(startDateInMS);
  const todayInMS = new Date();
  const todayISO = new Date(todayInMS);
  const [dateFilter, setDateFilter] = useState({
    startDate: startDate,
    endDate: todayISO,
  });
  const [sessionFilteredInfo, setSessionFilteredInfo] = useState(null);
  const [parsedAnswer, setParsedAnswer] = useState([]);

  useEffect(() => {
    if (isOpen && isAnObject(answerEditorAnswer)) {
      if (isCreateMode && similarAnswers) {
        setAnswer({ ...answerEditorAnswer, version: 'draft' });
      }

      if (answerEditorAnswer?.quickReply) {
        setQuickReplies({
          ...quickReplies,
          quickReply: answerEditorAnswer.quickReply,
          quickReplyOptions: answerEditorAnswer.quickReplyOptions,
        });
      } else if (answerEditorAnswer.requestAgent) {
        setRequestAgent(true);
      }
    }

    return () => {
      handleClearAnswerEditorLocalState();
      setAnswer({});
    };
  }, [answerEditorAnswer]);

  const [requestAgent, setRequestAgent] = useState(
    answer?.requestAgent || false
  );
  const isRemoveRowDisabled =
    quickReplies?.quickReplyOptions?.length <= MIN_QUICK_REPLY_ROWS;

  const handleChangeRequestAgentCheckbox = () => {
    setRequestAgent(!requestAgent);
    setQuickReplies({
      ...quickReplies,
      quickReply: false,
    });
  };

  const scrollIntoWarningView = () => {
    setTimeout(() => {
      const target = document.getElementsByClassName('ant-alert');
      if (target && target[0]) {
        target[0].scrollIntoViewIfNeeded();
      }
    }, 500);
  };

  const handleOnUpdateAnswer = async istemp => {
    setConfirmLoading(true);

    // get similar answers instead if:
    // answer has not been edited
    // no similar answers has been set/found on state
    // answer version is final
    let hasNewSimilarAnswers = false;
    if (
      answerEditorAnswer.text !== editedStringAnswer &&
      answer.version === 'final'
    ) {
      hasNewSimilarAnswers = await getSimilarAnswers(answer.version);
    }
    if (hasNewSimilarAnswers) {
      return false;
    }

    const formattedAnswer =
      editor === DEFAULT_EDITOR ? editedRichTextAnswer : editedHTML;

    const quickButtons = {
      ...quickReplies,
      requestAgent,
    };

    try {
      const hasEmptyQuickReply =
        quickReplies?.quickReply &&
        (quickButtons?.quickReplyOptions || []).some(
          item =>
            // trim non-alphanemuric values
            !strippedString(item.label) ||
            !strippedString(item.label).length ||
            !strippedString(item.question) ||
            !strippedString(item.question).length
        );

      if (hasEmptyQuickReply) {
        throw new Error(
          'Empty quick reply input field found.\n Please fill out all quick reply fields or delete the entire row'
        );
      }

      // not sure if this is still needed
      if (istemp && !answerEditorAnswer.clone) {
        await onUpdateAnswer(
          null,
          editedStringAnswer,
          formattedAnswer,
          selectedCategory,
          answerEditorAnswer.idx,
          editor,
          quickButtons,
          answer?.version || DEFAULT_ANSWER_VERSION
        );
        return handleClose();
      } else if (answerEditorAnswer.clone) {
        await handleCloneAnswer(
          editedStringAnswer,
          formattedAnswer,
          editor,
          selectedCategory,
          answer?.version || DEFAULT_ANSWER_VERSION
        );
      } else {
        await onUpdateAnswer(
          answerEditorAnswer.id,
          editedStringAnswer,
          formattedAnswer,
          selectedCategory,
          answerEditorAnswer.idx,
          editor,
          quickButtons,
          answer?.version || DEFAULT_ANSWER_VERSION
        );
      }
      setConfirmLoading(false);
      handleClose();
    } catch (error) {
      setError({ detail: error?.message || error });
      scrollIntoWarningView();
    }
    setConfirmLoading(false);
  };

  const onUpdateAnswer = async (
    id,
    newAnswer,
    formattedAnswer,
    categoryId,
    chatIndex = null,
    editor,
    quickButtons,
    version
  ) => {
    setConfirmLoading(true);
    const isAnswerExists = getStringAnswerDuplicate(newAnswer, id);
    try {
      if (isAnswerExists) {
        throw new TypeError('Answer already exists.');
      }
      const res = await apiService.changeAnswer(
        sentinel,
        id,
        newAnswer,
        formattedAnswer,
        categoryId,
        token,
        editor,
        quickButtons,
        version
      );
      const updatedAnswer = res.data.report[0];

      dispatch({
        type: UPDATE_ANSWER,
        payload: { updatedAnswer, msgIndex: chatIndex },
      });

      setConfirmLoading(false);
    } catch (err) {
      setConfirmLoading(false);
      throw err.message;
    }
  };

  const handleOnAddAnswer = async skipSimilarCheck => {
    try {
      setConfirmLoading(true);
      const isAnswerExists = getStringAnswerDuplicate(answer?.text);
      try {
        if (limitReached) {
          throw new TypeError(
            'Maximum number of answers reached. Please upgrade your plan.'
          );
        }
        if (isAnswerExists) {
          throw new TypeError('Answer already exists.');
        }

        const answerdetails = {
          displayAnswer: answer?.text || answerEditorAnswer.text,
          text: answer?.text || answerEditorAnswer.text,
        };

        const res = await apiService.createAnswer(
          sentinel,
          jid,
          {
            source: CREATE_ANSWER_MANUAL_SOURCE,
            source_type: CREATE_ANSWER_MANUAL_SOURCE_TYPE,
          },
          answerdetails,
          token,
          skipSimilarCheck,
          answerEditorAnswer?.version
        );
        const answerData = res.data.report[0];
        if (Array.isArray(answerData)) {
          dispatch({
            type: UPDATE_ANSWER_EDITOR_ANSWER,
            payload: {
              // We're including the component state of the `answer` to the payload
              // since updating the similarAnswer's global state will cause rerender
              // and the component state will be lost
              text: editedStringAnswer,
              version: answer?.version,
              // ditched using component state to store similarAnswers data
              similarAnswers: answerData,
            },
          });
        } else {
          dispatch({
            type: ADD_ANSWER,
            payload: answerData,
          });
          message.success('Successfully added an answer.');
        }
      } catch (err) {
        message.error(
          err.message || 'Unexpected error encountered while saving.'
        );
      }
      setConfirmLoading(false);
      handleClose();
    } catch (error) {
      setError({ detail: error?.message || error });
      scrollIntoWarningView();
    }
    setConfirmLoading(false);
  };

  const handleSelectCategory = categoryId => {
    setSelectedCategory(categoryId);
  };

  const getStringAnswerDuplicate = (input, id) => {
    const strippedInput = strippedString(input);
    return allAnswers.find(
      answer =>
        strippedString(answer.text) === strippedInput && answer.jid !== id
    );
  };

  const handleCloneAnswer = async (
    newAnswer,
    formattedAnswer,
    editor = DEFAULT_EDITOR,
    categoryId,
    ansVersion
  ) => {
    setConfirmLoading(true);
    const isAnswerExists = getStringAnswerDuplicate(newAnswer);
    try {
      if (isAnswerExists) {
        setConfirmLoading(false);
        throw new TypeError('Answer already exists.');
      }
      const answerdetails = {
        displayAnswer: formattedAnswer,
        text: newAnswer,
      };
      const res = await apiService.createAnswer(
        sentinel,
        jid,
        {
          source: CREATE_ANSWER_MANUAL_SOURCE,
          source_type: CREATE_ANSWER_MANUAL_SOURCE_TYPE,
        },
        answerdetails,
        token,
        false,
        ansVersion,
        editor,
        categoryId
      );
      const answerData = res.data.report[0];
      dispatch({
        type: ADD_ANSWER,
        payload: answerData,
      });
    } catch (err) {
      setConfirmLoading(false);
      throw err.message;
    }
    setConfirmLoading(false);
  };

  const handleQuickReplyChange = (e, type, key) => {
    const updatedArr = [];
    const { quickReplyOptions } = quickReplies;
    for (const item of quickReplyOptions) {
      if (item.key === key) {
        if (type === 'edit') {
          updatedArr.push({
            ...item,
            editableQuestion: !item.editableQuestion,
          });
        } else if (type === 'unlink') {
          updatedArr.push({
            ...item,
            answer: null,
            editableQuestion: false,
          });
        } else if (type === 'label') {
          updatedArr.push({
            ...item,
            label: e.target.value,
          });
        } else if (type === 'answer') {
          updatedArr.push({
            ...item,
            answer: e,
          });
        } else if (type === 'question') {
          updatedArr.push({
            ...item,
            question: e.target.value,
          });
        } else if (!item.editableQuestion) {
          updatedArr.push({
            ...item,
            label: e.target.value,
            question: e.target.value,
            answer: '',
          });
        } else {
          updatedArr.push({ ...item, question: e.target.value });
        }
      } else {
        updatedArr.push(item);
      }
    }

    setQuickReplies({
      ...quickReplies,
      quickReplyOptions: [...updatedArr],
    });
  };

  const handleDrag = evt => {
    setDragId(Number(evt.currentTarget.id));
  };

  const handleDrop = evt => {
    const { quickReplyOptions } = quickReplies;
    const dragRow = (quickReplyOptions || []).find(item => item.key === dragId);
    const dropRow = (quickReplyOptions || []).find(
      item => item.key === Number(evt.currentTarget.id)
    );

    const dragBoxOrder = dragRow.key;
    const dropBoxOrder = dropRow.key;

    const newRowOrder = (quickReplyOptions || []).map(item => {
      // - 1
      if (item.key === dragId) {
        item.key = dropBoxOrder;
      }
      if (item.key === Number(evt.currentTarget.id)) {
        item.key = dragBoxOrder;
      }
      return item;
    });

    setQuickReplies({
      ...quickReplies,
      quickReplyOptions: newRowOrder,
    });
  };

  const isAddRowDisabled = () => {
    const { quickReplyOptions } = quickReplies;

    if (quickReplyOptions) {
      return (quickReplyOptions || []).find(
        item => !item.label.length || !item.question.length
      ) || quickReplyOptions.length >= MAX_QUICK_REPLY_ROWS
        ? true
        : false;
    }
    return false;
  };

  const addRow = () => {
    const isDisabled = isAddRowDisabled();
    if (isDisabled) {
      return false;
    } else {
      setQuickReplies({
        ...quickReplies,
        quickReplyOptions: [
          ...quickReplies.quickReplyOptions,
          {
            label: '',
            question: '',
            key: quickReplies.quickReplyOptions?.length,
            editableQuestion: false,
          },
        ],
      });
    }
  };

  const deleteRow = idx => {
    const { quickReplyOptions } = quickReplies;
    const filteredQuickReplies = (quickReplyOptions || []).filter(
      (item, key) => key !== idx
    );

    if (isRemoveRowDisabled) {
      return false;
    } else if (filteredQuickReplies.length === 0) {
      setQuickReplies({
        quickReply: false,
        quickReplyOptions: [...filteredQuickReplies],
      });
    } else {
      setQuickReplies({
        ...quickReplies,
        quickReplyOptions: [...filteredQuickReplies],
      });
    }
  };

  const updateEditedStringAnswer = async evt => {
    setEditedStringAnswer(evt.target.value);
    setError(null);
  };

  const updateFormattedAnswer = async (val, plainText) => {
    setEditedHTML(val);
    setEditedRichTextAnswer(val);
  };

  const handleClearAnswerEditorLocalState = () => {
    setFullScreen(false);
    setAnswerVariants([]);
    setEditedRichTextAnswer(null);
    setEditedHTML(null);
    setQuickReplies(initialQuickReplies);
    setRequestAgent(false);
    setEditor(DEFAULT_EDITOR);
    setError(null);
    setConfirmLoading(false);
    setTabView(null);
    setAnswer({});
  };

  const handleClose = () => {
    if (isFullScreen) {
      return setFullScreen(false);
    }
    handleClearAnswerEditorLocalState();
    dispatch({
      type: CLOSE_ANSWER_EDITOR,
    });
  };

  const handleAddAnswerVariant = () => {
    setAnswerVariants([...answerVariants, DEFAULT_ANSWER_VARIANT]);
  };

  const handleUpdateVariantAnswer = (editedVariant, isOnlyForm) => {
    const updatedVariants = answerVariants
      .map(i => {
        if (editedVariant.key === i.key && isOnlyForm) {
          return null;
        } else if (editedVariant.key === i.key) {
          return editedVariant;
        }
        return i;
      })
      .filter(i => !!i);

    setAnswerVariants(updatedVariants);
  };

  const getSimilarAnswers = async version => {
    setShouldUseSkeletonComponent(true);
    let res;
    try {
      res = await apiService.getSimilarAnswer(
        sentinel,
        jid,
        editedStringAnswer || answerEditorAnswer.text,
        answerEditorAnswer.id || null,
        token
      );
      const quickButtons = {
        ...quickReplies,
        requestAgent,
      };
      const MAX_ANSWERS_SCORE = res.data.report[0].filter(
        item => item.score > MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION
      );

      dispatch({
        type: UPDATE_ANSWER_EDITOR_ANSWER,
        payload: {
          // We're including the component state of the `answer` to the payload
          // since updating the similarAnswer's global state will cause rerender
          // and the component state will be lost
          text: editedStringAnswer,
          html: editedHTML,
          rich_text: editedRichTextAnswer,
          categoryId: selectedCategory,
          idx: answerEditorAnswer.idx,
          editor,
          quickButtons,
          version:
            MAX_ANSWERS_SCORE.length === 0 && version ? version : 'draft',
          // ditched using component state to store similarAnswers data
          similarAnswers: res.data.report[0],
        },
      });
      setShouldUseSkeletonComponent(false);

      if (!res.data.report[0]?.length) {
        setSuccssMessage('No similar answer found.');
        return false;
      }
      return true;
    } catch (error) {}
    setShouldUseSkeletonComponent(false);
  };

  const handleChangeSwitch = async e => {
    try {
      await getSimilarAnswers(e ? 'final' : 'draft');
    } catch (error) {
      message.error(error.message || GET_DATA_ERROR);
    }
  };

  const handleChangeDateRange = (startDate, endDate) => {
    setDateFilter({
      startDate,
      endDate,
    });
  };

  const handleFetchInsightFromDateFilter = () => {
    fetchAggregationsData();
  };

  const sortFields = field => {
    switch (field) {
      case 'visitorID':
        return `metadata.${field}`;
      case 'date':
        return `datetime`;
      case 'validation':
        return `validation`;
      case 'channel':
        return `metadata.${field}.keyword`;
      case 'sessionID':
        return `metadata.${field}.keyword`;
      default:
        return `${field}.raw`;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSessionFilteredInfo(filters.sessionID);
    fetchTableData({
      page: 1,
      size: 1000,
      sort: {
        [sortFields(sorter.field)]: sorter.order === 'ascend' ? 'asc' : 'desc',
      },
    });
  };

  const fetchGraphData = async () => {
    setConfirmLoading(true);
    const startDate = convertToStartOfDay(dateFilter.startDate);
    const endDate = convertToEndOfDay(dateFilter.endDate);
    const dateInterval = getGraphTimeInterval(startDate, endDate);

    try {
      const ESRes = await apiService.getElasticByIntervalLogDate(
        sentinel,
        jid,
        startDate,
        endDate,
        dateInterval,
        [],
        null,
        token,
        { answerIds: [answerEditorAnswer.id] }
      );

      dispatch({
        type: SET_ANSWER_EDITOR_GRAPH_INSIGHTS,
        payload: {
          data: ESRes.data.report[0],
          startDate,
          endDate,
        },
      });
    } catch (error) {}
    setConfirmLoading(false);
  };

  const fetchTableData = async params => {
    const startDate = convertToStartOfDay(dateFilter.startDate);
    const endDate = convertToEndOfDay(dateFilter.endDate);
    try {
      const ESRes = await apiService.getElasticLogDateFilter(
        sentinel,
        jid,
        startDate,
        endDate,
        token,
        [],
        [],
        {
          ...params,
        }
      );

      dispatch({
        type: SET_ANSWER_EDITOR_TABLE_INSIGHTS,
        payload: {
          data: ESRes.data.report[0],
          startDate,
          endDate,
        },
      });
    } catch (error) {
      message.error(
        error.message !== DEFAULT_ERROR_MESSAGE ? error.message : GET_DATA_ERROR
      );
    }
  };

  const fetchAggregationsData = async (filterParams = {}) => {
    const params = {
      size: 1000,
      from: 0,
      page: 1,
      answerIds: [answerEditorAnswer.id],
      ...filterParams,
    };

    const startDate = convertToStartOfDay(dateFilter.startDate);
    const endDate = convertToEndOfDay(dateFilter.endDate);
    setShouldUseSkeletonComponent(true);

    try {
      const ESRes = await apiService.getElasticLogDateFilter(
        sentinel,
        jid,
        startDate,
        endDate,
        token,
        [],
        [],
        {
          ...params,
          size: '0',
          for_aggs: true,
        }
      );

      dispatch({
        type: SET_ANSWER_EDITOR_CARD_INSIGHTS,
        payload: {
          data: ESRes.data.report[0],
          startDate,
          endDate,
        },
      });
      setShouldUseSkeletonComponent(false);
      const isAggregationsEmpty = isElasticAggregationsEmpty(
        ESRes?.data?.report[0]
      );
      if (!isAggregationsEmpty) {
        fetchGraphData();
        fetchTableData(params);
      }
    } catch (error) {
      message.error(
        error.message !== DEFAULT_ERROR_MESSAGE ? error.message : GET_DATA_ERROR
      );
    }
  };

  const handleChangeTabView = async tabName => {
    switch (tabName) {
      case 'question': {
        setShouldUseSkeletonComponent(true);
        setTabView('question');
        if (!answerEditorQuestions?.length) {
          try {
            const res = await apiService.getHardLinkQuestionsFromAnswer(
              answerEditorAnswer.id,
              sentinel,
              token
            );
            if (res.data.report[0]) {
              dispatch({
                type: SET_ANSWER_EDITOR_LINKED_QUESTIONS,
                payload: res.data.report[0],
              });
            }
          } catch (error) {
            message.error(GET_DATA_ERROR);
          }
        }
        setShouldUseSkeletonComponent(false);
        break;
      }
      case 'answer': {
        setTabView('answer');
        break;
      }
      case 'insights': {
        setTabView('insights');
        await fetchAggregationsData();
        break;
      }
      default:
        break;
    }
  };

  return {
    answer,
    categoryColor,
    confirmLoading,
    editedHTML,
    editedRichTextAnswer,
    editedStringAnswer,
    editor,
    error,
    jid,
    quickReplies,
    requestAgent,
    selectedCategory,
    showAdvanceSettings,
    addRow,
    deleteRow,
    handleAddAnswerVariant,
    handleClose,
    handleDrag,
    handleDrop,
    isAddRowDisabled,
    isFullScreen,
    isRemoveRowDisabled,
    isTextCategory,
    handleOnUpdateAnswer,
    handleUpdateVariantAnswer,
    handleQuickReplyChange,
    handleSelectCategory,
    handleChangeSwitch,
    handleChangeRequestAgentCheckbox,
    setCategoryColor,
    setEditor,
    setFullScreen,
    setIsTextCategory,
    setQuickReplies,
    setRequestAgent,
    setShowAdvanceSettings,
    setShowColorPicker,
    setTextCategoryValue,
    setAnswerVariants,
    showColorPicker,
    updateEditedStringAnswer,
    updateFormattedAnswer,
    answerVariants,
    textCategoryValue,
    answerEditorAnswer,
    allCategories,
    allAnswers,
    getSimilarAnswers,
    tabView,
    handleOnAddAnswer,
    handleChangeTabView,
    handleChangeDateRange,
    handleTableChange,
    handleFetchInsightFromDateFilter,
    setSessionFilteredInfo,
    sessionFilteredInfo,
    successMessage,
    answerEditorQuestions,
    answerEditorInsights,
    dateFilter,
    isCreateMode,
    isOpen,
    similarAnswers,
    shouldUseSkeletonComponent,
    parsedAnswer,
  };
};

export default useAnswerEditor;
