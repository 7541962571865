export const DEFAULT_EDITOR = 'rte';
export const WEBSITE_SLUG = 'websites';
export const FILE_SLUG = 'files';
export const ANSWER_SLUG = 'answers';
export const EDITORS = {
  RICH_TEXT: 'rte',
  HTML: 'html',
};

export const EDITOR_OPTIONS = [
  { label: 'Rich Text Editor', value: 'rte' },
  { label: 'HTML Editor', value: 'html' },
];

export const ANSWER_TIP =
  'Response entered here will be used to calculate the response score.';
export const DISPLAY_ANSWER_TIP =
  'The response here will be displayed to the user. You can design your response here. Please note, ZSB will not calculate response score based on the response here.';

export const DEFAULT_ANSWER_VERSION = 'final';
export const DEFAULT_LANGUAGE = 'ENGLISH (EN)';
export const MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION = 0.95;

export const EMPTY_ANSWER_OBJECT = {
  jid: null,
  text: null,
  show_text: null,
  editor: DEFAULT_EDITOR,
  type: 'answer',
  score: '0.000',
  version: null,
  answerLength: 0,
};

export const CREATE_ANSWER_MANUAL_SOURCE = 'manual';
export const CREATE_ANSWER_MANUAL_SOURCE_TYPE = 'manual';
export const CATEGORY_FILTER_TOOLTIP = 'View questions asked in this category';

export const ZSB_CHAT_BREAKER_ENCONDING = '&lt;zsb-chat-breaker /&gt;';
export const ZSB_CHAT_BREAKER_ELEMENT = '<zsb-chat-breaker />';

export const SCRAPPER_DEFAULT_POST_SCRIPTS = [
  {
    method: 'evaluate',
    expression:
      'try { document.querySelector("textarea[id=APjFqb]").value = "speed test"; document.querySelector("form[action=\'/search\']:has(input[type=submit][value=\'Google Search\'])").submit(); } catch (err) { }',
  },
  {
    method: 'wait_for_selector',
    selector: '#result-stats',
    state: 'visible',
  },
];

export const SEARCH_KEY_INITIAL_VALUE = {
  answer: '',
  file: '',
  website: '',
};
