import React from 'react';
import PropTypes from 'prop-types';
import BotCustomizer from 'components/BotCustomizer';
import { Spin } from 'antd';

const Demo = ({ isPageReady }) => {
  return (
    <Spin spinning={!isPageReady} tip="Just a moment...">
      <div>
        <BotCustomizer />
      </div>
    </Spin>
  );
};

Demo.propTypes = {
  isPageReady: PropTypes.bool,
};

export default Demo;
