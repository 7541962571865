import styled from 'styled-components';

export const StyledAnswerEditor = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-top: 1%;

  & .ant-tabs-tab {
    color: #66696f;
    ${props =>
      !props.isModal
        ? `background: #ffff !important;
        margin-left: 0px !important; `
        : ``};

    & .ant-tabs-tab {
      color: #66696f;
      ${props =>
        !props.isModal
          ? `background: #ffff !important;
      margin-left: 0px !important;`
          : ``};

      &.ant-tabs-tab-active {
        ${props => (!props.isModal ? `box-shadow: unset !important;` : ``)};
      }
    }

    &.ant-tabs-tab-active {
      ${props => (!props.isModal ? `  box-shadow: unset !important;` : ``)};
    }
  }

  & .ant-tabs-tab.ant-tabs-tab-active {
    ${props => (!props.isModal ? `background: #fff !important;` : ``)};
  }

  & .ant-tabs-tab:hover {
    color: #26292d;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #26292d;
  }

  @media (max-width: 600px) {
    min-width: 100%;
  }
`;
