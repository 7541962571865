import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import Input from 'components/Input';
import Alert from 'components/Alert';
import { cssVariables } from 'styles/root';

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
`;

export const StyledAlertCard = styled(Alert)`
  margin: 0px;
`;

export const StyledCategoryModalContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledCategoryModalSidebar = styled.div`
  display: block;
  width: 150px;
  margin-right: 24px;
  margin-top: 15px;

  & .ant-menu {
    border: none;
  }

  & .ant-menu-item {
    margin: 0 !important;
  }

  & .ant-menu-item-selected {
    background-color: #f9f9f9 !important;
  }
`;

export const StyledCategoryModalSection = styled.div`
  width: 446px;
  margin-bottom: 20px;
  display: block;

  & .ant-select .ant-select-selector {
    border-radius: 5px;
    height: 40px;
    align-items: center;
  }
`;

export const StyledCategoryModalSectionTitle = styled.h4`
  font-weight: ${cssVariables.font.bold};
  font-size: 15px;
  color: #2d3539;
  margin: 10px 0 0 0;
`;

export const StyledCategoryNameInput = styled(Input)`
  font-family: 'Helvetica';
  font-size: 16px;
  color: #000;
  height: 40px;
  margin-bottom: 10px;
`;

export const StyledCategoryColorPicker = styled.div`
  display: flex;
  margin-top: 5px;

  & .twitter-picker {
    width: 90%;
    box-shadow: none !important;
  }

  & .twitter-picker > div {
    padding: 0 !important;
  }
`;

export const StyledPickedCategoryColor = styled.div`
  width: 150px;
  border-radius: 5px;
  border: 1px solid #eee;
  text-align: center;
  color: #fff;
  padding: 5px;
  word-break: break-word;
  background: ${props => props.color};
`;

export const StyledSearchTemplateCategory = styled(Input)`
  & .ant-input-affix-wrapper {
    background-color: #f9f9f9;
    border: none;
  }

  & .ant-input {
    background-color: #f9f9f9;
  }
`;

export const StyledSearchOutlined = styled(SearchOutlined)`
  float: right;
  color: #b3b4b7;
`;
