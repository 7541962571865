import React from 'react';
import PropTypes from 'prop-types';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

const StyledWrapper = styled.div`
  background-color: ${props => props.color || cssVariables.gray2};
  padding: 5px 12px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;

  .anticon {
    margin-left: 5px;
  }

  :hover {
    cursor: pointer;
    background-color: ${cssVariables.primaryBlueHover};
    color: ${props => props.color || cssVariables.primaryBlue};
  }
`;

const ToggleFullScreenButton = ({
  isFullScreen,
  setFullScreen,
  color,
  ...rest
}) => {
  return (
    <>
      {isFullScreen ? (
        <StyledWrapper
          onClick={() => setFullScreen(false)}
          color={color}
          {...rest}
        >
          <span>{'Exit Fullscreen'}</span>
          <FullscreenExitOutlined />
        </StyledWrapper>
      ) : (
        <StyledWrapper
          onClick={() => setFullScreen(true)}
          color={color}
          {...rest}
        >
          <span>{'Enter Fullscreen'}</span>
          <FullscreenOutlined />
        </StyledWrapper>
      )}
    </>
  );
};

ToggleFullScreenButton.propTypes = {
  color: PropTypes.string,
  isFullScreen: PropTypes.bool,
  setFullScreen: PropTypes.func.isRequired,
};

export default ToggleFullScreenButton;
