import styled from 'styled-components';
import { Alert as AntdAlert } from 'antd';

export const StyledAlert = styled(AntdAlert)`
  font-size: 14px;
  width: 100%;
  & .ant-alert-content {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: ${props => (props.noPadding ? '0px' : `24px auto`)};
  display: flex;
  align-items: center;
  & .ant-alert {
    margin: 0 !important;
  }
`;
