import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledQuestionModalTitle = styled.h4`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.normal};
  font-size: 18px;
  color: #000;
  margin-top: 8px;
`;

export const StyledLinkedQuestionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${cssVariables.gray4};
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;

  &:hover {
    background-color: ${cssVariables.blue1};
  }
`;

export const StyledLinkedQuestion = styled.div`
  font-family: 'Helvetica';
  font-size: 15px;
  color: ${cssVariables.primaryGray};
  width: 80%;
`;

export const StyledBtnUnlinkQuestion = styled.a`
  font-family: 'Helvetica';
  font-size: 15px;
  color: ${cssVariables.primaryGray};
  width: 20%;
`;
