import Users from './Users';
import Dashboard from './Dashboard';
import ROUTES from 'constants/routes';
import GlobalVars from './GlobalVars';
import ActivityLogs from 'components/ActivityLogs';
import Versions from './Versions';

export const adminSidebarRoutes = [
  {
    name: 'Main Page',
    path: ROUTES.ADMIN_PAGE,
    component: Dashboard,
    key: 'admin',
  },
  {
    name: 'Users List',
    path: ROUTES.ADMIN_USERS,
    component: Users,
    key: 'admin_users',
  },
  {
    name: 'Global Vars',
    path: ROUTES.ADMIN_GLOBAL_VARS,
    component: GlobalVars,
    key: 'global_vars',
  },

  {
    name: 'All Users Activity Logs',
    path: ROUTES.ADMIN_ACTIVITY_LOGS,
    component: ActivityLogs,
    key: 'activity_logs',
  },
  {
    name: 'Version Management',
    path: ROUTES.ADMIN_VERSIONS,
    component: Versions,
    key: 'versions',
  },
];
