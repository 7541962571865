import useFile from './hooks';

import AnswerTabs from '../AnswerTabs';

const File = props => {
  const { allFiles } = useFile();
  return (
    <>
      {/* {props.limitReachedContent()} */}
      <AnswerTabs {...props} allRecords={allFiles} type="files" />
    </>
  );
};

export default File;
