import styled from 'styled-components';
import QuickReplyFormRow from 'components/QuickReplyFormRow';

export const StyledInputGroup = styled(QuickReplyFormRow)`
  align-items: center;
  transition: transform 0.2s ease-in-out;
  width: 100%;
  padding: 5px 0;

  & .unlinkedIcon {
    width: 15px;
    height: 17px;

    margin-right: 5px;
  }
  & .linkedIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  & .ant-input {
    padding: 5px;
    height: 32px;
  }

  & input-with-label {
    width: 100%;
  }
`;

export const StyledInputGroupLabel = styled.div`
  display: flex;
  & span {
    width: 100%;
    margin-left: 19px;
  }

  & span:first-child {
    width: 60%;
  }
`;
