import { DEFAULT_BOT_MODE } from 'constants/bot';
import { botStatistics } from 'utils/bot';
import { isAnObject } from 'utils/dataTypes';

export const extractBotData = botData => {
  if (!isAnObject(botData) || !isAnObject(botData.context)) {
    return {};
  }
  const { context, jid, statistics } = botData;
  return {
    jid: jid,
    name: typeof context.name === 'string' ? context.name : '',
    description: context.description,
    metadata: context.metadata || {},
    avatar:
      typeof context.name === 'string' ? context.name.substring(0, 2) : null,
    answerCount: context.answer_count || 0,
    fileCount: context.file_count || 0,
    websiteCount: context.website_count || 0,
    createdAt: context.created_time,
    lastUpdatedAt: context.last_updated_time,
    mailConfig: context?.mail_config || {},
    statistics: statistics || {},
    isWeak: botStatistics(botData).isWeakBot,
    showWeakStat: botStatistics(botData).isWeakBot,
    mode: context?.mode || DEFAULT_BOT_MODE,
    persona: context?.persona,
    retrievalCount: context?.max_candidate,
    useOpenAI: context?.use_openai,
    autoTranslateResponse: context?.auto_translate_response,
    syncedDate: context?.synced_date,
    syncedPoint: context?.synced_point,
    syncedIndex: context?.synced_index,
    language: context?.language,
    synced: context?.synced,
    shouldSendCallbackLog: Boolean(context?.mail_config?.enabled),
  };
};
