import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  StyledQuestionDetailsModalTitle,
  StyledQuestionDetailsModalLabel,
  StyledQuestionActionAndInfo,
  StyledEditQuestion,
  StyledDotSeparator,
  StyledQuestionTestInfo,
  StyledTestInfoTextSpan,
  StyledTestInfoTextDiv,
  StyledTestStatusInfoContainer,
  StyledQuestionContent,
} from './TestCaseModal.styles';
import Modal from 'components/Modals/GenericModal';
import { default as ToolTip } from 'components/ToolTips/BaseToolTip';
import { getTimeDifference } from 'utils/dates';
import { cssVariables } from 'styles/root';
import { message, Tag } from 'antd';
import { TEST_STATUS } from 'constants/testSuite';
import { AnswerRenderer } from 'components/AnswerRenderer';

const TestCaseModal = props => {
  const {
    actualAnswer,
    botName,
    handleEditTestCase,
    modalLoading,
    onOkModal,
    selectedAnswer,
    selectedTestCase,
    visible,
    ...rest
  } = props;
  const { question, lastTestTime, lastUpdated, testResult } = selectedTestCase;
  const isTestPassed = testResult === 'Passed';
  const isSelectedDefaultAnswer = selectedAnswer?.type.includes('default');
  const isActualDefaultAnswer = actualAnswer?.type.includes('default');
  const isResultOutDated = new Date(lastUpdated) > new Date(lastTestTime);

  const getTestStatusColor = result => {
    const { PASSED, FAILED, EDITED, UNTESTED } = TEST_STATUS;
    switch (result) {
      case PASSED:
        return cssVariables.success;
      case FAILED:
        return cssVariables.red10;
      case EDITED:
        return cssVariables.warning;
      case UNTESTED:
        return cssVariables.gray0;
      default:
        break;
    }
  };

  if (!selectedAnswer) {
    return message.error(
      'No selected answer found. Please edit/choose an answer first'
    );
  }

  return (
    <Modal
      visible={visible}
      title={
        <React.Fragment>
          <div>
            <StyledQuestionDetailsModalTitle>
              {question}
            </StyledQuestionDetailsModalTitle>
            <StyledQuestionActionAndInfo>
              <StyledEditQuestion
                onClick={() => handleEditTestCase(selectedTestCase)}
              >
                Edit Test
              </StyledEditQuestion>
              <StyledDotSeparator />
              <StyledTestInfoTextSpan>Test Result</StyledTestInfoTextSpan>

              <StyledQuestionTestInfo>
                <StyledTestStatusInfoContainer>
                  <ToolTip
                    title={
                      isResultOutDated
                        ? 'Test result outdated, please re-test the suite'
                        : ''
                    }
                  >
                    <Tag color={getTestStatusColor(testResult)}>
                      {testResult}
                    </Tag>
                  </ToolTip>
                </StyledTestStatusInfoContainer>
                <StyledTestInfoTextDiv>
                  {`Last Test Run: ${
                    lastTestTime ? getTimeDifference(lastTestTime) : 'N/A'
                  }`}
                </StyledTestInfoTextDiv>
              </StyledQuestionTestInfo>
            </StyledQuestionActionAndInfo>
          </div>
        </React.Fragment>
      }
      onOk={onOkModal}
      onCancel={onOkModal}
      okText="Done"
      spinning={modalLoading}
      confirmLoading={modalLoading}
      isFormModal
    >
      {isTestPassed ? (
        <StyledQuestionContent
          className={classNames({
            defaultAnswer: isSelectedDefaultAnswer,
          })}
        >
          <StyledQuestionDetailsModalLabel>
            {`Display Answer`} &nbsp;
            <ToolTip
              title={
                isSelectedDefaultAnswer ? 'Default Answer' : selectedAnswer.text
              }
              placement="right"
            >
              <InfoCircleTwoTone
                twoToneColor={
                  isSelectedDefaultAnswer
                    ? cssVariables.red10
                    : cssVariables.primaryBlue
                }
              />
            </ToolTip>
          </StyledQuestionDetailsModalLabel>
          <AnswerRenderer
            showDisplayAnswer={!isSelectedDefaultAnswer}
            answer={selectedAnswer}
          />
        </StyledQuestionContent>
      ) : (
        <>
          <StyledQuestionContent
            className={classNames({
              defaultAnswer: selectedAnswer.type.includes('default'),
            })}
          >
            <StyledQuestionDetailsModalLabel>
              Expected Display Answer &nbsp;
              <ToolTip
                title={
                  selectedAnswer.type.includes('default')
                    ? 'Default Answer'
                    : selectedAnswer.text
                }
                placement="right"
              >
                <InfoCircleTwoTone
                  twoToneColor={
                    selectedAnswer.type.includes('default')
                      ? cssVariables.red10
                      : cssVariables.primaryBlue
                  }
                />
              </ToolTip>
            </StyledQuestionDetailsModalLabel>
            <AnswerRenderer
              showDisplayAnswer={!isSelectedDefaultAnswer}
              answer={selectedAnswer}
            />
          </StyledQuestionContent>
          {!!actualAnswer ? (
            <StyledQuestionContent
              className={classNames({
                defaultAnswer: isActualDefaultAnswer,
              })}
            >
              <StyledQuestionDetailsModalLabel>
                {botName}'s Answer &nbsp;
                <ToolTip
                  title={
                    isActualDefaultAnswer ? 'Default Answer' : actualAnswer.text
                  }
                  placement="right"
                >
                  <InfoCircleTwoTone
                    twoToneColor={
                      isActualDefaultAnswer
                        ? cssVariables.red10
                        : cssVariables.primaryBlue
                    }
                  />
                </ToolTip>
              </StyledQuestionDetailsModalLabel>
              <AnswerRenderer
                showDisplayAnswer={
                  isActualDefaultAnswer && !!actualAnswer.show_text
                }
                answer={actualAnswer}
              />
            </StyledQuestionContent>
          ) : null}
        </>
      )}
    </Modal>
  );
};

TestCaseModal.propTypes = {
  actualAnswer: PropTypes.object,
  botName: PropTypes.string,
  handleEditTestCase: PropTypes.func,
  modalLoading: PropTypes.bool,
  onOkModal: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.object,
  selectedTestCase: PropTypes.object,
  visible: PropTypes.bool.isRequired,
};

export default TestCaseModal;
