import React, { useContext, useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { GET_ALL_GLOBAL_VARS } from 'store/action';
import AdminSidebar from '../AdminSidebar';
import GlobalVarsTable from './GlobalVarsTable/GlobalVarsTable';
import GlobalVarsSearch from './GlobalVarsSearch/GlobalVarsSearch';
import GlobalVarsAddModal from './GlobalVarsAddModal/GlobalVarsAddModal';
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitleWrapper,
  StyledTitle,
  StyledSortAddWrapper,
  StyledSortingWrapper,
  StyledSearchWrapper,
  StyledAddButtonWrapper,
  StyledAddButton,
} from './GlobalVars.style';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { getTokenSelector } from 'selectors/user';
import useSelector from 'store/useSelector';
import { SidebarRoutes } from '../SidebarRoutes';
import { Col, Row } from 'antd';

const GlobalVars = () => {
  const [state, dispatch] = useContext(Context);
  const token = useSelector(getTokenSelector);
  const {
    admin: { all_global_vars },
  } = state;

  const [initLoading, setInitLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    const getGlobalVars = async () => {
      setInitLoading(true);
      try {
        const allGlobalVars = await apiService.getGlobalVars({}, token);
        if (!allGlobalVars || !allGlobalVars.data) {
          throw new Error(DEFAULT_ERROR_MESSAGE);
        }
        const allGlobalVarsData = allGlobalVars.data.results;

        await dispatch({
          type: GET_ALL_GLOBAL_VARS,
          payload: allGlobalVarsData,
        });
      } catch (error) {
        throw new Error(error.message || DEFAULT_ERROR_MESSAGE);
      }
    };

    getGlobalVars();
    setInitLoading(false);
  }, [dispatch, token]);

  useEffect(() => {
    if (all_global_vars.length > 0) {
      setCurrentData(all_global_vars);
    }
  }, [all_global_vars]);

  const addGlobalVars = async (name, value, token) => {
    try {
      const res = await apiService.addGlobalVars(name, value, token);
      if (res.data.success) {
        setCurrentData([]);
        const refetchGlobalVars = async () => {
          setInitLoading(true);
          const allGlobalVars = await apiService.getGlobalVars({}, token);
          const allGlobalVarsData = allGlobalVars.data.results;

          await dispatch({
            type: GET_ALL_GLOBAL_VARS,
            payload: allGlobalVarsData,
          });
          setCurrentData(allGlobalVarsData);
        };

        refetchGlobalVars();
        setInitLoading(false);
      }
    } catch (err) {
      throw err;
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSearch = async event => {
    const searchedValue = all_global_vars.filter(all_global_var =>
      all_global_var.name
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );

    setCurrentData(searchedValue);
  };

  return (
    <>
      <StyledWrapper>
        <AdminSidebar title="Admin" route={SidebarRoutes} />
        <StyledContentWrapper>
          <StyledTitleWrapper>
            <StyledTitle>Global Vars</StyledTitle>
          </StyledTitleWrapper>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12} md={12}>
              <StyledSearchWrapper>
                <GlobalVarsSearch onChange={handleSearch} />
              </StyledSearchWrapper>
              {/* COMMENT DO NOT HAVE FUNCTION YET */}
              {/* <StyledDropdownWrapper>
                <GlobalVarsDropdown />
                <StyledCountWrapper>
                  <div>0 of {totalDatas} selected.</div>
                </StyledCountWrapper>
              </StyledDropdownWrapper> */}
            </Col>
            <Col
              xs={{ span: 24, push: 0 }}
              sm={{ span: 12, push: 0 }}
              md={{ span: 6, push: 6 }}
              lg={{ span: 5, push: 7 }}
              xxl={{ span: 3, push: 9 }}
            >
              <StyledAddButton
                onClick={handleOpenModal}
                style={{ width: '100%' }}
              >
                Add Global Vars <PlusCircleOutlined />
              </StyledAddButton>
            </Col>
          </Row>
          <GlobalVarsTable
            totalDatas={currentData.length}
            loading={initLoading}
            pageSize={10}
            setInitLoading={setInitLoading}
            datatable={currentData}
            setCurrentData={setCurrentData}
          />
        </StyledContentWrapper>
      </StyledWrapper>
      <GlobalVarsAddModal
        visible={openModal}
        onCloseModal={handleCloseModal}
        addGlobalVars={addGlobalVars}
      />
    </>
  );
};

export default GlobalVars;
