import { snakeCase, isEmpty } from 'lodash';
import { stripUUID } from 'utils';
import { getFileExtension, isImageOrDocFileType } from 'utils/dataTypes';

const AZURE_STORAGE_URI = process.env.REACT_APP_AZURE_STORAGE_URI;
const CUSTOM_AVATAR_CONTAINER_NAME = '$web';

/**
 *
 * @param {object|string} image the image from file or cloud path
 * @param {string} botId current bot id
 * @param {string} imageLocation options ['background', 'launcherAvatar, 'headerAvatar', 'responseAvatar']
 * @returns folderPath in cloud (e.g. `{folder-path}/user_bg.png`)
 *
 * or returns the back `image` if image is detected to be a path
 */
export const getImageCloudPath = (image, botId, imageLocation) => {
  const azurefolderPath = stripUUID(botId);

  if (
    typeof image === 'string' &&
    isImageOrDocFileType(image) === 'image' &&
    image.includes(azurefolderPath)
  ) {
    return image;
  }

  // starting here image should be object type
  // or it will return `null`
  const extractedImageName =
    !isEmpty(image) &&
    typeof image?.name === 'string' &&
    isImageOrDocFileType(image.name) === 'image'
      ? image?.name
      : '';

  if (imageLocation?.includes('background')) {
    return `${azurefolderPath}/user_bg.${getFileExtension(extractedImageName)}`;
  }
  // util to get image cloud path during upload to azure
  // azure constainer already have the azure path
  // therefore azure storage uri is not included
  else if (
    imageLocation?.toLowerCase()?.includes('azure') &&
    imageLocation?.toLowerCase()?.includes('avatar')
  ) {
    return `${azurefolderPath}/${snakeCase(imageLocation)}.${getFileExtension(
      extractedImageName
    )}`;
  }
  // custom avatars will have full path
  // since demo background url will inherit the demo html base url
  else if (imageLocation?.toLowerCase().includes('avatar')) {
    return `${AZURE_STORAGE_URI}${CUSTOM_AVATAR_CONTAINER_NAME}/${azurefolderPath}/${snakeCase(
      imageLocation
    )}.${getFileExtension(extractedImageName)}`;
  }
  return null;
};
