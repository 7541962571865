import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { Context } from 'store/store';

const AdminRoute = props => {
  const [state] = useContext(Context);
  const { isAuthenticated } = state;
  const { component: Component, location, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

AdminRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
};

export default AdminRoute;
