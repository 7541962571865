import { createSelector } from 'reselect';

const userSelector = state => state.user;
const adminSelector = state => state.admin;

export const isAdminSelector = createSelector(
  userSelector,
  user => user?.is_superuser || false
);

export const isImpersonatingSelector = createSelector(
  adminSelector,
  admin => admin?.is_impersonating || false
);

export const impersonatedUserSelector = createSelector(
  adminSelector,
  admin => admin?.impersonated_user || {}
);
