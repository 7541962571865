import { useState, useEffect, useContext } from 'react';
import { message } from 'antd';

import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import {
  CLOSE_FILE_EDITOR,
  UPDATE_FILE,
  UPDATE_FILE_EDITOR_FILE,
} from 'store/action';
import { GET_DATA_ERROR } from 'constants/error';
import {
  isFileEditorOpenSelector,
  fileEditorAnswerSelector,
} from 'selectors/bot/answers';
import useSelector from 'store/useSelector';

const useFileEditor = () => {
  const [state, dispatch] = useContext(Context);
  const fileEditorAnswer = useSelector(fileEditorAnswerSelector);
  const isOpen = useSelector(isFileEditorOpenSelector);
  const { sentinel, token } = state;

  const [previewDocs, setPreviewDocs] = useState([{ uri: null }]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFullScreen, setFullScreen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (showPreview && !previewDocs[0].uri) {
      generateFileUrl();
    }
  }, [previewDocs, showPreview]);

  const scrollIntoWarningView = () => {
    setTimeout(() => {
      const target = document.getElementsByClassName('ant-alert');
      if (target && target[0]) {
        target[0].scrollIntoViewIfNeeded();
      }
    }, 500);
  };

  const handleOnUpdateAnswer = async () => {
    setConfirmLoading(true);
    try {
      const res = await apiService.changeFile(
        sentinel,
        fileEditorAnswer?.id,
        token,
        fileEditorAnswer
      );

      if (res.data.success) {
        dispatch({
          type: UPDATE_FILE,
          payload: res.data.report[0],
        });
        setConfirmLoading(false);
        handleClose();
      } else {
        setError({ detail: error?.message || error });
        scrollIntoWarningView();
      }
    } catch (error) {
      setError({ detail: error?.message || error });
      scrollIntoWarningView();
    }
    setConfirmLoading(false);
  };

  const handleClearFileEditorLocalState = () => {
    setFullScreen(false);
    setError(null);
    setConfirmLoading(false);
  };

  const handleClose = () => {
    if (isFullScreen) {
      return setFullScreen(false);
    }
    handleClearFileEditorLocalState();
    dispatch({
      type: CLOSE_FILE_EDITOR,
    });
  };

  const handleChangeSwitch = async e => {
    try {
      dispatch({
        type: UPDATE_FILE_EDITOR_FILE,
        payload: {
          version: e ? 'final' : 'draft',
        },
      });
    } catch (error) {
      message.error(error.message || GET_DATA_ERROR);
    }
  };

  const generateFileUrl = async () => {
    try {
      const res = await apiService.generateFileCdnUrl(
        sentinel,
        fileEditorAnswer.id,
        token
      );
      const cdnUrl = res?.data?.report[0];
      setPreviewDocs([
        {
          uri: cdnUrl,
        },
      ]);
    } catch (error) {
      message.error('Error fetching file preview');
    }
  };

  const handleDownloadFile = async () => {
    setDownloadLoading(true);
    try {
      const res = await apiService.generateFileCdnUrl(
        sentinel,
        fileEditorAnswer.id,
        token
      );
      const cdnUrl = res.data.report[0];
      fetch(cdnUrl)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = fileEditorAnswer.file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          setDownloadLoading(false);
        })
        .catch(error => console.error('Error fetching file:', error));
    } catch (err) {
      setDownloadLoading(false);
      throw typeof err === 'string' ? err : err.message;
    }
  };

  return {
    confirmLoading,
    handleClose,
    isFullScreen,
    handleOnUpdateAnswer,
    setFullScreen,
    handleChangeSwitch,
    isOpen,
    fileEditorAnswer,
    handleDownloadFile,
    downloadLoading,
    previewDocs,
    showPreview,
    setShowPreview,
  };
};

export default useFileEditor;
