import { useContext, useMemo } from 'react';

import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { DELETE_ONBOARDING_FLAG, RESET_ONBOARDING_FLAG } from 'store/action';
import { getTokenSelector } from 'selectors/user';
import useSelector from 'store/useSelector';

const useOnboardingFlags = () => {
  const [state, dispatch] = useContext(Context);
  const token = useSelector(getTokenSelector);
  const {
    sentinel,
    graph,
    plan: { plan_type, onboarding_flag },
  } = state;

  const resetFlag = async flag => {
    const result = await apiService.resetOnboardingFlag(
      sentinel,
      token,
      graph,
      flag
    );

    if (result) {
      dispatch({ type: DELETE_ONBOARDING_FLAG, payload: flag });
    }
  };

  const resetAllFlags = async () => {
    const result = await apiService.resetAllOnboardingFlag(
      sentinel,
      token,
      graph
    );

    if (result) {
      dispatch({ type: RESET_ONBOARDING_FLAG });
    }
  };

  const isFreeUser = useMemo(() => plan_type === 'free', [plan_type]);

  return {
    onboarding_flag,
    resetFlag,
    resetAllFlags,
    isFreeUser,
    dispatch,
  };
};

export default useOnboardingFlags;
