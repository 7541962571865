import { useEffect, useState, useContext } from 'react';
import { apiService } from 'services/api.service';

import { AES } from 'crypto-js';

import { Context } from 'store/store';
import { useLocation } from 'react-router-dom';
import { withPrefixUUID } from 'utils';

const WEBHOOKURL = process.env.REACT_APP_WEBHOOK_URL;
const useOthers = () => {
  const [state, dispatch] = useContext(Context);
  const { state: locationState, pathname } = useLocation();
  const {
    sentinel,
    pubAskedQuestion,
    token,
    bot: { jid },
  } = state;
  const [botComponent, setBotComponent] = useState('');
  const [webHookURL, setWebHookURL] = useState(null);
  const [loading, setLoading] = useState(false);

  const WITH_PREFIX_BOT_JID = withPrefixUUID(jid || pathname);

  const generateCode = async () => {
    const pkRes = await apiService.getPublicKey(token);
    const botData = {
      pubAskedQuestion: {
        key: Object.keys(pkRes.data)[0],
        nd: WITH_PREFIX_BOT_JID,
        wlk: pubAskedQuestion,
        snt: sentinel,
      },
    };
    setBotComponent(AES.encrypt(JSON.stringify(botData), '').toString());
  };

  const handleSetWebHook = () => {
    setWebHookURL(`${WEBHOOKURL}`);
  };

  const generateUUID = async () => {
    setLoading(true);
    const pkRes = await apiService.getPublicKey(token);
    const generateUuidResponse = await apiService.getUUID({
      key: Object.keys(pkRes.data)[0],
      nd: WITH_PREFIX_BOT_JID,
      wlk: pubAskedQuestion,
      snt: sentinel,
    });
    setWebHookURL(
      `${WEBHOOKURL}webhook/messenger/${generateUuidResponse.data.uuid}`
    );
    setTimeout(() => setLoading(false), 200);
  };

  useEffect(() => {
    generateCode();
  }, [webHookURL]);

  return {
    botComponent,
    webHookURL,
    setWebHookURL,
    handleSetWebHook,
    generateUUID,
    loading,
  };
};

export default useOthers;
