import React from 'react';
import { Typography, Spin, Empty } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useApiKey from './hooks';
import {
  StyledFlexColumn,
  StyledFlexRowLeft,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';
import {
  StyledContent,
  StyledPageTitle,
  StyledCodeButton,
  StyledSnippets,
  StyledApiKeyContainer,
  StyledApiKeySubContainer,
  StyledPanelSubHeader,
  StyledCodeBlock,
} from './ApiKey.styles';
import Button from 'components/Button';
import ApiKeyModal from 'components/Modals/ApiKeyModal';

const { Paragraph } = Typography;
const ApiKey = () => {
  const {
    apiGatewayConfig,
    apiKeyloading,
    handleDisableApiKey,
    handleEnableApiKey,
    message,
  } = useApiKey();

  return (
    <Spin spinning={apiKeyloading} tip="Just a moment...">
      <StyledFlexColumn>
        <StyledPageTitle style={{ marginBottom: 10 }}>
          <StyledSpaceBetweenFlexCenter>
            <StyledFlexRowLeft>
              <h3 style={{ width: 'auto' }}>API Key</h3>
            </StyledFlexRowLeft>
            {(apiGatewayConfig?.auth || apiGatewayConfig?.auth) && (
              <Button
                value="Regenerate"
                onClick={handleEnableApiKey}
                variant="primary"
                style={{ marginRight: '5px' }}
              />
            )}
            {apiGatewayConfig?.auth && (
              <Button
                value="Disable"
                onClick={handleDisableApiKey}
                variant="red"
              />
            )}
          </StyledSpaceBetweenFlexCenter>
        </StyledPageTitle>
        <StyledContent>
          {apiGatewayConfig && (
            <StyledApiKeyContainer>
              <StyledPanelSubHeader>
                <span>{`Configuration`}</span>
              </StyledPanelSubHeader>
              <StyledSnippets>
                <StyledApiKeySubContainer>
                  {'Auth:'}
                  {!apiGatewayConfig?.auth.includes('****') && (
                    <CopyToClipboard text={apiGatewayConfig?.auth}>
                      <StyledCodeButton
                        className="secondary"
                        value="Copy code"
                        onClick={() => message.info('Copied to clipboard.')}
                      />
                    </CopyToClipboard>
                  )}
                </StyledApiKeySubContainer>
                <StyledCodeBlock>
                  <Paragraph>
                    <pre>
                      <code>
                        {apiGatewayConfig?.auth}{' '}
                        {apiGatewayConfig?.auth.includes('****') &&
                          `<Click button above to generate again>`}
                      </code>
                    </pre>
                  </Paragraph>
                </StyledCodeBlock>

                <StyledApiKeySubContainer>
                  {'Endpoint:'}
                  <CopyToClipboard text={apiGatewayConfig?.url}>
                    <StyledCodeButton
                      className="secondary"
                      value="Copy code"
                      onClick={() => message.info('Copied to clipboard.')}
                    />
                  </CopyToClipboard>
                </StyledApiKeySubContainer>
                <StyledCodeBlock>
                  <Paragraph>
                    <pre>
                      <code>{apiGatewayConfig?.url}</code>
                    </pre>
                  </Paragraph>
                </StyledCodeBlock>

                <StyledApiKeySubContainer>
                  {'Key'}
                  <CopyToClipboard text={apiGatewayConfig?.key}>
                    <StyledCodeButton
                      className="secondary"
                      value="Copy code"
                      onClick={() => message.info('Copied to clipboard.')}
                    />
                  </CopyToClipboard>
                </StyledApiKeySubContainer>
                <StyledCodeBlock>
                  <Paragraph>
                    <pre>
                      <code>{apiGatewayConfig?.key}</code>
                    </pre>
                  </Paragraph>
                </StyledCodeBlock>
              </StyledSnippets>
            </StyledApiKeyContainer>
          )}
          {!apiGatewayConfig && (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 150 }}
              description={<span>No configuration found.</span>}
            >
              <Button
                value="Generate API Key"
                onClick={handleEnableApiKey}
                variant="link"
              />
            </Empty>
          )}
        </StyledContent>
      </StyledFlexColumn>
      <ApiKeyModal />
    </Spin>
  );
};
export default ApiKey;
