/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import React from 'react';

import useSelector from 'store/useSelector';
import { botModeSelector } from 'selectors/bot';
import ToolTip from 'components/ToolTips/BaseToolTip';

export const withOpenAI = Component => {
  return props => {
    const botMode = useSelector(botModeSelector);
    if (botMode === 'openai') {
      return React.cloneElement(Component, { ...props });
    }
    return (
      <ToolTip title={'Only available to OpenAI bots'}>
        {React.cloneElement(Component, { ...props, disabled: true })}
      </ToolTip>
    );
  };
};

withOpenAI.propTypes = {
  Component: PropTypes.any.isRequired,
};
