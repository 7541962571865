import Dragger from 'antd/lib/upload/Dragger';
import Button from 'components/Button';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledFooterSubmit = styled(Button)`
  background-color: ${cssVariables.primaryBlue} !important;
  color: #fff !important;

  :hover {
    background-color: ${cssVariables.primaryBlueHover} !important;
    color: ${cssVariables.primaryBlue} !important;
  }
`;

export const StyledAntUploadContent = styled.p`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  flex-direction: row;
  align-items: center;
`;

export const StyledBotModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.showImport {
    justify-content: space-between !important;
  }
`;

export const StyledActionButtons = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: flex-end;
`;

export const StyledImportLabel = styled.span`
  color: ${cssVariables.green0};
`;

export const StyledImportLabelError = styled.span`
  color: ${cssVariables.red10};
`;

export const StyledExportLabel = styled.div`
  font-size: 16px;
  color: #33363c;
  margin-right: 8px;
`;

export const StyledExportContainer = styled.div`
  font-size: 15px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  font-weight: ${cssVariables.font.bold};
  padding: 15px 0;

  > span:first-child {
    margin-top: 10px;
  }

  > input[type='checkbox'] {
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }

  & .ant-checkbox-wrapper,
  .ant-checkbox-wrapper {
    margin: 10px 0 0 0;
    font-weight: ${cssVariables.font.normal} !important;
  }
`;
