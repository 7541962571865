import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const DisabledBtn = styled.div`
  border: ${props =>
    props.isBordered ? `1px solid ${cssVariables.gray1}` : 'none'};
  padding: 8px;
  border-radius: 5px;
  color: ${cssVariables.gray1};
  align-items: center;
  justify-content: center;
  display: flex;
  column-gap: 5px;
`;
