import { GET_DATA_ERROR } from 'constants/error';
import { apiService } from './api.service';
import { reverse } from 'lodash';

export const createBot = async (sentinel, graph, newBot, token) => {
  try {
    const res = await apiService.addBot(sentinel, graph, newBot, token);
    return res.data.report[0];
  } catch (err) {
    throw err;
  }
};

export const deleteBot = async (sentinel, botId, token) => {
  try {
    return await apiService.deleteBot(sentinel, botId, token);
  } catch (error) {
    throw new Error();
  }
};

export const editBot = async (
  sentinel,
  botId,
  name,
  mode,
  desc = '',
  token
) => {
  try {
    return await apiService.editBot(sentinel, botId, name, mode, desc, token);
  } catch (error) {
    throw error;
  }
};

export const setBotSession = bot => {
  localStorage.setItem('bot', JSON.stringify(bot));
};

export const getBotSession = () => JSON.parse(localStorage.getItem('bot'));

export const getViewType = () => localStorage.getItem('viewType');

export const getPlatform = () => localStorage.getItem('platform');

export const setPlatForm = platform =>
  localStorage.setItem('platform', platform);

export const setViewType = viewType => {
  localStorage.setItem('viewType', viewType);
};

export const fetchBotDetails = async (sentinel, jid, token) => {
  try {
    const res = await apiService.getBotDetails(sentinel, jid, token);
    if (
      !res.data.success ||
      !res.data.report[0] ||
      !res.data.report[0].context
    ) {
      throw new Error(GET_DATA_ERROR);
    }
    return res.data.report;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getBotIntegrations = async (sentinel, jid, token) => {
  try {
    const res = await apiService.getIntegration(sentinel, jid, token);
    const integrationData = res.data.report;
    const dataArr = integrationData.flatMap(item => {
      return item
        .flatMap(i => {
          if (i.context.identifier && i.context.settingsobj) {
            return {
              identifier: i.context.identifier,
              type: i.context.int_type || i.context.type,
              id: i.jid,
              path: i.context.path,
              settings: i.context.settingsobj,
              statistics: i.statistics,
              status: i.context.status,
            };
          }
          return null;
        })
        .filter(i => i);
    });
    return reverse(dataArr);
  } catch (error) {
    throw new Error(error);
  }
};
