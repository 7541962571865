import styled, { css } from 'styled-components';
import { cssVariables } from 'styles/root';
import { device } from 'constants/screens';
import { Menu, Row } from 'antd';

export const StyledWrapper = styled.div`
  ${props =>
    props.displayAdminSideBar
      ? css`
          display: flex;
        `
      : css`
          margin: 1% 2%;
        `};
  text-align: left;
  width: 95%;
`;

export const StyledTitleWrapper = styled(Row)`
  width: 100%;

  @media all and (${device.laptopL}) {
    font-size: 14px;
  }
`;

export const StyledTitle = styled.h2`
  font-weight: 600;
  margin: auto;
`;

export const StyledPageTitle = styled.div`
  & h3 {
    display: flex;
    width: 100%;
    font-size: 24px;
    font-weight: ${cssVariables.font.normal};
  }
`;

export const StyledContentWrapper = styled.div`
  ${props =>
    props.displayAdminSideBar
      ? css`
          & .ant-timeline-item-tail {
            border-left: 2px solid #ffff !important;
          }
        `
      : css`
          background: none;
        `}
  display: flex;
  width: 100%;
  flex-direction: column;

  ${props =>
    props.isAdmin
      ? css`
          padding: 50px;
          height: 100vh;
          overflow-x: hidden;
        `
      : css`
          height: 80vh;
        `}
  & .ant-alert {
    margin: 20px;
  }

  & h2 {
    font-size: 24px;
    font-weight: 500;
  }

  & .filter-button {
    background: #eff0fa !important;
    border: 1px solid rgba(206, 210, 234, 0.5) !important;
    border-radius: 5px;
    padding: 11px !important;

    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #060045 !important;
    height: auto !important;
  }
`;

export const StyledContentSubHeaderWrapper = styled(Row)`
  padding: 4% 0;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLogsDetailsContainer = styled.div`
  padding-top: 30px;
  overflow-x: hidden;
  height: 300px;
  overflow: auto;
  display: flex;
`;

export const StyledUserInfoContainer = styled.p`
  line-height: 0.05;
  color: #888888;
`;

export const StyledDetailsContainer = styled.p`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
`;

export const StyledSortSelected = styled(Menu.Item)`
  background-color: ${props =>
    props.eventKey === props.sortType ? cssVariables.primaryBlue : ''};
  color: ${props => (props.eventKey === props.sortType ? '#fff' : '')}; ;
`;
