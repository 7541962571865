import { stripUUID } from 'utils';

export const extractCategoryData = categoryAPIData => {
  return categoryAPIData
    .map(category => {
      return {
        jid: stripUUID(category.jid),
        name: category.context.name,
        color: category.context.color,
      };
    })
    .filter(Boolean);
};
