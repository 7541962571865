import { Space, Button, Modal, message } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { uuid } from 'uuidv4';
import { Context } from 'store/store';
import { csvToArray } from 'utils';
import { strippedString } from 'utils/stringManipulation';
import { validateAnswerBankJSONSchema } from 'utils/validator';
import { apiService } from 'services/api.service';
import { ADD_MULTIPLE_ANSWERS } from 'store/action';
import {
  allAnswersSelector,
  getTotalAnswersLeftSelector,
  isMaxAnswersSelector,
} from 'selectors/bot/answers';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import useSelector from 'store/useSelector';
import {
  convertToNewQuickReplyPayload,
  createDisplayAnswerAPIPayload,
} from 'utils/answers';

const { confirm } = Modal;

const useImportAnswerModal = ({
  fileType,
  onClose,
  visibility,
  setShowUploadModal,
  notificationAPI,
  currentStep,
  setCurrentStep,
  parsedFileAnswers,
  setParsedFileAnswers,
  similarAnswers,
  setSimilarAnswers,
  selectedSimilarAnswers,
  setSelectedSimilarAnswers,
  verifiedAnswers,
  setVerifiedAnswers,
  isVerifyingAnswers,
  setIsVerifyingAnswers,
  isImportBtnDisabled,
  setIsImportBtnDisabled,
  duplicatesFoundInFile,
  setDuplicatesFoundInFile,
  notificationKey,
  setNotificationKey,
}) => {
  const [state, dispatch] = useContext(Context);
  const {
    bot: { jid },
    sentinel,
    token,
    graph,
    plan,
  } = state;
  const { plan_type, onboarding_flag } = plan;
  const allAnswers = useSelector(allAnswersSelector);
  const limitReached = useSelector(isMaxAnswersSelector);
  const answersAllowed = useSelector(getTotalAnswersLeftSelector);

  const [answersFile, setAnswersFile] = useState(null);
  const [importErrorMsg, setImportErrorMsg] = useState(null);
  const [importWarnMsg, setImportWarnMsg] = useState(null);
  const [similarAnswerWarnMsg, setSimilarAnswerWarnMsg] = useState(null);
  const [finalAnswerWarnMsg, setFinalAnswerWarnMsg] = useState(null);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [modalBtnLoading, setModalBtnLoading] = useState(false);
  const [verificationBtnLoading, setVerificationBtnLoading] = useState(false);
  const [duplicatesFound, setDuplicatesFound] = useState([]);
  const [showImportTip, setShowImportTip] = useState(false);
  const [showCancelReqModal, setShowCancelReqModal] = useState(false);
  const limitErrorMsg = `You can add only ${answersAllowed} Answers in ${plan_type.toUpperCase()} Plan. Upgrade your plan to add answers.`;
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [showAnswerEditor, setShowAnswerEditor] = useState(false);
  const [ingestSelection, setIngestSelection] = useState('url');
  const [ingestionValue, setIngestionValue] = useState(null);
  const [openAiTemplatePlaceholders, setOpenAiTemplatePlaceholders] =
    useState(null);
  const [openAiFAQTemplatePlaceholders, setOpenAiFAQTemplatePlaceholders] =
    useState(null);
  const [textGenerateObject, setTextGenerateObject] = useState(null);
  const [editedTextPrompt, setEditedTextPrompt] = useState('');
  const [maxSentences, setMaxSentences] = useState(10);
  const [textGenerateMaxSentence, setTextGenerateMaxSentence] = useState(10);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [summaryCount, setSummaryCount] = useState({
    final: 0,
    draft: 0,
  });
  const [fileName, setFileName] = useState('');
  const [textGenerateSource, setTextGenerateSource] = useState('');
  const [generateFAQSource, setGenerateFAQSource] = useState('');
  const [generateFAQObject, setGenerateFAQObject] = useState(null);
  const [faqList, setFAQList] = useState([]);
  const generate_faq_params = {
    src: 'openai_faq_template',
  };

  const controller = new AbortController();

  const getStringAnswerDuplicate = input => {
    const strippedInput = strippedString(input);
    return allAnswers.find(
      answer => strippedString(answer.text) === strippedInput
    );
  };

  const getDuplicatesFromFile = answers => {
    const uniqAnswers = new Set(
      answers.map(answer => {
        if (typeof answer === 'string') {
          return answer;
        } else {
          return answer.text;
        }
      })
    );

    return uniqAnswers.size < answers.length;
  };

  const getDuplicatesFromFileList = useCallback(answers => {
    const newSetAnswers = new Set(
      answers.map(answer => (answer.text ? answer.text : answer))
    );
    return answers.filter(answer => {
      const ansDetails = answer.text ? answer.text : answer;
      if (newSetAnswers.has(ansDetails)) {
        newSetAnswers.delete(ansDetails);
      } else {
        return ansDetails;
      }
    });
  }, []);

  const sources = {
    json: {
      source: fileName,
      source_type: 'file_upload',
    },
    csv: {
      source: fileName,
      source_type: 'file_upload',
    },
    'text-ingest': {
      source: ingestionValue,
      source_type: 'text_summarize',
    },
    'text-generate': {
      source: textGenerateSource,
      source_type: 'text_generate',
    },
    'generate-faq': {
      source: generateFAQSource,
      source_type: 'generate_faq',
    },
  };

  const noValidAnswersFound = () => {
    setModalBtnLoading(false);
    setIsImportBtnDisabled(true);
    return message.error(`No valid answer found in ${answersFile?.name}`);
  };

  const saveToAnswerBank = async (fileType, answersToSubmit) => {
    if (fileType === 'json') {
      try {
        const answerList = {
          answer: answersToSubmit.map(answer => {
            const displayAnswer = createDisplayAnswerAPIPayload(answer);
            const answerDetails = {
              ...answer,
              ...displayAnswer,
              category: {
                name: answer.category?.name || answer.category,
                color: answer.category?.color || answer.category_color,
              },
              ans_version: verifiedAnswers.includes(answer.text)
                ? 'final'
                : 'draft',
              quick_reply: convertToNewQuickReplyPayload(answer),
              // new quick reply structure doesn't need the `quick_reply_options`
              quick_reply_options: undefined,
              ...sources[fileType],
            };

            if (!answerDetails.category.name) {
              answerDetails.category = {};
            }

            return answerDetails;
          }),
        };

        const response = await apiService.importer(
          sentinel,
          jid,
          token,
          answerList
        );

        if (!response?.data?.report[0]?.answer[0]) {
          return noValidAnswersFound();
        }

        const answersResponse = response?.data?.report[0]?.answer;
        dispatch({
          type: ADD_MULTIPLE_ANSWERS,
          payload: response?.data?.report[0],
        });
        setSummaryCount({
          draft: handleCheckSummaryCount(answersResponse, 'draft') || 0,
          final: handleCheckSummaryCount(answersResponse, 'final') || 0,
        });
      } catch (error) {
        throw new Error(
          error.message || 'Something went wrong while importing answers'
        );
      }
    } else {
      try {
        const response = await apiService.createManyAnswers(
          sentinel,
          jid,
          sources[fileType],
          answersToSubmit,
          token
        );

        if (!response?.data?.report[0]) {
          return noValidAnswersFound();
        }

        const answersResponse = response?.data?.report;
        dispatch({
          type: ADD_MULTIPLE_ANSWERS,
          payload: response?.data?.report,
        });
        setSummaryCount({
          draft: handleCheckSummaryCount(answersResponse, 'draft') || 0,
          final: handleCheckSummaryCount(answersResponse, 'final') || 0,
        });
      } catch (error) {
        throw new Error(
          error.message || 'Something went wrong while importing answers'
        );
      }
    }
  };

  const handleDisplaySimilarAnswer = async (
    selectedAnswer,
    isVerifyAll = false
  ) => {
    setVerificationBtnLoading(true);
    try {
      const res = await apiService.getSimilarAnswer(
        sentinel,
        jid,
        selectedAnswer,
        null,
        token
      );
      const answerData = res?.data?.report[0];
      let similarAns = similarAnswers;
      if (answerData.length > 0) {
        similarAns.push({ answerData, answer: selectedAnswer });
        setSimilarAnswers(similarAns);
        selectedSimilarAnswers.push({ answerData, answer: selectedAnswer });
        setSelectedSimilarAnswers(selectedSimilarAnswers);
        setVerificationBtnLoading(false);
      } else {
        setSelectedSimilarAnswers([]);
        setVerificationBtnLoading(false);
        if (!isVerifyAll) {
          message.success('No similar answer found.');
        }
        return handleVerifyAnswer(selectedAnswer);
      }
    } catch (error) {
      setSelectedSimilarAnswers([]);
      setVerificationBtnLoading(false);
      return message.error(
        error.message || `Something went wrong. File upload failed.`
      );
    }
  };

  const handleVerifyAnswer = selectedAnswer => {
    setVerificationBtnLoading(true);
    let verified_answers = verifiedAnswers || [];
    const answers = verifiedAnswers?.filter(
      verifiedAnswer => verifiedAnswer === selectedAnswer
    );
    if (answers.length === 0) {
      verified_answers.push(selectedAnswer);
      setSelectedSimilarAnswers([]);
      setVerifiedAnswers(verified_answers);
      setVerificationBtnLoading(false);
      const verified_similar_answer = similarAnswers.filter(
        res => res.answer === selectedAnswer
      );

      if (verified_similar_answer.length) {
        setSimilarAnswerWarnMsg(
          'You have converted answer to final with similar answer(s) this may affect the bot perfomance.'
        );
      }
      handleVerifiedAnswers();
      return verifiedAnswers;
    }
    setVerificationBtnLoading(false);
    return;
  };

  const handleCheckSummaryCount = (response, version) => {
    return response.filter(
      answer =>
        (fileType === 'json' ? answer : answer.answer).context.ans_version ===
        version
    )?.length;
  };

  const handleSubmitFile = async fileType => {
    if (answersAllowed <= 0) {
      return message.error(limitErrorMsg);
    }
    setModalBtnLoading(true);
    if (fileType === 'json') {
      const dataValidation = validateAnswerBankJSONSchema(parsedFileAnswers);

      if (dataValidation.status === 'missing_fields') {
        let errorMsg =
          'Following fields are missing for some of the data in the json file: ';
        errorMsg += dataValidation.data.join(', ');

        setImportErrorMsg(errorMsg);
        return setModalBtnLoading(false);
      }

      if (dataValidation.status === 'invalid_fields') {
        let errorMsg =
          'Following fields have invalid data type for some of the data in the json file: ';
        errorMsg += dataValidation.data.join(', ');

        setModalBtnLoading(false);
        return setImportErrorMsg(errorMsg);
      }
    }
    handleDuplicateAnswers(parsedFileAnswers);
    handleVerifiedAnswers();

    const extraAnswers = parsedFileAnswers.length - answersAllowed;
    const splicedAnswers = parsedFileAnswers.filter(
      (item, idx) => idx + 1 <= answersAllowed
    );

    try {
      let answersToSubmit = extraAnswers ? splicedAnswers : parsedFileAnswers;
      if (fileType !== 'json') {
        answersToSubmit = answersToSubmit.map(answer => {
          let question = {};
          const displayAnswer = createDisplayAnswerAPIPayload(answer);
          if (fileType === 'generate-faq') {
            question = {
              text: handleFindFAQquestion(answer.text),
              version: !verifiedAnswers.includes(answer.text)
                ? 'draft'
                : 'final',
            };
          }
          return {
            answer: {
              text: answer.text,
              ...displayAnswer,
              version: !verifiedAnswers.includes(answer.text)
                ? 'draft'
                : 'final',
            },
            question,
          };
        });
      }

      try {
        await saveToAnswerBank(fileType, answersToSubmit);
        setIsUploadSuccess(true);
        setShowCancelReqModal(false);
        message.success(`Answer(s) added successfully.`);
        setModalBtnLoading(false);
        handleCloseUploadModal(true, true);
        setOpenConfirmationModal(true);
      } catch (error) {
        return message.error(error.message);
      }
    } catch (error) {
      // if (error.name === 'AbortError') {
      if (axios.isCancel(error)) {
        setShowCancelReqModal(false);
        setIsImportBtnDisabled(false);
        setIsUploadSuccess(true);
        return message.error(error.message || 'Operation aborted by the user.');
      } else {
        setModalBtnLoading(false);
        setIsImportBtnDisabled(false);
        return message.error(
          error.message || `Something went wrong. File upload failed.`
        );
      }
    }
  };

  const getOpenAiTemplate = useCallback(async () => {
    setModalBtnLoading(true);
    try {
      const res = await apiService.getOpenAiTemplate(sentinel, token);

      if (res?.data?.success) {
        const placeHolders = res?.data?.report[0].placeholders;
        setOpenAiTemplatePlaceholders(placeHolders);

        // create empty object with keys from placeholders
        const initialTextGenerateObject = Object.keys(placeHolders).reduce(
          (o, key) => ({ ...o, [key]: '' }),
          {}
        );
        setTextGenerateObject(initialTextGenerateObject);
        setModalBtnLoading(false);
      } else {
        message.error(
          'Something went wrong with your request. Please try again.'
        );
        setIsImportBtnDisabled(true);
      }
    } catch (error) {
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  }, [sentinel, setIsImportBtnDisabled, token]);

  const getOpenAiFAQTemplate = useCallback(async () => {
    setModalBtnLoading(true);
    try {
      const res = await apiService.getOpenAiFAQTemplate(
        sentinel,
        token,
        generate_faq_params
      );

      if (res?.data?.success) {
        const placeHolders = res?.data?.report[0].placeholders;
        setOpenAiFAQTemplatePlaceholders(placeHolders);

        // create empty object with keys from placeholders
        const initialTextGenerateObject = Object.keys(placeHolders).reduce(
          (o, key) => ({ ...o, [key]: '' }),
          {}
        );
        initialTextGenerateObject.max_answer_count = 10;
        setGenerateFAQObject(initialTextGenerateObject);
        setModalBtnLoading(false);
      } else {
        message.error(
          'Something went wrong with your request. Please try again.'
        );
        setIsImportBtnDisabled(true);
      }
    } catch (error) {
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  }, [generate_faq_params, sentinel, setIsImportBtnDisabled, token]);

  const handleDuplicateAnswers = useCallback(
    answers => {
      const textAnswers = answers.map(answer => {
        return getStringAnswerDuplicate(answer.text ? answer.text : answer);
      });
      setDuplicatesFound(textAnswers.filter(item => !!item));
      setDuplicatesFoundInFile(getDuplicatesFromFileList(answers));
      const hasFileDuplicate = getDuplicatesFromFile(answers);
      const duplicateFound = textAnswers.filter(item => !!item);
      if (duplicateFound.length > 0 || hasFileDuplicate) {
        setImportErrorMsg(
          'Duplicate Answers Found. Please review the answers.'
        );
      } else {
        setImportErrorMsg(null);
      }
    },
    [
      getDuplicatesFromFileList,
      getStringAnswerDuplicate,
      setDuplicatesFoundInFile,
    ]
  );

  useEffect(() => {
    if (fileType === 'generate-faq') {
      getOpenAiFAQTemplate();
    } else {
      getOpenAiTemplate();
    }
  }, [fileType]);

  const handleCheckValidations = () => {
    setCurrentStep(currentStep + 1);
    handleDuplicateAnswers(parsedFileAnswers);

    if (answersAllowed < parsedFileAnswers.length) {
      setImportWarnMsg(
        `Maximum number of answers reached. System will only upload ${
          answersAllowed <= 0 ? 0 : answersAllowed
        } answer(s).`
      );
    }
  };

  const handleStepChange = curr => {
    if (!parsedFileAnswers || !parsedFileAnswers.length) {
      return;
    } else {
      setCurrentStep(curr);
    }
  };

  const handleToggleAnswerEditor = (answer, idx) => {
    if (showAnswerEditor && !answer) {
      setSelectedAnswer({});
      setShowAnswerEditor(false);
    } else {
      setSelectedAnswer({ ...answer, idx });
      setShowAnswerEditor(true);
    }
  };

  const modifyParsedAnswer = useCallback(parsedAnswers => {
    if (parsedAnswers.length > 0) {
      return parsedAnswers.map(answer => {
        const showTextAnswer = answer.show_text || answer.text;
        if (answer.text) {
          return {
            ...answer,
            show_text: showTextAnswer,
            uuid: uuid(),
          };
        } else {
          return {
            text: answer,
            show_text: showTextAnswer,
            uuid: uuid(),
          };
        }
      });
    } else {
      return parsedAnswers;
    }
  }, []);

  const handleUploadFile = info => {
    const isJson = info.type === 'application/json';
    const isCSV = info.type.includes('csv');
    setFileName(info.name);

    setImportErrorMsg(null);
    setImportWarnMsg(null);
    const reader = new FileReader();
    reader.onload = e => {
      const answers = e.target.result;
      let parsedAnswers = [];
      let delimiters = ['\n'];
      setAnswersFile(info.name);

      if (isCSV) {
        delimiters.push(',');
      }

      if (!isJson) {
        parsedAnswers = csvToArray(answers, delimiters, true);
      } else {
        parsedAnswers = JSON.parse(answers);
      }

      if (answersAllowed < parsedAnswers.length) {
        setImportWarnMsg(
          `Maximum number of answers reached. System will only upload ${answersAllowed} answer(s).`
        );
      }
      if (!isJson && answers.length) {
        const modifiedAnswer = modifyParsedAnswer(parsedAnswers);
        setParsedFileAnswers(modifiedAnswer);
        setIsImportBtnDisabled(false);
      } else if (isJson && parsedAnswers && parsedAnswers.length) {
        const modifiedAnswer = modifyParsedAnswer(parsedAnswers);
        setParsedFileAnswers(modifiedAnswer);
        setIsImportBtnDisabled(false);
      } else if (isJson && parsedAnswers?.bot?.length) {
        if (parsedAnswers?.bot[0]?.answer.length) {
          const modifiedAnswer = modifyParsedAnswer(
            parsedAnswers?.bot[0]?.answer
          );
          setParsedFileAnswers(modifiedAnswer);
          setIsImportBtnDisabled(false);
        }
      } else if (
        isJson &&
        Array.isArray(parsedAnswers) &&
        !parsedAnswers.length
      ) {
        setImportErrorMsg(`No valid answer found from the file.`);
      } else {
        setImportErrorMsg('Your json data format is not correct');
      }
    };
    reader.readAsText(info);
    return false;
  };

  const handleRemoveFile = () => {
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setParsedFileAnswers([]);
    setIsImportBtnDisabled(true);
  };

  const handleEditAnswer = (e, idx) => {
    if (parsedFileAnswers[idx].text) {
      parsedFileAnswers[idx].text = e.target.value;
    } else {
      parsedFileAnswers[idx] = e.target.value;
    }
    handleDuplicateAnswers(parsedFileAnswers);
    return setParsedFileAnswers([...parsedFileAnswers]);
  };

  const handleVerifiedAnswers = () => {
    const allowedAnsCount =
      parsedFileAnswers.length > answersAllowed
        ? answersAllowed
        : parsedFileAnswers.length;
    if (allowedAnsCount > verifiedAnswers.length) {
      setFinalAnswerWarnMsg(
        `There are ${
          allowedAnsCount - verifiedAnswers.length
        } answers in the list which has not been verified for duplicates.`
      );
    } else {
      setFinalAnswerWarnMsg(null);
    }
  };

  const handleCheckAnswer = useCallback(answer => {
    return answer.text ? answer.text : answer;
  }, []);

  const handleRemoveAnswer = useCallback(
    answerToRemove => {
      setParsedFileAnswers(asnwers => {
        const filteredAnswers = asnwers.filter(answer => {
          if (answer.uuid) {
            return answer.uuid !== answerToRemove.uuid;
          }
          return (
            strippedString(handleCheckAnswer(answer)) !==
            strippedString(handleCheckAnswer(answerToRemove))
          );
        });

        handleDuplicateAnswers(filteredAnswers);
        if (!filteredAnswers.length) {
          setCurrentStep(currentStep - 1);
        }
        if (answersAllowed >= filteredAnswers.length && importWarnMsg) {
          setImportWarnMsg(null);
        }
        return filteredAnswers;
      });
    },
    [
      answersAllowed,
      currentStep,
      handleCheckAnswer,
      handleDuplicateAnswers,
      importWarnMsg,
      setCurrentStep,
      setParsedFileAnswers,
    ]
  );

  const handleRunVerifyAnswersInBackground = useCallback(() => {
    setShowUploadModal(false);
  }, [setShowUploadModal]);

  // TODO: move this on <AnswerBank /> component
  const resetModal = useCallback(() => {
    setIngestSelection('url');
    setIngestionValue(null);
    setTextGenerateObject(null);
    setMaxSentences(10);
    setShowImportTip(false);
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setFinalAnswerWarnMsg(null);
    setSimilarAnswerWarnMsg(null);
    setParsedFileAnswers([]);
    setVerifiedAnswers([]);
    setGenerateFAQObject(null);
    setFAQList(null);
    setIsVerifyingAnswers(false);
    setCurrentStep(0);
    setShowUploadModal(false);
  }, [
    setCurrentStep,
    setIsVerifyingAnswers,
    setParsedFileAnswers,
    setShowUploadModal,
    setVerifiedAnswers,
  ]);

  const handleCloseUploadModal = useCallback(
    (closeAll, isAddedAnswer) => {
      if (isVerifyingAnswers) {
        confirm({
          content: <p>Are you sure you want to close this window?</p>,
          onOk() {
            resetModal();
          },
          onCancel() {
            return;
          },
          cancelText: 'Cancel',
          okText: 'Close',
        });
      } else if (!closeAll && modalBtnLoading) {
        setCurrentStep(0);
        message.info('Import process will continue in the background', 10);
        setShowUploadModal(false);
        return onClose();
      } else if (closeAll) {
        resetModal();

        if (!isAddedAnswer) {
          return onClose();
        }
      }
    },
    [
      isVerifyingAnswers,
      modalBtnLoading,
      onClose,
      resetModal,
      setCurrentStep,
      setShowUploadModal,
    ]
  );

  const handleCancelImport = useCallback(() => {
    controller.abort();
    setModalBtnLoading(false);
    return handleCloseUploadModal(true);
  }, [controller, handleCloseUploadModal]);

  const openNotification = useCallback(() => {
    const key = `open${uuid()}`;
    setNotificationKey(key);
    const btn = (
      <Space>
        <Button
          type="link"
          size="small"
          onClick={() => {
            setCurrentStep(1);
            setShowUploadModal(true);
            setIsImportBtnDisabled(false);
            notificationAPI.close(key);
          }}
        >
          Show Verify Modal
        </Button>
      </Space>
    );

    notificationAPI.open({
      message: `Verify answers is running in the background.`,
      btn,
      key,
      placement: 'bottomLeft',
      duration: 500,
    });
  }, [
    notificationAPI,
    setCurrentStep,
    setIsImportBtnDisabled,
    setNotificationKey,
    setShowUploadModal,
  ]);

  const runVerifyAnswersInBackground = useCallback(() => {
    handleRunVerifyAnswersInBackground();
    openNotification();
  }, [handleRunVerifyAnswersInBackground, openNotification]);

  useEffect(() => {
    if (!visibility && parsedFileAnswers.length) {
      handleCancelImport();
    }
  }, [handleCancelImport, parsedFileAnswers, visibility]);

  const isUrl = string => {
    try {
      return Boolean(new URL(string));
    } catch (e) {
      return false;
    }
  };

  const generateAanswersViaGpt = async () => {
    setModalBtnLoading(true);
    try {
      const res = await apiService.generateAanswersViaOpenAI(
        sentinel,
        jid,
        token,
        textGenerateObject
      );

      if (res?.data?.success) {
        let answer = res?.data?.report[1][0];
        let textGenerateSource = res?.data?.report[0];
        setTextGenerateSource(textGenerateSource);
        answer = answer.replace(/(\r\n|\n|\r)/gm, '');
        setCurrentStep(currentStep + 1);
        const modifiedAnswer = modifyParsedAnswer([answer]);
        setParsedFileAnswers(modifiedAnswer);
        setIsImportBtnDisabled(false);
        setModalBtnLoading(false);
        message.success('Source successfully generated answer.');
      } else {
        message.error(
          'Something went wrong with your request. Please try again.'
        );
        setModalBtnLoading(false);
        setIsImportBtnDisabled(true);
      }
    } catch (error) {
      setModalBtnLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleSummarizeSource = async () => {
    if (!isUrl(String(ingestionValue)) && ingestSelection === 'url') {
      return message.error('Please enter a URL');
    }

    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setModalBtnLoading(true);
    try {
      const res = await apiService.getSummary(
        sentinel,
        jid,
        String(ingestionValue),
        ingestSelection,
        maxSentences || 10,
        token
      );

      const summarized = res.data.report[0];

      if (answersAllowed < summarized.length) {
        setImportWarnMsg(
          `Maximum number of answers reached. System will only upload ${
            answersAllowed <= 0 ? 0 : answersAllowed
          } answer(s).`
        );
      }

      if (summarized.length) {
        const answers = modifyParsedAnswer(summarized);
        setCurrentStep(currentStep + 1);
        message.success('Source successfully summarized');
        handleDuplicateAnswers(answers);
        handleVerifiedAnswers();
        setParsedFileAnswers(answers);
      } else if (
        Array.isArray(summarized) &&
        !summarized.length &&
        answersAllowed
      ) {
        setImportErrorMsg(`No valid answer found from the file.`);
      }
      if (answersAllowed && summarized.length) {
        setIsImportBtnDisabled(false);
      }
    } catch (error) {
      setModalBtnLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
    setModalBtnLoading(false);
  };

  const handleGenerateFAQ = async () => {
    setModalBtnLoading(true);
    try {
      if (generateFAQObject?.max_answer_count > answersAllowed) {
        generateFAQObject['max_answer_count'] = answersAllowed;
      }
      const res = await apiService.generateFAQViaOpenAi(
        sentinel,
        token,
        generateFAQObject
      );

      if (res?.data?.success && res?.data?.report[1]) {
        const parsedAnswerList = JSON.parse(
          res?.data?.report[1]?.content.replace(/(\r\n|\n|\r)/gm, '')
        );
        const answerList =
          parsedAnswerList?.faqs ||
          parsedAnswerList?.FAQ ||
          parsedAnswerList?.FAQs;

        const parsedAnswers = [];
        setFAQList(answerList);
        setGenerateFAQSource(res?.data?.report[0]);
        setCurrentStep(currentStep + 1);
        answerList?.map(answers =>
          parsedAnswers.push({ text: answers.answer, uuid: uuid() })
        );
        setParsedFileAnswers(parsedAnswers);
        setIsImportBtnDisabled(false);
        setModalBtnLoading(false);
        message.success('Source successfully generated answer.');
      } else {
        message.error(
          'Something went wrong with your request. Please try again.'
        );
        setModalBtnLoading(false);
      }
    } catch (error) {
      setModalBtnLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleUpdateAnswerEditor = val => {
    const updatedAnswers = parsedFileAnswers.map((answer, key) => {
      if (key === val.idx) {
        setSelectedAnswer(val);
        return val;
      }
      return answer;
    });
    return setParsedFileAnswers(updatedAnswers);
  };

  const handleRadioChange = val => {
    setIngestSelection(() => val);
  };

  const handleSetIngestionValue = text => {
    setIngestionValue(text);
  };

  const handleClearData = () => {
    notificationAPI.close(notificationKey);
    setVerifiedAnswers([]);
    setCurrentStep(currentStep + -1);
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setFinalAnswerWarnMsg(null);
    setSimilarAnswerWarnMsg(null);
    setSimilarAnswers([]);
    setSummaryCount({ final: 0, draft: 0 });
  };

  const handleCloseConfirmationModal = () => {
    handleClearData();
    setCurrentStep(0);
    setOpenConfirmationModal(false);
    return onClose();
  };

  const handleFindFAQquestion = answer => {
    return faqList.find(faq => faq.answer === answer)?.question;
  };
  return {
    answersAllowed,
    answersFile,
    currentStep,
    fileType,
    handleRemoveAnswer,
    importErrorMsg,
    importWarnMsg,
    ingestSelection,
    ingestionValue,
    isImportBtnDisabled,
    isUploadSuccess,
    limitReached,
    maxSentences,
    modalBtnLoading,
    parsedFileAnswers,
    duplicatesFound,
    duplicatesFoundInFile,
    handleCancelImport,
    runVerifyAnswersInBackground,
    handleCloseUploadModal,
    handleEditAnswer,
    handleRadioChange,
    handleSetIngestionValue,
    handleRemoveFile,
    handleToggleAnswerEditor,
    handleStepChange,
    handleSubmitFile,
    handleSummarizeSource,
    generateAanswersViaGpt,
    handleGenerateFAQ,
    getOpenAiTemplate,
    openAiTemplatePlaceholders,
    openAiFAQTemplatePlaceholders,
    handleUpdateAnswerEditor,
    handleUploadFile,
    handleCheckValidations,
    selectedAnswer,
    similarAnswers,
    selectedSimilarAnswers,
    allAnswers,
    sentinel,
    setCurrentStep,
    setImportErrorMsg,
    setIngestionValue,
    setTextGenerateObject,
    textGenerateObject,
    setEditedTextPrompt,
    editedTextPrompt,
    setIngestSelection,
    setMaxSentences,
    textGenerateMaxSentence,
    setTextGenerateMaxSentence,
    setParsedFileAnswers,
    setSelectedAnswer,
    setShowImportTip,
    setShowCancelReqModal,
    handleDisplaySimilarAnswer,
    handleVerifyAnswer,
    handleClearData,
    handleCloseConfirmationModal,
    showCancelReqModal,
    showImportTip,
    token,
    visibility,
    onboarding_flag,
    dispatch,
    graph,
    verifiedAnswers,
    verificationBtnLoading,
    similarAnswerWarnMsg,
    finalAnswerWarnMsg,
    summaryCount,
    openConfirmationModal,
    textGenerateSource,
    setGenerateFAQObject,
    generateFAQObject,
    handleFindFAQquestion,
    handleVerifiedAnswers,
    handleDuplicateAnswers,
    handleCheckAnswer,
    setImportWarnMsg,
  };
};

export default useImportAnswerModal;
