import { useContext, useState } from 'react';

import { REMOVE_ANSWER_QLINK } from 'store/action';
import { Context } from 'store/store';
import { message } from 'antd';
import { deleteQuestionLink } from 'services/linkQuestion.service';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';

const useLinkedQuestionModal = () => {
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const { sentinel, token } = state;

  const handleUnlinkQuestion = async (answerLinked, question, index) => {
    setLoading(true);
    const linkedIndex = index || 0;
    try {
      await deleteQuestionLink(answerLinked, sentinel, token, linkedIndex);

      await dispatch({
        type: REMOVE_ANSWER_QLINK,
        payload: {
          answer: answerLinked.jid,
          question,
        },
      });
    } catch (err) {
      message.error(err?.message || DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return {
    handleUnlinkQuestion,
    loading,
  };
};

export default useLinkedQuestionModal;
