import React from 'react';
import PropTypes from 'prop-types';
import { cssVariables } from 'styles/root';
import { StyledButton } from './Button.style';

const Button = props => {
  const {
    className,
    disabled,
    endIcon,
    full,
    size,
    startIcon,
    type,
    variant,
    value,
    ...rest
  } = props;
  const getColor = variant => {
    switch (variant) {
      case 'primary':
      case 'primary-btn-v2':
      case 'link':
        return cssVariables.primaryBlue;
      case 'secondary':
      case 'secondary-btn-v2':
        return cssVariables.secondaryColor;
      case 'success':
        return cssVariables.success;
      case 'cyan':
        return cssVariables.primaryCyan;
      case 'error':
        return cssVariables.errorButtonColor;
      case 'white':
        return '#fff';
      case 'red':
        return cssVariables.red10;
      case 'gray':
      case 'grey':
        return cssVariables.gray0;
      default:
        return cssVariables.primaryBlue;
    }
  };

  const getHoverColor = variant => {
    switch (variant) {
      case 'primary':
      case 'primary-btn-v2':
        return cssVariables.primaryBlueHover;
      case 'secondary':
      case 'secondary-btn-v2':
        return cssVariables.gray1;
      case 'success':
        return cssVariables.successHover;
      case 'error':
        return cssVariables.errorBorder;
      case 'white':
      case 'link':
        return cssVariables.primaryTransparent;
      case 'red':
        return cssVariables.red1;
      case 'gray':
      case 'grey':
        return cssVariables.secondaryColor;
      default:
        return cssVariables.primaryBlueHover;
    }
  };

  return (
    <StyledButton
      className={className}
      color={getColor(variant)}
      disabled={disabled}
      $full={full}
      hover={getHoverColor(variant)}
      htmlType={type}
      size={size}
      variant={variant}
      {...rest}
    >
      {startIcon || null}
      {value ? <span>{value}</span> : rest.children}
      {endIcon || null}
    </StyledButton>
  );
};

Button.defaultProps = {
  size: 'large',
  variant: 'primary-btn-v2',
};

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'middle', 'large']),
  value: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'link',
    'primary-btn-v2',
    'secondary-btn-v2',
    'success',
    'error',
    'gray',
    'grey',
    'white',
    'cyan',
  ]),
  className: PropTypes.string,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

export { Button };
