import styled from 'styled-components';
import { cssVariables } from 'styles/root';
import { Dropdown } from 'antd';

export const StyledCustomTooltip = styled.div`
  width: 364px;
  display: block;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 6px;

  & button {
    cursor: pointer;
    width: 62px;
    height: 30px;
    color: #ffffff;
    background-color: #1f6be4;
    border: 0;
    border-radius: 3px;
    font-size: 10px;
    font-weight: ${cssVariables.font.normal};
    letter-spacing: 2px;
    margin-right: 4px;
  }
`;

export const StyledToolTipContainer = styled.div`
  display: block;
  padding: 10px;

  & h3 {
    font-weight: ${cssVariables.font.bold};
  }
`;

export const StyledFooterActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDropdownButtonMenu = styled(Dropdown.Button)`
  border-radius: 5px;
  width: auto !important;
  button {
    height: 40px;
    font-size: 16px;
  }

  button:first-child {
    ${props =>
      props.isMainButtonDisabled
        ? `pointer-events: none;
      `
        : ``}

    ${props =>
      props.isQuestionPage
        ? `width: 100%;
          `
        : ``}
  }
`;
