import { DEFAULT_URL_OUTPUT_FIElDS, NEW_OBJECT_ITEM } from 'constants/aiTools';
import { INITIAL_BOT_INTEGRATION_DATA } from 'constants/botCustomizer';
import { getViewType } from 'services/bots.service';

const viewType = getViewType();

export const initialCategoryModalState = {
  jid: '',
  name: '',
  color: '',
  action: '',
  isOpen: false,
  templates: [],
  errors: [],
};

export const initialResponsePickerModalState = {
  isOpen: false,
  answers: [],
  softLink: false,
  withQuestion: false,
  botName: null,
  title: null,
  similarQuestions: [],
  itemToChange: {
    question: null,
    answer: {},
    idx: null,
  },
};

export const initialCallbackLogsState = {
  table: [],
  sessionDetails: {
    data: [],
  },
};

export const initialAnalyticsAggregationsState = {
  allAnswersCount: 0,
  answerCountEachType: {},
  visitorCount: 0,
  sessionCount: 0,
  uniqueAnswers: 0,
  totalQuestions: 0,
  totalUniqueQuestions: 0,
  hits: 0,
  totalCorrectValidation: 0,
  totalIncorrectValidation: 0,
  categoriesFromES: [],
  integrationsFromES: [],
  tableData: {
    mostAskedQuestionData: [],
    intentsData: [],
  },
};

export const initialAnalyticsState = {
  table: {
    allQuestions: [],
    mostAskedQuestions: [],
    intents: [],
    positiveFeedbackQuestions: [],
    negativeFeedbackQuestions: [],
    correctQuestions: [],
    incorrectQuestions: [],
  },
  aggregations: initialAnalyticsAggregationsState,
};

export const initialAnswerEditorModalState = {
  isOpen: false,
  answerTab: null,
  questionsTab: null,
  insightsTab: {
    aggregations: null,
    graph: null,
    table: null,
  },
  isCreateMode: false,
};

export const initialFileEditorModalState = {
  isOpen: false,
  file: null,
};

export const initialWebsitEditorModalState = {
  isOpen: false,
  file: null,
};

export const initialQuestionEditorModalState = {
  isOpen: false,
  text: null,
  jid: null,
  action: null,
  similarQuestions: [],
  answersWithScore: [],
  version: null,
};

export const initialAIToolModalState = {
  isOpen: false,
  jid: null,
  parameters: NEW_OBJECT_ITEM,
  output: DEFAULT_URL_OUTPUT_FIElDS,
  name: '',
  description: '',
  version: 'draft',
};

export const initialImageViewerModal = {
  isOpen: false,
  source: null,
  title: null,
};

export const initialBotModalsState = {
  responsePickerModal: initialResponsePickerModalState,
  similarQuestionsModal: {
    isOpen: false,
    data: [],
  },
  answerEditorModal: initialAnswerEditorModalState,
  questionEditorModal: initialQuestionEditorModalState,
  aiToolModal: initialAIToolModalState,
  avatarPickerModal: {
    isOpen: false,
  },
  imageViewerModal: initialImageViewerModal,
  apiKeyModal: {
    isOpen: false,
    isIntegrationComponent: false,
  },
};

export const initialWeakBotStatsState = {
  overview: true,
};

export const initialChatAnswerInModal = {
  show: false,
  answer: {},
  question: {},
};

export const initialChatSequenceInModal = {
  show: false,
  answer: {},
  question: {},
};

export const initialChatSelectedSequenceInModal = {
  show: false,
  sequence: {},
};

export const initialUIState = {
  // TODO: move other bot ui state here
  // TODO: plz use `isOpen` to show/open modals
  modals: initialBotModalsState,
  weakBotStats: initialWeakBotStatsState,
  isChatOnDevMode: false,
  isAnswerBankReady: false,
  chatAnswerInModal: initialChatAnswerInModal,
};

export const initialWebsiteSelectedPage = {
  urlSelected: [],
  urlSelectedRowKeys: [],
  categorySelected: [],
  categorySelectedRowKeys: [],
};

export const initialBotState = {
  aggregations: {},
  analytics: initialAnalyticsState,
  answerCount: 0,
  fileCount: 0,
  websiteCount: 0,
  answers: [],
  files: [],
  websites: [],
  questions: [],
  avatar: null,
  description: null,
  categories: [],
  chatList: [],
  createdAt: null,
  currentIntegrationSettings: {
    id: null,
    panel: {
      activePanel: 1,
      permit: 1,
      postCustomizationConfirmEditModal: {
        isOpen: false,
        editable: false,
      },
      preCustomizationConfirmEditModal: {
        isOpen: false,
      },
    },
    ...INITIAL_BOT_INTEGRATION_DATA,
  },
  defaultAnswer: [],
  integrations: [],
  jid: null,
  name: null,
  lastUpdatedAt: null,
  metadata: {},
  selectedAnswer: null,
  selectedCategory: initialCategoryModalState,
  strippedBotID: null,
  testSuites: [],
  testSuite: {
    jid: null,
    testCases: [],
  },
  totalTestSuites: null,
  totalIntegrations: null,
  hasBotError: false,
  ui: initialUIState,
  websiteSelectedPages: initialWebsiteSelectedPage,
};

export const initialPlansAndFeatures = {
  free: {},
  basic: {},
  advanced: {},
};

export const initialPlanState = {
  export_import: false,
  payment_status: '',
  user_type: 'free',
  plan_type: 'free',
  customer_id: null,
  customer: {
    id: null,
    cards: [],
  },
  subscription: null,
  lastPaymentError: null,
  current_bot_count: 0,
  onboarding_flag: [],
  max_ans_count: 10,
  max_test_cases: 5,
  max_test_suite: 1,
  max_bot_count: 1,
  current_transaction_count: 0,
  max_txn_count: 1000,
  last_reset_date: '06/25/2021',
  payment_methods: [],
  payments: [],
  plansAndFeatures: initialPlansAndFeatures,
  invoices: [],
  showFirstLoginPlan: false,
};

export const initialUserState = {
  email: '',
  is_superuser: false,
  is_activated: false,
  name: 'Guest',
  password: '',
  firstName: 'Guest',
};

export const initialAdminState = {
  is_impersonating: false,
  impersonated_user: {},
  all_users: [],
  all_global_vars: [],
  networkData: {},
  showDigraph: false,
};

export const initialWSState = {
  channel: null,
  askQuestionActions: [],
  askQuestionActiveAction: null,
};

export const initialState = {
  isAuthenticated: false,
  isExternalPage: Boolean(localStorage.getItem('external')) || false,
  token: null,
  user: initialUserState,
  sentinel: null,
  featureFlags: {},
  graph: null,
  bots: [],
  bot: initialBotState,
  tempBot: null,
  viewType: viewType || 'gridview',
  error: null,
  messengerCallback: null,
  messengerApi: {},
  publicKey: null,
  pubAskedQuestion: null,
  plan: initialPlanState,
  categoryTemplates: [],
  hasLogout: false,
  webSocket: initialWSState,
  admin: initialAdminState,
  layout: {
    initialized: false,
    botModal: {
      visible: false,
    },
    isMobileView: false,
    standardFontSize: '1rem',
  },
};
