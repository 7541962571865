import styled from 'styled-components';
import { Select as AntdSelect } from 'antd';

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.full ? '100%' : 'auto')};
`;

export const StyledLabel = styled.label`
  margin-right: 5px;
`;

export const StyledAntdSelect = styled(AntdSelect)`
  border-radius: 3px;
`;
