import { isEmpty } from 'lodash';

import { PRICE_PER_QUERY } from 'constants/plan';

export const detailedPlanFeatures = (plan, name, isList) => {
  if (isEmpty(plan)) {
    return [];
  }
  const planPrice = plan.stripe?.find(stripePrice => stripePrice.quantity);
  const priceId = planPrice?.priceId || null;
  return [
    isList ? undefined : name,
    `${plan.max_bot_count} Bot${plan.max_bot_count > 1 ? 's' : ''}`,
    `Up to ${plan.max_ans_count} Answers per bot`,
    plan.integration === 'all'
      ? 'Integrate to multiple Channels'
      : name === 'basic'
      ? 'Integrate on Website and Mobile'
      : undefined,
    `${plan.max_txn_count} queries per month, ${PRICE_PER_QUERY} per query`,
    `${plan.max_test_suite} Test Suite${plan.max_test_suite > 1 ? 's' : ''}`,
    `${plan.max_test_cases} Test Cases`,
    plan.analytics ? `Analytics, Reporting Available` : undefined,
    name === 'advanced' ? '24*7 Support Available' : undefined,
  ].filter(Boolean);
};
