import { Alert } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledAntUploadContent = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  row-gap: 16px;
  margin-top: -10px;
  height: 200px;
  justify-content: center;
  & .anticon {
    font-size: 30px;
  }
`;

export const StyledUploadTip = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  color: ${cssVariables.primaryBlue};

  & .ant-typography pre {
    margin-top: 5px;
  }
`;

export const StyledModalErrorAlert = styled(Alert)`
  margin: 20px 0;
`;

export const StyledUploadTipTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  color: ${props => (props?.error ? `red` : `rgba(0, 0, 0, 0.85)`)};
`;

export const StyledBtnAdvanceSettings = styled.button`
  display: flex;
  border: none;
  height: 38px;
  background: #ffff;
  color: #1667e7;
  border-radius: 1px;
  cursor: pointer;
  margin-top: 15px;
  float: right;
  line-height: 32px;

  &:hover {
    background: #1667e726;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

export const ActiveActionLabel = styled.h3`
  color: #167be7 !important;
`;

export const SelectOptionTitle = styled.div`
  font-weight: ${cssVariables.font.bold};
`;
