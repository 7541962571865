import { Menu, Divider, Avatar, Layout as AntdLayout } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

const { Item } = Menu;

const { Header } = AntdLayout;

export const StyledUserDetailsMenu = styled(Menu)`
  width: 260px;
  padding: 16px;

  & .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background: rgba(138, 138, 141, 0.1);
  }
  & .ant-dropdown-menu-item {
    text-align: left;
    padding: 10px;
    white-space: normal;
  }
  & .dropdown-container {
    font-weight: ${cssVariables.font.bold};
    font-size: 16px;
  }
`;

export const StyledUserDetailsMenuItem = styled(Menu.Item)`
  background: unset;
`;

export const StyledUserDetailsBlock = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  text-align: left;
`;

export const StyledUserDetails = styled.div`
  margin-left: 8px;
  width: 170px;
`;

export const StyledUserName = styled.div`
  font-size: 15px;
  font-weight: ${cssVariables.font.normal};
  color: #000000;
`;

export const StyledUserEmail = styled.div`
  word-break: break-all;
`;

export const StyledPlanDetails = styled(Item)`
  background: rgba(200, 200, 202, 0.1);

  &:hover {
    background: rgba(200, 200, 202, 0.1);
  }
`;

export const StyledUserMenuDivider = styled(Divider)`
  margin: 0;
`;

export const StyledRouteItem = styled(Item)`
  display: flex;
  align-items: center;
  column-gap: 3px;
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0px;
  background: ${cssVariables.primaryGray};
  position: sticky;

  .userDetails {
    background: #202225;
    justify-content: flex-end;
    display: flex;
    text-align: end;
    padding-right: 3%;
    flex: none !important;
    min-width: auto !important;
    width: auto !important;
  }
`;

export const StyledHeaderTitle = styled.div`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.normal};
  font-size: 18px;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledHeaderLogo = styled.img`
  height: 40px;
  margin-top: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledHeaderMenu = styled(Menu)`
  display: flex;
  align-items: center;
  background: #202225;
`;

export const StyledPageMenu = styled(Menu)`
  background-color: #202225 !important;
  color: #fff;
  border-bottom: none !important;

  .ant-menu-item-selected {
    background-color: unset !important;
  }
`;

export const StyledDropdownWrapper = styled.div`
  display: flex;
`;

export const StyledHeaderName = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-size: 1rem;
  color: #fff;
  line-height: 18px;
  cursor: pointer;

  :hover {
    text-decoration: underline 1px;
    text-underline-offset: 3px;
  }
`;

export const StyledProfileAvatar = styled(Avatar)`
  cursor: pointer;
  box-shadow: 1px 1px 0.5px 0px #b5b5b5;
  margin-right: 10px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

export const StyledButtonSpan = styled.span`
  color: '#fff';
`;

export const StyledTransactionParagraph = styled.span`
  color: ${props => (props.isRed ? '#ff4d4f' : 'auto')};
`;
