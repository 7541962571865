import { GET_DATA_ERROR } from 'constants/error';
import { apiService } from './api.service';

export const fetchSessionCallback = async (
  sessionID,
  sentinel,
  withPrefixBotJID,
  token
) => {
  try {
    const res = await apiService.getElasticLogFilter(
      sentinel,
      withPrefixBotJID,
      'session_id',
      sessionID,
      token
    );
    return res.data.report[0];
  } catch (error) {
    const errMsg =
      error !== GET_DATA_ERROR
        ? error.message
        : 'Error while fetching  callback data';
    throw new Error(errMsg);
  }
};
