import styled from 'styled-components';
import Button from 'components/Button';
import { cssVariables } from 'styles/root';
import { device } from 'constants/screens';

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 34px;
  width: 100%;
  overflow-y: scroll;

  @media all and (${device.laptopL}) {
    padding: 32px 34px;
    width: 100%;
    overflow-y: scroll;
  }

  overflow-y: auto;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 10px;
    margin-right: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
    margin-right: 15px;
    border: 2px solid #f9f9f9;
  }
`;

export const StyledNavigationWrapper = styled.div`
  display: flex;
  gap: 3rem;
  padding: 0rem 0rem 0.5rem 0rem;
  width: 100%;

  @media all and (${device.laptopL}) {
    padding: 0rem 0rem 0.5rem 0rem;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${cssVariables.primaryBlueHover};
  color: ${cssVariables.primaryBlue};
  width: 97px;

  :hover {
    background-color: ${cssVariables.primaryBlue};
    color: ${cssVariables.primaryBlueHover};
    width: 97px;
  }

  @media all and (${device.laptopL}) {
    width: 87px;
  }
`;

export const StyledTitleWrapper = styled.div`
  width: 100%;

  @media all and (${device.laptopL}) {
    font-size: 14px;
  }
`;

export const StyledTitle = styled.h2`
  font-weight: 600;
  margin: auto;
`;

export const StyledSubTitleWrapper = styled.div`
  font-size: 15px;
  width: 100%;

  @media all and (${device.laptopL}) {
    font-size: 13px;
  }
`;

export const StyledSubTitle = styled.h3`
  margin: auto;
`;

export const StyledSortAddWrapper = styled.div`
  display: flex;
  justify-content: space-bertween;
  width: 100%;
  gap: 10rem;
  padding: 20px 0;
`;

export const StyledSortingWrapper = styled.div`
  display: flex;
  width: 80%;
  gap: 2rem;
`;

export const StyledSearchWrapper = styled.div`
  display: flex;
  width: 500px;
`;

export const StyledDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;

  @media all and (${device.laptopL}) {
    width: 30%;
  }
`;

export const StyledCountWrapper = styled.div`
  width: 100%;
  height: 30%;
  font-size: 14px;
  line-height: 20px;
  color: rgba(31, 49, 51, 0.74);

  @media all and (${device.laptopL}) {
    font-size: 12px;
  }
`;
