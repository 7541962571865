import {
  SET_ANALYTICS_AGGREGATIONS_DATA,
  SET_ANALYTICS_GRAPH_DATA,
  SET_ANALYTICS_TABLE_DATA,
  VALIDATE_QUESTION_FROM_ANALYTICS,
} from 'store/action';
import {
  createESAggregationsData,
  createESAnalyticsTableData,
  getESChartData,
  getIfUpdatedQuestionPreviousValidation,
  updateAnalyticsAggregationsValidation,
  updateAnalyticsTableQuestionValidation,
} from '../helpers/bot/analytics';

export const analyticsReducer = (state, action) => {
  switch (action.type) {
    case SET_ANALYTICS_GRAPH_DATA: {
      const { data, startDate, endDate } = action.payload;

      const graphData = getESChartData(startDate, endDate, data);

      return {
        ...state,
        bot: {
          ...state.bot,
          analytics: {
            ...state.bot.analytics,
            graph: graphData,
          },
        },
      };
    }

    case SET_ANALYTICS_TABLE_DATA: {
      const { data } = action.payload;
      const tableData = createESAnalyticsTableData(data);

      return {
        ...state,
        bot: {
          ...state.bot,
          analytics: {
            ...state.bot.analytics,
            table: {
              ...state.bot.analytics.table,
              ...tableData,
            },
          },
        },
      };
    }

    case VALIDATE_QUESTION_FROM_ANALYTICS: {
      const { data } = action.payload;
      const allQuestions = state.bot.analytics.table.allQuestions;
      const currentAggregations = state.bot.analytics.aggregations;
      const tableData = updateAnalyticsTableQuestionValidation(
        allQuestions,
        data
      );
      const isQuestionHasValidation = getIfUpdatedQuestionPreviousValidation(
        allQuestions,
        data
      );
      const aggregations = updateAnalyticsAggregationsValidation(
        currentAggregations,
        data.validation,
        isQuestionHasValidation
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          analytics: {
            ...state.bot.analytics,
            table: {
              ...state.bot.analytics.table,
              ...tableData,
            },
            aggregations: {
              ...state.bot.analytics.aggregations,
              ...aggregations,
            },
          },
        },
      };
    }

    case SET_ANALYTICS_AGGREGATIONS_DATA: {
      const { data, filters } = action.payload;
      const { startDate, endDate, categories, integrations } = filters;
      const aggregations = createESAggregationsData(data);
      const hasCategoryFilter = Boolean(categories?.length);
      const hasIntegrationFilter = Boolean(integrations?.length);

      return {
        ...state,
        bot: {
          ...state.bot,
          analytics: {
            ...state.bot.analytics,
            aggregations: {
              ...aggregations,
              // category filter fetches the data from ES
              // hence if it has category filter
              // it will not display the other categories that were not selected
              categoryFilter: hasCategoryFilter
                ? // here we use the previous state without the filtered category
                  // if categories filter is not empty
                  // to display all categories from ES
                  state.bot.aggregations.categoriesFromES
                : aggregations.categoriesFromES,
              integrationFilter: hasIntegrationFilter
                ? // same with other filters
                  state.bot.aggregations.integrationsFromES
                : aggregations.integrationsFromES,
            },
            startDate,
            endDate,
            table: {
              ...state.bot.analytics?.table,
              mostAskedQuestions: aggregations.tableData.mostAskedQuestionData,
              intents: aggregations.tableData.intentsData,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};
