import Button from 'components/Button';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';
import Alert from 'components/Alert';

export const CategoryFieldWrapper = styled.div`
  display: flex;
  > * + * {
    margin-left: 5px;
  }
`;

export const StyledAddVariantButton = styled(Button)`
  width: fit-content;
  font-size: 13px;
  height: auto;
  margin: 10px 0;

  :hover,
  :focus,
  :active {
    background-color: ${cssVariables.gray1};
    color: ${cssVariables.primaryColor};
  }

  .anticon {
    margin-left: 5px;
    font-size: 18px;
    font-weight: ${cssVariables.font.bold};
  }
`;

export const StyledAnswerEditor = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;

  @media (max-width: 600px) {
    min-width: 100%;
  }
`;

export const StyledTitleWithTip = styled.div`
  display: flex;
  align-items: center;

  & h4 {
    margin: 0;
  }
`;

export const StyledTopText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -5px;

  & h4 {
    font-size: 14px;
    font-weight: ${cssVariables.font.bold};
  }

  & span {
    font-size: 12px;
  }
`;

export const StyledDisplayAnswerEditor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & span:first-child {
    font-weight: ${cssVariables.font.bold};
  }

  & .ant-radio-button-wrapper {
    color: ${cssVariables.primaryColor};
  }

  & .ant-radio-button-wrapper-checked {
    border-color: ${cssVariables.primaryTransparent} !important;
    background-color: ${cssVariables.primaryTransparent} !important;

    &::before {
      background-color: transparent;
    }
  }
`;

export const StyledSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
  & h4 {
    font-weight: ${cssVariables.font.bold};
  }
`;

export const StyledToggleLink = styled.div`
  & button {
    text-transform: capitalize;
    font-size: 12px;

    & .MuiButton-startIcon span {
      font-size: 14px;
    }
  }
`;

export const StyledEditorDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  & h4 {
    margin: 0;
  }
`;

export const StyledErrorMsg = styled(Alert)`
  margin: 0 auto;
`;

export const StyledQuickBtnToggleOption = styled.div`
  width: 100%;
`;

export const StyledQuickBtnToggleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledQuestionsWrapper = styled.div`
  margin: 5px;
  ul {
    padding: 0px;
  }
`;
