import { Spin, Tag } from 'antd';
import React from 'react';
import { DeleteFilled, EditTwoTone, ToolOutlined } from '@ant-design/icons';

import Title from 'components/Title';
import Button from 'components/Button';
import {
  StyledFlexRowRight,
  StyledSpacEvenlyFlexRow,
} from 'styles/GenericStyledComponents';
import useFunctions from './hooks';
import { stringLocaleCompare } from 'utils/stringManipulation';
import FunctionModal from './FunctionModal';
import CodeBlock from 'components/CodeBlock';
import { cssVariables } from 'styles/root';
import { StyledFunctionsTable } from './StyledComponents';
import ConfirmDelete from 'components/Modals/ConfirmDelete';

const Functions = () => {
  const {
    allAITools,
    isPageReady,
    isToolModalOpen,
    selectedTool,
    handleClickDelete,
    handleViewTool,
    handleAddTool,
    handleCloseToolModal,
    handleDeleteTool,
    handleCloseDeleteModal,
  } = useFunctions();

  const toolColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      width: '12%',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.name, b.name),
      },
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '10%',
      align: 'center',
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    },
    {
      title: 'Parameters',
      dataIndex: 'parametersInTable',
      align: 'center',
      width: '30%',
      render: code => {
        return <CodeBlock style={{ maxWidth: '100%' }} codeContent={code} />;
      },
      responsive: ['xxl', 'xl', 'lg', 'md'],
    },
    {
      title: 'Output',
      dataIndex: 'outputInTable',
      align: 'center',
      width: '30%',
      render: code => {
        return <CodeBlock style={{ maxWidth: '100%' }} codeContent={code} />;
      },
      responsive: ['xxl', 'xl', 'lg', 'md'],
    },
    {
      title: 'Version',
      dataIndex: 'version',
      align: 'center',
      width: '8%',
      render: version => {
        return (
          <Tag color={version === 'final' ? 'green' : 'red'}>{version}</Tag>
        );
      },
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm'],
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      width: '12%',
      render: (_, tool) => {
        return (
          <StyledSpacEvenlyFlexRow>
            <EditTwoTone onClick={() => handleViewTool(tool)} />
            <DeleteFilled
              onClick={() => handleClickDelete(tool)}
              style={{ color: cssVariables.red10 }}
            />
          </StyledSpacEvenlyFlexRow>
        );
      },
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    },
  ];

  return (
    <Spin tip="Just a moment" spinning={!isPageReady}>
      <Title text="Functions" type="secondary" />
      <StyledFlexRowRight>
        <Button
          value="Add"
          endIcon={<ToolOutlined />}
          onClick={handleAddTool}
        />
      </StyledFlexRowRight>
      <StyledFunctionsTable
        dataSource={allAITools}
        columns={toolColumns}
        style={{ marginTop: 20 }}
      />
      {isToolModalOpen ? (
        <FunctionModal onClose={handleCloseToolModal} />
      ) : null}
      {selectedTool.toDelete ? (
        <ConfirmDelete
          show
          modalTitle={'Delete AI Tool'}
          loading={selectedTool.loading}
          onOk={handleDeleteTool}
          onCancel={handleCloseDeleteModal}
          itemTobeDeleted={selectedTool.name}
        />
      ) : null}
    </Spin>
  );
};

export default Functions;
