import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ROUTES from 'constants/routes';
import BackButton from '.';
import { cssVariables } from 'styles/root';
import { HomeOutlined } from '@ant-design/icons';

const StyledBack = styled(BackButton)`
  text-transform: uppercase;
  color: ${cssVariables.gray0} !important;
  width: 88%;
  text-align: left;
  background: none !important;
  font-weight: ${cssVariables.font.bold};
  font-size: 14px;
  box-shadow: none;
  :hover {
    color: ${cssVariables.gray0} !important;
    background: none !important;
  }

  .anticon-arrow-left {
    font-size: 18px;
  }
`;

export const BackToBotsPage = props => {
  const history = useHistory();
  const { iconOnly, ...rest } = props;
  return (
    <StyledBack
      icon={<HomeOutlined />}
      value={!iconOnly ? 'Back to bots' : ''}
      onClick={() => history.push(ROUTES.BOTS_PAGE)}
      {...rest}
    />
  );
};

BackToBotsPage.propTypes = {
  iconOnly: PropTypes.bool,
};
