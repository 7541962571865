import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Typography } from 'antd';
import TextArea from 'components/TextArea';
import Input from 'components/Input';
import ToolTip from 'components/ToolTips/BaseToolTip';
import Select from 'components/Select';
import Button from 'components/Button';
import {
  StyledFormItem,
  StyledFormRow,
  StyledFormToolsContent,
} from './StyledComponents';
import { orderBy } from 'lodash';
import {
  DeleteOutlined,
  InfoCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { AUTH_OPTIONS } from 'constants/aiTools';
import { keyPropertyValidator } from 'store/reducers/helpers/bot/aiTools';
import {
  StyledFlexRowRight,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';

const { Text } = Typography;
const { Panel } = Collapse;

const Auth = props => {
  const { outputProps, setOutputProps, ...rest } = props;
  const orderAuth = value => orderBy(value, 'position', 'asc');
  const authProps = useMemo(
    () => outputProps.find(output => output.name === 'auth')?.request,
    [outputProps]
  );

  const displayField = authDetails => {
    const fieldConnected = authProps?.find(
      auth => auth.name === authDetails.display?.relatedTo && auth.value
    );
    const authType = authDetails.authType === authProps[0]?.value;

    if (!authType && authDetails.name !== 'type') {
      return false;
    }

    return !authDetails?.display?.onload && !fieldConnected ? false : true;
  };

  const getAuthPropertyDetails = value => {
    return authProps?.find(auth => auth.name === value);
  };

  const handleFieldChanges = (value, name) => {
    if (name === 'type') {
      setOutputProps(
        orderAuth([
          ...outputProps.filter(output => output.name !== 'auth'),
          {
            ...outputProps.find(output => output.name === 'auth'),
            type: value,
            request: orderAuth([
              ...authProps.filter(auth => auth.name !== name),
              {
                ...authProps.find(auth => auth.name === name),
                value,
              },
            ]),
          },
        ])
      );
    } else {
      setOutputProps(
        orderAuth([
          ...outputProps.filter(output => output.name !== 'auth'),
          {
            ...outputProps.find(output => output.name === 'auth'),
            request: orderAuth([
              ...authProps.filter(auth => auth.name !== name),
              {
                ...authProps.find(auth => auth.name === name),
                value,
              },
            ]),
          },
        ])
      );
    }
  };

  const handleChangeProperties = (name, value, field, propertyInfo, idx) => {
    const authSelected = authProps?.find(auth => auth.name === name);
    const propertyExist = authSelected?.properties?.find(
      auth => auth.id === idx
    );
    const currentProperties = authSelected?.properties?.filter(
      auth => auth.id !== idx
    );

    const propertyValue = propertyExist ? propertyExist?.value : propertyInfo;
    const propertyKey = propertyExist ? propertyExist?.name : propertyInfo;
    const propertyId = propertyExist ? idx : currentProperties?.length;
    const properties = orderBy(
      [
        ...currentProperties,
        {
          id: propertyId,
          name: field === 'key' ? value : propertyKey,
          value: field === 'key' ? propertyValue : value,
          validator: name === 'query' ? keyPropertyValidator : null,
        },
      ],
      'id',
      'asc'
    );

    setOutputProps(
      orderAuth([
        ...outputProps.filter(output => output.name !== 'auth'),
        {
          ...outputProps.find(output => output.name === 'auth'),
          request: orderAuth([
            ...authProps?.filter(auth => auth.name !== name),
            {
              ...authSelected,
              properties,
            },
          ]),
        },
      ])
    );
  };

  const addAuthProperties = name => {
    const filterField = authProps?.find(auth => auth.name === name);
    const properties = filterField?.properties
      ? [
          ...filterField?.properties,
          { id: filterField?.properties?.length, name: '', value: null },
        ]
      : [{ id: 0, name: '', value: null }];

    setOutputProps(
      orderAuth([
        ...outputProps.filter(output => output.name !== 'auth'),
        {
          ...outputProps.find(output => output.name === 'auth'),
          request: orderAuth([
            ...authProps?.filter(auth => auth.name !== name),
            {
              ...filterField,
              properties,
            },
          ]),
        },
      ])
    );
  };

  const deleteAuthProperty = (propertyName, idToRemove) => {
    const authSelected = authProps?.find(auth => auth.name === propertyName);
    const newProperty = authSelected.properties
      ?.filter(obj => obj.id !== idToRemove)
      .map((obj, id) => ({ ...obj, id }));

    setOutputProps(
      orderAuth([
        ...outputProps.filter(output => output.name !== 'auth'),
        {
          ...outputProps.find(output => output.name === 'auth'),
          request: orderAuth([
            ...authProps?.filter(auth => auth.name !== propertyName),
            {
              ...authSelected,
              properties: orderBy(newProperty, 'id', 'asc'),
            },
          ]),
        },
      ])
    );
  };

  const RenderCollapsePanel = () => (
    <Collapse
      accordion
      defaultActiveKey="type"
      style={{ width: '100%', marginTop: '10px' }}
      expandIconPosition="end"
    >
      {authProps &&
        authProps
          ?.filter(auth => auth.collapsibleField)
          ?.map(
            auth =>
              displayField(auth) && (
                <Panel
                  key={auth.name}
                  header={<Text strong>{auth.label}</Text>}
                >
                  <StyledFlexRowRight>
                    {auth?.action?.find(action => action === 'add') && (
                      <Button
                        value="Add Property"
                        startIcon={<PlusOutlined />}
                        variant="link"
                        size="small"
                        onClick={() => addAuthProperties(auth.name)}
                      />
                    )}
                  </StyledFlexRowRight>
                  {auth?.properties &&
                    auth?.properties?.map((property, idx) => (
                      <StyledSpaceBetweenFlexCenter key={idx}>
                        <StyledFormRow>
                          <Text strong>Key:</Text>
                          <Input
                            defaultValue={property.name}
                            value={property.name}
                            size="small"
                            placeholder={'Enter key...'}
                            onChange={evt =>
                              handleChangeProperties(
                                auth.name,
                                evt.target.value,
                                'key',
                                property.value,
                                property.id
                              )
                            }
                            style={{ marginRight: '5px' }}
                          />
                        </StyledFormRow>
                        <StyledFormRow>
                          <Text strong>Value:</Text>
                          <Input
                            defaultValue={property.value}
                            value={property.value}
                            size="small"
                            placeholder={'Enter value...'}
                            onChange={evt =>
                              handleChangeProperties(
                                auth.name,
                                evt.target.value,
                                'value',
                                property.name,
                                property.id
                              )
                            }
                            disabled={!property.name}
                            style={{ marginLeft: '5px' }}
                          />
                        </StyledFormRow>
                        {auth?.action?.find(action => action === 'delete') &&
                        idx ? (
                          <Button
                            icon={<DeleteOutlined />}
                            variant="link"
                            onClick={() =>
                              deleteAuthProperty(auth.name, property.id)
                            }
                          />
                        ) : null}
                      </StyledSpaceBetweenFlexCenter>
                    ))}
                </Panel>
              )
          )}
    </Collapse>
  );

  const RenderInputField = auth => (
    <StyledFormItem name={auth.name}>
      <Input
        size="small"
        label={
          <>
            <Text strong>{auth.label}</Text>
            {auth.tooltip && (
              <ToolTip title={auth.tooltip}>
                <InfoCircleTwoTone />
              </ToolTip>
            )}
          </>
        }
        value={getAuthPropertyDetails(auth.name)?.value}
        defaultValue={getAuthPropertyDetails(auth.name)?.value}
        onChange={evt => handleFieldChanges(evt.target.value, auth.name)}
        placeholder={auth.placeholder}
      />
    </StyledFormItem>
  );

  const RenderTextAreaField = auth => (
    <StyledFormItem name={auth.name}>
      <TextArea
        label={
          <>
            <Text strong>{auth.label}</Text>{' '}
            {auth.tooltip && (
              <ToolTip title={auth.tooltip}>
                <InfoCircleTwoTone />
              </ToolTip>
            )}
          </>
        }
        defaultValue={getAuthPropertyDetails(auth.name)?.value}
        onChange={evt => handleFieldChanges(evt.target.value, auth.name)}
        placeholder={auth.placeholder}
      />
    </StyledFormItem>
  );

  const RenderSelectField = auth => (
    <StyledFormItem name={auth.name}>
      <Select
        label={
          <>
            <Text strong>{auth.label}</Text>{' '}
            {auth.tooltip && (
              <ToolTip title={auth.tooltip}>
                <InfoCircleTwoTone />
              </ToolTip>
            )}
          </>
        }
        options={auth.options}
        onChange={evt => handleFieldChanges(evt, auth.name)}
        value={auth.value}
        defaultValue={auth.value}
      />
    </StyledFormItem>
  );

  return (
    <StyledFormToolsContent name="authForm" fullWidth={true}>
      {authProps &&
        authProps
          ?.filter(auth => !auth.collapsibleField)
          .map((auth, propIdx) => (
            <div key={propIdx}>
              {auth.field === 'input' &&
                displayField(auth) &&
                RenderInputField(auth)}
              {auth.field === 'textArea' &&
                displayField(auth) &&
                RenderTextAreaField(auth)}
              {auth.field === 'select' &&
                displayField(auth) &&
                RenderSelectField(auth)}
            </div>
          ))}
      {RenderCollapsePanel()}
    </StyledFormToolsContent>
  );
};

Auth.propTypes = {
  outputProps: PropTypes.array.isRequired,
  setOutputProps: PropTypes.func.isRequired,
};
export default Auth;
