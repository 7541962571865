import React from 'react';
import { Link } from 'react-router-dom';
import Input from 'components/Input';
import useSignUp from './hooks';
import Alert from 'components/Alert';
import Button from 'components/Button';
import ROUTES from 'constants/routes';
import CountDownRedirectModal from 'components/Modals/RedirectModal';
import {
  StyledForm,
  StyledSignInGoogleContainer,
  StyledSwitchLinkContainer,
} from './SignUp.style';
import { Spin } from 'antd';

const SignUp = () => {
  const {
    email,
    error,
    isActivated,
    loading,
    name,
    onChangeName,
    onChangeEmail,
    onChangePassword,
    onSubmit,
    signupSuccess,
    signInToGoogle,
    requestGoogleAccess,
    isExternalPage,
  } = useSignUp();

  return (
    <Spin spinning={loading || signInToGoogle} tip={'Just a moment...'}>
      <StyledForm onSubmit={onSubmit}>
        {error && <Alert type="error" message={error} />}
        {signupSuccess && !error && (
          <Alert type="success" message="Registration Success" />
        )}
        <Input
          full
          required
          label="Name"
          type="text"
          onChange={onChangeName}
          defaultValue={name}
        />
        <Input
          full
          required
          label="Email"
          type="email"
          onChange={onChangeEmail}
          defaultValue={email}
        />
        <Input
          full
          required
          label="Password"
          type="password"
          onChange={onChangePassword}
        />
        <Button
          type="submit"
          size="large"
          loading={loading}
          value="Sign Up"
          variant="cyan"
          style={{ borderRadius: 50 }}
        />
      </StyledForm>
      <StyledSwitchLinkContainer>
        <span className="have-account">{`Already have an account? `}</span>
        &nbsp;
        <Link
          to={isExternalPage ? ROUTES.EXTERNAL_LOGIN : ROUTES.LOGIN}
          className="sign-in-link"
        >
          {`Sign In`}
        </Link>
      </StyledSwitchLinkContainer>
      {isActivated ? (
        <CountDownRedirectModal
          page="registration"
          redirectURL={isExternalPage ? ROUTES.EXTERNAL_LOGIN : ROUTES.LOGIN}
          email={email}
        />
      ) : !isActivated && signupSuccess ? (
        <CountDownRedirectModal
          page="verification"
          redirectURL={ROUTES.VERIFY_EMAIL}
          email={email}
        />
      ) : null}
      <StyledSignInGoogleContainer onClick={requestGoogleAccess}>
        {'Sign up with Google'}
      </StyledSignInGoogleContainer>
    </Spin>
  );
};

export default SignUp;
