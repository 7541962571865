import { Tag } from 'antd';
import {
  FolderOutlined,
  FolderOpenFilled,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledTemplateCategory = styled.div`
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  background-color: ${props => (props.theme.isEven ? '#FFFFFF' : '#F9F9F9')};
  padding: 12px;
  border-radius: 5px;
`;
export const StyledTemplateCategoryHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: auto;
`;

export const StyledTemplateCategoryInfos = styled.div`
  display: flex;
  align-items: center;

  & span {
    font-size: 16px;
    font-weight: ${cssVariables.font.normal};
    margin: 0;
  }
  & p {
    font-size: 12px;
    color: #8c8f93;
    margin: 0;
  }
`;

export const StyledTemplateCategoryName = styled.div`
  margin-left: 20px;

  & .ant-tag {
    position: absolute;
    margin-left: 10px;
    border-radius: 8px;
    border: none;
    padding: 3px 7px 0 7px;

    & .anticon {
      font-size: 14px;
    }
  }
  & .ant-tag span {
    font-size: 10px;
    font-weight: ${cssVariables.font.normal};
    color: #00bd57;
  }
  & .ant-tag span:nth-child(2) {
    margin-left: 3px;
  }
`;

export const StyledFolderOpenFilled = styled(FolderOpenFilled)`
  color: #1667e7 !important;
  font-size: 28px !important;
`;

export const StyledFolderOutlined = styled(FolderOutlined)`
  color: #1667e7 !important;
  font-size: 28px !important;
`;

export const StyledTag = styled(Tag)`
  font-weight: ${cssVariables.font.normal} !important;
  font-size: 10px !important;
  background-color: #dafae8 !important;
  color: #dafae8 !important;
`;

export const StyledMinusOutlined = styled(MinusOutlined)`
  background-color: #ffe6e6;
  border-radius: 5px;
  font-size: 15px;
  padding: 8px;
  color: #eb5757;
`;

export const StyledPlusOutlined = styled(PlusOutlined)`
  background-color: #1667e7;
  border-radius: 5px;
  font-size: 15px;
  padding: 8px;
  color: #ffffff;
`;

export const StyledListContainer = styled.div`
  display: ${props => (props.theme.showAnswers ? 'block' : 'none')};
  background-color: #ffffff;
  border-radius: 5px;
  padding: 12px;
  margin-top: 12px;
`;

export const StyledListItem = styled.ul`
  list-style: none;
  color: #85888e;
`;
