import moment from 'moment';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import { maxTransactionCountSelector, planTypeSelector } from './plan';
import { parseBoolean } from 'utils/dataTypes';
import { getLocalTimeString } from 'utils/dates';

export const subscriptionSelector = state => state.subscription;
export const paymentErrorSelector = state => state.lastPaymentError || null;

export const subscriptionIdSelector = createSelector(
  subscriptionSelector,
  subscription => subscription?.id
);

export const isTrialSubscriptionSelector = createSelector(
  subscriptionSelector,
  subscription => subscription?.trial || false
);

export const trialDaysLeftSelector = createSelector(
  subscriptionSelector,
  subscription => {
    if (subscription?.trial_end) {
      const trialEnd = moment.unix(subscription.trial_end);
      const trialDaysLeft = trialEnd.diff(moment(), 'days');
      if (trialDaysLeft >= 0) {
        return trialDaysLeft;
      }
    }
    return null;
  }
);

export const planRenewDateSelector = createSelector(
  subscriptionSelector,
  subscription => {
    const currentPeriodEnd = subscription?.current_period_end;
    if (currentPeriodEnd) {
      return moment(currentPeriodEnd * 1000)
        .add(1, 'd')
        .format('MMMM D, YYYY');
    }
    return null;
  }
);

export const paymentMethodsSelector = createSelector(
  subscriptionSelector,
  subscription => subscription?.paymentMethods || []
);

export const showCancelledSubscriptionSuccessMessageSelector = createSelector(
  subscriptionSelector,
  subscription =>
    parseBoolean(subscription?.showCancelledSubscriptionSuccessMessage)
);

export const showUpgradedSubscriptionSuccessMessageSelector = createSelector(
  subscriptionSelector,
  subscription =>
    parseBoolean(subscription?.showUpgradedSubscriptionSuccessMessage)
);

export const showCancelSubscriptionModalSelector = createSelector(
  subscriptionSelector,
  subscription => parseBoolean(subscription?.showCancelSubscriptionModal)
);

export const showSwitchPlanSubscriptionModalSelector = createSelector(
  subscriptionSelector,
  subscription => parseBoolean(subscription?.showSwitchPlanSubscriptionModal)
);

export const switchPlanSelector = createSelector(
  subscriptionSelector,
  subscription => subscription?.switchPlan
);

export const showDowngradeSubscriptionModalSelector = createSelector(
  subscriptionSelector,
  subscription => parseBoolean(subscription?.showDowngradeSubscriptionModal)
);

export const customerSelector = createSelector(
  subscriptionSelector,
  subscription => subscription?.customer || {}
);

export const isProcessingSelector = createSelector(
  subscriptionSelector,
  subscription => subscription?.isProcessing || false
);

export const isCancelledSubscriptionSelector = createSelector(
  subscriptionSelector,
  subscription => parseBoolean(subscription?.isCancelled) || false
);

export const currentTransactionCountSelector = createSelector(
  subscriptionSelector,
  subscription => subscription?.transaction?.count || 0
);

export const transactionPercentageSelector = createSelector(
  currentTransactionCountSelector,
  maxTransactionCountSelector,
  (currentTransactionCount, maxTransactionCount) => {
    return Math.round((currentTransactionCount / maxTransactionCount) * 100);
  }
);

export const defaultPaymentMethodSelector = createSelector(
  paymentMethodsSelector,
  customerSelector,
  (paymentMethods, customer) => {
    const defaultPaymentMethod =
      paymentMethods.find(
        pm => pm.id === customer?.invoice_settings?.default_payment_method
      ) || paymentMethods[0];

    return defaultPaymentMethod || {};
  }
);

export const isPaymentInfoEmptySelector = createSelector(
  planTypeSelector,
  paymentMethodsSelector,
  defaultPaymentMethodSelector,
  (planType, paymentMethods, defaultPaymentMethod) => {
    return planType === 'free' ||
      !paymentMethods?.length ||
      isEmpty(defaultPaymentMethod)
      ? true
      : false;
  }
);

export const billingDetailsSelector = createSelector(
  defaultPaymentMethodSelector,
  defaultPaymentMethod => defaultPaymentMethod?.billingDetails
);
