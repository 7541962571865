import { useState, useContext } from 'react';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import {
  UPDATE_ANSWER_EDITOR_LINKED_QUESTIONS,
  UPDATE_SIMILAR_QUESTIONS_AFTER_HARD_LINK,
} from 'store/action';
import { message } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { similarQuestionsModalSelector } from 'selectors/bot/ui';
import useSelector from 'store/useSelector';

const useQuestion = () => {
  const [state, dispatch] = useContext(Context);
  const similarQuestionsModal = useSelector(similarQuestionsModalSelector);
  const {
    sentinel,
    token,
    bot: { jid },
  } = state;
  const [viewAnswer, setViewAnswer] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newQuestion, setNewQuestion] = useState(false);

  const handleDeleteHardLink = async questionId => {
    try {
      await apiService.deleteQuestionHardLink(questionId, sentinel, token);
      dispatch({
        type: UPDATE_SIMILAR_QUESTIONS_AFTER_HARD_LINK,
        payload: {
          questionId,
        },
      });
    } catch (error) {
      message.error(error?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleDeleteQuestionThenHardLinkAnswer = async (question, answerId) => {
    try {
      // question object with `jid` => the question item
      // from the list of similar questions

      // question from `itemToChange` => the current question
      // the user ask from the chat
      const { jid: questionId } = question;
      const { itemToChange } = similarQuestionsModal;
      await apiService.deleteQuestionThenHardLinkAnswer(
        // questionId to delete the question node
        questionId,
        // new question (text/string) to be hard linked to the answer
        // connected to the `questionId`
        itemToChange.question,
        answerId,
        jid,
        sentinel,
        token
      );

      const res = await apiService.getSimilarQuestions(
        itemToChange.question,
        sentinel,
        jid,
        token
      );
      dispatch({
        type: UPDATE_SIMILAR_QUESTIONS_AFTER_HARD_LINK,
        payload: {
          data: res.data.report[0],
          itemToChange: {
            question: itemToChange.question,
            answer: itemToChange.answer,
            idx: itemToChange?.idx || 0,
          },
        },
      });
      return message.success(
        'Successfully hard link the current question to new answer'
      );
    } catch (error) {
      message.error(error?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleSaveNewQuestion = async (questionId, answerId) => {
    try {
      const res = await apiService.editQuestion(
        newQuestion,
        answerId,
        questionId,
        sentinel,
        token
      );
      dispatch({
        type: UPDATE_ANSWER_EDITOR_LINKED_QUESTIONS,
        payload: res.data.report,
      });
    } catch (error) {}
  };

  return {
    editing,
    handleDeleteHardLink,
    handleDeleteQuestionThenHardLinkAnswer,
    handleSaveNewQuestion,
    setEditing,
    setNewQuestion,
    setViewAnswer,
    viewAnswer,
  };
};

export default useQuestion;
