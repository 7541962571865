import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Spin } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { omit } from 'lodash';
import {
  StyledCodeButton,
  StyledActionBtns,
  StyledDivider,
  StyledEncircledNumber,
  StyledSnippets,
  StyledIntegrationSteps,
  StyledIntegrationInstruction,
  StyledCollapsiblePanel,
  StyledCollapsiblePanelHeader,
  StyledPanelSubHeader,
} from './FacebookMessenger.styles';
import Input from 'components/Input';
import Button from 'components/Button';
import Modal from 'components/Modals/GenericModal';
import Select from 'components/Select';
import useFacebookMessenger from './hooks';
import { SET_INTEGRATION_ACTIVE_PANEL } from 'store/action';
import { INTEGRATION_PLATFORMS } from 'constants/integrationPlatforms';
import TextArea from 'components/TextArea';
import CodeBlock from 'components/CodeBlock';
import { MESSENGER_API_VERSION } from 'constants/integration';

const isDevelopment = process.env.NODE_ENV === 'development';
const testToken = process.env.REACT_APP_MESSENGER_ACCESS_TOKEN;

const FacebookMessenger = ({ isPageReady }) => {
  const {
    activePanel,
    confirmEditModal,
    currentIntegrationID,
    dispatch,
    integrationData,
    loading,
    handleChangeFields,
    handlePanelChange,
    handleSaveChannelInfo,
    messengerAPIs,
    setConfirmEditModal,
    setEnableEdit,
  } = useFacebookMessenger();

  const ConfirmEditModal = (
    <Modal
      title="Edit Channel Information"
      visible={confirmEditModal.visible}
      closable={false}
      onOk={() => {
        setEnableEdit(true);
        setConfirmEditModal({ ...confirmEditModal, visible: false });
        dispatch({
          type: SET_INTEGRATION_ACTIVE_PANEL,
          payload: { panel: confirmEditModal.panel },
        });
      }}
      okText={'Yes, Edit channel information'}
      onCancel={() => setConfirmEditModal(false)}
    >
      <p>
        {`Making a change in the channel information can result into a change in
        the code. Are you sure you want to edit the channel information?`}
      </p>
    </Modal>
  );

  const getButtonValue = useMemo(() => {
    if (!!currentIntegrationID) {
      return 'Update';
    } else if (loading) {
      return 'Processing';
    }
    return 'Save';
  }, [currentIntegrationID, loading]);

  return (
    <Spin spinning={!isPageReady || loading} tip="Just a moment...">
      <div>
        <Collapse
          activeKey={`fb-integration-panel-${activePanel}`}
          accordion
          ghost
          onChange={e => handlePanelChange(e)}
        >
          <StyledCollapsiblePanel
            showArrow={false}
            key={'fb-integration-panel-1'}
            header={
              <StyledCollapsiblePanelHeader>
                <StyledEncircledNumber>1</StyledEncircledNumber>
                <span>Channel information</span>
              </StyledCollapsiblePanelHeader>
            }
          >
            <StyledIntegrationSteps>
              <StyledSnippets>
                <h1>{'Before you start 💡'}</h1>
                <ol>
                  <li>{`On your Facebook Apps page(https://developers.facebook.com/apps). Choose the page where you'd want to add the bot and then click.`}</li>
                  <li>{`Navigate to "Messenger" from the sidebar then click "Settings".`}</li>
                  <li>{`Your current URL should be https://developers.facebook.com/apps/{page-or-app-id}/messenger/settings/`}</li>
                  <li>{`Find the "Access Tokens" section and generate your token.`}</li>
                  <li>{`Enter a name for your integration on the Channel Name input field below.`}</li>
                  <li>{`Then copy and paste the generated "Access Token" from the Step 2 panel, to the "Verify Token" field of the "Edit Callback URL" modal of the Facebook Developers.`}</li>
                </ol>
              </StyledSnippets>
              <form onSubmit={handleSaveChannelInfo}>
                <Input
                  disabled={!!currentIntegrationID}
                  value={integrationData.identifier}
                  label="Channel Name"
                  placeholder="My Facebook Messenger integration name"
                  onChange={e =>
                    handleChangeFields('identifier', e.target.value)
                  }
                  required
                />
                <Select
                  disabled
                  defaultValue={integrationData.type}
                  onChange={e => handleChangeFields('type', e)}
                  label="Integration Platform"
                  value={INTEGRATION_PLATFORMS['fb-messenger']}
                />
                <TextArea
                  placeholder="Enter access token..."
                  label={
                    isDevelopment ? 'Access Token (test token)' : 'Access Token'
                  }
                  value={integrationData.settings?.fb_access_token}
                  defaultValue={isDevelopment ? testToken : null}
                  required
                  onChange={e =>
                    handleChangeFields('accessToken', e.target.value)
                  }
                />
                <Select
                  options={Object.keys(omit(messengerAPIs, 'latest'))}
                  label="API Version"
                  value={integrationData.settings?.fb_version}
                  required
                  defaultValue={MESSENGER_API_VERSION}
                  placeholder="Select API version"
                  onChange={e => handleChangeFields('messengerAPIVersion', e)}
                />
                <StyledActionBtns>
                  <Button
                    value={getButtonValue}
                    type="submit"
                    variant="primary"
                    loading={loading}
                  />
                  <Button
                    variant="secondary"
                    value="Reset"
                    type="button"
                    onClick={() => handleChangeFields('clear')}
                  />
                </StyledActionBtns>
              </form>
            </StyledIntegrationSteps>
          </StyledCollapsiblePanel>
          <StyledDivider />

          <StyledCollapsiblePanel
            showArrow={false}
            key={'fb-integration-panel-2'}
            header={
              <StyledCollapsiblePanelHeader>
                <StyledEncircledNumber>2</StyledEncircledNumber>
                <span>{'Save and Integrate'}</span>
              </StyledCollapsiblePanelHeader>
            }
          >
            <StyledIntegrationSteps>
              <StyledPanelSubHeader>
                <span>{`You're almost there, next step is to verify your Callback URL.`}</span>
                <ol>
                  <li>
                    {
                      'Navigate to the "Webhooks" section (just below the Access Tokens section)'
                    }
                  </li>
                  <li>{'Click "Edit callback URL'}</li>
                </ol>
              </StyledPanelSubHeader>
              {!!integrationData.code1 && (
                <>
                  <StyledSnippets>
                    <StyledIntegrationInstruction>
                      {'Copy and paste this webhook URL.'}
                      <CopyToClipboard text={integrationData.code1}>
                        <StyledCodeButton
                          className="secondary"
                          value="Copy code"
                        />
                      </CopyToClipboard>
                    </StyledIntegrationInstruction>
                    <CodeBlock codeContent={integrationData.code1} />

                    {!!integrationData.code2 && (
                      <>
                        <StyledIntegrationInstruction>
                          {`Copy and paste this Access Token to the "Verify Token" field, then click the "Verify and save" button.`}
                          <CopyToClipboard text={integrationData.code2}>
                            <StyledCodeButton
                              className="secondary"
                              value="Copy code"
                            />
                          </CopyToClipboard>
                        </StyledIntegrationInstruction>
                        <CodeBlock codeContent={integrationData.code2} />
                      </>
                    )}
                  </StyledSnippets>
                </>
              )}
            </StyledIntegrationSteps>
            {ConfirmEditModal}
          </StyledCollapsiblePanel>
        </Collapse>
      </div>
    </Spin>
  );
};

FacebookMessenger.propTypes = {
  isPageReady: PropTypes.bool,
};

export default FacebookMessenger;
