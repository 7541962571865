import React, { useMemo } from 'react';
import { Row, Col, Button, Progress, Popover, Typography } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import {
  StyledWrapper,
  StyledPageTitle,
  StyledContent,
  StyledCard,
  ProgressSection,
  FlagName,
} from './OnboardingFlags.style';
import useOnboardingFlags from './hooks';
import onboardingFlags from 'constants/onboardingFlags.json';

const { Title } = Typography;

const paidUsersExclusiveOnboarding = ['TextIngest', 'ImportAnswerButton'];

const OnboardingFlags = () => {
  const { onboarding_flag, resetFlag, resetAllFlags, isFreeUser } =
    useOnboardingFlags();

  const handleReset = value => {
    resetFlag(value);
  };

  const handleResetAll = () => {
    resetAllFlags();
  };

  const percent = useMemo(
    () => Math.round((onboarding_flag.length / onboardingFlags.length) * 100),
    [onboarding_flag.length]
  );

  return (
    <StyledWrapper>
      <StyledPageTitle>
        <Title level={3}>Onboarding Flags</Title>
      </StyledPageTitle>
      <ProgressSection>
        <Progress percent={percent} />
        <Button onClick={handleResetAll}>Reset All</Button>
      </ProgressSection>
      <StyledContent>
        <Row gutter={24}>
          {onboardingFlags.map(flag => (
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
              key={flag.key}
            >
              <Popover content={flag.hoverContent}>
                <StyledCard
                  isGray={
                    isFreeUser &&
                    paidUsersExclusiveOnboarding.includes(flag.key)
                  }
                  hoverable={
                    !(
                      isFreeUser &&
                      paidUsersExclusiveOnboarding.includes(flag.key)
                    )
                  }
                >
                  {onboarding_flag.includes(flag.key) ? (
                    <Row>
                      <Col span={12} offset={6}>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={12} offset={6}>
                        {' '}
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                      </Col>
                    </Row>
                  )}

                  <FlagName>{flag.name}</FlagName>

                  {onboarding_flag.includes(flag.key) &&
                  flag.key !== 'ViewBotButton' ? (
                    <Row>
                      <Col
                        xs={{ span: 2 }}
                        md={{ span: 2 }}
                        lg={{ span: 8, offset: 8 }}
                        xl={{ span: 8, offset: 8 }}
                      />
                      <Col
                        xs={{ span: 22 }}
                        md={{ span: 24 }}
                        lg={{ span: 8, offset: 8 }}
                        xl={{ span: 8, offset: 8 }}
                      >
                        <Button onClick={() => handleReset(flag.key)}>
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                </StyledCard>
              </Popover>
            </Col>
          ))}
        </Row>
      </StyledContent>
    </StyledWrapper>
  );
};

export default OnboardingFlags;
