import { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';

import {
  switchPlanSelector,
  showSwitchPlanSubscriptionModalSelector,
  showUpgradedSubscriptionSuccessMessageSelector,
} from 'selectors/subscription';
import { getTokenSelector, isExternalPageSelector } from 'selectors/user';
import {
  CLOSE_SWITCH_PLAN_SUBSCRIPTION_MODAL,
  SET_USER_SUBSCRIPTION,
} from 'store/action';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import { stripeAPIService } from 'services/stripeAPI.service';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import ROUTES from 'constants/routes';
import {
  CANCELED_CHECKOUT_URL_PARAM,
  DOWNGRADE_SUCCESS_MESSAGE,
  UPGRADE_SUCCESS_MESSAGE,
} from 'constants/plan';

const PAGE_BASE_URL = process.env.REACT_APP_PAGE_BASE_URL;

const useSwitchPlanSubscriptionModal = () => {
  const [state, dispatch] = useContext(Context);
  const { pathname } = useLocation();
  const showUpgradedSubscriptionSuccessMessage = useSelector(
    showUpgradedSubscriptionSuccessMessageSelector
  );
  const showSwitchPlanSubscriptionModal = useSelector(
    showSwitchPlanSubscriptionModalSelector
  );
  const switchPlan = useSelector(switchPlanSelector);
  const token = useSelector(getTokenSelector);
  const isExternalPage = useSelector(isExternalPageSelector);

  const { graph, sentinel } = state;
  const [loading, setLoading] = useState(false);

  const SUCCESS_URL = `${window.location.origin}${ROUTES.SUBSCRIPTION}`;
  const CANCEL_URL = `${window.location.origin}${ROUTES.SUBSCRIPTION}${CANCELED_CHECKOUT_URL_PARAM}`;

  const handleCloseModal = () => {
    dispatch({
      type: CLOSE_SWITCH_PLAN_SUBSCRIPTION_MODAL,
    });
  };

  const handleUpgradeSubscription = async () => {
    setLoading(true);
    try {
      if (isExternalPage) {
        window.open(`${PAGE_BASE_URL}profile/subscription`, '_black');
      } else {
        const RETURN_PATH = `${window.location.origin}${pathname}${CANCELED_CHECKOUT_URL_PARAM}`;
        const res = await stripeAPIService.switchPlan(
          switchPlan.planType,
          SUCCESS_URL,
          RETURN_PATH || CANCEL_URL,
          graph,
          sentinel,
          token
        );

        const checkoutURl = res.data?.report[0]?.url;
        if (checkoutURl) {
          window.location.href = checkoutURl;
        } else if (res.data?.success) {
          await dispatch({
            type: SET_USER_SUBSCRIPTION,
            payload: res.data.report[0],
          });
          message.success(
            switchPlan.action === 'upgrade'
              ? UPGRADE_SUCCESS_MESSAGE
              : DOWNGRADE_SUCCESS_MESSAGE
          );
        } else {
          throw new Error(GET_DATA_ERROR);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return message.error(error?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  return {
    handleCloseModal,
    handleUpgradeSubscription,
    loading,
    showSwitchPlanSubscriptionModal,
    showUpgradedSubscriptionSuccessMessage,
    switchPlan,
  };
};

export default useSwitchPlanSubscriptionModal;
