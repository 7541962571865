import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { cssVariables } from 'styles/root';
import { useState, useEffect } from 'react';
import Modal from 'components/Modals/GenericModal';
import ROUTES from 'constants/routes';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
import useSelector from 'store/useSelector';
import { isExternalPageSelector } from 'selectors/user';

const CountDownRedirectModal = ({ page, redirectURL, email }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(true);
  const [secondsToGo, setSecondsToGo] = useState(5);
  const [timerStopped, setTimerStopped] = useState(false);
  const isExternalPage = useSelector(isExternalPageSelector);

  const redirectToVerification = email => {
    history.push({
      pathname: redirectURL,
      state: { success: true, email },
    });
  };

  useEffect(() => {
    let timer;
    setTimeout(() => {
      timer = setInterval(() => {
        setSecondsToGo(c => c - 1);
      }, 1000);

      if (secondsToGo === 1) {
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (secondsToGo < 1 && !timerStopped) {
      setShowModal(false);
      return redirectToVerification(email);
    }
  }, [secondsToGo, timerStopped]);

  const handleClose = () => {
    setTimerStopped(true);
    return setShowModal(false);
  };

  return (
    <Modal
      destroyOnClose
      onCancel={handleClose}
      visible={showModal}
      title={
        <StyledFlexRowLeft>
          <CheckCircleTwoTone twoToneColor={cssVariables.success} />{' '}
          <>
            {` ${
              redirectURL === ROUTES.VERIFY_EMAIL
                ? 'Registration'
                : page[0].toUpperCase() + page.substr(1)
            } success!`}
          </>
        </StyledFlexRowLeft>
      }
      onOk={() => redirectToVerification(email)}
    >
      {`You will be redirect to ${
        redirectURL === isExternalPage
          ? ROUTES.EXTERNAL_LOGIN
          : ROUTES.LOGIN
          ? 'login'
          : page
      } page in ${secondsToGo} second.`}
    </Modal>
  );
};

CountDownRedirectModal.propTypes = {
  page: PropTypes.string.isRequired,
  redirectURL: PropTypes.string.isRequired,
  email: PropTypes.string,
};

export default CountDownRedirectModal;
