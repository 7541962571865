import { createSelector } from 'reselect';

export const featureFlagsSelector = state => state.featureFlags;

export const isBotModeEnabledSelector = createSelector(
  featureFlagsSelector,
  featureFlags => Boolean(featureFlags?.botMode)
);

export const isBotSimulatorEnabledSelector = createSelector(
  featureFlagsSelector,
  featureFlags => {
    return Boolean(featureFlags?.botSimulator);
  }
);
