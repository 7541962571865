import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  InfoCircleTwoTone,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { orderBy } from 'lodash';

import { StyledFormItem, StyledFormRow } from './StyledComponents';
import {
  StyledFlexRowRight,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';

import Button from 'components/Button';
import Select from 'components/Select';
import { RESPONSE_OPTIONS, SELECTABLE_FIELDS } from 'constants/aiTools';
import { Typography, Tabs } from 'antd';
import Input from 'components/Input';
import ToolTip from 'components/ToolTips/BaseToolTip';
import TextArea from 'components/TextArea';
import { keyPropertyValidator } from 'store/reducers/helpers/bot/aiTools';
import TabPane from 'antd/lib/tabs/TabPane';
import Parameters from './Parameters';
import Auth from './Auth';

const { Text } = Typography;
const Output = props => {
  const {
    outputProps,
    requiredFields,
    getOutputPropertyDetails,
    setOutputProps,
    advanceSettingOption,
    showAdvancedSettings,
    setParamsProps,
    paramProps,
    objectifiedParamProps,
    addProps,
    setRequiredFields,
    ...rest
  } = props;
  const orderOutput = value => orderBy(value, 'position', 'asc');
  const [activeTab, setActiveTab] = useState('parameters');
  const isUrlValid = useMemo(() => {
    try {
      new URL(outputProps?.find(output => output.name === 'url')?.value);
      return true;
    } catch (error) {
      return false;
    }
  }, outputProps);

  const displayField = outputDetails => {
    const fieldConnected = outputProps.find(
      output => output.name === outputDetails.display?.relatedTo && output.value
    );

    return !outputDetails?.display?.onload && !fieldConnected ? false : true;
  };

  const deleteProperty = (propertyName, idToRemove) => {
    const outputSelected = outputProps?.find(
      output => output.name === propertyName
    );
    const newProperty = outputSelected.properties
      ?.filter(obj => obj.id !== idToRemove)
      .map((obj, id) => ({ ...obj, id }));

    const newOutput = [
      ...outputProps?.filter(output => output.name !== propertyName),
      {
        ...outputSelected,
        properties: orderBy(newProperty, 'id', 'asc'),
      },
    ];

    setOutputProps(orderOutput(newOutput));
  };

  const handleChangeProperties = (name, value, field, propertyInfo, idx) => {
    const outputSelected = outputProps?.find(output => output.name === name);
    const propertyExist = outputSelected?.properties?.find(
      output => output.id === idx
    );
    const currentProperties = outputSelected?.properties?.filter(
      output => output.id !== idx
    );

    const propertyValue = propertyExist ? propertyExist?.value : propertyInfo;
    const propertyKey = propertyExist ? propertyExist?.name : propertyInfo;
    const propertyId = propertyExist ? idx : currentProperties?.length;
    const properties = orderBy(
      [
        ...currentProperties,
        {
          id: propertyId,
          name: field === 'key' ? value : propertyKey,
          value: field === 'key' ? propertyValue : value,
          validator: name === 'query' ? keyPropertyValidator : null,
        },
      ],
      'id',
      'asc'
    );

    if (name === 'path') {
      const urlProperty = outputProps?.find(output => output.name === 'url');
      const newUrl = urlProperty.pathValue?.replace(`{${propertyKey}}`, value);

      const renderedOutput = [
        ...outputProps?.filter(
          output => output.name !== name && output.name !== 'url'
        ),
        {
          ...urlProperty,
          value: newUrl,
        },
        {
          ...outputSelected,
          properties,
        },
      ];

      setOutputProps(orderOutput(renderedOutput));
    } else {
      const renderedOutput = [
        ...outputProps?.filter(output => output.name !== name),
        {
          ...outputSelected,
          properties,
        },
      ];

      setOutputProps(orderOutput(renderedOutput));
    }
  };

  const handleOutputPropsChanges = (value, name) => {
    setOutputProps(currentOutputProps => {
      const selectedOutput = currentOutputProps?.find?.(
        output => output.name === name
      );

      if (selectedOutput) {
        const selectedOutputProperties = {
          ...selectedOutput,
          name,
          value,
          pathValue: value,
          properties: !showProperties(value) ? [] : selectedOutput?.properties,
        };

        const isManualPathVariable = currentOutputProps.find(
          output => output.name === 'path' && output.value === 'manual'
        );
        const isOpenAiPathVariable = currentOutputProps.find(
          output => output.name === 'path' && output.value === '{{$}}'
        );

        if (
          (name === 'url' && isManualPathVariable) ||
          (name === 'path' && value === 'manual')
        ) {
          const matches =
            name === 'url'
              ? value?.match(/\{([^}]+)\}/g)
              : currentOutputProps
                  ?.find(output => output.name === 'url')
                  .value.match(/\{([^}]+)\}/g);

          const newPathVariableProperties = matches
            ? matches?.reduce((acc, property) => {
                return [
                  ...acc,
                  {
                    id: acc.length,
                    name: property.substring(1, property.length - 1),
                    value: '',
                  },
                ];
              }, [])
            : [{ id: 0, name: '', value: null }];

          const newProperties = {
            ...currentOutputProps?.find(output => output.name === 'path'),
            value: 'manual',
            properties: newPathVariableProperties,
          };

          const outputs =
            name === 'url'
              ? [
                  ...currentOutputProps?.filter?.(
                    output => !['url', 'path'].includes(output.name)
                  ),
                  newProperties,
                  selectedOutputProperties,
                ]
              : [
                  ...currentOutputProps?.filter?.(
                    output => output.name !== 'path'
                  ),
                  newProperties,
                ];

          return orderOutput(outputs);
        } else if (
          (name === 'url' && isOpenAiPathVariable) ||
          (name === 'path' && value === '{{$}}')
        ) {
          return renderPathVariableOpenAiUrl(
            currentOutputProps,
            name,
            selectedOutputProperties,
            value
          );
        } else {
          return orderOutput([
            ...currentOutputProps?.filter?.(output => output.name !== name),
            selectedOutputProperties,
          ]);
        }
      } else {
        return orderOutput(currentOutputProps);
      }
    });
  };

  const addOutputProperties = name => {
    const filterField = outputProps?.find(output => output.name === name);
    const properties = filterField?.properties
      ? [
          ...filterField?.properties,
          { id: filterField?.properties?.length, name: '', value: '' },
        ]
      : [{ id: 0, name: '', value: '' }];

    const output = [
      ...outputProps?.filter(output => output.name !== name),
      {
        ...filterField,
        properties,
      },
    ];
    setOutputProps(orderOutput(output));
  };

  const renderPathVariableOpenAiUrl = (
    currentOutputProps,
    name,
    selectedOutputProperies,
    value
  ) => {
    try {
      if (isUrlValid) {
        const selectedValue =
          name === 'url'
            ? value
            : currentOutputProps?.find(output => output.name === 'url').value;
        const url = new URL(selectedValue);
        const pathVariableValue =
          name === 'url'
            ? currentOutputProps?.find(output => output.name === 'path').value
            : value;

        const openAiURL =
          requiredFields && pathVariableValue === '{{$}}'
            ? requiredFields?.reduce((acc, requiredField) => {
                return `${acc}/{${requiredField}}`;
              }, url.origin)
            : selectedValue;

        if (name === 'url') {
          return orderOutput([
            ...currentOutputProps?.filter?.(output => output.name !== name),
            {
              ...selectedOutputProperies,
              value: openAiURL,
              pathValue: openAiURL,
            },
          ]);
        } else {
          return orderOutput([
            ...currentOutputProps?.filter?.(
              output => output.name !== name && output.name !== 'url'
            ),
            {
              ...currentOutputProps?.find(output => output.name === 'url'),
              value: openAiURL,
              pathValue: openAiURL,
            },
            selectedOutputProperies,
          ]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderAdvanceSettingCondition = output => {
    return advanceSettingOption
      ? !output.advanceSettingOption ||
          (output.advanceSettingOption && !showAdvancedSettings)
      : output.advanceSettingOption;
  };

  const requiredFieldsOptions = useMemo(() => {
    return objectifiedParamProps
      ? Object.keys(objectifiedParamProps) || []
      : [];
  }, [objectifiedParamProps]);

  const handleRequiredFieldChanges = value => {
    setRequiredFields(value);
    const pathVariableProperty = getOutputPropertyDetails('path');
    if (pathVariableProperty.value === '{{$}}' && isUrlValid) {
      setOutputProps(currentOutput => {
        const urlOutput = currentOutput?.find?.(
          output => output.name === 'url'
        );
        const url = new URL(urlOutput.value);
        const openAiURL = requiredFields
          ? requiredFields?.reduce((acc, requiredField) => {
              return `${acc}/{${requiredField}}`;
            }, url.origin)
          : urlOutput.value;

        return orderOutput([
          ...currentOutput.filter(output => output.name !== 'url'),
          {
            ...urlOutput,
            value: openAiURL,
          },
        ]);
      });
    }
  };

  const showProperties = method => {
    return (
      RESPONSE_OPTIONS.find(methodTypes => methodTypes.value === method)
        ?.showProperties || false
    );
  };

  const TabAdvancedSettings = () => {
    return (
      showAdvancedSettings && (
        <Tabs activeKey={activeTab} onTabClick={key => setActiveTab(key)}>
          <TabPane tab={<Text strong>Parameters</Text>} key="parameters">
            <Parameters
              setParamsProps={setParamsProps}
              paramProps={paramProps}
              objectifiedParamProps={objectifiedParamProps}
              addProps={addProps}
            />

            {!!requiredFieldsOptions && (
              <StyledFormRow>
                {' '}
                <Text strong>Required Fields</Text>
                <Select
                  size="medium"
                  placeholder="Select to add required fields"
                  options={requiredFieldsOptions}
                  mode="multiple"
                  onChange={value => handleRequiredFieldChanges(value)}
                  value={requiredFields}
                />
              </StyledFormRow>
            )}
          </TabPane>
          {outputProps?.map(output =>
            ['type', 'auth'].includes(output.name) ||
            output.hide ||
            !output.advanceSettingOption ||
            (!showAdvancedSettings &&
              !SELECTABLE_FIELDS.includes(output.name) &&
              !displayField(output)) ? null : (
              <TabPane
                tab={<Text strong>{output.label}</Text>}
                key={output.name}
              >
                <StyledFlexRowRight>
                  {output.name === 'header' &&
                  getOutputPropertyDetails(output.name)?.properties?.length ===
                    0 ? (
                    <Button
                      value="Add Property"
                      startIcon={<PlusOutlined />}
                      variant="link"
                      size="small"
                      onClick={() => addOutputProperties(output.name)}
                    />
                  ) : (
                    showProperties(output?.value) &&
                    output?.action?.find(action => action === 'add') && (
                      <Button
                        value="Add Property"
                        startIcon={<PlusOutlined />}
                        variant="link"
                        size="small"
                        onClick={() => addOutputProperties(output.name)}
                      />
                    )
                  )}
                </StyledFlexRowRight>
                <StyledFormItem
                  name={`${output.name}-method`}
                  rules={[
                    output.validator
                      ? {
                          validator: (_, value) =>
                            output.validator(
                              _,
                              value,
                              output.name,
                              requiredFields,
                              outputProps
                            ),
                        }
                      : {
                          required: output.required || false,
                          message: output.placeholder,
                        },
                  ]}
                >
                  {output?.options && (
                    <>
                      <Text strong>{output?.optionLabel}</Text>{' '}
                      <Select
                        options={output.options}
                        onChange={evt =>
                          handleOutputPropsChanges(evt, output.name)
                        }
                        value={output.value}
                      />
                    </>
                  )}
                </StyledFormItem>
                {output?.options &&
                  showProperties(output?.value) &&
                  output?.properties?.map((property, idx) => (
                    <StyledSpaceBetweenFlexCenter key={'property' + idx}>
                      <StyledFormItem
                        name="name"
                        rules={[
                          property?.validator
                            ? {
                                validator: (_, value) =>
                                  property.validator(
                                    _,
                                    value,
                                    output.name,
                                    requiredFields
                                  ),
                              }
                            : null,
                        ]}
                      >
                        {console.log(property.name)}
                        <Input
                          label={<Text strong>Key:</Text>}
                          defaultValue={property.name}
                          value={property.name}
                          size="small"
                          placeholder={'Enter key...'}
                          onChange={evt =>
                            handleChangeProperties(
                              output.name,
                              evt.target.value,
                              'key',
                              property.value,
                              property.id
                            )
                          }
                          style={{ marginRight: '5px' }}
                          disabled={output.disableNameProperty}
                        />
                      </StyledFormItem>
                      <StyledFormItem
                        name="value"
                        rules={[
                          {
                            required: output.value || false,
                            message: 'Enter value...',
                          },
                        ]}
                      >
                        {console.log(property.value)}
                        <Input
                          label={
                            <Text strong>
                              Value:{' '}
                              {output.name === 'path' &&
                                output.value === 'manual' && (
                                  <ToolTip
                                    title={`If you want to access open AI value, use this format {{$.your_parameter_name_here}}. It defaults to null if parameters doesnt exist`}
                                  >
                                    {' '}
                                    <InfoCircleTwoTone />
                                  </ToolTip>
                                )}
                            </Text>
                          }
                          defaultValue={property.value}
                          value={property.value}
                          size="small"
                          placeholder={'Enter value...'}
                          onChange={evt =>
                            handleChangeProperties(
                              output.name,
                              evt.target.value,
                              'value',
                              property.name,
                              property.id
                            )
                          }
                          style={{ marginLeft: '5px' }}
                          disabled={!property.name}
                        />
                      </StyledFormItem>
                      {output?.action?.find(action => action === 'delete') &&
                      idx ? (
                        <Button
                          icon={<DeleteOutlined />}
                          variant="link"
                          onClick={() =>
                            deleteProperty(output.name, property.id)
                          }
                        />
                      ) : null}
                    </StyledSpaceBetweenFlexCenter>
                  ))}
                {!output?.options &&
                  output?.properties?.map((property, idx) => (
                    <StyledSpaceBetweenFlexCenter key={idx}>
                      <StyledFormRow>
                        <Text strong>Key:</Text>
                        <Input
                          defaultValue={property.name}
                          value={property.name}
                          size="small"
                          placeholder={'Enter key...'}
                          onChange={evt =>
                            handleChangeProperties(
                              output.name,
                              evt.target.value,
                              'key',
                              property.value,
                              property.id
                            )
                          }
                          style={{ marginRight: '5px' }}
                        />
                      </StyledFormRow>
                      <StyledFormRow>
                        <Text strong>
                          Value:
                          {output.name === 'path' && output.value === 'manual' && (
                            <ToolTip
                              title={`If you want to access open AI value, use this format {{$.your_parameter_name_here}}. It defaults to null if parameters doesnt exist`}
                            >
                              {' '}
                              <InfoCircleTwoTone />
                            </ToolTip>
                          )}
                        </Text>
                        <Input
                          defaultValue={property.value}
                          value={property.value}
                          size="small"
                          placeholder={'Enter value...'}
                          onChange={evt =>
                            handleChangeProperties(
                              output.name,
                              evt.target.value,
                              'value',
                              property.name,
                              property.id
                            )
                          }
                          disabled={!property.name}
                          style={{ marginLeft: '5px' }}
                        />
                      </StyledFormRow>
                      {output?.action?.find(action => action === 'delete') &&
                      idx ? (
                        <Button
                          icon={<DeleteOutlined />}
                          variant="link"
                          onClick={() =>
                            deleteProperty(output.name, property.id)
                          }
                        />
                      ) : null}
                    </StyledSpaceBetweenFlexCenter>
                  ))}
              </TabPane>
            )
          )}

          <TabPane tab={<Text strong>Auth</Text>} key="auth">
            <Auth outputProps={outputProps} setOutputProps={setOutputProps} />
          </TabPane>
        </Tabs>
      )
    );
  };

  const RenderInputField = output => (
    <StyledFormItem
      name={output.name}
      rules={[
        output.validator
          ? {
              validator: (_, value) =>
                output.validator(
                  _,
                  value,
                  output.name,
                  requiredFields,
                  outputProps
                ),
            }
          : {
              required: output.required || false,
              message: output.placeholder,
            },
      ]}
    >
      <Input
        size="small"
        label={
          <>
            <Text strong>{output.label}</Text>
            {output.tooltip && (
              <ToolTip title={output.tooltip}>
                <InfoCircleTwoTone />
              </ToolTip>
            )}
          </>
        }
        value={getOutputPropertyDetails(output.name)?.value}
        defaultValue={getOutputPropertyDetails(output.name)?.value}
        onChange={evt =>
          handleOutputPropsChanges(evt.target.value, output.name)
        }
        placeholder={output.placeholder}
      />
    </StyledFormItem>
  );

  const RenderTextAreaField = output => (
    <StyledFormItem
      name={output.name}
      rules={[
        output.validator
          ? {
              validator: (_, value) =>
                output.validator(_, value, output.name, requiredFields),
            }
          : {
              required: output.required || false,
              message: output.placeholder,
            },
      ]}
    >
      <TextArea
        label={
          <>
            <Text strong>{output.label}</Text>{' '}
            {output.tooltip && (
              <ToolTip title={output.tooltip}>
                <InfoCircleTwoTone />
              </ToolTip>
            )}
          </>
        }
        defaultValue={getOutputPropertyDetails(output.name)?.value}
        onChange={evt =>
          handleOutputPropsChanges(evt.target.value, output.name)
        }
        placeholder={output.placeholder}
      />
    </StyledFormItem>
  );

  return (
    <div>
      {outputProps?.map((output, propIdx) =>
        output.name === 'type' ||
        output.hide ||
        renderAdvanceSettingCondition(output) ||
        SELECTABLE_FIELDS.includes(output.name) ? null : (
          <div key={propIdx}>
            {output.field === 'input' &&
              displayField(output) &&
              RenderInputField(output)}
            {output.field === 'textArea' &&
              displayField(output) &&
              RenderTextAreaField(output)}
            {output.field === 'select' &&
              displayField(output) &&
              !SELECTABLE_FIELDS.includes(output.name) && (
                <StyledFormItem
                  name={output.name}
                  rules={[
                    output.validator
                      ? {
                          validator: output.validator,
                        }
                      : {
                          required: output.required || false,
                          message: output.placeholder,
                        },
                  ]}
                >
                  <Select
                    label={
                      <>
                        <Text strong>{output.label}</Text>{' '}
                        {output.tooltip && (
                          <ToolTip title={output.tooltip}>
                            <InfoCircleTwoTone />
                          </ToolTip>
                        )}
                      </>
                    }
                    options={output.options}
                    onChange={evt => handleOutputPropsChanges(evt, output.name)}
                    value={output.value}
                    defaultValue={output.value}
                  />
                </StyledFormItem>
              )}
          </div>
        )
      )}

      {showAdvancedSettings && TabAdvancedSettings()}
    </div>
  );
};

Output.propTypes = {
  outputProps: PropTypes.array.isRequired,
  requiredFields: PropTypes.array.isRequired,
  getOutputPropertyDetails: PropTypes.func.isRequired,
  setOutputProps: PropTypes.func.isRequired,
  advanceSettingOption: PropTypes.bool.isRequired,
  showAdvancedSettings: PropTypes.bool.isRequired,
  setParamsProps: PropTypes.func,
  paramProps: PropTypes.array,
  objectifiedParamProps: PropTypes.any,
  addProps: PropTypes.func,
  setRequiredFields: PropTypes.func,
};

export default Output;
