import styled from 'styled-components';

export const StyledRoot = styled.div`
  width: 100%;

  & .ant-select {
    width: 100%;
  }

  & .ant-select-selector {
    border-radius: 5px;
  }
`;
