import {
  CLEAR_BOT_AGGREGATIONS,
  CLEAR_BOT_DETAILS,
  REMOVE_BOT_JID,
  REMOVE_WEAK_BOT_STATUS,
  SET_BOTDETAILS_PAGE_LOADING,
  SET_BOTDETAILS_PAGE_READY,
  SET_BOT_AGGREGATIONS,
  SET_BOT_DETAILS,
  SET_BOT_ERROR,
  SET_BOT_JID,
  SET_BOT_OVERVIEW,
  SET_BOT_SETTINGS,
  SET_TYPING_EXPERIENCE,
} from 'store/action';
import { initialBotState } from 'store/initialState';
import { stripUUID } from 'utils';
import { createESAggregationsData } from '../helpers/bot/analytics';
import { botStatistics } from 'utils/bot';
import { extractBotData } from '../helpers/bot/botDetails';
import { extractDefaultAnswer } from '../helpers/bot/answers';

export const botDetailsReducer = (state, action) => {
  switch (action.type) {
    case SET_BOT_JID:
      return {
        ...state,
        bot: {
          ...state.bot,
          jid: action.payload,
          strippedBotID: stripUUID(action.payload),
        },
      };

    case REMOVE_BOT_JID:
      return {
        ...state,
        bot: {
          ...state.bot,
          jid: null,
          strippedBotID: null,
        },
      };

    case SET_BOT_OVERVIEW:
      return {
        ...state,
        bot: {
          ...state.bot,
          totalTestSuites: action.payload.testsuite_count || 0,
          totalIntegrations: action.payload.integration_count || 0,
        },
      };

    case SET_BOT_SETTINGS: {
      const { bot, defaultAnswer } = action.payload;
      const { jid } = bot;
      const defaultAnswerData = extractDefaultAnswer(
        defaultAnswer.context,
        defaultAnswer.jid
      );
      const updatedBots = state.bots.map(item => {
        if (item.jid === jid) {
          return extractBotData(bot);
        } else {
          return item;
        }
      });
      return {
        ...state,
        bot: {
          ...state.bot,
          ...extractBotData(bot),
          defaultAnswer: [defaultAnswerData],
        },
        bots: [...updatedBots],
      };
    }

    case SET_BOT_DETAILS: {
      const botData = extractBotData(action.payload[0]);
      const defaultAnswerData = extractDefaultAnswer(
        action.payload[1]?.context,
        action.payload[1]?.jid
      );
      return {
        ...state,
        bot: {
          ...state.bot,
          ...botData,
          defaultAnswer: [defaultAnswerData],
          ui: {
            ...state.bot.ui,
            weakBotStats: {
              overview: botStatistics(action.payload).isWeakBot,
            },
          },
        },
      };
    }

    case CLEAR_BOT_DETAILS: {
      return {
        ...state,
        bot: initialBotState,
      };
    }

    case SET_BOT_ERROR: {
      return {
        ...state,
        bot: { ...initialBotState, hasBotError: true },
      };
    }

    case SET_BOT_AGGREGATIONS: {
      const aggregations = createESAggregationsData(action.payload);

      return {
        ...state,
        bot: {
          ...state.bot,
          aggregations,
        },
      };
    }

    case CLEAR_BOT_AGGREGATIONS:
      return {
        ...state,
        bot: {
          ...state.bot,
          aggregations: {},
        },
      };

    case SET_BOTDETAILS_PAGE_READY:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            detailsPageReady: true,
          },
        },
      };

    case SET_BOTDETAILS_PAGE_LOADING:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            detailsPageReady: false,
          },
        },
      };

    case REMOVE_WEAK_BOT_STATUS: {
      const { location, jid } = action.payload;
      if (location === 'bots') {
        return state;
      }

      if (location === 'integrations') {
        const integrations = state.bot.integrations?.map(integration => {
          if (integration.id === jid) {
            return { ...integration, showWeakStat: false };
          }
          return integration;
        });
        return {
          ...state,
          bot: {
            ...state.bot,
            integrations,
          },
        };
      }

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            weakBotStats: {
              ...state.bot.ui.weakBotStats,
              [location]: undefined,
            },
          },
        },
      };
    }

    case SET_TYPING_EXPERIENCE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          typingExperience: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
