import styled from 'styled-components';
import { Card } from 'antd';
import { cssVariables } from 'styles/root';

export const StyledWrapper = styled.div`
  margin: 1% 2%;
`;

export const StyledPageTitle = styled.div`
  & h3 {
    display: flex;
    width: 100%;
    font-size: 24px;
    font-weight: ${cssVariables.font.normal};
  }
`;
export const StyledCard = styled(Card)`
  font-size: 32px;
  text-align: center;
  margin-bottom: 16px;
  background-color: ${props => (props.isGray ? '#cccccc' : 'none')};
  border-radius: 4px;

  > span {
    font-size: 16px;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  height: auto;
  background: #fff;
  width: 100%;
`;

export const ProgressSection = styled.div`
  display: flex;
  align-items: center;

  > button {
    margin-left: 16px;
  }
`;

export const FlagName = styled.p`
  font-size: 1rem;
`;
