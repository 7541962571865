import { ZSB_PRICING_PAGE } from 'constants/outboundLinks';
import ROUTES from 'constants/routes';
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  botModeSelector,
  currentBotSelector,
  isPageReadySelector,
  strippedBotJIDSelector,
} from 'selectors/bot';
import {
  allAnswersSelector,
  isMaxAnswersSelector,
} from 'selectors/bot/answers';
import { SET_CATEGORY_MODAL_OPEN, SHOW_DIGRAPH } from 'store/action';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import { useHistory } from 'react-router-dom';
import { StyledAddAnswer, StyledFirstBotNoAnswers } from '../AnswerBank.styles';
import { BOT_MODES } from 'constants/bot';
import {
  FileTextOutlined,
  GlobalOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import {
  ANSWER_SLUG,
  FILE_SLUG,
  WEBSITE_SLUG,
} from 'constants/answerbank/defaults';
import Answer from './Answer';
import Website from './Website';
import File from './File';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { isFreeUserSelector } from 'selectors/plan';
import AnchorButton from 'components/Button/AnchorButton';
import EmptyData from 'components/EmptyAnswer';
import { isANewBotSelector, isAnswerBankReadySelector } from 'selectors/bot/ui';
import { withFeatureFlag } from 'components/hoc/withFeatureFlags';
import { GENERATE_FAQ } from 'constants/featureFlags';

const useAnswerType = ({
  filteredAnswers,
  handleManualBotSyncing,
  handleChangeTab,
  selectedAnswers,
  setShowBulkMoveCategoryModal,
  setShowDisplayAnswer,
  showDisplayAnswer,
  handleSelectAll,
  handleShowAskedQuestions,
  handleShowLinkedQuestions,
  handleChangeCheckbox,
  handleShowAnswerEditorModal,
  handleShowFileEditorModal,
  onDelete,
  handleChangeCategory,
  exportSelectedAnswers,
  handleOpenExportModal,
  setShowBulkDeleteModal,
  handleSortAnswers,
  newAnswer,
  isAddAnswerDisabled,
  handleOpenFileModal,
  sending,
  onAddAnswer,
  handleOpenAnswerModal,
  onChangeNewAnswer,
  setAnswerVersion,
  path,
  handleOpenAddAnswerModal,
  selectedTab,
  renderFilterAnswer,
  runTour,
}) => {
  const { pathname } = useLocation();
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const limitReached = useSelector(isMaxAnswersSelector);
  const bot = useSelector(currentBotSelector);
  const mode = useSelector(botModeSelector);
  const allAnswers = useSelector(allAnswersSelector);
  const isFreeUser = useSelector(isFreeUserSelector);
  const isAnswerBankReady = useSelector(isAnswerBankReadySelector);
  const isANewBot = useSelector(isANewBotSelector);
  const isDetailsPageReady = useSelector(isPageReadySelector);
  const strippedBotID = useSelector(strippedBotJIDSelector);
  const [selectedAnswerTypeTab, setSelectedAnswerTypeTab] =
    useState(ANSWER_SLUG);
  const { synced, name } = bot;
  const {
    plan: { max_ans_count: maxAnswerCount, plan_type: planType },
  } = state;

  const handleShowDigraph = () => {
    dispatch({
      type: SHOW_DIGRAPH,
    });
  };

  const limitReachedLabel = () => {
    if (path('files')) {
      return ' file.';
    } else if (path('websites')) {
      return ' website.';
    } else {
      return ' answer.';
    }
  };

  const limitReachedContent = () =>
    limitReached ? (
      <StyledAddAnswer>
        <span className="answerbank__input__max-reached-notice">
          {`You can add only ${maxAnswerCount} Answers in ${planType.toUpperCase()} Plan. `}{' '}
          {planType === 'advanced' ? (
            <>
              <a href={ZSB_PRICING_PAGE} rel="noreferrer">
                {' '}
                Contact Us{' '}
              </a>{' '}
              for support.
            </>
          ) : (
            <>
              <Link to={ROUTES.SUBSCRIPTION}>Upgrade Plan</Link> to add more
              {limitReachedLabel()}
            </>
          )}
        </span>
      </StyledAddAnswer>
    ) : null;

  const answerData = useMemo(() => {
    return {
      filteredAnswers,
      handleManualBotSyncing,
      handleChangeTab,
      selectedAnswers,
      setShowBulkMoveCategoryModal,
      handleShowDigraph,
      synced,
      setShowDisplayAnswer,
      showDisplayAnswer,
      handleSelectAll,
      handleShowAskedQuestions,
      handleShowLinkedQuestions,
      handleChangeCheckbox,
      handleShowAnswerEditorModal,
      handleShowFileEditorModal,
      onDelete,
      handleChangeCategory,
      exportSelectedAnswers,
      handleOpenExportModal,
      setShowBulkDeleteModal,
      handleSortAnswers,
      newAnswer,
      isAddAnswerDisabled,
      handleOpenFileModal,
      sending,
      onAddAnswer,
      handleOpenAnswerModal,
      maxAnswerCount,
      planType,
      onChangeNewAnswer,
      setAnswerVersion,
      path,
      selectedTab,
      limitReachedContent,
      renderFilterAnswer,
    };
  }, [pathname, newAnswer, filteredAnswers, bot, showDisplayAnswer]);

  const handleClickCategoryTemplate = () => {
    dispatch({
      type: SET_CATEGORY_MODAL_OPEN,
      payload: { action: 'add', isTemplate: true },
    });
  };

  const GenerateFAQFeature = withFeatureFlag(
    GENERATE_FAQ,
    <>
      or{' '}
      <AnchorButton
        onClick={() => handleOpenAnswerModal('generate-faq')}
        title="Generate FAQ"
      />
    </>
  );

  const BotWithNoAnswers = () => (
    <StyledFirstBotNoAnswers>
      <h3>Let's teach {name || 'your bot'} how to respond.</h3>
      <p>
        It's Day 1 for {name || 'your bot'}. Add answers to its answer bank.
      </p>
      <p>{name || 'Your bot'} will use these answers to respond.</p>
      <p>You can start with someting like</p>
      <p> - "Hi! I am {name || 'your bot'}."</p>
      <p> - "I will be answering questions for you."</p>
      <p> - "I use advanced AI to identify the right response."</p>
      {!isFreeUser && mode === BOT_MODES.ZSB && (
        <p>
          or add answers from our{' '}
          <AnchorButton
            onClick={handleClickCategoryTemplate}
            title="Template Category"
          />{' '}
          {<GenerateFAQFeature />}
        </p>
      )}
    </StyledFirstBotNoAnswers>
  );

  const StillInprogressMessage = () => (
    <EmptyData
      loading={!isAnswerBankReady}
      subtitle="Please be patient while we fetch your bot data..."
    />
  );

  const renderAnswerTypeContent = (type, props) => {
    if (!isANewBot) {
      switch (type) {
        case 'websites':
          return <Website {...props} />;
        case 'files':
          return <File {...props} />;
        default:
          return (
            <Answer
              {...props}
              handleOpenAddAnswerModal={handleOpenAddAnswerModal}
            />
          );
      }
    } else if (
      isDetailsPageReady &&
      isAnswerBankReady &&
      (runTour || isANewBot) &&
      !allAnswers.length
    ) {
      return <BotWithNoAnswers />;
    } else if (isDetailsPageReady && !isAnswerBankReady) {
      return <StillInprogressMessage />;
    } else {
      return null;
    }
  };

  const ANSWER_BANK_SUBMENU_ITEMS = [
    {
      name: 'Answers',
      icon: <ProfileOutlined />,
      key: ANSWER_SLUG,
      count: bot.answerCount,
      content: props => renderAnswerTypeContent('answers', props),
    },
  ];

  const OPEN_AI_ANSWER_BANK_SUBMENU_ITEMS = [
    {
      name: 'File',
      icon: <FileTextOutlined />,
      key: FILE_SLUG,
      count: bot.fileCount,
      content: props => renderAnswerTypeContent('files', props),
    },
    {
      name: 'Website',
      icon: <GlobalOutlined />,
      key: WEBSITE_SLUG,
      count: bot.websiteCount,
      content: props => renderAnswerTypeContent('websites', props),
    },
  ];

  const menuList = useMemo(() => {
    return mode === BOT_MODES.OPENAI
      ? [...ANSWER_BANK_SUBMENU_ITEMS, ...OPEN_AI_ANSWER_BANK_SUBMENU_ITEMS]
      : ANSWER_BANK_SUBMENU_ITEMS;
  }, [
    answerData,
    ANSWER_BANK_SUBMENU_ITEMS,
    OPEN_AI_ANSWER_BANK_SUBMENU_ITEMS,
    bot.answerCount,
    bot.fileCount,
    bot.websiteCount,
  ]);

  const handleClickMenu = key => {
    history.push(`${ROUTES.BOT_DETAILS}/${strippedBotID}/answer-bank/${key}`);
    setSelectedAnswerTypeTab(key);
  };

  return {
    handleClickMenu,
    menuList,
    handleShowDigraph,
    synced,
    maxAnswerCount,
    planType,
    limitReachedContent,
    answerData,
    selectedAnswerTypeTab,
  };
};

export default useAnswerType;
