import { apiService } from './api.service';
const keys = {
  SESSION: 'Session',
  TOKEN: 'token',
  USER: 'user',
};

const LOGIN_TOKEN_TTL_HOURS = 16;

export const setSession = session =>
  localStorage.setItem(keys.SESSION, JSON.stringify(session));

export const getSession = () => JSON.parse(localStorage.getItem(keys.SESSION));

export const setToken = token => {
  const millisecondsPerHour = 1000 * 60 * 60;
  const ttl = LOGIN_TOKEN_TTL_HOURS * millisecondsPerHour;
  if (!token) {
    return;
  }
  const now = new Date();

  const item = {
    value: token,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem('token', JSON.stringify(item));
};

export const getToken = () => {
  const tokenStr = localStorage.getItem('token');
  if (!tokenStr) {
    return null;
  }

  const tokenObj = JSON.parse(tokenStr);
  const now = new Date();

  if (now.getTime() > tokenObj.expiry) {
    localStorage.removeItem('token');
    return null;
  }

  return tokenObj.value;
};

export const setImpersonateUser = (masterUserND, impersonatedUserObject) => {
  localStorage.setItem('masterUserND', masterUserND);
  localStorage.setItem('impersonatedUserObject', impersonatedUserObject);
};

export const clearImpersonatedUserObject = () => {
  localStorage.removeItem('masterUserND');
  localStorage.removeItem('impersonatedUserObject');
};

export const authorizedGoogleAccount = async code => {
  try {
    const res = await apiService.sigInToGoogle({
      code,
      callback_url: process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URI,
    });
    return res;
  } catch (err) {
    throw err;
  }
};
