import { useState, useContext, useEffect } from 'react';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { base64toBlob, getBase64 } from 'utils';
import { message } from 'antd';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';

const INITIAL_ACTIVE_TAB = 'createRequest';

const useJiraForm = () => {
  const [state] = useContext(Context);
  const { sentinel, token, user, graph } = state;
  const [reportTitle, setReportTitle] = useState(null);
  const [reportDescription, setReportDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState({
    isVisible: false,
    previewImage: '',
    previewTitle: '',
  });
  const [activePage, setActivePage] = useState(INITIAL_ACTIVE_TAB);
  const [tickets, setTickets] = useState();
  const [activePagination, setActivePagination] = useState(1);
  const [totalTicketsCount, setTotalTicketsCount] = useState(0);
  const [ticketAttachments, setTicketAttachments] = useState([]);
  const initialTicketInfo = {
    id: '',
    title: '',
    ticketId: '',
    status: '',
    dateRaised: '',
    description: '',
    attachments: [],
  };

  const [ticketInfo, setTicketInfo] = useState(initialTicketInfo);

  const handleChangeReportTitle = evt => {
    setReportTitle(evt.target.value);
  };

  const handleChangeReportDescription = evt => {
    setReportDescription(evt.target.value);
  };

  const createJiraTicket = async evt => {
    evt.preventDefault();
    setIsSubmitBtnLoading(true);
    try {
      const res = await apiService.createJiraTicket(
        reportTitle,
        reportDescription,
        user.name,
        ticketAttachments,
        state.token,
        state.sentinel,
        state.graph
      );
      setIsSubmitBtnLoading(false);
      if (res.data.success) {
        clearFormData();
        message.success('Bug reported successfully submitted.');
      } else {
        throw new Error('Something went wrong while sending your request.');
      }
      setIsSubmitBtnLoading(false);
    } catch (error) {
      setIsSubmitBtnLoading(false);
      message.error(
        error.message || 'Something went wrong while sending your request.'
      );
    }
  };

  const handlePreviewFile = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewFile({
      isVisible: true,
      previewImage: file.url || file.preview,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const handleRemoveFile = file => {
    const filteredAttachments = ticketAttachments.filter(
      item => file.name !== item.name
    );
    setTicketAttachments([...filteredAttachments]);
  };

  const handleChangeFile = async file => {
    setTicketAttachments([...ticketAttachments, file]);
    return false;
  };

  const handleDownloadAttachment = async file => {
    setIsLoading(true);
    try {
      const res = await apiService.downloadJiraAttachment(
        file.id,
        graph,
        sentinel,
        token
      );
      if (
        !res.data ||
        !res.data.success ||
        !res.data.report ||
        !res.data.report[0] ||
        !res.data.report[0].content
      ) {
        throw new Error('Something went wrong while downloading your file.');
      }
      const blob = base64toBlob(res.data.report[0].content);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = file.filename;
      link.href = url;
      link.click();
    } catch (error) {
      setIsLoading(false);
      message.error(
        error.message || 'Something went wrong while downloading your file.'
      );
    }
    setIsLoading(false);
  };

  const handleActivePage = page => {
    getAllJiraTickets(1);
    clearFormData();
    setActivePage(page);
  };

  useEffect(() => {
    if (!ticketInfo.id || !ticketInfo.ticketId) {
      setActivePage(INITIAL_ACTIVE_TAB);
    }
  }, [ticketInfo]);

  const handleTicketDetails = async selectedId => {
    setIsLoading(true);
    try {
      const res = await apiService.getJiraIssue(
        selectedId,
        graph,
        sentinel,
        token
      );

      if (
        !res.data.report ||
        !res.data.report[0] ||
        !res.data.report[0] ||
        !res.data.report[0].fields ||
        !res.data.success
      ) {
        throw new Error('Something went wrong while fetching the ticket');
      }

      const { attachment, created, description, status, summary, updated } =
        res.data.report[0].fields;
      const { id } = res.data.report[0];

      setTicketInfo({
        id,
        ticketId: selectedId,
        title: summary,
        status: status.name,
        dateRaised: created,
        description,
        lastUpdate: updated,
        attachments: attachment,
      });
    } catch (error) {
      setIsLoading(false);
      return message.error(error.message || GET_DATA_ERROR);
    }
    setIsLoading(false);
    setActivePage('ticketDetails');
  };

  const getAllJiraTickets = async page => {
    setIsLoading(true);
    const allTickets = await apiService.getAllJiraTicket(
      user?.name,
      token,
      sentinel,
      page * 5 - 5
    );
    if (allTickets) {
      setIsLoading(false);
    }
    setActivePagination(page);
    setTotalTicketsCount(allTickets.data.report[0].total);
    setTickets(allTickets.data.report[0].issues);
  };

  const onPageChange = page => {
    getAllJiraTickets(page);
  };

  const clearFormData = () => {
    setReportTitle(null);
    setReportDescription(null);
    setTicketAttachments([]);
  };

  useEffect(() => {
    clearFormData();
    return () => {
      setTicketInfo(initialTicketInfo);
    };
  }, []);

  return {
    activePage,
    activePagination,
    initialTicketInfo,
    isLoading,
    isSubmitBtnLoading,
    previewFile,
    reportTitle,
    reportDescription,
    ticketAttachments,
    tickets,
    ticketInfo,
    totalTicketsCount,
    onPageChange,
    getAllJiraTickets,
    handleTicketDetails,
    handleActivePage,
    handleChangeFile,
    handlePreviewFile,
    createJiraTicket,
    handleChangeReportDescription,
    handleChangeReportTitle,
    setActivePage,
    handleRemoveFile,
    handleDownloadAttachment,
  };
};

export default useJiraForm;
