export const TEXT_INGEST_STEP_ONE = [
  {
    content: (
      <div>
        <h3
          style={{
            display: 'inline-block',
          }}
        >
          Text Ingest
        </h3>
        <p>
          You can ingest text from a web page (eg:
          https://www.zeroshotbot.com/terms ). Just copy the url and paste it
          here to start the text ingesting process. Our AI will convert the
          content on the website into relevant answers.
        </p>
        <br />
        <p>
          Or you can chose to use the text or paragraphs option to copy the text
          directly and parse them as answers.
        </p>
      </div>
    ),
    target: '.text-ingest-inputs',
    disableBeacon: true,
    hideFooter: true,
    placement: 'auto',
  },
  {
    content: (
      <div>
        <h3
          style={{
            display: 'inline-block',
          }}
        >
          Text Ingest
        </h3>
        <p>Click next to start generating answers.</p>
      </div>
    ),
    target: '.next-step-button',
    disableBeacon: true,
    hideFooter: true,
    placement: 'auto',
  },
];
