import styled from 'styled-components';
import { Input } from 'antd';
const { Search } = Input;

export const StyledSearch = styled(Search)`

  > .ant-input-search
    .ant-input-group
    .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &
    .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 12px 12px 0;
  }
  }
`;
