import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledTextArea,
  StyledSpan,
  StyledAntdTextArea,
} from './TextArea.styles';

const TextArea = props => {
  const {
    value,
    noPaddingTop,
    full,
    children,
    allowClear,
    disableSpan,
    ...rest
  } = props;

  return (
    <StyledTextArea>
      {!disableSpan ? (
        <StyledSpan noPaddingTop={noPaddingTop}>{rest.label || ''}</StyledSpan>
      ) : null}
      <StyledAntdTextArea
        value={value}
        $full={full}
        $allowClear={allowClear}
        contentEditable={false}
        {...rest}
      >
        {children}
      </StyledAntdTextArea>
    </StyledTextArea>
  );
};

TextArea.defaultProps = {
  autoSize: {
    minRows: 3,
    maxRows: 5,
  },
};

TextArea.propTypes = {
  value: PropTypes.any,
  children: PropTypes.any,
  textAreaRef: PropTypes.object,
  full: PropTypes.bool,
  noPaddingTop: PropTypes.bool,
  disableSpan: PropTypes.bool,
  allowClear: PropTypes.bool,
};

export default React.memo(TextArea);
