import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { StyledChromePickerPosition } from './ColorPicker.style';

const ColorPicker = ({
  color,
  onChangeColor,
  handleChangeFields,
  setShowColorPicker,
}) => {
  return (
    <StyledChromePickerPosition
      onDoubleClick={() => {
        if (setShowColorPicker) {
          setShowColorPicker(false);
        }
      }}
    >
      <ChromePicker
        color={color}
        onChangeComplete={color => {
          if (onChangeColor) {
            onChangeColor(color);
          } else {
            // to deprecate
            handleChangeFields('color', color.hex);
          }
        }}
      />
    </StyledChromePickerPosition>
  );
};

ColorPicker.propTypes = {
  setShowColorPicker: PropTypes.func,
  color: PropTypes.string,
  handleChangeFields: PropTypes.func,
  onChangeColor: PropTypes.func,
};

export default ColorPicker;
