import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledMenu = styled(Menu)`
  background-color: #f0f2f5;
`;

export const FeatureFlagStyledMenu = styled(Menu.Item)`
  box-shadow: none;
  background-color: #fff;

  &:hover {
    background-color: #f5f5f5 !important;
  }
`;
