import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledCategoryModalFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  button {
    font-size: 13px;
    padding: 10px 5px;
  }

  button:first-child {
    background-color: transparent !important;
    :hover {
      background-color: ${cssVariables.blue1} !important;
    }
  }

  .modal-action-buttons {
    display: flex;
    align-items: flex-end;

    button:last-child {
      background-color: ${cssVariables.primaryBlue};
      color: #fff;
    }
  }
`;

export const StyledMoveCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: ${cssVariables.gray0};
    font-size: 16px;
  }
  .bulk-move-section {
    display: flex;
    width: 100%;
    font-size: 14px;

    h4 {
      margin: 0;
      font-weight: ${cssVariables.font.normal} !important;
    }

    h4:first-child {
      width: 70%;
    }

    h4:nth-child(2) {
      text-align: center;
      width: 30%;
    }
  }
`;

export const StyledCategoryPicker = styled.div`
  display: flex;
  width: 100%;
  column-gap: 10px;
  flex-direction: column;
  /* word-break: break-all;
  align-items: center;

  span {
    margin: 5px 0;
  }

  
  span:first-child {
    width: 70%;
  } */
`;

export const StyledCategoryName = styled.span`
  color: ${props =>
    props.isSelected ? '#fff' : cssVariables.gray0} !important;
  border-radius: 3px;
  padding: 2px;
  font-weight: ${cssVariables.font.normal};
  padding: 10px 5px;
  background: ${props =>
    props.isSelected ? `${cssVariables.primaryBlue} !important` : 'none'};

  :hover {
    background-color: ${cssVariables.blue1};
    cursor: pointer;
  }
`;
