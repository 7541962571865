import React, { useState, useEffect } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { WechatFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import Layout from 'components/Layout';
import AnswerBank from './AnswerBank';
import Analytics from './Analytics';
import Validation from './Validation';
import Integration from './Integration';
import Settings from './Settings';
import ROUTES from 'constants/routes';
import useBotDetails from './hooks';
import LinkedQuestionModal from 'components/Modals/LinkedQuestionModal';
import ResponsePickerModal from 'components/Modals/ResponsePickerModal';
import AddQuestionToTestSuiteModal from 'components/Modals/AddQuestionToTestSuiteModal';
import { apiService } from 'services/api.service';
import { SET_ONBOARDING_FLAG } from 'store/action';
import {
  StyledCustomTooltip,
  StyledToolTipContainer,
  StyledFooterActions,
} from './BotDetails.styles';
import CallbackLogs from './CallbackLog';
import BotOverview from './Overview';
import { stripUUID } from 'utils';
import Questions from './Questions';
import SimilarQuestionsModal from 'components/Modals/SimilarQuestionsModal';
import AnswerEditor from 'components/Modals/AnswerEditor';
import QuestionEditor from 'components/Modals/QuestionEditor';
import ConversationSimulator from './ConversationSimulator';
import FileEditor from 'components/Modals/FileEditor';
import Functions from './Functions';
import EmptyAnswer from 'components/EmptyAnswer';
import WebsiteEditor from 'components/Modals/WebsiteEditor';

const steps = [
  {
    content: (
      <div>
        <WechatFilled
          style={{
            fontSize: 24,
            color: '#0E3F8A',
            display: 'inline-block',
            marginRight: 10,
          }}
        />
        <h3
          style={{
            display: 'inline-block',
          }}
        >
          Test your bot
        </h3>
        <p>
          Awesome! Now that you have added answers, you can test your bot by
          clicking here.
        </p>
        <p>Ask relevant question that you think your customers might ask.</p>
      </div>
    ),
    target: '.test-icon',
    disableBeacon: true,
    hideFooter: true,
    placement: 'left',
  },
];

const CustomTooltip = ({
  continuous,
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
}) => {
  return (
    <StyledCustomTooltip {...tooltipProps}>
      <StyledToolTipContainer>
        {step.title && <div>{step.title}</div>}
        <div>{step.content}</div>
        <StyledFooterActions>
          <div>
            {index > 0 && (
              <button type="button" {...backProps}>
                BACK
              </button>
            )}
            {continuous && (
              <button type="button" {...primaryProps}>
                CLOSE
              </button>
            )}
          </div>
        </StyledFooterActions>
      </StyledToolTipContainer>
    </StyledCustomTooltip>
  );
};

CustomTooltip.propTypes = {
  continuous: PropTypes.bool,
  index: PropTypes.number,
  step: PropTypes.object,
  backProps: PropTypes.object,
  primaryProps: PropTypes.object,
  tooltipProps: PropTypes.object,
};

const BotDetails = () => {
  const {
    allAnswers,
    contentLoading,
    currentLocation,
    dispatch,
    isChatVisible,
    isFreeUser,
    graph,
    handleChatAskQuestion,
    handleShowAnswerEditorModal,
    handleCloseLinkedQuestionModal,
    handleShowLinkedQuestions,
    handleShowAddTestSuiteModal,
    handleUnlinkQuestion,
    handleSeekAnswer,
    isPageReady,
    linkedAnswerToEdit,
    loading,
    onUpdateAnswer,
    questionForTestSuite,
    sentinel,
    onboarding_flag,
    setShowAddQuestionToTestSuiteModal,
    showAddQuestionToTestSuiteModal,
    showLinkedQuestionModal,
    token,
    validationDetails,
    jid,
    selectedAnswer,
    selectedFile,
    selectedWebsite,
    questionEditor,
    contextHistory,
    setContextHistory,
    maxInteraction,
    setMaxInteraction,
  } = useBotDetails();
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (
      isPageReady &&
      allAnswers.length &&
      !onboarding_flag.includes('TestIconBot') &&
      onboarding_flag.includes('BotSideBarMenu')
    ) {
      setRunTour(true);
    }
    return () => setRunTour(false);
  }, [allAnswers, isPageReady, onboarding_flag]);

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      setStepIndex(0);
      if (!onboarding_flag.includes('TestIconBot')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'TestIconBot'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'TestIconBot' });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
      if (index === 0) {
      }
    }
  };

  const botDetailsRoute = `${ROUTES.BOT_DETAILS}/${stripUUID(jid)}`;

  return (
    <>
      <Joyride
        run={runTour}
        steps={steps}
        stepIndex={stepIndex}
        tooltipComponent={CustomTooltip}
        continuous
        debug
        spotlightClicks
        showProgress={true}
        showSkipButton={true}
        callback={onClickCallback}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Layout
        isChatVisible={isChatVisible}
        loading={loading}
        handleChatAskQuestion={handleChatAskQuestion}
        handleShowAnswerEditorModal={handleShowAnswerEditorModal}
        handleSeekAnswer={handleSeekAnswer}
        handleShowAddTestSuiteModal={handleShowAddTestSuiteModal}
        setRunTour={setRunTour}
        runTour={runTour}
        contextHistory={contextHistory}
        setContextHistory={setContextHistory}
        maxInteraction={maxInteraction}
        setMaxInteraction={setMaxInteraction}
      >
        <Switch>
          <Route
            path={`${botDetailsRoute}${ROUTES.BOT_OVERVIEW}`}
            component={BotOverview}
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.ANSWERBANK}`}
            component={AnswerBank}
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.VALIDATION}`}
            component={Validation}
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.QUESTIONS}`}
            component={Questions}
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.BOT_SETTINGS}`}
            component={Settings}
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.ANALYTICS}`}
            render={() =>
              !isPageReady && isFreeUser ? (
                <EmptyAnswer
                  spinning={contentLoading || !isPageReady}
                  subtitle="Please be patient while we fetch your bot data..."
                />
              ) : (
                <Analytics
                  handleShowAddTestSuiteModal={handleShowAddTestSuiteModal}
                  isPageReady={isPageReady}
                  validationDetails={validationDetails}
                />
              )
            }
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.CALLBACK_LOGS}`}
            component={CallbackLogs}
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.FUNCTIONS}`}
            component={Functions}
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.BOT_INTEGRATION}`}
            component={Integration}
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.FLOW_SIMULATOR}`}
            component={ConversationSimulator}
          />
          <Route
            path={`${botDetailsRoute}${ROUTES.BOT_DETAILS}`}
            component={BotOverview}
          />
        </Switch>

        <LinkedQuestionModal
          visible={showLinkedQuestionModal}
          linkedAnswerToEdit={linkedAnswerToEdit}
          showLinkedQuestionModal={showLinkedQuestionModal}
          handleCloseLinkedQuestionModal={handleCloseLinkedQuestionModal}
          handleUnlinkQuestion={handleUnlinkQuestion}
        />

        <ResponsePickerModal />
        <SimilarQuestionsModal />
        {selectedAnswer?.isOpen ? <AnswerEditor /> : null}
        {selectedFile?.isOpen ? <FileEditor /> : null}
        {selectedWebsite?.isOpen ? <WebsiteEditor /> : null}
        {questionEditor?.isOpen ? <QuestionEditor /> : null}

        {showAddQuestionToTestSuiteModal && (
          <AddQuestionToTestSuiteModal
            show={showAddQuestionToTestSuiteModal}
            question={questionForTestSuite}
            onCloseModal={() => setShowAddQuestionToTestSuiteModal(false)}
          />
        )}
      </Layout>
    </>
  );
};

export default BotDetails;
