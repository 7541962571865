import styled from 'styled-components';
import { Row, Table, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Input from 'components/Input';
import { cssVariables } from 'styles/root';

export const StyledToolTip = styled(Tooltip)`
  strong {
    display: flex;
    width: 100%;
  }
`;

export const NoTestGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 65vh;
  margin: auto;
  justify-content: center;
`;

export const StyledIconWrapper = styled.div`
  // display: flex;
  // justify-content: start;
  // align-items: center;
  // margin-left: 20px;
  // gap: 10px;

  .anticon {
    margin-right: 2%;
    font-size: 16px;
  }
`;

export const StyledValidationTable = styled(Table)`
  padding: 0 16px;
  font-size: 0.8rem;
  th.ant-table-cell {
    font-weight: ${cssVariables.font.bold};
    font-size: 0.8rem;
  }
`;

export const StyledLinkColumn = styled.div`
  color: ${props => props.theme.primaryBlue};
  font-size: 0.8rem;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const TestStatusWrapper = styled(Row)`
  margin: 16px 0;
`;

export const StyledTestStatus = styled.div`
  background: #fff;
  border: 1px solid #f4f6f8;
  border-radius: 5px;
  box-shadow: inset 0px 3px 0px ${props => props.color};
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto;

  div:first-child {
    font-weight: ${cssVariables.font.normal};
    font-size: 28px;
    line-height: 24px;
    color: ${props => props.color};
    margin-bottom: 2px;
  }

  div:last-child {
    font-size: 14px;
    line-height: 24px;
    color: #000;
  }
`;

export const TestCaseHeader = styled(Row)`
  padding: 10px 0;
  align-items: center;
`;

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const StyledNoTestGroupText = styled.div`
  font-family: ${cssVariables.font.fontFamily};
  font-size: 26px;
  font-weight: ${cssVariables.font.normal};
  color: #66696f;
`;

export const StyledTestCases = styled.div`
  border: 1px solid #e8f0fd;
  border-radius: 5px;
  background: #fff;
  // position: relative;
`;

export const StyledTestCasesHeader = styled.div`
  padding: 21px 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledTestCasesAction = styled(Row)`
  width: 100%;
`;

export const StyledTestCaseSearchInput = styled(Input)`
  margin-right: 8px;
  height: 36px;
  width: 100%;
`;

export const StyledIconSearch = styled(SearchOutlined)`
  color: #b3b4b7;
`;
