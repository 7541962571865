import styled from 'styled-components';
import Modal from 'components/Modals/GenericModal';
import { cssVariables } from 'styles/root';

export const StyledQuestionModalTitle = styled.h4`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.normal};
  font-size: 18px;
  color: #000;
  margin-top: 8px;
`;

export const StyledResponsePicker = styled(Modal)`
  width: 816px !important;
  padding: 0;

  & .ant-modal-body {
    max-height: 600;
    overflow-y: auto;
  }

  @media (max-width: 1000px) {
    width: 60vw !important;
  }
`;

export const StyledModalSection = styled.div`
  margin-bottom: 30px;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${cssVariables.font.bold};
    color: ${cssVariables.gray0};
  }
`;

export const StyledCurrentQuestion = styled.div`
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e8f0fd;
  display: flex;
  padding-left: 10px;
  align-items: center;
`;

export const StyledCurrentAnswer = styled.div`
  background-color: ${cssVariables.gray3};
  & div:first-child {
    background: none;
  }
  & > *:hover {
    border: 1px solid ${cssVariables.gray3};
  }
`;

export const StyledLinkHint = styled.div`
  padding: 5px 0px;
  font-size: 11px;
  margin-bottom: 10px;
`;
