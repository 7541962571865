import React, { useState, useContext, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import { Context } from 'store/store';
import { message } from 'antd';

import { apiService } from 'services/api.service';
import {
  CLOSE_CHAT_ANSWER_IN_MODAL,
  CLOSE_CHAT_SELECTED_SEQUENCE_IN_MODAL,
  GET_USERNAME,
  INIT_JAC,
  LOAD_APPLICATION,
  LOGOUT_EXTERNAL_USER,
  LOGOUT_USER,
  SET_CHAT_MODE,
  SET_ONBOARDING_FLAG,
  SET_PLAN_DETAILS,
  SET_PUBLIC_KEY,
  SHOW_FIRST_LOGIN_PLAN,
  START_IMPERSONATING_USER,
  TOGGLE_EXPAND_SIDEBAR_CHAT,
} from 'store/action';
import { withPrefixUUID } from 'utils';
import { initialUserState } from 'store/initialState';
import { isPageReadySelector } from 'selectors/bot';
import { allAnswersSelector } from 'selectors/bot/answers';
import { getTokenSelector, isExternalPageSelector } from 'selectors/user';
import useSelector from 'store/useSelector';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import { isAdminSelector, isImpersonatingSelector } from 'selectors/admin';
import {
  onboardingFlagSelector,
  showFirstLoginPlanSelector,
} from 'selectors/plan';
import ROUTES from 'constants/routes';
import {
  chatAnswerInModalSelector,
  chatSequenceInModalSelector,
  chatSelectedSequenceInModalSelector,
  isSidebarChatExpandedSelector,
} from 'selectors/bot/ui';

const useLayout = ({ setRunTour, children }) => {
  const [state, dispatch] = useContext(Context);
  const allAnswers = useSelector(allAnswersSelector);
  const chatAnswerInModal = useSelector(chatAnswerInModalSelector);
  const chatSequenceInModal = useSelector(chatSequenceInModalSelector);
  const chatSelectedSequenceInModal = useSelector(
    chatSelectedSequenceInModalSelector
  );
  const isAdmin = useSelector(isAdminSelector);
  const isSidebarChatExpanded = useSelector(isSidebarChatExpandedSelector);
  const isImpersonating = useSelector(isImpersonatingSelector);
  const isPageReady = useSelector(isPageReadySelector);
  const token = useSelector(getTokenSelector);
  const onboardingFlags = useSelector(onboardingFlagSelector);
  const isExternalPage = useSelector(isExternalPageSelector);
  const showFirstLoginPlan = useSelector(showFirstLoginPlanSelector);
  const scrollContainerRef = useRef(null);
  const lastElementRef = useRef(null);

  const {
    user,
    bot: { name, hasBotError, jid },
    sentinel,
    graph,
  } = state;

  const location = useLocation();
  const { push } = useHistory();
  const { pathname } = location;
  const WITH_PREFIX_BOT_JID = withPrefixUUID(jid || pathname);

  const [rightNavOpen, setRightNavOpen] = useState(false);

  const getPublicKey = async () => {
    try {
      const res = await apiService.getPublicKey(token);
      dispatch({
        type: SET_PUBLIC_KEY,
        payload: res.data.anyone,
      });
    } catch (error) {}
  };

  const loadUserData = async (sentinel, graph) => {
    try {
      const planData = await apiService.init(sentinel, graph, token);
      await dispatch({
        type: INIT_JAC,
        payload: {
          plan: planData.data.report[0].context,
          subscription: planData.data?.report[1]?.context || {},
        },
      });
      getPublicKey();
    } catch (error) {
      throw new Error(GET_DATA_ERROR);
    }
  };

  const checkUserData = async () => {
    const impersonatedUserObj = JSON.parse(
      localStorage.getItem('impersonatedUserObject')
    );

    if (impersonatedUserObj && impersonatedUserObj?.impersonated_user?.graph) {
      await dispatch({
        type: START_IMPERSONATING_USER,
        payload: impersonatedUserObj,
      });
      loadApplication(impersonatedUserObj?.impersonated_user?.graph);
    } else {
      loadApplication();
    }
  };

  const setUserDetails = async () => {
    try {
      const user = await apiService.getUser(token);
      const { email, id, is_activated, is_superuser, name } = user.data;
      dispatch({
        type: GET_USERNAME,
        payload: { email, id, is_activated, is_superuser, name },
      });
    } catch (error) {
      dispatch({
        type: isExternalPage ? LOGOUT_EXTERNAL_USER : LOGOUT_USER,
      });
    }
  };

  const toggleRightNav = async () => {
    if (!allAnswers.length) {
      return false;
    }
    setRightNavOpen(!rightNavOpen);
    localStorage.removeItem('interactions');
    if (!rightNavOpen) {
      dispatch({
        type: SET_CHAT_MODE,
        payload: false,
      });
      setRunTour(false);
      if (!onboardingFlags.includes('TestIconBot')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'TestIconBot'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'TestIconBot' });
      }
    }
  };

  const loadApplication = async graph => {
    try {
      const res = await apiService.jacLoadApplication(token);
      if (res.data['active:sentinel'] && res.data['active:graph']) {
        const sentinel = res.data['active:sentinel'];
        const activeGraph = res.data['active:graph'];
        const pubAskedQuestion = res.data['spawned:walker:zsb_public_api'];
        const messengerCallback = res.data['spawned:walker:messenger_callback'];
        const viberCallback = res.data['spawned:walker:viber_callback'];
        const pubInfo = res.data['spawned:walker:zsb_public_info'];
        await dispatch({
          type: LOAD_APPLICATION,
          payload: {
            sentinel,
            graph: graph || activeGraph,
            pubAskedQuestion,
            messengerCallback,
            viberCallback,
            pubInfo,
          },
        });
        await loadUserData(sentinel, graph || activeGraph);
        await getPlansWithDetails();
      } else {
        const sentinelResponse = await apiService.setDefaultSentinel(token);
        const graphResponse = await apiService.createGraph(token);
        if (sentinelResponse.status === 200 && graphResponse.status === 200) {
          const {
            data: { sentinel },
          } = sentinelResponse;
          const { data: graph } = graphResponse;

          if (sentinel.jid && graph.jid) {
            await dispatch({
              type: LOAD_APPLICATION,
              payload: {
                sentinel: sentinel.jid,
                graph: graph.jid,
              },
            });
            await loadUserData(sentinel.jid, graph.jid);
            await getPlansWithDetails();
            dispatch({
              type: SHOW_FIRST_LOGIN_PLAN,
            });
          }
        }
      }
    } catch (error) {
      return message.error(error?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const getPlansWithDetails = async () => {
    try {
      const res = await apiService.getPlansWithDetails(sentinel, token);
      dispatch({
        type: SET_PLAN_DETAILS,
        payload: res.data.report[0],
      });
    } catch (error) {
      return message.error(DEFAULT_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token && isEqual(user, initialUserState)) {
          await setUserDetails();
        } else if (token && !sentinel && !graph) {
          checkUserData();
        }
      } catch (error) {
        return message.error(error?.message || GET_DATA_ERROR);
      }
    };

    if (token) {
      localStorage.removeItem('interactions');
      fetchData();
    }
  }, [graph, sentinel, token, user]);

  const toggleExpandSidebarChat = () => {
    dispatch({
      type: TOGGLE_EXPAND_SIDEBAR_CHAT,
    });
  };

  const handleCloseJSONAnswerInModal = () => {
    dispatch({
      type: CLOSE_CHAT_ANSWER_IN_MODAL,
    });
  };

  const handleCloseSelectedSequenceInModal = () => {
    dispatch({
      type: CLOSE_CHAT_SELECTED_SEQUENCE_IN_MODAL,
    });
  };

  useEffect(() => {
    if (!token) {
      dispatch({
        type: isExternalPage ? LOGOUT_EXTERNAL_USER : LOGOUT_USER,
      });
      return push(isExternalPage ? ROUTES.EXTERNAL_LOGIN : ROUTES.LOGIN);
    }
  }, []);

  const validChildren = React.Children.toArray(children).filter(child =>
    React.isValidElement(child)
  );
  const childrenWithRef = validChildren.map((child, index) =>
    React.cloneElement(child, {
      ref: index === validChildren.length - 1 ? lastElementRef : null,
    })
  );

  useEffect(() => {
    const scrollToBottom = () => {
      if (lastElementRef.current) {
        lastElementRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToBottom();
  }, []);

  return {
    allAnswers,
    chatAnswerInModal,
    chatSequenceInModal,
    chatSelectedSequenceInModal,
    isPageReady,
    isAdmin,
    name,
    WITH_PREFIX_BOT_JID,
    location,
    rightNavOpen,
    sentinel,
    toggleRightNav,
    isImpersonating,
    isSidebarChatExpanded,
    hasBotError,
    handleCloseJSONAnswerInModal,
    handleCloseSelectedSequenceInModal,
    showFirstLoginPlan,
    toggleExpandSidebarChat,
    isExternalPage,
    scrollContainerRef,
    childrenWithRef,
  };
};

export default useLayout;
