import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Row, Spin, Typography } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  StyledActionBtns,
  StyledDivider,
  StyledEncircledNumber,
  StyledIntegrationSteps,
  StyledCollapsiblePanel,
  StyledCollapsiblePanelHeader,
  StyledPanelSubheader,
  StyledSnippets,
  StyledCodeBlock,
  StyledCodeButton,
  StyledIntegrationInstallationInstruction,
  StyledIntegrationWPDownload,
} from './Wordpress.styles';
import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Select';
import useWordpress from './hooks';
import BotCustomizer from 'components/BotCustomizer';
import { INTEGRATION_PLATFORMS } from 'constants/integrationPlatforms';
import { DownloadOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;
const ZSB_WORDPRESS_FILE_URI = process.env.REACT_APP_AZURE_BOTCLIENT_URI;
const ZSB_WORDPRESS_FILE_TOKEN =
  process.env.REACT_APP_AZURE_BOTCLIENT_SAS_TOKEN;
const ZSB_WORDPRESS_FIE = process.env.REACT_APP_AZURE_WORDPRESS_FILE;

const Wordpress = ({ isPageReady }) => {
  const {
    activePanel,
    currentIntegrationID,
    currentIntegrationSettings,
    integrationData,
    loading,
    handleChangeFields,
    handlePanelChange,
    handleSaveChannelInfo,
    onSave,
    onUpdate,
    setBotComponent,
  } = useWordpress();
  const getButtonValue = useMemo(() => {
    if (!!currentIntegrationID) {
      return 'Update';
    } else if (loading) {
      return 'Processing';
    }
    return 'Save';
  }, [currentIntegrationID, loading]);

  return (
    <Spin spinning={!isPageReady || loading}>
      <div>
        <Collapse
          activeKey={`wordpress-integration-panel-${activePanel}`}
          accordion
          ghost
          onChange={e => handlePanelChange(e)}
        >
          <StyledCollapsiblePanel
            showArrow={false}
            key={'wordpress-integration-panel-1'}
            header={
              <StyledCollapsiblePanelHeader>
                <StyledEncircledNumber>{'1'}</StyledEncircledNumber>
                <span>{'Channel information'}</span>
              </StyledCollapsiblePanelHeader>
            }
          >
            <StyledIntegrationSteps>
              <form onSubmit={e => handleSaveChannelInfo(e)}>
                <Input
                  disabled={!!currentIntegrationID}
                  value={integrationData.identifier}
                  label="Channel Name"
                  placeholder="My Website integration name"
                  onChange={e =>
                    handleChangeFields('identifier', e.target.value)
                  }
                  required
                />
                <Input
                  value={integrationData.path}
                  label="Website URL"
                  placeholder="Enter website URL"
                  onChange={e => handleChangeFields('path', e.target.value)}
                />
                <Select
                  disabled
                  defaultValue={integrationData.type}
                  onChange={e => handleChangeFields('type', e)}
                  label="Integration Platform"
                  value={INTEGRATION_PLATFORMS['wordpress']}
                />
                <StyledActionBtns>
                  <Button
                    value={getButtonValue}
                    type="submit"
                    loading={loading}
                  />
                  <Button
                    variant="secondary"
                    value="Reset"
                    type="button"
                    onClick={() => handleChangeFields('clear')}
                  />
                </StyledActionBtns>
              </form>
            </StyledIntegrationSteps>
          </StyledCollapsiblePanel>
          <StyledDivider />

          <StyledCollapsiblePanel
            showArrow={false}
            key={'wordpress-integration-panel-2'}
            header={
              <StyledCollapsiblePanelHeader>
                <StyledEncircledNumber>{'2'}</StyledEncircledNumber>
                <span>{'Personalise your bot'}</span>
              </StyledCollapsiblePanelHeader>
            }
          >
            {!!currentIntegrationSettings &&
            !!currentIntegrationSettings.settings ? (
              <BotCustomizer
                setBotComponent={setBotComponent}
                onSave={onSave}
                onUpdate={onUpdate}
              />
            ) : null}
          </StyledCollapsiblePanel>
          <StyledDivider />

          <StyledCollapsiblePanel
            showArrow={false}
            key={'wordpress-integration-panel-3'}
            header={
              <StyledCollapsiblePanelHeader>
                <StyledEncircledNumber>{'3'}</StyledEncircledNumber>
                <span>{'Save and Integrate'}</span>
              </StyledCollapsiblePanelHeader>
            }
          >
            <StyledIntegrationSteps>
              <StyledPanelSubheader>
                {`You are done. Copy the code into your file. Analytics and
                reports for this integration can be viewed in integrated
                channels.`}
              </StyledPanelSubheader>
              <br />
              {!!currentIntegrationSettings?.settings && (
                <StyledSnippets>
                  <Row>
                    <Col
                      xs={{ span: 20 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      <StyledIntegrationWPDownload>
                        Zeroshotbot Plugin for WordPress
                      </StyledIntegrationWPDownload>
                    </Col>
                    <Col
                      xs={{ span: 20, offset: 0 }}
                      md={{ span: 4, offset: 8 }}
                      lg={{ span: 4, offset: 8 }}
                      xl={{ span: 4, offset: 8 }}
                    >
                      <a
                        href={`${ZSB_WORDPRESS_FILE_URI}/${ZSB_WORDPRESS_FIE}?${ZSB_WORDPRESS_FILE_TOKEN}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DownloadOutlined /> Download
                      </a>
                    </Col>
                  </Row>
                  <StyledIntegrationInstallationInstruction>
                    <li>
                      Upload Zeroshotbot plugin directory to WordPress plugins
                      directory (/wp-content/plugins/).
                    </li>
                    <li>
                      Activate the Zeroshotbot plugin through the Plugins menu
                      in WordPress.
                    </li>
                    <li>
                      Click the Zeroshotbot plugin Settings under Zeroshotbot
                      Menu.
                    </li>
                    <li>
                      Copy and paste Bot ID below into the Bot ID field and
                      click Save Changes.
                    </li>
                  </StyledIntegrationInstallationInstruction>
                  <Row>
                    <Col
                      xs={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                    >
                      {' '}
                      <h1>{'Bot ID'}</h1>
                    </Col>
                    <Col
                      xs={{ span: 6, offset: 6 }}
                      md={{ span: 4, offset: 8 }}
                      lg={{ span: 4, offset: 8 }}
                      xl={{ span: 4, offset: 8 }}
                    >
                      {' '}
                      <CopyToClipboard
                        text={
                          currentIntegrationSettings?.code1?.wordpress
                            ?.botComponent
                        }
                      >
                        <StyledCodeButton className="secondary" value="Copy" />
                      </CopyToClipboard>
                    </Col>
                  </Row>
                  <StyledCodeBlock>
                    <Paragraph>
                      <pre>
                        <code>
                          {
                            currentIntegrationSettings?.code1?.wordpress
                              ?.botComponent
                          }
                        </code>
                      </pre>
                    </Paragraph>
                  </StyledCodeBlock>
                </StyledSnippets>
              )}
            </StyledIntegrationSteps>
          </StyledCollapsiblePanel>
        </Collapse>
      </div>
    </Spin>
  );
};

Wordpress.propTypes = {
  isPageReady: PropTypes.bool,
};

export default Wordpress;
