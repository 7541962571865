import { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import { BlobServiceClient } from '@azure/storage-blob';

import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { stripUUID } from 'utils';
import { strippedString } from 'utils/stringManipulation';
import {
  currentIntegrationIDSelector,
  integrationDataSelector,
  currentIntegrationActivePanelSelector,
  integrationPanelPermitSelector,
  initialIntegrationSettingsSelector,
  integrationChannelNameSelector,
} from 'selectors/bot/integration';
import {
  ADD_INTEGRATION,
  RESET_INTEGRATION_SETTINGS,
  SET_CURRENT_INTEGRATION_DATA,
  SET_INTEGRATION_ACTIVE_PANEL,
  SET_INTEGRATION_IDENTIFIER,
  SET_INTEGRATION_PATH,
  SET_INTEGRATION_WIDGET_TYPE,
  SHOW_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  UPDATE_INTEGRATION,
} from 'store/action';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { INTEGRATION_DATA } from 'constants/localStorage';
import { getIntegrationPlatform } from 'utils/integration';
import useSelector from 'store/useSelector';
import { INITIAL_BOT_INTEGRATION_DATA } from 'constants/botCustomizer';

const useMobile = () => {
  const [state, dispatch] = useContext(Context);
  const permit = useSelector(integrationPanelPermitSelector);
  const activePanel = useSelector(currentIntegrationActivePanelSelector);
  const currentIntegrationID = useSelector(currentIntegrationIDSelector);
  const channelName = useSelector(integrationChannelNameSelector);
  const initialIntegrationSettings = useSelector(
    initialIntegrationSettingsSelector
  );
  const currentIntegrationSettings = useSelector(integrationDataSelector);

  const { state: locationState, search } = useLocation();
  const {
    sentinel,
    token,
    bot: { name, jid, integrations },
  } = state;

  const [loading, setLoading] = useState(false);
  const platform = getIntegrationPlatform(search);
  const isDemo = platform.includes('demo');
  const defaultSettings = {
    type: 'mobile',
    settings: {
      identifier: name,
      label: `Ask ${name}`,
    },
  };
  const localIntegration =
    JSON.parse(localStorage.getItem(INTEGRATION_DATA)) || locationState;
  const [botComponent, setBotComponent] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [integrationData, setIntegrationData] = useState(
    localIntegration || defaultSettings
  );
  const [tempIdentifier, setIdentifier] = useState(
    currentIntegrationSettings.identifier
  );
  const [tempPath, setPath] = useState(currentIntegrationSettings.path);
  const [confirmEditModal, setConfirmEditModal] = useState({
    visible: false,
    panel: 1,
  });
  const [enableEdit, setEnableEdit] = useState(false);
  const AZURE_STORAGE_URI = process.env.REACT_APP_AZURE_STORAGE_URI;
  const SAS_TOKEN = process.env.REACT_APP_AZURE_SAS_TOKEN;
  const INTEGRATIONS_CONTAINER_NAME = 'integrations';
  const blobServiceClient = new BlobServiceClient(
    `${AZURE_STORAGE_URI}?${SAS_TOKEN}`
  );
  const webContainerClient = blobServiceClient.getContainerClient(
    INTEGRATIONS_CONTAINER_NAME
  );

  const getDuplicateName = (input, id) => {
    const strippedInput = strippedString(input);
    return integrations.find(
      i => strippedString(i.identifier) === strippedInput && i.id !== id
    );
  };

  const onSave = async data => {
    setLoading(true);
    const isNameExisting = getDuplicateName(data.identifier);
    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('Channel name already exists.');
      }
      const res = await apiService.createIntegration(
        sentinel,
        jid,
        data,
        'mobile',
        token
      );
      await azureUpload(
        res.data.report[0].context.settingsobj,
        res.data.report[0].jid,
        res.data.report[0].context.identifier
      );
      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.int_type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };

      localStorage.setItem(
        INTEGRATION_DATA,
        JSON.stringify({
          ...data,
          ...payload,
        })
      );

      dispatch({ type: ADD_INTEGRATION, payload });
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const onUpdate = async (data, id) => {
    setLoading(true);
    const isNameExisting = getDuplicateName(data.identifier, id);
    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('A channel with the same name already exists.');
      }
      const res = await apiService.editIntegration(
        sentinel,
        id,
        data,
        'mobile',
        token
      );
      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.int_type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };
      localStorage.setItem(
        INTEGRATION_DATA,
        JSON.stringify({
          ...data,
          ...payload,
        })
      );
      dispatch({ type: UPDATE_INTEGRATION, payload });
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const azureUpload = async (
    integrationData,
    integrationID,
    integrationName
  ) => {
    const folderPath = stripUUID(jid);
    const integrationConfigURL = `${folderPath}/${stripUUID(
      integrationID
    )}/config.json`;
    const configFileType = 'application/json';
    const configBlob = new Blob(
      [
        JSON.stringify({
          ...integrationData,
          integration: {
            name: integrationName,
            id: integrationID,
          },
        }),
      ],
      {
        type: configFileType,
      }
    );
    const blobOptions = {
      blobHTTPHeaders: {
        blobContentType: configBlob.type,
        blobCacheControl: 'public, max-age=0',
      },
    };
    const blockConfigBlob =
      webContainerClient.getBlockBlobClient(integrationConfigURL);

    // finally uploading the config file
    await blockConfigBlob.upload(configBlob, configBlob.size, blobOptions);
  };

  const handleChangeFields = (name, value) => {
    switch (name) {
      case 'identifier':
        setIdentifier(value);
        break;
      case 'path':
        setPath(value);
        break;
      case 'widgetType':
        dispatch({ type: SET_INTEGRATION_WIDGET_TYPE, payload: value });
        break;
      case 'clear':
        if (showColorPicker) {
          setShowColorPicker(!showColorPicker);
        }
        setBotComponent(null);
        setIntegrationData(initialIntegrationSettings);
        break;
      default:
        break;
    }
  };

  const handleBlurFormField = name => {
    switch (name) {
      case 'identifier':
        dispatch({
          type: SET_INTEGRATION_IDENTIFIER,
          payload: tempIdentifier || channelName,
        });
        break;
      case 'path':
        dispatch({
          type: SET_INTEGRATION_PATH,
          payload: tempPath || currentIntegrationSettings.path,
        });
        break;
      case 'clear':
        if (showColorPicker) {
          setShowColorPicker(!showColorPicker);
        }
        setBotComponent(null);
        setIntegrationData(initialIntegrationSettings);
        break;
      default:
        break;
    }
  };

  const handleSaveChannelInfo = async evt => {
    setLoading(true);
    evt.preventDefault();
    try {
      const isNewIntegration =
        !localIntegration || !localIntegration.id || !currentIntegrationID;
      const integrationDataPayload = {
        ...integrationData,
        settings: isNewIntegration
          ? {
              ...INITIAL_BOT_INTEGRATION_DATA.settings,
              ...integrationData.settings,
            }
          : integrationData.settings,
      };

      const res = isNewIntegration
        ? await onSave(integrationDataPayload)
        : await onUpdate(integrationDataPayload, currentIntegrationID);

      if (res.id) {
        dispatch({
          type: SET_CURRENT_INTEGRATION_DATA,
          payload: res,
        });
        dispatch({
          type: SHOW_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL,
        });
        message.success(
          isNewIntegration
            ? `Integration channel added`
            : `Successfully updated integration channel`
        );
      } else {
        return;
      }
    } catch (error) {
      return message.error(
        error || `An error encountred while adding the integration channel.`
      );
    }
    setLoading(false);
  };

  const handlePanelChange = panel => {
    const panelNumber = panel?.split('-').pop();
    if (permit >= 3 && panelNumber < permit && !enableEdit) {
      setConfirmEditModal({ visible: true, panel: panelNumber });
    } else if (panelNumber <= permit) {
      dispatch({
        type: SET_INTEGRATION_ACTIVE_PANEL,
        payload: { panel: panelNumber },
      });
    } else if (permit < panelNumber) {
      return message.error(
        `Please complete the current step before you proceed to step ${panelNumber}.`
      );
    }
  };

  useEffect(() => {
    dispatch({
      type: SET_CURRENT_INTEGRATION_DATA,
      payload: integrationData,
    });

    if (currentIntegrationID) {
      setIntegrationData(currentIntegrationSettings);
    }

    return () => {
      dispatch({
        type: RESET_INTEGRATION_SETTINGS,
      });
      localStorage.removeItem(INTEGRATION_DATA);
    };
  }, []);

  useEffect(() => {
    if (currentIntegrationSettings?.id) {
      setIntegrationData(currentIntegrationSettings);
    }
  }, [currentIntegrationSettings]);

  return {
    activePanel,
    confirmEditModal,
    currentIntegrationID,
    dispatch,
    integrationData,
    currentIntegrationSettings,
    isDemo,
    loading,
    permit,
    showColorPicker,
    handleChangeFields,
    handlePanelChange,
    handleSaveChannelInfo,
    onSave,
    onUpdate,
    setBotComponent,
    setConfirmEditModal,
    setEnableEdit,
    setShowColorPicker,
    handleBlurFormField,
  };
};

export default useMobile;
