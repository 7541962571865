import {
  ADD_BOT,
  CLOSE_BOT_MODAL,
  CLOSE_WORDPRESS_MODAL,
  DELETE_BOT,
  IMPORT_BOT,
  REMOVE_WEAK_BOT_STATUS,
  SET_BOTS,
  SET_BOT_AS_WEAK_BOT,
  SET_BOT_MODES,
  SET_TEMP_BOT,
  SET_VIEW_TYPE,
  SHOW_BOT_MODAL,
  UPDATE_BOT,
  UPDATE_BOT_SYNC,
  VIEW_BOT,
  WORD_PRESS_SUCCESS,
} from 'store/action';
import { initialBotState } from 'store/initialState';
import { stripUUID } from 'utils';
import { getAllBotsData } from './helpers/bots';
import { BOT_MODES, DEFAULT_BOT_MODE } from 'constants/bot';
import {
  extractAnswerData,
  extractDefaultAnswer,
  extractFileData,
} from './helpers/bot/answers';
import {
  generateAuthenticatedUserNPMHTMLTag,
  generateBotComponent,
  generateEncryptedWordPressBot,
  generateWebHTMLTagHeader,
} from 'services/fileGenerator.service';
import { extractBotData } from './helpers/bot/botDetails';
import { botStatistics } from 'utils/bot';

export const botsReducer = (state, action) => {
  switch (action.type) {
    case SET_BOTS: {
      const { bots, order } = action.payload;
      const isExternalPage = Boolean(localStorage.getItem('external')) || false;

      if (!bots || !bots.length) {
        return {
          ...state,
          layout: {
            ...state.layout,
            wordPressModal: {
              visible: isExternalPage,
            },
          },
        };
      }

      const allBots = getAllBotsData(bots, order);

      return {
        ...state,
        bots: allBots,
        layout: {
          ...state.layout,
          wordPressModal: {
            visible: false,
          },
        },
      };
    }

    case SET_TEMP_BOT:
      return {
        ...state,
        tempBot: action.payload,
      };

    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };

    case ADD_BOT: {
      const newBot = action.payload;
      // TODO: create bot data extractor
      const bot = {
        jid: newBot.jid,
        name: newBot.context.name,
        mode: newBot.context.mode || DEFAULT_BOT_MODE,
        description: newBot.context.description,
        metadata: newBot.context.metadata || {},
        avatar: newBot.context.name.substring(0, 2),
        // first index or [0] is the bot information
        answerCount: newBot.length - 1 || 0,
        createdAt: newBot.context.created_time,
        lastUpdatedAt: newBot.context.last_updated_time,
      };

      return {
        ...state,
        bots: [bot, ...state.bots],
        plan: {
          ...state.plan,
          current_bot_count: state.plan.current_bot_count + 1,
        },
      };
    }

    case IMPORT_BOT: {
      const newBot = action.payload[0];
      const { jid, context } = newBot;
      const bot = {
        jid: jid,
        name: context.name,
        description: context.description,
        metadata: context.metadata || {},
        avatar: context.name.substring(0, 2),
        // first index or [0] is the bot information
        answerCount: action.payload.answerCount || 0,
        createdAt: context.created_time,
        lastUpdatedAt: context.last_updated_time,
        autoTranslateResponse: context.auto_translate_response,
        language: context.language,
        mode: context.mode,
      };

      return {
        ...state,
        bots: [bot, ...state.bots],
        plan: {
          ...state.plan,
          current_bot_count: state.plan.current_bot_count + 1,
        },
      };
    }

    case VIEW_BOT:
      return {
        ...state,
        bot: {
          ...initialBotState,
          ...action.payload,
          strippedBotID: stripUUID(action.payload.jid),
        },
      };

    case SET_BOT_MODES:
      return {
        ...state,
        botModes: action.payload,
      };

    case DELETE_BOT:
      const filteredBots = state.bots.filter(bot => bot.jid !== action.payload);

      return {
        ...state,
        bots: filteredBots,
        plan: {
          ...state.plan,
          current_bot_count: state.plan.current_bot_count - 1,
        },
      };

    case UPDATE_BOT: {
      const { last_updated_time, created_time, ...rest } = action.payload;
      const synced =
        state.bot.mode !== BOT_MODES.ZSB ? false : state.bot.synced;
      const updatedBots = state.bots.map(item => {
        if (item.jid === action.payload.jid) {
          const bot = {
            ...item,
            ...rest,
            createdAt: action.payload.created_time,
            lastUpdatedAt: action.payload.last_updated_time,
          };
          return bot;
        }
        return item;
      });

      return {
        ...state,
        bot:
          state.bot.jid === action.payload.jid
            ? {
                ...state.bot,
                ...action.payload,
              }
            : state.bot,
        synced,
        bots: [...updatedBots],
      };
    }

    case UPDATE_BOT_SYNC: {
      return {
        ...state,
        bot:
          state.bot.jid === action.payload.jid
            ? {
                ...state.bot,
                synced: true,
              }
            : state.bot,
      };
    }

    case SET_BOT_AS_WEAK_BOT: {
      const { location, jid } = action.payload;
      if (location !== 'bots') {
        return state;
      }

      const updatedBots = state.bots.map(item => {
        if (item.jid === jid) {
          const bot = {
            ...item,
            showWeakStat: true,
          };
          return bot;
        }
        return item;
      });

      return {
        ...state,
        bots: [...updatedBots],
      };
    }

    case REMOVE_WEAK_BOT_STATUS: {
      const { location, jid } = action.payload;
      if (location !== 'bots') {
        return state;
      }

      const updatedBots = state.bots.map(item => {
        if (item.jid === jid) {
          const bot = {
            ...item,
            showWeakStat: false,
          };
          return bot;
        }
        return item;
      });

      return {
        ...state,
        bots: [...updatedBots],
      };
    }

    case SHOW_BOT_MODAL: {
      return {
        ...state,
        layout: {
          ...state.layout,
          botModal: {
            visible: true,
            ...action.payload,
          },
        },
      };
    }

    case CLOSE_BOT_MODAL: {
      return {
        ...state,
        layout: {
          ...state.layout,
          botModal: {
            visible: false,
          },
        },
      };
    }

    case CLOSE_WORDPRESS_MODAL: {
      return {
        ...state,
        layout: {
          ...state.layout,
          wordPressModal: {
            visible: false,
          },
        },
      };
    }

    case WORD_PRESS_SUCCESS: {
      const { integration, file, botInformation } = action.payload;
      const newBotCreated = botInformation[0];
      const isWebsite = file.context?.scraping_info;
      const { pubAskedQuestion, publicKey, sentinel, pubInfo } = state;
      const newAnswers = !isWebsite
        ? extractFileData(file)
        : [extractAnswerData(file, true)];
      const folderPath = stripUUID(newBotCreated.jid);
      const integrationID = stripUUID(integration.id);
      const integrationConfigURL = `${folderPath}/${integrationID}/config.json`;
      const botData = extractBotData(newBotCreated);
      const defaultAnswerData = extractDefaultAnswer(
        botInformation[1]?.context,
        botInformation[1]?.jid
      );

      const integrationSettings = {
        ...state.bot.currentIntegrationSettings.settings,
        botTitle:
          state.bot.currentIntegrationSettings.settings?.botTitle ||
          state.bot.currentIntegrationSettings.settings?.title ||
          state.bot.currentIntegrationSettings.identifier,
        ...integration.settings,
        viber_version: undefined,
      };

      const currentIntegrationSettings = {
        ...state.bot.currentIntegrationSettings,
        ...integration,
        code1: {
          html: {
            botComponent: generateBotComponent(
              newBotCreated.jid,
              sentinel,
              publicKey,
              pubAskedQuestion,
              integration,
              integrationConfigURL,
              integration?.settings?.height,
              false,
              false
            ),
            headerCode: generateWebHTMLTagHeader(
              integration?.settings?.authenticatedUser,
              newBotCreated?.currentIntegrationSettings?.widgetVersion
            ),
          },
          npm: {
            botComponent: generateBotComponent(
              newBotCreated.jid,
              sentinel,
              publicKey,
              pubAskedQuestion,
              integration,
              integrationConfigURL,
              integration?.settings?.height,
              false,
              false,
              newBotCreated?.currentIntegrationSettings?.widgetVersion
            ),
            headerCode: generateAuthenticatedUserNPMHTMLTag(
              integration?.settings?.authenticatedUser
            ),
          },
          wordpress: {
            botComponent: generateEncryptedWordPressBot(
              newBotCreated.jid,
              sentinel,
              integrationConfigURL,
              publicKey,
              pubAskedQuestion,
              pubInfo
            ),
          },
        },
        code2: null,
        settings: integrationSettings,
        widgetVersion: 'v1',
        panel: {
          permit: 3,
          activePanel: 3,
        },
      };

      const bots = {
        jid: newBotCreated.jid,
        name: newBotCreated.context.name,
        mode: newBotCreated.context.mode || DEFAULT_BOT_MODE,
        description: newBotCreated.context.description,
        metadata: newBotCreated.context.metadata || {},
        avatar: newBotCreated.context.name.substring(0, 2),
        createdAt: newBotCreated.context.created_time,
        lastUpdatedAt: newBotCreated.context.last_updated_time,
        fileCount: !isWebsite && newAnswers.length + state.bot.fileCount,
        websiteCount: isWebsite && newAnswers.length + state.bot.websiteCount,
      };

      const bot = {
        ...state.bot,
        ...botData,
        integrations: [integration, ...state.bot.integrations],
        files: !isWebsite ? newAnswers : [],
        websites: isWebsite ? newAnswers : [],
        currentIntegrationSettings,
        strippedBotID: stripUUID(newBotCreated.jid),
        ui: {
          ...state.bot.ui,
          weakBotStats: {
            overview: botStatistics({
              data: botInformation,
              botJID: newBotCreated.jid,
            }).isWeakBot,
          },
        },
        defaultAnswer: [defaultAnswerData],
      };

      return {
        ...state,
        bots: [bots, ...state.bots],
        bot,
        plan: {
          ...state.plan,
          current_bot_count: state.plan.current_bot_count + 1,
        },
        webSocket: {
          ...state.webSocket,
          fileAdded: [],
          scannedTableDataSource: [],
          categorizedTableDataSource: [],
          scan: [],
          categorizedUrls: [],
          activeAction: null,
          triggerID: null,
          websiteFile: [],
        },
      };
    }

    default:
      return state;
  }
};
