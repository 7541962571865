import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modals/GenericModal';
import { StyledSuiteInput } from './CreateTestsuiteModal.styles';

const CreateTestsuiteModal = ({
  showCreateSuiteModal,
  handleCreateNewSuite,
  handleCloseCreateSuiteModal,
}) => {
  const [newSuiteName, setNewSuiteName] = useState('');

  const handleChangeNewSuiteName = e => {
    setNewSuiteName(e.target.value);
  };

  return (
    <Modal
      visible={showCreateSuiteModal}
      title="Suite Name"
      okButtonProps={{ disabled: !newSuiteName }}
      onOk={() => handleCreateNewSuite(null, newSuiteName)}
      onCancel={handleCloseCreateSuiteModal}
    >
      <form onSubmit={e => handleCreateNewSuite(e, newSuiteName)}>
        <StyledSuiteInput
          required
          autoFocus
          placeholder="Enter Test suite name"
          value={newSuiteName}
          onChange={handleChangeNewSuiteName}
        />
      </form>
    </Modal>
  );
};

CreateTestsuiteModal.propTypes = {
  showCreateSuiteModal: PropTypes.bool,
  handleCreateNewSuite: PropTypes.func,
  handleCloseCreateSuiteModal: PropTypes.func,
};

export default CreateTestsuiteModal;
