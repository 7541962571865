import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ApiFilled,
  ApiTwoTone,
  DeleteFilled,
  DeleteTwoTone,
  EditTwoTone,
  EyeTwoTone,
} from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';

import { cssVariables } from 'styles/root';
import AnchorButton from 'components/Button/AnchorButton';
import {
  StyledFlexColumn,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';
import useQuestion from './hooks';
import { StyledMore } from 'components/AnswerItem/Answer.styles';
import { StyledAnswerScore } from 'components/AnswerWithScore/AnswerWithScore.styles';
import ToolTip from 'components/ToolTips/BaseToolTip';
import { FlexRowSpaceBetweenWrapper } from 'components/Modals/AnswerEditor/Variant/Variant.styles';
import Button from 'components/Button';
import Input from 'components/Input';
import { AnswerRenderer } from 'components/AnswerRenderer';

const QuestionFlexColumn = styled(StyledFlexColumn)`
  box-shadow: ${cssVariables.itemListShadow};
  padding: 10px;

  .ant-input {
    width: 100%;
  }
`;

const StyledQuestionMoreMenu = styled(StyledMore)`
  width: 36px;
  background-color: ${cssVariables.gray2};
  border-radius: 50%;
`;

const StyledLeftActionButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  .anticon {
    font-size: 14px;
  }
`;

const Question = ({ item, showDisplayAnswer, deleteOnly, noScore }) => {
  const {
    editing,
    handleDeleteHardLink,
    handleDeleteQuestionThenHardLinkAnswer,
    handleSaveNewQuestion,
    setEditing,
    setNewQuestion,
    setViewAnswer,
    viewAnswer,
  } = useQuestion();

  // component prop
  const { question, answer, score } = item;

  const handleClickMenuItem = item => {
    switch (item.key) {
      case 'show-answer': {
        return setViewAnswer(!viewAnswer);
      }
      case 'delete-question-and-link-answer':
        return handleDeleteQuestionThenHardLinkAnswer(question, answer?.jid);
      case 'remove-hard-link':
        return handleDeleteHardLink(question?.jid);
      default:
        return false;
    }
  };

  const QUESTION_MENU_ITEMS = [
    {
      key: 'show-answer',
      label: viewAnswer ? 'Hide Answer' : 'Show Answer',
      icon: <EyeTwoTone />,
    },
    {
      key: 'delete-question-and-link-answer',
      label: 'Remove Question then Hard Link Answer',
      icon: <ApiTwoTone />,
    },
    {
      key: 'remove-hard-link',
      label: 'Remove Question Link',
      icon: <DeleteFilled style={{ color: cssVariables.errorText }} />,
    },
  ];

  const moreMenu = (
    <Menu onClick={handleClickMenuItem}>
      {QUESTION_MENU_ITEMS.map(item => (
        <Menu.Item key={item.key} icon={item.icon}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <QuestionFlexColumn>
      <StyledSpaceBetweenFlexCenter>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <StyledAnswerScore className={deleteOnly ? '' : 'answerScore'}>
            {deleteOnly ? (
              <ApiTwoTone />
            ) : (
              <ToolTip title={deleteOnly ? 'Score' : 'Similarity Score'}>
                {score && !noScore ? score?.toFixed(3) : <ApiTwoTone />}
              </ToolTip>
            )}
          </StyledAnswerScore>
          {!editing ? (
            <span>{question?.context?.text}</span>
          ) : (
            <Input
              full
              defaultValue={question?.context?.text}
              onChange={evt => setNewQuestion(evt.target.value)}
            />
          )}
        </div>
        <StyledLeftActionButtons>
          {deleteOnly ? (
            <FlexRowSpaceBetweenWrapper>
              {editing ? (
                <Button
                  size="small"
                  value="Save"
                  variant="link"
                  onClick={() =>
                    handleSaveNewQuestion(question?.jid, answer?.jid)
                  }
                />
              ) : (
                <EditTwoTone onClick={() => setEditing(true)} />
              )}
              <DeleteTwoTone
                twoToneColor={cssVariables.red10}
                onClick={() => handleDeleteHardLink(question?.jid)}
              />
            </FlexRowSpaceBetweenWrapper>
          ) : (
            <Dropdown
              overlay={moreMenu}
              placement="bottomLeft"
              trigger={['click']}
            >
              <StyledQuestionMoreMenu />
            </Dropdown>
          )}
        </StyledLeftActionButtons>
      </StyledSpaceBetweenFlexCenter>
      {viewAnswer ? (
        <StyledFlexColumn style={{ marginTop: 10 }}>
          {
            <StyledSpaceBetweenFlexCenter
              style={{ alignSelf: 'start', marginTop: 5 }}
            >
              <b>
                <ApiFilled />
                {' Hard Linked Answer: '}
              </b>
              <AnchorButton
                title={'Remove Question then Hard Link Answer'}
                onClick={() =>
                  handleDeleteQuestionThenHardLinkAnswer(question, answer?.jid)
                }
              />
            </StyledSpaceBetweenFlexCenter>
          }
          <AnswerRenderer answer={answer} />
        </StyledFlexColumn>
      ) : null}
    </QuestionFlexColumn>
  );
};

Question.propTypes = {
  item: PropTypes.object,
  showDisplayAnswer: PropTypes.bool,
  deleteOnly: PropTypes.bool,
  noScore: PropTypes.bool,
};

export default Question;
