import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { FeatureFlagStyledMenu, StyledMenu } from './AnswerMenu.style';
import { withFeatureFlag } from 'components/hoc/withFeatureFlags';
import LimitationTooltip from 'components/ToolTips/LimitationToolTip';
import useSelector from 'store/useSelector';
import {
  isTextGenerateEnabledSelector,
  isTextIngestEnabledSelector,
} from 'selectors/plan';
import { GENERATE_FAQ, TEXT_GENERATE } from 'constants/featureFlags';

const AnswerMenu = ({ handleOpenModal, handleOpenAddAnswerModal }) => {
  const isTextIngestEnabled = useSelector(isTextIngestEnabledSelector);
  const isTextGenerateEnabled = useSelector(isTextGenerateEnabledSelector);

  const GenerateFAQFeature = withFeatureFlag(
    GENERATE_FAQ,
    <FeatureFlagStyledMenu
      key="text-generate"
      onClick={() =>
        isTextGenerateEnabled ? handleOpenModal('generate-faq') : {}
      }
      style={{ background: '#fff' }}
    >
      {isTextGenerateEnabled ? (
        <div>{`Generate FAQ`}</div>
      ) : (
        <LimitationTooltip
          isBordered={false}
          disabledFeat={'Generate FAQ'}
          style={{ padding: 0 }}
          title="Upgrade to access Generate FAQ"
        />
      )}
    </FeatureFlagStyledMenu>
  );

  const GenerateTextGenerate = withFeatureFlag(
    TEXT_GENERATE,
    <FeatureFlagStyledMenu
      key="text-generate"
      onClick={() =>
        isTextGenerateEnabled ? handleOpenModal('text-generate') : {}
      }
      style={{ background: '#fff' }}
    >
      {isTextGenerateEnabled ? (
        <div>{`Text Generate`}</div>
      ) : (
        <LimitationTooltip
          isBordered={false}
          disabledFeat={'Text Generate'}
          style={{ padding: 0 }}
          title="Upgrade to access Text Generate"
        />
      )}
    </FeatureFlagStyledMenu>
  );

  return (
    <StyledMenu>
      <Menu.Item key="planText" onClick={handleOpenAddAnswerModal}>
        {`Plain Text`}
      </Menu.Item>
      <Menu.Item key="csv" onClick={() => handleOpenModal('csv')}>
        {`Import .CSV or .TXT`}
      </Menu.Item>
      <Menu.Item key="json" onClick={() => handleOpenModal('json')}>
        Import .JSON
      </Menu.Item>
      {isTextIngestEnabled ? (
        <Menu.Item
          key="ingest"
          onClick={() => handleOpenModal('text-ingest')}
          className="text-ingest"
        >
          {`Text Summarize`}
        </Menu.Item>
      ) : (
        <Menu.Item key="text-ingest">
          <LimitationTooltip
            isBordered={false}
            disabledFeat={'Text Summarize'}
            style={{ padding: 0 }}
            title="Upgrade to access Text Summarize"
          />
        </Menu.Item>
      )}
      <GenerateTextGenerate />
      <GenerateFAQFeature />
    </StyledMenu>
  );
};

AnswerMenu.propTypes = {
  handleOpenModal: PropTypes.func,
  handleOpenAddAnswerModal: PropTypes.func,
};

export default AnswerMenu;
