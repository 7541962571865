import { useContext, useEffect, useState } from 'react';

import { useQuery } from 'pages/useQuery';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import {
  botTitleSelector,
  currentIntegrationSettingsSettingPropSelector,
  isDemoIntegrationSelector,
} from 'selectors/bot/integration';
import {
  SET_BOT_CUSTOMIZER_ACTIVE_PANEL,
  SET_WIDGET_PLACEHOLDER,
  SET_WIDGET_SUBTITLE,
  SET_WIDGET_TITLE,
  SET_WIDGET_WELCOME_MESSAGE,
  TOGGLE_WIDGET_USER_AUTHETICATED_SWITCH,
} from 'store/action';

export const useConfigPanel = () => {
  const query = useQuery();
  const [, dispatch] = useContext(Context);

  const settings = useSelector(currentIntegrationSettingsSettingPropSelector);
  const botTitle = useSelector(botTitleSelector);
  const isDemo = useSelector(isDemoIntegrationSelector, query.get('platform'));

  const { subtitle, placeholder, welcomeMessage, authenticatedUser } = settings;

  const [tempTitle, setTitle] = useState(botTitle);
  const [tempSubitle, setSubtitle] = useState(subtitle);
  const [tempWelcomeMessage, setWelcomeMessage] = useState(welcomeMessage);
  const [tempPlaceholder, setPlaceholder] = useState(placeholder);

  const handleInputChange = key => evt => {
    switch (key) {
      case 'title':
        setTitle(evt.target.value);
        break;

      case 'subtitle':
        setSubtitle(evt.target.value);
        break;

      case 'placeholder':
        setPlaceholder(evt.target.value);
        break;

      case 'welcomeMessage':
        setWelcomeMessage(evt.target.value);
        break;

      default:
        break;
    }
  };

  const handlePanelChange = async val => {
    dispatch({ type: SET_BOT_CUSTOMIZER_ACTIVE_PANEL, payload: val });
  };

  useEffect(() => {
    // Necessary for demo integration cuz demo config
    // has to be fetched unlike existing integrations
    // has already the configs from the table of integrations
    if (isDemo) {
      if (botTitle !== tempTitle) {
        setTitle(botTitle);
      }
      if (subtitle !== tempSubitle) {
        setSubtitle(subtitle);
      }
      if (welcomeMessage !== tempWelcomeMessage) {
        setWelcomeMessage(botTitle);
      }
      if (placeholder !== tempPlaceholder) {
        setPlaceholder(botTitle);
      }
    }
  }, [botTitle, subtitle, welcomeMessage, placeholder, isDemo]);

  const handleBlur = key => evt => {
    // Add delay cuz user might have clicked other component
    // other than the expected next input field
    setTimeout(() => {
      switch (key) {
        case 'title':
          // dont fire if no changes made
          if (tempTitle && tempTitle !== botTitle) {
            dispatch({ type: SET_WIDGET_TITLE, payload: tempTitle });
          }
          break;

        case 'subtitle':
          if (tempSubitle && tempSubitle !== subtitle) {
            dispatch({ type: SET_WIDGET_SUBTITLE, payload: tempSubitle });
          }
          break;

        case 'placeholder':
          if (tempPlaceholder && tempPlaceholder !== placeholder) {
            dispatch({
              type: SET_WIDGET_PLACEHOLDER,
              payload: tempPlaceholder,
            });
          }
          break;

        case 'welcomeMessage':
          if (tempWelcomeMessage && tempWelcomeMessage !== welcomeMessage) {
            dispatch({
              type: SET_WIDGET_WELCOME_MESSAGE,
              payload: tempWelcomeMessage,
            });
          }
          break;

        default:
          break;
      }
    }, 50);
  };

  const handleChangeSwitch = val => {
    dispatch({
      type: TOGGLE_WIDGET_USER_AUTHETICATED_SWITCH,
      payload: val,
    });
  };
  return {
    handleBlur,
    handleChangeSwitch,
    handleInputChange,
    handlePanelChange,
    authenticatedUser,
    isDemo,
    tempTitle,
    tempSubitle,
    tempPlaceholder,
    tempWelcomeMessage,
    subtitle,
    botTitle,
    welcomeMessage,
    placeholder,
  };
};
