import React, { useMemo } from 'react';
import { Route, Link, useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';

import { Container, CenteredMessage } from './Admin.style';
import { adminSidebarRoutes } from './adminSidebarRoutes';
import ROUTES from 'constants/routes';
import Layout from 'components/Layout';
import useSelector from 'store/useSelector';
import { isAdminSelector } from 'selectors/admin';
import { userSelector } from 'selectors/user';
import { initialUserState } from 'store/initialState';

const AdminPage = () => {
  const location = useLocation();
  const isMainPage = useMemo(
    () =>
      adminSidebarRoutes.find(route => location.pathname === route.path)
        ?.key === 'admin',
    [location.pathname, adminSidebarRoutes]
  );
  const isAdmin = useSelector(isAdminSelector);
  const user = useSelector(userSelector);

  if (!isAdmin && !isEqual(user, initialUserState)) {
    return (
      <Layout>
        <CenteredMessage>
          <span>You are not allowed to access this page.</span>
          <Link to={ROUTES.BOTS_PAGE}>Back to bots page.</Link>
        </CenteredMessage>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container mainPage={isMainPage}>
        {adminSidebarRoutes.map(routes => {
          return (
            <Route
              key={routes.path}
              path={routes.path}
              component={routes.component}
              exact
            />
          );
        })}
      </Container>
    </Layout>
  );
};

export default AdminPage;
