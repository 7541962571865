import styled from 'styled-components';
import Input from 'components/Input';
import { cssVariables } from 'styles/root';
import { Select } from 'antd';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';

export const StyledQuickReplyLabel = styled(Input)`
  width: 100%;
`;

export const StyledQuickReplyQuestion = styled(Input)`
  width: 100%;
  margin-left: 10px;
`;

export const StyledQuickReplyAnswerList = styled(Select)`
  width: 100%;
  height: auto;

  & .ant-select-selector {
    height: auto !important;
    & .ant-select-selection-item {
      white-space: pre-wrap;
      text-overflow: ellipsis;
      overflow: inherit;
    }

    & .ant-select-item-option-content {
      white-space: pre-wrap;
      text-overflow: ellipsis;
      overflow: inherit;
      height: auto;
    }
  }

  & .ant-select-item-option-content {
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: inherit;
  }
`;

export const StyledEditCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 10px;
  align-items: center;
  font-size: 11px;
  font-weight: ${cssVariables.font.bold};

  > span:first-child {
    margin-top: -10px;
  }
`;

export const StyledActionButtons = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }

  > * + * {
    margin: 0px 5px;
  }

  .anticon:not(.anticon-close) {
    font-size: 20px;
  }

  .ant-btn {
    &:hover,
    &:active {
      background-color: transparent !important;
    }
  }
`;

export const StyledDraggableWrapper = styled.div`
  margin: auto 5px 5px auto;
`;

export const StyledAnswerLabel = styled(StyledFlexRowLeft)`
  margin-left: 0;
  width: 100%;
  display: flex;
  padding: 10px;
  cursor: pointer;
`;

export const StyledLabel = styled(StyledFlexRowLeft)`
  width: 20%;
  display: flex;
`;

export const StyledDeleteRowButton = styled.div`
  margin: auto auto auto 5px;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;
