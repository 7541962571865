import styled from 'styled-components';
import { cssVariables } from 'styles/root';
import { MoreOutlined } from '@ant-design/icons';
import Button from 'components/Button';

export const StyledAskedQuestionModalTitle = styled.h4`
  font-family: Roboto;
  font-weight: ${cssVariables.font.normal};
  font-size: 18px;
  color: #000;
  margin-top: 8px;
`;

export const StyledQuestionLogItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${cssVariables.gray4};
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;

  &:hover {
    background-color: ${cssVariables.blue1};
  }
`;

export const StyledAskedQuestion = styled.div`
  font-family: Helvetica;
  font-size: 15px;
  color: ${cssVariables.primaryGray};
  width: 75%;
`;

export const StyledAskedQuestionTimeAndAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledAskedQuestionTime = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  color: #26292d;
`;

export const StyledMoreOutlined = styled(MoreOutlined)`
  transform: rotate(-90deg);
  padding: 10px;
  margin-left: 10px;
  color: #98a5ac;

  &:hover {
    background: ${cssVariables.gray4};
    border-radius: 50%;
  }
`;

export const StyledDatePickerButton = styled(Button)`
  padding: 3px 12px;
  height: 30px;
  margin-left: 5px;
`;

export const StyledFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding-top: 5px;
`;

export const StyledDateFilterLabel = styled.div`
  font-size: 15px;
  color: #33363c;
  margin-right: 6px;
`;
