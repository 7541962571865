import { useContext, useEffect, useState } from 'react';
import { camelCase, isEqual } from 'lodash';

import { isBotMailConfigEnabledSelector } from 'selectors/bot';
import { currentIntegrationSettingsSettingPropSelector } from 'selectors/bot/integration';
import useSelector from 'store/useSelector';
import { widgetFormFieldsSelectors } from 'selectors/bot/integration/settings';
import { Context } from 'store/store';
import {
  ADD_INTEGRATION_HANDOFF_USER_INFO_FIELD,
  CHANGE_INTEGRATION_HANDOFF_USER_INFO_FIELD,
  REMOVE_INTEGRATION_HANDOFF_USER_INFO_FIELD,
  SET_BOT_CUSTOMIZER_ACTIVE_PANEL,
  SET_INTEGRATION_HANDOFF_CANCEL_RESPONSE,
  SET_INTEGRATION_HANDOFF_LABEL,
  SET_INTEGRATION_HANDOFF_SUCCESS_RESPONSE,
  TOGGLE_INTEGRATION_HANDOFF_CALLBACK,
  TOGGLE_INTEGRATION_HANDOFF_DISPLAY_FORM,
} from 'store/action';

const useFeedbackAndHandOff = () => {
  const [, dispatch] = useContext(Context);
  const {
    authenticatedUser,
    callbackEmail,
    cancelledFormMessage,
    displayFormFields,
    handoffLabel,
    submittedFormMessage,
  } = useSelector(currentIntegrationSettingsSettingPropSelector);
  const isSendCallbackLog = useSelector(isBotMailConfigEnabledSelector);
  const widgetFormFields = useSelector(widgetFormFieldsSelectors);

  const [tempFormFields, setTempFormFields] = useState(widgetFormFields);
  const [tempLabel, setTempLabel] = useState(handoffLabel);
  const [tempMessageSent, setTempMessageSent] = useState(submittedFormMessage);
  const [tempCancelledMessage, setTempCancelledMessage] =
    useState(cancelledFormMessage);

  const handleChangeFormFields = (e, key, checkbox) => {
    const newFormFieldsVal = tempFormFields.map((f, idx) => {
      if (idx === key) {
        if (checkbox && e.target.checked) {
          f.mandatory = true;
        } else if (checkbox && !e?.target?.checked) {
          f.mandatory = false;
        } else if (!checkbox && e?.target?.value) {
          f.attribute = camelCase(e.target.value);
          f.label = e.target.value;
        }
      }
      return f;
    });
    return setTempFormFields(newFormFieldsVal);
  };

  const handleAddFormField = () => {
    dispatch({
      type: ADD_INTEGRATION_HANDOFF_USER_INFO_FIELD,
    });
  };

  const handleBlurFormField = key => {
    const fieldIndexToChange = tempFormFields.findIndex(
      (f, idx) => Number(idx) === Number(key)
    );
    if (
      fieldIndexToChange &&
      !isEqual(
        widgetFormFields[fieldIndexToChange],
        tempFormFields[fieldIndexToChange]
      )
    ) {
      dispatch({
        type: CHANGE_INTEGRATION_HANDOFF_USER_INFO_FIELD,
        payload: {
          ...tempFormFields[fieldIndexToChange],
          key,
        },
      });
    }
  };

  useEffect(() => {
    if (!isEqual(tempFormFields, widgetFormFields)) {
      setTempFormFields(widgetFormFields);
    }
  }, [widgetFormFields]);

  const handleDeleteFormRow = attr => {
    dispatch({
      type: REMOVE_INTEGRATION_HANDOFF_USER_INFO_FIELD,
      payload: attr,
    });
  };

  const handleToggleCallbackEmail = attr => {
    dispatch({
      type: TOGGLE_INTEGRATION_HANDOFF_CALLBACK,
    });
  };

  const handleToggleDisplayFormFields = attr => {
    dispatch({
      type: TOGGLE_INTEGRATION_HANDOFF_DISPLAY_FORM,
    });
  };

  const handleBlurHandOffFields = key => evt => {
    // Add delay cuz user might have clicked other component
    // other than the expected next input field
    setTimeout(() => {
      switch (key) {
        case 'label':
          // dont fire if no changes made
          if (tempLabel && tempLabel !== handoffLabel) {
            dispatch({
              type: SET_INTEGRATION_HANDOFF_LABEL,
              payload: tempLabel,
            });
          }
          break;

        case 'messageSent':
          if (tempMessageSent && tempMessageSent !== submittedFormMessage) {
            dispatch({
              type: SET_INTEGRATION_HANDOFF_SUCCESS_RESPONSE,
              payload: tempMessageSent,
            });
          }
          break;

        case 'cancelMessage':
          if (
            tempCancelledMessage &&
            tempCancelledMessage !== cancelledFormMessage
          ) {
            dispatch({
              type: SET_INTEGRATION_HANDOFF_CANCEL_RESPONSE,
              payload: tempCancelledMessage,
            });
          }
          break;

        default:
          break;
      }
    }, 50);
  };

  const handleChangeHandOffField = key => evt => {
    switch (key) {
      case 'label':
        // dont fire if no changes made
        setTempLabel(evt.target.value);
        break;

      case 'messageSent':
        setTempMessageSent(evt.target.value);
        break;

      case 'cancelMessage':
        setTempCancelledMessage(evt.target.value);
        break;

      default:
        break;
    }
  };

  const handlePanelChange = async val => {
    dispatch({ type: SET_BOT_CUSTOMIZER_ACTIVE_PANEL, payload: val });
  };

  return {
    authenticatedUser,
    callbackEmail,
    cancelledFormMessage,
    displayFormFields,
    handoffLabel,
    isSendCallbackLog,
    submittedFormMessage,
    tempFormFields,
    handleAddFormField,
    handleBlurFormField,
    handleBlurHandOffFields,
    handleChangeFormFields,
    handleChangeHandOffField,
    handleDeleteFormRow,
    handlePanelChange,
    handleToggleCallbackEmail,
    handleToggleDisplayFormFields,
  };
};

export default useFeedbackAndHandOff;
