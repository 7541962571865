import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .action-buttons {
    justify-content: flex-end;
    display: flex;
    width: 100%;

    button:last-child {
      background-color: ${cssVariables.primaryBlue};
      color: #fff;
    }

    button:active,
    button:focus {
      background-color: ${cssVariables.primaryBlue} !important;
      color: #fff !important;
    }

    button:disabled {
      background-color: ${cssVariables.gray2} !important;
    }
  }
`;

export const StyledExportLabel = styled.div`
  font-size: 16px;
  color: #33363c;
  margin-right: 8px;
`;

export const StyledExportContainer = styled.div`
  font-size: 15px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  font-weight: ${cssVariables.font.bold};
  padding: 15px 0;

  > span:first-child {
    margin-top: 10px;
  }

  > input[type='checkbox'] {
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }

  & .ant-checkbox-wrapper,
  .ant-checkbox-wrapper {
    margin: 10px 0 0 0;
    font-weight: ${cssVariables.font.normal} !important;
  }
`;
