import { createSelector } from 'reselect';
import { groupBy } from 'lodash';

import { currentBotSelector } from '.';
import { initialCallbackLogsState } from 'store/initialState';

export const callbackLogsSelector = createSelector(
  currentBotSelector,
  bot => bot.callbackLogs || initialCallbackLogsState
);

export const callbackLogsTableDataSelector = createSelector(
  callbackLogsSelector,
  callbackLogs => callbackLogs.table || []
);

export const callbackLogsSessionDetailsSelector = createSelector(
  callbackLogsSelector,
  callbackLogs => callbackLogs.sessionDetails || {}
);

export const callbackSessionDetailsDataSelector = createSelector(
  callbackLogsSessionDetailsSelector,
  sessionDetails => sessionDetails.data || []
);

export const callbackSessionDetailsIdSelector = createSelector(
  callbackLogsSessionDetailsSelector,
  sessionDetails => sessionDetails.sessionId
);

export const callbackSessionDetailsInteractionIdsSelector = createSelector(
  callbackLogsSessionDetailsSelector,
  sessionDetails => sessionDetails.callbackInteractionIds
);

export const callbackSessionFiltersSelector = createSelector(
  callbackLogsTableDataSelector,
  tableData => {
    const sessions = Object.keys(groupBy(tableData, 'sessionID'));

    return (sessions || []).map(s => {
      return {
        text: s === 'undefined' ? 'None' : s,
        value: s,
      };
    });
  }
);
