import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledSequenceViewerContainer,
  StyledSequenceViewerModal,
} from './SequenceViewerModal.style';
import useSelector from 'store/useSelector';
import { chatSelectedSequenceInModalSelector } from 'selectors/bot/ui';
import {
  StyledFlexColumn,
  StyledFlexRowCenter,
} from 'styles/GenericStyledComponents';
import CodeBlock from 'components/CodeBlock';
import { Descriptions } from 'antd';
import { cssVariables } from 'styles/root';
import { useMemo } from 'react';

const SequenceViewerModal = ({ onCancel }) => {
  const chatSelectedSequenceInModal = useSelector(
    chatSelectedSequenceInModalSelector
  );
  const selectedSequenceType = chatSelectedSequenceInModal.sequence.type;
  const typeList = ['translation', 'retrieval', 'generation', 'calculation'];
  const useDefaultViewer = selectedSequenceType in typeList;

  const TRANSLATION_DESCRIPTION_LIST = useMemo(() => {
    if (chatSelectedSequenceInModal.sequence.data) {
      return [
        {
          title: 'Translation',
          fieldName: 'translated_text',
        },
        {
          title: 'Language',
          fieldName: 'detected_language',
        },
      ];
    } else {
      return [
        {
          title: 'Translation',
          fieldName: 'translation',
        },
        {
          title: 'Language',
          fieldName: 'language',
        },
      ];
    }
  }, [chatSelectedSequenceInModal]);

  const SELECTED_SEQUENCE = useMemo(
    () =>
      chatSelectedSequenceInModal.sequence.data
        ? chatSelectedSequenceInModal.sequence.data
        : chatSelectedSequenceInModal.sequence,
    [chatSelectedSequenceInModal]
  );

  return (
    <StyledSequenceViewerModal
      onCancel={onCancel}
      noOkButton
      cancelText="Close"
      visible={true}
      width={
        selectedSequenceType === 'translation' ||
        selectedSequenceType === 'calculation'
          ? '30vw'
          : '70vw'
      }
      title={`${
        selectedSequenceType?.charAt(0).toUpperCase() +
        selectedSequenceType?.slice(1)
      } Viewer`}
    >
      <StyledSequenceViewerContainer>
        <StyledFlexColumn>
          <StyledFlexRowCenter>
            {useDefaultViewer && (
              <StyledFlexRowCenter>
                <CodeBlock codeContent={SELECTED_SEQUENCE} />
              </StyledFlexRowCenter>
            )}

            <StyledFlexRowCenter>
              <Descriptions layout="horizontal" bordered>
                {selectedSequenceType === 'translation' &&
                  TRANSLATION_DESCRIPTION_LIST?.map((translation, key) => (
                    <Descriptions.Item
                      label={translation.title}
                      labelStyle={{
                        fontWeight: cssVariables.font.bold,
                      }}
                      span={3}
                      key={key}
                    >
                      {JSON.stringify(SELECTED_SEQUENCE[translation.fieldName])}
                    </Descriptions.Item>
                  ))}

                {selectedSequenceType === 'retrieval' &&
                  SELECTED_SEQUENCE?.response?.map((response, key) => (
                    <Descriptions.Item span={3} key={`retrieval${key}`}>
                      {JSON.stringify(response.text)}
                    </Descriptions.Item>
                  ))}

                {selectedSequenceType === 'generation' && (
                  <Descriptions.Item span={3} key="generation">
                    {JSON.stringify(SELECTED_SEQUENCE.generated)}
                  </Descriptions.Item>
                )}

                {selectedSequenceType === 'calculation' && (
                  <>
                    <Descriptions.Item
                      label="Text"
                      labelStyle={{
                        fontWeight: cssVariables.font.bold,
                      }}
                      span={3}
                      key="text"
                    >
                      {JSON.stringify(
                        SELECTED_SEQUENCE.response?.answers
                          ? SELECTED_SEQUENCE.response?.answers[0]?.context
                              ?.text
                          : SELECTED_SEQUENCE.response?.answer?.context?.text
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Score"
                      labelStyle={{
                        fontWeight: cssVariables.font.bold,
                      }}
                      span={3}
                      key="score"
                    >
                      {JSON.stringify(
                        SELECTED_SEQUENCE.response?.answers
                          ? SELECTED_SEQUENCE.response?.answers[0]?.context
                              ?.score
                          : SELECTED_SEQUENCE.response?.answer?.context?.score
                      )}
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
            </StyledFlexRowCenter>
          </StyledFlexRowCenter>
          <p />
        </StyledFlexColumn>
      </StyledSequenceViewerContainer>
    </StyledSequenceViewerModal>
  );
};

SequenceViewerModal.propTypes = {
  onCancel: PropTypes.func,
};

export default SequenceViewerModal;
