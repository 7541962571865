import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const Wrapper = styled.div`
  width: 364px;
  display: block;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 6px;

  button {
    cursor: pointer;
    width: 62px;
    height: 30px;
    color: #fff;
    background-color: ${cssVariables.blue2};
    border: 0;
    border-radius: 3px;
    font-size: 10px;
    font-weight: ${cssVariables.font.normal};
    letter-spacing: 2px;
    margin-right: 4px;
  }
`;

export const Content = styled.div`
  display: block;
  padding: 10px;
  h3 {
    font-weight: ${cssVariables.font.bold};
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
