import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

const StyledCenteredText = styled.div`
  display: flex;
  line-height: 1em;
  color: ${cssVariables.primaryColor};
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;

  ::before,
  ::after {
    content: '';
    display: inline-block;
    flex-grow: 1;
    margin-top: 0.5em;
    background: gray;
    height: 1px;
  }
`;

const HRWithCenteredText = props => {
  const { text, ...rest } = props;
  return <StyledCenteredText {...rest}>{text}</StyledCenteredText>;
};

HRWithCenteredText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default HRWithCenteredText;
