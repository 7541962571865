import React from 'react';
import styled from 'styled-components';
import { Skeleton, Steps, Tag, Tooltip } from 'antd';
import FlipMove from 'react-flip-move';

import Modal from '../GenericModal';
import useQuestionEditor from './hooks';
import TextArea from 'components/TextArea';
import { FlexRowWrapper } from '../AnswerEditor/Variant/Variant.styles';
import { StyledAnswersItem } from 'pages/BotDetails/AnswerBank/SimilarAnswerModal/SimilarAnswerModal.style';
import { StyledAnswers } from '../ImportAnswerModal/ImportAnswerModal.style';
import {
  StyledFlexRowRight,
  StyledSpaceBetweenFlexCenter,
  UnpaddedList,
} from 'styles/GenericStyledComponents';
import { useMemo } from 'react';
import Button from 'components/Button';
import AnswerWithScore from 'components/AnswerWithScore';
import ToggleShowDisplayAnswer from 'components/Button/ToggleShowDisplayAnswer';
import { StyledFlexColumn } from 'styles/GenericStyledComponents';

const { Step } = Steps;

const QuestionEditor = props => {
  const { ...rest } = props;
  const {
    currentStep,
    disabledNextStep,
    loading,
    questionEditorModal,
    newQuestion,
    showSkeleton,
    showDisplayAnswer,
    handleChangeText,
    handleClose,
    handleClickLinkAnswer,
    handleSaveQuestion,
    handleUnlinkQuestion,
    pushAsQuestionEditorAnswer,
    setCurrentStep,
    setShowDisplayAnswer,
  } = useQuestionEditor();

  const {
    action,
    isOpen,
    similarQuestions,
    text,
    answersWithScore,
    answersHasChanged,
    isHardLinked,
    answerId,
    jid,
  } = questionEditorModal;

  const skipSimilarityCheck = useMemo(() => {
    // if similar questions is found
    // and question inside textarea is not change
    // or no similar questions found
    // skip similarity check
    if (
      (similarQuestions?.length &&
        (questionEditorModal?.newQuestion === newQuestion ||
          text === newQuestion ||
          !newQuestion)) ||
      (!disabledNextStep && !newQuestion && !similarQuestions?.length) ||
      (similarQuestions?.length && !newQuestion && action === 'add')
    ) {
      return true;
    }
    // add new question:
    // always check for similarity
    // when question is edited
    // call similarity check
    else if (newQuestion !== text) {
      return false;
    }
    // if similar questions is found
    // and question inside textarea has changed
    // call similarity check
    else if (similarQuestions?.length && newQuestion !== text) {
      return false;
    }
    // if no similar questions found
    // skip similarity check
    else if (!similarQuestions?.length) {
      return true;
    }
    // always call similarity check for all
    // other scenarios
    return false;
  }, [similarQuestions?.length, newQuestion, text]);

  const steps = [
    {
      title: action === 'add' ? 'Add Question' : 'Edit Question',
    },
    {
      title: 'Link Answer',
    },
  ];

  return (
    <Modal
      cancelButtonProps={{
        disabled: loading,
      }}
      cancelText="Cancel"
      confirmLoading={loading}
      destroyOnClose
      closable={false}
      maskClosable={false}
      okText={
        similarQuestions?.length ? 'Ignore Similar Questions & Save' : 'Save'
      }
      title={'Question Editor'}
      visible={isOpen}
      width={'70%'}
      spinning={loading}
      {...rest}
      footer={[
        <StyledSpaceBetweenFlexCenter>
          {currentStep ? (
            <Button
              value="Back"
              onClick={() => setCurrentStep(currentStep - 1)}
            />
          ) : null}
          <Button variant="link" value="Cancel" onClick={handleClose} />
          <StyledFlexRowRight>
            <Button
              disabled={disabledNextStep}
              value="Save as Draft"
              variant="gray"
              onClick={() => handleSaveQuestion(true)}
            />
            {Boolean(currentStep && isHardLinked) ? null : (
              <Button
                disabled={!currentStep ? disabledNextStep : !answerId}
                value={
                  !currentStep && similarQuestions?.length
                    ? `Ignore similar Questions and Link Answer`
                    : !currentStep
                    ? `Link Answer`
                    : 'Publish'
                }
                loading={loading}
                onClick={() =>
                  !currentStep ? handleClickLinkAnswer() : handleSaveQuestion()
                }
              />
            )}
          </StyledFlexRowRight>
        </StyledSpaceBetweenFlexCenter>,
      ]}
    >
      <Steps current={currentStep} onChange={val => setCurrentStep(val)}>
        {steps.map((step, idx) => (
          <Step key={idx} title={step.title} disabled={true} />
        ))}
      </Steps>
      <div>
        <StyledSpaceBetweenFlexCenter style={{ alignItems: 'flex-end' }}>
          <strong>{'Question'}:</strong>
          <StyledFlexRowRight>
            <Tag
              style={{ fontSize: 18, padding: '5px 10px', margin: 0 }}
              color={questionEditorModal?.version === 'final' ? 'green' : 'red'}
            >
              {questionEditorModal?.version}
            </Tag>
          </StyledFlexRowRight>
        </StyledSpaceBetweenFlexCenter>
        <TextArea
          // disable if not on first step
          disabled={currentStep}
          defaultValue={text}
          onChange={handleChangeText}
        />
      </div>
      {!currentStep ? (
        <>
          {showSkeleton ? <Skeleton active /> : null}
          {!similarQuestions?.length &&
          skipSimilarityCheck &&
          questionEditorModal?.newQuestion &&
          questionEditorModal?.newQuestion === text ? (
            'No Similar Questions Found'
          ) : similarQuestions?.length ? (
            <>
              <p />
              <strong>{'Similar Questions'}</strong>
            </>
          ) : null}
          {similarQuestions?.map(item => (
            <FlexRowWrapper key={item.jid}>
              <Tooltip title={'Similarity Score'}>
                <Tag>{item.score?.toFixed(3)}</Tag>
              </Tooltip>
              <StyledAnswersItem
                key={item.jid}
                style={{
                  width: '100%',
                }}
              >
                {<StyledAnswers>{item.text}</StyledAnswers>}
              </StyledAnswersItem>
            </FlexRowWrapper>
          ))}
        </>
      ) : (
        <>
          {showSkeleton ? <Skeleton active /> : null}
          {answersWithScore?.length ? (
            <StyledFlexColumn>
              <ToggleShowDisplayAnswer
                style={{ alignSelf: 'flex-end', padding: 5, height: '100%' }}
                showDisplayAnswer={showDisplayAnswer}
                onClick={() => setShowDisplayAnswer(!showDisplayAnswer)}
              />
              <UnpaddedList>
                <FlipMove>
                  {answersWithScore.map((answer, index) => (
                    // react-flip-move requires list element
                    <li key={answer.jid}>
                      <AnswerWithScore
                        answer={answer}
                        isCurrentAnswerHasLink={isHardLinked}
                        isCurrentAnswer={answerId === answer?.jid}
                        qlinks={answer?.qlinks}
                        handleSelect={answerId =>
                          pushAsQuestionEditorAnswer(answerId, 'selected')
                        }
                        handleUnlinkQuestion={handleUnlinkQuestion}
                        showDisplayAnswer={showDisplayAnswer}
                        toolTipText="Link Answer"
                      />
                    </li>
                  ))}
                </FlipMove>
              </UnpaddedList>
            </StyledFlexColumn>
          ) : (
            <span>
              {'No valid answer found. Please check your answer bank.'}
            </span>
          )}
        </>
      )}
    </Modal>
  );
};

QuestionEditor.propTypes = {};

export default QuestionEditor;
