import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';

import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import {
  customerChargesSelector,
  customerIdSelector,
  customerPaymentsSelector,
  maxTransactionCountSelector,
  planDetailsSelector,
  planTypeSelector,
} from 'selectors/plan';
import {
  SET_INVOICES,
  SET_PAYMENT_METHODS,
  SET_USER_SUBSCRIPTION,
  SHOW_CANCEL_SUBSCRIPTION_MODAL,
} from 'store/action';
import ROUTES from 'constants/routes';
import { stripeAPIService } from 'services/stripeAPI.service';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import { getTokenSelector } from 'selectors/user';
import {
  currentTransactionCountSelector,
  defaultPaymentMethodSelector,
  isCancelledSubscriptionSelector,
  isPaymentInfoEmptySelector,
  isProcessingSelector,
  isTrialSubscriptionSelector,
  paymentErrorSelector,
  paymentMethodsSelector,
  planRenewDateSelector,
  subscriptionIdSelector,
  subscriptionSelector,
  transactionPercentageSelector,
  trialDaysLeftSelector,
} from 'selectors/subscription';
import { billingDetailsSelector } from 'selectors/subscription';
import { AUTO_RENEW_SUCCESS_MESSAGE } from 'constants/plan';
import { invoicesSelector } from 'selectors/invoice';

const usePlanAndPayments = () => {
  const { push } = useHistory();
  const [state, dispatch] = useContext(Context);
  const { sentinel, graph, user } = state;

  const billingDetails = useSelector(billingDetailsSelector);
  const currentTransactionCount = useSelector(currentTransactionCountSelector);
  const customerCharges = useSelector(customerChargesSelector);
  const customerId = useSelector(customerIdSelector);
  const customerPayments = useSelector(customerPaymentsSelector);
  const defaultPaymentMethod = useSelector(defaultPaymentMethodSelector);
  const paymentError = useSelector(paymentErrorSelector);
  const invoices = useSelector(invoicesSelector);
  const isCancelledSubscription = useSelector(isCancelledSubscriptionSelector);
  const isPaymentInfoEmpty = useSelector(isPaymentInfoEmptySelector);
  const isProcessing = useSelector(isProcessingSelector);
  const isTrialSubscription = useSelector(isTrialSubscriptionSelector);
  const maxTransactionCount = useSelector(maxTransactionCountSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const planDetails = useSelector(planDetailsSelector);
  const planRenewDate = useSelector(planRenewDateSelector);
  const planType = useSelector(planTypeSelector);
  const subscription = useSelector(subscriptionSelector);
  const subscriptionId = useSelector(subscriptionIdSelector);
  const token = useSelector(getTokenSelector);
  const transactionPercentage = useSelector(transactionPercentageSelector);
  const trialDaysLeft = useSelector(trialDaysLeftSelector);

  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
  });
  const [loading, setLoading] = useState(false);
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  const showAddPaymentMethodForm = () => {
    setShowAddPaymentMethod(true);
  };

  const handleChangeCard = async () => {
    const returnUrl = `${window.location.origin}${ROUTES.PLAN_AND_PAYMENTS}`;
    try {
      const res = await stripeAPIService.changeCard(
        returnUrl,
        graph,
        sentinel,
        token
      );
      if (!res.data?.report[0]) {
        throw new Error('No payment data found!');
      } else if (res.data?.report[0]) {
        const checkoutURl = res.data.report[0]?.url;
        if (checkoutURl) {
          window.location.href = checkoutURl;
        } else {
          throw new Error(GET_DATA_ERROR);
        }
      }
    } catch (error) {
      message.error(error?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const fetchSubscriptionStatus = async () => {
    setLoading(true);
    try {
      const res = await stripeAPIService.getSubscriptionStatus(
        graph,
        sentinel,
        token
      );
      if (res.data?.report) {
        dispatch({
          type: SET_USER_SUBSCRIPTION,
          payload: res.data.report[0],
        });
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.message || GET_DATA_ERROR);
    }
    setLoading(false);
  };

  const fetchInvoiceList = async () => {
    setLoading(true);
    try {
      const res = await stripeAPIService.getInvoiceList(graph, sentinel, token);
      if (res.data?.report) {
        dispatch({
          type: SET_INVOICES,
          payload: res.data.report[0]?.data,
        });
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.message || GET_DATA_ERROR);
    }
    setLoading(false);
  };

  const renewSubscription = async () => {
    setLoading(true);
    try {
      const res = await stripeAPIService.autoRenewSubscription(
        graph,
        sentinel,
        token
      );
      if (res.data?.report[0]) {
        dispatch({
          type: SET_USER_SUBSCRIPTION,
          payload: res.data.report[0],
        });

        message.success(AUTO_RENEW_SUCCESS_MESSAGE);
      }
    } catch (error) {
      message.error(GET_DATA_ERROR);
    }
    setLoading(false);
  };

  const getPaymentMethods = async () => {
    setLoading(true);
    try {
      const res = await stripeAPIService.getPaymentMethods(
        graph,
        sentinel,
        token
      );
      if (res.data?.report[0]) {
        const paymentMethod = res.data.report[0]?.data;
        dispatch({
          type: SET_PAYMENT_METHODS,
          payload: paymentMethod,
        });
      }
    } catch (error) {
      message.error(error?.message || DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const onCancelChangePaymentMethod = () => {
    setShowAddPaymentMethod(false);
    setUserDetails({
      name: '',
      email: user.email,
    });
    setIsCreatingPaymentMethod(false);
  };

  const onFormInput = useCallback(
    event => {
      const { name, value } = event.target;
      setUserDetails({
        ...userDetails,
        [name]: value,
      });
    },
    [userDetails]
  );

  const showConfirmCancelSubscriptionModal = () => {
    dispatch({
      type: SHOW_CANCEL_SUBSCRIPTION_MODAL,
    });
  };

  const formatDateTime = dateTime => {
    return new Date(dateTime).toLocaleString('en-US', {
      day: 'numeric',
      year: 'numeric',
      month: 'long',
    });
  };

  useEffect(() => {
    async function fetchData() {
      await getPaymentMethods();
      await fetchSubscriptionStatus();
      await fetchInvoiceList();
    }

    if (planType !== 'free' || isTrialSubscription) {
      fetchData();
    }
  }, [planType, isTrialSubscription]);

  const handleExpand = (rowKey, isExpanded) => {
    setExpandedRows({
      ...expandedRows,
      [rowKey]: !isExpanded || true,
    });
  };

  return {
    billingDetails,
    currentTransactionCount,
    customerCharges,
    customerId,
    customerPayments,
    defaultPaymentMethod,
    paymentError,
    handleChangeCard,
    invoices,
    isCancelledSubscription,
    isCreatingPaymentMethod,
    isPaymentInfoEmpty,
    isProcessing,
    isTrialSubscription,
    loading,
    maxTransactionCount,
    onCancelChangePaymentMethod,
    onFormInput,
    planDetails,
    planRenewDate,
    planType,
    paymentMethods,
    renewSubscription,
    showAddPaymentMethod,
    showAddPaymentMethodForm,
    showConfirmCancelSubscriptionModal,
    subscription,
    subscriptionId,
    transactionPercentage,
    trialDaysLeft,
    userDetails,
    formatDateTime,
    selectedKey,
    setSelectedKey,
    expandedRows,
    handleExpand,
  };
};

export default usePlanAndPayments;
