import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import 'react-chat-widget/lib/styles.css';
import './index.css';
import App from './App';
import Store from 'store/store';
import reportWebVitals from './reportWebVitals';
import FallbackBoundary from 'components/FallbackBoundary';

ReactDOM.render(
  // Antd uses findDOMNode and throws error on dev's browser console
  // TODO: migrate to latest antd component to enable StrictMode
  // <React.StrictMode>
  <FallbackBoundary>
    <Store>
      <App />
    </Store>
  </FallbackBoundary>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
