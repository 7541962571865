import { useContext, useState } from 'react';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';

import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import {
  getAllQuestionsWithAnswerSelector,
  questionEditorSelector,
} from 'selectors/bot';
import { getTokenSelector } from 'selectors/user';
import { apiService } from 'services/api.service';
import {
  ADD_QUESTION,
  CLOSE_QUESTION_EDITOR,
  PUSH_AS_QUESTION_EDITOR_SELECTED_ANSWER,
  REMOVE_HARD_LINK_FROM_QUESTION_EDITOR,
  SHOW_RESPONSE_PICKER_MODAL_FOR_QUESTIONS,
  SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD,
  UPDATE_QUESTION_AND_ANSWER,
  UPDATE_QUESTION_EDITOR,
} from 'store/action';
import { Context } from 'store/store';
import { withPrefixUUID } from 'utils';
import { allAnswersSelector } from 'selectors/bot/answers';
import useSelector from 'store/useSelector';

const useQuestionEditor = () => {
  const token = useSelector(getTokenSelector);
  const questionEditorModal = useSelector(questionEditorSelector);
  const allQuestions = useSelector(getAllQuestionsWithAnswerSelector);
  const allAnswers = useSelector(allAnswersSelector);
  const { pathname } = useLocation();
  const [state, dispatch] = useContext(Context);
  const {
    sentinel,
    bot: { jid, name },
  } = state;

  const WITH_PREFIX_BOT_JID = withPrefixUUID(jid || pathname);
  const [loading, setLoading] = useState(false);
  const [newQuestion, setNewQuestion] = useState(questionEditorModal.text);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [disabledNextStep, setDisabledNextStep] = useState(false);
  const [version, setVersion] = useState(null);
  const [showDisplayAnswer, setShowDisplayAnswer] = useState(false);

  const handleClose = () => {
    setShowSkeleton(false);
    setNewQuestion(null);
    setCurrentStep(0);
    dispatch({
      type: CLOSE_QUESTION_EDITOR,
    });
  };

  const handleChangeText = evt => {
    setNewQuestion(evt.target.value);
    if (!evt.target.value) {
      setDisabledNextStep(true);
    } else if (disabledNextStep) {
      setDisabledNextStep(false);
    }
  };

  const pushAsQuestionEditorAnswer = answerId => {
    dispatch({
      type: PUSH_AS_QUESTION_EDITOR_SELECTED_ANSWER,
      payload: {
        answerId,
        answersWithScore: questionEditorModal?.answersWithScore,
      },
    });
  };

  const handleSaveQuestion = async saveAsDraft => {
    try {
      setLoading(true);
      const { action, text, jid, answerId, answersWithScore } =
        questionEditorModal;

      if (action === 'add') {
        const res = await apiService.hardLinkQuestion(
          newQuestion || text,
          answerId || null,
          // use bot jid when creating new answer
          WITH_PREFIX_BOT_JID,
          saveAsDraft ? 'draft' : 'final',
          sentinel,
          token,
          true
        );

        if (!res.data.success) {
          throw new Error(DEFAULT_ERROR_MESSAGE);
        }
        await dispatch({
          type: ADD_QUESTION,
          payload: res.data.report[0],
        });
      } else {
        const res = await apiService.editQuestion(
          newQuestion || text,
          answerId,
          // use question jid when editing new answer
          jid,
          saveAsDraft ? 'draft' : 'final',
          sentinel,
          token,
          true
        );

        if (!res.data.success) {
          throw new Error(DEFAULT_ERROR_MESSAGE);
        }

        await dispatch({
          type: UPDATE_QUESTION_AND_ANSWER,
          payload: res.data.report[0],
        });
      }
      message.success('Successfully saved question');
      handleClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const handleUnlinkQuestion = async () => {
    setLoading(true);
    try {
      await apiService.deleteQuestionHardLink(
        questionEditorModal.jid,
        sentinel,
        token
      );
      setLoading(false);
      dispatch({
        type: REMOVE_HARD_LINK_FROM_QUESTION_EDITOR,
        payload: questionEditorModal.jid,
      });
      return message.success(
        'Successfully unlinked the question to the answer'
      );
    } catch (error) {
      setLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleClickLinkAnswer = async skipSimilarityCheck => {
    // NOTE:
    // questionEditorModal.text => text from source
    // (sources: textarea OR sidebar user question OR question from question list)
    // questionEditorModal.newQuestion => text after any reducer is called to update questionEditorModal
    // newQuestion => question state on a component level; editable; changes when textArea value is changed
    setLoading(true);
    try {
      if (
        questionEditorModal.action === 'edit' &&
        questionEditorModal.answerId
      ) {
        setCurrentStep(currentStep + 1);
        setShowSkeleton(true);
        const res =
          await apiService.getAnswersWithScoreFromQuestionWithLinkedAnswer(
            newQuestion ||
              questionEditorModal.newQuestion ||
              questionEditorModal.text,
            questionEditorModal.answerId,
            WITH_PREFIX_BOT_JID,
            sentinel,
            token
          );
        if (res.data.report[0]?.length) {
          await dispatch({
            type: UPDATE_QUESTION_EDITOR,
            payload: {
              answersWithScore: res.data.report[0],
              newQuestion,
              answerId: questionEditorModal.answerId,
            },
          });
        }
      } else if (
        questionEditorModal.action === 'add' ||
        (questionEditorModal.action === 'edit' && !questionEditorModal.answerId)
      ) {
        setCurrentStep(currentStep + 1);
        setShowSkeleton(true);
        const res = await apiService.getAnswersWithScoreFromQuestion(
          newQuestion ||
            questionEditorModal.newQuestion ||
            questionEditorModal.text,
          WITH_PREFIX_BOT_JID,
          sentinel,
          token
        );
        if (res.data.report[0]?.length) {
          await dispatch({
            type: UPDATE_QUESTION_EDITOR,
            payload: {
              answersWithScore: res.data?.report[0],
              newQuestion,
            },
          });
        }
      }
      setShowSkeleton(false);
      return setLoading(false);
    } catch (error) {
      message.error(error.message || DEFAULT_ERROR_MESSAGE);
      setLoading(false);
    }
  };

  const handleChooseNewLinkedAnswer = () => {
    dispatch({
      type: SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD,
      payload: {
        answers: allAnswers,
        botName: name,
        withQuestion: true,
        isHardLinked: true,
        action: 'edit',
        answerId: questionEditorModal.answer.jid,
        itemToChange: {
          question: questionEditorModal,
          answer: questionEditorModal.answer,
        },
      },
    });
  };

  const handleChooseNewquestion = () => {
    dispatch({
      type: SHOW_RESPONSE_PICKER_MODAL_FOR_QUESTIONS,
      payload: {
        questions: allQuestions,
        action: 'edit',
        botName: name,
        editableQuestion: true,
        isHardLinked: true,
        questionId: questionEditorModal.jid,
        answer: questionEditorModal.answer,
      },
    });
  };

  return {
    currentStep,
    disabledNextStep,
    loading,
    questionEditorModal,
    newQuestion,
    showSkeleton,
    showDisplayAnswer,
    version,
    handleClose,
    handleChangeText,
    handleChooseNewLinkedAnswer,
    handleClickLinkAnswer,
    handleSaveQuestion,
    handleUnlinkQuestion,
    pushAsQuestionEditorAnswer,
    setCurrentStep,
    setShowDisplayAnswer,
    setVersion,
  };
};

export default useQuestionEditor;
