import useWebsite from './hooks';
import AnswerTabs from '../AnswerTabs';
const Website = props => {
  const { allWebsite } = useWebsite();

  return (
    <>
      {/* {props.limitReachedContent()} */}
      <AnswerTabs {...props} allRecords={allWebsite} type="websites" />
    </>
  );
};

export default Website;
