import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Joyride from 'react-joyride';
import {
  ResponseListWrapper,
  Wrapper,
  StyledQuestionContainer,
  StyledAddQuestionType,
  StyledQuestionUploader,
  StyledQuestionModalLabel,
  StyledQuestionContent,
  StyledAddQuestionForm,
} from './ValidationQuestionModal.style';
import AnswerWithScore from 'components/AnswerWithScore';
import Modal from 'components/Modals/GenericModal';
import Input from 'components/Input';
import Button from 'components/Button';
import { ImportOutlined } from '@ant-design/icons';
import { Radio, Space, Checkbox, Tag, Tooltip, Dropdown, Menu } from 'antd';
import useValidationQuestionModal from './hooks';
import Alert from 'components/Alert';
import { RESPONSE_PICKER_STEPS } from 'constants/joyride_steps/validation_page';
import JoyrideTooltip from 'components/ToolTips/JoyrideToolTip';
import ToggleShowDisplayAnswer from 'components/Button/ToggleShowDisplayAnswer';
import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';
import { extractDisplayAnswer } from 'utils/answers';
import MoreMenu from 'components/MoreMenu';
import { StyledAntUploadContent } from '../ImportAnswerModal/ImportAnswerModal.style';

const ValidationQuestionModal = props => {
  const {
    answer,
    handleAddQuestion,
    handleSelect,
    handleShowResponses,
    handleUpdateTestCase,
    modalLoading,
    mode,
    onCloseModal,
    onChangeText,
    onSubmit,
    responseCount,
    responses,
    selectedResponse,
    testCase,
    totalTestCases,
    visible,
    ...rest
  } = props;
  const {
    addQuestionType,
    showDraftResponses,
    handleToggleShowDraft,
    handleReadCSV,
    handleChangeAnswerType,
    shouldUseDefaultAnswer,
    importErrorMsg,
    importWarnMsg,
    importExportEnabled,
    onClickClose,
    question,
    questionsArr,
    runTour,
    setAddQuestionType,
    setShowDisplayAnswer,
    setQuestion,
    showDisplayAnswer,
    stepIndex,
    handleToggleShowBelowThreshold,
    showBelowThresholdResponses,
  } = useValidationQuestionModal({ mode, totalTestCases, testCase, responses });

  const isSaveBtnDisabled = () => {
    if (addQuestionType === 'input' && selectedResponse) {
      return false;
    } else if (addQuestionType === 'import' && questionsArr) {
      return false;
    }

    return true;
  };

  const handleSaveTestCase = (questionType, shouldUseDefaultAnswer) => {
    onClickClose({
      action: 'close',
      index: 1,
      type: 'tour:end',
      status: 'finished',
    });
    handleAddQuestion(questionType, shouldUseDefaultAnswer);
  };

  const moreMenu = (
    <Menu>
      <Menu.Item>
        <Checkbox
          onChange={handleToggleShowDraft}
          defaultChecked={showDraftResponses}
        >
          <Tooltip title="Include draft answers">
            {'Include draft answers'}
          </Tooltip>
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          onChange={handleToggleShowBelowThreshold}
          defaultChecked={showBelowThresholdResponses}
        >
          <Tooltip title="Show below threshold responses">
            {'Show below threshold responses'}
          </Tooltip>
        </Checkbox>
      </Menu.Item>
    </Menu>
  );
  const QuestionForm = (
    <StyledQuestionContainer>
      {mode === 'Add' ? (
        <StyledQuestionModalLabel>
          {`Add Test Question`}{' '}
        </StyledQuestionModalLabel>
      ) : null}
      {addQuestionType === 'input' ? (
        <StyledAddQuestionForm
          onSubmit={e => {
            e.preventDefault();
            handleShowResponses(3, question);
          }}
        >
          <Input
            full
            autoFocus
            onChange={e => setQuestion(e.target.value)}
            placeholder="Enter test question"
            required
            value={question}
            size="medium"
          />
          <Button
            disabled={!question}
            onClick={() => handleShowResponses(3, question)}
            value="Show Response"
            variant="primary-btn-v2"
          />
        </StyledAddQuestionForm>
      ) : null}
    </StyledQuestionContainer>
  );

  const firstAnswer = useMemo(
    () => responses.filter(response => response?.type === 'answer')[0],
    [responses]
  );

  return (
    <Wrapper>
      <Joyride
        run={runTour}
        steps={RESPONSE_PICKER_STEPS}
        stepIndex={stepIndex}
        spotlightClicks
        showProgress={true}
        showSkipButton={true}
        callback={onClickClose}
        tooltipComponent={JoyrideTooltip}
        continuous
      />
      <Modal
        visible={visible}
        title={`${mode} Test Question`}
        onOk={() =>
          mode === 'Edit'
            ? handleUpdateTestCase(question, testCase.jid, testCase.testResult)
            : handleSaveTestCase(
                addQuestionType === 'input' ? question : questionsArr,
                shouldUseDefaultAnswer
              )
        }
        okButtonProps={{
          disabled: isSaveBtnDisabled(),
          className: 'validation-response__button',
        }}
        cancelButtonProps={{
          disabled: modalLoading,
        }}
        onCancel={onCloseModal}
        spinning={modalLoading}
        confirmLoading={modalLoading}
        {...rest}
        testCaseToEdit
        isFormModal
        width={'60vw'}
      >
        {mode === 'Add' ? (
          <StyledAddQuestionType
            onChange={e => setAddQuestionType(e.target.value)}
            value={addQuestionType}
          >
            <Space direction="vertical">
              <Radio value="input">{QuestionForm}</Radio>
              {mode === 'Add' && (
                <Radio value="import" disabled={!importExportEnabled}>
                  <StyledQuestionContainer>
                    <span>Import List of Tests (.csv)</span>
                    {addQuestionType === 'import' && (
                      <>
                        {importErrorMsg ? (
                          <Alert message={importErrorMsg} type="error" />
                        ) : null}
                        {importWarnMsg ? (
                          <Alert message={importWarnMsg} type="warning" />
                        ) : null}
                        <StyledQuestionUploader
                          headers={{ 'Content-Type': 'multipart/form-data' }}
                          beforeUpload={file => handleReadCSV(file)}
                          multiple={false}
                          maxCount={1}
                          accept={
                            '.csv, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                          }
                        >
                          <StyledAntUploadContent>
                            <ImportOutlined />
                            Drag files here or select file
                          </StyledAntUploadContent>
                        </StyledQuestionUploader>
                        <StyledFlexLeftColumn>
                          Please select an answer type from below
                          <Checkbox
                            checked={!shouldUseDefaultAnswer}
                            value="best"
                            onChange={handleChangeAnswerType}
                          >
                            <span>Use best response from answer bank</span>{' '}
                            <Tag color="green">Recommended</Tag>
                          </Checkbox>
                          <Checkbox
                            checked={shouldUseDefaultAnswer}
                            value="default"
                            onChange={handleChangeAnswerType}
                          >
                            Apply Fallback Answer to Tests
                          </Checkbox>
                        </StyledFlexLeftColumn>
                      </>
                    )}
                  </StyledQuestionContainer>
                </Radio>
              )}
            </Space>
          </StyledAddQuestionType>
        ) : (
          QuestionForm
        )}
        {answer ? (
          <>
            <StyledQuestionModalLabel>
              <div>{'Selected Answer'}</div>
              <ToggleShowDisplayAnswer
                showDisplayAnswer={showDisplayAnswer}
                onClick={() => setShowDisplayAnswer(!showDisplayAnswer)}
              />
            </StyledQuestionModalLabel>
            {showDisplayAnswer ? (
              <StyledQuestionContent
                dangerouslySetInnerHTML={{
                  __html: extractDisplayAnswer(answer),
                }}
              ></StyledQuestionContent>
            ) : (
              <StyledQuestionContent>{answer}</StyledQuestionContent>
            )}
          </>
        ) : null}
        {addQuestionType === 'input' && responses && responses.length > 0 ? (
          <ResponseListWrapper className="validation-response__list">
            <div>
              <StyledQuestionModalLabel>
                {'Select Expected Answer'}
                <Dropdown
                  overlay={moreMenu}
                  placement="bottomLeft"
                  trigger={['click']}
                >
                  <MoreMenu />
                </Dropdown>
              </StyledQuestionModalLabel>
              {responses.map(response => {
                return (
                  !!response && (
                    <AnswerWithScore
                      className={classNames({
                        selected:
                          selectedResponse &&
                          selectedResponse.jid === response.jid,
                        defaultAnswer:
                          response.type && response.type.includes('default'),
                        firstAnswer: firstAnswer?.jid === response?.jid,
                      })}
                      showDisplayAnswer={showDisplayAnswer}
                      answer={response}
                      handleSelect={() => handleSelect(response)}
                      key={response.jid}
                    />
                  )
                );
              })}
            </div>
          </ResponseListWrapper>
        ) : null}
      </Modal>
    </Wrapper>
  );
};

ValidationQuestionModal.propTypes = {
  answer: PropTypes.string,
  handleAddQuestion: PropTypes.func,
  handleSelect: PropTypes.func,
  handleShowResponses: PropTypes.func,
  handleUpdateTestCase: PropTypes.func,
  modalLoading: PropTypes.bool,
  mode: PropTypes.oneOf(['Add', 'Edit']).isRequired,
  onChangeText: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  responseCount: PropTypes.number,
  responses: PropTypes.array.isRequired,
  selectedResponse: PropTypes.object,
  testCase: PropTypes.object,
  totalTestCases: PropTypes.number,
  visible: PropTypes.bool.isRequired,
};

export default ValidationQuestionModal;
