import { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';

import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { getTokenSelector } from 'selectors/user';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import { getAllQuestionsWithAnswerSelector } from 'selectors/bot';
import {
  DELETE_QUESTION,
  SET_ALL_QUESTIONS,
  SET_PAGE_DETAILS,
  SHOW_QUESTION_EDITOR,
} from 'store/action';
import { csvToArray, withPrefixUUID } from 'utils';
import useSelector from 'store/useSelector';
import { isMobileViewSelector } from 'selectors/layout';

const useQuestions = () => {
  const [state, dispatch] = useContext(Context);
  const allQuestionsWithAnswer = useSelector(getAllQuestionsWithAnswerSelector);
  const isMobileView = useSelector(isMobileViewSelector);
  const token = useSelector(getTokenSelector);
  const {
    bot: { jid },
  } = state;
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [showQuestionImporter, setShowQuestionImporter] = useState(false);
  const WITH_PREFIX_BOT_JID = withPrefixUUID(jid || location.pathname);
  const [newQuestion, setNewQuestion] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [importFileName, setImportErrorMsg] = useState(null);
  const [importErrorMsg, setImportFileName] = useState(null);
  const [parsedQuestions, setParsedQuestion] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [fileType, setFileType] = useState(null);
  const { sentinel } = state;

  const onChangeQuestion = e => {
    setNewQuestion(e.target.value);
  };

  const onSearchQuestion = e => {
    setSearchKey(e.target.value);
  };

  const handleEditQuestion = async (question, answer) => {
    setLoading(true);
    try {
      const questionToEdit = question?.text;
      await dispatch({
        type: SHOW_QUESTION_EDITOR,
        payload: {
          action: 'edit',
          isHardLinked: answer.jid ? true : false,
          answerId: answer.jid,
          text: questionToEdit,
          jid: question?.jid,
          version: question.version,
        },
      });
    } catch (error) {
      message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const handleAddNewQuestion = async () => {
    setLoading(true);
    try {
      const similarQuestionsRes = await apiService.getSimilarQuestions(
        newQuestion,
        sentinel,
        WITH_PREFIX_BOT_JID,
        token
      );

      const similarQuestions = similarQuestionsRes.data.report[0];
      try {
        dispatch({
          type: SHOW_QUESTION_EDITOR,
          payload: {
            version: 'draft',
            action: 'add',
            similarQuestions: similarQuestions,
            text: newQuestion,
          },
        });
        setNewQuestion(null);
      } catch (error) {
        message.error(error.message || DEFAULT_ERROR_MESSAGE);
      }
    } catch (error) {}
    setNewQuestion(null);
    return setLoading(false);
  };

  const handleDeleteQuestion = async questionId => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await apiService.deleteQuestionHardLink(
        questionId,
        sentinel,
        token
      );
      if (!res.data.success) {
        throw new Error(DEFAULT_ERROR_MESSAGE);
      }
      dispatch({
        type: DELETE_QUESTION,
        payload: questionId,
      });
    } catch (error) {
      message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
    return setLoading(false);
  };

  const getAllQuestions = async () => {
    setLoading(true);
    try {
      const res = await apiService.getAllQuestionsWithAnswer(
        jid,
        sentinel,
        token
      );
      if (!res.data.success) {
        throw new Error(GET_DATA_ERROR);
      }
      dispatch({
        type: SET_ALL_QUESTIONS,
        payload: res.data.report[0],
      });
    } catch (error) {
      message.error(error.message || GET_DATA_ERROR);
      setLoading(false);
      return setLoading(false);
    }
    setLoading(false);
  };

  const handleUploadFile = info => {
    const isCSV = info.type.includes('csv');

    const reader = new FileReader();
    reader.onload = e => {
      const questions = e.target.result;
      let parsedQuestions = [];
      let delimiters = ['\n'];
      setImportFileName(info.name);

      if (isCSV) {
        delimiters.push(',');
      }

      parsedQuestions = csvToArray(questions, delimiters, true);
      if (questions.length) {
        setParsedQuestion(parsedQuestions);
      } else {
        setImportErrorMsg('Your json data format is not correct');
      }
    };
    reader.readAsText(info);
    return false;
  };

  const handleOpenExportModal = () => {
    setOpenExportModal(true);
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
  };

  const handleOpenImportModal = type => {
    setFileType(type);
    setShowImportModal(true);
  };

  const handleCloseImportModal = () => {
    setFileType(null);
    setImportFileName(null);
    setShowImportModal(false);
  };

  useEffect(() => {
    if (searchKey?.length) {
      const filteredAnswers = allQuestionsWithAnswer.filter(
        ({ question, answer }) =>
          question.text?.toLowerCase().includes(searchKey.toLowerCase()) ||
          answer.text?.toLowerCase().includes(searchKey.toLowerCase())
      );
      setFilteredQuestions(filteredAnswers);
    } else {
      setFilteredQuestions([]);
    }
  }, [searchKey]);

  useEffect(() => {
    if (!allQuestionsWithAnswer.length) {
      getAllQuestions();
    }
  }, []);

  return {
    allQuestionsWithAnswer,
    filteredQuestions,
    loading,
    importErrorMsg,
    newQuestion,
    searchKey,
    showQuestionImporter,
    onChangeQuestion,
    onSearchQuestion,
    handleEditQuestion,
    handleAddNewQuestion,
    handleDeleteQuestion,
    handleUploadFile,
    setFilteredQuestions,
    setShowQuestionImporter,
    openExportModal,
    handleOpenExportModal,
    handleCloseExportModal,
    showImportModal,
    fileType,
    handleCloseImportModal,
    handleOpenImportModal,
    isMobileView,
  };
};

export default useQuestions;
