import React from 'react';
import Title from 'components/Title';
import useDashboard from './hooks';
import { dashboardRoutes } from './dashboardRoutes';
import DashboardItem from './DashboardItem/DashboardItem';
import {
  StyledRoot,
  StyledTitleWrapper,
  StyledDashboardMenu,
} from './Dashboard.styles';
import { Col, Row } from 'antd';

const Dashboard = () => {
  const { user } = useDashboard();

  return (
    <StyledRoot>
      <StyledTitleWrapper>
        <Title text={`Hi ${user.firstName}`} type="secondary" />
      </StyledTitleWrapper>
      <Row gutter={[8, 8]}>
        {dashboardRoutes.map(link => {
          return (
            <Col xs={11} md={5}>
              <DashboardItem
                name={link.name}
                alias={link.alias}
                key={link.path}
                link={link.path}
              />
            </Col>
          );
        })}
      </Row>
    </StyledRoot>
  );
};

export default Dashboard;
