import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_EDITOR, EDITORS } from 'constants/answerbank/defaults';
import { StyledAnswerEditor } from './EditorAndViewer.styles';
import { Tabs } from 'antd';
import ChatBubble from 'components/ChatBubble';
import {
  StyledHTMLEditorWrapper,
  StyledRCEEditor,
  StyledRCETip,
} from './Editor/Editor.styles';
import RichTextEditor from 'components/RichTextEditor';
import CodeMirror from './Editor/CodeMirror';
import { answerEditorAnswerSelector } from 'selectors/bot/answers';
import useSelector from 'store/useSelector';

const EditorAndViewer = props => {
  const {
    answerId,
    editor,
    editedHTML,
    editedRichTextAnswer,
    updateFormattedAnswer,
    updateTabs,
    isModal,
    isADefaultAnswer,
  } = props;
  const answerEditorAnswer = useSelector(answerEditorAnswerSelector);
  const htmlInputed = editedHTML || answerEditorAnswer?.html;

  return (
    <StyledAnswerEditor isModal={isModal}>
      <Tabs defaultActiveKey={editor} onChange={updateTabs}>
        <Tabs.TabPane tab="Rich Text Editor" key={EDITORS.RICH_TEXT}>
          <StyledRCEEditor>
            <StyledRCETip>
              ({`Please prefix your links with`} <strong>{`https://`}</strong>{' '}
              {'or'} <strong>{`http://`}</strong>{' '}
              {`if you want the user to be redirected outside
          your page.`}
              )
            </StyledRCETip>
            <RichTextEditor
              answer={editedRichTextAnswer}
              setRichTextAnswer={updateFormattedAnswer}
              answerId={answerId}
              isADefaultAnswer={isADefaultAnswer}
            />
          </StyledRCEEditor>
        </Tabs.TabPane>
        <Tabs.TabPane tab="HTML Editor" key={EDITORS.HTML}>
          <StyledHTMLEditorWrapper>
            <CodeMirror
              isModal={isModal}
              editedHTML={htmlInputed}
              updateAnswer={updateFormattedAnswer}
            />
          </StyledHTMLEditorWrapper>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Preview" key="preview">
          <ChatBubble answer={htmlInputed} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Other Platform Preview" key="otherPlatFormPreview">
          <ChatBubble answer={htmlInputed} isOtherPlatformPreview />
        </Tabs.TabPane>
      </Tabs>
    </StyledAnswerEditor>
  );
};

EditorAndViewer.defaultProps = {
  editor: DEFAULT_EDITOR,
};

EditorAndViewer.propTypes = {
  answerId: PropTypes.string,
  editor: PropTypes.string.isRequired,
  editedHTML: PropTypes.string,
  editedRichTextAnswer: PropTypes.string,
  updateFormattedAnswer: PropTypes.func,
  isModal: PropTypes.bool,
  isADefaultAnswer: PropTypes.bool,
};

export default EditorAndViewer;
