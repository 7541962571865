import { isBoolean, isString, isEqual } from 'lodash';

export const isNumber = x => {
  if (!x) {
    return false;
  }
  if (typeof x === 'number') {
    return true;
  }
  if (typeof x !== 'string') {
    return false;
  }

  const canParse = !isNaN(Number.parseInt(x));
  return canParse && `${Number.parseInt(x)}` === x;
};

export const isAnObject = value => {
  if (!value) {
    return false;
  }

  return typeof value === 'object' &&
    value instanceof Object &&
    !Array.isArray(value)
    ? true
    : false;
};

export const parseBoolean = input => {
  if (isBoolean(input)) {
    return input;
  }

  if (isString(input)) {
    const lowercasedInput = input?.toLowerCase();

    if (isEqual(lowercasedInput, 'true')) {
      return true;
    } else if (isEqual(lowercasedInput, 'false')) {
      return false;
    }
  }

  return false;
};

export const isZSBUUID = uuidStr => {
  const zsbUUIDPattern =
    /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/;
  return zsbUUIDPattern.test(uuidStr);
};

export const getFileExtension = fileName => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return '';
  }

  return fileName.slice(lastDotIndex + 1).toLowerCase();
};

export const isImageOrDocFileType = fileName => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const ext = getFileExtension(fileName);

  return imageExtensions.includes(ext.toLowerCase())
    ? 'image'
    : ext
    ? 'doc'
    : 'invalid';
};
