import styled from 'styled-components';
import { Button, Input, Modal } from 'antd';
import { cssVariables } from 'styles/root';
export const StyledModal = styled(Modal)`
  .dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .dynamic-delete-button:hover {
    color: #777;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  [data-theme='dark'] .dynamic-delete-button {
    color: rgba(255, 255, 255, 0.45);
  }
  [data-theme='dark'] .dynamic-delete-button:hover {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-input-group {
    width: auto;
  }
`;

export const StyledFeatureContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  font-weight: ${cssVariables.font.bold};
  padding: 15px 0;

  > span:first-child {
    margin-top: 10px;
  }

  > input[type='checkbox'] {
    justify-content: space-between;
    align-items: center;
    font-weight: ${cssVariables.font.normal};
    font-size: 12px;
  }

  > .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

export const StyledSwitchWrapper = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  margin-top: 20px;

  > div {
    width: 100%;
    display: flex;

    b:first-child,
    div:first-child:not(.ant-switch-handle) {
      width: 50%;
    }
  }

  > * + * {
    margin: 10px 0;
  }
`;
export const StyledErrorBottomText = styled.span`
  margin-top: 10px;
  font-size: 12px;
  font-weight: ${cssVariables.font.bold};
  text-transform: uppercase;
  text-align: left;
  color: red;
`;
