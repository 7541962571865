import Button from 'components/Button';
import styled from 'styled-components';

export const StyledBackButton = styled(Button)`
  width: fit-content;
  font-size: 12px;
  padding: 5px;
  text-align: center !important;

  .anticon {
    font-size: 18px;
  }
`;
