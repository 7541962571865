import { useEffect, useState, useContext } from 'react';
import { message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { isEqual, isEmpty } from 'lodash';

import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import ROUTES from 'constants/routes';
import { BOT_REDIRECT } from 'constants/localStorage';
import { uuid } from 'uuidv4';
import { stripUUID, withPrefixUUID } from 'utils';
import {
  SET_ANSWERS,
  GET_CATEGORIES,
  SET_TEST_SUITES,
  SET_ANSWER_EDITOR_SELECTED_ANSWER,
  UPDATE_ANSWER,
  PUSH_CHAT_ANSWER,
  PUSH_CHAT_QUESTION,
  SET_BOT_AGGREGATIONS,
  SET_BOT_DETAILS,
  CLEAR_BOT_DETAILS,
  SET_BOT_OVERVIEW,
  PUSH_CHAT_ERROR_REPLY,
  SET_BOT_ERROR,
  CLOSE_ANSWER_EDITOR,
  SET_BOTDETAILS_PAGE_READY,
  SET_CATEGORY_TEMPLATES,
  SET_AI_TOOLS,
} from 'store/action';
import {
  allAnswersSelector,
  botAggregationsSelector,
  topDefaultAnswerSelector,
  fileEditorContentsSelector,
  websiteEditorContentsSelector,
} from 'selectors/bot/answers';
import { allCategoriesSelector } from 'selectors/bot/categories';
import { answerEditorContentsSelector } from 'selectors/bot/answers';
import { getTokenSelector } from 'selectors/user';
import { isFreeUserSelector, userPlanSelector } from 'selectors/plan';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import { fetchBotDetails } from 'services/bots.service';
import { aiTools } from 'services/aiTools.service';
import {
  currentBotSelector,
  isPageReadySelector,
  isBotTypingExperienceEnabledSelector,
  questionEditorSelector,
} from 'selectors/bot';
import useSelector from 'store/useSelector';
import { initialBotState } from 'store/initialState';
import { strippedString } from 'utils/stringManipulation';

const useBotDetails = () => {
  const [state, dispatch] = useContext(Context);
  const botState = useSelector(currentBotSelector);
  const selectedAnswer = useSelector(answerEditorContentsSelector);
  const selectedFile = useSelector(fileEditorContentsSelector);
  const selectedWebsite = useSelector(websiteEditorContentsSelector);
  const allAnswers = useSelector(allAnswersSelector);
  const aggregations = useSelector(botAggregationsSelector);
  const allCategories = useSelector(allCategoriesSelector);
  const isFreeUser = useSelector(isFreeUserSelector);
  const isPageReady = useSelector(isPageReadySelector);
  const questionEditor = useSelector(questionEditorSelector);
  const token = useSelector(getTokenSelector);
  const plan = useSelector(userPlanSelector);
  const defaultAnswer = useSelector(topDefaultAnswerSelector);
  const isBotTypingExperienceEnabled = useSelector(
    isBotTypingExperienceEnabledSelector
  );
  const {
    bot: { hasBotError, jid, name },
    sentinel,
    graph,
    user,
    webSocket,
  } = state;
  const location = useLocation();
  const history = useHistory();
  const [chatItemToChangeAnswer, setChatItemToChangeAnswer] = useState({
    answer: null,
    question: null,
    idx: null,
  });
  const [currentLocation, setCurrentLocation] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [linkedAnswerToEdit, setLinkedAnswerToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [questionForTestSuite, setQuestionForTestSuite] = useState(null);
  const [relatedAnswers, setRelatedAnswers] = useState([]);
  const [showAddQuestionToTestSuiteModal, setShowAddQuestionToTestSuiteModal] =
    useState(false);
  const [showLinkedQuestionModal, setShowLinkedQuestionModal] = useState(false);
  const [setShowResponsePickerWithScoresModal] = useState(false);
  const [contextHistory, setContextHistory] = useState(false);

  const startDateInMS = new Date().setDate(new Date().getDate() - 7);
  const startDate = new Date(startDateInMS).toISOString();
  const todayInMS = new Date();
  const todayISO = new Date(todayInMS).toISOString();
  const { pathname } = location;
  const WITH_PREFIX_BOT_JID = withPrefixUUID(jid || pathname);
  const [maxInteraction, setMaxInteraction] = useState(3);

  useEffect(() => {
    if (!token) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [token]);

  const fetchBotOverview = async () => {
    try {
      const res = await apiService.getBotSummary(
        sentinel,
        WITH_PREFIX_BOT_JID,
        token
      );
      const totals = res.data?.report[0];
      dispatch({
        type: SET_BOT_OVERVIEW,
        payload: totals,
      });
    } catch (error) {
      setLoading(false);
      throw error.message;
    }
  };

  const fetchAnswers = async () => {
    setContentLoading(true);
    try {
      const answersResponse = await apiService.getAnswers(
        sentinel,
        WITH_PREFIX_BOT_JID,
        token
      );

      const filesResponse = await apiService.getFiles(
        sentinel,
        WITH_PREFIX_BOT_JID,
        token
      );
      dispatch({
        type: SET_ANSWERS,
        payload: {
          answers: answersResponse.data.report[0],
          files: filesResponse.data.report[0],
          // ATM the index [1] is where the API stored the default answer
          defaultAnswer:
            answersResponse.data?.report[1] && isEmpty(defaultAnswer)
              ? answersResponse.data.report[1]
              : [],
        },
      });
    } catch (err) {}

    setContentLoading(false);
  };

  const fetchTools = async () => {
    try {
      const res = await aiTools.getTools(jid);
      dispatch({
        type: SET_AI_TOOLS,
        payload: res.data.report,
      });
    } catch (error) {
      message.error(GET_DATA_ERROR);
    }
  };

  const fetchCategoryTemplates = async () => {
    try {
      const response = await apiService.getCategoryTemplates(
        sentinel,
        jid,
        token
      );

      const categoryTemplates = response.data?.report[0];
      dispatch({ type: SET_CATEGORY_TEMPLATES, payload: categoryTemplates });
    } catch (error) {
      message.error(
        error.message ||
          'Something went wrong while fetching category templates.'
      );
    }
  };

  const fetchAggregations = async () => {
    setLoading(true);
    try {
      const ESRes = await apiService.getElasticLogDateFilter(
        sentinel,
        WITH_PREFIX_BOT_JID,
        startDate,
        todayISO,
        token,
        [],
        [],
        { for_aggs: true }
      );

      if (!ESRes?.data?.report[0]) {
        throw new Error(GET_DATA_ERROR);
      }

      const { hits, aggregations } = ESRes.data.report[0];

      await dispatch({
        type: SET_BOT_AGGREGATIONS,
        payload: { aggregations, hits },
      });
    } catch (error) {
      setLoading(false);
      // Don't show `No records found` from bot overview page
      // since its unadjustable
      !error.message.includes('No records found') &&
        message.error(
          typeof error === 'string'
            ? error
            : error.message !== DEFAULT_ERROR_MESSAGE
            ? error.message
            : 'Something went wrong while fetching bot summary data'
        );
    }

    try {
      await fetchBotOverview();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return error.message && !error.message.includes('No records found')
        ? message.error(error.message)
        : message.error(
            'Something went wrong while fetching test suites and integration summary'
          );
    }

    await dispatch({
      type: SET_BOTDETAILS_PAGE_READY,
    });

    try {
      fetchAnswers();
      fetchTools();
      fetchCategories();
      fetchTestSuites();
      fetchCategoryTemplates();
    } catch (error) {
      dispatch({
        type: SET_BOTDETAILS_PAGE_READY,
      });
      throw new TypeError(
        typeof error === 'string'
          ? error
          : error.message !== DEFAULT_ERROR_MESSAGE
          ? error.message
          : 'We encountered an error while getting response'
      );
    }
    return setLoading(false);
  };

  const redirectToAnswerbank = () => {
    history.push(`${ROUTES.BOT_DETAILS}/${stripUUID(jid)}${ROUTES.ANSWERLIST}`);
  };

  useEffect(() => {
    async function hydrateBotDetails(sentinel, jid, token) {
      try {
        const botDetails = await fetchBotDetails(sentinel, jid, token);
        const answerCount = botDetails[0]?.context?.answer_count;
        const fileCount = botDetails[0]?.context?.file_count;
        if (!answerCount && !fileCount) {
          redirectToAnswerbank();
        }
        dispatch({
          type: SET_BOT_DETAILS,
          payload: { jid, ...botDetails },
        });
      } catch (error) {
        dispatch({
          type: SET_BOT_ERROR,
          payload: null,
        });
      }
    }

    if (!stripUUID(WITH_PREFIX_BOT_JID) && !jid) {
      dispatch({
        type: SET_BOT_ERROR,
        payload: null,
      });
      return history.push(ROUTES.BOTS_PAGE);
    }
    if (
      sentinel &&
      token &&
      isEqual(botState, initialBotState) &&
      location.pathname.includes(
        `${ROUTES.BOT_DETAILS}/${stripUUID(WITH_PREFIX_BOT_JID)}`
      )
    ) {
      hydrateBotDetails(sentinel, WITH_PREFIX_BOT_JID, token);
    }
    if (sentinel && token && jid && !Object.keys(aggregations).length) {
      fetchAggregations(true);
    }
  }, [sentinel, token, jid]);

  useEffect(() => {
    setCurrentLocation(location.pathname);
    if (
      location.pathname.includes(ROUTES.ANSWERBANK) ||
      location.pathname.includes(ROUTES.QUESTIONS)
    ) {
      setIsChatVisible(true);
    } else {
      setIsChatVisible(false);
    }
    return () => setIsChatVisible(false);
  }, [location]);

  // TODO: move setting of payload to reducer
  // to reduce passing this func to the components as prop
  const handleShowAnswerEditorModal = (answer, args) => {
    dispatch({
      type: SET_ANSWER_EDITOR_SELECTED_ANSWER,
      payload: {
        answer,
        args,
      },
    });
  };

  const handleCloseAnswerEditor = () => {
    dispatch({
      type: CLOSE_ANSWER_EDITOR,
      payload: {
        show_text: null,
        show_html: null,
        text: null,
        id: null,
      },
    });
  };

  const getStringAnswerDuplicate = (input, id) => {
    const strippedInput = strippedString(input);
    return allAnswers.find(
      answer =>
        strippedString(answer.text) === strippedInput && answer.jid !== id
    );
  };

  const onUpdateAnswer = async (
    id,
    newAnswer,
    formattedAnswer,
    categoryId,
    chatIndex,
    editor,
    quickButtons,
    version
  ) => {
    setLoading(true);
    const isAnswerExists = getStringAnswerDuplicate(newAnswer, id);
    const matchedCategory = allCategories.find(
      category => stripUUID(category.jid) === categoryId
    );
    try {
      if (isAnswerExists) {
        throw new TypeError('Answer already exists.');
      }
      const res = await apiService.changeAnswer(
        sentinel,
        id,
        newAnswer,
        formattedAnswer,
        matchedCategory?.jid || null,
        token,
        editor,
        quickButtons,
        version
      );
      const updatedAnswer = res.data.report[0];

      dispatch({
        type: UPDATE_ANSWER,
        payload: { updatedAnswer, msgIndex: chatIndex },
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err.message;
    }
  };

  const getStoredInteractions = () => {
    const interactionList = JSON.parse(localStorage.getItem('interactions'));
    if (interactionList?.length > maxInteraction * 2) {
      interactionList.splice(0, 2);
    }
    return contextHistory ? interactionList || [] : [];
  };

  const handleAddInteraction = ({ newMessage, answerMessage }) => {
    let history = [];
    history.push({
      from: 'user',
      message: newMessage,
    });
    history.push({
      from: 'assistant',
      message: answerMessage.text,
    });
    localStorage.setItem(
      'interactions',
      JSON.stringify([...getStoredInteractions(), ...history])
    );
  };

  const handleChatAskQuestion = async (
    chatQuestion,
    channel,
    includeDraftAnswers
  ) => {
    const theTextQuestion =
      typeof chatQuestion === 'object' ? chatQuestion.question : chatQuestion;
    const displayQuestion =
      typeof chatQuestion === 'object' ? chatQuestion.label : chatQuestion;
    const interactionId = uuid();

    const questionPayload = {
      text: displayQuestion,
      metadata: { channel, interactionId },
      use_draft: includeDraftAnswers,
      history: getStoredInteractions(),
      ws_target: webSocket?.channel || null,
    };

    await dispatch({
      type: PUSH_CHAT_QUESTION,
      payload: questionPayload,
    });

    try {
      const res = await apiService.askQuestion(
        sentinel,
        WITH_PREFIX_BOT_JID,
        theTextQuestion,
        token,
        channel,
        includeDraftAnswers,
        getStoredInteractions(),
        isBotTypingExperienceEnabled ? webSocket.channel : null,
        interactionId
      );
      if (!res.data.success && !res.data.is_queued) {
        throw new Error(res?.data?.errors || DEFAULT_ERROR_MESSAGE);
      }

      if (!res.data.is_queued) {
        const chatAnswer = res.data.report[0];
        if (contextHistory) {
          handleAddInteraction({
            newMessage:
              typeof chatQuestion === 'object'
                ? chatQuestion.question
                : chatQuestion,
            answerMessage: chatAnswer.context,
          });
        }

        dispatch({
          type: PUSH_CHAT_ANSWER,
          payload: chatAnswer,
        });
      }
    } catch (err) {
      dispatch({
        type: PUSH_CHAT_ERROR_REPLY,
        payload: err?.message,
      });
      throw err.message;
    }
  };

  const handleSeekAnswer = answerId => {
    if (Array.isArray(answerId)) {
      const ids = answerId.map(({ id }) => `.answer-${stripUUID(id)}`);
      const previousHighlighted = document.querySelectorAll('.highlight');
      if (!isEmpty(previousHighlighted)) {
        previousHighlighted.forEach((el, idx) => {
          if (!idx) {
            el?.scrollIntoViewIfNeeded();
          }
          el?.classList?.remove('highlight', 'seekBgColor');
        });
      }
      const target = document.querySelectorAll(ids.join(','));
      target.forEach((el, idx) => {
        // if multiple answers
        // scroll to the first answer only
        if (!idx) {
          el?.scrollIntoViewIfNeeded();
        }
        el?.classList?.add('highlight', 'seekBgColor');
      });

      setTimeout(() => {
        target.forEach((el, idx) => {
          // if multiple answers
          // scroll to the first answer only
          if (!idx) {
            el?.scrollIntoViewIfNeeded();
          }
          el?.classList?.remove('highlight', 'seekBgColor');
        });
      }, 10000);
    } else {
      const previousHighlighted = document.querySelector('.highlight');
      if (previousHighlighted) {
        previousHighlighted.classList.remove('highlight');
      }
      const target = document.getElementById(answerId);
      target.scrollIntoViewIfNeeded();
      target.classList.add('highlight', 'seekBgColor');
      setTimeout(() => {
        target.classList.remove('seekBgColor');
      }, 4000);
    }
  };

  const fetchTestSuites = async () => {
    try {
      const res = await apiService.getTestSuites(
        sentinel,
        WITH_PREFIX_BOT_JID,
        token
      );

      await dispatch({
        type: SET_TEST_SUITES,
        payload: res.data?.report,
      });
    } catch (err) {
      throw err?.message || GET_DATA_ERROR;
    }
  };

  const handleShowAddTestSuiteModal = askedQuestion => {
    const userAsked =
      typeof askedQuestion === 'object'
        ? askedQuestion.question
        : askedQuestion;
    setQuestionForTestSuite(userAsked);
    setShowAddQuestionToTestSuiteModal(true);
  };

  const handleCloseLinkedQuestionModal = () => {
    setLinkedAnswerToEdit(null);
    setShowLinkedQuestionModal(false);
  };

  const handleShowLinkedQuestions = val => {
    setLinkedAnswerToEdit(val);
    setShowLinkedQuestionModal(true);
  };

  const handleCloseResponsePickerModal = () => {
    setChatItemToChangeAnswer({
      answer: null,
      question: null,
      idx: null,
    });
    setRelatedAnswers([]);
    setShowResponsePickerWithScoresModal(false);
  };

  const fetchCategories = async () => {
    try {
      const res = await apiService.getCategories(
        sentinel,
        WITH_PREFIX_BOT_JID,
        token
      );
      if (!res.data?.report[0]) {
        throw new Error(GET_DATA_ERROR);
      }
      dispatch({
        type: GET_CATEGORIES,
        payload: res?.data?.report[0],
      });
    } catch (err) {
      throw typeof err === 'string' ? err : err.message;
    }
  };

  useEffect(() => {
    if (isPageReady && localStorage.getItem(BOT_REDIRECT)) {
      localStorage.removeItem(BOT_REDIRECT);
    }
  }, [isPageReady]);

  useEffect(() => {
    return () => {
      setLoading(false);
      dispatch({
        type: CLEAR_BOT_DETAILS,
        payload: null,
      });
    };
  }, []);

  return {
    allAnswers,
    WITH_PREFIX_BOT_JID,
    allCategories,
    chatItemToChangeAnswer,
    contentLoading,
    currentLocation,
    dispatch,
    isChatVisible,
    isFreeUser,
    graph,
    handleChatAskQuestion,
    handleShowAnswerEditorModal,
    handleCloseAnswerEditor,
    handleCloseResponsePickerModal,
    handleCloseLinkedQuestionModal,
    handleShowLinkedQuestions,
    handleShowAddTestSuiteModal,
    handleSeekAnswer,
    isPageReady,
    linkedAnswerToEdit,
    loading,
    name,
    onUpdateAnswer,
    questionForTestSuite,
    sentinel,
    onboarding_flag: plan.onboarding_flag,
    selectedAnswer,
    selectedFile,
    selectedWebsite,
    setShowAddQuestionToTestSuiteModal,
    showAddQuestionToTestSuiteModal,
    showLinkedQuestionModal,
    token,
    aggregations,
    user,
    jid,
    hasBotError,
    fetchCategories,
    questionEditor,
    contextHistory,
    setContextHistory,
    maxInteraction,
    setMaxInteraction,
  };
};

export default useBotDetails;
