import React, { useContext, useEffect, useState } from 'react';
import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { GET_ALL_VERSIONS } from 'store/action';
import AdminSidebar from '../AdminSidebar';
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitleWrapper,
  StyledTitle,
  StyledSortAddWrapper,
  StyledSortingWrapper,
  StyledSearchWrapper,
} from './Versions.style';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { getTokenSelector } from 'selectors/user';
import VersionsTable from './VersionsTable/VersionsTable';
import VersionSearch from './VersionSearch/VersionSearch';
import _ from 'lodash';
import { SidebarRoutes } from '../SidebarRoutes';

const Versions = () => {
  const [state, dispatch] = useContext(Context);
  const token = getTokenSelector(state);
  const [initLoading, setInitLoading] = useState(true);
  const [currentData, setCurrentData] = useState([]);
  const {
    admin: { all_versions },
    sentinel,
  } = state;

  useEffect(() => {
    const getVersions = async () => {
      setInitLoading(true);
      try {
        await getAllVersions();
      } catch (error) {
        throw new Error(error.message || DEFAULT_ERROR_MESSAGE);
      }
    };

    getVersions();
    setInitLoading(false);
  }, [dispatch, token]);

  useEffect(() => {
    if (all_versions?.length > 0) {
      setCurrentData(_.orderBy(all_versions, 'status', 'desc'));
    }
  }, [all_versions]);

  const getAllVersions = async () => {
    const allVersions = await apiService.getVersions(sentinel, token);
    if (!allVersions || !allVersions.data) {
      throw new Error(DEFAULT_ERROR_MESSAGE);
    }

    const versionData = allVersions?.data.report[0];
    const allVersionsData = Object.entries(versionData.versions).map(
      version => ({
        version: version[0],
        features_string: JSON.stringify(version[1]),
        features: version[1],
        status: version[0] === versionData.current ? 'current' : '',
      })
    );
    await dispatch({
      type: GET_ALL_VERSIONS,
      payload: allVersionsData,
    });
  };

  const handleSearch = async event => {
    const searchedValue = all_versions.filter(all_version =>
      all_version.version
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setCurrentData(searchedValue);
  };

  return (
    <>
      <StyledWrapper>
        <AdminSidebar title="Admin" route={SidebarRoutes} />
        <StyledContentWrapper>
          <StyledTitleWrapper>
            <StyledTitle>Version Management</StyledTitle>
          </StyledTitleWrapper>
          <StyledSortAddWrapper>
            <StyledSortingWrapper>
              <StyledSearchWrapper>
                <VersionSearch onChange={handleSearch} />
              </StyledSearchWrapper>
            </StyledSortingWrapper>
          </StyledSortAddWrapper>
          <VersionsTable
            totalDatas={currentData.length}
            loading={initLoading}
            pageSize={10}
            setInitLoading={setInitLoading}
            datatable={currentData}
            getAllVersions={getAllVersions}
            sentinel={sentinel}
            token={token}
            setCurrentData={setCurrentData}
          />
        </StyledContentWrapper>
      </StyledWrapper>
    </>
  );
};

export default Versions;
