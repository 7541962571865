import { useContext, useState } from 'react';

import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import {
  currentIntegrationSettingsSettingPropSelector,
  isAppearancePanelSettingsUnchangedSelector,
  widgetHeightSelector,
} from 'selectors/bot/integration';
import { botNameSelector } from 'selectors/bot';
import {
  SET_BOT_CUSTOMIZER_ACTIVE_PANEL,
  SET_SHOW_WIDGET_AVATAR_PICKER_MODAL,
  SET_WIDGET_FONT_COLOR,
  SET_WIDGET_HEIGHT,
  SET_WIDGET_THEME_COLOR,
} from 'store/action';
import { launcherAvatarSelector } from 'selectors/bot/integration/settings';

export const useAppearancePanel = () => {
  const settings = useSelector(currentIntegrationSettingsSettingPropSelector);
  const isAppearancePanelSettingsUnchanged = useSelector(
    isAppearancePanelSettingsUnchangedSelector
  );
  const botName = useSelector(botNameSelector);
  const launcherAvatar = useSelector(launcherAvatarSelector);
  const height = useSelector(widgetHeightSelector);

  const [, dispatch] = useContext(Context);

  const { iconColor, color, textColor } = settings;

  const [tempHeight, setHeight] = useState(height);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);

  const handleEditAvatar = () => {
    dispatch({ type: SET_SHOW_WIDGET_AVATAR_PICKER_MODAL });
  };

  const handleChangeTheme = color => {
    dispatch({ type: SET_WIDGET_THEME_COLOR, payload: color.hex });
  };

  const handleChangeHeight = () => {
    dispatch({ type: SET_WIDGET_HEIGHT, payload: tempHeight });
  };

  const handleSelectFontColor = val => {
    dispatch({ type: SET_WIDGET_FONT_COLOR, payload: val });
  };

  const handlePanelChange = val => {
    dispatch({ type: SET_BOT_CUSTOMIZER_ACTIVE_PANEL, payload: val });
  };

  return {
    handleEditAvatar,
    handleChangeTheme,
    handleChangeHeight,
    handleSelectFontColor,
    setHeight,
    setShowColorPicker,
    setShowAdvanceSettings,
    showColorPicker,
    showAdvanceSettings,
    iconColor,
    color,
    height,
    textColor,
    botName,
    isAppearancePanelSettingsUnchanged,
    launcherAvatar,
    tempHeight,
    handlePanelChange,
  };
};
