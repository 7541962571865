import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Typography, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  StyledCodeButton,
  StyledSnippets,
  StyledIntegrationSteps,
  StyledPanelSubHeader,
  StyledCodeBlock,
} from './API.styles';
import useAPI from './hooks';
import Button from 'components/Button';
import ROUTES from 'constants/routes';

const { Paragraph } = Typography;
const API = () => {
  const history = useHistory();
  const {
    apiGatewayConfig,
    jid,
    payload,
    header,
    generateDummyIdPayload,
    generateBoleanPayload,
    generatePayload,
  } = useAPI();

  const botIdPayload = useMemo(
    () => `  "bot_id": "${jid}",  `,
    [apiGatewayConfig]
  );

  const questionPayload = useMemo(
    () => `  "question": "<your-question-here>",  `,
    [apiGatewayConfig]
  );

  const interactionIdPayload = useMemo(
    () => generateDummyIdPayload('interaction_id'),
    [apiGatewayConfig]
  );

  const sessionIdPayload = useMemo(
    () => generateDummyIdPayload('session_id'),
    [apiGatewayConfig]
  );

  const visitorIdPayload = useMemo(
    () => generateDummyIdPayload('visitor_id'),
    [apiGatewayConfig]
  );

  const authenticatedPayload = useMemo(
    () => generateBoleanPayload('is_authenticated'),
    [apiGatewayConfig]
  );

  const asyncPayload = useMemo(
    () => generateBoleanPayload('is_async'),
    [apiGatewayConfig]
  );

  return (
    <StyledIntegrationSteps>
      <StyledPanelSubHeader>
        <span>{`Copy the code API Config below using POST request method. Analytics and reports for this integration can be viewed in integrated channels.`}</span>
      </StyledPanelSubHeader>
      <StyledSnippets>
        <Row>
          <Col
            xs={{ span: 15 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            {'Copy and paste this endpoint.'}
          </Col>
          <Col
            xs={{ span: 4, offset: 0 }}
            md={{ span: 4, offset: 8 }}
            lg={{ span: 4, offset: 8 }}
            xl={{ span: 4, offset: 8 }}
          >
            <CopyToClipboard text={apiGatewayConfig?.url}>
              <StyledCodeButton
                className="secondary"
                value="Copy code"
                onClick={() => message.info('Copied to clipboard.')}
              />
            </CopyToClipboard>
          </Col>
        </Row>
        <StyledCodeBlock>
          <Paragraph>
            <pre>
              <code>{apiGatewayConfig?.url}</code>
            </pre>
          </Paragraph>
        </StyledCodeBlock>
        <Row>
          <Col
            xs={{ span: 15 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            {'Copy and paste this to the header.'}{' '}
          </Col>
          <Col
            xs={{ span: 4, offset: 0 }}
            md={{ span: 8, offset: 4 }}
            lg={{ span: 6, offset: 6 }}
            xl={{ span: 6, offset: 6 }}
          >
            <Button
              value="Get API Key"
              variant="link"
              onClick={() => history.push(ROUTES.API_KEY)}
            />
            <CopyToClipboard text={header}>
              <StyledCodeButton
                className="secondary"
                value="Copy code"
                onClick={() => message.info('Copied to clipboard.')}
              />
            </CopyToClipboard>
          </Col>
        </Row>
        <StyledCodeBlock>
          <Paragraph>
            <pre>
              <code>{header}</code>
            </pre>
          </Paragraph>
        </StyledCodeBlock>

        <Row>
          <Col
            xs={{ span: 15 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            Copy and paste this payload.
          </Col>
          <Col
            xs={{ span: 4, offset: 0 }}
            md={{ span: 4, offset: 8 }}
            lg={{ span: 4, offset: 8 }}
            xl={{ span: 4, offset: 8 }}
          >
            <CopyToClipboard text={payload}>
              <StyledCodeButton
                className="secondary"
                value="Copy code"
                onClick={() => message.info('Copied to clipboard.')}
              />
            </CopyToClipboard>
          </Col>
        </Row>
        <StyledCodeBlock>
          <Paragraph>
            <pre>
              <code>
                {`{ `}
                {generatePayload(true, botIdPayload)}
                {generatePayload(true, questionPayload)}
                {generatePayload(
                  false,
                  interactionIdPayload,
                  'getDummyComment',
                  'interaction_id'
                )}
                {generatePayload(
                  false,
                  sessionIdPayload,
                  'getDummyComment',
                  'session_id'
                )}
                {generatePayload(
                  false,
                  visitorIdPayload,
                  'getDummyComment',
                  'visitor_id'
                )}
                {generatePayload(false, authenticatedPayload, 'authenticated')}
                {generatePayload(false, asyncPayload, 'async')}
                {`}`}
              </code>
            </pre>
          </Paragraph>
        </StyledCodeBlock>
      </StyledSnippets>
    </StyledIntegrationSteps>
  );
};
export default API;
