const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  EXTERNAL_LOGIN: '/external-login',
  SIGNIN: '/sign-in',
  SIGNUP: '/signup',
  EXTERNAL_SIGNUP: '/external-signup',
  LOGOUT: '/logout',
  BOTS_PAGE: '/bots',
  ADMIN_PAGE: '/admin',
  ADMIN_USERS: '/admin/users',
  ADMIN_GLOBAL_VARS: '/admin/global-vars',
  ADMIN_ACTIVITY_LOGS: '/admin/activity-logs',
  ADMIN_VERSIONS: '/admin/versions',
  BOT_DETAILS: '/bot',
  USER_PROFILE: '/profile',
  USER_PASSWORD: '/profile/password',
  PLAN_AND_PAYMENTS: '/profile/plan',
  LANGUAGE_AND_REGION: '/profile/language',
  SECURITY: '/profile/security',
  INTEGRATION: '/profile/integration',
  ACTIVITY_LOGS: '/profile/activity-logs',
  RESET_PASSWORD: '/reset-password',
  CONFIRM_NEW_PASSWORD: '/confirm-new-password',
  SUBSCRIPTION: '/profile/subscription',
  ONBOARDING_FLAGS: '/profile/onboarding-flags',
  VERIFY_EMAIL: '/activation',
  VALIDATION: '/automation-testing',
  BOT_INTEGRATION: '/integration',
  CALLBACK_LOGS: '/callback-logs',
  FUNCTIONS: '/functions',
  BOT_SETTINGS: '/bot-settings',
  QUESTIONS: '/linked-questions',
  ANSWERBANK: '/answer-bank',
  ANSWERLIST: '/answer-bank/answers',
  ANALYTICS: '/analytics',
  BOT_OVERVIEW: '/overview',
  ANALYTICS_QUESTION_LOGS: '/analytics#question-log',
  MOST_ASKED_QUESTIONS: '/analytics#most-asked-question',
  ANALYTICS_CATEGORY: '/analytics#most-asked-question?category=',
  FLOW_SIMULATOR: '/flow-simulator',
  API_KEY: '/profile/api-key',
};

export default ROUTES;
