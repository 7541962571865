import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Col, Collapse, Dropdown, Menu, Row, Spin, Tag, Upload } from 'antd';
import styled from 'styled-components';
import {
  CaretDownFilled,
  CaretRightOutlined,
  DeleteTwoTone,
  EditTwoTone,
  ImportOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import Title from 'components/Title';
import { StyledBotDetailsWrapper } from 'styles/GenericStyledComponents';
import useQuestions from './hooks';
import { cssVariables } from 'styles/root';
import TextArea from 'components/TextArea';
import { FlexRowSpaceBetweenWrapper } from 'components/Modals/AnswerEditor/Variant/Variant.styles';
import Modal from 'components/Modals/GenericModal';
import { StyledAntUploadContent } from 'components/Modals/ImportAnswerModal/ImportAnswerModal.style';
import Button from 'components/Button';
import Input from 'components/Input';
import ExportModal from 'components/Modals/ExportModal';
import SettingsDropdownMenu from './SettingsDropdownMenu/SettingsDropdownMenu';
import { StyledDropdownButtonMenu } from '../BotDetails.styles';
import QuestionImportExportMenu from './QuestionImportExportMenu/QuestionImportExportMenu';
import ImportQuestionModal from 'components/Modals/ImportQuestionModal';

const { Panel } = Collapse;
const { Dragger } = Upload;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-arrow {
    height: 80% !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
  }
  .ant-collapse-expand-icon {
    align-self: center;
  }

  .ant-collapse-header .ant-collapse-header-text {
    align-self: center;
  }

  .ant-collapse-extra {
    width: 30%;
  }
`;

const StyledSearch = styled(Input)`
  border: none;
  border-bottom: 1px solid ${cssVariables.gray1} !important;
  // width: 24vw;
`;

const Questions = props => {
  const {
    allQuestionsWithAnswer,
    filteredQuestions,
    loading,
    newQuestion,
    searchKey,
    showQuestionImporter,
    onSearchQuestion,
    onChangeQuestion,
    handleDeleteQuestion,
    handleEditQuestion,
    handleAddNewQuestion,
    handleUploadFile,
    setShowQuestionImporter,
    openExportModal,
    handleOpenExportModal,
    handleCloseExportModal,
    showImportModal,
    fileType,
    handleCloseImportModal,
    handleOpenImportModal,
    isMobileView,
  } = useQuestions();
  const ErrorPage = () => (
    <div>
      <h2>Cannot load the page. Please refresh your browser.</h2>
    </div>
  );

  const questionsWithAnswer = useMemo(() => {
    if (filteredQuestions?.length && searchKey?.length) {
      return filteredQuestions;
    } else if (!searchKey) {
      return allQuestionsWithAnswer;
    }
    return [];
  }, [allQuestionsWithAnswer, filteredQuestions]);

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Spin spinning={loading}>
        <Title
          text={`Linked Questions (${allQuestionsWithAnswer?.length})`}
          type="responsive-text"
        />
        <Row gutter={[0, 8]}>
          <Col xs={24} sm={19} lg={20}>
            <TextArea
              value={newQuestion}
              onChange={onChangeQuestion}
              autoSize={{ minRows: 1.3, maxRows: 3 }}
              noPaddingTop
              placeholder={'Type question to add...'}
            />
          </Col>
          <Col xs={24} sm={4}>
            <StyledDropdownButtonMenu
              type="primary"
              icon={<CaretDownFilled />}
              isMainButtonDisabled={!newQuestion}
              onClick={evt => {
                evt.preventDefault();
                handleAddNewQuestion();
              }}
              overlay={
                <QuestionImportExportMenu
                  handleOpenImportModal={handleOpenImportModal}
                />
              }
              title={!newQuestion ? 'Question is required' : 'Save Question'}
              isQuestionPage={true}
            >
              {'Add Question'}
            </StyledDropdownButtonMenu>
          </Col>
        </Row>
        <Row style={{ marginTop: '2%' }} gutter={[16, 8]}>
          <Col
            xs={{ span: 23 }}
            sm={{ span: 23 }}
            md={{ span: 1 }}
            lg={{ span: 2 }}
            xl={{ span: 1 }}
            style={{ marginTop: '.5%', marginRight: '1%' }}
          >
            <h3>{'Search: '}</h3>
          </Col>
          <Col
            xs={{ span: 23 }}
            sm={{ span: 23 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
          >
            <StyledSearch
              prefix={
                <SearchOutlined style={{ color: cssVariables.primaryBlue }} />
              }
              bordered={false}
              onChange={onSearchQuestion}
              value={searchKey}
              placeholder="Type question to search..."
            />
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 3, offset: 14 }}
            md={{ span: 3, offset: 13 }}
            lg={{ span: 3, offset: 11 }}
            xl={{ span: 3, offset: 13 }}
          >
            <Dropdown
              overlay={
                <SettingsDropdownMenu
                  questionsWithAnswer={questionsWithAnswer}
                  handleOpenExportModal={handleOpenExportModal}
                />
              }
              menudisabled={!questionsWithAnswer?.length}
              trigger={['click']}
            >
              <Button
                value={'Settings'}
                startIcon={<SettingOutlined />}
                variant="link"
                ghost
              />
            </Dropdown>
          </Col>
        </Row>

        <StyledBotDetailsWrapper style={{ marginTop: 10 }}>
          {questionsWithAnswer.length ? (
            questionsWithAnswer?.map((item, idx) => {
              return (
                <StyledCollapse
                  size="large"
                  key={idx}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                >
                  <Panel
                    header={item.question?.text}
                    extra={
                      <Menu
                        style={{ background: 'transparent' }}
                        mode="horizontal"
                      >
                        <Menu.Item disabled>
                          {item.question?.version ? (
                            <Tag
                              style={{
                                width: isMobileView ? '100%' : 50,
                                textAlign: 'center',
                              }}
                              color={
                                item.question.version === 'final'
                                  ? 'green'
                                  : 'red'
                              }
                            >
                              {item.question?.version}
                            </Tag>
                          ) : null}
                        </Menu.Item>

                        <Menu.Item
                          key={`Edit`}
                          onClick={() =>
                            handleEditQuestion(item.question, item.answer)
                          }
                        >
                          <Button
                            variant="link"
                            value={isMobileView ? 'Edit' : ''}
                            icon={<EditTwoTone />}
                            full
                          />
                        </Menu.Item>
                        <Menu.Item
                          key={`Delete`}
                          onClick={() =>
                            handleDeleteQuestion(item.question?.jid)
                          }
                        >
                          <Button
                            variant="link"
                            value={isMobileView ? 'Delete' : ''}
                            icon={
                              <DeleteTwoTone
                                twoToneColor={cssVariables.red10}
                              />
                            }
                            full
                          />
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <p style={{ width: '100%' }}>
                      {'Answer'}
                      {': '}
                      {item.answer?.text}
                    </p>
                  </Panel>
                </StyledCollapse>
              );
            })
          ) : (
            <div>
              <h2>Empty</h2>
            </div>
          )}
        </StyledBotDetailsWrapper>
        <Modal
          title="Import Questions"
          isFormModal
          visible={showQuestionImporter}
          onOk={() => setShowQuestionImporter(false)}
          onCancel={() => setShowQuestionImporter(false)}
        >
          <Dragger
            headers={{ 'Content-Type': 'multipart/form-data' }}
            beforeUpload={handleUploadFile}
            accept={
              'text/plain, .csv, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            listType="picture"
            // onRemove={handleRemoveFile}
            maxCount={1}
          >
            <StyledAntUploadContent>
              <ImportOutlined />
              Drag files here or select file
            </StyledAntUploadContent>
          </Dragger>
        </Modal>
      </Spin>

      {openExportModal && (
        <ExportModal
          show={openExportModal}
          onClose={handleCloseExportModal}
          onloadSelected={['question']}
        />
      )}

      {showImportModal ? (
        <ImportQuestionModal
          show={showImportModal}
          onClose={handleCloseImportModal}
          fileType={fileType}
        />
      ) : null}
    </ErrorBoundary>
  );
};

Questions.propTypes = {};

export default Questions;
