import { SET_CATEGORY_TEMPLATES } from 'store/action';

export const categoriesTemplatesReducer = (state, action) => {
  switch (action.type) {
    case SET_CATEGORY_TEMPLATES: {
      const extractedCategoryTemplates = Object.values(action.payload).map(
        cat => {
          return {
            name: cat.category[0]?.name,
            color: cat.category[0]?.color,
            answers: cat.answer,
          };
        }
      );

      return {
        ...state,
        categoryTemplates: extractedCategoryTemplates,
      };
    }

    default:
      return state;
  }
};
