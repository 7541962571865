import { Menu } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledMenuItem = styled(Menu.Item)`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.normal};
  // font-size: 15px;
  color: #fff !important;
  // line-height: 18px;
  cursor: pointer;
  // margin-right: 32px;
  border-bottom: none !important;

  &.ant-menu-item-active::after {
    border-bottom: none !important;
  }

  &:hover {
    // border-bottom: 1px solid #fff;
    text-decoration: underline 1px;
    text-underline-offset: 3px;
  }
`;
