import styled from 'styled-components';
import { Card } from 'antd';
import { cssVariables } from 'styles/root';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const OverviewContent = styled(Card)`
  width: 100%;
  margin: 24px auto;
  display: flex;
  align-items: center;

  .ant-card-body {
    width: 100%;
  }

  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 0;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 8px;
  }
`;

export const DataSummary = styled(Card)`
  border: 1px solid ${props => props.theme.blue1};
  height: 150px;
  background-color: ${props => (props.disabled ? props.theme.gray5 : 'none')};

  .ant-card-body {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: ${props =>
      props.disabled ? props.theme.gray1 : props.theme.primaryColor} !important;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};

    a {
      color: ${props =>
        props.disabled ? props.theme.gray1 : props.theme.primaryBlue};
      cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    }

    span:nth-child(2) {
      font-size: ${props => (props.disabled ? '12px' : '24px')};
      font-weight: ${props => (props.disabled ? 500 : 700)};
    }

    div:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span:first-child {
        font-size: 24px;
        font-weight: ${cssVariables.font.bold};
      }
      span.ant-tag {
        height: 100%;
        font-size: 12px;
      }
    }
  }
`;

export const WeakbotWarning = styled.p`
  background-color: ${cssVariables.red2};
  color: ${cssVariables.red10};
  font-size: ${props => (props.isHeader ? `16px` : `11px`)};
  padding: ${props => (props.isHeader ? `10px 16px` : `3px 5px`)};
  line-height: 16px;
  margin: 0;
`;

export const WeakbotWarningClickable = styled.span`
  text-decoration: underline;
  :hover {
    font-weight: bold;
    text-decoration: none;
  }
  cursor: pointer;
`;

export const WeakBotContent = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  border-radius: 10px;
  font-family: 'Roboto';
  font-style: normal;

  > h3 {
    margin-top: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #333333;
  }
  > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const WeakBotContentActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;
