import { MoreOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledWrapper = styled(Row)`
  align-items: center;
  background: #fff;
  border: 1px solid #e8f0fd;
  border-radius: 5px;
  padding: 2%;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${cssVariables.blue2};
  }
`;

export const StyledContentContainer = styled(Col)`
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // flex: 1 1 auto;
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInfoName = styled.span`
  font-weight: ${cssVariables.font.normal};
  font-size: 18px;
  line-height: 24px;
  color: #000;
`;

export const StyledInfoQuestions = styled.span`
  font-size: 14px;
  color: #66696f;
`;

export const StyledLastModified = styled.div`
  font-size: 14px;
  color: #66696f;
  margin-right: 27px;
`;

export const StyledMore = styled(MoreOutlined)`
  transform: rotate(-90deg);
  width: 36px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c8f93;
  &:hover {
    background: #f5f5f5;
    border-radius: 3px;
  }
`;

export const StyledTestStatusGraph = styled.div`
  display: flex;
  width: ${props => (props?.isMobileView ? '55vw' : '273px')};
  height: 8px;
  margin-bottom: 8px;
`;

export const StyledTestStatusCount = styled.div`
  font-family: ${cssVariables.font.fontFamily};
  font-size: 14px;
  color: #66696f;
`;

export const StyledTestStatusPassed = styled.div`
  width: ${props => {
    return (
      (100 * props.theme.testStatus?.success) /
      (props.theme.disableOutdatedAndUntested
        ? props.theme.testStatus?.success + props.theme.testStatus?.fail
        : props.theme.testCount)
    );
  }}%;
  background: #23b576;
`;

export const StyledTestStatusFailed = styled.div`
  width: ${props => {
    return (
      (100 * props.theme.testStatus?.fail) /
      (props.theme.disableOutdatedAndUntested
        ? props.theme.testStatus?.success + props.theme.testStatus?.fail
        : props.theme.testCount)
    );
  }}%;
  background: #eb6841;
`;

//  COMMENTED FOR FUTURE USE OF OUTDATED AND UNTESTED
// export const StyledTestStatusOutdated = styled.div`
//   width: ${props => {
//     return !props.theme.disableOutdatedAndUntested
//       ? 0
//       : `${(100 * props.theme.testStatus?.outdated) / props.theme.testCount}`;
//   }}%;
//   background: ${cssVariables.warning};
// `;

// export const StyledTestStatusUntested = styled.div`
//   width: ${props => {
//     return !props.theme.disableOutdatedAndUntested
//       ? 0
//       : `${(100 * props.theme.testStatus?.unknown) / props.theme.testCount}`;
//   }}%;
//   background: #d9dadb;
// `;
