import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../GenericModal';

const ConfirmDelete = props => {
  const { itemTobeDeleted, loading, modalTitle, show, ...rest } = props;
  return (
    <Modal
      cancelButtonProps={{
        disabled: loading,
      }}
      okButtonProps={{
        danger: true,
      }}
      cancelText="Cancel"
      confirmLoading={loading}
      destroyOnClose
      okText="Confirm Delete"
      title={modalTitle ? modalTitle : 'Confirm Delete'}
      visible={show}
      onOk={rest.onOk}
      onCancel={rest.onCancel}
      {...rest}
    >
      <div>{`Are you sure you want to delete ${itemTobeDeleted}?`}</div>
    </Modal>
  );
};

ConfirmDelete.propTypes = {
  itemTobeDeleted: PropTypes.string,
  loading: PropTypes.bool,
  modalTitle: PropTypes.string,
  show: PropTypes.bool.isRequired,
};

export default ConfirmDelete;
