import styled from 'styled-components';
import { Dropdown, Row, Tabs } from 'antd';
import { SearchOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { cssVariables } from 'styles/root';
import Input from 'components/Input';

const { TabPane } = Tabs;

export const StyledAnswerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  align-items: center;

  div.left {
    display: flex;
    column-gap: 18px;
    width: 100%;
    align-items: center;
  }

  .ant-checkbox-wrapper {
    margin-left: 22px;
  }

  .ghost {
    font-size: 1vw;
    white-space: nowrap;
    border: 1px solid ${cssVariables.gray2} !important;
    background-color: transparent !important;
    color: ${cssVariables.primaryColor} !important;
  }

  .bulk-action-buttons {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
  }
`;

export const StyledAnswerInput = styled(Row)`
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding: 0px 1%;
  // padding-right: 0;
  width: 100%;

  textarea {
    min-height: 40px !important;
  }
  & *,
  *:hover,
  *:focus {
    box-shadow: none;
  }
  & textarea,
  textarea:hover,
  textarea:focus {
  }
  textarea:hover,
  textarea:focus {
  }
  .answerbank__input__max-reached-notice {
    padding: 10px;
  }

  & > span {
    flex-direction: column;
    width: 100%;
  }

  & .ant-upload-list {
    margin-right: auto;
  }

  & .ant-upload-text-icon {
    width: 20px;
  }

  & .ant-upload-list-text .ant-upload-list-item-name {
    flex: none;
  }

  & .ant-upload-list-item-card-actions-btn {
    background-color: transparent;
  }
`;

export const StyledSearchInput = styled(Input)`
  height: 40px;
  border-bottom: 1px solid ${cssVariables.gray2};
  margin: 10px 0;
  min-width: 24vw;
  :hover {
    border-bottom: 2px solid ${cssVariables.blue1};
  }

  .ant-input {
    width: 100%;
  }
`;

export const StyledRoot = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  & .ant-input-clear-icon {
    margin-right: 12px;
  }
`;

export const StyledAnswersContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;

  .ant-menu-submenu-arrow {
    display: none;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    font-size: 18px;
    color: ${cssVariables.primaryTransparent};
  }
`;

export const StyledGuidelines = styled.div`
  background: #feffc2;
  border: 1px solid #feffda;
  border-radius: 5px;
  margin-top: 26px;
  padding: 16px 24px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 24px;
  color: #595e68;
`;

export const StyledGuidelineHeader = styled.div`
  margin-bottom: 20px;
`;

export const StyledFirstLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    font-size: 18px;
    color: ${cssVariables.primaryTransparent};
  }
`;

export const StyledGuidelineList = styled.ol`
  margin-left: calc(-40px + 1em);
`;

export const StyledAddAnswer = styled.div`
  margin: 32px 0 28px;
`;

export const StyledAnswerAction = styled(Row)`
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin-top: 14px;
  // column-gap: 6px;

  & > span {
    flex-direction: column;
    width: 100%;
  }

  & .ant-upload-list {
    margin-right: auto;
  }

  & .ant-upload-text-icon {
    width: 20px;
  }

  & .ant-upload-list-text .ant-upload-list-item-name {
    flex: none;
  }

  & .ant-upload-list-item-card-actions-btn {
    background-color: transparent;
  }
`;

export const StyledAnswerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button.btn.primary {
    width: 120px;
    text-transform: capitalize;

    & span.anticon,
    .MuiButton-label {
      font-size: 12px;
    }
  }
`;

export const StyledDropdown = styled(Dropdown)`
  text-transform: capitalize;
`;

export const StyledAnswerTabsContainer = styled.div`
  display: block;

  & .ant-tabs-nav {
    margin: 0;

    & .ant-tabs-nav-list {
      width: 200px;
    }
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    padding: 2% 3%;
  }

  .answer-types-tab-pane {
    background-color: transparent;
  }

  .answer-types-tab > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100px;
  }

  .answer-types-tab > .ant-tabs-nav .ant-tabs-tab:hover {
    color: #1890ff;
    background-color: #e6f7ff;
  }

  .answer-types-tab > .ant-tabs-nav {
    width: 100px;
    padding: 2% 0 !important;
    margin: 0 !important;
  }

  .answer-types-tab > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 0;
    transition: color 0.3s ease, background-color 0.3s ease;
  }

  .answer-types-tab > .ant-tabs-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
    flex: 1;
  }

  .answer-types-tab > .ant-tabs-tabpane {
    padding: 0 !important;
  }

  .answer-types-tab > .ant-tabs-nav .ant-tabs-tab-active {
    position: relative;
  }

  .answer-types-tab > .ant-tabs-tab {
    position: relative;
  }
`;

export const StyledAnswerTabs = styled(Tabs)`
  & .ant-tabs-tab {
    color: #66696f;
    background: #f4f6f8 !important;
    margin-left: 0px !important;
  }

  & .ant-tabs-tab.ant-tabs-tab-active {
    background: #fff !important;
  }

  & .ant-tabs-tab:hover {
    color: #26292d;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #26292d;
  }

  & .ant-tabs-extra-content {
    width: 40%;
  }

  & .ant-menu-item {
    padding: 0 !important;
  }
`;

export const StyledAnswerTabsPane = styled(TabPane)`
  background: #fff;
  padding: 3% 2%;
`;

export const StyledSearchOutlined = styled(SearchOutlined)`
  color: ${cssVariables.primaryBlue};
  font-weight: ${cssVariables.font.bold};
  font-size: 18px;
`;

export const StyledSortDescendingOutlined = styled(SortDescendingOutlined)`
  margin-right: 10px;
`;

export const StyledFirstBotNoAnswers = styled.div`
  height: 100%;
  background: #ffffff;
  color: #6d757d;
  padding: 16px;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  & h3 {
    font-size: 26px;
    font-weight: ${cssVariables.font.bold};
  }
`;

export const StyledCustomTooltip = styled.div`
  width: 364px;
  display: block;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 6px;

  & button {
    cursor: pointer;
    width: 62px;
    height: 30px;
    color: #ffffff;
    background-color: #1f6be4;
    border: 0;
    border-radius: 3px;
    font-size: 10px;
    font-weight: ${cssVariables.font.normal};
    letter-spacing: 2px;
    margin-right: 4px;
  }
`;

export const StyledToolTipContainer = styled.div`
  display: block;
  padding: 10px;

  & h3 {
    font-weight: ${cssVariables.font.bold};
  }
`;

export const StyledFooterActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDropdownButtonMenu = styled(Dropdown.Button)`
  border-radius: 5px;
  width: auto !important;
  button {
    height: 40px;
    font-size: 16px;
  }

  button:first-child {
    width: 100%;
    ${props =>
      props.isMainButtonDisabled
        ? `cursor: not-allowed;
      `
        : ``};
  }
`;

export const StyledUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-upload {
    width: 100%;
  }

  .ant-upload-list-item {
    width: 50%;
  }
`;

export const StyledAnswerbankTabNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledAnswerbankTabNavLabel = styled.span`
  font-size: 14px;
  white-space: nowrap;
`;
export const StyledAnswerbankTabNavCount = styled.span`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(200, 200, 200, 0.5);
  font-size: 12px;
  color: gray;
  position: absolute;
  right: 1px;
`;
