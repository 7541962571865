import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { default as PageTitle } from 'components/Title';
import EmptyAnswer from 'components/EmptyAnswer';
import {
  DataSummary,
  OverviewContent,
  TitleContainer,
  WeakbotWarning,
  WeakbotWarningClickable,
} from './Overview.style';
import ROUTES from 'constants/routes';
import { Context } from 'store/store';
import { isFreeUserSelector } from 'selectors/plan';
import {
  allAnswersSelector,
  botAggregationsSelector,
} from 'selectors/bot/answers';
import { WeakStatPopover } from 'components/WeakStatPopover';
import {
  currentBotSelector,
  showWeakBotStatInOverviewSelector,
  totalArtefactsSelector,
} from 'selectors/bot';
import useSelector from 'store/useSelector';
import { botStatistics } from '../../../utils/bot';
import UpgradeTag from 'components/UpgradeTag';
import { Col, Row, Skeleton } from 'antd';
import { stripUUID } from 'utils';

const BotOverview = ({ loading }) => {
  const [state] = useContext(Context);
  const isFreeUser = useSelector(isFreeUserSelector);
  const allAnswers = useSelector(allAnswersSelector);
  const aggregations = useSelector(botAggregationsSelector);
  const showWeakBotStatInOverview = useSelector(
    showWeakBotStatInOverviewSelector
  );
  const bot = useSelector(currentBotSelector);
  const totalArtefacts = useSelector(totalArtefactsSelector);
  const strippedBotID = stripUUID(bot?.jid);
  const history = useHistory();
  const { user } = state;
  const { name, totalIntegrations, totalTestSuites, hasBotError } = bot;

  const SUMMARIZED_LIST = [
    {
      key: 1,
      title: 'Total Artifacts',
      data: allAnswers.length || totalArtefacts,
      link: 'View Artifacts',
      path: `${ROUTES.BOT_DETAILS}/${strippedBotID}${ROUTES.ANSWERBANK}`,
      isFree: true,
    },
    {
      key: 2,
      title: 'Questions Asked (Last 7 days)',
      data: aggregations?.totalQuestions,
      link: 'View Questions',
      path: `${ROUTES.BOT_DETAILS}/${strippedBotID}${ROUTES.MOST_ASKED_QUESTIONS}`,
      isFree: false,
    },
    {
      key: 3,
      title: 'Fallback answer called (Last 7 days)',
      data: aggregations?.answerCountEachType?.defaultAnswerCount,
      link: 'View Interactions',
      path: `${ROUTES.BOT_DETAILS}/${strippedBotID}${ROUTES.ANALYTICS_QUESTION_LOGS}`,
      isFree: false,
    },
    {
      key: 4,
      title: 'Total Live Integrations',
      data: totalIntegrations,
      link: 'View Integrations',
      path: `${ROUTES.BOT_DETAILS}/${strippedBotID}${ROUTES.BOT_INTEGRATION}`,
      isFree: false,
    },
    {
      key: 5,
      title: 'Total Test Suites',
      data: totalTestSuites,
      link: 'View Test Suites',
      path: `${ROUTES.BOT_DETAILS}/${strippedBotID}${ROUTES.VALIDATION}`,
      isFree: true,
    },
  ];

  const botStat = useMemo(() => {
    const { redirect, totalQuestions } = botStatistics(bot);
    return {
      redirect,
      totalQuestions,
    };
  }, [bot]);

  return hasBotError ? (
    <EmptyAnswer loading={loading} />
  ) : (
    <>
      <TitleContainer>
        <PageTitle
          text={`Hello ${user.firstName}!`}
          type="secondary"
          subHeader={`Here's an overview of ${name}`}
        />
        {showWeakBotStatInOverview && (
          <WeakbotWarning isHeader>
            {`There are `}
            <WeakStatPopover
              location="overview"
              dataStatName="bot"
              weakData={bot}
            >
              <WeakbotWarningClickable
                onClick={() => history.push(botStat.redirect)}
              >
                {botStat.totalQuestions} questions
              </WeakbotWarningClickable>
            </WeakStatPopover>
            {` which have issues in the last 7 days.`}
          </WeakbotWarning>
        )}
      </TitleContainer>
      <OverviewContent>
        <Row gutter={[16, 16]}>
          {SUMMARIZED_LIST.map(card => {
            return (
              <Col
                xs={{ span: 22 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
              >
                <DataSummary
                  key={card.key}
                  disabled={isFreeUser && !card.isFree}
                  className={card.className}
                >
                  <span>{card.title}</span>
                  {card.data !== undefined && (
                    <>
                      {isFreeUser && !card.isFree ? (
                        <div>
                          <span>{card.data}</span>
                          <UpgradeTag />
                        </div>
                      ) : (
                        <span>{card.data}</span>
                      )}
                      <Link to={isFreeUser && !card.isFree ? '#' : card.path}>
                        {card.link}
                      </Link>
                    </>
                  )}
                  {card.data === undefined && (
                    <Skeleton paragraph={{ rows: 2 }} />
                  )}
                </DataSummary>
              </Col>
            );
          })}
        </Row>
        <Row gutter={[16, 16]} style={{ paddingTop: '2%' }}>
          <Col
            xs={{ span: 22 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            <DataSummary disabled={isFreeUser}>
              <span>{'Visitors (Last 7 days)'}</span>
              {aggregations?.visitorCount !== undefined && (
                <>
                  {isFreeUser ? (
                    <div>
                      <span>{aggregations?.visitorCount || 0}</span>
                      <UpgradeTag />
                    </div>
                  ) : (
                    <span>{aggregations?.visitorCount || 0}</span>
                  )}

                  <Link
                    to={`${ROUTES.BOT_DETAILS}/${strippedBotID}${ROUTES.ANALYTICS_QUESTION_LOGS}`}
                  >
                    {'View Report'}
                  </Link>
                </>
              )}
              {aggregations?.visitorCount === undefined && (
                <Skeleton paragraph={{ rows: 2 }} />
              )}
            </DataSummary>
          </Col>
        </Row>
      </OverviewContent>
    </>
  );
};

BotOverview.propTypes = {
  loading: PropTypes.bool,
};

export default BotOverview;
