import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Steps, Spin } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import CreateTestsuiteModal from '../CreateTestsuiteModal';
import useAddQuestionToTestSuiteModal from './hooks';
import {
  StyledStepAction,
  StyledStepContent,
  StyledTestSuite,
  StyledTestSuites,
  StyledTestSuitesList,
  StyledNoTestSuites,
  StyledLabel,
  StyledQuestion,
  StyledAnswerList,
} from './AddQuestionToTestSuiteModal.styles';
import AnswerWithScore from 'components/AnswerWithScore';
import Modal from 'components/Modals/GenericModal';
import Button from 'components/Button';
import LimitationTooltip from 'components/ToolTips/LimitationToolTip';
import ToggleShowDisplayAnswer from 'components/Button/ToggleShowDisplayAnswer';

const { Step } = Steps;

const steps = ['Select Test Suite', 'Select Answer'];

const AddQuestionToTestSuiteModal = ({ show, question, onCloseModal }) => {
  const {
    currentStep,
    fetchResponses,
    handleAddQuestionToTestSuite,
    handleCreateNewTestSuite,
    handleSelectResponse,
    handleSelectTestSuite,
    loading,
    maxTestCases,
    maxTestSuites,
    onNext,
    onPrev,
    planType,
    responses,
    selectedTestSuite,
    selectedResponse,
    setShowDisplayAnswer,
    showDisplayAnswer,
    showNewTestSuiteModal,
    testSuites,
    totalAnswers,
    toggleShowNewTestSuiteModal,
  } = useAddQuestionToTestSuiteModal({ question, onCloseModal });

  const renderTestSuites = () => {
    return (
      <StyledTestSuites>
        {testSuites?.length ? (
          <StyledTestSuitesList>
            {testSuites.map(testSuite =>
              maxTestCases <= testSuite.testCount ? (
                <LimitationTooltip
                  title={
                    planType === 'advanced'
                      ? `Contact Us for support`
                      : `Upgrade to Add more Test Cases`
                  }
                >
                  <StyledTestSuite key={testSuite.jid} disabled={true}>
                    <div className="test-suite-name">{testSuite.name}</div>
                    <div className="test-suite-count">
                      {testSuite.testCount} Questions
                    </div>
                    {selectedTestSuite &&
                      testSuite.jid === selectedTestSuite.jid && (
                        <CheckCircleFilled />
                      )}
                  </StyledTestSuite>
                </LimitationTooltip>
              ) : (
                <StyledTestSuite
                  onClick={() => handleSelectTestSuite(testSuite)}
                  key={testSuite.jid}
                >
                  <div className="test-suite-name">{testSuite.name}</div>
                  <div className="test-suite-count">
                    {testSuite.testCount} Questions
                  </div>
                  {selectedTestSuite &&
                    testSuite.jid === selectedTestSuite.jid && (
                      <CheckCircleFilled />
                    )}
                </StyledTestSuite>
              )
            )}
          </StyledTestSuitesList>
        ) : (
          <StyledNoTestSuites>
            You don't have any TestSuites. <br />
            Create a new one.
          </StyledNoTestSuites>
        )}
        {maxTestSuites <= testSuites?.length ? (
          <LimitationTooltip
            title={
              planType === 'advanced'
                ? `Contact Us for support`
                : `Upgrade to add more Test Suite`
            }
            disabledFeat="Add New Test Suite"
          />
        ) : (
          <Button
            onClick={toggleShowNewTestSuiteModal}
            value="Add New Test Suite"
            disabled={maxTestSuites <= testSuites?.length}
          />
        )}

        <CreateTestsuiteModal
          showCreateSuiteModal={showNewTestSuiteModal}
          handleCreateNewSuite={handleCreateNewTestSuite}
          handleCloseCreateSuiteModal={toggleShowNewTestSuiteModal}
        />
      </StyledTestSuites>
    );
  };

  const renderAnswers = () => {
    return (
      <React.Fragment>
        <StyledLabel>Test</StyledLabel>
        <StyledQuestion>{question}</StyledQuestion>
        {responses?.length ? (
          <React.Fragment>
            <StyledLabel>
              {`Select Expected Answer`}
              <ToggleShowDisplayAnswer
                showDisplayAnswer={showDisplayAnswer}
                onClick={() => setShowDisplayAnswer(!showDisplayAnswer)}
              />
            </StyledLabel>
            <StyledAnswerList>
              {responses?.map(
                answer =>
                  answer && (
                    <AnswerWithScore
                      className={classNames({
                        selected:
                          selectedResponse &&
                          selectedResponse.jid === answer.jid,
                        defaultAnswer: answer.type.includes('default'),
                      })}
                      answer={answer}
                      key={answer.jid}
                      handleSelect={() => handleSelectResponse(answer)}
                      qlinks={answer.qlinks}
                      showDisplayAnswer={showDisplayAnswer}
                    />
                  )
              )}
            </StyledAnswerList>
            {responses?.length >= totalAnswers && (
              <Button
                full
                variant="link"
                onClick={fetchResponses}
                value="Show more answers"
              />
            )}
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <Modal
      visible={show}
      title="Add question to Test Suite"
      onCancel={onCloseModal}
      footer={null}
    >
      <Spin spinning={loading}>
        <Steps current={currentStep}>
          {steps.map(step => (
            <Step key={step} title={step} />
          ))}
        </Steps>
        <StyledStepContent>
          {currentStep === 0 ? renderTestSuites() : renderAnswers()}
        </StyledStepContent>
        <StyledStepAction>
          {currentStep < steps.length - 1 && (
            <Button
              onClick={onNext}
              disabled={!selectedTestSuite}
              value="Next"
            />
          )}
          {currentStep > 0 && (
            <Button
              style={{ margin: '0 8px' }}
              onClick={onPrev}
              value="Previous"
            />
          )}
          {currentStep === steps.length - 1 && (
            <Button
              onClick={handleAddQuestionToTestSuite}
              disabled={!selectedResponse}
              value="Add"
            />
          )}
        </StyledStepAction>
      </Spin>
    </Modal>
  );
};

AddQuestionToTestSuiteModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default AddQuestionToTestSuiteModal;
