import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DeleteFilled, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import {
  StyledFieldRows,
  StyledFormRow,
  StyledNameInput,
  StyledValueInput,
} from './StyledComponents';
import { cssVariables } from 'styles/root';
import {
  StyledFlexLeftColumn,
  StyledFlexRowLeft,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';

import Button from 'components/Button';
import Select from 'components/Select';
import { DATA_TYPES, NEW_OBJECT_ITEM } from 'constants/aiTools';
import Input from 'components/Input';
import HRWithCenteredText from 'components/HR/HRWithCenteredText';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const Parameters = props => {
  const { paramProps, setParamsProps, ...rest } = props;

  const handleChangeParamName = (e, prop) => {
    const modifiedProp = paramProps.map(item => {
      if (prop.position === item.position) {
        return {
          ...item,
          name: e.target.value,
        };
      }

      return item;
    });
    setParamsProps(modifiedProp);
  };

  const handleChangeParamDescription = (e, prop) => {
    const modifiedProp = paramProps.map((item, idx) => {
      if (prop.position === item.position) {
        return {
          ...item,
          description: e.target.value,
        };
      }

      return item;
    });
    setParamsProps(modifiedProp);
  };

  const onSelectParamPropType = (e, prop) => {
    const modifiedProp = paramProps.map((item, idx) => {
      if (prop.position === item.position) {
        if (e === 'object') {
          return {
            ...item,
            type: e,
            properties: !isEmpty(item.properties)
              ? item.properties
              : NEW_OBJECT_ITEM,
          };
        } else {
          return {
            ...item,
            type: e,
            properties: undefined,
          };
        }
      }
      return item;
    });
    setParamsProps(modifiedProp);
  };

  const deleteProps = position => {
    const filteredParams = paramProps.filter(
      prop => prop.position !== position
    );
    setParamsProps(filteredParams);
  };

  const handleChangeObjectFieldChange = (e, prop, field, position) => {
    const modifiedProp = paramProps.map(item => {
      if (prop.position === item.position) {
        return {
          ...item,
          properties: item.properties?.map((f, fieldIdx) => {
            if (fieldIdx === position) {
              return {
                ...f,
                [field]: field === 'type' ? e : e.target.value,
              };
            }
            return f;
          }),
        };
      }

      return item;
    });
    setParamsProps(modifiedProp);
  };

  const handleAddObjectField = prop => {
    const modifiedProp = paramProps.map(item => {
      if (prop.position === item.position && prop.type === 'object') {
        return {
          ...item,
          properties: [
            ...item.properties,
            { position: item.properties.length + 1, name: '' },
          ],
        };
      }

      return item;
    });
    setParamsProps(modifiedProp);
  };

  const handleDeleteObjectField = (prop, position) => {
    const modifiedProp = paramProps.map(item => {
      if (prop.position === item.position && prop.type === 'object') {
        return {
          ...item,
          properties: item.properties.filter(f => f.position !== position),
        };
      }

      return item;
    });
    setParamsProps(modifiedProp);
  };

  return (
    <Collapse
      defaultActiveKey={['0']}
      style={{ width: '100%', marginTop: '10px' }}
      expandIconPosition="end"
    >
      {paramProps.map((prop, idx) => {
        return (
          <Panel
            header={<label>{prop.name || `property ${idx + 1}`}</label>}
            extra={
              idx ? (
                <Button
                  size="small"
                  variant="error"
                  value={`Delete Parameter`}
                  startIcon={<MinusOutlined />}
                  onClick={() => deleteProps(prop.position, idx)}
                />
              ) : (
                <Button
                  value="Add parameter property"
                  startIcon={<PlusOutlined />}
                  variant="link"
                  size="small"
                  onClick={props.addProps}
                />
              )
            }
            key={idx}
          >
            <StyledFormRow key={`param-props-${idx}`}>
              <StyledFlexRowLeft>
                <StyledFormRow>
                  <Select
                    size="medium"
                    placeholder={`Select data type for ${
                      prop.name || `property ${idx + 1}`
                    }`}
                    label="Type:"
                    options={DATA_TYPES}
                    onChange={e => onSelectParamPropType(e, prop)}
                    defaultValue={prop.type}
                  />
                  {prop.type === 'object' ? (
                    <Input
                      size="small"
                      placeholder={`Enter name for ${
                        prop.name || `property ${idx + 1}`
                      }...`}
                      label="Name:"
                      onChange={e => handleChangeParamName(e, prop)}
                    />
                  ) : null}
                  {prop.type !== 'object' ? (
                    <StyledFieldRows>
                      <StyledNameInput
                        size="small"
                        placeholder={`Enter name for ${
                          prop.name || `property ${idx + 1}`
                        }...`}
                        label="Name:"
                        defaultValue={prop.name}
                        onChange={e => handleChangeParamName(e, prop)}
                      />
                      <StyledValueInput
                        placeholder={`Enter description for ${
                          prop.name || `property ${idx + 1}`
                        }...`}
                        label="Description"
                        size="small"
                        defaultValue={prop.description}
                        onChange={e => handleChangeParamDescription(e, prop)}
                      />
                    </StyledFieldRows>
                  ) : null}
                  {prop.type === 'object' ? (
                    <StyledFormRow>
                      <span>{`${
                        prop.name || `property ${idx + 1}`
                      } Properties:`}</span>
                      {(prop.properties || []).map((f, fieldIndex) => {
                        return (
                          <StyledFlexLeftColumn>
                            <HRWithCenteredText
                              text={
                                (prop.name || `property ${idx + 1}`) +
                                ` ${f.name || ` Field ${fieldIndex + 1}`}`
                              }
                            />
                            {prop.type === 'object' ? (
                              <Select
                                size="medium"
                                placeholder={`Select data type for ${
                                  prop.name || `property ${idx + 1}`
                                }`}
                                label="Type:"
                                options={DATA_TYPES.filter(
                                  i => i.value !== 'object'
                                )}
                                onChange={e =>
                                  handleChangeObjectFieldChange(
                                    e,
                                    prop,
                                    'type',
                                    fieldIndex
                                  )
                                }
                              />
                            ) : null}
                            <StyledFieldRows>
                              <StyledNameInput
                                size="small"
                                placeholder={`Enter name for ${
                                  f.name || `field ${idx + 1}`
                                }...`}
                                label="Name"
                                onChange={e =>
                                  handleChangeObjectFieldChange(
                                    e,
                                    prop,
                                    'name',
                                    fieldIndex
                                  )
                                }
                              />
                              <StyledValueInput
                                size="small"
                                placeholder={`Enter description for field ${
                                  f.name || `field ${idx + 1}`
                                }...`}
                                label="Description"
                                onChange={e =>
                                  handleChangeObjectFieldChange(
                                    e,
                                    prop,
                                    'description',
                                    fieldIndex
                                  )
                                }
                              />
                              {fieldIndex ? (
                                <DeleteFilled
                                  onClick={() =>
                                    handleDeleteObjectField(prop, f.position)
                                  }
                                  disabled={!fieldIndex}
                                />
                              ) : (
                                <DeleteFilled
                                  style={{ color: cssVariables.gray1 }}
                                  disabled={!fieldIndex}
                                />
                              )}
                            </StyledFieldRows>
                          </StyledFlexLeftColumn>
                        );
                      })}
                      {prop.type === 'object' ? (
                        <StyledFlexRowLeft>
                          <Button
                            variant="link"
                            size="small"
                            onClick={e => handleAddObjectField(prop)}
                            value="Add field"
                          />
                        </StyledFlexRowLeft>
                      ) : null}
                    </StyledFormRow>
                  ) : null}
                </StyledFormRow>
              </StyledFlexRowLeft>
            </StyledFormRow>
          </Panel>
        );
      })}
    </Collapse>
  );
};

Parameters.propTypes = {
  paramProps: PropTypes.array.isRequired,
  setParamsProps: PropTypes.func.isRequired,
  objectifiedParamProps: PropTypes.func.isRequired,
  addProps: PropTypes.func.isRequired,
};

export default Parameters;
