import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledAuth = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100vh;

  a {
    color: ${cssVariables.primaryCyan} !important;
    font-weight: ${cssVariables.font.bold};
    font-size: 14px;
  }
`;

export const StyledSectionWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 900px) {
    width: 80%;
  }
`;

export const StyledFormContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 50px;
  margin: auto;
  min-width: 300px;
  max-width: 600px;
  padding: 50px;
  padding-top: 0px;
  height: 100%;
  justify-content: center;
`;

export const StyledLogo = styled.img`
  max-width: 300px;
  margin-bottom: 30px;
  display: flex;
`;

export const StyledBannerWrapper = styled.div`
  width: 80%;
  background: ${cssVariables.zsbCyanGradient};
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  height: 100%;

  > div:has(span) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-self: center;
    height: 50%;
  }

  div > span {
    font-size: 13px;
    line-height: 17px;
  }

  span,
  h1 {
    color: #fff;
  }

  h1 {
    align-self: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const StyledImageBanner = styled.img`
  width: 24%;
  height: 45%;
  align-self: end;
  position: absolute;
  bottom: 0;
  max-width: 400px;
`;

export const StyledForm = styled.form`
  width: 100%;
  padding: 0 10px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const StyledSwitchLinkContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
