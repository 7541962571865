import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import DeadBotLogo from 'assets/images/zsb-dead-logo.png';
import { StyledLogo } from 'pages/Auth/SignIn/SignIn.style';
import Button from 'components/Button';
import { cssVariables } from 'styles/root';
import ROUTES from 'constants/routes';
import { Context } from 'store/store';
import { LOGOUT_EXTERNAL_USER, LOGOUT_USER } from 'store/action';
import { isExternalPageSelector } from 'selectors/user';
import useSelector from 'store/useSelector';

const StyledInfo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: ${cssVariables.blue1};
  margin: 20px 0;
  font-size: 1.2rem;
  color: ${cssVariables.primaryGray};
  padding: 1%;
`;

const StyledLogout = styled.div`
  padding-top: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  button {
    margin-top: 24px;
  }
`;

const Logout = () => {
  const history = useHistory();
  const [, dispatch] = useContext(Context);
  const isExternalPage = useSelector(isExternalPageSelector);

  useEffect(() => {
    dispatch({
      type: isExternalPage ? LOGOUT_EXTERNAL_USER : LOGOUT_USER,
      payload: null,
    });
  }, []);

  return (
    <StyledLogout>
      <StyledLogo className="logo" src={DeadBotLogo} alt="Logo" />
      <StyledInfo>{'You have logged out.'}</StyledInfo>
      <Button
        value={'Go to Sign in Page'}
        onClick={() =>
          history.push(isExternalPage ? ROUTES.EXTERNAL_LOGIN : ROUTES.LOGIN)
        }
      />
    </StyledLogout>
  );
};

export default Logout;
