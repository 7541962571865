import React, { useContext, useState } from 'react';
import { Col, Row, Switch } from 'antd';
import styled from 'styled-components';

import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';
import {
  StyledInteractionInput,
  StyledLabel,
} from '../../../CustomizeBot.style';
import useSelector from 'store/useSelector';
import {
  interactionHistorySelector,
  maxInteractionSelector,
} from 'selectors/bot/integration';
import { Context } from 'store/store';
import { TOGGLE_INTERACTION_HISTORY } from 'store/action';
import { isBotOpenAIEnabledSelector } from 'selectors/bot';

const StyledWrapper = styled.div`
  > * {
    margin: 10px 0;
    width: 100%;
  }
`;

const ContextHistory = props => {
  const [, dispatch] = useContext(Context);

  const interactionHistory = useSelector(interactionHistorySelector);
  const maxInteraction = useSelector(maxInteractionSelector);
  const isBotOpenAIEnabled = useSelector(isBotOpenAIEnabledSelector);

  const [tempMaxInteraction, setTempMaxInteraction] = useState(maxInteraction);

  const handleToggleInteractionHistory = val => {
    dispatch({ type: TOGGLE_INTERACTION_HISTORY, payload: val });
  };

  const handleChangeMaxInteractionInput = evt => {
    setTempMaxInteraction(evt.target.value);
  };

  const handleBlurMaxInteractionInput = () => {
    dispatch({ type: TOGGLE_INTERACTION_HISTORY, payload: tempMaxInteraction });
  };

  return isBotOpenAIEnabled ? (
    <StyledWrapper>
      <Row>
        <Col span={12}>
          <b>{'Enable Context History'} </b>
        </Col>
        <Col span={12}>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={interactionHistory}
            onChange={handleToggleInteractionHistory}
          />
        </Col>
      </Row>
      <StyledFlexLeftColumn>
        <StyledLabel style={{ paddingTop: 5 }}>
          {'No. of Interactions'}
        </StyledLabel>
        <StyledInteractionInput
          min={2}
          max={5}
          defaultValue={maxInteraction}
          value={tempMaxInteraction}
          onChange={handleChangeMaxInteractionInput}
          onBlur={handleBlurMaxInteractionInput}
          disabled={!interactionHistory}
          step={1}
        />
      </StyledFlexLeftColumn>
    </StyledWrapper>
  ) : null;
};

ContextHistory.propTypes = {};

export default ContextHistory;
