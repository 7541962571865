import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Popover } from 'antd';

import Input from 'components/Input';
import {
  StyledActionBtns,
  StyledIntegrationSteps,
  StyledTempIconImage,
} from './CustomizeBot.style';
import ColorPicker from './ColorPicker';
import useSelector from 'store/useSelector';
import {
  widgetHeightSelector,
  widgetPlaceholderSelector,
  widgetThemeColorSelector,
} from 'selectors/bot/integration';
import { MAX_WIDGET_HEIGHT, MIN_WIDGET_HEIGHT } from './constants';
import { Context } from 'store/store';
import {
  OPEN_IMAGE_VIEWER_MODAL,
  RESET_SEARCH_CUSTOMIZER_DATA,
  SET_SHOW_WIDGET_AVATAR_PICKER_MODAL,
  SET_WIDGET_HEIGHT,
  SET_WIDGET_PLACEHOLDER,
  SET_WIDGET_THEME_COLOR,
} from 'store/action';
import LauncherIconPickerModal from '../LauncherIconPickerModal';
import { isAvatarPickerModalOpenSelector } from 'selectors/bot/ui';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
import Button from 'components/Button';
import { generateIcon } from '../iconUtils';
import {
  isHeaderAvatarSvgIconSelector,
  widgetHeaderAvatarSelector,
} from 'selectors/bot/integration/settings';

const SearchCustomizer = ({ onUpdate }) => {
  const [, dispatch] = useContext(Context);
  const themeColor = useSelector(widgetThemeColorSelector);
  const height = useSelector(widgetHeightSelector);
  const placeholder = useSelector(widgetPlaceholderSelector);
  const avatar = useSelector(widgetHeaderAvatarSelector);
  const isAvatarPickerModalOpen = useSelector(isAvatarPickerModalOpenSelector);
  const isIconTypeAvatar = useSelector(isHeaderAvatarSvgIconSelector);

  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleChangeColor = color => {
    dispatch({
      type: SET_WIDGET_THEME_COLOR,
      payload: color.hex,
    });
  };

  const handleChangeHeight = evt => {
    dispatch({
      type: SET_WIDGET_HEIGHT,
      payload: evt.target.value,
    });
  };

  const handleChangePlaceholder = evt => {
    dispatch({
      type: SET_WIDGET_PLACEHOLDER,
      payload: evt.target.value,
    });
  };

  const handleChangeAvatar = () => {
    dispatch({ type: SET_SHOW_WIDGET_AVATAR_PICKER_MODAL });
  };

  const handleClear = () => {
    dispatch({ type: RESET_SEARCH_CUSTOMIZER_DATA });
  };

  const handlePreviewAvatar = () => {
    dispatch({
      type: OPEN_IMAGE_VIEWER_MODAL,
      payload: { source: avatar, title: 'Search Widget Avatar Preview' },
    });
  };

  return (
    <StyledIntegrationSteps>
      <h2>{'Personalise you search widget'}</h2>
      <form onSubmit={onUpdate}>
        <Input
          label="Placeholder"
          full
          value={placeholder}
          onChange={handleChangePlaceholder}
        />
        <Input
          label="Max-height"
          value={height}
          type="number"
          min={MIN_WIDGET_HEIGHT}
          max={MAX_WIDGET_HEIGHT}
          placeholder={`Enter search widget max-height (${MIN_WIDGET_HEIGHT}-${MAX_WIDGET_HEIGHT})`}
          onChange={handleChangeHeight}
        />
        <Popover
          content={
            <ColorPicker
              color={themeColor}
              onChangeColor={handleChangeColor}
              setShowColorPicker={setShowColorPicker}
            />
          }
          trigger="click"
          open={showColorPicker}
          onOpenChange={() => setShowColorPicker(!showColorPicker)}
          overlayClassName={{
            '& .ant-popover-inner': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <Input
            label="Theme Color"
            value={themeColor}
            onChange={handleChangeColor}
            placeholder={`Pick your bot color (e.g. #D3D3D3)`}
          />
        </Popover>
        <StyledFlexRowLeft>
          {isIconTypeAvatar ? (
            <Avatar
              src={generateIcon(avatar, themeColor, '60', '65')}
              integrationDataColor="#fff"
              size={65}
            />
          ) : (
            <StyledTempIconImage onClick={handlePreviewAvatar}>
              <img src={avatar} alt="widget icon" />
            </StyledTempIconImage>
          )}
          <Button
            style={{ width: 'fit-content' }}
            variant="link"
            bordered
            value="Change"
            onClick={handleChangeAvatar}
            size="medium"
          />
        </StyledFlexRowLeft>
        <StyledActionBtns>
          <Button
            // loading={loading}
            value={'Save'}
            type="submit"
            onClick={onUpdate}
          />
          <Button
            variant="secondary"
            value="Reset"
            type="button"
            onClick={handleClear}
          />
        </StyledActionBtns>
      </form>
      {isAvatarPickerModalOpen ? <LauncherIconPickerModal /> : null}
    </StyledIntegrationSteps>
  );
};

SearchCustomizer.propTypes = {
  onUpdate: PropTypes.func,
};

export default SearchCustomizer;
