import { has } from 'lodash';

import { isAnObject, parseBoolean } from './dataTypes';
import { ZSB_CHAT_BREAKER_ENCONDING } from 'constants/answerbank/defaults';

export const extractDisplayAnswer = answer => {
  const { show_html, show_text, text } = answer;

  if (Array.isArray(show_html) && show_html.length) {
    return (show_html || []).map(item => getAnswerStringValue(item));
  } else if (Array.isArray(show_text) && show_text.length) {
    return show_text.map(item => getAnswerStringValue(item));
  }
  // --- old Data types and non-answer object --- //
  else if (typeof show_text === 'string') {
    return show_text;
  } else if (Array.isArray(answer) && answer?.length) {
    return (answer || []).map(item => getAnswerStringValue(item));
  } else if (isAnObject(answer) && answer?.value) {
    return extractAnswerFromObjectWithValue(answer);
  } else if (typeof answer === 'string') {
    return answer;
  } else {
    return text;
  }
};

const getAnswerStringValue = answer => {
  if (isAnObject(answer)) {
    return extractAnswerFromObjectWithValue(answer);
  } else if (typeof answer === 'string') {
    return answer + '\n';
  } else if (Array.isArray(answer) && answer.length) {
    return answer.map(i => {
      if (typeof i === 'string') {
        return i + '\n';
      } else if (isAnObject(i)) {
        return extractAnswerFromObjectWithValue(i);
      }
      return i;
    });
  }
  return '';
};

const extractAnswerFromObjectWithValue = answer => {
  if (answer.type === 'image') {
    return `<img src='${answer.value}' aria-label='image from answer' />`;
  } else if (answer.type === 'text' && answer.value) {
    return answer.value + '\n';
  } else if (typeof answer === 'string') {
    return answer + '\n';
  }
  return '';
};

/**
 * return => {
      show_text: [{ type: 'text', value: '' }],
      show_html: [''],
    }
  */
export const createDisplayAnswerAPIPayload = displayAnswer => {
  // if `displayAnswer` is object we assume it has show_text and/or show_html
  if (
    isAnObject(displayAnswer) &&
    has(displayAnswer, 'show_text') &&
    !has(displayAnswer, 'text')
  ) {
    return displayAnswer;
  } else if (isAnObject(displayAnswer) && has(displayAnswer, 'text')) {
    return Object.fromEntries(
      // clean answer object to only contain show_text && show_html
      Object.entries(displayAnswer).filter(
        ([key, val]) => key === 'show_text' || key === 'show_html'
      )
    );
  }
  // if `displayAnswer` is array we assume it is an array of objects [{show_text: '', show_html: ''}]
  else if (Array.isArray(displayAnswer)) {
    const displayAnswerWithShowTextAndHTML = displayAnswer.find(
      item => item.show_text && item.show_html
    );

    const displayAnswerWithTextAndValueObject = displayAnswer.find(
      item => item.type && item.value
    );

    if (displayAnswerWithShowTextAndHTML && displayAnswer.length === 1) {
      // return the object that has show_text and show_html
      return displayAnswerWithShowTextAndHTML;
    } else if (displayAnswerWithTextAndValueObject) {
      const displayAnswerTextValue = displayAnswer.find(item => item.value);

      // create new object with show_text, show_html
      return {
        show_text: [displayAnswerWithTextAndValueObject],
        show_html: [displayAnswerTextValue?.value],
      };
    } else {
      const displayAnswerObject = {};
      displayAnswer.forEach(obj => {
        if (obj.show_text) {
          displayAnswerObject.show_text = obj.show_text;
        } else if (obj.show_html) {
          displayAnswerObject.show_html = obj.show_html;
        }
      });
      return displayAnswerObject;
    }
  }
  // else we create object containing show_text && show_html
  else if (typeof displayAnswer === 'string') {
    const showText = splitDisplayAnswer(displayAnswer);
    const showHtml = splitDisplayAnswer(displayAnswer)?.reduce((acc, arr) => {
      return [...acc, arr.value];
    }, []);

    return {
      show_text: showText,
      show_html: showHtml,
    };
  } else {
    return {
      show_text: [{ type: 'text', value: '' }],
      show_html: [''],
    };
  }
};

export const extractQuickReplyData = answerFromAPIData => {
  const { context } = answerFromAPIData;
  const { quick_reply, quick_reply_options } = context;
  if (parseBoolean(quick_reply)) {
    return {
      quick_reply,
      quick_reply_options,
      messageInputIsDisabled: false,
    };
  } else if (isAnObject(quick_reply)) {
    const { enabled, message, replies } = quick_reply;
    const quickRepliesArray = (replies || [])?.map((item, idx) => ({
      key: idx,
      label: item.display,
      question: item.reply,
      answer: item.answer,
    }));
    return {
      quick_reply: enabled,
      quick_reply_options: quickRepliesArray,
      messageInputIsDisabled: message,
    };
  } else {
    return {
      quick_reply: false,
      quick_reply_options: [],
      messageInputIsDisabled: false,
    };
  }
};

export const convertToNewQuickReplyPayload = answerData => {
  const {
    quick_reply,
    quick_reply_options,
    messageInputIsDisabled,
    quickReply,
    quickReplyOptions,
  } = answerData;
  const quickRepliesData = {
    enabled: quick_reply?.enabled || parseBoolean(quick_reply || quickReply),
    message: parseBoolean(messageInputIsDisabled),
    replies: [],
  };
  if (Array.isArray(quick_reply?.replies)) {
    const quickRepliesArray = (quick_reply?.replies || []).map(item => ({
      reply: item.reply,
      display: item.display,
      answer: item.answer,
    }));
    quickRepliesData.replies = quickRepliesArray;
  } else if (
    !quick_reply?.replies &&
    (Array.isArray(quick_reply_options) || Array.isArray(quickReplyOptions))
  ) {
    const quickRepliesArray = (
      quick_reply_options ||
      quickReplyOptions ||
      []
    ).map(item => ({
      reply: item.question,
      display: item.label,
      answer: item.answer,
    }));
    quickRepliesData.replies = quickRepliesArray;
  }
  return quickRepliesData;
};

const extractHTMLElement = (currentValue, nodes) => {
  const parentNode = Array.from(nodes);
  const extractedElement = parentNode?.reduce((acc, arr) => {
    const childNode = arr.childNodes;
    if (childNode?.length > 0) {
      return Array.from(childNode)?.reduce((childCurrent, value) => {
        if (value.tagName === 'IMG') {
          return [
            ...childCurrent,
            {
              type: 'image',
              value: `<img src="${value.getAttribute('src')}" alt="answer" />`,
            },
          ];
        } else {
          return [
            ...childCurrent,
            {
              type: 'text',
              value: value.textContent,
            },
          ];
        }
      }, acc);
    } else {
      const imgRegex = /<img\b[^>]*>/i;
      const element = arr.outerHTML;
      const imgElementMatch = element ? element.match(imgRegex) : null;
      if (imgElementMatch) {
        return [
          ...acc,
          {
            type: 'img',
            value: imgElementMatch[0],
          },
        ];
      } else {
        return [
          ...acc,
          {
            type: 'text',
            value: arr.textContent,
          },
        ];
      }
    }
  }, currentValue);

  return extractedElement?.filter(answer => answer.value !== '');
};

export const splitDisplayAnswer = displayAnswer => {
  if (!Array.isArray(displayAnswer)) {
    if (
      !displayAnswer?.includes('<img') &&
      !displayAnswer?.includes(ZSB_CHAT_BREAKER_ENCONDING)
    ) {
      return [
        {
          type: 'text',
          value: displayAnswer,
        },
      ];
    } else {
      const splittedAnswer = displayAnswer.split(ZSB_CHAT_BREAKER_ENCONDING);
      const extractedDisplayAnswer = splittedAnswer?.reduce((acc, arr) => {
        const imgRegex = /<img\b[^>]*>/;
        const imgElementMatch = arr?.toString().match(imgRegex);
        if (imgElementMatch) {
          const htmlString = arr
            ?.replace('<strong><em>', '')
            ?.replace('</em></strong>', '');
          const tempElement = document.createElement('div');
          tempElement.innerHTML = htmlString;
          const childNodes = tempElement.childNodes;
          return extractHTMLElement(acc, childNodes);
        } else {
          return [
            ...acc,
            {
              type: 'text',
              value: arr,
            },
          ];
        }
      }, []);
      return extractedDisplayAnswer;
    }
  }
};
