import React, { useContext, useState } from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import DeleteCategoryModal from 'components/Modals/DeleteCategoryModal';
import { Context } from 'store/store';
import {
  StyledCategoryList,
  StyledCategoryListNameSection,
  StyledFolderFilled,
  StyledCategoryListName,
  StyledEditButton,
  StyledEditOutlined,
  StyledDeleteButton,
  StyledDeleteOutlined,
} from './EditCategoryList.styles';
import { allAnswersSelector } from 'selectors/bot/answers';
import { stripUUID } from 'utils';
import { SET_SELECTED_CATEGORY_DETAILS } from 'store/action';
import useSelector from 'store/useSelector';
import { isUserCategorySelector } from 'selectors/bot/categories';

const EditCategoryList = ({ category, handleSave }) => {
  const [state, dispatch] = useContext(Context);
  const allAnswers = useSelector(allAnswersSelector);

  const { name, color, jid } = category;
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const isUserCategory = useSelector(isUserCategorySelector, category);

  const answersList = allAnswers.filter(
    answer => stripUUID(answer.categoryId) === stripUUID(category.jid)
  );

  const handleEditCategory = () => {
    dispatch({
      type: SET_SELECTED_CATEGORY_DETAILS,
      payload: {
        name,
        color,
        jid,
        action: 'edit',
      },
    });
  };

  return (
    <StyledCategoryList>
      <StyledCategoryListNameSection>
        <StyledFolderFilled color={color} />
        <StyledCategoryListName>{name}</StyledCategoryListName>(
        {answersList.length})
      </StyledCategoryListNameSection>
      <div>
        {isUserCategory && (
          <Tooltip title={`Edit ${name}`}>
            <StyledEditButton>
              <StyledEditOutlined onClick={handleEditCategory} />
            </StyledEditButton>
          </Tooltip>
        )}
        <Tooltip title={`Delete ${name}`}>
          <StyledDeleteButton>
            <StyledDeleteOutlined
              onClick={() => setShowDeleteCategoryModal(true)}
            />
          </StyledDeleteButton>
        </Tooltip>
      </div>

      <DeleteCategoryModal
        isUserCategory={isUserCategory}
        category={category}
        visible={showDeleteCategoryModal}
        handleSave={handleSave}
        onCancel={() => setShowDeleteCategoryModal(false)}
      />
    </StyledCategoryList>
  );
};

EditCategoryList.propTypes = {
  category: PropTypes.object,
  handleSave: PropTypes.func,
};

export default EditCategoryList;
