import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { StyledBackButton } from './BackButton.style';

const BackButton = props => {
  let history = useHistory();
  const { prevPageTitle, iconOnly, icon, noArrow, ...rest } = props;

  return (
    <StyledBackButton
      onClick={rest.onClick ? rest.onClick : () => history.goBack()}
      value={
        !iconOnly
          ? ` Back${prevPageTitle ? ` to ${prevPageTitle}` : null}`
          : null
      }
      startIcon={icon ? icon : noArrow ? undefined : <ArrowLeftOutlined />}
      variant="link"
      {...rest}
    />
  );
};

BackButton.defaultProps = {
  prevPageTitle: 'Previous Page',
};

BackButton.propTypes = {
  prevPageTitle: PropTypes.string,
  iconOnly: PropTypes.bool,
  icon: PropTypes.node,
  noArrow: PropTypes.bool,
};

export default BackButton;
