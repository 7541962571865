import styled from 'styled-components';

import Button from 'components/Button';
import { cssVariables } from 'styles/root';

export const StyledApiKey = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCodeButton = styled(Button)`
  background-color: ${cssVariables.primaryBlue} !important;
  text-transform: capitalize;
  max-width: 200px;

  &.secondary {
    color: ${cssVariables.primaryBlue} !important;
    background-color: transparent !important;
  }

  & span {
    font-weight: ${cssVariables.font.normal};
    letter-spacing: 0px;
  }
`;

export const StyledCodeBlock = styled.div`
  max-width: 100%;
  border-radius: 5px;

  & pre {
    max-width: 100%;
    width: 100%;
    word-break: break-all;
  }
`;
