import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Menu, Layout } from 'antd';
import Joyride from 'react-joyride';
import { snakeCase } from 'lodash';
import {
  ReadOutlined,
  RiseOutlined,
  ExpandOutlined,
  SettingOutlined,
  AppstoreOutlined,
  FormOutlined,
  DashboardOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
  LineChartOutlined,
  FundProjectionScreenOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FunctionOutlined,
} from '@ant-design/icons';

import SubMenu from 'antd/lib/menu/SubMenu';
import TextIcon from 'components/TextIcon/TextIcon';
import {
  Wrapper,
  StyledCustomTooltip,
  StyledToolTipContainer,
  StyledFooterActions,
  StyledToggleIconWrapper,
} from './Sidebar.style';
import { slugify } from 'utils/stringManipulation';
import ROUTES from 'constants/routes';
import { BOT_SIDEBAR_MENU } from 'constants/joyride_steps/bot_sidebar_menu';
import { BackToBotsPage } from 'components/BackButton/BackToBotsPage';
import {
  StyledFlexRowCenter,
  StyledSpaceBetweenFlexColumn,
} from 'styles/GenericStyledComponents';
import {
  PERFORMANCE_METRICS_PATH_PREFIX,
  QUESTION_LOGS,
} from 'constants/analytics';
import ToolTip from 'components/ToolTips/BaseToolTip';
import botLogo from 'assets/images/robot-icon-black-small.svg';
import { useMemo } from 'react';
import useSidebar from './hooks';

const DEFAULT_BOT_DETAILS_LANDING_PAGE = 'overview';

const CustomTooltip = ({
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  skipProps,
}) => {
  return (
    <StyledCustomTooltip {...tooltipProps}>
      <StyledToolTipContainer>
        {step.title && <div>{step.title}</div>}
        <div>{step.content}</div>
        <StyledFooterActions>
          <div>
            {index > 0 && (
              <button type="button" {...backProps}>
                BACK
              </button>
            )}
            {isLastStep ? (
              <button type="button" {...primaryProps}>
                CLOSE
              </button>
            ) : (
              <button type="button" {...primaryProps}>
                NEXT
              </button>
            )}
          </div>
          <button type="button" {...skipProps}>
            SKIP
          </button>
        </StyledFooterActions>
      </StyledToolTipContainer>
    </StyledCustomTooltip>
  );
};

CustomTooltip.propTypes = {
  index: PropTypes.number,
  step: PropTypes.object,
  backProps: PropTypes.object,
  primaryProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  isLastStep: PropTypes.bool,
};

const Sidebar = () => {
  const {
    allAnswers,
    allAITools,
    hash,
    onClickCallback,
    runTour,
    pathname,
    mode,
    handleClickMenu,
    handleToggleCollapse,
    showIconOnly,
    strippedBotID,
    botName,
    isBotSimulatorEnabled,
    stepIndex,
    isExternalPage,
  } = useSidebar();

  const MENU_LIST = useMemo(() => {
    let menuList = [
      {
        name: 'Overview',
        icon: <DashboardOutlined />,
      },
      {
        name: 'Answer Bank',
        icon: <ReadOutlined />,
      },
      {
        name: 'Linked Questions',
        icon: <QuestionCircleOutlined />,
      },
      {
        name: 'Automation Testing',
        icon: <ExpandOutlined />,
      },
      {
        name: 'Integration',
        icon: <AppstoreOutlined />,
      },
      {
        name: 'Analytics',
        icon: <RiseOutlined />,
      },
      {
        name: 'Callback Logs',
        icon: <OrderedListOutlined />,
      },
      {
        name: 'Bot Settings',
        icon: <SettingOutlined />,
      },
    ];
    menuList = [
      ...menuList.slice(0, 3),
      mode?.includes('openai')
        ? {
            name: 'Functions',
            icon: <FunctionOutlined />,
          }
        : {
            name: 'Flow Simulator',
            icon: <FundProjectionScreenOutlined className="menu-icon" />,
          },
      ...menuList.slice(3),
    ];

    const externalMenuList = [
      {
        name: 'Answer Bank',
        icon: <ReadOutlined />,
      },

      {
        name: 'Integration',
        icon: <AppstoreOutlined />,
      },
    ];
    return isExternalPage ? externalMenuList : menuList;
  }, [mode]);

  const ANALYTICS_SUBMENU_ITEMS = [
    {
      name: 'Question Log',
      icon: <FormOutlined />,
      key: QUESTION_LOGS,
    },
    {
      name: 'Performance Metrics',
      icon: <LineChartOutlined />,
      key: PERFORMANCE_METRICS_PATH_PREFIX,
    },
  ];

  const isAlwaysActiveMenu = [
    'overview',
    'answer-bank',
    'bot-settings',
    'functions',
  ];

  const renderSubMenu = (parentMenu, submenuItems = []) => {
    return (
      <SubMenu
        icon={parentMenu.icon}
        key={slugify(parentMenu.name)}
        title={parentMenu.name}
        disabled={
          !allAnswers?.length &&
          !allAITools.length &&
          parentMenu.name !== 'Answer Bank'
        }
        className={
          pathname?.length && pathname?.includes(slugify(parentMenu.name))
            ? `ant-menu-item-active ant-menu-item-selected ${snakeCase(
                parentMenu?.name
              )}`
            : ''
        }
      >
        {submenuItems.map(item => {
          return (
            <Menu.Item
              icon={item.icon}
              key={slugify(item.name)}
              className={
                hash?.length && hash.includes(slugify(item?.name))
                  ? `ant-menu-item-active ant-menu-item-selected ${snakeCase(
                      item?.name
                    )}`
                  : ''
              }
              onClick={() =>
                handleClickMenu(slugify(parentMenu.name), item.key)
              }
            >
              {item?.name}
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  };

  const isDefaultDetailsPage = () => {
    const regex = /\/bot\/([0-9a-fA-F-]+)/;
    const match = pathname.match(regex);

    if (pathname.includes(DEFAULT_BOT_DETAILS_LANDING_PAGE)) {
      return true;
    } else if (match) {
      const textAfterMatch = pathname.slice(
        pathname.indexOf(match[0]) + match[0].length
      );

      return (
        (textAfterMatch &&
          textAfterMatch.includes(DEFAULT_BOT_DETAILS_LANDING_PAGE)) ||
        !textAfterMatch
      );
    }
    return false;
  };

  const renderMenu = (menu, pathName) => {
    const normalizeMenuName = menu.name.replace(/ /g, '_').toLowerCase();
    const slugifiedNamed = slugify(menu.name);
    const defaultDetailsPage =
      slugify(menu.name) === 'overview' && isDefaultDetailsPage();
    if (menu?.name === 'Analytics') {
      return renderSubMenu(menu, ANALYTICS_SUBMENU_ITEMS);
    } else if (menu?.name === 'Flow Simulator' && !isBotSimulatorEnabled) {
      return null;
    } else if (
      ['Linked Questions', 'Automation Testing'].includes(menu?.name)
    ) {
      return (
        <Menu.Item
          key={slugifiedNamed}
          className={
            pathName ===
              `${ROUTES.BOT_DETAILS}/${strippedBotID}/${slugifiedNamed}` ||
            defaultDetailsPage
              ? `ant-menu-item-active ant-menu-item-selected ${normalizeMenuName}`
              : '' + normalizeMenuName
          }
          icon={menu.icon}
          onClick={() => handleClickMenu(slugifiedNamed)}
          disabled={
            !isAlwaysActiveMenu?.includes(slugifiedNamed) && !allAnswers?.length
          }
        >
          {menu.name}
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item
          key={slugifiedNamed}
          className={
            pathName ===
              `${ROUTES.BOT_DETAILS}/${strippedBotID}/${slugifiedNamed}` ||
            defaultDetailsPage
              ? `ant-menu-item-active ant-menu-item-selected ${normalizeMenuName}`
              : '' + normalizeMenuName
          }
          icon={menu.icon}
          onClick={() => handleClickMenu(slugifiedNamed)}
          disabled={
            !isAlwaysActiveMenu?.includes(slugifiedNamed) &&
            !allAnswers?.length &&
            !allAITools.length
          }
        >
          {menu.name}
        </Menu.Item>
      );
    }
  };

  return (
    <Wrapper>
      <Layout.Sider collapsed={showIconOnly} theme="light" className="sider">
        <div className="top-sider-menu">
          <BackToBotsPage iconOnly={showIconOnly} />
          <Divider style={{ margin: '10px 0' }} />
          <div className="bot-name-container">
            {showIconOnly ? (
              <TextIcon>{botName[0]?.toUpperCase() || '?'}</TextIcon>
            ) : (
              <img src={botLogo} alt="bot" height={40} />
            )}
            {!showIconOnly && <span>{botName}</span>}
          </div>
          <Divider key={`sidebar-divider-${2}`} />
        </div>
        <StyledSpaceBetweenFlexColumn
          height="calc(100vh - 250px)"
          className="sidebar-content"
        >
          <Menu theme="light" mode="inline" className="bot-menu-sidebar">
            {!!MENU_LIST
              ? MENU_LIST.map(menu => renderMenu(menu, pathname))
              : null}
          </Menu>
          <StyledFlexRowCenter
            style={{ cursor: 'pointer' }}
            padding="0px 0px 10px 0px"
            onClick={handleToggleCollapse}
          >
            <ToolTip title={showIconOnly ? 'Expand' : 'Collapse'}>
              <StyledToggleIconWrapper showIconOnly={showIconOnly}>
                {showIconOnly ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </StyledToggleIconWrapper>
            </ToolTip>
          </StyledFlexRowCenter>
        </StyledSpaceBetweenFlexColumn>
      </Layout.Sider>
      <Joyride
        run={runTour}
        steps={BOT_SIDEBAR_MENU}
        stepIndex={stepIndex}
        tooltipComponent={CustomTooltip}
        continuous
        debug
        spotlightClicks
        showProgress={true}
        showSkipButton={true}
        callback={onClickCallback}
        isFirstStep
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
      />
    </Wrapper>
  );
};

export default Sidebar;
