import { createSelector } from 'reselect';

import {
  botTitleSelector,
  currentIntegrationIDSelector,
  integrationDataSelector,
  currentIntegrationSettingsSettingPropSelector,
  demoCustomBackgroundSelector,
  demoIntegrationBackgroundSelector,
  encryptedBotSelector,
  initialIntegrationSettingsSelector,
  integrationChannelNameSelector,
  integrationConfigURLSelector,
  integrationFolderPathSelector,
  integrationPathSelector,
  interactionHistorySelector,
  isDemoCustomBgSelectedSelector,
  isDemoDarkBackgroundSelector,
  isDemoHasCustomBackgroundSelector,
  isDemoIntegrationSelector,
  isMobileIntegrationSelector,
  launcherPositionSelector,
  launcherShapeSelector,
  maxInteractionSelector,
  replyBubbleGradientSelector,
  widgetHeightWithPixelSelector,
  widgetIconColorSelector,
  widgetPlaceholderSelector,
  widgetThemeColorSelector,
  widgetV2TypeSelector,
  widgetVersionSelector,
} from '.';
import {
  LAUNCHER_ONLY_AVATAR,
  AVATAR_ICON_OPTIONS,
  DEFAULT_REPLY_BUBBLE_GRADIENT,
  DEFAULT_WIDGET_WELCOME_MESSAGE,
  DEFAULT_MAX_INTERACTION,
  DEFAULT_BG,
  DEFAULT_BG_LIGHT,
} from 'constants/botCustomizer';
import { hexToRGBA } from 'components/BotCustomizer/utils';
import { isImageOrDocFileType } from 'utils/dataTypes';
import { MESSENGER_API_VERSION } from 'constants/integration';
import { cssVariables } from 'styles/root';
import {
  COPY_TO_CLIPBOARD_SCRIPT,
  generateBody,
  generateHeader,
} from 'services/fileGenerator.service';
import { botNameSelector, currentBotSelector } from '..';
import { getImageCloudPath } from 'store/reducers/helpers/bot/integrations';

// TODO: move widget integration settings here plz
export const widgetFormFieldsSelectors = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => settings.formFields || []
);

export const widgetAvatarResponseSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => settings.responseAvatar
);

export const launcherAvatarSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => settings.launcherAvatar || 'default'
);

export const launcherAvatarTypeSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => {
    return settings.launcherAvatarType || 'icon';
  }
);

export const isUseWidgetIconRadioDisabledSelector = createSelector(
  launcherAvatarSelector,
  launcherAvatarTypeSelector,
  (launcherAvatar, launcherAvatarType) => {
    return (
      launcherAvatarType !== 'icon' ||
      (launcherAvatarType === 'icon' &&
        Boolean(
          launcherAvatar && LAUNCHER_ONLY_AVATAR.includes(launcherAvatar)
        ))
    );
  }
);

export const widgetHeaderAvatarTypeSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  isUseWidgetIconRadioDisabledSelector,
  (settings, isUseWidgetIconRadioDisabled) => {
    if (settings.headerAvatarType === 'icon' && isUseWidgetIconRadioDisabled) {
      return 'none';
    }
    // Deprecating `botAvatar` to `headerAvatarType`
    return settings.headerAvatarType || settings.botAvatar || 'none';
  }
);

export const widgetAvatarResponseTypeSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  isUseWidgetIconRadioDisabledSelector,
  (settings, isUseWidgetIconRadioDisabled) => {
    if (
      settings.responseAvatarType === 'icon' &&
      isUseWidgetIconRadioDisabled
    ) {
      return 'none';
    }
    return settings.responseAvatarType || 'none';
  }
);

export const isLauncherAvatarSvgIconSelector = createSelector(
  launcherAvatarTypeSelector,
  launcherAvatarSelector,
  (launcherAvatarType, launcherAvatar) =>
    launcherAvatarType === 'icon' &&
    AVATAR_ICON_OPTIONS.includes(launcherAvatar)
);

export const isLauncherAvatarCustomSelector = createSelector(
  launcherAvatarTypeSelector,
  launcherAvatarSelector,
  (launcherAvatarType, launcherAvatar) =>
    launcherAvatarType === 'custom' &&
    !AVATAR_ICON_OPTIONS.includes(launcherAvatar) &&
    (launcherAvatar instanceof File ||
      (typeof launcherAvatar === 'string' &&
        isImageOrDocFileType(launcherAvatar) === 'image'))
);

export const widgetHeaderAvatarSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  widgetHeaderAvatarTypeSelector,
  launcherAvatarTypeSelector,
  (settings, headerAvatarType, launcherAvatarType) => {
    if (
      headerAvatarType === 'icon' &&
      launcherAvatarType === 'icon' &&
      !LAUNCHER_ONLY_AVATAR.includes(settings.headerAvatar)
    ) {
      return settings.launcherAvatar;
    }
    return settings.headerAvatar || null;
  }
);

export const isHeaderAvatarSvgIconSelector = createSelector(
  widgetHeaderAvatarTypeSelector,
  widgetHeaderAvatarSelector,
  (headerAvatarType, avatar) => {
    return typeof headerAvatarType !== 'undefined' && avatar
      ? headerAvatarType === 'icon' &&
          AVATAR_ICON_OPTIONS.includes(avatar) &&
          !LAUNCHER_ONLY_AVATAR.includes(avatar)
      : // fallback icon type is launcherAvatarType
        false;
  }
);

// returns true if
// avatar is file type or in-cloud path
export const isHeaderAvatarCustomSelector = createSelector(
  widgetHeaderAvatarTypeSelector,
  isHeaderAvatarSvgIconSelector,
  widgetHeaderAvatarSelector,
  initialIntegrationSettingsSelector,
  (avatarType, isIconTypeAvatar, avatar) => {
    return (
      avatarType === 'custom' &&
      // if avatar is not uploaded yet
      // expects `file` type value
      (avatar instanceof File ||
        (typeof avatar === 'string' &&
          isImageOrDocFileType(avatar) === 'image'))
    );
  }
);

// only show cloud path avatar
export const showHeaderImagePreviewSelector = createSelector(
  widgetHeaderAvatarSelector,
  isHeaderAvatarCustomSelector,
  (avatar, isHeaderAvatarCustomType) => {
    return (
      isHeaderAvatarCustomType &&
      typeof avatar === 'string' &&
      isImageOrDocFileType(avatar) === 'image'
    );
  }
);

// only show cloud path avatar
export const showLauncherImagePreviewSelector = createSelector(
  launcherAvatarSelector,
  isLauncherAvatarCustomSelector,
  (avatar, isAvatarCustomType) => {
    return (
      isAvatarCustomType &&
      typeof avatar === 'string' &&
      isImageOrDocFileType(avatar) === 'image'
    );
  }
);

export const isHeaderAvatarCantApplySelector = createSelector(
  widgetHeaderAvatarSelector,
  headerAvatar => {
    return (
      LAUNCHER_ONLY_AVATAR.includes(headerAvatar) ||
      headerAvatar === 'custom' ||
      (headerAvatar !== 'custom' && !AVATAR_ICON_OPTIONS.includes(headerAvatar))
    );
  }
);

// default replyBubbleColor + gradient
// value returned is for rendering
// not for saving into `config.json`
export const defaultReplyBubbleColorSelector = createSelector(
  replyBubbleGradientSelector,
  widgetThemeColorSelector,
  (replyBubbleGradient, themeColor) => {
    const converted = hexToRGBA(
      themeColor,
      replyBubbleGradient || DEFAULT_REPLY_BUBBLE_GRADIENT
    );
    return `rgba(${converted})`;
  }
);

export const welcomeMessageSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => {
    return settings.welcomeMessage || DEFAULT_WIDGET_WELCOME_MESSAGE;
  }
);

export const isDemoCustomBackgroundInCloudSelector = createSelector(
  demoIntegrationBackgroundSelector,
  isDemoHasCustomBackgroundSelector,
  background => {
    const bgList = ['light', 'dark', 'custom'];
    return typeof background === 'string' && !bgList.includes(background)
      ? isImageOrDocFileType(background) === 'image'
      : false;
  }
);

export const demoCustomBackgroundCloudPathSelector = createSelector(
  demoIntegrationBackgroundSelector,
  integrationFolderPathSelector,
  (background, folderPath) => {
    const bgList = ['light', 'dark', 'custom'];
    // expects that the customBackground value is from `file` type value
    if (typeof background === 'string' && bgList.includes(background)) {
      return null;
    } else {
      return getImageCloudPath(background, folderPath, 'demo-background');
    }
  }
);

export const widgetHeaderAvatarCloudPathSelector = createSelector(
  widgetHeaderAvatarSelector,
  integrationFolderPathSelector,
  (headerAvatar, folderPath) => {
    return getImageCloudPath(headerAvatar, folderPath, 'headerAvatar');
  }
);

export const widgetResponseAvatarCloudPathSelector = createSelector(
  widgetAvatarResponseSelector,
  integrationFolderPathSelector,
  (responseAvatar, folderPath) => {
    return getImageCloudPath(responseAvatar, folderPath, 'responseAvatar');
  }
);

export const launcherAvatarCloudPathSelector = createSelector(
  launcherAvatarSelector,
  integrationFolderPathSelector,
  (launcherAvatar, folderPath) => {
    return getImageCloudPath(launcherAvatar, folderPath, 'launcherAvatar');
  }
);

export const integrationConfigDataSelector = createSelector(
  widgetHeaderAvatarTypeSelector,
  widgetHeaderAvatarSelector,
  launcherAvatarSelector,
  launcherAvatarTypeSelector,
  demoCustomBackgroundSelector,
  welcomeMessageSelector,
  widgetAvatarResponseSelector,
  widgetAvatarResponseTypeSelector,
  widgetFormFieldsSelectors,
  demoIntegrationBackgroundSelector,
  launcherShapeSelector,
  replyBubbleGradientSelector,
  isDemoIntegrationSelector,
  interactionHistorySelector,
  launcherPositionSelector,
  botTitleSelector,
  integrationChannelNameSelector,
  widgetIconColorSelector,
  widgetThemeColorSelector,
  widgetHeightWithPixelSelector,
  widgetPlaceholderSelector,
  widgetV2TypeSelector,
  widgetVersionSelector,
  currentIntegrationIDSelector,
  maxInteractionSelector,
  currentIntegrationSettingsSettingPropSelector,
  currentBotSelector,
  (
    headerAvatarType,
    headerAvatar,
    launcherAvatar,
    launcherAvatarType,
    customBackground,
    welcomeMessage,
    responseAvatar,
    responseAvatarType,
    formFields,
    demoBackground,
    shape,
    replyBubbleGradient,
    isDemo,
    interactionHistory,
    position,
    botTitle,
    identifier,
    iconColor,
    color,
    height,
    placeholder,
    widgetType,
    widgetVersion,
    integrationId,
    maxInteraction,
    settings,
    bot
  ) => {
    return {
      identifier: identifier || bot.name,
      integration: {
        name: identifier || bot.name,
        id: isDemo ? '0' : integrationId,
      },
      autoOpen:
        // openWidget name is deprecated
        typeof settings.autoOpen !== 'undefined'
          ? Boolean(settings.autoOpen)
          : Boolean(settings.openWidget),
      authenticatedUser: Boolean(settings.authenticatedUser),
      background: isDemo
        ? customBackground
          ? // image background
            customBackground
          : // either 'dark', or 'light'
            demoBackground
        : // background is used for demo html
          // therefore non-demo type of integration dont need background
          undefined,
      botTitle,
      // v1 uses `componentTitle`
      componentTitle:
        widgetVersion === 'v1'
          ? botTitle || settings.componentTitle
          : undefined,
      bubbleColor: settings.bubbleColor || color,
      category: shape === 'rectangle' ? 'text' : 'image',
      callbackEmail: Boolean(settings.callbackEmail),
      // widget theme color
      color,
      // whether we allow client to show request agent fields (?)
      displayFormFields: Boolean(settings.displayFormFields),
      // the formfields that will be displayed for request callback
      formFields,
      fb_access_token: settings.fb_access_token || null,
      fb_api_version: settings.fb_api_version || MESSENGER_API_VERSION,
      formHeader: settings.formHeader,
      // font size for chat messages (?)
      fontSize: Boolean(settings.fontSize)
        ? String(settings.fontSize).includes('px')
          ? settings.fontSize
          : `${settings.fontSize}px`
        : '13px',
      iconColor,
      interactionHistory,
      handoffLabel: settings.handoffLabel || `Request Agent Call Back`,
      height,
      // v1 uses avatar && avatarPosition
      avatar: widgetVersion === 'v2' ? undefined : headerAvatar,
      avatarPosition:
        widgetVersion === 'v2'
          ? undefined
          : settings.avatarPosition
          ? headerAvatarType !== 'none' && headerAvatar
            ? 'header'
            : responseAvatarType !== 'none' && responseAvatar
            ? 'chat'
            : settings.avatarPosition
          : undefined,
      headerAvatar,
      responseAvatar,
      launcherAvatar,
      // types are either 'none', 'icon' or 'custom'
      headerAvatarType,
      launcherAvatarType,
      responseAvatarType,
      maxInteraction: maxInteraction || DEFAULT_MAX_INTERACTION,
      maxUnlike: settings.maxUnlike || DEFAULT_MAX_INTERACTION,
      placeholder,
      position,
      // deprecated in v2
      openWidget:
        widgetVersion === 'v2'
          ? undefined
          : typeof settings.autoOpen !== 'undefined'
          ? Boolean(settings.autoOpen)
          : Boolean(settings.openWidget),
      // color gradient of the `color` prop
      replyBubbleGradient,
      // no label needed if widget is circle
      // todo: plz deprecate `src`
      src: shape === 'rectangle' ? settings.label : null,
      label: shape === 'rectangle' ? settings.label : null,
      shape,
      showCloseButton: Boolean(settings.showCloseButton),
      subtitle: settings?.subtitle || 'Come and Test me',
      textColor: settings.textColor || color,
      // v2 can now accept either 'chat' or 'search' type
      type: widgetType,
      widgetVersion,
      welcomeMessage,
      cancelledFormMessage:
        settings.cancelledFormMessage ||
        `You've cancelled the form. Anything else I can help you with?`,
      submittedFormMessage:
        settings.submittedFormMessage ||
        `Thank you, your information has been submitted. Next available agent will connect with you shortly.`,
      typingExperience: Boolean(settings.typingExperience) || false,
    };
  }
);

export const currentFullIntegrationSelector = createSelector(
  integrationChannelNameSelector,
  integrationPathSelector,
  integrationConfigDataSelector,
  integrationDataSelector,
  (identifier, path, configData, currentIntegration) => {
    return {
      identifier,
      path,
      type: currentIntegration.type,
      settings: configData,
    };
  }
);

const ZSB_JS_FILE = process.env.REACT_APP_AZURE_BOTCLIENT_FILE;
const ZSB_JS_FILE_V2 = process.env.REACT_APP_AZURE_BOTCLIENT_FILE_V2;
const ZSB_JS_URI = process.env.REACT_APP_AZURE_BOTCLIENT_URI;
const ZSB_JS_TOKEN = process.env.REACT_APP_AZURE_BOTCLIENT_SAS_TOKEN;

export const BOT_SCRIPT = `<script src="${ZSB_JS_URI}/${ZSB_JS_FILE}?${ZSB_JS_TOKEN}"></script>`;
export const BOT_SCRIPT_V2 = `<script src="${ZSB_JS_URI}/${ZSB_JS_FILE_V2}?${ZSB_JS_TOKEN}"></script>`;

export const demoBodySelector = createSelector(
  isDemoIntegrationSelector,
  isDemoCustomBgSelectedSelector,
  integrationConfigURLSelector,
  botNameSelector,
  isDemoDarkBackgroundSelector,
  (isDemo, isDemoCustomBgSelected, demoLink, botName, isDemoDarkBg) => {
    return isDemo && !isDemoCustomBgSelected
      ? generateBody(demoLink, botName, isDemoDarkBg)
      : '';
  }
);

export const demoHeaderSelector = createSelector(
  isDemoIntegrationSelector,
  isDemoCustomBgSelectedSelector,
  isDemoDarkBackgroundSelector,
  (isDemo, isDemoCustomBgSelected, isDemoDarkBg) => {
    return isDemo && isDemoCustomBgSelected ? generateHeader(isDemoDarkBg) : '';
  }
);

export const botComponentSelector = createSelector(
  encryptedBotSelector,
  currentIntegrationSettingsSettingPropSelector,
  isMobileIntegrationSelector,
  widgetVersionSelector,
  widgetHeightWithPixelSelector,
  (encryptedBot, settings, isMobileIntegration, widgetVersion, height) => {
    const isAuthenticatedUser = Boolean(settings.authenticatedUser);
    const authenticatedHTMLTag = isAuthenticatedUser
      ? `visitorid=$visitorID`
      : ``;

    const botComponent = isMobileIntegration
      ? `WebViewPlus(
        javascriptMode: JavascriptMode.unrestricted,
        onWebViewCreated: (controller) {
          ${
            isAuthenticatedUser
              ? `final String visitorID = ModalRoute.of(context)!.settings.arguments as String;`
              : ''
          }
          final String botIntegration='${
            widgetVersion === 'v2' ? BOT_SCRIPT_V2 : BOT_SCRIPT
          }<zeroshot-bot textColor="${settings.textColor || '#fff'}" color="${
          settings.color
        }" height="${height}" bot="${encryptedBot}" showCloseButton="${
          settings.showCloseButton
        } " autoOpen="${
          settings.autoOpen
        } " ${authenticatedHTMLTag}></zeroshot-bot>';
          _controller = controller;
          controller.loadString(botIntegration);
                }
              )`
      : `<zeroshot-bot textColor="${settings.textColor || '#fff'}" color="${
          settings.color
        }" height="${height}" bot="${encryptedBot}"></zeroshot-bot>`;

    return botComponent;
  }
);

export const headTagCodeSelector = createSelector(
  widgetVersionSelector,
  widgetVersion => `<!DOCTYPE html>
    <html lang='en'>
      <head>
      <meta charset='utf-8'>
      ${widgetVersion === 'v2' ? BOT_SCRIPT_V2 : BOT_SCRIPT}
      </head>
    </html>`
);

export const demoHTMLSelector = createSelector(
  isDemoIntegrationSelector,
  demoCustomBackgroundCloudPathSelector,
  botComponentSelector,
  isDemoHasCustomBackgroundSelector,
  headTagCodeSelector,
  isDemoDarkBackgroundSelector,
  demoHeaderSelector,
  demoBodySelector,
  (
    isDemo,
    demoCustomBackground,
    zsbComponent,
    isCustomBg,
    headTag,
    isDarkBg,
    header,
    body
  ) => {
    if (isDemo) {
      const HTMLBgImgURL = isCustomBg
        ? demoCustomBackground
        : isDarkBg
        ? DEFAULT_BG
        : DEFAULT_BG_LIGHT;

      let demoHTML;

      const demoBodyStyle = `
    ${Boolean(isCustomBg) ? '' : COPY_TO_CLIPBOARD_SCRIPT}
    <style>
    body {
      overflow: hidden !important;
      background-image: url('${HTMLBgImgURL}');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }
    
    
    .demo-header {
      z-index: 1;
      top: 0;
      padding-left: 38px;
      padding-top: 12px;
    
    }
    
    .demo-body {
      font-family: 'Arial';
      flex-direction: column;
      justify-content: center;
      align-items: center;
      justify-content: center;
      align-items: revert;
      margin: 15% 0px 0px 20%;
    }
  
    .bot-name {
      font-size: 40px;
      font-weight: ${cssVariables.font.bold};
    }
  
    .demo-greeting-message {
      color: ${isDarkBg ? '#d3d3d3' : '#000'};
      margin-top: 15px;
    }
  
    .demo-link {
      flex-direction: row;
      margin: 32px 0;
      background-color: #d3d3d3;
      border-radius: 5px;
      max-width: 70% !important;
      align-self: start;
      align-items: center;
      justify-content: space-between;
      overflow-wrap: break-word;
    }
  
    .demo-body, .bot-name, .demo-greeting-message, .demo-link {
      display: flex;
      position: relative;
    }
  
    a {
      text-decoration: none;
      max-width: 80%;
      color: #333;
    }
  
    .copy-icon {
      height: 25px;
      padding: 5px;
      position: absolute;
      right: 0;
  
    }
  
    .copy-icon:hover {
      cursor: pointer;
    }
  
    .custom-close svg, .custom-close img {
      width: 24px !important;
    }
  
    .custom-launcher .avatar svg {
      height: 40px !important;
      width: 40px !important;
    }
    </style>
    `;
      demoHTML = headTag.replace('</script>', `$& ${demoBodyStyle}`);
      demoHTML = demoHTML.replace(
        '</head>',
        `$& <body>
        ${isCustomBg ? zsbComponent : header + body + zsbComponent}
      </body>`
      );
      return demoHTML;
    }

    return null;
  }
);
