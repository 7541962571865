/* eslint-disable react/display-name */
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Spin,
  Table,
  Dropdown,
  Menu,
  Row,
  Col,
  Pagination,
  Space,
  Drawer,
  Checkbox,
  Tag,
  Collapse,
  Grid,
} from 'antd';
import { useReactToPrint } from 'react-to-print';
import {
  FolderAddOutlined,
  LockTwoTone,
  CaretDownOutlined,
  SmileOutlined,
  DownloadOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ArrowsAltOutlined,
  FilterFilled,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { startCase } from 'lodash';

import Title from 'components/Title';
import AddQuestionToTestSuiteModal from 'components/Modals/AddQuestionToTestSuiteModal';
import CallbackModal from 'components/Modals/CallbackModal';
import FeedbackModal from 'components/Modals/FeedbackModal';
import DateFilter from 'components/DateFilter';
import { UTCToLocal, getLocalTimeString } from 'utils/dates';
import { stringLocaleCompare } from 'utils/stringManipulation';
import useAnalytics from './hooks';
import {
  CardsWrapper,
  ChartWrapper,
  StyledColumnChart,
  DataSummaryCard,
  StyledDatePickerButton,
  StyledGhostDropdown,
  StyledExpandedRow,
  StyledRoot,
  StyledMetric,
  StyledMetricPeriodFilter,
  StyledDateFilterLabel,
  StyledQuestionsList,
  StyledQuestion,
  StyledQuestions,
  StyledMore,
  StyledShowAnswer,
  StyledFiltersSelect,
  RowSpaceBetween,
  KeyMetricsSummaryCard,
  KeyMetricsCardsWrapper,
  StyledExpandedTableTitle,
  StyledFilterWrapper,
  StyledSequenceContainer,
} from './Analytics.styles';
import LimitationBanner from 'components/LimitationBanner';
import { cssVariables } from 'styles/root';
import Button from 'components/Button';
import moment from 'moment';
import ToolTip from 'components/ToolTips/BaseToolTip';
import {
  CORRECT_QUESTIONS,
  INCORRECT_QUESTIONS,
  INTENTS,
  MOST_ASKED_QUESTION,
  PERFORMANCE_METRICS_DEFAULT_ANSWERS,
  PERFORMANCE_METRICS_INCORRECT_QUESTIONS,
  PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS,
  QUESTION_LOGS,
  QUESTION_WITH_NEGATIVE_FEEDBACK,
  QUESTION_WITH_POSITIVE_FEEDBACK,
  TOOLTIPS,
} from 'constants/analytics';
import ConfirmQuestionValidation from 'components/Modals/ConfirmQuestionValidation';
import { isAnObject } from 'utils/dataTypes';
import { AnswerRenderer } from 'components/AnswerRenderer';
import {
  StyledFlexColumn,
  StyledFlexRowLeft,
} from 'styles/GenericStyledComponents';

const { Panel } = Collapse;
const { useBreakpoint } = Grid;
const EXPORT_TO_EXCEL =
  process.env.REACT_APP_EXPORT_TO_EXCEL === 'true' ? true : false;

const FILTER_SELECT_OPTIONS = [
  {
    label: 'Category',
    value: 'category',
    key: 'category',
  },
  {
    label: 'Integration',
    value: 'integration',
    key: 'integration',
  },
];

const Analytics = ({ handleShowAddTestSuiteModal }) => {
  const {
    chartData,
    dateFilter,
    handleChangeDataFilterButton,
    handleChangeIntegrationSelection,
    handleChangeCategories,
    handleCloseAddTestSuiteModal,
    handleCloseInteractionFeedback,
    handleCloseSessionCallbacks,
    handleDateFilterChange,
    handleCSVDownloadQlogs,
    handleXLSXDownloadQlogs,
    handleOnSelect,
    handleShowSessionCallbacks,
    handleClickedQuality,
    handleChangePageNumber,
    integrationFilterOptions,
    isFreeUser,
    pageLoading,
    questionForTestSuite,
    selectedInteractionID,
    selectedTableView,
    selectedSessionID,
    sessionFilters,
    showAddQuestionToTestSuiteModal,
    showModal,
    tableSpinner,
    aggregations,
    params,
    showPagination,
    handleTableChange,
    sessionFilteredInfo,
    isPerformanceMetricsView,
    tableViewList,
    maximizeTableColumn,
    selectedLog,
    setMaximizeTableColumn,
    expandTable,
    showConfirmQuestionValidation,
    handleShowMarkQuestionModal,
    validationDetails,
    setShowConfirmQuestionValidation,
    handleValidateQuestion,
    modalIsLoading,
    sessionData,
    allQuestions,
    tableData,
    categoryFilter,
    graphSpinner,
    isPageReady,
    selectedCategories,
    showFilterDropdown,
    setShowFilterDropdown,
    filters,
    handleChangeFilters,
    setFilters,
    handlePanelChange,
    sequenceActivePannel,
    selectedPanelDetails,
    isMobileView,
    smallFont,
  } = useAnalytics();
  const componentRef = useRef();
  const screens = useBreakpoint();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `margin: 20px`,
  });
  const { startDate, endDate } = dateFilter;
  const rangePickerStyle = {
    width: screens.xs ? '100%' : 'auto',
    padding: screens.xs ? '8px 12px' : 'auto',
  };

  const dropdownStyle = {
    width: screens.xs ? '100%' : 'auto',
  };

  const questionDropdownMenu = (question, id, validation) => (
    <Menu>
      <Menu.Item
        icon={<FolderAddOutlined />}
        onClick={() => handleShowAddTestSuiteModal(question)}
      >
        {'Add to Test Suite'}
      </Menu.Item>
      {validation !== 1 && (
        <Menu.Item
          icon={<CheckOutlined />}
          onClick={() => handleShowMarkQuestionModal(id, 1)}
        >
          {'Mark as Correct'}
        </Menu.Item>
      )}
      {validation !== 0 && (
        <Menu.Item
          icon={<CloseOutlined />}
          onClick={() => handleShowMarkQuestionModal(id, 0)}
        >
          {'Mark as Incorrect'}
        </Menu.Item>
      )}
    </Menu>
  );

  const getTablePaginatedTotal = () => {
    if (
      [QUESTION_LOGS, PERFORMANCE_METRICS_DEFAULT_ANSWERS].includes(
        selectedTableView
      )
    ) {
      return allQuestions?.length;
    } else if (selectedTableView === MOST_ASKED_QUESTION) {
      return tableData?.mostAskedQuestions?.length;
    } else if (selectedTableView === QUESTION_WITH_POSITIVE_FEEDBACK) {
      return tableData?.positiveFeedbackQuestions?.length;
    } else if (
      [
        QUESTION_WITH_NEGATIVE_FEEDBACK,
        PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS,
      ].includes(selectedTableView)
    ) {
      return tableData?.negativeFeedbackQuestions?.length;
    } else if (selectedTableView === INTENTS) {
      return tableData?.intents?.length;
    } else if (selectedTableView === CORRECT_QUESTIONS) {
      return tableData.correctQuestions?.length;
    } else if (selectedTableView === INCORRECT_QUESTIONS) {
      return tableData.incorrectQuestions?.length;
    }
    return 0;
  };

  const getCurrentTableViewTotalQuestions = () => {
    switch (selectedTableView) {
      case PERFORMANCE_METRICS_DEFAULT_ANSWERS:
        return aggregations.answerCountEachType?.defaultAnswerCount;
      case CORRECT_QUESTIONS:
        return aggregations.totalCorrectValidation;
      case INCORRECT_QUESTIONS:
        return aggregations.totalIncorrectValidation;
      case MOST_ASKED_QUESTION:
        return aggregations.tableData.mostAskedQuestionsData?.length || 0;
      case INTENTS:
        return aggregations.tableData.intentsData?.length || 0;
      case QUESTION_WITH_NEGATIVE_FEEDBACK:
      case PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS:
        return aggregations.dislikes;
      case QUESTION_WITH_POSITIVE_FEEDBACK:
        return aggregations.likes;
      case QUESTION_LOGS:
        return aggregations.totalQuestions;
      default:
        return 0;
    }
  };

  const allQuestionsTableData = [
    {
      title: '',
      align: 'right',
      dataIndex: 'maximizeTable',
      width: '2%',
      render: (value, row) => (
        <ArrowsAltOutlined onClick={() => expandTable(row)} />
      ),

      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    },
    {
      title: 'Sessions',
      align: 'center',
      dataIndex: 'sessionID',
      width: '20%',
      sorter: {},
      filters: sessionFilters,
      filteredValue: sessionFilteredInfo || null,
      onFilter: (value, row) => {
        if (value === 'undefined') {
          return !row.sessionID;
        } else {
          return row.sessionID && row.sessionID.includes(value);
        }
      },
      render: (sessionID, row) => {
        if (sessionID) {
          return (
            <Button
              value="View Session"
              onClick={() => handleShowSessionCallbacks(row)}
              variant="link"
              style={smallFont}
            />
          );
        } else {
          return 'N/A';
        }
      },
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    },
    {
      title: `Question (${getTablePaginatedTotal()})`,
      dataIndex: 'question',
      width: '40%',
      render: (question, questionDetails) => {
        return (
          <StyledQuestion>
            <span>{question}</span>
            <Dropdown
              overlay={questionDropdownMenu(
                question,
                questionDetails.questionID,
                questionDetails.validation
              )}
              placement="bottomLeft"
              trigger={['click']}
            >
              <StyledMore />
            </Dropdown>
          </StyledQuestion>
        );
      },
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    },
    {
      title: 'Visitor ID',
      align: 'center',
      dataIndex: 'visitorID',
      sorter: {},
      render: visitorID => (
        <div style={smallFont}>{!!visitorID ? visitorID : 'N/A'}</div>
      ),
      responsive: ['xxl', 'xl', 'lg'],
    },
    {
      title: 'Date',
      align: 'center',
      dataIndex: 'date',
      width: '20%',
      defaultSortOrder: 'ascend',
      sorter: {},
      render: date => {
        const localDate = UTCToLocal(new Date(date));
        return <div style={smallFont}>{getLocalTimeString(localDate)}</div>;
      },
      responsive: ['xxl', 'xl', 'lg'],
    },
    {
      title: 'Channel',
      align: 'center',
      dataIndex: 'channel',
      sorter: {},
      onFilter: (value, row) => {
        if (value === 'undefined') {
          return !row.channel;
        } else {
          const matchChannel = new RegExp(`^${value}$`);
          return row?.channel && row?.channel.match(matchChannel);
        }
      },
      render: channel => <div style={smallFont}>{channel || 'N/A'}</div>,
      responsive: ['xxl', 'xl', 'lg'],
    },
    {
      title: 'Category',
      align: 'center',
      dataIndex: 'category',
      render: category => {
        const categoryName =
          category && typeof category === 'string'
            ? category
            : isAnObject(category) && category.name
            ? category?.name
            : 'N/A';
        return <div style={smallFont}>{categoryName}</div>;
      },
      responsive: ['xxl', 'xl', 'lg'],
    },
  ];

  const allQuestionsExpandedTableData = [
    {
      title: 'Fields',
      align: 'center',
      dataIndex: 'field',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.field, b.field),
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      align: 'center',
      width: '80%',
    },
  ];

  const mostAskedQuestionsTableData = [
    {
      title: 'Count',
      align: 'center',
      dataIndex: 'count',
      width: '12%',
      sorter: (a, b) => b.count - a.count,
    },
    {
      title: `Question (${getTablePaginatedTotal()})`,
      dataIndex: 'question',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.question, b.question),
      },
    },
  ];

  const intentsTableData = [
    {
      title: 'Count',
      align: 'center',
      dataIndex: 'count',
      width: '12%',
      sorter: (a, b) => b.count - a.count,
    },
    {
      title: `Intents (${getTablePaginatedTotal()})`,
      dataIndex: 'intents',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.intents, b.intents),
      },
    },
  ];

  const filteredColumns = allQuestionsTableData.filter(col => {
    if (!col.responsive) {
      return true;
    }
    return col.responsive.some(bp => screens[bp]);
  });

  const expandable = {
    // eslint-disable-next-line react/display-name
    expandedRowRender: data => (
      <StyledExpandedRow>
        <span>
          <strong>{'Answer'}</strong> &nbsp;
        </span>
        <span>
          {'Type'}: &nbsp; {startCase(data?.answer?.type)}
        </span>
        <StyledShowAnswer>
          <span>
            {'Source'}: &nbsp; {data?.answer?.text}
          </span>
        </StyledShowAnswer>
        <span>{'Display Answer'}:</span>
        <AnswerRenderer
          showDisplayAnswer
          showInChatBubble
          answer={data.answer}
        />
      </StyledExpandedRow>
    ),
  };

  const config = useMemo(() => {
    return {
      data: chartData,
      xField: 'date',
      yField: 'count',
      xAxis: {
        label: {
          autoRotate: false,
        },
      },
    };
  }, [chartData]);

  const isPerformanceMetricsCardPanelActive = cardName => {
    if (isPerformanceMetricsView) {
      switch (selectedTableView) {
        case PERFORMANCE_METRICS_DEFAULT_ANSWERS:
          return cardName === PERFORMANCE_METRICS_DEFAULT_ANSWERS;
        case PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS:
          return cardName === PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS;
        case PERFORMANCE_METRICS_INCORRECT_QUESTIONS:
          return cardName === PERFORMANCE_METRICS_INCORRECT_QUESTIONS;
        default:
          return cardName === PERFORMANCE_METRICS_DEFAULT_ANSWERS;
      }
    }
  };

  const SelectMenuList = (
    <Menu onClick={item => handleOnSelect(item.key)}>
      {(tableViewList || []).map(item => (
        <Menu.Item key={item.key}>{item.value}</Menu.Item>
      ))}
    </Menu>
  );

  const renderTable = () => {
    switch (selectedTableView) {
      case PERFORMANCE_METRICS_DEFAULT_ANSWERS:
      case QUESTION_LOGS:
        return (
          <Table
            loading={tableSpinner}
            columns={filteredColumns}
            dataSource={tableData?.allQuestions}
            onChange={handleTableChange}
            expandable={expandable}
            pagination={false}
            showSorterTooltip={{
              title: 'Click to sort',
              placement: 'topRight',
            }}
          />
        );

      case MOST_ASKED_QUESTION:
        return (
          <Table
            loading={tableSpinner}
            columns={mostAskedQuestionsTableData}
            dataSource={tableData?.mostAskedQuestions}
            pagination={false}
            showSorterTooltip={{
              title: 'Click to sort ascending',
              placement: 'topRight',
            }}
          />
        );

      case INTENTS:
        return (
          <Table
            loading={tableSpinner}
            columns={intentsTableData}
            dataSource={tableData?.intents}
            pagination={false}
            showSorterTooltip={{
              title: 'Click to sort ascending',
              placement: 'topRight',
            }}
          />
        );

      case QUESTION_WITH_POSITIVE_FEEDBACK: {
        return (
          <Table
            loading={tableSpinner}
            columns={filteredColumns}
            dataSource={tableData?.positiveFeedbackQuestions}
            pagination={false}
            showSorterTooltip={{
              title: 'Click to sort ascending',
              placement: 'topRight',
            }}
          />
        );
      }

      case PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS:
      case QUESTION_WITH_NEGATIVE_FEEDBACK: {
        return (
          <Table
            loading={tableSpinner}
            columns={filteredColumns}
            dataSource={tableData?.negativeFeedbackQuestions}
            pagination={false}
            showSorterTooltip={{
              title: 'Click to sort ascending',
              placement: 'topRight',
            }}
          />
        );
      }

      case CORRECT_QUESTIONS: {
        return (
          <Table
            loading={tableSpinner}
            columns={filteredColumns}
            dataSource={tableData?.correctQuestions}
            pagination={false}
            showSorterTooltip={{
              title: 'Click to sort ascending',
              placement: 'topRight',
            }}
          />
        );
      }

      case PERFORMANCE_METRICS_INCORRECT_QUESTIONS:
      case INCORRECT_QUESTIONS: {
        return (
          <Table
            loading={tableSpinner}
            columns={filteredColumns}
            dataSource={tableData?.incorrectQuestions}
            pagination={false}
            showSorterTooltip={{
              title: 'Click to sort',
              placement: 'topRight',
            }}
          />
        );
      }

      default:
        return (
          <Table
            loading={tableSpinner}
            columns={filteredColumns}
            dataSource={tableData?.allQuestions}
            onChange={handleTableChange}
            expandable={expandable}
            pagination={false}
            showSorterTooltip={{
              title: 'Click to sort',
              placement: 'topRight',
            }}
          />
        );
    }
  };

  const renderExpandedTable = (key, value) => (
    <Table
      key={key}
      columns={allQuestionsExpandedTableData}
      dataSource={value}
      pagination={false}
      showSorterTooltip={{
        title: 'Click to sort',
        placement: 'topRight',
      }}
    />
  );

  const LogMoreDetails = () => {
    const sequenceList = selectedLog?.find(
      expandedColumns => expandedColumns?.field === 'Sequence'
    );

    return (
      <StyledSequenceContainer>
        <Collapse
          accordion={true}
          activeKey={sequenceActivePannel}
          onChange={e => handlePanelChange(e, sequenceList?.value)}
        >
          <Panel header="General Info" key="generalInfo">
            {renderExpandedTable(
              'generalInfo',
              isMobileView
                ? selectedLog?.filter(
                    expandedColumns => expandedColumns?.field !== 'Sequence'
                  )
                : selectedLog?.filter(
                    expandedColumns =>
                      expandedColumns?.field !== 'Sequence' &&
                      !expandedColumns?.forMobileViewOnly
                  )
            )}
          </Panel>
          {sequenceList?.value?.map(sequenceValue => (
            <Panel
              header={
                sequenceValue?.type?.charAt(0).toUpperCase() +
                sequenceValue?.type?.slice(1)
              }
              key={sequenceValue.type}
            >
              {renderExpandedTable(sequenceValue.type, selectedPanelDetails)}
            </Panel>
          ))}
        </Collapse>
      </StyledSequenceContainer>
    );
  };

  const ExportButtonMenu = () => {
    return (
      <Menu>
        <Menu.Item key={'csv'} onClick={handleCSVDownloadQlogs}>
          {'CSV'}
        </Menu.Item>
        <Menu.Item
          key={'xlsx'}
          onClick={handleXLSXDownloadQlogs}
          hidden={!EXPORT_TO_EXCEL}
        >
          {'Excel (.xlsx)'}
        </Menu.Item>
      </Menu>
    );
  };

  const tagRender = props => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={'blue'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const FilterDropdownMenu = (
    <>
      <Menu onClick={handleChangeFilters}>
        {FILTER_SELECT_OPTIONS.map(filterItem => (
          <Menu.Item key={filterItem.key}>
            <Checkbox checked={filters?.includes(filterItem.value)}>
              {filterItem.label}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu>
      <StyledFlexColumn style={{ background: '#fff' }}></StyledFlexColumn>
    </>
  );

  return (
    <>
      <Spin spinning={!isPageReady || pageLoading} tip="Just a moment...">
        <StyledRoot ref={componentRef}>
          <Title
            text="Analytics"
            type="secondary"
            hasPrintBtn
            onPrint={handlePrint}
          />
          {isFreeUser ? (
            <LimitationBanner
              icon={<LockTwoTone twoToneColor={cssVariables.primaryBlue} />}
              subtitle={`Upgrade to view how your visitors interact with your chatbot. `}
              title={`Keep track of your users!`}
            />
          ) : (
            <>
              <StyledMetric>
                <StyledMetricPeriodFilter></StyledMetricPeriodFilter>
              </StyledMetric>

              <Row gutter={[8, 8]}>
                <StyledDateFilterLabel xs={22} md={7} xl={5} xxl={4}>
                  {'Question logs showing results from'}
                </StyledDateFilterLabel>
                <Col xs={!filters?.length ? 14 : 22} md={8} xl={5} xxl={4}>
                  <DateFilter
                    style={rangePickerStyle}
                    onChange={handleDateFilterChange}
                    getPopupContainer={trigger => trigger.parentNode}
                    dropdownClassName="responsive-range-picker-dropdown"
                    popupStyle={dropdownStyle}
                  />
                </Col>
                <Col xs={4} md={2} xl={1}>
                  {!filters?.length ? (
                    <Button
                      value="Go"
                      size="medium"
                      background="#fff"
                      endIcon={<ArrowRightOutlined />}
                      onClick={handleChangeDataFilterButton}
                      loading={tableSpinner}
                    />
                  ) : null}
                </Col>
                <Col xs={5} md={3}>
                  {!isPerformanceMetricsView &&
                  allQuestions.length &&
                  !filters?.length ? (
                    <Dropdown
                      overlay={FilterDropdownMenu}
                      trigger={['hover', 'click']}
                      onVisibleChange={() =>
                        setShowFilterDropdown(!showFilterDropdown)
                      }
                    >
                      <StyledDatePickerButton
                        value={`Filter`}
                        startIcon={<FilterFilled color="white" />}
                        variant="link"
                      />
                    </Dropdown>
                  ) : null}
                </Col>
              </Row>

              <StyledFilterWrapper>
                {filters?.length ? (
                  <Col xs={22} md={7}>
                    <StyledFiltersSelect
                      options={['category', 'integration']}
                      label="Filter by"
                      mode="multiple"
                      optionLabelProp="label"
                      value={filters}
                      allowClear
                      onSelect={e => setFilters([...filters, e])}
                      onDeselect={e => setFilters(filters.filter(f => f !== e))}
                      onClear={() => setFilters([])}
                      placeholder="Filter by Category"
                      bordered={false}
                      selectedKeys={filters}
                      tagRender={tagRender}
                    />
                  </Col>
                ) : null}
                {filters?.includes('category') ? (
                  <Col xs={22} md={7}>
                    <StyledFiltersSelect
                      options={categoryFilter}
                      value={selectedCategories.map(cat => cat.value)}
                      label="Category"
                      mode="multiple"
                      optionLabelProp="label"
                      allowClear
                      onChange={handleChangeCategories}
                      size="medium"
                      placeholder="Select category filter"
                      bordered={false}
                      tagRender={tagRender}
                    />
                  </Col>
                ) : null}
                {filters?.includes('integration') ? (
                  <Col xs={22} md={7}>
                    <StyledFiltersSelect
                      options={integrationFilterOptions}
                      mode="multiple"
                      optionLabelProp="value"
                      allowClear
                      onChange={handleChangeIntegrationSelection}
                      label="Integration"
                      size="medium"
                      bordered={false}
                      placeholder="Select Integration Channel filter"
                      tagRender={tagRender}
                    />
                  </Col>
                ) : null}
                {filters?.length ? (
                  <Col xs={22} md={3}>
                    <Button
                      value="Go"
                      size="medium"
                      background="#fff"
                      endIcon={<ArrowRightOutlined />}
                      onClick={handleChangeDataFilterButton}
                      loading={tableSpinner}
                      full={isMobileView}
                    />
                  </Col>
                ) : null}
              </StyledFilterWrapper>

              {!isPerformanceMetricsView ? (
                <CardsWrapper gutter={[8, 8]}>
                  <Col xs={12} md={8} lg={4}>
                    <ToolTip title={TOOLTIPS.SESSIONS}>
                      <DataSummaryCard noFooter>
                        <div>{`# of sessions`}</div>
                        <h1>{aggregations?.sessionCount || 0}</h1>
                        <div></div>
                      </DataSummaryCard>
                    </ToolTip>
                  </Col>
                  <Col xs={12} md={8} lg={4}>
                    <ToolTip title={TOOLTIPS.VISITORS}>
                      <DataSummaryCard noFooter>
                        <div>{`# of visitors`}</div>
                        <h1>{aggregations?.visitorCount || 0}</h1>
                        <div></div>
                      </DataSummaryCard>
                    </ToolTip>
                  </Col>
                  <Col xs={12} md={8} lg={4}>
                    <ToolTip title={TOOLTIPS.QUESTIONS_ASKED}>
                      <DataSummaryCard noFooter>
                        <div>{`# of questions asked`}</div>
                        <h1>{aggregations?.totalQuestions || 0}</h1>
                        <div></div>
                      </DataSummaryCard>
                    </ToolTip>
                  </Col>
                  <Col xs={12} md={8} lg={4}>
                    <DataSummaryCard clickableFooter>
                      <div>{'Audit Results'}</div>
                      <div>
                        <ToolTip title={TOOLTIPS.VALIDATIONS_CORRECT}>
                          <h1 onClick={() => handleClickedQuality(1)}>
                            {aggregations?.totalCorrectValidation || 0}
                          </h1>
                        </ToolTip>
                        <ToolTip title={TOOLTIPS.VALIDATIONS_INCORRECT}>
                          <h1 onClick={() => handleClickedQuality(0)}>
                            {aggregations?.totalIncorrectValidation || 0}
                          </h1>
                        </ToolTip>
                      </div>
                      <div>
                        <ToolTip title={TOOLTIPS.CORRECT_QUESTIONS_FILTER_ICON}>
                          <CheckCircleFilled
                            style={{ color: cssVariables.success }}
                            onClick={() => handleClickedQuality(1)}
                          />
                        </ToolTip>
                        <ToolTip
                          title={TOOLTIPS.INCORRECT_QUESTIONS_FILTER_ICON}
                        >
                          <CloseCircleFilled
                            style={{ color: cssVariables.red10 }}
                            onClick={() => handleClickedQuality(0)}
                          />
                        </ToolTip>
                      </div>
                    </DataSummaryCard>
                  </Col>
                  <Col xs={12} md={8} lg={4}>
                    <ToolTip title={TOOLTIPS.FALLBACK_ANSWER}>
                      <DataSummaryCard>
                        <div>{`# of fallback answers called`}</div>
                        <h1>
                          {aggregations?.answerCountEachType
                            ?.defaultAnswerCount || 0}
                        </h1>
                        <div>{`${
                          aggregations?.containmentRate || '0%'
                        } Containment Rate`}</div>
                      </DataSummaryCard>
                    </ToolTip>
                  </Col>
                  <Col xs={12} md={8} lg={4}>
                    <ToolTip title={TOOLTIPS.CSAT_SCORE}>
                      <DataSummaryCard
                        feedback={aggregations?.feedbackSummary || 'neutral'}
                      >
                        <div>{`CSAT Score`}</div>
                        <h1>
                          <SmileOutlined />
                        </h1>
                        <div>{aggregations?.csatScore}</div>
                      </DataSummaryCard>
                    </ToolTip>
                  </Col>
                </CardsWrapper>
              ) : (
                <KeyMetricsCardsWrapper>
                  <>
                    <ToolTip
                      title={TOOLTIPS.DEFAULT_ANSWERT}
                      onClick={() =>
                        handleOnSelect(PERFORMANCE_METRICS_DEFAULT_ANSWERS)
                      }
                    >
                      <KeyMetricsSummaryCard
                        className={{
                          active: isPerformanceMetricsCardPanelActive(
                            PERFORMANCE_METRICS_DEFAULT_ANSWERS
                          ),
                        }}
                      >
                        <div>{`# of default answer`}</div>
                        <h1>
                          {aggregations?.answerCountEachType
                            ?.defaultAnswerCount || 0}
                        </h1>
                      </KeyMetricsSummaryCard>
                    </ToolTip>
                    <ToolTip
                      title={TOOLTIPS.VALIDATIONS_INCORRECT}
                      onClick={() =>
                        handleOnSelect(PERFORMANCE_METRICS_INCORRECT_QUESTIONS)
                      }
                    >
                      <KeyMetricsSummaryCard
                        className={{
                          active: isPerformanceMetricsCardPanelActive(
                            PERFORMANCE_METRICS_INCORRECT_QUESTIONS
                          ),
                        }}
                      >
                        <div>{`# of incorrect audit result`}</div>
                        <h1>{aggregations?.totalIncorrectValidation || 0}</h1>
                      </KeyMetricsSummaryCard>
                    </ToolTip>
                    <ToolTip
                      title={TOOLTIPS.NEGATIVE_FEEDBACK}
                      onClick={() =>
                        handleOnSelect(PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS)
                      }
                    >
                      <KeyMetricsSummaryCard
                        className={{
                          active: isPerformanceMetricsCardPanelActive(
                            PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS
                          ),
                        }}
                      >
                        <div>{`# of negative feedback`}</div>
                        <h1>{aggregations?.dislikes || 0}</h1>
                      </KeyMetricsSummaryCard>
                    </ToolTip>
                  </>
                </KeyMetricsCardsWrapper>
              )}
              <Spin
                tip="Fetching logs data..."
                spinning={isPageReady && graphSpinner}
              >
                <ChartWrapper>
                  <h4>{`Total questions asked for ${moment(startDate).format(
                    'MMMM D, YYYY'
                  )} - ${moment(endDate).format('MMMM D, YYYY')}`}</h4>
                  <StyledColumnChart {...config} />
                </ChartWrapper>
              </Spin>
              <StyledQuestions>
                <RowSpaceBetween>
                  <StyledGhostDropdown
                    overlay={SelectMenuList}
                    trigger={['click']}
                  >
                    <Button
                      variant="link"
                      value={
                        selectedTableView
                          ? startCase(selectedTableView)
                          : isPerformanceMetricsView
                          ? 'Default Answer'
                          : 'All Questions'
                      }
                      endIcon={<CaretDownOutlined />}
                    />
                  </StyledGhostDropdown>
                  <Dropdown overlay={ExportButtonMenu} trigger="hover">
                    <Button
                      startIcon={<DownloadOutlined />}
                      value={'Export'}
                      variant="success"
                    />
                  </Dropdown>
                </RowSpaceBetween>
                <Spin spinning={isPageReady && tableSpinner && !graphSpinner}>
                  <StyledQuestionsList id="table">
                    <Row gutter={[8, 24]}>
                      <Col span={24}>{renderTable()} </Col>
                      <Col xl={8}>
                        <Space
                          direction="horizontal"
                          style={{ width: '100%' }}
                        />
                      </Col>
                      {showPagination && (
                        <Col
                          xs={24}
                          sm={24}
                          md={16}
                          lg={16}
                          xl={16}
                          style={{ textAlign: 'right' }}
                        >
                          <Pagination
                            defaultCurrent={1}
                            current={params.page}
                            total={getCurrentTableViewTotalQuestions()}
                            defaultPageSize={params.size}
                            onChange={handleChangePageNumber}
                          />
                        </Col>
                      )}
                    </Row>
                  </StyledQuestionsList>
                </Spin>
              </StyledQuestions>
              {showAddQuestionToTestSuiteModal && (
                <AddQuestionToTestSuiteModal
                  show={showAddQuestionToTestSuiteModal}
                  question={questionForTestSuite}
                  onCloseModal={handleCloseAddTestSuiteModal}
                />
              )}
              {showModal ? (
                <CallbackModal
                  show={showModal}
                  questions={sessionData}
                  onClose={handleCloseSessionCallbacks}
                  sessionID={!!sessionData?.length && selectedSessionID}
                />
              ) : null}
              {!!sessionData?.length && !!selectedInteractionID && (
                <FeedbackModal
                  interactionID={selectedInteractionID}
                  questions={sessionData}
                  onClose={handleCloseInteractionFeedback}
                />
              )}
              {showConfirmQuestionValidation && (
                <ConfirmQuestionValidation
                  onCancel={() => setShowConfirmQuestionValidation(false)}
                  modalTitle="Question Validation"
                  show={showConfirmQuestionValidation}
                  loading={modalIsLoading}
                  onOk={handleValidateQuestion}
                  validationDetails={validationDetails}
                />
              )}
            </>
          )}
        </StyledRoot>{' '}
      </Spin>
      <Drawer
        width={isMobileView ? '100%' : '40%'}
        title={
          <StyledExpandedTableTitle>
            {'Expanded Table'}
          </StyledExpandedTableTitle>
        }
        placement="right"
        onClose={() => setMaximizeTableColumn(false)}
        getContainer={false}
        visible={maximizeTableColumn}
        style={{ position: 'absolute' }}
      >
        <LogMoreDetails />
      </Drawer>
    </>
  );
};

Analytics.propTypes = {
  handleShowAddTestSuiteModal: PropTypes.func,
  isPageReady: PropTypes.bool,
};

export default Analytics;
