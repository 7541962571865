import React from 'react';
import styled from 'styled-components';
import Input from 'components/Input';
import { Button } from 'antd';
import Alert from 'components/Alert';
import ROUTES from 'constants/routes';
import Logo from 'assets/images/zeroshotbot_black.png';
import Header from 'components/Header';
import { cssVariables } from 'styles/root';
import useVerifyEmail from './hooks';
import CountDownRedirectModal from 'components/Modals/RedirectModal';

const Wrapper = styled.div`
  width: 472px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  display: flex;
  height: 100vh;
  justify-content: center;
  text-align: center;
  row-gap: 20px;

  form {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  h1 {
    text-align: center;
    font-size: 16px;
    font-weight: ${cssVariables.font.bold};
    color: ${cssVariables.green1};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledLogo = styled.img`
  max-width: 300px;
  margin-bottom: 30px;
  align-self: center;
`;

const VerifyEmail = () => {
  const {
    code,
    error,
    key,
    loading,
    state,
    verified,
    handleChangeCode,
    handleVerify,
    history,
    isExternalPage,
  } = useVerifyEmail();

  return (
    <>
      <Header isLogout />
      <Wrapper>
        {!key && !verified ? (
          <>
            <div>{`An activation code has been sent to your email: `}</div>
            {state && state.email && <span>{state.email}</span>}
            {error && <Alert type="error" message={error} />}
            <form autocomplete="off" onSubmit={handleVerify}>
              <div>
                <Input
                  required
                  type="text"
                  value={code}
                  onChange={handleChangeCode}
                  autocomplete="off"
                />
              </div>
              <StyledButton
                htmlType="submit"
                variant="primary"
                size="large"
                required
                loading={loading}
              >{`Verify`}</StyledButton>
            </form>
          </>
        ) : null}
        {verified ? (
          <>
            <StyledLogo src={Logo} alt="Logo" />
            <h1>Success! Your account has been successfully verified.</h1>
            <StyledButton
              htmlType="button"
              variant="primary"
              size="large"
              onClick={() =>
                history.push({
                  pathname: isExternalPage
                    ? ROUTES.EXTERNAL_LOGIN
                    : ROUTES.LOGIN,
                  state: { email: state && state.email ? state.email : null },
                })
              }
            >
              {`Login to build a bot!`}
            </StyledButton>
            <CountDownRedirectModal
              page="verification"
              redirectURL={
                isExternalPage ? ROUTES.EXTERNAL_LOGIN : ROUTES.LOGIN
              }
              email={state && state.email ? state.email : null}
            />
          </>
        ) : null}
      </Wrapper>
    </>
  );
};

VerifyEmail.propTypes = {};

export default VerifyEmail;
