import { StyledChatWidgetLauncher } from './ChatWidgetLauncher.style';
import useSelector from 'store/useSelector';
import {
  isMobileIntegrationSelector,
  widgetHeightSelector,
  widgetIconColorSelector,
  widgetThemeColorSelector,
} from 'selectors/bot/integration';

const ChatWidgetLauncher = () => {
  const widgetThemeColor = useSelector(widgetThemeColorSelector);
  const iconColor = useSelector(widgetIconColorSelector);
  const height = useSelector(widgetHeightSelector);
  const isMobile = useSelector(isMobileIntegrationSelector);
  return (
    <StyledChatWidgetLauncher
      integrationDataColor={widgetThemeColor}
      isMobile={isMobile}
      height={height}
      className="chatBotWidgetLauncher"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        viewBox="-16 -24 380 460"
      >
        <polygon
          points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3     214.2,178.5   "
          fill={iconColor}
        />
      </svg>
    </StyledChatWidgetLauncher>
  );
};

export default ChatWidgetLauncher;
