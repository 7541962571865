import React from 'react';
import PropTypes from 'prop-types';
import { Col, Dropdown, Menu, Row } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ThemeProvider } from 'styled-components';
import { getTimeDifference } from 'utils/dates';
import {
  StyledWrapper,
  StyledContentContainer,
  StyledInfoContainer,
  StyledInfo,
  StyledInfoName,
  StyledInfoQuestions,
  StyledLastModified,
  StyledMore,
  StyledTestStatusGraph,
  StyledTestStatusCount,
  StyledTestStatusPassed,
  StyledTestStatusFailed,
} from './TestSuite.styles';

const DISABLE_UNTESTED_AND_OUTDATED = true;

const TestSuite = ({
  testSuite,
  handleShowTestCases,
  handleEditTestSuite,
  handleDeleteTestSuite,
  isMobileView,
}) => {
  const theme = {
    testStatus: testSuite?.lastTestStatus,
    testCount: testSuite?.testCount,
    disableOutdatedAndUntested: DISABLE_UNTESTED_AND_OUTDATED,
  };

  const moreMenu = (
    <Menu>
      <Menu.Item
        icon={<EditOutlined />}
        onClick={() => handleEditTestSuite(testSuite)}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        icon={<DeleteOutlined />}
        onClick={() => handleDeleteTestSuite(testSuite?.jid)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledWrapper>
      <StyledContentContainer
        xs={{ span: 20 }}
        sm={{ span: 22 }}
        onClick={() => handleShowTestCases(testSuite)}
      >
        <Row>
          <Col md={{ span: 24 }} lg={{ span: 8 }}>
            <StyledInfo>
              <StyledInfoName>{testSuite?.name}</StyledInfoName>
              <StyledInfoQuestions>
                {testSuite?.testCount} Tests
              </StyledInfoQuestions>
            </StyledInfo>
          </Col>
          <Col md={{ span: 24 }} lg={{ span: 10 }}>
            {testSuite?.lastTestStatus && (
              <div>
                <StyledTestStatusGraph isMobileView={isMobileView}>
                  <ThemeProvider theme={theme}>
                    <StyledTestStatusPassed />
                    <StyledTestStatusFailed />
                    {/* COMMENTED FOR FUTURE USE OF OUTDATED AND UNTESTED */}
                    {/* <StyledTestStatusOutdated />
                  <StyledTestStatusUntested /> */}
                  </ThemeProvider>
                </StyledTestStatusGraph>
                <StyledTestStatusCount>
                  {testSuite?.lastTestStatus?.success} Passed{' | '}
                  {testSuite?.lastTestStatus?.fail} Failed
                  {/* COMMENTED FOR FUTURE USE OF OUTDATED AND UNTESTED */}
                  {/* {!DISABLE_UNTESTED_AND_OUTDATED &&
                  !!testSuite?.lastTestStatus?.outdated &&
                  ' | ' + testSuite.lastTestStatus?.outdated + ' Edited'}
                {!DISABLE_UNTESTED_AND_OUTDATED &&
                  !!testSuite?.lastTestStatus?.unknown &&
                  ' | ' + testSuite.lastTestStatus?.unknown + ' Untested'} */}
                </StyledTestStatusCount>
              </div>
            )}
          </Col>
          <Col md={{ span: 24 }} xl={{ span: 6 }}>
            <StyledLastModified>
              Last modified {getTimeDifference(testSuite?.lastModified)}
            </StyledLastModified>
          </Col>
        </Row>
      </StyledContentContainer>
      <StyledContentContainer xs={{ span: 4 }} sm={{ span: 2 }}>
        <Dropdown overlay={moreMenu} placement="bottomLeft" trigger={['click']}>
          <StyledMore
            onClick={e => {
              e.stopPropagation();
            }}
          />
        </Dropdown>
      </StyledContentContainer>
    </StyledWrapper>
  );
};

TestSuite.propTypes = {
  testSuite: PropTypes.object.isRequired,
  handleDeleteTestSuite: PropTypes.func.isRequired,
  handleEditTestSuite: PropTypes.func.isRequired,
  handleShowTestCases: PropTypes.func,
  isMobileView: PropTypes.bool,
};

export { TestSuite };
