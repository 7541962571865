import { websitesSelector } from 'selectors/bot/answers';
import useSelector from 'store/useSelector';

const useWebsite = () => {
  const allWebsite = useSelector(websitesSelector);

  return {
    allWebsite,
  };
};

export default useWebsite;
