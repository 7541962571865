import { useState } from 'react';
import { useGlobalGoogle } from './useGlobalGoogle';
import { authorizedGoogleAccount } from 'services/auth.service';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
const SCOPE = process.env.REACT_APP_GOOGLE_LOGIN_SCOPE;
const REDIRECT_URI = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URI;

function useGoogleAuthentication(
  setSignInToGoogle,
  setEmail,
  setPassword,
  setError,
  setSpinnerLabel
) {
  const [tokenClient, setTokenClient] = useState({});
  const url = new URL(window.location.href);
  const urlCode = url.searchParams.get('code');

  const authorizeGoogleAccount = async () => {
    try {
      const response = await authorizedGoogleAccount(
        decodeURIComponent(urlCode)
      );
      if (response.status === 200) {
        if (response.data.type === 'BINDING') {
          setSpinnerLabel('Linking account with Google...');
        } else if (response.data.type === 'REGISTRATION') {
          setSpinnerLabel('Creating an account with Google...');
        } else {
          setSpinnerLabel('Signing in with Google...');
        }
        setTokenClient({ ...tokenClient, user: response.data });
      } else {
        setSignInToGoogle(false);
        return setError(
          (response && response.message) ||
            'We encountered unexpected error while signing in with Google'
        );
      }
    } catch (err) {
      setSignInToGoogle(false);
      return handleError(err);
    }
  };

  const initializeGoogleClient = () => {
    try {
      /* global google */
      return google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: SCOPE,
        ux_mode: 'redirect',
        redirect_uri: REDIRECT_URI,
      });
    } catch (err) {
      setSignInToGoogle(false);
      return handleError(err);
    }
  };

  const onloadAuth = () => {
    try {
      setTokenClient(initializeGoogleClient());
      handleExistingCodeURL();
    } catch (err) {
      setSignInToGoogle(false);
      return handleError(err);
    }
  };

  const refreshAuth = () => {
    try {
      const googleAuth = initializeGoogleClient();
      setTokenClient(googleAuth);
      handleExistingCodeURL();
      return googleAuth.requestCode();
    } catch (err) {
      setSignInToGoogle(false);
      return handleError(err);
    }
  };

  const handleError = error => {
    setSignInToGoogle(false);
    return setError(
      (error && error.message) ||
        'We encountered unexpected error while signing in'
    );
  };

  const handleExistingCodeURL = () => {
    try {
      if (urlCode) {
        setSignInToGoogle(true);
        setEmail(null);
        setPassword(null);
        setTimeout(async () => await authorizeGoogleAccount(), 300);
      }
    } catch (err) {
      setSignInToGoogle(false);
      return handleError(err);
    }
  };

  useGlobalGoogle(onloadAuth);
  return {
    tokenClient,
    refreshAuth,
  };
}

export { useGoogleAuthentication };
