import styled from 'styled-components';
import { Collapse, Col, InputNumber, Avatar, Radio, Row } from 'antd';
import Button from 'components/Button';
import Select from 'components/Select';
import { cssVariables } from 'styles/root';
import {
  StyledFlexLeftColumn,
  StyledFlexRowLeft,
} from 'styles/GenericStyledComponents';

const { Panel } = Collapse;

export const StyledSimulatorWrapper = styled(Row)`
  width: 100%;
`;

export const StyledIntegrationSteps = styled(Col)`
  width: 100%;
  & form {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .ant-collapse-header .ant-collapse-arrow {
    padding-top: 0 !important;
  }
`;

export const StyledCollapsiblePanel = styled(Panel)`
  & .ant-collapse-content-box {
    padding: ${props => (props.botAppearance ? '2% 5% 5% 5%' : '2% 5%')};
  }

  & .ant-collapse-arrow {
    top: 50% !important;

    & svg {
      font-size: 25px;
    }
  }

  & label {
    padding-top: 17px;
  }

  & .ant-layout {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  & .rect-wrapper {
    padding: 10px;
    border-radius: 2px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    align-self: center;
    font-weight: ${cssVariables.font.bold};
    word-wrap: break-all;
    margin-top: 25px;
    box-shadow: 1px 1px 0.5px 0px #b5b5b5;
  }

  & .formFieldHeader {
    padding-top: 17px;
  }
`;

export const StyledCollapsiblePanelHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 6px;

  & span {
    font-size: 1rem;
    margin: 0;
    flex-direction: column;

    & span {
      font-weight: ${cssVariables.font.bold};
    }

    & p {
      font-size: 0.7rem;
    }
  }

  & .icon {
    margin-right: 10px;
    vertical-align: middle;
  }

  svg {
    font-size: 1.5rem;
  }
`;

export const StyledErrorBottomText = styled.span`
  margin-top: 10px;
  font-size: 10px;
  font-weight: ${cssVariables.font.bold};
  text-transform: uppercase;
  text-align: center;
  color: red;
`;

export const StyledActionBtns = styled.div`
  display: flex;
  column-gap: 10px;
  padding-top: 15px;

  & button {
    text-transform: capitalize;
  }
`;

export const StyledLabel = styled.label`
  font-weight: ${cssVariables.font.bold};
`;

export const StyledFormFieldRow = styled(Row)`
  display: flex;
  align-items: center;
  width: 100%;

  .anticon {
    margin-left: 5px;
    cursor: pointer;

    :hover {
      color: ${cssVariables.errorText};
    }
  }

  > * + * {
    margin: 5px 0;
  }
`;

export const StyledFormFieldSubmit = styled(Button)`
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  align-self: center;
  width: 100%;
  box-shadow: none;

  :hover,
  :active {
    background-color: transparent;
  }
`;

export const StyledBotProfileBlock = styled.div`
  display: flex;
`;

export const StyledBtnEditBotProfile = styled.button`
  border: none;
  width: 40px;
  height: 38px;
  background: #ffff;
  color: #1667e7;
  border-radius: 1px;
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    background: #1667e726;
  }
`;

export const StyledBtnAdvanceSettings = styled.button`
  display: flex;
  border: none;
  height: 38px;
  background: #ffff;
  color: #1667e7;
  border-radius: 1px;
  cursor: pointer;
  margin-top: 15px;
  float: right;
  line-height: 32px;

  &:hover {
    background: #1667e726;
  }
`;

export const StyledAdvanceSettings = styled.div`
  padding-top: 10px;
`;

export const StyledTempIconImage = styled.div`
  height: 100px;
  border: 1px solid ${cssVariables.gray2};
  margin-bottom: 8px;
  width: fit-content;

  & img {
    height: 100%;
    width: auto;
    padding: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledImageUploaderWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

export const StyledGradientDiv = styled(Col)`
  margin-top: 15px;
  margin-left: 5px;
`;

export const StyledInputGradient = styled(InputNumber)`
  margin-top: 15px;
  padding: 10px;
  border-radius: 3px;
  width: auto;
`;

export const StyledLabelAutoOpen = styled.label`
  font-weight: ${cssVariables.font.bold};
  padding-right: 15px;
`;

export const StyledBgUpload = styled(StyledFlexRowLeft)`
  & .ant-upload-list-item-list-type-picture-card {
    border-color: ${cssVariables.gray2};
  }

  & .ant-tooltip {
    display: none;
  }

  & .hidden {
    display: none;
  }
`;

export const StyledCurrentBgLabel = styled.span`
  font-size: 12px;
  font-style: italic;
`;

export const StyledPositionLabel = styled.span`
  align-self: flex-start;
  font-weight: ${cssVariables.font.bold};
  font-size: 14px;
  display: block;
  padding-top: 15px;
`;

export const StyledPositionCols = styled(Col)`
  background-color: #f4f7f9;
  border-color: #f9f9f9;
  border-style: solid;
  height: 110px;
`;

export const StyledPositionBtn = styled(Button)`
  background-color: white;
  color: black;
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
  font-size: 15px;

  &:hover {
    cursor: pointer;
    background-color: ${cssVariables.primaryBlue};
    color: white;
  }

  &:active {
    background-color: ${cssVariables.primaryBlue};
    color: white;
  }
`;

export const StyledRightPosition = styled.div`
  padding-left: 70%;
`;

export const StyledPositionMidCols = styled(Col)`
  background-color: #f4f7f9;
  border-color: #f9f9f9;
  border-style: solid;
  height: 110px;
  text-align: center;
`;

export const StyledMidRotateBtn = styled(Button)`
  background-color: white;
  color: black;
  width: auto;
  height: auto;
  border-radius: 0 !important;
  font-size: 15px;
  padding: 45px;
  position: relative;
  transform: rotate(-90deg);

  &:hover {
    cursor: pointer;
    background-color: ${cssVariables.primaryBlue};
    color: white;
  }

  &:active {
    background-color: ${cssVariables.primaryBlue};
    color: white;
  }
`;

export const StyledPositionBtnTrans = styled(Button)`
  cursor: none;
`;

export const StyledBottomLeftPosition = styled.div`
  margin-top: 5em;
`;

export const StyledBottomRightPosition = styled.div`
  padding-left: 70%;
  margin-top: 5em;
`;

export const StyledChatBot = styled(Col)`
  & .rcw-widget-container {
    z-index: 100;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;

    & .rcw-conversation-container {
      & .rcw-messages-container {
        & .rcw-message {
          & div:not(rcw-client) {
            & div {
              & div {
                background-color: ${props =>
                  props.replyBubbleGradient || props.bubbleGradient} !important;
                color: ${props =>
                  props.textColor
                    ? props.textColor
                    : cssVariables.primaryColor} !important;
                font-size: ${props => props.fontSize || '12px'} !important;
              }
            }
          }
        }
      }
    }
  }

  & .rcw-messages-container {
    height: ${props => props.height}px;
    max-height: ${props => props.height}px;
  }

  & .rcw-header,
  .rcw-message-text {
    background-color: ${props => props.color} !important;
    color: ${props => (props.textColor ? props.textColor : '#fff')} !important;
  }

  & .rcw-conversation-container > .rcw-header {
    border-bottom: 1px solid #d9d9d9;
    font-family: 'Calibri', sans-serif;
    position: relative;

    .rcw-title {
      position: relative;
    }
  }

  & .rcw-message-text {
    background-color: ${props =>
      props.color !== props.bubbleColor
        ? props.bubbleColor
        : props.color} !important;
    font-size: ${props => props.fontSize} !important;
  }

  & .rcw-client .rcw-timestamp {
    font-family: 'Calibri', sans-serif;
  }

  & .rcw-client > .rcw-message-text {
    background-color: ${cssVariables.primaryTransparent};
    color: #fff;
    box-shadow: 1px 1px 2px 0px #b5b5b5;
    font-family: 'Calibri', sans-serif;
  }

  & .rcw-sender {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    font-family: 'Calibri', sans-serif;
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit !important;
  }

  & h1 {
    font-size: 2em;
  }

  & h2 {
    font-size: 1.5em;
  }
`;

export const StyledChatWidgetV2Bot = styled(Col)`
  #zsb-widget-wrapper {
    position: relative;
  }
`;

export const StyledAvatar = styled(Avatar)`
  background-color: ${props => props.color};
  padding: ${props =>
    props.avatar === 'default' || !props.avatar ? '10px' : '0px'};

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const StyledSelect = styled(Select)`
  margin: 0px;
  width: 100%;
`;

export const StyledRadio = styled(Radio)`
  padding-left: 40px;
`;

export const StyledRadioLabel = styled.div`
  background-color: ${props => props.color};
  color: ${props => props.textColor};
  padding: 15px;
`;

export const StyledSwitchWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;

  > div {
    width: 100%;
    display: flex;

    b:first-child,
    div:first-child:not(.ant-switch-handle) {
      width: 50%;
    }
  }

  > * + * {
    margin: 10px 0;
  }
`;

export const StyledInteractionCol = styled(Col)`
  display: flex;
  width: 100%;
  margin-top: 13px;

  b:first-child,
  div:first-child:not(.ant-switch-handle) {
    width: 75%;
  }
`;

export const StyledInteractionInput = styled(InputNumber)`
  margin-top: 5px;
  border-radius: 3px;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledWidgetVersionWrapper = styled(StyledFlexLeftColumn)`
  > * + * {
    margin: 10px 0;
  }
`;

export const StyledCollapsibleHeaderWithRadio = styled(StyledFlexLeftColumn)`
  > :nth-child(2) {
    margin-left: 36px;
    margin-top: 10px;
  }
`;
