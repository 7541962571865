import styled from 'styled-components';
import { cssVariables } from 'styles/root';
import Modal from '../GenericModal';

export const StyledSequenceViewerModal = styled(Modal)``;

export const StyledSequenceViewerContainer = styled.div`
  text-align: lef;
  padding: 16px 18px;
  color: ${cssVariables.primaryColor};
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  div {
    display: flex;
    justify-content: space-between;

    button {
      background-color: ${cssVariables.gray5};
      color: ${cssVariables.gray0};

      :hover {
        background-color: ${cssVariables.gray0};
        color: #fff;
      }
    }
  }

  .heading {
    font-size: 20px;
    font-weight: ${cssVariables.font.bold};
    line-height: 8px;
  }

  .text-content {
    margin-bottom: 20px;
  }
`;
