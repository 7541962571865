import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from 'store/store';
import {
  isAdvancedUserSelector,
  isFreeUserSelector,
  planTypeSelector,
} from 'selectors/plan';
import { apiService } from 'services/api.service';
import {
  AUTO_OPEN_INTEGRATION_PANEL,
  DELETE_INTEGRATION,
  SET_INTEGRATIONS,
  RESET_INTEGRATION_PANEL_AND_PERMIT,
} from 'store/action';
import { message } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import ROUTES from 'constants/routes';
import { withPrefixUUID } from 'utils';
import { getCurrentBotDetailsPage } from 'utils/bot';
import { INTEGRATION_DATA } from 'constants/localStorage';
import { getIntegrationPlatform } from 'utils/integration';
import { getBotIntegrations } from 'services/bots.service';
import useSelector from 'store/useSelector';
import {
  botJIDSelector,
  currentBotSelector,
  isPageReadySelector,
} from 'selectors/bot';
import { isExternalPageSelector } from 'selectors/user';
import {
  isPostCustomizationEditModalOpenSelector,
  isPreCustomizationEditModalOpenSelector,
} from 'selectors/bot/integration';

const useIntegration = () => {
  const [state, dispatch] = useContext(Context);
  const isFreeUser = useSelector(isFreeUserSelector);
  const jid = useSelector(botJIDSelector);
  const bot = useSelector(currentBotSelector);
  const isAdvancedUser = useSelector(isAdvancedUserSelector);
  const planType = useSelector(planTypeSelector);
  const isExternalPage = useSelector(isExternalPageSelector);
  const isPostCustomizationEditModalOpen = useSelector(
    isPostCustomizationEditModalOpenSelector
  );
  const isPreCustomizationEditModalOpen = useSelector(
    isPreCustomizationEditModalOpenSelector
  );

  const { token, sentinel, apiGatewayConfig } = state;
  const { search, state: locationState, pathname } = useLocation();
  const platform = getIntegrationPlatform(search);
  const { name, integrations } = bot;
  const isPageReady = useSelector(isPageReadySelector);

  const [contentLoading, setContentLoading] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const isDemo = platform && platform.includes('demo');
  const WITH_PREFIX_BOT_JID = withPrefixUUID(jid || pathname);
  const BOT_INTEGRATION_PAGE = getCurrentBotDetailsPage(
    jid,
    ROUTES.BOT_INTEGRATION
  );
  const isCurrentPage = pathname === BOT_INTEGRATION_PAGE;

  const handleDelete = async id => {
    setModalLoading(true);
    try {
      await apiService.deleteIntegration(sentinel, id, token);
      dispatch({ type: DELETE_INTEGRATION, payload: id });
      setItemToDelete({});
      setModalLoading(false);
      setShowDeleteModal(false);
      return message.success('Successfully deleted integration');
    } catch (error) {
      setModalLoading(false);
      return message.error(
        error.message !== DEFAULT_ERROR_MESSAGE
          ? error.message
          : 'Error while fetching  callback data'
      );
    }
  };

  const fetchAllIntegrations = async () => {
    try {
      const allIntegrations = await getBotIntegrations(
        sentinel,
        WITH_PREFIX_BOT_JID,
        token
      );
      dispatch({
        type: SET_INTEGRATIONS,
        payload: allIntegrations,
      });
      setContentLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (locationState || isDemo) {
      dispatch({ type: AUTO_OPEN_INTEGRATION_PANEL, payload: null });
    }
  }, [locationState, isDemo]);

  useEffect(() => {
    if (isPageReady && sentinel && isCurrentPage && !integrations.length) {
      setContentLoading(true);
      fetchAllIntegrations();
    }
  }, [isPageReady, sentinel]);

  useEffect(() => {
    return () => {
      localStorage.removeItem(INTEGRATION_DATA);
      dispatch({ type: RESET_INTEGRATION_PANEL_AND_PERMIT, payload: null });
    };
  }, []);

  return {
    contentLoading,
    handleDelete,
    integrations,
    isAdvancedUser,
    isFreeUser,
    itemToDelete,
    jid,
    locationState,
    modalLoading,
    name,
    planType,
    platform,
    setItemToDelete,
    setShowDeleteModal,
    showDeleteModal,
    isPageReady,
    isExternalPage,
    apiGatewayConfig,
    isPostCustomizationEditModalOpen,
    isPreCustomizationEditModalOpen,
  };
};

export default useIntegration;
