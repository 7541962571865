import { useContext } from 'react';
import {
  apiKeyModalSelector,
  isApiKeyModalOpenSelector,
} from 'selectors/bot/ui';
import { CLOSE_API_KEY_MODAL } from 'store/action';

import { Context } from 'store/store';
import useSelector from 'store/useSelector';

const useApiKeyModal = () => {
  const [state, dispatch] = useContext(Context);

  const isApiKeyModalOpen = useSelector(isApiKeyModalOpenSelector);
  const apiKeyModal = useSelector(apiKeyModalSelector);
  const { apiGatewayConfig } = state;

  const handleCloseModal = () => {
    dispatch({ type: CLOSE_API_KEY_MODAL });
  };

  return {
    isApiKeyModalOpen,
    apiKeyModal,
    handleCloseModal,
    apiGatewayConfig,
  };
};

export default useApiKeyModal;
