import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modals/GenericModal';
import {
  StyledQuestionModalTitle,
  StyledLinkedQuestionItem,
  StyledLinkedQuestion,
} from './LinkedQuestionModal.style';
import { LinkOutlined } from '@ant-design/icons';
import AnchorButton from 'components/Button/AnchorButton';
import useLinkedQuestionModal from './hooks';

const LinkedQuestionModal = ({
  linkedAnswerToEdit,
  showLinkedQuestionModal,
  handleCloseLinkedQuestionModal,
}) => {
  const { handleUnlinkQuestion, loading } = useLinkedQuestionModal();
  return (
    <Modal
      visible={showLinkedQuestionModal}
      title={
        <StyledQuestionModalTitle>Linked Questions</StyledQuestionModalTitle>
      }
      spinning={loading}
      onCancel={handleCloseLinkedQuestionModal}
      cancelText="Close"
    >
      {linkedAnswerToEdit &&
        linkedAnswerToEdit.qlinks.map((question, idx) => (
          <StyledLinkedQuestionItem>
            <StyledLinkedQuestion>{question}</StyledLinkedQuestion>
            <AnchorButton
              href="#"
              title={'Unlink'}
              onClick={() =>
                handleUnlinkQuestion(linkedAnswerToEdit, question, idx)
              }
              icon={<LinkOutlined />}
            />
          </StyledLinkedQuestionItem>
        ))}
      {linkedAnswerToEdit &&
        (!linkedAnswerToEdit.qlinks ||
          linkedAnswerToEdit.qlinks.length === 0) && (
          <div>No questions linked to the answer</div>
        )}
    </Modal>
  );
};

LinkedQuestionModal.propTypes = {
  showLinkedQuestionModal: PropTypes.bool,
  linkedAnswerToEdit: PropTypes.object,
  handleCloseLinkedQuestionModal: PropTypes.func,
};

export default LinkedQuestionModal;
