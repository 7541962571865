import React from 'react';
import { StyledSearch } from './GlobalVarsSearch.style';

const GlobalVarsSearch = ({ onChange }) => (
  <StyledSearch
    placeholder="Search global vars"
    size="large"
    allowClear
    onChange={onChange}
  />
);

export default GlobalVarsSearch;
