import { isEmpty, omit } from 'lodash';

import {
  DEFAULT_WIDGET_VERSION,
  INITIAL_BOT_INTEGRATION_DATA,
  INTEGRATION_SETTING_PROP_TO_EXCLUDE,
  LAUNCHER_ONLY_AVATAR,
} from 'constants/botCustomizer';
import { API_BASE_URL } from 'constants/websocket';
import {
  generateAuthenticatedUserNPMHTMLTag,
  generateBotComponent,
  generateEncryptedBot,
  generateMobileHTMLTagHeader,
  generateWebHTMLTagHeader,
} from 'services/fileGenerator.service';
import {
  ADD_INTEGRATION_HANDOFF_USER_INFO_FIELD,
  CHANGE_INTEGRATION_HANDOFF_USER_INFO_FIELD,
  CLEAR_COMPONENT_INTEGRATION_DATA,
  CLEAR_CURRENT_INTEGRATION_ID,
  REMOVE_INTEGRATION_HANDOFF_USER_INFO_FIELD,
  RESET_APPEARANCE_PANEL_SETTINGS,
  RESET_CONFIG_PANEL_SETTINGS,
  RESET_INTEGRATION_SETTINGS,
  RESET_PLACEMENT_PANEL_SETTINGS,
  RESET_SEARCH_CUSTOMIZER_DATA,
  SET_BOT_CUSTOMIZER_ACTIVE_PANEL,
  SET_CURRENT_INTEGRATION_DATA,
  SET_CURRENT_INTEGRATION_TYPE,
  SET_INTEGRATION_BOT_VERSION,
  SET_INTEGRATION_HANDOFF_CANCEL_RESPONSE,
  SET_INTEGRATION_HANDOFF_LABEL,
  SET_INTEGRATION_HANDOFF_SUCCESS_RESPONSE,
  SET_INTEGRATION_WIDGET_TYPE,
  SET_LAUNCHER_LABEL,
  SET_LAUNCHER_SHAPE,
  SET_MAX_INTERACTION_HISTORY,
  SET_REPLY_BUBBLE_GRADIENT,
  SET_WIDGET_AVATAR_COLOR,
  SET_HEADER_AVATAR,
  SET_WIDGET_CHAT_BUBBLE_COLOR,
  SET_WIDGET_FONT_COLOR,
  SET_WIDGET_FONT_SIZE,
  SET_WIDGET_HEIGHT,
  SET_WIDGET_LAUNCHER_ICON,
  SET_WIDGET_LAUNCHER_AVATAR_TYPE,
  SET_WIDGET_LAUNCHER_POSITION,
  SET_WIDGET_PLACEHOLDER,
  SET_WIDGET_SUBTITLE,
  SET_WIDGET_THEME_COLOR,
  SET_WIDGET_TITLE,
  SET_WIDGET_WELCOME_MESSAGE,
  TOGGLE_AUTO_OPEN_WIDGET_SETTING,
  TOGGLE_INTEGRATION_HANDOFF_CALLBACK,
  TOGGLE_INTEGRATION_HANDOFF_DISPLAY_FORM,
  TOGGLE_INTERACTION_HISTORY,
  TOGGLE_SHOW_CLOSE_BUTTON,
  TOGGLE_WIDGET_SPEECH_TO_TEXT,
  TOGGLE_WIDGET_TEXT_TO_SPEECH,
  TOGGLE_WIDGET_TYPING_EXPERIENCE,
  TOGGLE_WIDGET_USER_AUTHETICATED_SWITCH,
  SET_HEADER_AVATAR_TYPE,
  SET_RESPONSE_AVATAR_TYPE,
  SET_RESPONSE_AVATAR,
  SAVE_NEW_INTEGRATION,
} from 'store/action';
import { stripUUID } from 'utils';
import { cssVariables } from 'styles/root';

export const integrationSettingsReducer = (state, action) => {
  switch (action.type) {
    case RESET_CONFIG_PANEL_SETTINGS:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              botTitle:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .botTitle,
              subtitle:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .subtitle,
              placeholder:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .placeholder,
              welcomeMessage:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .welcomeMessage,
            },
          },
        },
      };

    case SET_HEADER_AVATAR:
      const avatar = action.payload;
      const launcherAvatarType =
        state.bot.currentIntegrationSettings.settings?.launcherAvatarType;
      const headerAvatarType =
        state.bot.currentIntegrationSettings.settings?.headerAvatarType;
      const launcherAvatar =
        state.bot.currentIntegrationSettings.settings?.launcherAvatar;

      const newAvatar =
        launcherAvatarType === 'icon' && headerAvatarType === 'icon'
          ? launcherAvatar
          : !isEmpty(avatar)
          ? avatar
          : state.bot.currentIntegrationSettings.settings?.headerAvatar;

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              headerAvatar: newAvatar,
            },
          },
        },
      };

    case SET_RESPONSE_AVATAR: {
      const avatar = action.payload;
      const type =
        state.bot.currentIntegrationSettings.settings?.responseAvatarType;
      // automatically picked launcher avatar if no header avatar
      const launcherAvatar =
        state.bot.currentIntegrationSettings.settings?.launcherAvatar;
      // automatically picked if no response avatar
      const headerAvatar =
        type !== 'none'
          ? state.bot.currentIntegrationSettings.settings?.headerAvatar ||
            launcherAvatar
          : null;
      const headerSVGIconTypeAvatar =
        type === 'icon' && !LAUNCHER_ONLY_AVATAR.includes(avatar)
          ? avatar
          : null;
      const launcherSVGIconTypeAvatar =
        type === 'icon' && !LAUNCHER_ONLY_AVATAR.includes(avatar)
          ? avatar
          : null;

      const newAvatar =
        type === 'icon' &&
        (headerSVGIconTypeAvatar || launcherSVGIconTypeAvatar)
          ? headerAvatar || launcherAvatar
          : // for now, we didnt implement custom image for response avatar
          type === 'custom'
          ? avatar || headerAvatar
          : null;

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              headerAvatar: newAvatar,
            },
          },
        },
      };
    }

    case SET_HEADER_AVATAR_TYPE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              headerAvatarType: action.payload,
              // remove avatar every time type changes
              headerAvatar: null,
            },
          },
        },
      };

    case SET_RESPONSE_AVATAR_TYPE: {
      const type = action.payload;
      const launcherAvatar =
        state.bot.currentIntegrationSettings.settings.launcherAvatar;
      const oldAvatarProp =
        state.bot.currentIntegrationSettings.settings.avatar;
      const currentAvatar =
        state.bot.currentIntegrationSettings.settings.responseAvatar;
      const launcherAvatarType =
        state.bot.currentIntegrationSettings.settings.launcherAvatarType;

      const responseAvatar =
        type === 'icon' && launcherAvatarType === 'icon'
          ? launcherAvatar || oldAvatarProp
          : type === 'none'
          ? null
          : currentAvatar;

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              responseAvatar,
              responseAvatarType: type,
            },
          },
        },
      };
    }

    case RESET_APPEARANCE_PANEL_SETTINGS:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              launcherAvatar:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .launcherAvatar,
              responseAvatar:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .responseAvatar,
              avatarPosition:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .avatarPosition,
              height:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .height,
              textColor:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .textColor,
              headerAvatarType:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .headerAvatarType,
              replyBubbleGradient:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .replyBubbleGradient,
              bubbleColor:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .bubbleColor,
              fontSize:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .fontSize,
              autoOpen:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .autoOpen,
              speechToText:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .speechToText,
              textToSpeech:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .textToSpeech,
              typingExperience:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .typingExperience,
              showCloseButton:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .showCloseButton,
            },
          },
        },
      };

    case RESET_PLACEMENT_PANEL_SETTINGS:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              background:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .background,
              shape:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .shape,
              src: state.bot.currentIntegrationSettings.initialSettings.settings
                .src,
              position:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .position,
              label:
                state.bot.currentIntegrationSettings.initialSettings.settings
                  .label,
            },
          },
        },
      };

    case SAVE_NEW_INTEGRATION:
    case SET_CURRENT_INTEGRATION_DATA: {
      const {
        settings,
        type,
        id,
        widgetVersion: widgetVersionFromPayload,
        ...restOfPayload
      } = action.payload;
      const { botTitle, viber_version, ...restOfSettingsProp } = settings || {};
      const { bot, messengerCallback, pubAskedQuestion, publicKey, sentinel } =
        state;
      const currentWidgetVersion =
        widgetVersionFromPayload ||
        bot.currentIntegrationSettings?.widgetVersion ||
        DEFAULT_WIDGET_VERSION;

      const folderPath = stripUUID(bot.jid);
      const integrationID = stripUUID(id);
      const integrationConfigURL = `${folderPath}/${integrationID}/config.json`;
      let code1 = null;
      let code2 = null;

      switch (type) {
        // WEBHOOK: REACT_APP_API_BASE_URL
        // JID: from current integration JID
        // MESSENGERCALLBACKJID: created thru spawn create
        // >> when user first time create a facebook integration
        // >> or value fetched from `alias_list`
        // PUBLICKEY: publicKey from global store; aslo create thru spawn create,
        // >> when user first time created any integration
        // >> or value fetched from `alias_list`
        case 'facebook-messenger':
          code1 = `${API_BASE_URL}/js_public/walker_callback/${stripUUID(
            id
          )}/${stripUUID(messengerCallback)}?key=${publicKey}`;

          code2 = action.payload.settings?.fb_verify_token;
          break;

        case 'web':
          code1 = {
            html: {
              botComponent: generateBotComponent(
                bot.jid,
                sentinel,
                publicKey,
                pubAskedQuestion,
                action.payload,
                integrationConfigURL,
                action.payload.settings.height,
                false,
                currentWidgetVersion
              ),
              headerCode: generateWebHTMLTagHeader(
                action.payload.settings.authenticatedUser,
                currentWidgetVersion
              ),
            },
            npm: {
              botComponent: generateBotComponent(
                bot.jid,
                sentinel,
                publicKey,
                pubAskedQuestion,
                action.payload,
                integrationConfigURL,
                action.payload.settings.height,
                false,
                currentWidgetVersion
              ),
              headerCode: generateAuthenticatedUserNPMHTMLTag(
                action.payload.settings.authenticatedUser
              ),
            },
            wordpress: {
              botComponent: generateEncryptedBot(
                bot.jid,
                sentinel,
                integrationConfigURL,
                publicKey,
                pubAskedQuestion
              ),
            },
          };
          break;

        case 'wordpress':
          code1 = {
            html: {
              botComponent: generateBotComponent(
                bot.jid,
                sentinel,
                publicKey,
                pubAskedQuestion,
                action.payload,
                integrationConfigURL,
                action.payload.settings.height,
                false,
                currentWidgetVersion
              ),
              headerCode: generateWebHTMLTagHeader(
                action.payload.settings.authenticatedUser,
                currentWidgetVersion
              ),
            },
            npm: {
              botComponent: generateBotComponent(
                bot.jid,
                sentinel,
                publicKey,
                pubAskedQuestion,
                action.payload,
                integrationConfigURL,
                action.payload.settings.height,
                false,
                currentWidgetVersion
              ),
              headerCode: generateAuthenticatedUserNPMHTMLTag(
                action.payload.settings.authenticatedUser
              ),
            },
            wordpress: {
              botComponent: generateEncryptedBot(
                bot.jid,
                sentinel,
                integrationConfigURL,
                publicKey,
                pubAskedQuestion
              ),
            },
          };
          break;

        case 'mobile':
          code2 = generateMobileHTMLTagHeader(action.payload.settings);

          code1 = generateBotComponent(
            bot.jid,
            sentinel,
            publicKey,
            pubAskedQuestion,
            action.payload,
            integrationConfigURL,
            action.payload.settings.height,
            true,
            currentWidgetVersion
          );
          break;

        case 'viber':
        default:
          break;
      }
      const integrationId = id || state.bot.currentIntegrationSettings?.id;

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            // spread initial settings
            ...INITIAL_BOT_INTEGRATION_DATA,
            // add current settings
            ...state.bot.currentIntegrationSettings,
            identifier: state.bot.name,
            id: integrationId,
            type,
            code1,
            code2,
            ...restOfPayload,
            widgetVersion: currentWidgetVersion,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              ...restOfSettingsProp,
              botTitle: action.payload?.settings?.botTitle
                ? botTitle
                : state.bot.currentIntegrationSettings.settings?.botTitle ||
                  state.bot.currentIntegrationSettings.identifier,
              viber_version:
                viber_version ||
                state.bot.currentIntegrationSettings?.settings?.viber_version ||
                undefined,
            },
            // state used to reset settings
            initialSettings: isEmpty(
              state.bot.currentIntegrationSettings.initialSettings
            )
              ? {
                  identifier: state.bot.name,
                  ...omit(
                    state.bot.currentIntegrationSettings,
                    INTEGRATION_SETTING_PROP_TO_EXCLUDE
                  ),
                  id: integrationId,
                  type,
                  ...restOfPayload,
                  code1,
                  code2,
                  widgetVersion: currentWidgetVersion,
                  settings: {
                    ...state.bot.currentIntegrationSettings.settings,
                    botTitle: action.payload?.settings?.botTitle
                      ? botTitle
                      : state.bot.currentIntegrationSettings.settings
                          ?.botTitle ||
                        state.bot.currentIntegrationSettings?.identifier ||
                        state.bot.name,
                    ...restOfSettingsProp,
                    viber_version:
                      viber_version ||
                      state.bot.currentIntegrationSettings?.settings
                        ?.viber_version ||
                      undefined,
                  },
                }
              : state.bot.currentIntegrationSettings.initialSettings,
          },
        },
      };
    }

    case CLEAR_CURRENT_INTEGRATION_ID:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            id: null,
          },
        },
      };

    case SET_CURRENT_INTEGRATION_TYPE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            type: action.payload,
          },
        },
      };

    case CLEAR_COMPONENT_INTEGRATION_DATA:
    case RESET_INTEGRATION_SETTINGS:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            id: null,
            panel: {
              permit: 1,
              activePanel: 1,
            },
            ...INITIAL_BOT_INTEGRATION_DATA,
            widgetVersion: DEFAULT_WIDGET_VERSION,
            initialSettings: {},
          },
        },
      };

    case RESET_SEARCH_CUSTOMIZER_DATA:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              permit: 1,
              activePanel: 1,
            },
            ...INITIAL_BOT_INTEGRATION_DATA,
            widgetVersion: 'v2',
            settings: {
              ...INITIAL_BOT_INTEGRATION_DATA.settings,
              type: 'search',
            },
          },
        },
      };

    case SET_INTEGRATION_BOT_VERSION: {
      console.log('action.payload', action.payload);
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            widgetVersion: action.payload,
          },
        },
      };
    }

    case SET_INTEGRATION_WIDGET_TYPE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              type: action.payload,
              color:
                action.payload !== 'search'
                  ? cssVariables.primaryColor
                  : state.bot.currentIntegrationSettings.settings.color,
            },
          },
        },
      };

    case SET_WIDGET_HEIGHT: {
      const height =
        typeof action.payload === 'string' && action.payload.includes('px')
          ? Number(action.payload)
          : `${Number(action.payload)}px`;

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              height,
            },
          },
        },
      };
    }

    case SET_WIDGET_PLACEHOLDER:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              placeholder: action.payload,
            },
          },
        },
      };

    case SET_WIDGET_THEME_COLOR:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              color: action.payload,
            },
          },
        },
      };

    case SET_WIDGET_FONT_COLOR:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              textColor: action.payload,
            },
          },
        },
      };

    case SET_WIDGET_LAUNCHER_ICON: {
      const newLauncherIcon = action.payload;

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              launcherAvatar: newLauncherIcon,
            },
          },
        },
      };
    }

    case SET_WIDGET_LAUNCHER_AVATAR_TYPE: {
      const type = action.payload;

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              launcherAvatarType: type,
            },
          },
        },
      };
    }

    case SET_WIDGET_AVATAR_COLOR:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              iconColor: action.payload,
            },
          },
        },
      };

    case SET_WIDGET_TITLE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              botTitle: action.payload,
            },
          },
        },
      };

    case SET_WIDGET_SUBTITLE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              subtitle: action.payload,
            },
          },
        },
      };

    case SET_WIDGET_WELCOME_MESSAGE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              welcomeMessage: action.payload,
            },
          },
        },
      };

    case TOGGLE_WIDGET_USER_AUTHETICATED_SWITCH:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              authenticatedUser: action.payload,
            },
          },
        },
      };

    case TOGGLE_INTERACTION_HISTORY:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              interactionHistory: action.payload,
            },
          },
        },
      };

    case SET_MAX_INTERACTION_HISTORY:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              maxInteraction: action.payload,
            },
          },
        },
      };

    case SET_WIDGET_CHAT_BUBBLE_COLOR:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              bubbleColor: action.payload,
            },
          },
        },
      };

    case SET_REPLY_BUBBLE_GRADIENT:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              replyBubbleGradient: action.payload,
              // to deprecate
              bubbleGradient: action.payload,
            },
          },
        },
      };

    case SET_WIDGET_FONT_SIZE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              fontSize: action.payload,
            },
          },
        },
      };

    case TOGGLE_AUTO_OPEN_WIDGET_SETTING:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              autoOpen: action.payload,
            },
          },
        },
      };

    case TOGGLE_WIDGET_SPEECH_TO_TEXT:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              speechToText: action.payload,
            },
          },
        },
      };

    case TOGGLE_WIDGET_TYPING_EXPERIENCE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              typingExperience: action.payload,
            },
          },
        },
      };

    case TOGGLE_WIDGET_TEXT_TO_SPEECH:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              speechToText: action.payload,
            },
          },
        },
      };

    case TOGGLE_SHOW_CLOSE_BUTTON:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              showCloseButton: action.payload,
            },
          },
        },
      };

    case SET_WIDGET_LAUNCHER_POSITION:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              position: action.payload,
            },
          },
        },
      };

    case SET_LAUNCHER_LABEL:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              label: action.payload,
            },
          },
        },
      };

    case SET_LAUNCHER_SHAPE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              shape: action.payload,
            },
          },
        },
      };

    case SET_INTEGRATION_HANDOFF_LABEL:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              handoffLabel: action.payload,
            },
          },
        },
      };

    case SET_INTEGRATION_HANDOFF_SUCCESS_RESPONSE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              submittedFormMessage: action.payload,
            },
          },
        },
      };

    case SET_INTEGRATION_HANDOFF_CANCEL_RESPONSE:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              cancelledFormMessage: action.payload,
            },
          },
        },
      };

    case ADD_INTEGRATION_HANDOFF_USER_INFO_FIELD: {
      const newUserInfoField = { label: '', attribute: '', mandatory: false };

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,

              formFields: state.bot.currentIntegrationSettings.settings
                ?.formFields?.length
                ? [
                    ...state.bot.currentIntegrationSettings.settings.formFields,
                    newUserInfoField,
                  ]
                : [newUserInfoField],
            },
          },
        },
      };
    }

    case REMOVE_INTEGRATION_HANDOFF_USER_INFO_FIELD: {
      const formFields =
        state.bot.currentIntegrationSettings.settings?.formFields || [];

      if (formFields.length) {
        return {
          ...state,
          bot: {
            ...state.bot,
            currentIntegrationSettings: {
              ...state.bot.currentIntegrationSettings,
              settings: {
                ...state.bot.currentIntegrationSettings.settings,

                formFields: !isEmpty(action.payload)
                  ? formFields.filter(
                      field => action.payload !== field.attribute
                    )
                  : formFields.splice(0, formFields.length - 1),
              },
            },
          },
        };
      }
      return state;
    }

    case CHANGE_INTEGRATION_HANDOFF_USER_INFO_FIELD: {
      if (state.bot.currentIntegrationSettings.settings?.formFields?.length) {
        const formFields =
          state.bot.currentIntegrationSettings.settings.formFields;
        const { key, label, attribute, mandatory } = action.payload;
        const updatedFields = formFields.map((field, idx) => {
          if (idx === key) {
            return {
              label,
              attribute,
              mandatory,
            };
          }
          return field;
        });
        return {
          ...state,
          bot: {
            ...state.bot,
            currentIntegrationSettings: {
              ...state.bot.currentIntegrationSettings,
              settings: {
                ...state.bot.currentIntegrationSettings.settings,

                formFields: updatedFields,
              },
            },
          },
        };
      }
      return state;
    }

    case TOGGLE_INTEGRATION_HANDOFF_CALLBACK:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              callbackEmail: Boolean(
                !state.bot.currentIntegrationSettings.settings.callbackEmail
              ),
            },
          },
        },
      };

    case TOGGLE_INTEGRATION_HANDOFF_DISPLAY_FORM:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              displayFormFields: Boolean(
                !state.bot.currentIntegrationSettings.settings.displayFormFields
              ),
            },
          },
        },
      };

    case SET_BOT_CUSTOMIZER_ACTIVE_PANEL:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            customizerActivePanel: action.payload,
          },
        },
      };

    default:
      return state;
  }
};
