export const PERFORMANCE_METRICS_DEFAULT_ANSWERS = 'default-answers';
export const PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS = 'negative-feedback';
export const PERFORMANCE_METRICS_INCORRECT_QUESTIONS = 'incorrect-questions';
export const INCORRECT_QUESTIONS = 'incorrect-questions';
export const CORRECT_QUESTIONS = 'correct-questions';
export const QUESTION_LOGS = 'question-log';
export const MOST_ASKED_QUESTION = 'most-asked-question';
export const INTENTS = 'intents';
export const QUESTION_WITH_POSITIVE_FEEDBACK =
  'questions-with-positive-feedback';
export const QUESTION_WITH_NEGATIVE_FEEDBACK =
  'questions-with-negative-feedback';

export const PERFORMANCE_METRICS_DEFAULT_ANSWER_SLUG = `#${PERFORMANCE_METRICS_DEFAULT_ANSWERS}`;
export const PERFORMANCE_METRICS_INCORRECT_QUESTIONS_SLUG = `#${PERFORMANCE_METRICS_INCORRECT_QUESTIONS}`;
export const PERFORMANCE_METRICS_NEGATIVE_FEEDBACK_SLUG = `#${PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS}`;
export const PERFORMANCE_METRICS_PATH_PREFIX = 'performance-metrics';
export const PERFORMANCE_METRICS_HASH = '#performance-metrics';

export const TABLE_VIEW_QUERY = '?tableView=';

export const TOOLTIPS = {
  CSAT_SCORE:
    'This counts the thumbs up and thumbs down feedback on the session. CSAT Score is computed based on Total Questions Asked less Total no. of thumbs down over Total Questions Asked.',
  FALLBACK_ANSWER:
    'This counts the number of Fallback Response is displayed on the interaction. Containment Rate is computed based on Total Questions Asked less Total no. of Fallback Answers Called over Total Questions Asked.',
  QUESTIONS_ASKED:
    'This counts the number of questions a user/visitor asks the bot.',
  VISITORS:
    'This counts the number of visitors that access the bot. (per device)',
  SESSIONS: 'This counts the number of sessions the user access the bot.',
  VALIDATIONS_CORRECT: 'This counts the correct questions.',
  VALIDATIONS_INCORRECT: 'This counts the incorrect questions.',
  CORRECT_QUESTIONS_FILTER_ICON: 'Click to show Correct Questions only',
  INCORRECT_QUESTIONS_FILTER_ICON: 'Click to show Incorrect Questions only',
  DEFAULT_ANSWERT: 'This counts the number of default answers called.',
  NEGATIVE_FEEDBACK: 'This counts the number of negative feedbacks.',
};

export const TABLE_VIEW = {
  QUESTION_LOGS_LIST: [
    {
      value: 'Question Log',
      key: QUESTION_LOGS,
    },
    {
      value: 'Most Asked Questions',
      key: MOST_ASKED_QUESTION,
    },
    {
      value: 'Intents',
      key: INTENTS,
    },
    {
      value: 'Questions with Positive Feedback',
      key: QUESTION_WITH_POSITIVE_FEEDBACK,
    },
    {
      value: 'Questions with Negative Feedback',
      key: QUESTION_WITH_NEGATIVE_FEEDBACK,
    },
    {
      value: 'Correct Questions',
      key: CORRECT_QUESTIONS,
    },
    {
      value: 'Incorrect Questions',
      key: INCORRECT_QUESTIONS,
    },
  ],
  KEY_METRICS_LIST: [
    {
      value: 'Default Answers',
      key: PERFORMANCE_METRICS_DEFAULT_ANSWERS,
    },
    {
      value: 'Negative Feedback',
      key: PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS,
    },
    {
      value: 'Incorrect Audit Result',
      key: PERFORMANCE_METRICS_INCORRECT_QUESTIONS,
    },
  ],
};
