import React, { useState, useEffect } from 'react';
import { Layout, Menu, Progress } from 'antd';
import { Link } from 'react-router-dom';
import { snakeCase } from 'lodash';
import {
  DollarOutlined,
  FlagOutlined,
  KeyOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
  UserOutlined,
} from '@ant-design/icons';

import {
  StyledFlexColumn,
  StyledFlexRowCenter,
  StyledSpaceBetweenFlexColumn,
} from 'styles/GenericStyledComponents';
import { BackToBotsPage } from 'components/BackButton/BackToBotsPage';
import {
  StyledToggleIconWrapper,
  Wrapper,
  StyledSidebarBottom,
  StyledSidebarBottomCounts,
  StyledSidebarBottomTitle,
  StyledSidebarItemsTitle,
} from './Sidebar.style';
import useSidebar from './hooks';
import { useHistory } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { slugify } from 'utils/stringManipulation';
import useSelector from 'store/useSelector';
import {
  botPercentageSelector,
  currentBotCountSelector,
  maxBotCountSelector,
  maxTransactionCountSelector,
  planTypeSelector,
} from 'selectors/plan';
import { StyledTransactionParagraph } from 'pages/UserProfile/index.style';
import {
  currentTransactionCountSelector,
  transactionPercentageSelector,
  trialDaysLeftSelector,
} from 'selectors/subscription';
import { ZSB_CONTACT } from 'constants/outboundLinks';
import {
  botsExceedsLimitSelector,
  botsRemainingSelector,
  totalExceedingBotsSelector,
} from 'selectors/bots';
import GenericHR from 'components/HR/GenericHR';

const { Sider } = Layout;

const MENU_LIST = [
  {
    name: 'My Account',
    icon: <UserOutlined className="menu-icon" />,
    path: ROUTES.USER_PROFILE,
  },
  {
    name: 'Activity Logs',
    icon: <ProfileOutlined className="menu-icon" />,
    path: ROUTES.ACTIVITY_LOGS,
  },
  {
    name: 'Onboarding Flags',
    icon: <FlagOutlined className="menu-icon" />,
    path: ROUTES.ONBOARDING_FLAGS,
  },
  {
    name: 'Plan and Payments',
    icon: <ReconciliationOutlined className="menu-icon" />,
    path: ROUTES.PLAN_AND_PAYMENTS,
  },
  {
    name: 'Pricing',
    icon: <DollarOutlined className="menu-icon" />,
    path: ROUTES.SUBSCRIPTION,
  },
];

const API_KEY_MENU = [
  {
    name: 'API Key',
    icon: <KeyOutlined className="menu-icon" />,
    path: ROUTES.API_KEY,
  },
];

const UserSidebar = () => {
  const botsExceedsLimit = useSelector(botsExceedsLimitSelector);
  const botsRemaining = useSelector(botsRemainingSelector);
  const botPercentage = useSelector(botPercentageSelector);
  const currentBotCount = useSelector(currentBotCountSelector);
  const currentTransactionCount = useSelector(currentTransactionCountSelector);
  const maxBotCount = useSelector(maxBotCountSelector);
  const maxTransactionCount = useSelector(maxTransactionCountSelector);
  const planType = useSelector(planTypeSelector);
  const totalExceedingBots = useSelector(totalExceedingBotsSelector);
  const transactionPercentage = useSelector(transactionPercentageSelector);
  const trialDaysLeft = useSelector(trialDaysLeftSelector);

  const { handleToggleCollapse, showIconOnly } = useSidebar();
  const [selectedKey, setSelectedKey] = useState();
  const [menu, setMenu] = useState(MENU_LIST);
  const { push } = useHistory();

  useEffect(() => {
    setMenu(planType !== 'free' ? [...MENU_LIST, ...API_KEY_MENU] : MENU_LIST);
  }, [planType]);

  const getTransactionCountParagraph = () => {
    if (currentTransactionCount > maxTransactionCount) {
      const excessTransaction = Math.abs(
        currentTransactionCount - maxTransactionCount
      );
      return `You have gone above your transaction limit this month. Excess transaction count: ${excessTransaction} `;
    } else {
      return `You have used ${currentTransactionCount} of ${maxTransactionCount} ${
        planType === 'free' ? 'free' : ''
      } transactions per month.`;
    }
  };

  const renderMenu = (menu, slug) => {
    return (
      <Menu.Item
        active={selectedKey === snakeCase(menu.name)}
        key={snakeCase(menu.name)}
        icon={menu.icon}
        onClick={() => {
          push(menu.path);
        }}
      >
        {menu.name}
      </Menu.Item>
    );
  };

  return (
    <Wrapper>
      <Sider collapsed={showIconOnly} theme="light" className="sider">
        <StyledSpaceBetweenFlexColumn>
          <div style={{ margin: '10px 0px' }}>
            <BackToBotsPage iconOnly={showIconOnly} />
          </div>
          <GenericHR />
          <StyledSpaceBetweenFlexColumn height="calc(100vh - 150px)">
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={selectedKey}
              onClick={e => setSelectedKey(e?.key)}
            >
              {!!menu &&
                menu.map((menu, key) => {
                  const slug = slugify(menu.name);
                  return renderMenu(menu, slug);
                })}
            </Menu>
            <StyledFlexRowCenter
              style={{ cursor: 'pointer', width: '100%' }}
              padding="0px 0px 10px 0px"
              onClick={handleToggleCollapse}
            >
              <StyledToggleIconWrapper showIconOnly={showIconOnly}>
                {showIconOnly ? (
                  <StepForwardOutlined />
                ) : (
                  <StepBackwardOutlined />
                )}
              </StyledToggleIconWrapper>
            </StyledFlexRowCenter>
          </StyledSpaceBetweenFlexColumn>

          <StyledSidebarBottom>
            <StyledSidebarBottomTitle>
              {planType.charAt(0).toUpperCase() + planType.slice(1)}{' '}
              {showIconOnly ? null : 'Plan'}
            </StyledSidebarBottomTitle>

            {planType !== 'free' ? (
              <div>
                {/* {showIconOnly ? null : (
                  <> */}
                <StyledSidebarItemsTitle>
                  {'Transactions'}
                </StyledSidebarItemsTitle>
                <StyledSidebarBottomCounts>
                  {`${currentTransactionCount}/${maxTransactionCount}`}
                </StyledSidebarBottomCounts>
                {/* </>
                )} */}
                <Progress
                  percent={transactionPercentage}
                  showInfo={false}
                  strokeColor="#B3B4B7"
                  trailColor="#E8F0FD"
                  size="small"
                />
                {showIconOnly ? null : (
                  <>
                    {trialDaysLeft > 0 ? (
                      <span>
                        You have {trialDaysLeft} day/s left on your trial!.{' '}
                      </span>
                    ) : null}
                    <StyledTransactionParagraph
                      isRed={transactionPercentage > 80}
                    >
                      {getTransactionCountParagraph()}
                    </StyledTransactionParagraph>
                    {planType === 'advanced' ? (
                      <a href={ZSB_CONTACT} target="_blank" rel="noreferrer">
                        Contact us for more
                      </a>
                    ) : (
                      <Link to={ROUTES.SUBSCRIPTION}>
                        Get more transactions.
                      </Link>
                    )}
                  </>
                )}
              </div>
            ) : null}
            <div>
              <StyledSidebarItemsTitle>Bots</StyledSidebarItemsTitle>
              <StyledSidebarBottomCounts>
                {`${currentBotCount}/${maxBotCount}`}
              </StyledSidebarBottomCounts>
              <Progress
                percent={botPercentage}
                showInfo={false}
                strokeColor="#B3B4B7"
                trailColor="#E8F0FD"
                size="small"
              />
              {showIconOnly ? null : (
                <>
                  {botsExceedsLimit ? (
                    <span>{`You have exceeded ${totalExceedingBots}`}</span>
                  ) : (
                    <span>{`${botsRemaining} bots left. `}</span>
                  )}
                  {planType === 'advanced' ? (
                    <a href={ZSB_CONTACT} target="_blank" rel="noreferrer">
                      Contact us for more
                    </a>
                  ) : (
                    <Link to={ROUTES.SUBSCRIPTION}> {'Upgrade for more.'}</Link>
                  )}
                </>
              )}

              {planType === 'advanced' && showIconOnly ? (
                <a href={ZSB_CONTACT} target="_blank" rel="noreferrer">
                  Contact us
                </a>
              ) : null}
            </div>
          </StyledSidebarBottom>
        </StyledSpaceBetweenFlexColumn>
      </Sider>
    </Wrapper>
  );
};

export default UserSidebar;
