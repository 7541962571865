import { useState, useContext, useEffect } from 'react';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { stripUUID } from 'utils';
import { strippedString } from 'utils/stringManipulation';
import {
  ADD_INTEGRATION,
  OPEN_EDIT_PANEL_INTEGRATION,
  RESET_INTEGRATION_SETTINGS,
  SAVE_NEW_INTEGRATION,
  SET_CURRENT_INTEGRATION_DATA,
  SET_INTEGRATION_IDENTIFIER,
  SET_INTEGRATION_PATH,
  SET_INTEGRATION_WIDGET_TYPE,
  SHOW_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  SPAWN_CREATE,
  UPDATE_INTEGRATION,
} from 'store/action';
import {
  integrationDataSelector,
  currentIntegrationIDSelector,
  currentIntegrationActivePanelSelector,
  integrationPanelPermitSelector,
  isPostCustomizationEditModalEditableSelector,
  integrationChannelNameSelector,
  isPostCustomizationEditModalOpenSelector,
  integrationPathSelector,
  widgetVersionSelector,
} from 'selectors/bot/integration';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { INTEGRATION_DATA } from 'constants/localStorage';
import useSelector from 'store/useSelector';
import {
  currentFullIntegrationSelector,
  isHeaderAvatarCustomSelector,
  isLauncherAvatarCustomSelector,
  launcherAvatarCloudPathSelector,
  launcherAvatarSelector,
  widgetHeaderAvatarCloudPathSelector,
  widgetHeaderAvatarSelector,
} from 'selectors/bot/integration/settings';
import { publicKeySelector, sentinelSelector } from 'selectors/publicKeys';
import { botJIDSelector } from 'selectors/bot';
import { BlobStorageService } from 'services/azureBlobStorage.service';

const INTEGRATIOM_METHODS = [
  {
    label: 'HTML',
    value: 'html',
  },
  {
    label: 'NPM',
    value: 'npm',
  },
];

const useWeb = () => {
  const [state, dispatch] = useContext(Context);
  const permit = useSelector(integrationPanelPermitSelector);
  const activePanel = useSelector(currentIntegrationActivePanelSelector);
  const currentIntegrationId = useSelector(currentIntegrationIDSelector);
  const channelName = useSelector(integrationChannelNameSelector);
  const path = useSelector(integrationPathSelector);
  const publicKey = useSelector(publicKeySelector);
  const fullIntegrationData = useSelector(currentFullIntegrationSelector);
  const sentinel = useSelector(sentinelSelector);
  const botJid = useSelector(botJIDSelector);
  const isPostCustomizationEditable = useSelector(
    isPostCustomizationEditModalEditableSelector
  );
  const isPostCustomizationEditModalOpen = useSelector(
    isPostCustomizationEditModalOpenSelector
  );
  const currentIntegrationSettings = useSelector(integrationDataSelector);
  const isLauncherAvatarCustom = useSelector(isLauncherAvatarCustomSelector);
  const launcherAvatarCloudPath = useSelector(launcherAvatarCloudPathSelector);
  const headerAvatarCloudPath = useSelector(
    widgetHeaderAvatarCloudPathSelector
  );
  const isHeaderAvatarCustom = useSelector(isHeaderAvatarCustomSelector);
  const launcherAvatar = useSelector(launcherAvatarSelector);
  const headerAvatar = useSelector(widgetHeaderAvatarSelector);
  const widgetVersion = useSelector(widgetVersionSelector);

  const { state: locationState } = useLocation();
  const {
    token,
    bot: { integrations },
  } = state;

  const [loading, setLoading] = useState(false);
  const localIntegration =
    JSON.parse(localStorage.getItem(INTEGRATION_DATA)) || locationState;

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [tempIdentifier, setIdentifier] = useState(channelName);
  const [tempPath, setPath] = useState(path);
  const [integrationMethod, setIntegrationMethod] = useState(
    INTEGRATIOM_METHODS[0].value
  );

  const getDuplicateName = () => {
    const strippedInput = strippedString(tempIdentifier);
    return (
      integrations.findIndex(
        i =>
          strippedString(i.identifier) === strippedInput &&
          i.id !== currentIntegrationId
      ) >= 0
    );
  };

  const spawnCreate = async () => {
    const apiName = 'zsb_public_api';
    try {
      const res = await apiService.spawnCreate(apiName, token);
      const pkRes = await apiService.getPublicKey(token);
      dispatch({
        type: SPAWN_CREATE,
        payload: {
          publicKey: pkRes.data.anyone,
          pubAskedQuestion: res.data.jid,
        },
      });
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  const onSave = async () => {
    setLoading(true);
    const isNameExisting = getDuplicateName();

    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('Channel name already exists.');
      }
      const res = await apiService.createIntegration(
        sentinel,
        botJid,
        {
          ...fullIntegrationData,
          identifier: tempIdentifier || channelName,
          path: tempPath || path,
        },
        'web',
        token
      );

      if (!publicKey) {
        try {
          await spawnCreate();
        } catch (error) {
          setLoading(false);
          return message.error(error.message || DEFAULT_ERROR_MESSAGE);
        }
      }

      const folderPath = stripUUID(botJid);
      const integrationConfigURL = `${folderPath}/${stripUUID(
        stripUUID(res.data.report[0].jid)
      )}/config.json`;

      await BlobStorageService.uploadConfigJsonData(
        res.data.report[0].context.settingsobj,
        integrationConfigURL
      );

      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.int_type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };

      localStorage.setItem(INTEGRATION_DATA, JSON.stringify(payload));

      dispatch({ type: ADD_INTEGRATION, payload });
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const onUpdate = async () => {
    setLoading(true);
    // plz move me to a separate form component
    const isNameExisting = getDuplicateName(currentIntegrationId);
    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('A channel with the same name already exists.');
      }
      const res = await apiService.editIntegration(
        sentinel,
        currentIntegrationId,
        {
          ...fullIntegrationData,
          identifier: tempIdentifier || channelName,
          path: tempPath || path,
          settings: {
            ...fullIntegrationData.settings,
            launcherAvatar: isLauncherAvatarCustom
              ? launcherAvatarCloudPath
              : launcherAvatar,
            widgetIconUrl:
              widgetVersion === 'v1' && isLauncherAvatarCustom
                ? launcherAvatarCloudPath
                : undefined,
            headerAvatar: isHeaderAvatarCustom
              ? headerAvatarCloudPath
              : headerAvatar,
            avatar:
              widgetVersion === 'v1' && isHeaderAvatarCustom
                ? headerAvatarCloudPath
                : undefined,
          },
        },
        'web',
        token
      );

      const folderPath = stripUUID(botJid);
      const integrationConfigURL = `${folderPath}/${stripUUID(
        stripUUID(res.data.report[0].jid)
      )}/config.json`;

      await BlobStorageService.uploadConfigJsonData(
        res.data.report[0].context.settingsobj,
        integrationConfigURL
      );

      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.int_type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };
      localStorage.setItem(INTEGRATION_DATA, JSON.stringify(payload));

      dispatch({ type: UPDATE_INTEGRATION, payload });
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleChangeFields = (name, value) => {
    switch (name) {
      case 'identifier':
        setIdentifier(value);
        break;
      case 'path':
        setPath(value);
        break;
      case 'widgetType':
        dispatch({ type: SET_INTEGRATION_WIDGET_TYPE, payload: value });
        break;
      case 'clear':
        if (showColorPicker) {
          setShowColorPicker(!showColorPicker);
        }
        dispatch({
          type: RESET_INTEGRATION_SETTINGS,
        });
        break;
      default:
        break;
    }
  };

  const handleBlurFormField = name => {
    switch (name) {
      case 'identifier':
        if (tempIdentifier && channelName !== tempIdentifier) {
          dispatch({
            type: SET_INTEGRATION_IDENTIFIER,
            payload: tempIdentifier || channelName,
          });
        }
        break;
      case 'path':
        if (tempPath && path !== tempPath) {
          dispatch({
            type: SET_INTEGRATION_PATH,
            payload: tempPath || path,
          });
        }
        break;
      default:
        break;
    }
  };

  const handleSaveChannelInfo = async evt => {
    setLoading(true);
    evt.preventDefault();

    const isNewIntegration =
      !localIntegration || !localIntegration?.id || !currentIntegrationId;

    try {
      const res = isNewIntegration ? await onSave() : await onUpdate();
      if (isNewIntegration) {
        dispatch({
          type: SAVE_NEW_INTEGRATION,
          payload: res,
        });
        message.success(
          isNewIntegration
            ? `Integration channel added`
            : `Successfully updated integration channel`
        );
      } else {
        return;
      }
    } catch (error) {
      return message.error(
        error || `An error encountred while adding the integration channel.`
      );
    }
    setLoading(false);
  };

  const handlePanelChange = panel => {
    const panelNumber = panel?.split('-').pop();
    if (
      permit >= 3 &&
      panelNumber < permit &&
      !isPostCustomizationEditable &&
      !isPostCustomizationEditModalOpen
    ) {
      dispatch({
        type: SHOW_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL,
        payload: { panel: Number(panelNumber) },
      });
    } else if (panelNumber <= permit) {
      dispatch({
        type: OPEN_EDIT_PANEL_INTEGRATION,
        payload: { panel: Number(panelNumber) },
      });
    } else if (permit < panelNumber) {
      return message.error(
        `Please complete the current step before you proceed to step ${panelNumber}.`
      );
    }
  };

  const handleChangeIntegrationMethod = e => {
    setIntegrationMethod(e);
  };

  useEffect(() => {
    if (
      localIntegration?.id &&
      localIntegration?.type !== currentIntegrationSettings?.type
    ) {
      dispatch({
        type: SET_CURRENT_INTEGRATION_DATA,
        payload: { ...localIntegration },
      });
    }
  }, [localIntegration]);

  useEffect(() => {
    if (isEmpty(localIntegration) && !isEmpty(currentIntegrationSettings)) {
      localStorage.setItem(
        INTEGRATION_DATA,
        JSON.stringify(currentIntegrationSettings)
      );
    }
    return () => {
      dispatch({
        type: RESET_INTEGRATION_SETTINGS,
      });
      localStorage.removeItem(INTEGRATION_DATA);
    };
  }, []);

  return {
    activePanel,
    currentIntegrationId,
    fullIntegrationData,
    loading,
    handleChangeFields,
    handleChangeIntegrationMethod,
    handlePanelChange,
    handleSaveChannelInfo,
    integrationMethod,
    onSave,
    onUpdate,
    INTEGRATIOM_METHODS,
    handleBlurFormField,
    tempIdentifier,
    tempPath,
    channelName,
    path,
  };
};

export default useWeb;
