import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Spin } from 'antd';
import { isEmpty } from 'lodash';

import {
  StyledActionBtns,
  StyledDivider,
  StyledEncircledNumber,
  StyledIntegrationSteps,
  StyledCollapsiblePanel,
  StyledCollapsiblePanelHeader,
  StyledPanelSubheader,
} from './Web.styles';
import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Select';
import useWeb from './hooks';
import BotCustomizer from 'components/BotCustomizer';
import {} from 'store/action';
import SandboxIntegration from './SandboxIntegration';
import NPMIntegration from './NPMIntegration';
import { INTEGRATION_PLATFORMS } from 'constants/integrationPlatforms';
import WidgetVersion from 'components/BotCustomizer/CustomizeBot/WidgetVersion';
import { StyledCollapsibleHeaderWithRadio } from 'components/BotCustomizer/CustomizeBot/CustomizeBot.style';

const Web = ({ isPageReady }) => {
  const {
    activePanel,
    currentIntegrationId,
    fullIntegrationData,
    loading,
    handleChangeFields,
    handleChangeIntegrationMethod,
    handlePanelChange,
    handleSaveChannelInfo,
    integrationMethod,
    onSave,
    onUpdate,
    INTEGRATIOM_METHODS,
    tempIdentifier,
    tempPath,
    handleBlurFormField,
    channelName,
    path,
  } = useWeb();

  const WEB_INTEGRATION_PANEL_KEY_PREFIX = 'web-integration-panel-';

  const getButtonValue = useMemo(() => {
    if (!!currentIntegrationId) {
      return 'Update';
    } else if (loading) {
      return 'Processing';
    }
    return 'Save';
  }, [currentIntegrationId, loading]);

  return (
    <Spin spinning={!isPageReady || loading}>
      <div>
        <Collapse
          activeKey={`${WEB_INTEGRATION_PANEL_KEY_PREFIX}${activePanel}`}
          accordion
          ghost
          onChange={handlePanelChange}
        >
          <StyledCollapsiblePanel
            showArrow={false}
            key={`${WEB_INTEGRATION_PANEL_KEY_PREFIX}1`}
            header={
              <StyledCollapsiblePanelHeader>
                <StyledEncircledNumber>{'1'}</StyledEncircledNumber>
                <span>{'Channel information'}</span>
              </StyledCollapsiblePanelHeader>
            }
          >
            <StyledIntegrationSteps>
              <form onSubmit={e => handleSaveChannelInfo(e)}>
                <Input
                  disabled={Boolean(currentIntegrationId)}
                  defaultValue={channelName}
                  value={tempIdentifier}
                  label="Channel Name"
                  placeholder={
                    // FIX ME: first input field of a form gets undefined
                    channelName || 'My Website integration name'
                  }
                  onChange={e =>
                    handleChangeFields('identifier', e.target.value)
                  }
                  required
                  onBlur={() => handleBlurFormField('identifier')}
                />
                <Input
                  defaultValue={path}
                  label="Website URL"
                  placeholder="Enter website URL"
                  value={tempPath}
                  onChange={e => handleChangeFields('path', e.target.value)}
                  onBlur={() => handleBlurFormField('path')}
                />
                <Select
                  disabled
                  onChange={e => handleChangeFields('type', e)}
                  label="Integration Platform"
                  value={INTEGRATION_PLATFORMS['web']}
                />
                <StyledActionBtns>
                  <Button
                    value={getButtonValue}
                    type="submit"
                    loading={loading}
                  />
                  <Button
                    variant="secondary"
                    value="Reset"
                    type="button"
                    onClick={() => handleChangeFields('clear')}
                  />
                </StyledActionBtns>
              </form>
            </StyledIntegrationSteps>
          </StyledCollapsiblePanel>
          <StyledDivider />

          <StyledCollapsiblePanel
            showArrow={false}
            key={`${WEB_INTEGRATION_PANEL_KEY_PREFIX}2`}
            header={
              <StyledCollapsibleHeaderWithRadio>
                <StyledCollapsiblePanelHeader>
                  <StyledEncircledNumber>{'2'}</StyledEncircledNumber>
                  <span>{'Personalise your bot'}</span>
                </StyledCollapsiblePanelHeader>
                {activePanel === 2 ? <WidgetVersion /> : null}
              </StyledCollapsibleHeaderWithRadio>
            }
          >
            {!isEmpty(fullIntegrationData.settings) ? (
              <BotCustomizer onSave={onSave} onUpdate={onUpdate} />
            ) : null}
          </StyledCollapsiblePanel>
          <StyledDivider />

          <StyledCollapsiblePanel
            showArrow={false}
            key={`${WEB_INTEGRATION_PANEL_KEY_PREFIX}3`}
            header={
              <StyledCollapsiblePanelHeader>
                <StyledEncircledNumber>{'3'}</StyledEncircledNumber>
                <span>{'Save and Integrate'}</span>
              </StyledCollapsiblePanelHeader>
            }
          >
            <StyledIntegrationSteps>
              <StyledPanelSubheader>
                {`You are done. Copy the code into your file. Analytics and
                reports for this integration can be viewed in integrated
                channels.`}
              </StyledPanelSubheader>
              <br />
              <Select
                defaultValue={integrationMethod}
                placeholder="Select a method"
                label="Select a integration method"
                onChange={handleChangeIntegrationMethod}
                options={INTEGRATIOM_METHODS}
              />
              <br />
              {!isEmpty(fullIntegrationData.settings) ? (
                integrationMethod === 'html' ? (
                  <SandboxIntegration />
                ) : (
                  <NPMIntegration />
                )
              ) : null}
            </StyledIntegrationSteps>
          </StyledCollapsiblePanel>
        </Collapse>
      </div>
    </Spin>
  );
};

Web.propTypes = {
  isPageReady: PropTypes.bool,
};

export default Web;
