import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Menu } from 'antd';

import { Context } from 'store/store';
import Button from 'components/Button';
import { SET_SHOW_ANSWER_VERSIONS } from 'store/action';

const AnswerVersionsMenu = ({ handleAnswerDropdownVisibilityChange }) => {
  const [state, dispatch] = useContext(Context);
  const {
    bot: { showAnswersVersions },
  } = state;
  const [isDraftChecked, setIsDraftChecked] = useState(false);
  const [isFinalChecked, setIsFinalChecked] = useState(false);

  const handleToggleShowDraft = dropDownVisivility => {
    handleAnswerDropdownVisibilityChange(dropDownVisivility);
    const payload =
      isDraftChecked && isFinalChecked
        ? ['draft', 'final']
        : isDraftChecked
        ? ['draft']
        : isFinalChecked
        ? ['final']
        : [];
    dispatch({
      type: SET_SHOW_ANSWER_VERSIONS,
      payload,
    });
  };

  return (
    <Menu>
      <Menu.Item key={'draft'}>
        <Checkbox
          onChange={e =>
            e.target.checked && !isDraftChecked
              ? setIsDraftChecked(true)
              : setIsDraftChecked(false)
          }
          defaultChecked={showAnswersVersions?.includes('draft')}
        >
          {'Draft'}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key={'final'}>
        <Checkbox
          defaultChecked={showAnswersVersions?.includes('final')}
          onChange={e =>
            e.target.checked && !isFinalChecked
              ? setIsFinalChecked(true)
              : setIsFinalChecked(false)
          }
        >
          {'Final'}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key={'version-submit'}>
        <Button
          size={'small'}
          value={'OK'}
          variant="white"
          onClick={() => handleToggleShowDraft(false)}
        />
      </Menu.Item>
    </Menu>
  );
};

AnswerVersionsMenu.propTypes = {
  handleAnswerDropdownVisibilityChange: PropTypes.func.isRequired,
};

export default AnswerVersionsMenu;
