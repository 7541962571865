import { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { message } from 'antd';
import { isEqual } from 'lodash';
import { ZSB_CHAT_BREAKER_ELEMENT } from 'constants/answerbank/defaults';

const SAS_TOKEN = process.env.REACT_APP_AZURE_SAS_TOKEN;
const AZURE_STORAGE_URI = process.env.REACT_APP_AZURE_STORAGE_URI;
const QUILL_IMAGES_CONTAINER = 'quill-images';

const useRichTextEditor = ({
  answer,
  answerId,
  setRichTextAnswer,
  isADefaultAnswer,
}) => {
  const [value, setValue] = useState(answer);
  const [loading, setLoading] = useState(false);
  const uuidPrefix = 'urn:uuid:';
  const blobServiceClient = new BlobServiceClient(
    `${AZURE_STORAGE_URI}?${SAS_TOKEN}`
  );
  const containerClient = blobServiceClient.getContainerClient(
    QUILL_IMAGES_CONTAINER
  );

  const folderPath = answerId
    ? answerId.split(uuidPrefix).pop()
    : isADefaultAnswer
    ? 'default-answers'
    : '';

  const imageHandler = quillRef => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      const { name, type } = file;
      const blockImgBlob = containerClient.getBlockBlobClient(
        folderPath + '/' + name
      );

      const quillImageBlob = new Blob([file], {
        type: type,
      });
      const blobOptions = {
        blobHTTPHeaders: {
          blobContentType: type,
          blobCacheControl: 'public, max-age=0',
        },
      };

      const range = quillRef.current.getEditorSelection();
      const uploadedImgPath = `${AZURE_STORAGE_URI}${QUILL_IMAGES_CONTAINER}/${folderPath}/${name}`;
      try {
        setLoading(true);
        await blockImgBlob.upload(
          quillImageBlob,
          quillImageBlob.size,
          blobOptions
        );

        if (uploadedImgPath) {
          quillRef.current
            .getEditor()
            .insertEmbed(range.index, 'image', uploadedImgPath);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        return message.error(
          error.message || 'Something went wrong while adding your image'
        );
      }
    };
  };

  const handleChange = (content, delta, source, editor) => {
    setValue(content);
    setRichTextAnswer(content, editor?.getText());
  };

  useEffect(() => {
    setValue(value);
    return () => setValue(null);
  }, [value]);

  const getLinesAroundCursor = (editor, range) => {
    const lineBefore = editor.getText(range.index - 1, range.index);
    const lineAfter = editor.getText(range.index, range.index + 1);

    return [lineBefore, lineAfter];
  };

  const zsbLineBreak = quillRef => {
    const zsbchatBreakerText = `\n<zsb-chat-breaker />\n`;
    const range = quillRef.current.getEditorSelection();
    const editor = quillRef.current.getEditor();
    const cursorPosition = editor.getSelection().index;

    const text = editor?.getText();
    const content = editor?.getContents()?.ops;
    const isCursorAtEnd = cursorPosition === Number(text.length) - 1;
    const quillTextEachLine = text.split('\n');

    const lineIndex = quillTextEachLine.findIndex(
      line => cursorPosition >= line.length
    );

    if (lineIndex === -1) {
      return;
    }

    if (range) {
      const filteredQuilltext = (quillTextEachLine || []).filter(
        text => text?.length
      );
      const lastLineWithText =
        filteredQuilltext[filteredQuilltext?.length - 1] || '';
      const [lineBefore, lineAfter] = getLinesAroundCursor(editor, range);
      const isAboveChatBreak = isEqual(lineAfter, ZSB_CHAT_BREAKER_ELEMENT);
      const isBelowChatBreak = isEqual(lineBefore, ZSB_CHAT_BREAKER_ELEMENT);

      if (
        (isCursorAtEnd &&
          lastLineWithText.endsWith(ZSB_CHAT_BREAKER_ELEMENT)) ||
        (!isCursorAtEnd &&
          (lastLineWithText.endsWith(ZSB_CHAT_BREAKER_ELEMENT) ||
            !lineBefore.trim() ||
            isAboveChatBreak ||
            isBelowChatBreak) &&
          !content[content.length - 2]?.insert?.image)
      ) {
        return;
      }
    }
    editor.insertText(range?.index, zsbchatBreakerText);
  };

  return {
    imageHandler,
    handleChange,
    loading,
    value,
    zsbLineBreak,
  };
};
export default useRichTextEditor;
