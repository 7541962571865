import { CHAT_ERROR_REPLY } from 'constants/answerbank/chatlist';
import {
  ADD_TO_CHAT_LIST,
  CLEAR_CHAT_LIST,
  PUSH_CHAT_ANSWER,
  PUSH_CHAT_ERROR_REPLY,
  PUSH_CHAT_QUESTION,
  UPDATE_CHAT_LIST_ITEM,
} from 'store/action';
import { extractAnswerData } from '../helpers/bot/answers';

export const chatListReducer = (state, action) => {
  switch (action.type) {
    case ADD_TO_CHAT_LIST:
      return {
        ...state,
        bot: {
          ...state.bot,
          chatList: [...state.bot.chatList, ...action.payload],
        },
      };

    case PUSH_CHAT_QUESTION: {
      return {
        ...state,
        bot: {
          ...state.bot,
          chatList: [
            ...state.bot.chatList,
            {
              question: action.payload?.text,
              // add question payload sent to api
              questionPayload: action.payload,
            },
          ],
        },
      };
    }

    case PUSH_CHAT_ERROR_REPLY: {
      const { chatList } = state.bot;

      const updatedChatList = chatList.map((chatItem, idx) => {
        if (idx === chatList?.length - 1 && !chatItem.answer) {
          return {
            ...chatItem,
            answer: CHAT_ERROR_REPLY,
            answerContext: {
              error: action.payload,
            },
          };
        }
        return chatItem;
      });

      return {
        ...state,
        bot: {
          ...state.bot,
          chatList: updatedChatList,
        },
      };
    }

    case PUSH_CHAT_ANSWER: {
      const { chatList } = state.bot;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const chatAnswer = extractAnswerData(action.payload, isBotOpenAIEnabled);

      const updatedChatList = chatList.map((chatItem, idx) => {
        if (idx === chatList?.length - 1 && !chatItem.answer) {
          return {
            ...chatItem,
            answer: chatAnswer,
            answerContext: {
              ...action.payload?.context,
              error: action.payload?.error || undefined,
              name: action.payload?.name,
            },
          };
        }
        return chatItem;
      });
      return {
        ...state,
        bot: {
          ...state.bot,
          chatList: updatedChatList,
        },
        webSocket: {
          ...state.webSocket,
          askQuestionActions: [],
          askQuestionActiveAction: null,
          sending: false,
        },
      };
    }

    case CLEAR_CHAT_LIST:
      return {
        ...state,
        bot: {
          ...state.bot,
          chatList: [],
        },
      };

    case UPDATE_CHAT_LIST_ITEM: {
      const { answer, msgIndex, chatItemToChangeAnswerJID } = action.payload;
      const { chatList } = state.bot;
      const updatedChatList = [];
      let currentChatItem = {};

      if (chatList.length) {
        for (let i = 0; i < chatList?.length; i++) {
          if (msgIndex === i) {
            currentChatItem = {
              question: chatList[i].question,
              answer: {
                jid: answer.jid,
                show_text: answer.show_text,
                show_html: answer.show_html || answer.show_text,
                text: answer.text,
                qlinks: answer.qlinks || null,
                score: answer.score || null,
                hitcount: answer.hitcount || null,
                editor: answer.editor || null,
                type: answer.type,
                sequence: answer.sequence || [],
              },
              idx: i,
            };
            updatedChatList.push(currentChatItem);
          } else {
            updatedChatList.push({ ...chatList[i] });
          }
        }
      }

      return {
        ...state,
        bot: {
          ...state.bot,
          chatList: updatedChatList,
        },
      };
    }

    default:
      return state;
  }
};
