import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { Context } from 'store/store';
import {
  StyledNewUserModal,
  StyledNewUserModalContainer,
} from './NewUserModal.style';
import { SHOW_BOT_MODAL } from 'store/action';
import { Col, Row } from 'antd';

const NewBotModal = ({ title, visible, onCancel }) => {
  const [state, dispatch] = useContext(Context);
  const { user } = state;

  return (
    <StyledNewUserModal
      visible={visible}
      okButtonProps={{
        disabled: false,
        style: { display: 'none' },
      }}
      cancelButtonProps={{
        disabled: false,
        style: { display: 'none' },
      }}
      onCancel={onCancel}
      footer={null}
    >
      <StyledNewUserModalContainer>
        <p className="heading">Hello {user?.name || 'User'},</p>
        <p className="heading"> Welcome to ZeroShotBot!</p>
        <p className="text-content">
          ZeroShotBot is a no-code, no-train bot platform. First of its kind.
          Just add your answers in the answer bank and deploy your bot. Select a
          path you’d like to get started with.
        </p>

        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Button
              type="primary"
              value="CREATE BOT"
              onClick={() =>
                dispatch({
                  type: SHOW_BOT_MODAL,
                  payload: {
                    action: 'add',
                  },
                })
              }
              full
            />
          </Col>
          <Col xs={24} md={12}>
            <Button
              value="READ DOCUMENTATION"
              onClick={() =>
                window.open('https://docs.zeroshotbot.com/', '_black')
              }
              full
            />
          </Col>
          <Col xs={24} md={12}>
            <Button
              value="SKIP, I'LL EXPLORE LATER"
              onClick={() => onCancel()}
              variant="secondary"
              full
            />
          </Col>
        </Row>
      </StyledNewUserModalContainer>
    </StyledNewUserModal>
  );
};

NewBotModal.propTypes = {
  onCancel: PropTypes.func,
  title: PropTypes.string,
  visible: PropTypes.bool,
};

export default NewBotModal;
