import React from 'react';
import PropTypes from 'prop-types';
import { stripUUID } from 'utils';
import AnswerItem from 'components/AnswerItem';
import EmptyAnswer from 'components/EmptyAnswer';

const AnswerList = ({
  filteredAnswers,
  handleChangeCheckbox,
  handleShowAnswerEditorModal,
  handleShowFileEditorModal,
  handleShowLinkedQuestions,
  handleShowAskedQuestions,
  onDelete,
  onUpdateCategoryAnswer,
  searchKey,
  searchResults,
  showDisplayAnswer,
  selectedAnswers,
  tab,
  handleSelectAll,
  totalSelectedAnswers,
  isWebsite,
}) => {
  const renderTitleRow = () => (
    <AnswerItem
      visible={true}
      showDisplayAnswer={''}
      id={'titleRow'}
      answer={{}}
      onSelectAnswer={handleSelectAll}
      key={'titleRow'}
      totalSelectedAnswers={totalSelectedAnswers}
      isTitleRow
      isWebsite={isWebsite}
    />
  );

  if (searchKey && searchResults && searchResults.length) {
    const searchResultsAnswerList = searchResults.map(answer => (
      <AnswerItem
        visible={
          stripUUID(tab.key) === stripUUID(answer.categoryId) ||
          tab.key === 'all'
        }
        showDisplayAnswer={showDisplayAnswer}
        id={answer.jid}
        className={`answer-${stripUUID(answer.jid)}`}
        answer={answer}
        isSelected={selectedAnswers.includes(answer.jid)}
        onDelete={() => onDelete(answer.jid)}
        onSelectAnswer={handleChangeCheckbox}
        handleShowAnswerEditorModal={handleShowAnswerEditorModal}
        handleShowFileEditorModal={handleShowFileEditorModal}
        onUpdateCategoryAnswer={onUpdateCategoryAnswer}
        key={answer.jid}
        handleShowLinkedQuestions={handleShowLinkedQuestions}
        handleShowAskedQuestions={handleShowAskedQuestions}
        isWebsite={isWebsite}
      />
    ));
    return (
      <>
        {renderTitleRow()}
        {searchResultsAnswerList}
      </>
    );
  } else if (!searchKey && filteredAnswers && filteredAnswers.length) {
    const filteredAnswersList = filteredAnswers.map(answer => (
      <AnswerItem
        visible={
          stripUUID(tab.key) === stripUUID(answer.categoryId) ||
          tab.key === 'all'
        }
        showDisplayAnswer={showDisplayAnswer}
        id={answer.jid}
        className={`answer-${stripUUID(answer.jid)}`}
        answer={answer}
        isSelected={selectedAnswers.includes(answer.jid)}
        onDelete={() => onDelete(answer.jid)}
        onSelectAnswer={handleChangeCheckbox}
        handleShowAnswerEditorModal={handleShowAnswerEditorModal}
        handleShowFileEditorModal={handleShowFileEditorModal}
        onUpdateCategoryAnswer={onUpdateCategoryAnswer}
        key={answer.jid}
        handleShowLinkedQuestions={handleShowLinkedQuestions}
        handleShowAskedQuestions={handleShowAskedQuestions}
        isWebsite={isWebsite}
      />
    ));

    return (
      <>
        {renderTitleRow()}
        {filteredAnswersList}
      </>
    );
  } else {
    return (
      <EmptyAnswer
        loading={false}
        subtitle={
          searchKey
            ? `No answer found. Its seems you typed some jibberish stuff huh? :/`
            : 'No answers found.'
        }
      />
    );
  }
};

AnswerList.propTypes = {
  filteredAnswers: PropTypes.any,
  handleChangeCheckbox: PropTypes.func,
  handleShowAnswerEditorModal: PropTypes.func,
  handleShowFileEditorModal: PropTypes.func,
  handleShowLinkedQuestions: PropTypes.func,
  handleShowAskedQuestions: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdateCategoryAnswer: PropTypes.any,
  searchKey: PropTypes.string,
  searchResults: PropTypes.any,
  showDisplayAnswer: PropTypes.bool,
  selectedAnswers: PropTypes.any,
  tab: PropTypes.any,
  handleSelectAll: PropTypes.func,
  totalSelectedAnswers: PropTypes.number,
  isWebsite: PropTypes.bool,
};
export default AnswerList;
