import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DeleteFilled, EditOutlined } from '@ant-design/icons';

import {
  StyledQuickReplyLabel,
  StyledQuickReplyAnswerList,
  StyledLabel,
  StyledAnswerLabel,
  StyledDeleteRowButton,
} from './QuickReplyFormRow.styles';
import { StyledFlexRowCenter } from 'styles/GenericStyledComponents';
import { cssVariables } from 'styles/root';
import { allAnswersSelector } from 'selectors/bot/answers';
import { Context } from 'store/store';
import Button from 'components/Button';
import UnlinkIcon from 'assets/images/unlink-solid.svg';
import LinkIcon from 'assets/images/link-solid.svg';
import HorizontalDivider from 'components/HorizontalDivider';

const QuickReplyFormRow = props => {
  const {
    deleteRow,
    index,
    isRemoveRowDisabled,
    item,
    onQuickReplyChange,
    ...rest
  } = props;
  const [state] = useContext(Context);
  const allAnswers = allAnswersSelector(state);

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const answerList = useMemo(() => {
    return allAnswers.map(answer => ({
      label: answer.text,
      value: answer.jid,
    }));
  }, [allAnswers, item?.answer]);

  const actionSelected = useMemo(() => {
    if (!item.editableQuestion && item.answer) {
      return 'Update';
    } else if (item.editableQuestion || item.answer) {
      return 'Unlink';
    } else {
      return 'Link';
    }
  }, [item.editableQuestion, item.answer]);

  return (
    <div {...rest}>
      <HorizontalDivider />
      <StyledFlexRowCenter style={{ marginTop: 10 }}>
        <StyledLabel style={{ width: '23%', marginLeft: '0' }}>
          Question:
        </StyledLabel>
        <StyledQuickReplyLabel
          full
          value={item.question}
          placeholder="Enter question"
          size="default"
          maxLength={40}
          onChange={e => onQuickReplyChange(e, 'question', item.key)}
          disabled={!item.editableQuestion && item.answer}
        />
        <StyledLabel style={{ width: '23%', marginLeft: '1%' }}>
          Display:
        </StyledLabel>
        <StyledQuickReplyLabel
          full
          value={item.label}
          placeholder="Enter Display Reply"
          size="default"
          maxLength={40}
          onChange={e => onQuickReplyChange(e, 'label', item.key)}
          disabled={!item.editableQuestion && item.answer}
        />
        <Button
          startIcon={
            actionSelected === 'Update' ? (
              <EditOutlined />
            ) : actionSelected === 'Unlink' ? (
              <img
                src={UnlinkIcon}
                alt={'Unlink Answer'}
                className="unlinkedIcon"
              />
            ) : (
              <img src={LinkIcon} alt={'Link Answer'} className="linkedIcon" />
            )
          }
          value={actionSelected}
          variant="link"
          style={{ fontSize: 16 }}
          onClick={e =>
            onQuickReplyChange(
              e,
              actionSelected === 'Unlink' ? 'unlink' : 'edit',
              item.key
            )
          }
          size="small"
        />
        <StyledDeleteRowButton>
          <DeleteFilled
            style={{ color: cssVariables.errorText }}
            onClick={() => deleteRow(item.key)}
          />
        </StyledDeleteRowButton>
      </StyledFlexRowCenter>
      {(actionSelected === 'Unlink' || actionSelected === 'Update') && (
        <StyledFlexRowCenter style={{ marginTop: 10 }}>
          <StyledLabel style={{ width: '8%', marginLeft: '0' }}>
            Linked Answer:
          </StyledLabel>
          <StyledQuickReplyAnswerList
            placeholder="Search Answer"
            defaultValue={item.answer}
            onChange={e => onQuickReplyChange(e, 'answer', item.key)}
            options={answerList}
            filterOption={filterOption}
            showSearch
            hidden={!item.editableQuestion}
          />
          <StyledAnswerLabel
            hidden={item.editableQuestion}
            onClick={e => onQuickReplyChange(e, 'edit', item.key)}
          >
            {allAnswers?.find(answer => answer.jid === item.answer)?.text}
          </StyledAnswerLabel>
        </StyledFlexRowCenter>
      )}
    </div>
  );
};

QuickReplyFormRow.propTypes = {
  deleteRow: PropTypes.func,
  index: PropTypes.number,
  isRemoveRowDisabled: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onQuickReplyChange: PropTypes.func.isRequired,
};

export default QuickReplyFormRow;
