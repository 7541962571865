import styled from 'styled-components';
import Modal from 'components/Modals/GenericModal';
import { CloseOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';
import { device } from 'constants/screens';
const { TextArea } = Input;

export const StyledModal = styled(Modal)`
  & .ant-modal-close {
    display: none;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCloseButton = styled(CloseOutlined)`
  color: #33363c;
  background: white;
  height: 20px;
  display: block;
`;

export const StyledTitle = styled.h2`
  font-style: normal;
  font-weight: 550;
  font-size: 24px;
  color: #31353e;
  margin-top: 10px;
`;

export const StyledNameTextfieldWrapper = styled.div`
  margin: 1rem 0;
`;

export const StyledNameTextfield = styled(Input)`
  box-sizing: border-box;
  height: 42px;
  background: #f4f6f8;
  border: ${prop =>
    prop.error ? '1px solid red' : '1px solid rgba(65, 108, 114, 0.05)'};
  border-radius: 6px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  &:hover,
  :focus {
    border: ${prop =>
      prop.error ? '1px solid red' : '1px solid rgba(65, 108, 114, 0.05)'};
  }
`;

export const StyledValueTextfieldWrapper = styled.div`
  margin: 1rem 0;
`;

export const StyledValueTextarea = styled(TextArea)`
  padding: 1rem 0.75rem 1rem 0.75rem;
  box-sizing: border-box;
  width: 465px;
  height: 120px;
  background: #f4f6f8;
  border: ${prop =>
    prop.error ? '1px solid red' : '1px solid rgba(65, 108, 114, 0.05)'};
  border-radius: 6px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  &:hover,
  :focus {
    border: ${prop =>
      prop.error ? '1px solid red' : '1px solid rgba(65, 108, 114, 0.05)'};
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 2.5rem;
  justify-content: space-between;

  @media all and (${device.laptopL}) {
    gap: 10px;
  }
`;

export const StyledSaveAddAnother = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  width: 176px;
  height: 41px;
  background: rgba(51, 54, 60, 0.2);
  border-radius: 7px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;

  &:hover,
  :focus {
    color: black;
    background: rgba(51, 54, 60, 0.2);
  }

  @media all and (${device.laptopL}) {
    padding: 6px 10px;
  }
`;

export const StyledSave = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  width: 70px;
  height: 41px;
  background: #1667e7;
  border-radius: 7px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: #ffffff;
  border: none;

  &:hover,
  :focus {
    color: #ffffff;
    background: #1667e7;
  }

  @media all and (${device.laptopL}) {
    padding: 6px 10px;
  }
`;
