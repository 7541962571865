import React, { useContext, useMemo } from 'react';

import Logo from 'assets/images/zeroshotbot_black.png';
import AuthBanner from 'assets/images/auth-banner.png';
import useAuth from './hooks';
import {
  StyledAuth,
  StyledImageBanner,
  StyledBannerWrapper,
  StyledLogo,
  StyledSectionWrapper,
  StyledFormContent,
} from './StyledComponents';
import { StyledFlexColumn } from 'styles/GenericStyledComponents';
import SignUp from './SignUp';
import { Context } from 'store/store';
import SignIn from './SignIn';
import ResetPassword from 'pages/ResetPassword';
import { SET_PAGE_EXTERNAL, UNSET_PAGE_EXTERNAL } from 'store/action';

const Auth = () => {
  const { pathname } = useAuth();
  const [state, dispatch] = useContext(Context);

  const AuthView = useMemo(() => {
    if (pathname) {
      if (pathname.includes('external-login')) {
        localStorage.setItem('external', 'true');
        dispatch({ type: SET_PAGE_EXTERNAL });
        return <SignIn />;
      }

      if (
        pathname.includes('login') ||
        pathname.includes('sign-in') ||
        pathname === '/'
      ) {
        dispatch({ type: UNSET_PAGE_EXTERNAL });
        localStorage.removeItem('external');
        return <SignIn />;
      }

      if (pathname.includes('signup')) {
        return <SignUp />;
      }

      if (pathname.includes('reset-password')) {
        return <ResetPassword />;
      }
    }
    localStorage.removeItem('external');
    return <SignIn />;
  }, [pathname]);

  return (
    <StyledAuth>
      <StyledBannerWrapper>
        <StyledFlexColumn>
          <h1>{`It's easy peasey`}</h1>
          <StyledFlexColumn>
            <span>Build your own Next-Gen AI.</span>
            <span>Easy, fast and reliable. No coding required.</span>
          </StyledFlexColumn>
        </StyledFlexColumn>
        <StyledImageBanner src={AuthBanner} alt="Banner" />
      </StyledBannerWrapper>
      <StyledSectionWrapper>
        <StyledFormContent>
          <StyledLogo src={Logo} alt="Logo" />
          {AuthView}
        </StyledFormContent>
      </StyledSectionWrapper>
    </StyledAuth>
  );
};

export default Auth;
