import { useState, useEffect } from 'react';

const useFeedbackModal = ({ interactionID, questions }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      questions.find(question => question.interactionID === interactionID)
    );
  }, []);

  return {
    data,
  };
};

export default useFeedbackModal;
