import React from 'react';
import { Typography } from 'antd';
import Input from 'components/Input';
import useResetPassword from './hooks';
import Alert from 'components/Alert';
import Button from 'components/Button';
import {
  StyledResetPassword,
  StyledHeaderTitle,
  StyledResetPasswordFields,
  StyledForm,
} from './ConfirmResetPassword.style';

const { Title, Text } = Typography;

const ConfirmResetPassword = () => {
  const {
    error,
    loading,
    onChangePassword,
    onChangeConfirmPassword,
    onSubmit,
  } = useResetPassword();

  return (
    <StyledResetPassword>
      <StyledHeaderTitle>
        <Title level={5}>Reset Password</Title>
        <Text>New password must be 8 character or more.</Text>
      </StyledHeaderTitle>
      <StyledForm onSubmit={onSubmit}>
        {error && <Alert type="error" message={error} />}
        <StyledResetPasswordFields>
          <Input
            label="New Password"
            type="password"
            onChange={onChangePassword}
          />
          <Input
            label="Confirm Password"
            type="password"
            onChange={onChangeConfirmPassword}
            required
          />
        </StyledResetPasswordFields>
        <Button
          type="submit"
          full
          value="Reset Password"
          variant="primary"
          size="large"
          loading={loading}
        />
      </StyledForm>
    </StyledResetPassword>
  );
};

export default ConfirmResetPassword;
