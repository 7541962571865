import styled from 'styled-components';
import { Table } from 'antd';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

export const StyledTable = styled(Table)`
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);

  & .ant-table-tbody > tr > td {
    padding: 18px;
  }

  & tr > td {
    border-right: 1px solid #33363c1a;
    padding: 12px !important;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  & button {
    margin-right: 15px;
  }
`;
