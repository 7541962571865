import React from 'react';
import { message, Typography } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyTwoTone } from '@ant-design/icons';

import {
  StyledCodeBlock,
  StyledCodeButton,
  StyledIntegrationInstruction,
  StyledSnippets,
} from './Web.styles';
import { StyledDemoLink } from 'components/CodeCopier/CodeCopier.styles';
import { cssVariables } from 'styles/root';
import useSelector from 'store/useSelector';
import {
  botComponentSelector,
  integrationConfigDataSelector,
} from 'selectors/bot/integration/settings';
import { widgetVersionSelector } from 'selectors/bot/integration';
import { generateAuthenticatedUserNPMHTMLTag } from 'services/fileGenerator.service';

const { Paragraph } = Typography;
const PACKAGE_NAME = process.env.REACT_APP_WIDGET_PACKAGE_NAME;
const PACKAGE_NAME_V2 = process.env.REACT_APP_WIDGET_V2_PACKAGE_NAME;
const NPM_LINK = process.env.REACT_APP_WIDGET_NPM_LINK;
const NPM_V2_LINK = process.env.REACT_APP_WIDGET_V2_NPM_LINK;

const NPMIntegration = () => {
  const zsbComponent = useSelector(botComponentSelector);
  const widgetVersion = useSelector(widgetVersionSelector);
  const authenticatedUser = useSelector(integrationConfigDataSelector);

  const npmInstallScript = `npm i ${
    widgetVersion === 'v1' ? PACKAGE_NAME : PACKAGE_NAME_V2
  }`;
  const yarnInstallScript = `yarn add ${
    widgetVersion === 'v1' ? PACKAGE_NAME : PACKAGE_NAME_V2
  }`;
  const npmjsLink = widgetVersion === 'v1' ? NPM_LINK : NPM_V2_LINK;

  const usageExample = `
  import '${widgetVersion === 'v1' ? PACKAGE_NAME : PACKAGE_NAME_V2}'
  
  const App = () => {
    ${generateAuthenticatedUserNPMHTMLTag(authenticatedUser)}
    return (
      <>
        ${zsbComponent}
      </>
    )
  }
  `;

  return (
    <StyledSnippets>
      <StyledIntegrationInstruction>
        <h1>{'Installation'}</h1>
        <CopyToClipboard text={npmInstallScript}>
          <StyledCodeButton className="secondary" value="Copy code" />
        </CopyToClipboard>
      </StyledIntegrationInstruction>
      <StyledCodeBlock>
        <Paragraph>
          <pre>
            <code>{npmInstallScript}</code>
          </pre>
        </Paragraph>
      </StyledCodeBlock>
      <StyledIntegrationInstruction>
        <span>{'OR'}</span>
        <CopyToClipboard text={yarnInstallScript}>
          <StyledCodeButton className="secondary" value="Copy code" />
        </CopyToClipboard>
      </StyledIntegrationInstruction>
      <StyledCodeBlock>
        <Paragraph>
          <pre>
            <code>{yarnInstallScript}</code>
          </pre>
        </Paragraph>
      </StyledCodeBlock>

      <StyledIntegrationInstruction>
        <h1>{'Usage'}</h1>
        <CopyToClipboard text={usageExample}>
          <StyledCodeButton className="secondary" value="Copy code" />
        </CopyToClipboard>
      </StyledIntegrationInstruction>
      <StyledCodeBlock>
        <Paragraph>
          <pre>
            <code>{usageExample}</code>
          </pre>
        </Paragraph>
      </StyledCodeBlock>
      <br />
      <StyledIntegrationInstruction>
        <h1>{'Documentation'}</h1>
      </StyledIntegrationInstruction>
      <StyledDemoLink>
        <a href={npmjsLink} target="_blank" rel="noreferrer">
          {npmjsLink}
        </a>
        <CopyToClipboard text={npmjsLink}>
          <CopyTwoTone
            onClick={() => message.info('Copied to clipboard.')}
            twoToneColor={cssVariables.primaryBlue}
          />
        </CopyToClipboard>
      </StyledDemoLink>
    </StyledSnippets>
  );
};

export default NPMIntegration;
