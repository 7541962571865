import { RESET_PLAN, SET_PLAN, SET_PLAN_DETAILS } from 'store/action';
import { initialPlanState } from 'store/initialState';

export const planReducer = (state, action) => {
  switch (action.type) {
    case SET_PLAN:
      return {
        ...state,
        plan: {
          ...state.plan,
          ...action.payload,
        },
      };

    case RESET_PLAN: {
      return {
        ...state,
        plan: initialPlanState,
        bots: [],
        bot: {
          ...state.bot,
          files: [],
          websites: [],
          answers: [],
          integrations: [],
          categories: [],
        },
      };
    }

    case SET_PLAN_DETAILS: {
      return {
        ...state,
        plan: {
          ...state.plan,
          plansAndFeatures: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
