import React from 'react';
import PropTypes from 'prop-types';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ANSWER_SORTING_LIST } from '../AnswerBank.constant';
import { StyledMenu, StyledSortSelected } from './SortDropdownMenu.style';

const SortDropdownMenu = ({ handleSortAnswers, answersOrder }) => {
  return (
    <StyledMenu>
      {ANSWER_SORTING_LIST.map(item => {
        return (
          <StyledSortSelected
            key={item.key}
            icon={item.key % 2 === 0 ? <DownOutlined /> : <UpOutlined />}
            onClick={() => handleSortAnswers(item)}
            answersOrderKey={answersOrder?.key}
            itemKey={item?.key}
          >
            {' '}
            {item.label}
          </StyledSortSelected>
        );
      })}
    </StyledMenu>
  );
};

SortDropdownMenu.propTypes = {
  handleSortAnswers: PropTypes.func,
  answersOrder: PropTypes.shape({
    key: PropTypes.number,
    sortBy: PropTypes.string,
  }),
};
export default SortDropdownMenu;
