/* eslint-disable react/display-name */
import { useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from 'store/store';

export const withFeatureFlag = (flag, Component, showLimitationBanner) => {
  return props => {
    const [state] = useContext(Context);
    const { featureFlags } = state;
    if (featureFlags[flag]) {
      return Component;
    }
    return null;
  };
};

withFeatureFlag.propTypes = {
  Component: PropTypes.any.isRequired,
};
