import styled from 'styled-components';
import { Modal, Form, Button as AntdButton, Card, Tag, Row, Col } from 'antd';
import { FileFilled } from '@ant-design/icons';
import Button from 'components/Button';
import { cssVariables } from 'styles/root';

export const PageHeader = styled.h3`
  font-family: Helvetica;
  font-style: normal;
  font-weight: ${cssVariables.font.bold};
  font-size: 1rem;
  line-height: 37px;
`;

export const Divider = styled.div`
  border: 1px solid #d9dadb;
  margin: ${props => (props.space ? props.space : '24')}px 0;
`;

export const StyledModal = styled(Modal)`
  font-family: Helvetica;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
  background: #ffffff !important;
  border: 1.5px solid #eff3f4;
  font-size: 14px;
  font-weight: ${cssVariables.font.bold};
  color: #1667e7 !important;
`;

export const SuccessMessage = styled.div`
  width: 368px;
  margin: 0 auto;
  text-align: center;

  > h4 {
    font-size: 16px;
    font-weight: ${cssVariables.font.bold};
  }
  > p {
    font-size: 14px;
    line-height: 1;
  }

  ${StyledButton} {
    background: #1667e7 !important;
    color: #ffffff !important;
  }
`;

export const InvoiceStatus = styled(Tag)`
  color: ${props => (props.status === 'paid' ? 'green' : '#808583')};
`;

export const CardElementsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormWrapper = styled(Form)`
  margin-top: 20px;
`;

export const PaymentMethodsList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledWrapper = styled.div``;

export const StyledPageTitle = styled.div`
  & h3 {
    display: flex;
    width: 100%;
    font-size: 24px;
    font-weight: ${cssVariables.font.strong};
  }
`;

export const StyledContent = styled(Card)`
  background: #fff;
  margin-bottom: 10px;
  border-radius: 15px;

  & .ant-card-head-title {
    padding-bottom: 0;
  }
  & .ant-card-body {
    padding-top: 15px !important;
  }
  & .ant-alert {
    margin: 20px;
  }

  & h2 {
    font-size: 1.2rem;
    font-weight: ${cssVariables.font.bold};
  }
`;

export const StyledCurrentPlan = styled(Row)`
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // width: 80%;
  margin-bottom: 40px;
`;

export const StyledPlanInfo = styled.div`
  & span,
  p,
  a {
    font-size: 14px;
  }
`;

export const StyledPlanCTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTransactionParagraph = styled.span`
  color: ${props => (props.isRed ? '#ff4d4f' : 'auto')};
  margin-right: 5px;
`;

export const StyledFormButtons = styled.div`
  display: flex;
  column-gap: 10px;

  & button {
    width: 100%;
  }

  &:first-child {
    margin: 0 !important;
  }
`;

export const StyledShowConfirmContent = styled.div`
  margin-left: 36;
`;

export const StyledPaymentInfoContainer = styled(Col)`
  // display: flex;
  // flex-direction: column;
  // > * + * {
  //   margin: 5px 0;
  // }
`;

export const StyledAntdButton = styled(AntdButton)`
  margin-bottom: 10px;
`;

export const StyledPaymentInfoDetail = styled.div`
  display: flex;
  justify-content: space-between;

  .space-between {
    width: 80%;
    justify-content: space-between;
  }
`;

export const StyledInvoiceInfo = styled.div`
  margin-bottom: 40px;
`;

export const StyledInvoiceDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const StyledFileFilled = styled(FileFilled)`
  color: #3993dd;
  margin-right: 8px;
`;

export const StyledIsProcessingWrapper = styled.div`
  ${props => {
    if (props.isProcessing) {
      return `
      -webkit-filter: blur(0.8px);
      -moz-filter: blur(0.8px);
      -o-filter: blur(0.8px);
      -ms-filter: blur(0.8px);
      filter: blur(0.8px);
    `;
    }
  }}
`;
