import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Descriptions, message, Modal, Layout, Spin, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

import Button from 'components/Button';
import DiGraph from 'components/DiGraph';
import UpdateUserModal from '../UpdateUserModal';
import AdminSidebar from '../../AdminSidebar';
import {
  UserDetailsContainer,
  UserInformation,
  GraphModal,
  UserDetailsCTA,
  SelectContainer,
} from './UserDetails.style';
import { Context } from 'store/store';
import {
  START_IMPERSONATING_USER,
  INIT_JAC,
  SHOW_DIGRAPH,
  HIDE_DIGRAPH,
  GET_ALL_VERSIONS,
} from 'store/action';
import ROUTES from 'constants/routes';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import { apiService } from 'services/api.service';
import { getToken, setImpersonateUser } from 'services/auth.service';
import { stripeAPIService } from 'services/stripeAPI.service';
import { getGraphObject } from 'utils/apiUtils';
import { cssVariables } from 'styles/root';
import { withPrefixUUID } from 'utils';
import { SidebarRoutes } from 'pages/Admin/SidebarRoutes';

const { confirm } = Modal;

const UserDetails = ({ jid }) => {
  const userJID = withPrefixUUID(jid);
  const [state, dispatch] = useContext(Context);
  const {
    sentinel,
    graph,
    admin: { all_users, showDigraph, all_versions },
  } = state;
  const [showUpdateBotset, setShowUpdateBotset] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userObject, setUserObject] = useState(null);
  const [selectedUser] = all_users.filter(data => data.jid === userJID);
  const token = getToken();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [editVersion, setEditVersion] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);

  const getAllVersions = async () => {
    setEditVersion(false);
    const allVersions = await apiService.getVersions(sentinel, token);
    if (!allVersions || !allVersions.data) {
      throw new Error(DEFAULT_ERROR_MESSAGE);
    }

    const versionData = allVersions?.data.report[0];
    setCurrentVersion(versionData.current);
    const allVersionsData = Object.entries(versionData.versions).map(
      version => ({
        version: version[0],
      })
    );
    await dispatch({
      type: GET_ALL_VERSIONS,
      payload: allVersionsData,
    });
  };

  useEffect(() => {
    setloading(true);
    const getUser = async () => {
      try {
        const userObject = await getGraphObject(userJID, token);
        if (userObject?.data?.active_gph_id) {
          if (sentinel && token) {
            const botSet = await apiService.getBotSet(
              userObject.data.active_gph_id,
              sentinel,
              token
            );
            setSelectedVersion(botSet.data.report[0].context.features.version);
            setUserData(botSet.data.report[0]);
          }
        }

        getAllVersions();
        setUserObject(userObject.data);
        setloading(false);
      } catch (error) {
        setloading(false);
        message.error(GET_DATA_ERROR);
      }
    };

    getUser();
  }, [userJID, sentinel, token]);

  const loadUserData = async (sentinel, graph) => {
    try {
      const planData = await apiService.init(sentinel, graph, token);
      await dispatch({
        type: INIT_JAC,
        payload: {
          plan: planData.data.report[0].context,
          subscription: planData.data?.report[1]?.context || {},
        },
      });
    } catch (error) {
      throw new Error(GET_DATA_ERROR);
    }
  };

  const handleShowDigraph = () => {
    dispatch({
      type: SHOW_DIGRAPH,
    });
  };

  const handleCloseDigraph = () => {
    dispatch({
      type: HIDE_DIGRAPH,
    });
  };

  const handleImpersonateClick = async () => {
    if (userData) {
      const userActiveGraph = userObject?.active_gph_id || null;
      const userType = userObject?.j_type || null;
      const userDataPayload = {
        impersonated_user: {
          graph: userActiveGraph,
          ...userObject,
          name: selectedUser.name,
          email: selectedUser.user,
        },
      };

      setImpersonateUser(graph, JSON.stringify(userDataPayload));
      if (userActiveGraph && userType !== 'super_master') {
        await dispatch({
          type: START_IMPERSONATING_USER,
          payload: userDataPayload,
        });

        await loadUserData(sentinel, userActiveGraph, null, token);

        history.push(ROUTES.BOTS_PAGE);
      } else {
        return message.error('Sorry! Graph data not available.', 3);
      }
    } else {
      return message.error('Sorry! Graph data not available.', 3);
    }
  };

  const handleActivityLogsClick = () => {
    history.push({
      pathname: ROUTES.ADMIN_ACTIVITY_LOGS,
      state: { user_id: userJID },
    });
  };

  const updatePlan = useCallback(
    async plan => {
      const result = await stripeAPIService.overrideCustomerPlan(
        userObject?.active_gph_id,
        sentinel,
        token,
        plan
      );
      const [botSet] = result.data.report.filter(
        report => report.name === 'botset'
      );
      setUserData(botSet);

      if (result.data.success && result.data.report.length > 0) {
        setShowUpdateBotset(false);
        message.success(`Plan updated to ${plan}!`, 3);
      } else {
        message.error('Sorry! Something went wrong updating user plan.', 3);
      }
    },
    [sentinel, token, userObject?.active_gph_id]
  );

  const handleChange = useCallback(
    async value => {
      confirm({
        content: <p>Update plan to {value}?</p>,
        onOk() {
          updatePlan(value);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    [updatePlan]
  );

  const handleSyncSentinel = async () => {
    setloading(true);
    try {
      const result = await apiService.syncSentinelGlobal(
        selectedUser.jid,
        token
      );
      if (result.data.success) {
        if (result?.data?.report.length > 0) {
          setUserObject(result?.data?.report[0]);
        }
        setloading(false);
        return message.success(`Successfully done`, 3);
      } else {
        setloading(false);
        return message.error('Sorry! Something went wrong while syncing.', 3);
      }
    } catch (error) {
      setloading(false);
      return message.error('Sorry! Something went wrong while syncing.', 3);
    }
  };

  const handleSetVersion = async () => {
    setloading(true);
    try {
      const result = await apiService.updateUserVersion(
        sentinel,
        token,
        {
          ver: selectedVersion,
        },
        userObject.active_gph_id
      );

      if (result.data.success) {
        if (result?.data?.report.length > 0) {
          const userObject = await getGraphObject(userJID, token);
          if (userObject?.data?.active_gph_id) {
            if (sentinel && token) {
              const botSet = await apiService.getBotSet(
                userObject.data.active_gph_id,
                sentinel,
                token
              );

              if (botSet.data?.report && botSet.data?.report[0]) {
                setSelectedVersion(
                  botSet.data.report[0].context.features.version
                );
                setUserData(botSet.data.report[0]);
              }
            }
          }
        }
        setloading(false);
        setEditVersion(false);
        return message.success(`Successfully updated.`, 3);
      } else {
        setloading(false);
        setEditVersion(false);
        return message.error('Sorry! Something went wrong while syncing.', 3);
      }
    } catch (err) {
      setloading(false);
      setEditVersion(false);
      throw err;
    }
  };

  const handleChangeVersion = e => {
    setSelectedVersion(e);
  };

  if (!selectedUser) {
    return null;
  }

  return (
    <Layout>
      <AdminSidebar title="Admin" route={SidebarRoutes} />
      <UserDetailsContainer>
        <UserDetailsCTA gutter={[8, 8]}>
          {!selectedUser.is_superuser ? (
            <Col xs={23} md={11} lg={6}>
              {' '}
              <Button
                onClick={handleImpersonateClick}
                value="Impersonate User"
                variant="primary"
                // style={{ width: '150px', marginRight: 10 }}
                disabled={userData === null}
                full
              />
            </Col>
          ) : null}
          <Col xs={23} md={11} lg={!selectedUser.is_superuser ? 6 : 8}>
            <Button
              onClick={() => setShowUpdateBotset(true)}
              value="Update User"
              variant="primary"
              // style={{ width: '150px', marginRight: 10 }}
              full
            />
          </Col>
          <Col xs={23} md={11} lg={!selectedUser.is_superuser ? 6 : 8}>
            <Button
              onClick={handleShowDigraph}
              value="View User Graph"
              variant="primary"
              // style={{ width: '150px', marginRight: 10 }}
              disabled={!userData || userData?.context.current_bot_count === 0}
              full
            />
          </Col>
          <Col xs={23} md={11} lg={!selectedUser.is_superuser ? 6 : 8}>
            <Button
              onClick={handleActivityLogsClick}
              value="Activity Log"
              variant="primary"
              // style={{ width: '150px', marginRight: 10 }}
              full
            />
          </Col>
        </UserDetailsCTA>
        <Spin spinning={loading} tip="Just a moment...">
          <UserInformation>
            <Descriptions
              title="User Info"
              layout="horizontal"
              bordered
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item
                label="Email"
                labelStyle={{ fontWeight: cssVariables.font.bold }}
              >
                {selectedUser.user}
              </Descriptions.Item>
              <Descriptions.Item
                label="Name"
                labelStyle={{ fontWeight: cssVariables.font.bold }}
              >
                {selectedUser.name}
              </Descriptions.Item>
              <Descriptions.Item
                label="Created Date"
                labelStyle={{ fontWeight: cssVariables.font.bold }}
              >
                {moment(selectedUser.created_date).format(
                  'MMMM D, YYYY, h:mm a'
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="User ID"
                labelStyle={{ fontWeight: cssVariables.font.bold }}
              >
                {selectedUser.jid}
              </Descriptions.Item>
              <Descriptions.Item
                label="Active Graph ID"
                labelStyle={{ fontWeight: cssVariables.font.bold }}
              >
                {userObject?.active_gph_id}
              </Descriptions.Item>
              <Descriptions.Item
                label="Active Sentinel ID"
                labelStyle={{ fontWeight: cssVariables.font.bold }}
              >
                {userObject?.alias_map['active:sentinel']}
                {userObject?.active_snt_id && (
                  <>
                    <br />({userObject?.active_snt_id})
                  </>
                )}
                <Button
                  variant="link"
                  value="Sync"
                  onClick={handleSyncSentinel}
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'none',
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item
                label="Spawned Walker IDs"
                labelStyle={{ fontWeight: cssVariables.font.bold }}
              >
                {userObject?.spawned_walker_ids > 0
                  ? userObject?.spawned_walker_ids.toString()
                  : 'None'}
              </Descriptions.Item>
              <Descriptions.Item
                label="Plan Type"
                labelStyle={{ fontWeight: 'bold' }}
              >
                {userData?.context.plan_type}
              </Descriptions.Item>
              <Descriptions.Item
                label="Payment Status"
                labelStyle={{ fontWeight: 'bold' }}
              >
                {userData?.context.payment_status}
              </Descriptions.Item>
              <Descriptions.Item
                label="Current Bot Count"
                labelStyle={{ fontWeight: 'bold' }}
              >
                {userData?.context.current_bot_count}
              </Descriptions.Item>
              <Descriptions.Item
                label="Current Transaction Count"
                labelStyle={{ fontWeight: 'bold' }}
              >
                {userData?.context.current_transaction_count}
              </Descriptions.Item>
              <Descriptions.Item
                label="Max Bot Count"
                labelStyle={{ fontWeight: 'bold' }}
              >
                {userData?.context.max_bot_count}
              </Descriptions.Item>
              <Descriptions.Item
                label="Max Transaction Count"
                labelStyle={{ fontWeight: 'bold' }}
              >
                {userData?.context.max_txn_count}
              </Descriptions.Item>
              <Descriptions.Item
                label="Current Version"
                labelStyle={{ fontWeight: 'bold' }}
              >
                {!editVersion && (
                  <>
                    {userData?.context.features.version}{' '}
                    <Button
                      variant="link"
                      value={<EditOutlined />}
                      onClick={() => setEditVersion(true)}
                      style={{
                        marginLeft: '10px',
                        backgroundColor: 'none',
                      }}
                    />
                  </>
                )}

                {editVersion && (
                  <>
                    <SelectContainer
                      value={selectedVersion}
                      showSearch
                      width="100%"
                      placeholder="Select Version"
                      optionFilterProp="children"
                      onChange={handleChangeVersion}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {all_versions.map((versionDetails, idx) => {
                        return (
                          <SelectContainer.Option
                            value={versionDetails.version}
                            key={idx}
                          >
                            {versionDetails.version}{' '}
                            {currentVersion === versionDetails.version &&
                              `(current)`}
                          </SelectContainer.Option>
                        );
                      })}
                    </SelectContainer>
                    <Button
                      variant="link"
                      value={<SaveOutlined />}
                      onClick={handleSetVersion}
                    />
                  </>
                )}
              </Descriptions.Item>
            </Descriptions>
          </UserInformation>
        </Spin>
        <GraphModal
          visible={showDigraph}
          title="User Graph Representation"
          width={'98%'}
          onOk={handleCloseDigraph}
          cancelButtonProps={{ style: { display: 'none' } }}
          bodyStyle={{
            height: '100%',
            textAlign: 'center',
            padding: 0,
            margin: 10,
          }}
          closable={false}
          destroyOnClose={true}
          style={{ top: 20 }}
        >
          <DiGraph
            jid={userJID}
            options={{
              layout: {
                improvedLayout: false,
              },
              physics: {
                enabled: true,
                stabilization: {
                  iterations: 500,
                },
              },
              interaction: {
                navigationButtons: true,
                keyboard: true,
              },
            }}
          />
        </GraphModal>
        <UpdateUserModal
          showUpdateBotset={showUpdateBotset}
          setShowUpdateBotset={setShowUpdateBotset}
          handleChange={handleChange}
          selectedUser={userData}
        />
      </UserDetailsContainer>
    </Layout>
  );
};

UserDetails.propTypes = {
  jid: PropTypes.string.isRequired,
};

export default UserDetails;
