import {
  ADD_INTEGRATION,
  AUTO_OPEN_INTEGRATION_PANEL,
  DELETE_INTEGRATION,
  SET_INTEGRATIONS,
  RESET_INTEGRATION_PANEL_AND_PERMIT,
  SET_INTEGRATION_ACTIVE_PANEL,
  SET_INTEGRATION_PANEL_PERMIT,
  UPDATE_INTEGRATION,
  UPDATE_VIBER_WEBHOOK_STATUS,
  OPEN_EDIT_PANEL_INTEGRATION,
  SHOW_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  CLOSE_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  SET_DEMO_BACKGROUND,
  SET_INTEGRATION_IDENTIFIER,
  SET_INTEGRATION_PATH,
  SET_DEMO_BACKGROUND_TO_CLOUD_PATH,
  SET_LAUNCHER_AVATAR_TO_CLOUD_PATH,
  SET_HEADER_AVATAR_TO_CLOUD_PATH,
  SET_RESPONSE_AVATAR_TO_CLOUD_PATH,
  SHOW_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  CLOSE_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  SAVE_NEW_INTEGRATION,
} from 'store/action';
import { getImageCloudPath } from 'store/reducers/helpers/bot/integrations';
import { botStatistics } from 'utils/bot';

export const integrationsReducer = (state, action) => {
  switch (action.type) {
    case SET_INTEGRATIONS:
      const allIntegrations = action.payload.map((i, key) => {
        return {
          key,
          ...i,
          isWeak: botStatistics(i).isWeakBot,
          showWeakStat: botStatistics(i).isWeakBot,
        };
      });
      return {
        ...state,
        bot: { ...state.bot, integrations: allIntegrations },
      };
    case ADD_INTEGRATION: {
      return {
        ...state,
        bot: {
          ...state.bot,
          integrations: [action.payload, ...state.bot.integrations],
        },
      };
    }
    case UPDATE_VIBER_WEBHOOK_STATUS: {
      const updatedIntegrations = [];
      for (const item of state.bot.integrations) {
        if (item.id === action.payload.id) {
          updatedIntegrations.push({
            ...item,
            status: action.payload.status,
          });
        } else {
          updatedIntegrations.push(item);
        }
      }

      return {
        ...state,
        bot: {
          ...state.bot,
          integrations: [...updatedIntegrations],
        },
      };
    }
    case UPDATE_INTEGRATION: {
      const updatedIntegrations = [];
      for (const item of state.bot.integrations) {
        if (item.id === action.payload.id) {
          updatedIntegrations.push(action.payload);
        } else {
          updatedIntegrations.push(item);
        }
      }
      return {
        ...state,
        bot: {
          ...state.bot,
          integrations: [...updatedIntegrations],
        },
      };
    }

    case DELETE_INTEGRATION: {
      const filteredIntegrations = state.bot.integrations.filter(
        item => item.id !== action.payload
      );
      return {
        ...state,
        bot: { ...state.bot, integrations: [...filteredIntegrations] },
      };
    }

    case SET_INTEGRATION_PANEL_PERMIT:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              ...state.bot.currentIntegrationSettings.panel,
              permit: action.payload,
            },
          },
        },
      };

    case AUTO_OPEN_INTEGRATION_PANEL:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              ...state.bot.currentIntegrationSettings.panel,
              permit: 3,
              activePanel: 3,
            },
          },
        },
      };

    case SET_INTEGRATION_ACTIVE_PANEL: {
      const { panel, permit } = action.payload;
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              ...state.bot.currentIntegrationSettings.panel,
              permit:
                permit || state.bot.currentIntegrationSettings.panel?.permit,
              activePanel: Number(panel),
            },
          },
        },
      };
    }

    case RESET_INTEGRATION_PANEL_AND_PERMIT:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              ...state.bot.currentIntegrationSettings.panel,
              permit: 1,
              activePanel: 1,
            },
          },
        },
      };

    case OPEN_EDIT_PANEL_INTEGRATION: {
      const { panel, permit } = action.payload;
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              ...state.bot.currentIntegrationSettings.panel,
              permit: !permit
                ? state.bot.currentIntegrationSettings.panel?.permit
                : permit,
              activePanel:
                panel ||
                state.bot.currentIntegrationSettings.panel?.activePanel,
              postCustomizationConfirmEditModal: {
                isOpen: false,
                editable: true,
              },
            },
          },
        },
      };
    }

    case SHOW_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL:
      const { panel, permit } = state.bot.currentIntegrationSettings.panel;
      const LAST_PANEL_AND_PERMIT_KEY = 3;

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              ...state.bot.currentIntegrationSettings.panel,
              activePanel:
                panel > permit
                  ? LAST_PANEL_AND_PERMIT_KEY
                  : state.bot.currentIntegrationSettings.panel?.activePanel,
              postCustomizationConfirmEditModal: {
                isOpen: true,
                editable: false,
                selectedPanel: action.payload.panel,
              },
            },
          },
        },
      };

    case CLOSE_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              ...state.bot.currentIntegrationSettings.panel,
              postCustomizationConfirmEditModal: {
                isOpen: false,
                editable: false,
              },
            },
          },
        },
      };

    case SET_INTEGRATION_IDENTIFIER:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            identifier: action.payload,
          },
        },
      };

    case SET_INTEGRATION_PATH:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            path: action.payload,
          },
        },
      };

    case SAVE_NEW_INTEGRATION:
    case SHOW_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              ...state.bot.currentIntegrationSettings.panel,
              preCustomizationConfirmEditModal: {
                isOpen: true,
              },
            },
          },
        },
      };

    case CLOSE_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            panel: {
              ...state.bot.currentIntegrationSettings.panel,
              preCustomizationConfirmEditModal: {
                isOpen: false,
              },
            },
          },
        },
      };

    case SET_DEMO_BACKGROUND: {
      const background = action.payload;
      // custom bg
      const bgSelectOptions = ['light', 'dark', 'custom'];
      if (
        (typeof background === 'string' &&
          !bgSelectOptions?.includes(background)) ||
        background instanceof File
      ) {
        return {
          ...state,
          bot: {
            ...state.bot,
            currentIntegrationSettings: {
              ...state.bot.currentIntegrationSettings,
              settings: {
                ...state.bot.currentIntegrationSettings.settings,
                background: background,
              },
            },
          },
        };
      }

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              background,
            },
          },
        },
      };
    }

    case SET_DEMO_BACKGROUND_TO_CLOUD_PATH: {
      const currentBackgroundImage =
        state.bot.currentIntegrationSettings?.settings?.background;

      const cloudPath = getImageCloudPath(
        currentBackgroundImage,
        state.bot?.jid,
        'demo-background'
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            background: cloudPath || currentBackgroundImage,
          },
        },
      };
    }

    case SET_LAUNCHER_AVATAR_TO_CLOUD_PATH: {
      const currentLauncherAvatar =
        state.bot.currentIntegrationSettings?.settings?.launcherAvatar;

      console.log(
        'the settings',
        state.bot.currentIntegrationSettings?.settings
      );

      const cloudPath = getImageCloudPath(
        currentLauncherAvatar,
        state.bot?.jid,
        'launcherAvatar'
      );

      console.log('the cloud path', cloudPath, currentLauncherAvatar);

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              launcherAvatar: cloudPath || currentLauncherAvatar,
            },
          },
        },
      };
    }

    case SET_HEADER_AVATAR_TO_CLOUD_PATH: {
      const currentHeaderAvatar =
        state.bot.currentIntegrationSettings?.settings?.headerAvatar;
      const cloudPath = getImageCloudPath(
        currentHeaderAvatar,
        state.bot?.jid,
        'headerAvatar'
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            settings: {
              ...state.bot.currentIntegrationSettings.settings,
              headerAvatar: cloudPath || currentHeaderAvatar,
            },
          },
        },
      };
    }

    case SET_RESPONSE_AVATAR_TO_CLOUD_PATH: {
      const currentHeaderAvatar =
        state.bot.currentIntegrationSettings?.currentIntegrationSettings
          ?.headerAvatar;
      const cloudPath = getImageCloudPath(
        currentHeaderAvatar,
        state.bot?.jid,
        'responseAvatar'
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          currentIntegrationSettings: {
            ...state.bot.currentIntegrationSettings,
            headerAvatar: cloudPath || currentHeaderAvatar,
          },
        },
      };
    }

    default:
      return state;
  }
};
