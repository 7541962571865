import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { StyledAntUploadContent } from './ImportAnswerModal.style';
const { Dragger } = Upload;

const CsvOrJsonStep = ({ fileType, handleUploadFile, handleRemoveFile }) => (
  <Dragger
    headers={{ 'Content-Type': 'multipart/form-data' }}
    beforeUpload={handleUploadFile}
    accept={
      fileType === 'json' ? 'application/json' : 'text/plain, .csv, text/csv'
    }
    listType="picture"
    onRemove={handleRemoveFile}
    maxCount={1}
  >
    <StyledAntUploadContent>
      <ImportOutlined />
      Drag files here or select file
    </StyledAntUploadContent>
  </Dragger>
);

export default CsvOrJsonStep;

CsvOrJsonStep.propTypes = {
  fileType: PropTypes.string,
  handleUploadFile: PropTypes.func,
  handleRemoveFile: PropTypes.func,
};
