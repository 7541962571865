import React from 'react';
import PropTypes from 'prop-types';

import { getLocalTimeString } from 'utils/dates';
import useFeedbackModal from './hooks';
import {
  StyledModal,
  StyledModalTitle,
  StyledItem,
  StyledContent,
  StyledQuestionContainer,
  StyledQuestion,
  StyledAnswerContainer,
  StyledAnswer,
  StyledDateTime,
} from './FeedbackModal.styles';
import { extractDisplayAnswer } from 'utils/answers';

const FeedbackModal = ({ interactionID, questions, onClose }) => {
  const { data } = useFeedbackModal({ interactionID, questions });

  return (
    <StyledModal
      visible={true}
      title={<StyledModalTitle>Feedback Details</StyledModalTitle>}
      onCancel={onClose}
      footer={null}
    >
      <StyledItem>
        <StyledContent>
          <StyledQuestionContainer>
            <StyledQuestion>{data.question}</StyledQuestion>
          </StyledQuestionContainer>
          <StyledAnswerContainer>
            <StyledAnswer
              dangerouslySetInnerHTML={{
                __html: extractDisplayAnswer(data.answer),
              }}
            />
          </StyledAnswerContainer>
        </StyledContent>
        <StyledDateTime>{getLocalTimeString(data.date)}</StyledDateTime>
      </StyledItem>
    </StyledModal>
  );
};

FeedbackModal.propTypes = {
  interactionID: PropTypes.string,
  questions: PropTypes.array,
  onClose: PropTypes.func,
};

export default FeedbackModal;
