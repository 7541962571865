import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from 'store/store';
import { message } from 'antd';

import { apiService } from 'services/api.service';
import ROUTES from 'constants/routes';
import {
  SIGN_IN_SUCCESS,
  GET_USERNAME,
  EXTERNAL_SIGN_IN_SUCCESS,
} from 'store/action';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { useGoogleAuthentication } from 'utils/socialAuth/google/useGoogleAuthentication';
import useSelector from 'store/useSelector';
import { getTokenSelector, isExternalPageSelector } from 'selectors/user';
import { setToken } from 'services/auth.service';
import { isAdminSelector } from 'selectors/admin';

const useSignIn = () => {
  const [, dispatch] = useContext(Context);
  const isAdmin = useSelector(isAdminSelector);
  const isExternalPage = useSelector(isExternalPageSelector);
  const token = useSelector(getTokenSelector);
  const history = useHistory();
  const [email, setEmail] = useState(
    history.location.state && history.location.state.email
      ? history.location.state.email
      : null
  );
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isRedirected, setIsRedirected] = useState(false);
  const [successAction, setSuccessAction] = useState('');
  const [googleAccount, setGoogleAccount] = useState(null);
  const [signInToGoogle, setSignInToGoogle] = useState(false);
  const [spinnerLabel, setSpinnerLabel] = useState('Processing...');
  const googleAUth = useGoogleAuthentication(
    setSignInToGoogle,
    setEmail,
    setPassword,
    setError,
    setSpinnerLabel
  );

  const handleLoginToGoogle = async googleAccount => {
    setSignInToGoogle(true);
    try {
      if (googleAccount) {
        await loginAuthenticatedUser(googleAccount.token);
      } else {
        setLoading(false);
        setSignInToGoogle(false);
      }
    } catch (err) {
      setSignInToGoogle(false);
      return setError(
        (err && err.message) ||
          'We encountered unexpected error while signing in'
      );
    }
    return setLoading(false);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (token) {
      if (isAdmin) {
        history.push(ROUTES.ADMIN_PAGE);
      } else {
        history.push(ROUTES.BOTS_PAGE);
      }
    }
  }, []);

  useEffect(() => {
    if (history.location.state && history.location.state.success) {
      setSuccessAction(history.location.state.action);
      setIsRedirected(true);
    }
  }, [history.location.state]);
  const onChangePassword = e => {
    setPassword(e.target.value);
  };

  const redirectToVerification = () => {
    history.push({
      pathname: ROUTES.VERIFY_EMAIL,
      state: { email },
    });
  };

  const onSubmit = async evt => {
    evt.preventDefault();
    localStorage.removeItem('token');
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await apiService.userToken(email, password);
      if (res && res.status === 200) {
        try {
          loginAuthenticatedUser(res.data.token);
        } catch (error) {
          throw new Error(error.message || DEFAULT_ERROR_MESSAGE);
        }
      } else {
        setLoading(false);
        return setError(res);
      }
    } catch (err) {
      setLoading(false);
      return setError(
        err?.message || 'We encountered unexpected error while signing in'
      );
    }
    return setLoading(false);
  };

  const requestGoogleAccess = async () => {
    setLoading(true);
    setEmail(null);
    setPassword(null);
    if (Object.values(googleAUth.tokenClient).length > 0) {
      return await googleAUth.tokenClient.requestCode();
    } else {
      return googleAUth.refreshAuth();
    }
  };

  useEffect(() => {
    if (googleAUth.tokenClient.user && !googleAccount) {
      setGoogleAccount(googleAUth.tokenClient.user);
      handleLoginToGoogle(googleAUth.tokenClient.user);
    }
  }, [googleAUth.tokenClient.user]);

  const loginAuthenticatedUser = async token => {
    try {
      const user = await apiService.getUser(token);
      const { email, id, is_activated, is_superuser, name } = user.data;
      setToken(token);
      await dispatch({
        type: isExternalPage ? EXTERNAL_SIGN_IN_SUCCESS : SIGN_IN_SUCCESS,
        payload: token,
      });
      await dispatch({
        type: GET_USERNAME,
        payload: { email, id, is_activated, is_superuser, name },
      });

      setLoading(false);
      setError(null);
      setSignInToGoogle(false);
      if (is_superuser) {
        history.push(ROUTES.ADMIN_PAGE);
      } else {
        history.push(ROUTES.BOTS_PAGE);
      }
    } catch (error) {
      setSignInToGoogle(false);
      setLoading(false);
      message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  return {
    email,
    error,
    isRedirected,
    loading,
    signInToGoogle,
    spinnerLabel,
    onChangeEmail,
    onChangePassword,
    onSubmit,
    successAction,
    redirectToVerification,
    requestGoogleAccess,
    isExternalPage,
  };
};

export default useSignIn;
