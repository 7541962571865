import { useEffect, useState } from 'react';

import {
  callbackSessionDetailsDataSelector,
  callbackSessionDetailsInteractionIdsSelector,
  callbackSessionFiltersSelector,
} from 'selectors/bot/callbackLogs';
import useSelector from 'store/useSelector';

const useCallbackModal = () => {
  const sessionData = useSelector(callbackSessionDetailsDataSelector);
  const callbackSessionFilters = useSelector(callbackSessionFiltersSelector);
  const callBackInteractionIds = useSelector(
    callbackSessionDetailsInteractionIdsSelector
  );
  const [selectedInteractionIDs, setSelectedInteractionIDs] = useState([]);
  const [data, setData] = useState([]);
  const [allInteractionIDs, setAllInteractionIDs] = useState([]);

  useEffect(() => {
    if (sessionData?.length) {
      setData(sessionData);
    }
  }, [sessionData]);

  useEffect(() => {
    return () => {
      setData([]);
      setAllInteractionIDs([]);
    };
  }, []);

  useEffect(() => {
    if (callBackInteractionIds?.length) {
      setAllInteractionIDs(callBackInteractionIds);
    }
  }, [callBackInteractionIds]);

  const handleSelectInteractionID = e => {
    const unselectedIDs = allInteractionIDs.filter(
      item => !callBackInteractionIds.includes(item.interactionID)
    );
    setAllInteractionIDs(unselectedIDs);
    setSelectedInteractionIDs(e);
    if (e.length) {
      const filteredData = sessionData.filter(
        item => item.interactionID && e.includes(item.interactionID)
      );
      setData(filteredData);
    } else {
      setData(sessionData);
    }
  };

  return {
    data,
    callbackSessionFilters,
    allInteractionIDs,
    selectedInteractionIDs,
    handleSelectInteractionID,
    callBackInteractionIds,
  };
};

export default useCallbackModal;
