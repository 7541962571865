import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { apiService } from 'services/api.service';
import { setImpersonateUser } from 'services/auth.service';
import { Context } from 'store/store';
import {
  GET_ALL_USERS,
  START_IMPERSONATING_USER,
  UPDATE_USER_DATA,
  CLEAR_DIGRAPH_DATA,
} from 'store/action';
import ROUTES from 'constants/routes';
import AdminSidebar from '../AdminSidebar';
import UserDetails from './UserDetails';
import UsersListTable from './UsersListTable';
import {
  StyledWrapper,
  StyledTableContainer,
  StyledSearch,
  StyledTitle,
} from './Users.style';
import { useQuery } from '../../useQuery';
import { getGraphObject, masterUpdateUser } from 'utils/apiUtils';
import { stripUUID } from 'utils';
import { getTokenSelector } from 'selectors/user';
import useSelector from 'store/useSelector';
import { SidebarRoutes } from '../SidebarRoutes';

const Users = () => {
  const [state, dispatch] = useContext(Context);
  const {
    admin: { all_users },
    graph,
  } = state;
  const token = useSelector(getTokenSelector);

  const [initLoading, setInitLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const history = useHistory();
  let filtered_users = all_users;
  const query = useQuery();
  const userJID = query.get('jid') ? stripUUID(query.get('jid')) : null;

  useEffect(() => {
    if (!searchFilter) {
      const allUsers = all_users.map((user, key) => {
        return {
          key,
          ...user,
        };
      });
      setFilteredUsers(allUsers);
    }
  }, [all_users, searchFilter]);

  useEffect(() => {
    const getuser = async () => {
      setInitLoading(true);
      const allUsers = await apiService.getMasterAllUser(
        {
          limit: 1000,
          offset: 0,
        },
        token
      );

      const allUsersData = allUsers.data.data;
      await dispatch({
        type: GET_ALL_USERS,
        payload: allUsersData,
      });
    };

    getuser();
    setInitLoading(false);
  }, [dispatch, token]);

  const handleOnChange = event => {
    console.log('event ', event);
  };

  const handleImpersonateClick = async userData => {
    const userObject = await getGraphObject(userData.jid, token);
    const userActiveGraph = userObject.data?.active_gph_id || null;
    const userType = userObject.data?.j_type || null;
    const userDataPayload = {
      impersonated_user: {
        graph: userActiveGraph,
        ...userData,
        email: userData.user,
      },
    };

    setImpersonateUser(graph, JSON.stringify(userDataPayload));
    if (userActiveGraph && userType !== 'super_master') {
      await dispatch({
        type: START_IMPERSONATING_USER,
        payload: userDataPayload,
      });

      window.location.href = ROUTES.BOTS_PAGE;
    } else {
      return message.error('Sorry! Graph data not available.', 3);
    }
  };

  const handleActivityLogsClick = user_id => {
    history.push({
      pathname: ROUTES.ADMIN_ACTIVITY_LOGS,
      state: { user_id: user_id },
    });
  };

  const handleUpdateUser = async (userData, action) => {
    if (action === 'activate') {
      const result = await masterUpdateUser(userData.id, token, {
        is_activated: true,
      });
      if (result.status === 200 && result.data === 'Update Success!') {
        message.success('User is now active!');
        dispatch({
          type: UPDATE_USER_DATA,
          payload: {
            id: userData.id,
            is_activated: true,
          },
        });
      } else {
        message.success('Ooops. Something is wrong, try again later.');
      }
    } else {
      const result = await masterUpdateUser(userData.id, token, {
        is_activated: false,
      });
      if (result.status === 200 && result.data === 'Update Success!') {
        message.success('User is now inactive!');
        dispatch({
          type: UPDATE_USER_DATA,
          payload: {
            id: userData.id,
            is_activated: false,
          },
        });
      } else {
        message.success('Ooops. Something is wrong, try again later.');
      }
    }
  };

  const onSearch = event => {
    const { value } = event.target;
    filtered_users = all_users.filter(au => {
      if (
        au.user.toLowerCase().includes(value.toLowerCase()) ||
        au.user.toLowerCase().includes(value.toLowerCase()) ||
        au.jid.toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    setSearchFilter(value);
    setFilteredUsers(filtered_users);
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: CLEAR_DIGRAPH_DATA,
        payload: null,
      });
    };
  }, [dispatch, userJID]);

  if (userJID) {
    return <UserDetails jid={userJID} />;
  }

  return (
    <StyledWrapper>
      <AdminSidebar title="Admin" route={SidebarRoutes} />
      <StyledTableContainer>
        <StyledTitle>Users List</StyledTitle>
        <StyledSearch
          placeholder="Search name or email"
          value={searchFilter}
          allowClear
          size="large"
          onChange={onSearch}
        />
        <UsersListTable
          initLoading={initLoading}
          filteredUsers={filteredUsers}
          handleOnChange={handleOnChange}
          handleImpersonateClick={handleImpersonateClick}
          handleUpdateUser={handleUpdateUser}
          handleActivityLogsClick={handleActivityLogsClick}
        />
      </StyledTableContainer>
    </StyledWrapper>
  );
};

export default Users;
