import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Spin, Radio, Space } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  StyledCodeButton,
  StyledSnippets,
  StyledCodeBlock,
  StyledIntegrationSteps,
  StyledIntegrationInstruction,
  StyledApplicationKeyInput,
} from '../Others/Others.styles';
import Button from 'components/Button';
import useOthers from './hooks';

const { Paragraph } = Typography;
const Others = ({ isPageReady }) => {
  const [applicationSelected, setApplicationSelected] = useState('Messenger');
  const [applicationKey, setApplicationKey] = useState(null);
  const {
    botComponent,
    webHookURL,
    setWebHookURL,
    handleSetWebHook,
    generateUUID,
    loading,
  } = useOthers();

  const handleChangeApplicationSelected = event => {
    setWebHookURL(
      event.target.value === 'Messenger'
        ? process.env.REACT_APP_FACEBOOK_MESSENGER_URL
        : ''
    );
    setApplicationSelected(event.target.value);
  };
  const handleChangeApplicationKey = value => {
    setApplicationKey(value);
  };

  return (
    <Spin spinning={!isPageReady}>
      <div>
        <StyledIntegrationSteps>
          <Radio.Group
            onChange={handleChangeApplicationSelected}
            value={applicationSelected}
          >
            <Space direction="horizontal">
              <Radio value={'Messenger'}>Facebook Messenger</Radio>
              <Radio value={'Viber'}>Viber</Radio>
            </Space>
          </Radio.Group>
          {applicationSelected === 'Viber' && (
            <>
              <StyledApplicationKeyInput
                required
                value={applicationKey}
                label="Application Key"
                placeholder="Enter application key"
                onChange={e => handleChangeApplicationKey(e.target.value)}
              />
              <Button
                type="button"
                variant="primary"
                onClick={handleSetWebHook}
                value="Set Webhook"
              />
            </>
          )}

          {!!botComponent && applicationSelected === 'Messenger' && (
            <>
              <StyledSnippets>
                <Button
                  value="Generate UUID"
                  type="submit"
                  onClick={generateUUID}
                  loading={loading}
                />
              </StyledSnippets>
            </>
          )}

          {!!botComponent && webHookURL && (
            <>
              <StyledSnippets>
                <StyledIntegrationInstruction>
                  Copy and paste this webhook url.
                  <CopyToClipboard text={webHookURL}>
                    <StyledCodeButton
                      className=" secondary"
                      value="Copy code"
                    />
                  </CopyToClipboard>
                </StyledIntegrationInstruction>
                <StyledCodeBlock>
                  <Paragraph>
                    <pre>
                      <code>{webHookURL}</code>
                    </pre>
                  </Paragraph>
                </StyledCodeBlock>
              </StyledSnippets>
            </>
          )}
        </StyledIntegrationSteps>
      </div>
    </Spin>
  );
};

Others.propTypes = {
  isPageReady: PropTypes.bool,
};

export default Others;
