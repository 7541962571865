import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledRCEEditor = styled.div`
  width: 100%;

  span {
    font-weight: ${cssVariables.font.bold};
  }
`;

export const StyledRCETip = styled.div`
  font-size: 11px;
`;

export const StyledHTMLEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;

  & .CodeMirror {
    height: 200px;
    min-width: 100%;

    @media (min-width: 900px) {
      max-width: 40vw;
    }
  }
`;

export const StyledHtmlOutput = styled.div`
  & div {
    max-height: 200px;
    overflow-y: auto;
  }

  & span {
    font-weight: ${cssVariables.font.bold};
  }

  & img {
    width: 100%;
  }
`;
export const StyledTextOptions = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBotCustomComponent = styled.div`
  text-align: left;
  overflow-x: auto;
  box-shadow: 1px 1px 2px 0px #b5b5b5;
  border-radius: 10px;
  font-family: Calibri, sans-serif;
  line-height: 1.5715;
  background-color: #f0f2f5;
  margin: 10px;

  & p:first-child {
    margin: 0;
  }

  & img {
    width: 100% !important;
  }
`;

export const StyledParsedAnswer = styled.div`
  max-width: 230px;
  border-radius: 10px;
  padding: 15px;
`;

export const StyledResponse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
