import { ZSB_PRICING_PAGE } from 'constants/outboundLinks';
import { strippedString } from './stringManipulation';

const multiSplit = (str, delimiters, firstColSplit) => {
  return delimiters.reduce((acc, cur) => {
    if (typeof acc === 'string') {
      return acc.split(cur);
    }
    if (firstColSplit) {
      return acc.map(a => a.split(cur)[0]).flat(1);
    } else {
      return acc.map(a => a.split(cur)).flat(1);
    }
  }, str);
};

export const generateRandomColor = () =>
  '#' + Math.floor(Math.random() * 16777215).toString(16);

export const getBase64 = file => {
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  return null;
};

export const csvToArray = (str, delimiter, readOnlyFirstCol) => {
  const csvDelimiters = [',', '\n'];
  const csvData = multiSplit(str, delimiter || csvDelimiters, readOnlyFirstCol);

  return csvData.filter(
    item => !!item && !item.match(new RegExp(/[^\x00-\x7F]/g)) && item.trim()
  );
};

export const stripUUID = uuid => {
  return typeof uuid === 'string' ? uuid.split(':')?.pop() : '';
};

export const withPrefixUUID = uuid => {
  // uuid either coming from gloabl state
  // or url pathname
  const uuidPrefix = 'urn:uuid:';
  if (uuid.includes('/bot/')) {
    const strippedBotIDFromPath = uuid.split('/bot/').pop();
    if (strippedBotIDFromPath.includes('/')) {
      return `${uuidPrefix}${stripUUID(strippedBotIDFromPath.split('/')[0])}`;
    }
    return `${uuidPrefix}${stripUUID(strippedBotIDFromPath)}`;
  }
  const strippedBotID = uuid.split('/')[0];
  return `${uuidPrefix}${stripUUID(strippedBotID)}`;
};

export const formatBytes = bytes => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0,
    n = parseInt(bytes, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const base64toBlob = (base64Data, contentType) => {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const escapeCSV = str => {
  if (str.match && str.match(/,|"|\n|\r|\n\n/)) {
    return `"${str.replaceAll(/"/g, '""')}"`;
  } else {
    return str;
  }
};

export const getCurrentBrowser = () => {
  const { userAgent } = navigator;
  let match =
    userAgent.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];
  let temp;

  if (/trident/i.test(match[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

    return `IE ${temp[1] || ''}`;
  }

  if (match[1] === 'Chrome') {
    temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

    if (temp !== null) {
      return temp.slice(1).join(' ').replace('OPR', 'Opera');
    }

    temp = userAgent.match(/\b(Edg)\/(\d+)/);

    if (temp !== null) {
      return temp.slice(1).join(' ').replace('Edg', 'Edge (Chromium)');
    }

    if (navigator.brave) {
      return 'Brave (Chromium)';
    }
  }

  match = match[2]
    ? [match[1], match[2]]
    : [navigator.appName, navigator.appVersion, '-?'];
  temp = userAgent.match(/version\/(\d+)/i);

  if (temp !== null) {
    match.splice(1, 1, temp[1]);
  }

  return match.join(' ');
};

export const isM1Chip = () => {
  return !!navigator.userAgent.match(/OS X 10_([789]|1[0123456789])/);
};

// This is handy to filter out specific keys from the object
// Data types: {obj} -> Array of Objects, {keys} -> array of strings !!
export const filterObjectKeys = (obj, keys) => {
  // reject object of objects
  if (obj[0]) {
    return false;
  }
  const keysToExclude = Array.isArray(keys)
    ? keys
    : typeof keys === 'string'
    ? [keys]
    : [];

  const filteredObject = Object.entries(obj).filter(([key, value]) => {
    // exclude if the DATA TYPE of {key} is not a String
    if (typeof key !== 'string') {
      return false;
    } else if (!keysToExclude.includes(key)) {
      return true;
    }
    return false;
  });

  // Convert the key/value array back to an object
  return Object.fromEntries(filteredObject);
};

export const testEmailRegEx = email => {
  // HTML5 email regEx pattern
  const reg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return reg.test(email);
};

export const isCollectionValueHasDuplicate = (
  collection,
  valueToCompareForDuplicates,
  keyToCompareForDuplicates,
  id
) => {
  const strippedInput = strippedString(valueToCompareForDuplicates);
  return !strippedInput
    ? false
    : collection.some(item => {
        return strippedString(item[keyToCompareForDuplicates]) ===
          strippedInput &&
          (item.jid
            ? stripUUID(item.jid) !== stripUUID(id)
            : stripUUID(item.id) !== stripUUID(id))
          ? true
          : undefined;
      });
};

export const showLessTextCharacters = (
  text,
  showFullText,
  maxTextLength = 190
) => {
  if (text) {
    if (text.length > maxTextLength && !showFullText) {
      return text.substring(0, maxTextLength) + '... ';
    }
    return text;
  }
  return null;
};

export const makeKeywordAsAnchorText = toolTipTitle => {
  const keywords = ['Upgrade', 'Contact Us'];
  let resultString = toolTipTitle;

  keywords.forEach(substring => {
    const regex = new RegExp(substring, 'gi');
    if (typeof resultString === 'string' && resultString.match(regex)) {
      resultString = resultString.replace(
        regex,
        `<a style="color: #6AC8BC" href="${ZSB_PRICING_PAGE}">${substring}</a>`
      );
    }
  });

  return <span dangerouslySetInnerHTML={{ __html: resultString }} />;
};

// use to get data from api response that's multidimensional
// e.g. report[0][0]
export const getNodesFromArray = (data = []) => {
  if (Array.isArray(data) && data.length) {
    const isTheData = data.some(i => i?.kind === 'node');
    // if not the list with the matching node
    // get the first element of the array
    // then loop
    return isTheData ? data : getNodesFromArray(data.shift());
  }
  return data;
};

export const handlePageResizing = () => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 377) {
    return {
      isMobileView: true,
      standardFontSize: '.5rem',
    };
  } else if (windowWidth < 639) {
    return {
      isMobileView: true,
      standardFontSize: '.7rem',
    };
  } else if (windowWidth < 769) {
    return {
      isMobileView: false,
      standardFontSize: '.9rem',
    };
  } else {
    return {
      isMobileView: false,
      standardFontSize: '1rem',
    };
  }
};
