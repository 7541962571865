import { CANCELED_CHECKOUT_URL_PARAM } from 'constants/plan';
import { apiService } from './api.service';
import { parseBoolean } from 'utils/dataTypes';

export const stripeAPIService = {
  switchPlan: function (
    planType,
    successURL,
    cancelURL,
    graph,
    sentinel,
    token
  ) {
    return apiService.jacPrimeRun(
      {
        name: 'switch_plan',
        nd: graph,
        ctx: {
          plan_type: planType,
          success_url: successURL,
          cancel_url: cancelURL,
        },
      },
      sentinel,
      token
    );
  },

  overrideCustomerPlan: function (graph, sentinel, token, plan) {
    return apiService.jacPrimeRun(
      {
        name: 'override_customer_plan',
        nd: graph,
        ctx: {
          plan: plan,
        },
      },
      sentinel,
      token
    );
  },

  getStripeStatus: function (graph, sentinel, token) {
    return apiService.jacPrimeRun(
      {
        name: 'get_stripe',
        nd: graph,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  getSubscriptionStatus: function (graph, sentinel, token) {
    return apiService.jacPrimeRun(
      {
        name: 'get_subscription',
        nd: graph,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  setIsProcessingSubscription: function (status, graph, sentinel, token) {
    return apiService.jacPrimeRun(
      {
        name: 'subscription_is_processing',
        nd: graph,
        ctx: {
          is_processing: status,
        },
      },
      sentinel,
      token
    );
  },

  getInvoiceList: function (graph, sentinel, token) {
    return apiService.jacPrimeRun(
      {
        name: 'get_invoice_list',
        nd: graph,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  getPaymentMethods: function (graph, sentinel, token) {
    return apiService.jacPrimeRun(
      {
        name: 'get_payment_methods',
        nd: graph,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  updatePaymentMethod: function (
    successURL,
    cancelURL,
    graph,
    sentinel,
    token
  ) {
    return apiService.jacPrimeRun(
      {
        name: 'update_payment_method',
        nd: graph,
        ctx: {
          success_url: successURL,
          cancel_url: cancelURL + CANCELED_CHECKOUT_URL_PARAM,
        },
      },
      sentinel,
      token
    );
  },

  cancelSubscription: function (graph, sentinel, token) {
    return apiService.jacPrimeRun(
      {
        name: 'cancel_subscription',
        nd: graph,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  getCustomer: function (graph, sentinel, token) {
    return apiService.jacPrimeRun(
      {
        name: 'get_customer',
        nd: graph,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  changeCard: function (returnUrl, graph, sentinel, token) {
    return apiService.jacPrimeRun(
      {
        name: 'create_billing_portal_session',
        nd: graph,
        ctx: {
          return_url: returnUrl,
        },
      },
      sentinel,
      token
    );
  },

  autoRenewSubscription: function (graph, sentinel, token) {
    return apiService.jacPrimeRun(
      {
        name: 'update_auto_renewal',
        nd: graph,
        ctx: {
          auto_renew: true,
        },
      },
      sentinel,
      token
    );
  },

  changeStripeIsProcessingStatus: function (
    isProcessing,
    graph,
    sentinel,
    token
  ) {
    return apiService.jacPrimeRun(
      {
        name: 'subscription_is_processing',
        nd: graph,
        ctx: {
          is_processing: parseBoolean(isProcessing),
        },
      },
      sentinel,
      token
    );
  },
};
