import { useState, useContext, useEffect } from 'react';

import {
  ADD_QUESTION,
  CLOSE_RESPONSE_PICKER_MODAL,
  PUSH_ANSWER_AS_RESPONSE_PICKER_BEST_ANSWER,
  REMOVE_HARD_LINK_FROM_RESPONSE_PICKER,
  UPDATE_CHAT_LIST_ITEM,
  UPDATE_QUESTION_AND_ANSWER,
  UPDATE_RESPONSE_PICKER_ANSWERS,
  UPDATE_RESPONSE_PICKER_SIMILAR_QUESTIONS,
} from 'store/action';
import { Context } from 'store/store';
import { message } from 'antd';

import { apiService } from 'services/api.service';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { responsePickerModalSelector } from 'selectors/bot/ui';
import useSelector from 'store/useSelector';

const useResponsePickerModal = () => {
  const [state, dispatch] = useContext(Context);
  const {
    sentinel,
    token,
    bot: { jid },
  } = state;
  const responsePickerModal = useSelector(responsePickerModalSelector);

  const {
    isOpen,
    answers,
    isHardLinked,
    botName,
    title,
    itemToChange,
    modalStatus,
    questionIdLinked,
    similarQuestions,
    editableQuestion,
  } = responsePickerModal;
  const { question, answer } = itemToChange;
  const [searchKey, setSearchKey] = useState(null);
  const [filteredAnswers, setFilteredAnswers] = useState([]);
  const [showDisplayAnswer, setShowDisplayAnswer] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const [showQuestionsSkeleton, setshowQuestionsSkeleton] = useState(false);
  const [newQuestion, setNewQuestion] = useState(question?.text);

  const [currentAnswer, ...alternateAnswser] = answers;

  const chatQuestion =
    question && typeof question === 'object' ? question.text : question;
  const isCurrentAnswerHasLink = isHardLinked;

  const onChangeSearchKey = e => {
    e.preventDefault();
    setSearchKey(e.target.value);
  };

  useEffect(() => {
    setNewQuestion(question?.text);
  }, [question?.text]);

  const handleCloseResponsePickerModal = () => {
    setNewQuestion(null);
    setFilteredAnswers([]);
    setSearchKey(null);
    setshowQuestionsSkeleton(false);
    dispatch({
      type: CLOSE_RESPONSE_PICKER_MODAL,
    });
  };

  const handleChangeQuestion = evt => {
    setNewQuestion(evt.target.value);
  };

  const handleSelectQuestion = async (answerId, type) => {
    return pushAnswerAsResponsePickerAnswer(answerId);
  };

  const pushAnswerAsResponsePickerAnswer = answerId => {
    dispatch({
      type: PUSH_ANSWER_AS_RESPONSE_PICKER_BEST_ANSWER,
      payload: {
        answerId,
        answers,
        isHardLinked: false,
        questionText: newQuestion || question,
      },
    });
  };

  const updateResponsePickerAnswers = async () => {
    try {
      const res = await apiService.getQuestionMatchesWithAnswer(
        question,
        null,
        sentinel,
        jid,
        token
      );

      if (!res.data?.report[0]) {
        return message.error(DEFAULT_ERROR_MESSAGE);
      }

      setLoading(false);
      return dispatch({
        type: UPDATE_RESPONSE_PICKER_ANSWERS,
        payload: {
          answers: res.data.report[0],
          isHardLinked: false,
          answerId: res.data.report[0][0]?.answer?.jid || null,
          questionIdLinked: null,
        },
      });
    } catch (error) {
      message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const hardLinkQuestionToAnswer = async skipSimilarityCheck => {
    try {
      if (!skipSimilarityCheck) {
        setshowQuestionsSkeleton(true);
        const res = await apiService.getSimilarQuestions(
          newQuestion || question?.text,
          sentinel,
          jid,
          token
        );
        if (!res.data.success) {
          throw new Error(DEFAULT_ERROR_MESSAGE);
        }
        dispatch({
          type: UPDATE_RESPONSE_PICKER_SIMILAR_QUESTIONS,
          payload: {
            similarQuestions: res.data.report[0],
            questionText: newQuestion || question?.text,
            currentQuestion:
              responsePickerModal.action === 'edit' ? question?.jid : null,
          },
        });
        return setshowQuestionsSkeleton(false);
      } else {
        setLoading(true);
        if (
          responsePickerModal.action === 'add' ||
          !responsePickerModal.isHardLinked
        ) {
          const res = await apiService.hardLinkQuestion(
            newQuestion || question.text,
            answers[0].jid,
            jid,
            sentinel,
            token,
            true
          );

          if (!res.data.success) {
            throw new Error(DEFAULT_ERROR_MESSAGE);
          }

          await dispatch({
            type: ADD_QUESTION,
            payload: res.data.report[0],
          });
          message.success('Successfully linked the question to the answer');
          handleCloseResponsePickerModal();
        } else if (responsePickerModal.action === 'edit') {
          const res = await apiService.editQuestion(
            newQuestion || question.text,
            answers[0].jid,
            question.jid,
            question.version || 'draft',
            sentinel,
            token,
            true
          );

          if (!res.data.success) {
            throw new Error(DEFAULT_ERROR_MESSAGE);
          }

          await dispatch({
            type: UPDATE_QUESTION_AND_ANSWER,
            payload: res.data.report[0],
          });
          message.success('Successfully linked the question to the answer');
          handleCloseResponsePickerModal();
        }
      }
    } catch (error) {
      message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const handleUnlinkQuestion = async () => {
    setLoading(true);
    try {
      await apiService.deleteQuestionHardLink(question.jid, sentinel, token);
      setLoading(false);
      dispatch({
        type: REMOVE_HARD_LINK_FROM_RESPONSE_PICKER,
        payload: question.jid,
      });
      return message.success(
        'Successfully unlinked the question to the answer'
      );
    } catch (error) {
      setLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const updateChatList = (answer, msgIndex) => {
    dispatch({
      type: UPDATE_CHAT_LIST_ITEM,
      payload: {
        answer,
        msgIndex,
        chatItemToChangeAnswerJID: itemToChange.answer.jid,
      },
    });
  };

  return {
    alternateAnswser,
    answers,
    botName,
    chatQuestion,
    hardLinkQuestionToAnswer,
    currentAnswer,
    editableQuestion,
    filteredAnswers,
    handleChangeQuestion,
    handleCloseResponsePickerModal,
    handleSelectQuestion,
    handleUnlinkQuestion,
    modalStatus,
    isOpen,
    isCurrentAnswerHasLink,
    itemToChange,
    loading,
    newQuestion,
    onChangeSearchKey,
    question,
    responsePickerModal,
    showDisplayAnswer,
    searchKey,
    similarQuestions,
    setShowDisplayAnswer,
    setFilteredAnswers,
    showQuestionsSkeleton,
    title,
  };
};

export default useResponsePickerModal;
