import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { StyledInputGroup } from './QuickReply.styles';
import { StyledFlexColumn } from 'styles/GenericStyledComponents';

const QuickReply = ({
  addRow,
  deleteRow,
  handleQuickReplyChange,
  isAddRowDisabled,
  isRemoveRowDisabled,
  quickReplies,
}) => {
  const MemoisedQuickReplyForm = useMemo(() => {
    return (quickReplies?.quickReplyOptions || []).map((item, idx) => (
      <StyledInputGroup
        deleteRow={deleteRow}
        index={idx}
        id={item.key}
        item={item}
        key={idx + 1}
        onQuickReplyChange={handleQuickReplyChange}
        isRemoveRowDisabled={isRemoveRowDisabled}
      />
    ));
  }, [quickReplies.quickReplyOptions]);

  return (
    <StyledFlexColumn>
      {quickReplies?.quickReplyOptions?.length ? MemoisedQuickReplyForm : null}
      <Link
        disabled={isAddRowDisabled}
        onClick={addRow}
        to="#"
        style={{ alignSelf: 'start' }}
      >
        <PlusOutlined /> {'Add more'}
      </Link>
    </StyledFlexColumn>
  );
};

QuickReply.propTypes = {
  addRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  quickReplies: PropTypes.object.isRequired,
  handleQuickReplyChange: PropTypes.func.isRequired,
  isAddRowDisabled: PropTypes.bool,
  isRemoveRowDisabled: PropTypes.bool,
};

export default QuickReply;
