import { orderBy } from 'lodash';

import {
  DEFAULT_EDITOR,
  DEFAULT_ANSWER_VERSION,
  EMPTY_ANSWER_OBJECT,
  ZSB_CHAT_BREAKER_ENCONDING,
} from 'constants/answerbank/defaults';
import { DEFAULT_ANSWER_THRESHOLD } from 'constants/bot';
import { stripUUID } from 'utils';
import { extractQuickReplyData } from 'utils/answers';
import { isAnObject, parseBoolean } from 'utils/dataTypes';
import {
  ANSWER_FILE_TYPES,
  WEBSITE_NO_PAGE_SELECTED_ERROR_MESSAGE,
} from 'constants/answerbank/answertype';
import Button from 'components/Button';
import { ActionContainer } from 'components/Modals/WebsiteEditor/WebsiteEditor.styles';
import { Tag, Tooltip, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { SET_WEBSITE_SELECTED_PAGES } from 'store/action';

const extractOpenAIAnswerFromAPI = answerData => {
  const openaiObject =
    isAnObject(answerData) && answerData.openai
      ? answerData.openai
      : answerData;

  if (
    !isAnObject(openaiObject?.context) ||
    !isAnObject(openaiObject?.context?.openai)
  ) {
    return undefined;
  }
  const { openai } = openaiObject?.context;
  const {
    detected_language,
    intents,
    references,
    sentiments,
    target_language,
    translation,
  } = openai;

  return {
    detectedLanguage: detected_language,
    intents,
    references,
    sentiments,
    targetLanguage: target_language,
    translation,
  };
};

const renameAnswerAPIvariables = (answerData, isBotOpenAIEnabled) => {
  // new importer returns
  /**
   * answerData: {
   *  answer: {
   *  ...answerObjectResponseFromAPI
   *  }
   * }
   */
  const answerObject =
    // check whether answerData is from new importer
    isAnObject(answerData) && answerData.answer
      ? answerData.answer
      : answerData;
  const currentISODate = new Date().toISOString();
  const answerObjectScore = answerObject?.score;

  const {
    text,
    editor,
    qlinks,
    categoryid,
    last_updated_time,
    show_text,
    show_html,
    hitcount,
    score: contextScore,
    callback,
    ans_version,
    question_id,
    created_time,
    category_id,
    ver,
    category,
    sequence,
    scraping_info,
  } = answerObject.context;
  const isNotAnAnswer = ANSWER_FILE_TYPES.includes(answerObject.name);
  const version = isNotAnAnswer ? ver : ans_version || DEFAULT_ANSWER_VERSION;
  const { quick_reply, quick_reply_options, messageInputIsDisabled } =
    extractQuickReplyData(answerObject);

  const score = !isNaN(answerObjectScore)
    ? Number(answerObjectScore).toFixed(3)
    : !isNaN(contextScore)
    ? Number(contextScore).toFixed(3)
    : '0.000';

  return {
    jid: answerObject.jid,
    text: isNotAnAnswer ? answerObject.context?.name : text,
    qlinks: Array.isArray(qlinks) ? qlinks : [],
    categoryId:
      category && isAnObject(category)
        ? stripUUID(category?.jid)
        : typeof category === 'string'
        ? category
        : category_id || categoryid,
    lastEdited: last_updated_time || currentISODate,
    created_time: created_time,
    show_text: show_text,
    show_html: show_html || show_text,
    editor: editor || DEFAULT_EDITOR,
    hitcount: hitcount,
    type: answerObject.name,
    score: score,
    quickReply: quick_reply,
    requestAgent: parseBoolean(callback),
    quickReplyOptions: quick_reply_options || null,
    // can be use to enable / disable chat input
    // especially if quick reply is linked to an answer
    messageInputIsDisabled,
    version,
    questionId: question_id,
    answerLength: text?.length,
    openai: isBotOpenAIEnabled
      ? extractOpenAIAnswerFromAPI(answerData)
      : undefined,
    sequence: sequence || [],
    scrapingInfo: scraping_info || null,
  };
};

export const extractGeneratedAnswer = (text, jid) => {
  return {
    jid,
    text,
    show_text: text,
    show_html: text,
    score: 0,
    hitcount: 0,
    htmlString: text,
    type: 'answer',
  };
};

export const extractDefaultAnswer = (context, jid) => {
  return {
    jid,
    text: context.text,
    show_text: context.show_text,
    show_html: context.show_html || context.show_text,
    score: Number(context?.thresh_score) || DEFAULT_ANSWER_THRESHOLD,
    hitcount: context.hitcount,
    htmlString: Array.isArray(context.show_html)
      ? context.show_html.join(ZSB_CHAT_BREAKER_ENCONDING)
      : context.show_html
      ? String(context.show_html)
      : null,
    type: 'default_answer',
  };
};

const renameFileAPIvariables = fileData => {
  const currentISODate = new Date().toISOString();
  const {
    name,
    last_updated_time,
    created_time,
    ver,
    hitcount,
    scraping_info,
  } = fileData.context;
  return {
    jid: fileData.jid,
    text: name,
    qlinks: [],
    lastEdited: last_updated_time || currentISODate,
    created_time: created_time,
    show_text: [name],
    show_html: [name],
    editor: DEFAULT_EDITOR,
    type: scraping_info ? 'website' : fileData.name,
    version: ver || DEFAULT_ANSWER_VERSION,
    hitcount: hitcount,
    scrapingInfo: scraping_info,
  };
};

export const extractCategoryData = categoryFromAPI => {
  const { jid, context } = categoryFromAPI;
  return {
    jid: stripUUID(jid),
    name: context.name,
    color: context.color,
  };
};

/**
 * answerData = data from API
 * isBotOpenAIEnabled = value fetched from state.bot.useOpenAI
 */
export const extractAnswerData = (answerData, isBotOpenAIEnabled) => {
  if (Array.isArray(answerData) && answerData.length) {
    return answerData.map(item => {
      if (item.answer && item.answer?.name.includes('default')) {
        const { jid, context } = item.answer;
        return extractDefaultAnswer(context, jid);
      } else if (!item.answer && item.name.includes('default')) {
        const { jid, context } = item;
        return extractDefaultAnswer(context, jid);
      }
      // api returns an empty answer object
      else if (!Object.keys(item).length && !Object.keys(item?.answer).length) {
        return EMPTY_ANSWER_OBJECT;
      }
      return renameAnswerAPIvariables(item, isBotOpenAIEnabled);
    });
  } else if (Array.isArray(answerData)) {
    return [];
  }
  // api returns an empty answer object
  else if (isAnObject(answerData)) {
    if (!Object.keys(answerData).length) {
      return EMPTY_ANSWER_OBJECT;
    }
    return answerData?.name?.includes('default')
      ? extractDefaultAnswer(answerData.context, answerData.jid)
      : renameAnswerAPIvariables(answerData, isBotOpenAIEnabled);
  }
};

export const extractFileData = fileData => {
  if (Array.isArray(fileData) && fileData.length) {
    return fileData.map(item => {
      if (!Object.keys(item).length && !Object.keys(item).length) {
        return EMPTY_ANSWER_OBJECT;
      }
      return renameFileAPIvariables(item);
    });
  } else if (Array.isArray(fileData)) {
    return [];
  }
  // api returns an empty answer object
  else if (isAnObject(fileData)) {
    if (!Object.keys(fileData).length) {
      return EMPTY_ANSWER_OBJECT;
    }
    return renameFileAPIvariables(fileData);
  }
};

export const pushCurrentAnswerAsTopAnswer = (answers, currentAnswerId) => {
  const filteredAnswers = orderBy(
    answers
      .filter(item => item && item.jid !== currentAnswerId)
      .filter(item => !item.type?.includes('default_answer'))
      .filter(Boolean),
    'score',
    'desc'
  );

  const currentAnswer = currentAnswerId
    ? answers.find(item => item.jid === currentAnswerId)
    : null;
  // postion hard link response to `answers[0]`
  // to be fetch as the `currentAnswer` on ResponsePickerModal
  return [currentAnswer, ...filteredAnswers].filter(Boolean);
};

export const renderPreconfigData = (
  uploadingMethod,
  timeoutInSecond,
  getters
) => {
  return [
    {
      regex: '.+',
      scraper: {
        goto: {
          wait_until: uploadingMethod,
          timeout: timeoutInSecond * 1000,
        },
        getters,
      },
    },
  ];
};

export const extractScannedObject = scanned => {
  const traverse = Object.keys(scanned)?.reduce((acc, key) => {
    const value = scanned[key];

    if (value.hasOwnProperty('source')) {
      acc.push(value.source);
    } else {
      acc.push(key);
    }

    return acc;
  }, []);

  return [...new Set(traverse)];
};

export const categorizedWebsitePages = urlList => {
  const isObject = urlList?.every(
    item => typeof item === 'object' && item !== null
  );

  return urlList?.reduce((acc, url) => {
    const { pathname } = new URL(isObject ? url.url : url);
    const pageName = pathname?.split('/').filter(Boolean)[0];
    let category = acc.find(cat => cat.name === pageName);

    if (!category) {
      category = {
        key: acc.length + 1 || 0,
        name: pageName ? pageName : isObject ? url.url : url,
        urlList: [],
      };
      acc.push(category);
    }

    category.urlList.push(url);
    return acc;
  }, []);
};

export const renderWebsiteCategoryTableColumn = (
  handleOpenPages,
  urlSelectedCount
) => [
  {
    title: <h4>Category</h4>,
    dataIndex: 'name',
    key: 'name',
    render: (value, urlDetails) => (
      <span>
        <strong>
          {value?.charAt(0).toUpperCase() + value?.slice(1) || 'General'}
        </strong>
        <br />
        <Button
          onClick={() => handleOpenPages(urlDetails)}
          value={
            <>
              {urlSelectedCount(urlDetails)}/{urlDetails.urlList.length}{' '}
              Selected Page
              {urlSelectedCount(urlDetails) > 1 ? 's' : ''}
            </>
          }
          variant="link"
          style={{ padding: 0 }}
        />
      </span>
    ),
    width: '80%',
  },
];

export const renderWebsitePagesTableColumn = [
  {
    title: <h4>URL</h4>,
    dataIndex: 'url',
    key: 'url',
    render: (value, urlDetails) => (
      <ActionContainer error={urlDetails?.error}>
        {value}
        {urlDetails?.error && (
          <Tooltip title={urlDetails?.error}>
            <Tag color="error">
              <ExclamationCircleOutlined color="red" /> Error found.
            </Tag>
          </Tooltip>
        )}
      </ActionContainer>
    ),
    width: '80%',
  },
  // selectedTemplate === 'traversing'
  //   ? {
  //       title: 'URL Filter Condition',
  //       dataIndex: 'action',
  //       key: 'action',
  //       render: (value, urlSource) => (
  //         <Select
  //           id="action"
  //           name="action"
  //           onChange={evt => handleChangeFilterMethod(evt, urlSource.url)}
  //           defaultValue={urlSource.filterMethod}
  //           size="small"
  //           width="100%"
  //         >
  //           {FILTER_METHOD_OPTIONS?.map((filterMethod, idx) => (
  //             <Select.Option key={idx} value={filterMethod.value}>
  //               {filterMethod.label}
  //             </Select.Option>
  //           ))}
  //         </Select>
  //       ),
  //       width: '20%',
  //     }
  //   : null,
];

export const extractCategoryCountSelected = (
  category,
  urlSelected,
  categoryTableSource
) => {
  return categoryTableSource
    ?.find(categoryList => categoryList.name === category)
    ?.urlList?.map(urlObj => urlObj.url)
    ?.filter(url => urlSelected?.includes(url)).length;
};

export const websiteSelectionChangesConfirmationMessage = (
  record,
  selected,
  selectionState,
  dispatch
) => {
  if (selectionState?.urlSelected?.length === 1 && !selected) {
    return message.error(WEBSITE_NO_PAGE_SELECTED_ERROR_MESSAGE);
  } else {
    return confirm({
      title: `Do you really want to ${selected ? 'select' : 'unselect'} this?`,
      content: `This will ${
        selected ? 'select' : 'unselect'
      } all of the pages inside ${
        record.name?.charAt(0).toUpperCase() + record.name?.slice(1)
      }.`,
      okText: 'Proceed',
      cancelText: 'Cancel',
      onOk() {
        let payload = [];
        const selectedUrlList = record?.urlList?.map(urlObj => urlObj.url);
        const selectedKeyList = record?.urlList?.map(urlObj => urlObj.key);
        const unSelectedUrl = selectionState.urlSelected?.filter(
          url => !selectedUrlList?.includes(url)
        );
        const unSelectedKey = selectionState.urlSelectedRowKeys?.filter(
          key => !selectedKeyList?.includes(key)
        );

        if (selected) {
          payload = {
            urlSelectedRowKeys: [...unSelectedKey, ...selectedKeyList],
            urlSelected: [...unSelectedUrl, ...selectedUrlList],
            categorySelectedRowKeys: [
              ...selectionState.categorySelectedRowKeys,
              record.key,
            ],
            categorySelected: [...selectionState.categorySelected, record.name],
          };
        } else {
          payload = {
            categorySelectedRowKeys:
              selectionState?.categorySelectedRowKeys?.filter(
                state => state !== record.key
              ),
            categorySelected: selectionState?.categorySelected?.filter(
              state => state !== record.name
            ),
            urlSelected: unSelectedUrl,
            urlSelectedRowKeys: unSelectedKey,
          };
        }

        dispatch({
          type: SET_WEBSITE_SELECTED_PAGES,
          payload,
        });
      },
    });
  }
};

export const handleChangeRowSelectionWebsiteCategoryTable = (
  selectionState,
  dispatch
) => {
  return {
    selectedRowKeys: selectionState.categorySelectedRowKeys,
    onSelect: (record, selected) =>
      websiteSelectionChangesConfirmationMessage(
        record,
        selected,
        selectionState,
        dispatch
      ),
  };
};

export const handleChangeRowSelectionWebsitePagesTable = (
  categoryTableSource,
  selectionState,
  dispatch
) => {
  return {
    selectedRowKeys: selectionState.urlSelectedRowKeys,
    onSelect: (record, selected) => {
      if (selectionState?.urlSelected?.length === 1 && !selected) {
        return message.error(WEBSITE_NO_PAGE_SELECTED_ERROR_MESSAGE);
      } else {
        const selectedUrlCategory = categoryTableSource?.find(item =>
          item.urlList?.map(urlObj => urlObj.url)?.includes(record.url)
        );
        const unSelectedUrlCategory = selectionState?.categorySelected?.filter(
          category => category !== selectedUrlCategory?.name
        );
        const unSelectedUrlCategoryRowKey =
          selectionState?.categorySelectedRowKeys?.filter(
            category => category !== selectedUrlCategory?.key
          );

        if (selected) {
          let payload = {
            ...selectionState,
            urlSelectedRowKeys: [
              ...selectionState?.urlSelectedRowKeys,
              record.key,
            ],
            urlSelected: [...selectionState?.urlSelected, record.url],
          };
          payload.categorySelected.push(selectedUrlCategory.name);
          payload.categorySelectedRowKeys.push(selectedUrlCategory.key);
          payload = {
            ...payload,
            categorySelected: [...new Set(payload.categorySelected)],
            categorySelectedRowKeys: [
              ...new Set(payload.categorySelectedRowKeys),
            ],
          };

          dispatch({
            type: SET_WEBSITE_SELECTED_PAGES,
            payload,
          });
        } else {
          const categorySelectedUrlCount = extractCategoryCountSelected(
            selectedUrlCategory?.name,
            selectionState?.urlSelected?.filter(state => state !== record.url),
            categoryTableSource
          );

          const payload = {
            ...selectionState,
            categorySelected:
              categorySelectedUrlCount > 0
                ? [...unSelectedUrlCategory, selectedUrlCategory.name]
                : unSelectedUrlCategory,
            categorySelectedRowKeys:
              categorySelectedUrlCount > 0
                ? [...unSelectedUrlCategoryRowKey, selectedUrlCategory.key]
                : unSelectedUrlCategoryRowKey,
            urlSelectedRowKeys: selectionState?.urlSelectedRowKeys?.filter(
              state => state !== record.key
            ),
            urlSelected: selectionState?.urlSelected?.filter(
              state => state !== record.url
            ),
          };

          dispatch({
            type: SET_WEBSITE_SELECTED_PAGES,
            payload,
          });
        }
      }
    },
  };
};

export const extractWebsiteScanList = scanList => {
  return scanList?.map((scannedUrl, idx) => {
    return {
      key: idx,
      url: scannedUrl,
      filterMethod: 'exactString',
    };
  });
};

export const extractAnswerList = (answer, isBotOpenAIEnabled) =>
  orderBy(extractAnswerData(answer, isBotOpenAIEnabled), 'lastEdited', 'desc');
