import React, { useEffect, useMemo, useState } from 'react';
import { getToken } from 'services/auth.service';
import {
  StyledModal,
  StyledCloseButton,
  StyledTitle,
  StyledNameTextfieldWrapper,
  StyledNameTextfield,
  StyledValueTextfieldWrapper,
  StyledValueTextarea,
  StyledButtonWrapper,
  StyledSaveAddAnother,
  StyledSave,
  ModalHeader,
} from './GlobalVarsAddModal.style';

const GlobalVarsAddModal = ({
  visible,
  onCloseModal,
  addGlobalVars,
  globalVarData,
  editGlobalVars,
}) => {
  const [nameValue, setNameValue] = useState(globalVarData?.name || '');
  const [globalVarsValue, setGlobalVarsValue] = useState(
    globalVarData?.value || ''
  );
  const [isEmptyNameField, setIsEmptyNameField] = useState(false);
  const [isEmptyGlobalVarsField, setIsEmptyGlobalVarsField] = useState(false);
  const token = getToken();

  useEffect(() => {
    if (globalVarData?.name) {
      setNameValue(globalVarData?.name);
      setGlobalVarsValue(globalVarData?.value);
    }
  }, [globalVarData?.name, globalVarData?.value]);

  const handleChangeName = event => {
    setNameValue(event.target.value);
    if (event.target.value.length > 0) {
      setIsEmptyNameField(false);
    }
  };

  const handleChangeGlobalVars = event => {
    setGlobalVarsValue(event.target.value);
    if (event.target.value.length > 0) {
      setIsEmptyGlobalVarsField(false);
    }
  };

  const clickSaveAddAnother = async () => {
    if (nameValue !== '' && globalVarsValue !== '') {
      await addGlobalVars(nameValue, globalVarsValue, token);
      setNameValue('');
      setGlobalVarsValue('');
      setIsEmptyNameField(false);
      setIsEmptyGlobalVarsField(false);
    } else {
      setIsEmptyNameField(true);
      setIsEmptyGlobalVarsField(true);
    }
  };

  const clickSave = async () => {
    if (nameValue !== '' && globalVarsValue !== '') {
      if (isEditing) {
        await editGlobalVars(nameValue, globalVarsValue, token);
      } else {
        await addGlobalVars(nameValue, globalVarsValue, token);
      }
      setGlobalVarsValue('');
      setIsEmptyNameField(false);
      setIsEmptyGlobalVarsField(false);
      onCloseModal();
    } else {
      setIsEmptyNameField(true);
      setIsEmptyGlobalVarsField(true);
    }
  };

  const isEditing = useMemo(() => globalVarData?.name, [globalVarData?.name]);

  return (
    <>
      <StyledModal
        visible={visible}
        footer={
          <StyledButtonWrapper>
            {!isEditing ? (
              <StyledSaveAddAnother onClick={clickSaveAddAnother}>
                Save and Add Another
              </StyledSaveAddAnother>
            ) : null}
            <StyledSave onClick={clickSave}>Save</StyledSave>
          </StyledButtonWrapper>
        }
      >
        <ModalHeader>
          {isEditing ? (
            <div>
              <StyledTitle>Edit Global Vars</StyledTitle>
            </div>
          ) : (
            <div>
              <StyledTitle>Add Global Vars</StyledTitle>
            </div>
          )}
          <StyledCloseButton onClick={onCloseModal} />
        </ModalHeader>
        <div>
          <StyledNameTextfieldWrapper>
            <h5>Name</h5>
            <StyledNameTextfield
              value={nameValue}
              status="error"
              placeholder={
                isEmptyNameField
                  ? 'Please Input Here'
                  : 'Enter global vars name here'
              }
              onChange={handleChangeName}
              error={isEmptyNameField}
              disabled={isEditing}
            />
          </StyledNameTextfieldWrapper>
          <StyledValueTextfieldWrapper>
            <h5>Value</h5>
            <StyledValueTextarea
              value={globalVarsValue}
              status="error"
              placeholder={
                isEmptyGlobalVarsField
                  ? 'Please Input Here'
                  : 'Enter global vars value here'
              }
              maxLength={560}
              autoSize={{ minRows: 6, maxRows: 10 }}
              onChange={handleChangeGlobalVars}
              error={isEmptyGlobalVarsField}
            />
          </StyledValueTextfieldWrapper>
        </div>
      </StyledModal>
    </>
  );
};
export default GlobalVarsAddModal;
