import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { UPDATE_USER } from 'store/action';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import useSelector from 'store/useSelector';
import {
  impersonatedUserSelector,
  isImpersonatingSelector,
} from 'selectors/admin';
import { getTokenSelector, userSelector } from 'selectors/user';
import { planTypeSelector } from 'selectors/plan';

const SUCCESS_MESSAGE = 'Successfully updated profile!';

const useUserProfile = () => {
  const [state, dispatch] = useContext(Context);
  const impersonatedUser = useSelector(impersonatedUserSelector);
  const isImpersonating = useSelector(isImpersonatingSelector);
  const planType = useSelector(planTypeSelector);
  const user = useSelector(userSelector);
  const token = useSelector(getTokenSelector);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const { version } = state;
  const [form] = Form.useForm();

  useEffect(() => {
    if (token) {
      if (
        isImpersonating &&
        impersonatedUser &&
        Object.keys(impersonatedUser).length
      ) {
        form.setFieldsValue({
          name: impersonatedUser.name,
          email: impersonatedUser.email,
        });
      } else {
        form.setFieldsValue({ name: user.name, email: user.email });
      }
    }
  }, [dispatch, form, token, user]); // run only once the component is mounted

  const editUser = async values => {
    setLoading(true);
    const userData = {
      name: values.name,
      email: values.email,
      password: values.password,
    };

    try {
      const updatedUser = await apiService.updateUser(userData);
      dispatch({ type: UPDATE_USER, payload: updatedUser.data });
      setSuccess(true);
      if (error) {
        setError(null);
      }
    } catch (error) {
      setSuccess(false);
      setError(error.message || DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const onFinishFailed = val => {};

  const handleGoBack = () => {
    history.goBack();
  };

  return {
    user,
    form,
    success,
    error,
    loading,
    SUCCESS_MESSAGE,
    version,
    editUser,
    onFinishFailed,
    handleGoBack,
    history,
    planType,
  };
};

export default useUserProfile;
