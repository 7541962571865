import { filesSelector } from 'selectors/bot/answers';
import useSelector from 'store/useSelector';

const useFile = () => {
  const allFiles = useSelector(filesSelector);

  return {
    allFiles,
  };
};

export default useFile;
