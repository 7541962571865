import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledDashboardItem,
  StyledInfoWrapper,
  StyledInfo,
  StyledName,
  StyledDescription,
  StyledLinkButton,
} from './DashboardItem.style';

const DashboardItem = ({ name, alias, link }) => {
  return (
    <StyledDashboardItem>
      <StyledInfoWrapper>
        <StyledName>{name}</StyledName>
        <StyledInfo></StyledInfo>
      </StyledInfoWrapper>
      <StyledLinkButton to={link} exact>
        <span>View {alias}</span>
      </StyledLinkButton>
    </StyledDashboardItem>
  );
};

DashboardItem.propTypes = {
  name: PropTypes.string,
  alias: PropTypes.string,
  link: PropTypes.string,
};

export default DashboardItem;
