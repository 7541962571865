import { useContext, useState } from 'react';
import { message } from 'antd';

import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import {
  INITIALIZE_API_KEY,
  SET_PUBLIC_INFO,
  SET_PUBLIC_KEYS,
  SPAWN_CREATE,
  UNSET_API_KEY,
} from 'store/action';
import { getTokenSelector } from 'selectors/user';
import useSelector from 'store/useSelector';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { stripUUID } from 'utils';

const useApiKey = () => {
  const [state, dispatch] = useContext(Context);
  const token = useSelector(getTokenSelector);
  const [apiKeyloading, setApiKeyLoading] = useState(false);
  const { apiGatewayConfig, publicKey, pubAskedQuestion, sentinel } = state;

  const initializeApiGateway = async (key, askedQuestion) => {
    const res = await apiService.initializeApiGateway(
      sentinel,
      token,
      key,
      stripUUID(askedQuestion)
    );

    dispatch({
      type: INITIALIZE_API_KEY,
      payload: {
        apiConfig: res.data?.report[0],
        isIntegrationComponent: false,
      },
    });
  };

  const spawnCreate = async (pK, pAQ) => {
    const apiName = 'zsb_public_api';
    try {
      const res = await apiService.spawnCreate(apiName, token);
      const pkRes = await apiService.getPublicKey(token);
      pK = pkRes.data.anyone;
      pAQ = res.data.jid;

      await initializeApiGateway(pK, pAQ);

      dispatch({
        type: SPAWN_CREATE,
        payload: {
          publicKey: pK,
          pubAskedQuestion: pAQ,
        },
      });

      return [pK, pAQ];
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  const handleEnableApiKey = async () => {
    setApiKeyLoading(true);
    try {
      if (!publicKey) {
        await spawnCreate(publicKey, pubAskedQuestion);
      } else {
        await initializeApiGateway(publicKey, pubAskedQuestion);
      }
      setApiKeyLoading(false);
    } catch (error) {
      setApiKeyLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleDisableApiKey = async () => {
    setApiKeyLoading(true);
    if (!publicKey) {
      try {
        [publicKey, pubAskedQuestion] = await spawnCreate(
          publicKey,
          pubAskedQuestion
        );
      } catch (error) {
        setApiKeyLoading(false);
        return message.error(error.message || DEFAULT_ERROR_MESSAGE);
      }
    }

    try {
      await apiService.removeAPIGateway(sentinel, token);
      dispatch({
        type: UNSET_API_KEY,
      });

      setApiKeyLoading(false);
    } catch (error) {
      setApiKeyLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  return {
    apiGatewayConfig,
    apiKeyloading,
    handleDisableApiKey,
    handleEnableApiKey,
    message,
  };
};

export default useApiKey;
