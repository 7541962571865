import { Table, Form } from 'antd';
import Input from 'components/Input';
import styled from 'styled-components';
import {
  StyledFlexLeftColumn,
  StyledFlexRowLeft,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';
import { cssVariables } from 'styles/root';

export const StyledToolsContent = styled(StyledFlexLeftColumn)`
  input,
  textarea {
    width: 100%;
  }

  .ant-btn {
    margin-top: 10px;
  }
`;

export const StyledFormToolsContent = styled(Form)`
  width: ${props => (props.fullWidth ? '100% !important' : '60% !important')};
  input,
  textarea {
    width: 100%;
  }

  .ant-btn {
    margin-top: 10px;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  width: 100% !important;
  margin-bottom: 0 !important;
  > * {
    width: 100% !important;
  }

  > * + * {
    margin: 5px 0;
  }

  > label {
    width: 100px;
  }
  input,
  textarea {
    width: 100%;
  }

  .ant-input-sm {
    padding: 5px;
    width: 100% !important;
  }

  .anticon {
    height: max-content;
    width: auto;
    &:not(.anticon-delete) {
      color: ${cssVariables.primaryBlue};
    }

    &.anticon-delete {
      color: ${cssVariables.red10};
    }

    &.anticon-minus-circle {
      margin-left: 5px;
      color: ${cssVariables.red10};
    }
  }

  .ant-btn {
    margin-top: 10px;
  }
`;

export const StyledFormRow = styled(StyledFlexLeftColumn)`
  width: 100% !important;
  > * {
    width: 100% !important;
  }

  > * + * {
    margin: 5px 0;
  }

  > label {
    width: 100px;
  }
  input,
  textarea {
    width: 100%;
  }

  .ant-input-sm {
    padding: 5px;
    width: 100% !important;
  }

  .anticon {
    height: max-content;
    width: auto;
    &:not(.anticon-delete) {
      color: ${cssVariables.primaryBlue};
    }

    &.anticon-delete {
      color: ${cssVariables.red10};
    }

    &.anticon-minus-circle {
      margin-left: 5px;
      color: ${cssVariables.red10};
    }
  }

  .ant-btn {
    margin-top: 10px;
  }
`;

export const StyledNameInput = styled(Input)`
  width: 60% !important;
  margin-right: 5px;
  .ant-input {
    width: 100%;
  }
`;

export const StyledValueInput = styled(Input)`
  width: 100% !important;
  .ant-input {
    width: 100%;
  }
`;

export const StyledFieldRows = styled(StyledSpaceBetweenFlexCenter)`
  width: 100% !important;
  > * + * {
    margin: 0px 5px;
  }
  > * {
    width: 100%;
  }

  .ant-btn {
    margin-top: 10px;
  }
`;

export const StyledDefaultPropName = styled(StyledFlexRowLeft)`
  background-color: ${cssVariables.gray3};
  border: 1px solid ${cssVariables.gray2};
  padding: 5px;
  border-radius: 5px;
  color: ${cssVariables.gray0};
`;

export const StyledFunctionsTable = styled(Table)`
  .ant-table-tbody {
    .ant-table-row {
      .anticon {
        width: 40px;
        height: 40px;
        cursor: pointer;
        justify-content: center;

        &:hover {
          background-color: ${cssVariables.gray2};
          border-radius: 50%;
        }
      }
    }
  }
`;

export const StyledBtnAdvanceSettings = styled.button`
  display: flex;
  border: none;
  background: #ffff;
  color: #1667e7;
  border-radius: 1px;
  cursor: pointer;
  line-height: 32px;
  width: auto;

  &:hover {
    background: #1667e726;
  }
`;
