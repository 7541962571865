import styled from 'styled-components';
import { Menu, Input as NormalInput, Tabs } from 'antd';
import { cssVariables } from 'styles/root';
import Button from 'components/Button';
import Input from 'components/Input';
import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';

const { TabPane } = Tabs;

export const StyledTabPane = styled(TabPane)`
  display: flex;
  padding: 32px 28px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  & .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 16px;
    font-weight: ${cssVariables.font.normal};
    line-height: 24px;
    color: #000;
    padding-left: 28;
  }

  div {
    display: flex;
    gap: 5px;
    align-items: center;
    word-break: break-all;
    width: 100%;

    > span:last-child {
      width: 100%;
      border-bottom: 1px solid ${props => props.theme.gray2};
      min-height: 50px;
    }

    > span:first-child {
      width: 10%;
      font-weight: ${cssVariables.font.bold};
    }
  }
  button.ant-btn-lg {
    width: 10%;
  }
`;

export const StyledRoot = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
`;

export const StyledTabs = styled(Tabs)`
  margin-top: 48px;

  & .ant-tabs-content {
    background: #fff;
    padding: 2% 5%;
  }

  & .ant-tabs-nav {
    margin: 0;

    & .ant-tabs-nav-list {
      width: 200px;
    }
  }
`;

export const StyledTabPane1 = styled(TabPane)`
  width: 100%;
  padding: 20px 28px;

  & .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 16px;
    font-weight: ${cssVariables.font.normal};
    line-height: 24px;
    color: #000;
    padding-left: 28;
  }
`;

export const StyledBotInfoBlock = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  justify-content: space-between;
  padding: 20px 0px;
  border-top: 1px solid #d9dadb;

  p {
    margin-bottom: 0.1em;
  }
  &:first-child {
    border-top: none;
  }
`;

export const StyledBotInfo = styled.div`
  margin-right: 20px;
`;

export const StyledBotInfoTitle = styled.h5`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.normal};
  font-size: 1rem;
  line-height: 24px;
  color: #000;

  .anticon {
    font-size: 12px;
  }
`;

export const StyledBotInfoValue = styled.div`
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d9dadb;
  padding: 8px 12px;
  font-family: ${cssVariables.font.fontFamily};
  font-size: 16px;
  line-height: 24px;
  color: #33363c;
`;

export const StyledBotInfoDescription = styled.p`
  font-family: ${cssVariables.font.fontFamily};
  font-size: 0.8rem;
  line-height: 24px;
  color: #33363c;
  padding-bottom: 2%;
`;

export const StyledSendCallBackLabel = styled.span`
  width: 34%;
  display: inline-block;
`;

export const StyledBtnAction = styled(Button)`
  padding: 6px 12px;
  background: #f5f5f5;
  border-radius: 3px;
  border: none;
  font-family: ${cssVariables.font.fontFamily};
  font-size: 16px;
  line-height: 24px;
  color: #33363c;
  cursor: pointer;
`;

export const StyledInput = styled(Input)`
  width: 150px;
  border-color: ${props =>
    props.isThresholdLimitReached
      ? cssVariables.errorText
      : cssVariables.blue10};
`;

export const StyledDefaultAnswerInput = styled(NormalInput)`
  margin-bottom: 5px;
`;

export const StyledBtnSaveChanges = styled(Button)`
  background: #1667e7;
  border-radius: 3px;
  border: none;
  width: 100%;
  font-family: ${cssVariables.font.fontFamily};
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
`;

export const StyledCardContainer = styled.div`
  &.card-container {
    position: relative;

    label {
      min-width: 100px;
    }

    & > .ant-tabs-card {
      & > .ant-tabs-nav {
        margin: 0;

        &::before {
          display: none;
        }
      }

      & .ant-tabs-tab {
        background: #f4f6f8;
        box-shadow: inset 0px -1px 0px #e8f0fd;
        border-color: transparent;
        padding: 16px 24px;
        height: 56px;
        font-family: Roboto;
        font-style: normal;
        font-weight: ${cssVariables.font.normal};
        font-size: 16px;
        line-height: 24px;
        color: #66696f;

        &.ant-tabs-tab-active {
          background: #fff;
          box-shadow: inset 0px 1px 0px #3379e6;
          font-weight: ${cssVariables.font.normal};

          &.ant-tabs-tab-btn {
            color: #26292d;
          }
        }
      }
    }
  }
`;

export const StyledSettingsAnswerEditor = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;

  h4 {
    margin: 0;
  }
`;

export const StyledEmailReceiptsSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > span:first-child {
    width: 40%;
  }

  input {
    margin-left: 16px;
  }

  .anticon {
    margin: 0 5px;
  }
`;

export const StyledAnswerEditor = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-top: 1%;

  & .ant-tabs-tab {
    color: #66696f;
    background: #ffff !important;
    margin-left: 0px !important;

    &.ant-tabs-tab-active {
      box-shadow: unset !important;
    }
  }

  & .ant-tabs-tab.ant-tabs-tab-active {
    background: #fff !important;
  }

  & .ant-tabs-tab:hover {
    color: #26292d;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #26292d;
  }

  @media (max-width: 600px) {
    min-width: 100%;
  }
`;

export const StyledLabel = styled.div`
  width: 10em;
  font-size: 0.8rem;
`;

export const StyledColumnSections = styled(StyledFlexLeftColumn)`
  > * + * {
    margin-top: 5px;
  }
  width: 100%;
`;

export const StyledTabActionButtons = styled(Menu)`
  text-transform: capitalize;
  background: transparent;
  display: flex;
  justify-content: flex-end;
`;
