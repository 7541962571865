import { message } from 'antd';
import { useContext, useState } from 'react';
import _ from 'lodash';
import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { DELETE_CATEGORY } from 'store/action';
import useSelector from 'store/useSelector';
import { getTokenSelector } from 'selectors/user';
import { allAnswersSelector } from 'selectors/bot/answers';
import { stripUUID } from 'utils';
import { isUserCategorySelector } from 'selectors/bot/categories';

const useDeleteCategory = ({ onCancel, category }) => {
  const [state, dispatch] = useContext(Context);
  const { sentinel } = state;
  const token = useSelector(getTokenSelector);
  const allAnswers = useSelector(allAnswersSelector);
  const isUserCategory = useSelector(isUserCategorySelector, category);

  const { name } = category;
  const [deleteAnswers, setDeleteAnswers] = useState(isUserCategory);
  const [textChallenge, setTextChallenge] = useState('');
  const [loading, setLoading] = useState(false);
  const answersList = allAnswers.filter(
    answer => stripUUID(answer.categoryId) === stripUUID(category.jid)
  );

  const deleteCategory = async categoryId => {
    try {
      await apiService.deleteCategory(
        sentinel,
        categoryId,
        deleteAnswers,
        token
      );

      await dispatch({
        type: DELETE_CATEGORY,
        payload: {
          jid: categoryId,
          isDeleteAnswer: deleteAnswers,
        },
      });

      message.success('Successfully deleted a category.');
    } catch (error) {
      message.error(error.message || 'Something went wrong. Try again later.');
    }
  };

  const onChange = e => {
    setDeleteAnswers(e.target.value);
  };
  const handleOnChange = e => {
    setTextChallenge(e.target.value);
  };

  const onClickDelete = async () => {
    setLoading(true);
    await deleteCategory(category.jid);
    await onCancel();
    setLoading(false);
  };

  return {
    onClickDelete,
    onChange,
    handleOnChange,
    name,
    textChallenge,
    loading,
    answersList,
    deleteAnswers,
  };
};

export default useDeleteCategory;
