import styled from 'styled-components';
import { Layout } from 'antd';

export const Container = styled.div`
  display: flex;
  position: relative;
  height: ${props => (props?.mainPage ? '90vh' : 'auto')};
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 10px;
    margin-right: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
    margin-right: 15px;
    border: 2px solid #f9f9f9;
  }
`;

export const CenteredMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 45vh;
`;

export const StyledLayoutSider = styled(Layout.Sider)``;
