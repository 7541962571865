import { TOGGLE_SEND_CALLBACK_LOG } from 'store/action';

export const settingsReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_SEND_CALLBACK_LOG: {
      return {
        ...state,
        bot: {
          ...state.bot,
          shouldSendCallbackLog: !state.bot.shouldSendCallbackLog,
        },
      };
    }

    default:
      return state;
  }
};
