import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import Input from 'components/Input';
import Modal from 'components/Modals/GenericModal';
import { StyledDeleteContainer } from './DeleteCategoryModal.styles';
import Button from 'components/Button';
import useDeleteCategory from './hooks';

const DeleteCategoryModal = ({ visible, onCancel, category }) => {
  const {
    onClickDelete,
    onChange,
    handleOnChange,
    name,
    textChallenge,
    loading,
    answersList,
    deleteAnswers,
  } = useDeleteCategory({ onCancel, category });

  return (
    <Modal
      visible={visible}
      title={<h3>Delete {name}?</h3>}
      onCancel={onCancel}
      width={510}
      footer={[
        <Button key="back" onClick={onCancel} value={'Cancel'} />,
        <Button
          key="submit"
          variant="red"
          onClick={onClickDelete}
          disabled={textChallenge !== category.name}
          value={'Delete Category'}
        />,
      ]}
      spinning={loading}
    >
      <StyledDeleteContainer>
        <p>
          You are deleting <strong>{name}</strong> category with{' '}
          <strong>{answersList.length} answers. </strong>
          This action cannot be undone.
        </p>
        <p>Please select an action from below:</p>
        <Radio.Group onChange={onChange} value={deleteAnswers}>
          <Radio value={false}>{`Move answers to Default Category`}</Radio>
          <Radio value={true}>{`Delete Answers from Bot's Answer Bank`}</Radio>
        </Radio.Group>
        <br />
        <br />
        <p>To proceed, write `{name}` in the field below.</p>
        <Input
          required
          value={textChallenge}
          onChange={handleOnChange}
          maxLength={30}
        />
      </StyledDeleteContainer>
    </Modal>
  );
};

DeleteCategoryModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  category: PropTypes.object,
  isUserCategory: PropTypes.bool,
};

export default DeleteCategoryModal;
