import { Row } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
  background-color: ${props =>
    props.isHighlighted ? cssVariables.blue1 : 'none'};
`;

export const StyledContent = styled.div`
  width: 94%;
  padding-right: 20px;
`;

export const StyledQuestionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 90%;
  margin-left: auto;
  margin-bottom: 15px;
  word-break: break-word;
`;

export const StyledQuestion = styled.span`
  padding: 15px;
  color: #fff;
  border-radius: 10px;
  background-color: rgba(33, 35, 38, 0.774);
  word-break: break-all;
`;

export const StyledAnswerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 90%;
  word-break: break-word;
`;

export const StyledAnswer = styled.div`
  padding: 15px;
  border-radius: 10px;
  background-color: #f0f2f5;

  & p {
    margin-bottom: 0px;
  }
`;

export const StyledDateTime = styled.div`
  font-size: 12px;
  text-align: center;
`;

export const StyledCallbackModalTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h4 {
    margin-bottom: 0 !important;
  }
`;

export const StyledLegend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  font-size: 11px;

  .ant-tag {
    width: 40px;
  }
`;

export const StyleCustomerInforWrapper = styled(Row)`
  margin: 2% 0;
  font-size: 11px;
`;
