import React, { useContext, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Radio, Space, Tooltip, Upload, Popover } from 'antd';

import Input from 'components/Input';
import Modal from 'components/Modals/GenericModal';
import UploadButton from '../UploadButton/UploadButton';
import {
  StyledAvatarModalRadio,
  StyledAvatarSelect,
  StyledAvatarSelectLabel,
  StyledChromePickerPosition,
  StyledTempIconImage,
  StyledImageUploaderWrapper,
  StyledAvatar,
} from './LauncherIconPickerModal.style';
import { Context } from 'store/store';
import {
  CLOSE_WIDGET_AVATAR_PICKER_MODAL,
  OPEN_IMAGE_VIEWER_MODAL,
  SET_WIDGET_AVATAR_COLOR,
  SET_WIDGET_LAUNCHER_ICON,
  SET_WIDGET_LAUNCHER_AVATAR_TYPE,
  SET_WIDGET_THEME_COLOR,
} from 'store/action';
import useSelector from 'store/useSelector';
import {
  isSearchWidgetSelector,
  widgetIconColorSelector,
  widgetThemeColorSelector,
} from 'selectors/bot/integration';
import { getBase64 } from 'utils';
import DefaultIcon from '../svg/DefaultIcon';
import Icon1 from '../svg/Icon1';
import Icon2 from '../svg/Icon2';
import Icon3 from '../svg/Icon3';
import Icon4 from '../svg/Icon4';
import {
  launcherAvatarCloudPathSelector,
  launcherAvatarSelector,
  launcherAvatarTypeSelector,
  showLauncherImagePreviewSelector,
} from 'selectors/bot/integration/settings';

const LauncherIconPickerModal = () => {
  const [, dispatch] = useContext(Context);

  const themeColor = useSelector(widgetThemeColorSelector);
  const iconColor = useSelector(widgetIconColorSelector);
  const launcherAvatar = useSelector(launcherAvatarSelector);
  const launcherAvatarType = useSelector(launcherAvatarTypeSelector);
  const isSearchWidget = useSelector(isSearchWidgetSelector);
  const showLauncherImagePreview = useSelector(
    showLauncherImagePreviewSelector
  );
  const launcherAvatarInCloud = useSelector(launcherAvatarCloudPathSelector);

  const [showIconColorPicker, setShowIconColorPicker] = useState(false);

  const avatarColor = isSearchWidget ? themeColor : iconColor;
  const avatarBackgroundColor = isSearchWidget ? '#fff' : themeColor;

  const handleChangeAvatar = evt => {
    dispatch({ type: SET_WIDGET_LAUNCHER_ICON, payload: evt.target.value });
  };

  const handleChangeAvatarImg = async ({ file, fileList }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(fileList[0]?.originFileObj);
    }

    dispatch({
      type: SET_WIDGET_LAUNCHER_ICON,
      payload: file,
    });
  };

  const handleChangeAvatarColor = color => {
    if (isSearchWidget) {
      dispatch({ type: SET_WIDGET_THEME_COLOR, payload: color.hex });
    } else {
      dispatch({ type: SET_WIDGET_AVATAR_COLOR, payload: color.hex });
    }
  };

  const handleChangeAvatarType = evt => {
    dispatch({
      type: SET_WIDGET_LAUNCHER_AVATAR_TYPE,
      payload: evt.target.value,
    });
  };

  const handleCloseModal = () => {
    dispatch({ type: CLOSE_WIDGET_AVATAR_PICKER_MODAL });
  };

  const handleShowImagePreview = () => {
    dispatch({
      type: OPEN_IMAGE_VIEWER_MODAL,
      payload: { source: launcherAvatar, title: 'Launcher Avatar Preview' },
    });
  };

  return (
    <Modal
      visible={true}
      onOk={handleCloseModal}
      okText="Close"
      destroyOnClose
      closable={false}
    >
      <Radio.Group value={launcherAvatarType} onChange={handleChangeAvatarType}>
        <Space direction="vertical">
          <StyledAvatarModalRadio value="icon">
            Use Icons
            {launcherAvatarType !== 'custom' ? (
              <div>
                <br />
                <br />
                <Tooltip
                  title="When choosing this, showing avatar on chat header and next to response is not allowed."
                  className="default"
                >
                  <StyledAvatarSelect
                    value="default"
                    onChange={handleChangeAvatar}
                    type="radio"
                    id="default"
                    name="avatar"
                    checked={launcherAvatar === 'default'}
                  />
                  <StyledAvatarSelectLabel htmlFor="default">
                    <StyledAvatar
                      src={<DefaultIcon iconColor={avatarColor} />}
                      integrationDataColor={avatarBackgroundColor}
                      size={45}
                    />
                  </StyledAvatarSelectLabel>
                </Tooltip>
                <Tooltip title="When choosing this, showing avatar on chat header and next to response is not allowed.">
                  <StyledAvatarSelect
                    value="icon1"
                    onChange={handleChangeAvatar}
                    type="radio"
                    id="checkbox1"
                    name="avatar"
                    checked={launcherAvatar === 'icon1'}
                  />
                  <StyledAvatarSelectLabel htmlFor="checkbox1">
                    <StyledAvatar
                      size={45}
                      src={<Icon1 iconColor={avatarColor} />}
                      integrationDataColor={avatarBackgroundColor}
                    />
                  </StyledAvatarSelectLabel>
                </Tooltip>
                <StyledAvatarSelect
                  value="icon2"
                  onChange={handleChangeAvatar}
                  type="radio"
                  id="checkbox2"
                  name="avatar"
                  checked={launcherAvatar === 'icon2'}
                />
                <StyledAvatarSelectLabel htmlFor="checkbox2">
                  <StyledAvatar
                    src={<Icon2 iconColor={avatarColor} />}
                    integrationDataColor={avatarBackgroundColor}
                    size={45}
                  />
                </StyledAvatarSelectLabel>
                <StyledAvatarSelect
                  value="icon3"
                  onChange={handleChangeAvatar}
                  type="radio"
                  id="checkbox3"
                  name="avatar"
                  checked={launcherAvatar === 'icon3'}
                />
                <StyledAvatarSelectLabel htmlFor="checkbox3">
                  <StyledAvatar
                    src={<Icon3 iconColor={avatarColor} />}
                    integrationDataColor={avatarBackgroundColor}
                    size={45}
                  />
                </StyledAvatarSelectLabel>
                <StyledAvatarSelect
                  value="icon4"
                  onChange={handleChangeAvatar}
                  type="radio"
                  id="checkbox4"
                  name="avatar"
                  checked={launcherAvatar === 'icon4'}
                />
                <StyledAvatarSelectLabel htmlFor="checkbox4">
                  <StyledAvatar
                    src={<Icon4 iconColor={avatarColor} />}
                    integrationDataColor={avatarBackgroundColor}
                    size={45}
                  />
                </StyledAvatarSelectLabel>
                <br />
                <br />
                <Popover
                  content={
                    <StyledChromePickerPosition
                      onDoubleClick={() => setShowIconColorPicker(false)}
                    >
                      <ChromePicker
                        color={avatarColor}
                        onChangeComplete={handleChangeAvatarColor}
                      />
                    </StyledChromePickerPosition>
                  }
                  trigger="click"
                  open={showIconColorPicker}
                  onOpenChange={() =>
                    setShowIconColorPicker(!showIconColorPicker)
                  }
                  overlayClassName={{
                    '& .ant-popover-inner': {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <Input
                    label="Icon Color"
                    value={avatarColor}
                    onChange={handleChangeAvatarColor}
                    placeholder={`Pick your icon color (e.g. #D3D3D3)`}
                  />
                </Popover>
              </div>
            ) : null}
          </StyledAvatarModalRadio>
          <StyledAvatarModalRadio
            value="custom"
            onChange={handleChangeAvatarType}
          >
            Custom
            {launcherAvatarType === 'custom' ? (
              <>
                <StyledTempIconImage
                  hidden={!showLauncherImagePreview}
                  onClick={handleShowImagePreview}
                >
                  <img src={launcherAvatarInCloud} alt="launcher icon" />
                </StyledTempIconImage>
                <StyledImageUploaderWrapper>
                  <Upload
                    beforeUpload={({ file }) =>
                      file.type === 'image/png' || file.type === 'image/jpg'
                    }
                    listType="picture-card"
                    onPreview={handleShowImagePreview}
                    onChange={handleChangeAvatarImg}
                    accept=".png, .jpg, .jpeg"
                    maxCount={1}
                  >
                    <UploadButton />
                  </Upload>
                </StyledImageUploaderWrapper>
              </>
            ) : null}
          </StyledAvatarModalRadio>
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default LauncherIconPickerModal;
