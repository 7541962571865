export const header = {
  title: 'Zero Shot Bot',
  menu: [
    {
      title: 'Report a Bug',
      path: 'report a bug',
      key: 1,
    },
    {
      title: 'About Us',
      path: 'https://www.zeroshotbot.com/',
      key: 2,
    },
    {
      title: 'Documentation',
      path: 'https://docs.zeroshotbot.com/',
      key: 3,
    },
  ],
  userName: 'Deny He',
};
