import { apiService } from 'services/api.service';

export const getBotSet = async (nd, sentinel, token) => {
  try {
    const botSet = await apiService.getBotSet(nd, sentinel, token);
    return botSet;
  } catch (error) {
    console.log('error', error);
  }
};

export const getGraphObject = async (jid, token, depth) => {
  try {
    const userObject = await apiService.getGraphObject(jid, token, depth);
    return userObject;
  } catch (error) {
    console.log('error', error);
  }
};

export const changeBotSet = async (sentinel, token, graph, ctx = {}) => {
  try {
    const botSet = await apiService.changeBotSet(sentinel, token, graph, ctx);
    return botSet;
  } catch (error) {
    console.log('error', error);
  }
};

export const masterUpdateUser = async (id, token, data = {}) => {
  try {
    const updatedUser = await apiService.masterUpdateUser(id, token, data);
    return updatedUser;
  } catch (error) {
    console.log('error', error);
  }
};
