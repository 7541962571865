export const VALIDATION_WELCOME_STEPS = [
  {
    content: (
      <div>
        <p>
          Use this feature to run an automated test of your answers. Just simply
          add your question/s here then run the test to check if your Bot’s
          response are correct based on the threshold.
        </p>
        <p>Improve your Bot’s performance by running this test regularly.</p>
      </div>
    ),
    target: '.new-suite-btn',
    disableBeacon: true,
    placement: 'auto',
  },
];

export const FIRST_SUITE = [
  {
    content: (
      <div>
        <p>Click to add questions that you want to test.</p>
      </div>
    ),
    target: '.add-question-btn',
    disableBeacon: true,
    placement: 'auto',
  },
];

export const RESPONSE_PICKER_STEPS = [
  {
    content: (
      <div>
        <p>Choose your expected answer by clicking the tick mark.</p>
        <p>
          Note: The Best Answer is placed on the top and has the highest score.
        </p>
      </div>
    ),
    target: '.firstAnswer',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    content: (
      <div>
        <p>{`Click OK to save the test case.`}</p>
      </div>
    ),
    target: '.validation-response__button',
    disableBeacon: true,
    placement: 'bottom',
  },
];
