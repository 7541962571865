import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Switch } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';

import ToolTip from 'components/ToolTips/BaseToolTip';
import {
  currentIntegrationSettingsSettingPropSelector,
  isMobileIntegrationSelector,
} from 'selectors/bot/integration';
import useSelector from 'store/useSelector';
import { StyledLabelAutoOpen } from 'components/BotCustomizer/CustomizeBot/CustomizeBot.style';

const StyledWrapper = styled.div`
  > * {
    margin: 10px 0;
    width: 100%;
  }
`;

const SwitchSection = ({ onToggleChange }) => {
  const {
    autoOpen,
    speechToText,
    textToSpeech,
    typingExperience,
    showCloseButton,
  } = useSelector(currentIntegrationSettingsSettingPropSelector);

  const isMobileIntegration = useSelector(isMobileIntegrationSelector);

  return (
    <StyledWrapper>
      <Row>
        <Col span={12}>
          <b>{'Auto Open Widget'}</b>
        </Col>
        <Col span={12}>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={autoOpen}
            onChange={onToggleChange('autoOpen')}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <b>{'Enable Speech-to-text'} </b>
          <ToolTip
            title={`Speech Recognition is not available on Apple Silicon chips`}
          >
            <InfoCircleTwoTone />
          </ToolTip>
        </Col>
        <Col span={12}>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={speechToText}
            onChange={onToggleChange('speechToText')}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <b>{'Enable Text-to-speech'} </b>
        </Col>
        <Col span={12}>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={textToSpeech}
            onChange={onToggleChange('textToSpeech')}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <b>{'Enable Typing Experience'} </b>
        </Col>
        <Col span={12}>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={typingExperience}
            onChange={onToggleChange('typingExperience')}
          />
        </Col>
      </Row>

      {isMobileIntegration ? (
        <Row>
          <Col span={12}>
            <StyledLabelAutoOpen>{'Show Close Button'}</StyledLabelAutoOpen>
          </Col>
          <Col span={12}>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={showCloseButton}
              onChange={onToggleChange('showCloseButton')}
            />
          </Col>
        </Row>
      ) : null}
    </StyledWrapper>
  );
};

SwitchSection.propTypes = {
  onToggleChange: PropTypes.func,
};

export default SwitchSection;
