import React, { useMemo } from 'react';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import Modal from '../GenericModal';
import { cssVariables } from 'styles/root';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
import { SuccessMessage } from 'pages/UserProfile/PlanAndPayments/PlanAndPayments.style';
import Button from 'components/Button';
import useSwitchPlanSubscriptionModal from './hooks';
import { UPGRADE_SUCCESS_MESSAGE } from 'constants/plan';

const SwitchPlanSubscriptionModal = () => {
  const {
    handleCloseModal,
    handleUpgradeSubscription,
    loading,
    showSwitchPlanSubscriptionModal,
    showUpgradedSubscriptionSuccessMessage,
    switchPlan,
  } = useSwitchPlanSubscriptionModal();

  const Content = useMemo(() => {
    if (showUpgradedSubscriptionSuccessMessage) {
      return (
        <>
          <SuccessMessage>{UPGRADE_SUCCESS_MESSAGE}</SuccessMessage>
          <Button
            value="Back to home page"
            variant="link"
            bordered
            onClick={handleCloseModal}
          />
        </>
      );
    } else if (showSwitchPlanSubscriptionModal) {
      return (
        <>
          <StyledFlexRowLeft>
            <ExclamationCircleTwoTone
              twoToneColor={cssVariables.warning}
              style={{ fontSize: 24 }}
            />
            <span>{`Are you sure you want to ${switchPlan.action} your subscription?`}</span>
          </StyledFlexRowLeft>
        </>
      );
    }
  }, [showUpgradedSubscriptionSuccessMessage, showSwitchPlanSubscriptionModal]);

  return (
    <Modal
      title={`${
        switchPlan.action.charAt(0).toUpperCase() + switchPlan.action.slice(1)
      } Subscription`}
      onOk={handleUpgradeSubscription}
      onCancel={handleCloseModal}
      cancelText={'Close'}
      okButtonProps={{
        loading,
      }}
      okText={'Upgrade my subscription'}
      visible={showSwitchPlanSubscriptionModal}
      spinning={loading}
      noOkButton={showUpgradedSubscriptionSuccessMessage}
      noCancelButton={showUpgradedSubscriptionSuccessMessage}
    >
      {Content}
    </Modal>
  );
};

export default SwitchPlanSubscriptionModal;
