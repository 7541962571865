import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { cssVariables } from 'styles/root';

const { TextArea: AntdTextArea } = Input;

export const StyledTextArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledSpan = styled.span`
  font-weight: ${cssVariables.font.bold};
  padding-top: ${props => (props.noPaddingTop ? 0 : '10px')};
`;

export const StyledAntdTextArea = styled(AntdTextArea)`
  border-radius: 5px;
  padding: 5px;
  border: 0.5 solid ${cssVariables.blueishGray};

  ${props => {
    if (props.$full) {
      return css`
        display: flex;
        width: 100% !important;
      `;
    }
  }}

  &:focus-visible {
    border: none !important;
  }
`;
