import React from 'react';
import { Col, Row, Typography } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';

import {
  StyledCodeBlock,
  StyledCodeButton,
  StyledSnippets,
} from './Web.styles';
import useSelector from 'store/useSelector';
import {
  botComponentSelector,
  headTagCodeSelector,
} from 'selectors/bot/integration/settings';

const { Paragraph } = Typography;

const SandboxIntegration = () => {
  const zsbComponent = useSelector(botComponentSelector);
  const headTagCode = useSelector(headTagCodeSelector);

  return (
    <StyledSnippets>
      <Row style={{ alignItems: 'center' }}>
        <Col
          xs={{ span: 20 }}
          md={{ span: 14 }}
          lg={{ span: 14 }}
          xl={{ span: 14 }}
        >
          {'Copy and paste it in the head tag of your page.'}
        </Col>
        <Col
          xs={{ span: 20, offset: 0 }}
          md={{ span: 4, offset: 6 }}
          lg={{ span: 4, offset: 6 }}
          xl={{ span: 4, offset: 6 }}
        >
          <CopyToClipboard text={headTagCode}>
            <StyledCodeButton
              className="secondary"
              value="Copy code"
              style={{ padding: 0 }}
            />
          </CopyToClipboard>
        </Col>
      </Row>

      <StyledCodeBlock>
        <Paragraph>
          <pre>
            <code>{headTagCode}</code>
          </pre>
        </Paragraph>
      </StyledCodeBlock>

      <Row style={{ alignItems: 'center' }}>
        <Col
          xs={{ span: 20 }}
          md={{ span: 14 }}
          lg={{ span: 14 }}
          xl={{ span: 14 }}
          span={14}
        >
          Copy and paste this code into the page where you want the bot to
          appear.
        </Col>
        <Col
          xs={{ span: 20, offset: 0 }}
          md={{ span: 4, offset: 6 }}
          lg={{ span: 4, offset: 6 }}
          xl={{ span: 4, offset: 6 }}
        >
          {' '}
          <CopyToClipboard text={zsbComponent}>
            <StyledCodeButton
              className="secondary"
              value="Copy code"
              style={{ padding: 0 }}
            />
          </CopyToClipboard>
        </Col>
      </Row>
      <StyledCodeBlock>
        <Paragraph>
          <pre>
            <code>{zsbComponent}</code>
          </pre>
        </Paragraph>
      </StyledCodeBlock>
    </StyledSnippets>
  );
};

export default SandboxIntegration;
