import styled, { css } from 'styled-components';
import { cssVariables } from './root';
import { Tag } from 'antd';

export const StyledSpaceBetweenFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
`;

export const StyledSpacEvenlyFlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: row;
  ${props => {
    if (!props.padding?.includes('px')) {
      return css`
        padding: ${props.padding}px;
      `;
    }
    return ``;
  }}
`;

export const StyledFlexRowCenter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  ${props => {
    if (!props.padding?.includes('px')) {
      return css`
        padding: ${props.padding}px;
      `;
    } else if (props.padding?.includes('px')) {
      return css`
        padding: ${props.padding};
      `;
    }
    return ``;
  }}
`;

export const StyledFlexRowRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  ${props => {
    if (!props.padding?.includes('px')) {
      return css`
        padding: ${props.padding}px;
      `;
    } else if (props.padding?.includes('px')) {
      return css`
        padding: ${props.padding};
      `;
    }
    return ``;
  }}

  .ant-tag {
    text-transform: capitalize;
  }
`;

export const StyledFlexRowLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  > * + * {
    margin-left: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  ${props => {
    if (!props.padding?.includes('px')) {
      return css`
        padding: ${props.padding}px;
      `;
    } else if (props.padding?.includes('px')) {
      return css`
        padding: ${props.padding};
      `;
    }
    return ``;
  }}
`;

export const StyledBotDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px auto;
  width: 100%;
  height: 100%;
`;

export const UnpaddedList = styled.ul`
  padding: 0;
  width: 100%;

  li {
    padding: 0;
    list-style: none;
  }
`;

export const StyledWarningBox = styled.div`
  padding: 10px;
  border: 1px solid ${cssVariables.warning};
  width: 100%;
  margin: 5px 0;
`;

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${props => (props.height ? `height: ${props.height};` : `height: auto;`)}
`;

export const StyledFlexLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${props => (props.width ? `width: ${props.width};` : `width: 100%;`)}
  ${props => (props.height ? `height: ${props.height};` : `height: auto;`)}
`;

export const StyledSpaceBetweenFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${props =>
    props.height
      ? css`
          height: ${props.height};
        `
      : css`
          height: auto;
        `}
`;

export const StyledTextLinkEffect = styled.div`
  text-decoration: underline !important;
  cursor: pointer;
  margin: 0 2px !important;
  color: ${props => props.color || cssVariables.primaryBlue} !important;

  &:hover {
    font-weight: 700 !important;
    color: ${cssVariables.primaryBlue} !important;
  }
`;

export const StyledTag = styled(Tag)`
  word-break: break-word;
  white-space: break-spaces;
`;
