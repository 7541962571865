import React, { useContext, useState } from 'react';
import Button from 'components/Button';
import GlobalVarsAddModal from '../GlobalVarsAddModal/GlobalVarsAddModal';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { GET_ALL_GLOBAL_VARS } from 'store/action';
import {
  StyledTable,
  StyledWrapper,
  StyledButtonContainer,
} from './GlobalVarsTable.style';

const GlobalVarsTable = ({
  totalDatas,
  loading,
  pageSize,
  setInitLoading,
  datatable,
  setCurrentData,
}) => {
  const [, dispatch] = useContext(Context);
  const [currentSize, setCurrentSize] = useState(pageSize);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGlobalVar, setSelectedGlobalVar] = useState({
    name: '',
    value: {},
  });

  const handlePagination = (current, pageSize) => {
    setInitLoading(true);
    current * pageSize < totalDatas
      ? setCurrentSize(current * pageSize)
      : setCurrentSize(totalDatas);
    datatable.slice((current - 1) * pageSize, current * pageSize);
    setInitLoading(false);
  };

  const handleUpdateVersion = row => {
    setIsModalOpen(true);
    setSelectedGlobalVar(prevState => ({
      ...prevState,
      name: row.name,
      value: row.value,
    }));
  };

  const handleCloseModal = () => setIsModalOpen(false);
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       'selectedRows: ',
  //       selectedRows
  //     );
  //   },
  //   onSelect: (record, selected, selectedRows) => {
  //     console.log(record, selected, selectedRows);
  //   },
  //   onSelectAll: (selected, selectedRows, changeRows) => {
  //     console.log(selected, selectedRows, changeRows);
  //   },
  //   getCheckboxProps: record => ({
  //     name: record.name,
  //   }),
  // };

  const editGlobalVars = async (name, value, token) => {
    try {
      const res = await apiService.addGlobalVars(name, value, token);
      if (res.data.success) {
        const refetchGlobalVars = async () => {
          setInitLoading(true);
          const allGlobalVars = await apiService.getGlobalVars({}, token);
          const allGlobalVarsData = allGlobalVars.data.results;

          await dispatch({
            type: GET_ALL_GLOBAL_VARS,
            payload: allGlobalVarsData,
          });
          setCurrentData(allGlobalVarsData);
        };

        refetchGlobalVars();
        setInitLoading(false);
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <StyledWrapper>
      <StyledTable
        size="small"
        loading={loading}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            width: '20vw',
          },
          {
            title: 'Value',
            dataIndex: 'value',
            // responsive: ['md'],
          },
          {
            title: 'Actions',
            dataIndex: 'status',
            render: (value, row) => (
              <StyledButtonContainer>
                <Button
                  value="Edit"
                  variant="primary"
                  onClick={() => handleUpdateVersion(row)}
                />
              </StyledButtonContainer>
            ),
          },
        ]}
        dataSource={datatable}
        pagination={{
          onChange: (page, pageSize) => {
            handlePagination(page, pageSize);
          },
          pageSize: pageSize,
          total: totalDatas,
          position: ['topRight'],
        }}
        footer={() =>
          `Showing ${
            totalDatas >= currentSize ? currentSize : 1
          } of ${totalDatas} Global Vars.`
        }
      />

      <GlobalVarsAddModal
        visible={isModalOpen}
        onCloseModal={handleCloseModal}
        globalVarData={selectedGlobalVar}
        editGlobalVars={editGlobalVars}
      />
    </StyledWrapper>
  );
};

export default GlobalVarsTable;
