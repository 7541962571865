import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect } from 'antd';

import { StyledRoot, StyledLabel, StyledAntdSelect } from './Select.styles';
import { isAnObject } from 'utils/dataTypes';

const { Option } = AntdSelect;

const Select = props => {
  const {
    className,
    label,
    hidden,
    onChange,
    options,
    size,
    type,
    full,
    children,
    ghost,
    ...rest
  } = props;

  return (
    <StyledRoot $full={full} className={className || ''}>
      <StyledLabel hidden={hidden}>{label}</StyledLabel>
      <StyledAntdSelect
        size={size}
        onChange={onChange}
        hidden={hidden}
        {...rest}
        $ghost={ghost}
      >
        {children
          ? { ...children }
          : !!options && Array.isArray(options)
          ? options.map((option, key) =>
              isAnObject(option) && option.value ? (
                <Option key={option.key || key + 1} value={option.value}>
                  {option?.label}
                </Option>
              ) : (
                <Option key={key + 1} value={option}>
                  {option}
                </Option>
              )
            )
          : null}
      </StyledAntdSelect>
    </StyledRoot>
  );
};

Select.defaultProps = {
  size: 'large',
};

Select.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  ghost: PropTypes.bool,
  hidden: PropTypes.bool,
  full: PropTypes.bool,
  className: PropTypes.any,
  options: PropTypes.array,
  children: PropTypes.any,
  size: PropTypes.oneOf(['large', 'middle', 'medium', 'small']),
};

export default Select;
