import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Content, Footer } from './JoyrideTooltip.styles';

const JoyrideTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  isLastStep,
  primaryProps,
  tooltipProps,
  showSkipButton,
  skipProps,
  ...rest
}) => {
  const getButton = () => {
    if (isLastStep || (isLastStep && index === 1)) {
      return (
        <button type="button" {...closeProps}>
          CLOSE
        </button>
      );
    } else if (index === 3) {
      return (
        <button type="button" {...closeProps}>
          CLOSE
        </button>
      );
    } else {
      return (
        <>
          <button type="button" {...primaryProps}>
            NEXT
          </button>
          <button type="button" {...skipProps}>
            SKIP
          </button>
        </>
      );
    }
  };

  return (
    <Wrapper {...tooltipProps}>
      <Content>
        {step.title && <div>{step.title}</div>}
        <div>{step.content}</div>
        <Footer>
          <div>
            {index > 0 && (
              <button type="button" {...backProps}>
                BACK
              </button>
            )}
            {continuous && getButton()}
          </div>
        </Footer>
      </Content>
    </Wrapper>
  );
};

JoyrideTooltip.propTypes = {
  continuous: PropTypes.bool,
  isLastStep: PropTypes.bool,
  index: PropTypes.number,
  step: PropTypes.object,
  backProps: PropTypes.any,
  closeProps: PropTypes.any,
  primaryProps: PropTypes.any,
  showSkipButton: PropTypes.bool,
  tooltipProps: PropTypes.any,
  skipProps: PropTypes.bool,
};

export default JoyrideTooltip;
