import React, { useEffect, useMemo } from 'react';
import { ApiFilled, SearchOutlined } from '@ant-design/icons';
import FlipMove from 'react-flip-move';
import { Skeleton, Tag } from 'antd';

import Input from 'components/Input';
import AnswerWithScore from 'components/AnswerWithScore';
import {
  StyledQuestionModalTitle,
  StyledResponsePicker,
  StyledModalSection,
  StyledLinkHint,
  StyledCurrentQuestion,
} from './ResponsePickerModal.styles';
import ToggleShowDisplayAnswer from 'components/Button/ToggleShowDisplayAnswer';
import {
  StyledFlexRowCenter,
  StyledSpaceBetweenFlexCenter,
  StyledWarningBox,
  UnpaddedList,
} from 'styles/GenericStyledComponents';
import useResponsePickerModal from './hooks';
import TextArea from 'components/TextArea';
import ToolTip from 'components/ToolTips/BaseToolTip';
import {
  StyledAnswers,
  StyledAnswersItem,
} from 'pages/BotDetails/AnswerBank/SimilarAnswerModal/SimilarAnswerModal.style';

const ResponsePickerModal = () => {
  const {
    alternateAnswser,
    answers,
    chatQuestion,
    currentAnswer,
    hardLinkQuestionToAnswer,
    editableQuestion,
    filteredAnswers,
    handleChangeQuestion,
    handleCloseResponsePickerModal,
    handleUnlinkQuestion,
    handleSelectQuestion,
    isOpen,
    isCurrentAnswerHasLink,
    itemToChange,
    loading,
    modalStatus,
    newQuestion,
    onChangeSearchKey,
    question,
    responsePickerModal,
    showDisplayAnswer,
    searchKey,
    similarQuestions,
    setShowDisplayAnswer,
    setFilteredAnswers,
    showQuestionsSkeleton,
    title,
  } = useResponsePickerModal();

  const answerWithScoreOptions = useMemo(() => {
    if (filteredAnswers?.length && searchKey) {
      return filteredAnswers;
    } else if (!searchKey) {
      return answers;
    }
    return [];
  }, [answers, filteredAnswers]);

  useEffect(() => {
    if (alternateAnswser && searchKey) {
      const filteredAnswers = answers.filter(answer =>
        answer.text.toLowerCase().includes(searchKey.toLowerCase())
      );
      setFilteredAnswers(filteredAnswers);
    }
  }, [searchKey]);

  const skipSimilarityCheck = useMemo(() => {
    // add new question:
    // always check for similarity
    // when question in edited
    // call similarity check
    if (newQuestion !== question?.text) {
      return false;
    }
    // if similar questions is found
    // and question inside textarea is not change
    // skip similarity check
    if (similarQuestions?.length && newQuestion === question?.text) {
      return true;
    }
    // if similar questions is found
    // and question inside textarea has changed
    // call similarity check
    else if (similarQuestions?.length && newQuestion !== question?.text) {
      return false;
    }
    // if no similar questions found
    // skip similarity check
    else if (!similarQuestions?.length) {
      return true;
    }
    // always call similarity check for all
    // other scenarios
    return false;
  }, [similarQuestions?.length, newQuestion, question?.text]);

  if (!isOpen) {
    return false;
  }

  return (
    <StyledResponsePicker
      visible={isOpen}
      title={
        <StyledQuestionModalTitle>
          {title || 'Link Response'}
        </StyledQuestionModalTitle>
      }
      spinning={loading}
      onCancel={handleCloseResponsePickerModal}
      cancelButtonProps={{ disabled: showQuestionsSkeleton || loading }}
      onOk={() => hardLinkQuestionToAnswer(skipSimilarityCheck)}
      cancelText={'Cancel'}
      okText={
        skipSimilarityCheck && similarQuestions?.length
          ? 'Ignore Similar Questions & Save'
          : 'Save'
      }
      confirmLoading={showQuestionsSkeleton || loading}
      isFormModal
      modalStatus={modalStatus}
      closable={false}
      maskClosable={false}
    >
      <StyledModalSection>
        <strong>{'Users Question'}</strong>
        {editableQuestion ? (
          <TextArea
            onChange={handleChangeQuestion}
            value={newQuestion || question?.text}
          />
        ) : (
          <StyledCurrentQuestion>{chatQuestion}</StyledCurrentQuestion>
        )}
      </StyledModalSection>
      {
        <StyledModalSection>
          {showQuestionsSkeleton ? <Skeleton active /> : null}
          {!showQuestionsSkeleton && similarQuestions?.length ? (
            <>
              <h4>{'Similar Questions Found'}</h4>
              <StyledWarningBox>
                {similarQuestions.map(item => (
                  <StyledFlexRowCenter>
                    <ToolTip title={'Similarity Score'}>
                      <Tag>{item.score?.toFixed(3)}</Tag>
                    </ToolTip>
                    <StyledAnswersItem
                      key={item.question.jid}
                      style={{
                        width: '100%',
                      }}
                    >
                      {
                        <StyledAnswers>
                          {item.question.context.text}
                        </StyledAnswers>
                      }
                    </StyledAnswersItem>
                  </StyledFlexRowCenter>
                ))}
              </StyledWarningBox>
            </>
          ) : null}
        </StyledModalSection>
      }
      <StyledModalSection>
        <StyledSpaceBetweenFlexCenter>
          <h5>{'Link Alternate Response'}</h5>
          <ToggleShowDisplayAnswer
            showDisplayAnswer={showDisplayAnswer}
            onClick={() => setShowDisplayAnswer(!showDisplayAnswer)}
          />
        </StyledSpaceBetweenFlexCenter>
        {isCurrentAnswerHasLink ? (
          <>
            <StyledLinkHint>
              <ApiFilled /> &nbsp;
              {`Question is hard linked to the current answer.`}
            </StyledLinkHint>
          </>
        ) : null}
        <Input
          placeholder="Search"
          prefix={<SearchOutlined />}
          value={searchKey}
          onChange={onChangeSearchKey}
        />
        {answerWithScoreOptions?.length ? (
          <UnpaddedList>
            <FlipMove>
              {answerWithScoreOptions.map((answer, index) => (
                // react-flip-move requires list element
                <li key={answer.jid}>
                  <AnswerWithScore
                    answer={answer}
                    isCurrentAnswer={!index}
                    isCurrentAnswerHasLink={isCurrentAnswerHasLink}
                    qlinks={answer?.qlinks}
                    handleSelect={answerId =>
                      handleSelectQuestion(answerId, 'selected')
                    }
                    handleUnlinkQuestion={handleUnlinkQuestion}
                    key={answer.jid}
                    showDisplayAnswer={showDisplayAnswer}
                    toolTipText="Link Answer"
                  />
                </li>
              ))}
            </FlipMove>
          </UnpaddedList>
        ) : (
          <h1>{'No answer found.'}</h1>
        )}
      </StyledModalSection>
    </StyledResponsePicker>
  );
};

export default ResponsePickerModal;
