import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { cssVariables } from 'styles/root';

export const StyledDashboardItem = styled.div`
  width: 100%;
  height: 193px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: ${cssVariables.cardShadow};
  border-radius: 5px;
  align-items: inherit;
  border-bottom: none;
  padding: 0px;

  :hover {
    border-bottom: none;
    box-shadow: ${cssVariables.itemListShadow};
    cursor: auto;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem 0 1rem;
  width: auto;
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

export const StyledName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  color: ${cssVariables.primaryColor};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const StyledDescription = styled.span`
  font-size: 13px;
  line-height: 23px;
  color: #afafaf;
`;

export const StyledLinkButton = styled(Link)`
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 46px;
  border: none;
  background-color: #f3f3f3;
  color: black;
  padding: 0.5rem 0 0 0;
  font-size: 1rem;

  span {
    font-weight: 400;
  }

  .ant-btn-loading,
  :hover,
  :focus,
  :active {
    background-color: rgba(33, 35, 38, 0.774);
    color: white;
  }
`;
