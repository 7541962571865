import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, Switch } from 'antd';
import { Context } from 'store/store';
import Input from 'components/Input';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import {
  StyledErrorBottomText,
  StyledFeatureContainer,
  StyledModal,
  StyledSwitchWrapper,
} from './VersionModal.style';
import Button from 'components/Button';

const VersionModal = ({
  showVersionsModal,
  title,
  setShowVersionsModal,
  selectedVersion,
  addVersion,
  updateVersion,
  datatable,
}) => {
  const [state, dispatch] = useContext(Context);
  const [versionDetails, setVersionDetails] = useState({});
  const [checkedFeatures, setCheckedFeatures] = useState([]);
  const [showFeaturesInputField, setShowFeaturesInputField] = useState(false);
  const [addedFeature, setAddedFeature] = useState(null);
  const [featureExist, setFeatureExist] = useState(false);
  const [versionExist, setVersionExist] = useState(true);
  const [updateAction, setUpdateAction] = useState(false);
  const [modalTitle, setModalTitle] = useState(title);
  const {
    admin: { all_versions },
  } = state;
  const NEW_BTN_LABEL = 'New Feature';
  const ADD_BTN_LABEL = 'Add';
  const handleChangeField = (name, value) => {
    const newFeaturesList = [];
    switch (name) {
      case 'addedFeature':
        setVersionDetails({
          ...versionDetails,
          features: [...versionDetails.features, { label: value, value }],
        });
        setCheckedFeatures([...checkedFeatures, value]);
        break;

      case 'features':
        value.map(checked =>
          newFeaturesList.push({ label: checked, value: checked })
        );
        setVersionDetails({
          ...versionDetails,
          features: newFeaturesList,
        });
        break;

      case 'version':
        setVersionDetails({
          ...versionDetails,
          [name]: value,
        });
        if (datatable.find(data => data.version === value)) {
          setVersionExist(true);
        } else {
          setVersionExist(false);
        }
        break;
      default:
        setVersionDetails({
          ...versionDetails,
          [name]: value,
        });
        break;
    }

    setShowFeaturesInputField(false);
    setAddedFeature(null);
    setFeatureExist(false);
  };

  const handleFeatureCheckboxChange = e => {
    if (!e.target.checked) {
      setCheckedFeatures(
        checkedFeatures.filter(value => value !== e.target.value)
      );
    } else {
      setCheckedFeatures(features => {
        handleChangeField('features', [...features, e.target.value]);
        return [...features, e.target.value];
      });
    }
  };

  const handleFeatureInputChange = e => {
    if (checkedFeatures.includes(e.target.value)) {
      setFeatureExist(true);
    } else {
      setFeatureExist(false);
    }
    setAddedFeature(e.target.value);
  };

  const handleSave = async () => {
    const finalFeatures = checkedFeatures.reduce(
      (value, field) => ({ ...value, [field]: true }),
      {}
    );
    await addVersion({
      ver: versionDetails.version,
      features: finalFeatures,
      set_current: versionDetails.current,
    });
    setShowVersionsModal(false);
  };

  const handleUpdate = async () => {
    let versions = {};
    let updatedFeatures = {};
    datatable.map(res => {
      versions = {
        ...versions,
        [res.version]: res.features,
      };
    });
    versionDetails?.features.map(res => {
      updatedFeatures = {
        ...updatedFeatures,
        [res.value]: checkedFeatures.includes(res.value),
      };
    });
    versions = {
      ...versions,
      [versionDetails.version]: updatedFeatures,
    };
    const params = {
      current:
        versionDetails.current === true
          ? versionDetails.version
          : all_versions.find(versionList => versionList.status === 'current')
              .version,
      versions,
    };
    await updateVersion(JSON.stringify(params));
    setShowVersionsModal(false);
  };

  useEffect(() => {
    let featuresOptions = [];
    const features = selectedVersion?.features
      ? Object.entries(selectedVersion?.features).map(version => {
          if (version[1]) {
            featuresOptions.push(version[0]);
          }
          return {
            value: version[0],
            label: version[0],
          };
        })
      : null;
    setCheckedFeatures(featuresOptions);
    setVersionDetails({
      ...versionDetails,
      version: selectedVersion?.version,
      features,
      current: title !== 'Update Version',
    });
  }, [showVersionsModal, selectedVersion, updateAction]);

  useEffect(() => {
    setModalTitle(title);
    if (title === 'Update Version') {
      setUpdateAction(true);
    } else {
      setUpdateAction(false);
    }
  }, [title, showVersionsModal]);

  const handleCloneVersion = () => {
    setUpdateAction(false);
    setModalTitle('Add Version');
  };

  return (
    <StyledModal
      visible={showVersionsModal}
      title={modalTitle}
      onCancel={() => setShowVersionsModal(false)}
      footer={[
        <Button
          variant="secondary"
          onClick={() => setShowVersionsModal(false)}
          value={'Cancel'}
          key="cancel"
        />,
        updateAction && (
          <Button
            variant="primary"
            onClick={handleCloneVersion}
            disabled={!updateAction && versionExist}
            value={'Clone Version'}
            key="clone"
          />
        ),
        <Button
          variant="primary"
          onClick={() => (updateAction ? handleUpdate() : handleSave())}
          disabled={!updateAction && versionExist}
          value={'Submit'}
          key="submit"
        />,
      ]}
    >
      <Input
        required
        value={versionDetails.version}
        label="Version"
        placeholder="Input Version"
        onChange={e => handleChangeField('version', e.target.value)}
        disabled={updateAction}
      />
      <StyledFeatureContainer>
        <span>Features</span>
        {versionDetails?.features?.map((features, key) => (
          <Checkbox
            key={key}
            onChange={handleFeatureCheckboxChange}
            value={features.value}
            checked={checkedFeatures.includes(features.value)}
          >
            {features.label}
          </Checkbox>
        ))}
      </StyledFeatureContainer>
      {showFeaturesInputField && (
        <>
          <Input
            required
            label="Feature Name"
            placeholder="Input feature"
            onChange={e => handleFeatureInputChange(e)}
            addonAfter={
              <Button
                value={ADD_BTN_LABEL}
                variant="link"
                disabled={!addedFeature || featureExist}
                onClick={() => handleChangeField('addedFeature', addedFeature)}
              />
            }
          />
          {featureExist && (
            <StyledErrorBottomText>Feature exist.</StyledErrorBottomText>
          )}
        </>
      )}
      {!showFeaturesInputField && !updateAction && (
        <Button
          value={NEW_BTN_LABEL}
          variant="link"
          onClick={() => setShowFeaturesInputField(true)}
          startIcon={<PlusOutlined />}
        />
      )}
      <StyledSwitchWrapper>
        <div>
          <b>{'Current Version'}</b>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={versionDetails.current}
            onChange={value => handleChangeField('current', value)}
            key={versionDetails.version}
          />
        </div>
      </StyledSwitchWrapper>
    </StyledModal>
  );
};

VersionModal.propTypes = {
  showVersionsModal: PropTypes.bool.isRequired,
  setShoVersionsModal: PropTypes.func.isRequired,
  selectedVersion: PropTypes.object,
  addVersion: PropTypes.func,
  updateVersion: PropTypes.func,
};

export default VersionModal;
