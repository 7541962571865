import { initialState } from 'store/initialState';
import {
  SIGN_IN_SUCCESS,
  GET_USERNAME,
  UPDATE_USER,
  LOGOUT_USER,
  SET_PUBLIC_KEY,
  SPAWN_CREATE,
  EXTERNAL_SIGN_IN_SUCCESS,
  SET_PAGE_EXTERNAL,
  LOGOUT_EXTERNAL_USER,
  INITIALIZE_API_KEY,
  UNSET_API_KEY,
} from '../action';

export const userReducer = (state, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isExternalPage: false,
        isAuthenticated: true,
        token: action.payload,
      };

    case EXTERNAL_SIGN_IN_SUCCESS:
      return {
        ...state,
        isExternalPage: true,
        isAuthenticated: true,
        token: action.payload,
      };

    case SET_PAGE_EXTERNAL:
      return {
        ...state,
        isExternalPage: true,
      };

    case GET_USERNAME:
    case UPDATE_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          firstName: action.payload.name
            ? action.payload.name.split(' ')[0] || action.payload.name
            : 'Guest',
        },
      };
    }

    case SET_PUBLIC_KEY:
      return {
        ...state,
        publicKey: action.payload,
      };

    case SPAWN_CREATE:
      return {
        ...state,
        ...action.payload,
      };

    case LOGOUT_USER: {
      localStorage.clear();
      localStorage.removeItem('token');
      return {
        ...initialState,
        hasLogout: true,
      };
    }

    case LOGOUT_EXTERNAL_USER: {
      localStorage.clear();
      localStorage.removeItem('token');
      localStorage.setItem('external', 'true');
      return {
        ...initialState,
        isExternalPage: true,
        hasLogout: true,
      };
    }

    case INITIALIZE_API_KEY:
      return {
        ...state,
        apiGatewayConfig: action.payload.apiConfig,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              apiKeyModal: {
                isIntegrationComponent: action.payload.isIntegrationComponent,
                isOpen: true,
              },
            },
          },
        },
      };

    case UNSET_API_KEY:
      return {
        ...state,
        apiGatewayConfig: null,
      };

    default:
      return state;
  }
};
