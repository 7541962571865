import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import { StyledRCE } from './RichTextEditor.style';
import useRichTextEditor from './hooks';

const RichTextEditor = ({
  answer,
  answerId,
  setRichTextAnswer,
  isADefaultAnswer,
}) => {
  const { imageHandler, handleChange, loading, value, zsbLineBreak } =
    useRichTextEditor({
      answer,
      answerId,
      setRichTextAnswer,
      isADefaultAnswer,
    });
  const quillRef = useRef();

  const quillModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['link', 'image'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ align: [] }],
          ['clean'],
          ['emoji'],
          ['chatBreak'],
        ],
        handlers: {
          image: () => imageHandler(quillRef),
          chatBreak: () => zsbLineBreak(quillRef),
        },
      },
      'emoji-toolbar': true,
      'emoji-shortname': true,
    }),
    []
  );

  return (
    <Spin spinning={loading} tip="Uploading...">
      <StyledRCE
        ref={ref => (quillRef.current = ref)}
        onChange={handleChange}
        theme="snow"
        value={value || answer}
        modules={quillModules}
      />
    </Spin>
  );
};

RichTextEditor.propTypes = {
  answer: PropTypes.string,
  answerId: PropTypes.string,
  setRichTextAnswer: PropTypes.func,
  isADefaultAnswer: PropTypes.bool,
};

export default RichTextEditor;
