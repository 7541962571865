import ReactQuill from 'react-quill';
import styled from 'styled-components';

export const StyledRCE = styled(ReactQuill)`
  margin-bottom: 10px;
  .ql-editor {
    min-height: 150px;
    max-height: 35vh;
    overflow: auto;
  }

  .ql-chatBreak {
    position: relative;
    cursor: pointer;
  }

  .ql-chatBreak::before {
    content: '↩';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    width: 40px;
  }

  /** All toolbar buttons are inside of .ql-formats */
  .ql-formats button,
  .ql-formats .ql-picker {
    position: relative;
  }

  /** Set a tooltip with css pseudo-elements, when buttons are hover, active or focus  */
  .ql-formats button:hover:after,
  .ql-formats button:active:after,
  .ql-formats button:focus:after {
    position: absolute;
    background: black;
    color: white;
    padding: 0.5em;
    border-radius: 0.5em;
    left: -50%;
    top: 100%;
    width: max-content;
    z-index: 1;
  }

  .ql-formats .ql-picker:hover:after,
  .ql-formats .ql-picker:active:after,
  .ql-formats .ql-picker:focus:after {
    position: absolute;
    background: black;
    color: white;
    padding: 0.5em;
    border-radius: 0.5em;
    left: -5%;
    top: 100%;
    width: max-content;
    z-index: 1;
  }

  .ql-header:hover:after,
  .ql-header:active:after,
  .ql-header:focus:after {
    content: 'Style';
  }

  .ql-font:hover:after,
  .ql-font:active:after,
  .ql-font:focus:after {
    content: 'Font';
  }

  .ql-bold:hover:after,
  .ql-bold:active:after,
  .ql-bold:focus:after {
    content: 'Bold';
  }

  .ql-italic:hover:after,
  .ql-italic:active:after,
  .ql-italic:focus:after {
    content: 'Italic';
  }

  .ql-underline:hover:after,
  .ql-underline:active:after,
  .ql-underline:focus:after {
    content: 'Underline';
  }

  .ql-strike:hover:after,
  .ql-strike:active:after,
  .ql-strike:focus:after {
    content: 'Strike';
  }

  .ql-link:hover:after,
  .ql-link:active:after,
  .ql-link:focus:after {
    content: 'Link';
  }

  .ql-image:hover:after,
  .ql-image:active:after,
  .ql-image:focus:after {
    content: 'Attach Image';
  }

  .ql-blockquote:hover:after,
  .ql-blockquote:active:after,
  .ql-blockquote:focus:after {
    content: 'Block Quote';
  }

  .ql-code-block:hover:after,
  .ql-code-block:active:after,
  .ql-code-block:focus:after {
    content: 'Code Block';
  }

  .ql-list:hover:after,
  .ql-list:active:after,
  .ql-list:focus:after {
    content: 'List';
  }

  .ql-color:hover:after,
  .ql-color:active:after,
  .ql-color:focus:after {
    content: 'Font Color';
  }

  .ql-color-label:hover:after,
  .ql-color-label:active:after,
  .ql-color-label:focus:after {
    content: 'Background Color';
  }

  .ql-align:hover:after,
  .ql-align:active:after,
  .ql-align:focus:after {
    content: 'Align';
  }

  .ql-clean:hover:after,
  .ql-clean:active:after,
  .ql-clean:focus:after {
    content: 'Clean';
  }

  .ql-chatBreak:hover:after,
  .ql-chatBreak:active:after,
  .ql-chatBreak:focus:after {
    content: 'New Chat Bubble';
  }
`;
