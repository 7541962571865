import { answersSelector } from 'selectors/bot/answers';

import useSelector from 'store/useSelector';

const useAnswer = () => {
  const allAnswers = useSelector(answersSelector);

  return {
    allAnswers,
  };
};

export default useAnswer;
