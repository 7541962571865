import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'components/RichTextEditor';
import CodeMirror from './CodeMirror';
import { DEFAULT_EDITOR } from 'constants/answerbank/defaults';
import {
  StyledRCEEditor,
  StyledRCETip,
  StyledHTMLEditorWrapper,
} from './Editor.styles';
import ChatBubble from 'components/ChatBubble';

const Editor = props => {
  const {
    answerId,
    editor,
    editedHTML,
    editedRichTextAnswer,
    updateFormattedAnswer,
    isFullScreen,
    isADefaultAnswer,
    ...rest
  } = props;
  const RichText = useMemo(() => {
    return (
      <StyledRCEEditor {...rest}>
        <StyledRCETip>
          ({`Please prefix your links with`} <strong>{`https://`}</strong> or{' '}
          <strong>{`http://`}</strong>{' '}
          {`if you want the user to be redirected outside
          your page.`}
          )
        </StyledRCETip>
        <RichTextEditor
          answer={editedRichTextAnswer || ''}
          setRichTextAnswer={updateFormattedAnswer}
          answerId={answerId}
          isADefaultAnswer={isADefaultAnswer}
        />
      </StyledRCEEditor>
    );
  }, [editor, editedRichTextAnswer, answerId, isADefaultAnswer]);

  const HTMLEditor = useMemo(() => {
    return (
      <StyledHTMLEditorWrapper isFullScreen={isFullScreen} {...rest}>
        <CodeMirror
          editedHTML={editedHTML || ''}
          updateAnswer={updateFormattedAnswer}
        />
      </StyledHTMLEditorWrapper>
    );
  }, [editor, editedHTML, answerId]);

  const Preview = useMemo(() => {
    return (
      <ChatBubble
        answer={editedHTML}
        isOtherPlatformPreview={editor === 'otherPlatFormPreview'}
      />
    );
  }, [editor, editedHTML, answerId]);

  return editor === DEFAULT_EDITOR
    ? RichText
    : editor === 'preview'
    ? Preview
    : HTMLEditor;
};

Editor.defaultProps = {
  editor: DEFAULT_EDITOR,
};

Editor.propTypes = {
  answerId: PropTypes.string,
  editor: PropTypes.string.isRequired,
  editedHTML: PropTypes.string,
  editedRichTextAnswer: PropTypes.string,
  isFullScreen: PropTypes.bool,
  isADefaultAnswer: PropTypes.bool,
  answerWithOutLineBreaker: PropTypes.array,
  editedPreviewFormat: PropTypes.array,
};

export default Editor;
