import axios from 'axios';
import { DEFAULT_ANSWER_VERSION } from 'constants/answerbank/defaults';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import { createDisplayAnswerAPIPayload } from 'utils/answers';
import { convertToNewQuickReplyPayload } from 'utils/answers';
import { isAnObject, isZSBUUID } from 'utils/dataTypes';
import { convertToEndOfDay, getLastWeekISODate } from 'utils/dates';
import { snakeCase } from 'lodash';
import { renderPreconfigData } from 'store/reducers/helpers/bot/answers';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const STRIPE_URL = process.env.REACT_APP_STRIPE_URL;
const WEBHOOK_MESSENGER = process.env.REACT_APP_WEBHOOK_MESSENGER;
const WEBHOOK_VIBER = process.env.REACT_APP_WEBHOOK_URL_VIBER;
const CANCEL_REQUEST = 'Operation was cancelled by the user.';

export const getErrorMessage = error => {
  if (error?.response?.data && isAnObject(error?.response?.data)) {
    const isGeneric =
      Object.values(error.response.data)[0]?.includes(
        'This field is required.'
      ) || false;
    const message = Object.values(error.response.data)[0];
    const key = Object.keys(error.response.data)[0];
    return isGeneric ? `${key.toUpperCase()} is required` : message;
  } else if (
    (error?.response?.status >= 500 && error?.response?.status <= 599) ||
    (error?.response?.status >= 400 && error?.response?.status < 500)
  ) {
    return typeof error.response === 'string' && error.response?.length <= 100
      ? error.response
      : typeof error.response?.data === 'string' &&
        error.response?.data?.length <= 100
      ? error.response?.data
      : DEFAULT_ERROR_MESSAGE;
  }
  return DEFAULT_ERROR_MESSAGE;
};

export const apiService = {
  jacLoadApplication: function (tokenArg) {
    axios.defaults.headers.common['Authorization'] = 'token ' + tokenArg;
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: '/js/alias_list',
        method: 'post',
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  setDefaultSentinel: function (token) {
    axios.defaults.headers.common['Authorization'] = 'token ' + token;
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: '/js/sentinel_active_global',
        method: 'post',
      })
      .then(res => {
        if (
          !res?.data ||
          !res?.data?.success ||
          res?.data?.response?.includes('No global sentinel is available')
        ) {
          throw new Error('No global sentinel is available');
        }
        return res;
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  createGraph: function (token) {
    axios.defaults.headers.common['Authorization'] = 'token ' + token;
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: '/js/graph_create',
        method: 'post',
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  userGET: function (url, token) {
    if (!url.includes(`user/activate/`)) {
      axios.defaults.headers.common['Authorization'] = 'token ' + token;
    }
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: `/${url}`,
        method: 'get',
        data: url.includes(`user/activate/`) ? { code: token } : { token },
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  userPOST: function (url, data, token) {
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'token ' + token;
    } else if (url.includes('token')) {
      delete axios.defaults.headers.common['Authorization'];
    }
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: `/${url}`,
        method: 'post',
        data,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  jacSyncSentinelGlobal: function (data, tokenArg) {
    axios.defaults.headers.common['Authorization'] = 'token ' + tokenArg;
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: '/js/walker_run',
        method: 'post',
        data: { ...data },
      })
      .then(response => {
        if (!response.data.success) {
          throw new Error(DEFAULT_ERROR_MESSAGE);
        }
        return response;
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  // Pass token when calling `jacPrimeRun`
  jacPrimeRun: function (data, sentinel, tokenArg) {
    const cancelTokenSource = axios.CancelToken.source();
    axios.defaults.headers.common['Authorization'] = 'token ' + tokenArg;
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: `/js/walker_run?name=${data.name}`,
        cancelToken: cancelTokenSource.token,
        method: 'post',
        data: {
          ...data,
          snt: sentinel || 'active:sentinel',
          nd: data.nd || 'active:graph',
        },
      })
      .then(response => {
        if (!response.data.success && !response.data.is_queued) {
          throw new Error(DEFAULT_ERROR_MESSAGE);
        }
        return response;
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          throw new Error(CANCEL_REQUEST);
        }
        throw new Error(getErrorMessage(error));
      });
  },

  jacPrimeRunMultipart: function (data, sentinel, graph, tokenArg) {
    axios.defaults.headers.common['Authorization'] = 'token ' + tokenArg;
    let formData = new FormData();
    formData.append('snt', sentinel);
    formData.append('nd', graph);
    formData.append('name', data.name);

    Object.entries(data.ctx).forEach((item, key) => {
      // Find the files from the `data.ctx`
      // file object will have an integer key name
      if (item[0] && !isNaN(Number.parseInt(item[0]))) {
        // And append individually
        formData.append('files', item[1], item[1].name);
      }
    });

    // removing the object that has a key name equivalent to a number/integer
    // to exclude the files from the ctx before we append to formData
    const dataCtxArr = Object.entries(data.ctx).filter(
      ([key, value]) => !!key && isNaN(Number.parseInt(key))
    );

    // append the filtered ctx without the files
    formData.append('ctx', JSON.stringify(Object.fromEntries(dataCtxArr)));

    return axios
      .request({
        baseURL: API_BASE_URL,
        url: '/js/walker_run',
        method: 'post',
        data: formData,
      })
      .then(response => {
        // if (!response.data.success) {
        //   throw new Error('Something went wrong while doing that');
        // }
        return response;
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  stripePOST: function (url, data, tokenArg) {
    return axios
      .request({
        baseURL: STRIPE_URL,
        url: `/${url}`,
        method: 'post',
        data: { ...data },
      })
      .catch(error => {
        console.log('error ', error);
        return error.response;
      });
  },

  stripeGET: function (url, tokenArg) {
    return axios
      .request({
        baseURL: STRIPE_URL,
        url: `/${url}`,
        method: 'get',
      })
      .catch(error => {
        console.log('error ', error);
        return error.response;
      });
  },

  spawnCreate: function (name, token) {
    axios.defaults.headers.common['Authorization'] = 'token ' + token;
    return axios.request({
      baseURL: API_BASE_URL,
      url: '/js/walker_spawn_create',
      method: 'post',
      data: {
        name,
      },
    });
  },

  getPublicKey: function (token) {
    axios.defaults.headers.common['Authorization'] = 'token ' + token;
    return axios.request({
      baseURL: API_BASE_URL,
      url: '/js/walker_get',
      method: 'post',
      data: {
        mode: 'keys',
        wlk: 'spawned:walker:zsb_public_api',
        detailed: false,
      },
    });
  },

  getGraphObject: function (jid, token, depth = 0) {
    axios.defaults.headers.common['Authorization'] = 'token ' + token;
    return axios.request({
      baseURL: API_BASE_URL,
      url: '/js/object_get',
      method: 'post',
      data: {
        obj: jid,
        depth,
        detailed: true,
      },
    });
  },

  getGlobalVars: function (jid, token) {
    axios.defaults.headers.common['Authorization'] = 'token ' + token;
    return axios.request({
      baseURL: API_BASE_URL,
      url: '/global',
      method: 'get',
    });
  },

  getVersions: function (sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'get_global_features',
        snt: 'active:sentinel',
        nd: 'active:graph',
      },
      sentinel,
      token
    );
  },

  addVersions: function (sentinel, token, ctx) {
    return this.jacPrimeRun(
      {
        name: 'add_version',
        ctx,
        snt: 'active:sentinel',
        nd: 'active:graph',
      },
      sentinel,
      token
    );
  },

  updateUserVersion: function (sentinel, token, ctx, graph) {
    return this.jacPrimeRun(
      {
        name: 'set_current_version',
        ctx,
        nd: graph,
      },
      sentinel,
      token
    );
  },

  updateGlobalVars: function (ctx = {}) {
    return this.jacPrimeRun({
      name: 'global_var_setter',
      ctx,
    });
  },

  addGlobalVars: function (name, value, token) {
    axios.defaults.headers.common['Authorization'] = 'token ' + token;
    return axios.request({
      baseURL: API_BASE_URL,
      url: '/js_admin/global_set',
      method: 'post',
      data: {
        name,
        value,
      },
    });
  },

  init: function (sentinel, graph, token) {
    return this.jacPrimeRun(
      {
        name: 'init',
        nd: graph,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  getBotDetails: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_bot',
        nd: jid,
        ctx: {
          sync_count: true,
          start_date: getLastWeekISODate(),
        },
      },
      sentinel,
      token
    );
  },

  getBots: function (sentinel, graph, token) {
    return this.jacPrimeRun(
      {
        name: 'get_bots',
        nd: graph,
        ctx: {
          start_date: getLastWeekISODate(),
        },
      },
      sentinel,
      token
    );
  },

  addBot: function (sentinel, graph, newBot, token) {
    return this.jacPrimeRun(
      {
        name: 'add_bot',
        nd: graph,
        ctx: { ...newBot },
      },
      sentinel,
      token
    );
  },

  getBotSummary: function (sentinel, graph, token) {
    return this.jacPrimeRun(
      {
        name: 'get_bot_summary',
        nd: graph,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  getAnswers: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_answers',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  getAnswer: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_answer ',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  createAnswer: function (
    sentinel,
    jid,
    source,
    answerDetails,
    token,
    skip_similarity_check,
    version = DEFAULT_ANSWER_VERSION,
    editor,
    categoryId
  ) {
    const { text, displayAnswer } = answerDetails;
    const payloadDisplayAnswer = createDisplayAnswerAPIPayload(displayAnswer);
    return this.jacPrimeRun(
      {
        name: 'create_answer',
        nd: jid,
        ctx: {
          text,
          ...payloadDisplayAnswer,
          editor,
          category_id: categoryId,
          ans_version: version,
          skip_similarity_check,
          ...source,
        },
      },
      sentinel,
      token
    );
  },

  createManyAnswers: function (sentinel, jid, source, input, token) {
    return this.jacPrimeRun(
      {
        name: 'create_many_answers',
        nd: jid,
        ctx: { entry_list: input, ...source },
      },
      sentinel,
      token
    );
  },

  importAnswerBank: function (sentinel, jid, source, answerbank, token) {
    return this.jacPrimeRun(
      {
        name: 'import_answerbank',
        nd: jid,
        ctx: { answerbank, ans_version: 'final', ...source },
      },
      sentinel,
      token
    );
  },

  importDraftAnswerBank: function (sentinel, jid, source, answerbank, token) {
    return this.jacPrimeRun(
      {
        name: 'import_answerbank',
        nd: jid,
        ctx: { answerbank, ans_version: 'draft', ...source },
      },
      sentinel,
      token
    );
  },

  getSummary: function (sentinel, jid, source, type, maxCount, token) {
    return this.jacPrimeRun(
      {
        name: 'text_summarization',
        nd: jid,
        ctx: { doc_type: type, [type]: source, sentence_count: maxCount },
      },
      sentinel,
      token
    );
  },

  getOpenAiTemplate: function (sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'get_openai_template',
      },
      sentinel,
      token
    );
  },

  getOpenAiFAQTemplate: function (sentinel, token, ctx) {
    return this.jacPrimeRun(
      {
        name: 'get_openai_template',
        ctx,
      },
      sentinel,
      token
    );
  },

  generateFAQViaOpenAi: function (sentinel, token, generateFAQObject) {
    return this.jacPrimeRun(
      {
        name: 'generate_answers_via_openai',
        ctx: {
          src: 'openai_faq_template',
          fields: {
            ...generateFAQObject,
          },
        },
        snt: 'active:sentinel',
      },
      sentinel,
      token
    );
  },

  generateAanswersViaOpenAI: function (
    sentinel,
    jid,
    token,
    textGenerateObject
  ) {
    return this.jacPrimeRun(
      {
        name: 'generate_answers_via_openai',
        nd: jid,
        ctx: {
          pre_text: '',
          fields: {
            ...textGenerateObject,
            keywords: textGenerateObject.keywords
              ? textGenerateObject.keywords.join()
              : '',
            count: textGenerateObject.count || 5,
          },
          post_text: '',
        },
      },
      sentinel,
      token
    );
  },

  changeAnswer: function (
    sentinel,
    jid,
    input,
    displayAnswer,
    categoryId,
    token,
    editor,
    quickButtons,
    version
  ) {
    const payloadDisplayAnswer = createDisplayAnswerAPIPayload(displayAnswer);
    const quickReply =
      quickButtons && quickButtons?.quickReply ? 'true' : 'false';
    const requestAgent =
      quickButtons && quickButtons?.requestAgent ? 'true' : 'false';

    return this.jacPrimeRun(
      {
        name: 'change_answer',
        nd: jid,
        ctx: {
          ...payloadDisplayAnswer,
          text: input,
          editor,
          category_id: categoryId,
          quick_reply: quickButtons
            ? convertToNewQuickReplyPayload(quickButtons)
            : null,
          callback: requestAgent,
          ans_version: version,
        },
      },
      sentinel,
      token
    );
  },

  deleteAnswer: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'delete_answer',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  askQuestion: function (
    sentinel,
    jid,
    input,
    token,
    channel,
    useDraft,
    history,
    webSocketChannel,
    interactionId
  ) {
    const isDemo = channel?.toLowerCase()?.includes('demo');
    const isFromSidebarChat = channel?.toLowerCase()?.includes('zsb platform');
    const integration = {
      id: isDemo ? '0' : isFromSidebarChat ? '1' : '',
      name: channel,
    };
    return this.jacPrimeRun(
      {
        name: 'ask_question',
        nd: jid,
        ctx: {
          text: input,
          integration,
          metadata: { channel, interaction_id: interactionId },
          use_draft: useDraft,
          history,
          dont_log: false,
          ws_target: webSocketChannel || null,
        },
        is_async: webSocketChannel ? true : false,
      },
      sentinel,
      token
    );
  },

  askQuestion_nolog: function (sentinel, jid, input, token) {
    return this.jacPrimeRun(
      {
        name: 'ask_question_nolog',
        nd: jid,
        ctx: { text: input, dont_log: true },
      },
      sentinel,
      token
    );
  },

  userToken: function (email, password) {
    const data = { email, password };
    return this.userPOST('user/token/', data);
  },

  userCreate: function (email, password, name) {
    const data = {
      email,
      password,
      name,
      is_activated: process.env.NODE_ENV === 'development' ? true : false,
    };
    return this.userPOST('user/create/', data);
  },

  userActivate: function (code) {
    return this.userGET(`user/activate/${code}`, code);
  },

  getMasterAllUser: function (data, token) {
    axios.defaults.headers.common['Authorization'] = 'token ' + token;
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: '/js_admin/master_allusers',
        method: 'post',
        data,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  masterUpdateUser: function (id, token, data) {
    return this.userPOST(`user/manage/${id}`, data, token);
  },

  getGraphData: function (graph, token, depth) {
    axios.defaults.headers.common['Authorization'] = 'token ' + token;
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: '/js/graph_get',
        method: 'post',
        data: {
          nd: graph,
          detailed: false,
          depth: depth || undefined,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  getUser: function (token) {
    return this.userGET('user/manage/', token);
  },

  updateUser: function (user) {
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: '/user/manage/',
        method: 'patch',
        data: { ...user, is_activated: true },
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  updatePassword: function (email) {
    const data = { email };
    return this.userPOST('user/password_reset/', data);
  },

  updatePasswordValidateToken: function (token) {
    const data = { token };
    return this.userPOST('user/password_reset/validate_token/', data);
  },

  confirmPassword: function (password, token) {
    const data = { password, token };
    return this.userPOST('user/password_reset/confirm/', data);
  },

  createStripeCustomer: function (data) {
    return this.stripePOST('customer', data);
  },

  createStripeSubscription: function (data) {
    return this.stripePOST('subscription', data);
  },

  getStripeSubscription: function (subscription_id) {
    return this.stripeGET(`subscription/${subscription_id}`);
  },

  cancelSubscription: function (data) {
    return this.stripePOST('subscription/cancel', data);
  },

  updateSubscription: function (data) {
    return this.stripePOST('subscription/update', data);
  },

  getStripeCustomer: function (customer_id) {
    return this.stripeGET(`customer/${customer_id}`);
  },

  getPaymentMethods: function (customer_id) {
    return this.stripeGET(`customer/payment-method/${customer_id}`);
  },

  getCustomerPayments: function (customer_id) {
    return this.stripeGET(`payments/${customer_id}`);
  },

  getInvoices: function (subscription_id) {
    return this.stripeGET(`invoice/${subscription_id}`);
  },

  setCustomerUsage: function (data) {
    return this.stripePOST('subscription/usage', data);
  },

  addPaymentMethod: function (data) {
    return this.stripePOST('customer/add-payment-method', data);
  },

  updatePaymentMethod: function (data) {
    return this.stripePOST('customer/update-payment-method', data);
  },

  deleteBot: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'delete_bot',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  editBot: function (
    sentinel,
    jid,
    name,
    mode = '',
    desc,
    token,
    metadata,
    mailConfig,
    botLanguageConfig,
    defaultAnswer,
    threshold
  ) {
    const defaultAnswerData =
      typeof defaultAnswer === 'string' ||
      (isAnObject(defaultAnswer) &&
        !defaultAnswer.show_text &&
        !defaultAnswer.show_html)
        ? createDisplayAnswerAPIPayload(defaultAnswer)
        : defaultAnswer;
    return this.jacPrimeRun(
      {
        name: 'change_bot',
        nd: jid,
        ctx: {
          name,
          desc,
          metadata,
          mode,
          mail_config: mailConfig,
          default_answer: {
            ...defaultAnswerData,
            text:
              typeof defaultAnswer === 'string'
                ? defaultAnswer
                : defaultAnswer?.text,
            thresh_score: Number(threshold),
          },
          ...botLanguageConfig,
        },
      },
      sentinel,
      token
    );
  },

  editTranslationSettings: function (sentinel, jid, token, translateBot) {
    return this.jacPrimeRun(
      {
        name: 'change_bot',
        nd: jid,
        ctx: {
          auto_translate_response: translateBot,
        },
      },
      sentinel,
      token
    );
  },

  linkQuestion: function (sentinel, jid, text_input, token) {
    return this.jacPrimeRun(
      {
        name: 'link_question',
        nd: jid,
        ctx: { text: text_input },
      },
      sentinel,
      token
    );
  },

  unlinkQuestion: function (sentinel, jid, idx, token) {
    return this.jacPrimeRun(
      {
        name: 'unlink_question',
        nd: jid,
        ctx: { idx },
      },
      sentinel,
      token
    );
  },

  getMatches: function (sentinel, jid, text_input, num_answer_matches, token) {
    return this.jacPrimeRun(
      {
        name: 'get_question_matches',
        nd: jid,
        ctx: { question: text_input, limit: num_answer_matches },
      },
      sentinel,
      token
    );
  },

  getAnswerInsights: function (
    answerId,
    startDate,
    endDate,
    sentinel,
    jid,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'get_log',
        nd: jid,
        ctx: {
          answer_ids: answerId,
          start_date: startDate,
          end_date: endDate,
          integration_ids: [],
        },
      },
      sentinel,
      token
    );
  },

  getQuestionsByDateRange: function (
    sentinel,
    jid,
    start_date,
    end_date,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'get_log_date_filter',
        nd: jid,
        ctx: { start_date, end_date },
      },
      sentinel,
      token
    );
  },

  getFeedbackByDateRange: function (
    sentinel,
    jid,
    start_date,
    end_date,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'get_feedback_date_filter',
        nd: jid,
        ctx: { start_date, end_date },
      },
      sentinel,
      token
    );
  },

  getElasticLogAggregations: function (
    sentinel,
    jid,
    start_date,
    end_date,
    token,
    integrationIds,
    categories,
    params
  ) {
    const snakedCaseParams = Object.keys(params).reduce((acc, key) => {
      if (typeof key === 'string') {
        return { ...acc, [snakeCase(key)]: params[key] };
      }
      return key;
    }, {});
    return this.jacPrimeRun(
      {
        name: 'get_log',
        nd: jid,
        ctx: {
          start_date,
          end_date,
          integration_ids: Array.isArray(integrationIds)
            ? integrationIds
            : [integrationIds],
          category_ids: categories || [],
          ...snakedCaseParams,
          answer_ids: snakedCaseParams.answer_ids || [],
        },
      },
      sentinel,
      token
    ).then(response => {
      if (
        !!response.data.report[0] &&
        !!response.data.report[0].status &&
        response.data.report[0].status !== 200 &&
        response.data.report[0].status !== 404 &&
        !params?.for_aggs
      ) {
        throw new Error(GET_DATA_ERROR);
      } else if (response.data.report[0].status === 400) {
        throw new Error(
          'Sorry! Something went wrong while fetching analytics logs.'
        );
      }
      return response;
    });
  },

  getElasticLogDateFilter: function (
    sentinel,
    jid,
    start_date,
    end_date,
    token,
    integrationIds,
    categories,
    params
  ) {
    const snakedCaseParams = Object.keys(params).reduce((acc, key) => {
      if (typeof key === 'string') {
        return { ...acc, [snakeCase(key)]: params[key] };
      }
      return key;
    }, {});

    return this.jacPrimeRun(
      {
        name: 'get_log',
        nd: jid,
        ctx: {
          start_date,
          end_date,
          category_ids: categories || [],
          integration_ids: Array.isArray(integrationIds)
            ? integrationIds
            : [integrationIds],
          ...snakedCaseParams,
          answer_ids: snakedCaseParams.answer_ids || [],
        },
      },
      sentinel,
      token
    ).then(response => {
      if (
        !!response.data.report[0] &&
        !!response.data.report[0].status &&
        response.data.report[0].status !== 200 &&
        response.data.report[0].status !== 404 &&
        !params?.for_aggs
      ) {
        throw new Error(GET_DATA_ERROR);
      } else if (response.data.report[0].status === 400) {
        throw new Error(
          'Sorry! Something went wrong while fetching analytics logs.'
        );
      }
      return response;
    });
  },

  getElasticLogFilter: function (
    sentinel,
    jid,
    filter_key,
    filter_value,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'get_log_filter',
        nd: jid,
        ctx: { filter_key, filter_value },
      },
      sentinel,
      token
    ).then(response => {
      if (
        !!response.data.report[0] &&
        !!response.data.report[0].status &&
        response.data.report[0].status !== 200 &&
        response.data.report[0].status !== 404
      ) {
        throw new Error(GET_DATA_ERROR);
      } else if (response.data.report[0].status === 400) {
        throw new Error('Sorry! Something went wrong while fetching records.');
      } else if (
        !response.data.report.length ||
        response.data.report[0].status === 404
      ) {
        throw new Error('No records found');
      }
      return response;
    });
  },

  getElasticByIntervalLogDate: function (
    sentinel,
    jid,
    start_date,
    end_date,
    interval,
    integrationIds,
    categories,
    token,
    params
  ) {
    const snakedCaseParams = Object.keys(params).reduce((acc, key) => {
      if (typeof key === 'string') {
        return { ...acc, [snakeCase(key)]: params[key] };
      }
      return key;
    }, {});

    return this.jacPrimeRun(
      {
        name: 'get_graph',
        nd: jid,
        ctx: {
          start_date,
          end_date,
          start_range: start_date,
          end_range: end_date,
          interval,
          category_ids: categories || [],
          integration_ids: integrationIds || params?.interactionIds || [],
          ...snakedCaseParams,
          answer_ids: snakedCaseParams.answer_ids || [],
        },
      },
      sentinel,
      token
    ).then(response => {
      if (
        !!response.data.report[0] &&
        !!response.data.report[0].status &&
        response.data.report[0].status !== 200 &&
        response.data.report[0].status !== 404
      ) {
        throw new Error(GET_DATA_ERROR);
      } else if (response.data.report[0].status === 400) {
        throw new Error('Sorry! Something went wrong while fetching records.');
      } else if (response.data.report[0].status === 404) {
        throw new Error('No records found');
      } else if (
        (!response.data.report.length ||
          response.data.report[0].status === 404) &&
        !params?.for_aggs
      ) {
        throw new Error('No records found');
      }
      return response;
    });
  },

  getCallbackByDateRange: function (
    sentinel,
    jid,
    start_date,
    end_date,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'get_callback',
        nd: jid,
        ctx: { start_date, end_date },
      },
      sentinel,
      token
    ).then(response => {
      if (
        !!response.data.report[0] &&
        !!response.data.report[0].status &&
        response.data.report[0].status !== 200 &&
        response.data.report[0].status !== 404
      ) {
        throw new Error(GET_DATA_ERROR);
      } else if (response.data.report[0].status === 400) {
        throw new Error('Sorry! Something went wrong while fetching records.');
      } else if (
        !response.data.report ||
        !response.data.report.length ||
        response.data.report[0].status === 404
      ) {
        throw new Error('No records found');
      }
      return response;
    });
  },

  createCategory: function (sentinel, jid, name, color, token) {
    return this.jacPrimeRun(
      {
        name: 'create_category',
        nd: jid,
        ctx: { name, color },
      },
      sentinel,
      token
    );
  },

  getCategories: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_categories',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  updateCategory: function (sentinel, jid, name, color, token) {
    return this.jacPrimeRun(
      {
        name: 'change_category',
        nd: jid,
        ctx: { name, color },
      },
      sentinel,
      token
    );
  },

  deleteCategory: function (sentinel, jid, deleteAnswers, token) {
    const shouldDeleteAnswers = deleteAnswers ? 'yes' : 'no';
    return this.jacPrimeRun(
      {
        name: 'delete_category',
        nd: jid,
        ctx: { deleteanswers: shouldDeleteAnswers },
      },
      sentinel,
      token
    );
  },

  getCategoryTemplates: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_category_templates',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  importCategoryTemplates: function (sentinel, jid, token, categoryTemplates) {
    return this.jacPrimeRun(
      {
        name: 'import_category_template',
        nd: jid,
        ctx: { templates: categoryTemplates },
      },
      sentinel,
      token
    );
  },

  exportBot: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'exporter',
        ctx: {
          bot: jid,
        },
      },
      sentinel,
      token
    );
  },

  importBot: function (
    sentinel,
    graph,
    name,
    desc,
    metadata,
    answerbank,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'import_bot',
        nd: graph,
        ctx: { name, desc, metadata, answerbank },
      },
      sentinel,
      token
    );
  },

  importer: function (sentinel, graph, token, data) {
    return this.jacPrimeRun(
      {
        name: 'importer',
        nd: graph,
        ctx: { data },
      },
      sentinel,
      token
    );
  },

  createIntegration: function (sentinel, jid, data, type, token) {
    return this.jacPrimeRun(
      {
        name: 'create_integration',
        nd: jid,
        ctx: {
          identifier: data.identifier,
          int_type: type,
          path: data.path,
          settingsobj: data.settings,
        },
      },
      sentinel,
      token
    );
  },

  setViberWebHook: function (sentinel, jid, payload, token) {
    return this.jacPrimeRun(
      {
        name: 'viber_set_webhook',
        nd: jid,
        ctx: payload,
      },
      sentinel,
      token
    );
  },

  getIntegration: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_integrations',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  editIntegration: function (sentinel, jid, data, type, token) {
    return this.jacPrimeRun(
      {
        name: 'change_integration',
        nd: jid,
        ctx: {
          identifier: data.identifier,
          int_type: type,
          path: data.path,
          settingsobj: data.settings,
        },
      },
      sentinel,
      token
    );
  },

  deleteIntegration: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'delete_integration',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  createTestSuite: function (sentinel, jid, name, token) {
    return this.jacPrimeRun(
      {
        name: 'create_testsuite',
        nd: jid,
        ctx: { name },
      },
      sentinel,
      token
    );
  },

  getTestSuites: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_testsuites',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  updateTestSuite: function (sentinel, jid, name, token) {
    return this.jacPrimeRun(
      {
        name: 'change_testsuite',
        nd: jid,
        ctx: { name },
      },
      sentinel,
      token
    );
  },

  deleteTestSuite: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'delete_testsuite',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  getTestCases: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_testcases',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  createTestCase: function (sentinel, jid, question, response, token) {
    return this.jacPrimeRun(
      {
        name: 'create_testcase',
        nd: jid,
        ctx: { question, answer: response.text },
      },
      sentinel,
      token
    );
  },

  updateTestCase: function (sentinel, jid, question, response, token) {
    return this.jacPrimeRun(
      {
        name: 'change_testcase',
        nd: jid,
        ctx: { question, answer: response.show_text },
      },
      sentinel,
      token
    );
  },

  deleteTestCase: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'delete_testcase',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  runTestSuite: function (sentinel, jid, name, token, use_draft) {
    return this.jacPrimeRun(
      {
        name: 'run_testsuite',
        nd: jid,
        ctx: { name, use_draft },
      },
      sentinel,
      token
    );
  },

  runAllTests: function (sentinel, jid, token, use_draft) {
    return this.jacPrimeRun(
      {
        name: 'run_all_tests',
        nd: jid,
        ctx: { use_draft },
      },
      sentinel,
      token
    );
  },

  getQuestionLog: function (
    sentinel,
    jid,
    answerid,
    token,
    start_date,
    end_date
  ) {
    const endDateEndofDay = convertToEndOfDay(end_date);
    return this.jacPrimeRun(
      {
        name: 'get_question_log',
        nd: jid,
        ctx: {
          answer_ids: answerid,
          start_date,
          end_date: endDateEndofDay,
          integration_ids: [],
        },
      },
      sentinel,
      token
    );
  },

  export: function (sentinel, jid, token, params) {
    return this.jacPrimeRun(
      {
        name: 'exporter',
        ctx: params,
      },
      sentinel,
      token
    );
  },

  getDefaultAnswer: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_default_answer',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  setOnboardingFlag: function (sentinel, token, graph, flag) {
    return this.jacPrimeRun(
      {
        name: 'set_onboarding_flag',
        nd: graph,
        ctx: { flag },
      },
      sentinel,
      token
    );
  },

  resetOnboardingFlag: function (sentinel, token, graph, flag) {
    return this.jacPrimeRun(
      {
        name: 'reset_onboarding_flag',
        nd: graph,
        ctx: { flag },
      },
      sentinel,
      token
    );
  },

  resetAllOnboardingFlag: function (sentinel, token, graph) {
    return this.jacPrimeRun(
      {
        name: 'reset_onboarding',
        nd: graph,
      },
      sentinel,
      token
    );
  },

  createManyTestCases: function (sentinel, token, graph, questions, isDefault) {
    return this.jacPrimeRun(
      {
        name: 'create_many_testcases',
        nd: graph,
        ctx: { questions: [...questions], is_default: isDefault },
      },
      sentinel,
      token
    );
  },

  getBotSet: function (nd, sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'get_botset',
        nd,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  changeBotSet: function (sentinel, token, graph, ctx) {
    return this.jacPrimeRun(
      {
        name: 'change_botset',
        nd: graph,
        ctx,
      },
      sentinel,
      token
    );
  },

  resetBotset: function (sentinel, token, graph) {
    return this.jacPrimeRun(
      {
        name: 'reset_botset',
        nd: graph,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  createJiraTicket: function (
    title,
    body,
    reporter,
    files,
    token,
    sentinel,
    graph
  ) {
    return this.jacPrimeRunMultipart(
      {
        name: 'jira_create_issue',
        ctx: {
          projectKey: '10000',
          title,
          body,
          labels: [`Reporter:${reporter.replaceAll(' ', '_')}`],
          issueType: '10001',
          ...files,
        },
      },
      sentinel,
      graph,
      token
    );
  },

  getAllJiraTicket: function (reporter, token, sentinel, startAt) {
    return this.jacPrimeRun(
      {
        name: 'jira_get_issue',
        ctx: {
          jql: `Labels="Reporter:${reporter.replaceAll(' ', '_')}"`,
          startAt,
          maxResults: 5,
        },
      },
      sentinel,
      token
    );
  },

  getJiraIssue: function (ticket, graph, sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'jira_get_issue',
        nd: graph,
        ctx: {
          issueIdOrKey: ticket,
        },
      },
      sentinel,
      token
    );
  },

  downloadJiraAttachment: function (id, graph, sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'jira_download_attachment',
        nd: graph,
        ctx: {
          attachmentId: id,
        },
      },
      sentinel,
      token
    );
  },

  generateFBMessengerUUID: function (data) {
    return axios
      .post(`${WEBHOOK_MESSENGER}generate-uuid`, data)
      .catch(error => error.response);
  },

  setViberHook: function (data) {
    return axios.post(WEBHOOK_VIBER, data).catch(error => error.response);
  },

  globalVarGetter: function (name, sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'get_global_var',
        ctx: {
          name,
        },
      },
      sentinel,
      token
    );
  },

  getActivityLogs: function (sentinel, token, params, size) {
    return this.jacPrimeRun(
      {
        name: 'get_activity_log',
        ctx: {
          ...params,
          from: 0,
          size,
        },
        snt: 'active:sentinel',
        nd: 'active:graph',
      },
      sentinel,
      token
    );
  },

  getActivityLogsFilters: function (sentinel, token, master_id, all, params) {
    return this.jacPrimeRun(
      {
        name: 'get_activity_log_filters',
        snt: 'active:sentinel',
        nd: 'active:graph',
        ctx: { master_id: all ? null : master_id, all, ...params },
      },
      sentinel,
      token
    );
  },

  sigInToGoogle: function (data) {
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: `/auth/google/`,
        method: 'post',
        data,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  getSimilarQuestions: function (text, sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_similar_questions',
        nd: jid,
        ctx: {
          text,
        },
      },
      sentinel,
      token
    );
  },

  hardLinkQuestion: function (
    question,
    answerId,
    jid,
    version,
    sentinel,
    token,
    withAnswer = false
  ) {
    return this.jacPrimeRun(
      {
        name: 'hard_link_question',
        nd: jid,
        ctx: {
          question,
          answer_id: answerId,
          report_with_answer: withAnswer,
          question_version: version,
        },
      },
      sentinel,
      token
    );
  },

  deleteQuestionHardLink: function (questionId, sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'delete_question',
        nd: questionId,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  deleteQuestionThenHardLinkAnswer: function (
    questionId,
    questionText,
    answerid,
    botJID,
    sentinel,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'delete_question_then_link_answer',
        nd: botJID,
        ctx: {
          question_id: questionId,
          question: questionText,
          answer_id: answerid,
        },
      },
      sentinel,
      token
    );
  },

  updateQuestionValidation: function (sentinel, jid, token, validationDetails) {
    return this.jacPrimeRun(
      {
        name: 'update_log_validation',
        nd: jid,
        ctx: { ...validationDetails },
      },
      sentinel,
      token
    );
  },

  getSimilarAnswersWithID: function (answer, sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_similar_answers_with_id',
        nd: jid,
        ctx: {
          answer_obj: answer,
        },
      },
      sentinel,
      token
    );
  },

  getQuestionMatchesWithAnswer: function (
    text,
    num_matches,
    sentinel,
    jid,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'get_question_matches_with_answer',
        nd: jid,
        ctx: {
          text,
          num_matches,
        },
      },
      sentinel,
      token
    );
  },

  getSimilarAnswer: function (sentinel, jid, text, answerId = null, token) {
    return this.jacPrimeRun(
      {
        name: 'get_similar_answers',
        nd: jid,
        ctx: {
          text: text,
          //revert this to answer_id once BE refactor is deployed
          answer_id: isZSBUUID(answerId) ? answerId : undefined,
        },
      },
      sentinel,
      token
    );
  },

  syncSentinelGlobal: function (master_id, token) {
    return this.jacSyncSentinelGlobal(
      {
        name: 'sentinel_active_global',
        ctx: {
          master_id,
        },
      },
      token
    );
  },

  getHardLinkQuestionsFromAnswer: function (answerId, sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'get_questions_from_answer',
        nd: answerId,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  getHardLinkQuestionsFromAnswerWithScore: function (
    answerId,
    sentinel,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'get_questions_from_answer_with_score',
        nd: answerId,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  editQuestion: function (
    text,
    answerId,
    questionId,
    version,
    sentinel,
    token,
    withAnswer = false
  ) {
    return this.jacPrimeRun(
      {
        name: 'change_question',
        nd: questionId,
        ctx: {
          text,
          answer_id: answerId,
          report_with_answer: withAnswer,
          question_version: version,
        },
      },
      sentinel,
      token
    );
  },

  getAllQuestionsWithAnswer: function (jid, sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'get_all_questions_with_answer',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  // use this to fetch question score
  // or question that is NOT LINKED
  // returns similar questions if found
  getAnswersWithScoreFromQuestion: function (
    text,
    jid,
    sentinel,
    token,
    withDefaultAnswers
  ) {
    return this.jacPrimeRun(
      {
        name: 'get_answers_with_score_from_question',
        nd: jid,
        ctx: {
          question: text,
          with_default: withDefaultAnswers,
        },
      },
      sentinel,
      token
    );
  },

  // question is ALREADY LINKED to an answer
  // use this to fetch question score to all answers
  // and you want to link to new answer
  getAnswersWithScoreFromQuestionWithLinkedAnswer: function (
    question,
    answerId,
    jid,
    sentinel,
    token
  ) {
    return this.jacPrimeRun(
      {
        name: 'get_answers_with_score_from_question_with_linked_answer',
        nd: jid,
        ctx: {
          question,
          answer_id: answerId,
        },
      },
      sentinel,
      token
    );
  },
  getQuestion: function (questionId, sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'get_question',
        nd: questionId,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  addFeatureFlag: function (feature, currentVersion) {
    return this.jacPrimeRun({
      name: 'add_version',
      ctx: {
        ver: 'v1.0.1',
        features: {
          [feature]: true,
        },
      },
    });
  },

  getPlansWithDetails: function (sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'get_plans',
        ctx: {},
      },
      sentinel,
      token
    );
  },

  syncBot: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'sync_bot',
        nd: jid,
      },
      sentinel,
      token
    );
  },

  getFiles: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'get_files',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  addFile: function (
    { file, ver, pages, fileType, webSocketChannel, preConfigs },
    token,
    jid,
    sentinel
  ) {
    let formData;

    const ctxData = {
      pages,
      pre_configs: preConfigs,
      ver,
      ws_target: webSocketChannel || null,
    };

    if (fileType === 'url') {
      return this.jacPrimeRun(
        {
          name: 'add_file',
          nd: jid,
          ctx: ctxData,
          is_async: webSocketChannel ? true : false,
        },
        sentinel,
        token
      );
    } else {
      formData = new FormData();
      formData.append('nd', jid);
      formData.append('files', file);
      formData.append('ctx', JSON.stringify(ctxData));
      formData.append(
        'is_async',
        JSON.stringify(webSocketChannel ? true : false)
      );
      axios.defaults.headers.common['Authorization'] = 'token ' + token;
      return axios
        .request({
          baseURL: API_BASE_URL,
          url: '/js/walker_run?name=add_file',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch(error => {
          throw new Error(getErrorMessage(error));
        });
    }
  },

  scrappedUrl: function (
    {
      url,
      uploadingMethod,
      timeoutInSecond,
      crawler,
      webSocketChannel,
      method,
      expression,
      triggerID,
    },
    token,
    jid,
    sentinel
  ) {
    const getters = {
      method: method ? method : 'default',
      expression,
    };

    if (!getters.expression) {
      delete getters.expression;
    }

    return this.jacPrimeRun(
      {
        name: 'scrape',
        nd: jid,
        ctx: {
          pages: [
            {
              goto: {
                url,
                wait_until: uploadingMethod,
                timeout: timeoutInSecond * 1000,
              },
              getters: [getters],
              crawler,
            },
          ],
          pre_configs: renderPreconfigData(uploadingMethod, timeoutInSecond, [
            {
              method: 'none',
            },
          ]),
          ws_target: webSocketChannel || null,
          is_async: webSocketChannel ? true : false,
          trigger_id: triggerID,
        },
      },
      sentinel,
      token
    );
  },

  deleteFile: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'delete_file',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  downloadFile: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'download_file',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  generateFileCdnUrl: function (sentinel, jid, token) {
    return this.jacPrimeRun(
      {
        name: 'cdn_url_file',
        nd: jid,
        ctx: {},
      },
      sentinel,
      token
    );
  },

  changeFile: function (sentinel, jid, token, fileDetails) {
    const { version, categoryId } = fileDetails;
    return this.jacPrimeRun(
      {
        name: 'change_file',
        nd: jid,
        ctx: {
          ver: version,
          category: typeof categoryId !== 'undefined' ? categoryId : undefined,
        },
      },
      sentinel,
      token
    );
  },

  changeWebsite: function (sentinel, jid, token, websiteDetails) {
    return this.jacPrimeRun(
      {
        name: 'change_file',
        nd: jid,
        ctx: websiteDetails,
      },
      sentinel,
      token
    );
  },

  scrapePreview: function (page, token, jid, sentinel) {
    return this.jacPrimeRun(
      {
        name: 'scrape_preview',
        nd: jid,
        ctx: {
          page,
        },
      },
      sentinel,
      token
    );
  },

  initializeApiGateway: function (sentinel, token, key, wlk) {
    return this.jacPrimeRun(
      {
        name: 'initialize_api_gateway',
        ctx: {
          wlk,
          key,
        },
      },
      sentinel,
      token
    );
  },

  removeAPIGateway: function (sentinel, token) {
    return this.jacPrimeRun(
      {
        name: 'remove_api_gateway',
        ctx: {},
      },
      sentinel,
      token
    );
  },

  stopScrapping: function (triggerID, token, jid, sentinel) {
    return this.jacPrimeRun(
      {
        name: 'scrape_stop ',
        nd: jid,
        ctx: {
          trigger_id: triggerID,
        },
      },
      sentinel,
      token
    );
  },
};
