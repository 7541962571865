import {
  CLEANUP_ALL_GLOBAL_VARS,
  CLEAR_DIGRAPH_DATA,
  GET_ALL_GLOBAL_VARS,
  GET_ALL_VERSIONS,
  GET_ALL_USERS,
  SET_MESSENGER_API,
  HIDE_DIGRAPH,
  SET_NETWORK_DIGRAPH_DATA,
  SHOW_DIGRAPH,
  START_IMPERSONATING_USER,
  STOP_IMPERSONATING_USER,
  UPDATE_USER_DATA,
  SET_VIBER_API,
} from 'store/action';

export const adminReducer = (state, action) => {
  switch (action.type) {
    case GET_ALL_USERS: {
      return {
        ...state,
        admin: {
          ...state.admin,
          all_users: action.payload,
        },
      };
    }

    case UPDATE_USER_DATA: {
      const newData = state.admin.all_users.map(user => {
        if (action.payload.id === user.id) {
          return {
            ...user,
            is_activated: action.payload.is_activated,
          };
        }
        return user;
      });
      return {
        ...state,
        admin: {
          ...state.admin,
          all_users: newData,
        },
      };
    }

    case START_IMPERSONATING_USER: {
      const { impersonated_user } = action.payload;
      return {
        ...state,
        bots: [],
        graph: impersonated_user?.graph,
        admin: {
          ...state.admin,
          is_impersonating: true,
          impersonated_user: {
            ...impersonated_user,
            email: impersonated_user?.user,
          },
        },
      };
    }

    case STOP_IMPERSONATING_USER: {
      return {
        ...state,
        graph: localStorage.getItem('masterUserND'),
        bots: [],
        admin: {
          ...state.admin,
          is_impersonating: false,
          impersonated_user: {},
        },
      };
    }

    case SET_MESSENGER_API: {
      return {
        ...state,
        messengerApi: action.payload,
      };
    }

    case SET_VIBER_API: {
      return {
        ...state,
        viberConfig: action.payload,
      };
    }

    case GET_ALL_GLOBAL_VARS: {
      return {
        ...state,
        admin: {
          ...state.admin,
          all_global_vars: action.payload,
        },
      };
    }

    case GET_ALL_VERSIONS: {
      return {
        ...state,
        admin: {
          ...state.admin,
          all_versions: action.payload,
        },
      };
    }

    case CLEANUP_ALL_GLOBAL_VARS: {
      return {
        ...state,
        admin: {
          ...state.admin,
          all_global_vars: [],
        },
      };
    }

    case SET_NETWORK_DIGRAPH_DATA:
      return {
        ...state,
        admin: {
          ...state.admin,
          networkData: action.payload,
        },
      };

    case CLEAR_DIGRAPH_DATA:
      return {
        ...state,
        admin: {
          ...state.admin,
          networkData: {},
        },
      };

    case SHOW_DIGRAPH:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDigraph: true,
        },
      };

    case HIDE_DIGRAPH:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDigraph: false,
        },
      };

    default:
      return state;
  }
};
