import { message } from 'antd';
import { useContext, useState } from 'react';

import ROUTES from 'constants/routes';
import { stripeAPIService } from 'services/stripeAPI.service';

import { getTokenSelector } from 'selectors/user';
import { DISMISS_FIRST_LOGIN_PLAN } from 'store/action';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import { CANCELED_CHECKOUT_URL_PARAM } from 'constants/plan';
import { planDetailsSelector } from 'selectors/plan';

const useFirstLoginPromoModal = () => {
  const [state, dispatch] = useContext(Context);
  const token = useSelector(getTokenSelector);
  const planDetails = useSelector(planDetailsSelector);
  const { graph, sentinel } = state;

  const [loading, setLoading] = useState(false);

  const SUCCESS_URL = `${window.location.origin}${ROUTES.SUBSCRIPTION}`;
  const CANCEL_URL = `${window.location.origin}${ROUTES.SUBSCRIPTION}${CANCELED_CHECKOUT_URL_PARAM}`;

  const handleDismiss = () => {
    dispatch({
      type: DISMISS_FIRST_LOGIN_PLAN,
    });
  };

  const handleStartTrial = async () => {
    setLoading(true);
    try {
      const res = await stripeAPIService.switchPlan(
        'basic',
        SUCCESS_URL,
        CANCEL_URL,
        graph,
        sentinel,
        token
      );

      const checkoutURl = res.data?.report[0]?.url;
      if (checkoutURl) {
        window.location.href = checkoutURl;
        setLoading(false);
      } else {
        throw new Error('Something went wrong while fetching checkout URL.');
      }
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  return {
    handleDismiss,
    handleStartTrial,
    loading,
    planDetails,
  };
};

export default useFirstLoginPromoModal;
