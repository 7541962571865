import {
  keyPropertyValidator,
  urlAiToolValidator,
} from 'store/reducers/helpers/bot/aiTools';

export const SELECTABLE_FIELDS = ['header', 'query', 'body', 'path'];

export const DATA_TYPES = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'date', label: 'Date' },
  { value: 'enum', label: 'Enum' },
  // { value: 'object', label: 'Object (Experimental)' },
];

export const OUTPUT_OBJECT_DATA_TYPES = [
  { value: 'string', label: 'String' },
  { value: 'object', label: 'Object' },
];

export const OUTPUT_TYPES = [
  { value: 'url', label: 'URL', showAdvanceSettings: true },
  { value: 'walker', label: 'Walker', showAdvanceSettings: false },
  { value: 'static', label: 'Static', showAdvanceSettings: false },
];

export const REQUEST_METHODS = [
  { value: 'get', label: 'GET' },
  { value: 'post', label: 'POST' },
  { value: 'put', label: 'PUT' },
  { value: 'patch', label: 'PATCH' },
  { value: 'delete', label: 'DELETE' },
];

export const RESPONSE_OPTIONS = [
  { value: 'null', label: 'NULL', showProperties: false },
  { value: '{{$}}', label: 'Open AI', showProperties: false },
  { value: 'manual', label: 'Manual', properties: [], showProperties: true },
];

export const AUTH_OPTIONS = [
  {
    value: 'bearer',
    label: 'Bearer Token',
    properties: [],
    showProperties: true,
  },
  {
    value: 'oauth2_client_credentials',
    label: 'OAuth 2',
    properties: [],
    showProperties: true,
  },
];
export const CONTENT_TYPE_OPTIONS = [
  {
    value: 'application/json',
    label: 'application/json',
  },
  {
    value: 'application/x-www-form-urlencoded',
    label: 'application/x-www-form-urlencoded',
  },
];

export const DEFAULT_URL_OUTPUT_KEYS = [
  'type',
  'url',
  'method',
  'header',
  'body',
  'query',
];

export const DEFAULT_WALKER_OUTPUT_KEYS = ['type', 'code', 'request'];

export const DEFAULT_AUTH_FIELDS = [
  {
    name: 'type',
    value: '',
    type: 'string',
    position: 1,
    default: true,
    options: AUTH_OPTIONS,
    label: 'Type',
    field: 'select',
    placeholder: 'Select type...',
    collapsibleField: false,
    display: {
      onload: true,
    },
    authType: 'both',
  },
  {
    name: 'url',
    value: '',
    type: 'string',
    position: 2,
    default: true,
    label: 'URL',
    field: 'input',
    placeholder: 'Enter url...',
    collapsibleField: false,
    display: {
      onload: false,
      relatedTo: 'type',
    },
    authType: 'oauth2_client_credentials',
  },
  {
    name: 'method',
    value: 'get',
    type: 'string',
    position: 3,
    default: true,
    options: REQUEST_METHODS,
    label: 'Method',
    required: true,
    field: 'select',
    placeholder: 'Select method...',
    collapsibleField: false,
    display: {
      onload: false,
      relatedTo: 'type',
    },
    authType: 'oauth2_client_credentials',
  },
  {
    name: 'Content-Type',
    value: '',
    type: 'string',
    position: 3,
    default: true,
    options: CONTENT_TYPE_OPTIONS,
    label: 'Content-Type',
    field: 'select',
    placeholder: 'Select Content-type...',
    collapsibleField: false,
    display: {
      onload: false,
      relatedTo: 'type',
    },
    authType: 'oauth2_client_credentials',
  },
  {
    name: 'header',
    value: 'manual',
    properties: [{ id: 0, name: null, value: null }],
    type: 'object',
    position: 4,
    default: true,
    label: 'Header',
    field: null,
    action: ['add', 'delete'],
    collapsibleField: true,
    display: {
      onload: false,
      relatedTo: 'type',
    },
    authType: 'oauth2_client_credentials',
  },
  {
    name: 'body',
    value: '',
    type: 'object',
    position: 5,
    default: true,
    properties: [{ id: 0, name: null, value: null }],
    options: RESPONSE_OPTIONS,
    label: 'Body',
    optionLabel: 'Method',
    field: null,
    action: ['add', 'delete'],
    collapsibleField: true,
    display: {
      onload: false,
      relatedTo: 'type',
    },
    authType: 'oauth2_client_credentials',
  },
  {
    name: 'token',
    value: '',
    type: 'string',
    position: 2,
    default: true,
    label: 'Token',
    field: 'input',
    placeholder: 'Enter token...',
    collapsibleField: false,
    display: {
      onload: false,
      relatedTo: 'type',
    },
    authType: 'bearer',
  },
  {
    name: 'prefix',
    value: 'Bearer',
    type: 'string',
    position: 3,
    default: true,
    label: 'Prefix',
    field: 'input',
    placeholder: 'Enter prefix...',
    collapsibleField: false,
    display: {
      onload: false,
      relatedTo: 'type',
    },
    authType: 'bearer',
  },
];

export const DEFAULT_URL_OUTPUT_FIElDS = [
  {
    name: 'type',
    value: 'url',
    type: 'string',
    position: 1,
    default: true,
    hide: false,
  },
  {
    name: 'url',
    value: '',
    type: 'string',
    position: 2,
    default: true,
    label: 'URL',
    field: 'input',
    placeholder: 'Enter url...',
    pathValue: '',
    required: true,
    validator: urlAiToolValidator,
    hide: false,
    display: {
      onload: true,
    },
    advanceSettingOption: false,
  },
  {
    name: 'formatter',
    value: '',
    type: 'string',
    position: 3,
    default: true,
    label: 'Formatter',
    field: 'textArea',
    placeholder: 'How do you want the bot to respond...',
    pathValue: '',
    required: true,
    hide: false,
    tooltip:
      'Ex. You shall respond on my behalf to provide ...<more_information_here>',
    display: {
      onload: true,
    },
    advanceSettingOption: false,
  },
  {
    name: 'method',
    value: 'get',
    type: 'string',
    position: 4,
    default: true,
    options: REQUEST_METHODS,
    label: 'Method',
    required: true,
    field: 'select',
    placeholder: 'Select method...',
    hide: false,
    display: {
      onload: false,
      relatedTo: 'url',
    },
    advanceSettingOption: false,
  },
  {
    name: 'header',
    value: 'manual',
    properties: [{ id: 0, name: null, value: null }],
    type: 'object',
    position: 5,
    default: true,
    label: 'Header',
    field: null,
    action: ['add', 'delete'],
    hide: false,
    display: {
      onload: false,
      relatedTo: 'url',
    },
    advanceSettingOption: true,
  },
  {
    name: 'body',
    value: '',
    type: 'object',
    position: 6,
    default: true,
    properties: [{ id: 0, name: null, value: null }],
    options: RESPONSE_OPTIONS,
    label: 'Body',
    optionLabel: 'Method',
    field: null,
    action: ['add', 'delete'],
    hide: false,
    display: {
      onload: false,
      relatedTo: 'url',
    },
    advanceSettingOption: true,
  },
  {
    name: 'query',
    value: '',
    type: 'object',
    position: 7,
    properties: [
      { id: 0, name: null, value: null, validator: keyPropertyValidator },
    ],
    default: true,
    options: RESPONSE_OPTIONS,
    label: 'Query',
    optionLabel: 'Method',
    field: null,
    action: ['add', 'delete'],
    hide: false,
    validator: keyPropertyValidator,
    display: {
      onload: false,
      relatedTo: 'url',
    },
    advanceSettingOption: true,
  },
  {
    name: 'path',
    value: '',
    type: 'object',
    position: 8,
    properties: [{ id: 0, name: null, value: null }],
    default: true,
    options: RESPONSE_OPTIONS,
    label: 'Path Variable',
    optionLabel: 'Method',
    field: null,
    action: [],
    display: {
      onload: false,
      relatedTo: 'url',
    },
    advanceSettingOption: true,
  },
  {
    name: 'auth',
    request: DEFAULT_AUTH_FIELDS,
    position: 9,
    value: '',
    display: {
      onload: false,
      relatedTo: 'url',
    },
    advanceSettingOption: true,
    type: 'array',
  },
];

export const DEFAULT_WALKER_OUTPUT_FIElDS = [
  { name: 'type', value: 'walker', type: 'string', position: 1, default: true },
  {
    name: 'code',
    value: '',
    type: 'string',
    position: 2,
    default: true,
    field: 'textArea',
    label: 'Code',
    required: true,
    hide: false,
    placeholder: 'Enter code. ex. response="";',
    display: {
      onload: true,
    },
  },
  // { name: 'request', value: null, type: 'string', position: 3, default: true },
];

export const DEFAULT_STATIC_OUTPUT_FIElDS = [
  { name: 'type', value: 'static', type: 'string', position: 1 },
  {
    name: 'response',
    value: null,
    type: 'string',
    position: 2,
    field: 'textArea',
    label: 'Response',
    required: true,
    hide: false,
    placeholder: 'Enter response...',
    display: {
      onload: true,
    },
  },
];

export const OBJECT_TYPE_OUTPUT_FIELDS = ['query', 'body'];
export const DEFAULT_OUTPUT_TYPE = 'url';
export const DEFAULT_METHOD = 'get';

export const NEW_OBJECT_ITEM = [{ position: 1, name: '' }];

export const METHODS = [
  { value: 'get', label: 'GET' },
  { value: 'post', label: 'POST' },
  { value: 'put', label: 'PUT' },
  { value: 'patch', label: 'PATCH' },
  { value: 'del', label: 'DELETE' },
];
