import {
  StyledPreviewWidgetModal,
  StyledImage,
} from './PreviewImageModal.style';
import useSelector from 'store/useSelector';
import {
  imageViewerModalSourceSelector,
  imageViewerModalTitleSelector,
  isImageViewerModalOpenSelector,
} from 'selectors/bot/ui';
import { useContext } from 'react';
import { Context } from 'store/store';
import { CLOSE_IMAGE_VIEWER_MODAL } from 'store/action';

const PreviewImageModal = () => {
  const [, dispatch] = useContext(Context);
  const visible = useSelector(isImageViewerModalOpenSelector);
  const title = useSelector(imageViewerModalTitleSelector);
  const imageSource = useSelector(imageViewerModalSourceSelector);

  const handleCloseImagePreview = () => {
    dispatch({ type: CLOSE_IMAGE_VIEWER_MODAL });
  };

  return (
    <StyledPreviewWidgetModal
      visible={visible}
      title={title || `Image Preview`}
      footer={null}
      onCancel={handleCloseImagePreview}
    >
      <StyledImage alt="example" src={imageSource} />
    </StyledPreviewWidgetModal>
  );
};

export default PreviewImageModal;
