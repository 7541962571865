import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';
import { StyledRoot } from './CategorySelect.styles';
import { stripUUID } from 'utils';

const LABEL_COLOR = '#fff';

const CategorySelect = ({
  label,
  categories,
  isCategoryModal,
  selectedCategory,
  handleChange,
  placeholder,
}) => {
  const currentCategory = categories.find(
    c => stripUUID(c.jid) === stripUUID(selectedCategory)
  );

  return (
    <StyledRoot>
      {!!label && <h4>{label}</h4>}
      <Select
        value={currentCategory?.name}
        showSearch
        width="100%"
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={handleChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Select.Option
          value="Select Category"
          style={{ backgroundColor: 'transparent', color: '#000' }}
          disabled={isCategoryModal}
          key="label"
        >
          {'Select Category'}
        </Select.Option>
        {categories.map((category, idx) => {
          return (
            <Select.Option
              value={category.jid}
              style={{ backgroundColor: category.color, color: LABEL_COLOR }}
              key={idx}
            >
              {category.name}
            </Select.Option>
          );
        })}
      </Select>
    </StyledRoot>
  );
};

CategorySelect.propTypes = {
  categories: PropTypes.array.isRequired,
  isCategoryModal: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default CategorySelect;
