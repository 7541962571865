import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'antd';
import { kebabCase } from 'lodash';

import { cssVariables } from 'styles/root';

const StyledAnchorLink = styled(Button)`
  background-color: transparent !important;
  padding: 0;
  font-size: 12px;
  cursor: pointer;
  border: none !important;
  color: ${props =>
    !props?.adaptButtonColor ? cssVariables.primaryBlue : 'currentColor'};
  margin: 0;
  height: auto;

  &.ant-btn {
    span {
      color: ${props =>
        !props?.adaptButtonColor
          ? cssVariables.primaryBlue
          : 'currentColor'} !important;
    }
  }
`;

const AnchorButton = props => {
  const { title, adaptButtonColor, ...rest } = props;
  return (
    <StyledAnchorLink
      adaptButtonColor={Boolean(adaptButtonColor)}
      {...rest}
      aria-label={kebabCase(title)}
    >
      {title}
    </StyledAnchorLink>
  );
};

AnchorButton.propTypes = {
  title: PropTypes.string,
  adaptButtonColor: PropTypes.bool,
};

export default AnchorButton;
