import { message } from 'antd';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { SET_ONBOARDING_FLAG } from 'store/action';
import {
  ADD_FILE,
  RESET_WS_ADD_FILE_ADDED,
  RESET_WS_SCANNER,
} from 'store/action';
import { getTotalAnswersLeftSelector } from 'selectors/bot/answers';
import useSelector from 'store/useSelector';

const useImportAnswerModal = ({
  onClose,
  setShowUploadModal,
  setParsedFileAnswers,
  setIsImportBtnDisabled,
  fileType,
}) => {
  const controller = new AbortController();
  const [state, dispatch] = useContext(Context);
  const {
    bot: { jid },
    sentinel,
    token,
    graph,
    plan,
    webSocket,
  } = state;
  const { plan_type, onboarding_flag } = plan;
  const answersAllowed = useSelector(getTotalAnswersLeftSelector);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [importErrorMsg, setImportErrorMsg] = useState(null);
  const [importWarnMsg, setImportWarnMsg] = useState(null);
  const [finalAnswerWarnMsg, setFinalAnswerWarnMsg] = useState(null);
  const [modalBtnLoading, setModalBtnLoading] = useState(false);
  const limitErrorMsg = `You can add only ${answersAllowed} Answers in ${plan_type.toUpperCase()} Plan. Upgrade your plan to add answers.`;
  const [version, setVersion] = useState(true);
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const handleAddFile = async () => {
    setModalBtnLoading(true);
    try {
      if (answersAllowed <= 0) {
        return message.error(limitErrorMsg);
      }

      const payload = {
        file: fileUploaded,
        ver: version ? 'final' : 'draft',
        fileType,
      };

      const response = await apiService.addFile(payload, token, jid, sentinel);

      if (response?.data?.is_queued !== true) {
        const filesResponse = response?.data?.report[0]?.success;
        setTimeout(() => {
          message.success(`Successfully added.`);
          setFileUploaded(null);
          resetStates();
          dispatch({
            type: ADD_FILE,
            payload: {
              response: filesResponse,
              type: 'file',
            },
          });
          handleCloseUploadModal(true, true);
        }, 3000);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setIsImportBtnDisabled(false);
        return message.error(error.message || 'Operation aborted by the user.');
      } else {
        setModalBtnLoading(false);
        setIsImportBtnDisabled(false);
        return message.error(
          error.message || `Something went wrong. File upload failed.`
        );
      }
    }
  };

  const handleUploadFile = file => {
    setFileUploaded(file);
    return false;
  };

  const handleRemoveFile = () => {
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setParsedFileAnswers([]);
    setIsImportBtnDisabled(true);
  };

  const resetStates = () => {
    setModalBtnLoading(false);
  };

  const resetModal = useCallback(() => {
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setFinalAnswerWarnMsg(null);
    setParsedFileAnswers([]);
    setShowUploadModal(false);
  }, [setParsedFileAnswers, setShowUploadModal]);

  const handleCloseUploadModal = useCallback(() => {
    onClose();
  }, [modalBtnLoading, onClose, resetModal, setShowUploadModal]);

  const handleCancelImport = useCallback(() => {
    controller.abort();
    resetStates();
    dispatch({
      type: RESET_WS_SCANNER,
    });
    return handleCloseUploadModal(true);
  }, [controller, handleCloseUploadModal]);

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if (STATUS.FINISHED === status || STATUS.SKIPPED === status) {
      setRunTour(false);
      setStepIndex(0);
      if (!onboarding_flag.includes('TextIngest')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'TextIngest'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'TextIngest' });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
    }
  };

  useEffect(() => {
    if (webSocket?.fileAdded?.length > 0) {
      setFileUploaded(null);
      resetStates();
      dispatch({
        type: RESET_WS_ADD_FILE_ADDED,
      });
      handleCloseUploadModal(true, true);
    }
  }, [webSocket?.fileAdded]);

  useEffect(() => {
    setTimeout(() => {
      setRunTour(true);
    }, 3000);
  }, [onboarding_flag]);

  return {
    importErrorMsg,
    importWarnMsg,
    finalAnswerWarnMsg,
    modalBtnLoading,
    handleCancelImport,
    handleAddFile,
    handleUploadFile,
    handleRemoveFile,
    setVersion,
    version,
    fileUploaded,
    webSocket,
    onClickCallback,
    runTour,
    stepIndex,
  };
};

export default useImportAnswerModal;
