import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from 'react-chat-widget';
import { Collapse } from 'antd';
import {
  DragOutlined,
  FormOutlined,
  HighlightOutlined,
  ToolOutlined,
} from '@ant-design/icons';

import Button from 'components/Button';
import CodeCopier from 'components/CodeCopier';
import useBotCustomizer from '../hooks';
import ChatWidgetLauncher from '../ChatWidgetLauncher';
import LauncherIconPickerModal from '../LauncherIconPickerModal';
import {
  StyledSimulatorWrapper,
  StyledIntegrationSteps,
  StyledCollapsiblePanel,
  StyledCollapsiblePanelHeader,
  StyledActionBtns,
  StyledChatWidgetV2Bot,
  StyledChatBot,
} from './CustomizeBot.style';
import PanelActionButtons from './PanelActionButtons';
import useSelector from 'store/useSelector';
import 'zsb-widget-v2';

import {
  currentIntegrationSettingsSettingPropSelector,
  shouldShowWidgetV1Selector,
  shouldShowWidgetV2Selector,
} from 'selectors/bot/integration';
import SearchCustomizer from './SearchCustomizer';
import WidgetVersion from './WidgetVersion';
import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';
import ConfigPanel from './AccordionPanels/ConfigPanel';
import AppearancePanel from './AccordionPanels/AppearancePanel';
import { BOT_PANEL_KEY_PREFIX } from 'constants/botCustomizer';
import PreviewImageModal from '../PreviewImageModal';
import PlacementAndShape from './AccordionPanels/PlacementAndShape';
import FeedbackAndHandOff from './AccordionPanels/FeedbackAndHandOff';

const CustomizeBot = ({
  handlePanelChange,
  onSave,
  onUpdate,
  setBotComponent,
}) => {
  // TODO: create separate hook for CustomizeBot component
  // to remove rerendering of <BotCustomizer />
  // that causes form fields to be empty
  const shouldShowWidgetV2 = useSelector(shouldShowWidgetV2Selector);
  const shouldShowWidgetV1 = useSelector(shouldShowWidgetV1Selector);
  const currentIntegrationSettingsObject = useSelector(
    currentIntegrationSettingsSettingPropSelector
  );

  const {
    demoLink,
    getButtonValue,
    handleChangeFields,
    handleNewUserMessage,
    handleSubmitCustomizeBot,
    isDemo,
    isMobileIntegration,
    loading,
    isAvatarPickerModalOpen,
    headerAvatar,
    launcherAvatar,
    widgetThemeColor,
    avatarPosition,
    encryptedBot,
    handleChangeCustomizerActivePanel,
    customizerActivePanel,
    defaultReplyBubbleColor,
  } = useBotCustomizer({
    onSave,
    onUpdate,
    setBotComponent,
  });

  const {
    botTitle,
    color,
    placeholder,
    subtitle,
    textColor,
    bubbleColor,
    replyBubbleGradient,
    fontSize,
    type,
    height,
    responseAvatarType,
    headerAvatarType,
  } = currentIntegrationSettingsObject;

  return (
    <StyledSimulatorWrapper gutter={[8, 8]}>
      <StyledIntegrationSteps
        xs={{ span: 23 }}
        md={{ span: 13 }}
        lg={{ span: 14 }}
        xl={{ span: 14 }}
      >
        <form onSubmit={handleSubmitCustomizeBot}>
          {isDemo && (
            <StyledFlexLeftColumn>
              <h2>
                <b>Bot Widget Styling </b>
              </h2>
              <WidgetVersion />
            </StyledFlexLeftColumn>
          )}
          {shouldShowWidgetV2 && type === 'search' ? (
            <SearchCustomizer onUpdate={onUpdate} />
          ) : (
            <>
              <Collapse
                activeKey={customizerActivePanel}
                accordion={true}
                onChange={handleChangeCustomizerActivePanel}
                expandIconPosition="end"
              >
                <StyledCollapsiblePanel
                  key={`${BOT_PANEL_KEY_PREFIX}1`}
                  header={
                    <StyledCollapsiblePanelHeader>
                      <div className="icon">
                        <ToolOutlined />
                      </div>
                      <span>
                        <span>Bot Panel Config</span>
                        <p>{`Set your bot’s name and default welcome message.`}</p>
                      </span>
                    </StyledCollapsiblePanelHeader>
                  }
                >
                  <ConfigPanel />
                </StyledCollapsiblePanel>
                <StyledCollapsiblePanel
                  header={
                    <StyledCollapsiblePanelHeader>
                      <div className="icon">
                        <HighlightOutlined />
                      </div>
                      <span>
                        <span>Bot Appearance</span>
                        <p>Customize your Bot’s look, feel and brand.</p>
                      </span>
                    </StyledCollapsiblePanelHeader>
                  }
                  key={`${BOT_PANEL_KEY_PREFIX}2`}
                  botAppearance={true}
                >
                  <AppearancePanel />
                  {isAvatarPickerModalOpen ? <LauncherIconPickerModal /> : null}
                </StyledCollapsiblePanel>
                <StyledCollapsiblePanel
                  hidden={isMobileIntegration}
                  header={
                    <StyledCollapsiblePanelHeader>
                      <div className="icon">
                        <DragOutlined />
                      </div>
                      <span>
                        <span>Bot Placement and Shape</span>
                        <p>
                          Find the right placement of your bot in your website.
                        </p>
                      </span>
                    </StyledCollapsiblePanelHeader>
                  }
                  key={`${BOT_PANEL_KEY_PREFIX}3`}
                >
                  <PlacementAndShape />
                </StyledCollapsiblePanel>
                {/* TODO: clean me */}
                <StyledCollapsiblePanel
                  header={
                    <StyledCollapsiblePanelHeader>
                      <div className="icon">
                        <FormOutlined />
                      </div>
                      <span>
                        <span>{'User Feedback/Hand-off'}</span>
                        <p>
                          {
                            'Add, edit, and customize for the user hand-off functionality.'
                          }
                        </p>
                      </span>
                    </StyledCollapsiblePanelHeader>
                  }
                  key={`${BOT_PANEL_KEY_PREFIX}4`}
                >
                  <FeedbackAndHandOff />
                  <PanelActionButtons
                    isHidden={false}
                    disabled={false}
                    isLastPanel
                    handleChangeFields={handleChangeFields}
                    onPanelChange={handlePanelChange}
                  />
                </StyledCollapsiblePanel>
              </Collapse>
              <StyledActionBtns>
                <Button
                  loading={loading}
                  value={getButtonValue()}
                  type="submit"
                  onClick={handleSubmitCustomizeBot}
                />
                <Button
                  disabled={loading}
                  variant="secondary"
                  value="Reset"
                  type="button"
                  onClick={() => handleChangeFields('clear')}
                />
              </StyledActionBtns>
              {isDemo && !!demoLink && demoLink.length && (
                <CodeCopier code={demoLink} />
              )}
            </>
          )}
        </form>
      </StyledIntegrationSteps>
      {shouldShowWidgetV1 ? (
        <StyledChatBot
          xs={{ span: 23 }}
          md={{ span: 9 }}
          lg={{ span: 9 }}
          xl={{ span: 9 }}
          bubbleColor={color}
          replyBubbleGradient={defaultReplyBubbleColor}
          color={color}
          textColor={textColor}
          fontSize={fontSize}
          height={`${height}px`}
          className="chatBot"
          style={{ position: 'relative' }}
        >
          <Widget
            launcher={() => <ChatWidgetLauncher />}
            title={<div dangerouslySetInnerHTML={{ __html: botTitle }} />}
            subtitle={<div dangerouslySetInnerHTML={{ __html: subtitle }} />}
            senderPlaceHolder={placeholder}
            handleNewUserMessage={e =>
              handleNewUserMessage(
                e,
                'ZSB Platform',
                bubbleColor || color,
                textColor,
                fontSize
              )
            }
            className="chatBotWidget"
          />
        </StyledChatBot>
      ) : shouldShowWidgetV2 ? (
        <StyledChatWidgetV2Bot
          xs={{ span: 23 }}
          md={{ span: 9 }}
          lg={{ span: 9 }}
          xl={{ span: 9 }}
        >
          <zeroshot-bot
            textColor={textColor}
            color={widgetThemeColor}
            avatarPosition={avatarPosition}
            title={botTitle}
            subtitle={subtitle}
            showCloseButton={true}
            autoOpen="true"
            hideWidgetMenu="true"
            disableClose="true"
            launcherAvatar={launcherAvatar}
            headerAvatar={headerAvatar}
            headerAvatarType={headerAvatarType}
            responseAvatar={headerAvatar}
            responseAvatarType={responseAvatarType}
            placeholder={placeholder}
            type={type}
            clientBubbleColor={bubbleColor}
            replyBubbleGradient={replyBubbleGradient}
            height={height}
            bot={encryptedBot}
          />
        </StyledChatWidgetV2Bot>
      ) : null}
      <PreviewImageModal />
    </StyledSimulatorWrapper>
  );
};

CustomizeBot.propTypes = {
  handlePanelChange: PropTypes.func,
  onSave: PropTypes.func,
  onUpdate: PropTypes.func,
  setBotComponent: PropTypes.func,
};

export default CustomizeBot;
