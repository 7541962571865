import { orderBy } from 'lodash';
import {
  ADD_MULTIPLE_DRAFT_QUESTIONS,
  ADD_QUESTION,
  DELETE_QUESTION,
  SET_ALL_QUESTIONS,
  SHOW_RESPONSE_PICKER_MODAL_FOR_QUESTIONS,
  UPDATE_QUESTION,
  UPDATE_QUESTION_AND_ANSWER,
} from 'store/action';
import { extractAnswerData } from '../helpers/bot/answers';
import {
  extractQuestionData,
  pushCurrentQuestionAsTopQuestion,
} from '../helpers/bot/questions';

export const questionsReducer = (state, action) => {
  switch (action.type) {
    case SET_ALL_QUESTIONS: {
      const data = action.payload;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const questionsWithAnswer = data.map(item => {
        return {
          question: extractQuestionData(item.question, item.score),
          answer: extractAnswerData(item.answer, isBotOpenAIEnabled),
        };
      });
      const sortedQuestionsWithAnswer = orderBy(
        questionsWithAnswer,
        ['question.lastEdited'],
        'desc'
      );
      return {
        ...state,
        bot: {
          ...state.bot,
          questions: sortedQuestionsWithAnswer,
        },
      };
    }

    case ADD_QUESTION: {
      const { answer, question } = action.payload;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const newQuestion = extractQuestionData(question);
      const newAnswer = extractAnswerData(answer, isBotOpenAIEnabled);
      const sortedQuestionsWithAnswer = orderBy(
        [{ question: newQuestion, answer: newAnswer }, ...state.bot.questions],
        ['question.lastEdited'],
        'desc'
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          questions: sortedQuestionsWithAnswer,
        },
      };
    }

    case ADD_MULTIPLE_DRAFT_QUESTIONS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          questions: orderBy(
            [...action.payload, ...state.bot.questions],
            ['question.lastEdited'],
            'desc'
          ),
        },
      };
    }

    case UPDATE_QUESTION: {
      const selectedQuestion = action.payload?.context
        ? extractQuestionData(action.payload)
        : action.payload;
      const updatedQuestions = state.bot.questions.map(item => {
        if (selectedQuestion.jid === item.question.jid) {
          return {
            ...item,
            question: selectedQuestion,
          };
        }
        return item;
      });
      return {
        ...state,
        bot: {
          ...state.bot,
          questions: orderBy(updatedQuestions, ['question.lastEdited'], 'desc'),
        },
      };
    }

    case UPDATE_QUESTION_AND_ANSWER: {
      const isBotOpenAIEnabled = state.bot?.useOpenAI;

      const selectedQuestion = action.payload?.question?.context
        ? extractQuestionData(action.payload?.question)
        : action.payload.question;
      const newAnswer = extractAnswerData(
        action.payload?.answer,
        isBotOpenAIEnabled
      );
      const updatedQuestions = state.bot.questions.map(item => {
        if (selectedQuestion.jid === item.question.jid) {
          return {
            answer: newAnswer,
            question: selectedQuestion,
          };
        }
        return item;
      });
      return {
        ...state,
        bot: {
          ...state.bot,
          questions: orderBy(updatedQuestions, ['question.lastEdited'], 'desc'),
        },
      };
    }

    case SHOW_RESPONSE_PICKER_MODAL_FOR_QUESTIONS: {
      const { questionId, questions, ...rest } = action.payload;
      const extractedQuestions = questions[0]?.score
        ? extractQuestionData(questions)
        : questions;
      const sortedQuestions = pushCurrentQuestionAsTopQuestion(
        extractedQuestions,
        questionId
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              responsePickerModal: {
                ...state.bot.ui.modals.responsePickerModal,
                ...rest,
                isOpen: true,
                answers: sortedQuestions,
              },
            },
          },
        },
      };
    }

    case DELETE_QUESTION: {
      const filteredQuestions = state.bot.questions.filter(
        item => item.question.jid !== action.payload
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          questions: filteredQuestions,
        },
      };
    }

    default:
      return state;
  }
};
