import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert, Spin } from 'antd';
import {
  StyledCodeButton,
  StyledActionBtns,
  StyledIntegrationSteps,
  StyledCollapsiblePanelHeader,
  StyledSnippets,
} from './Viber.styles';
import Input from 'components/Input';
import Select from 'components/Select';
import useViber from './hooks';
import { INTEGRATION_PLATFORMS } from 'constants/integrationPlatforms';
import Button from 'components/Button';

const Viber = ({ isPageReady }) => {
  const {
    currentIntegrationID,
    integrationData,
    loading,
    handleChangeFields,
    handleSaveChannelInfo,
    viberConfig,
    enableManualSetViberWebHook,
    handleManualSetWebHook,
    webHookErrorMsg,
  } = useViber();

  const getButtonValue = useMemo(() => {
    if (!!currentIntegrationID) {
      return 'Update';
    } else if (loading) {
      return 'Processing';
    }
    return 'Save';
  }, [currentIntegrationID, loading]);

  return (
    <Spin spinning={!isPageReady || loading} tip="Just a moment...">
      <div>
        <StyledCollapsiblePanelHeader>
          <span>Channel information</span>
        </StyledCollapsiblePanelHeader>
        <StyledIntegrationSteps>
          <StyledSnippets>
            <h1>{'Before you start 💡'}</h1>
            <ol>
              <li>{`Create a Viber account on a platform which supports bots (iOS/Android). This account will automatically be set as the account administrator during the account creation process.`}</li>
              <li>{`Go to this link https://partners.viber.com/. Note: an active Viber account is needed to receive the login code to access the bot creation page.`}</li>
              <li>{`On the left navigation click Create Bot Account.`}</li>
              <li>{`Fill up the form.`}</li>
              <li>{`Click Create button.`}</li>
              <li>{`Once your account is created your authentication token will appear in the account’s “edit info” screen (for admins only).`}</li>
              <li>{`Copy the token.`}</li>
              <li>{`Paste token in the Access Token field below.`}</li>
            </ol>
          </StyledSnippets>
          <form onSubmit={handleSaveChannelInfo}>
            <Input
              disabled={!!currentIntegrationID}
              value={integrationData.identifier}
              label="Channel Name"
              placeholder="My Viber integration name"
              onChange={e => handleChangeFields('identifier', e.target.value)}
              required
            />
            <Input
              disabled={!!currentIntegrationID}
              value={
                (integrationData && integrationData.settings.viber_token) || ''
              }
              label="Access Token"
              placeholder="My Viber access token"
              onChange={e => handleChangeFields('viber_token', e.target.value)}
              required
            />
            <Select
              disabled
              defaultValue={integrationData && integrationData.type}
              onChange={e => handleChangeFields('type', e)}
              label="Integration Platform"
              value={INTEGRATION_PLATFORMS['viber']}
            />
            {viberConfig && (
              <Select
                defaultValue={
                  (integrationData && integrationData.viber_version) ||
                  viberConfig?.filter(
                    version => version.status === 'current'
                  )[0].value
                }
                onChange={e => handleChangeFields('viber_version', e)}
                label="Viber Version"
                options={viberConfig}
              />
            )}{' '}
            {webHookErrorMsg && (
              <Alert type="warning" message={webHookErrorMsg} />
            )}
            <StyledActionBtns>
              <StyledCodeButton value={getButtonValue} type="submit" />
              {currentIntegrationID && enableManualSetViberWebHook && (
                <Button
                  variant="secondary"
                  value="Set Webhook"
                  type="button"
                  onClick={() => handleManualSetWebHook(currentIntegrationID)}
                />
              )}
            </StyledActionBtns>
          </form>
        </StyledIntegrationSteps>
      </div>
    </Spin>
  );
};

Viber.propTypes = {
  isPageReady: PropTypes.bool,
};

export default Viber;
