const ANSWER_BANK_JSON_REQUIRED_FIELDS = [
  {
    name: 'text',
    type: 'string',
  },
  {
    name: 'show_text',
    type: ['string', 'object'],
  },
];

const QUESTION_JSON_REQUIRED_FIELDS = [
  {
    name: 'text',
    type: 'string',
  },
];

export const validateAnswerBankJSONSchema = jsonData => {
  const missingFields = [];
  const invalidFields = [];

  for (const data of jsonData) {
    for (const field of ANSWER_BANK_JSON_REQUIRED_FIELDS) {
      if (!data.hasOwnProperty(field.name)) {
        if (!missingFields.includes(field.name)) {
          missingFields.push(field.name);
        }
      } else {
        if (field.type === 'array') {
          if (
            !Array.isArray(data[field.name]) &&
            !invalidFields.includes(field.name)
          ) {
            invalidFields.push(field.name);
          }
        } else if (!field.type.includes(typeof data[field.name])) {
          if (!invalidFields.includes(field.name)) {
            invalidFields.push(field.name);
          }
        }
      }
    }
  }

  if (missingFields.length) {
    return {
      status: 'missing_fields',
      data: missingFields,
    };
  }

  if (invalidFields.length) {
    return {
      status: 'invalid_fields',
      data: invalidFields,
    };
  }

  return {
    status: 'valid',
    data: {},
  };
};

export const validateQuestionJSONSchema = jsonData => {
  const missingFields = [];
  const invalidFields = [];

  for (const data of jsonData) {
    for (const field of QUESTION_JSON_REQUIRED_FIELDS) {
      if (!data.hasOwnProperty(field.name)) {
        if (!missingFields.includes(field.name)) {
          missingFields.push(field.name);
        }
      } else {
        if (field.type === 'array') {
          if (
            !Array.isArray(data[field.name]) &&
            !invalidFields.includes(field.name)
          ) {
            invalidFields.push(field.name);
          }
        } else if (!field.type.includes(typeof data[field.name])) {
          if (!invalidFields.includes(field.name)) {
            invalidFields.push(field.name);
          }
        }
      }
    }
  }

  if (missingFields.length) {
    return {
      status: 'missing_fields',
      data: missingFields,
    };
  }

  if (invalidFields.length) {
    return {
      status: 'invalid_fields',
      data: invalidFields,
    };
  }

  return {
    status: 'valid',
    data: {},
  };
};

const BOT_JSON_REQUIRED_FIELDS = [
  {
    name: 'name',
    type: 'string',
  },
];

export const validateBotJSONSchema = data => {
  const missingFields = [];
  const invalidFields = [];
  let answerBankValidation = null;

  for (const field of BOT_JSON_REQUIRED_FIELDS) {
    if (!data.hasOwnProperty(field.name)) {
      if (!missingFields.includes(field.name)) {
        missingFields.push(field.name);
      }
    } else {
      if (field.type === 'array') {
        if (!Array.isArray(data[field.name])) {
          if (!invalidFields.includes(field.name)) {
            invalidFields.push(field.name);
          }
        } else if (field.name === 'answerbank') {
          answerBankValidation = validateAnswerBankJSONSchema(data[field.name]);
        }
      } else if (!field.type.includes(typeof data[field.name])) {
        if (!invalidFields.includes(field.name)) {
          invalidFields.push(field.name);
        }
      }
    }
  }

  if (missingFields.length) {
    return {
      status: 'missing_fields',
      data: missingFields,
    };
  }

  if (invalidFields.length) {
    return {
      status: 'invalid_fields',
      data: invalidFields,
    };
  }

  if (answerBankValidation && answerBankValidation.status !== 'valid') {
    return {
      status: `answerbank_${answerBankValidation.status}`,
      data: answerBankValidation.data,
    };
  }

  return {
    status: 'valid',
    data: {},
  };
};
