import styled, { css } from 'styled-components';
import { MoreOutlined, LikeFilled, DislikeTwoTone } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
import { Col, Dropdown, Row } from 'antd';
import Button from 'components/Button';
import { cssVariables } from '../../../styles/root';
import Select from 'components/Select';

export const CardsWrapper = styled(Row)`
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // margin: 20px 0;
  // gap: 5px;
  // height: 180px;

  @media (max-width: 768px) {
    // height: 120px;
    // max-width: 94% !important;
    h1 {
      font-size: 30px !important;
    }
  }
`;

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  height: 200px;
  h4 {
    align-items: start;
    text-align: left;
    font-size: 14px;
    font-weight: ${cssVariables.font.bold};
    color: ${props => props.theme.gray0};
    text-transform: capitalize;
  }

  canvas {
    width: 100% !important;
  }
`;

export const StyledColumnChart = styled(Column)`
  margin: 0 10px;
  width: 100%;
`;

export const DataSummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${props => (props.modalCard ? '200' : '350')}px;
  width: 100%;
  background-color: #fff;
  border: 1px solid ${cssVariables.gray1};
  padding: 24px;
  height: ${props => (props.modalCard ? '110px' : '100%')};

  div:first-child {
    font-size: 1rem;
    text-transform: uppercase;
    color: ${cssVariables.gray0};
    font-weight: ${cssVariables.font.bold};
    text-align: center;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    ${props =>
      props.clickableFooter
        ? css`
            h1 {
              cursor: pointer;
            }
          `
        : ''}
  }

  h1 {
    font-size: ${props => (props.modalCard ? 24 : 42)}px;
    margin-bottom: 0;
  }

  span div {
    font-size: 14px !important;
  }

  @media (max-width: 768px) {
    padding: 10px;
    .anticon {
      font-size: 18px !important;
    }

    h1 {
      font-size: 20px !important;
    }

    div:first-child {
      font-size: 10px;
      text-transform: uppercase;
      color: ${cssVariables.gray0};
      font-weight: ${cssVariables.font.bold};
      text-align: center;
    }

    div:nth-child(3) {
      font-size: 10px !important;
    }
  }

  div:nth-child(3) {
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    text-align: center;
    padding-bottom: ${props => (props.noFooter ? '25px' : '15px')};
    color: ${props =>
      props.feedback === 'positive' || !props.feedback
        ? cssVariables.success
        : props.feedback === 'negative'
        ? cssVariables.red10
        : cssVariables.primaryBlue};

    ${props =>
      props.clickableFooter
        ? css`
            cursor: pointer;
            .anticon {
              font-size: 18px !important;
              padding: 0;
            }
            display: flex;
            justify-content: space-around;
            width: 100%;
          `
        : ``}
  }
`;

export const StyledDatePickerButton = styled(Button)`
  padding: 3px 12px;
  height: 30px;
  margin-left: 5px;
`;

export const StyledGhostDropdown = styled(Dropdown)`
  white-space: nowrap;
  border: 1px solid ${props => props.theme.gray2} !important;
  background-color: #fff;
  color: ${props => props.theme.primaryColor} !important;
  display: flex;
  justify-content: space-between;
  width: 25%;
  font-size: 14px;
`;

export const StyledFiltersSelect = styled(Select)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 14px;

  .ant-select {
    min-width: 200px;
    width: 100%;
  }
  label {
    color: ${cssVariables.gray0};
    width: 100%;
  }
`;

export const StyledFilterWrapper = styled(Row)`
  margin: 2% 0;
`;

export const StyledExpandedRow = styled.div`
  width: 100%;
  word-break: break-all;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }
`;

export const StyledRoot = styled.div`
  width: 100%;
`;

export const StyledMetric = styled.div`
  position: relative;
  margin-top: 16px;
`;

export const StyledMetricPeriodFilter = styled.div`
  display: flex;
  border: 1px solid #e5e8e9;
  border-radius: 3px;
  position: absolute;
  top: -56px;
  right: 0px;
`;

export const StyledDateFilterLabel = styled(Col)`
  font-size: 1rem;
  color: #33363c;
  margin-right: 8px;
`;

export const StyledQuestionsList = styled.div`
  margin-top: 12px;
`;

export const StyledQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > span:first-child {
    word-break: break-word;
  }
`;

export const StyledQuestions = styled.div`
  margin-top: 10px;
`;

export const StyledMore = styled(MoreOutlined)`
  transform: rotate(-90deg);
  padding: 10px;
  margin-left: 10px;
  color: #98a5ac;

  &:hover {
    background: ${cssVariables.gray4};
    border-radius: 50%;
  }
`;

export const StyledColumnClickable = styled.div`
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: #1667e7;
    text-decoration: underline;
  }
`;

export const StyledFeedbackThumbsUpIcon = styled(LikeFilled)`
  font-size: 20px;
  color: #e5e111;
`;

export const StyledFeedbackThumbsDownIcon = styled(DislikeTwoTone)`
  font-size: 20px;
`;

export const StyledShowAnswer = styled.div`
  margin-top: 10;
`;

export const RowSpaceBetween = styled(Row)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const DataSummaryCardBotQuality = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  width: 100%;
  background-color: #fff;
  border: 1px solid ${cssVariables.gray1};
  padding: 24px;
  height: 100%;

  h1 {
    font-size: 25px !important;
  }

  div:first-child {
    font-size: 12px;
    text-transform: uppercase;
    color: ${cssVariables.gray0};
    font-weight: ${cssVariables.font.bold};
    text-align: center;
    cursor: pointer;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
  }

  div:nth-child(3) {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding-top: 5px;
    cursor: pointer;
  }

  .anticon {
    font-size: 25px;
    margin-top: 10px;
    cursor: pointer;
  }
`;

export const KeyMetricsSummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  width: 100%;
  background-color: #fff;
  border: 1px solid ${cssVariables.gray1};
  padding: 24px;
  height: 100%;
  cursor: pointer;

  div:first-child {
    font-size: 12px;
    text-transform: uppercase;
    color: ${cssVariables.gray0};
    font-weight: ${cssVariables.font.bold};
    text-align: center;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }

  h1 {
    font-size: 42px;
    margin-bottom: 0;
  }

  span div {
    font-size: 14px !important;
  }

  @media (max-width: 768px) {
    padding: 10px;
    .anticon {
      font-size: 18px !important;
    }

    h1 {
      font-size: 20px !important;
    }

    div:first-child {
      font-size: 10px;
      text-transform: uppercase;
      color: ${cssVariables.gray0};
      font-weight: ${cssVariables.font.bold};
      text-align: center;
    }

    div:nth-child(3) {
      font-size: 10px !important;
    }
  }

  background-color: ${props => (props.active ? '#e6f7ff' : 'none')};
`;

export const KeyMetricsCardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  gap: 5px;
  height: 180px;

  @media (max-width: 768px) {
    height: 120px;
    max-width: 94% !important;
    h1 {
      font-size: 30px !important;
    }
  }

  .active {
    background-color: #e6f7ff;
    border: 1px solid ${cssVariables.primaryBlue};
  }
`;

export const StyledExpandedTableTitle = styled.h5`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.normal};
  font-size: 18px;
  line-height: 24px;
  color: #000;

  .anticon {
    font-size: 12px;
  }
`;

export const StyledSequenceContainer = styled.div`
  padding: 2px 0px;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #f0f2f5;
  }

  .ant-table-thead > tr > th {
    font-weight: 700 !important;
  }
`;
