import { has } from 'lodash';

import { getToken } from 'services/auth.service';
import {
  DISMISS_FIRST_LOGIN_PLAN,
  INIT_JAC,
  INIT,
  LOAD_APPLICATION,
  SHOW_FIRST_LOGIN_PLAN,
} from 'store/action';
import { initialPlanState } from 'store/initialState';

export const initReducer = (state, action) => {
  switch (action.type) {
    case INIT_JAC: {
      const token = getToken();
      const { plan, subscription, ...rest } = action.payload;
      const { features, ...restPlanProps } = plan || {};
      const featureFlags = features?.features || {};
      const { subscription: subscriptionData, ...restOfSubscriptionObj } =
        subscription || {};
      const isAuthenticated = !has(state.isAuthenticated) && !!token;
      const lastPaymentError = subscriptionData?.last_payment_error
        ? {
            ...state.lastPaymentError,
            type: subscriptionData?.last_payment_error?.error?.type,
            code: subscriptionData?.last_payment_error?.error?.code,
            cardLastDigit:
              subscriptionData?.last_payment_error?.error?.payment_method?.card
                ?.last4,
            cardBrand:
              subscriptionData?.last_payment_error?.error?.payment_method?.card
                ?.brand,
          }
        : null;
      const isExternalPage = Boolean(localStorage.getItem('external'));

      return {
        ...state,
        isAuthenticated,
        featureFlags,
        plan: restPlanProps || initialPlanState,
        isExternalPage,
        token,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            detailsPageReady: true,
          },
        },
        subscription: {
          ...state.subscription,
          id: subscriptionData?.id,
          trial: subscriptionData?.trial,
          isProcessing: subscriptionData?.is_processing,
          ...restOfSubscriptionObj,
          ...rest,
        },
        lastPaymentError,
        version: features.version,
        layout: {
          ...state.layout,
          initialized: true,
        },
        apiGatewayConfig: plan.apigateway_config || null,
      };
    }

    case INIT:
      return {
        ...state,
        plan: {
          ...state.plan,
          ...action.payload,
        },
        featureFlags: {
          ...state.featureFlags,
          ...action.payload.features.features,
        },
        version: action.payload.features.version,
      };

    case SHOW_FIRST_LOGIN_PLAN:
      return {
        ...state,
        plan: {
          ...state.plan,
          showFirstLoginPlan: true,
        },
      };

    case DISMISS_FIRST_LOGIN_PLAN:
      return {
        ...state,
        plan: {
          ...state.plan,
          showFirstLoginPlan: false,
        },
      };

    case LOAD_APPLICATION: {
      const {
        graph,
        sentinel,
        pubAskedQuestion,
        messengerCallback,
        pubInfo,
        ...rest
      } = action.payload;
      const token = getToken();
      const isAuthenticated = !has(state.isAuthenticated) && !!token;
      const isExternalPage = Boolean(localStorage.getItem('external'));

      return {
        ...state,
        graph,
        sentinel,
        pubAskedQuestion,
        messengerCallback,
        token,
        isAuthenticated,
        isExternalPage,
        pubInfo,
        ...rest,
      };
    }

    default:
      return state;
  }
};
