import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Spin } from 'antd';

import Input from 'components/Input';
import useSignIn from './hooks';
import Alert from 'components/Alert';
import Button from 'components/Button';
import ROUTES from 'constants/routes';
import LowResoPrompt from 'components/Modals/LowResoPrompt';
import { MINIMUM_SCREEN_WIDTH } from 'constants/screens';
import {
  StyledForm,
  StyledSignInGoogleContainer,
  StyledSwitchLinkContainer,
} from './SignIn.style';

const SignIn = () => {
  const {
    email,
    error,
    isRedirected,
    loading,
    signInToGoogle,
    onChangeEmail,
    onChangePassword,
    onSubmit,
    successAction,
    redirectToVerification,
    requestGoogleAccess,
    spinnerLabel,
    isExternalPage,
  } = useSignIn();

  const isNotVerified =
    error &&
    error.non_field_errors &&
    error.non_field_errors[0].includes('not activated');

  const REGISTRATION_SUCCESS_MESSAGE = `Your account has been successfully ${
    !isNotVerified ? 'verified' : 'created'
  }. Please enter your login credentials.`;
  const UPDATE_PASSWORD_SUCCESS_MESSAGE = 'Account is updated successfully.';

  // if (window.innerWidth < MINIMUM_SCREEN_WIDTH && !isExternalPage) {
  //   return <LowResoPrompt />;
  // }

  return (
    <Spin spinning={loading || signInToGoogle} tip={spinnerLabel}>
      <StyledForm onSubmit={onSubmit}>
        {error && (
          <Alert
            type="error"
            message={error}
            hasActionButton
            actionButtonProps={
              error.includes('User not activated')
                ? {
                    value: 'Verify here',
                    onClick: () => redirectToVerification(),
                    adaptButtonColor: true,
                  }
                : {}
            }
          />
        )}
        {isRedirected && !error && (
          <Alert
            type="success"
            message={
              successAction === 'update'
                ? UPDATE_PASSWORD_SUCCESS_MESSAGE
                : REGISTRATION_SUCCESS_MESSAGE
            }
          />
        )}
        <Input
          full
          required
          label="Email"
          type="email"
          onChange={onChangeEmail}
          value={email}
          defaultValue=""
        />
        <Input
          full
          required
          label="Password"
          type="password"
          onChange={onChangePassword}
        />
        <Button
          type="submit"
          loading={loading}
          value="Sign In"
          variant="cyan"
          style={{ borderRadius: 50 }}
        />
      </StyledForm>
      <StyledSwitchLinkContainer>
        <Col xs={{ span: 20 }} md={{ span: 17 }}>
          <span className="no-account">{`Don't have an account`}? </span>
          <Link to={ROUTES.SIGNUP} className="sign-up-link">
            {' '}
            {`Sign Up`}
          </Link>
        </Col>
        <Col xs={{ span: 20 }} md={{ span: 6 }}>
          <Link to={ROUTES.RESET_PASSWORD}>{'Forgot Password'}?</Link>
        </Col>
      </StyledSwitchLinkContainer>
      <StyledSignInGoogleContainer onClick={requestGoogleAccess}>
        {'Sign in with Google'}
      </StyledSignInGoogleContainer>
    </Spin>
  );
};

export default SignIn;
