export const BOT_SIDEBAR_MENU = [
  {
    content: (
      <div className="joyride-content">
        <h3
          style={{
            display: 'inline-block',
          }}
        >
          Bot Menu - Overview
        </h3>
        <p>
          This is your Bot Menu where you can find the features and
          functionalities that you need to completely setup and integrate your
          bot. The Bot Overview will show you the Total no. of Answers in your
          Answer Bank, Total Questions Asked (Last 7 days), Fallback Answer
          Called (Last 7 days), Total Live Integrations, Total Test Suites, and
          Visitors (Last 7 days).
        </p>
        <p>
          Click on the link below the tile card to get the details on your Bot.
          Click on Answer Bank in the Bot menu to add answers in your Bot. After
          setting up your Answer Bank, you can test your answers by clicking on
          Automation Testing in the Bot menu. Once your ready to deploy your
          Bot, click on Integration in the menu, then select your desired
          integration channel (Web, Mobile, Viber, Facebook, Sandbox).
        </p>
        <p>
          Click on Analytics to view a summary of your Bot response and
          performance.
        </p>
        <p>
          Click on Bot Settings to update your Bot's general settings, response,
          and set the access and permission.
        </p>
      </div>
    ),
    target: '.bot-menu-sidebar',
    disableBeacon: true,
    hideFooter: true,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];
