import { getPlatform } from 'services/bots.service';
import { cssVariables } from 'styles/root';
import { MESSENGER_API_VERSION } from './integration';

const platform = getPlatform();

export const DEFAULT_WIDGET_WELCOME_MESSAGE = `Hi there! Ask me a question and I'll find the answer for you.`;

const DEMO_FIELDS_PLACEHOLDER = {
  maxUnlike: 3,
  welcomeMessage: DEFAULT_WIDGET_WELCOME_MESSAGE,
  cancelledFormMessage: `You've cancelled the form. Anything else I can help you with?`,
  submittedFormMessage: `Thank you, your information has been submitted. Next available agent will connect with you shortly.`,
  handoffLabel: `Request Agent Call Back`,
  formFields: [
    {
      label: 'Name',
      attribute: 'name',
      mandatory: false,
    },
    {
      label: 'Email',
      attribute: 'email',
      mandatory: true,
    },
    {
      label: 'Phone Number',
      attribute: 'phoneNumber',
      mandatory: false,
    },
  ],
  formHeader: 'Please share your details so we can reach out to you.',
};
export const DEFAULT_WIDGET_HEIGHT = '410px';
export const DEFAULT_WIDGET_HEIGHT_WITHOUT_PX = 410;
export const DEFAULT_BG = 'dummy_bg.jpg';
export const DEFAULT_BG_LIGHT = 'dummy_bg_light.jpg';

export const DEFAULT_MAX_INTERACTION = 3;
export const DEFAULT_REPLY_BUBBLE_GRADIENT = 0.15;
export const DEFAULT_WIDGET_VERSION = 'v1';
export const INTEGRATION_SETTING_PROP_TO_EXCLUDE = [
  'initialSettings',
  'customizerActivePanel',
];

export const BOT_PANEL_KEY_PREFIX = 'bot-customizer-panel-';

const INITIAL_BOT_INTEGRATION_DATA = {
  identifier: '',
  type: '',
  path: '',
  settings: {
    fontSize: null,
    color: cssVariables.primaryColor,
    height: DEFAULT_WIDGET_HEIGHT,
    placeholder: 'Type your query here',
    subtitle: 'Come and Test me',
    textColor: '#fff',
    headerAvatarType: 'none',
    launcherAvatar: 'default',
    headerAvatar: null,
    responseAvatar: null,
    responseAvatarType: 'none',
    launcherAvatarType: 'icon',
    iconColor: '#E9E5E5',
    position: 'bottom-right',
    shape: 'circle',
    label: `Ask bot`,
    bubbleColor: cssVariables.primaryColor,
    bubbleGradient: DEFAULT_REPLY_BUBBLE_GRADIENT,
    autoOpen: true,
    showCloseButton: true,
    authenticatedUser: false,
    callbackEmail: !!platform && platform.includes('demo') ? false : true,
    background: 'dark',
    displayFormFields: true,
    fb_access_token: null,
    widgetVersion: DEFAULT_WIDGET_VERSION,
    fb_api_version: MESSENGER_API_VERSION,
    ...DEMO_FIELDS_PLACEHOLDER,
  },
};

export const LAUNCHER_ONLY_AVATAR = ['default', 'icon1'];

export const AVATAR_ICON_OPTIONS = [
  'default',
  'icon1',
  'icon2',
  'icon3',
  'icon4',
];

export { DEMO_FIELDS_PLACEHOLDER, INITIAL_BOT_INTEGRATION_DATA };
