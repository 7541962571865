export const IS_PROCESSING_SUBSCRIPTION =
  'Your new subscription is still in process.';

export const AUTO_RENEW_SUCCESS_MESSAGE =
  'You have successfully renew your current subscription!';

export const UPGRADE_SUCCESS_MESSAGE =
  'You have successfully upgraded your current subscription!';

export const DOWNGRADE_SUCCESS_MESSAGE =
  'You have successfully downgraded your current subscription!';

export const CANCELED_CHECKOUT_URL_PARAM = '?checkout-success=false';

export const START_TRIAL_DAYS_MESSAGE = 'Start with 14 day trial';

export const PRICE_PER_QUERY = `$${process.env.REACT_APP_PRICE_PER_QUERY}`;

export const INTEGRATION_PLAN_LIMIT = 'integration';

export const PLAN_LIST = ['free', 'basic', 'advanced'];
