import { createSelector } from 'reselect';
import { sumBy, flattenDeep, isEmpty, orderBy } from 'lodash';

import {
  getBotModalsStateSelector,
  currentBotSelector,
  selectedCategorySelector,
} from '.';
import { DEFAULT_ANSWER_THRESHOLD } from 'constants/bot';
import {
  initialAnalyticsAggregationsState,
  initialWebsiteSelectedPage,
} from 'store/initialState';

export const allAnswersSelector = createSelector(currentBotSelector, bot =>
  orderBy(
    [...bot.answers, ...bot.files, ...bot.websites] || [],
    'lastEdited',
    'desc'
  )
);

export const answersSelector = createSelector(currentBotSelector, bot =>
  orderBy(bot.answers || [], 'lastEdited', 'desc')
);

export const filesSelector = createSelector(currentBotSelector, bot =>
  orderBy(bot.files || [], 'lastEdited', 'desc')
);

export const websitesSelector = createSelector(currentBotSelector, bot =>
  orderBy(bot.websites || [], 'lastEdited', 'desc')
);

export const topDefaultAnswerSelector = createSelector(
  currentBotSelector,
  bot => bot.defaultAnswer[0] || {}
);

export const allDefaultAnswersSelector = createSelector(
  currentBotSelector,
  bot => bot.defaultAnswer || []
);

export const defaultAnswerThresholdSelector = createSelector(
  topDefaultAnswerSelector,
  defaultAnswer => defaultAnswer.score || DEFAULT_ANSWER_THRESHOLD
);

export const botAggregationsSelector = createSelector(
  currentBotSelector,
  bot => bot.aggregations || initialAnalyticsAggregationsState
);

export const answerEditorContentsSelector = createSelector(
  getBotModalsStateSelector,
  modalState => modalState.answerEditorModal
);

export const fileEditorContentsSelector = createSelector(
  getBotModalsStateSelector,
  modalState => modalState.fileEditorModal
);

export const websiteEditorContentsSelector = createSelector(
  getBotModalsStateSelector,
  modalState => modalState.websiteEditorModal
);

export const answerEditorAnswerSelector = createSelector(
  answerEditorContentsSelector,
  answerEditorModal => answerEditorModal?.answerTab
);

export const fileEditorAnswerSelector = createSelector(
  fileEditorContentsSelector,
  fileEditorModal => fileEditorModal?.file
);

export const websiteEditorAnswerSelector = createSelector(
  websiteEditorContentsSelector,
  websiteEditorModal => websiteEditorModal?.website
);

export const answerEditorSimilarAnswerSelector = createSelector(
  answerEditorAnswerSelector,
  answerEditorAnswer => answerEditorAnswer?.similarAnswers || []
);

export const isAnswerEditorOpenSelector = createSelector(
  answerEditorContentsSelector,
  answerEditorModal => answerEditorModal?.isOpen
);

export const isFileEditorOpenSelector = createSelector(
  fileEditorContentsSelector,
  fileEditorModal => fileEditorModal?.isOpen
);

export const isWebsiteEditorOpenSelector = createSelector(
  websiteEditorContentsSelector,
  websiteEditorModal => websiteEditorModal?.isOpen
);

export const answerEditorQuestionsSelector = createSelector(
  answerEditorContentsSelector,
  answerEditorModal => answerEditorModal?.questionsTab
);

export const answerEditorInsightsSelector = createSelector(
  answerEditorContentsSelector,
  answerEditorModal => answerEditorModal?.insightsTab || {}
);

export const isCreateModeAnswerEditorSelector = createSelector(
  answerEditorContentsSelector,
  answerEditorModal => answerEditorModal?.createMode
);

export const getMaxAnswerCountSelector = state => state.plan.max_ans_count;

export const isCategoryModalOpenSelector = createSelector(
  selectedCategorySelector,
  selectedCategory => selectedCategory?.isOpen
);

export const isMaxAnswersSelector = createSelector(
  getMaxAnswerCountSelector,
  answersSelector,
  (maxAnswerCount, answers) => maxAnswerCount < answers?.length + 1
);

export const getTotalAnswersLeftSelector = createSelector(
  getMaxAnswerCountSelector,
  allAnswersSelector,
  (maxAnswerCount, answers) => maxAnswerCount - answers.length
);

export const totalAnswersSelectedFromCategoryTemplatesSelector = createSelector(
  selectedCategorySelector,
  selectedCategory => {
    if (selectedCategory.isTemplate && !isEmpty(selectedCategory.templates)) {
      return sumBy(
        flattenDeep(selectedCategory.templates),
        templates => templates.answers.length
      );
    }

    return 0;
  }
);

export const isTotalAnswersSelectedFromCategoryTemplatesReachMaxAnswerCountSelector =
  createSelector(
    totalAnswersSelectedFromCategoryTemplatesSelector,
    getTotalAnswersLeftSelector,
    (totalAnswersSelectedFromCategoryTemplates, totalAnswersLeft) =>
      totalAnswersSelectedFromCategoryTemplates > totalAnswersLeft
        ? true
        : false
  );

export const getSelectedAnswerSimilarAnswersSelector = createSelector(
  answerEditorContentsSelector,
  selectedAnswer => selectedAnswer?.similarAnswers || []
);

export const websiteSelectedPageselector = createSelector(
  currentBotSelector,
  bot => bot.websiteSelectedPages || initialWebsiteSelectedPage
);
