import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import ROUTES from 'constants/routes';
import Layout from 'components/Layout';
import MyAccount from './MyAccount';
import UserSidebar from 'components/Sidebar/UserSidebar';
import { StyledFlexRowCenter } from 'styles/GenericStyledComponents';
import ActivityLogs from 'components/ActivityLogs';
import OnboardingFlags from './OnboardingFlags';
import PlanAndPayments from './PlanAndPayments';
import Pricing from './Subscription/Pricing';
import useSelector from 'store/useSelector';
import {
  showCancelSubscriptionModalSelector,
  showSwitchPlanSubscriptionModalSelector,
} from 'selectors/subscription';
import CancelSubscriptionModal from 'components/Modals/CancelSubscriptionModal';
import SwitchPlanSubscriptionModal from 'components/Modals/SwitchPlanSubscriptionModal';
import ApiKey from './ApiKey';

const UserProfile = () => {
  const showCancelSubscriptionModal = useSelector(
    showCancelSubscriptionModalSelector
  );
  const showSwitchPlanSubscriptionModal = useSelector(
    showSwitchPlanSubscriptionModalSelector
  );
  const { pathname } = useLocation();

  const getContent = useMemo(() => {
    switch (pathname) {
      case ROUTES.USER_PROFILE:
        return <MyAccount />;

      case ROUTES.ACTIVITY_LOGS:
        return <ActivityLogs />;

      case ROUTES.ONBOARDING_FLAGS:
        return <OnboardingFlags />;

      case ROUTES.PLAN_AND_PAYMENTS:
        return <PlanAndPayments />;

      case ROUTES.SUBSCRIPTION:
        return <Pricing />;

      case ROUTES.API_KEY:
        return <ApiKey />;

      default:
        return <MyAccount />;
    }
  }, [pathname]);

  return (
    <StyledFlexRowCenter>
      <Layout SidebarComponent={() => <UserSidebar />}>{getContent}</Layout>
      {showCancelSubscriptionModal ? <CancelSubscriptionModal /> : null}
      {showSwitchPlanSubscriptionModal ? <SwitchPlanSubscriptionModal /> : null}
    </StyledFlexRowCenter>
  );
};

export default UserProfile;
