import Users from './Users';
import ROUTES from 'constants/routes';
import GlobalVars from './GlobalVars';
import ActivityLogs from 'components/ActivityLogs';
import Versions from './Versions';
import {
  CopyOutlined,
  FieldTimeOutlined,
  GlobalOutlined,
  UserOutlined,
} from '@ant-design/icons';

export const SidebarRoutes = [
  {
    name: 'Users List',
    path: ROUTES.ADMIN_USERS,
    component: Users,
    key: 'admin_users',
    icon: <UserOutlined />,
  },
  {
    name: 'Global Vars',
    path: ROUTES.ADMIN_GLOBAL_VARS,
    component: GlobalVars,
    key: 'global_vars',
    icon: <GlobalOutlined />,
  },
  {
    name: 'All Users Activity Logs',
    path: ROUTES.ADMIN_ACTIVITY_LOGS,
    component: ActivityLogs,
    key: 'activity_logs',
    icon: <FieldTimeOutlined />,
  },
  {
    name: 'Version Management',
    path: ROUTES.ADMIN_VERSIONS,
    component: Versions,
    key: 'versions',
    icon: <CopyOutlined />,
  },
];
