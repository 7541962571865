import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledStepAction = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
`;

export const StyledStepContent = styled.div`
  margin-top: 24px;
`;

export const StyledTestSuite = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.disabled ? props.theme.gray4 : '#fff')};
  color: ${props =>
    props.disabled ? props.theme.gray1 : props.theme.primaryColor};
  border: 1px solid #e8f0fd;
  border-radius: 5px;
  padding: 24px;
  margin-bottom: 8px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  :hover {
    border: 1px solid ${props => props.theme.blue2};
  }

  .test-suite-name {
    width: 70%;
    font-size: 16px;
  }

  .anticon-check-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.success};
    font-size: 20px;
    margin-right: -12px;
  }
`;

export const StyledTestSuites = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTestSuitesList = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
`;

export const StyledNoTestSuites = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: #000;
  text-align: center;
  padding: 20px 0;
`;

export const StyledLabel = styled.h4`
  font-size: 14px;
  font-weight: ${cssVariables.font.normal};
  line-height: 20px;
`;

export const StyledQuestion = styled.div`
  display: flex;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: ${cssVariables.font.normal};
`;

export const StyledAnswerList = styled.div`
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 15px;
`;
