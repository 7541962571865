import { useState, useEffect, useContext, useRef } from 'react';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';

import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import {
  CLEAR_CHAT_LIST,
  CLOSE_SIMILAR_QUESTIONS_MODAL,
  PUSH_CHAT_ANSWER,
  PUSH_CHAT_QUESTION,
  SET_ANSWER_EDITOR_SELECTED_ANSWER,
  SET_BOT_SETTINGS,
  SET_CHAT_MODE,
  SET_ONBOARDING_FLAG,
  SET_TYPING_EXPERIENCE,
  SET_WS_ASK_QUESTION_SENDING,
  SHOW_CHAT_ANSWER_IN_MODAL,
  SHOW_CHAT_SELECTED_SEQUENCE_IN_MODAL,
  SHOW_QUESTION_EDITOR,
  SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD,
} from 'store/action';
import { getCurrentBrowser, isM1Chip } from 'utils';
import {
  chatAnswerInModalSelector,
  isChatOnDevModeSelector,
  isSidebarChatExpandedSelector,
  similarQuestionsModalSelector,
} from 'selectors/bot/ui';
import { defaultAnswerThresholdSelector } from 'selectors/bot/answers';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import useSelector from 'store/useSelector';
import {
  botModeSelector,
  isAutoTranslateResponseSelector,
  isBotOpenAIEnabledSelector,
  isBotTypingExperienceEnabledSelector,
  shouldShowTestChatUseContextSelector,
} from 'selectors/bot';
import { chatListSelector } from 'selectors/bot/chatList';

const useSidebarChat = ({
  handleChatAskQuestion,
  setContextHistory,
  maxInteraction,
  setMaxInteraction,
  contextHistory,
}) => {
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  // const SpeechGrammarList =
  //   window.SpeechGrammarList || window.webkitSpeechGrammarList;
  // const SpeechRecognitionEvent =
  //   window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

  const [state, dispatch] = useContext(Context);
  const similarQuestionsModal = useSelector(similarQuestionsModalSelector);
  const isAutoTranslateResponse = useSelector(isAutoTranslateResponseSelector);
  const isBotTypingExperienceEnabled = useSelector(
    isBotTypingExperienceEnabledSelector
  );
  const isBotOpenAIEnabled = useSelector(isBotOpenAIEnabledSelector);
  const chatList = useSelector(chatListSelector);
  const isChatOnDevMode = useSelector(isChatOnDevModeSelector);
  const isSidebarChatExpanded = useSelector(isSidebarChatExpandedSelector);
  const chatAnswerInModal = useSelector(chatAnswerInModalSelector);
  const defaultAnswerThreshold = useSelector(defaultAnswerThresholdSelector);
  const botMode = useSelector(botModeSelector);
  const shouldShowTestChatUseContext = useSelector(
    shouldShowTestChatUseContextSelector
  );

  const {
    sentinel,
    token,
    graph,
    plan: { onboarding_flag },
    bot: { name, jid },
    featureFlags,
    webSocket,
  } = state;
  const { pathname } = useLocation();
  const [chatQuestion, setChatQuestion] = useState(null);
  const [isSpeechActivated, setSpeechActivated] = useState(false);
  const [flipAnswer, setflipAnswer] = useState([]);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listening, setListening] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [runTourDefaultAnswer, setRunTourDefaultAnswer] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [stepIndexDefaultAnswer, setStepIndexDefaultAnswer] = useState(0);
  const lastChatAnswer = chatList?.length
    ? chatList[chatList?.length - 1].answer
    : null;
  const [isQuickButtonsVisible, setIsQuickButtonsVisible] = useState(false);
  const [isIncludeDraftAnswers, setIncludeDraftAnswers] = useState(false);
  const [isChatMoreMenuOpen, setChatMoreMenuOpen] = useState(false);
  const [translateLanguage, setTranslateLanguage] = useState(
    isAutoTranslateResponse
  );

  const [typingExperience, setTypingExperience] = useState(
    isBotTypingExperienceEnabled
  );

  const bottomChatAreaRef = useRef(null);

  const excludedBrowsers = ['Opera', 'Firefox'];
  const isNotAnswerBank = !pathname.includes(`answer-bank`) ? true : false;
  // Should allow on google chrome browsers
  // OR
  // Should allow on all browser except browsers inside `excludedBrowsers`
  // AND
  // Should not be on mac m1 chips
  const isSpeechAllowed =
    getCurrentBrowser().includes('Chrome') ||
    (!excludedBrowsers.includes(getCurrentBrowser()) && !isM1Chip());
  const synth = window.speechSynthesis;
  const voices = synth?.getVoices();

  useEffect(() => {
    if (chatList !== null && chatList.length) {
      scrollToEnd();
    }

    if (
      (lastChatAnswer &&
        !!lastChatAnswer.quickReply &&
        lastChatAnswer.quickReplyOptions &&
        lastChatAnswer.quickReplyOptions.length) ||
      (lastChatAnswer && lastChatAnswer.requestAgent)
    ) {
      setIsQuickButtonsVisible(true);
    } else {
      setIsQuickButtonsVisible(false);
    }
  }, [chatList, isQuickButtonsVisible]);

  useEffect(() => {
    scrollToEnd();
  }, [webSocket]);

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_CHAT_MODE,
        payload: false,
      });
    };
  }, []);

  const handleFlipAnswer = index => {
    if (flipAnswer.includes(index)) {
      const flippedIndices = flipAnswer.filter(
        answerIndex => answerIndex !== index
      );
      setflipAnswer(flippedIndices);
    } else {
      setflipAnswer([...flipAnswer, index]);
    }
  };

  const scrollToEnd = () => {
    if (bottomChatAreaRef.current) {
      bottomChatAreaRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    const answersList = chatList || [];
    const isDefaultAnswer = answersList.some(
      list => list?.answer?.type === 'default_answer'
    );

    if (isDefaultAnswer && !onboarding_flag.includes('TestDefaultAnswer')) {
      setTimeout(() => setRunTourDefaultAnswer(true), 2000);
    } else {
      setRunTourDefaultAnswer(false);
    }
  }, [chatList, onboarding_flag]);

  useEffect(() => {
    const notDefaultAnswerList = chatList?.filter(
      chat => chat.answerContext?.name !== 'default_answer'
    );

    if (
      notDefaultAnswerList?.length === 1 &&
      !onboarding_flag.includes('TestChatAnswer')
    ) {
      setTimeout(() => setRunTour(true), 2000);
    } else {
      setRunTour(false);
    }
  }, [chatList?.length, onboarding_flag]);

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      ACTIONS.CLOSE === action
    ) {
      setRunTour(false);
      setStepIndex(0);
      if (!onboarding_flag.includes('TestChatAnswer')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'TestChatAnswer'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'TestChatAnswer' });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
      if (index === 0) {
      }
    }
  };

  const onDefaultAnswerClickCallback = async data => {
    const { action, index, type, status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTourDefaultAnswer(false);
      setStepIndexDefaultAnswer(0);
      if (!onboarding_flag.includes('TestDefaultAnswer')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'TestDefaultAnswer'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'TestDefaultAnswer' });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndexDefaultAnswer(stepIndex);
      if (index === 0) {
      }
    }
  };

  const handleChange = e => {
    if (sending || webSocket?.sending) {
      return false;
    }
    setChatQuestion(e.target.value);
  };

  const handleSubmit = async (evt, channel) => {
    evt.preventDefault();
    setChatQuestion(null);
    if (sending || webSocket?.sending) {
      return false;
    }
    await dispatch({
      type: SET_WS_ASK_QUESTION_SENDING,
    });

    try {
      await handleChatAskQuestion(chatQuestion, channel, isIncludeDraftAnswers);
    } catch (error) {}
    setChatQuestion(null);
  };

  const handleLinkAnswer = async (chatItem, idx) => {
    setLoading(true);
    try {
      await openQuestionEditor(chatItem.question, chatItem.answer, idx);
    } catch (error) {}
    setLoading(false);
  };

  const getStoredInteractions = () => {
    const interactionList = JSON.parse(localStorage.getItem('interactions'));
    if (interactionList?.length > maxInteraction * 2) {
      interactionList.splice(0, 2);
    }
    return contextHistory ? interactionList : [];
  };

  const handleQuickReplyAnswer = async (
    chatAnswer,
    chatQuestion,
    channel,
    includeDraftAnswers
  ) => {
    const questionPayload = {
      text: { ...chatQuestion, question: chatQuestion.label },
      metadata: { channel },
      use_draft: includeDraftAnswers,
      history: getStoredInteractions(),
    };

    await dispatch({
      type: PUSH_CHAT_QUESTION,
      payload: questionPayload,
    });

    // Add 1 second delay for answer
    setSending(true);
    setTimeout(() => {
      dispatch({
        type: PUSH_CHAT_ANSWER,
        payload: chatAnswer,
      });
      setSending(false);
    }, 1000);
  };

  const handleSendQuickReply = async quickReply => {
    setChatQuestion(quickReply.question);
    setSending(true);
    if (typeof quickReply.answer === 'object') {
      handleQuickReplyAnswer(
        quickReply.answer,
        quickReply,
        'ZSB Platform',
        isIncludeDraftAnswers
      );
    } else {
      await handleChatAskQuestion(
        quickReply,
        'ZSB Platform',
        isIncludeDraftAnswers
      );
    }

    setChatQuestion(null);
    setSending(false);
  };

  const openResponseModalPicker = async (text, answer, idx) => {
    setSending(true);
    if (
      text === 'string' &&
      !text.length &&
      !answer &&
      !Object.keys(similarQuestionsModal?.itemToChange)?.length
    ) {
      return false;
    }
    const question =
      typeof text === 'string' && text.length
        ? text
        : similarQuestionsModal.itemToChange?.question;
    const responsePickerSelectedAnswer =
      typeof answer === 'object' && Object.keys(answer).length
        ? answer
        : similarQuestionsModal.itemToChange?.answer;

    const title = 'Hard Link Question to Answer';
    const res = await apiService.getQuestionMatchesWithAnswer(
      question,
      null,
      sentinel,
      jid,
      token
    );

    if (!res.data?.report[0]) {
      setSending(false);
      return message.error(DEFAULT_ERROR_MESSAGE);
    }

    await dispatch({
      type: CLOSE_SIMILAR_QUESTIONS_MODAL,
    });
    await dispatch({
      type: SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD,
      payload: {
        answers: res.data.report[0],
        botName: name,
        withQuestion: true,
        action: similarQuestionsModal.action || 'edit',
        title,
        isHardLinked: responsePickerSelectedAnswer?.isHardLinked,
        answerId: responsePickerSelectedAnswer?.jid,
        itemToChange: {
          question: {
            text: question,
            jid: answer?.questionId,
          },
          answer: responsePickerSelectedAnswer,
          idx: idx || similarQuestionsModal?.itemToChange?.idx || 0,
        },
      },
    });
    setSending(false);
  };

  const openQuestionEditor = async (text, answer, idx) => {
    const res = await apiService.getSimilarQuestions(
      text,
      sentinel,
      jid,
      token
    );

    if (!res.data?.report[0]) {
      setSending(false);
      return message.error(DEFAULT_ERROR_MESSAGE);
    }
    const similarQuestions = res.data.report[0];

    dispatch({
      type: SHOW_QUESTION_EDITOR,
      payload: {
        action: answer?.isHardLinked ? 'edit' : 'add',
        answerId: answer?.jid,
        isHardLinked: answer?.isHardLinked ? true : false,
        jid: answer?.isHardLinked ? answer?.questionId : null,
        text,
        similarQuestions,
      },
    });
  };

  const handleCloseSimilarQuestionsModal = () => {
    dispatch({
      type: CLOSE_SIMILAR_QUESTIONS_MODAL,
    });
  };

  const toggleSpeak = () => {
    const isToggled = isSpeechActivated;
    if (!isToggled && SpeechRecognition) {
      const recognition = new SpeechRecognition();
      setSpeechActivated(!isSpeechActivated);
      setListening(true);
      recognition.continuous = false;
      recognition.lang = navigator.language;
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;
      recognition.start();

      recognition.onresult = function (event) {
        concatSpeech(event.results[0][0].transcript);
        stopListening();
      };
      recognition.onspeechend = function () {
        console.log('Speech ending');
        stopListening();
      };
      recognition.onend = function () {
        console.log('Speech ending');
        stopListening();
      };
      recognition.onabort = function () {
        console.log('Speech aborted');
        stopListening();
      };
      recognition.onnomatch = function (event) {
        console.log("Speech API can't recognize");
      };
      recognition.onerror = function (event) {
        stopListening();
        console.log('Speech API error occured: ', event.error);
      };
    } else if (isToggled && SpeechRecognition) {
      return stopListening();
    } else {
      console.log('Speech API not supported');
    }
  };

  const stopListening = () => {
    setListening(false);
    setSpeechActivated(false);
  };

  const readText = (answer, idx) => {
    if (answer && voices.length) {
      // only read the .text
      // show_text could contain html elements
      const utterThis = new SpeechSynthesisUtterance(answer);
      utterThis.lang = navigator.language;
      utterThis.onend = function (event) {
        console.log('SpeechSynthesisUtterance.onend');
      };
      utterThis.onerror = function (event) {
        console.error('SpeechSynthesisUtterance.onerror');
      };
      utterThis.pitch = 1;
      utterThis.rate = 1;
      synth.speak(utterThis);
    }
  };

  const concatSpeech = newSpeech => {
    if (chatQuestion) {
      setChatQuestion(`${chatQuestion} ${newSpeech}`);
    } else {
      setChatQuestion(newSpeech);
    }
  };

  const handleChangeAnswerVersion = e => {
    return e.target.checked && !isIncludeDraftAnswers
      ? setIncludeDraftAnswers(true)
      : setIncludeDraftAnswers(false);
  };

  const handleTranslateChange = async event => {
    setTranslateLanguage(event.target.checked);
    const res = await apiService.editTranslationSettings(
      sentinel,
      jid,
      token,
      event.target.checked
    );
    if (!res.data || !res.data.report[0] || !res.data.report[0].context) {
      throw new Error(GET_DATA_ERROR);
    }

    const botData = res.data.report[0];
    const defaultAnswerData = res.data.report[1];

    dispatch({
      type: SET_BOT_SETTINGS,
      payload: {
        bot: botData,
        defaultAnswer: defaultAnswerData,
      },
    });
  };

  const handleTypingExperience = async event => {
    setTypingExperience(event.target.checked);
    dispatch({
      type: SET_TYPING_EXPERIENCE,
      payload: event.target.checked,
    });
  };

  const handleContextHistoryChange = async event => {
    setContextHistory(event.target.checked);
  };

  const handleChangeDropdownVisibility = val => {
    setChatMoreMenuOpen(val);
  };

  const handleChangeChatView = isDevMode => {
    dispatch({
      type: SET_CHAT_MODE,
      payload: isDevMode,
    });
  };

  const handleShowJSONAnswerInModal = (answer, question) => {
    dispatch({
      type: SHOW_CHAT_ANSWER_IN_MODAL,
      payload: { answer, question },
    });
  };

  const handleShowSelectedSequenceInModal = sequence => {
    dispatch({
      type: SHOW_CHAT_SELECTED_SEQUENCE_IN_MODAL,
      payload: sequence,
    });
  };

  const clearChatHistory = () => {
    dispatch({
      type: CLEAR_CHAT_LIST,
      payload: null,
    });
  };

  const handleShowAnswerEditorModal = (answer, args) => {
    dispatch({
      type: SET_ANSWER_EDITOR_SELECTED_ANSWER,
      payload: { answer, args },
    });
  };

  return {
    chatAnswerInModal,
    chatList,
    chatQuestion,
    featureFlags,
    flipAnswer,
    bottomChatAreaRef,
    handleChange,
    handleFlipAnswer,
    handleLinkAnswer,
    handleSendQuickReply,
    handleSubmit,
    handleChangeAnswerVersion,
    handleCloseSimilarQuestionsModal,
    handleTranslateChange,
    handleContextHistoryChange,
    handleChangeChatView,
    handleChangeDropdownVisibility,
    handleShowJSONAnswerInModal,
    handleShowSelectedSequenceInModal,
    handleShowAnswerEditorModal,
    isBotOpenAIEnabled,
    isChatMoreMenuOpen,
    isChatOnDevMode,
    isNotAnswerBank,
    isSidebarChatExpanded,
    isSpeechAllowed,
    isQuickButtonsVisible,
    isSpeechActivated,
    lastChatAnswer,
    listening,
    loading,
    maxInteraction,
    name,
    onClickCallback,
    onDefaultAnswerClickCallback,
    openResponseModalPicker,
    readText,
    runTour,
    runTourDefaultAnswer,
    sending,
    setMaxInteraction,
    shouldShowTestChatUseContext,
    similarQuestionsModal,
    stepIndex,
    stepIndexDefaultAnswer,
    toggleSpeak,
    translateLanguage,
    clearChatHistory,
    defaultAnswerThreshold,
    botMode,
    webSocket,
    typingExperience,
    handleTypingExperience,
  };
};
export default useSidebarChat;
