import { Modal, Row, Select } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const UserDetailsContainer = styled.div`
  padding: 2% 5% 0 5%;
  margin: 2%;
  align-content: center;
  background-color: #ffffff;
  color: ${cssVariables.blueishGray};
  border-radius: 8px;
`;

export const UserDetailsCTA = styled(Row)`
  // display: flex;
  margin-bottom: 3%;
  // justify-content: space-between;
`;

export const UserInformation = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  color: ${cssVariables.blueishGray};
`;

export const GraphModal = styled(Modal)`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: ${cssVariables.blueishGray};
  min-height: 80%;

  .ant-modal-body {
    max-height: 100% !important;
  }

  .ant-modal-content {
    height: 100%;
  }

  .ant-spin-container {
    min-width: 98vw;
    display: flex;

    > div:first-child {
      justify-content: left;
    }
  }

  > h3 {
    font-weight: ${cssVariables.font.bold};
    font-size: 16px;
    line-height: 1.5715;
  }
`;

export const SelectContainer = styled(Select)`
  width: 150px;
`;
