import { createSelector } from 'reselect';

export const layoutSelector = state => state.layout;

export const botModalStateSelector = createSelector(
  layoutSelector,
  layout => layout?.botModal || {}
);

export const wordPressModalStateSelector = createSelector(
  layoutSelector,
  layout => layout?.wordPressModal || {}
);

export const isBotModalVisibleSelector = createSelector(
  botModalStateSelector,
  botModal => Boolean(botModal.visible)
);

export const isWordPressModalVisibleSelector = createSelector(
  wordPressModalStateSelector,
  wordPressModal => Boolean(wordPressModal.visible)
);

export const pageInitializedSelector = createSelector(
  layoutSelector,
  layout => layout.initialized
);

export const isMobileViewSelector = createSelector(
  layoutSelector,
  layout => layout.isMobileView
);

export const standardFontSizeSelector = createSelector(
  layoutSelector,
  layout => layout.standardFontSize
);
