import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import { StyledRoot } from './DateFilter.styles';

const { RangePicker } = DatePicker;

const DateFilter = props => {
  const { onChange, ...rest } = props;
  const disabledDate = current => {
    return (
      (current && current > moment().endOf('day')) ||
      current < moment().subtract(365, 'd')
    );
  };

  const handleChangeDates = (dates, dateStrings) => {
    onChange(dateStrings[0], dateStrings[1]);
  };

  return (
    <StyledRoot>
      <RangePicker
        defaultValue={[moment().subtract(7, 'd'), moment()]}
        {...rest}
        ranges={{
          Today: [moment().startOf('day'), moment()],
          'Last 3 days': [moment().subtract(2, 'd'), moment()],
          'Last 7 days': [moment().subtract(6, 'd'), moment()],
          'Last 30 days': [moment().subtract(29, 'd'), moment()],
          'Last 3 months': [moment().subtract(3, 'months'), moment()],
        }}
        disabledDate={disabledDate}
        onChange={handleChangeDates}
        allowClear={false}
        allowEmpty={(false, false)}
      />
    </StyledRoot>
  );
};

DateFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export { DateFilter };
