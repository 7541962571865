import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined, CopyTwoTone } from '@ant-design/icons';

import { StyledRoot, StyledDemoLink } from './CodeCopier.styles';
import { cssVariables } from 'styles/root';
import { message } from 'antd';

const CodeCopier = ({ code, isLight }) => {
  return (
    <StyledRoot>
      <span>Copy the link below and share it with your team</span>
      <StyledDemoLink isLight={isLight}>
        <a href={code} target="_blank" rel="noreferrer">
          {code}
        </a>
        <CopyToClipboard text={code}>
          {isLight ? (
            <CopyOutlined
              onClick={() => message.info('Copied to clipboard.')}
            />
          ) : (
            <CopyTwoTone
              onClick={() => message.info('Copied to clipboard.')}
              twoToneColor={cssVariables.primaryBlue}
            />
          )}
        </CopyToClipboard>
      </StyledDemoLink>
    </StyledRoot>
  );
};

CodeCopier.propTypes = {
  isLight: PropTypes.bool,
  code: PropTypes.string.isRequired,
};

export default CodeCopier;
