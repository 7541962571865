import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntdInput } from 'antd';
import { StyledInput } from './Input.style';

const Input = props => {
  const { full, label, type, size, inputRef, allowClear, ...rest } = props;
  return (
    <StyledInput full={full}>
      {label && <label htmlFor={label}>{label}</label>}
      {type === 'password' ? (
        <AntdInput.Password
          size={size}
          type="password"
          visibilityToggle
          ref={inputRef}
          {...rest}
        />
      ) : (
        <AntdInput
          ref={inputRef}
          size={size}
          type={type}
          allowClear={allowClear}
          {...rest}
        />
      )}
    </StyledInput>
  );
};

Input.defaultProps = {
  size: 'large',
  type: 'text',
};

Input.propTypes = {
  full: PropTypes.bool,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'middle', 'default', 'small']),
  type: PropTypes.string,
  onChange: PropTypes.func,
};

export { Input };
