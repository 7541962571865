import { cssVariables } from 'styles/root';
import styled, { css } from 'styled-components';

export const StyledTitle = styled.div`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.normal};

  .primary {
    font-size: 42px;
    line-height: 51px;
    color: ${cssVariables.primaryGray};
  }

  .secondary {
    font-size: 32px;
    line-height: 39px;
    color: #31353e;
  }

  .responsive-text {
    font-size: 1.5rem;
    line-height: 39px;
    color: #31353e;
    padding-bottom: 2%;
  }

  ${props =>
    props.hasPrintBtn || props.hasRightEl
      ? css`
          display: flex;
          justify-content: space-between;
          align-items: center;

          .anticon {
            font-size: 16px;
          }

          .right-el {
            font-size: 18px;
          }
        `
      : null}

  span:last-child {
    margin-left: 2px;
  }

  button {
    align-self: center;
  }
`;
