import { TEST_STATUS } from 'constants/testSuite';
import {
  ADD_TEST_CASE,
  ADD_TEST_SUITE,
  DELETE_TEST_CASE,
  DELETE_TEST_SUITE,
  SET_SELECTED_TEST_SUITE,
  SET_TEST_CASES,
  SET_TEST_SUITES,
  UPDATE_TEST_CASE,
  UPDATE_TEST_SUITE,
  RUN_TEST_SUITE,
} from 'store/action';
import {
  createTestCaseData,
  createTestSuiteDataFromAPI,
  updateTestCase,
} from 'utils/testSuite';

export const testSuitesReducer = (state, action) => {
  // get test status from parsed test case
  const getTestSuiteTestSummary = testCases => {
    const lastTestStatus = {
      success: 0,
      fail: 0,
      unknown: 0,
      outdated: 0,
    };
    const result = testCases.reduce((accumulator, result) => {
      if (result.testResult === TEST_STATUS.PASSED) {
        accumulator.success++;
      } else if (result.testResult === TEST_STATUS.FAILED) {
        accumulator.fail++;
      } else if (result.testResult === TEST_STATUS.EDITED) {
        accumulator.outdated++;
      } else {
        accumulator.unknown++;
      }
      return accumulator;
    }, lastTestStatus);
    return result;
  };

  switch (action.type) {
    case SET_TEST_SUITES: {
      const suitesData = createTestSuiteDataFromAPI(action.payload);

      return {
        ...state,
        bot: {
          ...state.bot,
          testSuites: suitesData,
        },
      };
    }

    case SET_SELECTED_TEST_SUITE: {
      const { testSuite, testCases } = action.payload;
      const { testCases: tc, lastTestStatus } = createTestCaseData(testCases);

      return {
        ...state,
        bot: {
          ...state.bot,
          testSuite: {
            ...testSuite,
            testCases: tc,
            lastTestStatus,
          },
        },
      };
    }

    case SET_TEST_CASES:
      return {
        ...state,
        bot: {
          ...state.bot,
          testSuite: {
            ...action.payload,
          },
        },
      };

    case ADD_TEST_SUITE:
      return {
        ...state,
        bot: {
          ...state.bot,
          testSuites: [...state.bot.testSuites, action.payload],
        },
      };

    case ADD_TEST_CASE: {
      const selectedTestSuiteJID = state.bot?.testSuite?.jid;
      const { testCases: newTestCase } = createTestCaseData(action.payload);
      const allTestCases = [...state.bot.testSuite?.testCases, ...newTestCase];

      const updatedTestSuites = state.bot?.testSuites?.map(ts => {
        if (selectedTestSuiteJID === ts.jid) {
          return {
            ...ts,
            testCount: Number(allTestCases.length) + 1,
            lastTestStatus: getTestSuiteTestSummary(allTestCases),
          };
        }
        return ts;
      });

      return {
        ...state,
        bot: {
          ...state.bot,
          testSuites: updatedTestSuites || [],
          testSuite: {
            ...state.bot.testSuite,
            testCases: allTestCases,
            lastTestStatus: getTestSuiteTestSummary(allTestCases),
          },
        },
      };
    }
    case UPDATE_TEST_CASE: {
      const selectedTestCase = action.payload;
      const testCases = state.bot.testSuite?.testCases;
      const selectedTestSuite = state.bot?.testSuite;

      const updatedTestCases = updateTestCase(selectedTestCase, testCases);
      const updatedTestSuite = {
        ...selectedTestSuite,
        lastTestStatus: getTestSuiteTestSummary(updatedTestCases),
      };
      return {
        ...state,
        bot: {
          ...state.bot,
          testSuite: {
            ...state.bot.testSuite,
            ...updatedTestSuite,
            testCases: updatedTestCases,
          },
        },
      };
    }

    case UPDATE_TEST_SUITE: {
      const { jid } = action.payload;
      let testSuite = createTestSuiteDataFromAPI(action.payload);
      const testSuites = state.bot.testSuites?.map(ts => {
        if (ts.jid === jid) {
          const suiteData = createTestSuiteDataFromAPI(action.payload);
          testSuite = suiteData;
          return {
            ...ts,
            ...suiteData,
          };
        }
        return ts;
      });
      return {
        ...state,
        bot: {
          ...state.bot,
          testSuites,
          testSuite,
        },
      };
    }

    case DELETE_TEST_CASE: {
      const { jid } = action.payload;
      const selectedTestSuiteJID = state.bot?.testSuite?.jid;
      const updatedTestCases = state.bot?.testSuite?.testCases.filter(
        item => item.jid !== jid
      );
      const updatedTestSuites = state.bot?.testSuites?.map(ts => {
        if (selectedTestSuiteJID === ts.jid) {
          return {
            ...ts,
            testCount: Number(updatedTestCases.length) + 1,
            lastTestStatus: getTestSuiteTestSummary(updatedTestCases),
          };
        }
        return ts;
      });

      return {
        ...state,
        bot: {
          ...state.bot,
          testSuite: {
            ...state.bot.testSuite,
            testCases: updatedTestCases,
            lastTestStatus: getTestSuiteTestSummary(updatedTestCases),
          },
          testSuites: updatedTestSuites || [],
        },
      };
    }

    case RUN_TEST_SUITE: {
      const { testCases, lastTestStatus } = createTestCaseData(action.payload);

      const testSuites = state.bot.testSuites.map(testSuite => {
        if (testSuite.jid === state.bot.testSuite.jid) {
          return {
            ...testSuite,
            lastTestStatus,
          };
        }
        return testSuite;
      });

      const updatedTestSuite = {
        ...state.bot.testSuite,
        lastTestStatus: lastTestStatus,
        testCases: testCases,
      };

      return {
        ...state,
        bot: {
          ...state.bot,
          testSuites,
          testSuite: updatedTestSuite,
        },
      };
    }

    case DELETE_TEST_SUITE:
      const filteredTestSuites = state.bot.testSuites.filter(
        item => item.jid !== action.payload
      );
      return {
        ...state,
        bot: { ...state.bot, testSuites: [...filteredTestSuites] },
      };

    default:
      return state;
  }
};
