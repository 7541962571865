import { userReducer } from 'store/reducers/user';
import { adminReducer } from './admin';
import { botDetailsReducer } from './bot/botDetails';
import { botsReducer } from './bots';
import { initReducer } from './init';
import { chatListReducer } from './bot/chatlist';
import { planReducer } from './plan';
import { testSuitesReducer } from './bot/testSuites';
import { answersReducer } from './bot/answers';
import { categoriesReducer } from './bot/categories';
import { integrationsReducer } from './bot/integrations';
import { integrationSettingsReducer } from './bot/integrations/settings';
import { onboardingReducer } from './onboarding';
import { botUIReducer } from './bot/ui';
import { questionsReducer } from './bot/questions';
import { subscriptionReducer } from './subscription';
import { categoriesTemplatesReducer } from './categoryTemplates';
import { analyticsReducer } from './bot/analytics';
import { callbackLogsReducer } from './bot/callbackLogs';
import { aiToolsReducer } from './bot/aiTools';
import { websocketReducer } from './websocket';
import { invoiceReducer } from './invoice';
import { settingsReducer } from './bot/settings';

const combineReducers =
  (...reducers) =>
  (state, action) =>
    reducers.reduce((newState, reducer) => reducer(newState, action), state);

export const combinedReducers = combineReducers(
  initReducer,
  botsReducer,
  userReducer,
  planReducer,
  onboardingReducer,
  adminReducer,
  answersReducer,
  botDetailsReducer,
  categoriesReducer,
  chatListReducer,
  integrationsReducer,
  integrationSettingsReducer,
  testSuitesReducer,
  botUIReducer,
  questionsReducer,
  subscriptionReducer,
  categoriesTemplatesReducer,
  analyticsReducer,
  callbackLogsReducer,
  aiToolsReducer,
  websocketReducer,
  invoiceReducer,
  settingsReducer
);
