import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { EditOutlined, DownloadOutlined } from '@ant-design/icons';

import { StyledMenu } from './SettingsDropdownMenu.style';
import { Context } from 'store/store';
import { SET_CATEGORY_MODAL_OPEN } from 'store/action';
import { allAnswersSelector } from 'selectors/bot/answers';
import { allCategoriesSelector } from 'selectors/bot/categories';

import useSelector from 'store/useSelector';
import { botModeSelector } from 'selectors/bot';
import { BOT_MODES } from 'constants/bot';

const SettingsDropdownMenu = ({
  filteredAnswers,
  handleOpenExportModal,
  isExportImportEnabled,
}) => {
  const [_, dispatch] = useContext(Context);
  const allAnswers = useSelector(allAnswersSelector);
  const allCategories = useSelector(allCategoriesSelector);
  const botMode = useSelector(botModeSelector);

  const handleOpenCategoryModal = () => {
    dispatch({
      type: SET_CATEGORY_MODAL_OPEN,
      payload: { action: 'view-categories' },
    });
  };

  return (
    <StyledMenu>
      {allCategories.length ? (
        <Menu.Item
          key={'edit-category'}
          icon={<EditOutlined />}
          onClick={handleOpenCategoryModal}
        >
          Edit Category
        </Menu.Item>
      ) : null}
      {botMode !== BOT_MODES.OPENAI ? (
        <Menu.Item
          disabled={!allAnswers.length || !isExportImportEnabled}
          key={'export-answers'}
          icon={<DownloadOutlined />}
          onClick={handleOpenExportModal}
        >
          Export
        </Menu.Item>
      ) : null}
    </StyledMenu>
  );
};

SettingsDropdownMenu.propTypes = {
  filteredAnswers: PropTypes.any,
  handleOpenExportModal: PropTypes.func,
  isExportImportEnabled: PropTypes.bool,
};

export default SettingsDropdownMenu;
