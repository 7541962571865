import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { ImportOutlined } from '@ant-design/icons';
import { Context } from 'store/store';
import { Progress, Radio, Spin, Steps, Switch } from 'antd';
import Button from 'components/Button';
import Modal from 'components/Modals/GenericModal';
import ImportAlertMessage from './ImportAlertMessage';
import {
  StyledAntUploadContent,
  ActiveActionLabel,
  StyledWordPressHeaderContainer,
} from './WordPressIntegrationModal.style';
import useWordPressIntegrationModal from './hooks';
import Dragger from 'antd/lib/upload/Dragger';
import {
  SPINNER_LABEL,
  VALID_FILE_FORMATS,
} from 'constants/answerbank/website';
import ImportUrlForm from 'components/ImportUrlForm';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
const { Step } = Steps;

const WordPressIntegrationModal = ({
  onClose,
  visibility,
  notificationAPI,
  parsedFileAnswers,
  setParsedFileAnswers,
  isImportBtnDisabled,
}) => {
  const {
    importErrorMsg,
    importWarnMsg,
    finalAnswerWarnMsg,
    modalBtnLoading,
    handleCancelImport,
    handleAddFile,
    handleUploadFile,
    handleRemoveFile,
    currentStep,
    handleBackward,
    crawlerParams,
    setCrawlerParams,
    handleCloseElementSelecting,
    fileType,
    setFileType,
    handleSubmit,
    actionPercentage,
    activeActionLabel,
    setModalBtnLoading,
    handelWebsiteFormStateChanges,
    setWebsiteFormState,
    websiteFormState,
    setVersion,
    version,
    handleStopScrapping,
    webSocket,
    handleBackToPagesList,
    newBotCreated,
  } = useWordPressIntegrationModal({
    onClose,
    visibility,
    notificationAPI,
    parsedFileAnswers,
    setParsedFileAnswers,
    isImportBtnDisabled,
  });

  const displayStopButton = useMemo(
    () =>
      !webSocket?.scannedTableDataSource &&
      webSocket?.activeAction === 'scrape',
    [webSocket?.activeAction, webSocket?.scannedTableDataSource]
  );

  const disableUrlFormFields = useMemo(
    () => webSocket?.activeAction === 'add_file' || actionPercentage > 0,
    [webSocket?.activeAction, actionPercentage]
  );

  const [state] = useContext(Context);
  const { user } = state;

  const Header = () => (
    <StyledWordPressHeaderContainer>
      <p className="heading">Hello {user?.name || 'User'},</p>
      <p className="heading"> Welcome to ZeroShotBot!</p>
      <p className="text-content">
        ZeroShotBot is a no-code, no-train bot platform. We can create a bot and
        create a Wordpress integration for you, you just need to upload a file
        or import a url below.
      </p>{' '}
      <h4>{fileType === 'file' ? 'Select a file' : 'Import Website'} </h4>
    </StyledWordPressHeaderContainer>
  );

  const renderFooter = (
    <div
      style={{
        display: 'flex',
        justifyContent:
          websiteFormState?.isTargetElementOpen === true
            ? 'end'
            : 'space-between',
      }}
    >
      {websiteFormState?.isTargetElementOpen === false && (
        <StyledFlexRowLeft style={{ width: '50%' }}>
          <Switch
            style={{ alignSelf: 'center' }}
            checkedChildren={'Final'}
            unCheckedChildren={'Draft'}
            size="default"
            defaultChecked={version}
            onChange={value => setVersion(value ? 'final' : 'draft')}
            disabled={modalBtnLoading || actionPercentage > 0}
          />
        </StyledFlexRowLeft>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {displayStopButton ? (
          <Button variant="red" value="Stop" onClick={handleStopScrapping} />
        ) : (
          <Button
            variant={'link'}
            value={
              fileType === 'file'
                ? 'Cancel'
                : currentStep === 0
                ? 'Cancel'
                : 'Back'
            }
            onClick={
              fileType === 'file'
                ? handleCancelImport
                : websiteFormState?.isTargetElementOpen === true
                ? handleCloseElementSelecting
                : currentStep === 0
                ? handleCancelImport
                : websiteFormState?.selectedPageList?.urls?.length > 0
                ? handleBackToPagesList
                : handleBackward
            }
          />
        )}
        <Button
          variant="primary-btn-v2"
          value={
            activeActionLabel === 'Verifying...' && currentStep !== 0
              ? 'Next'
              : 'Submit'
          }
          loading={
            modalBtnLoading && websiteFormState?.isTargetElementOpen === false
          }
          onClick={() => {
            activeActionLabel === 'Verifying...' && currentStep !== 0
              ? handleAddFile(newBotCreated)
              : handleSubmit();
          }}
          disabled={
            activeActionLabel !== 'Verifying...' && actionPercentage > 0
          }
        />
      </div>
    </div>
  );

  return (
    <>
      <Modal
        closable={!websiteFormState?.isTargetElementOpen}
        visible={visibility}
        onCancel={onClose}
        onOk={handleAddFile}
        okText="Submit"
        okButtonProps={{
          disabled: isImportBtnDisabled || !parsedFileAnswers?.length,
        }}
        title={!websiteFormState?.isTargetElementOpen ? <Header /> : null}
        cancelText={modalBtnLoading ? 'Close' : 'Cancel'}
        confirmLoading={modalBtnLoading}
        destroyOnClose={false}
        maskClosable={false}
        width={websiteFormState?.isTargetElementOpen ? '95%' : '80%'}
        isFullScreen={websiteFormState?.isTargetElementOpen}
        footer={renderFooter}
      >
        <div style={{ maxHeight: '40vh' }}>
          <Spin spinning={modalBtnLoading} tip={SPINNER_LABEL}>
            {!websiteFormState?.isTargetElementOpen && (
              <Radio.Group
                onChange={e => setFileType(e.target.value)}
                value={fileType}
                style={{ paddingLeft: '10px', paddingBottom: '3%' }}
                disabled={actionPercentage > 0}
              >
                <Radio value="file">File</Radio>
                <Radio value="website">Website</Radio>
              </Radio.Group>
            )}
            {actionPercentage > 0 && !websiteFormState?.isTargetElementOpen && (
              <>
                <Progress
                  percent={actionPercentage}
                  showInfo={false}
                  strokeColor="#00BD57"
                  trailColor="#E8F0FD"
                  style={{ paddingLeft: '10px' }}
                />
                <ActiveActionLabel style={{ paddingLeft: '10px' }}>
                  {activeActionLabel}
                </ActiveActionLabel>
              </>
            )}

            {fileType === 'file' && !websiteFormState?.isTargetElementOpen && (
              <div className="steps-content" style={{ margin: '10px 0' }}>
                <Dragger
                  headers={{ 'Content-Type': 'multipart/form-data' }}
                  beforeUpload={handleUploadFile}
                  listType="picture"
                  onRemove={handleRemoveFile}
                  maxCount={1}
                  accept={VALID_FILE_FORMATS}
                  style={{ paddingLeft: '10px' }}
                  disabled={actionPercentage > 0}
                >
                  <StyledAntUploadContent>
                    <ImportOutlined />
                    {'Drag files here or select file'}
                  </StyledAntUploadContent>
                </Dragger>
                <ImportAlertMessage
                  importErrorMsg={importErrorMsg}
                  importWarnMsg={importWarnMsg}
                  finalAnswerWarnMsg={finalAnswerWarnMsg}
                />
              </div>
            )}

            {fileType === 'website' && actionPercentage < 60 && (
              <ImportUrlForm
                currentStep={currentStep}
                setModalBtnLoading={setModalBtnLoading}
                crawlerParams={crawlerParams}
                setCrawlerParams={setCrawlerParams}
                websiteFormState={websiteFormState}
                handelWebsiteFormStateChanges={handelWebsiteFormStateChanges}
                setWebsiteFormState={setWebsiteFormState}
                disableUrlFormFields={disableUrlFormFields}
                isWordPressModal={true}
              />
            )}
          </Spin>
        </div>
      </Modal>
    </>
  );
};

WordPressIntegrationModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

export default WordPressIntegrationModal;
