import { useContext, useMemo } from 'react';
import { Tag } from 'antd';

import { uuid } from 'uuidv4';
import { Context } from 'store/store';
import {
  ASYNC_PAYLOAD_COMMENT,
  AUTHENTICATED_PAYLOAD_COMMENT,
} from 'constants/integration';
import { stripUUID } from 'utils';

const useAPI = () => {
  const [state] = useContext(Context);
  const {
    apiGatewayConfig,
    bot: { jid },
  } = state;

  const getDummyComment = field =>
    `  // This is a dummy ${field} you can include your own ${field} here.`;
  const generateDummyIdPayload = field => `  "${field}": "${uuid()}",  `;
  const generateBoleanPayload = field => `  "${field}": false,  `;

  const payload = useMemo(
    () => `{
    "bot_id": "${stripUUID(jid)}",
    "question": "<your-question-here>",
    "interaction_id": "${uuid()}", ${getDummyComment('interaction_id')}
    "session_id": "${uuid()}",  ${getDummyComment('session_id')}
    "visitor_id": "${uuid()}",  ${getDummyComment('visitor_id')}
    "is_authenticated": false, ${AUTHENTICATED_PAYLOAD_COMMENT}
    "is_async": false ${ASYNC_PAYLOAD_COMMENT}
    }`,
    [apiGatewayConfig]
  );

  const header = useMemo(
    () => `
  integration-auth: <your-api-key-here>
  x-api-key: ${apiGatewayConfig?.key}
    `,
    [apiGatewayConfig]
  );

  const generatePayload = (isRequired, fieldName, comment, field) => {
    if (isRequired) {
      return (
        <span style={{ margin: 0 }}>
          {fieldName}
          <Tag color="red" style={{ margin: 0 }}>
            Required
          </Tag>
        </span>
      );
    } else {
      return (
        <span style={{ margin: 0 }}>
          {fieldName}
          <Tag color="blue" style={{ margin: 0 }}>
            Optional
          </Tag>{' '}
          {comment === 'authenticated'
            ? AUTHENTICATED_PAYLOAD_COMMENT
            : comment === 'async'
            ? ASYNC_PAYLOAD_COMMENT
            : getDummyComment(field)}
        </span>
      );
    }
  };

  return {
    apiGatewayConfig,
    jid,
    getDummyComment,
    generateDummyIdPayload,
    generateBoleanPayload,
    generatePayload,
    payload,
    header,
  };
};

export default useAPI;
