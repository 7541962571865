import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledBotCard = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: ${props => (props.isList ? 'row' : 'column')};
  justify-content: space-between;
  box-shadow: ${cssVariables.cardShadow};
  border-radius: 5px;
  align-items: ${props => (props.isList ? 'center' : 'inherit')};
  border-bottom: 1px solid ${cssVariables.gray2};
  padding: ${props => (props.isList ? '10px' : '0px ')};
  margin: ${props => (props.isList ? '10px 0' : '0px ')};
  min-height: ${props => (props.isList ? 'auto' : '180px')};

  :hover {
    border-bottom: ${props =>
      props.isList ? `1px solid ${cssVariables.gray3}` : 'none'};
    box-shadow: ${cssVariables.itemListShadow};
    background-color: ${cssVariables.gray5};
    cursor: ${props => (props.isList ? 'pointer' : 'auto')};
  }

  .info {
    display: flex;
    align-items: center;
    padding: ${props => (props.isList ? '0px' : '16px 16px 0 16px')};
    width: ${props => (props.isList ? '100%' : 'auto')};
  }

  .bot-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: linear-gradient(180deg, #c4c4c4 0%, #fffcfc 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }

  .bot-info {
    display: flex;
    flex-direction: ${props => (props.isList ? 'row' : 'column')};
    width: ${props => (props.isList ? '100%' : '70%')};
  }

  .weak-bot-button {
    height: 32px;
    display: flex;
    flex-direction: row-reverse;
    margin: ${props => (props.isList ? '0' : '0px 20px 10px 0px')};

    .weak-stat {
      color: #fff;
      background-color: ${cssVariables.red10};
      padding: 5px 10px;
      border-radius: 5px;
      .anticon {
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }

  .bot-name {
    font-size: 18px;
    line-height: 22px;
    color: ${cssVariables.primaryColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: ${props => (props.isList ? '10px' : '0px')};
  }

  .bot-actions {
    ${props =>
      props.isList
        ? `
      display: flex;
      flex-start: end;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      align-items: center;`
        : ''}
  }

  .bot-footer-actions {
    display: flex;
    flex-direction: ${props => (!props.isList ? 'row' : 'column')};
    align-items: center;
    justify-content: space-between;
    padding-right: ${props => (!props.isList ? '20px' : 0)};
    padding-bottom: ${props => (!props.isList ? '16px' : 0)};
    padding-left: ${props => (!props.isList ? '16px' : 0)};
    font-style: italic;
    color: ${cssVariables.gray0};
    font-size: 13px;
    margin-right: ${props => (props.isList ? '36px' : 0)};
    width: ${props => (props.isList ? '20%' : 'auto')};
  }

  .answers {
    font-size: 13px;
    line-height: 23px;
    color: #afafaf;
  }

  .more {
    position: absolute;
    transform: rotate(-90deg);
    top: 16px;
    right: 16px;
    padding: 5px;

    :hover {
      background: ${cssVariables.gray2};
      border-radius: 50%;
    }
  }

  .ant-spin-container,
  .ant-spin-blur {
    width: 100%;
    height: 100%;
  }
`;

export const WeakBotContent = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  border-radius: 10px;
  font-family: 'Roboto';
  font-style: normal;

  > h3 {
    margin-top: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #333333;
  }
  > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const WeakBotContentActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;
