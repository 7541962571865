import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import Modal from '../GenericModal';
import { cssVariables } from 'styles/root';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
import { SuccessMessage } from 'pages/UserProfile/PlanAndPayments/PlanAndPayments.style';
import Button from 'components/Button';
import ROUTES from 'constants/routes';
import useCancelSubscriptionModal from './hooks';

const CancelSubscriptionModal = () => {
  const {
    handleCloseModal,
    handleCancelSubscription,
    loading,
    showCancelSubscriptionModal,
    showCancelledSubscriptionSuccessMessage,
    trialDaysLeft,
  } = useCancelSubscriptionModal();

  const Content = useMemo(() => {
    const deactivatingBotNotice = (
      <p>{`Your existing bots will be inactive${
        trialDaysLeft ? ' in ' + trialDaysLeft + ' days.' : '.'
      }`}</p>
    );
    if (showCancelledSubscriptionSuccessMessage) {
      return (
        <>
          <SuccessMessage>
            <h4>{'Subscription Cancelled!'}</h4>
            {deactivatingBotNotice}
            <p>
              {`You can always `}
              <Link to={ROUTES.SUBSCRIPTION}>subscribe to a plan</Link>
              {` if you
            change your mind :)`}
            </p>
          </SuccessMessage>
          <Button
            value="Back to home page"
            variant="link"
            bordered
            onClick={handleCloseModal}
          />
        </>
      );
    } else if (showCancelSubscriptionModal) {
      return (
        <>
          <StyledFlexRowLeft>
            <ExclamationCircleTwoTone
              twoToneColor={cssVariables.warning}
              style={{ fontSize: 24 }}
            />
            <span>{'Are you sure you want to cancel your subscription?'}</span>
          </StyledFlexRowLeft>
          {deactivatingBotNotice}
        </>
      );
    }
  }, [showCancelledSubscriptionSuccessMessage, showCancelSubscriptionModal]);

  return (
    <Modal
      title="Cancel Subscription"
      onOk={handleCancelSubscription}
      onCancel={handleCloseModal}
      cancelText={'Close'}
      okButtonProps={{
        danger: true,
        loading,
      }}
      okText={'Cancel my subscription'}
      visible={showCancelSubscriptionModal}
      spinning={loading}
      noOkButton={showCancelledSubscriptionSuccessMessage}
      noCancelButton={showCancelledSubscriptionSuccessMessage}
    >
      {Content}
    </Modal>
  );
};

export default CancelSubscriptionModal;
