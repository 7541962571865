import PropTypes from 'prop-types';
import { StyledModalErrorAlert } from './WordPressIntegrationModal.style';

const ImportAlertMessage = ({
  importErrorMsg,
  importWarnMsg,
  similarAnswerWarnMsg,
  finalAnswerWarnMsg,
}) => {
  return (
    <>
      {importErrorMsg ? (
        <StyledModalErrorAlert message={importErrorMsg} type="error" />
      ) : null}
      {importWarnMsg ? (
        <StyledModalErrorAlert
          message={importWarnMsg}
          type="warning"
          closable
        />
      ) : null}
      {similarAnswerWarnMsg ? (
        <StyledModalErrorAlert
          message={similarAnswerWarnMsg}
          type="warning"
          closable
        />
      ) : null}
      {finalAnswerWarnMsg ? (
        <StyledModalErrorAlert
          message={finalAnswerWarnMsg}
          type="warning"
          closable
        />
      ) : null}
    </>
  );
};

export default ImportAlertMessage;

ImportAlertMessage.propTypes = {
  importErrorMsg: PropTypes.string,
  importWarnMsg: PropTypes.string,
  similarAnswerWarnMsg: PropTypes.string,
  finalAnswerWarnMsg: PropTypes.string,
};
