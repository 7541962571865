import React from 'react';
import {
  DeleteOutlined,
  DownOutlined,
  DownloadOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';
import { Menu, Switch } from 'antd';
import { capitalize } from 'lodash';

import { default as PageTitle } from 'components/Title';
import Button from 'components/Button';
import LimitationTooltip from 'components/ToolTips/LimitationToolTip';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import ConfirmDelete from 'components/Modals/ConfirmDelete';
import {
  StyledTabPane,
  StyledRoot,
  StyledContainer,
  StyledTabs,
  StyledTabPane1,
  StyledBtnAction,
  StyledBtnSaveChanges,
  StyledBotInfoBlock,
  StyledBotInfo,
  StyledBotInfoTitle,
  StyledBotInfoDescription,
  StyledInput,
  StyledCardContainer,
  StyledLabel,
  StyledColumnSections,
  StyledTabActionButtons,
} from './Settings.styles';
import useSettings from './hooks';
import ToolTip from 'components/ToolTips/BaseToolTip';
import { cssVariables } from 'styles/root';
import LanguagePicker from 'components/LanguagePicker';
import { withFeatureFlag } from 'components/hoc/withFeatureFlags';
import ExportModal from 'components/Modals/ExportModal';
import { BOT_MODE } from 'constants/featureFlags';
import {
  StyledFlexColumn,
  StyledFlexRowLeft,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';
import EditorAndViewer from 'components/EditorAndViewer/EditorAndViewer';
import TitleToolTip from 'components/ToolTips/TitleToolTip';
import { DEFAULT_BOT_MODE } from 'constants/bot';
import OpenAIResponseSettings from './OpenAIResponseSettings';
import { ZSB_CHAT_BREAKER_ENCONDING } from 'constants/answerbank/defaults';

const Settings = () => {
  const {
    botName,
    botDescription,
    botMode,
    btnLoading,
    defaultAnswerEditor,
    emailRecipients,
    isConfirmDeleteBot,
    isThresholdLimitReached,
    importExportEnabled,
    handleBotNameInput,
    handleBotDescriptionInput,
    handleResetToDefault,
    toggleConfirmDeleteBot,
    handleDeleteBot,
    handleChangeThreshold,
    handleSaveChanges,
    handleChangeEmailRecipentsInput,
    hasInvalidEmail,
    minThreshold,
    maxThreshold,
    newDefaultAnswer,
    setdefaultAnswerEditor,
    setSelectedLanguage,
    updateFormattedAnswer,
    setTranslateLanguage,
    threshold,
    selectedLanguage,
    useOpenAI,
    setUseOpenAI,
    translateLanguage,
    isOpenAIPlanEnabled,
    openExportModal,
    DEFAULT_EXPORT_DETAILS,
    handleOpenExportModal,
    handleCloseExportModal,
    jid,
    setBotPersona,
    setBotRetrievalCount,
    botPersona,
    botRetrievalCount,
    isSettingsFormsHasError,
    toggleSendCallbackLog,
    isBotMailConfigEnabled,
  } = useSettings();
  const html = newDefaultAnswer.htmlString || newDefaultAnswer.show_html;
  const htmlString = Array.isArray(html)
    ? html?.join(ZSB_CHAT_BREAKER_ENCONDING)
    : String(html);

  const BotModeComponent = withFeatureFlag(
    BOT_MODE,
    <StyledFlexRowLeft>
      <label>{'Bot Mode'}:</label>
      <Input
        disabled
        value={capitalize(botMode || DEFAULT_BOT_MODE)}
        suffix={<DownOutlined />}
        full
      />
    </StyledFlexRowLeft>
  );

  return (
    <StyledRoot>
      <StyledContainer>
        <PageTitle text="Settings" type="secondary" />
        <StyledCardContainer className="card-container">
          <StyledTabs
            type="card"
            defaultActiveKey="1"
            tabBarExtraContent={
              <StyledTabActionButtons mode="horizontal">
                <Menu.Item>
                  <Button
                    onClick={handleResetToDefault}
                    variant="secondary"
                    value="Discard changes"
                    full
                  />
                </Menu.Item>
                <Menu.Item>
                  <StyledBtnSaveChanges
                    className="btn-general-save"
                    onClick={handleSaveChanges}
                    value="Save"
                    disabled={isSettingsFormsHasError}
                    full
                  />
                </Menu.Item>
              </StyledTabActionButtons>
            }
          >
            <StyledTabPane tab="General" key="1">
              <StyledColumnSections>
                <StyledFlexRowLeft>
                  <label>{`Bot name`}:</label>
                  <Input full value={botName} onChange={handleBotNameInput} />
                </StyledFlexRowLeft>
                <BotModeComponent />
                <StyledFlexRowLeft>
                  <label>{`Bot description`}:</label>
                  <TextArea
                    full
                    value={botDescription}
                    onChange={handleBotDescriptionInput}
                    disableSpan={true}
                  />
                </StyledFlexRowLeft>
              </StyledColumnSections>
            </StyledTabPane>
            <StyledTabPane1 tab="Response" key="3">
              <StyledBotInfoBlock>
                <StyledBotInfo>
                  <StyledBotInfoTitle>{'Bot Language'}</StyledBotInfoTitle>
                </StyledBotInfo>
                <LanguagePicker
                  value={selectedLanguage}
                  onChange={setSelectedLanguage}
                />
              </StyledBotInfoBlock>
              <StyledBotInfoBlock>
                <StyledBotInfo>
                  <StyledBotInfoTitle>
                    {'Response Threshold'}
                  </StyledBotInfoTitle>
                  <StyledBotInfoDescription>
                    {`Response Threshold is the distance score between the
                    question and answer below which the bot should provide the
                    default answer. The value depends on the quality of answers
                    in the answerbank.`}
                  </StyledBotInfoDescription>
                  <p>{`Recommended to be set between 0.06 - 0.12.`}</p>
                </StyledBotInfo>
                <StyledInput
                  type="number"
                  min={minThreshold}
                  max={maxThreshold}
                  step={0.01}
                  defaultValue={threshold}
                  onChange={handleChangeThreshold}
                  status={isThresholdLimitReached ? 'error' : ''}
                />
              </StyledBotInfoBlock>
              <StyledBotInfoBlock>
                <StyledBotInfo>
                  <StyledFlexColumn>
                    <StyledSpaceBetweenFlexCenter>
                      <TitleToolTip
                        title="Fall-back response"
                        tip="The Bot will respond with this message, if there is no
                    answer within the specific threshold"
                      />
                    </StyledSpaceBetweenFlexCenter>
                  </StyledFlexColumn>
                  <EditorAndViewer
                    editor={defaultAnswerEditor}
                    editedHTML={htmlString}
                    editedRichTextAnswer={htmlString}
                    answerId={jid}
                    isADefaultAnswer
                    updateFormattedAnswer={updateFormattedAnswer}
                    updateTabs={setdefaultAnswerEditor}
                  />
                </StyledBotInfo>
              </StyledBotInfoBlock>
              <StyledBotInfoBlock flexDirection={'column'}>
                <StyledColumnSections>
                  <StyledFlexRowLeft style={{ width: 'auto' }}>
                    <StyledLabel>{'Send Callback Log '}</StyledLabel>
                    <Switch
                      defaultChecked={isBotMailConfigEnabled}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={toggleSendCallbackLog}
                    />
                  </StyledFlexRowLeft>
                  {isBotMailConfigEnabled ? (
                    <StyledFlexRowLeft>
                      <StyledLabel>
                        {'Email Recipents  '}
                        <ToolTip title="Please separate email addresses with a comma">
                          <InfoCircleTwoTone />
                        </ToolTip>
                      </StyledLabel>{' '}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '80%',
                        }}
                      >
                        <Input
                          size="default"
                          full
                          style={{
                            width: '100%',
                            border: hasInvalidEmail
                              ? `1px solid ${cssVariables.errorBorder}`
                              : '',
                          }}
                          defaultValue={emailRecipients}
                          placeholder="Enter email address here"
                          onChange={handleChangeEmailRecipentsInput}
                        />
                      </div>
                    </StyledFlexRowLeft>
                  ) : null}
                </StyledColumnSections>
              </StyledBotInfoBlock>
              <StyledBotInfoBlock>
                {isOpenAIPlanEnabled ? (
                  <OpenAIResponseSettings
                    onToggleTranslateLanguage={setTranslateLanguage}
                    onToggleUseOpenAI={setUseOpenAI}
                    onChangeBotPersona={setBotPersona}
                    onChangeRetrievalCount={setBotRetrievalCount}
                    useOpenAI={useOpenAI}
                    translateLanguage={translateLanguage}
                    retrievalCount={botRetrievalCount}
                    botPersona={botPersona}
                  />
                ) : null}
              </StyledBotInfoBlock>

              <Button
                onClick={handleSaveChanges}
                value={`Save changes`}
                disabled={isSettingsFormsHasError}
              />
            </StyledTabPane1>
            <StyledTabPane1 tab="Access and Permission" key="4">
              <StyledBotInfoBlock>
                <StyledBotInfo>
                  <StyledBotInfoTitle>Delete bot</StyledBotInfoTitle>
                  <StyledBotInfoDescription>
                    {`Deleting this bot will remove all its knowledge in answer
                    bank. This action is irreversible.`}
                  </StyledBotInfoDescription>
                </StyledBotInfo>
                <Button
                  variant="error"
                  onClick={toggleConfirmDeleteBot}
                  startIcon={<DeleteOutlined />}
                  value="Delete Bot"
                />
              </StyledBotInfoBlock>
              <StyledBotInfoBlock>
                <StyledBotInfo>
                  <StyledBotInfoTitle>Export bot</StyledBotInfoTitle>
                </StyledBotInfo>
                {!importExportEnabled ? (
                  <LimitationTooltip
                    isBordered={true}
                    title="Upgrade to Export your bot"
                    disabledFeat={
                      <>
                        <DownloadOutlined /> {` Export Bot`}
                      </>
                    }
                  />
                ) : (
                  <StyledBtnAction
                    disabled={!importExportEnabled}
                    variant="secondary"
                    onClick={handleOpenExportModal}
                    startIcon={<DownloadOutlined />}
                    value="Export Bot"
                    loading={btnLoading}
                  />
                )}
                {openExportModal && (
                  <ExportModal
                    show={openExportModal}
                    onClose={handleCloseExportModal}
                    onloadSelected={DEFAULT_EXPORT_DETAILS}
                  />
                )}
              </StyledBotInfoBlock>
              {isConfirmDeleteBot && (
                <ConfirmDelete
                  itemTobeDeleted={botName}
                  modalTitle={'Confirm Delete Bot'}
                  show={isConfirmDeleteBot}
                  onOk={handleDeleteBot}
                  onCancel={toggleConfirmDeleteBot}
                />
              )}
            </StyledTabPane1>
          </StyledTabs>
          <StyledBotInfoBlock>
            <StyledBotInfo>
              <StyledBotInfoTitle>Discard changes</StyledBotInfoTitle>
              <StyledBotInfoDescription>
                {`You can reset all settings to default`}
              </StyledBotInfoDescription>
            </StyledBotInfo>
          </StyledBotInfoBlock>
        </StyledCardContainer>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Settings;
