import { userSelector } from 'selectors/user';
import useSelector from 'store/useSelector';

const useDashboard = () => {
  const user = useSelector(userSelector);

  return { user };
};

export default useDashboard;
