import React, { useCallback } from 'react';
import { Alert, Col, Spin, Tag } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import {
  PriceHeading,
  StyledPricing,
  PlanCard,
  PlanHeader,
  PlanCardContent,
  Features,
  StyledButton,
  BottomText,
  ContactUsSection,
  LeftContent,
  RightContent,
  ContactUsButton,
  StyledModal,
  SuccessMessage,
  ContactDetails,
} from './Pricing.style';
import RobotIcon from 'assets/images/robot-icon-black-small.svg';
import { ZSB_CONTACT } from 'constants/outboundLinks';
import usePricing from './hooks';
import {
  IS_PROCESSING_SUBSCRIPTION,
  START_TRIAL_DAYS_MESSAGE,
} from 'constants/plan';
import { detailedPlanFeatures } from 'pages/UserProfile/planDetails';

const Pricing = () => {
  const {
    handleSwitchPlan,
    isCancelledSubscription,
    isProcessing,
    isSubscriptionLoading,
    isSuccessModalVisible,
    isTrialSubscription,
    onCloseSubscriptionMessage,
    planDetails,
    planType,
    selectedPlan,
    setSuccessMessageVisible,
    trialDaysLeft,
  } = usePricing();

  const buttonPlanValue = useCallback(
    buttonPlan => {
      switch (buttonPlan) {
        case 'basic': {
          if (planType === 'free' && !isCancelledSubscription) {
            return 'Subscribe';
          } else if (planType === 'advanced' && !isCancelledSubscription) {
            return 'Downgrade';
          } else if (planType === buttonPlan && !isCancelledSubscription) {
            return 'Cancel Subscription';
          } else if (planType === buttonPlan && isCancelledSubscription) {
            return 'Renew';
          } else {
            return START_TRIAL_DAYS_MESSAGE;
          }
        }

        case 'advanced': {
          if (
            ['free', 'basic'].includes(planType) &&
            !isCancelledSubscription
          ) {
            return 'Upgrade';
          } else if (
            planType === 'basic' &&
            !isCancelledSubscription &&
            isTrialSubscription
          ) {
            return 'Upgrade';
          } else if (planType === buttonPlan && !isCancelledSubscription) {
            return 'Cancel Subscription';
          } else if (planType === buttonPlan && isCancelledSubscription) {
            return 'Renew';
          } else {
            return START_TRIAL_DAYS_MESSAGE;
          }
        }

        default:
          return null;
      }
    },
    [isTrialSubscription, planType, isCancelledSubscription]
  );

  return (
    <Spin
      spinning={isSubscriptionLoading}
      tip="Just a moment..."
      wrapperClassName="grid-spinner-wrapper"
    >
      <StyledPricing gutter={[16, 16]}>
        <Col
          xs={{ span: 22, pull: 0 }}
          md={{ span: 12, pull: 0 }}
          lg={{ span: 8, pull: 0 }}
          xl={{ span: 8, pull: 0 }}
          xxl={{ span: 8, pull: 1 }}
        >
          <PlanCard isCurrentPlan={planType === 'free'}>
            <PlanHeader isCurrentPlan={planType === 'free'} color="#6D757D">
              {'Starter'}{' '}
              {planType === 'free' && !isCancelledSubscription && (
                <Tag
                  color="green"
                  style={{
                    textAlign: 'center',
                    fontSize: '8px',
                    marginLeft: '1%',
                    textTransform: 'capitalize',
                  }}
                >
                  Current Plan
                </Tag>
              )}
            </PlanHeader>
            <PlanCardContent>
              <PriceHeading>
                {isProcessing && planType === 'free' ? (
                  <Alert message={IS_PROCESSING_SUBSCRIPTION} showIcon />
                ) : (
                  <>
                    <>{'Free'}</>
                    <p>{'forever'}</p>
                  </>
                )}
              </PriceHeading>
              <Features isCurrentPlan={planType === 'free'}>
                {detailedPlanFeatures(planDetails.free, 'free', true).map(
                  (feature, key) => (
                    <li key={key}>
                      <CheckOutlined /> {feature}
                    </li>
                  )
                )}
              </Features>
            </PlanCardContent>
          </PlanCard>
        </Col>
        <Col
          xs={{ span: 22, pull: 0 }}
          md={{ span: 12, pull: 0 }}
          lg={{ span: 8, pull: 0 }}
          xl={{ span: 8, pull: 0 }}
          xxl={{ span: 8, pull: 2 }}
        >
          <PlanCard isCurrentPlan={planType === 'basic'}>
            <PlanHeader color="#1F6BE4" isCurrentPlan={planType === 'basic'}>
              <>
                {isTrialSubscription && planType === 'basic'
                  ? `Basic Trial ${
                      !!trialDaysLeft && !isCancelledSubscription
                        ? trialDaysLeft + ' day(s) left'
                        : ''
                    }`
                  : 'Basic'}
                <span>
                  {isCancelledSubscription && planType === 'basic'
                    ? ` (CANCELLED)`
                    : null}
                </span>
              </>{' '}
              {planType === 'basic' && !isCancelledSubscription && (
                <Tag
                  color="green"
                  style={{
                    textAlign: 'center',
                    fontSize: '8px',
                    marginLeft: '1%',

                    textTransform: 'capitalize',
                  }}
                >
                  Current Plan
                </Tag>
              )}{' '}
            </PlanHeader>
            <PlanCardContent>
              <PriceHeading>
                {isProcessing && planType === 'basic' ? (
                  <Alert message={IS_PROCESSING_SUBSCRIPTION} showIcon />
                ) : (
                  <>
                    <span>$</span>
                    40
                    <p>per month</p>
                  </>
                )}
              </PriceHeading>
              <Features isCurrentPlan={planType === 'basic'}>
                {detailedPlanFeatures(planDetails.basic, 'basic', true).map(
                  (feature, key) => (
                    <li key={key}>
                      <CheckOutlined /> {feature}
                    </li>
                  )
                )}
              </Features>
              <StyledButton
                value={buttonPlanValue('basic')}
                isSelected={planType === 'basic'}
                hidden={planType !== 'basic' && isCancelledSubscription}
                onClick={() => handleSwitchPlan('basic')}
              />

              <BottomText>{'credit card required'}</BottomText>
            </PlanCardContent>
          </PlanCard>
        </Col>

        <Col
          xs={{ span: 22, pull: 0 }}
          md={{ span: 11, pull: 0 }}
          lg={{ span: 8, pull: 0 }}
          xl={{ span: 8, pull: 0 }}
          xxl={{ span: 8, pull: 3 }}
        >
          <PlanCard isCurrentPlan={planType === 'advanced'}>
            <PlanHeader color="#FED130" isCurrentPlan={planType === 'advanced'}>
              {isTrialSubscription && planType === 'advanced'
                ? `Advanced Trial ${
                    !!trialDaysLeft && !isCancelledSubscription
                      ? trialDaysLeft + ' day(s) left'
                      : ''
                  }`
                : 'Advanced'}
              <span>
                {isCancelledSubscription && planType === 'advanced'
                  ? ` (CANCELLED)`
                  : null}
              </span>{' '}
              {planType === 'advanced' && !isCancelledSubscription && (
                <Tag
                  color="green"
                  style={{
                    textAlign: 'center',
                    fontSize: '8px',
                    marginLeft: '1%',
                    textTransform: 'capitalize',
                  }}
                >
                  Current Plan
                </Tag>
              )}
            </PlanHeader>

            <PlanCardContent>
              <PriceHeading>
                {isProcessing && planType === 'advanced' ? (
                  <Alert message={IS_PROCESSING_SUBSCRIPTION} showIcon />
                ) : (
                  <>
                    <span>$</span>
                    200
                    <p>per month</p>
                  </>
                )}
              </PriceHeading>
              <Features isCurrentPlan={planType === 'advanced'}>
                {detailedPlanFeatures(
                  planDetails.advanced,
                  'advanced',
                  true
                ).map((feature, key) => (
                  <li key={key}>
                    <CheckOutlined /> {feature}
                  </li>
                ))}
              </Features>
              <StyledButton
                value={buttonPlanValue('advanced')}
                isSelected={planType === 'advanced'}
                onClick={() => handleSwitchPlan('advanced')}
                hidden={planType !== 'advanced' && isCancelledSubscription}
              />
              <BottomText>{'credit card required'}</BottomText>
            </PlanCardContent>
          </PlanCard>
        </Col>
      </StyledPricing>
      <ContactUsSection>
        <ContactDetails sm={24} md={18}>
          <h5>{`Can't find the right plan?`}</h5>
          <p>
            {`We'd be more than happy to find a solution for your organisation`}
          </p>
        </ContactDetails>
        <Col sm={24} md={4}>
          <a href={ZSB_CONTACT} target="_blank" rel="noreferrer">
            <ContactUsButton value="Contact Us" full />
          </a>
        </Col>
      </ContactUsSection>
      <StyledModal
        visible={isSuccessModalVisible}
        onOk={() => setSuccessMessageVisible(false)}
        onCancel={() => setSuccessMessageVisible(false)}
        height={'100%'}
        footer={null}
      >
        <SuccessMessage>
          <img src={RobotIcon} alt="start chat" width={75} />
          <p>
            Thank you for starting your free trial to{' '}
            {planType.charAt(0).toUpperCase() + planType.slice(1)} Plan :)
          </p>
          <span>With a {selectedPlan} plan, you now have:</span>
          <ul>
            {selectedPlan &&
              planDetails[selectedPlan].shortFeatures.map((sf, key) => (
                <li key={key}>{sf}</li>
              ))}
          </ul>
          <StyledButton
            value="Go to my bots"
            onClick={onCloseSubscriptionMessage}
          ></StyledButton>
        </SuccessMessage>
      </StyledModal>
    </Spin>
  );
};

export default Pricing;
// TODO: clean up the modal in this component;
