import { useContext, useState } from 'react';

import {
  currentIntegrationSettingsSettingPropSelector,
  replyBubbleGradientSelector,
  widgetThemeColorSelector,
} from 'selectors/bot/integration';
import {
  isUseWidgetIconRadioDisabledSelector,
  showHeaderImagePreviewSelector,
  widgetHeaderAvatarSelector,
  widgetHeaderAvatarTypeSelector,
  widgetAvatarResponseSelector,
  widgetAvatarResponseTypeSelector,
} from 'selectors/bot/integration/settings';
import {
  OPEN_IMAGE_VIEWER_MODAL,
  SET_REPLY_BUBBLE_GRADIENT,
  SET_HEADER_AVATAR,
  SET_WIDGET_CHAT_BUBBLE_COLOR,
  SET_WIDGET_FONT_SIZE,
  TOGGLE_AUTO_OPEN_WIDGET_SETTING,
  TOGGLE_SHOW_CLOSE_BUTTON,
  TOGGLE_WIDGET_SPEECH_TO_TEXT,
  TOGGLE_WIDGET_TEXT_TO_SPEECH,
  TOGGLE_WIDGET_TYPING_EXPERIENCE,
  SET_HEADER_AVATAR_TYPE,
  SET_RESPONSE_AVATAR_TYPE,
  SET_RESPONSE_AVATAR,
} from 'store/action';
import useSelector from 'store/useSelector';
import { Context } from 'store/store';
import { getBase64 } from 'utils';

const useAdvanceSettings = () => {
  const [, dispatch] = useContext(Context);

  const currentSettings = useSelector(
    currentIntegrationSettingsSettingPropSelector
  );
  const headerAvatar = useSelector(widgetHeaderAvatarSelector);
  const headerAvatarType = useSelector(widgetHeaderAvatarTypeSelector);
  const responseAvatarType = useSelector(widgetAvatarResponseTypeSelector);
  const responseAvatar = useSelector(widgetAvatarResponseSelector);
  const widgetThemeColor = useSelector(widgetThemeColorSelector);
  const showHeaderImagePreview = useSelector(showHeaderImagePreviewSelector);
  const replyBubbleGradient = useSelector(replyBubbleGradientSelector);
  const isUseWidgetIconsDisabled = useSelector(
    isUseWidgetIconRadioDisabledSelector
  );

  const [showColorPickerBubble, setShowColorPickerBubble] = useState(false);
  const [tempFontSize, setTempFontSize] = useState(currentSettings.fontSize);

  const handleChangeAvatarType = (position, value) => {
    if (position === 'header') {
      dispatch({
        type: SET_HEADER_AVATAR_TYPE,
        payload: value,
      });
    } else if (position === 'response') {
      dispatch({
        type: SET_RESPONSE_AVATAR_TYPE,
        payload: value,
      });
    }
  };

  const handlePreviewAvatar = position => {
    dispatch({
      type: OPEN_IMAGE_VIEWER_MODAL,
      payload: {
        source: position === 'header' ? headerAvatar : responseAvatar,
      },
    });
  };

  const handleChangeHeaderImage = async ({ file, fileList }) => {
    if (file instanceof File) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file);
      }
      dispatch({ type: SET_HEADER_AVATAR, payload: file });
    } else {
      dispatch({ type: SET_HEADER_AVATAR, payload: null });
    }
  };

  const handleRemoveImage = () => {
    dispatch({ type: SET_HEADER_AVATAR, payload: null });
  };

  const handleChangeResponseImage = async ({ file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file);
    }
    dispatch({ type: SET_RESPONSE_AVATAR, payload: file });
  };

  const handleSelectBubbleColor = color => {
    dispatch({
      type: SET_WIDGET_CHAT_BUBBLE_COLOR,
      payload: color.hex,
    });
  };

  const handleChangeReplyBubbleGradient = val => {
    if (typeof val === 'number') {
      dispatch({
        type: SET_REPLY_BUBBLE_GRADIENT,
        payload: val,
      });
    }
  };

  const handleChangeFontSize = evt => {
    setTempFontSize(evt.target.value);
  };

  const handleBlurFontSizeInput = () => {
    dispatch({
      type: SET_WIDGET_FONT_SIZE,
      payload: tempFontSize,
    });
  };

  const onToggleSectionChange = settingProp => val => {
    switch (settingProp) {
      case 'autoOpen':
        dispatch({
          type: TOGGLE_AUTO_OPEN_WIDGET_SETTING,
          payload: val,
        });
        break;

      case 'speechToText':
        dispatch({
          type: TOGGLE_WIDGET_SPEECH_TO_TEXT,
          payload: val,
        });
        break;

      case 'textToSpeech':
        dispatch({
          type: TOGGLE_WIDGET_TEXT_TO_SPEECH,
          payload: val,
        });
        break;

      case 'typingExperience':
        dispatch({
          type: TOGGLE_WIDGET_TYPING_EXPERIENCE,
          payload: val,
        });
        break;

      case 'showCloseButton':
        dispatch({
          type: TOGGLE_SHOW_CLOSE_BUTTON,
          payload: val,
        });
        break;

      default:
        break;
    }
  };

  return {
    currentSettings,
    handleChangeAvatarType,
    handleChangeHeaderImage,
    handleSelectBubbleColor,
    handlePreviewAvatar,
    showColorPickerBubble,
    setShowColorPickerBubble,
    widgetThemeColor,
    showHeaderImagePreview,
    handleChangeReplyBubbleGradient,
    replyBubbleGradient,
    handleChangeFontSize,
    handleBlurFontSizeInput,
    tempFontSize,
    onToggleSectionChange,
    isUseWidgetIconsDisabled,
    responseAvatar,
    responseAvatarType,
    headerAvatar,
    headerAvatarType,
    handleChangeResponseImage,
    handleRemoveImage,
  };
};

export default useAdvanceSettings;
