import PropTypes from 'prop-types';
import { isString } from 'lodash';

import useSelector from 'store/useSelector';
import { planTypeSelector, userPlanSelector } from 'selectors/plan';
import { isNumber, parseBoolean } from 'utils/dataTypes';
import { isDateValid } from 'utils/dates';
import { userReachedLimit } from 'utils/plan';
import moment from 'moment';

export const withPlanLimit = (
  flag,
  Component,
  FallbackComponent,
  currentNumber
) => {
  return props => {
    const plan = useSelector(userPlanSelector);
    const userPlanType = useSelector(planTypeSelector);
    const PLANS = ['free', 'basic', 'advanced'];
    if (
      isString(flag) &&
      PLANS.includes(flag.toLowerCase()) &&
      !userReachedLimit(flag, userPlanType)
    ) {
      return Component;
    }

    // Plan limit is boolean
    else if (parseBoolean(plan[flag])) {
      return FallbackComponent;
    }
    // Plan limit is number
    else if (isNumber(plan[flag]) && plan[flag] >= Number(currentNumber)) {
      return Component;
    }
    // Plan limit is date
    else if (
      isDateValid(plan[flag]) &&
      moment(plan[flag], 'yyyy-MM-dd').isAfter(moment(new Date(), 'yyyy-MM-dd'))
    ) {
      return Component;
    }
    // Plan limit is string
    else if (
      isString(plan[flag]) &&
      (plan[flag].toLowerCase() === flag || plan[flag].toLowerCase() === 'all')
    ) {
      return Component;
    }
    return FallbackComponent || null;
  };
};

withPlanLimit.propTypes = {
  Component: PropTypes.any.isRequired,
};
