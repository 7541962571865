import { useState, useEffect, useContext, useMemo } from 'react';
import { message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx/xlsx.mjs';
import { omit, snakeCase, isEqual } from 'lodash';

import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { isFreeUserSelector } from 'selectors/plan';
import { escapeCSV, generateRandomColor, withPrefixUUID } from 'utils';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { getBotIntegrations } from 'services/bots.service';
import {
  SET_INTEGRATIONS,
  SET_ANALYTICS_AGGREGATIONS_DATA,
  SET_ANALYTICS_GRAPH_DATA,
  SET_ANALYTICS_TABLE_DATA,
  SET_CALLBACK_MODAL_SESSION_DETAILS,
  VALIDATE_QUESTION_FROM_ANALYTICS,
  SET_PAGE_DETAILS,
} from 'store/action';
import {
  CORRECT_QUESTIONS,
  INCORRECT_QUESTIONS,
  PERFORMANCE_METRICS_DEFAULT_ANSWERS,
  PERFORMANCE_METRICS_INCORRECT_QUESTIONS,
  PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS,
  PERFORMANCE_METRICS_PATH_PREFIX,
  QUESTION_LOGS,
  QUESTION_WITH_NEGATIVE_FEEDBACK,
  QUESTION_WITH_POSITIVE_FEEDBACK,
  TABLE_VIEW,
} from 'constants/analytics';
import { extractESAnalyticsData, getGraphTimeInterval } from 'utils/analytics';
import { useQuery } from 'pages/useQuery';
import useSelector from 'store/useSelector';
import {
  analyticsAggregationsSelector,
  analyticsGraphSelector,
  analyticsTableSelector,
  analyticsAllQuestionsSelector,
  analyticsCategoriesFilterSelector,
  analyticsIntegrationFilterSelector,
} from 'selectors/bot/analytics';
import {
  UTCToLocal,
  convertToEndOfDay,
  convertToStartOfDay,
  getLocalTimeString,
} from 'utils/dates';
import { extractDisplayAnswer } from 'utils/answers';
import { botJIDSelector, isPageReadySelector } from 'selectors/bot';
import { AnswerRenderer } from 'components/AnswerRenderer';
import { DislikeTwoTone, LikeTwoTone } from '@ant-design/icons';
import { initialAnalyticsAggregationsState } from 'store/initialState';
import { isAnObject } from 'utils/dataTypes';
import { isMobileViewSelector } from 'selectors/layout';

const MAX_TABLE_ITEMS_PER_PAGE = 10;
const largeFont = { fontSize: 20 };

const useAnalytics = () => {
  const [state, dispatch] = useContext(Context);
  const isFreeUser = useSelector(isFreeUserSelector);
  const allQuestions = useSelector(analyticsAllQuestionsSelector);
  const aggregations = useSelector(analyticsAggregationsSelector);
  const categoryFilter = useSelector(analyticsCategoriesFilterSelector);
  const integrationFilterOptions = useSelector(
    analyticsIntegrationFilterSelector
  );
  const tableData = useSelector(analyticsTableSelector);
  const chartData = useSelector(analyticsGraphSelector);
  const isPageReady = useSelector(isPageReadySelector);
  const isMobileView = useSelector(isMobileViewSelector);
  const jid = useSelector(botJIDSelector);
  const {
    bot: { integrations },
    token,
    sentinel,
  } = state;
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const { hash } = location;

  const WITH_PREFIX_BOT_JID = withPrefixUUID(jid || location.pathname);
  const integrationFilter = query.get('integration');
  const categoryQueryParamsFilter = query.get('category');

  const [showAddQuestionToTestSuiteModal, setShowAddQuestionToTestSuiteModal] =
    useState(false);
  const [questionForTestSuite, setQuestionForTestSuite] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [tableSpinner, setTableSpinner] = useState(false);
  const [graphSpinner, setGraphSpinner] = useState(false);
  const [selectedSessionID, setSelectedSessionID] = useState(null);
  const [selectedInteractionID, setSelectedInteractionID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [callbackInteractionIDs, setCallbackInteractionIDs] = useState([]);
  const [sessionFilters, setSessionFilters] = useState(null);
  const startDateInMS = new Date().setDate(new Date().getDate() - 7);
  const startDate = new Date(startDateInMS).toISOString();
  const todayInMS = new Date();
  const todayISO = new Date(todayInMS).toISOString();
  const [dateFilter, setDateFilter] = useState({
    startDate: startDate,
    endDate: todayISO,
  });
  const [selectedIntegrationChannel, setSelectedIntegrationChannel] = useState(
    []
  );
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sessionFilteredInfo, setSessionFilteredInfo] = useState(null);
  const [params, setParams] = useState({
    size: MAX_TABLE_ITEMS_PER_PAGE,
    from: 0,
    page: 1,
    sort: {
      datetime: 'desc',
    },
  });

  const [showPagination, setShowPagination] = useState(true);
  const [maximizeTableColumn, setMaximizeTableColumn] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [showConfirmQuestionValidation, setShowConfirmQuestionValidation] =
    useState(false);
  const [validationDetails, setValidationDetails] = useState(null);
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedPanelDetails, setSelectedPanelDetails] = useState(null);
  const [sequenceActivePannel, setSequenceActivePannel] =
    useState('generalInfo');
  const smallFont = { fontSize: 12 };

  const isPerformanceMetricsView = useMemo(() => {
    return hash?.includes(PERFORMANCE_METRICS_PATH_PREFIX) ? true : false;
  }, [hash]);

  const tableViewList = useMemo(() => {
    return isPerformanceMetricsView
      ? TABLE_VIEW.KEY_METRICS_LIST
      : TABLE_VIEW.QUESTION_LOGS_LIST;
  }, [isPerformanceMetricsView]);

  const selectedTableView = useMemo(
    () =>
      query && query.get('tableView')
        ? query.get('tableView')
        : hash?.includes(QUESTION_LOGS)
        ? QUESTION_LOGS
        : PERFORMANCE_METRICS_DEFAULT_ANSWERS,
    [query, hash]
  );

  const handleOnSelect = async selectId => {
    await history.replace({ ...location, search: `?tableView=${selectId}` });
    // Fetch all and updated data in the db onchange of filter
    fetchESAnalyticsTableData(dateFilter.startDate, dateFilter.endDate, {
      ...params,
      selectedTableView: selectId,
    });
    fetchGraphData(dateFilter.startDate, dateFilter.endDate, {
      ...params,
      selectedTableView: selectId,
    });
  };

  const handleHashChange = () => {
    const { validStartDate, validEndDate } = parseToStartAndEndOfDayISODate();
    if (
      !isPerformanceMetricsView ||
      (isPerformanceMetricsView &&
        isEqual(aggregations, initialAnalyticsAggregationsState))
    ) {
      fetchESAnalyticsAggregationsData(validStartDate, validEndDate, params);
    }
    fetchESAnalyticsTableData(validStartDate, validEndDate, params);
    fetchGraphData(validStartDate, validEndDate, params);
  };

  const getMetricsParams = params => {
    // dont use query param `selectedTableView` if `params.selectedTableViewExists`
    if (params.selectedTableView) {
      const keyMetricsParams = {
        feedback: params.selectedTableView.includes(
          PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS
        )
          ? 0
          : params.selectedTableView.includes(QUESTION_WITH_POSITIVE_FEEDBACK)
          ? 1
          : null,
        answerType:
          params.selectedTableView === PERFORMANCE_METRICS_DEFAULT_ANSWERS
            ? 'default_answer'
            : null,
        validation:
          params.selectedTableView === INCORRECT_QUESTIONS
            ? 0
            : params.selectedTableView === CORRECT_QUESTIONS
            ? 1
            : null,
      };
      return {
        ...params,
        ...keyMetricsParams,
      };
    } else {
      const keyMetricsParams = {
        feedback: selectedTableView.includes(
          PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS
        )
          ? 0
          : selectedTableView.includes(QUESTION_WITH_POSITIVE_FEEDBACK)
          ? 1
          : null,
        answerType:
          selectedTableView === PERFORMANCE_METRICS_DEFAULT_ANSWERS
            ? 'default_answer'
            : null,
        validation: selectedTableView.includes(INCORRECT_QUESTIONS)
          ? 0
          : selectedTableView === CORRECT_QUESTIONS
          ? 1
          : null,
      };
      return {
        ...params,
        ...keyMetricsParams,
      };
    }
  };

  useEffect(() => {
    if (hash && token && jid) {
      handleHashChange();
    }
  }, [hash]);

  const handleCloseAddTestSuiteModal = () => {
    setShowAddQuestionToTestSuiteModal(false);
  };

  const handleDateFilterChange = (startDate, endDate) => {
    setParams({
      ...params,
      from: 0,
      page: 1,
    });
    setDateFilter({
      startDate,
      endDate,
    });
  };

  const handleChangeDataFilterButton = () => {
    const { validStartDate, validEndDate } = parseToStartAndEndOfDayISODate();
    fetchESAnalyticsAggregationsData(validStartDate, validEndDate, params);
    fetchGraphData(validStartDate, validEndDate, params);
    fetchESAnalyticsTableData(validStartDate, validEndDate, params);
  };

  const handleChangeIntegrationSelection = (val, args) => {
    setParams({
      ...params,
      from: 0,
      page: 1,
    });
    setSelectedIntegrationChannel(args);
  };

  const handleChangeCategories = (val, args) => {
    setParams({
      ...params,
      from: 0,
      page: 1,
    });
    setSelectedCategories(args);
  };

  const fetchGraphData = async (startDate, endDate, fetchParams) => {
    setGraphSpinner(true);

    const payloadParams = getMetricsParams(fetchParams);
    const validStartDate = convertToStartOfDay(startDate);
    const validEndDate = convertToEndOfDay(endDate);
    const categoryJids = getKeyFromList(selectedCategories);
    const integrationIds = getKeyFromList(selectedIntegrationChannel);

    const dateInterval = getGraphTimeInterval(startDate, endDate);

    try {
      const ESResAggregation = await apiService.getElasticByIntervalLogDate(
        sentinel,
        jid,
        validStartDate,
        validEndDate,
        dateInterval,
        integrationIds,
        categoryJids,
        token,
        { ...payloadParams, for_aggs: true }
      );
      const { error } = ESResAggregation.data.report[0];
      if (error) {
        throw new Error('No records found');
      }
      dispatch({
        type: SET_ANALYTICS_GRAPH_DATA,
        payload: {
          data: ESResAggregation.data.report[0],
          // add start and end date for future use
          // and to get date interval
          startDate,
          endDate,
        },
      });
    } catch (error) {
      setGraphSpinner(false);
      return message.error(typeof err === 'string' ? error : error.message);
    }
    setGraphSpinner(false);
  };

  const getKeyFromList = list => {
    return list.map(item => item.key);
  };

  const fetchESAnalyticsAggregationsData = async (
    startDate,
    endDate,
    fetchParams
  ) => {
    const payloadParams =
      // we dont want to fetch filtered aggregations
      selectedTableView || fetchParams.selectedTableView
        ? // so we're passing empty payload param
          {}
        : getMetricsParams(fetchParams);
    const validStartDate = convertToStartOfDay(startDate);
    const validEndDate = convertToEndOfDay(endDate);
    const categoryJids = getKeyFromList(selectedCategories);
    const integrationIds = getKeyFromList(selectedIntegrationChannel);
    const ESResAggregation = await apiService.getElasticLogDateFilter(
      sentinel,
      jid,
      validStartDate,
      validEndDate,
      token,
      integrationIds,
      categoryJids,
      { ...payloadParams, for_aggs: true }
    );

    dispatch({
      type: SET_ANALYTICS_AGGREGATIONS_DATA,
      payload: {
        data: ESResAggregation.data.report[0],
        filters: {
          startDate,
          endDate,
          categories: categoryJids,
          integrations: integrationIds,
        },
      },
    });
  };

  const fetchESAnalyticsTableData = async (startDate, endDate, fetchParams) => {
    setTableSpinner(true);
    try {
      const payloadParams = getMetricsParams(fetchParams);
      const validStartDate = convertToStartOfDay(startDate);
      const validEndDate = convertToEndOfDay(endDate);
      const categoryJids = getKeyFromList(selectedCategories);
      const integrationIds = getKeyFromList(selectedIntegrationChannel);
      const ESResAggregation = await apiService.getElasticLogDateFilter(
        sentinel,
        jid,
        validStartDate,
        validEndDate,
        token,
        integrationIds,
        categoryJids,
        {
          ...omit(payloadParams, 'selectedTableView'),
          total: aggregations.totalQuestions,
        }
      );
      dispatch({
        type: SET_ANALYTICS_TABLE_DATA,
        payload: {
          data: ESResAggregation.data.report[0],
          startDate,
          endDate,
        },
      });
    } catch (error) {}
    setTableSpinner(false);
  };

  const handleShowSessionCallbacks = async row => {
    setSelectedSessionID(row.sessionID);
    dispatch({
      type: SET_CALLBACK_MODAL_SESSION_DETAILS,
      payload: {
        data: row,
        sessionId: row.sessionID,
      },
    });
    setShowModal(true);
  };

  // switched from state to memoised sessionData
  const sessionData = useMemo(() => {
    switch (selectedTableView) {
      case PERFORMANCE_METRICS_DEFAULT_ANSWERS:
      case QUESTION_LOGS:
        return allQuestions;
      case PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS:
        return tableData?.negativeFeedbackQuestions;
      case PERFORMANCE_METRICS_INCORRECT_QUESTIONS:
        return tableData?.incorrectQuestions;
      default:
        return [];
    }
  }, [selectedTableView, tableData]);

  const handleCloseSessionCallbacks = () => {
    setSelectedSessionID(null);
    setCallbackInteractionIDs([]);
    setShowModal(false);
  };

  const handleCloseInteractionFeedback = () => {
    setSelectedInteractionID(null);
  };

  const fetchAllIntegrations = async () => {
    try {
      const allIntegrations = await getBotIntegrations(
        sentinel,
        WITH_PREFIX_BOT_JID,
        token
      );
      dispatch({
        type: SET_INTEGRATIONS,
        payload: allIntegrations,
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (
      isPageReady &&
      sentinel &&
      !isFreeUser &&
      typeof integrations === 'undefined'
    ) {
      fetchAllIntegrations();
    }
  }, [isPageReady, isFreeUser, sentinel]);

  useEffect(() => {
    const questions = allQuestions;
    const tagsList = [
      {
        tag: 'All',
        count: questions?.length,
      },
    ];
    const tagsTree = {};
    const tagColorTree = {};
    const questionList = [];

    if (questions && questions.length) {
      for (const question of questions) {
        if (!tagsTree[question.question_group]) {
          tagsTree[question.question_group] = 1;
        } else {
          tagsTree[question.question_group]++;
        }
        questionList.push({
          ...question,
          selected: false,
        });
      }
    }

    for (const tag of Object.keys(tagsTree)) {
      tagsList.push({
        tag,
        count: tagsTree[tag],
      });

      tagColorTree[tag] = '#' + generateRandomColor();
    }
  }, []);

  useEffect(() => {
    if (categoryQueryParamsFilter) {
      setSelectedCategories([categoryQueryParamsFilter]);
      setFilters([...filters, 'category']);
    }
  }, [categoryQueryParamsFilter]);

  useEffect(() => {
    if (!filters?.length) {
      setSelectedCategories([]);
      setSelectedIntegrationChannel([]);
    }
  }, [filters]);

  const whiteListLogs = (excludedKeys, obj) => {
    excludedKeys.forEach(function (key) {
      delete obj[key];
    });
    return obj;
  };

  const handleChangeFilters = (e, args) => {
    if (e?.key) {
      if (filters.includes(e.key)) {
        setFilters(filters.filter(f => f !== e.key));
      } else {
        setFilters([...filters, e.key]);
      }
    }
  };

  const handleXLSXDownloadQlogs = () => {
    const excludedKeys = ['type', 'logType', 'key'];
    const questions = allQuestions;
    const xlsxData = questions
      .map(item => {
        item['sessionID'] = !item?.sessionID ? 'N/A' : item.sessionID;
        item['visitorID'] = !item?.visitorID ? 'N/A' : item.visitorID;
        item['channel'] = !item?.channel ? 'N/A' : item.channel;
        item['interactionID'] = !item?.interactionID
          ? 'N/A'
          : item.interactionID;
        item['feedback'] =
          item?.feedback === 0
            ? 'Dislike'
            : item.feedback === 1
            ? 'Like'
            : 'N/A';

        return whiteListLogs(excludedKeys, item);
      })
      // rename keys
      .map(i => {
        return {
          'Session ID': i.sessionID || 'N/A',
          Question: i.question,
          'Visitor ID': i.visitorID || 'N/A',
          Authenticated: i.isAuthenticated || 'N/A',
          Feedback: i.feedback || 'N/A',
          Date: i.date,
          Channel: i.channel || 'N/A',
          'Answer Text': i.answer?.text,
          'Answer Show Text': extractDisplayAnswer(i.answer),
        };
      });

    const worksheet = XLSX.utils.json_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ZSB Question Logs');
    XLSX.writeFile(workbook, 'zsb-qlogs.xlsx');
  };

  const handleCSVDownloadQlogs = async () => {
    setPageLoading(true);
    const { validStartDate, validEndDate } = parseToStartAndEndOfDayISODate();
    const categoryJids = getKeyFromList(selectedCategories);
    const integrationIds = getKeyFromList(selectedIntegrationChannel);
    const payloadParams = getMetricsParams(params);
    const ESRes = await apiService.getElasticLogDateFilter(
      sentinel,
      jid,
      validStartDate,
      validEndDate,
      token,
      integrationIds,
      categoryJids,
      { ...payloadParams, size: aggregations?.totalQuestions }
    );

    let unpaginatedData = extractESAnalyticsData(
      ESRes.data.report[0]?.hits?.hits
    );

    if (selectedTableView === QUESTION_WITH_POSITIVE_FEEDBACK) {
      unpaginatedData = unpaginatedData.filter(i => i.feedback === 1);
    } else if (selectedTableView === QUESTION_WITH_NEGATIVE_FEEDBACK) {
      unpaginatedData = unpaginatedData.filter(i => i.feedback === 0);
    }

    const titleColumn = [
      'Session ID,',
      'Question,',
      'Category,',
      'Visitor ID,',
      'Authenticated,',
      'Feedback,',
      'Date,',
      'Channel,',
      'Answer Text,',
      'Display Answer,',
      '\n',
    ];
    const fileData = unpaginatedData.map(item => {
      const sessionID = !item?.sessionID ? 'N/A' : item.sessionID;
      const visitorID = !item?.visitorID ? 'N/A' : item.visitorID;
      const isAuthenticated = !item?.isAuthenticated
        ? 'N/A'
        : item.isAuthenticated;
      const channel = !item?.channel ? 'N/A' : item.channel;
      const category = !item?.category?.name ? 'N/A' : item.category?.name;
      const feedback =
        item?.feedback === 0 ? 'Dislike' : item.feedback === 1 ? 'Like' : 'N/A';

      return (
        sessionID +
        ',' +
        escapeCSV(`${item.question}`) +
        ',' +
        category +
        ',' +
        visitorID +
        ',' +
        isAuthenticated +
        ',' +
        feedback +
        ',' +
        item.date +
        ',' +
        channel +
        ',' +
        escapeCSV(`${item.answer?.text}`) +
        ',' +
        escapeCSV(`${extractDisplayAnswer(item.answer)}`) +
        '\n'
      );
    });
    const blob = new Blob(['\ufeff', ...titleColumn, ...fileData], {
      type: 'text/csv',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'zsb-qlogs.csv';
    link.href = url;
    link.click();
    setPageLoading(false);
  };

  const handleClickedQuality = async validation => {
    const tableDiv = document.getElementById('table');
    tableDiv.scrollIntoView({ behavior: 'smooth' }, true);
    handleOnSelect(
      validation === 1
        ? CORRECT_QUESTIONS
        : validation === 0
        ? INCORRECT_QUESTIONS
        : QUESTION_LOGS
    );
  };

  const handleChangePageNumber = pageNumber => {
    const newParams = {
      ...params,
      size: params.size,
      page: pageNumber,
      from: pageNumber * params.size - params.size,
    };
    setParams(newParams);
    fetchESAnalyticsTableData(
      dateFilter.startDate,
      dateFilter.endDate,
      newParams
    );
  };

  const parseToStartAndEndOfDayISODate = () => {
    const { startDate, endDate } = dateFilter;

    var validStartDate = convertToStartOfDay(startDate);
    var validEndDate = convertToEndOfDay(endDate);

    return { validStartDate, validEndDate };
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      size: MAX_TABLE_ITEMS_PER_PAGE,
      sort: {
        [sortFields(snakeCase(sorter.field))]:
          sorter.order === 'ascend' ? 'asc' : 'desc',
      },
    };
    setSessionFilteredInfo(filters.sessionID);
    setParams(newParams);
    fetchESAnalyticsTableData(
      dateFilter.startDate,
      dateFilter.endDate,
      newParams
    );
  };

  const sortFields = field => {
    switch (field) {
      case 'visitorID':
        return `metadata.${field}`;
      case 'date':
        return `datetime`;
      case 'validation':
        return `validation`;
      case 'channel':
        return `metadata.${field}.keyword`;
      case 'sessionID':
        return `metadata.${field}.keyword`;
      default:
        return `${field}.raw`;
    }
  };

  const expandTable = logData => {
    const logDetails = [
      {
        field: 'Question',
        value: logData.question,
        forMobileViewOnly: false,
      },
      {
        field: 'Visitor ID',
        value: (
          <div style={smallFont}>
            {!!logData?.visitorID ? logData?.visitorID : 'N/A'}
          </div>
        ),
        forMobileViewOnly: true,
      },
      {
        field: 'Date',
        value: () => {
          const localDate = UTCToLocal(new Date(logData?.date));
          return <div style={smallFont}>{getLocalTimeString(localDate)}</div>;
        },
        forMobileViewOnly: true,
      },
      {
        field: 'Channel',
        value: <div style={smallFont}>{logData?.channel || 'N/A'}</div>,
        forMobileViewOnly: true,
      },
      {
        field: 'Category',
        value: () => {
          const categoryName =
            logData?.category && typeof logData?.category === 'string'
              ? logData?.category
              : isAnObject(logData?.category) && logData?.category.name
              ? logData?.category?.name
              : 'N/A';
          return <div style={smallFont}>{categoryName}</div>;
        },
        forMobileViewOnly: true,
      },
      {
        field: 'Source',
        value: logData?.answer?.url || logData?.answer?.text,
        forMobileViewOnly: false,
      },
      {
        field: 'Display Answer',
        value: (
          <AnswerRenderer
            answer={logData.answer}
            showDisplayAnswer
            showInChatBubble
          />
        ),
        forMobileViewOnly: false,
      },
      {
        field: 'Answer Type',
        value: logData.type,
        forMobileViewOnly: false,
      },
      {
        field: 'Authenticated',
        value: logData.isAuthenticated && logData.isAuthenticated,
        forMobileViewOnly: false,
      },
      {
        field: 'Feedback',
        value:
          logData.feedback === 1 ? (
            <LikeTwoTone style={largeFont} />
          ) : logData.feedback === 0 ? (
            <DislikeTwoTone style={largeFont} twoToneColor="red" />
          ) : null,
        forMobileViewOnly: false,
      },
      {
        field: 'Intents',
        value: logData.intent && JSON.stringify(logData.intent),
        forMobileViewOnly: false,
      },
      {
        field: 'References',
        value: logData.references && JSON.stringify(logData.references),
        forMobileViewOnly: false,
      },
      {
        field: 'Category',
        value: logData.category?.name || logData.category?.label,
        forMobileViewOnly: false,
      },
      {
        field: 'Channel',
        value: logData.channel,
        forMobileViewOnly: false,
      },
      {
        field: 'Sentiments',
        value: JSON.stringify(logData.sentiments),
        forMobileViewOnly: false,
      },
      {
        field: 'Named Entities',
        value: JSON.stringify(logData.named_entities),
        forMobileViewOnly: false,
      },
      {
        field: 'Detected Language',
        value: logData.detected_language,
        forMobileViewOnly: false,
      },
      {
        field: 'Target Language',
        value: logData.target_language,
        forMobileViewOnly: false,
      },
      {
        field: 'Translation',
        value: logData.translation,
        forMobileViewOnly: false,
      },
      {
        field: 'Translation Message',
        value: JSON.stringify(logData.translation_messages),
        forMobileViewOnly: false,
      },
      {
        field: 'Asking Messages',
        value: JSON.stringify(logData.asking_messages),
        forMobileViewOnly: false,
      },
      {
        field: 'Visitor ID',
        value: logData.visitorID,
        forMobileViewOnly: false,
      },
      {
        field: 'Sequence',
        value: logData.sequence,
        forMobileViewOnly: false,
      },
    ];
    Object.keys(logDetails).forEach(key => {
      if (
        logDetails[key]?.value === null ||
        logDetails[key]?.value === undefined
      ) {
        delete logDetails[key];
      }
    });
    setSelectedLog(logDetails);
    setMaximizeTableColumn(true);
  };

  const handlePanelChange = (selectedPanel, value) => {
    setSequenceActivePannel(selectedPanel);
    setSelectedPanelDetails(null);
    if (selectedPanel !== 'generalInfo') {
      const sequenceList = value?.find(
        sequence => sequence.type === selectedPanel
      );
      if (sequenceList) {
        const sequenceDetails = Object.entries(sequenceList)?.map(seq => ({
          field: seq[0],
          value: JSON.stringify(seq[1]),
        }));
        setSelectedPanelDetails(
          sequenceDetails.filter(
            seq => seq?.field !== 'type' && seq?.value !== 'null'
          )
        );
      }
    }
  };

  const handleShowMarkQuestionModal = (questionID, validation) => {
    setValidationDetails({
      source_id: questionID,
      validation: validation,
    });
    setShowConfirmQuestionValidation(true);
  };

  const handleValidateQuestion = async () => {
    try {
      setModalIsLoading(true);
      const response = await apiService.updateQuestionValidation(
        sentinel,
        jid,
        token,
        validationDetails
      );

      if (response.status === 200 || response.data.success) {
        setValidationDetails(null);
        dispatch({
          type: VALIDATE_QUESTION_FROM_ANALYTICS,
          payload: { data: validationDetails },
        });
        setShowConfirmQuestionValidation(false);
        setModalIsLoading(false);
        await message.success('Question validation successfully updated.');
      } else {
        setModalIsLoading(false);
        setValidationDetails(null);
        setShowConfirmQuestionValidation(false);
        throw new Error(DEFAULT_ERROR_MESSAGE);
      }
    } catch (error) {
      setModalIsLoading(false);
      setValidationDetails(null);
      setShowConfirmQuestionValidation(false);
      message.error(error.message || 'Something went wrong. Try again later.');
    }
  };

  useEffect(() => {
    setShowPagination(
      selectedTableView === QUESTION_LOGS ||
        selectedTableView === CORRECT_QUESTIONS ||
        selectedTableView === INCORRECT_QUESTIONS ||
        selectedTableView === PERFORMANCE_METRICS_INCORRECT_QUESTIONS ||
        selectedTableView === PERFORMANCE_METRICS_DEFAULT_ANSWERS ||
        selectedTableView === PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS ||
        selectedTableView === QUESTION_WITH_POSITIVE_FEEDBACK ||
        selectedTableView === QUESTION_WITH_NEGATIVE_FEEDBACK
    );
  }, [selectedTableView]);

  return {
    chartData,
    callbackInteractionIDs,
    dateFilter,
    handleChangeDataFilterButton,
    handleChangeIntegrationSelection,
    handleChangeCategories,
    handleCloseAddTestSuiteModal,
    handleCloseInteractionFeedback,
    handleCloseSessionCallbacks,
    handleDateFilterChange,
    handleCSVDownloadQlogs,
    handleXLSXDownloadQlogs,
    handleOnSelect,
    handleShowSessionCallbacks,
    handleClickedQuality,
    handleChangePageNumber,
    integrationFilterOptions,
    isFreeUser,
    pageLoading,
    questionForTestSuite,
    selectedInteractionID,
    selectedTableView,
    selectedSessionID,
    sessionFilters,
    showAddQuestionToTestSuiteModal,
    showModal,
    tableSpinner,
    aggregations,
    params,
    showPagination,
    handleTableChange,
    sessionFilteredInfo,
    isPerformanceMetricsView,
    tableViewList,
    maximizeTableColumn,
    selectedLog,
    setMaximizeTableColumn,
    expandTable,
    showConfirmQuestionValidation,
    handleShowMarkQuestionModal,
    validationDetails,
    setShowConfirmQuestionValidation,
    handleValidateQuestion,
    modalIsLoading,
    sessionData,
    allQuestions,
    tableData,
    categoryFilter,
    graphSpinner,
    isPageReady,
    selectedCategories,
    showFilterDropdown,
    setShowFilterDropdown,
    filters,
    setFilters,
    handleChangeFilters,
    handlePanelChange,
    sequenceActivePannel,
    setSequenceActivePannel,
    selectedPanelDetails,
    isMobileView,
    smallFont,
  };
};

export default useAnalytics;
