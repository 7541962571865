import { createSelector } from 'reselect';
import { currentBotCountSelector, maxBotCountSelector } from './plan';

export const allBotsSelector = state => state?.bots || [];
export const botModesSelector = state => state?.botModes || [];

export const botsExceedsLimitSelector = createSelector(
  currentBotCountSelector,
  maxBotCountSelector,
  (currentBotCount, maxBotCount) => currentBotCount > maxBotCount
);

export const botsRemainingSelector = createSelector(
  currentBotCountSelector,
  maxBotCountSelector,
  (currentBotCount, maxBotCount) => maxBotCount - currentBotCount
);

export const totalExceedingBotsSelector = createSelector(
  currentBotCountSelector,
  maxBotCountSelector,
  (currentBotCount, maxBotCount) => currentBotCount - maxBotCount
);

const botJIdSelector = (_state, botJID) => {
  return botJID;
};

export const showWeakBotStatSelector = createSelector(
  allBotsSelector,
  botJIdSelector,
  (allBots, botJID) => {
    const matchBot = allBots?.find(bot => bot?.jid === botJID);
    return matchBot?.showWeakStat || false;
  }
);
