import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { Dropdown, Avatar, Progress, Popover, Modal, Menu, Layout } from 'antd';
import { Context } from 'store/store';
import { header } from 'constants/header';
import ROUTES from 'constants/routes';
import SmallLogoWhite from 'assets/images/zeroshotbot-header-white-logo.svg';
import {
  CaretDownOutlined,
  PoweroffOutlined,
  SettingOutlined,
  AppstoreOutlined,
  LockOutlined,
} from '@ant-design/icons';
import Button from 'components/Button';
import { STOP_IMPERSONATING_USER } from 'store/action';
import {
  StyledUserDetailsMenu,
  StyledUserDetailsMenuItem,
  StyledUserDetailsBlock,
  StyledUserDetails,
  StyledUserName,
  StyledUserEmail,
  StyledPlanDetails,
  StyledUserMenuDivider,
  StyledRouteItem,
  StyledHeader,
  StyledHeaderTitle,
  StyledHeaderLogo,
  StyledHeaderMenu,
  StyledPageMenu,
  StyledDropdownWrapper,
  StyledHeaderName,
  StyledProfileAvatar,
  StyledButtonContainer,
  StyledButtonSpan,
  StyledTransactionParagraph,
} from './Header.style';
import MenuItem from 'components/MenuItem';
import { ImpersonationBanner } from 'components/ImpersonationBanner';
import useSelector from 'store/useSelector';
import { maxTransactionCountSelector, planTypeSelector } from 'selectors/plan';
import {
  impersonatedUserSelector,
  isAdminSelector,
  isImpersonatingSelector,
} from 'selectors/admin';
import {
  currentTransactionCountSelector,
  transactionPercentageSelector,
} from 'selectors/subscription';
import { isExternalPageSelector } from 'selectors/user';

const { Sider, Content } = Layout;
const { confirm } = Modal;
const PAGE_BASE_URL = process.env.REACT_APP_PAGE_BASE_URL;

const Header = ({ title, isLogout }) => {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const planType = useSelector(planTypeSelector);
  const impersonatedUser = useSelector(impersonatedUserSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isImpersonating = useSelector(isImpersonatingSelector);
  const currentTransactionCount = useSelector(currentTransactionCountSelector);
  const maxTransactionCount = useSelector(maxTransactionCountSelector);
  const isExternalPage = useSelector(isExternalPageSelector);
  const transactionPercentage = useSelector(transactionPercentageSelector);
  const userName = useMemo(() => {
    return isImpersonating
      ? impersonatedUser.name
      : state.user && (state.user.firstName || state.user.name || 'Guest');
  }, [impersonatedUser.name, isImpersonating, state.user]);

  const email = useMemo(() => {
    return isImpersonating ? impersonatedUser.email : state?.user?.email;
  }, [impersonatedUser.email, isImpersonating, state?.user?.email]);

  const handleLogout = () => {
    history.push(ROUTES.LOGOUT);
  };

  const goToHome = e => {
    if (isAdmin) {
      if (isImpersonating) {
        confirm({
          content: (
            <p>
              Impersonating user will end, are you sure you want to go back to
              admin page?
            </p>
          ),
          onOk() {
            stopImpersonation();
            history.push(ROUTES.ADMIN_PAGE);
          },
          onCancel() {
            return;
          },
        });
      } else {
        history.push(ROUTES.ADMIN_PAGE);
      }
    } else {
      history.push(ROUTES.HOME);
    }
  };

  const stopImpersonation = async () => {
    await dispatch({
      type: STOP_IMPERSONATING_USER,
    });
  };

  const getTransactionCountParagraph = () => {
    if (currentTransactionCount > maxTransactionCount) {
      const excessTransaction = Math.abs(
        currentTransactionCount - maxTransactionCount
      );
      return `You have gone above your transaction limit this month. Excess transaction count: ${excessTransaction} `;
    } else {
      return `You have used ${currentTransactionCount} of ${maxTransactionCount} ${
        planType === 'free' ? 'free' : ''
      } transactions per month.`;
    }
  };

  const UserOptions = (
    <StyledUserDetailsMenu>
      <StyledUserDetailsMenuItem key={1}>
        <StyledUserDetailsBlock>
          <Avatar size="large">{userName[0].toUpperCase()}</Avatar>
          <StyledUserDetails>
            <StyledUserName>{userName}</StyledUserName>
            <StyledUserEmail>{email}</StyledUserEmail>
          </StyledUserDetails>
        </StyledUserDetailsBlock>
      </StyledUserDetailsMenuItem>
      <StyledPlanDetails key={2}>
        <div>
          <strong>
            {planType.charAt(0).toUpperCase() + planType.slice(1)} Plan
          </strong>
        </div>
        <div>
          <Progress
            percent={transactionPercentage}
            showInfo={false}
            strokeColor="#00BD57"
            trailColor="#E8F0FD"
          />
          <StyledTransactionParagraph isRed={transactionPercentage > 80}>
            {getTransactionCountParagraph()}
          </StyledTransactionParagraph>
          {isExternalPage ? (
            <a
              href={`${PAGE_BASE_URL}profile/subscription`}
              target="_blank"
              rel="noreferrer"
            >
              Upgrade Plan
            </a>
          ) : (
            <Link to={ROUTES.SUBSCRIPTION}>Upgrade Plan</Link>
          )}
        </div>
      </StyledPlanDetails>
      <StyledUserMenuDivider key={`header-menu-divider-${1}`} />
      <StyledRouteItem
        disabled={state?.user?.is_superuser && isImpersonating}
        key={3}
        onClick={() => history.push(ROUTES.USER_PROFILE)}
        icon={
          state?.user?.is_superuser && isImpersonating ? (
            <LockOutlined />
          ) : (
            <SettingOutlined />
          )
        }
      >
        <div>Settings</div>
      </StyledRouteItem>
      {state?.user?.is_superuser && !isImpersonating ? (
        <StyledRouteItem
          key={4}
          className="route-item"
          onClick={() => history.push(ROUTES.ADMIN_PAGE)}
          icon={<AppstoreOutlined />}
        >
          <div>Admin</div>
        </StyledRouteItem>
      ) : (
        <StyledRouteItem
          key={4}
          onClick={() => history.push(ROUTES.BOTS_PAGE)}
          icon={<AppstoreOutlined />}
        >
          <div>My Bots</div>
        </StyledRouteItem>
      )}
      <StyledUserMenuDivider key={`header-menu-divider-${2}`} />
      <StyledRouteItem
        key={5}
        onClick={handleLogout}
        icon={<PoweroffOutlined />}
      >
        Sign out
      </StyledRouteItem>
    </StyledUserDetailsMenu>
  );
  const items1 = ['1', '2', '3'].map(key => ({
    key,
    label: `nav ${key}`,
  }));

  return (
    <StyledHeader>
      {isImpersonating ? <ImpersonationBanner /> : null}
      {!isLogout ? (
        <Layout>
          <Content
            style={{
              background: '#202225',
            }}
          >
            <StyledPageMenu
              mode="horizontal"
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                textAlign: 'end',
                backgroundColor: '#202225 !important',
              }}
              triggerSubMenuAction="click"
              theme="dark"
            >
              {header.menu.map((item, key) => (
                <MenuItem
                  path={item.path}
                  title={item.title}
                  index={item.key}
                  key={key}
                />
              ))}
            </StyledPageMenu>
          </Content>
          <Sider className="userDetails">
            <Dropdown overlay={UserOptions} trigger={['click']}>
              <StyledDropdownWrapper>
                <StyledProfileAvatar
                  size={{
                    xs: 40,
                    sm: 50,
                    md: 50,
                    lg: 50,
                    xl: 50,
                    xxl: 50,
                  }}
                >
                  {userName[0].toUpperCase()}
                </StyledProfileAvatar>
                <StyledHeaderName noBorder>
                  {`Hi ${userName}`}
                  <CaretDownOutlined />
                </StyledHeaderName>
              </StyledDropdownWrapper>
            </Dropdown>
          </Sider>
        </Layout>
      ) : (
        <StyledButtonContainer>
          <StyledButtonSpan style={{ color: '#fff' }}>
            Already have an account?
          </StyledButtonSpan>
          <Button
            value={`Sign in`}
            onClick={() =>
              history.push(
                isExternalPage ? ROUTES.EXTERNAL_LOGIN : ROUTES.LOGIN
              )
            }
            variant="secondary"
          />
        </StyledButtonContainer>
      )}
    </StyledHeader>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  isLogout: PropTypes.bool,
  // menu: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { Header };
