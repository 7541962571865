import { createSelector } from 'reselect';

import { currentBotSelector } from '.';
import {
  initialAnalyticsAggregationsState,
  initialAnalyticsState,
} from 'store/initialState';
import { allCategoriesSelector } from './categories';

export const analyticsSelector = createSelector(
  currentBotSelector,
  bot => bot.analytics || initialAnalyticsState
);

export const performanceMetricsSelector = createSelector(
  analyticsSelector,
  analytics => analytics.performanceMetrics
);

export const analyticsAggregationsSelector = createSelector(
  analyticsSelector,
  analytics => analytics.aggregations || initialAnalyticsAggregationsState
);

export const analyticsCategoriesFilterSelector = createSelector(
  analyticsAggregationsSelector,
  aggregations => aggregations.categoryFilter || []
);

export const analyticsIntegrationFilterSelector = createSelector(
  analyticsAggregationsSelector,
  aggregations => aggregations.integrationFilter || []
);

export const allCategoryFilterSelector = createSelector(
  allCategoriesSelector,
  allCategories => {
    return allCategories.map(category => ({
      label: category.name,
      value: category.name,
      key: category.jid,
    }));
  }
);

export const analyticsTableSelector = createSelector(
  analyticsSelector,
  analytics => analytics.table
);

export const analyticsGraphSelector = createSelector(
  analyticsSelector,
  analytics => analytics.graph || []
);

export const analyticsAllQuestionsSelector = createSelector(
  analyticsTableSelector,
  table => table.allQuestions || []
);

export const analyticsMostAskedQuestionsSelector = createSelector(
  analyticsTableSelector,
  table => table.mostAskedQuestions || []
);

export const analyticsCorrectQuestionsSelector = createSelector(
  analyticsTableSelector,
  table => table.correctQuestions || []
);

export const analyticIncorrectQuestionsSelector = createSelector(
  analyticsTableSelector,
  table => table.incorrectQuestions || []
);

export const analyticsQuestionIntentsSelector = createSelector(
  analyticsTableSelector,
  table => table.intents || []
);

export const analyticsPositiveFeedbackQuestionsSelector = createSelector(
  analyticsTableSelector,
  table => table.positiveFeedbackQuestions || []
);

export const analyticsNegativeFeedbackQuestionsSelector = createSelector(
  analyticsTableSelector,
  table => table.negativeFeedbackQuestions || []
);
