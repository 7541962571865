import { useState, useEffect, useContext } from 'react';
import { message } from 'antd';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { strippedString } from 'utils/stringManipulation';
import {
  ADD_TEST_SUITE,
  DELETE_TEST_SUITE,
  SET_TEST_SUITES,
  SET_ONBOARDING_FLAG,
  UPDATE_TEST_SUITE,
  RUN_TEST_SUITE,
  SET_SELECTED_TEST_SUITE,
  DELETE_TEST_CASE,
  ADD_TEST_CASE,
  UPDATE_TEST_CASE,
} from 'store/action';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { importExportEnabledSelector } from 'selectors/plan';
import {
  getTestSuitesSelector,
  isMaxTestCasesSelector,
  isMaxTestSuitesSelector,
  selectedTestSuiteSelector,
  selectedTestSuiteStatusSelector,
  testCasesSelector,
} from 'selectors/bot/testSuites';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import { DEFAULT_ANSWER_VERSION } from 'constants/answerbank/defaults';
import {
  allAnswersSelector,
  topDefaultAnswerSelector,
} from 'selectors/bot/answers';
import useSelector from 'store/useSelector';
import {
  botJIDSelector,
  showBelowThresholdResponsesSelector,
  showDraftResponsesSelector,
} from 'selectors/bot';
import { isMobileViewSelector } from 'selectors/layout';

const useValidation = () => {
  const [state, dispatch] = useContext(Context);
  const isMaxTestSuites = useSelector(isMaxTestSuitesSelector);
  const allAnswers = useSelector(allAnswersSelector);
  const testSuites = useSelector(getTestSuitesSelector);
  const importExportEnabled = useSelector(importExportEnabledSelector);
  const isMaxTestCases = useSelector(isMaxTestCasesSelector);
  const showDraftResponses = useSelector(showDraftResponsesSelector);
  const showBelowThresholdResponses = useSelector(
    showBelowThresholdResponsesSelector
  );

  const jid = useSelector(botJIDSelector);
  const testCases = useSelector(testCasesSelector);
  const selectedTestSuite = useSelector(selectedTestSuiteSelector);
  const testStatus = useSelector(selectedTestSuiteStatusSelector);
  const isMobileView = useSelector(isMobileViewSelector);
  const defaultAnswer = useSelector(topDefaultAnswerSelector);

  const {
    bot: { name },
    token,
    sentinel,
    graph,
    plan: {
      onboarding_flag,
      max_test_cases,
      max_test_suite,
      plan_type,
      export_import,
    },
  } = state;

  const [showCreateSuiteModal, setShowCreateSuiteModal] = useState(false);
  const [showEditSuiteModal, setShowEditSuiteModal] = useState(false);
  const [newSuiteName, setNewSuiteName] = useState('');
  const [testSuiteToUpdate, setTestSuiteToUpdate] = useState();
  const [showTestCases, setShowTestCases] = useState(false);
  const [filteredTestCases, setFilteredTestCases] = useState([]);
  const [testCaseSearchKey, setTestCaseSearchKey] = useState('');
  const [showValidationQuestionModal, setShowValidationQuestionModal] =
    useState(false);
  const [responses, setResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [responseCount, setResponseCount] = useState(3);
  const [testCaseToEdit, setTestCaseToEdit] = useState(null);
  const [expectedAnswerToEdit, setExpectedAnswerToEdit] = useState(null);
  const [selectedTestCase, setSelectedTestCase] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [actualAnswer, setActualAnswer] = useState(null);
  const [showTestCaseDetailsModal, setShowTestCaseDetailsModal] =
    useState(false);
  const [testLastRanTime, setTestLastRanTime] = useState('');
  const [showTestStatus, setShowTestStatus] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [runFirstSuiteTour, setRunFirstSuiteTour] = useState(false);
  const [firstSuiteStepIndex, setFirstSuiteStepIndex] = useState(0);
  const [isPageReady, setIsPageReady] = useState(false);
  const [useDraft, setUseDraft] = useState(false);
  const [allSuitesUseDraft, setAllSuitesUseDraft] = useState(false);
  const [allResponses, setAllResponses] = useState([]);
  const [belowThresholdResponses, setBelowThresholdResponses] = useState([]);

  const handleShowCreateSuite = () => {
    if (isMaxTestSuites) {
      return message.error('Max number of test suites reached.');
    }
    if (runTour) {
      onClickClose({ action: 'close', status: 'skipped' });
    }
    return setShowCreateSuiteModal(true);
  };

  const handleCloseCreateSuiteModal = () => {
    setShowCreateSuiteModal(false);
  };

  const handleCreateNewSuite = async (evt, newSuiteName) => {
    if (evt) {
      evt.preventDefault();
    }
    if (isMaxTestSuites) {
      return message.error('Max number of test suites reached.');
    }

    try {
      const suiteWithSameName = testSuites?.find(
        testSuite => testSuite.name === newSuiteName
      );
      if (suiteWithSameName) {
        return message.error(
          'Test Suite with same name already exists. Try another name'
        );
      }

      const res = await apiService.createTestSuite(
        sentinel,
        jid,
        newSuiteName,
        token
      );
      const data = res.data.report[0];
      const newSuite = {
        jid: data.jid,
        name: data.context.name,
        lastTestRanTime: data.context.last_ran_time,
        lastTestStatus: data.context.last_result_summary,
        lastModified: data.context.last_updated_time,
        testCount: 0,
      };
      dispatch({
        type: ADD_TEST_SUITE,
        payload: newSuite,
      });
      setNewSuiteName('');
      message.success('New suite is created');
      setShowCreateSuiteModal(false);
      handleShowTestCases(newSuite);
    } catch (err) {
      return message.error('Error while creating a new suite');
    }
  };

  const handleShowEditTestSuiteModal = testSuite => {
    setTestSuiteToUpdate(testSuite);
    setShowEditSuiteModal(true);
  };

  const handleUpdateTestSuite = async evt => {
    evt.preventDefault();
    setModalLoading(true);
    try {
      const suiteWithSameName = testSuites?.find(
        testSuite =>
          testSuite?.jid !== testSuiteToUpdate?.jid &&
          testSuite?.name === testSuiteToUpdate.name
      );
      if (suiteWithSameName) {
        return message.error(
          'Test Suite with same name already exists. Try another name'
        );
      }

      const res = await apiService.updateTestSuite(
        sentinel,
        testSuiteToUpdate.jid,
        testSuiteToUpdate.name,
        token
      );

      dispatch({
        type: UPDATE_TEST_SUITE,
        payload: res.data?.report[0],
      });
      setShowEditSuiteModal(false);
      setTestSuiteToUpdate(null);
      message.success('Test Suite is updated successfully');
    } catch (err) {
      setModalLoading(false);
      return message.error('Updating Test Suite is failed');
    }
    setModalLoading(false);
  };

  const handleCloseEditSuiteModal = () => {
    setTestSuiteToUpdate(null);
    setShowEditSuiteModal(false);
  };

  const handleChangeTestSuiteName = e => {
    setTestSuiteToUpdate({
      ...testSuiteToUpdate,
      name: e.target.value,
    });
  };

  const handleDeleteTestSuite = async jid => {
    setPageLoading(true);
    try {
      await apiService.deleteTestSuite(sentinel, jid, token);
      dispatch({
        type: DELETE_TEST_SUITE,
        payload: jid,
      });
      message.success('Test Suite is successfully deleted');
    } catch (err) {
      setPageLoading(false);
      return message.error('Failed to delete Test Suite');
    }
    setPageLoading(false);
  };

  const handleShowTestCases = async testSuite => {
    setPageLoading(true);
    try {
      const res = await apiService.getTestCases(sentinel, testSuite.jid, token);

      dispatch({
        type: SET_SELECTED_TEST_SUITE,
        payload: {
          testSuite,
          testCases: res.data.report[0],
        },
      });

      setShowTestStatus(true);
      setTestLastRanTime(testSuite.lastTestRanTime);
      setShowTestCases(true);
    } catch (err) {
      setPageLoading(false);
      return message(err.message || GET_DATA_ERROR);
    }
    if (testSuites?.length === 1 && !onboarding_flag.includes('FirstSuite')) {
      setTimeout(() => setRunFirstSuiteTour(true), 2000);
    }
    setPageLoading(false);
  };

  const handleSearchTestCases = e => {
    const searchKey = e.target.value;
    const searchedTestCases = testCases.filter(testCase =>
      testCase.question.toLowerCase().includes(searchKey.toLowerCase())
    );
    setFilteredTestCases(searchedTestCases);
    setTestCaseSearchKey(searchKey);
  };

  const handleShowValidationQuestionModal = testSuite => {
    setRunFirstSuiteTour(false);
    if (isMaxTestCases) {
      return message.error('Max number of test cases reached.');
    }

    if (testSuite) {
      setTestSuiteToUpdate(testSuite);
    } else {
      setExpectedAnswerToEdit(null);
      setResponseCount(3);
      setResponses([]);
      setAllResponses([]);
      setBelowThresholdResponses([]);
      setSelectedResponse(null);
    }
    return setShowValidationQuestionModal(true);
  };

  const generateDefaultAnswers = responseAnswerList => {
    return {
      jid: responseAnswerList[1].jid,
      score: responseAnswerList[1].context.thresh_score?.toFixed(3) || '0.000',
      text: responseAnswerList[1].context?.text,
      show_text: responseAnswerList[1].context?.show_text,
      show_html:
        responseAnswerList[1].context?.show_html ||
        responseAnswerList[1].context?.show_text,
      type: responseAnswerList[1].name,
      version:
        responseAnswerList[1].context?.ans_version || DEFAULT_ANSWER_VERSION,
    };
  };

  const generateAboveThresholdAnswers = responseAnswerList => {
    const filteredAboveThresholdAnswers = responseAnswerList[0]?.filter(
      answerList =>
        answerList?.context?.score > responseAnswerList[1].context?.thresh_score
    );
    return filteredAboveThresholdAnswers?.map((answerInfo, idx) => {
      const { context, name, jid } = answerInfo;
      return {
        jid,
        score: answerInfo?.context?.score?.toFixed(3) || '0.000',
        text: context?.text,
        show_text: context?.show_text,
        show_html: context?.show_html || context?.show_text,
        type: name,
        version: context?.ans_version || DEFAULT_ANSWER_VERSION,
      };
    });
  };

  const generateBelowThresholdAnswers = responseAnswerList => {
    const filteredBelowThresholdAnswers = responseAnswerList[0]?.filter(
      answerList =>
        answerList?.context?.score < responseAnswerList[1].context?.thresh_score
    );
    return filteredBelowThresholdAnswers?.map((answerInfo, idx) => {
      const { context, name, jid } = answerInfo;
      return {
        jid,
        score: answerInfo?.context?.score?.toFixed(3) || '0.000',
        text: context?.text,
        show_text: context?.show_text,
        show_html: context?.show_html || context?.show_text,
        type: name,
        version: context?.ans_version || DEFAULT_ANSWER_VERSION,
      };
    });
  };

  const handleShowResponses = async (totalResponses, question) => {
    setModalLoading(true);
    handleChangeTestCaseQuestion(question);
    try {
      const res = await apiService.getAnswersWithScoreFromQuestion(
        question,
        jid,
        sentinel,
        token,
        true
      );
      const responseAnswerList = res.data.report;
      const allResponses = [
        generateDefaultAnswers(responseAnswerList),
        ...generateAboveThresholdAnswers(responseAnswerList),
      ];

      setResponseCount(totalResponses);
      setResponses(
        showDraftResponses
          ? allResponses
          : allResponses.filter(answer => answer.version === 'final')
      );
      setAllResponses(allResponses);
      setBelowThresholdResponses(
        generateBelowThresholdAnswers(responseAnswerList)
      );
    } catch (err) {
      setModalLoading(false);
      return message.error('Error while getting response');
    }
    setModalLoading(false);
  };

  const handleSelectResponse = response => {
    setSelectedResponse(response);
  };

  const handleDownloadTestCases = () => {
    const fileData = testCases.map(item => item.question + '\n');
    const blob = new Blob(['\ufeff', ...fileData]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'zeroshotbot-testcases.csv';
    link.href = url;
    link.click();
  };

  const getStringTestDuplicate = (input, id) => {
    if (Array.isArray(input)) {
      return input.some(str =>
        testCases.find(
          testcase =>
            strippedString(testcase.question) === strippedString(str) &&
            testcase.jid !== id
        )
      );
    } else {
      return testCases.find(
        item =>
          strippedString(item.question) === strippedString(input) &&
          item.jid !== id
      );
    }
  };

  const handleAddQuestion = async (question, shouldUseDefaultAnswer) => {
    if (isMaxTestCases) {
      return message.error('Max number of test cases reached.');
    }
    setModalLoading(true);
    let res;
    const isAnswerExists = getStringTestDuplicate(question);
    try {
      if (isAnswerExists) {
        setModalLoading(false);
        return message.warn('Test case already exists.');
      }
      if (typeof question === 'string') {
        res = await apiService.createTestCase(
          sentinel,
          selectedTestSuite.jid,
          question,
          selectedResponse,
          token
        );
      } else {
        res = await apiService.createManyTestCases(
          sentinel,
          token,
          selectedTestSuite.jid,
          question,
          shouldUseDefaultAnswer
        );
      }

      if (res.status === 200 && !res.data.report.length) {
        setModalLoading(false);
        return message.warn('Duplicated test case(s) found.');
      }

      const data = res.data.report;

      if (!data || !data.length) {
        throw new Error('No test case found.');
      }

      dispatch({
        type: ADD_TEST_CASE,
        payload: data,
      });

      setTestCaseSearchKey('');
      setShowValidationQuestionModal(false);
      setTestCaseToEdit(null);
      message.success('TestCase is added successfully');
    } catch (err) {
      setModalLoading(false);
      return message.error(err.message || 'Failed to add question');
    }
    return setModalLoading(false);
  };

  const handleShowTestCase = testCase => {
    let expectedAnswer;
    expectedAnswer = allAnswers.find(
      answer => answer?.text === testCase.answer
    );

    if (testCase.actualAnswerId) {
      let matchedAnswer;
      matchedAnswer = allAnswers.find(
        answer => answer?.show_text === testCase.actualAnswer
      );
      setActualAnswer(matchedAnswer || defaultAnswer);
    }

    setSelectedTestCase(testCase);
    setSelectedAnswer(expectedAnswer || defaultAnswer);
    setShowTestCaseDetailsModal(true);
  };

  const handleCloseTestCaseDetailsModal = () => {
    setSelectedAnswer(null);
    setActualAnswer(null);
    setSelectedTestCase(null);
    setShowTestCaseDetailsModal(false);
  };

  const handleEditTestCase = async testCase => {
    if (showTestCaseDetailsModal) {
      setModalLoading(true);
    } else {
      setPageLoading(true);
    }
    try {
      const res = await apiService.getAnswersWithScoreFromQuestion(
        testCase.question,
        jid,
        sentinel,
        token,
        true
      );
      const responseAnswerList = res.data.report;
      const allResponses = [
        generateDefaultAnswers(responseAnswerList),
        ...generateAboveThresholdAnswers(responseAnswerList),
      ];

      setResponses(
        showDraftResponses
          ? allResponses
          : allResponses.filter(answer => answer.version === 'final')
      );
      setAllResponses(allResponses);
      setBelowThresholdResponses(
        generateBelowThresholdAnswers(responseAnswerList)
      );

      const expectedAnswer = allAnswers.find(
        answer => answer.text === testCase.answer
      );
      setTestCaseToEdit(testCase);
      setExpectedAnswerToEdit(expectedAnswer || defaultAnswer);
      setResponseCount(3);

      const matchedResponse = [
        ...generateAboveThresholdAnswers(responseAnswerList),
        ...generateBelowThresholdAnswers(responseAnswerList),
      ].find(answer => answer.text === testCase.answer);
      setSelectedResponse(matchedResponse);

      setShowTestCaseDetailsModal(false);
      setSelectedTestCase(null);
      setShowValidationQuestionModal(true);
    } catch (err) {
      if (showTestCaseDetailsModal) {
        setModalLoading(false);
      } else {
        setPageLoading(false);
      }
      return message.error('Error while getting response');
    }
    if (showTestCaseDetailsModal) {
      setModalLoading(false);
    } else {
      setPageLoading(false);
    }
  };

  const handleCloseValidationQuestionModal = () => {
    setTestCaseToEdit(null);
    setSelectedResponse(null);
    setResponses(null);
    setResponseCount(3);
    setExpectedAnswerToEdit(null);
    setShowValidationQuestionModal(false);
  };

  const handleChangeTestCaseQuestion = value => {
    setTestCaseToEdit({
      ...testCaseToEdit,
      question: value,
    });
  };

  const handleUpdateTestCase = async (question, id, testResult) => {
    setModalLoading(true);
    try {
      const res = await apiService.updateTestCase(
        sentinel,
        id,
        question,
        selectedResponse.show_text,
        token
      );

      dispatch({
        type: UPDATE_TEST_CASE,
        payload: res.data?.report[0],
      });

      setShowValidationQuestionModal(false);
      setTestCaseToEdit(null);
      setSelectedResponse(null);
      setResponses(null);
      setResponseCount(3);
      message.success('TestCase is updated successfully');
    } catch (err) {
      setModalLoading(false);
      return message.error('Failed to update TestCase');
    }
    setModalLoading(false);
  };

  const handleDeleteTestCase = async testCase => {
    setPageLoading(true);
    try {
      await apiService.deleteTestCase(sentinel, testCase.jid, token);
      dispatch({
        type: DELETE_TEST_CASE,
        payload: {
          jid: testCase.jid,
        },
      });
      setTestCaseSearchKey('');

      message.success('TestCase is deleted successfully');
    } catch (err) {
      setPageLoading(false);
      message.error('Failed to delete TestCase');
    }
    setPageLoading(false);
  };

  const handleBackToTestSuites = () => {
    setFilteredTestCases([]);
    setTestCaseSearchKey('');
    setShowTestCases(false);
  };

  const handleRunTestSuite = async () => {
    setPageLoading(true);
    try {
      const res = await apiService.runTestSuite(
        sentinel,
        selectedTestSuite.jid,
        selectedTestSuite.name,
        token,
        useDraft
      );
      dispatch({
        type: RUN_TEST_SUITE,
        payload: res.data.report[0],
      });
      setTestCaseSearchKey('');
    } catch (err) {
      setPageLoading(false);
      return message.error(
        err.message !== DEFAULT_ERROR_MESSAGE
          ? err.message
          : 'Error while running test'
      );
    }
    setPageLoading(false);
  };

  const handleRunAllTests = async () => {
    setPageLoading(true);
    try {
      setPageLoading(true);
      const res = await apiService.runAllTests(
        sentinel,
        jid,
        token,
        allSuitesUseDraft
      );

      await dispatch({
        type: SET_TEST_SUITES,
        payload: res.data.report,
      });
    } catch (err) {
      message.error(err.message || GET_DATA_ERROR);
      setPageLoading(false);
    }
    setPageLoading(false);
  };

  const onClickClose = async ({ action, index, type, status }) => {
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      ACTIONS.CLOSE === action
    ) {
      setRunTour(false);
      setStepIndex(0);
      if (!onboarding_flag.includes('ValidationWelcomeMessage')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'ValidationWelcomeMessage'
        );
        dispatch({
          type: SET_ONBOARDING_FLAG,
          payload: 'ValidationWelcomeMessage',
        });
      }
    }
  };

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      ACTIONS.CLOSE === action
    ) {
      setRunTour(false);
      setStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
    }
  };

  const handleToggleUseDraft = e => {
    return e.target.checked && !useDraft
      ? setUseDraft(true)
      : setUseDraft(false);
  };

  const handleToggleAllSuitesUseDraft = e => {
    return e.target.checked && !allSuitesUseDraft
      ? setAllSuitesUseDraft(true)
      : setAllSuitesUseDraft(false);
  };

  useEffect(() => {
    if (sentinel) {
      setIsPageReady(true);
    }

    return () => setIsPageReady(false);
  }, []);

  useEffect(() => {
    if (showDraftResponses && showBelowThresholdResponses) {
      setResponses([...allResponses, ...belowThresholdResponses]);
    } else if (!showDraftResponses && showBelowThresholdResponses) {
      setResponses([
        ...allResponses.filter(answer => answer.version === 'final'),
        ...belowThresholdResponses,
      ]);
    } else if (showDraftResponses && !showBelowThresholdResponses) {
      setResponses(allResponses);
    } else if (!showDraftResponses && !showBelowThresholdResponses) {
      setResponses(allResponses.filter(answer => answer.version === 'final'));
    }
  }, [showBelowThresholdResponses, showDraftResponses]);

  useEffect(() => {
    setFilteredTestCases(testCases);
  }, [testCases]);

  useEffect(() => {
    if (
      isPageReady &&
      !testSuites?.length &&
      !onboarding_flag.includes('ValidationWelcomeMessage') &&
      !showCreateSuiteModal
    ) {
      setRunTour(true);
    } else {
      setRunTour(false);
    }

    if (
      isPageReady &&
      testSuites?.length &&
      !onboarding_flag.includes('FirstSuite') &&
      !showValidationQuestionModal &&
      showTestCases
    ) {
      setRunFirstSuiteTour(true);
    }

    return () => {
      setRunFirstSuiteTour(false);
      setRunTour(false);
    };
  }, [
    isPageReady,
    onboarding_flag,
    showValidationQuestionModal,
    testSuites?.length,
    showTestCases,
    showCreateSuiteModal,
  ]);

  return {
    actualAnswer,
    name,
    expectedAnswerToEdit,
    filteredTestCases,
    isMaxTestCases,
    isMaxTestSuites,
    importExportEnabled,
    max_test_cases,
    max_test_suite,
    modalLoading,
    onClickClose,
    pageLoading,
    plan_type,
    responseCount,
    responses,
    runFirstSuiteTour,
    runTour,
    setRunFirstSuiteTour,
    selectedAnswer,
    selectedResponse,
    selectedTestCase,
    selectedTestSuite,
    showCreateSuiteModal,
    showEditSuiteModal,
    showTestCaseDetailsModal,
    showTestCases,
    showTestStatus,
    showValidationQuestionModal,
    testCases,
    testCaseSearchKey,
    testCaseToEdit,
    testLastRanTime,
    testStatus,
    testSuites,
    testSuiteToUpdate,
    handleAddQuestion,
    handleBackToTestSuites,
    handleChangeTestCaseQuestion,
    handleChangeTestSuiteName,
    handleCloseCreateSuiteModal,
    handleCloseEditSuiteModal,
    handleCloseValidationQuestionModal,
    handleCloseTestCaseDetailsModal,
    handleCreateNewSuite,
    handleDeleteTestCase,
    handleDeleteTestSuite,
    handleDownloadTestCases,
    handleEditTestCase,
    handleRunAllTests,
    handleRunTestSuite,
    handleSearchTestCases,
    handleSelectResponse,
    handleShowCreateSuite,
    handleShowEditTestSuiteModal,
    handleShowResponses,
    handleShowTestCase,
    handleShowTestCases,
    handleShowValidationQuestionModal,
    handleUpdateTestCase,
    handleUpdateTestSuite,
    handleToggleUseDraft,
    handleToggleAllSuitesUseDraft,
    sentinel,
    token,
    graph,
    dispatch,
    onboarding_flag,
    useDraft,
    allSuitesUseDraft,
    stepIndex,
    firstSuiteStepIndex,
    onClickCallback,
    isMobileView,
  };
};

export default useValidation;
