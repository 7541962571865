import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined } from '@ant-design/icons';
import {
  StyledTemplateCategory,
  StyledTemplateCategoryHeading,
  StyledTemplateCategoryInfos,
  StyledTemplateCategoryName,
  StyledFolderOpenFilled,
  StyledFolderOutlined,
  StyledTag,
  StyledMinusOutlined,
  StyledPlusOutlined,
  StyledListContainer,
  StyledListItem,
} from './TemplateCategories.styles';
import { ThemeProvider } from 'styled-components';

const TemplateCategories = ({
  template,
  isEven,
  isCategoryAdded,
  isSelected,
  onAddTemplateCategory,
  onRemoveTemplateCategory,
}) => {
  const [showAnswers, setShowAnswers] = useState(false);
  let modifiedTemplate = template;
  if (template.answers.length > 0) {
    const modifiedAnswers = template.answers.map(answer => {
      return {
        ...answer,
        editor: '',
        quick_reply: 'false',
        quick_reply_options: [],
        callback: 'false',
      };
    });
    modifiedTemplate = {
      ...template,
      answers: [...modifiedAnswers],
    };
  }

  const { name, answers } = modifiedTemplate;

  const theme = {
    isEven,
    showAnswers,
  };

  const toggleShowAnswers = () => {
    setShowAnswers(!showAnswers);
  };

  const handleAddClick = () => {
    onAddTemplateCategory(modifiedTemplate);
  };

  const handleMinusClick = () => {
    onRemoveTemplateCategory(modifiedTemplate);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledTemplateCategory>
        <StyledTemplateCategoryHeading>
          <StyledTemplateCategoryInfos>
            {showAnswers ? (
              <StyledFolderOpenFilled onClick={toggleShowAnswers} />
            ) : (
              <StyledFolderOutlined onClick={toggleShowAnswers} />
            )}
            <StyledTemplateCategoryName>
              <span>
                {name}
                {isCategoryAdded && (
                  <StyledTag icon={<CheckOutlined />}>
                    <span>Added</span>
                  </StyledTag>
                )}
                {isSelected && (
                  <StyledTag icon={<CheckOutlined />}>
                    <span>Selected</span>
                  </StyledTag>
                )}
              </span>
              <p>{`${answers.length} Answers`}</p>
            </StyledTemplateCategoryName>
          </StyledTemplateCategoryInfos>
          {!isCategoryAdded && isSelected && (
            <StyledMinusOutlined onClick={handleMinusClick} />
          )}
          {!isCategoryAdded && !isSelected && (
            <StyledPlusOutlined onClick={handleAddClick} />
          )}
        </StyledTemplateCategoryHeading>
        <StyledListContainer>
          <StyledListItem>
            {answers.map(answer => {
              return <li>{answer.text}</li>;
            })}
          </StyledListItem>
        </StyledListContainer>
      </StyledTemplateCategory>
    </ThemeProvider>
  );
};

TemplateCategories.propTypes = {
  template: PropTypes.array,
  isEven: PropTypes.bool,
  isCategoryAdded: PropTypes.bool,
  color: PropTypes.string,
  isSelected: PropTypes.bool,
  onAddTemplateCategory: PropTypes.bool,
  onRemoveTemplateCategory: PropTypes.func,
};

export default TemplateCategories;
