import { createSelector } from 'reselect';
import { isEqual } from 'lodash';
import { initialWSState } from 'store/initialState';

export const websocketSelector = state => state.websocket;

export const isInitialWebsocketStateSelector = createSelector(
  websocketSelector,
  websocket => {
    return isEqual(websocket, initialWSState);
  }
);
