import { QUESTION_LOGS } from 'constants/analytics';
import moment from 'moment';

export const getGraphTimeInterval = (startDate, endDate) => {
  var validStartDate = new Date(
    new Date(startDate).setHours(0, 0, 0, 0)
  ).toISOString();
  var validEndDate = new Date(
    new Date(endDate).setHours(23, 59, 59, 59)
  ).toISOString();

  const dateInterval = Math.abs(
    moment(validStartDate).diff(validEndDate, 'days')
  );

  if (dateInterval < 6) {
    return '1h';
  } else if (dateInterval >= 6 && dateInterval < 20) {
    return '5h';
  } else if (dateInterval >= 21 && dateInterval < 150) {
    return '1d';
  } else if (dateInterval >= 150 && dateInterval < 365) {
    return '7d';
  } else if (dateInterval <= 365 && dateInterval < 366) {
    return '31d';
  } else {
    return '1d';
  }
};

export const extraAnswersFromESHits = ESAnswer => {
  return {
    jid: ESAnswer.id,
    show_html: ESAnswer.show_html,
    show_text: ESAnswer.show_text,
    text: ESAnswer.text,
    type: ESAnswer.type,
  };
};

export const extractESAnalyticsData = dataArray => {
  return (dataArray || []).map((hit, key) => {
    const {
      datetime,
      feedback,
      log_type,
      metadata,
      question,
      validation,
      answer,
      category,
      sequence,
    } = hit._source;
    return {
      answer,
      channel: metadata?.channel,
      date: datetime,
      feedback,
      key,
      logType: log_type,
      question,
      interactionID: metadata?.interaction_id,
      sessionID: metadata?.session_id,
      visitorID: metadata?.visitor_id,
      isAuthenticated: metadata?.isAuthenticated,
      questionID: hit?._id,
      validation,
      category,
      intent: metadata?.openai?.intents,
      references: metadata?.openai?.references,
      sentiments: metadata?.openai?.sentiments,
      named_entities: metadata?.openai?.named_entities,
      detected_language: metadata?.openai?.detected_language,
      target_language: metadata?.openai?.target_language,
      translation: metadata?.openai?.translation,
      translation_messages: metadata?.openai?.translation_messages,
      asking_messages: metadata?.openai?.asking_messages,
      sequence,
    };
  });
};

export const isElasticAggregationsEmpty = data => {
  const { hits } = data;
  if (!hits?.hits?.length && !hits?.total?.value) {
    return true;
  }
  return false;
};

export const getAnalyticsCategoryFilterLink = category => {
  return `analytics?category=${category}#${QUESTION_LOGS}`;
};

export const getAnalyticsIntegrationFilterLink = integration => {
  return `analytics?integration=${integration}#${QUESTION_LOGS}`;
};
