import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
const StyleIcon2 = styled.svg`
  width: ${props => props.width};
  height: ${props => props.height};
`;
const Icon2 = ({ width, height, viewbox, iconColor }) => {
  return (
    <StyleIcon2
      width={width || '45px'}
      height={height || '45px'}
      viewBox={viewbox || '0 -2.9 24 30'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2316 6.01074H7.76843C4.58948 6.01074 2 8.60022 2 11.7792V12.2213C2 15.4002 4.58948 17.9897 7.76843 17.9897H16.2316C19.4105 17.9897 22 15.4002 22 12.2213V11.7792C22 8.60022 19.4105 6.01074 16.2316 6.01074ZM20.5474 12.2213C20.5474 14.6002 18.6105 16.5371 16.2316 16.5371H7.76843C5.38948 16.5371 3.45264 14.6002 3.45264 12.2213V11.7792C3.45264 9.40022 5.38948 7.46338 7.76843 7.46338H16.2316C18.6105 7.46338 20.5474 9.40022 20.5474 11.7792V12.2213Z"
        fill={iconColor || '#E9E5E5'}
      />
      <path
        d="M7.34798 13.4005C8.11536 13.4005 8.73745 12.7784 8.73745 12.0111C8.73745 11.2437 8.11536 10.6216 7.34798 10.6216C6.58059 10.6216 5.9585 11.2437 5.9585 12.0111C5.9585 12.7784 6.58059 13.4005 7.34798 13.4005Z"
        fill={iconColor || '#E9E5E5'}
      />
      <path
        d="M11.9993 13.4005C12.7667 13.4005 13.3888 12.7784 13.3888 12.0111C13.3888 11.2437 12.7667 10.6216 11.9993 10.6216C11.2319 10.6216 10.6099 11.2437 10.6099 12.0111C10.6099 12.7784 11.2319 13.4005 11.9993 13.4005Z"
        fill={iconColor || '#E9E5E5'}
      />
      <path
        d="M16.6527 13.4005C17.42 13.4005 18.0421 12.7784 18.0421 12.0111C18.0421 11.2437 17.42 10.6216 16.6527 10.6216C15.8853 10.6216 15.2632 11.2437 15.2632 12.0111C15.2632 12.7784 15.8853 13.4005 16.6527 13.4005Z"
        fill={iconColor || '#E9E5E5'}
      />
    </StyleIcon2>
  );
};

Icon2.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewbox: PropTypes.string,
  iconColor: PropTypes.string,
};

export default Icon2;
