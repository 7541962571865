import { StyledBannerWrapper } from 'pages/Auth/StyledComponents';
import styled from 'styled-components';
import {
  StyledFlexColumn,
  StyledFlexRowCenter,
} from 'styles/GenericStyledComponents';
import { cssVariables } from 'styles/root';

export const StyledFullWidthWrapper = styled(StyledFlexRowCenter)`
  @keyframes flip-in {
    0% {
      transform: rotateX(180deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  animation: flip-in 1s;
  transform-style: preserve-3d;
`;

export const StyledFullHeightWrapper = styled(StyledBannerWrapper)`
  height: 100vh;
  width: 45%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;

  img {
    width: auto;
  }

  .anticon-close {
    cursor: pointer;
    position: fixed;
    top: 30px;
    right: 30px;
    color: ${cssVariables.gray0};
  }
`;

export const StyledLeftSidePromo = styled(StyledFlexColumn)`
  padding: 50px;
  font-family: 'Open Sans';
  width: 55%;

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
    color: ${cssVariables.gray0};
  }

  .anticon-check-circle {
    font-size: 24px;
  }

  .ant-btn {
    margin-top: 50px;
    width: 100%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const StyledFloatingTopLogo = styled.img`
  position: fixed;
  width: 150px;
  top: 30px;
  left: 50px;
`;

export const StyledScreenshotsWrapper = styled(StyledFlexColumn)`
  height: 35%;

  img {
    z-index: 10;
    width: 50%;
    border-radius: 5px;
    box-shadow: ${cssVariables.itemListShadow};
    position: absolute;

    @media (max-width: 1440px) {
      width: 50%;

      &:first-child {
        right: 26%;
        bottom: 50%;
      }

      &:nth-child(2) {
        bottom: 45%;
        right: 18%;
      }

      &:nth-child(3) {
        right: 10%;
        bottom: 30%;
      }
    }

    &:first-child {
      right: 26%;
      bottom: 65%;
    }

    &:nth-child(2) {
      bottom: 55%;
      right: 18%;
    }

    &:nth-child(3) {
      right: 10%;
      bottom: 40%;
    }
  }
`;
