import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { generateIcon } from '../iconUtils';

const WidgetLogo = ({
  color,
  shape,
  label,
  position,
  avatar,
  iconColor,
  textColor,
}) => {
  // TODO: use selectors to remove props
  return shape === 'rectangle' ? (
    <div
      className="rect-wrapper"
      style={{
        backgroundColor: color,
        color: textColor,
        transform: position
          ? position.includes('mid')
            ? 'rotate(-90deg) scale(0.7)'
            : 'none'
          : 'none',
        width: position
          ? position.includes('mid-right')
            ? 100
            : position.includes('mid-left')
            ? 100
            : 'none'
          : 'none',

        display: position
          ? position.includes('mid')
            ? 'inline-block'
            : ''
          : '',
        verticalAlign: position
          ? position.includes('mid')
            ? 'middle !important'
            : ''
          : '',
      }}
    >
      {label}
    </div>
  ) : (
    <Avatar
      style={{
        backgroundColor: color,
        padding: (avatar === 'default') | !avatar ? '4px' : '0',
      }}
      src={
        (avatar === 'default') | !avatar
          ? generateIcon(avatar, iconColor, '40', '40', '0 12 58 35')
          : generateIcon(avatar, iconColor, '40', '40', '-4.5 0 40 35')
      }
    />
  );
};

WidgetLogo.propTypes = {
  color: PropTypes.any,
  shape: PropTypes.any,
  label: PropTypes.any,
  position: PropTypes.any,
  avatar: PropTypes.any,
  iconColor: PropTypes.any,
  textColor: PropTypes.any,
};

export default WidgetLogo;
