import styled, { keyframes } from 'styled-components';

const rotateLeft = keyframes`
    0% {
      transform: rotate(-45deg);
    };

    100% {
      transform: rotate(0);
    };
`;

const rotateRight = keyframes`
    0% {
      transform: rotate(45deg);
    };

    100% {
      transform: rotate(0);
    };
`;

export const StyledChatWidgetLauncher = styled.div`
  display: ${props => (props.isMobile ? 'none' : 'flex')};
  align-self: flex-end;
  margin-right: 20px;
  margin-top: 10px;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  animation: ${rotateRight} 0.5s forwards;
  bottom: 20px;
  cursor: not-allowed;
  background-color: ${props => props.integrationDataColor};
  box-shadow: 1px 2px 3px 1px #b5b5b5;

  &:only-child {
    width: 60px;
    height: 60px;
    padding: 5px;
    display: flex;
    justify-content: center;
    position: fixed;
  }

  &:not(:only-child) {
    animation: ${rotateLeft} 0.5s forwards;
    padding: 10px;
    justify-content: center;
  }
`;
