import React from 'react';
import PropTypes from 'prop-types';
import { StyledTextIcon } from './TextIcon.styles';

const TextIcon = props => {
  const { children, ...rest } = props;
  return <StyledTextIcon {...rest}>{children}</StyledTextIcon>;
};

TextIcon.propTypes = {
  children: PropTypes.any.isRequired,
};

export default TextIcon;
