/* eslint-disable react/display-name */
import { Dropdown, Menu, Spin, Table, Row, Col, Grid } from 'antd';
import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import _ from 'lodash';

import Title from 'components/Title';
import { filterObjectKeys } from 'utils';
import { stringLocaleCompare } from 'utils/stringManipulation';
import { UTCToLocal, getLocalTimeString } from 'utils/dates';
import CallbackModal from 'components/Modals/CallbackModal';
import useCallbackLog from './hooks';
import DateFilter from 'components/DateFilter';
import {
  ArrowsAltOutlined,
  CaretRightFilled,
  DownloadOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Button from 'components/Button';
import { cssVariables } from 'styles/root';
import { parseBoolean } from 'utils/dataTypes';

const { useBreakpoint } = Grid;

const EXPORT_TO_EXCEL = parseBoolean(process.env.REACT_APP_EXPORT_TO_EXCEL);

const StyledCallbackLogsTable = styled(Table)`
  padding: 20px 0;
`;

const StyledDateFilter = styled(Row)`
  div:first-child {
    font-size: 16px;
    margin-right: 1%;
    color: ${props => props.theme.gray0};
  }
`;

const StyledDateFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`;

const StyledSessionID = styled.span`
  color: ${cssVariables.primaryBlue};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const CallbackLogs = ({ loading }) => {
  const {
    tableData,
    handleClickDateFilterButton,
    handleCloseSessionCallbacks,
    handleDateFilterChange,
    handleShowSessionCallbacks,
    handleCSVDownloadQlogs,
    handleXLSXDownloadQlogs,
    handleTableChange,
    modalLoading,
    pageLoading,
    questions,
    selectedSessionID,
    callbackInteractionIDs,
    callbackSessionFilters,
    sessionFilteredInfo,
    showModal,
    EXCLUDED_CALLBACK_COLUMNS,
    isPageReady,
    isMobileView,
    selectedCallbackLog,
  } = useCallbackLog();
  const screens = useBreakpoint();
  const componentRef = useRef();
  const rangePickerStyle = {
    width: screens.xs ? '100%' : 'auto',
    padding: screens.xs ? '8px 12px' : 'auto',
  };

  const dropdownStyle = {
    width: screens.xs ? '100%' : 'auto',
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `margin: 20px`,
  });

  const renderInteractionIDs = (interactionID, item, idx) =>
    !!item ? (
      <React.Fragment key={idx}>
        <span style={{ fontSize: 12, display: 'flex' }}>{item}</span>
        {/* dont render <hr /> for last item */}
        {idx < interactionID?.length - 1 ? (
          <hr style={{ height: 0.5, background: '#000', border: 'none' }} />
        ) : null}
      </React.Fragment>
    ) : null;

  const callbackColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => new Date(b.date) - new Date(a.date),
      },
      render: date => {
        const localDate = UTCToLocal(new Date(date));
        return <div>{getLocalTimeString(localDate)}</div>;
      },
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    },
    {
      title: 'Session ID',
      dataIndex: 'sessionID',
      width: '16%',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.sessionID, b.sessionID),
      },
      filters: callbackSessionFilters,
      filteredValue: sessionFilteredInfo || null,
      onFilter: (value, row) => {
        if (value === 'undefined') {
          return !row.sessionID;
        } else {
          return row.sessionID && row.sessionID.includes(value);
        }
      },
      render: (sessionID, row) => (
        <StyledSessionID onClick={() => handleShowSessionCallbacks(row)}>
          {sessionID || 'N/A'}
        </StyledSessionID>
      ),
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.name, b.name),
      },
      render: name => <div style={{ fontSize: 12 }}>{name}</div>,
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '18%',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.email, b.email),
      },
      render: email => <div style={{ fontSize: 12 }}>{email}</div>,
      responsive: ['xxl', 'xl', 'lg', 'md'],
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile',
      width: '10%',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.mobile, b.mobile),
      },
      render: mobile => <div style={{ fontSize: 12 }}>{mobile}</div>,
      responsive: ['xxl', 'xl', 'lg', 'md'],
    },
    {
      title: 'Interaction IDs',
      dataIndex: 'interactionID',
      width: '10%',
      render: interactionID =>
        interactionID.map((i, idx) =>
          renderInteractionIDs(interactionID, i, idx)
        ),
      responsive: ['xxl', 'xl', 'lg', 'md'],
    },
    {
      title: 'Others',
      width: '30%',
      dataIndex: 'others',
      render: others => {
        const extraData = filterObjectKeys(others, EXCLUDED_CALLBACK_COLUMNS);
        if (Object.keys(extraData).length) {
          return Object.entries(extraData).map(([key, value], idx) => {
            return (
              <div style={{ fontSize: 12 }} key={`${key}-${idx}`}>
                <strong>
                  {!!key ? _.startCase(key) : ''}
                  {': '}
                </strong>
                <span>{value}</span>
              </div>
            );
          });
        }
        return null;
      },
      responsive: ['xxl', 'xl', 'lg', 'md'],
    },
    // {
    //   title: 'Action',
    //   width: '30%',
    //   dataIndex: 'action',
    //   render: (value, row) => <ArrowsAltOutlined />,
    //   responsive: ['sm', 'xs'],
    // },
  ];

  const filteredColumns = callbackColumns.filter(col => {
    if (!isMobileView && col.dataIndex === 'action') {
      return false;
    } else if (isMobileView && col.dataIndex === 'action') {
      return true;
    }

    if (!col.responsive) {
      return true;
    }
    return col.responsive.some(bp => screens[bp]);
  });

  const ExportButtonMenu = () => {
    return (
      <Menu>
        <Menu.Item key={'csv'} onClick={handleCSVDownloadQlogs}>
          {'CSV'}
        </Menu.Item>
        <Menu.Item
          key={'xlsx'}
          onClick={handleXLSXDownloadQlogs}
          hidden={!EXPORT_TO_EXCEL}
        >
          {'Excel (.xlsx)'}
        </Menu.Item>
      </Menu>
    );
  };

  const CallbackLogTable = forwardRef(() => (
    <div ref={componentRef}>
      <StyledCallbackLogsTable
        columns={filteredColumns}
        dataSource={tableData}
        onChange={handleTableChange}
      />
    </div>
  ));

  return (
    <Spin spinning={loading || pageLoading || !isPageReady} tip="Just a moment">
      <Row style={{ paddingBottom: '2%' }} gutter={[8, 8]}>
        <Col
          xs={{ span: 21 }}
          md={{ span: 16 }}
          lg={{ span: 16 }}
          xl={{ span: 16 }}
        >
          <Title text="Callback Logs" type="responsive-text" />
        </Col>

        <Col
          xs={{ span: 23 }}
          md={{ span: 4 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
        >
          <Dropdown overlay={ExportButtonMenu} trigger="hover">
            <Button
              style={{ marginLeft: 5 }}
              startIcon={<DownloadOutlined />}
              value={'Export'}
              variant="success"
              full
            />
          </Dropdown>
        </Col>
        <Col
          xs={{ span: 23 }}
          md={{ span: 4 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
        >
          <Button
            style={{ marginLeft: 5 }}
            value="Print Page"
            startIcon={<PrinterOutlined />}
            onClick={handlePrint}
            full
          />
        </Col>
      </Row>

      <StyledDateFilterWrapper>
        <StyledDateFilter>
          <Col
            xs={{ span: 23 }}
            md={{ span: 10 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}
          >
            Callback logs showing results from
          </Col>
          <Col
            xs={{ span: 19 }}
            md={{ span: 11 }}
            lg={{ span: 11 }}
            xl={{ span: 11 }}
          >
            <DateFilter
              onChange={handleDateFilterChange}
              style={rangePickerStyle}
              getPopupContainer={trigger => trigger.parentNode}
              dropdownClassName="responsive-range-picker-dropdown"
              popupStyle={dropdownStyle}
            />
          </Col>
          <Col
            xs={{ span: 1 }}
            md={{ span: 2 }}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
          >
            <Button
              onClick={handleClickDateFilterButton}
              value={`Go`}
              endIcon={<CaretRightFilled />}
              variant="primary"
              style={{ height: '90%' }}
            />
          </Col>
        </StyledDateFilter>
      </StyledDateFilterWrapper>
      <CallbackLogTable />

      <CallbackModal
        loading={modalLoading}
        callbackInteractionIDs={callbackInteractionIDs}
        sessionID={!!questions?.length && selectedSessionID}
        questions={questions}
        show={showModal}
        onClose={handleCloseSessionCallbacks}
        customerInfo={isMobileView ? selectedCallbackLog : null}
      />
    </Spin>
  );
};

CallbackLogs.propTypes = {
  loading: PropTypes.bool,
  isPageReady: PropTypes.bool,
};

export default CallbackLogs;
