import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledWrapper = styled.div`
  margin-top: 30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  height: 70vh;
`;

export const StyledBot = styled.img`
  height: 200px;
  margin-top: 20px;
`;

export const StyledLargeText = styled.div`
  font-weight: ${cssVariables.font.bold};
  color: #d1d3d6;
  font-size: 38px;
`;

export const StyledMessage = styled.div`
  color: ${cssVariables.gray0};
  font-size: 20px;
  font-weight: ${cssVariables.font.bold};
  text-align: center;
`;
