export const userReachedLimit = (featurePlanType, userPlanType) => {
  switch (featurePlanType.toLowerCase()) {
    case 'free':
      return false;
    case 'basic':
      return userPlanType === 'free';
    case 'advanced':
      return userPlanType !== 'advanced';
    default:
      return true;
  }
};
