import React, { useEffect } from 'react';
import { Dropdown, Menu, Breadcrumb, Modal, Col } from 'antd';
import {
  SearchOutlined,
  DownOutlined,
  UpOutlined,
  PlusOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import Joyride from 'react-joyride';
import { useHistory } from 'react-router-dom';

import Title from 'components/Title';
import BotCard from 'components/BotCard';
import BotModal from 'components/Modals/BotModal/BotModal';
import NewUserModal from 'components/Modals/NewUserModal';
import Layout from 'components/Layout';
import JoyrideTooltip from 'components/ToolTips/JoyrideToolTip';
import Button from 'components/Button';
import {
  NewBotCard,
  StyledRoot,
  StyledBotSearch,
  StyledSearchInput,
  StyledBotSorter,
  StyledSortSelected,
  StyledViewTypeMenu,
  StyledMenuOutlined,
  StyledTableOutlined,
  StyledCheckOutlined,
  StyledDownOutlined,
  StyledViewTypeDropDown,
  StyledViewTypeOption,
  StyledViewTypeText,
  StyledListviewBots,
  StyledListviewNewBot,
  StyledNoBots,
  StyledNoBotsText,
  StyledBots,
} from './bots.styles';
import useYourBots from './hooks';
import ROUTES from 'constants/routes';
import WordPressIntegrationModal from 'components/Modals/WordPressIntegrationModal';
import { CLOSE_WORDPRESS_MODAL } from 'store/action';

const { confirm } = Modal;

const steps = [
  {
    content: `This is your Home page where you can view all your Bot. In here you can view all your Bots, the no. of answers in your bot, and the no. of days your bot has been modified. At the top right of your Bot tile, select the menu (vertical ellipsis) to edit or delete the selected bot. 
    To add or create a new bot, click on +New tile card, and enter your Bot details (Bot Name and Bot Description). To open your Bot, click on View Bot button below your Bot's Name.`,
    target: '.view-bot-button',
    disableBeacon: true,
    hideFooter: true,
  },
];

const BOT_SORTING_LIST = [
  {
    key: 0,
    label: 'Date Modified (desc)',
    sortBy: 'lastUpdatedAt',
  },
  {
    key: 1,
    label: 'Date Modified (asc)',
    sortBy: 'lastUpdatedAt',
  },
  {
    key: 2,
    label: 'Date Created (desc)',
    sortBy: 'createdAt',
  },
  {
    key: 3,
    label: 'Date Created (asc)',
    sortBy: 'createdAt',
  },
  {
    key: 4,
    label: 'Bot Name (desc)',
    sortBy: 'name',
  },
  {
    key: 5,
    label: 'Bot Name (asc)',
    sortBy: 'name',
  },
  {
    key: 6,
    label: 'Total Answers (desc)',
    sortBy: 'answerCount',
  },
  {
    key: 7,
    label: 'Total Answers (asc)',
    sortBy: 'answerCount',
  },
];

const YourBots = () => {
  const {
    botsOrder,
    bots,
    searchKey,
    loading,
    tempBot,
    viewType,
    onAddBot,
    onClickCallback,
    onSearchKeyChange,
    runTour,
    setRunTour,
    setShowNewUserModal,
    showNewUserModal,
    handleSortBots,
    handleViewBot,
    handleUpdateViewType,
    stopImpersonation,
    isAdmin,
    isImpersonating,
    isBotModalVisible,
    stepIndex,
    setStepIndex,
    isWordPressModalVisible,
    dispatch,
    isExternalPage,
  } = useYourBots();
  const { push } = useHistory();

  useEffect(() => {
    if (isImpersonating) {
      window.history.pushState(null, window.title, window.location.href);
      window.onpopstate = e => {
        confirm({
          content: (
            <p>
              Impersonating user will end, are you sure you want to go back to
              admin page?
            </p>
          ),
          onOk() {
            stopImpersonation();
            window.history.back();
          },
          onCancel() {
            window.history.pushState(
              null,
              document.title,
              window.location.href
            );
          },
        });
      };

      return () => (window.onpopstate = null);
    }
  }, []);

  const viewTypeMenu = (
    <StyledViewTypeDropDown>
      <Menu.Item onClick={() => handleUpdateViewType('gridview')} key="grid">
        <StyledViewTypeOption>
          <StyledCheckOutlined hidden={viewType === 'listview'} />
          <StyledViewTypeText>Grid</StyledViewTypeText>
          <StyledTableOutlined />
        </StyledViewTypeOption>
      </Menu.Item>
      <Menu.Item onClick={() => handleUpdateViewType('listview')} key="list">
        <StyledViewTypeOption>
          <StyledCheckOutlined hidden={viewType !== 'listview'} />
          <StyledViewTypeText>List</StyledViewTypeText>
          <StyledMenuOutlined />
        </StyledViewTypeOption>
      </Menu.Item>
    </StyledViewTypeDropDown>
  );

  const handleAddBotClick = () => {
    if (tempBot) {
      return false;
    }

    onAddBot();
  };

  const handleCloseNewUserModal = () => {
    setShowNewUserModal(false);
  };

  const getSortBtnTitle = () => BOT_SORTING_LIST[botsOrder.key].label;

  const SortDropdownMenu = (
    <Menu>
      {BOT_SORTING_LIST.map(item => {
        return (
          <StyledSortSelected
            key={item.key}
            icon={item.key % 2 === 0 ? <DownOutlined /> : <UpOutlined />}
            onClick={() => handleSortBots(item)}
            itemKey={item.key}
            botsOrderKey={botsOrder.key}
          >
            {' '}
            {item.label}
          </StyledSortSelected>
        );
      })}
    </Menu>
  );

  const AddNewBot = (
    <StyledListviewNewBot
      newbot
      onClick={handleAddBotClick}
      data-testid="add-new-bot"
    >
      <Button
        disabled={tempBot}
        value="New"
        startIcon={<PlusOutlined />}
        variant="primary"
      />
    </StyledListviewNewBot>
  );

  return (
    <Layout loading={loading}>
      <StyledRoot>
        <Joyride
          run={runTour}
          steps={steps}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          tooltipComponent={JoyrideTooltip}
          continuous
          showProgress={true}
          showSkipButton={true}
          callback={onClickCallback}
        />
        {!isImpersonating && isAdmin ? (
          <Breadcrumb style={{ marginBottom: 20 }}>
            <Breadcrumb.Item
              onClick={() => push(ROUTES.ADMIN_PAGE)}
              style={{ cursor: 'pointer' }}
            >
              <HomeOutlined />
              <span>Back To Admin</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : null}
        <Title
          text="Your bots"
          type="responsive-text"
          rightEl={viewType === 'listview' ? AddNewBot : undefined}
        />

        <StyledBotSearch>
          <Col xs={24} sm={24} md={24} lg={12}>
            <StyledSearchInput
              placeholder="Search bots"
              prefix={<SearchOutlined />}
              value={searchKey}
              onChange={onSearchKeyChange}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <StyledBotSorter>
              {`Sort by: `}
              <Dropdown trigger={['click']} overlay={SortDropdownMenu}>
                <Button
                  variant="secondary"
                  value={getSortBtnTitle() || `Date Modified`}
                  size="middle"
                />
              </Dropdown>
              <Dropdown
                overlay={viewTypeMenu}
                trigger={['click']}
                className="list-dropdown"
              >
                {viewType === 'listview' ? (
                  <StyledViewTypeMenu>
                    <StyledMenuOutlined />
                    <StyledDownOutlined />
                  </StyledViewTypeMenu>
                ) : (
                  <StyledViewTypeMenu>
                    <StyledTableOutlined />
                    <StyledDownOutlined />
                  </StyledViewTypeMenu>
                )}
              </Dropdown>
            </StyledBotSorter>
          </Col>
        </StyledBotSearch>

        {viewType === 'listview' ? (
          <StyledListviewBots data-testid="list-view">
            {tempBot && (
              <BotCard isList key={tempBot.jid} bot={tempBot} loading />
            )}
            {bots.map(bot => (
              <BotCard
                isList
                bot={bot}
                key={bot.jid}
                viewBot={() => handleViewBot(bot)}
              />
            ))}
            {!bots || bots.length === 0 ? (
              <StyledNoBots>
                <StyledNoBotsText>You don't have bots</StyledNoBotsText>
                <StyledNoBotsText>
                  Start creating your first bot!
                </StyledNoBotsText>
              </StyledNoBots>
            ) : null}
          </StyledListviewBots>
        ) : (
          <StyledBots
            className={`${!bots.length ? 'flex' : ''}`}
            data-testid="grid-view"
          >
            <NewBotCard
              variant="outlined"
              className="new-bot"
              onClick={handleAddBotClick}
              data-testid="add-new-bot"
              disabled={tempBot}
            >
              <PlusOutlined />
              <span>New</span>
            </NewBotCard>
            {tempBot && (
              <BotCard
                key={tempBot.jid}
                bot={tempBot}
                viewBot={() => handleViewBot(tempBot)}
                style={{ width: '100%', height: '100%' }}
                loading
              />
            )}
            {bots.map(bot => (
              <BotCard
                bot={bot}
                key={bot.jid}
                viewBot={() => handleViewBot(bot)}
                disabled={tempBot}
              />
            ))}
          </StyledBots>
        )}
      </StyledRoot>

      {isBotModalVisible && !isWordPressModalVisible ? (
        <BotModal setRunTour={setRunTour} disabled={tempBot} />
      ) : null}

      {showNewUserModal &&
      !isWordPressModalVisible &&
      !isBotModalVisible &&
      !tempBot &&
      !bots?.length ? (
        <NewUserModal
          title="New Bot"
          visible={showNewUserModal}
          onCancel={handleCloseNewUserModal}
        />
      ) : null}

      {isWordPressModalVisible &&
      !isBotModalVisible &&
      !tempBot &&
      !bots?.length ? (
        <WordPressIntegrationModal
          visibility={isWordPressModalVisible}
          onClose={() =>
            dispatch({
              type: CLOSE_WORDPRESS_MODAL,
            })
          }
        />
      ) : null}
    </Layout>
  );
};
export default YourBots;
