import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { MINIMUM_RECOMMENDED_WIDTH } from 'constants/screens';
import {
  botModeSelector,
  isPageReadySelector,
  strippedBotJIDSelector,
} from 'selectors/bot';
import { Context } from 'store/store';
import { allAnswersSelector } from 'selectors/bot/answers';
import { onboardingFlagSelector } from 'selectors/plan';
import { getTokenSelector, isExternalPageSelector } from 'selectors/user';
import { isBotSimulatorEnabledSelector } from 'selectors/featureFlags';
import useSelector from 'store/useSelector';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { apiService } from 'services/api.service';
import { SET_ONBOARDING_FLAG } from 'store/action';
import ROUTES from 'constants/routes';
import { aiToolsSelector } from 'selectors/bot/aiTools';
import { isMobileViewSelector } from 'selectors/layout';

const useSidebar = () => {
  const [state, dispatch] = useContext(Context);

  const allAnswers = useSelector(allAnswersSelector);
  const allAITools = useSelector(aiToolsSelector);
  const isPageReady = useSelector(isPageReadySelector);
  const onboardingFlags = useSelector(onboardingFlagSelector);
  const token = useSelector(getTokenSelector);
  const strippedBotID = useSelector(strippedBotJIDSelector);
  const isBotSimulatorEnabled = useSelector(isBotSimulatorEnabledSelector);
  const mode = useSelector(botModeSelector);
  const isExternalPage = useSelector(isExternalPageSelector);
  const isMobileView = useSelector(isMobileViewSelector);

  const [expandedView, setExpandedView] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const {
    bot: { name },
    sentinel,
    graph,
  } = state;

  const history = useHistory();
  const { pathname, hash } = useLocation();

  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const botName = name || 'Unknown Bot';

  useEffect(() => {
    if (isPageReady && !onboardingFlags?.includes('BotSideBarMenu')) {
      setRunTour(true);
    }
    return () => setRunTour(false);
  }, [isPageReady, onboardingFlags]);

  const detectSize = () => {
    setWindowWidth(window.innerWidth);
  };

  const showIconOnly = useMemo(() => {
    // expandedView state will determine the ui
    if (expandedView === true) {
      return false;
    } else if (expandedView === false) {
      return true;
    }
    // but onload expandedView is neither true or false
    // the window size will decide to showIconOnly or not
    return windowWidth < MINIMUM_RECOMMENDED_WIDTH;
  }, [windowWidth, expandedView]);

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowWidth]);

  const handleToggleCollapse = () => {
    if (expandedView === null && !showIconOnly) {
      return setExpandedView(false);
    } else if (expandedView === null && !showIconOnly) {
      return setExpandedView(true);
    }
    return setExpandedView(!expandedView);
  };

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED]?.includes(status)) {
      setRunTour(false);
      setStepIndex(0);
      if (isMobileView) {
        document.body.classList.remove('fullscreen-tour');
      }
      if (!onboardingFlags?.includes('BotSideBarMenu')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'BotSideBarMenu'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'BotSideBarMenu' });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
    }
  };

  const handleClickMenu = (slug, key) => {
    if (slug === 'analytics') {
      history.push(`${ROUTES.BOT_DETAILS}/${strippedBotID}/${slug}#${key}`);
    } else if (isExternalPage && slug === 'integration') {
      history.push(
        `${ROUTES.BOT_DETAILS}/${strippedBotID}/${slug}#integrated-channels`
      );
    } else if (slug === 'answer-bank') {
      history.push(`${ROUTES.BOT_DETAILS}/${strippedBotID}/${slug}/answers`);
    } else {
      history.push(`${ROUTES.BOT_DETAILS}/${strippedBotID}/${slug}`);
    }
  };

  return {
    allAnswers,
    allAITools,
    hash,
    onClickCallback,
    runTour,
    pathname,
    mode,
    handleClickMenu,
    handleToggleCollapse,
    showIconOnly,
    strippedBotID,
    botName,
    isBotSimulatorEnabled,
    stepIndex,
    isExternalPage,
    isMobileView,
  };
};

export default useSidebar;
