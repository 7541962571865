import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledQuestionDetailsModalTitle = styled.h4`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.bold};
  font-size: 18px;
  line-height: 24px;
  color: #33363c;
  margin-bottom: 8px;
`;

export const StyledQuestionActionAndInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30rem;
`;

export const StyledEditQuestion = styled.span`
  font-family: ${cssVariables.font.fontFamily};
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  color: #1667e7;
  cursor: pointer;
`;

export const StyledDotSeparator = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #b3b4b7;
  margin: 0 8px;
`;

export const StyledQuestionTestInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
`;

export const StyledTestStatusInfoContainer = styled.div`
  display: flex;
  align-items: center;

  & div {
    padding: 0 8px;
    font-family: ${cssVariables.font.fontFamily};
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
    border-radius: 3px;
    color: #fff;
  }
`;

export const StyledTestInfoTextSpan = styled.span`
  font-family: ${cssVariables.font.fontFamily};
  font-size: 14px;
  line-height: 20px;
  color: #26292d;
  margin-right: 8px;
`;

export const StyledTestInfoTextDiv = styled.div`
  font-family: ${cssVariables.font.fontFamily};
  font-size: 14px;
  line-height: 20px;
  color: #26292d;
  margin-right: 15px;
`;

export const StyledQuestionContent = styled.div`
  background: #f4f6f8;
  border: 1px solid #d9dadb;
  border-radius: 3px;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #33363c;
  margin-bottom: 16px;
  align-items: center;

  &.defaultAnswer {
    background-color: ${cssVariables.errorBgColor};
    border: 1px solid ${cssVariables.errorBorder};

    & svg {
      color: ${cssVariables.errorText};
      margin-bottom: 2px;
    }
  }

  & img {
    max-width: 100%;
  }
`;

export const StyledQuestionDetailsModalLabel = styled.h4`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.bold};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #33363c;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
