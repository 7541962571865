import { Spin } from 'antd';
import styled from 'styled-components';

export const StyledVisNetworkContainer = styled(Spin)`
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

export const StyledGraphFallbackContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
  align-items: center;

  > h2 {
    height: 100%;
  }
`;

export const NodeDataContainer = styled.div`
  width: 28vw;
  overflow-y: auto;
  max-height: 80vh;

  .anticon {
    font-size: 24px;
    right: 8px;
    top: 8px;
    position: absolute;
    cursor: pointer;
  }

  > div {
    margin: 10px 5px;
    width: 26vw;
    text-align: left;
    word-break: break-word;
  }

  pre {
    white-space: pre-wrap;
  }
`;
