import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect } from 'antd';

import {
  StyledRoot,
  StyledLabel,
  StyledAntdSelect,
} from './LanguagePicker.styles';

import languages from 'constants/languages.json';

const { Option } = AntdSelect;

const LanguagePicker = props => {
  const {
    className,
    label,
    hidden,
    onChange,
    size,
    type,
    full,
    children,
    ghost,
    ...rest
  } = props;

  return (
    <StyledRoot $full={full} className={className || ''}>
      <StyledLabel hidden={hidden}>{label}</StyledLabel>
      <StyledAntdSelect
        size={size}
        onChange={onChange}
        hidden={hidden}
        {...rest}
        $ghost={ghost}
      >
        {children
          ? { ...children }
          : !!languages && Array.isArray(languages)
          ? languages.map((language, key) => (
              <Option
                key={key + 1}
                value={`${language.name} (${language.code})`}
              >
                {`${language.name} (${language.code})`}
              </Option>
            ))
          : null}
      </StyledAntdSelect>
    </StyledRoot>
  );
};

LanguagePicker.defaultProps = {
  size: 'large',
};

LanguagePicker.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  ghost: PropTypes.bool,
  hidden: PropTypes.bool,
  full: PropTypes.bool,
  className: PropTypes.any,
  options: PropTypes.array,
  children: PropTypes.any,
  size: PropTypes.oneOf(['large', 'middle', 'medium', 'small']),
};

export default LanguagePicker;
