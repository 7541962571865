import React, { useContext } from 'react';

import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import {
  postCustomizationSelectedPanelOpenSelector,
  isPostCustomizationEditModalEditableSelector,
  isPostCustomizationEditModalOpenSelector,
} from 'selectors/bot/integration';
import {
  CLOSE_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  OPEN_EDIT_PANEL_INTEGRATION,
} from 'store/action';

import Modal from '../Modals/GenericModal';

const DEFAULT_LOCKED_PANEL = 2;

const PostCustomizationConfirmEditModal = () => {
  const [, dispatch] = useContext(Context);
  const isPostCustomizationEditable = useSelector(
    isPostCustomizationEditModalEditableSelector
  );
  const isPostCustomizationEditModalOpen = useSelector(
    isPostCustomizationEditModalOpenSelector
  );
  const selectedPanelToOpen = useSelector(
    postCustomizationSelectedPanelOpenSelector
  );

  const handleClose = () => {
    dispatch({
      type: CLOSE_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL,
    });
  };

  const handleOk = () => {
    dispatch({
      type: OPEN_EDIT_PANEL_INTEGRATION,
      payload: { panel: selectedPanelToOpen || DEFAULT_LOCKED_PANEL },
    });
  };

  return (
    <Modal
      title="Edit Channel Information"
      visible={isPostCustomizationEditModalOpen && !isPostCustomizationEditable}
      closable={false}
      onOk={handleOk}
      okText={'Yes, Edit channel information'}
      onCancel={handleClose}
    >
      <p>
        {`Making a change in the channel information can result into a change in
        the code. Are you sure you want to edit the channel information?`}
      </p>
    </Modal>
  );
};

export default PostCustomizationConfirmEditModal;
