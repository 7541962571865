import React, { useState, useEffect, useRef, useContext } from 'react';
import vis from 'vis';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { mapValues, startCase, lowerCase } from 'lodash';

import {
  NodeDataContainer,
  StyledGraphFallbackContent,
  StyledVisNetworkContainer,
} from './StyledComponents';
import { Context } from 'store/store';
import { getGraphObject } from 'utils/apiUtils';
import { apiService } from 'services/api.service';
import { GET_DATA_ERROR } from 'constants/error';
import { getLocalTimeString } from 'utils/dates';
import { SET_NETWORK_DIGRAPH_DATA } from 'store/action';
import { CloseCircleFilled } from '@ant-design/icons';
import { cssVariables } from 'styles/root';
import { AnswerRenderer } from 'components/AnswerRenderer';
import { getTokenSelector } from 'selectors/user';
import useSelector from 'store/useSelector';

const GraphNotAvailable = () => (
  <StyledGraphFallbackContent>
    <h2>{'Ooops! Something went wrong. Please try again later.'}</h2>
  </StyledGraphFallbackContent>
);

const DiGraph = ({ jid, options = {}, depth }) => {
  const [state, dispatch] = useContext(Context);
  const token = useSelector(getTokenSelector);
  const {
    admin: { networkData },
  } = state;

  const container = useRef(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  useEffect(() => {
    const graphDepth = !!depth ? depth - 1 : depth;
    const getGraph = async () => {
      setLoading(true);
      try {
        const graphObject = await getGraphObject(jid, token, depth);
        if (graphObject.data.active_gph_id || graphObject.data.jid) {
          try {
            const graphData = await apiService.getGraphData(
              graphObject.data.active_gph_id || graphObject.data.jid,
              token,
              graphDepth
            );

            if (graphData.data.errors) {
              throw new Error(GET_DATA_ERROR);
            }
            const newData = drawNetwork(graphData.data);
            newData.then(data =>
              dispatch({
                type: SET_NETWORK_DIGRAPH_DATA,
                payload: data,
              })
            );
          } catch (error) {
            throw new Error(GET_DATA_ERROR);
          }
        } else {
          setHasError(true);
          setLoading(false);
        }
      } catch (error) {
        setHasError(true);
        setLoading(false);
      }
    };

    if (!Object.keys(networkData).length) {
      getGraph();
    }
  }, [jid, token]);

  const getNodeColor = name => {
    switch (name) {
      case 'answer':
        return cssVariables.primaryBlue;
      case 'default_answer':
        return cssVariables.red10;
      case 'question':
        return cssVariables.warning;
      case 'file':
        return cssVariables.success;
      case 'bot':
      case 'openai_answer':
        return cssVariables.primaryCyan;
      case 'root':
        return cssVariables.errorText;
      default:
        return undefined;
    }
  };

  const drawNetwork = async userDataGraph => {
    try {
      const nodes = new vis.DataSet();
      const edges = new vis.DataSet();

      const data = {
        nodes,
        edges,
      };
      const network =
        container.current && new vis.Network(container.current, data, options);

      await userDataGraph.forEach(i => {
        const {
          name,
          kind,
          jid,
          from_node_id,
          to_node_id,
          bidirected,
          context,
        } = i;
        if (kind === 'node') {
          nodes.add({
            id: jid,
            label: name,
            attributes: i,
            color: getNodeColor(name),
            font: { color: !getNodeColor(name) ? undefined : '#fff' },
          });
        } else {
          edges.add({
            id: jid,
            label: name,
            from: from_node_id,
            to: to_node_id,
            arrows: bidirected
              ? { to: { enabled: true }, from: { enabled: true } }
              : 'to',
            attributes: i,
          });
        }
      });
      network.on('click', item => {
        const node = !!nodes ? nodes.get(item.nodes[0]).attributes : null;
        mapNodeContext(node);
      });
      setLoading(false);
      return data;
    } catch (error) {
      setHasError(true);
      setLoading(false);
    }
  };

  const mapNodeContext = (node, isHovering) => {
    const context = [];

    if (node && node.context && Object.keys(node.context).length) {
      mapValues(node.context, (value, key) => {
        if (lowerCase(key) !== 'private') {
          context.push(`
              <strong>${startCase(key.toString())}: </strong>
              ${
                typeof value === 'object'
                  ? '<pre>' + JSON.stringify(value, null, 2) + '</pre>'
                  : value && lowerCase(key).includes('time')
                  ? getLocalTimeString(value)
                  : value?.toString() || 'None'
              }
              <br />
          `);
        }
      });
    }

    if (context.length && !isHovering) {
      setSelectedNode(Array.from(context).join('\n').toString());
    }

    return context;
  };

  useEffect(() => {
    if (Object.keys(networkData).length && container.current) {
      const network = new vis.Network(container.current, networkData, options);
      network.on('click', item => {
        const node = !!network?.body?.data?.nodes
          ? network.body.data.nodes.get(item.nodes[0]).attributes
          : null;
        mapNodeContext(node);
      });
    }
  }, []);

  return (
    <StyledVisNetworkContainer spinning={loading}>
      {!hasError ? (
        <ErrorBoundary FallbackComponent={GraphNotAvailable}>
          <div
            ref={container}
            style={{
              height: '80vh',
              width:
                selectedNode && Object.keys(selectedNode).length
                  ? '70vw'
                  : '100%',
            }}
          />
          {selectedNode && Object.keys(selectedNode).length ? (
            <NodeDataContainer>
              <CloseCircleFilled
                value={'Close'}
                onClick={() => setSelectedNode(null)}
              />
              <AnswerRenderer showDisplayAnswer={true} answer={selectedNode} />
            </NodeDataContainer>
          ) : null}
        </ErrorBoundary>
      ) : (
        <GraphNotAvailable />
      )}
    </StyledVisNetworkContainer>
  );
};

DiGraph.propTypes = {
  jid: PropTypes.string.isRequired,
  options: PropTypes.object,
  depth: PropTypes.number,
};

export default DiGraph;
