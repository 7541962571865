import styled from 'styled-components';
import { Modal } from 'antd';
import { cssVariables } from 'styles/root';

export const StyledModal = styled(Modal)`
  & .ant-modal-footer {
    text-align: left;
    & button {
      margin-right: 8px;
    }
  }
`;

export const StyledModalTitle = styled.h4`
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.normal};
  font-size: 18px;
  color: #000;
`;

export const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
`;

export const StyledContent = styled.div`
  width: 70%;
  padding-right: 20px;
`;

export const StyledQuestionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 90%;
  margin-left: auto;
  margin-bottom: 15px;
`;

export const StyledQuestion = styled.span`
  padding: 15px;
  color: #fff;
  border-radius: 10px;
  background-color: rgba(33, 35, 38, 0.774);
`;

export const StyledAnswerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 90%;
`;

export const StyledAnswer = styled.div`
  padding: 15px;
  border-radius: 10px;
  background-color: #f0f2f5;

  & p {
    margin-bottom: 0px;
  }
`;

export const StyledDateTime = styled.div`
  font-size: 12px;
`;
