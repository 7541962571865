import styled, { css } from 'styled-components';
import { Card, Input, Menu, Row } from 'antd';
import {
  MenuOutlined,
  TableOutlined,
  DownOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { cssVariables } from 'styles/root';

export const NewBotCard = styled(Card)`
  width: 100%;
  background-color: #fff;
  border: 1px solid ${cssVariables.gray1};
  border-radius: 5px;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 150px;
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  span {
    font-size: 14px;
  }
  :hover {
    cursor: pointer;
    background: ${cssVariables.primaryTransparent};
    color: ${cssVariables.secondaryColor};
    div {
      color: ${cssVariables.secondaryColor};
    }
  }

  .anticon-plus {
    font-size: 32px;
    font-weight: ${cssVariables.font.bold};
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const StyledRoot = styled.div`
  padding: 0px;
  min-height: 84vh;
  position: relative;

  & .MuiPaper-root {
    & .anticon {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;

export const StyledBotSearch = styled(Row)`
  margin-top: 27px;
  margin-bottom: 30px;
`;

export const StyledSearchInput = styled(Input)`
  height: 40px;
  border-radius: 3px;
  width: 100%;
`;

export const StyledBotSorter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledSortSelected = styled(Menu.Item)`
  background-color: ${props =>
    props.botsOrderKey === props.itemKey ? cssVariables.primaryBlue : ''};
  color: ${props => (props.botsOrderKey === props.itemKey ? '#fff' : '')};
`;

export const StyledViewTypeMenu = styled.div`
  background: #e8e8e8;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${cssVariables.gray0};
    color: #fff;
  }
`;

export const StyledMenuOutlined = styled(MenuOutlined)`
  margin-right: 5px;
`;

export const StyledTableOutlined = styled(TableOutlined)`
  margin-right: 5px;
`;

export const StyledCheckOutlined = styled(CheckOutlined)`
  margin-right: 5px;
`;

export const StyledDownOutlined = styled(DownOutlined)`
  font-size: 12px !important;
`;

export const StyledViewTypeDropDown = styled(Menu)`
  & .ant-dropdown-menu-item {
    padding: 0px;
  }
`;

export const StyledViewTypeOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px;

  & :hover {
    background: #f0f2f5;
  }
`;

export const StyledViewTypeText = styled.span`
  font-weight: ${cssVariables.font.bold};
  width: 80px;
`;

export const StyledListviewBots = styled.div`
  position: relative;
`;

export const StyledListviewNewBot = styled.div`
  ${props =>
    props.newBot
      ? css`
          column-gap: 7px;
          display: flex;
          flex-direction: row;
          text-align: center;
          height: 45px;
          width: 100px;
          padding: 10px;
        `
      : null}
`;

export const StyledNoBots = styled.div`
  text-align: center;
  margin-top: 100px;
`;

export const StyledNoBotsText = styled.p`
  font-family: ${cssVariables.font.fontFamily};
  font-size: 18px;
  margin-bottom: 8px;
`;

export const StyledBots = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;

  &.flex {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    & .MuiPaper-root,
    > div {
      display: flex;
      width: 250px;
      max-width: 300px;
    }

    @media (min-width: 620px) and (max-width: 767px) {
      display: grid;
      gap: 20;
    }

    @media (min-width: 0px) and (max-width: 890px) {
      display: grid;
      gap: 20px;

      & .MuiPaper-root,
      > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  & .ant-spin-spinning,
  & .ant-spin-container {
    height: 100%;
    width: 100%;
  }
`;
