import styled from 'styled-components';
import { Menu } from 'antd';
import { cssVariables } from 'styles/root';

export const StyledSortSelected = styled(Menu.Item)`
  color: ${props =>
    props.answersOrderKey === props.itemKey ? cssVariables.primaryBlue : ''};
`;

export const StyledMenu = styled(Menu)`
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%) !important;
`;
