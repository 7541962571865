/* eslint-disable react/display-name */
import React from 'react';
import { List, Tabs, Card, Table, Spin, Avatar } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  AndroidFilled,
  AppleFilled,
  GlobalOutlined,
  MobileOutlined,
  DeleteOutlined,
  AppstoreOutlined,
  FacebookOutlined,
  PhoneOutlined,
  ApiOutlined,
} from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';

import BackButton from 'components/BackButton';
import Web from './Web';
import Demo from './Demo';
import Mobile from './Mobile';
import Others from './Others';

import { slugify, stringLocaleCompare } from 'utils/stringManipulation';
import {
  IconRowsWrapper,
  StyledRoot,
  StyledCardContainer,
  StyledPlatformList,
  StyledTitle,
  StyledIntegrationContainer,
} from './Integration.styles';
import useIntegration from './hooks';
import LimitationBanner from 'components/LimitationBanner';
import { cssVariables } from 'styles/root';
import ConfirmDelete from 'components/Modals/ConfirmDelete';
import FacebookMessenger from './FacebookMessenger';
import Viber from './Viber';
import Button from 'components/Button';
import ROUTES from 'constants/routes';
import { INTEGRATION_PLATFORMS_AS_LIST } from 'constants/integrationPlatforms';
import { botStatistics } from 'utils/bot';
import { StyledSpaceBetweenFlexCenter } from 'styles/GenericStyledComponents';
import {
  WeakbotWarning,
  WeakbotWarningClickable,
} from '../Overview/Overview.style';
import { WeakStatPopover } from 'components/WeakStatPopover';
import { withPlanLimit } from 'components/hoc/withPlanLimit';
import UpgradeTag from 'components/UpgradeTag';
import { userReachedLimit } from 'utils/plan';
import WordpressIcon from 'assets/images/wordpress-icon.svg';
import Wordpress from './Wordpress';
import API from './API';
import PostCustomizationConfirmEditModal from 'components/BotCustomizer/PostCustomizationConfirmEditModal';
import PreCustomizationConfirmEditModal from 'components/BotCustomizer/PreCustomizationConfirmEditModal';

const { TabPane } = Tabs;

const appType = [
  {
    label: 'Android',
    desc: 'Android',
    value: 'Android',
  },
  {
    label: 'IOS',
    desc: 'IOS',
    value: 'IOS',
  },
  {
    label: 'Both',
    desc: 'Both',
    value: 'Both',
  },
];

const UPGRADE_TO_ADVANCED = 'Contact Us';

const Integration = () => {
  const {
    contentLoading,
    handleDelete,
    integrations,
    isAdvancedUser,
    isFreeUser,
    itemToDelete,
    jid,
    locationState,
    modalLoading,
    name,
    planType,
    platform,
    setItemToDelete,
    setShowDeleteModal,
    showDeleteModal,
    isPageReady,
    isExternalPage,
    apiGatewayConfig,
    isPostCustomizationEditModalOpen,
    isPreCustomizationEditModalOpen,
  } = useIntegration();
  const { hash } = useLocation();
  let history = useHistory();

  const integrationColumns = [
    {
      title: 'Name',
      dataIndex: 'identifier',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.identifier, b.identifier),
      },
      render: (identifier, row) => {
        const isWeakChannel = row.showWeakStat;
        const { redirect, totalQuestions } = botStatistics(row, jid);
        return (
          <StyledSpaceBetweenFlexCenter>
            <Link to={{ search: `?platform=${row.type}`, state: row }}>
              {identifier}
            </Link>
            {isWeakChannel ? (
              <WeakbotWarning>
                {`There are`}{' '}
                <WeakStatPopover
                  location="integrations"
                  dataStatName="channel"
                  weakData={row}
                  jid={jid}
                >
                  <WeakbotWarningClickable
                    onClick={() => history.push(redirect)}
                  >
                    {totalQuestions} questions
                  </WeakbotWarningClickable>
                </WeakStatPopover>{' '}
                {`which were not correctly answered by the bot
          in the last 7 days.`}{' '}
              </WeakbotWarning>
            ) : null}
          </StyledSpaceBetweenFlexCenter>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '20%',
      align: 'center',
      sorter: {
        compare: (a, b) => stringLocaleCompare(a.type, b.type),
      },
    },
    {
      title: 'Delete',
      width: '10%',
      align: 'center',
      render: row => {
        return (
          <DeleteOutlined
            onClick={() => {
              setItemToDelete(row);
              setShowDeleteModal(true);
            }}
          />
        );
      },
    },
  ];

  const getButtonStyles = (btnPlan, label) => {
    if (planType === 'advanced' || btnPlan === 'free') {
      return {
        text: label,
      };
    } else if (btnPlan !== 'free' && planType !== btnPlan) {
      return {
        text: btnPlan !== 'advanced' ? 'Upgrade' : UPGRADE_TO_ADVANCED,
      };
    } else if (!isFreeUser && !isAdvancedUser && btnPlan !== planType) {
      return {
        text: btnPlan === 'basic' ? 'Upgrade' : UPGRADE_TO_ADVANCED,
      };
    }
    return {
      text: btnPlan === 'free' ? 'Connect' : label,
    };
  };

  const getButtonRedirect = (btnLabel, value) => {
    if (btnLabel === 'Upgrade') {
      return history.push(ROUTES.SUBSCRIPTION);
    } else if (value === 'api' && !apiGatewayConfig) {
      return history.push(ROUTES.API_KEY);
    } else {
      return history.push({
        search: `?platform=${value}`,
      });
    }
  };

  const PlatformList = () => (
    <List
      dataSource={INTEGRATION_PLATFORMS_AS_LIST}
      renderItem={(item, idx) => {
        const { text } = getButtonStyles(item.btnPlan, item.buttonLabel);
        const IntegrationButtons = withPlanLimit(
          item.btnPlan,
          item.value === 'api' ? (
            <Button
              value={!apiGatewayConfig ? 'Generate' : 'Connect'}
              onClick={() => getButtonRedirect(text, item.value)}
            />
          ) : (
            <Button
              value={'Connect'}
              onClick={() => getButtonRedirect(text, item.value)}
            />
          ),
          <UpgradeTag size="large" />
        );
        return (
          <List.Item key={idx + 1} actions={[<IntegrationButtons />]}>
            <StyledPlatformList
              greyedOut={userReachedLimit(item.btnPlan, planType)}
              avatar={item.icon}
              title={item.label}
              description={
                item.value.includes('demo')
                  ? item.desc
                  : `Add ${name} to your ${item.desc}`
              }
            />
          </List.Item>
        );
      }}
    />
  );

  const IntegrationChannel = ({ integrations }) => (
    <Spin spinning={!isPageReady || contentLoading}>
      <Table columns={integrationColumns} dataSource={integrations} />
    </Spin>
  );

  const integrationTabs = [
    {
      title: 'All Channels',
      content: <PlatformList />,
    },
    {
      title: 'Integrated Channels',
      content: <IntegrationChannel integrations={integrations} />,
    },
  ];

  const Content = () => {
    switch (platform) {
      case 'web': {
        return (
          <>
            <BackButton prevPageTitle="Integration" />
            <StyledTitle>
              <GlobalOutlined />
              {` Web${!!locationState ? ' - ' + locationState.identifier : ''}`}
            </StyledTitle>
            <Card>
              <Web isPageReady={isPageReady || !contentLoading} />
            </Card>
          </>
        );
      }
      case 'wordpress': {
        return (
          <>
            <BackButton prevPageTitle="Integration" />
            <StyledTitle>
              <Avatar src={WordpressIcon} size={38} />
              {` WordPress${
                !!locationState ? ' - ' + locationState.identifier : ''
              }`}
            </StyledTitle>
            <Card>
              <Wordpress
                isPageReady={isPageReady || !contentLoading}
                data={locationState}
                appType={appType}
              />
            </Card>
          </>
        );
      }
      case 'mobile': {
        return (
          <>
            <BackButton prevPageTitle="Integration" />
            <StyledTitle>
              <MobileOutlined />
              {` Mobile${
                !!locationState ? ' - ' + locationState.identifier : ''
              }`}
            </StyledTitle>
            <Card>
              <Mobile
                isPageReady={isPageReady || !contentLoading}
                data={locationState}
                appType={appType}
              />
            </Card>
          </>
        );
      }
      case 'facebook-messenger': {
        return (
          <>
            <BackButton prevPageTitle="Integration" />
            <StyledTitle>
              <FacebookOutlined />
              {` Facebook Messenger${
                !!locationState ? ' - ' + locationState.identifier : ''
              }`}
            </StyledTitle>
            <Card>
              <FacebookMessenger
                isPageReady={isPageReady || !contentLoading}
                data={locationState}
                appType={appType}
              />
            </Card>
          </>
        );
      }
      case 'viber': {
        return (
          <>
            <BackButton prevPageTitle="Integration" />
            <StyledTitle>
              <PhoneOutlined />
              {` Viber${
                !!locationState ? ' - ' + locationState.identifier : ''
              }`}
            </StyledTitle>
            <Card>
              <Viber
                isPageReady={isPageReady || !contentLoading}
                data={locationState}
                appType={appType}
              />
            </Card>
          </>
        );
      }
      case 'api': {
        return (
          <>
            <BackButton prevPageTitle="Integration" />
            <StyledTitle>
              <ApiOutlined />
              {` API${!!locationState ? ' - ' + locationState.identifier : ''}`}
            </StyledTitle>
            <Card>
              <API
                isPageReady={isPageReady || !contentLoading}
                data={locationState}
              />
            </Card>
          </>
        );
      }
      case 'demo-web':
        return (
          <>
            <BackButton prevPageTitle="Integration" />
            <Card>
              <Demo isPageReady={isPageReady || !contentLoading} />
            </Card>
          </>
        );
      case 'others': {
        return (
          <>
            <BackButton prevPageTitle="Integration" />
            <StyledTitle>
              <AppstoreOutlined /> Others
            </StyledTitle>
            <Card>
              <Others
                isPageReady={isPageReady || !contentLoading}
                data={locationState}
              />
            </Card>
          </>
        );
      }

      default:
        return (
          <>
            <StyledTitle>Integration</StyledTitle>
            <StyledCardContainer>
              <Tabs
                defaultActiveKey="all-channels"
                activeKey={hash ? hash.replace('#', '') : 'all-channels'}
                type="card"
                onChange={key => history.push(`#${key}`)}
              >
                {!!integrationTabs &&
                  integrationTabs.map(tab => {
                    const slug = slugify(tab.title);
                    return (
                      <TabPane
                        tab={tab.title}
                        key={slug}
                        disabled={isFreeUser && !isExternalPage}
                      >
                        {tab.content}
                      </TabPane>
                    );
                  })}
              </Tabs>
              {isFreeUser && (
                <LimitationBanner
                  title={
                    'Website, iOS, Android and more channels for integration!'
                  }
                  subtitle={
                    'Upgrade and integrate your bot on multiple channels.'
                  }
                  icon={
                    <IconRowsWrapper>
                      <GlobalOutlined
                        style={{ color: cssVariables.primaryBlue }}
                      />
                      <AppleFilled style={{ color: cssVariables.gray0 }} />
                      <AndroidFilled style={{ color: cssVariables.green0 }} />
                    </IconRowsWrapper>
                  }
                />
              )}
            </StyledCardContainer>
          </>
        );
    }
  };

  return (
    <StyledRoot>
      <StyledIntegrationContainer>
        {Content()}
        <ConfirmDelete
          itemTobeDeleted={itemToDelete.identifier}
          loading={modalLoading}
          onOk={() => handleDelete(itemToDelete.id)}
          onCancel={() => {
            setItemToDelete({});
            setShowDeleteModal(false);
          }}
          show={showDeleteModal}
          modalTitle="Delete Integration"
        />
        {isPreCustomizationEditModalOpen ? (
          <PreCustomizationConfirmEditModal />
        ) : null}
        {isPostCustomizationEditModalOpen ? (
          <PostCustomizationConfirmEditModal />
        ) : null}
      </StyledIntegrationContainer>
    </StyledRoot>
  );
};

export default Integration;
