export const getIntegrationPlatform = search => {
  const query = new URLSearchParams(search);
  return query.get('platform');
};

export const isHexValid = string => {
  const hexRegex = /^[0-9A-Fa-f]+$/g;

  if (hexRegex.test(string)) {
    return true;
  }
  return false;
};

export const normalizeHex = string => {
  if (!string || !isHexValid(string)) {
    return null;
  } else if (string.charAt(0) !== '#') {
    return '#' + string;
  }

  return string;
};
