import { createSelector } from 'reselect';
import { pageInitializedSelector } from 'selectors/layout';
import {
  initialCategoryModalState,
  initialQuestionEditorModalState,
} from 'store/initialState';
import { stripUUID } from 'utils';
import { parseBoolean } from 'utils/dataTypes';

export const currentBotSelector = state => state.bot;
export const getFeaturesStateSelector = state => state.featureFlags;

const getBotUIStateSelector = createSelector(
  currentBotSelector,
  botState => botState.ui
);

export const botNameSelector = createSelector(
  currentBotSelector,
  bot => bot.name
);

export const answerCountSelector = createSelector(
  currentBotSelector,
  bot => bot.answerCount || 0
);

export const fileCountSelector = createSelector(
  currentBotSelector,
  bot => bot.fileCount || 0
);

export const totalArtefactsSelector = createSelector(
  answerCountSelector,
  fileCountSelector,
  (answerCount, fileCount) => answerCount + fileCount
);

export const featureFlagSelector = createSelector(
  getFeaturesStateSelector,
  featuresState => featuresState
);

export const featureFlagVersionSelector = createSelector(
  featureFlagSelector,
  featureFlag => featureFlag?.version
);

export const featureFlagFeaturesSelector = createSelector(
  featureFlagSelector,
  featureFlag => featureFlag || {}
);

export const getBotModalsStateSelector = createSelector(
  getBotUIStateSelector,
  botUiState => botUiState.modals
);

export const isPageReadySelector = createSelector(
  getBotUIStateSelector,
  currentBotSelector,
  pageInitializedSelector,
  (botUiState, bot, initialized) =>
    (botUiState.detailsPageReady || bot.hasError) && initialized
);

export const strippedBotJIDSelector = createSelector(currentBotSelector, bot =>
  stripUUID(bot.jid)
);

export const botJIDSelector = createSelector(
  currentBotSelector,
  bot => bot.jid
);

export const selectedCategorySelector = createSelector(
  currentBotSelector,
  bot => bot.selectedCategory || initialCategoryModalState
);

export const getAllQuestionsWithAnswerSelector = createSelector(
  currentBotSelector,
  bot => bot.questions || []
);

export const questionEditorSelector = createSelector(
  getBotModalsStateSelector,
  bot => bot.questionEditorModal || initialQuestionEditorModalState
);

export const showDraftResponsesSelector = createSelector(
  currentBotSelector,
  botState => botState.showDraftResponses
);

export const showBelowThresholdResponsesSelector = createSelector(
  currentBotSelector,
  botState => botState.showBelowThresholdResponses
);

export const showWeakBotStatInOverviewSelector = createSelector(
  currentBotSelector,
  getBotUIStateSelector,
  (bot, ui) => {
    return parseBoolean(bot.isWeak && ui?.weakBotStats?.overview);
  }
);

export const isAutoTranslateResponseSelector = createSelector(
  currentBotSelector,
  botState => parseBoolean(botState.autoTranslateResponse)
);

export const isBotTypingExperienceEnabledSelector = createSelector(
  currentBotSelector,
  botState => parseBoolean(botState.typingExperience)
);

export const botModeSelector = createSelector(
  currentBotSelector,
  bot => bot.mode
);

export const botPersonaSelector = createSelector(
  currentBotSelector,
  bot => bot.persona
);

export const botPersonaRetrievalCountSelector = createSelector(
  currentBotSelector,
  bot => bot.retrievalCount
);

export const autoTranslateResponseSelector = createSelector(
  currentBotSelector,
  bot => bot.autoTranslateResponse
);

// checks if openAI for bot enabled
export const isBotOpenAIEnabledSelector = createSelector(
  currentBotSelector,
  botState => parseBoolean(botState.useOpenAI)
);

// checks if openAI featureFlag is enabled
export const isOpenAIFeatureEnabledSelector = createSelector(
  featureFlagFeaturesSelector,
  features => parseBoolean(features.openai)
);

// checks if openAI for bot enabled
// and
// checks if openAI featureFlag is enabled
export const isOpenAIBotAndFeatureEnabledSelector = createSelector(
  isBotOpenAIEnabledSelector,
  isOpenAIFeatureEnabledSelector,
  (isBotOpenAIEnabled, openAIFeatureEnabled) =>
    parseBoolean(isBotOpenAIEnabled && openAIFeatureEnabled)
);

export const shouldShowTestChatUseContextSelector = createSelector(
  isBotOpenAIEnabledSelector,
  featureFlagFeaturesSelector,
  (isBotOpenAIEnabled, features) =>
    isBotOpenAIEnabled && parseBoolean(features?.contextHistory)
);

export const botMailConfigSelector = createSelector(
  currentBotSelector,
  bot => bot.mailConfig || {}
);

export const isBotMailConfigEnabledSelector = createSelector(
  currentBotSelector,
  bot => Boolean(bot.shouldSendCallbackLog)
);
