import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modals/GenericModal';
import Button from 'components/Button';
import {
  StyledExportContainer,
  StyledExportLabel,
  StyledFooterWrapper,
} from './ExportModal.style';
import useExportModal from './hooks';
import { Checkbox, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const ExportModal = ({ onClose, show, onloadSelected }) => {
  const {
    exportOptions,
    selectExportType,
    loading,
    handleChangeExportType,
    handleDownloadData,
  } = useExportModal({
    onClose,
    show,
    onloadSelected,
  });

  return (
    <>
      <Modal
        visible={show}
        onCancel={onClose}
        cancelText="Close"
        destroyOnClose
        title="Export"
        footer={
          <StyledFooterWrapper>
            <div className="action-buttons">
              <Button
                variant="primary-btn-v2"
                value="Export"
                icon={<DownloadOutlined />}
                onClick={handleDownloadData}
                disabled={selectExportType?.length === 0}
              />
            </div>
          </StyledFooterWrapper>
        }
      >
        <Spin spinning={loading} tip="Just a moment...">
          <StyledExportContainer>
            <StyledExportLabel>Select data to be exported:</StyledExportLabel>
            {exportOptions?.map(option => (
              <Checkbox
                onChange={handleChangeExportType}
                value={option.value}
                checked={selectExportType.includes(option.value)}
              >
                {option.label}
              </Checkbox>
            ))}
          </StyledExportContainer>
        </Spin>
      </Modal>
    </>
  );
};

ExportModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  onloadSelected: PropTypes.array,
};

export default ExportModal;
