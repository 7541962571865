export const FILTER_METHOD_OPTIONS = [
  {
    label: 'Exact string',
    value: 'exactString',
  },
  {
    label: 'Start with',
    value: 'start',
  },

  {
    label: 'Start with origin and path only',
    value: 'originPath',
  },
  {
    label: 'Start with origin only',
    value: 'origin',
  },
];

export const SCANNING_METHOD_OPTIONS = [
  { label: 'Scan all possible URL', value: 'all' },
  { label: ' Includes domain name', value: 'sameDomainOnly' },
];

export const FILTER_METHOD_TOOLTIP = 'Capture all available links that';
export const SPINNER_LABEL = 'Just a moment...';

export const SCAN_OPTIONS = [
  {
    value: 'fullWebsite',
    title: 'Full Website',
    description: 'Scan entire content from the provided page.',
    crawlerParams: 'origin',
    displayScanMethodOptions: false,
    showTargetElementOptions: false,
  },
  {
    value: 'nestedPages',
    title: 'Only Nested Pages',
    description: `Scan only nested pages within the URL you provided.`,
    crawlerParams: 'start',
    displayScanMethodOptions: false,
    showTargetElementOptions: false,
  },
  {
    value: 'single',
    title: 'A Single Page',
    description: 'Scan only single URL you provided.',
    crawlerParams: null,
    displayScanMethodOptions: false,
    showTargetElementOptions: true,
  },
  // {
  //   value: 'traversing',
  //   title: 'Custom Traversing',
  //   description: 'Scanning can be more customizable.',
  //   crawlerParams: 'start',
  //   displayScanMethodOptions: true,
  //   showTargetElementOptions: true,
  // },
];

export const INITIAL_SELECTED_PAGE_LIST = {
  category: null,
  urls: [],
};

export const INITIAL_URL_LIST = [
  {
    key: 0,
    url: null,
  },
];

export const INITIAL_TARGET_ELEMENT = {
  draft: 'body',
  final: 'body',
};

export const UPLOAD_METHOD_OPTIONS = [
  {
    label: 'Dom Content Loaded',
    value: 'domcontentloaded',
    description:
      'Use when contents are rendered in server and returned without the need of lazy loading.',
    example: 'e.g. Static Websites',
  },
  {
    label: 'Load',
    value: 'load',
    description: 'Use when website follows lazy loading approach. ',
    example: 'e.g. React/Angular Websites',
  },
  {
    label: 'Network Idle',
    value: 'networkidle',
    description:
      "Use when load is not sufficient and website's lazy loading approach requires multiple requests that may takes time to complete. ",
    example:
      "Not recommended as this will be base on network traffic if it's been idle for 500ms.",
  },
];

export const VALID_DOMAIN_LIST = [
  'https://www.',
  'www.',
  'http://www.',
  'http://',
  'https://',
];

export const VALID_FILE_FORMATS =
  '.txt, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .csv';

export const DEFAULT_WEBSITE_FORM_STATE_VALUE = {
  urlList: INITIAL_URL_LIST,
  selectedPageList: INITIAL_SELECTED_PAGE_LIST,
  selectedTemplate: 'fullWebsite',
  targetElement: INITIAL_TARGET_ELEMENT,
  selectedScanningMethod: 'all',
  selectedUploadingMethod: 'load',
  isTargetElementOpen: false,
};
