import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import { Spin, Switch, Tag, message, Typography, Radio } from 'antd';

import Modal from 'components/Modals/GenericModal';
import {
  StyledFlexRowLeft,
  StyledFlexRowRight,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  StyledFormToolsContent,
  StyledFormItem,
  StyledBtnAdvanceSettings,
} from './StyledComponents';
import Button from 'components/Button';
import { aiTools } from 'services/aiTools.service';
import useSelector from 'store/useSelector';
import CodeBlock from 'components/CodeBlock';
import { botJIDSelector } from 'selectors/bot';
import {
  DEFAULT_STATIC_OUTPUT_FIElDS,
  DEFAULT_URL_OUTPUT_FIElDS,
  DEFAULT_WALKER_OUTPUT_FIElDS,
  NEW_OBJECT_ITEM,
  OUTPUT_TYPES,
} from 'constants/aiTools';
import { aiToolModalSelector } from 'selectors/bot/ui';
import { Context } from 'store/store';
import { ADD_AI_TOOL, EDIT_AI_TOOL } from 'store/action';
import ToolTip from 'components/ToolTips/BaseToolTip';
import Output from './Output';
import { isMobileViewSelector } from 'selectors/layout';

const { Text } = Typography;

const FunctionModal = props => {
  const [, dispatch] = useContext(Context);
  const botJID = useSelector(botJIDSelector);
  const isMobileView = useSelector(isMobileViewSelector);
  const selectedTool = useSelector(aiToolModalSelector);
  const modalAction = selectedTool.action;
  const { title, onClose } = props;
  const [paramProps, setParamsProps] = useState(
    selectedTool.parameters?.properties?.length > 0
      ? selectedTool.parameters?.properties
      : NEW_OBJECT_ITEM
  );
  const [outputProps, setOutputProps] = useState(
    selectedTool.output || DEFAULT_URL_OUTPUT_FIElDS
  );
  const [toolName, setToolName] = useState(selectedTool.name || null);
  const [toolDescription, setToolDescription] = useState(
    selectedTool.description || null
  );
  const [version, setVersion] = useState(selectedTool.version || 'draft');
  const [requiredFields, setRequiredFields] = useState(
    selectedTool?.parameters?.required || []
  );
  const [modalLoading, setModalLoading] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const isFormValid = useMemo(() => {
    const isOutputValid =
      outputProps?.filter(
        output => output.required === true && output.value === ''
      ).length === 0;

    return toolName && toolDescription && isOutputValid;
  }, [toolName, toolDescription, outputProps]);

  const addProps = (evt, isOutputProps) => {
    const newKey = isOutputProps ? outputProps.length : paramProps.length;
    const newProps = {
      position: newKey + 1,
      name: '',
    };

    return isOutputProps
      ? setOutputProps([...outputProps, newProps])
      : setParamsProps([...paramProps, newProps]);
  };

  const onSelectOutputType = e => {
    setShowAdvancedSettings(false);
    if (e.target.value === selectedTool?.outputInTable?.type) {
      // fill output props with previous values
      setOutputProps(selectedTool.output);
    } else {
      switch (e.target.value) {
        case 'url':
          setOutputProps(DEFAULT_URL_OUTPUT_FIElDS);
          break;

        case 'walker':
          setOutputProps(DEFAULT_WALKER_OUTPUT_FIElDS);
          break;

        default:
          setOutputProps(DEFAULT_STATIC_OUTPUT_FIElDS);
          break;
      }
    }
  };

  const handleChangeToolName = e => {
    setToolName(e.target.value);
  };

  const handleChangeToolDescription = e => {
    setToolDescription(e.target.value);
  };

  const handleSubmitTool = async e => {
    e.preventDefault();
    try {
      setModalLoading(true);
      if (modalAction === 'add') {
        const output = objectifiedOutput;

        if (!output.auth?.type) {
          delete output.auth;
        }

        const res = await aiTools.addTool(
          toolName,
          toolDescription,
          objectifiedParamProps?.hasOwnProperty() ? objectifiedParamProps : {},
          objectifiedOutput,
          version,
          requiredFields,
          botJID
        );

        dispatch({
          type: ADD_AI_TOOL,
          payload: res.data.report,
        });
      } else {
        const {
          name,
          description,
          parameters,
          jid: toolJid,
          output,
          version: currentVersion,
        } = selectedTool;
        const res = await aiTools.changeTool(toolJid, {
          // removing unchanged props
          name: toolName === name ? undefined : toolName,
          description:
            toolDescription === description ? undefined : toolDescription,
          ver: version === currentVersion ? undefined : version,
          requiredFields: isEqual(parameters.required, requiredFields)
            ? undefined
            : requiredFields,
          properties: isEqual(paramProps, parameters?.properties)
            ? undefined
            : objectifiedParamProps,
          output: isEqual(output, outputProps) ? undefined : objectifiedOutput,
        });

        dispatch({
          type: EDIT_AI_TOOL,
          payload: res.data.report,
        });
      }
      setShowAdvancedSettings(false);
      setModalLoading(false);
      message.success(
        `Successfully ${modalAction === 'add' ? 'saved' : 'updated'} AI tool`
      );
    } catch (error) {
      setShowAdvancedSettings(false);
      setModalLoading(false);
      message.error(error?.message || 'Error saving AI tool');
    }
  };

  const objectifiedParamProps = useMemo(() => {
    return paramProps?.reduce((acc, item) => {
      const key = item.name;
      const objectProperties = {};

      if (!isEmpty(item.properties)) {
        item.properties.forEach(item => {
          const { name, value, position, ...rest } = item;
          // dont include name, value and position
          objectProperties[name] = rest;
        });
      }
      const newItem = {
        ...item,
        properties: isEmpty(item.properties) ? undefined : objectProperties,
      };
      // delete fields not to be shown on preview object
      delete newItem.position;
      delete newItem.name;

      acc[key] = newItem;

      return acc;
    }, {});
  }, [paramProps]);

  const authParamsPropsOutput = auth => {
    const authTypeValue = auth?.request?.find(
      request => request.name === 'type'
    )?.value;
    const objectType = authTypeValue === 'bearer' ? 'header' : 'request';

    const objectRequest = auth?.request?.reduce(
      (acc, item) => {
        const key = item?.name || '';
        const value = item?.value || null;
        const objectProperties = {};
        const displayData = item.authType === authTypeValue;
        if (
          item.type === 'object' &&
          !isEmpty(item.properties) &&
          displayData
        ) {
          item.properties.forEach(item => {
            const { name, value } = item;
            // pair the name & value
            // to create key value pair as an object item
            // for the properties array
            if (name && name !== 'type') {
              objectProperties[name] = value && value !== 'null' ? value : null;
            }
          });
          // assign the object to create a nested object for 'object' type
          const newItem = {
            [key]: objectProperties,
          };

          return Object.assign(acc, newItem);
        } else if (displayData) {
          // assign directly the name & value
          // to create key value pair
          const newItem = {
            [key]: value && value !== 'null' ? value : null,
          };

          return Object.assign(acc, newItem);
        } else {
          return acc;
        }
      },
      // initialize the accumulator with the output type
      // other items are stored in an array
      // so we use `reduce` to create nested object from the array
      {}
    );

    return {
      [auth.name]: {
        type: authTypeValue,
        [objectType]: objectRequest,
      },
    };
  };

  const objectifiedOutput = useMemo(() => {
    return outputProps?.reduce(
      (acc, item) => {
        const key = item?.name || '';
        const value = item?.value || null;
        const objectProperties = {};
        if (item.type === 'object' && !isEmpty(item.properties)) {
          item.properties.forEach(item => {
            const { name, value } = item;
            // pair the name & value
            // to create key value pair as an object item
            // for the properties array
            if (name) {
              objectProperties[name] = value && value !== 'null' ? value : null;
            }
          });
          // assign the object to create a nested object for 'object' type
          const newItem = {
            [key]: objectProperties,
          };

          return Object.assign(acc, newItem);
        } else if (item.name === 'auth') {
          const newItem = authParamsPropsOutput(item);
          return Object.assign(acc, newItem);
        } else {
          // assign directly the name & value
          // to create key value pair
          const newItem = {
            [key]: value && value !== 'null' ? value : null,
          };

          return Object.assign(acc, newItem);
        }
      },
      // initialize the accumulator with the output type
      // other items are stored in an array
      // so we use `reduce` to create nested object from the array
      {}
    );
  }, [outputProps]);

  const selectedOutput = useMemo(() => {
    if (outputProps) {
      return outputProps[0]?.value;
    }
  }, [outputProps]);

  const getOutputPropertyDetails = value => {
    return outputProps?.find(output => output.name === value);
  };

  const validateToolName = (_, value) => {
    const regex = /^[a-zA-Z0-9_-]{1,64}$/;
    if (!value) {
      return Promise.reject('Please enter your tool name.');
    }

    if (!regex.test(value)) {
      return Promise.reject('Please enter a valid tool name.');
    }
    return Promise.resolve();
  };

  const CodeBlockDetails = () => (
    <CodeBlock
      codeContent={{
        name: toolName,
        description: toolDescription,
        parameters: {
          type: 'object',
          properties: objectifiedParamProps,
          required: requiredFields,
        },
        version,
        output: objectifiedOutput,
      }}
      style={{
        height: '100%',
        marginLeft: 10,
      }}
    />
  );

  return (
    <Modal
      open={true}
      title={
        <div>
          <span>
            {title ||
              `${selectedTool?.action === 'edit' ? 'Edit' : 'Add'} Tool`}
          </span>
          &nbsp;
          <Tag color={version === 'draft' ? 'red' : 'success'}>
            {version.toUpperCase()}
          </Tag>
        </div>
      }
      onCancel={onClose}
      isFormModal
      width={'95%'}
      okText={'Submit'}
      onOk={handleSubmitTool}
      footer={
        <StyledSpaceBetweenFlexCenter>
          <Switch
            onChange={e => setVersion(e ? 'final' : 'draft')}
            checked={version === 'final'}
            checkedChildren={'final'}
            unCheckedChildren={'draft'}
          />
          <StyledFlexRowRight>
            <Button
              loading={modalLoading}
              value="Cancel"
              onClick={onClose}
              variant="secondary"
            />
            <Button
              loading={modalLoading}
              value="Submit"
              onClick={handleSubmitTool}
              disabled={!isFormValid}
            />
          </StyledFlexRowRight>
        </StyledSpaceBetweenFlexCenter>
      }
    >
      <Spin
        spinning={modalLoading}
        fullWidth={isMobileView}
        tip="Just a moment..."
      >
        <StyledFlexRowLeft
          style={{
            alignItems: 'flex-start',
          }}
        >
          <StyledFormToolsContent fullWidth={true} name="toolForm">
            <StyledFormItem
              name="toolName"
              rules={[{ validator: validateToolName }]}
            >
              <Input
                size="small"
                label={
                  <>
                    Name: {''}
                    <ToolTip title={`Name should be _ separated.`}>
                      <InfoCircleTwoTone />
                    </ToolTip>
                  </>
                }
                defaultValue={toolName}
                onChange={handleChangeToolName}
                placeholder={'Enter tool name...'}
              />
            </StyledFormItem>
            <StyledFormItem
              name="toolDescription"
              rules={[
                { required: true, message: 'Please enter tool description.' },
              ]}
            >
              <TextArea
                label="Description"
                defaultValue={toolDescription}
                onChange={handleChangeToolDescription}
                placeholder={'Enter tool description...'}
              />
            </StyledFormItem>

            <StyledFlexRowLeft>
              <Text strong style={{ margin: '15px 0' }}>
                Output:
              </Text>
              <Radio.Group
                options={OUTPUT_TYPES}
                onChange={onSelectOutputType}
                defaultValue={selectedOutput}
              />
            </StyledFlexRowLeft>

            {outputProps && (
              <Output
                outputProps={outputProps}
                requiredFields={requiredFields}
                getOutputPropertyDetails={getOutputPropertyDetails}
                setOutputProps={setOutputProps}
                advanceSettingOption={false}
              />
            )}

            {OUTPUT_TYPES.find(
              outputType => outputType.value === selectedOutput
            ).showAdvanceSettings && (
              <StyledFlexRowLeft>
                <StyledBtnAdvanceSettings
                  onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                  type="button"
                >
                  {showAdvancedSettings === false
                    ? 'Show advanced settings'
                    : 'Hide advanced settings'}
                </StyledBtnAdvanceSettings>
              </StyledFlexRowLeft>
            )}
          </StyledFormToolsContent>
          {!isMobileView && (
            <StyledFlexRowLeft
              style={{ width: '40%', height: '100%', alignSelf: 'normal' }}
            >
              <CodeBlockDetails />
            </StyledFlexRowLeft>
          )}
        </StyledFlexRowLeft>

        <StyledFormToolsContent fullWidth={true} name="outputForm">
          {outputProps && (
            <Output
              outputProps={outputProps}
              requiredFields={requiredFields}
              getOutputPropertyDetails={getOutputPropertyDetails}
              setOutputProps={setOutputProps}
              advanceSettingOption={true}
              showAdvancedSettings={showAdvancedSettings}
              setParamsProps={setParamsProps}
              paramProps={paramProps}
              objectifiedParamProps={objectifiedParamProps}
              addProps={addProps}
              setRequiredFields={setRequiredFields}
            />
          )}
        </StyledFormToolsContent>

        {isMobileView && (
          <StyledFlexRowLeft
            style={{
              alignItems: 'flex-start',
              height: '100%',
              alignSelf: 'normal',
            }}
          >
            <CodeBlockDetails />
          </StyledFlexRowLeft>
        )}
      </Spin>
    </Modal>
  );
};

FunctionModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default FunctionModal;
