import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export const StyledDemoLink = styled.div`
  display: flex;
  padding: 5px;
  border-radius: 5px;
  justify-content: space-between;
  background-color: ${props =>
    props.isLight ? cssVariables.secondaryColor : cssVariables.blue1};
  align-items: center;

  & .anticon {
    padding: 5px;
    font-size: 18px;
    color: ${props => props.isLight && cssVariables.gray0};
  }
`;
