import React from 'react';
import { Popover } from 'antd';

import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';
import Input from 'components/Input';
import {
  StyledAvatar,
  StyledBotProfileBlock,
  StyledBtnAdvanceSettings,
  StyledBtnEditBotProfile,
  StyledErrorBottomText,
  StyledLabel,
  StyledSelect,
} from '../../CustomizeBot.style';
import ColorPicker from '../../ColorPicker';
import { generateIcon } from '../../../iconUtils';
import {
  MAX_WIDGET_HEIGHT,
  MIN_WIDGET_HEIGHT,
  TEXT_COLOR_LIST,
} from '../../constants';
import AdvanceSettings from './AdvanceSettings';
import PanelActionButtons from '../../PanelActionButtons';
import { useAppearancePanel } from './hooks';

const NEXT_PANEL_KEY = 'bot-customizer-panel-3';

const AppearancePanel = () => {
  const {
    handleEditAvatar,
    handleChangeTheme,
    handleChangeHeight,
    handleSelectFontColor,
    setHeight,
    setShowColorPicker,
    setShowAdvanceSettings,
    showColorPicker,
    showAdvanceSettings,
    iconColor,
    color,
    height,
    textColor,
    botName,
    isAppearancePanelSettingsUnchanged,
    launcherAvatar,
    tempHeight,
    handlePanelChange,
  } = useAppearancePanel();

  return (
    <StyledFlexLeftColumn>
      <StyledLabel>{`${botName} Widget launcher Icon`}</StyledLabel>
      <StyledBotProfileBlock>
        {
          <StyledAvatar
            color={color}
            size={65}
            src={generateIcon(
              launcherAvatar,
              iconColor,
              '60',
              '60',
              launcherAvatar === 'default' || !launcherAvatar
                ? '-1 6 40 20'
                : launcherAvatar === 'icon1'
                ? '-3 -1 28 25'
                : launcherAvatar === 'icon2'
                ? '-2.2 -1.8 28 25 '
                : '0 0 24 25'
            )}
          />
        }

        <StyledBtnEditBotProfile onClick={handleEditAvatar} type="button">
          {'Edit'}
        </StyledBtnEditBotProfile>
      </StyledBotProfileBlock>

      <Popover
        content={
          <ColorPicker
            color={color}
            onChangeColor={handleChangeTheme}
            setShowColorPicker={setShowColorPicker}
          />
        }
        trigger="click"
        open={showColorPicker}
        onOpenChange={() => setShowColorPicker(!showColorPicker)}
        overlayClassName={{
          '& .ant-popover-inner': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <Input
          label="Theme Color"
          value={color}
          onChange={handleChangeTheme}
          placeholder={`Pick your bot color (e.g. #D3D3D3)`}
          full
        />
      </Popover>
      {!color ? (
        <StyledErrorBottomText>
          {'Theme Color is Required.'}
        </StyledErrorBottomText>
      ) : null}
      <StyledSelect
        value={textColor || '#fff'}
        onChange={handleSelectFontColor}
        label="Chat text color"
        options={TEXT_COLOR_LIST}
      />

      <Input
        label="Height"
        value={String(tempHeight || height)?.replace('px', '')}
        defaultValue={height}
        full
        type="number"
        min={MIN_WIDGET_HEIGHT}
        max={MAX_WIDGET_HEIGHT}
        placeholder={`Enter bot widget height (${MIN_WIDGET_HEIGHT}-${MAX_WIDGET_HEIGHT})`}
        onChange={e => setHeight(e.target.value)}
        onBlur={handleChangeHeight}
      />
      {!tempHeight ? (
        <StyledErrorBottomText>{'Height is Required.'}</StyledErrorBottomText>
      ) : null}

      <StyledBtnAdvanceSettings
        onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
        type="button"
      >
        {showAdvanceSettings === false
          ? 'Show advanced settings'
          : 'Hide advanced settings'}
      </StyledBtnAdvanceSettings>
      <AdvanceSettings showAdvanceSettings={showAdvanceSettings} />
      <PanelActionButtons
        isHidden={isAppearancePanelSettingsUnchanged}
        disabled={!color || !textColor || !height}
        nextPanelKey={NEXT_PANEL_KEY}
        onPanelChange={handlePanelChange}
        panelName="appearance"
      />
    </StyledFlexLeftColumn>
  );
};

export default AppearancePanel;
