import { TEST_STATUS } from 'constants/testSuite';

export const getTestResult = (testStatus, lastTestTime, lastUpdated) => {
  const { EDITED, PASSED, FAILED, UNTESTED } = TEST_STATUS;
  if (lastUpdated > lastTestTime && lastTestTime) {
    return EDITED;
  } else {
    switch (testStatus) {
      case 1:
        return PASSED;

      case 0:
        return FAILED;
      default:
        return UNTESTED;
    }
  }
};

// data => testCase data response from API
// do not use this if data is already parsed/camelCase or is from context store
export const createTestCaseData = data => {
  const lastTestStatus = {
    success: 0,
    fail: 0,
    unknown: 0,
    outdated: 0,
  };

  const testCases = data.map(testCase => {
    const { context, jid } = testCase;
    const {
      question,
      expected_answer,
      last_actual_answer,
      last_ran_time,
      last_test_status,
      last_updated_time,
    } = context;

    if (
      testCase.context.last_ran_time &&
      new Date(testCase.context.last_updated_time) >
        new Date(testCase.context.last_ran_time)
    ) {
      lastTestStatus.outdated++;
    } else if (testCase.context.last_test_status === 1) {
      lastTestStatus.success++;
    } else if (testCase.context.last_test_status === 0) {
      lastTestStatus.fail++;
    } else {
      lastTestStatus.unknown++;
    }
    return {
      jid,
      key: jid,
      question,
      answer: expected_answer,
      actualAnswer: last_actual_answer,
      lastTestTime: last_ran_time,
      testStatus: last_test_status,
      lastUpdated: last_updated_time,
      testResult: getTestResult(
        last_test_status,
        last_ran_time,
        last_updated_time
      ),
    };
  });

  return {
    lastTestStatus,
    testCases: testCases || [],
  };
};

export const updateTestCase = (data, currentTestCases) => {
  return currentTestCases.map(testCase => {
    if (data.jid === testCase.jid) {
      const {
        last_actual_answer,
        last_ran_time,
        last_test_status,
        last_updated_time,
        expected_answerid,
        created_time,
      } = data?.context;

      return {
        ...testCase,
        created_time,
        expectedAnswer: expected_answerid,
        actualAnswer: last_actual_answer,
        lastTestTime: last_ran_time,
        testStatus: last_test_status,
        testResult: getTestResult(
          last_test_status,
          last_ran_time,
          last_updated_time
        ),
      };
    }
    return testCase;
  });
};

export const getTestCaseCurrentStatus = data => {
  const status = {
    success: 0,
    fail: 0,
    unknown: 0,
    outdated: 0,
  };
  for (const testCase of data) {
    if (
      new Date(testCase.context.last_updated_time) >
      new Date(testCase.context.last_ran_time)
    ) {
      status.outdated++;
    } else if (testCase.context.last_test_status === 1) {
      status.success++;
    } else if (testCase.context.last_test_status === 0) {
      status.fail++;
    } else {
      status.unknown++;
    }
  }
  return status;
};

export const createTestSuiteDataFromAPI = data => {
  if (Array.isArray(data)) {
    return data.map(data => {
      return {
        jid: data.jid,
        name: data.context.name,
        lastTestRanTime: data.context.last_ran_time,
        lastTestStatus: data.context.last_result_summary,
        lastModified: data.context.last_updated_time,
        testCount: data.context.test_count,
      };
    });
  } else {
    return {
      jid: data.jid,
      name: data.context.name,
      lastTestRanTime: data.context.last_ran_time,
      lastTestStatus: data.context.last_result_summary,
      lastModified: data.context.last_updated_time,
      testCount: data.context.test_count,
    };
  }
};
