import {
  CaretDownOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Col } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const Wrapper = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2%;
  border-bottom: 1px solid ${props => props.theme.gray4};
  background: ${props =>
    props.isSelected ? `${props.theme.blue1} !important` : 'none'};

  &.highlight {
    border: 1px solid ${props => props.theme.primaryBlue};
    background: ${cssVariables.blue1};
  }
  &.seekBgColor {
    background-color: ${props => props.theme.blue1};
  }

  &.defaultAnswer {
    background-color: ${cssVariables.red1};
    box-sizing: border-box;
    box-shadow: inset 0px 2px 0px ${cssVariables.red10};
  }

  :hover:not(.isTitleRow) {
    box-shadow: ${props => props.theme.itemListShadow};

    .hit-count {
      display: none;
    }
    .link-questions {
      display: none;
    }
    .action {
      display: ${props => (props.isSelected ? 'none' : 'flex')};
    }
  }
  .action {
    display: none;
  }
`;

export const StyledColumn = styled(Col)`
  margin-right: 1%;
  word-break: break-word;
  text-align: justify;
  font-weight: ${props =>
    props.titleCell ? cssVariables.font.bold : cssVariables.font.normal};
  flex-direction: ${props =>
    props.lastCol || props.showDisplayAnswer ? 'column' : 'row'};

  :hover {
    text-align: ${props => (props.lastCol ? 'left' : 'justify')};
  }

  img {
    max-width: 80%;
  }

  .ant-tag {
    align-self: center;
    height: fit-content;
  }

  .ant-btn {
    font-weight: ${props =>
      props.titleCell ? cssVariables.font.bold : cssVariables.font.normal};
  }

  .ant-btn:hover,
  .ant-btn:active,
  .ant-btn:focus {
    background-color: white !important;
    color: ${cssVariables.primaryGray} !important;
  }

  // span {
  //   font-size: ${props => (props.font ? props.font + 'px' : '1rem')};
  // }
`;

export const StyledCategory = styled.div`
  margin-right: 1%;
  cursor: pointer;
`;

export const StyledCategoryName = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 5px;
  text-align: left;
  background: ${props =>
    props.currentCategory ? props.currentCategory.color : ''};
  color: ${props =>
    props.currentCategory ? props.currentCategory && '#fff' : ''};
`;

export const StyledCategoryDropdownIcon = styled(CaretDownOutlined)`
  margin-left: 10px;
  fill: #b3b4b7;
  & path {
    fill: #b3b4b7;
  }
`;

export const StyledEdit = styled(EditOutlined)`
  margin-right: 10px;
  padding: 10px;
  color: #98a5ac;
  &:hover {
    background: ${cssVariables.gray4};
    border-radius: 50%;
  }
`;

export const StyledMore = styled(MoreOutlined)`
  transform: rotate(-90deg);
  padding: 10px;
  color: #98a5ac;
  &:hover {
    background: ${cssVariables.gray4};
    border-radius: 50%;
  }
`;

export const StyledAnswerType = styled.span`
  text-transform: capitalize;
`;
