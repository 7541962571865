import { RESET_INVOICE, SET_INVOICES } from 'store/action';

export const invoiceReducer = (state, action) => {
  switch (action.type) {
    case SET_INVOICES: {
      try {
        const invoiceList = action.payload?.map(invoice => {
          return {
            periodStart: invoice.lines?.data[0]?.period?.start,
            periodEnd:
              invoice.lines?.data[invoice.lines?.data?.length - 1]?.period?.end,
            currency: invoice.currency,
            amount: invoice.total,
            status: invoice.status,
            file: invoice.invoice_pdf,
            hostedURL: invoice.hosted_invoice_url,
            id: invoice.id,
          };
        });
        return {
          ...state,
          invoices: invoiceList,
        };
      } catch (error) {
        return state;
      }
    }
    case RESET_INVOICE: {
      return {
        ...state,
        invoices: [],
      };
    }

    default:
      return state;
  }
};
