import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Upload, Slider, Popover, Col, Row } from 'antd';

import {
  StyledAdvanceSettings,
  StyledGradientDiv,
  StyledImageUploaderWrapper,
  StyledInputGradient,
  StyledLabel,
  StyledTempIconImage,
} from '../../../CustomizeBot.style';
import UploadButton from 'components/BotCustomizer/UploadButton/UploadButton';
import { CONTEXT_HISTORY } from 'constants/featureFlags';
import { withFeatureFlag } from 'components/hoc/withFeatureFlags';
import Input from 'components/Input';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
import ContextHistory from './ContextHistory';
import ColorPicker from '../../../ColorPicker';
import useAdvanceSettings from './hooks';
import SwitchSection from './ToggleSwitchSection';
import ToolTip from 'components/ToolTips/BaseToolTip';

const AdvanceSettings = props => {
  const { showAdvanceSettings } = props;

  const {
    currentSettings,
    handleChangeAvatarType,
    handleChangeHeaderImage,
    handleSelectBubbleColor,
    handlePreviewAvatar,
    showColorPickerBubble,
    setShowColorPickerBubble,
    widgetThemeColor,
    showHeaderImagePreview,
    handleChangeReplyBubbleGradient,
    replyBubbleGradient,
    handleChangeFontSize,
    handleBlurFontSizeInput,
    tempFontSize,
    onToggleSectionChange,
    isUseWidgetIconsDisabled,
    responseAvatar,
    responseAvatarType,
    headerAvatar,
    headerAvatarType,
    handleRemoveImage,
  } = useAdvanceSettings();

  const { avatarPosition, bubbleColor } = currentSettings;

  const ContextHistoryComponent = withFeatureFlag(
    CONTEXT_HISTORY,
    <ContextHistory />
  );

  return (
    <div>
      <StyledAdvanceSettings hidden={showAdvanceSettings === false}>
        <StyledLabel style={{ width: '100%', display: 'flex' }}>
          {'Header Image'}
        </StyledLabel>

        <Radio.Group
          value={headerAvatarType}
          onChange={e => handleChangeAvatarType('header', e.target.value)}
        >
          <Row>
            <Col xs={{ span: 23 }} md={{ span: 6 }}>
              <Radio value="none">{'None'}</Radio>
            </Col>
            <Col xs={{ span: 23 }} md={{ span: 12 }}>
              <ToolTip
                title={
                  isUseWidgetIconsDisabled
                    ? 'Current icon is not allowed'
                    : undefined
                }
              >
                <Radio value="icon" disabled={isUseWidgetIconsDisabled}>
                  {'Use Widget Icons'}
                </Radio>
              </ToolTip>
            </Col>
            <Col xs={{ span: 23 }} md={{ span: 6 }}>
              <Radio value="custom">{'Custom'}</Radio>
            </Col>
          </Row>
        </Radio.Group>

        <br />
        <br />
        <StyledFlexRowLeft
          hidden={
            avatarPosition !== 'header' &&
            headerAvatarType !== 'custom' &&
            headerAvatar !== 'custom'
          }
        >
          <StyledTempIconImage
            hidden={!showHeaderImagePreview}
            onClick={handlePreviewAvatar}
          >
            <img src={headerAvatar} alt="widget icon" />
          </StyledTempIconImage>
          <StyledImageUploaderWrapper>
            <Upload
              beforeUpload={({ file }) =>
                file.type === 'image/png' || file.type === 'image/jpg'
              }
              listType="picture-card"
              onPreview={handlePreviewAvatar}
              onChange={handleChangeHeaderImage}
              onRemove={handleRemoveImage}
              accept=".png, .jpg, .jpeg"
              maxCount={1}
            >
              <UploadButton />
            </Upload>
          </StyledImageUploaderWrapper>
        </StyledFlexRowLeft>

        <StyledLabel style={{ width: '100%', display: 'flex' }}>
          {'Avatar Next To Response'}
        </StyledLabel>

        <Radio.Group
          value={responseAvatarType}
          onChange={e => handleChangeAvatarType('response', e.target.value)}
        >
          <Row>
            <Col xs={{ span: 23 }} md={{ span: 8 }}>
              <Radio value="none">{'None'}</Radio>
            </Col>
            <Col xs={{ span: 23 }} md={{ span: 16 }}>
              <Radio value="icon" disabled={isUseWidgetIconsDisabled}>
                {'Use Widget Icon'}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
        <Row>
          <Col xs={{ span: 21 }} md={{ span: 8 }}>
            <Popover
              content={
                <ColorPicker
                  color={bubbleColor}
                  onChangeColor={handleSelectBubbleColor}
                  setShowColorPickerBubble={setShowColorPickerBubble}
                />
              }
              trigger="click"
              open={showColorPickerBubble}
              onOpenChange={() =>
                setShowColorPickerBubble(!showColorPickerBubble)
              }
              overlayClassName={{
                '& .ant-popover-inner': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }}
            >
              <Input
                label="Chat Bubble Color"
                value={bubbleColor || widgetThemeColor}
                placeholder={`Pick bubble color (e.g. #D3D3D3)`}
              />
            </Popover>
          </Col>
          <Col xs={{ span: 23 }} md={{ span: 8 }}>
            <StyledGradientDiv span={6}>
              <StyledLabel>{'Gradient'}</StyledLabel>
              <Slider
                min={0}
                max={1}
                onAfterChange={handleChangeReplyBubbleGradient}
                value={replyBubbleGradient}
                step={0.01}
              />
            </StyledGradientDiv>
          </Col>
          <Col xs={{ span: 21 }} md={{ span: 8 }}>
            <StyledInputGradient
              min={0}
              max={1}
              value={replyBubbleGradient}
              onChange={handleChangeReplyBubbleGradient}
              step={0.01}
            />
          </Col>
        </Row>
        <Row>
          <Col span={21}>
            <Input
              label="Font Size"
              value={tempFontSize}
              type="number"
              max={100}
              placeholder={`Enter font size`}
              onChange={handleChangeFontSize}
              onBlur={handleBlurFontSizeInput}
            />
          </Col>
        </Row>

        <SwitchSection onToggleChange={onToggleSectionChange} />

        <ContextHistoryComponent />
      </StyledAdvanceSettings>
    </div>
  );
};

AdvanceSettings.propTypes = {
  showAdvanceSettings: PropTypes.bool,
};

export default AdvanceSettings;
