import React from 'react';
import { TwitterPicker } from 'react-color';
import PropTypes from 'prop-types';
import { Menu, Popover } from 'antd';

import TemplateCategories from 'components/TemplateCategories';
import {
  StyledRoot,
  StyledCategoryModalContent,
  StyledCategoryModalSidebar,
  StyledCategoryModalSection,
  StyledCategoryModalSectionTitle,
  StyledCategoryNameInput,
  StyledCategoryColorPicker,
  StyledPickedCategoryColor,
  StyledAlertCard,
  StyledSearchTemplateCategory,
  StyledSearchOutlined,
} from './StyledComponents';
import useCategoryForm from './hooks';
import ColorPicker from 'components/BotCustomizer/CustomizeBot/ColorPicker';
import Button from 'components/Button';

const ColorsAvailable = [
  '#b80000',
  '#862d43',
  '#bf4040',
  '#e91e63',
  '#f06292',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#004dcf',
  '#8ed1fc',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#ff9800',
  '#e27300',
  '#e65100',
  '#795548',
  '#607d8b',
  '#2d5786',
  '#33691e',
];

const CategoryForm = props => {
  const { onSave, ...rest } = props;
  const {
    categoryColor,
    categoryName,
    filteredTemplates,
    searchInput,
    selectedCategory,
    handleSidebarClick,
    handleChangeColor,
    handleChangeName,
    onAddTemplateCategory,
    onChangeSearchInput,
    onRemoveTemplateCategory,
  } = useCategoryForm();

  return (
    <StyledRoot>
      {selectedCategory?.errors?.length ? (
        <StyledAlertCard type="error" message={selectedCategory.errors} />
      ) : null}
      <StyledCategoryModalContent>
        {selectedCategory.action === 'add' && (
          <StyledCategoryModalSidebar>
            <Menu
              onClick={handleSidebarClick}
              defaultSelectedKeys={
                selectedCategory.isTemplate ? ['template'] : ['category']
              }
            >
              <Menu.Item key="category">{'Create New'}</Menu.Item>
              <Menu.Item key="template">{'Template'}</Menu.Item>
            </Menu>
          </StyledCategoryModalSidebar>
        )}
        {selectedCategory.isTemplate ? (
          <StyledCategoryModalSection>
            <StyledSearchTemplateCategory
              prefix={<StyledSearchOutlined />}
              value={searchInput}
              onChange={onChangeSearchInput}
              placeholder="Search in Template"
            />
            {filteredTemplates.map((template, index) => {
              const isEven = index % 2 === 0;
              const isSelected = selectedCategory.templates.some(
                selectedTemplate =>
                  selectedTemplate.name.toLowerCase() ===
                  template.name.toLowerCase()
              );

              return (
                <TemplateCategories
                  isEven={isEven}
                  template={template}
                  color={selectedCategory.color}
                  isSelected={isSelected}
                  onAddTemplateCategory={onAddTemplateCategory}
                  onRemoveTemplateCategory={onRemoveTemplateCategory}
                />
              );
            })}
          </StyledCategoryModalSection>
        ) : (
          <StyledCategoryModalSection>
            <StyledCategoryModalSectionTitle>
              {'Category Name'}
            </StyledCategoryModalSectionTitle>
            <form onSubmit={onSave}>
              <StyledCategoryNameInput
                autoFocus
                required
                defaultValue={categoryName}
                onChange={handleChangeName}
                maxLength={30}
              />
            </form>
            <StyledCategoryModalSectionTitle>
              {'Category Color'}
            </StyledCategoryModalSectionTitle>
            <StyledCategoryColorPicker>
              <>
                <TwitterPicker
                  triangle="hide"
                  width="400px"
                  colors={ColorsAvailable}
                  color={selectedCategory?.color}
                  onChange={color => handleChangeColor(color.hex)}
                />
                <Popover
                  content={
                    <ColorPicker
                      color={selectedCategory?.color}
                      onChangeColor={color => handleChangeColor(color.hex)}
                    />
                  }
                  trigger="click"
                  overlayClassName={{
                    '& .ant-popover-inner': {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <Button value={'More Colors'} variant="link" size={'small'} />
                </Popover>
              </>
            </StyledCategoryColorPicker>
            <div>
              <StyledCategoryModalSectionTitle>
                {'Preview'}
              </StyledCategoryModalSectionTitle>
              <StyledPickedCategoryColor color={selectedCategory?.color}>
                {categoryName || selectedCategory.name || 'Category Name'}
              </StyledPickedCategoryColor>
            </div>
          </StyledCategoryModalSection>
        )}
      </StyledCategoryModalContent>
    </StyledRoot>
  );
};

CategoryForm.propTypes = {
  onSave: PropTypes.func,
};

export default CategoryForm;
