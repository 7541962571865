import { useContext, useState } from 'react';
import { message } from 'antd';

import { Context } from 'store/store';
import { DELETE_BOT, SHOW_BOT_MODAL } from 'store/action';
import useSelector from 'store/useSelector';
import {
  allBotsSelector,
  botsExceedsLimitSelector,
  showWeakBotStatSelector,
  totalExceedingBotsSelector,
} from 'selectors/bots';
import { deleteBot } from 'services/bots.service';

const useBotCard = ({ bot }) => {
  const [state, dispatch] = useContext(Context);
  const { sentinel, token } = state;

  const allBots = useSelector(allBotsSelector);
  const showWeakBotStat = useSelector(showWeakBotStatSelector, bot.jid);
  const botsExceedsLimit = useSelector(botsExceedsLimitSelector);
  const totalExceedingBots = useSelector(totalExceedingBotsSelector);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEditBot = () => {
    dispatch({
      type: SHOW_BOT_MODAL,
      payload: {
        ...bot,
        action: 'edit',
      },
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteBot(sentinel, bot.jid, token);
      await dispatch({ type: DELETE_BOT, payload: bot.jid });
      setLoading(false);
      message.success('Bot successfully deleted.');
      return allBots;
    } catch (err) {
      setLoading(false);
      message.error('Something went wrong while deleting your bot');
    }
  };

  return {
    botsExceedsLimit,
    handleEditBot,
    totalExceedingBots,
    handleDelete,
    isConfirmModalOpen,
    loading,
    showWeakBotStat,
    setIsConfirmModalOpen,
  };
};

export default useBotCard;
