import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledRoot = styled.div`
  display: flex;
  align-items: start;
  padding: 16px 8px;
  border: 1px solid ${cssVariables.gray3};
  margin: 5px 0px;
  border-radius: 5px;
  flex-direction: column;

  &:hover {
    border: '1px solid ${cssVariables.primaryBlue}';
  }
`;

export const StyledAnswerToLink = styled.div`
  flex: 1 1 auto;
  font-family: 'Helvetica';
  font-size: 15px;
  line-height: 24px;
  color: ${cssVariables.blueishGray};
  padding: 5px;
  word-break: break-word;
  display: block;

  & img {
    max-width: 100%;
  }
`;

export const StyledAnswerLinks = styled.div`
  display: block;
  margin-left: 16px;
  font-size: 12px;

  & a {
    margin-left: 15px;
  }
  > * {
    margin-right: 10px;
  }
`;

export const StyledAnswerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  width: 100%;

  & svg {
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-right: 10px;
    color: ${cssVariables.gray1};

    &:hover {
      color: ${cssVariables.primaryBlue};
    }
  }

  &.selected svg {
    color: ${cssVariables.green1};
  }
`;

export const StyledAnswerScore = styled.div`
  margin-left: 32px;
  margin-right: 20px;
  border-radius: 5px;
  padding: 6px 8px;

  &.answerScore {
    background-color: ${cssVariables.gray3};
  }

  &.currentAnswer {
    color: #fff;
  }

  &.answerScoreSuccess {
    background-color: #23b576 !important;
  }

  &.answerScoreDanger {
    background-color: ${cssVariables.errorText} !important;
    color: #fff;
  }
`;
