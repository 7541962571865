import styled from 'styled-components';
import { Menu } from 'antd';
import { cssVariables } from 'styles/root';
import { StyledFlexColumn } from 'styles/GenericStyledComponents';

export const StyledProfileSibar = styled(Menu)`
  margin-top: 20px;

  & .ant-menu-item {
    padding-left: 36px !important;
    margin: 0px !important;
    font-size: 16px;
  }
`;

export const StyledTransactionParagraph = styled.span`
  color: ${props => (props.isRed ? '#ff4d4f' : 'auto')};
`;

export const StyledUserContentWrapper = styled(StyledFlexColumn)`
  min-width: 350px;
`;
