import { message } from 'antd';
import { useState, useContext } from 'react';

import { isTotalAnswersSelectedFromCategoryTemplatesReachMaxAnswerCountSelector } from 'selectors/bot/answers';
import { allCategoriesSelector } from 'selectors/bot/categories';
import { importCategoryTemplates } from 'services/categories.service';
import {
  ADD_CATEGORY,
  IMPORT_CATEGORY_TEMPLATES,
  SET_CATEGORY_MODAL_CLOSE,
  SET_SELECTED_CATEGORY_DETAILS,
  UPDATE_CATEGORY,
} from 'store/action';
import { Context } from 'store/store';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { selectedCategorySelector } from 'selectors/bot';
import useSelector from 'store/useSelector';
import { apiService } from 'services/api.service';

const useViewCategories = () => {
  const [state, dispatch] = useContext(Context);
  const {
    sentinel,
    token,
    bot: { jid },
  } = state;
  const selectedCategory = useSelector(selectedCategorySelector);
  const allCategories = useSelector(allCategoriesSelector);
  const isTotalAnswersSelectedFromCategoryTemplatesReachMaxAnswerCount =
    useSelector(
      isTotalAnswersSelectedFromCategoryTemplatesReachMaxAnswerCountSelector
    );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isEditingCategory =
    !!selectedCategory.jid || selectedCategory.action === 'add';

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      if (selectedCategory.action === 'edit') {
        await handleUpdateCategory();
      } else if (
        selectedCategory.action === 'add' &&
        !selectedCategory.isTemplate
      ) {
        await handleAddNewCategory();
      } else if (selectedCategory.isTemplate && selectedCategory.templates) {
        const res = await importCategoryTemplates(
          selectedCategory.templates,
          jid,
          sentinel,
          token
        );

        if (!res.data.success) {
          throw new Error(DEFAULT_ERROR_MESSAGE);
        } else if (
          res.data.report[0] &&
          !res.data.report[0].answer?.length &&
          !res.data.report[0].category?.length
        ) {
          message.warning('Category templates and answers already exists.');
        } else {
          dispatch({
            type: IMPORT_CATEGORY_TEMPLATES,
            payload: res.data.report[0],
          });
          message.success('Category templates successfully added.');
          handleCloseModal();
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddNewCategory = async () => {
    const { name, color } = selectedCategory;
    try {
      const res = await apiService.createCategory(
        sentinel,
        jid,
        name,
        color,
        token
      );
      const categoryData = res.data.report[0];
      dispatch({ type: ADD_CATEGORY, payload: categoryData });
      dispatch({ type: SET_CATEGORY_MODAL_CLOSE });
    } catch (err) {
      message.error(err.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleUpdateCategory = async () => {
    const { name, color, jid } = selectedCategory;
    try {
      const res = await apiService.updateCategory(
        sentinel,
        jid,
        name,
        color,
        token
      );
      const categoryData = res.data.report[0];
      dispatch({ type: UPDATE_CATEGORY, payload: categoryData });
      dispatch({ type: SET_CATEGORY_MODAL_CLOSE });
      message.success(`${name} category successfully updated.`);
    } catch (err) {
      message.success(err.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleCloseModal = () => {
    setError(null);
    dispatch({
      type: SET_CATEGORY_MODAL_CLOSE,
    });
  };

  const handleBackToCategories = () => {
    dispatch({
      type: SET_SELECTED_CATEGORY_DETAILS,
      payload: {
        action: 'view-categories',
      },
    });
  };

  return {
    error,
    handleSubmit,
    allCategories,
    isEditingCategory,
    isTotalAnswersSelectedFromCategoryTemplatesReachMaxAnswerCount,
    selectedCategory,
    handleBackToCategories,
    handleCloseModal,
    loading,
  };
};

export default useViewCategories;
