import moment from 'moment';
import { camelCase } from 'lodash';

import { extractESAnalyticsData, getGraphTimeInterval } from 'utils/analytics';
import { initialAnalyticsState } from 'store/initialState';

export const initialAnswerCountEachType = {
  defaultAnswerCount: 0,
  openAIAnswerCount: 0,
  answerCount: 0,
  fileCount: 0,
  websiteCount: 0,
};

export const createESAggregationsData = ESdata => {
  const { aggregations, hits } = ESdata;

  // sum of each answer type ['default_answer', 'answer', 'openai_answer']
  const answerCountEachType = (
    aggregations?.default_count?.buckets || []
  ).reduce(
    (result, item) => {
      const key = `${camelCase(item.key)}Count`;
      if (result.hasOwnProperty(key)) {
        result[key] += item.doc_count;
      }
      return result;
    },
    { ...initialAnswerCountEachType }
  );

  const totalHits = hits?.hits?.length || 0;
  const isNoUniqueQuesiton =
    !aggregations?.question_count?.buckets ||
    aggregations?.question_count?.value === 0 ||
    !aggregations?.question_count?.value;

  if (!aggregations) {
    return {
      ...initialAnalyticsState.aggregations,
      hits: totalHits,
      answerCountEachType,
    };
  } else {
    const allAnswersCount = aggregations?.answer_count?.buckets?.length || 0;
    const visitorCount = aggregations?.visitor_count?.value || 0;
    const sessionCount = aggregations?.session_count?.value || 0;
    const uniqueAnswers = aggregations?.unique_answers?.value || 0;
    const uniqueIntentCount = aggregations?.intents?.buckets?.length || 0;
    const totalQuestions = Number(hits.total?.value) || 0;
    const totalIntentCount = (aggregations?.intents?.buckets || []).reduce(
      (sum, item) => {
        return sum + item.doc_count;
      },
      0
    );

    const categories = (aggregations.categories?.buckets || []).map(
      category => ({
        label: category.key[1],
        value: category.key[1],
        key: category.key[0],
      })
    );
    const integrations = (aggregations.integrations?.buckets || []).map(
      integration => ({
        label: integration.key[1],
        value: integration.key[1],
        key: integration.key[0],
      })
    );

    const totalUniqueQuestions = isNoUniqueQuesiton
      ? 0
      : aggregations?.question_count?.buckets?.length;

    const totalCorrectValidation =
      (aggregations?.validation_count?.buckets || []).filter(
        value => value.key === 1
      )[0]?.doc_count || 0;

    const totalIncorrectValidation =
      (aggregations?.validation_count?.buckets || [])?.filter(
        value => value.key === 0
      )[0]?.doc_count || 0;

    const totalLikes =
      (aggregations?.feedback_count?.buckets || []).find(i => i.key === 1)
        ?.doc_count || 0;

    const totalDislikes =
      (aggregations?.feedback_count?.buckets || []).find(i => i.key === 0)
        ?.doc_count || 0;

    // (Questions Asked - Fallback) / Questions Asked
    const containmentRate =
      ((totalQuestions - answerCountEachType?.defaultAnswerCount || 0) /
        totalQuestions) *
      100;
    //  (Questions Asked - Thumbs Down) / Questions Asked
    const CSATScore = ((totalQuestions - totalDislikes) / totalQuestions) * 100;

    const positiveFeedback =
      (totalLikes * 100) / (totalLikes + totalDislikes) || 0;

    const negativeFeedback =
      (totalDislikes * 100) / (totalLikes + totalDislikes) || 0;

    const neutral =
      ((totalLikes + totalDislikes - (totalLikes + totalDislikes)) * 100) /
        (totalLikes + totalDislikes) || 0;

    const defaultAnswerPercentage = totalHits
      ? (answerCountEachType?.defaultAnswerCount * 100) / totalHits
      : 0;

    // FIXME: unverified calculation
    const uniqueAnswersPercentage = totalHits
      ? String((uniqueAnswers * 100) / totalHits) + '%'
      : '0%';

    const feedbackSummary =
      CSATScore > 50 ? 'positive' : CSATScore < 50 ? 'negative' : 'neutral';

    const mostAskedQuestionData = !aggregations?.question_count?.buckets?.length
      ? []
      : // used `arrayData` + `|| []` fallback
        // to make sure it returns an array whether arrayData exists or not
        (aggregations?.question_count?.buckets || []).map((item, key) => {
          return {
            count: item.doc_count,
            question: item.key,
            key,
          };
        });

    const intentsData = !aggregations?.intents?.buckets?.length
      ? []
      : (aggregations?.intents?.buckets || []).map((item, key) => {
          return {
            count: item.doc_count,
            intents: item.key,
            key,
          };
        });

    return {
      allAnswersCount,
      visitorCount,
      sessionCount,
      uniqueAnswers,
      totalQuestions,
      totalUniqueQuestions,
      hits: totalHits,
      totalCorrectValidation,
      totalIncorrectValidation,
      likes: totalLikes,
      dislikes: totalDislikes,
      containmentRate: containmentRate
        ? containmentRate.toFixed(1) + '%'
        : '0%',
      csatScore: CSATScore ? CSATScore.toFixed(1) + '%' : '0%',
      feedbackSummary,
      uniqueAnswersPercentage,
      positiveFeedback,
      negativeFeedback,
      neutral,
      defaultAnswerPercentage,
      answerCountEachType,
      uniqueIntentCount,
      totalIntentCount,
      categoriesFromES: categories,
      integrationsFromES: integrations,
      tableData: {
        mostAskedQuestionData,
        intentsData,
      },
    };
  }
};

export const getESChartData = (startDate, endDate, ESData) => {
  const { aggregations } = ESData;
  const { buckets } = aggregations?.question_count;
  const dateInterval = getGraphTimeInterval(startDate, endDate);
  const dateFormat =
    dateInterval === '1h' || dateInterval === '5h'
      ? `MMMM DD YYYY, h:mm a`
      : `MMMM DD YYYY`;

  const chartData = (buckets || []).map(data => {
    return {
      date: moment(data.key_as_string).format(dateFormat),
      count: data.doc_count,
    };
  });

  return chartData;
};

export const createESAnalyticsTableData = data => {
  const { hits } = data;

  const positiveFb = (hits?.hits || []).filter(i => i._source.feedback === 1);
  const negativeFb = (hits?.hits || []).filter(i => i._source.feedback === 0);
  const allQuestionData = extractESAnalyticsData(hits?.hits || []);
  const correctQuestions = (allQuestionData || []).filter(
    i => i.validation === 1
  );
  const incorrectQuestions = (allQuestionData || []).filter(
    i => i.validation === 0
  );

  const allPositiveFeedback = extractESAnalyticsData(positiveFb);

  const allNegativeFeedback = extractESAnalyticsData(negativeFb);

  return {
    allQuestions: allQuestionData,
    correctQuestions,
    incorrectQuestions,
    negativeFeedbackQuestions: allNegativeFeedback,
    positiveFeedbackQuestions: allPositiveFeedback,
  };
};

export const convertUndefinedToNoneWithTextValue = data => {
  if (Array.isArray(data)) {
    return data.map(i => {
      return {
        text: !i || i === 'undefined' ? 'None' : i,
        value: i,
      };
    });
  }
  return {
    text: !data || data === 'undefined' ? 'None' : data,
    value: data,
  };
};

export const updateAnalyticsTableQuestionValidation = (
  allQuestions,
  questionToUpdate
) => {
  const { source_id, validation } = questionToUpdate;

  return allQuestions.map(question => {
    if (question.questionID === source_id) {
      return {
        ...question,
        validation,
      };
    }
    return question;
  });
};

export const getIfUpdatedQuestionPreviousValidation = (
  allQuestions,
  questionToUpdate
) => {
  const { source_id } = questionToUpdate;

  return allQuestions.some(question => {
    return (
      question.questionID === source_id &&
      question.validation !== -1 &&
      typeof question.validation !== 'undefined'
    );
  });
};

export const updateAnalyticsAggregationsValidation = (
  aggregations,
  validation,
  hasPreviousValidation
) => {
  const totalCorrectValidation =
    hasPreviousValidation && validation === 0
      ? aggregations.totalCorrectValidation - 1
      : validation === 1
      ? aggregations.totalCorrectValidation + 1
      : aggregations.totalCorrectValidation;
  const totalIncorrectValidation =
    hasPreviousValidation && validation === 1
      ? aggregations.totalIncorrectValidation - 1
      : validation === 0
      ? aggregations.totalIncorrectValidation + 1
      : aggregations.totalIncorrectValidation;
  return {
    ...aggregations,
    totalCorrectValidation,
    totalIncorrectValidation,
  };
};
