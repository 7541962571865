import React, { useEffect, useMemo, useState } from 'react';
import {
  Breadcrumb,
  Layout,
  Menu,
  Pagination,
  Spin,
  Table,
  Tag,
  Upload,
} from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Content } from 'antd/lib/layout/layout';
import TextArea from 'components/TextArea';
import useJiraForm from './hooks';
import {
  EditOutlined,
  PaperClipOutlined,
  ProfileOutlined,
} from '@ant-design/icons';

import { formatBytes } from 'utils';
import { getLocalTimeString } from 'utils/dates';
import {
  StyledJiraForm,
  StyledSplittedRows,
  StyledPopoverLayout,
  StyledResponsiveRow,
  StyledJiraColumn,
} from './JiraForm.style';
import Button from 'components/Button';
import Input from 'components/Input';
import { MINIMUM_RECOMMENDED_WIDTH } from 'constants/screens';
import { cssVariables } from 'styles/root';

const JiraForm = () => {
  const {
    activePage,
    activePagination,
    isLoading,
    isSubmitBtnLoading,
    reportDescription,
    reportTitle,
    tickets,
    ticketInfo,
    totalTicketsCount,
    onPageChange,
    handleTicketDetails,
    handleActivePage,
    handleChangeFile,
    handlePreviewFile,
    createJiraTicket,
    handleChangeReportDescription,
    handleChangeReportTitle,
    setActivePage,
    handleRemoveFile,
    handleDownloadAttachment,
  } = useJiraForm();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'key',
      key: 'key',
      render: (_, record) => {
        return (
          <a
            onClick={() =>
              handleTicketDetails(
                record.key,
                record.fields.summary,
                record.fields.status.name,
                record.fields.created,
                record.fields.description
              )
            }
          >
            {_}
          </a>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'summary',
      key: 'summary',
      render: (_, record) => record.fields.summary,
    },
    {
      title: 'Reporter',
      dataIndex: 'reporter',
      key: 'reporter',
      render: (_, record) =>
        record.fields.labels
          .find(str => str.includes('Reporter:'))
          ?.replace('Reporter:', '')
          .replaceAll('_', ' '),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => record.fields.status.name,
    },
    {
      title: 'Date Created',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        return getLocalTimeString(new Date(record.fields.created));
      },
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const showIconOnly = useMemo(() => {
    return windowWidth < MINIMUM_RECOMMENDED_WIDTH;
  }, [windowWidth]);

  const detectSize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowWidth]);

  return (
    <Spin spinning={isLoading}>
      <StyledPopoverLayout hasSider>
        <Sider collapsed={showIconOnly} theme="light">
          <Menu defaultSelectedKeys={['1']}>
            <Menu.Item
              key="1"
              onClick={() => handleActivePage('createRequest')}
              icon={<EditOutlined />}
            >
              {'Create Request'}
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => handleActivePage('allRequest')}
              icon={<ProfileOutlined />}
            >
              {'All Requests'}
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ background: 'none', margin: '0 5px' }}>
          {activePage === 'createRequest' ? (
            <Content style={{ margin: '10px 16px 0', overflow: 'initial' }}>
              <StyledJiraForm
                onSubmit={createJiraTicket}
                style={{ margin: '10px, 0' }}
              >
                <Input
                  required
                  placeholder="Describe the issue"
                  onChange={handleChangeReportTitle}
                  value={reportTitle}
                />
                <TextArea
                  value={reportDescription}
                  required
                  rows={6}
                  placeholder="Tell us what’s going on in as much detail as possible. Attach a screenshot of the error you came across to help resolve it faster."
                  onChange={handleChangeReportDescription}
                />
                <StyledResponsiveRow>
                  <Upload
                    headers={{ 'Content-Type': 'multipart/form-data' }}
                    onPreview={handlePreviewFile}
                    beforeUpload={handleChangeFile}
                    onRemove={handleRemoveFile}
                    multiple={false}
                  >
                    <PaperClipOutlined style={{ fontSize: 15 }} />
                    &nbsp;{'Upload File'}
                  </Upload>
                  <Button
                    disabled={!reportTitle || !reportDescription}
                    type="submit"
                    value={`Submit`}
                    onClick={createJiraTicket}
                    loading={isSubmitBtnLoading}
                  />
                </StyledResponsiveRow>
              </StyledJiraForm>
            </Content>
          ) : activePage === 'allRequest' ? (
            <Content style={{ margin: '10px 16px 0', overflow: 'initial' }}>
              <Table
                columns={columns}
                dataSource={tickets}
                pagination={false}
                size={'small'}
              />
              <Pagination
                defaultCurrent={activePagination || 1}
                total={totalTicketsCount}
                pageSize="5"
                onChange={onPageChange}
              />
            </Content>
          ) : (
            <Content>
              <Breadcrumb
                style={{
                  marginLeft: '10px',
                }}
              >
                <Breadcrumb.Item>
                  {' '}
                  <a onClick={() => setActivePage('allRequest')}>All Request</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{ticketInfo.id}</Breadcrumb.Item>
              </Breadcrumb>
              <StyledSplittedRows>
                <b>{'Status'}</b>
                <Tag
                  color={
                    ticketInfo.status === 'Open'
                      ? cssVariables.primaryBlue
                      : cssVariables.success
                  }
                >
                  {ticketInfo.status}
                </Tag>
              </StyledSplittedRows>
              <StyledSplittedRows
                span={10}
                style={{
                  fontSize: '17px',
                  marginLeft: '10px',
                  fontWeight: cssVariables.font.bold,
                }}
              >
                {`${ticketInfo.ticketId} - ${ticketInfo.title}`}
              </StyledSplittedRows>
              <StyledJiraColumn>
                <b>{'Description'}</b>
                <span>{ticketInfo.description}</span>
              </StyledJiraColumn>
              {ticketInfo?.attachments && ticketInfo.attachments.length ? (
                <StyledJiraColumn>
                  <b>{'Attachments'}</b>
                  {ticketInfo.attachments.map((file, key) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a onClick={() => handleDownloadAttachment(file)} key={key}>
                      {`${file.filename} (${formatBytes(file.size)})`}
                    </a>
                  ))}
                </StyledJiraColumn>
              ) : null}
              <StyledSplittedRows>
                <div span={4}>
                  <b>{'Date created: '}</b>
                  {ticketInfo.dateRaised
                    ? getLocalTimeString(new Date(ticketInfo.dateRaised))
                    : null}
                  <br />
                </div>
                <div span={4}>
                  <b>{'Last update: '}</b>
                  {ticketInfo.lastUpdate
                    ? getLocalTimeString(new Date(ticketInfo.lastUpdate))
                    : null}
                  <br />
                </div>
              </StyledSplittedRows>
            </Content>
          )}
        </Layout>
      </StyledPopoverLayout>
    </Spin>
  );
};

JiraForm.propTypes = {};

export default JiraForm;
