import { StyledUpload } from './UploadButton.style';
import { PlusOutlined } from '@ant-design/icons';

const UploadButton = () => {
  return (
    <div>
      <PlusOutlined />
      <StyledUpload>Upload</StyledUpload>
    </div>
  );
};

export default UploadButton;
