import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Tag, Typography } from 'antd';
import Input from 'components/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';
import useUserProfile from './hooks';
import {
  StyledPageTitle,
  StyledContent,
  StyledFormButtons,
  StyledVersionDetailsContainer,
} from './UserProfile.style';
import {
  StyledFlexColumn,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';
import ROUTES from 'constants/routes';

const { Title } = Typography;

const MyAccount = props => {
  const {
    user,
    form,
    success,
    error,
    loading,
    SUCCESS_MESSAGE,
    version,
    editUser,
    onFinishFailed,
    handleGoBack,
    history,
    planType,
  } = useUserProfile();

  const name = user.name;
  const email = user.email;

  return (
    <StyledFlexColumn>
      <StyledPageTitle style={{ marginBottom: 10 }}>
        <Row>
          <Col
            xs={{ span: 18 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 4 }}
          >
            <Title level={3}>User Settings</Title>
          </Col>
          <Col
            xs={{ span: 4 }}
            md={{ span: 7 }}
            lg={{ span: 6 }}
            xl={{ span: 8 }}
          >
            <Tag color="blue">
              <StyledSpaceBetweenFlexCenter
                style={{ height: 38, fontSize: 12 }}
              >
                {planType.toUpperCase()}
              </StyledSpaceBetweenFlexCenter>
            </Tag>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 7 }}
            xl={{ span: 6 }}
          />
          <Col
            xs={{ span: 24, push: 0 }}
            md={{ span: 5, push: 2 }}
            lg={{ span: 4, push: 2 }}
            xl={{ span: 4, push: 2 }}
          >
            <Button
              hidden={planType === 'advanced'}
              onClick={() => history.push(ROUTES.SUBSCRIPTION)}
              value="Upgrade Subscription"
              block={true}
            />
          </Col>
        </Row>
      </StyledPageTitle>
      <StyledContent>
        <div>
          <Form
            form={form}
            name="user"
            onFinish={editUser}
            onFinishFailed={onFinishFailed}
            initialValues={{ name, email }}
          >
            {error && <Alert type="error" message={error} />}
            {success && <Alert type="success" message={SUCCESS_MESSAGE} />}

            <Form.Item name="name">
              <Input required full label="Name" type="text" size="large" />
            </Form.Item>
            <Form.Item name="email">
              <Input disabled full label="Email" type="email" />
            </Form.Item>
            <Form.Item name="password">
              <Input full label="Password" type="password" size="large" />
            </Form.Item>

            <StyledFormButtons>
              <Button value="Submit" type="submit" loading={loading} />
              <Button
                value="Cancel"
                variant="secondary"
                type="button"
                onClick={handleGoBack}
              />
            </StyledFormButtons>
          </Form>
        </div>
      </StyledContent>
      <StyledVersionDetailsContainer>
        Version: {version}
      </StyledVersionDetailsContainer>
    </StyledFlexColumn>
  );
};

MyAccount.user = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }),
};

export default MyAccount;
