import styled from 'styled-components';
import { cssVariables } from 'styles/root';
import { MoreOutlined } from '@ant-design/icons';

export const StyledSimilarAnswerModalTitle = styled.h4`
  font-family: Roboto;
  font-weight: ${cssVariables.font.normal};
  font-size: 18px;
  color: #000;
  margin-top: 8px;
`;

export const StyledAnswersItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${cssVariables.gray4};
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
`;

export const StyledAnswers = styled.div`
  font-family: Helvetica;
  font-size: 15px;
  color: ${cssVariables.primaryGray};
  width: 75%;
`;

export const StyledAskedQuestionTimeAndAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledAskedQuestionTime = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  color: #26292d;
`;

export const StyledMoreOutlined = styled(MoreOutlined)`
  transform: rotate(-90deg);
  padding: 10px;
  margin-left: 10px;
  color: #98a5ac;

  &:hover {
    background: ${cssVariables.gray4};
    border-radius: 50%;
  }
`;

export const StyledSimilarAnswersLabel = styled.div`
  font-size: 15px;
  color: #33363c;
  margin-right: 6px;
  margin-top: 20px;
  font-weight: ${cssVariables.font.bold};
`;
