export const hexToRGBA = (hexColor, alpha) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  if (result) {
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    return alpha ? r + ',' + g + ',' + b + ',' + alpha : r + ',' + g + ',' + b;
  }
  return '';
};
