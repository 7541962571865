export const OPEN_AI = 'openai';

export const GENERATE_FAQ = 'generatefaq';

export const TEXT_GENERATE = 'textgenerate';

export const CONTEXT_HISTORY = 'contextHistory';

export const BOT_MODE = 'botMode';

export const BOT_SIMULATOR = 'botSimulator';
