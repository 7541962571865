import React from 'react';
import PropTypes from 'prop-types';
import { StyledAlert, Wrapper } from './Alert.style';
import { Space } from 'antd';
import AnchorButton from 'components/Button/AnchorButton';

const Alert = ({
  message,
  type,
  hasActionButton,
  actionButtonProps,
  noPadding,
  ...rest
}) => {
  return (
    <Wrapper noPadding={noPadding}>
      <StyledAlert
        style={
          hasActionButton && {
            flexDirection: 'row',
            alignItems: 'center',
          }
        }
        {...rest}
        showIcon
        type={type}
        action={
          hasActionButton && actionButtonProps?.value ? (
            <Space>
              <AnchorButton
                danger
                size="middle"
                onClick={actionButtonProps.onClick}
                title={actionButtonProps.value}
                adaptButtonColor={actionButtonProps.adaptButtonColor}
              />
            </Space>
          ) : null
        }
        message={
          Array.isArray(message) ? (
            message.map(i => i)
          ) : (
            <>
              {message.email && (
                <li>
                  <strong>Email: </strong>
                  {message.email}
                </li>
              )}
              {message.password && (
                <li>
                  <strong>Password: </strong>
                  {message.password}
                </li>
              )}
              {message.name && (
                <li>
                  <strong>Name: </strong>
                  {message.name}
                </li>
              )}
              {message.non_field_errors &&
                message.non_field_errors.map((error, key) => (
                  <li key={key + 1}>{error}</li>
                ))}
              {message.error && <li>{message.error}</li>}
              {message.detail && <li>{message.detail}</li>}
              {typeof message === 'string' && message}
            </>
          )
        }
      />
    </Wrapper>
  );
};

Alert.defaultProps = {
  message: { error: 'Oops! We encounter an error.' },
  type: 'info',
};

Alert.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  type: PropTypes.string,
  noPadding: PropTypes.bool,
  hasActionButton: PropTypes.bool,
  actionButtonProps: PropTypes.shape({
    value: PropTypes.string,
    onClick: PropTypes.func,
    adaptButtonColor: PropTypes.bool,
  }),
};

export default Alert;
