import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { message } from 'antd';

import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import ROUTES from 'constants/routes';
import { planDetailsSelector, planTypeSelector } from 'selectors/plan';
import { getTokenSelector } from 'selectors/user';
import { stripeAPIService } from 'services/stripeAPI.service';
import {
  SET_STRIPE_STATUS,
  SET_USER_SUBSCRIPTION,
  SHOW_CANCEL_SUBSCRIPTION_MODAL,
  SHOW_SWITCH_PLAN_SUBSCRIPTION_MODAL,
} from 'store/action';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import {
  isCancelledSubscriptionSelector,
  isProcessingSelector,
  isTrialSubscriptionSelector,
  trialDaysLeftSelector,
} from 'selectors/subscription';
import {
  AUTO_RENEW_SUCCESS_MESSAGE,
  CANCELED_CHECKOUT_URL_PARAM,
  DOWNGRADE_SUCCESS_MESSAGE,
  UPGRADE_SUCCESS_MESSAGE,
} from 'constants/plan';
import { useQuery } from 'pages/useQuery';
import { parseBoolean } from 'utils/dataTypes';

const usePricing = () => {
  const [state, dispatch] = useContext(Context);
  const { graph, sentinel } = state;
  const isCancelledSubscription = useSelector(isCancelledSubscriptionSelector);
  const isProcessing = useSelector(isProcessingSelector);
  const isTrialSubscription = useSelector(isTrialSubscriptionSelector);
  const planDetails = useSelector(planDetailsSelector);
  const planType = useSelector(planTypeSelector);
  const token = useSelector(getTokenSelector);
  const trialDaysLeft = useSelector(trialDaysLeftSelector);

  const { push } = useHistory();
  const { pathname } = useLocation();
  const query = useQuery();

  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isSuccessModalVisible, setSuccessMessageVisible] = useState(false);

  const SUCCESS_URL = `${window.location.origin}${ROUTES.SUBSCRIPTION}`;
  const CANCEL_URL = `${window.location.origin}${ROUTES.SUBSCRIPTION}${CANCELED_CHECKOUT_URL_PARAM}`;

  const getCurrentStripeStatus = async () => {
    try {
      const res = await stripeAPIService.getSubscriptionStatus(
        graph,
        sentinel,
        token
      );
      if (res.data?.report) {
        dispatch({
          type: SET_USER_SUBSCRIPTION,
          payload: res.data.report[0],
        });
      }
    } catch (error) {
      message.error(error?.message || GET_DATA_ERROR);
    }
  };

  const getStripeStatus = async () => {
    try {
      const res = await stripeAPIService.getStripeStatus(
        graph,
        sentinel,
        token
      );
      if (res.data?.report) {
        dispatch({
          type: SET_STRIPE_STATUS,
          payload: res.data.report[0],
        });
      }
    } catch (error) {
      message.error(GET_DATA_ERROR);
    }
  };

  const fetchSubscriptionStatus = async () => {
    try {
      const res = await stripeAPIService.getSubscriptionStatus(
        graph,
        sentinel,
        token
      );
      if (res.data?.report) {
        dispatch({
          type: SET_USER_SUBSCRIPTION,
          payload: res.data.report[0],
        });
      }
    } catch (error) {
      message.error(error?.message || GET_DATA_ERROR);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await fetchSubscriptionStatus();
    }
    if (planType !== 'free' || isTrialSubscription) {
      fetchData();
    }
  }, [planType, isTrialSubscription]);

  useEffect(() => {
    const fetchStripeData = async () => {
      await getCurrentStripeStatus();
      await getStripeStatus();
    };

    if (planType !== 'free' || isTrialSubscription) {
      fetchStripeData();
    }
  }, []);

  const changeStripeIsProcessingStatus = async isProcessing => {
    try {
      const res = await stripeAPIService.changeStripeIsProcessingStatus(
        isProcessing,
        graph,
        sentinel,
        token
      );
      dispatch({
        type: SET_STRIPE_STATUS,
        payload: res.data.report[0],
      });
    } catch (error) {
      message.error(error?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleCancelSubscription = async () => {
    dispatch({
      type: SHOW_CANCEL_SUBSCRIPTION_MODAL,
    });
  };

  const handleUpgradeSubscription = async newPlan => {
    dispatch({
      type: SHOW_SWITCH_PLAN_SUBSCRIPTION_MODAL,
      payload: newPlan,
    });
  };

  useEffect(() => {
    if (query && query.get('checkout-success')) {
      const isCheckoutSuccess = parseBoolean(query.get('checkout-success'));
      changeStripeIsProcessingStatus(isCheckoutSuccess);
    }
  }, [query]);

  const onCloseSubscriptionMessage = () => {
    setSuccessMessageVisible(false);
    push(ROUTES.HOME);
  };

  const handleSwitchPlan = async newPlan => {
    setIsSubscriptionLoading(true);
    try {
      if (planType === newPlan && !isCancelledSubscription) {
        await handleCancelSubscription();
      } else if (newPlan === planType && isTrialSubscription) {
        const res = await stripeAPIService.autoRenewSubscription(
          graph,
          sentinel,
          token
        );

        if (res.data?.report[0]) {
          await dispatch({
            type: SET_USER_SUBSCRIPTION,
            payload: res.data.report[0],
          });
          setIsSubscriptionLoading(false);
          return message.success(AUTO_RENEW_SUCCESS_MESSAGE);
        }
      } else if (planType !== 'advanced' && newPlan === 'advanced') {
        await handleUpgradeSubscription({
          planType: newPlan,
          action: 'upgrade',
        });
      } else if (planType === 'free' && !isCancelledSubscription) {
        await handleUpgradeSubscription({
          planType: newPlan,
          action: 'upgrade',
        });
      } else if (planType !== 'basic' && newPlan === 'basic') {
        await handleUpgradeSubscription({
          planType: newPlan,
          action: 'downgrade',
        });
      } else {
        const RETURN_PATH = `${window.location.origin}${pathname}${CANCELED_CHECKOUT_URL_PARAM}`;
        const res = await stripeAPIService.switchPlan(
          newPlan,
          SUCCESS_URL,
          RETURN_PATH || CANCEL_URL,
          graph,
          sentinel,
          token
        );

        const checkoutURl = res.data?.report[0]?.url;
        if (checkoutURl) {
          window.location.href = checkoutURl;
        } else if (res.data?.success) {
          await dispatch({
            type: SET_USER_SUBSCRIPTION,
            payload: res.data.report[0],
          });
          message.success(
            (!isCancelledSubscription ||
              (isTrialSubscription && !isCancelledSubscription)) &&
              planType === newPlan
              ? AUTO_RENEW_SUCCESS_MESSAGE
              : trialDaysLeft &&
                !isCancelledSubscription &&
                planType !== 'advanced'
              ? UPGRADE_SUCCESS_MESSAGE
              : DOWNGRADE_SUCCESS_MESSAGE
          );
        } else {
          throw new Error(GET_DATA_ERROR);
        }
      }
    } catch (error) {
      message.error(error?.message || DEFAULT_ERROR_MESSAGE);
    }
    setIsSubscriptionLoading(false);
  };

  return {
    handleSwitchPlan,
    isCancelledSubscription,
    isProcessing,
    isSubscriptionLoading,
    isSuccessModalVisible,
    isTrialSubscription,
    onCloseSubscriptionMessage,
    planDetails,
    planType,
    selectedPlan,
    setSuccessMessageVisible,
    trialDaysLeft,
  };
};

export default usePricing;
