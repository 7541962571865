import { createSelector } from 'reselect';
import { isEmpty, isEqual } from 'lodash';

import { botJIDSelector, currentBotSelector, strippedBotJIDSelector } from '..';
import { allAnswersSelector } from '../answers';
import {
  BOT_PANEL_KEY_PREFIX,
  DEFAULT_WIDGET_HEIGHT,
  DEFAULT_MAX_INTERACTION,
  DEFAULT_REPLY_BUBBLE_GRADIENT,
  DEFAULT_WIDGET_HEIGHT_WITHOUT_PX,
  DEFAULT_BG,
} from 'constants/botCustomizer';
import {
  publicAskedQuestionSelector,
  publicKeySelector,
  sentinelSelector,
} from 'selectors/publicKeys';
import { stripUUID } from 'utils';
import { generateEncryptedBot } from 'services/fileGenerator.service';
export const integrationDataSelector = createSelector(
  currentBotSelector,
  bot => bot.currentIntegrationSettings || {}
);

export const initialIntegrationSettingsSelector = createSelector(
  integrationDataSelector,
  currentSettings => currentSettings.initialSettings || {}
);

export const hasIntegrationDataSelector = createSelector(
  integrationDataSelector,
  currentIntegrationSettings => !isEmpty(currentIntegrationSettings.settings)
);

export const isIntegrationDataNotLoadedSelector = createSelector(
  integrationDataSelector,
  initialIntegrationSettingsSelector,
  (currentIntegrationSettings, initialSettings) => {
    return (
      isEqual(currentIntegrationSettings, initialSettings) ||
      isEmpty(initialSettings)
    );
  }
);

export const allIntegrationsSelector = createSelector(
  currentBotSelector,
  bot => bot.integrations
);

export const integrationsFilterSelector = createSelector(
  allIntegrationsSelector,
  allIntegrations => {
    const defaultIntegrations = [
      { key: '0', id: '0', identifier: 'Demo Bot', value: 'Demo bot' },
      { key: '1', id: '1', identifier: 'ZSB Platform', value: 'ZSb Platform' },
    ];
    const integrationWithValues = allIntegrations.map(integration => ({
      ...integration,
      value: integration.identifier,
    }));
    return [...integrationWithValues, ...defaultIntegrations];
  }
);

export const currentIntegrationIDSelector = createSelector(
  integrationDataSelector,
  currentIntegrationSettings =>
    currentIntegrationSettings?.id || currentIntegrationSettings?.jid || null
);

export const integrationPanelSettingsSelector = createSelector(
  integrationDataSelector,
  currentIntegrationSettings => currentIntegrationSettings?.panel
);

export const currentIntegrationActivePanelSelector = createSelector(
  integrationPanelSettingsSelector,
  integrationPanelSettings => integrationPanelSettings?.activePanel || 1
);

export const integrationPanelPermitSelector = createSelector(
  integrationPanelSettingsSelector,
  integrationPanelSettings => integrationPanelSettings?.permit || 1
);

export const shouldShowWidgetSelector = createSelector(
  hasIntegrationDataSelector,
  hasIntegrationSettings => Boolean(hasIntegrationSettings)
);

export const widgetVersionSelector = createSelector(
  integrationDataSelector,
  currentIntegrationSettings =>
    currentIntegrationSettings?.widgetVersion || 'v1'
);

export const isWidgetV2Selector = createSelector(
  widgetVersionSelector,
  widgetVersion => widgetVersion === 'v2'
);

export const isWidgetV1Selector = createSelector(
  widgetVersionSelector,
  widgetVersion => widgetVersion === 'v1'
);

export const currentIntegrationSettingsSettingPropSelector = createSelector(
  integrationDataSelector,
  currentIntegrationSettings => currentIntegrationSettings.settings || {}
);

export const widgetV2TypeSelector = createSelector(
  integrationDataSelector,
  currentIntegrationSettings =>
    currentIntegrationSettings.settings?.type || 'chat'
);

export const isSearchWidgetSelector = createSelector(
  isWidgetV2Selector,
  widgetV2TypeSelector,
  (isWidgetV2, widgetV2Type) => Boolean(isWidgetV2 && widgetV2Type === 'search')
);

export const shouldShowWidgetV2Selector = createSelector(
  allAnswersSelector,
  isWidgetV2Selector,
  (allAnswers, isWidgetV2) => Boolean(allAnswers.length && isWidgetV2)
);

export const shouldShowWidgetV1Selector = createSelector(
  allAnswersSelector,
  isWidgetV1Selector,
  (allAnswers, isWidgetV1) => Boolean(allAnswers.length && isWidgetV1)
);

export const widgetPlaceholderSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => settings.placeholder
);

export const widgetHeightSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => {
    return typeof settings.height === 'string' &&
      settings.height?.includes('px')
      ? !isNaN(settings.height.split('px')[0])
        ? settings.height.split('px')[0]
        : DEFAULT_WIDGET_HEIGHT
      : DEFAULT_WIDGET_HEIGHT_WITHOUT_PX;
  }
);

export const widgetHeightWithPixelSelector = createSelector(
  widgetHeightSelector,
  height => {
    const heightInNumber =
      typeof height === 'string' && height.includes('px')
        ? Number(height.split('px')[0])
        : !isNaN(height)
        ? height
        : DEFAULT_WIDGET_HEIGHT_WITHOUT_PX;

    return !isNaN(heightInNumber) && typeof height === 'string'
      ? height.includes('px')
        ? height
        : `${height}px`
      : !isNaN(height)
      ? `${height}px`
      : DEFAULT_WIDGET_HEIGHT;
  }
);

export const widgetThemeColorSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => settings.color
);

export const widgetIconColorSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => settings.iconColor
);

// either `chat` || `header`
export const avatarPositionSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => settings.avatarPosition
);

export const integrationChannelNameSelector = createSelector(
  integrationDataSelector,
  currentBotSelector,
  (currentIntegration, bot) => currentIntegration.identifier
);

export const integrationPathSelector = createSelector(
  integrationDataSelector,
  currentIntegration => currentIntegration.path || ''
);

export const botTitleSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  integrationChannelNameSelector,
  (settings, identifier) => {
    return settings?.botTitle || identifier;
  }
);

export const launcherPositionSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => {
    return settings?.position || 'bottom-right';
  }
);

export const isConfigPanelSettingsUnchangedSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  initialIntegrationSettingsSelector,
  (currentSettings, initialSettings) => {
    return (
      currentSettings.botTitle === initialSettings.settings?.botTitle &&
      currentSettings.subtitle === initialSettings.settings?.subtitle &&
      currentSettings.placeholder === initialSettings.settings?.placeholder &&
      currentSettings.welcomeMessage ===
        initialSettings.settings?.welcomeMessage
    );
  }
);

export const isAppearancePanelSettingsUnchangedSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  initialIntegrationSettingsSelector,
  (currentSettings, initialSettings) => {
    return (
      currentSettings.avatar === initialSettings.settings?.avatar &&
      currentSettings.avatarPosition ===
        initialSettings.settings?.avatarPosition &&
      currentSettings.height === initialSettings.settings?.height &&
      currentSettings.textColor === initialSettings.settings?.textColor &&
      currentSettings.headerAvatarType ===
        initialSettings.settings?.headerAvatarType &&
      currentSettings.replyBubbleGradient ===
        initialSettings.settings?.replyBubbleGradient &&
      currentSettings.bubbleColor === initialSettings.settings?.bubbleColor &&
      currentSettings.fontSize === initialSettings.settings?.fontSize &&
      currentSettings.autoOpen === initialSettings.settings?.autoOpen &&
      currentSettings.speechToText === initialSettings.settings?.speechToText &&
      currentSettings.textToSpeech === initialSettings.settings?.textToSpeech &&
      currentSettings.typingExperience ===
        initialSettings.settings?.typingExperience &&
      currentSettings.showCloseButton ===
        initialSettings.settings?.showCloseButton
    );
  }
);

export const isPlacementPanelSettingsUnchangedSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  initialIntegrationSettingsSelector,
  (currentSettings, initialSettings) => {
    return (
      currentSettings.background === initialSettings.settings?.background &&
      currentSettings.shape === initialSettings.settings?.shape &&
      currentSettings.src === initialSettings.settings?.src &&
      currentSettings.position === initialSettings.settings?.position &&
      currentSettings.label === initialSettings.settings?.label
    );
  }
);

export const interactionHistorySelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => {
    return settings.interactionHistory;
  }
);

export const maxInteractionSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => {
    return settings.maxInteraction || DEFAULT_MAX_INTERACTION;
  }
);

export const currentIntegrationTypeSelector = createSelector(
  integrationDataSelector,
  currentIntegration => {
    return currentIntegration?.type || null;
  }
);

const platformSelector = (_state, platform) => {
  return platform;
};

export const isDemoIntegrationSelector = createSelector(
  currentIntegrationTypeSelector,
  platformSelector,
  (type, platform) => {
    return type ? type.includes('demo') : false;
  }
);

export const replyBubbleGradientSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => {
    const gradient =
      settings?.replyBubbleGradient ||
      settings?.bubbleGradient ||
      DEFAULT_REPLY_BUBBLE_GRADIENT;
    return typeof gradient !== 'number' ? Number(gradient) : gradient;
  }
);

export const launcherShapeSelector = createSelector(
  currentIntegrationSettingsSettingPropSelector,
  settings => settings.shape || 'circle'
);

export const isMobileIntegrationSelector = createSelector(
  currentIntegrationTypeSelector,
  integrationType => {
    return integrationType?.toLowerCase()?.includes('mobile');
  }
);

export const postCustomizationConfirmEditModalSelector = createSelector(
  integrationPanelSettingsSelector,
  panelSettings => {
    return panelSettings?.postCustomizationConfirmEditModal || {};
  }
);

export const preCustomizationConfirmEditModalSelector = createSelector(
  integrationPanelSettingsSelector,
  panelSettings => {
    return panelSettings?.preCustomizationConfirmEditModal || {};
  }
);

export const postCustomizationSelectedPanelOpenSelector = createSelector(
  postCustomizationConfirmEditModalSelector,
  modal => {
    return modal.selectedPanel;
  }
);

export const isPostCustomizationEditModalOpenSelector = createSelector(
  postCustomizationConfirmEditModalSelector,
  postCustomizationConfirmEditModal => {
    return Boolean(postCustomizationConfirmEditModal.isOpen);
  }
);

export const isPostCustomizationEditModalEditableSelector = createSelector(
  postCustomizationConfirmEditModalSelector,
  postCustomizationConfirmEditModal => {
    return Boolean(postCustomizationConfirmEditModal?.editable);
  }
);

export const customizerActivePanelSelector = createSelector(
  integrationDataSelector,
  currentIntegration => {
    return (
      currentIntegration.customizerActivePanel || `${BOT_PANEL_KEY_PREFIX}1`
    );
  }
);

export const isPreCustomizationEditModalOpenSelector = createSelector(
  preCustomizationConfirmEditModalSelector,
  modal => {
    return Boolean(modal.isOpen);
  }
);

export const demoIntegrationBackgroundSelector = createSelector(
  isDemoIntegrationSelector,
  currentIntegrationSettingsSettingPropSelector,
  (isDemo, settings) => (isDemo ? settings.background || DEFAULT_BG : '')
);

export const isDemoCustomBgSelectedSelector = createSelector(
  demoIntegrationBackgroundSelector,
  background => background !== 'dark' && background !== 'light'
);

export const isDemoHasCustomBackgroundSelector = createSelector(
  isDemoIntegrationSelector,
  demoIntegrationBackgroundSelector,
  (isDemo, background) => {
    const bgList = ['light', 'dark', 'custom'];
    if (
      isDemo &&
      (background.length || background instanceof File) &&
      !bgList.includes(background)
    ) {
      return true;
    }
    return false;
  }
);

export const isDemoDarkBackgroundSelector = createSelector(
  demoIntegrationBackgroundSelector,
  isDemoIntegrationSelector,
  (background, isDemo) => Boolean(isDemo && background === 'dark')
);

export const integrationFolderPathSelector = createSelector(
  strippedBotJIDSelector,
  botJid => botJid
);

// returns the value from `file` type value
// else null
export const demoCustomBackgroundSelector = createSelector(
  demoIntegrationBackgroundSelector,
  isDemoHasCustomBackgroundSelector,
  integrationFolderPathSelector,
  (background, hasCustomDemoBg) => {
    // checks if background is still not uploaded to cloud
    if (hasCustomDemoBg && background instanceof File) {
      return background;
    }
    return null;
  }
);

const AZURE_DEMO_URI = process.env.REACT_APP_AZURE_DEMO_URI;
const AZURE_STORAGE_URI = process.env.REACT_APP_AZURE_STORAGE_URI;
const INTEGRATIONS_CONTAINER_NAME = 'integrations';

export const integrationConfigURLSelector = createSelector(
  currentBotSelector,
  isDemoIntegrationSelector,
  currentIntegrationIDSelector,
  (bot, isDemo, currentIntegrationId) => {
    const folderPath = stripUUID(bot.jid);
    const integrationID = stripUUID(currentIntegrationId);
    const fileName = 'config.json';

    return isDemo
      ? `${AZURE_DEMO_URI}${folderPath}/${fileName}`
      : !isDemo && integrationID
      ? `${AZURE_STORAGE_URI}${INTEGRATIONS_CONTAINER_NAME}/${folderPath}/${integrationID}/${fileName}`
      : null;
  }
);

export const encryptedBotSelector = createSelector(
  sentinelSelector,
  publicAskedQuestionSelector,
  publicKeySelector,
  botJIDSelector,
  integrationConfigURLSelector,
  isDemoIntegrationSelector,
  (sentinel, pubAskedQuestion, publicKey, botJid, integrationConfigURL) => {
    return generateEncryptedBot(
      botJid,
      sentinel,
      integrationConfigURL,
      publicKey,
      pubAskedQuestion
    );
  }
);

const ZSB_JS_FILE = process.env.REACT_APP_AZURE_BOTCLIENT_FILE;
const ZSB_JS_FILE_V2 = process.env.REACT_APP_AZURE_BOTCLIENT_FILE_V2;
const ZSB_JS_URI = process.env.REACT_APP_AZURE_BOTCLIENT_URI;
const ZSB_JS_TOKEN = process.env.REACT_APP_AZURE_BOTCLIENT_SAS_TOKEN;
export const BOT_SCRIPT = `<script src="${ZSB_JS_URI}/${ZSB_JS_FILE}?${ZSB_JS_TOKEN}"></script>`;
export const BOT_SCRIPT_V2 = `<script src="${ZSB_JS_URI}/${ZSB_JS_FILE_V2}?${ZSB_JS_TOKEN}"></script>`;
