// SIGNIN / SIGNUP
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';

// EXTERNAL PAGE
export const LOGOUT_EXTERNAL_USER = 'LOGOUT_EXTERNAL_USER';
export const EXTERNAL_SIGN_IN_SUCCESS = 'EXTERNAL_SIGN_IN_SUCCESS';
export const SET_PAGE_EXTERNAL = 'SET_PAGE_EXTERNAL';
export const UNSET_PAGE_EXTERNAL = 'UNSET_PAGE_EXTERNAL';
export const WORD_PRESS_SUCCESS = 'WORD_PRESS_SUCCESS';

// INIT
export const INIT_JAC = 'INIT_JAC';
export const SET_ONBOARDING_FLAG = 'SET_ONBOARDING_FLAG';
export const DELETE_ONBOARDING_FLAG = 'DELETE_ONBOARDING_FLAG';
export const RESET_ONBOARDING_FLAG = 'RESET_ONBOARDING_FLAG';
export const SET_PUBLIC_KEY = 'SET_PUBLIC_KEY';
export const SPAWN_CREATE = 'SPAWN_CREATE';
export const LOAD_APPLICATION = 'LOAD_APPLICATION';

// USER
export const GET_USERNAME = 'GET_USERNAME';
export const UPDATE_USER = 'UPDATE_USER';

// PLAN
export const INIT = 'INIT';
export const SET_PLAN = 'SET_PLAN';
export const RESET_PLAN = 'RESET_PLAN';
export const SHOW_FIRST_LOGIN_PLAN = 'SHOW_FIRST_LOGIN_PLAN';
export const DISMISS_FIRST_LOGIN_PLAN = 'DISMISS_FIRST_LOGIN_PLAN';
export const SET_PLAN_DETAILS = 'SET_PLAN_DETAILS';

// SUBSCRIPTION
export const SET_USER_SUBSCRIPTION = 'SET_USER_SUBSCRIPTION';
export const SET_STRIPE_STATUS = 'SET_STRIPE_STATUS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CLOSE_SUCCESS_CANCEL_SUBSCRIPTION_MODAL =
  'CLOSE_SUCCESS_CANCEL_SUBSCRIPTION_MODAL';
export const SHOW_CANCEL_SUBSCRIPTION_MODAL = 'SHOW_CANCEL_SUBSCRIPTION_MODAL';
export const CLOSE_CANCEL_SUBSCRIPTION_MODAL =
  'CLOSE_CANCEL_SUBSCRIPTION_MODAL';
export const SHOW_SWITCH_PLAN_SUBSCRIPTION_MODAL =
  'SHOW_SWITCH_PLAN_SUBSCRIPTION_MODAL';
export const CLOSE_SWITCH_PLAN_SUBSCRIPTION_MODAL =
  'CLOSE_SWITCH_PLAN_SUBSCRIPTION_MODAL';

// DASHBOARD BOT / BOTS
export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';
export const SET_BOTS = 'SET_BOTS';
export const SET_TEMP_BOT = 'SET_TEMP_BOT';
export const ADD_BOT = 'ADD_BOT';
export const DELETE_BOT = 'DELETE_BOT';
export const UPDATE_BOT = 'UPDATE_BOT';
export const VIEW_BOT = 'VIEW_BOT';
export const IMPORT_BOT = 'IMPORT_BOT';
export const SET_BOT_MODES = 'SET_BOT_MODES';
export const UPDATE_BOT_SYNC = 'UPDATE_BOT_SYNC';

// ANSWERS
export const BULK_DELETE_ANSWERS = 'BULK_DELETE_ANSWERS';
export const SET_ANSWERS = 'SET_ANSWERS';
export const ADD_ANSWER_QLINK = 'ADD_ANSWER_QLINK';
export const ADD_ANSWER = 'ADD_ANSWER';
export const ADD_FILE = 'ADD_FILE';
export const ADD_MULTIPLE_ANSWERS = 'ADD_MULTIPLE_ANSWERS';
export const SET_DEFAULT_ANSWER = 'SET_DEFAULT_ANSWER';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_FILE = 'UPDATE_FILE';
export const UPDATE_WEBSITE = 'UPDATE_WEBSITE';
export const DELETE_ANSWERBANK = 'DELETE_ANSWERBANK';
export const REMOVE_ANSWER_QLINK = 'REMOVE_ANSWER_QLINK';
export const UPDATE_ANSWER_HITCOUNT = 'UPDATE_ANSWER_HITCOUNT';
export const SET_SHOW_ANSWER_VERSIONS = 'SET_SHOW_ANSWER_VERSIONS';
export const SET_SHOW_DRAFT_RESPONSES = 'SET_SHOW_DRAFT_RESPONSES';
export const SET_SHOW_BELOW_THRESHOLD_RESPONSES =
  'SET_SHOW_BELOW_THRESHOLD_RESPONSES';
export const SET_WEBSITE_SELECTED_PAGES = 'SET_WEBSITE_SELECTED_PAGES';
export const RESET_WEBSITE_SELECTED_PAGES = 'RESET_WEBSITE_SELECTED_PAGES';

// ANSWER CATEGORIES
export const BULK_MOVE_CATEGORY = 'BULK_MOVE_CATEGORY';
export const SET_CATEGORY_TEMPLATES = 'SET_CATEGORY_TEMPLATES';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const SET_CATEGORY_MODAL_OPEN = 'SET_CATEGORY_MODAL_OPEN';
export const SET_SELECTED_CATEGORY_DETAILS = 'SET_SELECTED_CATEGORY_DETAILS';
export const CLEAR_SELECTED_CATEGORY_DETAILS =
  'CLEAR_SELECTED_CATEGORY_DETAILS';
export const SET_CATEGORY_MODAL_CLOSE = 'SET_CATEGORY_MODAL_CLOSE';
export const IMPORT_CATEGORY_TEMPLATES = 'IMPORT_CATEGORY_TEMPLATES';

// TEST SUITE
export const SET_TEST_SUITES = 'SET_TEST_SUITES';
export const SET_TEST_CASES = 'SET_TEST_CASES';
export const SET_SELECTED_TEST_SUITE = 'SET_SELECTED_TEST_SUITE';
export const UPDATE_TEST_SUITE = 'UPDATE_TEST_SUITE';
export const RUN_TEST_SUITE = 'RUN_TEST_SUITE';
export const UPDATE_TEST_CASE_STATUS = 'UPDATE_TEST_CASE_STATUS';
export const DELETE_TEST_CASE = 'DELETE_TEST_CASE';
export const DELETE_TEST_SUITE = 'DELETE_TEST_SUITE';
export const ADD_TEST_SUITE = 'ADD_TEST_SUITE';
export const ADD_TEST_CASE = 'ADD_TEST_CASE';
export const UPDATE_TEST_CASE = 'UPDATE_TEST_CASE';
export const UPDATE_TEST_CASE_COUNT = 'UPDATE_TEST_CASE_COUNT';

// INTEGRATION
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export const ADD_INTEGRATION = 'ADD_INTEGRATION';
export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const SET_INTEGRATION_PANEL_PERMIT = 'SET_INTEGRATION_PANEL_PERMIT';
export const AUTO_OPEN_INTEGRATION_PANEL = 'AUTO_OPEN_INTEGRATION_PANEL';
export const SET_INTEGRATION_ACTIVE_PANEL = 'SET_INTEGRATION_ACTIVE_PANEL';
export const RESET_INTEGRATION_PANEL_AND_PERMIT =
  'RESET_INTEGRATION_PANEL_AND_PERMIT';
export const SET_MESSENGER_API = 'SET_MESSENGER_API';
export const SET_VIBER_API = 'SET_VIBER_API';
export const SET_COMPONENT_INTEGRATION_DATA = 'SET_COMPONENT_INTEGRATION_DATA';
export const SET_CURRENT_INTEGRATION_ID = 'SET_CURRENT_INTEGRATION_ID';
export const SET_CURRENT_INTEGRATION_DATA = 'SET_CURRENT_INTEGRATION_DATA';
export const SAVE_NEW_INTEGRATION = 'SAVE_NEW_INTEGRATION';
export const RESET_SEARCH_CUSTOMIZER_DATA = 'RESET_SEARCH_CUSTOMIZER_DATA';
export const CLEAR_COMPONENT_INTEGRATION_DATA =
  'CLEAR_COMPONENT_INTEGRATION_DATA';
export const CLEAR_CURRENT_INTEGRATION_ID = 'CLEAR_CURRENT_INTEGRATION_ID';
export const RESET_INTEGRATION_SETTINGS = 'RESET_INTEGRATION_SETTINGS';
export const UPDATE_VIBER_WEBHOOK_STATUS = 'UPDATE_VIBER_WEBHOOK_STATUS';
export const GENERATE_INTEGRATION_CODE = 'GENERATE_INTEGRATION_CODE';
export const SET_INTEGRATION_BOT_VERSION = 'SET_INTEGRATION_BOT_VERSION';
export const SET_INTEGRATION_WIDGET_TYPE = 'SET_INTEGRATION_WIDGET_TYPE';
export const SET_WIDGET_PLACEHOLDER = 'SET_WIDGET_PLACEHOLDER';
export const SET_WIDGET_THEME_COLOR = 'SET_WIDGET_THEME_COLOR';
export const SET_WIDGET_FONT_COLOR = 'SET_WIDGET_FONT_COLOR';
export const SET_WIDGET_HEIGHT = 'SET_WIDGET_HEIGHT';
export const SET_HEADER_AVATAR = 'SET_HEADER_AVATAR';
export const SET_HEADER_AVATAR_TYPE = 'SET_HEADER_AVATAR_TYPE';
export const SET_RESPONSE_AVATAR = 'SET_RESPONSE_AVATAR';
export const SET_RESPONSE_AVATAR_TYPE = 'SET_RESPONSE_AVATAR_TYPE';
export const SET_WIDGET_LAUNCHER_ICON = 'SET_WIDGET_LAUNCHER_ICON';
export const SET_WIDGET_LAUNCHER_AVATAR_TYPE =
  'SET_WIDGET_LAUNCHER_AVATAR_TYPE';
export const SET_WIDGET_AVATAR_COLOR = 'SET_WIDGET_AVATAR_COLOR';
export const SET_WIDGET_TITLE = 'SET_WIDGET_TITLE';
export const SET_WIDGET_SUBTITLE = 'SET_WIDGET_SUBTITLE';
export const SET_WIDGET_WELCOME_MESSAGE = 'SET_WIDGET_WELCOME_MESSAGE';
export const TOGGLE_WIDGET_USER_AUTHETICATED_SWITCH =
  'TOGGLE_WIDGET_USER_AUTHETICATED_SWITCH';
export const SET_SHOW_WIDGET_AVATAR_PICKER_MODAL =
  'SET_SHOW_WIDGET_AVATAR_PICKER_MODAL';
export const CLOSE_WIDGET_AVATAR_PICKER_MODAL =
  'CLOSE_WIDGET_AVATAR_PICKER_MODAL';
export const RESET_CONFIG_PANEL_SETTINGS = 'RESET_CONFIG_PANEL_SETTINGS';
export const RESET_APPEARANCE_PANEL_SETTINGS =
  'RESET_APPEARANCE_PANEL_SETTINGS';
export const RESET_PLACEMENT_PANEL_SETTINGS = 'RESET_PLACEMENT_PANEL_SETTINGS';
export const TOGGLE_INTERACTION_HISTORY = 'TOGGLE_INTERACTION_HISTORY';
export const TOGGLE_AUTO_OPEN_WIDGET_SETTING =
  'TOGGLE_AUTO_OPEN_WIDGET_SETTING';
export const TOGGLE_WIDGET_SPEECH_TO_TEXT = 'TOGGLE_WIDGET_SPEECH_TO_TEXT';
export const TOGGLE_WIDGET_TEXT_TO_SPEECH = 'TOGGLE_WIDGET_TEXT_TO_SPEECH';
export const TOGGLE_WIDGET_TYPING_EXPERIENCE =
  'TOGGLE_WIDGET_TYPING_EXPERIENCE';
export const SET_MAX_INTERACTION_HISTORY = 'SET_MAX_INTERACTION_HISTORY';
export const SET_WIDGET_CHAT_BUBBLE_COLOR = 'SET_WIDGET_CHAT_BUBBLE_COLOR';
export const SET_REPLY_BUBBLE_GRADIENT = 'SET_REPLY_BUBBLE_GRADIENT';
export const SET_WIDGET_FONT_SIZE = 'SET_WIDGET_FONT_SIZE';
export const SET_CURRENT_INTEGRATION_TYPE = 'SET_CURRENT_INTEGRATION_TYPE';
export const TOGGLE_SHOW_CLOSE_BUTTON = 'TOGGLE_SHOW_CLOSE_BUTTON';
export const OPEN_EDIT_PANEL_INTEGRATION = 'OPEN_EDIT_PANEL_INTEGRATION';
export const SHOW_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL =
  'SET_SHOW_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL';
export const CLOSE_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL =
  'CLOSE_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL';
export const SHOW_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL =
  'SHOW_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL';
export const CLOSE_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL =
  'CLOSE_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL';
export const SET_DEMO_BACKGROUND = 'SET_DEMO_BACKGROUND';
export const OPEN_IMAGE_VIEWER_MODAL = 'OPEN_IMAGE_VIEWER_MODAL';
export const CLOSE_IMAGE_VIEWER_MODAL = 'CLOSE_IMAGE_VIEWER_MODAL';
export const SET_INTEGRATION_IDENTIFIER = 'SET_INTEGRATION_IDENTIFIER';
export const SET_INTEGRATION_PATH = 'SET_INTEGRATION_PATH';
export const SET_LAUNCHER_SHAPE = 'SET_LAUNCHER_SHAPE';
export const SET_WIDGET_LAUNCHER_POSITION = 'SET_WIDGET_LAUNCHER_POSITION';
export const SET_LAUNCHER_LABEL = 'SET_LAUNCHER_LABEL';
export const SET_BOT_CUSTOMIZER_ACTIVE_PANEL =
  'SET_BOT_CUSTOMIZER_ACTIVE_PANEL';
export const SET_INTEGRATION_HANDOFF_LABEL = 'SET_INTEGRATION_HANDOFF_LABEL';
export const SET_INTEGRATION_HANDOFF_SUCCESS_RESPONSE =
  'SET_INTEGRATION_HANDOFF_SUCCESS_RESPONSE';
export const SET_INTEGRATION_HANDOFF_CANCEL_RESPONSE =
  'SET_INTEGRATION_HANDOFF_CANCEL_RESPONSE';
export const ADD_INTEGRATION_HANDOFF_USER_INFO_FIELD =
  'ADD_INTEGRATION_HANDOFF_USER_INFO_FIELD';
export const REMOVE_INTEGRATION_HANDOFF_USER_INFO_FIELD =
  'REMOVE_INTEGRATION_HANDOFF_USER_INFO_FIELD';
export const CHANGE_INTEGRATION_HANDOFF_USER_INFO_FIELD =
  'CHANGE_INTEGRATION_HANDOFF_USER_INFO_FIELD';
export const TOGGLE_INTEGRATION_HANDOFF_CALLBACK =
  'TOGGLE_INTEGRATION_HANDOFF_CALLBACK';
export const TOGGLE_INTEGRATION_HANDOFF_DISPLAY_FORM =
  'TOGGLE_INTEGRATION_HANDOFF_DISPLAY_FORM';
export const SET_LAUNCHER_AVATAR_TO_CLOUD_PATH =
  'SET_LAUNCHER_AVATAR_TO_CLOUD_PATH';
export const SET_HEADER_AVATAR_TO_CLOUD_PATH =
  'SET_HEADER_AVATAR_TO_CLOUD_PATH';
export const SET_RESPONSE_AVATAR_TO_CLOUD_PATH =
  'SET_RESPONSE_AVATAR_TO_CLOUD_PATH';
export const SET_DEMO_BACKGROUND_TO_CLOUD_PATH =
  'SET_DEMO_BACKGROUND_TO_CLOUD_PATH';

// ADMIN
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const START_IMPERSONATING_USER = 'START_IMPERSONATING_USER';
export const STOP_IMPERSONATING_USER = 'STOP_IMPERSONATING_USER';
export const GET_ALL_GLOBAL_VARS = 'GET_ALL_GLOBAL_VARS';
export const GET_ALL_VERSIONS = 'GET_ALL_VERSIONS';
export const CLEANUP_ALL_GLOBAL_VARS = 'CLEANUP_ALL_GLOBAL_VARS';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const SET_NETWORK_DIGRAPH_DATA = 'SET_NETWORK_DIGRAPH_DATA';
export const CLEAR_DIGRAPH_DATA = 'CLEAR_NETWORK_DIGRAPH_DATA';
export const SHOW_DIGRAPH = 'SHOW_DIGRAPH';
export const HIDE_DIGRAPH = 'HIDE_DIGRAPH';

// SIDEBAR CHATLIST
export const PUSH_CHAT_QUESTION = 'PUSH_CHAT_LIST_QUESTION';
export const PUSH_CHAT_ANSWER = 'PUSH_CHAT_ANSWER';
export const PUSH_CHAT_ERROR_REPLY = 'PUSH_CHAT_ERROR_REPLY';
// Update answer with specific chat message index
export const UPDATE_CHAT_LIST_ITEM = 'UPDATE_CHAT_LIST_ITEM';
export const ADD_TO_CHAT_LIST = 'ADD_TO_CHAT_LIST';
export const CLEAR_CHAT_LIST = 'CLEAR_CHAT_LIST';
export const SET_CHAT_MODE = 'SET_CHAT_MODE';
export const TOGGLE_EXPAND_SIDEBAR_CHAT = 'TOGGLE_EXPAND_SIDEBAR_CHAT';
export const SHOW_CHAT_ANSWER_IN_MODAL = 'SHOW_CHAT_ANSWER_IN_MODAL';
export const CLOSE_CHAT_ANSWER_IN_MODAL = 'CLOSE_CHAT_ANSWER_IN_MODAL';
export const SHOW_CHAT_SELECTED_SEQUENCE_IN_MODAL =
  'SHOW_CHAT_SELECTED_SEQUENCE_IN_MODAL';
export const CLOSE_CHAT_SELECTED_SEQUENCE_IN_MODAL =
  'CLOSE_CHAT_SELECTED_SEQUENCE_IN_MODAL';

// BOT
export const CLEAR_BOT_AGGREGATIONS = 'CLEAR_BOT_AGGREGATIONS';
export const REMOVE_BOT_JID = 'REMOVE_BOT_JID';
export const SET_BOT_AGGREGATIONS = 'SET_BOT_AGGREGATIONS';
export const SET_BOT_JID = 'SET_BOT_JID';
export const SET_BOT_DETAILS = 'SET_BOT_DETAILS';
export const CLEAR_BOT_DETAILS = 'CLEAR_BOT_DETAILS';
export const SET_BOT_ERROR = 'SET_BOT_ERROR';
export const SET_BOT_OVERVIEW = 'SET_BOT_OVERVIEW';
export const SET_BOT_SETTINGS = 'SET_BOT_SETTINGS';
export const SET_TYPING_EXPERIENCE = 'SET_TYPING_EXPERIENCE';
export const SET_BOTDETAILS_PAGE_READY = 'SET_BOTDETAILS_PAGE_READY';
export const SET_BOTDETAILS_PAGE_LOADING = 'SET_BOTDETAILS_PAGE_LOADING';
export const SET_BOT_AS_WEAK_BOT = 'SET_BOT_AS_WEAK_BOT';
export const REMOVE_WEAK_BOT_STATUS = 'REMOVE_WEAK_BOT_STATUS';
export const SHOW_BOT_MODAL = 'SHOW_BOT_MODAL';
export const CLOSE_BOT_MODAL = 'CLOSE_BOT_MODAL';
export const CLOSE_WORDPRESS_MODAL = 'CLOSE_WORDPRESS_MODAL';

// MODALS
export const SHOW_RESPONSE_PICKER_MODAL = 'SHOW_RESPONSE_PICKER_MODAL';
export const CLOSE_RESPONSE_PICKER_MODAL = 'CLOSE_RESPONSE_PICKER_MODAL';
export const UPDATE_RESPONSE_PICKER_ANSWERS = 'UPDATE_RESPONSE_PICKER_ANSWERS';
export const SHOW_SIMILAR_QUESTIONS_MODAL = 'SHOW_SIMILAR_QUESTIONS_MODAL';
export const CLOSE_SIMILAR_QUESTIONS_MODAL = 'CLOSE_SIMILAR_QUESTIONS_MODAL';
export const UPDATE_SIMILAR_QUESTIONS_MODAL = 'UPDATE_SIMILAR_QUESTIONS_MODAL';
export const SET_ANSWER_EDITOR_SELECTED_ANSWER =
  'SET_ANSWER_EDITOR_SELECTED_ANSWER';
export const SET_FILE_EDITOR_SELECTED_FILE = 'SET_FILE_EDITOR_SELECTED_FILE';
export const SET_WEBSITE_EDITOR_SELECTED_WEBSITE =
  'SET_WEBSITE_EDITOR_SELECTED_WEBSITE';
export const CLOSE_ANSWER_EDITOR = 'CLOSE_ANSWER_EDITOR';
export const CLOSE_FILE_EDITOR = 'CLOSE_FILE_EDITOR';
export const CLOSE_WEBSITE_EDITOR = 'CLOSE_WEBSITE_EDITOR';
export const OPEN_ANSWER_EDITOR_CREATE_MODE = 'OPEN_ANSWER_EDITOR_CREATE_MODE';
export const UPDATE_ANSWER_EDITOR_ANSWER = 'UPDATE_ANSWER_EDITOR_ANSWER';
export const UPDATE_FILE_EDITOR_FILE = 'UPDATE_FILE_EDITOR_FILE';
export const UPDATE_WEBSITE_EDITOR_WEBSITE = 'UPDATE_WEBSITE_EDITOR_WEBSITE';
export const SHOW_QUESTION_EDITOR = 'SHOW_QUESTION_EDITOR';
export const UPDATE_QUESTION_EDITOR = 'UPDATE_QUESTION_EDITOR';
export const UPDATE_QUESTION_EDITOR_SIMILAR_QUESTIONS =
  'UPDATE_QUESTION_EDITOR_SIMILAR_QUESTIONS';
export const CLOSE_QUESTION_EDITOR = 'CLOSE_QUESTION_EDITOR';
export const PUSH_ANSWER_AS_RESPONSE_PICKER_BEST_ANSWER =
  'PUSH_ANSWER_AS_RESPONSE_PICKER_BEST_ANSWER';
export const PUSH_AS_QUESTION_EDITOR_SELECTED_ANSWER =
  'PUSH_AS_QUESTION_EDITOR_SELECTED_ANSWER';
export const REMOVE_HARD_LINK_FROM_RESPONSE_PICKER =
  'REMOVE_HARD_LINK_FROM_RESPONSE_PICKER';
export const REMOVE_HARD_LINK_FROM_QUESTION_EDITOR =
  'REMOVE_HARD_LINK_FROM_QUESTION_EDITOR';
export const UPDATE_RESPONSE_PICKER_SIMILAR_QUESTIONS =
  'UPDATE_RESPONSE_PICKER_SIMILAR_QUESTIONS';
export const SET_ANSWER_EDITOR_CARD_INSIGHTS =
  'SET_ANSWER_EDITOR_CARD_INSIGHTS';
export const SET_ANSWER_EDITOR_GRAPH_INSIGHTS =
  'SET_ANSWER_EDITOR_GRAPH_INSIGHTS';
export const SET_ANSWER_EDITOR_TABLE_INSIGHTS =
  'SET_ANSWER_EDITOR_TABLE_INSIGHTS';

// QUESTIONS
export const SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD =
  'SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD';
export const SHOW_RESPONSE_PICKER_MODAL_FOR_QUESTIONS =
  'SHOW_RESPONSE_PICKER_MODAL_FOR_QUESTIONS';
export const UPDATE_RESPONSE_PICKER_ANSWERS_AFTER_UNLINK =
  'UPDATE_RESPONSE_PICKER_ANSWERS_AFTER_UNLINK';
export const UPDATE_SIMILAR_QUESTIONS_AFTER_HARD_LINK =
  'UPDATE_SIMILAR_QUESTIONS_AFTER_HARD_LINK';
export const UPDATE_ANSWER_EDITOR_LINKED_QUESTIONS =
  'UPDATE_ANSWER_EDITOR_LINKED_QUESTIONS';
export const SET_ANSWER_EDITOR_LINKED_QUESTIONS =
  'SET_ANSWER_EDITOR_LINKED_QUESTIONS';
export const SET_ALL_QUESTIONS = 'SET_ALL_QUESTIONS';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_QUESTION_AND_ANSWER = 'UPDATE_QUESTION_AND_ANSWER';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const ADD_QUESTION = 'ADD_QUESTION';
export const ADD_MULTIPLE_DRAFT_QUESTIONS = 'ADD_MULTIPLE_DRAFT_QUESTIONS';

// ANALYTICS
export const SET_ANALYTICS_AGGREGATIONS_DATA =
  'SET_ANALYTICS_AGGREGATIONS_DATA';
export const SET_ANALYTICS_TABLE_DATA = 'SET_ANALYTICS_TABLE_DATA';
export const SET_ANALYTICS_GRAPH_DATA = 'SET_ANALYTICS_GRAPH_DATA';
export const SET_ANALYTICS_GRAPH_AND_TABLE_DATA =
  'SET_ANALYTICS_GRAPH_AND_TABLE_DATA';
export const SET_ANALYTICS_FROM_ES_DATA = 'SET_ANALYTICS_FROM_ES_DATA';
export const VALIDATE_QUESTION_FROM_ANALYTICS =
  'VALIDATE_QUESTION_FROM_ANALYTICS';

// CALLBACK LOGS
export const SET_CALLBACK_LOGS_TABLE_DATA = 'SET_CALLBACK_LOGS_TABLE_DATA';
export const SET_CALLBACK_MODAL_SESSION_DETAILS =
  'SET_CALLBACK_MODAL_SESSION_DETAILS';
export const CLEAR_CALLBACK_MODAL_SESSION_DETAILS =
  'CLEAR_CALLBACK_MODAL_SESSION_DETAILS';

// WEBSOCKET
export const SET_WS_TOKEN = 'SET_WS_TOKEN';
export const DISCONNECT_WEBSOCKET = 'DISCONNECT_WEBSOCKET';
export const SET_WS_ASK_QUESTION_ACTION = 'SET_WS_ASK_QUESTION_ACTION';
export const SET_WS_ASK_QUESTION_SENDING = 'SET_WS_ASK_QUESTION_SENDING';
export const RESET_WS_GENERATED_ANSWER = 'RESET_WS_GENERATED_ANSWER';
export const SET_WS_SCRAPER_ACTION = 'SET_WS_SCRAPER_ACTION ';
export const UPDATE_WS_SCANNED_DATA_SOURCE = 'UPDATE_WS_SCANNED_DATA_SOURCE';
export const RESET_WS_SCANNER = 'RESET_WS_SCANNER';
export const WS_ADD_FILE = 'WS_ADD_FILE';
export const RESET_WS_ADD_FILE_ADDED = 'RESET_WS_ADD_FILE_ADDED';
export const WS_UPDATE_ACTION = 'WS_UPDATE_ACTION';
export const WS_STOP_SCRAPER = 'WS_STOP_SCRAPER';

// FUNCTIONS
export const SET_AI_TOOLS = 'SET_AI_TOOLS';
export const ADD_AI_TOOL = 'ADD_AI_TOOL';
export const EDIT_AI_TOOL = 'EDIT_AI_TOOL';
export const SET_SELECTED_AI_TOOL = 'SET_SELECTED_AI_TOOL';
export const DELETE_AI_TOOL = 'DELETE_AI_TOOL';
export const OPEN_AI_TOOL_MODAL = 'OPEN_AI_TOOL_MODAL';
export const CLOSE_AI_TOOL_MODAL = 'CLOSE_AI_TOOL_MODAL';

//SET_INVOICES
export const SET_INVOICES = 'SET_INVOICE';
export const RESET_INVOICE = 'RESET_INVOICE';

//API KEY
export const INITIALIZE_API_KEY = 'INITIALIZE_API_KEY';
export const UNSET_API_KEY = 'UNSET_API_KEY';
export const SET_SHOW_API_KEY_MODAL = 'SET_SHOW_API_KEY_MODAL';
export const CLOSE_API_KEY_MODAL = 'CLOSE_API_KEY_MODAL';

// BOT SETTINGS
export const TOGGLE_SEND_CALLBACK_LOG = 'TOGGLE_SEND_CALLBACK_LOG';
export const SET_PAGE_DETAILS = 'SET_PAGE_DETAILS';
