import React from 'react';
import Modal from '../GenericModal';
import Question from 'components/Question';
import useSimilarQuestionsModal from './hooks';

const SimilarQuestionsModal = () => {
  const {
    handleCloseSimilarQuestionsModal,
    loading,
    openResponseModalPicker,
    openQuestionEditor,
    similarQuestionsModal,
  } = useSimilarQuestionsModal();

  if (!similarQuestionsModal?.isOpen) {
    return false;
  }
  return (
    <Modal
      title="Similar Questions Found"
      visible={similarQuestionsModal.isOpen}
      okText={'Continue to Hard Link'}
      onCancel={handleCloseSimilarQuestionsModal}
      cancelText={'Close'}
      width={816}
      isFormModal
      onOk={openQuestionEditor}
      okButtonProps={{
        disabled: loading || similarQuestionsModal.hasHardLinkFromList,
      }}
      spinning={loading}
    >
      {similarQuestionsModal.isOpen && similarQuestionsModal.data?.length ? (
        <>
          <strong>{'Users Question:'}</strong>
          <br />

          <span> {similarQuestionsModal.itemToChange.question}</span>
          <p />
          <strong>{'Similar Questions:'}</strong>
          {similarQuestionsModal.data.map((item, idx) => {
            return <Question key={idx} item={item} />;
          })}
        </>
      ) : (
        'No Similar questions found!'
      )}
    </Modal>
  );
};

SimilarQuestionsModal.propTypes = {};

export default SimilarQuestionsModal;
