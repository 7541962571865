import useAnswer from './hooks';
import AnswerTabs from '../AnswerTabs';

const Answer = props => {
  const { allAnswers } = useAnswer();

  return (
    <>
      {props.limitReachedContent()}
      <AnswerTabs {...props} allRecords={allAnswers} type="answers" />
    </>
  );
};

export default Answer;
