import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Popover } from 'antd';

import { Context } from 'store/store';
import Button from './Button';
import { StyledSpaceBetweenFlexColumn } from 'styles/GenericStyledComponents';
import { cssVariables } from 'styles/root';
import { REMOVE_WEAK_BOT_STATUS } from 'store/action';
import { botStatistics } from 'utils/bot';

const StyledPopover = styled(Popover)`
  img {
    max-width: 100%;
  }

  p:last-child {
    margin-bottom: 0;
  }

  text-align: center;
`;

const StyledContentWrapper = styled(StyledSpaceBetweenFlexColumn)`
  max-width: 450px;
  text-align: center;
  padding: 20px 0;
  > * + * {
    margin: 5px 0;
  }
`;

export const WeakStatPopover = props => {
  const { weakData, triggerComponent, dataStatName, location, jid, ...rest } =
    props;
  const { push } = useHistory();
  const [_, dispatch] = useContext(Context);
  const { weakBotMessage, redirect } = botStatistics(weakData, jid);

  const handleDismiss = () => {
    dispatch({
      type: REMOVE_WEAK_BOT_STATUS,
      payload: {
        location,
        jid: dataStatName === 'bot' ? weakData.jid : weakData.id,
      },
    });
  };

  if (!weakData) {
    return false;
  }

  const content = (
    <StyledContentWrapper style={{ padding: '10px 20px 10px 20px' }}>
      <ExclamationCircleTwoTone
        twoToneColor={cssVariables.red10}
        style={{ fontSize: 24 }}
      />
      <h3>
        <b>{dataStatName === 'bot' ? weakData?.name : weakData?.identifier}</b>{' '}
        {'is not doing great lately'}...
      </h3>
      {weakBotMessage && Array.isArray(weakBotMessage) ? (
        weakBotMessage.map(msg => <p key={msg}>{msg}</p>)
      ) : (
        <p>
          {`This ${dataStatName} doesn't have a good conversational skills and introduce "dead
          end" most of the time.`}
        </p>
      )}
      <StyledContentWrapper>
        <Button
          onClick={() => (window.location.href = redirect)}
          value="SEE METRICS"
          variant="primary"
          full
          rounded
        />
        <Button
          onClick={handleDismiss}
          value="DISMISS"
          variant="link"
          rounded
          full
          bordered
        />
      </StyledContentWrapper>
    </StyledContentWrapper>
  );

  return (
    <StyledPopover
      className="weak-stat"
      {...rest}
      content={content}
      style={{ maxWidth: 300 }}
    >
      {triggerComponent || rest.children}
    </StyledPopover>
  );
};

WeakStatPopover.propTypes = {
  triggerComponent: PropTypes.node,
  name: PropTypes.string,
  weakData: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired,
  dataStatName: PropTypes.oneOf(['bot', 'channel']).isRequired,
  statMessage: PropTypes.array,
};
