export function appendWithParenthesesIfDuplicate(
  duplicatesToSearch,
  searchString
) {
  const duplicateNameFound = duplicatesToSearch.find(
    str => str === searchString
  );

  let highestCount = 0;
  if (duplicateNameFound) {
    duplicatesToSearch.forEach(str => {
      const match = str.match(new RegExp(`${searchString} \\((\\d+)\\)$`));
      if (match) {
        const count = parseInt(match[1], 10);
        if (!isNaN(count) && count > highestCount) {
          highestCount = count;
        }
      }
    });

    return `${searchString} (${highestCount === 0 ? 1 : highestCount + 1})`;
  }

  return searchString;
}

export const generateRandomString = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  const charactersLength = chars.length;
  for (let i = 0; i < 12; i++) {
    result += chars.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const slugify = str => {
  str = str
    .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
    .toLowerCase();
  str = str.replace(/^\s+|\s+$/gm, '');
  str = str.replace(/\s+/g, '-');
  return str;
};

export const strippedString = str => {
  if (!str) {
    return '';
  }
  if (Array.isArray(str)) {
    return str.map(i => i?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase());
  } else if (!str || !str?.trim() || !str?.trim()?.length) {
    return '';
  }

  return str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

export const stringLocaleCompare = (val1, val2) => {
  return !!val2 && !!val1 ? val1.localeCompare(val2) : !val2 && !!val1 ? -1 : 1;
};

export const stripSpecialCharactersFromText = text => {
  if (!text) {
    return;
  }

  const symbolsRegex = /[\|&;:\$%@!"<>\(\)\+,]/g;
  return text.trim().toLowerCase().replace(symbolsRegex, '');
};
