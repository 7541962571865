import Dragger from 'antd/lib/upload/Dragger';
import { Radio } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const Wrapper = styled.div`
  display: flex;
  padding: 0 0 24px !important;
`;

export const ResponseListWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto !important;
`;

export const StyledQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  width: 100%;

  & .input-with-label {
    flex: 1 1 auto;
  }

  input {
    height: 40px;
    padding: 8px;
    width: 70%;
  }

  & .ant-checkbox-wrapper,
  .ant-checkbox-wrapper {
    margin: 10px 0 0 0;
  }
`;

export const StyledAddQuestionForm = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: row !important;

  > div,
  .ant-input {
    width: 100%;
  }
`;

export const StyledAddQuestionType = styled(Radio.Group)`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  & .ant-space,
  .ant-radio-wrapper,
  .ant-upload-list-item-info {
    width: 100%;
    display: flex;
  }

  & .ant-radio {
    height: 100%;
    width: 17px;
  }

  & .ant-upload,
  ant-upload-list {
    width: 100%;
    display: flex;
  }

  & .ant-upload-list-item-name {
    width: 100%;
    display: flex;
    white-space: pre-wrap;
  }

  &span.ant-radio {
    width: 100%;
  }

  .ant-radio-wrapper > span:not(.ant-radio) {
    width: 100%;
  }
`;

export const StyledQuestionUploader = styled(Dragger)`
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const StyledAnswerTypeContainer = styled.div`
  margin: 18px 0;
`;

export const StyledQuestionModalLabel = styled.div`
  font-size: 14px;
  font-weight: ${cssVariables.font.normal};
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
`;

export const StyledQuestionContent = styled.div`
  background: #f4f6f8;
  border: 1px solid #d9dadb;
  border-radius: 3px;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #33363c;
  margin-bottom: 16px;
  align-items: center;

  &.defaultAnswer {
    background-color: ${cssVariables.errorBgColor};
    border: 1px solid ${cssVariables.errorBorder};

    & svg {
      color: ${cssVariables.errorText};
      margin-bottom: 2px;
    }
  }

  & img {
    max-width: 100%;
  }
`;
