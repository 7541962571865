import axios from 'axios';
import { isEmpty } from 'lodash';
import { getErrorMessage } from './api.service';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const aiTools = {
  // Pass token when calling `jacPrimeRun`
  jacPrimeRun: function (data) {
    const { graph, ...rest } = data;
    const cancelTokenSource = axios.CancelToken.source();
    if (data?.token) {
      axios.defaults.headers.common['Authorization'] = 'token ' + data.token;
    }
    return axios
      .request({
        baseURL: API_BASE_URL,
        url: `/js/walker_run?name=${data.name}`,
        cancelToken: cancelTokenSource.token,
        method: 'post',
        data: {
          ...rest,
          nd: graph || 'active:graph',
        },
      })
      .then(response => {
        if (!response.data.success && !response.data.is_queued) {
          throw new Error(DEFAULT_ERROR_MESSAGE);
        }
        return response;
      })
      .catch(error => {
        throw new Error(getErrorMessage(error));
      });
  },

  getTools: function (jid) {
    return this.jacPrimeRun({
      name: 'get_tools',
      graph: jid,
    });
  },

  deleteTool: function (toolId) {
    return this.jacPrimeRun({
      name: 'delete_tool',
      graph: toolId,
    });
  },

  changeTool: function (jid, ctx) {
    const { properties, requiredFields, ...rest } = ctx;
    const paramsObject = {
      type: 'object',
      properties,
      required: requiredFields,
    };

    const context = {
      parameters: isEmpty(properties) ? undefined : paramsObject,
      ...rest,
    };

    return this.jacPrimeRun({
      graph: jid,
      name: 'change_tool',
      ctx: context,
    });
  },

  addTool: function (
    name,
    description,
    parameters = {},
    output = {},
    version,
    requiredFields,
    graph
  ) {
    const paramsObject = {
      type: 'object',
      properties: parameters,
      required: requiredFields,
    };

    return this.jacPrimeRun({
      graph,
      name: 'add_tool',
      ctx: {
        name,
        description,
        parameters: paramsObject,
        ver: version,
        output: {
          ...output,
        },
      },
    });
  },
};
