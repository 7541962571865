import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const ToolTip = props => {
  const { children, ...rest } = props;
  return (
    <Tooltip placement="bottom" {...rest}>
      {children}
    </Tooltip>
  );
};

ToolTip.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ToolTip;
