import { apiService } from './api.service';

export const importCategoryTemplates = async (
  categoryTemplates,
  jid,
  sentinel,
  token
) => {
  try {
    const categyNames = categoryTemplates.map(template =>
      template.name?.toLowerCase()
    );
    return await apiService.importCategoryTemplates(
      sentinel,
      jid,
      token,
      categyNames
    );
  } catch (error) {
    return error;
  }
};
