import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { StyledModal } from './GenericModal.style';
import {
  StyledFlexRowLeft,
  StyledFlexRowRight,
  StyledSpaceBetweenFlexCenter,
} from 'styles/GenericStyledComponents';
import { cssVariables } from 'styles/root';
import Button from 'components/Button';

const Modal = props => {
  const {
    children,
    spinning,
    isFormModal,
    modalStatus,
    noCancelButton,
    noOkButton,
    ...rest
  } = props;
  return (
    <StyledModal
      footer={
        modalStatus
          ? [
              <StyledSpaceBetweenFlexCenter>
                <StyledFlexRowLeft style={{ color: cssVariables.primaryBlue }}>
                  {modalStatus}
                </StyledFlexRowLeft>
                <StyledFlexRowRight>
                  <Button
                    variant="secondary"
                    disabled={rest.loading}
                    onClick={rest.onCancel}
                    value={rest?.cancelText || 'Cancel'}
                  />
                  <Button
                    loading={rest.loading}
                    variant="primary"
                    value={'Save'}
                    onClick={rest.onOk}
                  />
                </StyledFlexRowRight>
              </StyledSpaceBetweenFlexCenter>,
            ]
          : undefined
      }
      {...rest}
      cancelButtonProps={
        !rest.onCancel || noCancelButton
          ? { hidden: true, ...rest.cancelButtonProps }
          : { ...rest.cancelButtonProps }
      }
      okButtonProps={
        !rest.onOk || noOkButton
          ? { hidden: true, ...rest.okButtonProps }
          : { ...rest.okButtonProps }
      }
      isFormModal={isFormModal}
    >
      <Spin spinning={spinning} tip="Just a moment...">
        {children}
      </Spin>
    </StyledModal>
  );
};

Modal.defaultProps = {
  spinning: false,
};

Modal.propTypes = {
  children: PropTypes.any,
  spinning: PropTypes.bool,
  isFormModal: PropTypes.bool,
  noCancelButton: PropTypes.bool,
  noOkButton: PropTypes.bool,
  modalStatus: PropTypes.string,
};

export default Modal;
