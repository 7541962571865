import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { StyledTable } from './Users.style';
import { stripUUID } from 'utils';

const UsersListTable = ({
  user,
  initLoading,
  filteredUsers,
  handleOnChange,
  handleImpersonateClick,
  handleUpdateUser,
  handleActivityLogsClick,
}) => {
  const menu = userData => (
    <Menu>
      {userData.is_activated ? (
        <Menu.Item
          key="1"
          onClick={() => handleUpdateUser(userData, 'deactivate')}
        >
          Deactivate User
        </Menu.Item>
      ) : (
        <Menu.Item
          key="1"
          onClick={() => handleUpdateUser(userData, 'activate')}
        >
          Activate User
        </Menu.Item>
      )}
      {!userData?.is_superuser ? (
        <Menu.Item key="2" onClick={() => handleImpersonateClick(userData)}>
          Impersonate User
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <StyledTable
      size="small"
      loading={initLoading}
      columns={[
        {
          title: 'Name',
          // eslint-disable-next-line react/display-name
          render: userData => {
            return (
              <Link to={`/admin/users?jid=${stripUUID(userData.jid)}`}>
                {userData.name}
              </Link>
            );
          },
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: 'Id',
          dataIndex: 'jid',
          responsive: ['lg'],
        },
        {
          title: 'Email',
          dataIndex: 'user',
        },
        {
          title: 'Status',
          dataIndex: 'is_activated',
          responsive: ['md'],
          render: value => <span>{value ? 'Active' : 'Inactive'}</span>,
        },
        {
          title: 'Created Date',
          dataIndex: 'created_date',
          responsive: ['md'],
          // eslint-disable-next-line react/display-name
          render: value => (
            <span>{moment(value).format('MMMM D, YYYY, h:mm a')}</span>
          ),
          sorter: (a, b) => new Date(a.created_date) - new Date(b.created_date),
        },
        {
          title: 'Action',
          key: 'action',
          responsive: ['lg'],
          // eslint-disable-next-line react/display-name
          render: userData => (
            <div style={{ textAlign: 'center' }}>
              <Dropdown.Button
                overlay={() => menu(userData)}
                type="primary"
                icon={<DownOutlined />}
              >
                Select Action
              </Dropdown.Button>
            </div>
          ),
        },
      ]}
      pagination={{
        pageSize: 10,
        total: filteredUsers.length,
        position: ['topRight'],
      }}
      onChange={handleOnChange}
      dataSource={filteredUsers}
      footer={() => `Showing ${filteredUsers.length} total users.`}
    />
  );
};

UsersListTable.propTypes = {
  user: PropTypes.object,
  initLoading: PropTypes.bool.isRequired,
  filteredUsers: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleImpersonateClick: PropTypes.func.isRequired,
  handleUserTypeClick: PropTypes.func.isRequired,
  handleUpdateUser: PropTypes.func.isRequired,
  handleActivityLogsClick: PropTypes.func.isRequired,
};

export default UsersListTable;
