import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
const StyleIcon3 = styled.svg`
  width: ${props => props.width};
  height: ${props => props.height};
`;
const Icon3 = ({ width, height, viewbox, iconColor }) => {
  return (
    <StyleIcon3
      width={width || '45px'}
      height={height || '48px'}
      viewBox={viewbox || '-4.5 -.5 33 30'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8433 12.3889H20.3176V10.5802C20.3176 9.33944 19.3081 8.30895 18.0463 8.30895H12.7466V6.43723C13.5247 6.14281 14.0715 5.38571 14.0715 4.50243C14.0715 3.34575 13.1462 2.42041 11.9895 2.42041C10.8328 2.42041 9.90747 3.34575 9.90747 4.50243C9.90747 5.38571 10.4543 6.12178 11.2324 6.43723V8.30895H5.91167C4.67087 8.30895 3.64038 9.31841 3.64038 10.5802V12.3889H3.13565C2.50473 12.3889 2 12.8936 2 13.5245V16.1954C2 16.8263 2.50473 17.331 3.13565 17.331H3.66141V19.3079C3.66141 20.5487 4.67087 21.5792 5.9327 21.5792H18.0673C19.3081 21.5792 20.3386 20.5697 20.3386 19.3079V17.331H20.8644C21.4953 17.331 22 16.8263 22 16.1954V13.5245C21.979 12.9146 21.4742 12.3889 20.8433 12.3889ZM7.21556 13.0618C7.21556 12.1786 7.9306 11.4846 8.79285 11.4846C9.67613 11.4846 10.3701 12.1996 10.3701 13.0618C10.3701 13.9451 9.6551 14.6391 8.79285 14.6391C7.9306 14.6391 7.21556 13.9241 7.21556 13.0618ZM11.9895 19.2448C10.5594 19.2448 9.40273 18.0881 9.40273 16.6581C9.40273 16.4477 9.57098 16.3005 9.76025 16.3005H14.1977C14.408 16.3005 14.5552 16.4688 14.5552 16.6581C14.5762 18.0881 13.4196 19.2448 11.9895 19.2448ZM15.1651 14.6391C14.2818 14.6391 13.5878 13.9241 13.5878 13.0618C13.5878 12.1786 14.3028 11.4846 15.1651 11.4846C16.0273 11.4846 16.7424 12.1996 16.7424 13.0618C16.7634 13.9241 16.0484 14.6391 15.1651 14.6391Z"
        fill={iconColor || '#E9E5E5'}
      />
    </StyleIcon3>
  );
};

Icon3.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewbox: PropTypes.string,
  iconColor: PropTypes.string,
};

export default Icon3;
