import React from 'react';
import ReactFlow, { MiniMap, Controls, Background } from 'react-flow-renderer';
import { ErrorBoundary } from 'react-error-boundary';
import { Spin } from 'antd';
import Title from 'components/Title';
import useConversationSimulator from './hooks';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import { FlexRowSpaceBetweenWrapper } from 'components/Modals/AnswerEditor/Variant/Variant.styles';

const ErrorPage = () => (
  <div>
    <h2>Cannot load the page. Please refresh your browser.</h2>
  </div>
);

const ConversationSimulator = props => {
  const {
    loading,
    question,
    layoutedNodes,
    layoutedEdges,
    handleFindAnswer,
    setQuestion,
    onNodesChange,
    onEdgesChange,
    onNodeClick,
  } = useConversationSimulator();

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Spin spinning={loading}>
        <Title text={`Conversation Simulator`} type="responsive-text" />

        <FlexRowSpaceBetweenWrapper>
          <TextArea
            autoSize={{ minRows: 1, maxRows: 3 }}
            noPaddingTop
            placeholder={'Type question to simulate...'}
            onChange={evt => setQuestion(evt.target.value)}
            value={question}
          />
          <Button
            type="primary"
            value="Submit"
            size="medium"
            onClick={handleFindAnswer}
          />
        </FlexRowSpaceBetweenWrapper>
        <div style={{ width: '80vw', height: '80vh' }}>
          <ReactFlow
            nodes={layoutedNodes}
            edges={layoutedEdges}
            elementsSelectable={true}
            onNodeClick={onNodeClick}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
          >
            <Background />
            <MiniMap />
            <Controls />
          </ReactFlow>
        </div>
      </Spin>
    </ErrorBoundary>
  );
};

ConversationSimulator.propTypes = {};

export default ConversationSimulator;
