import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ROUTES from 'constants/routes';
import {
  StyledLimitNoticeWrapper,
  StyledIcon,
  StyledH1,
  StyledButton,
} from './LimitationBanner.styles';

const LimitationBanner = props => {
  const history = useHistory();
  const { icon, title, subtitle, ...rest } = props;

  return (
    <StyledLimitNoticeWrapper {...rest}>
      {icon || <StyledIcon />}
      <StyledH1>{title}</StyledH1>
      <span>{subtitle}</span>
      <StyledButton
        value="View Plans"
        variant="primary-btn-v2"
        bordered
        onClick={() => history.push(ROUTES.PLAN_AND_PAYMENTS)}
      />
    </StyledLimitNoticeWrapper>
  );
};

LimitationBanner.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default LimitationBanner;
