import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledBotCustomComponent = styled.div`
  max-width: 250;
  text-align: left;
  overflow-x: auto;
  box-shadow: 1px 1px 2px 0px #b5b5b5;
  border-radius: 10px;
  font-family: Calibri, sans-serif;
  line-height: 1.5715;

  & p:first-child {
    margin: 0;
  }

  & img {
    width: 100% !important;
  }

  &.error {
    max-width: 100%;
    color: ${cssVariables.errorBorder};
    font-weight: ${cssVariables.font.bold};
    background-color: ${cssVariables.errorBgColor};
    border: ${cssVariables.errorBorder};
    padding: 10px;
    border-radius: 8px;
  }
`;

export const StyledParsedAnswer = styled.div`
  max-width: 230px;
  border-radius: 10px;
  padding: 15px;
`;

export const StyledTempImage = styled.div`
  height: 90px;
  border: 1px solid ${cssVariables.gray2};
  margin-bottom: 8px;

  & img {
    height: 100%;
    width: 150px;
    padding: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledHeaderrw = styled.div`
  display: -ms-flexbox;
  display: flex;
  white-space: pre-line;
`;

export const StyledResponse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledTime = styled.span`
  font-size: 10px;
  margin-top: 5px;
  font-family: Calibri, sans-serif;
`;
