import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as AntdTooltip } from 'antd';
import styled from 'styled-components';
import ToolTip from './BaseToolTip';
import { InfoCircleFilled } from '@ant-design/icons';
import { cssVariables } from 'styles/root';

const StyledTooltip = styled(AntdTooltip)`
  display: flex;
  align-items: center;

  span:first-child {
    margin-right: 10px;
    font-size: ${props => (props.titleSize ? props.titleSize + 'px' : '14px')};
    font-weight: ${cssVariables.font.bold};
  }

  .ant-tooltip-inner {
    font-size: 11px !important;
    border-radius: 4px !important;
  }
`;

const TitleToolTip = ({ titleSize, title, tip, children, ...rest }) => {
  return (
    <StyledTooltip titleSize={titleSize}>
      <span>{`${title}:`}</span>
      <ToolTip title={tip} placement={rest.placement || 'right'} {...rest}>
        <InfoCircleFilled
          alt={`${title} tip`}
          style={{ color: rest.color || cssVariables.primaryBlue }}
        />
      </ToolTip>
    </StyledTooltip>
  );
};

TitleToolTip.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  tip: PropTypes.string.isRequired,
  titleSize: PropTypes.number,
};

export default TitleToolTip;
