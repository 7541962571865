import styled from 'styled-components';

export const StyledRoot = styled.div`
  padding: 1% 5%;
  position: relative;
  width: 100%;

  & .MuiPaper-root {
    & .anticon {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;

export const StyledTitleWrapper = styled.div`
  padding-bottom: 3rem;
`;

export const StyledDashboardMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;

  & .MuiPaper-root,
  > div {
    display: flex;
    width: 250px;
    max-width: 300px;
  }
`;
