import styled from 'styled-components';
import { Layout, Divider } from 'antd';
import Button from 'components/Button';

export const StyledLayoutSider = styled(Layout.Sider)`
  box-shadow: inset -1px 0px 0px #f1f4f5;
  height: 100%;
  padding-top: 2rem;

  & .sidebar-menu {
    width: 199px !important;

    &.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
      .ant-menu-item-selected {
      background-color: #5aa3cb1a;
      border-right: 5px solid #1890ff;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0 0.5rem 0 1.2rem;
  background-color: #162831;
  width: 80%;
  min-width: 80%;
`;

export const StyledTitle = styled.h3`
  color: white;
  padding: 1rem;
  margin: 1rem 0 1rem 1rem;
`;

export const StyledBackButton = styled(Button)`
  width: fit-content;
  font-size: 16px;
  padding: 5px;
  margin: 0 1rem 1rem 1rem;
  background-color: inherit;
  color: #ffffffa6;

  &:hover {
    color: #ffffffa6;
    background-color: inherit;
  }
`;
