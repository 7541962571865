import { botModeSelector } from 'selectors/bot';
import {
  allAnswersSelector,
  allDefaultAnswersSelector,
  isCategoryModalOpenSelector,
} from 'selectors/bot/answers';
import {
  importExportEnabledSelector,
  isFreeUserSelector,
} from 'selectors/plan';
import { useHistory } from 'react-router-dom';
import useSelector from 'store/useSelector';
import { useEffect, useMemo, useState } from 'react';
import {
  answerCategoriesTabSelector,
  filesCategoriesTabSelector,
  websitesCategoriesTabSelector,
} from 'selectors/bot/categories';
import { SEARCH_KEY_INITIAL_VALUE } from 'constants/answerbank/defaults';
import { extractDisplayAnswer } from 'utils/answers';

const useAnswerTabs = ({
  type,
  renderFilterAnswer,
  selectedTab,
  showDisplayAnswer,
  filteredAnswers,
}) => {
  const isCategoryModalOpen = useSelector(isCategoryModalOpenSelector);
  const isFreeUser = useSelector(isFreeUserSelector);
  const isExportImportEnabled = useSelector(importExportEnabledSelector);
  const botMode = useSelector(botModeSelector);
  const allDefaultAnswers = useSelector(allDefaultAnswersSelector);
  const filesCategory = useSelector(filesCategoriesTabSelector);
  const websitesCategory = useSelector(websitesCategoriesTabSelector);
  const allAnswers = useSelector(allAnswersSelector);
  const answersCategory = useSelector(answerCategoriesTabSelector);
  const [categorListTab, setCategoryListTab] = useState([]);
  const { push } = useHistory();
  const [searchKey, setSearchKey] = useState(SEARCH_KEY_INITIAL_VALUE);
  const searchType = useMemo(() => type.substr(0, type.length - 1), [type]);

  const searchResults = useMemo(() => {
    const answerBankList = renderFilterAnswer();

    if (allAnswers.length && !!searchKey) {
      const tabAnswers =
        selectedTab === 'all'
          ? answerBankList
          : answerBankList.filter(
              answerBank => answerBank.categoryId === selectedTab
            );
      return tabAnswers.filter(ans => {
        const displayAnswer = extractDisplayAnswer(ans);
        const response = showDisplayAnswer
          ? searchKey[searchType].length
            ? displayAnswer.filter(showTextAnswer =>
                showTextAnswer
                  ?.toLowerCase()
                  ?.includes(searchKey[searchType].toLowerCase())
              )
            : displayAnswer
          : searchKey[searchType].length
          ? ans.text.toLowerCase().includes(searchKey[searchType].toLowerCase())
          : ans.text.toLowerCase();
        return response;
      });
    } else {
      return [];
    }
  }, [searchKey, filteredAnswers]);

  const onChangeSearchKey = (value, type) => {
    setSearchKey(currentSearchKey => {
      return {
        ...currentSearchKey,
        [type]: value,
      };
    });
  };

  useEffect(() => {
    switch (type) {
      case 'files':
        return setCategoryListTab(filesCategory);
      case 'websites':
        return setCategoryListTab(websitesCategory);
      default:
        return setCategoryListTab(answersCategory);
    }
  }, [type, filteredAnswers]);

  return {
    botMode,
    isCategoryModalOpen,
    isFreeUser,
    isExportImportEnabled,
    allDefaultAnswers,
    push,
    categorListTab,
    searchKey,
    searchResults,
    onChangeSearchKey,
    searchType,
  };
};

export default useAnswerTabs;
