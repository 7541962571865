import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import { DisabledBtn } from './LimitationTooltip.styles';
import { makeKeywordAsAnchorText } from 'utils';

const LimitationTooltip = props => {
  const {
    children,
    disabledFeat,
    isBordered,
    placement,
    title,
    style,
    isAnElement,
    ...rest
  } = props;

  return (
    <Tooltip
      title={isAnElement ? title : makeKeywordAsAnchorText(title)}
      placement={placement}
      {...rest}
    >
      {children || (
        <DisabledBtn isBordered={isBordered} style={style}>
          {disabledFeat}
        </DisabledBtn>
      )}
    </Tooltip>
  );
};

LimitationTooltip.defaultProps = {
  placement: 'topRight',
  isBordered: true,
};

LimitationTooltip.propTypes = {
  children: PropTypes.any,
  isBordered: PropTypes.bool,
  placement: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.any.isRequired,
  disabledFeat: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default LimitationTooltip;
