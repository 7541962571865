import styled from 'styled-components';
import { Collapse, Divider } from 'antd';
import { cssVariables } from 'styles/root';
import Button from 'components/Button';

const { Panel } = Collapse;

export const StyledCodeButton = styled(Button)`
  background-color: ${cssVariables.primaryBlue} !important;
  text-transform: capitalize;
  max-width: 200px;

  &.secondary {
    color: ${cssVariables.primaryBlue} !important;
    background-color: transparent !important;
  }

  & span {
    font-weight: ${cssVariables.font.normal};
    letter-spacing: 0px;
  }
`;

export const StyledActionBtns = styled.div`
  display: flex;
  column-gap: 10px;

  & button {
    text-transform: capitalize;
  }
`;

export const StyledEncircledNumber = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid ${cssVariables.gray1};
  text-align: center;
  color: #0c3980;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
`;

export const StyledIntegrationSteps = styled.div`
  width: 100%;

  & form {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const StyledCollapsiblePanel = styled(Panel)`
  & .ant-collapse-content-box {
    padding: 2% 5% 5% 5%;
  }
`;

export const StyledCollapsiblePanelHeader = styled.div`
  display: flex;
  align-items: center;

  & span {
    font-size: 1.3rem;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 20px;
`;

export const StyledPanelSubheader = styled.div`
  display: flex;
  align-items: center;

  & span {
    font-size: 1rem;
  }
`;

export const StyledSnippets = styled.div`
  background: rgb(191, 221, 255, 0.1);
  padding: 20px;
  border-radius: 5px;

  & > * {
    opacity: 1;
  }
`;

export const StyledIntegrationInstruction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCodeBlock = styled.div`
  max-width: 100%;
  border-radius: 5px;

  & pre {
    max-width: 100%;
    width: 100%;
    word-break: break-all;
  }
`;

export const StyledPanelSubHeader = styled.div`
  margin-bottom: 10px;
  color: ${cssVariables.gray0};
`;
