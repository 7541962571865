import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledTextIcon = styled.div`
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(180deg, #c4c4c4 0%, #fffcfc 100%);
  font-family: ${cssVariables.font.fontFamily};
  font-weight: ${cssVariables.font.normal};
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;

  @media (max-width: 1023px) {
    height: 34px;
    width: 34px;
  }
`;
