import { Tabs } from 'antd';
import {
  StyledAnswerTabsContainer,
  StyledAnswerbankTabNavCount,
  StyledAnswerbankTabNavLabel,
  StyledAnswerbankTabNavWrapper,
} from '../AnswerBank.styles';
import useAnswerType from './hooks';
import TabPane from 'antd/lib/tabs/TabPane';

const AnswerType = props => {
  const { handleClickMenu, menuList, answerData, selectedAnswerTypeTab } =
    useAnswerType(props);

  return (
    <StyledAnswerTabsContainer>
      <Tabs
        tabPosition="left"
        className="answer-types-tab"
        size="small"
        onTabClick={handleClickMenu}
        activeKey={selectedAnswerTypeTab}
        destroyInactiveTabPane
      >
        {menuList?.map(menu => (
          <TabPane
            tab={
              <StyledAnswerbankTabNavWrapper>
                <StyledAnswerbankTabNavLabel>
                  {menu.name}
                </StyledAnswerbankTabNavLabel>
                {menu.count > 0 && (
                  <StyledAnswerbankTabNavCount>
                    {menu.count}
                  </StyledAnswerbankTabNavCount>
                )}
              </StyledAnswerbankTabNavWrapper>
            }
            key={menu.key}
            className="answer-types-tab-pane"
          >
            {menu.content(answerData)}
          </TabPane>
        ))}
      </Tabs>
    </StyledAnswerTabsContainer>
  );
};

export default AnswerType;
