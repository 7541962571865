import styled from 'styled-components';
import LimitationTooltip from 'components/ToolTips/LimitationToolTip';
import Button from 'components/Button';
import { Menu } from 'antd';

export const StyledTabActionButtons = styled(Menu)`
  text-transform: capitalize;
  background: transparent;
  display: flex;
  justify-content: flex-end;

  .syncBtn {
    color: red !important;
    @keyframes blink {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    animation: blink 1s ease-in-out infinite; /* Adjust the duration and timing function as needed */
  }
`;

export const StyledLimitationTooltip = styled(LimitationTooltip)`
  border: none !important;
`;

export const StyledButton = styled(Button)`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #33363c;
  padding: 8px;
  margin-left: 10px;

  .anticon {
    font-size: 13px;
  }
`;
