import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Menu } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  StyledLayoutSider,
  StyledDivider,
  StyledTitle,
  StyledBackButton,
} from './AdminSidebar.style';
import { MINIMUM_RECOMMENDED_WIDTH } from 'constants/screens';

const AdminSidebar = ({ theme, route, title }) => {
  const [activeMenu, setActiveMenu] = useState(route[0].key);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const currentRouteLocation = useLocation();
  let history = useHistory();

  const detectSize = () => {
    setWindowWidth(window.innerWidth);
  };

  const showIconOnly = useMemo(() => {
    // the window size will decide to showIconOnly or not
    return windowWidth < MINIMUM_RECOMMENDED_WIDTH;
  }, [windowWidth]);

  useEffect(() => {
    setActiveMenu(currentRouteLocation.pathname);
  }, [currentRouteLocation.pathname]);

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowWidth]);

  return (
    <StyledLayoutSider theme={theme} collapsed={showIconOnly} className="sider">
      {showIconOnly ? (
        <StyledBackButton
          onClick={() => history.push('/admin')}
          value=""
          startIcon={
            <ArrowLeftOutlined style={{ fontSize: '16px', margin: '0 10%' }} />
          }
          variant="link"
        />
      ) : (
        <StyledBackButton
          onClick={() => history.push('/admin')}
          value="Back to Home"
          startIcon={<ArrowLeftOutlined />}
          variant="link"
        />
      )}
      <StyledDivider />
      {showIconOnly ? (
        <Avatar size="medium" style={{ margin: '0 30%' }}>
          {title[0].toUpperCase()}
        </Avatar>
      ) : (
        <StyledTitle theme={theme} title={title}>
          {title}
        </StyledTitle>
      )}
      <StyledDivider />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[route[0].path]}
        selectedKeys={[activeMenu]}
        className="bot-menu-sidebar"
        style={{ fontSize: '13px' }}
      >
        {route.map(link => {
          return (
            <Menu.Item icon={link.icon} theme={theme} key={link.path}>
              <Link to={link.path} exact>
                <span>{link.name}</span>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </StyledLayoutSider>
  );
};

AdminSidebar.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  route: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      component: PropTypes.func,
      key: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
};

export default AdminSidebar;
