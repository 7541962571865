import styled from 'styled-components';
import Modal from 'components/Modals/GenericModal';

export const StyledPreviewWidgetModal = styled(Modal)`
  width: 60% !important;
  z-index: 200;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;
