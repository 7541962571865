import { useState, useContext, useEffect } from 'react';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';

import { apiService } from 'services/api.service';
import { BlobServiceClient } from '@azure/storage-blob';
import { Context } from 'store/store';
import { stripUUID, withPrefixUUID } from 'utils';
import {
  ADD_INTEGRATION,
  OPEN_EDIT_PANEL_INTEGRATION,
  RESET_INTEGRATION_SETTINGS,
  SET_CURRENT_INTEGRATION_DATA,
  SHOW_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  SHOW_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  SPAWN_CREATE,
  UPDATE_INTEGRATION,
} from 'store/action';
import {
  integrationDataSelector,
  currentIntegrationIDSelector,
  currentIntegrationActivePanelSelector,
  integrationPanelPermitSelector,
  isPostCustomizationEditModalEditableSelector,
  isPostCustomizationEditModalOpenSelector,
  initialIntegrationSettingsSelector,
} from 'selectors/bot/integration';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { INTEGRATION_DATA } from 'constants/localStorage';
import { INITIAL_BOT_INTEGRATION_DATA } from 'constants/botCustomizer';
import useSelector from 'store/useSelector';
import { strippedString } from 'utils/stringManipulation';

const AZURE_STORAGE_URI = process.env.REACT_APP_AZURE_STORAGE_URI;
const SAS_TOKEN = process.env.REACT_APP_AZURE_SAS_TOKEN;
const INTEGRATIONS_CONTAINER_NAME = 'integrations';

const useWordpress = () => {
  const [state, dispatch] = useContext(Context);
  const permit = useSelector(integrationPanelPermitSelector);
  const activePanel = useSelector(currentIntegrationActivePanelSelector);
  const currentIntegrationID = useSelector(currentIntegrationIDSelector);
  const isPostCustomizationEditable = useSelector(
    isPostCustomizationEditModalEditableSelector
  );
  const isPostCustomizationEditModalOpen = useSelector(
    isPostCustomizationEditModalOpenSelector
  );
  const currentIntegrationSettings = useSelector(integrationDataSelector);
  const initialIntegrationSettings = useSelector(
    initialIntegrationSettingsSelector
  );
  const { pathname, state: locationState } = useLocation();
  const {
    sentinel,
    token,
    bot: { jid, integrations },
    pubAskedQuestion,
    pubInfo,
  } = state;

  const [loading, setLoading] = useState(false);
  const localIntegration =
    JSON.parse(localStorage.getItem(INTEGRATION_DATA)) || locationState;

  const [botComponent, setBotComponent] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [integrationData, setIntegrationData] = useState(
    localIntegration || currentIntegrationSettings
  );
  const WITH_PREFIX_BOT_JID = withPrefixUUID(jid || pathname);
  const blobServiceClient = new BlobServiceClient(
    `${AZURE_STORAGE_URI}?${SAS_TOKEN}`
  );
  const wordpressContainerClient = blobServiceClient.getContainerClient(
    INTEGRATIONS_CONTAINER_NAME
  );
  const getDuplicateName = (input, id) => {
    const strippedInput = strippedString(input);
    return integrations.find(
      i => strippedString(i.identifier) === strippedInput && i.id !== id
    );
  };

  const spawnCreate = async () => {
    try {
      let publicApiRes = null;
      let publicInfoRes = null;

      if (!pubAskedQuestion) {
        publicApiRes = await apiService.spawnCreate('zsb_public_api', token);
      }

      if (!pubInfo) {
        publicInfoRes = await apiService.spawnCreate('zsb_public_info', token);
      }
      const pkRes = await apiService.getPublicKey(token);
      const payload = {
        publicKey: pkRes.data.anyone,
        pubAskedQuestion: publicApiRes.data.jid,
        pubInfo: publicInfoRes.data.jid,
      };
      dispatch({
        type: SPAWN_CREATE,
        payload,
      });
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  const onSave = async data => {
    setLoading(true);
    const isNameExisting = getDuplicateName(data.identifier);
    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('Channel name already exists.');
      }
      const res = await apiService.createIntegration(
        sentinel,
        WITH_PREFIX_BOT_JID,
        data,
        'wordpress',
        token
      );

      if (!pubAskedQuestion || !pubInfo) {
        try {
          await spawnCreate();
        } catch (error) {
          setLoading(false);
          return message.error(error.message || DEFAULT_ERROR_MESSAGE);
        }
      }

      await azureUpload(
        res.data.report[0].context.settingsobj,
        res.data.report[0].jid,
        res.data.report[0].context.identifier
      );
      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.int_type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };

      localStorage.setItem(
        INTEGRATION_DATA,
        JSON.stringify({
          ...data,
          ...payload,
        })
      );

      dispatch({ type: ADD_INTEGRATION, payload });
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const onUpdate = async (data, id) => {
    setLoading(true);
    const isNameExisting = getDuplicateName(data.identifier, id);
    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('A channel with the same name already exists.');
      }
      const res = await apiService.editIntegration(
        sentinel,
        id,
        data,
        'wordpress',
        token
      );
      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.int_type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };
      localStorage.setItem(
        INTEGRATION_DATA,
        JSON.stringify({
          ...data,
          ...payload,
        })
      );

      dispatch({ type: UPDATE_INTEGRATION, payload });
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      return message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleChangeFields = (name, value) => {
    switch (name) {
      case 'identifier':
        setIntegrationData({ ...integrationData, identifier: value });
        break;
      case 'path':
        setIntegrationData({ ...integrationData, path: value });
        break;
      case 'type':
        setIntegrationData({ ...integrationData, type: value });
        break;
      case 'clear':
        if (showColorPicker) {
          setShowColorPicker(!showColorPicker);
        }
        setBotComponent(null);
        setIntegrationData(initialIntegrationSettings);
        break;
      default:
        break;
    }
  };

  const handleSaveChannelInfo = async evt => {
    setLoading(true);
    evt.preventDefault();

    const isNewIntegration =
      !localIntegration || !localIntegration.id || !currentIntegrationID;
    const integrationDataPayload = {
      ...integrationData,
      settings: isNewIntegration
        ? {
            ...INITIAL_BOT_INTEGRATION_DATA.settings,
            ...integrationData.settings,
          }
        : integrationData.settings,
    };
    try {
      const res = isNewIntegration
        ? await onSave(integrationDataPayload)
        : await onUpdate(integrationDataPayload, currentIntegrationID);
      if (res.id) {
        dispatch({
          type: SET_CURRENT_INTEGRATION_DATA,
          payload: res,
        });
        dispatch({
          type: SHOW_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL,
        });
        message.success(
          isNewIntegration
            ? `Integration channel added`
            : `Successfully updated integration channel`
        );
      } else {
        return;
      }
    } catch (error) {
      return message.error(
        error || `An error encountred while adding the integration channel.`
      );
    }
    setLoading(false);
  };

  const handlePanelChange = panel => {
    const panelNumber = panel?.split('-').pop();
    if (
      permit >= 3 &&
      panelNumber < permit &&
      !isPostCustomizationEditable &&
      !isPostCustomizationEditModalOpen
    ) {
      dispatch({
        type: SHOW_POST_CUSTOMIZATION_EDIT_WIDGET_MODAL,
        payload: { panel: Number(panelNumber) },
      });
    } else if (panelNumber <= permit) {
      dispatch({
        type: OPEN_EDIT_PANEL_INTEGRATION,
        payload: { panel: Number(panelNumber) },
      });
    } else if (permit < panelNumber) {
      return message.error(
        `Please complete the current step before you proceed to step ${panelNumber}.`
      );
    }
  };

  const azureUpload = async (
    integrationData,
    integrationID,
    integrationName
  ) => {
    const folderPath = stripUUID(jid);
    const integrationConfigURL = `${folderPath}/${stripUUID(
      integrationID
    )}/config.json`;
    const configFileType = 'application/json';
    const configBlob = new Blob(
      [
        JSON.stringify({
          ...integrationData,
          integration: {
            name: integrationName,
            id: integrationID,
          },
        }),
      ],
      {
        type: configFileType,
      }
    );
    const blobOptions = {
      blobHTTPHeaders: {
        blobContentType: configBlob.type,
        blobCacheControl: 'public, max-age=0',
      },
    };
    const blockConfigBlob =
      wordpressContainerClient.getBlockBlobClient(integrationConfigURL);

    // finally uploading the config file
    await blockConfigBlob.upload(configBlob, configBlob.size, blobOptions);
  };

  useEffect(() => {
    if (locationState?.id) {
      dispatch({
        type: SET_CURRENT_INTEGRATION_DATA,
        payload: locationState,
      });
    }
    return () => {
      dispatch({
        type: RESET_INTEGRATION_SETTINGS,
      });
      localStorage.removeItem(INTEGRATION_DATA);
    };
  }, []);

  useEffect(() => {
    if (locationState?.id) {
      setIntegrationData(locationState);
    }
  }, [locationState]);

  return {
    activePanel,
    currentIntegrationID,
    currentIntegrationSettings,
    integrationData,
    loading,
    handleChangeFields,
    handlePanelChange,
    handleSaveChannelInfo,
    onSave,
    onUpdate,
    setBotComponent,
  };
};

export default useWordpress;
