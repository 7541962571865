import { BlobServiceClient } from '@azure/storage-blob';
import { getImageCloudPath } from 'store/reducers/helpers/bot/integrations';

const DEMO_CONTAINER_NAME = '$web';
const INTEGRATIONS_CONTAINER_NAME = 'integrations';
const SAS_TOKEN = process.env.REACT_APP_AZURE_SAS_TOKEN;
const AZURE_STORAGE_URI = process.env.REACT_APP_AZURE_STORAGE_URI;
const BLOB_CACHE_CONTROL = 'public, max-age=0';

const blobServiceClient = new BlobServiceClient(
  `${AZURE_STORAGE_URI}?${SAS_TOKEN}`
);
const containerClient =
  blobServiceClient.getContainerClient(DEMO_CONTAINER_NAME);
const webContainerClient = blobServiceClient.getContainerClient(
  INTEGRATIONS_CONTAINER_NAME
);

const getBlobHTMLFilepath = folderPath => folderPath + '/index.html';
const getBlobConfigJsonFilepath = folderPath => folderPath + '/config.json';

// let's move the azure upload here
// for separation of concerns
export const BlobStorageService = {
  uploadDemoHTMLAndImageFiles: async function (
    folderPath,
    configData,
    isDemo,
    integrationConfigURL,
    demoHTML,
    isDemoHasCustomBackground,
    demoCustomBackground
  ) {
    try {
      const formData = new FormData();

      const blockHTMLBlob = containerClient.getBlockBlobClient(
        getBlobHTMLFilepath(folderPath)
      );
      const htmlBlob = new Blob([demoHTML], { type: 'text/html' });
      // no bg / html upload for web integration
      if (isDemo) {
        const backgroundPathInCloud = getImageCloudPath(
          demoCustomBackground,
          folderPath,
          'demo-background'
        );
        const blockBackgroundBlob = containerClient.getBlockBlobClient(
          backgroundPathInCloud
        );
        if (isDemoHasCustomBackground) {
          const customBackgroundBlob = new Blob([demoCustomBackground], {
            type: demoCustomBackground.type,
          });

          formData.append(
            'backgroundImage',
            customBackgroundBlob,
            demoCustomBackground.name
          );
        }
        formData.append('upload_file', htmlBlob, 'index.html');

        const files = isDemoHasCustomBackground
          ? [formData.getAll('upload_file'), formData.getAll('backgroundImage')]
          : [formData.getAll('upload_file')];

        // uploading the blobs
        await Promise.all(
          files.map((file, key) => {
            const blobOptions = {
              blobHTTPHeaders: {
                blobContentType: String(file[0].type),
                blobCacheControl: BLOB_CACHE_CONTROL,
              },
            };
            if (blobOptions) {
              if (file && file[0].type === 'text/html') {
                return blockHTMLBlob.upload(file[0], file[0].size, blobOptions);
              } else if (isDemoHasCustomBackground) {
                return blockBackgroundBlob.upload(
                  file[0],
                  file[0].size,
                  blobOptions
                );
              }
            }
            return false;
          })
        );
      }

      const blockConfigBlob = isDemo
        ? containerClient.getBlockBlobClient(
            getBlobConfigJsonFilepath(folderPath)
          )
        : webContainerClient.getBlockBlobClient(integrationConfigURL);

      const configFileType = 'application/json';
      const configBlob = new Blob(
        [
          JSON.stringify(
            isDemoHasCustomBackground
              ? {
                  ...configData,
                  // expects that the background is already uploaded to cloud
                  background: getImageCloudPath(
                    demoCustomBackground,
                    folderPath,
                    'demo-background'
                  ),
                }
              : configData
          ),
        ],
        {
          type: configFileType,
        }
      );

      const blobOptions = {
        blobHTTPHeaders: {
          blobContentType: configBlob.type,
          blobCacheControl: BLOB_CACHE_CONTROL,
        },
      };

      // finally uploading the config file
      await blockConfigBlob.upload(configBlob, configBlob.size, blobOptions);
    } catch (error) {
      throw new TypeError(error.message);
    }
  },

  /**
   *
   * @param {string} folderPath the bot id
   * @param {file} imageFile the image file to be uploaded
   * @param {string} imageLocation options ['launcherAvatar', 'headerAvatar', 'responseAvatar']
   */
  uploadIntegrationCustomAvatar: async function (
    folderPath,
    imageFile,
    imageLocation
  ) {
    try {
      const formData = new FormData();
      const avatarPathInCloud = getImageCloudPath(
        imageFile,
        folderPath,
        'azure' + imageLocation
      );

      const blockImageBlob =
        containerClient.getBlockBlobClient(avatarPathInCloud);

      const customAvatarBlob = new Blob([imageFile], {
        type: imageFile.type,
      });

      formData.append(imageLocation, customAvatarBlob, imageFile.name);

      const files = [formData.getAll(imageLocation)];

      // uploading the blobs
      await Promise.all(
        files.map((file, key) => {
          const blobOptions = {
            blobHTTPHeaders: {
              blobContentType: String(file[0].type),
              blobCacheControl: BLOB_CACHE_CONTROL,
            },
          };
          if (blobOptions) {
            return blockImageBlob.upload(file[0], file[0].size, blobOptions);
          }
          return false;
        })
      );
    } catch (error) {
      throw new TypeError(error.message);
    }
  },

  uploadConfigJsonData: async function (configData, integrationConfigURL) {
    try {
      const blockConfigBlob =
        webContainerClient.getBlockBlobClient(integrationConfigURL);

      const configBlob = new Blob([JSON.stringify(configData)], {
        type: 'application/json',
      });

      const blobOptions = {
        blobHTTPHeaders: {
          blobContentType: configBlob.type,
          blobCacheControl: BLOB_CACHE_CONTROL,
        },
      };

      await blockConfigBlob.upload(configBlob, configBlob.size, blobOptions);
    } catch (error) {
      throw new TypeError(error.message);
    }
  },
};
