import React from 'react';
import { StyledSearch } from './VersionSearch.style';

const VersionSearch = ({ onChange }) => (
  <StyledSearch
    placeholder="Search version"
    size="large"
    allowClear
    onChange={onChange}
  />
);

export default VersionSearch;
