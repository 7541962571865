import styled, { css } from 'styled-components';
import { cssVariables } from 'styles/root';
import { Dropdown, Spin, Menu, InputNumber } from 'antd';
import { StyledFlexColumn } from 'styles/GenericStyledComponents';
import {
  InfoCircleFilled,
  InfoCircleOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';

export const StyledRightNav = styled.div`
  display: flex;
  width: ${props => (props.isSidebarChatExpanded ? '50vw' : '400px')};
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 1200px) {
    position: absolute;
    right: 0;
    max-width: ${props => (props.isSidebarChatExpanded ? '100%' : '300px')};
    z-index: 2;
  }
`;

export const StyledBotTitleSideNav = styled.div`
  padding: 10px;
  height: 80px;
  background: ${cssVariables.zsbCyanGradient};
  color: #fff;
  font-size: 20px;
  font-weight: ${cssVariables.font.bold};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .anticon-close {
    display: none;
  }

  @media (max-width: 1200px) {
    background-color: ${cssVariables.gray0};
    border: 1px solid ${cssVariables.gray1};
    border-bottom: none;

    .anticon-close {
      display: block;
      cursor: pointer;
      margin-left: 20px;
      position: absolute;
      left: 0;
    }
  }
`;

export const StyledSpinning = styled(Spin)`
  min-height: 50vh !important;
`;

export const StyledChatComponent = styled.div`
  border-left: 1px solid ${cssVariables.gray1};
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;

  & .input-with-label {
    margin: 10px;
    flex-direction: unset;
    height: 40px;
    width: 100%;
  }

  & img {
    max-width: 100%;
  }
`;

export const StyledChatQuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  max-width: ${props => (props.isSidebarChatExpanded ? '100%' : '300px')};
`;

export const StyledChatQuestion = styled.div`
  background-color: ${props =>
    props.isChatOnDevMode ? 'transparent' : cssVariables.primaryTransparent};
  color: #fff;
  border-radius: 10px;
  max-width: ${props => (props.isSidebarChatExpanded ? '100%' : '215px')};
  padding: ${props => (props.isChatOnDevMode ? '0px' : '15px')};
  word-break: break-word;
`;

export const StyledChatAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const StyledMessageOptions = styled.div`
  font-size: 11px;
  display: flex;
  max-width: ${props => (props.isSidebarChatExpanded ? '100%' : '270px')};
  flex-direction: column;
`;

export const StyledMsgIndicators = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledChatAnswer = styled.div`
  display: flex;
  align-items: center;
  max-width: ${props => (props.isSidebarChatExpanded ? '100%' : '270px')};
`;

export const StyledTestBotInfo = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 11px;
  & span {
    display: flex;
    align-items: center;
  }
  & .anticon-caret-right {
    margin-right: 5px;
    font-size: 24px;
  }
`;

export const StyledChatViewButton = styled(StyledFlexColumn)`
  height: 60px;
  justify-content: center;
  cursor: pointer;
  ${props =>
    props.isSelected
      ? css`
          background-color: ${cssVariables.primaryCyanHover};
          color: ${cssVariables.primaryCyan};
        `
      : css`
          background-color: transparent;
        `}
`;

export const StyledChatContentHeader = styled.div`
  & .ant-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    height: 78px;
    & span {
      display: block;
    }
    & .ant-menu-item-selected,
    .ant-menu-item-disabled,
    .ant-menu-item-selected:hover,
    .ant-menu-item-disabled:hover,
    .ant-menu-horizontal {
      padding-top: 10px;
      border: none;
    }

    & .ant-menu-item-selected,
    .ant-menu-item-selected:hover,
    .ant-menu-item-disabled,
    .ant-menu-item-disabled:hover {
      background-color: #fff;
      color: ${cssVariables.primaryColor};
      width: 100%;
      text-align: center;
      margin: 0px;
      top: 0px;
      border: none;
      border-top: 1px solid ${cssVariables.primaryColor};
      & .anticon {
        font-weight: ${cssVariables.font.bold};
        font-size: 20px;
      }
    }
    & .ant-menu-item-selected {
      font-weight: ${cssVariables.font.bold};
    }
    & .ant-menu-item:not(.ant-menu-item-selected) {
      background-color: ${cssVariables.gray4};
    }
  }
`;

export const StyledChatList = styled.div`
  padding: 10px;
  overflow-y: auto;
  height: 100%;
`;

export const StyledQuickReplyContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  & span {
    border: 1px solid ${cssVariables.gray2};
    padding: 5px 10px;
    border-radius: 20px;
    margin: 0px 5px;
    margin-top: 5px;
    height: fit-content;
    text-align: center;
    &:hover {
      background-color: ${cssVariables.gray4};
      cursor: pointer;
    }
  }
`;

export const StyledChatScore = styled.div`
  font-style: italic;
  padding: 5px;
  &.danger {
    color: ${cssVariables.errorText};
  }

  &.success {
    color: ${cssVariables.primaryBlue};
  }

  & .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

export const StyledMsgOption = styled.div`
  margin: 5px;
  background-color: ${cssVariables.gray4};
  padding: 3px;
  border-radius: 5px;
  width: fit-content;
  display: block;

  &:hover {
    cursor: pointer;
    background-color: ${cssVariables.gray2};
  }
`;

export const StyledChatAnswerText = styled.div`
  padding: ${props => (props.isChatOnDevMode ? '0px' : '15px')};
  background-color: ${props =>
    props.isChatOnDevMode ? 'transparent' : cssVariables.gray4};
  max-width: ${props => (props.isSidebarChatExpanded ? '100%' : '250px')};
  border-radius: 10px;
  overflow-x: auto;
  white-space: break-spaces;
  word-break: break-word;
  margin-top: 10px;

  &.default-answer {
    background-color: ${cssVariables.red1};
    box-sizing: border-box;
    box-shadow: inset 0px 2px 0px ${cssVariables.red10};
  }

  & p {
    margin: 0px;
    margin-bottom: 0px;
    word-break: break-word;
  }
`;

export const StyledChatAction = styled(Dropdown)`
  font-size: 15px;
  text-align: center;
  border-radius: 50%;
  padding: 5px;
  &:hover {
    background-color: ${cssVariables.gray2};
  }
`;

export const StyledChatInputContainer = styled.div`
  border-top: 1px solid ${cssVariables.gray1};
  background-color: ${cssVariables.gray4};
  height: 60px;

  &.disabled {
    pointer-events: none;
    cursor: none;
    opacity: 0.4;
  }

  & form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    & textarea.ant-input {
      font-size: 16px !important;
      border: none;
      max-height: 37px !important;
    }

    & .ant-input:focus {
      border-color: none;
      box-shadow: none;
    }

    & button {
      background-color: transparent !important;
      padding: 5px;
      margin: 0px;
      min-width: auto;
      cursor: pointer;

      & svg {
        color: ${cssVariables.primaryColor};
        height: 25px;
        font-size: 30px;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
`;

export const StyledColFlexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  .anticon {
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${cssVariables.gray4};
      border-radius: 50px;
    }
  }
`;

export const StyledLoader = styled.div`
  margin: 10px 10px 10px 0;
  display: flex;
`;

export const StyledLoaderContainer = styled.div`
  background-color: #f4f7f9;
  border-radius: 10px;
  padding: 15px;
  max-width: ${props => (props.isSidebarChatExpanded ? '100%' : '215px')};
  text-align: left;

  > span:first-child {
    animation-delay: 0.2s !important;
  }
`;

export const StyledLoaderDots = styled.span`
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: gray;
  margin-right: 2px;
  animation: a 0.5s ease infinite alternate;
`;

export const StyledMenu = styled(Menu)`
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%) !important;
`;

export const StyledLabel = styled.label`
  margin-right: 5px;
`;

export const StyledInteractionInput = styled(InputNumber)`
  width: 50px;
  // font-size: 12px;
`;

export const StyledZoomIn = styled(ZoomInOutlined)`
  font-size: 16px;
  color: ${cssVariables.primaryBlue};
  display: contents;
`;

export const StyledWebSocketActionContainer = styled.div`
  font-size: 11px;
  display: flex;
  max-width: ${props => (props.isSidebarChatExpanded ? '100%' : '270px')};
  flex-direction: column;
`;

export const StyledWebSocketAction = styled.div`
  font-style: italic;
  padding: 5px;
  color: ${cssVariables.primaryBlue};
  margin-bottom: 8px;
`;

export const StyledWebSocketActionList = styled.div`
  padding: 5px;
  color: ${cssVariables.primaryBlue};
`;

export const StyledInfoSequence = styled(InfoCircleFilled)`
  position: absolute;
  margin-top: 3px;
  right: 20px;
  font-size: 13px;
  color: ${cssVariables.primaryBlue};
`;
