import React from 'react';
import Button from '..';
import PropTypes from 'prop-types';

const ToggleShowDisplayAnswer = props => {
  const { showDisplayAnswer, ...rest } = props;
  return (
    <Button
      style={{ fontSize: 12, height: 36 }}
      type="button"
      variant={'link'}
      size="small"
      value={
        showDisplayAnswer ? 'Show plain text answer' : 'Show display answer'
      }
      {...rest}
    />
  );
};

ToggleShowDisplayAnswer.propTypes = {
  showDisplayAnswer: PropTypes.bool,
};

export default ToggleShowDisplayAnswer;
