import React, { useCallback } from 'react';
import { Tag, Switch, Descriptions } from 'antd';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import {
  CheckCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeInvisibleTwoTone,
  EyeTwoTone,
  FileTextOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { StyledFileEditor } from './FileEditor.styles';
import Modal from 'components/Modals/GenericModal';
import Button from 'components/Button';
import { DEFAULT_ANSWER_VERSION } from 'constants/answerbank/defaults';
import useFileEditor from './hooks';

import { cssVariables } from 'styles/root';
import ToggleFullScreenButton from 'components/Button/ToggleFullScreenButton';
import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';

const FileEditor = () => {
  const {
    confirmLoading,
    handleClose,
    isFullScreen,
    handleOnUpdateAnswer,
    setFullScreen,
    handleChangeSwitch,
    fileEditorAnswer,
    isOpen,
    handleDownloadFile,
    downloadLoading,
    previewDocs,
    showPreview,
    setShowPreview,
  } = useFileEditor();
  const isFinalAnswer =
    fileEditorAnswer?.version === 'final'
      ? true
      : false || fileEditorAnswer?.version === DEFAULT_ANSWER_VERSION;

  const renderModalTitle = () => (
    <>
      <span>
        {'Edit File Version'}
        <Tag
          style={{ marginLeft: 20, fontSize: 14 }}
          color={isFinalAnswer ? 'green' : 'red'}
        >
          {isFinalAnswer ? 'Final' : 'Draft'}{' '}
          {isFinalAnswer ? <CheckCircleOutlined /> : <EditOutlined />}
        </Tag>
      </span>
      <ToggleFullScreenButton
        isFullScreen={isFullScreen}
        setFullScreen={setFullScreen}
      />
    </>
  );

  const renderFilePreview = useCallback(() => {
    if (showPreview) {
      return (
        <StyledFlexLeftColumn>
          <Button
            endIcon={showPreview ? <EyeInvisibleTwoTone /> : <EyeTwoTone />}
            onClick={() => setShowPreview(!showPreview)}
            variant="link"
            value={showPreview ? 'Hide Preview' : 'View'}
            bordered
          />
          <DocViewer
            documents={previewDocs}
            pluginRenderers={DocViewerRenderers}
            style={{ width: '100%' }}
            config={{ disableHeader: true }}
          />
        </StyledFlexLeftColumn>
      );
    }
    return (
      <Button
        endIcon={showPreview ? <EyeInvisibleTwoTone /> : <EyeTwoTone />}
        onClick={() => setShowPreview(!showPreview)}
        variant="link"
        value={showPreview ? 'Hide Preview' : 'View'}
        bordered
      />
    );
  }, [fileEditorAnswer, previewDocs, showPreview]);

  return (
    <Modal
      title={renderModalTitle()}
      visible={isOpen}
      onCancel={handleClose}
      destroyOnClose={true}
      onOk={handleOnUpdateAnswer}
      confirmLoading={confirmLoading}
      width={isFullScreen && '100%'}
      isFullScreen={isFullScreen}
      isFormModal
      spinning={confirmLoading}
      okText={
        <>
          <span>{'OK'}</span>
        </>
      }
      footer={
        <div>
          <Button
            disabled={confirmLoading}
            value={'Cancel'}
            onClick={handleClose}
            variant="link"
          />
          <Button
            loading={confirmLoading}
            disabled={confirmLoading}
            variant={!isFinalAnswer ? 'gray' : 'primary'}
            value={!isFinalAnswer ? 'Save as Draft' : 'Save'}
            endIcon={isFinalAnswer ? <SaveOutlined /> : <FileTextOutlined />}
            onClick={handleOnUpdateAnswer}
          />
        </div>
      }
    >
      <StyledFileEditor>
        <Descriptions
          title={
            <Button
              value={'Download'}
              onClick={handleDownloadFile}
              startIcon={<DownloadOutlined />}
              variant="link"
              loading={downloadLoading}
            />
          }
          layout="horizontal"
          bordered
          span={1}
        >
          <Descriptions.Item
            label="File Name"
            labelStyle={{ fontWeight: cssVariables.font.bold }}
            span={3}
          >
            {fileEditorAnswer.file_name}
          </Descriptions.Item>
          <Descriptions.Item
            label="Version"
            labelStyle={{ fontWeight: cssVariables.font.bold }}
            span={3}
          >
            <Switch
              style={{ alignSelf: 'center' }}
              checkedChildren={'Final'}
              unCheckedChildren={'Draft'}
              defaultChecked={isFinalAnswer}
              size="default"
              onChange={handleChangeSwitch}
              loading={confirmLoading}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label="Last Edited"
            labelStyle={{ fontWeight: cssVariables.font.bold }}
            span={3}
          >
            {fileEditorAnswer.lastEdited}
          </Descriptions.Item>
          <Descriptions.Item label={'Preview'} span={3}>
            {renderFilePreview()}
          </Descriptions.Item>
        </Descriptions>
      </StyledFileEditor>
    </Modal>
  );
};

export default FileEditor;
