import { createSelector } from 'reselect';
import { initialPlansAndFeatures } from 'store/initialState';
import { parseBoolean } from 'utils/dataTypes';

export const userPlanSelector = state => state.plan;

export const planTypeSelector = createSelector(
  userPlanSelector,
  plan => plan?.plan_type || 'free'
);

export const isFreeUserSelector = createSelector(
  planTypeSelector,
  planType => planType?.toLowerCase() === 'free'
);

export const onboardingFlagSelector = createSelector(
  userPlanSelector,
  plan => plan?.onboarding_flag || []
);

export const isAdvancedUserSelector = createSelector(
  planTypeSelector,
  planType => planType?.toLowerCase() === 'advanced'
);

export const isBasicUserSelector = createSelector(
  planTypeSelector,
  planType => planType?.toLowerCase() === 'basic'
);

export const maxTransactionCountSelector = createSelector(
  userPlanSelector,
  plan => plan?.max_txn_count || 0
);

export const importExportEnabledSelector = createSelector(
  userPlanSelector,
  plan => {
    if (
      !plan?.export_import ||
      (plan?.export_import &&
        typeof plan?.export_import === 'string' &&
        plan?.export_import?.toLowerCase() === 'false')
    ) {
      return false;
    }
    return true;
  }
);

export const customerPaymentsSelector = createSelector(
  userPlanSelector,
  plan => plan?.payments || []
);

export const invoiceIdSelector = createSelector(
  userPlanSelector,
  plan => plan?.invoice_id
);

export const customerIdSelector = createSelector(
  userPlanSelector,
  plan => plan?.customer?.id
);

export const currentBotCountSelector = createSelector(
  userPlanSelector,
  plan => plan?.current_bot_count || 0
);

export const maxBotCountSelector = createSelector(
  userPlanSelector,
  plan => plan?.max_bot_count || 0
);

export const isMaxBotCountReachedSelector = createSelector(
  maxBotCountSelector,
  currentBotCountSelector,
  (maxBotCount, currentBotCount) => maxBotCount <= currentBotCount
);

export const botPercentageSelector = createSelector(
  currentBotCountSelector,
  maxBotCountSelector,
  (currentBotCount, maxBotCount) =>
    Math.round((currentBotCount / maxBotCount) * 100)
);

export const customerChargesSelector = createSelector(
  customerPaymentsSelector,
  payments => {
    const customerCharges = payments?.charges?.data
      ?.map(payment => payment)
      .filter(Boolean);
    return customerCharges || [];
  }
);

export const showFirstLoginPlanSelector = createSelector(
  userPlanSelector,
  plan => plan.showFirstLoginPlan
);

export const isTextIngestEnabledSelector = createSelector(
  userPlanSelector,
  plan => parseBoolean(plan.text_ingest)
);

export const isTextGenerateEnabledSelector = createSelector(
  userPlanSelector,
  plan => parseBoolean(plan.text_generate)
);

export const isOpenAIPlanEnabledSelector = createSelector(
  userPlanSelector,
  plan => parseBoolean(plan.openai)
);

export const planDetailsSelector = createSelector(
  userPlanSelector,
  plan => plan?.plansAndFeatures || initialPlansAndFeatures
);
