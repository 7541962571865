import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FlexColWrapper,
  FlexRowWrapper,
  StyledDeleteIcon,
} from './Variant.styles';
import Select from 'components/Select';

const VariantConditionForm = ({
  form,
  handleDelete,
  isOnlyForm,
  isFirstRow,
}) => {
  const variantOptions1 = [
    {
      label: 'Option 1',
      value: 'option1',
    },
    {
      label: 'Option 2',
      value: 'option2',
    },
    {
      label: 'Option 3',
      value: 'option3',
    },
  ];

  const variantOptions2 = [
    {
      label: 'Option 1',
      value: 'option1',
    },
    {
      label: 'Option 2',
      value: 'option2',
    },
    {
      label: 'Option 3',
      value: 'option3',
    },
  ];

  const tagOptions = ['Apples', 'Nails', 'Bananas', 'Helicopters'];

  const [selectedItems, setSelectedItems] = useState([]);
  const filteredOptions = tagOptions.filter(o => !selectedItems.includes(o));

  return (
    <FlexRowWrapper>
      <FlexColWrapper>
        <FlexRowWrapper>
          {isFirstRow ? <h6>IF</h6> : <h6>AND</h6>}
          <Select options={variantOptions1} full size={'medium'} />
          <Select options={variantOptions2} full size={'medium'} />
          <Select
            mode="multiple"
            placeholder="Keywords"
            value={selectedItems}
            onChange={setSelectedItems}
            full
            options={filteredOptions}
            size={'medium'}
          />
        </FlexRowWrapper>
      </FlexColWrapper>
      <StyledDeleteIcon
        onClick={() => handleDelete(form.key, isOnlyForm)}
        color="red"
      />
    </FlexRowWrapper>
  );
};

VariantConditionForm.propTypes = {
  form: PropTypes.object,
  handleDelete: PropTypes.func.isRequired,
  isOnlyForm: PropTypes.bool,
  isFirstRow: PropTypes.bool,
};

export default VariantConditionForm;
