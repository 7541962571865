import { orderBy } from 'lodash';

import { DEFAULT_BOT_MODE } from 'constants/bot';
import { botStatistics } from 'utils/bot';

export const getAllBotsData = (bots, order) => {
  return orderBy(
    bots.reverse().map((item, key) => {
      return {
        jid: item.jid,
        name: typeof item.context.name === 'string' ? item.context.name : '',
        description: item.context.description,
        metadata: item.context.metadata || {},
        avatar:
          typeof item.context.name === 'string'
            ? item.context.name.substring(0, 2)
            : null,
        answerCount: item.context.answer_count || 0,
        fileCount: item.context.file_count || 0,
        websiteCount: item.context.website_count || 0,
        createdAt: item.context.created_time,
        lastUpdatedAt: item.context.last_updated_time,
        mailConfig: item.context?.mail_config || {},
        statistics: item.statistics || {},
        isWeak: botStatistics(item).isWeakBot,
        showWeakStat: botStatistics(item).isWeakBot,
        mode: item.context?.mode || DEFAULT_BOT_MODE,
        key,
        shouldSendCallbackLog: Boolean(item.context?.mail_config?.enabled),
      };
    }),
    order && order.sortBy ? order.sortBy : 'createdAt',
    !order || !!order.key % 2 === 0 ? 'desc' : 'asc'
  );
};
