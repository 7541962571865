import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';

const HorizontalDivider = props => {
  const { text, position } = props;
  return <Divider orientation={position}>{text}</Divider>;
};

HorizontalDivider.propTypes = {
  text: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right', 'center']),
};

export default HorizontalDivider;
