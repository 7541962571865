import { useState, useContext, useEffect } from 'react';
import { message } from 'antd';

import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { stripUUID } from 'utils';
import { strippedString } from 'utils/stringManipulation';
import {
  currentIntegrationIDSelector,
  integrationPanelPermitSelector,
} from 'selectors/bot/integration';
import {
  ADD_INTEGRATION,
  SET_VIBER_API,
  SET_CURRENT_INTEGRATION_ID,
  SPAWN_CREATE,
  UPDATE_INTEGRATION,
  UPDATE_VIBER_WEBHOOK_STATUS,
  SET_CURRENT_INTEGRATION_DATA,
  RESET_INTEGRATION_SETTINGS,
} from 'store/action';
import { useLocation } from 'react-router-dom';
import { INTEGRATION_DATA } from 'constants/localStorage';
import { getIntegrationPlatform } from 'utils/integration';
import useSelector from 'store/useSelector';

const VIBER_HEADER_TOKEN_AUTH = process.env.REACT_APP_VIBER_HEADER_TOKEN_AUTH;
const WEBHOOKURL = process.env.REACT_APP_API_BASE_URL;

const useViber = () => {
  const [state, dispatch] = useContext(Context);
  const currentIntegrationID = useSelector(currentIntegrationIDSelector);

  const { state: locationState, pathname, search } = useLocation();
  const {
    sentinel,
    publicKey,
    viberConfig,
    viberCallback,
    token,
    bot: { name, jid, integrations, componentIntegrationData },
  } = state;
  const permit = useSelector(integrationPanelPermitSelector);

  const [loading, setLoading] = useState(false);
  const platform = getIntegrationPlatform(search);
  const isDemo = platform && platform.includes('demo');
  const defaultSettings = {
    ...componentIntegrationData,
    type: 'viber',
    settings: {
      identifier: name,
      label: `Ask ${name}`,
    },
  };
  const localIntegration =
    JSON.parse(localStorage.getItem(INTEGRATION_DATA)) || locationState;
  const [botComponent, setBotComponent] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [integrationData, setIntegrationData] = useState(
    localIntegration || defaultSettings
  );
  const [confirmEditModal, setConfirmEditModal] = useState({
    visible: false,
    panel: 1,
  });
  const [enableManualSetViberWebHook, setEnableManualSetViberWebHook] =
    useState(true);

  const [webHookErrorMsg, setWebHookErrorMsg] = useState(null);

  const getDuplicateName = (input, id) => {
    const strippedInput = strippedString(input);
    return integrations.find(
      i => strippedString(i.identifier) === strippedInput && i.id !== id
    );
  };

  const getViberConfig = async () => {
    const apiName = 'viber_api';
    try {
      const res = await apiService.globalVarGetter(apiName, sentinel, token);
      if (res.data.report[0]) {
        const allVersionsData = Object.entries(res.data.report[0])
          .map(version => ({
            details: version[1],
            status: version[0] === res.data.report[0]?.latest ? 'current' : '',
            value: version[0],
            label: version[0],
          }))
          ?.filter(res => res.value !== 'latest');

        setIntegrationData({
          ...integrationData,
          settings: {
            ...integrationData.settings,
            viber_version: res.data.report[0]?.latest,
          },
        });

        dispatch({
          type: SET_VIBER_API,
          payload: allVersionsData,
        });
        dispatch({
          type: SET_CURRENT_INTEGRATION_DATA,
          payload: {
            type: 'viber',
            viber_version: res.data.report[0]?.latest,
          },
        });
      }
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  useEffect(() => {
    if (integrationData) {
      setEnableManualSetViberWebHook(
        integrationData.status === 'SUCCESS' ? false : true
      );
      if (!viberConfig) {
        getViberConfig();
      }
      if (integrationData?.id) {
        dispatch({
          type: SET_CURRENT_INTEGRATION_DATA,
          payload: integrationData,
        });
      }

      return () => {
        dispatch({
          type: RESET_INTEGRATION_SETTINGS,
        });
        localStorage.removeItem(INTEGRATION_DATA);
      };
    }
  }, []);

  const generateWebhook = async (viberCallbackJID, pk, id) => {
    return `${WEBHOOKURL}/js_public/walker_callback/${stripUUID(
      id
    )}/${stripUUID(viberCallbackJID)}?key=${pk}`;
  };

  const handleViberSetWebhook = async id => {
    const callbackJID = await spawnCreate();
    const pk = publicKey || (await spawnCreatePublicKey());
    const viberURL = await generateWebhook(callbackJID, pk, id);
    await apiService.setViberWebHook(sentinel, id, { url: '' }, token);
    const webhookResponse = await apiService.setViberWebHook(
      sentinel,
      id,
      { url: viberURL, event_types: ['message'] },
      token
    );

    if (webhookResponse.data.report[0].status > 0) {
      setWebHookErrorMsg(
        webhookResponse.data.report[0].status_message === 'invalidAuthToken'
          ? 'Invalid Access Token.'
          : webhookResponse.data.report[0].status_message
      );
    } else {
      setWebHookErrorMsg(null);
    }

    setEnableManualSetViberWebHook(
      webhookResponse.data.report[0].status === 0 ? false : true
    );
    await dispatch({
      type: UPDATE_VIBER_WEBHOOK_STATUS,
      payload: {
        status: webhookResponse.data.report[0].status === 0 ? 'SUCCESS' : null,
        id,
      },
    });
  };

  const handleManualSetWebHook = async id => {
    setLoading(true);
    try {
      await handleViberSetWebhook(id);
      setLoading(false);
      message.success(`Viber webhook successfully set`);
    } catch (error) {
      setLoading(false);
      throw error.message;
    }
  };

  const onSave = async data => {
    setLoading(true);
    const isNameExisting = getDuplicateName(data.identifier);
    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('Channel name already exists.');
      }

      data = {
        ...data,
        settings: {
          ...data.settings,
          viber_version:
            data.settings.viber_version ||
            viberConfig?.filter(version => version?.status === 'current')[0]
              .value,
        },
      };

      const res = await apiService.createIntegration(
        sentinel,
        jid,
        data,
        'viber',
        token
      );
      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.int_type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };

      await handleViberSetWebhook(payload.id);
      setIntegrationData({
        ...integrationData,
        ...payload,
      });
      await dispatch({ type: ADD_INTEGRATION, payload });
      localStorage.setItem(
        INTEGRATION_DATA,
        JSON.stringify({
          ...data,
          ...payload,
        })
      );
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      throw error.message;
    }
  };

  const onUpdate = async (data, id) => {
    setLoading(true);
    const isNameExisting = getDuplicateName(data.identifier, id);
    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('A channel with the same name already exists.');
      }
      const res = await apiService.editIntegration(sentinel, id, data, token);
      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };

      await handleViberSetWebhook(payload.id);
      localStorage.setItem(
        INTEGRATION_DATA,
        JSON.stringify({
          ...data,
          ...payload,
        })
      );
      dispatch({ type: UPDATE_INTEGRATION, payload });
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      throw error.message;
    }
  };

  const handleChangeFields = (name, value) => {
    switch (name) {
      case 'identifier':
        setIntegrationData({ ...integrationData, identifier: value });
        break;

      case 'viber_token':
        setIntegrationData({
          ...integrationData,
          settings: {
            ...integrationData.settings,
            viber_token: value,
            viber_headers: { [VIBER_HEADER_TOKEN_AUTH]: value },
          },
        });
        break;

      case 'viber_version': {
        return setIntegrationData({
          ...integrationData,
          settings: {
            ...integrationData.settings,
            viber_version: value,
          },
        });
      }

      case 'clear':
        if (showColorPicker) {
          setShowColorPicker(!showColorPicker);
        }
        setBotComponent(null);
        setIntegrationData(defaultSettings);
        break;
      default:
        break;
    }
  };

  const handleSaveChannelInfo = async evt => {
    setLoading(true);
    evt.preventDefault();
    try {
      if (!localIntegration || !localIntegration.id || !currentIntegrationID) {
        const res = await onSave(integrationData);
        if (res.id) {
          dispatch({
            type: SET_CURRENT_INTEGRATION_ID,
            payload: res.id,
          });
          message.success(`Integration channel added`);
        } else {
          return;
        }
      } else {
        const res = await onUpdate(integrationData, currentIntegrationID);
        if (res.id) {
          dispatch({
            type: SET_CURRENT_INTEGRATION_ID,
            payload: res.id,
          });
          message.success(`Successfully updated integration channel`);
        } else {
          return;
        }
      }
    } catch (error) {
      return message.error(
        error || `An error encountred while adding the integration channel.`
      );
    }
    setLoading(false);
  };

  const spawnCreatePublicKey = async () => {
    const apiName = 'zsb_public_api';
    try {
      const res = await apiService.spawnCreate(apiName, token);
      const pkRes = await apiService.getPublicKey(token);
      const pK = pkRes.data.anyone;
      const pAQ = res.data.jid;
      dispatch({
        type: SPAWN_CREATE,
        payload: {
          publicKey: pK,
          pubAskedQuestion: pAQ,
        },
      });
      return pK;
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  const spawnCreate = async () => {
    const apiName = 'viber_callback';

    if (viberCallback) {
      return viberCallback;
    }

    try {
      const res = await apiService.spawnCreate(apiName, token);
      // no report prop for spawn create
      const jid = res.data.jid;
      dispatch({
        type: SPAWN_CREATE,
        payload: {
          viberCallback: jid,
        },
      });

      return jid;
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  useEffect(() => {
    if (componentIntegrationData) {
      setIntegrationData({ ...componentIntegrationData, type: 'viber' });
    }
  }, [componentIntegrationData]);

  return {
    botComponent,
    confirmEditModal,
    currentIntegrationID,
    dispatch,
    integrationData,
    isDemo,
    loading,
    permit,
    showColorPicker,
    handleChangeFields,
    handleSaveChannelInfo,
    setBotComponent,
    setConfirmEditModal,
    enableManualSetViberWebHook,
    setShowColorPicker,
    componentIntegrationData,
    viberConfig,
    handleManualSetWebHook,
    webHookErrorMsg,
  };
};

export default useViber;
