import React from 'react';
import { Radio, Upload } from 'antd';

import {
  StyledFlexLeftColumn,
  StyledSpacEvenlyFlexRow,
} from 'styles/GenericStyledComponents';
import Input from 'components/Input';
import {
  StyledAvatar,
  StyledBgUpload,
  StyledBottomLeftPosition,
  StyledBottomRightPosition,
  StyledCurrentBgLabel,
  StyledErrorBottomText,
  StyledImageUploaderWrapper,
  StyledMidRotateBtn,
  StyledPositionBtn,
  StyledPositionBtnTrans,
  StyledPositionCols,
  StyledPositionLabel,
  StyledPositionMidCols,
  StyledRadio,
  StyledRadioLabel,
  StyledRightPosition,
} from '../../CustomizeBot.style';
import PanelActionButtons from '../../PanelActionButtons';
import Select from 'components/Select';
import { BG_OPTIONS } from '../../constants';
import UploadButton from 'components/BotCustomizer/UploadButton/UploadButton';
import { generateIcon } from 'components/BotCustomizer/iconUtils';
import WidgetLogo from 'components/BotCustomizer/WidgetLogo';
import { StyledTempImage } from 'components/BotCustomizer/BotCustomizer.styles';
import { usePlacementAndShape } from './hooks';

const BOT_PANEL_KEY_PREFIX = 'bot-customizer-panel-';

const PlacementAndShape = () => {
  const {
    handleSelectDemoBackground,
    handleShowUserBgPreview,
    handleSelectLauncherShape,
    handleChangeLauncherPosition,
    handleBlurLabelInput,
    launcherAvatar,
    color,
    iconColor,
    textColor,
    label,
    position,
    background,
    shape,
    setTempLabel,
    isCustomBgSelected,
    isDemoHasCustomBackground,
    isDemoCustomBackgroundInCloud,
    isDemo,
    botName,
    demoCustomBackground,
    isPlacementPanelSettingsUnchanged,
    handlePanelChange,
    handleChangeCustomBg,
  } = usePlacementAndShape();

  const renderPreviewImgBackground = () => {
    return (
      <div>
        <StyledCurrentBgLabel>
          Current demo page background
        </StyledCurrentBgLabel>
        <StyledImageUploaderWrapper>
          <StyledTempImage onClick={handleShowUserBgPreview}>
            <img src={demoCustomBackground} alt="demo background" />
          </StyledTempImage>
        </StyledImageUploaderWrapper>
      </div>
    );
  };

  return (
    <StyledFlexLeftColumn>
      <Select
        hidden={!isDemo}
        placeholder={`Pick a background for your demo page`}
        value={isCustomBgSelected ? 'custom' : background}
        label="Demo Page Background Options"
        options={BG_OPTIONS}
        onChange={handleSelectDemoBackground}
      />
      {isDemo && (
        <StyledBgUpload>
          {isDemoCustomBackgroundInCloud ? renderPreviewImgBackground() : null}
          <StyledImageUploaderWrapper
            hidden={!isCustomBgSelected && !isDemoHasCustomBackground}
          >
            <Upload
              beforeUpload={({ file }) =>
                file.type === 'image/png' || file.type === 'image/jpg'
              }
              listType="picture-card"
              onPreview={handleShowUserBgPreview}
              onChange={handleChangeCustomBg}
              accept=".png, .jpg, .jpeg"
              maxCount={1}
            >
              <UploadButton />
            </Upload>
          </StyledImageUploaderWrapper>
        </StyledBgUpload>
      )}

      <StyledPositionLabel>Shape</StyledPositionLabel>
      <Radio.Group
        value={shape || 'circle'}
        onChange={handleSelectLauncherShape}
      >
        <StyledRadio value="circle">
          {
            <StyledAvatar
              color={color}
              avatar={launcherAvatar}
              style={{ height: 50, width: 50 }}
              src={generateIcon(
                launcherAvatar,
                iconColor,
                '60',
                '60',
                (launcherAvatar === 'default') | !launcherAvatar
                  ? '0 6 40 25'
                  : '0 0 24 25'
              )}
            />
          }
        </StyledRadio>
        <StyledRadio value="rectangle">
          <StyledRadioLabel
            className="rect-wrapper"
            color={color}
            textColor={textColor}
          >
            {label || `Ask ${botName}`}
          </StyledRadioLabel>
        </StyledRadio>
      </Radio.Group>

      {shape === 'rectangle' ? (
        <div>
          <Input
            label="Label (for rectangular buttons only)"
            value={label}
            onChange={e => setTempLabel(e.target.value)}
            placeholder={`Enter bot label`}
            onBlur={handleBlurLabelInput}
            maxLength={25}
          />
          {!label ? (
            <StyledErrorBottomText>Label is Required.</StyledErrorBottomText>
          ) : label.length === 25 ? (
            <StyledErrorBottomText>
              Label must have at least 1 character but not more than 25
              characters.
            </StyledErrorBottomText>
          ) : null}
        </div>
      ) : null}

      <StyledPositionLabel>Position {position}</StyledPositionLabel>
      <StyledSpacEvenlyFlexRow gutter={[8, 32]}>
        <StyledPositionCols span={8}>
          {position === 'top-left' ? (
            <WidgetLogo
              color={color}
              label={label}
              position={position}
              shape={shape}
              avatar={launcherAvatar}
              iconColor={iconColor}
              textColor={textColor}
            />
          ) : (
            <StyledPositionBtn
              type="button"
              value="TL"
              onClick={e => handleChangeLauncherPosition('top-left')}
            />
          )}
        </StyledPositionCols>
        <StyledPositionCols span={8}></StyledPositionCols>
        <StyledPositionCols span={8}>
          {position === 'top-right' ? (
            shape === 'rectangle' ? (
              <WidgetLogo
                color={color}
                label={label}
                position={position}
                shape={shape}
                avatar={launcherAvatar}
                iconColor={iconColor}
                textColor={textColor}
              />
            ) : (
              <StyledRightPosition>
                <WidgetLogo
                  color={color}
                  label={label}
                  position={position}
                  shape={shape}
                  avatar={launcherAvatar}
                  iconColor={iconColor}
                  textColor={textColor}
                />
              </StyledRightPosition>
            )
          ) : (
            <StyledPositionBtn
              type="button"
              value="TR"
              onClick={e => handleChangeLauncherPosition('top-right')}
            />
          )}
        </StyledPositionCols>
      </StyledSpacEvenlyFlexRow>
      <StyledSpacEvenlyFlexRow>
        <StyledPositionMidCols span={8}>
          {shape === 'rectangle' ? (
            position === 'mid-left' ? (
              <WidgetLogo
                color={color}
                label={label}
                position={position}
                shape={shape}
                avatar={launcherAvatar}
                iconColor={iconColor}
                textColor={textColor}
              />
            ) : (
              <StyledMidRotateBtn
                type="button"
                value="ML"
                onClick={e => handleChangeLauncherPosition('mid-left')}
              />
            )
          ) : (
            <StyledPositionBtnTrans
              type="button"
              value=""
              variant="secondary"
              disabled
            />
          )}
        </StyledPositionMidCols>
        <StyledPositionCols span={8}></StyledPositionCols>
        <StyledPositionMidCols span={8}>
          {shape === 'rectangle' ? (
            position === 'mid-right' ? (
              shape === 'rectangle' ? (
                <WidgetLogo
                  color={color}
                  label={label}
                  position={position}
                  shape={shape}
                  avatar={launcherAvatar}
                  iconColor={iconColor}
                  textColor={textColor}
                />
              ) : (
                <WidgetLogo
                  color={color}
                  label={label}
                  position={position}
                  shape={shape}
                  avatar={launcherAvatar}
                  iconColor={iconColor}
                />
              )
            ) : (
              <StyledMidRotateBtn
                type="button"
                value="MR"
                onClick={e => handleChangeLauncherPosition('mid-right')}
              />
            )
          ) : (
            ''
          )}
        </StyledPositionMidCols>
      </StyledSpacEvenlyFlexRow>
      <StyledSpacEvenlyFlexRow>
        <StyledPositionCols span={8}>
          {position === 'bottom-left' ? (
            shape === 'rectangle' ? (
              <WidgetLogo
                color={color}
                label={label}
                position={position}
                shape={shape}
                avatar={launcherAvatar}
                iconColor={iconColor}
                textColor={textColor}
              />
            ) : (
              <StyledBottomLeftPosition>
                <WidgetLogo
                  color={color}
                  label={label}
                  position={position}
                  shape={shape}
                  avatar={launcherAvatar}
                  iconColor={iconColor}
                  textColor={textColor}
                />
              </StyledBottomLeftPosition>
            )
          ) : (
            <StyledPositionBtn
              type="button"
              value="BL"
              onClick={e => handleChangeLauncherPosition('bottom-left')}
            />
          )}
        </StyledPositionCols>
        <StyledPositionCols span={8}> </StyledPositionCols>
        <StyledPositionCols span={8}>
          {position === 'bottom-right' || !position ? (
            shape === 'rectangle' ? (
              <WidgetLogo
                color={color}
                label={label}
                position={position}
                shape={shape}
                avatar={launcherAvatar}
                iconColor={iconColor}
                textColor={textColor}
              />
            ) : (
              <StyledBottomRightPosition>
                <WidgetLogo
                  color={color}
                  label={label}
                  position={position}
                  shape={shape}
                  avatar={launcherAvatar}
                  iconColor={iconColor}
                  textColor={textColor}
                />
              </StyledBottomRightPosition>
            )
          ) : (
            <StyledPositionBtn
              type="button"
              value="BR"
              onClick={e => handleChangeLauncherPosition('bottom-right')}
            />
          )}
        </StyledPositionCols>
      </StyledSpacEvenlyFlexRow>
      <PanelActionButtons
        disabled={isPlacementPanelSettingsUnchanged}
        nextPanelKey={`${BOT_PANEL_KEY_PREFIX}2`}
        panelName="config"
        onPanelChange={handlePanelChange}
      />
    </StyledFlexLeftColumn>
  );
};

export default PlacementAndShape;
