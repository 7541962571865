import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import {
  StyledFlexColumn,
  StyledFlexRowCenter,
} from 'styles/GenericStyledComponents';
import DeadBotLogo from 'assets/images/zsb-dead-logo.png';
import { Alert, Button } from 'antd';
import { cssVariables } from 'styles/root';
import { ReloadOutlined } from '@ant-design/icons';

const StyledDeadBot = styled.img`
  height: 400px;
  margin: 40px 0;
`;

const Wrapper = styled(StyledFlexRowCenter)`
  justify-content: center;

  h3 {
    color: ${cssVariables.errorText};
    font-weight: 900;
  }
`;

const StyledErrorWrapper = styled(Alert)`
  justify-content: center !important;
`;

const FallbackComponent = ({ error, showDetails, onShowDetails, onReload }) => {
  const handleShowDetails = () => {
    onShowDetails(showDetails);
  };

  return (
    <Wrapper>
      <StyledFlexColumn style={{ maxWidth: '60vw', paddingTop: 40 }}>
        <StyledDeadBot src={DeadBotLogo} alt="Logo" height={400} />
        <h1>{'ZSB will brb...'}</h1>
        <StyledErrorWrapper
          style={{ width: showDetails ? '100%' : '500px' }}
          type="error"
          showIcon
          message={'Something went wrong!'}
          description={
            !showDetails
              ? error?.message || DEFAULT_ERROR_MESSAGE
              : error?.stack || ''
          }
          action={
            <StyledFlexColumn>
              <Button
                onClick={onReload}
                icon={<ReloadOutlined />}
                type="link"
                style={{ marginBottom: 5 }}
              >
                {'Try again'}
              </Button>
              <Button danger onClick={handleShowDetails}>
                {showDetails ? 'Hide details' : 'Show detailed'}
              </Button>
            </StyledFlexColumn>
          }
        />
      </StyledFlexColumn>
    </Wrapper>
  );
};

const FallbackBoundary = props => {
  const [showDetails, setShowDetails] = useState(false);
  const { children } = props;

  const handleReload = details => {
    window.location.reload();
  };

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <FallbackComponent
          error={error}
          onShowDetails={() => setShowDetails(!showDetails)}
          showDetails={showDetails}
          onReload={handleReload}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

FallbackBoundary.propTypes = {
  children: PropTypes.any,
};

FallbackComponent.propTypes = {
  error: PropTypes.object,
  showDetails: PropTypes.bool,
  onShowDetails: PropTypes.func,
  onReload: PropTypes.func,
};

export default FallbackBoundary;
