import {
  Avatar,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Table,
  message,
} from 'antd';
import {
  ActiveActionLabel,
  SelectOptionTitle,
  StyledBtnAdvanceSettings,
} from './ImportUrlForm.style';
import {
  FILTER_METHOD_OPTIONS,
  FILTER_METHOD_TOOLTIP,
  INITIAL_TARGET_ELEMENT,
  SCANNING_METHOD_OPTIONS,
  SCAN_OPTIONS,
  SPINNER_LABEL,
  UPLOAD_METHOD_OPTIONS,
  VALID_DOMAIN_LIST,
} from 'constants/answerbank/website';
import { Context } from 'store/store';
import { useCallback, useContext, useMemo, useState } from 'react';
import { apiService } from 'services/api.service';
import { DEFAULT_SCRAPE_PREVIEW_EXPRESSION } from 'constants/bot';
import {
  LinkOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { orderBy } from 'lodash';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import {
  handleChangeRowSelectionWebsiteCategoryTable,
  handleChangeRowSelectionWebsitePagesTable,
  renderWebsiteCategoryTableColumn,
  renderWebsitePagesTableColumn,
} from 'store/reducers/helpers/bot/answers';
import { websiteSelectedPageselector } from 'selectors/bot/answers';
import useSelector from 'store/useSelector';

const useImportUrlForm = ({
  setModalBtnLoading,
  crawlerParams,
  setCrawlerParams,
  handelWebsiteFormStateChanges,
  websiteFormState,
  setWebsiteFormState,
  disableUrlFormFields,
  isWordPressModal,
}) => {
  const selectionState = useSelector(websiteSelectedPageselector);
  const [state, dispatch] = useContext(Context);
  const {
    bot: { jid },
    sentinel,
    token,
    webSocket,
  } = state;

  const [pages, setPages] = useState(null);
  const [paginateURLList, setPaginatedURLList] = useState([]);
  const [scrapePreviewSrc, setScrapePreviewSrc] = useState(null);
  const [timeoutInSecond, setTimeoutInSecond] = useState(60);
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);

  const allUrlIsValid = useMemo(() => {
    try {
      return websiteFormState?.urlList?.map(list => {
        const extractedUrl = VALID_DOMAIN_LIST?.find(domain =>
          list.url.includes(domain)
        )
          ? list.url
          : VALID_DOMAIN_LIST[0] + list.url;
        new URL(extractedUrl);
        return true;
      });
    } catch (error) {
      return false;
    }
  }, [websiteFormState?.urlList]);

  const scanNotExist = useMemo(() => {
    if (webSocket?.scan) {
      return (
        Object.keys(webSocket?.scan)?.length === 0 &&
        Object.keys(webSocket?.scan)
      );
    }
  }, [webSocket?.scan]);

  const selectedTemplateDetails = useMemo(
    () =>
      SCAN_OPTIONS?.find(
        option => option.value === websiteFormState?.selectedTemplate
      ),
    [websiteFormState?.selectedTemplate]
  );

  const urlIsValid = useCallback(
    url => {
      try {
        new URL(url);
        return true;
      } catch (error) {
        return false;
      }
    },
    [websiteFormState?.urlList]
  );

  const urlSelectedCount = urlDetails =>
    urlDetails?.urlList?.filter(url =>
      selectionState?.urlSelected?.includes(url.url)
    )?.length;

  const handleOpenPages = page => {
    handelWebsiteFormStateChanges(
      {
        category: page.name,
        urls: webSocket?.scannedTableDataSource?.filter(scanned =>
          page.urlList.some(page => page.url === scanned.url)
        ),
      },
      'selectedPageList'
    );
  };

  const columns = useMemo(
    () =>
      !websiteFormState?.selectedPageList?.urls?.length
        ? renderWebsiteCategoryTableColumn(handleOpenPages, urlSelectedCount)
        : renderWebsitePagesTableColumn,
    [websiteFormState?.selectedPageList, selectionState]
  );

  const domainNameRenderedToolTip = useMemo(() => {
    if (selectedTemplateDetails?.displayScanMethodOptions) {
      if (
        urlIsValid(websiteFormState?.urlList[0].url) &&
        websiteFormState?.urlList[0].url
      ) {
        const inputtedUrl = new URL(websiteFormState?.urlList[0]?.url);
        return inputtedUrl.origin;
      }
    }
    return null;
  }, [websiteFormState?.urlList]);

  const rowSelection = useMemo(
    () =>
      !websiteFormState?.selectedPageList?.urls?.length
        ? handleChangeRowSelectionWebsiteCategoryTable(selectionState, dispatch)
        : handleChangeRowSelectionWebsitePagesTable(
            webSocket?.categorizedTableDataSource,
            selectionState,
            dispatch
          ),
    [
      websiteFormState?.selectedPageList,
      selectionState,
      webSocket?.categorizedTableDataSource,
    ]
  );

  const generateExampleUrl = scanOption => {
    try {
      const extractedUrl = VALID_DOMAIN_LIST?.find(domain =>
        websiteFormState?.urlList[0]?.url.includes(domain)
      )
        ? websiteFormState?.urlList[0]?.url
        : VALID_DOMAIN_LIST[0] + websiteFormState?.urlList[0]?.url;
      const url = new URL(extractedUrl);
      switch (scanOption) {
        case 'fullWebsite':
          return 'e.g. ' + url.origin;

        case 'nestedPages':
          return 'e.g. ' + url.origin + '/about-us/';

        case 'single':
          return 'e.g. ' + url.origin + '/about-us.html';

        default:
          return '';
      }
    } catch {
      return false;
    }
  };

  const handleInputURLChange = (value, key) => {
    const currentUrlList = websiteFormState?.urlList.filter(
      details => details.key !== key
    );
    const existingKey = websiteFormState?.urlList.find(
      details => details.key === key
    );
    const addedUrl = existingKey
      ? { key, url: value }
      : {
          key: websiteFormState?.urlList.length,
          url: value,
        };
    setWebsiteFormState(prevState => {
      return {
        ...prevState,
        urlList: orderBy([...currentUrlList, addedUrl], 'key', 'asc'),
        targetElement: INITIAL_TARGET_ELEMENT,
      };
    });
  };

  const handleAddUrl = () => {
    handelWebsiteFormStateChanges(
      currentUrl => [
        ...currentUrl,
        { key: websiteFormState?.urlList?.length, url: null },
      ],
      'urlList'
    );
  };

  const handleRemoveUrl = key => {
    handelWebsiteFormStateChanges(
      currentUrl => currentUrl.filter(url => url.key !== key),
      'urlList'
    );
  };

  const parsePageRange = pageRange => {
    const [start, end] = pageRange?.split('-').map(Number);
    return Array.from({ length: end - start + 1 }, (_, i) =>
      websiteFormState?.urlList[0]?.url.replace('{pages}', start + i)
    );
  };

  const handleGeneratePaginatedUrl = () => {
    if (websiteFormState?.urlList[0]?.url) {
      const pagesList = pages?.split(',').map(part => part.trim());
      let renderPageList = [];
      for (const page of pagesList) {
        if (page.includes('-')) {
          renderPageList = renderPageList.concat(parsePageRange(page));
        } else {
          renderPageList.push(
            websiteFormState?.urlList[0]?.url?.replace('{pages}', page)
          );
        }
      }

      setPaginatedURLList(renderPageList);
      return renderPageList;
    }
  };

  const handleTemplateChanges = event => {
    // resetStates();
    setCrawlerParams(selectedTemplateDetails?.crawlerParams);
    handelWebsiteFormStateChanges(event, 'selectedTemplate');
  };

  const filterConditionToolTipRenderedToolTip = useMemo(() => {
    if (selectedTemplateDetails?.displayScanMethodOptions) {
      if (
        urlIsValid(websiteFormState?.urlList[0].url) &&
        websiteFormState?.urlList[0].url
      ) {
        const inputtedUrl = new URL(websiteFormState?.urlList[0].url);
        switch (crawlerParams) {
          case 'start':
            return websiteFormState?.urlList[0].url;

          case 'originPath':
            return inputtedUrl.origin + inputtedUrl.pathname;

          case 'sameDomainOnly':
            return inputtedUrl.origin;

          default:
            return null;
        }
      }
    }
    return null;
  }, [websiteFormState?.urlList, crawlerParams]);

  const handleSelectElement = async () => {
    try {
      setModalBtnLoading(true);
      handelWebsiteFormStateChanges(true, 'isTargetElementOpen');
      const pages = {
        goto: {
          url: websiteFormState?.urlList[0].url,
          wait_until: websiteFormState?.selectedUploadingMethod,
          timeout: timeoutInSecond * 1000,
          post_scripts: [
            {
              method: 'evaluate',
              expression: DEFAULT_SCRAPE_PREVIEW_EXPRESSION,
            },
          ],
        },
      };

      const response = await apiService.scrapePreview(
        pages,
        token,
        jid,
        sentinel
      );
      const blob = new Blob([response?.data?.report[0]], { type: 'text/html' });
      const previewSrc = URL.createObjectURL(blob);
      setScrapePreviewSrc(previewSrc);
      setModalBtnLoading(false);
    } catch (error) {
      setModalBtnLoading(false);
      handelWebsiteFormStateChanges(false, 'isTargetElementOpen');
      message.error(
        `Issue occurred while trying to load ${websiteFormState?.urlList[0].url}. Please contact Admin support.`
      );
    }
  };

  const ScrapingProgressList = () => (
    <Spin spinning={scanNotExist} tip={SPINNER_LABEL}>
      {webSocket?.categorizedUrls?.length > 0 && (
        <List
          itemLayout="horizontal"
          grid={{ gutter: 16, column: 4 }}
          dataSource={webSocket?.categorizedUrls}
          renderItem={(category, index) => (
            <List.Item bordered={true} key={index}>
              <List.Item.Meta
                title={
                  category.name === 'processing'
                    ? null
                    : category?.name?.charAt(0).toUpperCase() +
                        category?.name?.slice(1) || 'General'
                }
                avatar={
                  <Avatar
                    icon={
                      category.name === 'processing' ? (
                        <LoadingOutlined style={{ color: '#52c41a' }} />
                      ) : (
                        <LinkOutlined />
                      )
                    }
                  />
                }
                description={
                  <Skeleton
                    loading={category.name === 'processing'}
                    size="small"
                    paragraph={{ rows: 1 }}
                    style={{ lineHeight: 'unset' }}
                  >
                    {category.urlList?.length} Page
                    {category.urlList?.length > 1 ? 's' : ''}
                  </Skeleton>
                }
              />
            </List.Item>
          )}
        />
      )}
    </Spin>
  );

  const uploadURLSteps = [
    {
      title: 'Source',
      content: (
        <Form>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              {websiteFormState?.urlList?.map(urlDetails => (
                <Form.Item
                  label="URL"
                  hasFeedback
                  key={urlDetails.key}
                  tooltip={{
                    title:
                      websiteFormState?.selectedTemplate === 'pagination'
                        ? 'Characters inside the curly braces will be replace by the pages. ex. URL: www.google.com/{page} OUTPUT: www.google.com/1 '
                        : '',
                    icon: (
                      <QuestionCircleOutlined
                        style={{
                          display:
                            websiteFormState?.selectedTemplate === 'pagination'
                              ? 'flex'
                              : 'none',
                        }}
                      />
                    ),
                  }}
                >
                  <Input
                    required
                    onChange={event =>
                      handleInputURLChange(event.target.value, urlDetails.key)
                    }
                    style={{
                      width:
                        websiteFormState?.selectedTemplate === 'multiple'
                          ? urlDetails.key > 0
                            ? '80%'
                            : '90%'
                          : '100%',
                    }}
                    value={urlDetails.url}
                    disabled={disableUrlFormFields}
                  />

                  {websiteFormState?.selectedTemplate === 'multiple' &&
                    urlDetails.key === websiteFormState?.urlList.length - 1 && (
                      <Button
                        variant={'link'}
                        icon={<PlusCircleOutlined />}
                        onClick={handleAddUrl}
                      />
                    )}

                  {websiteFormState?.selectedTemplate === 'multiple' &&
                    urlDetails.key === websiteFormState?.urlList.length - 1 &&
                    urlDetails.key > 0 && (
                      <Button
                        variant={'link'}
                        icon={<MinusCircleOutlined />}
                        onClick={() => handleRemoveUrl(urlDetails.key)}
                      />
                    )}
                </Form.Item>
              ))}
            </Col>
            <Col span={24}>
              {allUrlIsValid && (
                <Form.Item label="Scan">
                  <Select
                    id="template"
                    name="template"
                    onChange={handleTemplateChanges}
                    defaultValue={websiteFormState?.selectedTemplate}
                    style={{
                      width:
                        websiteFormState?.selectedTemplate === 'pagination' &&
                        websiteFormState?.urlList[0]?.url
                          ? '75%'
                          : '100%',
                    }}
                    disabled={disableUrlFormFields}
                  >
                    {SCAN_OPTIONS?.map(option => (
                      <Select.Option value={option.value} key={option.value}>
                        <SelectOptionTitle>{option.title}</SelectOptionTitle>
                        <div>{option.description}</div>
                        <div>{generateExampleUrl(option.value)}</div>
                      </Select.Option>
                    ))}
                  </Select>
                  {websiteFormState?.selectedTemplate === 'pagination' &&
                    websiteFormState?.urlList[0]?.url && (
                      <Button
                        variant="link"
                        value="Generate URL"
                        onClick={handleGeneratePaginatedUrl}
                      />
                    )}
                </Form.Item>
              )}
            </Col>
          </Row>

          {websiteFormState?.selectedTemplate === 'pagination' && (
            <>
              <Form.Item
                label="Pages"
                tooltip={{
                  title: 'Pages should be comma separated',
                  icon: <QuestionCircleOutlined />,
                }}
              >
                <Input
                  placeholder="ex. 1-5, 8, 11-13"
                  onChange={evt => setPages(evt.target.value)}
                  disabled={webSocket?.activeAction === 'add_file'}
                />
              </Form.Item>
              {paginateURLList.length > 0 && (
                <Form.Item labelCol={{ span: 5 }}>
                  <List
                    header={<strong>URL</strong>}
                    bordered
                    dataSource={paginateURLList}
                    renderItem={url => <List.Item>{url}</List.Item>}
                  />
                </Form.Item>
              )}
            </>
          )}
          {allUrlIsValid && (
            <>
              {selectedTemplateDetails?.showTargetElementOptions && (
                <Form.Item
                  label="Target Element"
                  tooltip={{
                    title: 'Should be a valid queryselector.',
                    icon: <QuestionCircleOutlined />,
                  }}
                >
                  <Space.Compact style={{ width: '100%' }}>
                    <TextArea
                      onChange={event =>
                        handelWebsiteFormStateChanges(
                          event?.target?.value,
                          'targetElement'
                        )
                      }
                      value={websiteFormState?.targetElement?.final}
                      autoSize
                      noPaddingTop={true}
                      disableSpan={true}
                      disabled={disableUrlFormFields}
                    />
                    <Popconfirm
                      title="This will load the page and may take sometime."
                      onConfirm={handleSelectElement}
                      okText="Proceed"
                      cancelText="Cancel"
                      showCancel={false}
                    >
                      <Button
                        variant="link"
                        value="Select Element"
                        disabled={disableUrlFormFields}
                      />
                    </Popconfirm>
                  </Space.Compact>
                </Form.Item>
              )}

              <Form.Item>
                <StyledBtnAdvanceSettings
                  onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
                  type="button"
                  disabled={disableUrlFormFields}
                >
                  {showAdvanceSettings === false
                    ? 'Show advanced settings'
                    : 'Hide advanced settings'}
                </StyledBtnAdvanceSettings>
              </Form.Item>

              {showAdvanceSettings && (
                <>
                  {selectedTemplateDetails?.displayScanMethodOptions && (
                    <Row gutter={24}>
                      <Col
                        span={
                          websiteFormState?.selectedScanningMethod === 'all'
                            ? 12
                            : 24
                        }
                      >
                        <Form.Item
                          label="Scan Method"
                          tooltip={{
                            title:
                              websiteFormState?.selectedScanningMethod ===
                              'sameDomainOnly'
                                ? domainNameRenderedToolTip
                                  ? `${FILTER_METHOD_TOOLTIP} contains ${domainNameRenderedToolTip}`
                                  : `${FILTER_METHOD_TOOLTIP} contains the URL`
                                : '',
                            icon: (
                              <QuestionCircleOutlined
                                style={{
                                  display:
                                    websiteFormState?.selectedScanningMethod ===
                                    'sameDomainOnly'
                                      ? 'flex'
                                      : 'none',
                                }}
                              />
                            ),
                          }}
                        >
                          <Select
                            id="action"
                            name="action"
                            onChange={evt =>
                              handelWebsiteFormStateChanges(
                                evt,
                                'selectedScanningMethod'
                              )
                            }
                            defaultValue={
                              websiteFormState?.selectedScanningMethod
                            }
                            width="100%"
                          >
                            {SCANNING_METHOD_OPTIONS?.map(
                              (scanningMethod, idx) => (
                                <Select.Option
                                  key={idx}
                                  value={scanningMethod.value}
                                >
                                  {scanningMethod.label}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      {websiteFormState?.selectedScanningMethod === 'all' && (
                        <Col span={12}>
                          <Form.Item
                            label="Filter Condition"
                            tooltip={{
                              title: filterConditionToolTipRenderedToolTip
                                ? `${FILTER_METHOD_TOOLTIP} start with ${filterConditionToolTipRenderedToolTip}.`
                                : `${FILTER_METHOD_TOOLTIP} start with the URL.`,
                              icon: <QuestionCircleOutlined />,
                            }}
                          >
                            <Select
                              id="action"
                              name="action"
                              onChange={evt => setCrawlerParams(evt)}
                              defaultValue={crawlerParams}
                              width="100%"
                            >
                              {FILTER_METHOD_OPTIONS?.filter(
                                method => method.value !== 'exactString'
                              )?.map((filterMethod, idx) => (
                                <Select.Option
                                  key={idx}
                                  value={filterMethod.value}
                                >
                                  {filterMethod.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  )}

                  <Form.Item label="Wait Until">
                    <Select
                      full
                      size={'medium'}
                      onChange={event =>
                        handelWebsiteFormStateChanges(
                          event,
                          'selectedUploadingMethod'
                        )
                      }
                      defaultValue={websiteFormState?.selectedUploadingMethod}
                    >
                      {UPLOAD_METHOD_OPTIONS?.map(option => (
                        <Select.Option value={option.value} key={option.value}>
                          <SelectOptionTitle>{option.label}</SelectOptionTitle>
                          <div>{option.description}</div>
                          <div>{option.example}</div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Timeout">
                    <InputNumber
                      defaultValue={timeoutInSecond}
                      min={1}
                      onChange={value => setTimeoutInSecond(value)}
                    />{' '}
                    Second(s)
                  </Form.Item>
                </>
              )}
            </>
          )}
        </Form>
      ),
    },
    {
      title: 'Verify Website',
      content: (
        <>
          {!isWordPressModal && (
            <ActiveActionLabel>
              {webSocket?.activeAction === 'add_file'
                ? 'Importing URL...'
                : !webSocket?.scannedTableDataSource
                ? 'Fetching...'
                : null}
            </ActiveActionLabel>
          )}
          {webSocket?.scannedTableDataSource?.length > 0 ? (
            <Spin
              spinning={webSocket?.activeAction === 'add_file'}
              tip={SPINNER_LABEL}
            >
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={
                  !websiteFormState?.selectedPageList?.urls?.length
                    ? webSocket?.categorizedTableDataSource
                    : websiteFormState?.selectedPageList?.urls
                }
                pagination={false}
                size="small"
                rowKey="key"
                title={() => (
                  <h3>
                    <strong>
                      {websiteFormState?.selectedPageList?.category
                        ?.charAt(0)
                        .toUpperCase() +
                        websiteFormState?.selectedPageList?.category?.slice(
                          1
                        ) || ''}{' '}
                    </strong>
                  </h3>
                )}
              />
            </Spin>
          ) : (
            <ScrapingProgressList />
          )}
        </>
      ),
    },
  ];

  const handleTargetElementChanges = event => {
    setWebsiteFormState(currentElement => {
      return {
        ...currentElement,
        targetElement: {
          ...currentElement.targetElement,
          draft: event?.target?.value,
        },
      };
    });
    const iframe = document.getElementById('iframe');
    if (iframe) {
      const iframeDocument =
        iframe?.contentDocument || iframe?.contentWindow?.document;
      const complexSelector = event?.target?.value;
      const nodeList = iframeDocument?.querySelectorAll(complexSelector);
      const nodesWithAttribute =
        iframeDocument?.querySelectorAll('[scraped-selected]');
      nodesWithAttribute.forEach(function (element) {
        element.removeAttribute('scraped-selected');
      });
      nodeList.forEach(function (element) {
        element.setAttribute('scraped-selected', '');
      });
    }
  };

  return {
    uploadURLSteps,
    handleTargetElementChanges,
    scrapePreviewSrc,
  };
};

export default useImportUrlForm;
