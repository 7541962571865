import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Spin } from 'antd';
import { getTimeDifference } from 'utils/dates';
import Modal from 'components/Modals/GenericModal';
import QuestionToTestSuiteDropdownMenu from '../QuestionToTestSuiteDropdownMenu/QuestionToTestSuiteDropdownMenu';
import {
  StyledAskedQuestionModalTitle,
  StyledQuestionLogItem,
  StyledAskedQuestion,
  StyledAskedQuestionTimeAndAction,
  StyledAskedQuestionTime,
  StyledMoreOutlined,
  StyledDatePickerButton,
  StyledFilterWrapper,
  StyledDateFilterLabel,
} from './AskedQuestionModal.style';
import { CaretRightFilled } from '@ant-design/icons';
import DateFilter from 'components/DateFilter';
import moment from 'moment';

const AskedQuestionModal = ({
  handleCloseAskedQuestionModal,
  handleShowAddTestSuiteModal,
  questionLog,
  dateFilter,
  showAskedQuestionModal,
  askedQuestionModalLoading,
  setShowAskedQuestionModal,
  handleDateFilterChange,
  handleShowAskedQuestions,
}) => {
  return (
    <Modal
      visible={showAskedQuestionModal}
      title={
        <StyledAskedQuestionModalTitle>
          Asked Questions
        </StyledAskedQuestionModalTitle>
      }
      onCancel={handleCloseAskedQuestionModal}
      cancelText="Close"
      isFormModal
      width="60%"
    >
      <Spin spinning={askedQuestionModalLoading} tip="Just a moment...">
        <StyledFilterWrapper>
          <StyledDateFilterLabel>Showing results from</StyledDateFilterLabel>
          <DateFilter
            onChange={handleDateFilterChange}
            defaultValue={[
              moment(dateFilter.endDate),
              moment(dateFilter.startDate),
            ]}
            value={[moment(dateFilter.endDate), moment(dateFilter.startDate)]}
          />
          <StyledDatePickerButton
            onClick={handleShowAskedQuestions}
            value={`Go`}
            endIcon={<CaretRightFilled />}
          />
        </StyledFilterWrapper>

        {questionLog.map((askedQuestion, idx) => (
          <StyledQuestionLogItem>
            <StyledAskedQuestion>{askedQuestion.question}</StyledAskedQuestion>
            <StyledAskedQuestionTimeAndAction>
              <StyledAskedQuestionTime>
                {getTimeDifference(askedQuestion.time)}
              </StyledAskedQuestionTime>
              <Dropdown
                overlay={
                  <QuestionToTestSuiteDropdownMenu
                    handleShowAddTestSuiteModal={handleShowAddTestSuiteModal}
                    setShowAskedQuestionModal={setShowAskedQuestionModal}
                    question={askedQuestion.question}
                  />
                }
                placement="bottomLeft"
                trigger={['click']}
              >
                <StyledMoreOutlined />
              </Dropdown>
            </StyledAskedQuestionTimeAndAction>
          </StyledQuestionLogItem>
        ))}
        {questionLog.length === 0 && (
          <div>No questions asked to the answer</div>
        )}
      </Spin>
    </Modal>
  );
};
AskedQuestionModal.propTypes = {
  handleCloseAskedQuestionModal: PropTypes.func,
  handleShowAddTestSuiteModal: PropTypes.any,
  questionLog: PropTypes.any,
  showAskedQuestionModal: PropTypes.bool,
  dateFilter: PropTypes.any,
  showAskedQuestionModal: PropTypes.any,
  setShowAskedQuestionModal: PropTypes.func,
  handleDateFilterChange: PropTypes.func,
  handleShowAskedQuestions: PropTypes.func,
};

export default AskedQuestionModal;
