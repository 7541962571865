import styled from 'styled-components';
import { Radio, Avatar } from 'antd';
import { cssVariables } from 'styles/root';

export const StyledAvatarModalRadio = styled(Radio)`
  padding-top: 30px;

  & span:last-child {
    & div {
      & .default {
        & label {
          & span {
            padding: 6px;
          }
        }
      }
    }
  }
`;

export const StyledAvatarSelect = styled.input`
  margin-top: 25px;
  display: none;

  &:checked + label {
    font-weight: ${cssVariables.font.bold};
    background: ${cssVariables.primaryBlue};
    padding: 25px 15px;
    border-radius: 10%;
  }
`;

export const StyledAvatarSelectLabel = styled.label`
  cursor: pointer;
  padding: 25px 15px;

  &:hover {
    background: ${cssVariables.blue1};
    padding: 25px 15px;
    border-radius: 10%;
  }
`;

export const StyledAvatar = styled(Avatar)`
  background-color: ${props => props.integrationDataColor};
`;

export const StyledChromePickerPosition = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const StyledTempIconImage = styled.div`
  height: 100px;
  border: 1px solid ${cssVariables.gray2};
  margin-bottom: 8px;
  width: fit-content;

  & img {
    height: 100%;
    width: auto;
    padding: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledImageUploaderWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;
