import { createSelector } from 'reselect';
import { currentBotSelector } from '.';

export const getMaxTestCases = state => state.plan.max_test_cases;
export const getMaxTestSuites = state => state.plan.max_test_suite;
export const getTestSuitesSelector = state => state.bot.testSuites;

const defaultTestStatus = {
  success: 0,
  fail: 0,
  unknown: 0,
  outdated: 0,
};

export const isMaxTestSuitesSelector = createSelector(
  getMaxTestSuites,
  getTestSuitesSelector,
  (maxTestSuite, testSuites) => {
    return maxTestSuite <= testSuites?.length;
  }
);

export const selectedTestSuiteSelector = createSelector(
  currentBotSelector,
  bot => bot.testSuite || {}
);

export const selectedTestSuiteStatusSelector = createSelector(
  currentBotSelector,
  bot => bot.testSuite?.lastTestStatus || defaultTestStatus
);

export const testCasesSelector = createSelector(
  selectedTestSuiteSelector,
  testSuite => testSuite.testCases || []
);

export const isMaxTestCasesSelector = createSelector(
  getMaxTestCases,
  testCasesSelector,
  (maxTestCases, testCases) => maxTestCases <= testCases?.length
);

export const getTestSuitesLeftSelector = createSelector(
  getMaxTestSuites,
  getTestSuitesSelector,
  (state, testSuites) => testSuites?.length,
  (maxTestSuite, totalTestSuites) => maxTestSuite - totalTestSuites
);

export const getTestCasesLeftSelector = createSelector(
  getMaxTestCases,
  (state, testCases) => testCases?.length,
  (maxTestCases, totalTestCases) => maxTestCases - totalTestCases
);
