import styled from 'styled-components';
import { FolderFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons';

export const StyledCategoryList = styled.div`
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 5px 10px;

  &:hover {
    background-color: #f8f9fa;
  }
`;

export const StyledCategoryListNameSection = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #6d757d;

  & span:nth-child(2) {
    margin-left: 16px;
  }
`;

export const StyledFolderFilled = styled(FolderFilled)`
  color: ${props => props.color};
  font-size: 28px;
`;

export const StyledCategoryListName = styled.span`
  color: #0f1a20;
  margin-right: 10px;
`;

export const StyledEditButton = styled.span`
  color: #1855b6;
  margi-right: 2px;
  padding: 6px;
  border-radius: 24px;

  &:hover {
    background-color: #c4def6;
  }
`;

export const StyledEditOutlined = styled(EditOutlined)`
  font-size: 16px;
`;

export const StyledDeleteButton = styled.span`
  margin-left: 4px;
  padding: 6px;
  border-radius: 24px;

  &:hover {
    background-color: #ffdfe6;
  }
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: #e80c3d;
  font-size: 16px;
`;
