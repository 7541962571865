import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { FolderAddOutlined } from '@ant-design/icons';

const QuestionToTestSuiteDropdownMenu = ({
  handleShowAddTestSuiteModal,
  setShowAskedQuestionModal,
  question,
}) => {
  return (
    <Menu>
      <Menu.Item
        icon={<FolderAddOutlined />}
        key="add-to-test-suite"
        onClick={() => {
          setShowAskedQuestionModal(false);
          handleShowAddTestSuiteModal(question);
        }}
      >
        Add to TestSuite
      </Menu.Item>
    </Menu>
  );
};
QuestionToTestSuiteDropdownMenu.propTypes = {
  handleShowAddTestSuiteModal: PropTypes.any,
  setShowAskedQuestionModal: PropTypes.func,
  question: PropTypes.any,
};
export default QuestionToTestSuiteDropdownMenu;
