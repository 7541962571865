import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import Input from 'components/Input';
import useResetPassword from './hooks';
import Alert from 'components/Alert';
import Button from 'components/Button';
import ROUTES from 'constants/routes';
import {
  StyledResetPassword,
  StyledHeaderTitle,
  StyledResetPasswordFields,
  StyledResetPasswordBottomLinks,
  StyledForm,
} from './ResetPassword.style';
import { StyledColFlexbox } from 'components/SidebarChat/StyledComponents';
import {
  StyledFlexColumn,
  StyledFlexRowLeft,
} from 'styles/GenericStyledComponents';

const { Text, Title } = Typography;

const ResetPassword = () => {
  const {
    error,
    loading,
    onChangeEmail,
    onSubmit,
    showTokenField,
    onTokenSubmit,
    onChangeToken,
    email,
    token,
    isExternalPage,
  } = useResetPassword();

  return (
    <StyledFlexColumn>
      <StyledFlexColumn style={{ alignItems: 'start' }}>
        <Title level={5}>Forgot Password?</Title>
        <Text>We can help you reset your password.</Text>
      </StyledFlexColumn>
      {error && <Alert type="error" message={error} />}
      {showTokenField ? (
        <StyledForm onSubmit={onTokenSubmit}>
          <StyledResetPasswordFields>
            <Input
              label="Reset password token"
              type="text"
              onChange={onChangeToken}
              value={token}
            />
          </StyledResetPasswordFields>
          <Button
            type="submit"
            full
            value="Confirm Token"
            variant="cyan"
            size="large"
            loading={loading}
          />
        </StyledForm>
      ) : (
        <StyledForm onSubmit={onSubmit}>
          <StyledResetPasswordFields>
            <Input
              required
              label="Email"
              type="email"
              onChange={onChangeEmail}
              value={email}
            />
          </StyledResetPasswordFields>
          <Button
            type="submit"
            full
            value="Send Reset Link"
            variant="cyan"
            size="large"
            loading={loading}
          />
        </StyledForm>
      )}
      <StyledResetPasswordBottomLinks>
        <Link to={isExternalPage ? ROUTES.EXTERNAL_LOGIN : ROUTES.LOGIN}>
          Sign in
        </Link>
        <Link to={isExternalPage ? ROUTES.EXTERNAL_SIGNUP : ROUTES.SIGNUP}>
          Create an account
        </Link>
      </StyledResetPasswordBottomLinks>
    </StyledFlexColumn>
  );
};

export default ResetPassword;
