import { Controlled as ControlledCodeMirror } from 'react-codemirror2';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/keymap/sublime';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

const CodeMirror = ({ editedHTML, updateAnswer, isModal }) => {
  const codeMirrorRef = useRef(null);
  useEffect(() => {
    if (isModal && codeMirrorRef.current) {
      codeMirrorRef.current.editor.refresh();
    }
  }, [isModal]);

  return (
    <ControlledCodeMirror
      value={editedHTML}
      ref={codeMirrorRef}
      options={{
        lineNumbers: true,
        lineWrapping: true,
        mode: 'htmlmixed',
        keyMap: 'sublime',
        theme: 'material',
        autofocus: true,
      }}
      onBeforeChange={(editor, data, value) =>
        updateAnswer(value, editor, data)
      }
    />
  );
};

CodeMirror.propTypes = {
  editedHTML: PropTypes.string,
  updateAnswer: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
};

export default CodeMirror;
