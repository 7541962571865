import { useContext, useState } from 'react';

import { useQuery } from 'pages/useQuery';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import {
  currentIntegrationSettingsSettingPropSelector,
  demoCustomBackgroundSelector,
  isDemoCustomBgSelectedSelector,
  isDemoHasCustomBackgroundSelector,
  isDemoIntegrationSelector,
  isPlacementPanelSettingsUnchangedSelector,
} from 'selectors/bot/integration';
import { botNameSelector } from 'selectors/bot';
import {
  OPEN_IMAGE_VIEWER_MODAL,
  SET_BOT_CUSTOMIZER_ACTIVE_PANEL,
  SET_DEMO_BACKGROUND,
  SET_LAUNCHER_LABEL,
  SET_LAUNCHER_SHAPE,
  SET_WIDGET_LAUNCHER_POSITION,
} from 'store/action';
import { getBase64 } from 'utils';
import {
  isDemoCustomBackgroundInCloudSelector,
  launcherAvatarSelector,
} from 'selectors/bot/integration/settings';

export const usePlacementAndShape = () => {
  const query = useQuery();
  const [, dispatch] = useContext(Context);

  const settings = useSelector(currentIntegrationSettingsSettingPropSelector);
  const botName = useSelector(botNameSelector);
  const isDemo = useSelector(isDemoIntegrationSelector, query.get('platform'));
  const isDemoHasCustomBackground = useSelector(
    isDemoHasCustomBackgroundSelector
  );
  const isDemoCustomBackgroundInCloud = useSelector(
    isDemoCustomBackgroundInCloudSelector
  );
  const demoCustomBackground = useSelector(demoCustomBackgroundSelector);
  const isPlacementPanelSettingsUnchanged = useSelector(
    isPlacementPanelSettingsUnchangedSelector
  );
  const isCustomBgSelected = useSelector(isDemoCustomBgSelectedSelector);
  const launcherAvatar = useSelector(launcherAvatarSelector);

  const { color, iconColor, textColor, label, position, background, shape } =
    settings;

  const [tempLabel, setTempLabel] = useState(label);

  const handleSelectDemoBackground = val => {
    dispatch({
      type: SET_DEMO_BACKGROUND,
      payload: val,
    });
  };

  const handleChangeCustomBg = async ({ file, fileList }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(fileList[0]?.originFileObj);
    }

    dispatch({
      type: SET_DEMO_BACKGROUND,
      payload: file,
    });
  };

  const handleShowUserBgPreview = () => {
    dispatch({
      type: OPEN_IMAGE_VIEWER_MODAL,
      payload: {
        source: demoCustomBackground,
        title: 'Demo background preview',
      },
    });
  };

  const handleSelectLauncherShape = evt => {
    dispatch({ type: SET_LAUNCHER_SHAPE, payload: evt.target.value });
  };

  const handleChangeLauncherPosition = val => {
    dispatch({ type: SET_WIDGET_LAUNCHER_POSITION, payload: val });
  };

  const handleBlurLabelInput = () => {
    if (tempLabel) {
      dispatch({ type: SET_LAUNCHER_LABEL, payload: tempLabel });
    }
  };

  const handlePanelChange = val => {
    dispatch({ type: SET_BOT_CUSTOMIZER_ACTIVE_PANEL, payload: val });
  };

  return {
    handleSelectDemoBackground,
    handleShowUserBgPreview,
    handleSelectLauncherShape,
    handleChangeLauncherPosition,
    handleBlurLabelInput,
    launcherAvatar,
    color,
    iconColor,
    textColor,
    label,
    position,
    background,
    shape,
    tempLabel,
    setTempLabel,
    isCustomBgSelected,
    isDemoHasCustomBackground,
    isDemoCustomBackgroundInCloud,
    isDemo,
    botName,
    demoCustomBackground,
    isPlacementPanelSettingsUnchanged,
    handlePanelChange,
    handleChangeCustomBg,
  };
};
