import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Popover,
  Space,
  Typography,
  message,
  Radio,
  Result,
  Tag,
} from 'antd';
import {
  CaretDownOutlined,
  ClearOutlined,
  ContainerOutlined,
  PlusOutlined,
  RobotOutlined,
  SearchOutlined,
  SettingOutlined,
  UserOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';

import { UTCToLocal, getLocalTimeString } from 'utils/dates';
import { apiService } from 'services/api.service';
import { GET_DATA_ERROR } from 'constants/error';
import { Context } from 'store/store';
import ROUTES from 'constants/routes';
import { StyledFlexRowRight } from 'styles/GenericStyledComponents';
import ToolTip from 'components/ToolTips/BaseToolTip';

const { Text } = Typography;
const useActivityLogs = () => {
  const location = useLocation();
  const [activityLogs, setActivityLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state] = useContext(Context);
  const { token, sentinel } = state;
  const startDateInMS = new Date().setDate(new Date().getDate() - 1);
  const startDate = new Date(startDateInMS).toISOString();
  const todayInMS = new Date();
  const todayISO = new Date(todayInMS).toISOString();
  const [dateFilter, setDateFilter] = useState({
    startDate: startDate,
    endDate: todayISO,
  });
  const [selectedActivityLogs, setSelectedActivityLogs] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('all');
  const [bots, setBots] = useState(null);
  const [actions, setActions] = useState(null);
  const [activeBots, setActiveBots] = useState(null);
  const [users, setUsers] = useState(null);
  const [searchCheckedAction, setSearchCheckedAction] = useState(['']);
  const [searchCheckedBotList, setSearchCheckedBotList] = useState({
    botId: [''],
    botName: [''],
  });
  const [searchCheckedUsers, setSearchCheckedUsers] = useState(['']);
  const [totalRecords, setTotalRecords] = useState(0);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [activityLogsState, setActivityLogsState] = useState({
    items: [],
    hasMore: true,
  });
  const [sort, setSort] = useState('desc');
  const { user } = state;
  const isAdmin =
    user?.is_superuser && location.pathname === ROUTES.ADMIN_ACTIVITY_LOGS;

  const resetState = () => {
    setIsOpen(false);
    setSort('desc');
    setFilter('all');
    getActivityLogs(dateFilter.startDate, dateFilter.endDate, {
      isExecutedAlready: false,
      sort: 'desc',
      bot: [],
      bot_id: [],
      activity_type: [],
      master_id: [],
    });

    setSelectedActivityLogs(null);
    setSearchCheckedBotList({
      botId: [''],
      botName: [''],
    });
    setSearchCheckedUsers(['']);
    setSearchCheckedAction(['']);
  };

  const loadingTemplate = (
    <p style={{ textAlign: 'center' }}>
      <b>Loading...</b>
    </p>
  );

  const endMessageTemplate = (
    <p style={{ textAlign: 'center' }}>
      <b>All logs are displayed.</b>
    </p>
  );

  const setParams = (start_date, end_date, parameter) => {
    let params = {
      master_id: location?.state ? [location?.state?.user_id] : null,
      start_date,
      end_date,
      all: location.pathname === ROUTES.ADMIN_ACTIVITY_LOGS,
      sort: {
        datetime: parameter.sort,
      },
    };

    if (
      parameter.bot &&
      parameter.bot_id &&
      parameter.activity_type &&
      parameter.master_id
    ) {
      return params;
    }

    if (
      searchCheckedBotList?.botId?.length > 1 &&
      searchCheckedBotList?.botName?.length > 1
    ) {
      params = {
        ...params,
        bot: searchCheckedBotList.botName,
        bot_id: searchCheckedBotList.botId,
      };
    }
    if (searchCheckedAction.length > 1) {
      params = {
        ...params,
        activity_type: searchCheckedAction,
      };
    }

    if (searchCheckedUsers.length > 1) {
      params = {
        ...params,
        all: false,
        master_id: searchCheckedUsers,
      };
    }

    return params;
  };

  const getActivityLogs = async (start_date, end_date, param) => {
    setIsLoading(true);
    try {
      let noOfRecords = 0;
      const params = setParams(start_date, end_date, param);
      if (!param.isExecutedAlready || param.isExecutedAlready === false) {
        noOfRecords = numberOfRecords;
        setPageNumber(1);
        setNumberOfRecords(noOfRecords);
      } else if (param.isExecutedAlready === true) {
        noOfRecords =
          totalRecords >= numberOfRecords
            ? 10 * (pageNumber + 1)
            : numberOfRecords;
        setNumberOfRecords(noOfRecords);
        setPageNumber(pageNumber + 1);
      }
      const res = await apiService.getActivityLogs(
        sentinel,
        token,
        params,
        noOfRecords
      );

      if (!res.data.success || !res.data.report || !res.data.report[0]) {
        throw new Error(GET_DATA_ERROR);
      }
      setActivityLogs(res?.data?.report[0]?.hits?.hits);
      setTotalRecords(res?.data?.report[0]?.hits?.total.value);
      setActivityLogsState({
        items: res?.data?.report[0]?.hits?.hits,
        hasMore:
          res?.data?.report[0]?.hits?.total.value >
          res?.data?.report[0]?.hits?.hits.length,
      });
      fetchFilters(params.master_id, params);
    } catch (err) {
      console.log('error:', err);
      setIsLoading(false);
    }
  };

  const parseToStartAndEndOfDayISODate = dateFilter => {
    const { startDate, endDate } = dateFilter;

    var validStartDate = new Date(
      new Date(startDate).setHours(0, 0, 0, 0)
    ).toISOString();
    var validEndDate = new Date(
      new Date(endDate).setHours(23, 59, 59, 59)
    ).toISOString();

    return { validStartDate, validEndDate };
  };

  const formatDateTime = dateTime => {
    const localDate = UTCToLocal(new Date(dateTime));
    return getLocalTimeString(localDate);
  };

  const handleDateFilterChange = (start_date, end_date) => {
    const startDateConverted = new Date(start_date);
    const endDate = new Date(end_date);
    setDateFilter({
      startDate: startDateConverted,
      endDate: endDate,
    });
  };

  const handleModalOpening = (
    activityDate,
    activityAction,
    bot,
    message,
    user,
    planType,
    id
  ) => {
    if (activityDate || activityAction) {
      setSelectedActivityLogs({
        activityDate,
        activityAction,
        bot,
        message,
        user,
        planType,
        id,
      });
    } else {
      setSelectedActivityLogs(null);
    }
    setShowDetailsModal(!showDetailsModal);
  };

  const handleModalClosing = () => {
    setSelectedActivityLogs(null);
    setShowDetailsModal(false);
  };

  const setSelectedActivityLogDetails = logDetails => {
    handleModalOpening(
      formatDateTime(moment(logDetails.datetime)),
      logDetails.activity_action,
      logDetails.bot,
      assignActivityMessage(logDetails),
      logDetails.user?.email,
      logDetails.data.plan_type,
      logDetails.bot_id
    );
  };

  const assignActivityMessage = (logDetails, isExportFile) => {
    switch (logDetails.activity_type) {
      case 'add_bot':
        return logDetails.data.desc
          ? `Name: ${logDetails.data.name} ` +
              (isExportFile ? `` : `\n`) +
              `Description: ${logDetails.data.desc}`
          : `Name: ${logDetails.data.name}`;
      case 'change_integration':
      case 'create_integration':
        return (
          `Name: ${logDetails?.data?.identifier} ` +
          (isExportFile ? `` : `\n`) +
          `Type: ${logDetails?.data?.int_type}`
        );
      default:
        return logDetails?.data?.text;
    }
  };

  const handleDownloadActivityLogs = () => {
    const titleColumn = [
      'Activity Date,',
      'User,',
      'Action,',
      'Bot Name,',
      'Current Bot Name,',
      'Message,',
      '\n',
    ];
    const fileData = activityLogs.map(item => {
      const message = assignActivityMessage(item._source, true)
        ? `Value: ${assignActivityMessage(item._source, true)}`
        : 'N/A';
      const userInfo = item._source.user ? item._source.user?.email : '';
      const newBotName = activeBots?.find(
        activeBot =>
          activeBot.jid === item._source.bot_id &&
          activeBot?.context?.name !== item._source.bot
      )
        ? activeBots?.find(activeBot => activeBot.jid === item._source.bot_id)
            ?.context?.name
        : '';

      return (
        moment(UTCToLocal(new Date(item._source.datetime))).format(
          'YYYY-MM-DD HH:mm'
        ) +
        ',' +
        userInfo +
        ',' +
        item._source.activity_action +
        ',' +
        item._source.bot +
        ',' +
        newBotName +
        ',' +
        message +
        '\n'
      );
    });
    const blob = new Blob(['\ufeff', ...titleColumn, ...fileData], {
      type: 'text/csv',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'zsb-activity-logs.csv';
    link.href = url;
    link.click();
    message.success('Activity logs is exported successfully');
  };

  const handleChangeFilter = e => {
    setFilter(e.target.value);
    if (e.target.value === 'all') {
      resetState();
    }
  };

  const handleChangeActivityDateSorting = sort => {
    setSort(sort);
    getActivityLogs(dateFilter.startDate, dateFilter.endDate, {
      sort,
      isExecutedAlready: true,
    });
  };

  const handleSearch = (e, botName) => {
    if (filter === 'action') {
      setSearchCheckedAction(
        handleCheckedOnSearch(
          searchCheckedAction.filter(value => value !== e.target?.value),
          e.target.value,
          e.target.checked
        )
      );
    } else if (filter === 'botName') {
      const selectedBot = {
        botId: handleCheckedOnSearch(
          searchCheckedBotList?.botId?.filter(
            value => value !== e.target?.value
          ),
          e.target.value,
          e.target.checked
        ),
        botName: handleCheckedOnSearch(
          searchCheckedBotList?.botName?.filter(value => value !== botName),
          botName,
          e.target.checked
        ),
      };
      setSearchCheckedBotList(selectedBot);
    } else if (filter === 'users') {
      setSearchCheckedUsers(
        handleCheckedOnSearch(
          searchCheckedUsers.filter(value => value !== e.target?.value),
          e.target.value,
          e.target.checked
        )
      );
    }
  };

  const handleCheckedOnSearch = (filteredData, value, checked) => {
    if (!checked) {
      return filteredData;
    } else {
      return [...filteredData, value];
    }
  };

  const fetchFilters = async (master_id, params) => {
    try {
      const res = await apiService.getActivityLogsFilters(
        sentinel,
        token,
        master_id,
        location.pathname === ROUTES.ADMIN_ACTIVITY_LOGS,
        params
      );

      if (!res.data.success || !res.data.report || !res.data.report[0]) {
        throw new Error(GET_DATA_ERROR);
      }
      setIsLoading(false);
      setBots(
        res.data.report[0]?.aggregations?.bots?.buckets.map(bot => {
          return {
            name: bot.key[0],
            id: bot.key[1],
            status: res.data?.report[1]?.find(
              activeBots => activeBots.jid === bot.key[1]
            )
              ? 'Active'
              : 'Deleted',
          };
        })
      );
      setActiveBots(res.data?.report[1]);
      setActions(res.data.report[0]?.aggregations?.types?.buckets);
      setUsers(res.data.report[0]?.aggregations?.masters?.buckets);
    } catch (err) {
      console.log('error:', err);
      setIsLoading(false);
    }
  };

  const handleChangePopOver = newOpen => {
    setIsOpen(newOpen);
  };

  const filterTitle = (
    <Radio.Group onChange={handleChangeFilter} value={filter}>
      <Radio value={'all'}>All</Radio>
      {actions && (
        <Radio value={'action'}>
          <SettingOutlined />
          <Text> Action Types</Text>
        </Radio>
      )}
      {bots && !isAdmin && (
        <Radio value={'botName'}>
          <RobotOutlined />
          <Text> Bot List</Text>
        </Radio>
      )}
      {isAdmin && (
        <Radio value={'users'}>
          <UserOutlined />
          Users
        </Radio>
      )}
    </Radio.Group>
  );

  const renderNewBotName = (id, name) => {
    return activeBots?.find(
      activeBot => activeBot.jid === id && activeBot?.context?.name !== name
    ) ? (
      <ToolTip
        title={
          <>
            Current Bot Name:{' '}
            <strong>
              {
                activeBots?.find(activeBot => activeBot.jid === id)?.context
                  ?.name
              }
            </strong>
          </>
        }
      >
        <InfoCircleTwoTone />
      </ToolTip>
    ) : null;
  };

  const filterList = (
    <div
      className="filter-container"
      style={{
        height: '40vh',
        overflowY: 'auto',
        overflowX: 'none',
      }}
    >
      <Space wrap size={25} style={{ width: '20vw' }}>
        <Space direction="vertical">
          {filter === 'all' && (
            <Result
              icon={<ContainerOutlined />}
              title="All logs are displayed."
              style={{ width: '100%', textAlign: 'center' }}
            />
          )}

          {filter === 'botName' &&
            !isAdmin &&
            bots?.map((bot, idx) => (
              <Checkbox
                onChange={e => handleSearch(e, bot.name)}
                value={bot.id}
                checked={
                  searchCheckedBotList?.botId?.find(
                    checkedBot => checkedBot === bot.id
                  ) &&
                  searchCheckedBotList?.botName?.find(
                    checkedBot => checkedBot === bot.name
                  )
                }
                key={idx}
              >
                {bot.name}{' '}
                {bot.status === 'Deleted' && (
                  <ToolTip title={bot.status}>
                    <InfoCircleTwoTone twoToneColor="red" />
                  </ToolTip>
                )}
                {renderNewBotName(bot.id, bot.name)}
              </Checkbox>
            ))}

          {filter === 'action' &&
            actions?.map(action => (
              <Checkbox
                onChange={handleSearch}
                value={action.key[0]}
                checked={searchCheckedAction.includes(action.key[0])}
              >
                {action.key[1]}
              </Checkbox>
            ))}

          {filter === 'users' &&
            users?.map(users => (
              <Checkbox
                onChange={handleSearch}
                value={users.key[0]}
                checked={searchCheckedUsers.includes(users.key[0])}
              >
                {users.key[1]}
              </Checkbox>
            ))}
          <Button
            type="primary"
            onClick={() => {
              setIsOpen(false);
              setSort('desc');
              getActivityLogs(dateFilter.startDate, dateFilter.endDate, {
                sort: 'desc',
              });
            }}
            style={{
              width: '40%',
              position: 'absolute',
              left: '10px',
              bottom: 0,
              marginBottom: '15px',
            }}
            hidden={filter === 'all' || !actions || !bots}
          >
            <SearchOutlined /> Search
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false);
              setSort('asc');
              resetState();
            }}
            style={{
              width: '40%',
              position: 'absolute',
              right: '10px',
              bottom: 0,
              marginBottom: '15px',
            }}
            hidden={filter === 'all' || !actions || !bots}
          >
            <ClearOutlined /> Clear
          </Button>
        </Space>
      </Space>
    </div>
  );

  const filters = (
    <Popover
      content={filterList}
      trigger="click"
      onOpenChange={handleChangePopOver}
      title={filterTitle}
      open={isOpen}
    >
      <Button className="filter-button">
        <Space>
          <PlusOutlined />
          Add Filter
          <CaretDownOutlined />
        </Space>
      </Button>
    </Popover>
  );

  const fetchNextScroll = () => {
    const { validStartDate, validEndDate } =
      parseToStartAndEndOfDayISODate(dateFilter);
    setTimeout(() => {
      getActivityLogs(validStartDate, validEndDate, {
        isExecutedAlready: true,
        sort: sort,
      });
    }, 500);
  };

  useEffect(() => {
    if (sentinel && token) {
      resetState();
    }
  }, [sentinel, token]);

  return {
    activityLogs,
    dateFilter,
    selectedActivityLogs,
    showDetailsModal,
    isLoading,
    filters,
    activityLogsState,
    sort,
    loadingTemplate,
    endMessageTemplate,
    isAdmin,
    formatDateTime,
    handleDateFilterChange,
    setSelectedActivityLogDetails,
    handleModalClosing,
    handleModalOpening,
    handleDownloadActivityLogs,
    handleChangeFilter,
    getActivityLogs,
    handleSearch,
    setSort,
    handleChangeActivityDateSorting,
    fetchNextScroll,
    parseToStartAndEndOfDayISODate,
    activeBots,
    renderNewBotName,
  };
};

export default useActivityLogs;
