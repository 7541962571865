import { message } from 'antd';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import {
  RESET_WEBSITE_SELECTED_PAGES,
  RESET_WS_SCANNER,
  SET_WEBSITE_SELECTED_PAGES,
  SPAWN_CREATE,
  UPDATE_WS_SCANNED_DATA_SOURCE,
  WORD_PRESS_SUCCESS,
  WS_STOP_SCRAPER,
  WS_UPDATE_ACTION,
} from 'store/action';
import {
  getTotalAnswersLeftSelector,
  websiteSelectedPageselector,
} from 'selectors/bot/answers';
import useSelector from 'store/useSelector';
import { createBot, fetchBotDetails } from 'services/bots.service';
import { faker } from '@faker-js/faker';
import { INTEGRATION_DATA } from 'constants/localStorage';
import { stripUUID, withPrefixUUID } from 'utils';
import { BlobServiceClient } from '@azure/storage-blob';
import { INITIAL_BOT_INTEGRATION_DATA } from 'constants/botCustomizer';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import ROUTES from 'constants/routes';
import {
  DEFAULT_WEBSITE_FORM_STATE_VALUE,
  INITIAL_SELECTED_PAGE_LIST,
  SCAN_OPTIONS,
} from 'constants/answerbank/website';
import {
  categorizedWebsitePages,
  extractScannedObject,
  extractWebsiteScanList,
} from 'store/reducers/helpers/bot/answers';
import { uuid } from 'uuidv4';

const AZURE_STORAGE_URI = process.env.REACT_APP_AZURE_STORAGE_URI;
const SAS_TOKEN = process.env.REACT_APP_AZURE_SAS_TOKEN;
const INTEGRATIONS_CONTAINER_NAME = 'integrations';

const useWordPressIntegrationModal = ({ onClose, setParsedFileAnswers }) => {
  const history = useHistory();
  const controller = new AbortController();
  const { pathname } = useLocation();
  const [state, dispatch] = useContext(Context);
  const { sentinel, token, plan, webSocket, graph, pubAskedQuestion, pubInfo } =
    state;
  const { plan_type } = plan;

  const selectionState = useSelector(websiteSelectedPageselector);
  const answersAllowed = useSelector(getTotalAnswersLeftSelector);

  const [fileUploaded, setFileUploaded] = useState(null);
  const [importErrorMsg, setImportErrorMsg] = useState(null);
  const [importWarnMsg, setImportWarnMsg] = useState(null);
  const [finalAnswerWarnMsg, setFinalAnswerWarnMsg] = useState(null);
  const [modalBtnLoading, setModalBtnLoading] = useState(false);
  const limitErrorMsg = `You can add only ${answersAllowed} Answers in ${plan_type.toUpperCase()} Plan. Upgrade your plan to add answers.`;
  const [timeoutInSecond, setTimeoutInSecond] = useState(60);
  const [currentStep, setCurrentStep] = useState(0);
  const [crawlerParams, setCrawlerParams] = useState('start');
  const [pages, setPages] = useState(null);
  // const [paginateURLList, setPaginatedURLList] = useState([]);
  const [fileType, setFileType] = useState('file');
  const [actionPercentage, setActionPercentage] = useState(0);
  const [activeActionLabel, setActiveActionLabel] = useState(null);
  const [websiteFormState, setWebsiteFormState] = useState(
    DEFAULT_WEBSITE_FORM_STATE_VALUE
  );
  const [version, setVersion] = useState(true);
  const [newBotCreated, setNewBotCreated] = useState(null);

  const integrationName =
    faker.word.sample().charAt(0).toUpperCase() + faker.word.sample().slice(1);
  const defaultIntegrationPayload = {
    type: 'wordpress',
    identifier: integrationName,
    settings: {
      ...INITIAL_BOT_INTEGRATION_DATA.settings,
      botTitle: integrationName,
      label: `Ask ${integrationName}`,
      componentTitle: integrationName,
    },
  };

  window.onmessage = function (e) {
    const targetElement = e.data;
    if (targetElement.type == 'iframe-scraper-target') {
      setWebsiteFormState(prevState => {
        return {
          ...prevState,
          targetElement: {
            ...prevState.targetElement,
            draft: targetElement.target,
          },
        };
      });
    }
  };

  const selectedTemplateDetails = useMemo(
    () =>
      SCAN_OPTIONS?.find(
        option => option.value === websiteFormState?.selectedTemplate
      ),
    [websiteFormState?.selectedTemplate]
  );

  const handelWebsiteFormStateChanges = (newValue, key) => {
    setWebsiteFormState(prevState => {
      return { ...prevState, [key]: newValue };
    });
  };

  const parsedUrlFilter = () => {
    const extractedURL = new URL(websiteFormState?.urlList[0]?.url);

    switch (crawlerParams) {
      case 'exactString':
        return websiteFormState?.urlList[0].url + '$';
      case 'origin':
        return extractedURL.origin;
      case 'originPath':
        return extractedURL.origin + extractedURL.pathname;
      default:
        return websiteFormState?.urlList[0].url;
    }
  };

  const verifyQueryParams = url => {
    const extractedURL = new URL(url);
    const filteredUrl = webSocket?.scannedTableDataSource?.filter(
      urlOptions => urlOptions.url === url
    )[0];

    switch (filteredUrl?.filterMethod) {
      case 'exactString':
        return url + '$';
      case 'origin':
        return extractedURL.origin;
      case 'originPath':
        return extractedURL.origin + extractedURL.pathname;
      default:
        return url;
    }
  };

  const renderImportedUrlParams = () => {
    let parsedUrl =
      selectionState?.urlSelected?.length > 0
        ? selectionState?.urlSelected
        : websiteFormState?.urlList?.map(list => list.url);
    let crawler = null;

    // Uncomment: If you want to include the url inputted in the field when filtering
    // const crawlerParams = selectedTemplateDetails?.crawlerParams
    //   ? '^' + parsedUrlFilter()
    //   : null;

    const verifiedURLList = selectionState?.urlSelected?.map(url =>
      verifyQueryParams(url)
    );
    const scrappedUrl = verifiedURLList
      ?.map(url => '^' + url)
      ?.filter(urlList => urlList !== null);

    const getters = {
      method: websiteFormState?.targetElement ? 'selector' : 'default',
      expression: websiteFormState?.targetElement?.final,
    };

    if (websiteFormState?.selectedTemplate === 'pagination') {
      const generatedURLs = handleGeneratePaginatedUrl();
      parsedUrl = generatedURLs?.map((url, key) => {
        return {
          key,
          url,
        };
      });
    }

    if (selectedTemplateDetails?.crawlerParams === null) {
      setModalBtnLoading(true);
    }

    crawler =
      selectedTemplateDetails?.crawlerParams === null
        ? null
        : {
            filters: scrappedUrl.length > 0 ? scrappedUrl : null,
            depth: 1,
            selector: websiteFormState?.targetElement
              ? websiteFormState?.targetElement?.final + ' a[href]'
              : null,
          };

    if (!crawler?.selector) {
      delete crawler?.selector;
    }

    if (!getters.expression) {
      delete getters.expression;
    }

    return parsedUrl?.map(urlDetails => {
      const params = {
        goto: {
          url: urlDetails,
          wait_until: websiteFormState?.selectedUploadingMethod,
          timeout: timeoutInSecond * 1000,
        },
        getters: [getters],
        crawler,
      };

      if (!params.crawler || selectedTemplateDetails?.value !== 'traversing') {
        delete params.crawler;
      } else if (!params.crawler?.filters) {
        delete params.crawler.filters;
      } else if (!params.crawler?.webSocketChannel) {
        delete params.crawler?.webSocketChannel;
      }

      if (!params.method) {
        delete params.method;
        delete params.targetElement;
      }

      return params;
    });
  };

  const syncBot = async (newBot, filesResponse) => {
    setActionPercentage(Math.round((3 / 4) * 100));
    setActiveActionLabel('Syncing Bot...');

    const res = await apiService.syncBot(sentinel, newBot.jid, token);
    if (!res.data.success) {
      throw new Error(GET_DATA_ERROR);
    }

    createIntegration(newBot, filesResponse);
  };

  const handleStopScrapping = async () => {
    try {
      const response = await apiService.stopScrapping(
        webSocket?.triggerID,
        token,
        newBotCreated?.jid,
        sentinel
      );

      if (response?.data?.success) {
        dispatch({
          type: WS_STOP_SCRAPER,
        });
        message.error(`Fetching stopped.`);
      }
    } catch (error) {
      setModalBtnLoading(false);
      return message.error(
        error.message || `Something went wrong. Stop fetching failed.`
      );
    }
  };

  const handleAddFile = async newBot => {
    let pages;

    try {
      dispatch({
        type: WS_UPDATE_ACTION,
        payload: {
          action: 'add_file',
        },
      });

      if (answersAllowed <= 0) {
        return message.error(limitErrorMsg);
      }

      if (fileType === 'website') {
        if (selectedTemplateDetails?.crawlerParams === null) {
          setModalBtnLoading(false);
          setCurrentStep(currentStep + 1);
        }
        pages = renderImportedUrlParams();
      }
      const payload = {
        file: fileUploaded,
        ver: version ? 'final' : 'draft',
        fileType: fileType === 'file' ? fileType : 'url',
        pages,
        uploadingMethod: websiteFormState?.selectedUploadingMethod,
        timeoutInSecond,
        webSocketChannel:
          fileType === 'website'
            ? webSocket?.channel
              ? webSocket?.channel
              : null
            : null,
      };

      if (!payload.file) {
        delete payload.file;
      } else if (!payload.pages) {
        delete payload.pages;
      }

      setActionPercentage(Math.round((2 / 4) * 100));
      setActiveActionLabel(
        fileType === 'website' ? 'Importing website...' : 'Adding File...'
      );
      const response = await apiService.addFile(
        payload,
        token,
        newBot?.jid,
        sentinel
      );
      if (response?.data?.is_queued !== true) {
        const filesResponse = response?.data?.report[0]?.success;
        setTimeout(() => {
          setFileUploaded(null);
          syncBot(newBot, filesResponse);
        }, 2000);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return message.error(error.message || 'Operation aborted by the user.');
      } else {
        return message.error(
          error.message || `Something went wrong. File upload failed.`
        );
      }
    }
  };

  const renderCrawlerParams = () => {
    const parsedUrl = parsedUrlFilter();
    const extractedURL = new URL(websiteFormState?.urlList[0]?.url);
    const params =
      websiteFormState?.selectedScanningMethod === 'all'
        ? {
            filters: ['^' + parsedUrl],
            depth: 1,
            selector: websiteFormState?.targetElement
              ? websiteFormState?.targetElement.final + ' a[href]'
              : null,
          }
        : {
            filters: ['^' + extractedURL],
            depth: 1,
            selector: websiteFormState?.targetElement
              ? websiteFormState?.targetElement.final + ' a[href]'
              : null,
          };

    if (!params.selector) {
      delete params.selector;
    }
    return params;
  };

  const handleScrapedUrl = async jid => {
    setModalBtnLoading(true);
    let urlDataSource = [];
    const triggerID = uuid();
    dispatch({
      type: WS_UPDATE_ACTION,
      payload: {
        action: 'scrape',
        triggerID,
      },
    });

    setActionPercentage(Math.round((2 / 4) * 100));
    setActiveActionLabel('Fetching...');

    try {
      const scrapedParams = {
        url: websiteFormState?.urlList[0]?.url,
        uploadingMethod: websiteFormState?.selectedUploadingMethod,
        timeoutInSecond,
        crawler: renderCrawlerParams(),
        webSocketChannel: webSocket?.channel,
        method: websiteFormState?.targetElement ? 'selector' : null,
        expression: websiteFormState?.targetElement?.final,
        triggerID,
      };
      if (!scrapedParams.crawler) {
        delete scrapedParams.crawler;
      } else if (!scrapedParams.crawler?.filters) {
        delete scrapedParams.crawler?.filters;
      } else if (!scrapedParams.crawler?.webSocketChannel) {
        delete scrapedParams.crawler?.webSocketChannel;
      }

      if (!scrapedParams.method) {
        delete scrapedParams.method;
        delete scrapedParams.targetElement;
      }

      const response = await apiService.scrappedUrl(
        scrapedParams,
        token,
        jid,
        sentinel
      );

      const scannedResponse = response.data?.report[0]?.scanned
        ? extractScannedObject(response.data?.report[0]?.scanned)
        : [];

      if (scannedResponse?.length > 1) {
        dispatch({
          type: UPDATE_WS_SCANNED_DATA_SOURCE,
          payload: [
            ...extractWebsiteScanList(scannedResponse),
            {
              key: urlDataSource.length,
              url: websiteFormState?.urlList[0]?.url,
            },
          ],
        });
      }

      setCurrentStep(currentStep + 1);
      setModalBtnLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        return message.error(error.message || 'Operation aborted by the user.');
      } else {
        setModalBtnLoading(false);
        return message.error(
          error.message || `Something went wrong. File upload failed.`
        );
      }
    }
  };

  const handleUploadFile = file => {
    setFileUploaded(file);
    return false;
  };

  const handleRemoveFile = () => {
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setParsedFileAnswers([]);
  };

  const resetStates = () => {
    setModalBtnLoading(false);
    setPages(null);
    setWebsiteFormState(DEFAULT_WEBSITE_FORM_STATE_VALUE);

    dispatch({
      type: RESET_WEBSITE_SELECTED_PAGES,
    });
  };

  const resetModal = useCallback(() => {
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setFinalAnswerWarnMsg(null);
    setParsedFileAnswers([]);
  }, [setParsedFileAnswers]);

  const handleCloseUploadModal = useCallback(() => {
    onClose();
  }, [onClose, resetModal]);

  const handleCancelImport = useCallback(() => {
    controller.abort();
    resetStates();
    dispatch({
      type: RESET_WS_SCANNER,
    });
    return handleCloseUploadModal(true);
  }, [controller, handleCloseUploadModal]);

  const handleBackward = () => {
    setCurrentStep(0);
    resetStates();
    dispatch({
      type: RESET_WS_SCANNER,
    });
  };

  const parsePageRange = pageRange => {
    const [start, end] = pageRange?.split('-').map(Number);
    return Array.from({ length: end - start + 1 }, (_, i) =>
      websiteFormState?.urlList[0]?.url.replace('{pages}', start + i)
    );
  };

  const handleGeneratePaginatedUrl = () => {
    if (websiteFormState?.urlList[0]?.url) {
      const pagesList = pages?.split(',').map(part => part.trim());
      let renderPageList = [];
      for (const page of pagesList) {
        if (page.includes('-')) {
          renderPageList = renderPageList.concat(parsePageRange(page));
        } else {
          renderPageList.push(
            websiteFormState?.urlList[0]?.url?.replace('{pages}', page)
          );
        }
      }

      // setPaginatedURLList(renderPageList);
      return renderPageList;
    }
  };

  const handleCloseElementSelecting = () => {
    handelWebsiteFormStateChanges(false, 'isTargetElementOpen');
  };

  const handleSaveElement = () => {
    setWebsiteFormState(prevState => {
      return {
        ...prevState,
        isTargetElementOpen: false,
        targetElement: {
          ...prevState.targetElement,
          final: prevState?.targetElement?.draft,
        },
      };
    });
  };

  const handleSubmit = async () => {
    setActiveActionLabel('Adding Bot...');
    setActionPercentage(Math.round((1 / 4) * 100));
    const botDetails = {
      name: `${
        faker.word.sample().charAt(0).toUpperCase() +
        faker.word.sample().slice(1)
      } Bot`,
      desc: 'This bot is automatically created.',
      mode: 'openai',
    };
    const newBot = await createBot(sentinel, graph, botDetails, token);
    fileType === 'file'
      ? handleAddFile(newBot)
      : websiteFormState?.isTargetElementOpen === true
      ? handleSaveElement()
      : currentStep === 0
      ? selectedTemplateDetails?.crawlerParams === null
        ? handleAddFile(newBot)
        : handleScrapedUrl(newBot.jid)
      : handleAddFile(newBot);

    setNewBotCreated(newBot);
  };

  const azureUpload = async (
    integrationData,
    integrationID,
    integrationName,
    jid
  ) => {
    const folderPath = stripUUID(jid);
    const integrationConfigURL = `${folderPath}/${stripUUID(
      integrationID
    )}/config.json`;
    const configFileType = 'application/json';
    const configBlob = new Blob(
      [
        JSON.stringify({
          ...integrationData,
          integration: {
            name: integrationName,
            id: integrationID,
          },
        }),
      ],
      {
        type: configFileType,
      }
    );
    const blobOptions = {
      blobHTTPHeaders: {
        blobContentType: configBlob.type,
        blobCacheControl: 'public, max-age=0',
      },
    };
    const blobServiceClient = new BlobServiceClient(
      `${AZURE_STORAGE_URI}?${SAS_TOKEN}`
    );
    const blockConfigBlob = blobServiceClient
      .getContainerClient(INTEGRATIONS_CONTAINER_NAME)
      .getBlockBlobClient(integrationConfigURL);

    // finally uploading the config file
    await blockConfigBlob.upload(configBlob, configBlob.size, blobOptions);
  };

  const spawnCreate = async () => {
    try {
      let publicApiRes = null;
      let publicInfoRes = null;

      if (!pubAskedQuestion) {
        publicApiRes = await apiService.spawnCreate('zsb_public_api', token);
      }

      if (!pubInfo) {
        publicInfoRes = await apiService.spawnCreate('zsb_public_info', token);
      }
      const pkRes = await apiService.getPublicKey(token);
      const payload = {
        publicKey: pkRes.data.anyone,
        pubAskedQuestion: publicApiRes.data.jid,
        pubInfo: publicInfoRes.data.jid,
      };
      dispatch({
        type: SPAWN_CREATE,
        payload,
      });
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  const createIntegration = async (newBot, filesResponse) => {
    setActiveActionLabel('Creating Integration...');
    const res = await apiService.createIntegration(
      sentinel,
      withPrefixUUID(newBot.jid || pathname),
      defaultIntegrationPayload,
      'wordpress',
      token
    );

    if (!pubAskedQuestion || !pubInfo) {
      try {
        await spawnCreate();
      } catch (error) {
        return message.error(error.message || DEFAULT_ERROR_MESSAGE);
      }
    }

    await azureUpload(
      res.data.report[0].context.settingsobj,
      res.data.report[0].jid,
      res.data.report[0].context.identifier,
      newBot.jid
    );
    const payload = {
      identifier: res.data.report[0].context.identifier,
      type: res.data.report[0].context.int_type,
      path: res.data.report[0].context.path,
      id: res.data.report[0].jid,
      settings: res.data.report[0].context.settingsobj,
    };

    localStorage.setItem(
      INTEGRATION_DATA,
      JSON.stringify({
        ...defaultIntegrationPayload,
        ...payload,
        settings: {
          ...payload.settings,
          botTitle: integrationName,
          componentTitle: integrationName,
        },
      })
    );
    setActionPercentage(Math.round((4 / 4) * 100));
    setActiveActionLabel(
      'Account successfully setup. Redirecting to integration page...'
    );
    renderIntegrationPage(newBot, payload, filesResponse);
  };

  const renderIntegrationPage = async (newBot, integration, file) => {
    const strippedJID = stripUUID(newBot.jid);
    const botInformation = await fetchBotDetails(sentinel, newBot.jid, token);
    await dispatch({
      type: WORD_PRESS_SUCCESS,
      payload: { integration, file, botInformation },
    });

    history.push(
      `${ROUTES.BOT_DETAILS}/${stripUUID(strippedJID)}${
        ROUTES.BOT_INTEGRATION
      }?platform=wordpress`
    );

    setFileUploaded(null);
    setCurrentStep(0);
    setTimeoutInSecond(60);
    resetStates();
    handleCloseUploadModal(true, true);
  };

  const handleBackToPagesList = () => {
    handelWebsiteFormStateChanges(
      INITIAL_SELECTED_PAGE_LIST,
      'selectedPageList'
    );
  };

  const getSelectedRowKeysFromUrls = (urls, data) => {
    return data?.filter(item => urls.includes(item.url)).map(item => item.key);
  };

  const getSelectedRowKeysFromCategories = (categories, data) => {
    return data
      ?.filter(item => categories.includes(item.name))
      .map(item => item.key);
  };

  useEffect(() => {
    const urlSelectedRowKeys = getSelectedRowKeysFromUrls(
      selectionState.urlSelected,
      webSocket?.scannedTableDataSource
    );
    const categorySelectedRowKeys = getSelectedRowKeysFromCategories(
      selectionState.categorySelected,
      webSocket?.categorizedTableDataSource
    );

    dispatch({
      type: SET_WEBSITE_SELECTED_PAGES,
      payload: {
        urlSelectedRowKeys,
        categorySelectedRowKeys,
      },
    });
  }, []);

  useEffect(() => {
    if (!webSocket?.sending && webSocket?.scan?.length > 0) {
      setActionPercentage(Math.round((2 / 4) * 100));
      setActiveActionLabel('Verifying...');
      const scannedList = extractWebsiteScanList(webSocket.scan);
      const extractedUrls = categorizedWebsitePages(scannedList);
      const websiteSelectedPages = {
        urlSelectedRowKeys: scannedList?.map(row => {
          return row.key;
        }),
        urlSelected: scannedList?.map(row => {
          return row.url;
        }),
        categorySelected: extractedUrls?.map(row => {
          return row.name;
        }),
        categorySelectedRowKeys: extractedUrls?.map(row => {
          return row.key;
        }),
      };

      dispatch({
        type: UPDATE_WS_SCANNED_DATA_SOURCE,
        payload: { scannedList, websiteSelectedPages },
      });
    }
  }, [webSocket?.sending, webSocket?.scan]);

  useEffect(() => {
    if (webSocket?.fileAdded?.length > 0) {
      setFileUploaded(null);
      syncBot(newBotCreated, webSocket?.websiteFile[0]);
    }
  }, [webSocket?.fileAdded]);

  return {
    importErrorMsg,
    importWarnMsg,
    finalAnswerWarnMsg,
    modalBtnLoading,
    handleCancelImport,
    handleAddFile,
    handleUploadFile,
    handleRemoveFile,
    currentStep,
    handleBackward,
    crawlerParams,
    setCrawlerParams,
    handleCloseElementSelecting,
    fileType,
    setFileType,
    handleSubmit,
    actionPercentage,
    activeActionLabel,
    setModalBtnLoading,
    handelWebsiteFormStateChanges,
    setWebsiteFormState,
    websiteFormState,
    setVersion,
    version,
    webSocket,
    handleStopScrapping,
    handleBackToPagesList,
    newBotCreated,
  };
};

export default useWordPressIntegrationModal;
