import {
  CodeSandboxOutlined,
  FacebookOutlined,
  GlobalOutlined,
  MobileOutlined,
  PhoneOutlined,
  ApiOutlined,
} from '@ant-design/icons';
import { cssVariables } from 'styles/root';
import { Avatar } from 'antd';

import WordpressIcon from 'assets/images/wordpress-icon.svg';

export const INTEGRATION_PLATFORMS = {
  web: {
    label: 'Web',
    icon: <GlobalOutlined />,
    desc: 'website',
    value: 'web',
    buttonLabel: 'Connect',
    btnPlan: 'basic',
  },
  wordpress: {
    label: 'Wordpress',
    icon: <Avatar src={WordpressIcon} size={48} />,
    desc: 'wordpress',
    value: 'wordpress',
    buttonLabel: 'Connect',
    btnPlan: 'free',
  },
  mobile: {
    label: 'Mobile',
    icon: <MobileOutlined />,
    desc: 'mobile',
    value: 'mobile',
    buttonLabel: 'Connect',
    btnPlan: 'basic',
  },
  'fb-messenger': {
    label: 'Facebook Messenger',
    icon: <FacebookOutlined />,
    desc: 'facebook-messenger',
    value: 'facebook-messenger',
    buttonLabel: 'Connect',
    btnPlan: 'advanced',
  },
  viber: {
    label: 'Viber',
    icon: <PhoneOutlined />,
    desc: 'viber',
    value: 'viber',
    buttonLabel: 'Connect',
    btnPlan: 'advanced',
  },
  api: {
    label: 'API',
    icon: <ApiOutlined style={{ color: cssVariables.primaryBlue }} />,
    desc: 'API',
    value: 'api',
    buttonLabel: 'Connect',
    btnPlan: 'basic',
  },
  sandbox: {
    label: 'Sandbox Environment',
    icon: <CodeSandboxOutlined style={{ color: cssVariables.primaryBlue }} />,
    desc: 'Share bot with your team on a demo website.',
    value: 'demo-web',
    buttonLabel: 'Preview',
    btnPlan: 'free',
  },
};

export const INTEGRATION_PLATFORMS_AS_LIST = Object.entries(
  INTEGRATION_PLATFORMS
).map(([key, value]) => ({
  key,
  ...value,
}));
