import PropTypes from 'prop-types';
import useImportUrlForm from './hooks';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Steps } from 'antd';
import TextArea from 'components/TextArea';

const { Step } = Steps;

const ImportUrlForm = ({
  setModalBtnLoading,
  currentStep,
  crawlerParams,
  setCrawlerParams,
  handelWebsiteFormStateChanges,
  websiteFormState,
  setWebsiteFormState,
  disableUrlFormFields,
  isWordPressModal,
}) => {
  const { uploadURLSteps, handleTargetElementChanges, scrapePreviewSrc } =
    useImportUrlForm({
      setModalBtnLoading,
      crawlerParams,
      setCrawlerParams,
      websiteFormState,
      handelWebsiteFormStateChanges,
      setWebsiteFormState,
      disableUrlFormFields,
      isWordPressModal,
    });
  return (
    <>
      {websiteFormState?.isTargetElementOpen === false ? (
        <>
          <Steps current={currentStep}>
            {uploadURLSteps.map((step, idx) => (
              <Step key={idx} title={step.title} />
            ))}
          </Steps>
          <div className="steps-content" style={{ margin: '10px 0' }}>
            {uploadURLSteps[currentStep]?.content}
          </div>
        </>
      ) : (
        <>
          <Form style={{ width: '100%' }}>
            <Form.Item
              label="Target Element"
              tooltip={{
                title: 'Should be a valid queryselector.',
                icon: <QuestionCircleOutlined />,
              }}
            >
              <TextArea
                onChange={handleTargetElementChanges}
                value={websiteFormState?.targetElement?.draft}
                autoSize
                noPaddingTop={true}
                disableSpan={true}
              />
            </Form.Item>
          </Form>

          {scrapePreviewSrc && (
            <iframe
              src={scrapePreviewSrc}
              width="100%"
              height="1400vh"
              sandbox="allow-scripts allow-same-origin"
              id="iframe"
            />
          )}
        </>
      )}
    </>
  );
};

ImportUrlForm.prototypes = {
  setModalBtnLoading: PropTypes.func,
  setUrlList: PropTypes.func,
  currentStep: PropTypes.number,
  urlList: PropTypes.array,
  crawlerParams: PropTypes.string,
  setCrawlerParams: PropTypes.func,
  handelWebsiteFormStateChanges: PropTypes.func,
  websiteFormState: PropTypes.obj,
  setWebsiteFormState: PropTypes.func,
  disableUrlFormFields: PropTypes.bool,
  isWordPressModal: PropTypes.bool,
};

export default ImportUrlForm;
