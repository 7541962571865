import React from 'react';
import PropTypes from 'prop-types';
import Prism from 'prismjs';
import styled from 'styled-components';

import 'styles/prism-one-dark.css';
import 'prismjs/components/prism-json';

const StyledPreWrapper = styled.pre`
  width: 100%;
`;

const CodeBlock = ({ codeContent, ...rest }) => {
  const highlightedJson =
    typeof codeContent === 'object'
      ? Prism.highlight(
          JSON.stringify(codeContent, null, 2),
          Prism.languages.json,
          'javascript'
        )
      : codeContent;

  return (
    <StyledPreWrapper
      dangerouslySetInnerHTML={{ __html: highlightedJson }}
      className="language-json"
      style={{
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
        ...rest.style,
      }}
    />
  );
};

CodeBlock.propTypes = {
  codeContent: PropTypes.any.isRequired,
};

export default CodeBlock;
