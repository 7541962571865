import React from 'react';
import PropTypes from 'prop-types';
import EditCategoryList from 'components/EditCategoryList';
import BackButton from 'components/BackButton';
import CategoryForm from '../../CategoryForm';
import { StyledCategoryModal } from './StyledComponents';
import useViewCategories from './hooks';
import Alert from 'components/Alert';

const ViewCategories = ({ visible, handleSave, deleteCategory }) => {
  const {
    handleSubmit,
    allCategories,
    isEditingCategory,
    selectedCategory,
    handleBackToCategories,
    handleCloseModal,
    isTotalAnswersSelectedFromCategoryTemplatesReachMaxAnswerCount,
    loading,
  } = useViewCategories();

  const isSubmitDisabled =
    (selectedCategory.action !== 'view-categories' &&
      !selectedCategory.isTemplate &&
      !selectedCategory.name) ||
    (selectedCategory.isTemplate && !selectedCategory.templates.length) ||
    isTotalAnswersSelectedFromCategoryTemplatesReachMaxAnswerCount;

  return (
    <StyledCategoryModal
      visible={visible}
      title={
        selectedCategory.jid &&
        !selectedCategory.action.includes('view-categories') ? (
          <BackButton
            prevPageTitle={'Categories'}
            onClick={handleBackToCategories}
          />
        ) : (
          <h3>
            {selectedCategory.action !== 'add'
              ? 'Edit/Delete Category'
              : 'New Category'}
          </h3>
        )
      }
      onOk={isEditingCategory ? handleSubmit : handleCloseModal}
      okButtonProps={{
        disabled: isSubmitDisabled,
        loading,
      }}
      onCancel={handleCloseModal}
      okText={selectedCategory.action !== 'view-categories' ? 'Save' : 'Close'}
      spinning={loading}
      cancelText={'Close'}
      destroyOnClose
      isFormModal
      cancelButtonProps={{
        style: { display: !isEditingCategory ? 'none' : 'block' },
        disabled: loading,
      }}
    >
      {selectedCategory.action !== 'view-categories' ? (
        <CategoryForm onSave={handleSubmit} />
      ) : allCategories.length ? (
        allCategories.map((category, index) => (
          <EditCategoryList
            category={category}
            handleSave={handleSave}
            key={index}
          />
        ))
      ) : (
        <span>{'No categories found'}</span>
      )}
      {isTotalAnswersSelectedFromCategoryTemplatesReachMaxAnswerCount &&
      selectedCategory?.templates?.length ? (
        <Alert
          type="warning"
          message={
            'Maximum number of answers reached. Try removing a category template to import'
          }
        />
      ) : null}
    </StyledCategoryModal>
  );
};

ViewCategories.propTypes = {
  visible: PropTypes.bool,
  handleSave: PropTypes.func,
};

export default ViewCategories;
