import styled from 'styled-components';
import { Layout as AntdLayout } from 'antd';
import { cssVariables } from 'styles/root';
import { StyledFlexColumn } from 'styles/GenericStyledComponents';

const { Sider, Content } = AntdLayout;

export const StyledDoubleSidebar = styled(AntdLayout)`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  & .app-container,
  .ant-layout,
  .ant-layout-content {
    max-height: ${props => (props?.isAdmin ? `none` : `100vh`)};
  }

  @keyframes scale_animate {
    0% {
      transform: scale(1);
    }

    20% {
      transform: scale(0.8);
    }

    70% {
      transform: scale(1.6);
    }

    100% {
      transform: scale(1);
    }
  }
`;

export const StyledMainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const StyledTopIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 5px;
`;

export const StyledBottomIcons = styled(StyledFlexColumn)`
  margin-bottom: 10px;
`;

export const StyledIconWithLabel = styled.div`
  margin: 10px auto;
  text-align: center;
`;

export const StyledSmallRightNavIconLabel = styled.div`
  font-size: 11px;
`;

export const StyledExpandArrow = styled.div`
  font-size: 10px;
`;

export const StyledPopoverTitle = styled.div`
  font-weight: ${cssVariables.font.bold};
  font-size: 16px;
  margin-bottom: 10px;
`;

export const StyledPopoverContents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const StyledPopoverTextContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

export const StyledHeaderLogo = styled.img`
  height: 40px;
  margin-top: 2%;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSider = styled(Sider)`
  background: #fff;
  flex: none !important;
  min-width: auto !important;
  width: auto !important;
`;

export const StyledLogoSider = styled(Sider)`
  background: #202225;
  padding: 1% 2%;
`;

export const StyledHeaderContent = styled(Content)`
  background: #202225;
  padding: 1% 0;
`;
