import React from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import { Typography, message } from 'antd';
import Modal from 'components/Modals/GenericModal';
import useApiKeyModal from './hooks';
import {
  StyledApiKey,
  StyledCodeBlock,
  StyledCodeButton,
} from './ApiKeyModal.styles';

const { Paragraph } = Typography;

const ApiKeyModal = () => {
  const { apiGatewayConfig, isApiKeyModalOpen, handleCloseModal } =
    useApiKeyModal();

  const RenderUserSettingsApiKey = () => (
    <>
      <StyledApiKey>
        Here's the generated API Key.
        <CopyToClipboard text={apiGatewayConfig?.auth}>
          <StyledCodeButton
            className="secondary"
            value="Copy"
            onClick={() => message.info('Copied to clipboard.')}
          />
        </CopyToClipboard>
      </StyledApiKey>
      <StyledCodeBlock>
        <Paragraph>
          <pre>
            <code>{apiGatewayConfig?.auth}</code>
          </pre>
        </Paragraph>
      </StyledCodeBlock>
    </>
  );

  return (
    <Modal
      visible={isApiKeyModalOpen}
      title="API Key"
      onCancel={handleCloseModal}
      footer={null}
    >
      <RenderUserSettingsApiKey />
    </Modal>
  );
};

export default ApiKeyModal;
