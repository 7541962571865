import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import { cssVariables } from 'styles/root';

export const FlexColWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin: 5px;
  }

  > :first-child {
    margin-right: 0;
  }

  > :last-child {
    margin: 0;
  }

  .variant-selector {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
  }
`;

export const FlexRowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  :first-child {
    h6 {
      width: 10%;
      font-size: 11px;
    }
  }

  > * + * {
    margin: 5px;
  }

  > :first-child {
    margin-right: 5px;
  }

  > :last-child {
    margin-right: 0;
  }

  .anticon {
    cursor: pointer;
  }

  .ant-select,
  .ant-selector {
    cursor: auto;
  }
`;

export const FlexRowSpaceBetweenWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :first-child {
    h6 {
      width: 10%;
      font-size: 11px;
    }
  }

  > * + * {
    margin: 5px;
  }

  > :first-child {
    margin-right: 5px;
  }

  > :last-child {
    margin-right: 0;
  }

  .anticon {
    cursor: pointer;
  }

  .anticon.anticon-edit {
    padding: 5px;
    &:hover {
      background-color: ${cssVariables.blue1};
      border-radius: 50%;
    }
  }
  .anticon.anticon-delete {
    padding: 5px;
    &:hover {
      background-color: ${cssVariables.errorBgColor};
      border-radius: 50%;
    }
  }

  .ant-select,
  .ant-selector {
    cursor: auto;
  }
`;

export const StyledDeleteIcon = styled(DeleteOutlined)`
  border-radius: 5px;
  padding: 5px;
  color: ${cssVariables.errorText};

  :hover {
    background-color: ${cssVariables.errorBorder};
  }
`;
