import React, { useCallback, useContext, useMemo } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Context } from 'store/store';
import {
  CLOSE_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL,
  SET_INTEGRATION_ACTIVE_PANEL,
} from 'store/action';
import useSelector from 'store/useSelector';
import {
  currentIntegrationActivePanelSelector,
  integrationPanelPermitSelector,
  isPreCustomizationEditModalOpenSelector,
} from 'selectors/bot/integration';

const { confirm } = Modal;

const PreCustomizationConfirmEditModal = () => {
  const [, dispatch] = useContext(Context);
  const permit = useSelector(integrationPanelPermitSelector);
  const activePanel = useSelector(currentIntegrationActivePanelSelector);
  const isPreCustomizationEditModalOpen = useSelector(
    isPreCustomizationEditModalOpenSelector
  );

  const renderConfirmModal = useCallback(() => {
    confirm({
      title: 'Bot Personalization',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to personalize this bot?',
      okText: 'Proceed',
      cancelText: 'Skip',
      open: isPreCustomizationEditModalOpen,
      onOk() {
        dispatch({
          type: CLOSE_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL,
        });
        dispatch({
          type: SET_INTEGRATION_ACTIVE_PANEL,
          payload: {
            panel: activePanel + 1,
            permit: permit === activePanel ? permit + 1 : permit,
          },
        });
      },
      onCancel() {
        dispatch({
          type: CLOSE_PRE_CUSTOMIZATION_EDIT_WIDGET_MODAL,
        });
        dispatch({
          type: SET_INTEGRATION_ACTIVE_PANEL,
          payload: {
            panel: activePanel + 2,
            permit: permit === activePanel ? permit + 2 : permit,
          },
        });
      },
    });
  }, [isPreCustomizationEditModalOpen, dispatch]);

  return <>{renderConfirmModal()}</>;
};

export default PreCustomizationConfirmEditModal;
