import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ImpersonationBannerContainer = styled.div`
  width: 100%;
  height: 66px;
  display: block;
  background-color: #1667e7;
  padding: 12px 20px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`;

export const StyledLink = styled(Link)`
  color: #ffffff;
`;
