import styled from 'styled-components';
import { Col, Form, Modal, Row } from 'antd';
import Button from 'components/Button';
import { cssVariables } from 'styles/root';

export const PageHeader = styled.h3`
  font-family: Helvetica;
  font-style: normal;
  font-weight: ${cssVariables.font.bold};
  font-size: 32px;
  line-height: 37px;
`;

export const StyledPricing = styled(Row)``;

export const PlanCard = styled.div`
  border-radius: 8px;
  border: ${props =>
    props.isCurrentPlan
      ? `1px solid ${cssVariables.primaryCyan}`
      : `1px solid ${cssVariables.gray1}`};
  box-sizing: border-box;
  max-width: 350px;
  margin: 0 auto;
`;

export const PlanHeader = styled.h4`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  padding: 16px 24px;
  padding-bottom: ${props => (props.isCurrentPlan ? '27px' : '20px')};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: ${cssVariables.font.bold};
  font-family: Helvetica;
  background-color: ${props => props.color};
  border-radius: 8px 8px 0 0;
  color: #ffffff;
`;

export const PlanCardContent = styled.div`
  font-family: Helvetica;
  display: flex;
  flex-direction: column;
  padding: 32px 20px;
  padding-top: 0;
  text-align: left;
  color: #66696f;
`;

export const Features = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    padding-top: 8px;
    font-size: 12px;

    > li span {
      margin-right: 20px;
    }
  }

  .anticon-check {
    font-weight: ${props =>
      props.isCurrentPlan ? cssVariables.font.bold : cssVariables.font.normal};
    color: ${props =>
      props.isCurrentPlan ? cssVariables.primaryCyan : 'inherit'};
    font-size: ${props => (props.isCurrentPlan ? '14px' : 'inherit')};
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
  background: ${props => (props.isSelected ? '#6D757D' : '#ffffff')} !important;
  border: 1.5px solid #eff3f4;
  font-size: 14px;
  font-weight: ${cssVariables.font.bold};
  color: ${props => (props.isSelected ? '#ffffff' : '#1667e7')} !important;
`;

export const BottomText = styled.span`
  margin-top: 10px;
  font-size: 10px;
  font-weight: ${cssVariables.font.bold};
  text-transform: uppercase;
  text-align: center;
`;

export const ContactUsSection = styled(Row)`
  width: 100%;
  padding: 5% 6%;
  background: #051e43;
  box-sizing: border-box;
  border-radius: 8px;
  bottom: 0;
`;

export const ContactDetails = styled(Col)`
  > h5 {
    font-weight: ${cssVariables.font.bold};
    font-size: 2rem;
    line-height: 32px;
    color: ${cssVariables.gray5};
  }
  > p {
    font-size: 1.2rem;
    line-height: 24px;
    color: #e9ecef;
    letter-spacing: -0.02em;
  }
`;

export const RightContent = styled.div``;

export const ContactUsButton = styled(Button)`
  font-size: 14px;
  font-weight: ${cssVariables.font.bold};
  width: 100%;
  background: #ffffff !important;
  color: #1667e7 !important;
`;

export const StyledModal = styled(Modal)`
  font-family: Helvetica;
`;

export const FormWrapper = styled(Form)`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PaymentInformation = styled.div`
  width: 670px;
  margin-right: 24px;
`;

export const PlanDetails = styled.div`
  width: 520px;
  margin-left: 24px;
`;

export const BigText = styled.p`
  color: #40444b;
  font-size: 14px;
  font-weight: ${cssVariables.font.normal};
  line-height: 20px;
  letter-spacing: 0.04em;
`;

export const PaymentInformationHeader = styled.h5`
  color: #000000;
  font-weight: ${cssVariables.font.bold};
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const Divider = styled.div`
  border: 1px solid #d9dadb;
  margin: 24px 0;
`;

export const CurrencySelection = styled.div`
  margin-top: 10px;
  font-weight: ${cssVariables.font.bold};
`;

export const SmallText = styled.p`
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  color: #8c8f93;
  font-size: 13px !important;
  line-height: 24px;
`;

export const LineItems = styled.div`
  display: flex;
  justify-content: space-between;

  > div > span {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;

  > div > span {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const PriceHeading = styled.div`
  display: flex;
  font-size: 48px;
  color: #000000;
  line-height: 55px;

  > span {
    font-size: 15px;
    transform: translateY(-12px);
    opacity: 0.5;
  }
  > p {
    font-size: 14px;
    color: #3d3f42;
    margin-left: 10px;
  }
`;

export const SuccessMessage = styled.div`
  width: 368px;
  margin: 0 auto;

  > div {
    margin-top: 20px;
  }

  > img {
    display: block;
    margin: 0 auto;
  }
  > p {
    font-size: 17px;
    font-weight: ${cssVariables.font.normal};
    margin-top: 24px;
  }

  > ul li,
  > span {
    font-size: 16px;
    line-height: 24px;
    color: #33363c;
  }

  ${StyledButton} {
    background: #1667e7 !important;
    color: #ffffff !important;
  }
`;

export const StyledCardElement = styled.div`
  padding: 12px 8px;
  border: 1px solid #e5e8e9;
  border-radius: 3px;
  width: ${props => (props.width ? props.width : 300)}px;
`;

export const CardElementsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledFormButtons = styled.div`
  display: flex;
  column-gap: 10px;

  button {
    width: 100%;
  }

  &:first-child {
    margin: 0px !important;
  }
`;
