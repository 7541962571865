import { isAnObject } from 'utils/dataTypes';

const extractQuestion = (context, jid, score) => {
  const currentISODate = new Date().toISOString();

  return {
    jid,
    text: context.text,
    type: 'question',
    linkType: context.link_type,
    score,
    lastEdited: context.last_updated_time || currentISODate,
    version: context.question_version,
  };
};

export const extractQuestionData = question => {
  if (Array.isArray(question) && question.length) {
    return question.map(item => {
      if (item.question) {
        const { jid, context } = item.question;
        return extractQuestion(context, jid, item.score);
      } else {
        return extractQuestion(item.context, item.jid, item.score);
      }
    });
  } else if (isAnObject(question)) {
    const { jid, context } = question;
    return extractQuestion(context, jid, question.score);
  }
};

export const pushCurrentQuestionAsTopQuestion = (
  questions,
  currentQuestionId
) => {
  const filteredQuestions = questions
    // extract only the question entity
    .map(item => item.question)
    .filter(item => item && item.jid !== currentQuestionId)
    .filter(Boolean);

  const currentAnswer = questions
    .map(item => item.question)
    .find(item => item.jid === currentQuestionId);
  // postion hard link response to `questions[0]`
  // to be fetch as the `currentQuestion` on ResponsePickerModal
  return [currentAnswer, ...filteredQuestions];
};
