import { createSelector } from 'reselect';

export const messengerApiSelector = state => state.messengerApi;

export const messengerLatestVersionSelector = createSelector(
  messengerApiSelector,
  messengerApi => messengerApi?.latest
);

export const messengerLatestAPISelector = createSelector(
  messengerApiSelector,
  messengerLatestVersionSelector,
  (apis, latestVersion) => apis[latestVersion]
);
