import React, { useContext } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { Context } from 'store/store';
import { ImpersonationBannerContainer } from './ImpersonationBanner.style';
import { clearImpersonatedUserObject } from 'services/auth.service';
import { apiService } from 'services/api.service';
import { STOP_IMPERSONATING_USER, INIT_JAC } from 'store/action';
import ROUTES from 'constants/routes';
import useSelector from 'store/useSelector';
import { impersonatedUserSelector } from 'selectors/admin';
import { getTokenSelector } from 'selectors/user';

export const ImpersonationBanner = () => {
  const [state, dispatch] = useContext(Context);
  const impersonatedUser = useSelector(impersonatedUserSelector);
  const token = useSelector(getTokenSelector);
  const { sentinel } = state;
  const history = useHistory();

  const handleStopImpersonation = async () => {
    const masterUserGraph = localStorage.getItem('masterUserND');

    const planData = await apiService.init(sentinel, masterUserGraph, token);
    if (planData.status === 200 && planData.data.success) {
      const user = await apiService.getUser(token);
      const { email, id, is_activated, is_superuser, name } = user.data;

      await dispatch({
        type: STOP_IMPERSONATING_USER,
      });

      await dispatch({
        type: INIT_JAC,
        payload: {
          plan: planData.data.report[0].context,
          subscription: planData.data?.report[1]?.context || {},
          pubAskedQuestion: '',
          messengerCallback: '',
          user: {
            email,
            id,
            is_activated,
            is_superuser,
            name,
          },
        },
      });

      await clearImpersonatedUserObject();
      history.push(ROUTES.ADMIN_PAGE);
    }
  };

  return (
    <ImpersonationBannerContainer>
      <div>You are impersonating {impersonatedUser?.name || 'User'}</div>
      <Button type="primary" danger onClick={handleStopImpersonation}>
        Stop Impersonating
      </Button>
    </ImpersonationBannerContainer>
  );
};
