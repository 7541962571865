import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { StyledMenu } from './QuestionImportExportMenu.style';

const QuestionImportExportMenu = ({ handleOpenImportModal }) => {
  return (
    <StyledMenu>
      <Menu.Item key="json" onClick={() => handleOpenImportModal('json')}>
        Import .JSON
      </Menu.Item>
    </StyledMenu>
  );
};

QuestionImportExportMenu.propTypes = {
  handleOpenModal: PropTypes.func,
};

export default QuestionImportExportMenu;
