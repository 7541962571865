import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Tooltip, Modal, notification, Menu, Input } from 'antd';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import PropTypes from 'prop-types';
import {
  CloseCircleOutlined,
  FileTextOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { ErrorBoundary } from 'react-error-boundary';
import AddQuestionToTestSuiteModal from 'components/Modals/AddQuestionToTestSuiteModal';

import ConfirmDelete from 'components/Modals/ConfirmDelete';
import ViewCategories from 'components/Modals/ViewCategories';
import ImportAnswerModal from 'components/Modals/ImportAnswerModal';
import LimitationBanner from 'components/LimitationBanner';
import Title from 'components/Title';
import { TEXT_INGEST_DROPDOWN_BUTTON_STEPS } from 'constants/joyride_steps/answer_bank_text_ingest';
import { ANSWER_SORTING_LIST } from './AnswerBank.constant';
import useAnswerBank from './hooks';

import {
  StyledRoot,
  StyledAnswersContainer,
  StyledGuidelines,
  StyledGuidelineHeader,
  StyledGuidelineList,
  StyledTitle,
  StyledFirstLine,
  StyledCustomTooltip,
  StyledToolTipContainer,
  StyledFooterActions,
} from './AnswerBank.styles';
import AskedQuestionModal from './AskedQuestionModal/AskedQuestionModal';
import MoveCategoryModal from './MoveCategoryModal/MoveCategoryModal';
import { GraphModal } from 'pages/Admin/Users/UserDetails/UserDetails.style';
import DiGraph from 'components/DiGraph';
import { SET_ONBOARDING_FLAG } from 'store/action';
import { apiService } from 'services/api.service';
import ExportModal from 'components/Modals/ExportModal';
import ImportFileModal from 'components/Modals/ImportFileModal';
import { BOT_MODES } from 'constants/bot';
import AnswerType from './AnswerTypes';
import AddAnswerModal from 'components/Modals/AddAnswerModal';
import Button from 'components/Button';
import AnswerMenu from './AnswerMenu/AnswerMenu';
import { StyledFlexRowRight } from 'styles/GenericStyledComponents';
import SubMenu from 'antd/lib/menu/SubMenu';
import ImportWebsiteModal from 'components/Modals/ImportWebsiteModal';

const { confirm } = Modal;

const ErrorPage = () => (
  <div>
    <h2>Cannot load the page. Please refresh your browser.</h2>
  </div>
);

const CustomTooltip = ({
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  skipProps,
}) => {
  return (
    <StyledCustomTooltip {...tooltipProps}>
      <StyledToolTipContainer>
        {step.title && <div>{step.title}</div>}
        <div>{step.content}</div>
        <StyledFooterActions>
          <div>
            {index > 0 && (
              <button type="button" {...backProps}>
                BACK
              </button>
            )}
            {isLastStep ? (
              <button type="button" {...primaryProps}>
                NEXT
              </button>
            ) : (
              <button type="button" {...primaryProps}>
                NEXT
              </button>
            )}
          </div>
          <button type="button" {...skipProps}>
            SKIP
          </button>
        </StyledFooterActions>
      </StyledToolTipContainer>
    </StyledCustomTooltip>
  );
};

CustomTooltip.propTypes = {
  continuous: PropTypes.bool,
  index: PropTypes.number,
  step: PropTypes.object,
  backProps: PropTypes.object,
  primaryProps: PropTypes.object,
  tooltipProps: PropTypes.object,
};

const AnswerBank = ({
  details,
  handleShowAnswerEditorModal,
  handleShowFileEditorModal,
  handleShowLinkedQuestions,
  handleShowAddTestSuiteModal,
  handleUpdateCategory,
  handleAddNewCategory,
  loading,
  onUpdateCategoryAnswer,
  deleteCategory,
  ...rest
}) => {
  const {
    answersOrder,
    allAnswers,
    allCategories,
    isANewBot,
    confirmModalLoading,
    filteredAnswers,
    limitReached,
    jid,
    maxAnswerCount,
    moveToCategory,
    pageLoading,
    exportSelectedAnswers,
    selectedAnswers,
    showAskedQuestionModal,
    showAddQuestionToTestSuiteModal,
    showBulkMoveCategoryModal,
    showBulkDeleteModal,
    questionForTestSuite,
    questionLog,
    dateFilter,
    selectedAnswerId,
    askedQuestionModalLoading,
    onDelete,
    handleBulkDelete,
    handleChangeTab,
    handleCloseAskedQuestionModal,
    handleCloseAddTestSuiteModal,
    handleChangeCheckbox,
    handleBulkMoveCategory,
    handleSortAnswers,
    handleShowAskedQuestions,
    handleSelectAll,
    handleShowDigraph,
    handleHideDigraph,
    handleCloseCategoryModal,
    isCategoryModalOpen,
    setShowAskedQuestionModal,
    setShowBulkMoveCategoryModal,
    setShowBulkDeleteModal,
    setShowDisplayAnswer,
    setMoveToCategory,
    showDisplayAnswer,
    showDigraph,
    onboarding_flag,
    setIsImportDropdownVisible,
    isAnswerBankReady,
    sentinel,
    token,
    graph,
    dispatch,
    handleDateFilterChange,
    handleOpenExportModal,
    handleCloseExportModal,
    openExportModal,
    synced,
    botMode,
    handleManualBotSyncing,
    isDetailsPageReady,
    handleChangeCategory,
    newAnswer,
    isAddAnswerDisabled,
    sending,
    onAddAnswer,
    onChangeNewAnswer,
    setAnswerVersion,
    path,
    answerVersion,
    showAddPlainAnswerModal,
    setShowAddPlainAnswerModal,
    handleCloseAddAnswerModal,
    selectedTab,
    isExportImportEnabled,
    renderFilterAnswer,
  } = useAnswerBank({
    onUpdateCategoryAnswer,
    loading,
  });
  const [fileType, setFileType] = useState(null);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [showUploadAnswerModal, setShowUploadAnswerModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [answersToVerify, setAnswersToVerify] = useState([]);
  const [parsedFileAnswers, setParsedFileAnswers] = useState([]);
  const [similarAnswers, setSimilarAnswers] = useState([]);
  const [selectedSimilarAnswers, setSelectedSimilarAnswers] = useState([]);
  const [verifiedAnswers, setVerifiedAnswers] = useState([]);
  const [isVerifyingAnswers, setIsVerifyingAnswers] = useState(false);
  const [isImportBtnDisabled, setIsImportBtnDisabled] = useState(true);
  const [duplicatesFoundInFile, setDuplicatesFoundInFile] = useState([]);
  const [notificationKey, setNotificationKey] = useState('');

  useEffect(() => {
    if (isAnswerBankReady && !onboarding_flag.includes('ImportAnswerButton')) {
      setRunTour(true);
    }
    return () => setRunTour(false);
  }, [isAnswerBankReady, onboarding_flag]);

  const handleResetVerifyingAnswers = useCallback(() => {
    setIsVerifyingAnswers(false);
    setAnswersToVerify([]);
    setParsedFileAnswers([]);
    setSimilarAnswers([]);
    setSelectedSimilarAnswers([]);
    setVerifiedAnswers([]);
    setShowUploadAnswerModal(true);
    setCurrentStep(0);
  }, []);

  const handleOpenAnswerModal = useCallback(
    type => {
      setFileType(type);
      if (isVerifyingAnswers) {
        confirm({
          content: (
            <p>Background process for verifying answers is already running.</p>
          ),
          onOk() {
            notification.close(notificationKey);
            setShowUploadAnswerModal(true);
          },
          onCancel() {
            handleResetVerifyingAnswers();
            notification.close(notificationKey);
          },
          cancelText: 'Cancel',
          okText: 'Resume',
        });
      } else {
        setShowUploadAnswerModal(true);
      }
    },
    [handleResetVerifyingAnswers, isVerifyingAnswers, notificationKey]
  );

  const handleOpenFileModal = useCallback(
    type => {
      setFileType(type);
      if (isVerifyingAnswers) {
        confirm({
          content: (
            <p>Background process for verifying answers is already running.</p>
          ),
          onOk() {
            notification.close(notificationKey);
            setShowUploadFileModal(true);
          },
          onCancel() {
            handleResetVerifyingAnswers();
            notification.close(notificationKey);
          },
          cancelText: 'Cancel',
          okText: 'Resume',
        });
      } else {
        setShowUploadFileModal(true);
      }
    },
    [handleResetVerifyingAnswers, isVerifyingAnswers, notificationKey]
  );

  const handleCloseImportAnswerModal = () => {
    setFileType(null);
    setShowUploadAnswerModal(false);
  };

  const handleCloseImportFileModal = () => {
    setFileType(null);
    setShowUploadFileModal(false);
  };

  const getSortBtnTitle = useMemo(
    () => ANSWER_SORTING_LIST[answersOrder.key].label,
    [answersOrder]
  );

  const verifyAnswersProgress = useCallback(
    () => Math.round((verifiedAnswers.length / parsedFileAnswers.length) * 100),
    [parsedFileAnswers.length, verifiedAnswers.length]
  );

  const saveOnboardingFlag = async () => {
    if (!onboarding_flag.includes('ImportAnswerButton')) {
      await apiService.setOnboardingFlag(
        sentinel,
        token,
        graph,
        'ImportAnswerButton'
      );
      dispatch({ type: SET_ONBOARDING_FLAG, payload: 'ImportAnswerButton' });
    }
  };

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if (STATUS.FINISHED === status) {
      setRunTour(false);
      setStepIndex(0);
      handleOpenAnswerModal('ingest');
      saveOnboardingFlag();
    } else if (STATUS.SKIPPED === status) {
      setRunTour(false);
      setStepIndex(0);
      saveOnboardingFlag();
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (stepIndex === 1) {
        setIsImportDropdownVisible(true);
        setRunTour(false);
        setTimeout(() => {
          setRunTour(true);
          setStepIndex(stepIndex);
        }, 250);
      }
      if (stepIndex === 2) {
        setIsImportDropdownVisible(false);
        setTimeout(() => {
          setStepIndex(stepIndex);
        }, 250);
      }

      setStepIndex(stepIndex);
    }
  };

  const AnswerBankButtons = () => {
    return (
      <StyledFlexRowRight>
        <Menu
          style={{
            backgroundColor: '#f0f2f5',
          }}
        >
          {path('files') && (
            <Menu.Item>
              <Button
                type="button"
                startIcon={<FileTextOutlined />}
                value={`Import File`}
                variant="link"
                onClick={() => handleOpenFileModal('file')}
              />
            </Menu.Item>
          )}
          {path('websites') && (
            <Menu.Item>
              <Button
                type="button"
                startIcon={<GlobalOutlined />}
                value={`Import Website`}
                variant="link"
                onClick={() => handleOpenFileModal('url')}
              />
            </Menu.Item>
          )}
          {botMode === BOT_MODES.ZSB && isExportImportEnabled && (
            <Menu.Item>
              <SubMenu
                key="submenu1"
                title="Add Answer"
                icon={<ProfileOutlined />}
              >
                <AnswerMenu
                  handleOpenModal={handleOpenAnswerModal}
                  handleOpenAddAnswerModal={() =>
                    setShowAddPlainAnswerModal(true)
                  }
                />
              </SubMenu>
            </Menu.Item>
          )}

          {botMode === BOT_MODES.OPENAI && path('answers') && (
            <Menu.Item>
              {' '}
              <Button
                type="button"
                startIcon={<ProfileOutlined />}
                value="Add Answer"
                variant="link"
                onClick={() => setShowAddPlainAnswerModal(true)}
              />
            </Menu.Item>
          )}
        </Menu>
      </StyledFlexRowRight>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <StyledRoot>
        <Joyride
          run={runTour}
          steps={TEXT_INGEST_DROPDOWN_BUTTON_STEPS}
          stepIndex={stepIndex}
          tooltipComponent={CustomTooltip}
          continuous
          debug
          spotlightClicks
          showProgress={true}
          showSkipButton={true}
          callback={onClickCallback}
          disableScrolling
        />
        <StyledAnswersContainer>
          <StyledTitle>
            <Title
              text={`Answer Bank (${allAnswers.length}/${maxAnswerCount})`}
              type="responsive-text"
            />
            {!showGuidelines && (
              <Tooltip title="Show guidelines">
                <InfoCircleOutlined
                  alt="show guidelines"
                  onClick={() => setShowGuidelines(true)}
                />
              </Tooltip>
            )}
          </StyledTitle>
          {showGuidelines && (
            <StyledGuidelines>
              <StyledGuidelineHeader>
                <StyledFirstLine>
                  Answer Bank is the repository of response your bot will use
                  when asked a question.
                  <CloseCircleOutlined
                    alt="close guidelines"
                    onClick={() => setShowGuidelines(false)}
                  />
                </StyledFirstLine>
                Here are few guidelines you should follow:
              </StyledGuidelineHeader>
              <StyledGuidelineList>
                <li>
                  Your answer should be <b>definite</b>
                </li>
                <li>
                  Keep your answers <b>succinct</b>
                </li>
                <li>No unrelated answers</li>
              </StyledGuidelineList>
            </StyledGuidelines>
          )}

          {isDetailsPageReady &&
            isAnswerBankReady &&
            (runTour || isANewBot) &&
            !allAnswers.length && <AnswerBankButtons />}

          <AnswerType
            isDetailsPageReady={isDetailsPageReady}
            isAnswerBankReady={isAnswerBankReady}
            pageLoading={pageLoading}
            filteredAnswers={filteredAnswers}
            handleManualBotSyncing={handleManualBotSyncing}
            handleChangeTab={handleChangeTab}
            selectedAnswers={selectedAnswers}
            setShowBulkMoveCategoryModal={setShowBulkMoveCategoryModal}
            handleShowDigraph={handleShowDigraph}
            synced={synced}
            setShowDisplayAnswer={setShowDisplayAnswer}
            showDisplayAnswer={showDisplayAnswer}
            handleSelectAll={handleSelectAll}
            handleShowAskedQuestions={handleShowAskedQuestions}
            handleShowLinkedQuestions={handleShowLinkedQuestions}
            handleChangeCheckbox={handleChangeCheckbox}
            handleShowAnswerEditorModal={handleShowAnswerEditorModal}
            handleShowFileEditorModal={handleShowFileEditorModal}
            onDelete={onDelete}
            handleChangeCategory={handleChangeCategory}
            exportSelectedAnswers={exportSelectedAnswers}
            handleOpenExportModal={handleOpenExportModal}
            setShowBulkDeleteModal={setShowBulkDeleteModal}
            handleSortAnswers={handleSortAnswers}
            newAnswer={newAnswer}
            isAddAnswerDisabled={isAddAnswerDisabled}
            handleOpenFileModal={handleOpenFileModal}
            sending={sending}
            onAddAnswer={onAddAnswer}
            handleOpenAnswerModal={handleOpenAnswerModal}
            onChangeNewAnswer={onChangeNewAnswer}
            setAnswerVersion={setAnswerVersion}
            handleOpenAddAnswerModal={() => setShowAddPlainAnswerModal(true)}
            path={path}
            selectedTab={selectedTab}
            answersOrder={answersOrder}
            renderFilterAnswer={renderFilterAnswer}
            runTour={runTour}
          />

          {limitReached && (
            <LimitationBanner
              title={`You have used ${allAnswers.length} of ${maxAnswerCount} answer limit`}
              subtitle={`Upgrade to add more answers to your bots answer bank`}
            />
          )}

          {isCategoryModalOpen ? (
            <ViewCategories
              visible={isCategoryModalOpen}
              handleSave={handleUpdateCategory}
              title="Edit Category"
              onClose={handleCloseCategoryModal}
            />
          ) : null}

          {showAskedQuestionModal ? (
            <AskedQuestionModal
              handleCloseAskedQuestionModal={handleCloseAskedQuestionModal}
              handleShowAddTestSuiteModal={handleShowAddTestSuiteModal}
              questionLog={questionLog}
              showAskedQuestionModal={showAskedQuestionModal}
              setShowAskedQuestionModal={setShowAskedQuestionModal}
              handleDateFilterChange={handleDateFilterChange}
              dateFilter={dateFilter}
              handleShowAskedQuestions={() =>
                handleShowAskedQuestions(selectedAnswerId)
              }
              askedQuestionModalLoading={askedQuestionModalLoading}
            />
          ) : null}
        </StyledAnswersContainer>

        {showUploadAnswerModal && (
          <ImportAnswerModal
            visibility={showUploadAnswerModal}
            setShowUploadModal={setShowUploadAnswerModal}
            onClose={handleCloseImportAnswerModal}
            fileType={fileType}
            notificationAPI={notification}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            answersToVerify={answersToVerify}
            setAnswersToVerify={setAnswersToVerify}
            parsedFileAnswers={parsedFileAnswers}
            setParsedFileAnswers={setParsedFileAnswers}
            similarAnswers={similarAnswers}
            setSimilarAnswers={setSimilarAnswers}
            selectedSimilarAnswers={selectedSimilarAnswers}
            setSelectedSimilarAnswers={setSelectedSimilarAnswers}
            verifiedAnswers={verifiedAnswers}
            setVerifiedAnswers={setVerifiedAnswers}
            verifyAnswersProgress={verifyAnswersProgress}
            isVerifyingAnswers={isVerifyingAnswers}
            setIsVerifyingAnswers={setIsVerifyingAnswers}
            isImportBtnDisabled={isImportBtnDisabled}
            setIsImportBtnDisabled={setIsImportBtnDisabled}
            duplicatesFoundInFile={duplicatesFoundInFile}
            setDuplicatesFoundInFile={setDuplicatesFoundInFile}
            notificationKey={notificationKey}
            setNotificationKey={setNotificationKey}
          />
        )}

        {showAddPlainAnswerModal && (
          <AddAnswerModal
            visibility={showAddPlainAnswerModal}
            onClose={handleCloseAddAnswerModal}
            onAddAnswer={onAddAnswer}
            sending={sending}
            newAnswer={newAnswer}
            onChangeNewAnswer={onChangeNewAnswer}
            answerVersion={answerVersion}
            setAnswerVersion={setAnswerVersion}
          />
        )}

        {showUploadFileModal && fileType === 'file' ? (
          <ImportFileModal
            visibility={showUploadFileModal}
            setShowUploadModal={setShowUploadFileModal}
            onClose={handleCloseImportFileModal}
            fileType={fileType}
            notificationAPI={notification}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            answersToVerify={answersToVerify}
            setAnswersToVerify={setAnswersToVerify}
            parsedFileAnswers={parsedFileAnswers}
            setParsedFileAnswers={setParsedFileAnswers}
            similarAnswers={similarAnswers}
            setSimilarAnswers={setSimilarAnswers}
            selectedSimilarAnswers={selectedSimilarAnswers}
            setSelectedSimilarAnswers={setSelectedSimilarAnswers}
            verifiedAnswers={verifiedAnswers}
            setVerifiedAnswers={setVerifiedAnswers}
            verifyAnswersProgress={verifyAnswersProgress}
            isVerifyingAnswers={isVerifyingAnswers}
            setIsVerifyingAnswers={setIsVerifyingAnswers}
            isImportBtnDisabled={isImportBtnDisabled}
            setIsImportBtnDisabled={setIsImportBtnDisabled}
            duplicatesFoundInFile={duplicatesFoundInFile}
            setDuplicatesFoundInFile={setDuplicatesFoundInFile}
            notificationKey={notificationKey}
            setNotificationKey={setNotificationKey}
          />
        ) : (
          <ImportWebsiteModal
            visibility={showUploadFileModal}
            setShowUploadModal={setShowUploadFileModal}
            onClose={handleCloseImportFileModal}
            fileType={fileType}
            notificationAPI={notification}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            answersToVerify={answersToVerify}
            setAnswersToVerify={setAnswersToVerify}
            parsedFileAnswers={parsedFileAnswers}
            setParsedFileAnswers={setParsedFileAnswers}
            similarAnswers={similarAnswers}
            setSimilarAnswers={setSimilarAnswers}
            selectedSimilarAnswers={selectedSimilarAnswers}
            setSelectedSimilarAnswers={setSelectedSimilarAnswers}
            verifiedAnswers={verifiedAnswers}
            setVerifiedAnswers={setVerifiedAnswers}
            verifyAnswersProgress={verifyAnswersProgress}
            isVerifyingAnswers={isVerifyingAnswers}
            setIsVerifyingAnswers={setIsVerifyingAnswers}
            isImportBtnDisabled={isImportBtnDisabled}
            setIsImportBtnDisabled={setIsImportBtnDisabled}
            duplicatesFoundInFile={duplicatesFoundInFile}
            setDuplicatesFoundInFile={setDuplicatesFoundInFile}
            notificationKey={notificationKey}
            setNotificationKey={setNotificationKey}
          />
        )}

        {showAddQuestionToTestSuiteModal && (
          <AddQuestionToTestSuiteModal
            show={showAddQuestionToTestSuiteModal}
            question={questionForTestSuite}
            onCloseModal={handleCloseAddTestSuiteModal}
          />
        )}

        <MoveCategoryModal
          categories={allCategories}
          confirmModalLoading={confirmModalLoading}
          handleBulkMoveCategory={handleBulkMoveCategory}
          moveToCategory={moveToCategory}
          showBulkMoveCategoryModal={showBulkMoveCategoryModal}
          selectedAnswers={selectedAnswers}
          setShowBulkMoveCategoryModal={setShowBulkMoveCategoryModal}
          setMoveToCategory={setMoveToCategory}
        />

        <ConfirmDelete
          title="Confirm Delete Answers"
          show={showBulkDeleteModal}
          onCancel={() => setShowBulkDeleteModal(false)}
          onOk={handleBulkDelete}
          okText="Confirm"
          confirmLoading={confirmModalLoading}
          itemTobeDeleted={`the ${selectedAnswers.length} selected answer(s)`}
        />
        <GraphModal
          visible={showDigraph}
          title="Bot Graph Representation"
          width={'98%'}
          onOk={handleHideDigraph}
          cancelButtonProps={{ style: { display: 'none' } }}
          bodyStyle={{
            height: '100%',
            textAlign: 'center',
            padding: 0,
            margin: 10,
          }}
          closable={false}
          destroyOnClose={true}
          style={{ top: 20 }}
        >
          <DiGraph
            depth={4}
            jid={jid}
            options={{
              layout: {
                improvedLayout: false,
              },
              physics: {
                enabled: true,
                stabilization: {
                  iterations: 500,
                },
              },
              interaction: {
                navigationButtons: true,
                keyboard: true,
              },
            }}
          />
        </GraphModal>
        {openExportModal && (
          <ExportModal
            show={openExportModal}
            onClose={handleCloseExportModal}
            onloadSelected={['answer']}
          />
        )}
      </StyledRoot>
    </ErrorBoundary>
  );
};

AnswerBank.propTypes = {
  details: PropTypes.func,
  handleShowAnswerEditorModal: PropTypes.func,
  handleShowFileEditorModal: PropTypes.func,
  handleUpdateCategory: PropTypes.func,
  handleAddNewCategory: PropTypes.func,
  handleShowAddTestSuiteModal: PropTypes.func,
  handleShowLinkedQuestions: PropTypes.func,
  onUpdateCategoryAnswer: PropTypes.func,
  deleteCategory: PropTypes.func,
  loading: PropTypes.bool,
};

export default AnswerBank;
