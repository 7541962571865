import styled from 'styled-components';
import { cssVariables } from 'styles/root';
import Input from 'components/Input';
import Button from 'components/Button';

export const StyledCodeButton = styled(Button)`
  background-color: ${cssVariables.primaryBlue} !important;
  text-transform: capitalize;
  max-width: 200;

  &.secondary {
    color: ${cssVariables.primaryBlue} !important;
    background-color: transparent !important;
  }

  & span {
    font-weight: ${cssVariables.font.normal};
    letter-spacing: 0px;
  }
`;

export const StyledSnippets = styled.div`
  background: rgb(191, 221, 255, 0.1);
  padding: 20px;
  border-radius: 5px;

  & > * {
    opacity: 1;
  }
`;

export const StyledCodeBlock = styled.div`
  max-width: 100%;
  border-radius: 5px;

  & pre {
    max-width: 100%;
    width: 100%;
    word-break: break-all;
  }
`;

export const StyledIntegrationSteps = styled.div`
  width: 100%;

  & form {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const StyledIntegrationInstruction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledApplicationKeyInput = styled(Input)`
  padding-top: 20px;
  padding-bottom: 20px;
`;
