import { cssVariables } from 'styles/root';
import styled from 'styled-components';
import Button from 'components/Button';

export const StyledWebsiteEditor = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 20vh;
  margin-top: 1%;

  @media (max-width: 600px) {
    min-width: 80%;
  }

  .ant-descriptions-title {
    font-weight: normal;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  color: ${props => (props.error ? `red` : `rgba(0, 0, 0, 0.85)`)};
`;

export const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => (props.isTitle ? '2%' : '0')};
  margin-bottom: 3px;
`;

export const StyledBtnAdvanceSettings = styled.button`
  display: flex;
  border: none;
  height: 38px;
  background: #ffff;
  color: #1667e7;
  border-radius: 1px;
  cursor: pointer;
  margin-top: 15px;
  float: right;
  line-height: 32px;

  &:hover {
    background: #1667e726;
  }
`;

export const StyledCodeButton = styled(Button)`
  background-color: ${cssVariables.primaryBlue} !important;
  text-transform: capitalize;
  max-width: 200px;
  color: #fff !important;

  &.secondary {
    color: ${cssVariables.primaryBlue} !important;
    background-color: transparent !important;
  }

  & span {
    font-weight: ${cssVariables.font.normal};
    letter-spacing: 0px;
  }
`;
