import {
  SET_ONBOARDING_FLAG,
  DELETE_ONBOARDING_FLAG,
  RESET_ONBOARDING_FLAG,
} from 'store/action';

export const onboardingReducer = (state, action) => {
  switch (action.type) {
    case SET_ONBOARDING_FLAG:
      return {
        ...state,
        plan: {
          ...state.plan,
          onboarding_flag: [...state.plan.onboarding_flag, action.payload],
        },
      };

    case DELETE_ONBOARDING_FLAG: {
      const toRemoveFlag = action.payload;
      const newFlags = state.plan.onboarding_flag.filter(
        flags => flags !== toRemoveFlag
      );
      return {
        ...state,
        plan: {
          ...state.plan,
          onboarding_flag: newFlags,
        },
      };
    }

    case RESET_ONBOARDING_FLAG:
      return {
        ...state,
        plan: {
          ...state.plan,
          onboarding_flag: [],
        },
      };

    default:
      return state;
  }
};
