import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Col } from 'antd';

import Input from 'components/Input';
import {
  StyledFormFieldRow,
  StyledFormFieldSubmit,
  StyledLabel,
} from './CustomizeBot.style';

const FormBuilder = ({
  tempFormFields,
  onAddFormField,
  onChangeFormFields,
  onDeleteFormRow,
  onBlurFormField,
}) => {
  return (
    <>
      <div className="formFieldHeader">
        <StyledLabel>{'Form Fields'}</StyledLabel>
      </div>
      {tempFormFields.map((form, idx) => (
        <React.Fragment key={`form-group-${idx}`}>
          <StyledFormFieldRow key={`form-field-${idx}-1`}>
            <Col xs={{ span: 22 }} md={{ span: 8 }}>
              {`Field ${idx + 1}`}
            </Col>
            <Col xs={{ span: 18 }} md={{ span: 12 }}>
              <Input
                required
                defaultValue={form.label}
                size={'medium'}
                onChange={e => onChangeFormFields(e, idx)}
                onBlur={() => onBlurFormField(idx)}
              />
            </Col>
            {/* Only allows delete for last form row */}
            <Col span={4}>
              {idx === tempFormFields.length - 1 && !!idx ? (
                <DeleteOutlined
                  disabled={tempFormFields?.length <= 1}
                  onClick={() => onDeleteFormRow(form.attribute)}
                />
              ) : null}
            </Col>
          </StyledFormFieldRow>
          <StyledFormFieldRow key={`form-field-${idx}-2`}>
            <Col xs={{ span: 22 }} md={{ span: 8 }} />{' '}
            <Col xs={{ span: 18 }} md={{ span: 12 }}>
              <Checkbox
                style={{ padding: 0 }}
                defaultChecked={form.mandatory}
                onChange={e => onChangeFormFields(e, idx, true)}
              >
                {'Required'}
              </Checkbox>
            </Col>
          </StyledFormFieldRow>
        </React.Fragment>
      ))}
      <StyledFormFieldSubmit
        variant={'link'}
        value={'Add Another Field'}
        endIcon={<PlusOutlined />}
        size={'medium'}
        onClick={onAddFormField}
      />
    </>
  );
};

FormBuilder.propTypes = {
  tempFormFields: PropTypes.array.isRequired,
  onAddFormField: PropTypes.func.isRequired,
  onDeleteFormRow: PropTypes.func.isRequired,
  onChangeFormFields: PropTypes.func.isRequired,
  onBlurFormField: PropTypes.func.isRequired,
};

export default FormBuilder;
