import React from 'react';
import {
  Timeline,
  Empty,
  Spin,
  Dropdown,
  Menu,
  Row,
  Col,
  Typography,
  Grid,
} from 'antd';
import moment from 'moment';
import {
  CaretRightFilled,
  DownloadOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  StyledWrapper,
  StyledContentWrapper,
  StyledButtonContainer,
  StyledTitleWrapper,
  StyledContentSubHeaderWrapper,
  StyledLogsDetailsContainer,
  StyledUserInfoContainer,
  StyledDetailsContainer,
  StyledSortSelected,
} from './ActivityLogs.style';
import Button from 'components/Button';
import useActivityLogs from './hooks';
import ActivityLogsModal from './ActivityLogsModal';
import AdminSidebar from 'pages/Admin/AdminSidebar';
import DateFilter from 'components/DateFilter';
import { StyledDatePickerButton } from 'pages/BotDetails/Analytics/Analytics.styles';

import useSelector from 'store/useSelector';
import { userSelector } from 'selectors/user';
import Paragraph from 'antd/lib/typography/Paragraph';
import { SidebarRoutes } from 'pages/Admin/SidebarRoutes';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const ActivityLogs = () => {
  const {
    activityLogs,
    dateFilter,
    selectedActivityLogs,
    showDetailsModal,
    isLoading,
    filters,
    activityLogsState,
    sort,
    loadingTemplate,
    endMessageTemplate,
    isAdmin,
    formatDateTime,
    handleDateFilterChange,
    setSelectedActivityLogDetails,
    handleModalClosing,
    handleModalOpening,
    handleDownloadActivityLogs,
    getActivityLogs,
    setSort,
    handleChangeActivityDateSorting,
    fetchNextScroll,
    parseToStartAndEndOfDayISODate,
    activeBots,
    renderNewBotName,
  } = useActivityLogs();
  const screens = useBreakpoint();
  const user = useSelector(userSelector);
  const rangePickerStyle = {
    width: screens.xs ? '100%' : 'auto',
    padding: screens.xs ? '8px 12px' : 'auto',
  };
  const dropdownStyle = {
    width: screens.xs ? '100%' : 'auto',
  };

  const RightEl = (
    <StyledButtonContainer>
      <Button
        startIcon={<DownloadOutlined />}
        value={'Export'}
        variant="success"
        onClick={handleDownloadActivityLogs}
        disabled={activityLogs?.length === 0}
        style={{ marginLeft: 5 }}
        s
      />
    </StyledButtonContainer>
  );
  const SortDropdownMenu = (
    <Menu>
      <StyledSortSelected
        key="asc"
        icon={<UpOutlined />}
        onClick={() => handleChangeActivityDateSorting('asc')}
        sortType={sort}
      >
        Ascending
      </StyledSortSelected>
      <StyledSortSelected
        key="desc"
        icon={<DownOutlined />}
        onClick={() => handleChangeActivityDateSorting('desc')}
        sortType={sort}
      >
        Descending
      </StyledSortSelected>
    </Menu>
  );

  return (
    <StyledWrapper displayAdminSideBar={isAdmin}>
      {isAdmin && <AdminSidebar title="Admin" route={SidebarRoutes} />}
      <Spin spinning={isLoading} tip="Just a moment...">
        <StyledContentWrapper superUser={user?.is_superuser} isAdmin={isAdmin}>
          <StyledTitleWrapper>
            <Col span={12}>
              <Title level={4}>Activity Logs</Title>
            </Col>
            <Col
              xs={{ span: 12 }}
              md={{ span: 4, offset: 8 }}
              lg={{ span: 4, offset: 8 }}
              xl={{ span: 4, offset: 8 }}
            >
              {RightEl}
            </Col>
          </StyledTitleWrapper>

          <StyledContentSubHeaderWrapper>
            <Col
              xs={{ span: 24 }}
              md={{ span: 7 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Paragraph level={5}>
                Activity logs showing results from
              </Paragraph>
            </Col>
            <Col
              xs={{ span: 19 }}
              md={{ span: 10 }}
              lg={{ span: 7 }}
              xl={{ span: 6 }}
            >
              <DateFilter
                onChange={handleDateFilterChange}
                defaultValue={[
                  moment(dateFilter.endDate),
                  moment(dateFilter.startDate),
                ]}
                style={rangePickerStyle}
                getPopupContainer={trigger => trigger.parentNode}
                dropdownClassName="responsive-range-picker-dropdown"
                popupStyle={dropdownStyle}
              />
            </Col>
            <Col
              xs={{ span: 3 }}
              md={{ span: 6 }}
              lg={{ span: 5 }}
              xl={{ span: 6 }}
            >
              <StyledDatePickerButton
                onClick={() => {
                  const { validStartDate, validEndDate } =
                    parseToStartAndEndOfDayISODate(dateFilter);
                  getActivityLogs(validStartDate, validEndDate, {
                    isExecutedAlready: false,
                    sort: 'desc',
                  });
                  setSort('desc');
                }}
                value={`Go`}
                endIcon={<CaretRightFilled />}
              />
            </Col>
          </StyledContentSubHeaderWrapper>
          <Row>
            <Col
              xs={{ span: 10 }}
              md={{ span: 10 }}
              lg={{ span: 11 }}
              xl={{ span: 12 }}
            >
              {filters}
            </Col>
            <Col
              xs={{ span: 14 }}
              md={{ span: 6, offset: 8 }}
              lg={{ span: 5, offset: 8 }}
              xl={{ span: 4, offset: 8 }}
            >
              Sort by:
              <Dropdown trigger={['click']} overlay={SortDropdownMenu}>
                <Button
                  variant="secondary"
                  value={`Activity Date`}
                  size="middle"
                  style={{ marginLeft: '7px' }}
                />
              </Dropdown>
            </Col>
          </Row>
          <StyledLogsDetailsContainer
            style={{ height: '500px', overflow: 'auto' }}
            // ref={scroll}
            id="scrollableDiv"
          >
            {activityLogs?.length > 0 && (
              <InfiniteScroll
                dataLength={activityLogsState.items.length}
                next={fetchNextScroll}
                style={{
                  overflowX: 'hidden',
                  paddingTop: '20px',
                }}
                inverse={false}
                hasMore={activityLogsState.hasMore}
                loader={loadingTemplate}
                endMessage={endMessageTemplate}
                scrollableTarget="scrollableDiv"
              >
                <Timeline
                  mode="left"
                  style={{
                    width: '50vw',
                  }}
                >
                  {activityLogsState.items?.map(activityLog => (
                    <Timeline.Item
                      label={formatDateTime(
                        moment(activityLog._source.datetime)
                      )}
                    >
                      <StyledDetailsContainer>
                        {activityLog._source.activity_action}
                        <Button
                          type="button"
                          variant={'link'}
                          size="small"
                          value="See Details"
                          onClick={() =>
                            setSelectedActivityLogDetails(activityLog._source)
                          }
                        />
                      </StyledDetailsContainer>
                      {isAdmin && activityLog._source.user?.email && (
                        <StyledUserInfoContainer>
                          {activityLog._source.user.email}
                        </StyledUserInfoContainer>
                      )}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </InfiniteScroll>
            )}
            {(activityLogs?.length === 0 || !activityLogs) && (
              <Empty
                description={<span>No Log Found.</span>}
                style={{ width: '100%' }}
              />
            )}
          </StyledLogsDetailsContainer>
        </StyledContentWrapper>
      </Spin>

      <ActivityLogsModal
        showDetailsModal={showDetailsModal}
        selectedActivityLogs={selectedActivityLogs}
        onOk={handleModalOpening}
        onCancel={handleModalClosing}
        status={
          activeBots?.find(
            activeBot => selectedActivityLogs?.id === activeBot.jid
          )
            ? 'Active Bot'
            : 'Inactive Bot'
        }
        renderNewBotName={renderNewBotName}
      />
    </StyledWrapper>
  );
};

export default ActivityLogs;
