import styled from 'styled-components';
import Modal from '../GenericModal';

export const StyledCategoryModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: right;
  }

  width: 816px !important;

  @media (max-width: 1000px) {
    width: 80vw !important;
  }
`;
