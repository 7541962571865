import { isEmpty } from 'lodash';
import ROUTES from 'constants/routes';
import { stripUUID } from 'utils';
import {
  INCORRECT_QUESTIONS,
  PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS,
  PERFORMANCE_METRICS_DEFAULT_ANSWERS,
  TABLE_VIEW_QUERY,
  PERFORMANCE_METRICS_HASH,
} from 'constants/analytics';

export const getBotJIDFromPath = (jid, search) => {
  const uuidPrefix = 'urn:uuid:';
  const query = new URLSearchParams(search);
  const botJIDParam = query.get('bot');
  const botJID = jid || botJIDParam?.split(uuidPrefix)?.pop();
  return botJID;
};

export const getCurrentBotDetailsPage = (jid, page) => {
  return `${ROUTES.BOT_DETAILS}/${stripUUID(jid)}${page}`;
};

const getRedirectPath = (jid, redirect) => {
  switch (redirect) {
    case PERFORMANCE_METRICS_DEFAULT_ANSWERS:
      return `${ROUTES.BOT_DETAILS}/${jid}/analytics${TABLE_VIEW_QUERY}${PERFORMANCE_METRICS_DEFAULT_ANSWERS}${PERFORMANCE_METRICS_HASH}`;
    case INCORRECT_QUESTIONS:
      return `${ROUTES.BOT_DETAILS}/${jid}/analytics${TABLE_VIEW_QUERY}${INCORRECT_QUESTIONS}${PERFORMANCE_METRICS_HASH}`;
    case PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS:
      return `${ROUTES.BOT_DETAILS}/${jid}/analytics${TABLE_VIEW_QUERY}${PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS}${PERFORMANCE_METRICS_HASH}`;
    // default to negative feedback
    default:
      return `${ROUTES.BOT_DETAILS}/${jid}/analytics${TABLE_VIEW_QUERY}${PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS}${PERFORMANCE_METRICS_HASH}`;
  }
};

export const botStatistics = (data, botJID) => {
  if (!data) {
    return {
      isWeakBot: false,
      redirect: null,
      weakBotMessage: null,
      totalQuestions: 0,
    };
  }

  let isWeakBot = false;
  const strippedJID = stripUUID(botJID || data.jid);
  let redirect = getRedirectPath(strippedJID);
  let totalQuestions = 0;

  let weakBotMessage = [];
  if (data === undefined || isEmpty(data.statistics)) {
    return false;
  } else {
    const { default_answer, incorrect, negativeFeedback, total } =
      data.statistics;
    const fallbackAnswerThreshold = total * 0.3;
    const negativeFeedbackThreshold = total * 0.3;
    const incorrectAnswerThreshold = total * 0.1;

    if (incorrect > incorrectAnswerThreshold) {
      isWeakBot = true;
      totalQuestions = data.statistics?.incorrect;
      redirect = getRedirectPath(strippedJID, INCORRECT_QUESTIONS);
      weakBotMessage.push("The bot doesn't have answer to user queries.");
    }
    if (default_answer > fallbackAnswerThreshold) {
      isWeakBot = true;
      totalQuestions = data.statistics?.default_answer;
      redirect = getRedirectPath(
        strippedJID,
        PERFORMANCE_METRICS_DEFAULT_ANSWERS
      );
      weakBotMessage.push('Users are unhappy with bot response.');
    }
    if (negativeFeedback > negativeFeedbackThreshold) {
      isWeakBot = true;
      totalQuestions = data.statistics?.negativeFeedback;
      redirect = getRedirectPath(
        strippedJID,
        PERFORMANCE_METRICS_NEGATIVE_FEEDBACKS
      );
      weakBotMessage.push('Unable to answer the questions correctly.');
    }
  }
  return {
    isWeakBot,
    redirect,
    totalQuestions,
    weakBotMessage,
  };
};
