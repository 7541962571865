import { Radio, Alert, Card, Tag, Input } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0 8px 0;
`;

export const HeaderTitle = styled.div`
  font-size: 16px;
`;

export const HeaderActions = styled.div`
  display: flex;
  font-size: 24px;
  gap: 4px;
  color: #66696f;
`;

export const StyledFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .action-buttons {
    justify-content: flex-end;
    display: flex;
    width: 100%;

    button:last-child {
      background-color: ${cssVariables.primaryBlue};
      color: #fff;
    }

    button:active,
    button:focus {
      background-color: ${cssVariables.primaryBlue} !important;
      color: #fff !important;
    }

    button:disabled {
      background-color: ${cssVariables.gray2} !important;
    }
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: 10px;
  }

  input,
  textarea {
    margin-top: 5px;
  }

  span.ant-radio + * {
    width: 100%;
  }
`;

export const MaxSentenceCountWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  > * {
    margin: 5px;
  }

  input {
    width: 30%;
  }
`;

export const KeywordsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  > * {
    margin: 5px;
  }
`;

export const EditableAnswerWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  align-items: center;

  > * {
    margin-right: 5px;
  }

  .text-area-with-duplicate {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
  }

  .text-area-similar-answer-validated {
    border: 1px solid #ffe58f;
    background-color: #fffbe6;
  }

  .mrgn-left {
    margin-left: 27px !important;
  }
`;

export const StyledAntUploadContent = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  row-gap: 16px;
  margin-top: -10px;
  height: 200px;
  justify-content: center;
  & .anticon {
    font-size: 30px;
  }
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledUploadTip = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  color: ${cssVariables.primaryBlue};

  & .ant-typography pre {
    margin-top: 5px;
  }
`;

export const StyledUploadTipTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledModalErrorAlert = styled(Alert)`
  margin: 20px 0;
`;

export const StyledSimilarAnswersLabel = styled.div`
  font-size: 12px;
  color: #33363c;
  margin-right: 6px;
  margin-top: 20px;
  font-weight: ${cssVariables.font.bold};
  margin-left: 15px;
`;

export const StyledAnswersItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${cssVariables.gray4};
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  margin-left: 15px;
`;

export const StyledAnswers = styled.div`
  font-family: Helvetica;
  font-size: 12px;
  color: ${cssVariables.primaryGray};
  width: 75%;
`;

export const StyledSimilarAnswers = styled.div`
  background-color: #fafafa;
  padding: 3px;
`;

export const StyledSummaryCard = styled(Card)`
  .ant-card-body {
    padding: 4px 10px 10px 10px;
  }
`;

export const StyledTag = styled(Tag)`
  padding: 4px 8px;
`;

export const StyledInput = styled(Input)`
  padding: 4px 8px;
`;

export const VerifiedTag = styled(Tag)`
  margin-left: 20px;
  font-size: 14;
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 1;
  color: ${props => (props.isVerified ? 'green' : 'red')};
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

export const ProductNameWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    width: 50%;
  }
`;

export const MaxAnswersCountWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    width: 50%;
  }
`;
