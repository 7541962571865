import React, { useMemo } from 'react';
import Joyride from 'react-joyride';
import { Menu, Row, Col, Tooltip, Checkbox, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { lowerCase } from 'lodash';
import {
  CloseOutlined,
  CaretRightFilled,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  SearchOutlined,
  SendOutlined,
  LinkOutlined,
  InfoCircleFilled,
  EyeInvisibleOutlined,
  FolderAddOutlined,
  ClearOutlined,
  AudioMutedOutlined,
  AudioOutlined,
  SoundOutlined,
  DashOutlined,
  EyeFilled,
  ToolFilled,
} from '@ant-design/icons';

import Button from 'components/Button';
import {
  StyledRightNav,
  StyledBotTitleSideNav,
  StyledChatComponent,
  StyledChatQuestionContainer,
  StyledChatAnswerContainer,
  StyledMessageOptions,
  StyledChatAnswer,
  StyledChatAnswerText,
  StyledTestBotInfo,
  StyledChatQuestion,
  StyledChatContentHeader,
  StyledChatList,
  StyledQuickReplyContainer,
  StyledChatScore,
  StyledMsgOption,
  StyledChatAction,
  StyledChatInputContainer,
  StyledSpinning,
  StyledColFlexbox,
  StyledLoader,
  StyledLoaderContainer,
  StyledLoaderDots,
  StyledMenu,
  StyledInteractionInput,
  StyledLabel,
  StyledChatViewButton,
  StyledWebSocketAction,
  StyledWebSocketActionContainer,
  StyledWebSocketActionList,
  StyledInfoSequence,
} from './StyledComponents';
import JoyrideTooltip from 'components/ToolTips/JoyrideToolTip';
import {
  CHAT_ANSWER_STEPS,
  DEFAULT_ANSWER_STEPS,
} from 'constants/joyride_steps/sidebar_chat';
import useSidebarChat from './hooks';
import TextArea from 'components/TextArea';
import { isAnObject } from 'utils/dataTypes';
import {
  StyledFlexColumn,
  StyledFlexLeftColumn,
  StyledFlexRowLeft,
  StyledFlexRowRight,
  StyledSpacEvenlyFlexRow,
  StyledTag,
} from 'styles/GenericStyledComponents';
import { cssVariables } from 'styles/root';
import GenericHR from 'components/HR/GenericHR';
import { stripSpecialCharactersFromText } from 'utils/stringManipulation';
import CodeBlock from 'components/CodeBlock';
import ChatBubble from 'components/ChatBubble';
import { BOT_MODES } from 'constants/bot';

const REQUEST_AGENT_QUICK_BTN = 'Request Callback';

const SidebarChat = ({
  handleChatAskQuestion,
  handleSeekAnswer,
  handleShowAddTestSuiteModal,
  toggleRightNav,
  contextHistory,
  setContextHistory,
  maxInteraction,
  setMaxInteraction,
}) => {
  const {
    chatList,
    chatQuestion,
    flipAnswer,
    bottomChatAreaRef,
    handleChange,
    handleFlipAnswer,
    handleLinkAnswer,
    handleSendQuickReply,
    handleSubmit,
    handleChangeAnswerVersion,
    handleShowJSONAnswerInModal,
    handleShowSelectedSequenceInModal,
    isNotAnswerBank,
    handleTranslateChange,
    handleContextHistoryChange,
    handleChangeChatView,
    handleChangeDropdownVisibility,
    handleShowAnswerEditorModal,
    isBotOpenAIEnabled,
    isChatMoreMenuOpen,
    isChatOnDevMode,
    isSidebarChatExpanded,
    isSpeechAllowed,
    isQuickButtonsVisible,
    isSpeechActivated,
    lastChatAnswer,
    listening,
    loading,
    name,
    onClickCallback,
    onDefaultAnswerClickCallback,
    readText,
    runTour,
    runTourDefaultAnswer,
    sending,
    shouldShowTestChatUseContext,
    stepIndex,
    stepIndexDefaultAnswer,
    toggleSpeak,
    translateLanguage,
    clearChatHistory,
    defaultAnswerThreshold,
    botMode,
    webSocket,
    typingExperience,
    handleTypingExperience,
  } = useSidebarChat({
    handleChatAskQuestion,
    setContextHistory,
    maxInteraction,
    setMaxInteraction,
    contextHistory,
  });

  const renderQuickButtons = () => {
    const quickReplyList = lastChatAnswer?.quickReply?.enabled
      ? lastChatAnswer?.quickReply?.replies
      : lastChatAnswer?.quickReply
      ? lastChatAnswer?.quickReplyOptions
      : null;
    return (
      <StyledQuickReplyContainer>
        {quickReplyList ? (
          quickReplyList.map((item, idx) => {
            if (item.reply?.length || item.label?.length) {
              return (
                <span
                  onClick={async () => handleSendQuickReply(item)}
                  key={idx}
                >
                  {item.reply || item.label}
                </span>
              );
            }

            return null;
          })
        ) : (
          <Tooltip title={`User option to transfer to agent`} placement="top">
            <span>{REQUEST_AGENT_QUICK_BTN}</span>
          </Tooltip>
        )}
      </StyledQuickReplyContainer>
    );
  };

  const MessageOptions = ({ chatItem, chatIndex }) => {
    const { answer, question } = chatItem;
    const { score, qlinks, type, isHardLinked } = chatItem.answer;
    const query =
      typeof question === 'object'
        ? stripSpecialCharactersFromText(question.question)
        : stripSpecialCharactersFromText(question);

    return (
      <>
        <StyledFlexLeftColumn>
          {isAnObject(answer.openai) && Array.isArray(answer.openai.intents) ? (
            <StyledFlexRowLeft style={{ display: 'block' }}>
              {answer.openai.intents.map((intent, idx) => (
                <StyledTag
                  key={`answer-${answer.jid}-intents-${idx}`}
                  style={{
                    marginTop: 5,
                  }}
                >
                  {lowerCase(intent)}
                </StyledTag>
              ))}
            </StyledFlexRowLeft>
          ) : null}
          {Array.isArray(qlinks) && !!qlinks.length && qlinks.includes(query) && (
            <>
              <LinkOutlined />
              &nbsp;<i>{'Linked'}</i>
            </>
          )}
          {type.includes('default') ? (
            <StyledChatScore className="danger">
              <span>
                {'Threshold'}: {defaultAnswerThreshold}
              </span>
              {` `}
              <Tooltip
                className="default-chat-score"
                title={'Default Answer'}
                placement="right"
              >
                <InfoCircleFilled />
              </Tooltip>
            </StyledChatScore>
          ) : (
            <>
              <StyledChatScore className="success">
                <span>{isHardLinked ? 'Hard Linked' : `Score: ${score}`}</span>
                {` `}
                <Tooltip
                  className="chat-score"
                  title={
                    isHardLinked
                      ? 'This answer is hard linked to the question'
                      : 'Answer'
                  }
                  placement="right"
                >
                  <InfoCircleFilled />
                </Tooltip>
              </StyledChatScore>
            </>
          )}
        </StyledFlexLeftColumn>
        {!type.includes('default') &&
          ['answer', 'openai_answer', 'file', 'website'].includes(
            chatItem.answer.type
          ) &&
          webSocket?.sending !== true && (
            <>
              {chatItem.answer.type === 'answer' && (
                <StyledMsgOption
                  className="chat-edit-answer"
                  onClick={() => handleShowAnswerEditorModal(answer, chatIndex)}
                >
                  <EditOutlined />
                  &nbsp;
                  <span>{'Edit Source'}</span>
                </StyledMsgOption>
              )}
            </>
          )}
      </>
    );
  };

  MessageOptions.propTypes = {
    chatItem: PropTypes.object.isRequired,
    chatIndex: PropTypes.number.isRequired,
  };

  const chatQuestionMenu = (chatItem, idx) => {
    const { question, answer } = chatItem;
    // disabled if
    // 1. chatItem is not last answer
    // 2. is openai answer node
    // 3. if bot has not replied to the question
    const isUseAnotherResponseDisabled =
      chatItem.answer?.type === 'openai_answer' ||
      chatList?.length - 1 > idx ||
      !answer;
    return (
      <Menu>
        <Menu.Item
          key="add-to-test-suite"
          icon={<FolderAddOutlined />}
          onClick={() => handleShowAddTestSuiteModal(question)}
        >
          {'Add to Test Suite'}
        </Menu.Item>
        <Menu.Item
          key={'link-to-answer'}
          icon={<LinkOutlined />}
          onClick={() => handleLinkAnswer(chatItem, idx)}
          aria-label={'Use another response'}
          disabled={isUseAnotherResponseDisabled}
        >
          {'Use another response'}
        </Menu.Item>
      </Menu>
    );
  };

  const moreMenu = (chatItem, chatIndex) => {
    const { answer } = chatItem;
    return (
      <Menu>
        {chatItem.answer.type.includes('default') ? (
          <Menu.Item
            key={3}
            icon={<LinkOutlined />}
            onClick={() => handleLinkAnswer(chatItem, chatIndex)}
            aria-label={'Use another response'}
          >
            {'Use another response'}
          </Menu.Item>
        ) : (
          <>
            {answer.type === 'answer' ? (
              <Menu.Item
                key={1}
                icon={<EditOutlined />}
                onClick={() => handleShowAnswerEditorModal(answer, chatIndex)}
                aria-label={'Edit'}
              >
                {'Edit'}
              </Menu.Item>
            ) : null}
            {isNotAnswerBank ? null : (
              <Menu.Item
                key={2}
                icon={<SearchOutlined />}
                disabled={isNotAnswerBank}
                onClick={() =>
                  isAnObject(answer?.openai)
                    ? handleSeekAnswer(answer.openai?.references)
                    : handleSeekAnswer(answer.jid)
                }
                aria-label={'Highlight Answer'}
              >
                {isAnObject(answer?.openai) && answer?.openai?.references
                  ? 'Highlight Answer Reference(s)'
                  : 'Highlight Answer'}
              </Menu.Item>
            )}
            <Menu.Item
              key={3}
              aria-label={
                flipAnswer.includes(chatIndex)
                  ? 'Show Display Answer'
                  : 'Hide Display Answer'
              }
              icon={
                flipAnswer.includes(chatIndex) ? (
                  <EyeOutlined />
                ) : (
                  <EyeInvisibleOutlined />
                )
              }
              onClick={() => handleFlipAnswer(chatIndex)}
            >
              {flipAnswer.includes(chatIndex)
                ? 'Show Display Answer'
                : 'Hide Display Answer'}
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  const moreOptions = (
    <StyledMenu visible={isChatMoreMenuOpen}>
      <Menu.Item key="clearChatHistory" onClick={clearChatHistory}>
        <ClearOutlined /> Clear Chat History
      </Menu.Item>

      <Menu.Item key="useDraft">
        <Checkbox onChange={handleChangeAnswerVersion}>{'Use Draft'}</Checkbox>
      </Menu.Item>

      {(isBotOpenAIEnabled || botMode !== BOT_MODES.ZSB) && (
        <Menu.Item key="translateLanguage">
          <Checkbox
            onChange={handleTranslateChange}
            checked={translateLanguage}
          >
            {'Translate Language'}
          </Checkbox>
        </Menu.Item>
      )}

      {shouldShowTestChatUseContext && (
        <>
          <Menu.Item
            key="enableContextHistory"
            style={{
              backgroundColor: contextHistory
                ? cssVariables.gray2
                : 'transparent',
            }}
          >
            <Checkbox
              onChange={handleContextHistoryChange}
              checked={contextHistory}
            >
              {'Use Context'}
            </Checkbox>
          </Menu.Item>
          <Menu.Item
            key="maxInteraction"
            style={{
              backgroundColor: contextHistory
                ? cssVariables.gray2
                : 'transparent',
            }}
            hidden={!contextHistory}
          >
            <StyledLabel>No. of Interactions</StyledLabel>
            <StyledInteractionInput
              min={2}
              max={5}
              value={maxInteraction || 3}
              onChange={value => setMaxInteraction(value)}
              disabled={!contextHistory}
              step={1}
            />
          </Menu.Item>
        </>
      )}

      <Menu.Item key="enableTypingExperience">
        <Checkbox onChange={handleTypingExperience} checked={typingExperience}>
          {'Enable Typing Experience'}
        </Checkbox>
      </Menu.Item>

      <StyledFlexColumn>
        <GenericHR />
        <StyledFlexRowRight>
          <Button
            value="Close"
            size="small"
            onClick={() => handleChangeDropdownVisibility(false)}
            style={{ margin: '5px 5px 0px 0px' }}
          />
        </StyledFlexRowRight>
      </StyledFlexColumn>
    </StyledMenu>
  );

  moreMenu.propTypes = {
    chatItem: PropTypes.object.isRequired,
    chatIndex: PropTypes.number.isRequired,
  };
  const renderChatBubble = (chatItem, answer, chatIndex) => (
    <StyledFlexRowLeft>
      <ChatBubble
        noMaxWidth
        isDevMode={isChatOnDevMode}
        isDefaultAnswer={chatItem?.answerContext?.name?.includes('default')}
        answer={answer}
        showJSONAnswerInModal={() =>
          handleShowJSONAnswerInModal(
            chatItem.answerContext,
            chatItem.questionPayload
          )
        }
        width="auto"
        hideFullAnswer={chatIndex === chatList.length - 1 ? false : true}
        className={
          chatItem?.answerContext?.name?.includes('default')
            ? 'default-chat-answer'
            : 'chat-answer'
        }
      />
      <StyledColFlexbox>
        <StyledChatAction
          overlay={moreMenu(chatItem, chatIndex)}
          placement="bottomLeft"
          trigger={['click']}
        >
          <MoreOutlined />
        </StyledChatAction>
        <SoundOutlined onClick={() => readText(answer, chatIndex)} />
      </StyledColFlexbox>
    </StyledFlexRowLeft>
  );

  const renderChatAnswer = (chatItem, chatIndex) => {
    if (Array.isArray(chatItem?.answer.show_html)) {
      return chatItem?.answer?.show_html?.map(html =>
        renderChatBubble(chatItem, html, chatIndex)
      );
    } else if (typeof chatItem?.answer?.show_html === 'string') {
      return renderChatBubble(chatItem, chatItem?.answer, chatIndex);
    } else if (Array.isArray(chatItem?.answer.show_text)) {
      return chatItem?.answer?.show_text?.map(html =>
        renderChatBubble(chatItem, html, chatIndex)
      );
    } else if (typeof chatItem?.answer?.show_text === 'string') {
      return renderChatBubble(chatItem, chatItem?.answer?.show_text, chatIndex);
    }
  };

  const renderDevMode = chatItem => (
    <StyledFlexRowLeft>
      <ChatBubble
        noMaxWidth
        isDevMode={isChatOnDevMode}
        answer={chatItem.answer}
        showJSONAnswerInModal={() =>
          handleShowJSONAnswerInModal(
            chatItem.answerContext,
            chatItem.questionPayload
          )
        }
        width="auto"
      />
      ;
    </StyledFlexRowLeft>
  );

  return (
    <StyledRightNav isSidebarChatExpanded={isSidebarChatExpanded}>
      <Joyride
        run={runTour}
        steps={CHAT_ANSWER_STEPS}
        stepIndex={stepIndex}
        tooltipComponent={JoyrideTooltip}
        continuous
        debug
        spotlightClicks
        showProgress={true}
        showSkipButton={true}
        callback={onClickCallback}
        disableScrollParentFix
      />
      <Joyride
        run={runTourDefaultAnswer}
        steps={DEFAULT_ANSWER_STEPS}
        stepIndex={stepIndexDefaultAnswer}
        tooltipComponent={JoyrideTooltip}
        isLastStep
        continuous
        debug
        spotlightClicks
        showProgress={true}
        showSkipButton={true}
        callback={onDefaultAnswerClickCallback}
        disableScrollParentFix
      />
      <StyledBotTitleSideNav>
        <CloseOutlined onClick={() => toggleRightNav(false)} />
        {name}
      </StyledBotTitleSideNav>
      <StyledChatComponent>
        <StyledChatContentHeader>
          <StyledTestBotInfo>
            <span>
              <CaretRightFilled />
              Test your bot here
            </span>
            <Row
              style={{ alignItems: 'center' }}
              justify="end"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 15 }}
              title={'More Options'}
            >
              <Col className="gutter-row" />
              <Col className="gutter-row" />
              <Col className="gutter-row">
                <Dropdown
                  trigger={['hover']}
                  onVisibleChange={() => handleChangeDropdownVisibility(true)}
                  overlay={moreOptions}
                  placement="bottomLeft"
                  visible={isChatMoreMenuOpen}
                >
                  <DashOutlined
                    style={{ fontSize: '19px', cursor: 'pointer' }}
                  />
                </Dropdown>
              </Col>
            </Row>
          </StyledTestBotInfo>
          <StyledSpacEvenlyFlexRow>
            <StyledChatViewButton
              onClick={() => handleChangeChatView(false)}
              isSelected={!isChatOnDevMode}
            >
              <EyeFilled />
              <span>{'Preview'}</span>
            </StyledChatViewButton>
            <StyledChatViewButton
              onClick={() => handleChangeChatView(true)}
              isSelected={isChatOnDevMode}
            >
              <ToolFilled />
              <span>{'Dev Mode'}</span>
            </StyledChatViewButton>
          </StyledSpacEvenlyFlexRow>
        </StyledChatContentHeader>
        <StyledChatList>
          <StyledSpinning
            spinning={loading || listening}
            tip={listening ? 'Listening...' : 'Just a moment...'}
          >
            {chatList?.map((chatItem, idx) => (
              <React.Fragment key={`chat-item-${idx}`}>
                {chatItem.question && (
                  <StyledChatQuestionContainer
                    key={`question-item-${idx}`}
                    isSidebarChatExpanded={isSidebarChatExpanded}
                  >
                    {isChatOnDevMode ? null : (
                      <StyledChatAction
                        overlay={chatQuestionMenu(chatItem, idx)}
                        placement="bottomLeft"
                        trigger={['click']}
                      >
                        <MoreOutlined />
                      </StyledChatAction>
                    )}
                    <StyledChatQuestion
                      isSidebarChatExpanded={isSidebarChatExpanded}
                      isChatOnDevMode={isChatOnDevMode}
                    >
                      {isChatOnDevMode ? (
                        <CodeBlock codeContent={chatItem.questionPayload} />
                      ) : (
                        chatItem.question?.question || chatItem.question
                      )}
                    </StyledChatQuestion>
                  </StyledChatQuestionContainer>
                )}
                {webSocket.askQuestionActions?.length > 0 &&
                  !isChatOnDevMode &&
                  !!typingExperience &&
                  webSocket.askQuestionActions
                    ?.filter(action => action.status === 'completed')
                    ?.map(action => (
                      <StyledWebSocketActionContainer>
                        <StyledWebSocketActionList className="success">
                          <span>
                            {action?.type?.charAt(0).toUpperCase() +
                              action?.type?.slice(1)}{' '}
                            <StyledInfoSequence
                              onClick={() =>
                                handleShowSelectedSequenceInModal(action)
                              }
                              title="View full details"
                            />
                          </span>
                        </StyledWebSocketActionList>
                      </StyledWebSocketActionContainer>
                    ))}

                {(sending === true || webSocket?.sending === true) &&
                  chatList.length - 1 === idx && (
                    <>
                      {webSocket.askQuestionActiveAction?.type &&
                        !!typingExperience && (
                          <StyledWebSocketActionContainer>
                            <StyledWebSocketAction>
                              <span>
                                {webSocket.askQuestionActiveAction?.type
                                  ?.charAt(0)
                                  .toUpperCase() +
                                  webSocket.askQuestionActiveAction?.type?.slice(
                                    1
                                  ) +
                                  '...'}
                              </span>
                            </StyledWebSocketAction>
                          </StyledWebSocketActionContainer>
                        )}
                      {webSocket.generatedAnswer ? null : (
                        <StyledLoader>
                          <StyledLoaderContainer>
                            <StyledLoaderDots />
                            <StyledLoaderDots />
                            <StyledLoaderDots />
                          </StyledLoaderContainer>
                        </StyledLoader>
                      )}
                    </>
                  )}

                {!isChatOnDevMode &&
                  chatItem.answer?.sequence?.map(sequence => (
                    <StyledWebSocketActionContainer>
                      <StyledWebSocketActionList>
                        <span>
                          {sequence.type?.charAt(0).toUpperCase() +
                            sequence?.type?.slice(1)}
                          <StyledInfoSequence
                            onClick={() =>
                              handleShowSelectedSequenceInModal(sequence)
                            }
                            title="View full details"
                          />
                        </span>
                      </StyledWebSocketActionList>
                    </StyledWebSocketActionContainer>
                  ))}

                {chatItem.answer ? (
                  <StyledChatAnswerContainer
                    key={`answer-item-${idx}`}
                    isSidebarChatExpanded={isSidebarChatExpanded}
                  >
                    {isChatOnDevMode
                      ? renderDevMode(chatItem)
                      : flipAnswer.includes(idx)
                      ? renderChatBubble(chatItem, chatItem.answer.text, idx)
                      : renderChatAnswer(chatItem, idx)}
                    <StyledMessageOptions
                      isSidebarChatExpanded={isSidebarChatExpanded}
                    >
                      <MessageOptions chatItem={chatItem} chatIndex={idx} />
                    </StyledMessageOptions>
                  </StyledChatAnswerContainer>
                ) : null}
              </React.Fragment>
            ))}
            {isQuickButtonsVisible ? renderQuickButtons() : null}
            <div ref={bottomChatAreaRef}></div>
          </StyledSpinning>
        </StyledChatList>
        <StyledChatInputContainer
          className={sending || webSocket?.sending === true ? 'disabled' : ''}
        >
          <form onSubmit={e => handleSubmit(e, 'ZSB Platform')}>
            <span className="ant-input-affix-wrapper ant-input-affix-wrapper-lg">
              <TextArea
                rows={1}
                value={chatQuestion}
                onChange={handleChange}
                placeholder="Ask here"
                disableSpan={true}
                autoSize={{ minRows: 1, maxRows: 2 }}
                style={{
                  fontSize: '16px !important',
                  overFlowY: 'auto',
                }}
                onPressEnter={e => handleSubmit(e, 'ZSB Platform')}
                noPaddingTop
                autoFocus
                disabled={sending || webSocket?.sending === true}
              />
              <span className="ant-input-suffix">
                {isSpeechAllowed ? (
                  !isSpeechActivated ? (
                    <AudioMutedOutlined onClick={toggleSpeak} />
                  ) : (
                    <>
                      <AudioOutlined onClick={toggleSpeak} />
                    </>
                  )
                ) : null}
              </span>
            </span>
            <Button
              disabled={sending || webSocket?.sending === true || listening}
              value=""
              type="submit"
              startIcon={<SendOutlined />}
            />
          </form>
        </StyledChatInputContainer>
      </StyledChatComponent>
    </StyledRightNav>
  );
};

SidebarChat.propTypes = {
  handleChatAskQuestion: PropTypes.func.isRequired,
  handleSeekAnswer: PropTypes.func.isRequired,
  handleShowAddTestSuiteModal: PropTypes.func,
  toggleRightNav: PropTypes.func,
  contextHistory: PropTypes.bool,
  setContextHistory: PropTypes.func,
  setMaxInteraction: PropTypes.func,
  maxInteraction: PropTypes.any,
};

export default SidebarChat;
