import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { apiService } from 'services/api.service';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { isExternalPageSelector } from 'selectors/user';
import useSelector from 'store/useSelector';

const useResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [showTokenField, setShowTokenField] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const isExternalPage = useSelector(isExternalPageSelector);

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const onChangeToken = e => {
    setToken(e.target.value);
  };

  const onTokenSubmit = async e => {
    e.preventDefault();

    setLoading(true);
    try {
      const res = await apiService.updatePasswordValidateToken(token);
      if (res && res.status === 200) {
        setLoading(false);
        history.push({
          pathname: '/confirm-new-password',
          state: { token },
        });
        setError(null);
      }
    } catch (err) {
      setLoading(false);
      return setError(err.message || DEFAULT_ERROR_MESSAGE);
    }

    return setLoading(false);
  };

  const onSubmit = async evt => {
    evt.preventDefault();
    setLoading(true);
    try {
      const res = await apiService.updatePassword(email);
      if (res && res.status === 200) {
        setLoading(false);
        setEmail('');
        setShowTokenField(true);
        setError(null);
      }
    } catch (err) {
      setLoading(false);
      return setError(err.message || DEFAULT_ERROR_MESSAGE);
    }
    return setLoading(false);
  };

  return {
    error,
    loading,
    onChangeEmail,
    onSubmit,
    showTokenField,
    onChangeToken,
    onTokenSubmit,
    email,
    token,
    isExternalPage,
  };
};

export default useResetPassword;
