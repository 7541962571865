import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FlexColWrapper, FlexRowWrapper } from './Variant.styles';
import VariantConditionForm from './VariantConditionForm';
import { DEFAULT_EDITOR, EDITOR_OPTIONS } from 'constants/answerbank/defaults';
import Select from 'components/Select';
import { PlusCircleTwoTone } from '@ant-design/icons';
import Editor from 'components/EditorAndViewer/Editor/Editor';

const Variants = ({
  answerId,
  variant,
  editor,
  editedHTML,
  editedRichTextAnswer,
  formFields,
  onUpdateVariant,
}) => {
  const [variantEditor, setVariantEditor] = useState(editor);
  const DEFAULT_ANSWER_VARIANT = {
    forms: [
      {
        key: variant.forms.length + 1 || 1,
        select1: '',
        select2: '',
        select3: [''],
      },
    ],
    displayAnswer: {
      html: '',
      rce: '',
    },
    editor: DEFAULT_EDITOR,
    key: variant.key + 1,
  };

  const handleUpdateVariantEditor = e => {
    setVariantEditor(e);
    onUpdateVariant({ ...variant, editor: e });
  };

  const handleAddFormField = () => {
    onUpdateVariant({
      ...variant,
      forms: [...variant.forms, ...DEFAULT_ANSWER_VARIANT.forms],
    });
  };

  const handleDeleteFormField = (formKey, isOnlyForm) => {
    if (isOnlyForm) {
      onUpdateVariant(variant, isOnlyForm);
    } else {
      const updatedForms = variant.forms.filter(f => f.key !== formKey);
      onUpdateVariant({
        ...variant,
        forms: updatedForms,
      });
    }
  };

  return (
    <FlexColWrapper>
      <FlexColWrapper>
        {formFields.map((f, idx) => {
          return (
            <VariantConditionForm
              isOnlyForm={formFields.length === 1}
              isFirstRow={idx === 0}
              form={f}
              key={idx}
              handleDelete={handleDeleteFormField}
            />
          );
        })}
      </FlexColWrapper>
      <FlexRowWrapper>
        <FlexColWrapper>
          <PlusCircleTwoTone onClick={handleAddFormField} />
        </FlexColWrapper>
      </FlexRowWrapper>
      <Select
        className="variant-selector"
        value={variantEditor}
        options={EDITOR_OPTIONS}
        onChange={handleUpdateVariantEditor}
        size="small"
        label={`Answer Variant ${variant.key} Editor:`}
      />
      <Editor
        style={{ width: '100%' }}
        editor={variantEditor}
        editedHTML={editedHTML}
        editedRichTextAnswer={editedRichTextAnswer}
        answerId={answerId}
        setRichTextAnswer={() => {}}
        updateFormattedAnswer={() => {}}
      />
    </FlexColWrapper>
  );
};

Variants.propTypes = {
  answerId: PropTypes.string.isRequired,
  editor: PropTypes.string.isRequired,
  editedHTML: PropTypes.string,
  editedRichTextAnswer: PropTypes.string,
  formFields: PropTypes.array.isRequired,
  onUpdateVariant: PropTypes.func.isRequired,
  variant: PropTypes.object.isRequired,
};

export default Variants;
