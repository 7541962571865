import { union } from 'lodash';

import {
  ADD_CATEGORY,
  BULK_MOVE_CATEGORY,
  SET_CATEGORY_MODAL_CLOSE,
  GET_CATEGORIES,
  SET_CATEGORY_MODAL_OPEN,
  UPDATE_CATEGORY,
  SET_SELECTED_CATEGORY_DETAILS,
  CLEAR_SELECTED_CATEGORY_DETAILS,
  IMPORT_CATEGORY_TEMPLATES,
  DELETE_CATEGORY,
} from 'store/action';
import { initialCategoryModalState } from 'store/initialState';
import {
  generateRandomColor,
  isCollectionValueHasDuplicate,
  stripUUID,
} from 'utils';
import { extractAnswerData, extractCategoryData } from '../helpers/bot/answers';
import { strippedString } from 'utils/stringManipulation';

export const categoriesReducer = (state, action) => {
  switch (action.type) {
    case GET_CATEGORIES: {
      const categories = action.payload.map(category => {
        return {
          jid: stripUUID(category.jid),
          name: category.context.name,
          color: category.context.color,
        };
      });
      return {
        ...state,
        bot: {
          ...state.bot,
          categories,
        },
      };
    }

    case ADD_CATEGORY: {
      const newCategory = extractCategoryData(action.payload);
      return {
        ...state,
        bot: {
          ...state.bot,
          categories: [...state.bot.categories, newCategory],
        },
      };
    }

    case DELETE_CATEGORY: {
      const { jid, isDeleteAnswer } = action.payload;
      let allAnswers = state.bot.answers;
      const updatedCategories = state.bot.categories?.filter(
        cat => cat.jid !== jid
      );

      if (isDeleteAnswer) {
        allAnswers = allAnswers.filter(answer => answer.categoryId !== jid);
      } else {
        allAnswers = allAnswers.map(answer => {
          if (answer.categoryId === jid) {
            return {
              ...answer,
              categoryId: null,
            };
          }
          return answer;
        });
      }

      return {
        ...state,
        bot: {
          ...state.bot,
          answers: allAnswers,
          categories: updatedCategories,
        },
      };
    }

    case UPDATE_CATEGORY:
      const newCategory = extractCategoryData(action.payload);
      const updatedCategories = state.bot.categories.map(category => {
        if (stripUUID(category.jid) === stripUUID(newCategory.jid)) {
          return newCategory;
        }
        return category;
      });
      return {
        ...state,
        bot: {
          ...state.bot,
          categories: [...updatedCategories],
        },
      };

    case BULK_MOVE_CATEGORY: {
      let answersArr = [];
      for (const item of state.bot.answers) {
        const updatedAnswers = action.payload.updatedAnswers;
        const isUpdatedAnswer = updatedAnswers.find(
          answer => answer.jid === item.jid
        );
        if (isUpdatedAnswer) {
          answersArr.push(isUpdatedAnswer);
        } else {
          answersArr.push(item);
        }
      }

      return {
        ...state,
        bot: {
          ...state.bot,
          answers: [...answersArr],
        },
      };
    }

    case SET_CATEGORY_MODAL_OPEN: {
      return {
        ...state,
        bot: {
          ...state.bot,
          selectedCategory: {
            ...initialCategoryModalState,
            color:
              action.payload.action === 'add' ? generateRandomColor() : null,
            isOpen: true,
            ...action.payload,
          },
        },
      };
    }

    case SET_SELECTED_CATEGORY_DETAILS: {
      const { name, isTemplate } = action.payload;
      const selectedCategory = state.bot.selectedCategory;

      const isCategoryOnTemplates = state.categoryTemplates.some(
        categoryTemplate =>
          categoryTemplate?.name?.toLowerCase() === name?.toLowerCase()
      );
      const hasDuplicate = isCollectionValueHasDuplicate(
        state.bot.categories,
        name,
        'name',
        action.payload.jid || selectedCategory.jid
      );
      const categoryNameErrors = hasDuplicate
        ? `${name} category already exists.`
        : !strippedString(name) &&
          typeof name !== 'undefined' &&
          !selectedCategory.isTemplate &&
          !isTemplate
        ? 'Category name is required.'
        : '';
      const categoryExistsOnTemplate = isCategoryOnTemplates
        ? 'This category already exist on templates.'
        : null;
      const categoryModalErrors = union(
        [categoryExistsOnTemplate, categoryNameErrors].filter(Boolean)
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          selectedCategory: {
            ...selectedCategory,
            ...action.payload,
            errors: categoryModalErrors,
          },
        },
      };
    }

    case CLEAR_SELECTED_CATEGORY_DETAILS: {
      if (!state.bot.selectedCategory || !state.bot.selectedCategory.isOpen) {
        return state;
      }
      return {
        ...state,
        bot: {
          ...state.bot,
          selectedCategory: {
            ...state.bot.selectedCategory,
            name: null,
            jid: null,
            color: null,
          },
        },
      };
    }

    case SET_CATEGORY_MODAL_CLOSE:
      return {
        ...state,
        bot: {
          ...state.bot,
          selectedCategory: initialCategoryModalState,
        },
      };

    case IMPORT_CATEGORY_TEMPLATES: {
      const { answer: answersFromCategorytemplate, category } = action.payload;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const answers = (
        extractAnswerData(answersFromCategorytemplate, isBotOpenAIEnabled) || []
      ).filter(Boolean);

      const newAnswers = [...state.bot.answers, ...answers]
        .map(fromCombinedAnswer => {
          const existingAnswerFromStore = state.bot.answers.find(
            answerFromStore =>
              stripUUID(answerFromStore.jid) ===
              stripUUID(fromCombinedAnswer.jid)
          );
          const existingAnswerFromCategoryTemplatePayload = answers.find(
            answerFromCategory =>
              stripUUID(answerFromCategory.jid) ===
              stripUUID(fromCombinedAnswer.jid)
          );
          const categoryIdFromCombinedAnswer =
            fromCombinedAnswer?.categoryId || fromCombinedAnswer?.categoryId;
          const categoryIdFromCategoryTempExistingAnswer =
            existingAnswerFromCategoryTemplatePayload?.categoryId ||
            existingAnswerFromCategoryTemplatePayload?.categoryId ||
            existingAnswerFromCategoryTemplatePayload?.category_id;

          if (
            existingAnswerFromStore &&
            existingAnswerFromCategoryTemplatePayload &&
            !Boolean(categoryIdFromCombinedAnswer) &&
            Boolean(categoryIdFromCategoryTempExistingAnswer)
          ) {
            // if current answer exists to store and payload
            // return existing/previous answer
            // then attach categoryId from payload of matched answer
            return {
              ...fromCombinedAnswer,
              categoryId: categoryIdFromCategoryTempExistingAnswer,
            };
          } else if (
            !existingAnswerFromCategoryTemplatePayload ||
            !existingAnswerFromStore
          ) {
            // return answer if it doesn't exists
            return fromCombinedAnswer;
          } else {
            return null;
          }
        })
        .filter(Boolean);

      const newCategories = category
        ?.map(category => {
          return {
            jid: stripUUID(category.jid),
            name: category.context.name,
            color: category.context.color,
          };
        })
        .filter(Boolean);
      const updatedBots = state.bots.map(bot => {
        if (bot.jid === state.bot.jid) {
          return {
            ...bot,
            answerCount: newAnswers.length,
          };
        }
        return bot;
      });
      return {
        ...state,
        bots: updatedBots,
        bot: {
          ...state.bot,
          answerCount: newAnswers.length,
          categories: [...state.bot.categories, ...newCategories],
          answers: newAnswers,
        },
      };
    }

    default:
      return state;
  }
};
