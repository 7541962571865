export const IMPORT_QUESTION = [
  {
    content: (
      <div>
        <h3
          style={{
            display: 'inline-block',
          }}
        >
          Import Question
        </h3>
        <p>
          You can link question to an answer by simply importing the file here.
        </p>
        <p>Drag your files or select a file.</p>
      </div>
    ),
    target: '.import-question-button',
    disableBeacon: true,
    hideFooter: true,
    placement: 'auto',
  },
  {
    content: (
      <div>
        <h3
          style={{
            display: 'inline-block',
          }}
        >
          Import Question
        </h3>
        <p>Click next to start importing questions.</p>
      </div>
    ),
    target: '.next-step-importquestion-button',
    disableBeacon: true,
    hideFooter: true,
    placement: 'auto',
  },
];
