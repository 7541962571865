import { useEffect, useContext, useState } from 'react';
import { message } from 'antd';

import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import { botJIDSelector, isPageReadySelector } from 'selectors/bot';
import { aiTools } from 'services/aiTools.service';
import {
  CLOSE_AI_TOOL_MODAL,
  DELETE_AI_TOOL,
  OPEN_AI_TOOL_MODAL,
  SET_AI_TOOLS,
  SET_SELECTED_AI_TOOL,
} from 'store/action';
import { DEFAULT_ERROR_MESSAGE, GET_DATA_ERROR } from 'constants/error';
import { aiToolsSelector } from 'selectors/bot/aiTools';
import { aiToolModalSelector } from 'selectors/bot/ui';

const INITIAL_SELECTED_TOOL_STATE = {
  jid: null,
  name: null,
  toDelete: false,
  loading: false,
};

const useFunctions = () => {
  const [state, dispatch] = useContext(Context);
  const isPageReady = useSelector(isPageReadySelector);
  const botJID = useSelector(botJIDSelector);
  const allAITools = useSelector(aiToolsSelector);
  const toolModal = useSelector(aiToolModalSelector);
  const isToolModalOpen = toolModal.isOpen;

  const { sentinel } = state;

  const [selectedTool, setSelectedTool] = useState(INITIAL_SELECTED_TOOL_STATE);

  const getTools = async () => {
    try {
      const res = await aiTools.getTools(botJID);
      dispatch({
        type: SET_AI_TOOLS,
        payload: res.data.report,
      });
    } catch (error) {
      message.error(GET_DATA_ERROR);
    }
  };

  const handleViewTool = tool => {
    dispatch({
      type: SET_SELECTED_AI_TOOL,
      payload: tool,
    });
  };

  const handleAddTool = () => {
    dispatch({
      type: OPEN_AI_TOOL_MODAL,
      payload: {
        action: 'add',
      },
    });
  };

  const handleDeleteTool = async () => {
    setSelectedTool({ ...selectedTool, loading: true });
    try {
      await aiTools.deleteTool(selectedTool.jid);
      dispatch({
        type: DELETE_AI_TOOL,
        payload: selectedTool.jid,
      });
      handleCloseDeleteModal();
    } catch (error) {
      message.error(DEFAULT_ERROR_MESSAGE);
    }
  };

  const handleCloseToolModal = () => {
    dispatch({
      type: CLOSE_AI_TOOL_MODAL,
    });
  };

  const handleCloseDeleteModal = () => {
    setSelectedTool(INITIAL_SELECTED_TOOL_STATE);
  };

  const handleClickDelete = ({ name, jid }) => {
    setSelectedTool({
      name,
      jid,
      toDelete: true,
    });
  };

  useEffect(() => {
    if (sentinel && isPageReady) {
      getTools();
    }
  }, [sentinel, isPageReady]);

  return {
    allAITools,
    isPageReady,
    isToolModalOpen,
    selectedTool,
    handleClickDelete,
    handleViewTool,
    handleAddTool,
    handleCloseToolModal,
    handleDeleteTool,
    handleCloseDeleteModal,
  };
};

export default useFunctions;
