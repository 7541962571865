import React from 'react';
import { Switch } from 'antd';

import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';
import Input from 'components/Input';
import {
  StyledErrorBottomText,
  StyledSwitchWrapper,
} from '../../CustomizeBot.style';
import PanelActionButtons from '../../PanelActionButtons';
import { useConfigPanel } from './hooks';

const BOT_PANEL_KEY_PREFIX = 'bot-customizer-panel-';

const ConfigPanel = () => {
  const {
    handleBlur,
    handleChangeSwitch,
    handleInputChange,
    handlePanelChange,
    authenticatedUser,
    isDemo,
    tempTitle,
    tempSubitle,
    tempPlaceholder,
    tempWelcomeMessage,
    subtitle,
    botTitle,
    welcomeMessage,
    placeholder,
  } = useConfigPanel();

  return (
    <StyledFlexLeftColumn>
      <Input
        value={tempTitle}
        label={'Bot Title'}
        placeholder={'Enter widget title'}
        full
        onChange={handleInputChange('title')}
        onBlur={handleBlur('title')}
      />
      {!tempTitle ? (
        <StyledErrorBottomText>
          {'Bot Title is Required.'}
        </StyledErrorBottomText>
      ) : null}
      <Input
        value={tempSubitle}
        defaultValue={tempSubitle}
        label={'Subitle'}
        placeholder={'Enter widget subitle'}
        full
        onChange={handleInputChange('subtitle')}
        onBlur={handleBlur('subtitle')}
      />
      {!tempSubitle ? (
        <StyledErrorBottomText>{'Subtitle is Required.'}</StyledErrorBottomText>
      ) : null}
      <Input
        label={'Placeholder'}
        placeholder={'Enter placeholder'}
        full
        value={tempPlaceholder}
        defaultValue={tempPlaceholder}
        onChange={handleInputChange('placeholder')}
        onBlur={handleBlur('placeholder')}
      />
      {!tempPlaceholder ? (
        <StyledErrorBottomText>
          {'Placeholder is Required.'}
        </StyledErrorBottomText>
      ) : null}
      <Input
        label={'Welcome Message'}
        placeholder={'Enter welcome message'}
        full
        value={tempWelcomeMessage}
        defaultValue={tempWelcomeMessage}
        onChange={handleInputChange('welcomeMessage')}
        onBlur={handleBlur('welcomeMessage')}
      />
      {!tempWelcomeMessage ? (
        <StyledErrorBottomText>
          {'Welcome Message is Required.'}
        </StyledErrorBottomText>
      ) : null}

      {isDemo && (
        <StyledSwitchWrapper>
          <div>
            <b>{'Allow Authenticated User'}</b>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={authenticatedUser}
              onChange={handleChangeSwitch}
            />
          </div>
        </StyledSwitchWrapper>
      )}

      <PanelActionButtons
        disabled={!botTitle || !subtitle || !placeholder || !welcomeMessage}
        nextPanelKey={`${BOT_PANEL_KEY_PREFIX}2`}
        panelName="config"
        onPanelChange={handlePanelChange}
      />
    </StyledFlexLeftColumn>
  );
};

export default ConfigPanel;
