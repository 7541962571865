import styled from 'styled-components';
import { FileExclamationTwoTone } from '@ant-design/icons';
import Button from 'components/Button';
import { cssVariables } from 'styles/root';

export const StyledLimitNoticeWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const StyledIcon = styled(FileExclamationTwoTone)`
  font-size: 75px;
`;

export const StyledH1 = styled.h1`
  font-weight: ${cssVariables.font.bold};
  font-size: 18px;
  margin: 0px;
`;

export const StyledButton = styled(Button)`
  width: auto;
`;
