import React from 'react';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

const StyledHR = styled.div`
  width: 100%;
  border: none;
  border-top: 1px solid ${cssVariables.gray2};
`;

const GenericHR = props => {
  const { ...rest } = props;
  return <StyledHR {...rest} />;
};

export default GenericHR;
