export const DEFAULT_ANSWER_STEPS = [
  {
    content: (
      <div>
        <h3>Default Answer</h3>
        <p>
          This is the Fallback response which the bot provides when none of
          answers are found to be relevant for the question by the AI, based on
          a threshold score defined for the bot.
        </p>
      </div>
    ),
    target: '.default-chat-answer',
    disableBeacon: true,
    placement: 'auto',
  },
  {
    content: (
      <div>
        <h3>Threshold Score</h3>
        <p>
          We have defined a default Threshold score of 0.1. You can change the
          threshold score as well as Fallback Response for your bot. This option
          is in the Bot settings section in the left navigation bar.
        </p>
      </div>
    ),
    target: '.default-chat-score',
    disableBeacon: true,
    placement: 'auto',
  },
  {
    content: (
      <div>
        <h3>Use Draft</h3>
        <p>Test your bot using draft answers.</p>
      </div>
    ),
    target: '.test-answer-use-draft',
    disableBeacon: true,
    placement: 'auto',
  },
];

export const CHAT_ANSWER_STEPS = [
  {
    content: (
      <div>
        <h3>Bot Response</h3>
        <p>
          ZSB Bot uses an advanced-first of its kind, AI model to select the
          best response for the question from the answer bank options.
        </p>
        <h3>Anchor and Display Answer</h3>
        <p>The Answer has two components:</p>
        <p>
          The <b>Anchor Answer</b> is used by the AI model to score the
          relevance of an answer to the question being asked. It is recommended
          to keep this limited to text.
        </p>
        <p>
          <b>Display Answer</b> is what the bot provides as response. This
          provides you the ability to add rich text content, images, links to
          the answer without affecting the AI model (or your could even use it
          to trick the AI 😉).
        </p>
      </div>
    ),
    target: '.chat-answer',
    disableBeacon: true,
    placement: 'auto',
  },
  {
    content: (
      <div>
        <h3>Score</h3>
        <p>
          The bot matches the question with all answers in the answer bank and
          returns the answer with the highest relevance score assigned by our
          AI.
        </p>
        <p>
          The 'Use Another Response' option in the menu (⋮) next to the Answer
          can be used to check what the AI has scored for other answers.
        </p>
      </div>
    ),
    target: '.chat-score',
    disableBeacon: true,
    placement: 'auto',
  },
  {
    content: (
      <div>
        <h3>Edit Answer</h3>
        <p>
          Not satisfied with the answer? Edit your bot's Anchor or Display
          Answer quickly from here or from within the Answer Bank. You also have
          the ability to nudge the AI's knowledge to make it respond with
          another answer.
        </p>
        <p>
          Try the 'Use Another Response' option in the menu next to the answer.
          Please note: Linking could change how the bot responds for other
          questions too.
        </p>
      </div>
    ),
    target: '.chat-edit-answer',
    disableBeacon: true,
    placement: 'auto',
  },
  {
    content: (
      <div>
        <p>
          The below options are available in the menu in addition to Edit Answer
          and Use Another Response.
        </p>
        <h3>Seek</h3>
        <p>
          You can use Seek, if you have a big list in the answer bank and want
          to navigate to the current answer.
        </p>
        <h3>Hide Display Answer</h3>
        <p>
          This flips the Display answer to show the Anchor Answer. You can
          revert by selecting Show Display answer.
        </p>
      </div>
    ),
    target: '.chat-action',
    disableBeacon: true,
    placement: 'auto',
  },
];
