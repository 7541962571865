import { orderBy } from 'lodash';
import {
  OPEN_ANSWER_EDITOR_CREATE_MODE,
  CLOSE_ANSWER_EDITOR,
  CLOSE_RESPONSE_PICKER_MODAL,
  CLOSE_SIMILAR_QUESTIONS_MODAL,
  SET_ANSWER_EDITOR_LINKED_QUESTIONS,
  SET_ANSWER_EDITOR_SELECTED_ANSWER,
  SHOW_RESPONSE_PICKER_MODAL,
  SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD,
  SHOW_SIMILAR_QUESTIONS_MODAL,
  UPDATE_ANSWER_EDITOR_LINKED_QUESTIONS,
  UPDATE_RESPONSE_PICKER_ANSWERS,
  UPDATE_SIMILAR_QUESTIONS_AFTER_HARD_LINK,
  UPDATE_SIMILAR_QUESTIONS_MODAL,
  UPDATE_ANSWER_EDITOR_ANSWER,
  SHOW_QUESTION_EDITOR,
  CLOSE_QUESTION_EDITOR,
  PUSH_ANSWER_AS_RESPONSE_PICKER_BEST_ANSWER,
  REMOVE_HARD_LINK_FROM_RESPONSE_PICKER,
  UPDATE_RESPONSE_PICKER_SIMILAR_QUESTIONS,
  UPDATE_QUESTION_EDITOR,
  PUSH_AS_QUESTION_EDITOR_SELECTED_ANSWER,
  UPDATE_QUESTION_EDITOR_SIMILAR_QUESTIONS,
  REMOVE_HARD_LINK_FROM_QUESTION_EDITOR,
  SET_ANSWER_EDITOR_CARD_INSIGHTS,
  SET_ANSWER_EDITOR_GRAPH_INSIGHTS,
  SET_ANSWER_EDITOR_TABLE_INSIGHTS,
  SET_CHAT_MODE,
  TOGGLE_EXPAND_SIDEBAR_CHAT,
  CLOSE_CHAT_ANSWER_IN_MODAL,
  SHOW_CHAT_ANSWER_IN_MODAL,
  SET_FILE_EDITOR_SELECTED_FILE,
  CLOSE_FILE_EDITOR,
  CLOSE_WEBSITE_EDITOR,
  UPDATE_FILE_EDITOR_FILE,
  SHOW_CHAT_SELECTED_SEQUENCE_IN_MODAL,
  CLOSE_CHAT_SELECTED_SEQUENCE_IN_MODAL,
  SET_SELECTED_AI_TOOL,
  OPEN_AI_TOOL_MODAL,
  CLOSE_AI_TOOL_MODAL,
  ADD_AI_TOOL,
  EDIT_AI_TOOL,
  SET_SHOW_WIDGET_AVATAR_PICKER_MODAL,
  CLOSE_WIDGET_AVATAR_PICKER_MODAL,
  OPEN_IMAGE_VIEWER_MODAL,
  CLOSE_IMAGE_VIEWER_MODAL,
  SET_SHOW_API_KEY_MODAL,
  CLOSE_API_KEY_MODAL,
  SET_PAGE_DETAILS,
  SET_WEBSITE_EDITOR_SELECTED_WEBSITE,
  UPDATE_WEBSITE_EDITOR_WEBSITE,
} from 'store/action';
import {
  initialAnswerEditorModalState,
  initialChatAnswerInModal,
  initialChatSelectedSequenceInModal,
  initialFileEditorModalState,
  initialImageViewerModal,
  initialQuestionEditorModalState,
  initialResponsePickerModalState,
  initialWebsitEditorModalState,
  initialWebsiteSelectedPage,
} from 'store/initialState';
import {
  createESAggregationsData,
  createESAnalyticsTableData,
  getESChartData,
} from '../helpers/bot/analytics';
import {
  DEFAULT_ANSWER_VERSION,
  DEFAULT_EDITOR,
  MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION,
  ZSB_CHAT_BREAKER_ENCONDING,
} from 'constants/answerbank/defaults';
import {
  categorizedWebsitePages,
  extractAnswerData,
  extractScannedObject,
  extractWebsiteScanList,
  pushCurrentAnswerAsTopAnswer,
} from '../helpers/bot/answers';
import { extractQuestionData } from '../helpers/bot/questions';
import { UTCToLocal, getLocalTimeString } from 'utils/dates';
import { parseBoolean } from 'utils/dataTypes';
import { DEFAULT_URL_OUTPUT_FIElDS, NEW_OBJECT_ITEM } from 'constants/aiTools';
import { getNodesFromArray, handlePageResizing } from 'utils';

export const botUIReducer = (state, action) => {
  switch (action.type) {
    case SHOW_RESPONSE_PICKER_MODAL: {
      const { answerId, answers } = action.payload;
      const newResponsePickersAnswers = answerId
        ? pushCurrentAnswerAsTopAnswer(answers, answerId)
        : answers;
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              responsePickerModal: {
                ...initialResponsePickerModalState,
                isOpen: true,
                ...action.payload,
                answers: newResponsePickersAnswers,
              },
            },
          },
        },
      };
    }

    case CLOSE_RESPONSE_PICKER_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              responsePickerModal: initialResponsePickerModalState,
            },
          },
        },
      };

    case UPDATE_RESPONSE_PICKER_ANSWERS: {
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const { answerId, answers, ...rest } = action.payload;
      const extractedAnswers = answers[0]?.score
        ? extractAnswerData(answers, isBotOpenAIEnabled)
        : answers;
      const newResponsePickersAnswers = answerId
        ? pushCurrentAnswerAsTopAnswer(extractedAnswers, answerId)
        : extractedAnswers;

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              responsePickerModal: {
                ...state.bot.ui.modals.responsePickerModal,
                ...rest,
                answers: newResponsePickersAnswers,
              },
            },
          },
        },
      };
    }

    case SHOW_SIMILAR_QUESTIONS_MODAL: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              similarQuestionsModal: {
                isOpen: true,
                ...action.payload,
              },
            },
          },
        },
      };
    }

    case CLOSE_SIMILAR_QUESTIONS_MODAL: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              similarQuestionsModal: {
                isOpen: false,
                data: [],
              },
            },
          },
        },
      };
    }

    case UPDATE_SIMILAR_QUESTIONS_MODAL: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              similarQuestionsModal: {
                ...state.bot.ui.modals.similarQuestionsModal,
                ...action.payload,
              },
            },
          },
        },
      };
    }

    case UPDATE_ANSWER_EDITOR_LINKED_QUESTIONS: {
      const answer = getNodesFromArray(action.payload);
      const updatedQuestion =
        state.bot.ui.modals.answerEditorModal?.questionsTab.map(item => {
          if (item?.jid === answer.jid) {
            return answer;
          }
          return item;
        });
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              answerEditorModal: {
                ...state.bot.ui.modals.answerEditorModal,
                questionsTab: updatedQuestion,
              },
            },
          },
        },
      };
    }

    case UPDATE_ANSWER_EDITOR_ANSWER: {
      const {
        similarAnswers: similarAnswerFromPayload,
        version,
        ...rest
      } = action.payload;
      let similarAnswers = null;
      const hasReachedMaxScore = similarAnswerFromPayload.some(
        response => response.score > MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION
      );
      if (similarAnswerFromPayload) {
        similarAnswers = orderBy(similarAnswerFromPayload, 'score', 'desc');
      }
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              answerEditorModal: {
                ...state.bot.ui.modals.answerEditorModal,
                answerTab: {
                  ...state.bot.ui.modals.answerEditorModal.answerTab,
                  ...rest,
                  version: hasReachedMaxScore
                    ? 'draft'
                    : version || DEFAULT_ANSWER_VERSION,
                  similarAnswers: similarAnswers || [],
                  isVersionToggleDisabled: hasReachedMaxScore,
                },
              },
            },
          },
        },
      };
    }

    case UPDATE_FILE_EDITOR_FILE: {
      const { version } = action.payload;
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              fileEditorModal: {
                ...state.bot.ui.modals.fileEditorModal,
                file: {
                  ...state.bot.ui.modals.fileEditorModal.file,
                  version: version || DEFAULT_ANSWER_VERSION,
                },
              },
            },
          },
        },
      };
    }

    case UPDATE_WEBSITE_EDITOR_WEBSITE: {
      const { version } = action.payload;
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              websiteEditorModal: {
                ...state.bot.ui.modals.websiteEditorModal,
                website: {
                  ...state.bot.ui.modals.websiteEditorModal.website,
                  version: version || DEFAULT_ANSWER_VERSION,
                },
              },
            },
          },
        },
      };
    }

    case SET_ANSWER_EDITOR_LINKED_QUESTIONS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              answerEditorModal: {
                ...state.bot.ui.modals.answerEditorModal,
                questionsTab: action.payload,
              },
            },
          },
        },
      };
    }

    case SET_ANSWER_EDITOR_SELECTED_ANSWER: {
      const { answer, args } = action.payload;
      const lastEdited = UTCToLocal(new Date(answer.lastEdited));
      const htmlString = Array.isArray(answer.show_html)
        ? answer.show_html.join(ZSB_CHAT_BREAKER_ENCONDING)
        : String(answer.show_html);

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              answerEditorModal: {
                isOpen: true,
                answerTab: {
                  html: htmlString,
                  rich_text: htmlString,
                  rich_html_text: answer.show_html || answer.show_text,
                  text: answer.text,
                  type: answer.type,
                  categoryId: answer.categoryId,
                  id: args === 'clone' ? null : answer.jid,
                  editor: answer.editor || DEFAULT_EDITOR,
                  idx: Number.isInteger(args) ? args : undefined,
                  clone: args === 'clone',
                  quickReply: answer.quickReply,
                  requestAgent: parseBoolean(answer.requestAgent),
                  quickReplyOptions: answer.quickReplyOptions,
                  version: answer?.version || DEFAULT_ANSWER_VERSION,
                  lastEdited: answer.lastEdited
                    ? getLocalTimeString(lastEdited)
                    : null,
                },
              },
            },
          },
        },
      };
    }

    case SET_FILE_EDITOR_SELECTED_FILE: {
      const { file, args } = action.payload;
      const lastEdited = UTCToLocal(new Date(file.lastEdited));

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              fileEditorModal: {
                isOpen: true,
                file: {
                  show_text: [],
                  show_html: [],
                  file_name: file.text,
                  id: file.jid,
                  idx: Number.isInteger(args) ? args : undefined,
                  clone: args === 'clone',
                  version: file?.version || DEFAULT_ANSWER_VERSION,
                  lastEdited: file.lastEdited
                    ? getLocalTimeString(lastEdited)
                    : null,
                },
              },
            },
          },
        },
      };
    }

    case SET_ANSWER_EDITOR_CARD_INSIGHTS: {
      const { data, startDate, endDate } = action.payload;
      const aggregations = createESAggregationsData(data);

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              answerEditorModal: {
                ...state.bot.ui.modals.answerEditorModal,
                insightsTab: {
                  ...state.bot.ui.modals.answerEditorModal.insightsTab,
                  aggregations,
                  startDate,
                  endDate,
                },
              },
            },
          },
          analytics: {
            ...state.bot.analytics,
            aggregations: state.bot?.analytics?.aggregations || aggregations,
            startDate,
            endDate,
          },
        },
      };
    }

    case SET_ANSWER_EDITOR_GRAPH_INSIGHTS: {
      const { data, startDate, endDate } = action.payload;
      const graph = getESChartData(startDate, endDate, data);

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              answerEditorModal: {
                ...state.bot.ui.modals.answerEditorModal,
                insightsTab: {
                  ...state.bot.ui.modals.answerEditorModal.insightsTab,
                  graph,
                },
              },
            },
          },
        },
      };
    }

    case SET_ANSWER_EDITOR_TABLE_INSIGHTS: {
      const { data } = action.payload;
      const tableDataObj = createESAnalyticsTableData(data);

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              answerEditorModal: {
                ...state.bot.ui.modals.answerEditorModal,
                insightsTab: {
                  ...state.bot.ui.modals.answerEditorModal.insightsTab,
                  table: {
                    ...state.bot.ui.modals.answerEditorModal.table,
                    ...tableDataObj,
                  },
                },
              },
            },
          },
        },
      };
    }

    case OPEN_ANSWER_EDITOR_CREATE_MODE: {
      const { similarAnswers: similarAnswerFromPayload, ...rest } =
        action.payload;
      let similarAnswers = null;
      if (similarAnswerFromPayload) {
        similarAnswers = orderBy(similarAnswerFromPayload, 'score', 'desc');
      }
      const hasReachedMaxScore = similarAnswerFromPayload.some(
        response => response.score > MAX_ANSWER_SCORE_TO_DISABLE_FINAL_VERSION
      );
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              answerEditorModal: {
                isOpen: true,
                createMode: true,
                answerTab: {
                  ...rest,
                  similarAnswers: similarAnswers || [],
                  isVersionToggleDisabled: hasReachedMaxScore,
                },
              },
            },
          },
        },
      };
    }

    case CLOSE_ANSWER_EDITOR: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              answerEditorModal: initialAnswerEditorModalState,
            },
          },
        },
      };
    }

    case CLOSE_FILE_EDITOR: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              fileEditorModal: initialFileEditorModalState,
            },
          },
        },
      };
    }

    case CLOSE_WEBSITE_EDITOR: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              websiteEditorModal: initialWebsitEditorModalState,
            },
          },
          websiteSelectedPages: initialWebsiteSelectedPage,
        },
      };
    }

    case SHOW_QUESTION_EDITOR: {
      const {
        similarQuestions,
        answerId,
        newQuestion,
        isHardLinked,
        answersWithScore,
        jid,
        ...rest
      } = action.payload;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const questionJID = state.bot?.ui?.modals?.questionEditorModal?.jid;
      const showDraftResponses = state.bot.showDraftResponses;

      const extractedQuestions = extractQuestionData(similarQuestions) || [];
      const allQuestions = state.bot.questions || [];
      const matchQuestion = allQuestions?.find(item => item.jid === jid);
      const linkedQuestionVersion =
        jid && matchQuestion?.version ? matchQuestion.version : 'draft';

      const extractedAnswers =
        (answersWithScore && answersWithScore[0]?.score) ||
        Array.isArray(answersWithScore)
          ? extractAnswerData(answersWithScore, isBotOpenAIEnabled)
          : answersWithScore || [];

      const allAnswers = !showDraftResponses
        ? extractedAnswers.filter(({ version }) => version === 'final')
        : extractedAnswers;

      const sortedAnswers = pushCurrentAnswerAsTopAnswer(allAnswers, answerId);

      const filteredQuestions = extractedQuestions.filter(
        item => item.jid !== questionJID
      );
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              questionEditorModal: {
                jid,
                isOpen: true,
                ...rest,
                similarQuestions: filteredQuestions,
                answerId,
                answersWithScore: sortedAnswers,
                isHardLinked,
                version:
                  rest.version ||
                  state.bot.ui.modals.questionEditorModal?.version ||
                  linkedQuestionVersion,
              },
            },
          },
        },
      };
    }

    case UPDATE_QUESTION_EDITOR: {
      const { answersWithScore, answerId, jid, ...rest } = action.payload;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const showDraftResponses = state.bot.showDraftResponses;
      const allQuestions = state.bot.questions || [];
      let linkedQuestionVersion = 'draft';
      if (jid) {
        const matchQuestion = allQuestions.find(item => item.jid === jid);
        if (matchQuestion) {
          linkedQuestionVersion = matchQuestion?.version;
        }
      }
      const extractedAnswers =
        (answersWithScore && answersWithScore[0]?.score) ||
        Array.isArray(answersWithScore)
          ? extractAnswerData(answersWithScore, isBotOpenAIEnabled)
          : answersWithScore || [];

      const allAnswers = !showDraftResponses
        ? extractedAnswers.filter(({ version }) => version === 'final')
        : extractedAnswers;

      const sortedAnswers = pushCurrentAnswerAsTopAnswer(allAnswers, answerId);
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              questionEditorModal: {
                ...state.bot.ui.modals.questionEditorModal,
                ...rest,
                answerId,
                answersWithScore: sortedAnswers,
                version:
                  rest.version ||
                  state.bot.ui.modals.questionEditorModal?.version ||
                  linkedQuestionVersion,
              },
            },
          },
        },
      };
    }

    case UPDATE_QUESTION_EDITOR_SIMILAR_QUESTIONS: {
      const { similarQuestions, answerId, newQuestion, jid, ...rest } =
        action.payload;
      const questionJID = state.bot?.ui?.modals?.questionEditorModal?.jid;
      const extractedQuestions = extractQuestionData(similarQuestions) || [];

      const filteredQuestions = extractedQuestions.filter(
        item => item.jid !== questionJID
      );

      const allQuestions = state.bot.questions || [];
      let linkedQuestionVersion = 'draft';
      if (jid) {
        const matchQuestion = allQuestions.find(item => item.jid === jid);
        if (matchQuestion) {
          linkedQuestionVersion = matchQuestion?.version;
        }
      }

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              questionEditorModal: {
                ...state.bot.ui.modals.questionEditorModal,
                ...rest,
                newQuestion,
                similarQuestions: filteredQuestions || [],
                version: linkedQuestionVersion,
              },
            },
          },
        },
      };
    }

    case PUSH_AS_QUESTION_EDITOR_SELECTED_ANSWER: {
      const { answersWithScore, answerId, ...rest } = action.payload;
      const sortedAnswers = pushCurrentAnswerAsTopAnswer(
        answersWithScore,
        answerId
      );
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              questionEditorModal: {
                ...state.bot.ui.modals.questionEditorModal,
                answersWithScore: sortedAnswers,
                answerId,
                answersHasChanged: true,
              },
            },
          },
        },
      };
    }

    case CLOSE_QUESTION_EDITOR: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              questionEditorModal: initialQuestionEditorModalState,
            },
          },
        },
      };
    }

    case SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD: {
      const { answerId, answers, itemToChange, ...rest } = action.payload;
      const isBotOpenAIEnabled = state.bot?.useOpenAI;
      const showDraftResponses = state.bot.showDraftResponses;
      const extractedAnswers =
        answers[0]?.score || Array.isArray(answers)
          ? extractAnswerData(answers, isBotOpenAIEnabled)
          : answers;
      const allAnswers = !showDraftResponses
        ? extractedAnswers.filter(({ version }) => version === 'final')
        : extractedAnswers;
      const sortedAnswers = pushCurrentAnswerAsTopAnswer(allAnswers, answerId);

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              responsePickerModal: {
                ...state.bot.ui.modals.responsePickerModal,
                itemToChange: {
                  ...state.bot.ui.modals.responsePickerModal.itemToChange,
                  ...itemToChange,
                  answer: itemToChange.answer || sortedAnswers[0],
                },
                ...rest,
                isOpen: true,
                answers: sortedAnswers,
              },
            },
          },
        },
      };
    }

    case UPDATE_RESPONSE_PICKER_SIMILAR_QUESTIONS: {
      const { similarQuestions, questionText, currentQuestion } =
        action.payload;
      // exclude current linked answer
      const filteredSimilarQuestions = similarQuestions.filter(
        ({ question }) => question.jid !== currentQuestion
      );
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              responsePickerModal: {
                ...state.bot.ui.modals.responsePickerModal,
                similarQuestions: filteredSimilarQuestions,
                itemToChange: {
                  ...state.bot.ui.modals.responsePickerModal.itemToChange,
                  question: {
                    ...state.bot.ui.modals.responsePickerModal.itemToChange
                      .question,
                    text: questionText,
                  },
                },
              },
            },
          },
        },
      };
    }

    case PUSH_ANSWER_AS_RESPONSE_PICKER_BEST_ANSWER: {
      const { answerId, answers, questionText, ...rest } = action.payload;
      const newAnswersOrder = pushCurrentAnswerAsTopAnswer(
        answers,
        answerId || answers[0]?.jid
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              responsePickerModal: {
                ...state.bot.ui.modals.responsePickerModal,
                ...rest,
                isOpen: true,
                answers: newAnswersOrder,
                modalStatus: 'Save to apply your changes',
                itemToChange: {
                  ...state.bot.ui.modals.responsePickerModal.itemToChange,
                  question: {
                    ...state.bot.ui.modals.responsePickerModal.itemToChange
                      ?.question,
                    text: questionText,
                  },
                },
              },
            },
          },
        },
      };
    }

    case REMOVE_HARD_LINK_FROM_RESPONSE_PICKER: {
      const filteredQuestions = state.bot.questions.filter(
        item => item.question.jid !== action.payload
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          questions: filteredQuestions,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              responsePickerModal: {
                ...state.bot.ui.modals.responsePickerModal,
                isHardLinked: false,
              },
            },
          },
        },
      };
    }

    case REMOVE_HARD_LINK_FROM_QUESTION_EDITOR: {
      const filteredQuestions = state.bot.questions.filter(
        item => item.question.jid !== action.payload
      );

      return {
        ...state,
        bot: {
          ...state.bot,
          questions: filteredQuestions,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              questionEditorModal: {
                ...state.bot.ui.modals.questionEditorModal,
                isHardLinked: false,
                jid: null,
                action: 'add',
              },
            },
          },
        },
      };
    }

    case UPDATE_SIMILAR_QUESTIONS_AFTER_HARD_LINK: {
      const { questionId, data } = action.payload;
      if (!questionId && data?.length) {
        const hasHardLinkFromList = data.some(item => item.score <= 1);
        return {
          ...state,
          bot: {
            ...state.bot,
            ui: {
              ...state.bot.ui,
              modals: {
                ...state.bot.ui.modals,
                similarQuestionsModal: {
                  ...state.bot.ui.modals.similarQuestionsModal,
                  data: data,
                  hasHardLinkFromList,
                },
              },
            },
          },
        };
      }

      const similarQuestionsData =
        state.bot.ui.modals.similarQuestionsModal.data;
      const newSimilarQuestionsData = similarQuestionsData.filter(
        item => item?.question?.jid !== questionId
      );
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              similarQuestionsModal: {
                ...state.bot.ui.modals.similarQuestionsModal,
                data: newSimilarQuestionsData,
              },
            },
          },
        },
      };
    }

    case SET_CHAT_MODE:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            isChatOnDevMode: action.payload,
          },
        },
      };

    case TOGGLE_EXPAND_SIDEBAR_CHAT:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            isSidebarChatExpanded: !state.bot.ui.isSidebarChatExpanded,
          },
        },
      };

    case SHOW_CHAT_ANSWER_IN_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            chatAnswerInModal: {
              show: true,
              answer: action.payload.answer,
              question: action.payload.question,
            },
          },
        },
      };

    case CLOSE_CHAT_ANSWER_IN_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            chatAnswerInModal: initialChatAnswerInModal,
          },
        },
      };

    case SHOW_CHAT_SELECTED_SEQUENCE_IN_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            chatSelectedSequenceInModal: {
              show: true,
              sequence: action.payload,
            },
          },
        },
      };

    case CLOSE_CHAT_SELECTED_SEQUENCE_IN_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            chatSelectedSequenceInModal: initialChatSelectedSequenceInModal,
          },
        },
      };

    case SET_SELECTED_AI_TOOL: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              aiToolModal: {
                isOpen: true,
                ...action.payload,
                action: 'edit',
              },
            },
          },
        },
      };
    }

    case OPEN_AI_TOOL_MODAL: {
      const { action: modalAction, ...rest } = action.payload;
      const parameters =
        modalAction === 'add'
          ? NEW_OBJECT_ITEM
          : state.bot?.ui?.modals.parameters || {};

      const output =
        modalAction === 'add'
          ? DEFAULT_URL_OUTPUT_FIElDS
          : state.bot?.ui?.modals.parameters || {};

      const name = modalAction === 'add' ? null : state.bot?.ui?.modals?.name;
      const version =
        modalAction === 'add' ? 'draft' : state.bot?.ui?.modals?.version;
      const description =
        modalAction === 'add' ? null : state.bot?.ui?.modals?.description;

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              aiToolModal: {
                isOpen: true,
                jid: null,
                name,
                description,
                parameters,
                version,
                output,
                action: modalAction,
                ...rest,
              },
            },
          },
        },
      };
    }

    // close modal on add and edit tool
    case ADD_AI_TOOL:
    case EDIT_AI_TOOL:
    case CLOSE_AI_TOOL_MODAL: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              aiToolModal: {
                jid: null,
                isOpen: false,
              },
            },
          },
        },
      };
    }

    case SET_SHOW_WIDGET_AVATAR_PICKER_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              avatarPickerModal: {
                isOpen: true,
              },
            },
          },
        },
      };

    case CLOSE_WIDGET_AVATAR_PICKER_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              avatarPickerModal: {
                isOpen: false,
              },
            },
          },
        },
      };

    case OPEN_IMAGE_VIEWER_MODAL: {
      // dont open if no source
      const { source, title } = action.payload;
      const isOpen =
        Boolean(source) && !state.bot.ui?.modals?.imageViewerModal?.isOpen;

      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              imageViewerModal: {
                open: isOpen,
                source,
                title,
              },
            },
          },
        },
      };
    }
    case SET_SHOW_API_KEY_MODAL: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              apiKeyModal: {
                isIntegrationComponent: action.payload,
                isOpen: true,
              },
            },
          },
        },
      };
    }
    case CLOSE_IMAGE_VIEWER_MODAL: {
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              imageViewerModal: initialImageViewerModal,
            },
          },
        },
      };
    }
    case CLOSE_API_KEY_MODAL:
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              apiKeyModal: {
                isIntegrationComponent: null,
                isOpen: false,
              },
            },
          },
        },
      };

    case SET_PAGE_DETAILS: {
      return {
        ...state,
        layout: {
          ...state.layout,
          ...handlePageResizing(),
        },
      };
    }

    case SET_WEBSITE_EDITOR_SELECTED_WEBSITE: {
      const { website, args } = action.payload;
      const lastEdited = UTCToLocal(new Date(website.lastEdited));
      const scannedList = extractWebsiteScanList(
        extractScannedObject(website.scrapingInfo?.scanned)
      );
      return {
        ...state,
        bot: {
          ...state.bot,
          ui: {
            ...state.bot.ui,
            modals: {
              ...state.bot.ui.modals,
              websiteEditorModal: {
                isOpen: true,
                website: {
                  show_text: [],
                  show_html: [],
                  file_name: website.text,
                  id: website.jid,
                  idx: Number.isInteger(args) ? args : undefined,
                  clone: args === 'clone',
                  version: website?.version || DEFAULT_ANSWER_VERSION,
                  lastEdited: website.lastEdited
                    ? getLocalTimeString(lastEdited)
                    : null,
                  scrapingInfo: {
                    urlList: scannedList,
                    categoryList: categorizedWebsitePages(scannedList),
                    archiveList: website?.scrapingInfo?.archived || [],
                    url: website.scrapingInfo?.url,
                  },
                },
              },
            },
          },
        },
      };
    }

    default:
      return state;
  }
};
