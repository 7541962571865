import styled from 'styled-components';
import { Layout } from 'antd';
import { cssVariables } from 'styles/root';
import BackButton from 'components/BackButton';

export const Wrapper = styled(Layout)`
  max-width: max-content;
  width: max-content;

  .sider {
    flex: none !important;
    min-width: auto !important;
    width: auto !important;
  }

  .sidebar-content {
    overflow: hidden auto;
  }

  .sidebar-content::-webkit-scrollbar-track {
    padding-right: 5px;
  }

  .sidebar-content::-webkit-scrollbar {
    width: 10px;
    margin-right: 15px;
  }

  .sidebar-content::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
    margin-right: 15px;
    border: 2px solid #f9f9f9;
  }

  .sidebar-content::-webkit-scrollbar-thumb:hover {
    background: #454b57;
  }

  .ant-menu.ant-menu-inline-collapsed {
    width: 80px !important;
  }

  .ant-layout.ant-layout-sider,
  .ant-layout.ant-layout-has-sider {
    max-width: max-content;
    width: max-content;
  }

  .ant-menu-sub.ant-menu-inline {
    text-transform: capitalize;
    font-size: 11px;
  }

  .top-sider-menu {
    margin: 20px;
    flex-direction: column;
    align-items: flex-start;

    .bot-name-container {
      display: flex;
      align-items: center;
      font-size: 18px;
      gap: 5px;

      span {
        font-size: 14px;
        max-width: 76%;
      }
    }
  }

  .menu {
    .ant-menu-sub {
      background: #fff;
    }
    height: 80vh;
    padding-left: 20px;

    .menu-text {
      text-transform: capitalize;
    }

    .ant-menu-item,
    .ant-menu-item-selected {
      background: transparent;

      a {
        color: #000;
      }

      &::after {
        border: none;
      }
    }
    .ant-menu-item:hover {
      background-color: ${cssVariables.primaryHighlight};
    }

    .ant-menu-item-selected {
      font-weight: ${cssVariables.font.bold};
    }
  }

  .back {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .back-arrow {
    width: 24;
    height: 24;
    color: #b9b9b9;
    margin-right: 16px;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }

  .back-text {
    font-family: ${cssVariables.font.fontFamily};
    font-weight: ${cssVariables.font.normal};
    font-size: 13;
    text-transform: uppercase;
    color: #b9b9b9;
  }
`;

export const StyledCustomTooltip = styled.div`
  width: 364px;
  display: block;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 6px;

  & button {
    cursor: pointer;
    width: 62px;
    height: 30px;
    color: #ffffff;
    background-color: #1f6be4;
    border: 0;
    border-radius: 3px;
    font-size: 10px;
    font-weight: ${cssVariables.font.normal};
    letter-spacing: 2px;
    margin-right: 4px;
  }
`;

export const StyledToolTipContainer = styled.div`
  display: block;
  padding: 10px;

  & h3 {
    font-weight: ${cssVariables.font.bold};
  }
`;

export const StyledFooterActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledToggleIconWrapper = styled.div`
  padding: ${props => (props.showIconOnly ? '0 31.5px' : '8px 8px 0px 20px')};
  align-items: center;
  .anticon {
    font-size: 20px;
  }
`;

export const StyledSidebarTitle = styled.h4`
  font-size: 14px;
  font-weight: ${cssVariables.font.bold};
  padding: 0 36px;
  margin: 0px;
  text-transform: uppercase;
`;

export const StyledSidebarBottom = styled.div`
  width: 100%;
  font-size: 11px;
  position: absolute;
  bottom: 0;
  word-break: break-all;
  padding: 5%;
  text-align: left;
  background: #f9f9fc;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.12);

  & > div {
    margin-top: 3%;
  }
`;

export const StyledSidebarBackButton = styled(BackButton)`
  width: 88%;
  text-align: left;
  margin-left: 30px;
  margin-top: 30px;

  :hover {
    background: none !important;
  }
`;

export const StyledSidebarBottomTitle = styled.span`
  font-size: 12px;
  font-weight: ${cssVariables.font.bold};
  margin-bottom: 10px;
`;

export const StyledSidebarBottomCounts = styled.span`
  display: inline-block;
  font-size: 0.6rem;
  font-weight: ${cssVariables.font.bold};
  color: #85888e;
`;

export const StyledSidebarItemsTitle = styled.p`
  display: inline-block;
  font-size: 0.6rem;
  font-weight: ${cssVariables.font.normal};
  margin-bottom: 0px;
  margin-right: 2%;
  text-transform: uppercase;
  color: #85888e;
`;
