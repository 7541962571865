import { useState, useEffect, useContext, useMemo } from 'react';
import { message } from 'antd';

import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import {
  CLOSE_WEBSITE_EDITOR,
  SET_WEBSITE_SELECTED_PAGES,
  UPDATE_WEBSITE,
  UPDATE_WEBSITE_EDITOR_WEBSITE,
} from 'store/action';
import { GET_DATA_ERROR } from 'constants/error';
import {
  isWebsiteEditorOpenSelector,
  websiteEditorAnswerSelector,
  websiteSelectedPageselector,
} from 'selectors/bot/answers';
import useSelector from 'store/useSelector';
import { INITIAL_SELECTED_PAGE_LIST } from 'constants/answerbank/website';

const useWebsiteEditor = () => {
  const [state, dispatch] = useContext(Context);
  const websiteEditorAnswer = useSelector(websiteEditorAnswerSelector);
  const selectionState = useSelector(websiteSelectedPageselector);
  const isOpen = useSelector(isWebsiteEditorOpenSelector);
  const { sentinel, token } = state;

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFullScreen, setFullScreen] = useState(false);
  const [selectedPageList, setSelectedPageList] = useState(
    INITIAL_SELECTED_PAGE_LIST
  );
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);
  const [advanceSettingsState, setAdvanceSettingsState] = useState({
    selectedUploadingMethod: 'load',
    timeoutInSecond: 60,
  });

  const websiteList = useMemo(
    () => websiteEditorAnswer?.scrapingInfo,
    [websiteEditorAnswer]
  );

  const scrollIntoWarningView = () => {
    setTimeout(() => {
      const target = document.getElementsByClassName('ant-alert');
      if (target && target[0]) {
        target[0].scrollIntoViewIfNeeded();
      }
    }, 500);
  };

  const handleOnUpdateAnswer = async () => {
    setConfirmLoading(true);
    const archiveList = websiteList?.urlList
      ?.filter(website => !selectionState?.urlSelected?.includes(website.url))
      ?.map(website => website.url);

    const payload = {
      archives: archiveList,
      ver: websiteEditorAnswer.version,
      wait_until: advanceSettingsState?.selectedUploadingMethod,
      timeout: advanceSettingsState?.timeoutInSecond * 1000,
    };

    if (!payload?.archives || payload?.archives?.length === 0) {
      delete payload.archives;
    }

    try {
      const res = await apiService.changeWebsite(
        sentinel,
        websiteEditorAnswer?.id,
        token,
        payload
      );

      if (res.data.success) {
        dispatch({
          type: UPDATE_WEBSITE,
          payload: {
            ...(res.data.report[1] || res.data.report[0]),
            name: 'website',
          },
        });
        setConfirmLoading(false);
        handleClose();
      } else {
        setError({ detail: error?.message || error });
        scrollIntoWarningView();
      }
    } catch (error) {
      setError({ detail: error?.message || error });
      scrollIntoWarningView();
    }
    setConfirmLoading(false);
  };

  const handleClearFileEditorLocalState = () => {
    setFullScreen(false);
    setError(null);
    setConfirmLoading(false);
  };

  const handleClose = () => {
    if (isFullScreen) {
      return setFullScreen(false);
    }
    handleClearFileEditorLocalState();
    dispatch({
      type: CLOSE_WEBSITE_EDITOR,
    });
  };

  const handleChangeSwitch = async e => {
    try {
      dispatch({
        type: UPDATE_WEBSITE_EDITOR_WEBSITE,
        payload: {
          version: e ? 'final' : 'draft',
        },
      });
    } catch (error) {
      message.error(error.message || GET_DATA_ERROR);
    }
  };

  const handleDownloadFile = async () => {
    setDownloadLoading(true);
    try {
      const res = await apiService.generateFileCdnUrl(
        sentinel,
        websiteEditorAnswer.id,
        token
      );
      const cdnUrl = res.data.report[0];
      fetch(cdnUrl)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = websiteEditorAnswer.file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          setDownloadLoading(false);
        })
        .catch(error => console.error('Error fetching file:', error));
    } catch (err) {
      setDownloadLoading(false);
      throw typeof err === 'string' ? err : err.message;
    }
  };

  const handleOpenPages = page => {
    setSelectedPageList({
      category: page.name,
      urls: websiteList?.urlList?.filter(scanned =>
        page.urlList.some(page => page.url === scanned.url)
      ),
    });
  };

  const handleBackToPagesList = () => {
    setSelectedPageList(INITIAL_SELECTED_PAGE_LIST);
  };

  useEffect(() => {
    const filteredUrlList = websiteList?.urlList?.filter(
      urlDetails => !websiteList?.archiveList?.includes(urlDetails.url)
    );
    const filteredCategoryList = websiteList?.categoryList.filter(item =>
      item.urlList.some(
        urlItem => !websiteList?.archiveList.includes(urlItem.url)
      )
        ? true
        : false
    );

    dispatch({
      type: SET_WEBSITE_SELECTED_PAGES,
      payload: {
        urlSelectedRowKeys: filteredUrlList?.map(row => {
          return row.key;
        }),
        urlSelected: filteredUrlList?.map(row => {
          return row.url;
        }),
        categorySelected: filteredCategoryList?.map(row => {
          return row.name;
        }),
        categorySelectedRowKeys: filteredCategoryList?.map(row => {
          return row.key;
        }),
      },
    });
  }, [websiteList]);

  return {
    confirmLoading,
    handleClose,
    isFullScreen,
    handleOnUpdateAnswer,
    setFullScreen,
    handleChangeSwitch,
    isOpen,
    websiteEditorAnswer,
    handleDownloadFile,
    downloadLoading,
    selectedPageList,
    selectionState,
    handleOpenPages,
    websiteList,
    handleBackToPagesList,
    dispatch,
    showAdvanceSettings,
    setShowAdvanceSettings,
    advanceSettingsState,
    setAdvanceSettingsState,
  };
};

export default useWebsiteEditor;
