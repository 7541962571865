import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import { ImportOutlined } from '@ant-design/icons';
import { Spin, Switch } from 'antd';
import Button from 'components/Button';
import Modal from 'components/Modals/GenericModal';
import JoyrideTooltip from 'components/ToolTips/JoyrideToolTip';
import ImportAlertMessage from './ImportAlertMessage';
import { StyledAntUploadContent } from './ImportFileModal.style';
import useImportAnswerModal from './hooks';
import { TEXT_INGEST_STEP_ONE } from 'constants/joyride_steps/import_answer_modal';
import Dragger from 'antd/lib/upload/Dragger';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
import {
  SPINNER_LABEL,
  VALID_FILE_FORMATS,
} from 'constants/answerbank/website';

const ImportFileModal = ({
  fileType,
  onClose,
  visibility,
  setShowUploadModal,
  notificationAPI,
  parsedFileAnswers,
  setParsedFileAnswers,
  isImportBtnDisabled,
  setIsImportBtnDisabled,
}) => {
  const {
    importErrorMsg,
    importWarnMsg,
    finalAnswerWarnMsg,
    modalBtnLoading,
    handleCancelImport,
    handleAddFile,
    handleUploadFile,
    handleRemoveFile,
    setVersion,
    version,
    fileUploaded,
    webSocket,
    onClickCallback,
    runTour,
    stepIndex,
  } = useImportAnswerModal({
    fileType,
    onClose,
    visibility,
    setShowUploadModal,
    notificationAPI,
    parsedFileAnswers,
    setParsedFileAnswers,
    isImportBtnDisabled,
    setIsImportBtnDisabled,
  });

  return (
    <>
      <Modal
        closable={true}
        visible={visibility}
        onCancel={handleCancelImport}
        onOk={handleAddFile}
        okText="Submit"
        okButtonProps={{
          disabled: isImportBtnDisabled || !parsedFileAnswers?.length,
        }}
        title={<h4>Select a file</h4>}
        cancelText={modalBtnLoading ? 'Close' : 'Cancel'}
        confirmLoading={modalBtnLoading}
        destroyOnClose={false}
        maskClosable={false}
        width="85%"
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <StyledFlexRowLeft style={{ width: '50%' }}>
              <Switch
                style={{ alignSelf: 'center' }}
                checkedChildren={'Final'}
                unCheckedChildren={'Draft'}
                size="default"
                defaultChecked={version}
                onChange={value => setVersion(value ? 'final' : 'draft')}
                disabled={
                  modalBtnLoading || webSocket?.activeAction === 'add_file'
                }
              />
            </StyledFlexRowLeft>
            <div>
              <Button
                variant={'link'}
                value={'Cancel'}
                onClick={handleCancelImport}
              />
              <Button
                variant="primary-btn-v2"
                value={'Add Answer'}
                loading={modalBtnLoading}
                onClick={handleAddFile}
                disabled={!fileUploaded}
              />
            </div>
          </div>
        }
      >
        <Spin spinning={modalBtnLoading} tip={SPINNER_LABEL}>
          <div className="steps-content" style={{ margin: '10px 0' }}>
            <Dragger
              headers={{ 'Content-Type': 'multipart/form-data' }}
              beforeUpload={handleUploadFile}
              listType="picture"
              onRemove={handleRemoveFile}
              maxCount={1}
              accept={VALID_FILE_FORMATS}
            >
              <StyledAntUploadContent>
                <ImportOutlined />
                {'Drag files here or select file'}
              </StyledAntUploadContent>
            </Dragger>
            <ImportAlertMessage
              importErrorMsg={importErrorMsg}
              importWarnMsg={importWarnMsg}
              finalAnswerWarnMsg={finalAnswerWarnMsg}
            />
          </div>
        </Spin>
      </Modal>
      <Joyride
        run={runTour}
        steps={TEXT_INGEST_STEP_ONE}
        stepIndex={stepIndex}
        tooltipComponent={JoyrideTooltip}
        continuous
        debug
        spotlightClicks
        showProgress={true}
        showSkipButton={true}
        callback={onClickCallback}
        disableOverlay={false}
        isFirstStep
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
    </>
  );
};

ImportFileModal.propTypes = {
  fileType: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

export default ImportFileModal;
