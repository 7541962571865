import React from 'react';
import {
  Progress,
  Button as AntdButton,
  Tag,
  Spin,
  Space,
  Typography,
  Descriptions,
  Table,
  Col,
  Menu,
  Row,
} from 'antd';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import ROUTES from 'constants/routes';
import {
  PageHeader,
  InvoiceStatus,
  StyledWrapper,
  StyledPageTitle,
  StyledContent,
  StyledCurrentPlan,
  StyledPlanInfo,
  StyledPlanCTA,
  StyledPaymentInfoContainer,
  StyledPaymentInfoDetail,
  StyledInvoiceInfo,
  StyledTransactionParagraph,
  StyledIsProcessingWrapper,
} from './PlanAndPayments.style';
import { ZSB_CONTACT } from 'constants/outboundLinks';
import usePlanAndPayments from './hooks';
import FallbackBoundary from 'components/FallbackBoundary';
import {
  StyledFlexColumn,
  StyledFlexRowLeft,
} from 'styles/GenericStyledComponents';
import Alert from 'components/Alert';
import { IS_PROCESSING_SUBSCRIPTION, PRICE_PER_QUERY } from 'constants/plan';
import { cssVariables } from 'styles/root';
import AnchorButton from 'components/Button/AnchorButton';
import { detailedPlanFeatures } from '../planDetails';
import {
  ArrowUpOutlined,
  CalendarOutlined,
  CloseOutlined,
  CreditCardOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import ToolTip from 'components/ToolTips/BaseToolTip';

const { Paragraph } = Typography;

const PlanAndPaymentsWrapper = () => {
  const {
    billingDetails,
    currentTransactionCount,
    defaultPaymentMethod,
    paymentError,
    handleChangeCard,
    isCancelledSubscription,
    invoices,
    isPaymentInfoEmpty,
    isProcessing,
    isTrialSubscription,
    loading,
    maxTransactionCount,
    planDetails,
    planRenewDate,
    planType,
    renewSubscription,
    showConfirmCancelSubscriptionModal,
    subscriptionId,
    transactionPercentage,
    trialDaysLeft,
    formatDateTime,
    selectedKey,
    setSelectedKey,
    expandedRows,
    handleExpand,
  } = usePlanAndPayments();

  const getTransactionCountParagraph = () => {
    if (currentTransactionCount > maxTransactionCount) {
      const excessTransaction = Math.abs(
        currentTransactionCount - maxTransactionCount
      );
      return `You have gone above your transaction limit this month. Excess transaction count: ${excessTransaction} `;
    } else {
      return `You have used ${currentTransactionCount} of ${maxTransactionCount} ${
        planType === 'free' ? 'free' : ''
      } transactions per month.`;
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'periodStart',
      key: 'periodStart',
      render: (text, value) => (
        <>
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
              expandable: 'collapsible',
              expanded: expandedRows[value?.id] || false, // Assign expanded state based on row key
              onExpand: (_, info) => handleExpand(value?.id, info?.expanded),
            }}
          >
            Invoice from {formatDateTime(text * 1000)} to{' '}
            {formatDateTime(value.periodEnd * 1000)}{' '}
          </Typography.Paragraph>
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <InvoiceStatus status={text}>
          {text?.charAt(0).toUpperCase() + text?.slice(1)}
        </InvoiceStatus>
      ),
    },
    {
      title: '',
      dataIndex: 'download',
      key: 'download',
      render: (text, value) => (
        <>
          <Button
            variant="link"
            icon={<FileSearchOutlined />}
            href={value.hostedURL}
            target="_blank"
            value="View"
          />
          <Button
            variant="link"
            value="Download"
            icon={<FilePdfOutlined />}
            href={value.file}
            target="_blank"
          />
        </>
      ),
    },
  ];

  return (
    <Spin spinning={loading} tip="Just a moment...">
      <StyledPageTitle>
        <PageHeader>{'BILLING'}</PageHeader>
      </StyledPageTitle>
      <StyledContent title={<h2>Current Plan</h2>}>
        <StyledCurrentPlan gutter={[16, 16]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 16 }}
            xl={{ span: 16 }}
          >
            <StyledIsProcessingWrapper>
              <StyledFlexRowLeft>
                <h2 style={{ margin: 0, marginRight: 10 }}>
                  {planType?.charAt(0).toUpperCase() + planType?.slice(1)}
                </h2>
                {isTrialSubscription ? (
                  <Tag color={cssVariables.primaryBlue}>{'Trial'}</Tag>
                ) : paymentError ? (
                  <Tag color="red">{'Payment failed'}</Tag>
                ) : null}
              </StyledFlexRowLeft>

              <StyledPlanInfo>
                {!!planType && planType !== 'free' ? (
                  <div>
                    <span>
                      {detailedPlanFeatures(planDetails[planType][0])} per month
                      for {maxTransactionCount} queries,
                      {PRICE_PER_QUERY} per query thereafter.{' '}
                    </span>
                    {planRenewDate && !isCancelledSubscription ? (
                      <p>Your plan renews on {planRenewDate}.</p>
                    ) : null}
                    {trialDaysLeft ? (
                      <p>{`Your trial subscription will end in ${trialDaysLeft} day(s)`}</p>
                    ) : null}
                  </div>
                ) : null}
              </StyledPlanInfo>
              <StyledPlanCTA>
                <div>
                  <Progress
                    percent={transactionPercentage}
                    showInfo={false}
                    strokeColor="#00BD57"
                    trailColor="#E8F0FD"
                  />
                  <StyledTransactionParagraph
                    isRed={transactionPercentage > 80}
                  >
                    {getTransactionCountParagraph()}
                  </StyledTransactionParagraph>
                  <Link to={ROUTES.SUBSCRIPTION}> {'Upgrade for more'}</Link>
                </div>
              </StyledPlanCTA>
            </StyledIsProcessingWrapper>
            {isProcessing ? (
              <StyledFlexRowLeft>
                <Alert
                  style={{ margin: '0px 0px 12px 12px' }}
                  message={IS_PROCESSING_SUBSCRIPTION}
                  showIcon
                />
              </StyledFlexRowLeft>
            ) : null}
            {isCancelledSubscription ? (
              <StyledFlexRowLeft style={{ margin: '10px 0px' }}>
                <div
                  style={{
                    padding: 10,
                    fontSize: 14,
                    backgroundColor: cssVariables.red1,
                    border: `1px solid ${cssVariables.red10}`,
                  }}
                >
                  {'Your subscription has been cancelled, you can renew it'}{' '}
                  <AnchorButton
                    style={{ fontSize: 14 }}
                    onClick={renewSubscription}
                    title="here"
                  />
                </div>
              </StyledFlexRowLeft>
            ) : null}
          </Col>
          {!paymentError && (
            <StyledPaymentInfoContainer
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
            >
              <Button
                type="primary"
                bordered
                size="large"
                onClick={() => {}}
                icon={<ArrowUpOutlined />}
              >
                {planType === 'advanced' ? (
                  <a href={ZSB_CONTACT} target="_blank" rel="noreferrer">
                    {'Contact us for more'}
                  </a>
                ) : (
                  <Link to={ROUTES.SUBSCRIPTION}>Upgrade Plan</Link>
                )}
              </Button>
              {!!subscriptionId && !isCancelledSubscription ? (
                <AntdButton
                  size="large"
                  onClick={showConfirmCancelSubscriptionModal}
                  icon={<CloseOutlined />}
                >
                  Cancel Subscription
                </AntdButton>
              ) : null}
            </StyledPaymentInfoContainer>
          )}
        </StyledCurrentPlan>
      </StyledContent>

      <StyledContent
        title={
          <Row>
            <Col
              xs={{ span: 20 }}
              md={{ span: 16 }}
              lg={{ span: 16 }}
              xl={{ span: 16 }}
            >
              {' '}
              <h2>Payment Information</h2>
            </Col>
            <Col
              xs={{ span: 4 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
            >
              <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={selectedKey}
                onClick={e => setSelectedKey(e?.key)}
              >
                <Menu.Item
                  active={selectedKey === 'updatePaymentMethod'}
                  key="updatePaymentMethod"
                  variant="link"
                  onClick={handleChangeCard}
                  icon={<EditOutlined />}
                  hidden={isPaymentInfoEmpty}
                >
                  Update Payment Method
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
        }
      >
        <div>
          <StyledPaymentInfoDetail>
            {isPaymentInfoEmpty ? (
              <p>{'No Data Yet.'}</p>
            ) : (
              <Row gutter={[16, 16]}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 16 }}
                  lg={{ span: 16 }}
                  xl={{ span: 16 }}
                >
                  <Typography.Title level={5} style={{ marginTop: '1em' }}>
                    Billing details
                  </Typography.Title>
                  <Descriptions bordered column={2}>
                    <Descriptions.Item span={2} label="Name">
                      {billingDetails?.name}
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Email">
                      {billingDetails?.email}
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Phone Number">
                      {billingDetails?.phone || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Country">
                      {billingDetails?.address?.country || 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  xl={{ span: 8 }}
                >
                  <Typography.Title level={5} style={{ marginTop: '1em' }}>
                    Payment Method
                  </Typography.Title>
                  {paymentError && (
                    <Paragraph
                      style={{
                        marginBottom: '1em',
                        width: '90%',
                        color: 'red',
                      }}
                    >
                      <ExclamationCircleOutlined style={{ color: 'red' }} />{' '}
                      Your latest payment has failed. Update your payment method
                      to continue this plan.
                    </Paragraph>
                  )}
                  <Paragraph>
                    <CreditCardOutlined style={{ color: '#52c41a' }} />{' '}
                    {defaultPaymentMethod.card?.brand.toUpperCase()} card ending
                    with {defaultPaymentMethod.card?.last4}
                  </Paragraph>
                  <Paragraph>
                    <CalendarOutlined style={{ color: 'blue' }} /> Expires{' '}
                    {defaultPaymentMethod.card?.exp_month}/
                    {defaultPaymentMethod.card?.exp_year}
                  </Paragraph>
                </Col>
              </Row>
            )}
          </StyledPaymentInfoDetail>
        </div>
      </StyledContent>

      <StyledContent title={<h2>Invoices</h2>}>
        <StyledInvoiceInfo>
          {planType === 'free' ? (
            <p>{'No Data Yet.'}</p>
          ) : Array.isArray(invoices) && invoices?.length > 0 ? (
            <Table showHeader={false} columns={columns} dataSource={invoices} />
          ) : (
            <p>{'No Data Yet.'}</p>
          )}
        </StyledInvoiceInfo>
      </StyledContent>
    </Spin>
  );
};

const PlanAndPayments = () => {
  return (
    <FallbackBoundary>
      <PlanAndPaymentsWrapper />
    </FallbackBoundary>
  );
};

export default PlanAndPayments;
