import React from 'react';
import PropTypes from 'prop-types';
import { Select, Modal } from 'antd';

const { Option } = Select;

const UpdateUserModal = ({
  showUpdateBotset,
  setShowUpdateBotset,
  handleChange,
  selectedUser,
}) => {
  const selectedUserPlan = selectedUser?.context?.plan_type;
  return (
    <Modal
      visible={showUpdateBotset}
      title="Update Account"
      onOk={() => {
        setShowUpdateBotset(false);
      }}
      onCancel={() => {
        setShowUpdateBotset(false);
      }}
    >
      {selectedUserPlan ? (
        <div>
          <p>
            Current Plan:{' '}
            {selectedUserPlan.charAt(0).toUpperCase() +
              selectedUserPlan.slice(1)}
          </p>
          <Select
            defaultValue={selectedUserPlan}
            style={{ width: 120 }}
            onChange={handleChange}
          >
            <Option value="free">Free</Option>
            <Option value="basic">Basic</Option>
            <Option value="advanced">Advanced</Option>
          </Select>
        </div>
      ) : (
        <p>No available data for this user.</p>
      )}
    </Modal>
  );
};

UpdateUserModal.propTypes = {
  showUpdateBotset: PropTypes.bool.isRequired,
  setShowUpdateBotset: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
};

export default UpdateUserModal;
