import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { message, notification } from 'antd';
import _ from 'lodash';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { fetchBotDetails } from 'services/bots.service';
import ROUTES from 'constants/routes';
import { BOT_REDIRECT } from 'constants/localStorage';
import {
  SET_BOTS,
  VIEW_BOT,
  STOP_IMPERSONATING_USER,
  SET_BOT_DETAILS,
  SET_BOT_MODES,
  SHOW_BOT_MODAL,
} from 'store/action';
import { SET_ONBOARDING_FLAG } from 'store/action';
import { GET_DATA_ERROR } from 'constants/error';
import { getTokenSelector, isExternalPageSelector } from 'selectors/user';
import useSelector from 'store/useSelector';
import { isAdminSelector, isImpersonatingSelector } from 'selectors/admin';
import {
  onboardingFlagSelector,
  showFirstLoginPlanSelector,
} from 'selectors/plan';
import { allBotsSelector, botModesSelector } from 'selectors/bots';
import { stripUUID } from 'utils';
import {
  isBotModalVisibleSelector,
  isWordPressModalVisibleSelector,
  pageInitializedSelector,
} from 'selectors/layout';

const useYourBots = () => {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const token = useSelector(getTokenSelector);
  const allBots = useSelector(allBotsSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isImpersonating = useSelector(isImpersonatingSelector);
  const onboardingFlags = useSelector(onboardingFlagSelector);
  const showFirstLoginPlan = useSelector(showFirstLoginPlanSelector);
  const isBotModalVisible = useSelector(isBotModalVisibleSelector);
  const isWordPressModalVisible = useSelector(isWordPressModalVisibleSelector);
  const isExternalPage = useSelector(isExternalPageSelector);
  const botModes = useSelector(botModesSelector);
  const pageInitialized = useSelector(pageInitializedSelector);
  const { graph, plan, sentinel, tempBot } = state;

  const nextLocation = localStorage.getItem(BOT_REDIRECT);
  const { subscription } = plan;
  const [searchKey, setSearchKey] = useState('');
  const [filteredBots, setFilteredBots] = useState(allBots);
  const [viewType, setViewType] = useState('gridview');
  const [loading, setLoading] = useState(pageInitialized ? false : true);
  const [isPageReady, setIsPageReady] = useState(false);
  const [botsOrder, setBotsOrder] = useState({
    key: 0,
    sortBy: 'lastUpdatedAt',
  });
  const [showNewUserModal, setShowNewUserModal] = useState(
    isPageReady && allBots?.length === 0 && !isAdmin
  );
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const redirectToBot = async () => {
    const trimmedLocation = nextLocation.split('/bot').pop();
    history.push(`${ROUTES.BOT_DETAILS}${trimmedLocation}`);
  };

  useEffect(() => {
    if (
      isPageReady &&
      allBots?.length === 0 &&
      !isAdmin &&
      !showFirstLoginPlan
    ) {
      setShowNewUserModal(true);
    } else {
      setShowNewUserModal(false);
    }
    if (isPageReady && nextLocation) {
      redirectToBot();
    }
  }, [allBots?.length, isPageReady, isAdmin, showFirstLoginPlan]);

  useEffect(() => {
    if (isPageReady && allBots?.length === 0) {
      if (['past_due', 'unpaid'].includes(subscription?.status)) {
        const openNotification = () => {
          notification['error']({
            message: 'Something wrong with your subscription.',
            description:
              'Your latest invoice is either past due or unpaid. Please update your payments to continue using our service',
            onClick: () => {
              console.log('Notification Clicked!');
            },
            duration: 10,
          });
        };
        openNotification();
      }
    }
  }, [allBots?.length, isPageReady, subscription]);

  useEffect(() => {
    if (isPageReady && !onboardingFlags.includes('ViewBotButton')) {
      setRunTour(true);
    } else {
      setRunTour(false);
    }
  }, [isPageReady, onboardingFlags]);

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      setStepIndex(0);

      if (!onboardingFlags.includes('ViewBotButton')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'ViewBotButton'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'ViewBotButton' });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
      if (index === 0) {
      }
    }
  };

  const onAddBot = () => {
    dispatch({
      type: SHOW_BOT_MODAL,
      payload: {
        action: 'add',
      },
    });
    setSearchKey('');
  };

  const onSearchKeyChange = e => {
    setSearchKey(e.target.value);
  };

  const navigateToBotDetailsPage = (jid, botDetails) => {
    const answerCount = botDetails[0]?.context?.answer_count;
    const fileCount = botDetails[0]?.context?.file_count;
    const websiteCount = botDetails[0]?.context?.website_count;
    if (!answerCount && !fileCount && !websiteCount) {
      history.push(
        `${ROUTES.BOT_DETAILS}/${stripUUID(jid)}${ROUTES.ANSWERLIST}`
      );
    } else {
      history.push(
        `${ROUTES.BOT_DETAILS}/${stripUUID(jid)}${ROUTES.BOT_OVERVIEW}`
      );
    }
  };

  const navigateToAnswerBankPage = (jid, botDetails) => {
    history.push(`${ROUTES.BOT_DETAILS}/${stripUUID(jid)}${ROUTES.ANSWERLIST}`);
  };

  const handleViewBot = async bot => {
    try {
      setLoading(true);
      dispatch({ type: VIEW_BOT, payload: bot });
      if (!onboardingFlags.includes('ViewBotButton')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'ViewBotButton'
        );
        dispatch({ type: SET_ONBOARDING_FLAG, payload: 'ViewBotButton' });
      }
      const strippedJID = stripUUID(bot.jid);
      const botDetails = await fetchBotDetails(sentinel, bot.jid, token);
      setLoading(false);
      await dispatch({
        type: SET_BOT_DETAILS,
        payload: {
          ...botDetails,
          jid: bot.jid,
        },
      });

      if (isExternalPage) {
        navigateToAnswerBankPage(strippedJID, botDetails);
      } else {
        navigateToBotDetailsPage(strippedJID, botDetails);
      }
    } catch (error) {
      setLoading(false);
      message.error(error.message || GET_DATA_ERROR);
    }
  };

  const handleUpdateViewType = type => {
    setViewType(type);
  };

  const fetchBots = async () => {
    setLoading(true);
    try {
      const botRes = await apiService.getBots(sentinel, graph, token);
      if (!botRes.data.success || !botRes.data.report) {
        throw new Error(GET_DATA_ERROR);
      }

      dispatch({
        type: SET_BOTS,
        payload: { bots: botRes.data.report },
      });
      setIsPageReady(true);
    } catch (error) {
      message.error(error.message || GET_DATA_ERROR);
      setLoading(false);
      setIsPageReady(true);
    }

    return setLoading(false);
  };

  const fetchBotModes = async () => {
    try {
      const res = await apiService.globalVarGetter(
        'bot_modes',
        sentinel,
        token
      );

      dispatch({
        type: SET_BOT_MODES,
        payload: res.data.report[0],
      });
    } catch (error) {}

    return setLoading(false);
  };

  const handleSortBots = bot => {
    setBotsOrder(bot);
    setFilteredBots(
      _.orderBy(allBots, bot.sortBy, bot.key % 2 === 0 ? 'desc' : 'asc')
    );
  };

  useEffect(() => {
    return () => {
      setFilteredBots([]);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (!!sentinel && !!token && !!graph && !allBots?.length) {
        await fetchBots();
      }

      if (!!sentinel && !!token && !!graph && !botModes.length) {
        await fetchBotModes();
      }
    }

    fetchData();
  }, [graph, sentinel, token]);

  useEffect(() => {
    const searchResult = allBots.filter(bot =>
      bot.name.toLowerCase().includes(searchKey.toLowerCase())
    );
    if (searchResult) {
      setFilteredBots(searchResult);
    } else {
      setFilteredBots(allBots);
    }
  }, [searchKey, allBots]);

  const stopImpersonation = async () => {
    await dispatch({
      type: STOP_IMPERSONATING_USER,
    });
  };

  return {
    botsOrder,
    bots: filteredBots,
    searchKey,
    loading,
    tempBot,
    viewType,
    onAddBot,
    onClickCallback,
    onSearchKeyChange,
    runTour,
    setRunTour,
    setShowNewUserModal,
    showNewUserModal,
    handleSortBots,
    handleViewBot,
    handleUpdateViewType,
    stopImpersonation,
    isAdmin,
    isImpersonating,
    isBotModalVisible,
    isWordPressModalVisible,
    isExternalPage,
    stepIndex,
    setStepIndex,
    dispatch,
  };
};

export default useYourBots;
