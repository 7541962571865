import { createSelector } from 'reselect';
import { currentBotSelector } from '.';
import {
  allAnswersSelector,
  answersSelector,
  filesSelector,
  websitesSelector,
} from './answers';
import { stripUUID } from 'utils';
import { categoryTemplatesSelector } from 'selectors/categoryTemplates';

export const allCategoriesSelector = createSelector(
  currentBotSelector,
  bot => bot.categories || []
);

export const answerCategoriesTabSelector = createSelector(
  allCategoriesSelector,
  answersSelector,
  (allCategories, allAnswers) => {
    const allAnswersTabs = {
      key: 'all',
      name: 'All Answers',
      count: allAnswers.length || 0,
    };
    const answerBankTabs = [allAnswersTabs, ...allCategories]
      .map(category => {
        const categoryAnswers = allAnswers.filter(
          answer =>
            answer && stripUUID(answer.categoryId) === stripUUID(category.jid)
        );
        if (!categoryAnswers.length && category.key !== 'all') {
          return null;
        }
        return {
          key: category.jid || category.key,
          name: category.name,
          count:
            category.key === 'all'
              ? allAnswers.length
              : categoryAnswers.length || 0,
        };
      })
      .filter(Boolean);
    return answerBankTabs;
  }
);

export const filesCategoriesTabSelector = createSelector(
  allCategoriesSelector,
  filesSelector,
  (allCategories, allFiles) => {
    const allFilesTab = {
      key: 'all',
      name: 'All Files',
      count: allFiles.length || 0,
    };
    const answerBankTabs = [allFilesTab, ...allCategories]
      .map(category => {
        const categoryAnswers = allFiles.filter(
          file => file && stripUUID(file.categoryId) === stripUUID(category.jid)
        );
        if (!categoryAnswers.length && category.key !== 'all') {
          return null;
        }
        return {
          key: category.jid || category.key,
          name: category.name,
          count:
            category.key === 'all'
              ? allFiles.length
              : categoryAnswers.length || 0,
        };
      })
      .filter(Boolean);
    return answerBankTabs;
  }
);

export const websitesCategoriesTabSelector = createSelector(
  allCategoriesSelector,
  websitesSelector,
  (allCategories, allWebsites) => {
    const allWebsitesTab = {
      key: 'all',
      name: 'All Website',
      count: allWebsites.length || 0,
    };
    const answerBankTabs = [allWebsitesTab, ...allCategories]
      .map(category => {
        const categoryAnswers = allWebsites.filter(
          website =>
            website && stripUUID(website.categoryId) === stripUUID(category.jid)
        );
        if (!categoryAnswers.length && category.key !== 'all') {
          return null;
        }
        return {
          key: category.jid || category.key,
          name: category.name,
          count:
            category.key === 'all'
              ? allWebsites.length
              : categoryAnswers.length || 0,
        };
      })
      .filter(Boolean);
    return answerBankTabs;
  }
);

const categorySelector = (_state, category) => {
  return category;
};

export const isUserCategorySelector = createSelector(
  categoryTemplatesSelector,
  categorySelector,
  (categoryTemplates, category) => {
    return !categoryTemplates.some(
      categoryTemplate =>
        categoryTemplate.name.toLowerCase() === category.name.toLowerCase()
    );
  }
);
