import { useState, useContext } from 'react';
import { message } from 'antd';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { getTestSuitesSelector } from 'selectors/bot/testSuites';
import { ADD_TEST_SUITE } from 'store/action';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import {
  allAnswersSelector,
  topDefaultAnswerSelector,
  defaultAnswerThresholdSelector,
} from 'selectors/bot/answers';
import useSelector from 'store/useSelector';
import { isAnObject } from 'utils/dataTypes';

const useAddQuestionToTestSuiteModal = ({ question, onCloseModal }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedTestSuite, setSelectedTestSuite] = useState(null);
  const [showNewTestSuiteModal, setShowNewTestSuiteModal] = useState(false);
  const [responses, setResponses] = useState([]);
  const [totalAnswers, setTotalAnswers] = useState(0);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [showDisplayAnswer, setShowDisplayAnswer] = useState(false);

  const [state, dispatch] = useContext(Context);
  const allAnswers = useSelector(allAnswersSelector);
  const testSuites = useSelector(getTestSuitesSelector);
  const defaultAnswer = useSelector(topDefaultAnswerSelector);
  const defaultAnswerThreshold = useSelector(defaultAnswerThresholdSelector);
  const {
    sentinel,
    bot: { jid },
    token,
    plan: {
      max_test_cases: maxTestCases,
      max_test_suite: maxTestSuites,
      plan_type: planType,
    },
  } = state;

  const onNext = () => {
    setCurrentStep(currentStep + 1);

    fetchResponses();
  };

  const onPrev = () => {
    setCurrentStep(currentStep - 1);
    setTotalAnswers(0);
    setSelectedResponse(null);
    setResponses([]);
  };

  const fetchResponses = async () => {
    try {
      setLoading(true);
      const res = await apiService.getMatches(
        sentinel,
        jid,
        question,
        totalAnswers + 3,
        token
      );

      setTotalAnswers(totalAnswers + 3);

      const matchedAnswers = res.data.report.flatMap((answerInfo, idx) => {
        const answer = allAnswers.find(
          answerItem => answerItem.jid === answerInfo[1]
        );

        if (isAnObject(defaultAnswer)) {
          return {
            jid: answerInfo[1],
            score: defaultAnswerThreshold,
            text: defaultAnswer.text,
            type: defaultAnswer.type,
          };
        }
        if (answer) {
          return {
            jid: answerInfo[1],
            score: answerInfo[0].toFixed(3),
            text: answer.text,
            type: answer.type,
            show_text: answer.show_text,
          };
        }

        return null;
      });
      setResponses(matchedAnswers);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return message.error(
        err.message !== DEFAULT_ERROR_MESSAGE
          ? err.message
          : 'We encountered an error while getting response'
      );
    }
  };

  const handleSelectTestSuite = testSuite => {
    setSelectedTestSuite(testSuite);
  };

  const toggleShowNewTestSuiteModal = () => {
    setShowNewTestSuiteModal(!showNewTestSuiteModal);
  };

  const handleCreateNewTestSuite = async (evt, newSuiteName) => {
    if (evt) {
      evt.preventDefault();
    }
    try {
      const suiteWithSameName = testSuites.find(
        testSuite => testSuite.name === newSuiteName
      );
      if (suiteWithSameName) {
        message.error(
          'Test Suite with same name already exists. Try another name'
        );
        return;
      }

      const res = await apiService.createTestSuite(
        sentinel,
        jid,
        newSuiteName,
        token
      );
      const data = res.data.report[0];
      const newSuite = {
        jid: data.jid,
        name: data.context.name,
        lastTestRanTime: data.context.last_ran_time,
        lastTestStatus: data.context.last_result_summary,
        lastModified: data.context.last_updated_time,
        testCount: 0,
      };
      dispatch({
        type: ADD_TEST_SUITE,
        payload: newSuite,
      });
      setShowNewTestSuiteModal(false);
      message.success('New suite is created');
    } catch (err) {
      return message.error(
        'We encountered an error while creating a new suite'
      );
    }
  };

  const handleSelectResponse = answer => {
    setSelectedResponse(answer);
  };

  const handleAddQuestionToTestSuite = async () => {
    try {
      setLoading(true);

      const res = await apiService.getTestCases(
        sentinel,
        selectedTestSuite.jid,
        token
      );
      const testCases = res.data.report[0];
      const existTestCase = testCases.find(
        testCase => testCase.context.question === question
      );

      if (existTestCase) {
        setLoading(false);
        message.error('The question is already in the Test Suite.');
        return;
      }

      await apiService.createTestCase(
        sentinel,
        selectedTestSuite.jid,
        question,
        selectedResponse,
        token
      );

      setLoading(false);
      onCloseModal();
      message.success('Question is added successfully');
    } catch (err) {
      setLoading(false);
      return message.error('Failed to add question');
    }
  };

  return {
    currentStep,
    fetchResponses,
    handleAddQuestionToTestSuite,
    handleCreateNewTestSuite,
    handleSelectResponse,
    handleSelectTestSuite,
    loading,
    maxTestCases,
    maxTestSuites,
    onNext,
    onPrev,
    planType,
    responses,
    selectedTestSuite,
    selectedResponse,
    setShowDisplayAnswer,
    showDisplayAnswer,
    showNewTestSuiteModal,
    testSuites,
    totalAnswers,
    toggleShowNewTestSuiteModal,
  };
};

export default useAddQuestionToTestSuiteModal;
