import PropTypes from 'prop-types';
import {
  StyledAnswerTabs,
  StyledAnswerTabsPane,
  StyledSearchInput,
  StyledSearchOutlined,
  StyledSortDescendingOutlined,
} from '../../AnswerBank.styles';
import { Col, Dropdown, Row, Tooltip } from 'antd';
import TabsExtraActions from '../../TabsExtraActions/TabsExtraActions';
import Button from 'components/Button';
import ToggleShowDisplayAnswer from 'components/Button/ToggleShowDisplayAnswer';
import AnswerList from '../../AnswerList/AnswerList';
import AnswerItem from 'components/AnswerItem';
import EmptyData from 'components/EmptyAnswer';
import { BOT_MODES } from 'constants/bot';
import { getAnalyticsCategoryFilterLink } from 'utils/analytics';

import {
  DeleteFilled,
  DownloadOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { CATEGORY_FILTER_TOOLTIP } from 'constants/answerbank/defaults';
import SortDropdownMenu from '../../SortDropdownMenu/SortDropdownMenu';
import useAnswerTabs from './hooks';
import { useMemo } from 'react';

const AnswerTabs = ({
  filteredAnswers,
  handleManualBotSyncing,
  handleChangeTab,
  selectedAnswers,
  setShowBulkMoveCategoryModal,
  handleShowDigraph,
  synced,
  setShowDisplayAnswer,
  showDisplayAnswer,
  handleSelectAll,
  handleShowAskedQuestions,
  handleShowLinkedQuestions,
  handleChangeCheckbox,
  handleShowAnswerEditorModal,
  handleShowFileEditorModal,
  onDelete,
  handleChangeCategory,
  exportSelectedAnswers,
  handleOpenExportModal,
  setShowBulkDeleteModal,
  handleSortAnswers,
  answersOrder,
  getSortBtnTitle,
  allRecords,
  type,
  handleOpenFileModal,
  handleOpenAnswerModal,
  handleOpenAddAnswerModal,
  selectedTab,
  renderFilterAnswer,
}) => {
  const {
    botMode,
    isCategoryModalOpen,
    isFreeUser,
    isExportImportEnabled,
    allDefaultAnswers,
    push,
    categorListTab,
    searchKey,
    searchResults,
    onChangeSearchKey,
    searchType,
  } = useAnswerTabs({
    type,
    renderFilterAnswer,
    selectedTab,
    showDisplayAnswer,
    filteredAnswers,
  });

  const displayAnswer = useMemo(() => {
    const newShowDisplayAnswer = type === 'answers' ? showDisplayAnswer : false;
    setShowDisplayAnswer(newShowDisplayAnswer);
    return newShowDisplayAnswer;
  }, [showDisplayAnswer]);

  return (
    <StyledAnswerTabs
      tabBarExtraContent={
        <TabsExtraActions
          filteredAnswers={filteredAnswers}
          handleOpenExportModal={handleOpenExportModal}
          isExportImportEnabled={isExportImportEnabled}
          isFreeUser={isFreeUser}
          selectedAnswers={selectedAnswers}
          setShowEditCategoryModal={isCategoryModalOpen}
          handleShowDigraph={handleShowDigraph}
          synced={synced}
          mode={botMode}
          handleManualBotSyncing={handleManualBotSyncing}
          type={type}
          handleOpenFileModal={handleOpenFileModal}
          handleOpenAnswerModal={handleOpenAnswerModal}
          handleOpenAddAnswerModal={handleOpenAddAnswerModal}
        />
      }
      type="card"
      onChange={handleChangeTab}
      activeKey={selectedTab}
    >
      {categorListTab.map(tab => (
        <StyledAnswerTabsPane tab={`${tab.name} (${tab.count})`} key={tab.key}>
          {allRecords?.length > 0 ? (
            <>
              <Row>
                <Col xs={24} sm={4} flex="none">
                  <StyledSearchInput
                    placeholder="Search"
                    allowClear
                    prefix={<StyledSearchOutlined />}
                    value={searchKey[searchType]}
                    onChange={evt => {
                      onChangeSearchKey(evt.target.value, searchType);
                    }}
                    bordered={false}
                  />
                </Col>
                <Col
                  xs={24}
                  sm={20}
                  flex="auto"
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                  }}
                >
                  {selectedAnswers?.length ? (
                    <div className="bulk-action-buttons">
                      {botMode !== BOT_MODES.OPENAI ? (
                        <Button
                          variant="link"
                          size="medium"
                          value={'Export'}
                          startIcon={<DownloadOutlined />}
                          onClick={exportSelectedAnswers}
                        />
                      ) : null}
                      <Button
                        size="medium"
                        variant="link"
                        value={'Move Category'}
                        startIcon={<SwapOutlined />}
                        onClick={() => setShowBulkMoveCategoryModal(true)}
                      />
                      <Button
                        value={'Delete'}
                        variant="error"
                        size="medium"
                        startIcon={<DeleteFilled />}
                        onClick={() => setShowBulkDeleteModal(true)}
                      />
                    </div>
                  ) : (
                    <div className="bulk-action-buttons">
                      <Dropdown
                        overlay={
                          <SortDropdownMenu
                            handleSortAnswers={handleSortAnswers}
                            answersOrder={answersOrder}
                          />
                        }
                        trigger={['click']}
                        className="ghost"
                      >
                        <Button
                          bordered
                          size="medium"
                          variant="gray"
                          value={getSortBtnTitle || `Date Added`}
                          startIcon={<StyledSortDescendingOutlined />}
                        />
                      </Dropdown>
                      {type === 'answers' && (
                        <ToggleShowDisplayAnswer
                          showDisplayAnswer={displayAnswer}
                          onClick={() => setShowDisplayAnswer(!displayAnswer)}
                        />
                      )}
                    </div>
                  )}
                  {tab.key === 'all' || selectedAnswers?.length ? null : (
                    <Tooltip title={CATEGORY_FILTER_TOOLTIP}>
                      <Button
                        style={{ fontSize: 12 }}
                        variant="link"
                        value={`View Question log for ${tab.name}`}
                        onClick={() =>
                          push(getAnalyticsCategoryFilterLink(tab.name))
                        }
                        size="medium"
                      />
                    </Tooltip>
                  )}
                </Col>
              </Row>
              <AnswerList
                filteredAnswers={filteredAnswers}
                handleChangeCheckbox={handleChangeCheckbox}
                handleShowAnswerEditorModal={handleShowAnswerEditorModal}
                handleShowFileEditorModal={handleShowFileEditorModal}
                handleShowLinkedQuestions={handleShowLinkedQuestions}
                handleShowAskedQuestions={handleShowAskedQuestions}
                onDelete={onDelete}
                onUpdateCategoryAnswer={handleChangeCategory}
                searchKey={searchKey}
                searchResults={searchResults}
                showDisplayAnswer={displayAnswer}
                selectedAnswers={selectedAnswers}
                tab={tab}
                handleSelectAll={handleSelectAll}
                isWebsite={type === 'websites'}
                totalSelectedAnswers={selectedAnswers?.length}
              />
              {allDefaultAnswers.map(answer => (
                <AnswerItem
                  defaultAnswer
                  id={answer.jid}
                  answer={answer}
                  key={answer.jid}
                  showDisplayAnswer={displayAnswer}
                  handleShowAskedQuestions={handleShowAskedQuestions}
                  isWebsite={type === 'website'}
                />
              ))}
            </>
          ) : (
            <EmptyData />
          )}
        </StyledAnswerTabsPane>
      ))}
    </StyledAnswerTabs>
  );
};

AnswerTabs.propTypes = {
  filteredAnswers: PropTypes.array,
  handleManualBotSyncing: PropTypes.func,
  handleChangeTab: PropTypes.func,
  setShowBulkMoveCategoryModal: PropTypes.func,
  selectedAnswers: PropTypes.array,
  handleShowDigraph: PropTypes.func,
  synced: PropTypes.bool,
  setShowDisplayAnswer: PropTypes.func,
  showDisplayAnswer: PropTypes.bool,
  handleSelectAll: PropTypes.func,
  handleShowAskedQuestions: PropTypes.func,
  handleChangeCheckbox: PropTypes.func,
  handleShowAnswerEditorModal: PropTypes.func,
  handleShowFileEditorModal: PropTypes.func,
  handleShowLinkedQuestions: PropTypes.func,
  onDelete: PropTypes.func,
  handleChangeCategory: PropTypes.func,
  exportSelectedAnswers: PropTypes.func,
  handleOpenExportModal: PropTypes.func,
  setShowBulkDeleteModal: PropTypes.func,
  handleSortAnswers: PropTypes.func,
  answersOrder: PropTypes.any,
  getSortBtnTitle: PropTypes.func,
  allRecords: PropTypes.array,
  type: PropTypes.string,
  handleOpenFileModal: PropTypes.string,
  handleOpenAnswerModal: PropTypes.func,
  handleOpenAddAnswerModal: PropTypes.func,
  selectedTab: PropTypes.string,
  renderFilterAnswer: PropTypes.func,
};

export default AnswerTabs;
