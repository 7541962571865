import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';

import { cssVariables } from 'styles/root';
import {
  StyledFlexColumn,
  StyledFlexRowCenter,
  StyledFlexRowLeft,
} from 'styles/GenericStyledComponents';
import Logo from 'assets/images/zeroshotbot-horizontal-medium.svg';
import { START_TRIAL_DAYS_MESSAGE } from 'constants/plan';
import { StyledImageBanner } from 'pages/Auth/StyledComponents';
import AuthBanner from 'assets/images/auth-banner.png';
import TestSuiteSample from 'assets/images/test-suite-sample.png';
import TestSuiteSample2 from 'assets/images/test-suite-sample-2.png';
import AnalyticsSample from 'assets/images/analytics-sample.png';
import {
  StyledFloatingTopLogo,
  StyledFullHeightWrapper,
  StyledFullWidthWrapper,
  StyledLeftSidePromo,
  StyledScreenshotsWrapper,
} from './StyledComponents';
import Button from 'components/Button';
import useFirstLoginPromoModal from './hooks';
import { Spin } from 'antd';
import { detailedPlanFeatures } from 'pages/UserProfile/planDetails';

const FirstLoginPromoModal = () => {
  const { handleDismiss, handleStartTrial, loading, planDetails } =
    useFirstLoginPromoModal();
  return (
    <StyledFullWidthWrapper>
      <Spin spinning={loading} tip="Starting checkout session...">
        <StyledFlexRowCenter>
          <StyledLeftSidePromo>
            <StyledFlexRowLeft>
              <StyledFloatingTopLogo src={Logo} alt="zsb logo" />
            </StyledFlexRowLeft>
            <StyledFlexColumn>
              <StyledFlexRowLeft>
                <h1>{START_TRIAL_DAYS_MESSAGE}</h1>
              </StyledFlexRowLeft>
              <StyledFlexRowLeft>
                <h2>{'Access our basic plan features.'}</h2>
              </StyledFlexRowLeft>
              <StyledFlexColumn
                style={{ marginTop: 30, marginLeft: 50, gap: 10 }}
              >
                {detailedPlanFeatures(planDetails?.basic, 'basic', true)?.map(
                  (feat, idx) => {
                    return (
                      <StyledFlexRowLeft
                        style={{ gap: 10 }}
                        key={`plan-feature-${idx}`}
                      >
                        <CheckCircleFilled
                          style={{ color: cssVariables.primaryCyan }}
                        />
                        <span>{feat}</span>
                      </StyledFlexRowLeft>
                    );
                  }
                )}
              </StyledFlexColumn>
              <StyledFlexRowCenter>
                <Button
                  value="Start Trial"
                  variant="cyan"
                  onClick={handleStartTrial}
                />
              </StyledFlexRowCenter>
            </StyledFlexColumn>
          </StyledLeftSidePromo>
          <StyledFullHeightWrapper>
            <CloseOutlined onClick={handleDismiss} />
            <StyledScreenshotsWrapper>
              <img src={TestSuiteSample} alt="test suite page" />
              <img src={TestSuiteSample2} alt="test suite page" />
              <img src={AnalyticsSample} alt="analytics page" />
            </StyledScreenshotsWrapper>
            <StyledImageBanner src={AuthBanner} alt="Banner" />
          </StyledFullHeightWrapper>
        </StyledFlexRowCenter>
      </Spin>
    </StyledFullWidthWrapper>
  );
};

export default FirstLoginPromoModal;
