import styled, { css, keyframes } from 'styled-components';
import { cssVariables } from 'styles/root';
import { List } from 'antd';

const rotateLeft = keyframes`
    0% {
      transform: rotate(-45deg);
    };

    100% {
      transform: rotate(0);
    };
`;

const rotateRight = keyframes`
    0% {
      transform: rotate(45deg);
    };

    100% {
      transform: rotate(0);
    };
`;

const fadeUp = keyframes`
    0% {
      transform: translateY(10%);
    };

    100% {
      transform: translateY(0%);
    };
`;

export const IconRowsWrapper = styled.div`
  display: flex;
  gap: 10px;
  .anticon {
    font-size: 40px;
  }
`;

export const StyledRoot = styled.div`
  display: flex;
  width: 100%;

  & .rcw-conversation-container.active {
    animation: ${fadeUp} 0.3s linear;

    & .rcw-close-button {
      background-color: ${cssVariables.primaryColor};
    }

    & p {
      margin: 0;
      margin-bottom: 0;
    }

    & .rcw-messages-container {
      overflow-y: auto;
      background-color: #f9f9f9 !important;
    }
  }
`;

export const StyledIntegrationContainer = styled.div`
  flex: 1 1 auto;

  & .ant-tabs-content-holder {
    background-color: #fff;
    padding: 2%;

    & .ant-list-split .ant-list-item {
      border: none;
    }

    & .ant-list-item-action {
      &:has:not(.ant-tag) {
        & a {
          background-color: ${cssVariables.primaryBlue};
          color: #fff;
          padding: 10px 16px;
          border-radius: 5px;
        }

        & span {
          text-transform: capitalize;
          letter-spacing: 0px;
        }

        & .contact-link {
          background-color: #fff;
          color: ${cssVariables.primaryBlue};
          padding: 10px 16px;
          border-radius: 5px;
        }
      }
    }
  }

  & .ant-card {
    margin-top: 20px;

    & .ant-card-body {
      padding: 2%;
    }
  }

  & .ant-tabs-tabpane,
  .ant-tabs-tab {
    background-color: transparent !important;

    & div {
      color: #888888;
      font-size: 0.8rem;
      line-height: 24px;
    }
  }

  & .ant-tabs-nav {
    margin: 0px;
  }

  & .ant-tabs-nav,
  .ant-tabs-nav-wrap {
    background-color: transparent !important;
  }

  & .ant-tabs-tab-active {
    background-color: #fff !important;
  }

  & .ant-table .ant-table-cell button {
    text-transform: capitalize;
  }
`;

export const StyledCardContainer = styled.div`
  margin-top: 20px;
`;

export const StyledPlatformList = styled(List.Item.Meta)`
  ${props =>
    props.greyedOut
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: auto;
        `}
  & .anticon {
    font-size: 3rem;

    &.anticon-global,
    &.anticon-mobile,
    &.anticon-facebook,
    &.anticon-phone,
    &.anticon-android {
      color: ${props =>
        props.greyedOut ? cssVariables.gray1 : cssVariables.primaryBlue};
    }
  }

  & .ant-list-item-meta-description {
    font-size: 13px !important;
  }
`;

export const StyledTitle = styled.div`
  font-size: 32px;
  color: #31353e;
  text-transform: capitalize;

  .anticon.anticon-global,
  .anticon.anticon-mobile,
  .anticon.anticon-facebook,
  .anticon.anticon-phone,
  .anticon-api {
    color: ${cssVariables.primaryBlue};
  }
`;
