import React, { useMemo } from 'react';
import {
  Tag,
  Switch,
  Descriptions,
  Table,
  Form,
  Select,
  InputNumber,
  message,
} from 'antd';
import {
  CheckCircleOutlined,
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  SpaceBetweenWrapper,
  StyledBtnAdvanceSettings,
  StyledCodeButton,
  StyledWebsiteEditor,
} from './WebsiteEditor.styles';
import Modal from 'components/Modals/GenericModal';
import Button from 'components/Button';
import { DEFAULT_ANSWER_VERSION } from 'constants/answerbank/defaults';
import useWebsiteEditor from './hooks';

import { cssVariables } from 'styles/root';
import ToggleFullScreenButton from 'components/Button/ToggleFullScreenButton';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
import {
  handleChangeRowSelectionWebsiteCategoryTable,
  handleChangeRowSelectionWebsitePagesTable,
  renderWebsiteCategoryTableColumn,
  renderWebsitePagesTableColumn,
} from 'store/reducers/helpers/bot/answers';
import { UPLOAD_METHOD_OPTIONS } from 'constants/answerbank/website';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const WebsiteEditor = () => {
  const {
    confirmLoading,
    handleClose,
    isFullScreen,
    handleOnUpdateAnswer,
    setFullScreen,
    handleChangeSwitch,
    websiteEditorAnswer,
    isOpen,
    handleDownloadFile,
    downloadLoading,
    selectedPageList,
    selectionState,
    handleOpenPages,
    websiteList,
    handleBackToPagesList,
    dispatch,
    showAdvanceSettings,
    setShowAdvanceSettings,
    advanceSettingsState,
    setAdvanceSettingsState,
  } = useWebsiteEditor();
  const isFinalAnswer =
    websiteEditorAnswer?.version === 'final'
      ? true
      : false || websiteEditorAnswer?.version === DEFAULT_ANSWER_VERSION;

  const urlSelectedCount = urlDetails =>
    urlDetails?.urlList?.filter(url =>
      selectionState?.urlSelected?.includes(url.url)
    )?.length;

  const columns = useMemo(
    () =>
      !selectedPageList?.urls?.length
        ? renderWebsiteCategoryTableColumn(handleOpenPages, urlSelectedCount)
        : renderWebsitePagesTableColumn,
    [selectedPageList, selectionState]
  );

  const rowSelection = useMemo(
    () =>
      !selectedPageList?.urls?.length
        ? handleChangeRowSelectionWebsiteCategoryTable(selectionState, dispatch)
        : handleChangeRowSelectionWebsitePagesTable(
            websiteList?.categoryList,
            selectionState,
            dispatch
          ),
    [selectedPageList, selectionState, websiteList?.categoryList]
  );

  const renderModalTitle = () => (
    <>
      <span>
        {'Edit Website'}
        <Tag
          style={{ marginLeft: 20, fontSize: 14 }}
          color={isFinalAnswer ? 'green' : 'red'}
        >
          {isFinalAnswer ? 'Final' : 'Draft'}{' '}
          {isFinalAnswer ? <CheckCircleOutlined /> : <EditOutlined />}
        </Tag>
      </span>
      <ToggleFullScreenButton
        isFullScreen={isFullScreen}
        setFullScreen={setFullScreen}
      />
    </>
  );

  return (
    <Modal
      title={renderModalTitle()}
      visible={isOpen}
      onCancel={handleClose}
      destroyOnClose={true}
      onOk={handleOnUpdateAnswer}
      confirmLoading={confirmLoading}
      width={isFullScreen ? '100%' : '80%'}
      isFullScreen={isFullScreen}
      isFormModal
      spinning={confirmLoading}
      okText={
        <>
          <span>{'OK'}</span>
        </>
      }
      footer={
        <SpaceBetweenWrapper>
          <StyledFlexRowLeft style={{ width: '50%' }}>
            <Switch
              style={{ alignSelf: 'center' }}
              checkedChildren={'Final'}
              unCheckedChildren={'Draft'}
              defaultChecked={isFinalAnswer}
              size="default"
              onChange={handleChangeSwitch}
              loading={confirmLoading}
            />
          </StyledFlexRowLeft>
          <div>
            <Button
              value={'Back'}
              hidden={!selectedPageList?.urls?.length}
              variant="link"
              onClick={handleBackToPagesList}
            />
            <Button
              disabled={confirmLoading}
              value={'Cancel'}
              onClick={handleClose}
              variant="link"
            />
            <Button
              loading={confirmLoading}
              disabled={confirmLoading}
              variant={!isFinalAnswer ? 'gray' : 'primary'}
              value={!isFinalAnswer ? 'Save as Draft' : 'Save'}
              endIcon={isFinalAnswer ? <SaveOutlined /> : <FileTextOutlined />}
              onClick={handleOnUpdateAnswer}
            />
          </div>
        </SpaceBetweenWrapper>
      }
    >
      <StyledWebsiteEditor>
        <Descriptions layout="horizontal" bordered span={1}>
          <Descriptions.Item
            label="File Name"
            labelStyle={{ fontWeight: cssVariables.font.bold }}
            span={3}
          >
            {websiteEditorAnswer.file_name}{' '}
            <Button
              value={'Download'}
              onClick={handleDownloadFile}
              startIcon={<DownloadOutlined />}
              variant="link"
              loading={downloadLoading}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label="Website Url"
            labelStyle={{ fontWeight: cssVariables.font.bold }}
            span={3}
          >
            {websiteEditorAnswer?.scrapingInfo?.url}{' '}
            <CopyToClipboard text={websiteEditorAnswer?.scrapingInfo?.url}>
              <StyledCodeButton
                className="secondary"
                value="Copy"
                onClick={() => message.info('Copied')}
                startIcon={<CopyOutlined />}
              />
            </CopyToClipboard>
          </Descriptions.Item>
        </Descriptions>
        <SpaceBetweenWrapper isTitle={true}>
          <StyledBtnAdvanceSettings
            onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
            type="button"
          >
            {showAdvanceSettings === false
              ? 'Show advanced settings'
              : 'Hide advanced settings'}
          </StyledBtnAdvanceSettings>
          <div>
            <h3>
              <strong>
                {selectedPageList?.category?.charAt(0).toUpperCase() +
                  selectedPageList?.category?.slice(1) || ''}{' '}
              </strong>
            </h3>
            <div>
              {' '}
              Last Edited:
              {websiteEditorAnswer.lastEdited}
            </div>
          </div>
        </SpaceBetweenWrapper>

        {!showAdvanceSettings && (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={
              !selectedPageList?.urls?.length
                ? websiteList?.categoryList
                : selectedPageList?.urls
            }
            pagination={false}
            size="small"
            rowKey="key"
          />
        )}

        {showAdvanceSettings && (
          <Form>
            <Form.Item label="Wait Until">
              <Select
                options={UPLOAD_METHOD_OPTIONS}
                full
                size={'medium'}
                onChange={event =>
                  setAdvanceSettingsState(currentState => {
                    return {
                      ...currentState,
                      selectedUploadingMethod: event,
                    };
                  })
                }
                defaultValue={advanceSettingsState?.selectedUploadingMethod}
              />
            </Form.Item>
            <Form.Item label="Timeout">
              <InputNumber
                defaultValue={advanceSettingsState?.timeoutInSecond}
                min={1}
                onChange={value =>
                  setAdvanceSettingsState(currentState => {
                    return {
                      ...currentState,
                      timeoutInSecond: value,
                    };
                  })
                }
              />{' '}
              Second(s)
            </Form.Item>
          </Form>
        )}
      </StyledWebsiteEditor>
    </Modal>
  );
};

export default WebsiteEditor;
