import React, { useMemo, useContext, useEffect } from 'react';
import { Layout as AntdLayout, Popover, Spin } from 'antd';
import PropTypes from 'prop-types';
import { ColumnWidthOutlined, StepForwardFilled } from '@ant-design/icons';
import { omit, isEmpty } from 'lodash';

import useLayout from './hooks';
import {
  StyledDoubleSidebar,
  StyledMainContent,
  StyledTopIcons,
  StyledIconWithLabel,
  StyledSmallRightNavIconLabel,
  StyledPopoverTitle,
  StyledPopoverContents,
  StyledPopoverTextContent,
  StyledBottomIcons,
  StyledHeaderLogo,
  StyledSider,
  StyledLogoSider,
  StyledHeaderContent,
} from './Layout.styles';
import { MINIMUM_SCREEN_WIDTH } from 'constants/screens';
import { useHistory, Link } from 'react-router-dom';
import RobotIcon from 'assets/images/robot-icon-black-small.svg';
import ComponentHeader from 'components/Header';
import LowResoPrompt from 'components/Modals/LowResoPrompt';
import Sidebar from 'components/Sidebar/BotSidebar';
import SidebarChat from 'components/SidebarChat';
import ROUTES from 'constants/routes';
import FallbackBoundary from 'components/FallbackBoundary';
import FirstLoginPromoModal from 'components/Modals/FirstLoginPromoModal';
import Modal from 'components/Modals/GenericModal';
import {
  StyledFlexColumn,
  StyledFlexRowCenter,
} from 'styles/GenericStyledComponents';
import HRWithCenteredText from 'components/HR/HRWithCenteredText';
import CodeBlock from 'components/CodeBlock';
import SmallLogoWhite from 'assets/images/zeroshotbot-header-white-logo.svg';
import { Context } from 'store/store';
import SequenceViewerModal from 'components/Modals/SequenceViewerModal';
import { isImpersonatingSelector } from 'selectors/admin';
import useSelector from 'store/useSelector';
import { SET_PAGE_DETAILS, STOP_IMPERSONATING_USER } from 'store/action';

const { confirm } = Modal;
const { Content, Header, Sider } = AntdLayout;
const POPOVER_TITLE = 'Test your bot here!';

const Layout = ({
  children,
  isChatVisible,
  loading,
  handleChatAskQuestion,
  handleSeekAnswer,
  handleShowAddTestSuiteModal,
  setRunTour,
  runTour,
  SidebarComponent,
  contextHistory,
  setContextHistory,
  maxInteraction,
  setMaxInteraction,
}) => {
  const {
    allAnswers,
    chatAnswerInModal,
    chatSequenceInModal,
    chatSelectedSequenceInModal,
    isAdmin,
    isPageReady,
    isSidebarChatExpanded,
    WITH_PREFIX_BOT_JID,
    location,
    rightNavOpen,
    toggleRightNav,
    hasBotError,
    handleCloseJSONAnswerInModal,
    handleCloseSequenceInModal,
    handleCloseSelectedSequenceInModal,
    showFirstLoginPlan,
    toggleExpandSidebarChat,
    isExternalPage,
    scrollContainerRef,
    childrenWithRef,
  } = useLayout({ setRunTour, children });
  const isAdminPage = location.pathname?.includes(ROUTES.ADMIN_PAGE);
  const isBotPage = location.pathname?.includes(ROUTES.BOT_DETAILS);
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const isImpersonating = useSelector(isImpersonatingSelector);

  const shouldShowSidebar =
    WITH_PREFIX_BOT_JID &&
    !hasBotError &&
    location.pathname !== ROUTES.BOTS_PAGE &&
    location.pathname?.includes('/bot/') &&
    !location.pathname?.includes(ROUTES.ADMIN_PAGE);

  const PopoverContent = (
    <>
      <StyledPopoverTitle>
        {allAnswers.length ? POPOVER_TITLE : 'Add an answer to test the bot'}
      </StyledPopoverTitle>
      <StyledPopoverContents>
        <StyledPopoverTextContent>
          {allAnswers.length ? (
            <>
              <span>{'You have added your answers.'}</span>
              <span>{'Now test your bot here.'}</span>
            </>
          ) : (
            <>
              <span>{'You need to add your answers first.'}</span>
              <span>{'Then you can test bot here.'}</span>
            </>
          )}
        </StyledPopoverTextContent>
      </StyledPopoverContents>
    </>
  );

  const isLoading = useMemo(() => {
    return !!loading && !isPageReady;
  }, [isPageReady, loading]);

  const isLowResolution = useMemo(
    () => window.innerWidth < MINIMUM_SCREEN_WIDTH,
    []
  );

  const stopImpersonation = async () => {
    await dispatch({
      type: STOP_IMPERSONATING_USER,
    });
  };

  const goToHome = e => {
    if (isAdmin) {
      if (isImpersonating) {
        confirm({
          content: (
            <p>
              Impersonating user will end, are you sure you want to go back to
              admin page?
            </p>
          ),
          onOk() {
            stopImpersonation();
            history.push(ROUTES.ADMIN_PAGE);
          },
          onCancel() {
            return;
          },
        });
      } else {
        history.push(ROUTES.ADMIN_PAGE);
      }
    } else {
      history.push(ROUTES.HOME);
    }
  };

  const handlePageResizing = () => {
    dispatch({
      type: SET_PAGE_DETAILS,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handlePageResizing);
    handlePageResizing();
    return () => {
      window.removeEventListener('resize', handlePageResizing);
    };
  }, []);

  return (
    <Spin spinning={isLoading} tip="Just a moment...">
      <FallbackBoundary>
        <StyledDoubleSidebar isAdmin={isAdmin && !isImpersonating}>
          <StyledMainContent>
            <AntdLayout style={{ height: 'auto' }}>
              <StyledLogoSider>
                <StyledHeaderLogo
                  src={SmallLogoWhite}
                  alt="Logo"
                  onClick={() => goToHome()}
                />
              </StyledLogoSider>
              <StyledHeaderContent>
                <ComponentHeader />
              </StyledHeaderContent>
            </AntdLayout>

            <AntdLayout
              style={{
                height: '100%',
              }}
            >
              {SidebarComponent ? (
                <StyledSider>
                  <SidebarComponent />
                </StyledSider>
              ) : shouldShowSidebar ? (
                <StyledSider>
                  <Sidebar isPageReady={isPageReady} />
                </StyledSider>
              ) : null}
              <Content
                style={{
                  padding:
                    isAdmin && !isImpersonating && isAdminPage ? '0' : '2% 1%',
                  height: 'auto',
                  overflow:
                    isAdmin && !isImpersonating && isAdminPage
                      ? 'hidden'
                      : 'hidden auto',
                  flex: 1,
                }}
                ref={scrollContainerRef}
              >
                {childrenWithRef}
              </Content>

              {allAnswers.length && isChatVisible ? (
                <Sider
                  width="auto"
                  style={{ background: 'rgb(229, 229, 229)', maxWidth: 'auto' }}
                >
                  <StyledTopIcons>
                    {rightNavOpen || runTour ? (
                      <StyledIconWithLabel className="test-icon">
                        <img
                          src={RobotIcon}
                          alt={'Start chat'}
                          onClick={toggleRightNav}
                        />
                        <StyledSmallRightNavIconLabel>
                          Test
                        </StyledSmallRightNavIconLabel>
                      </StyledIconWithLabel>
                    ) : (
                      <Popover
                        placement="leftTop"
                        content={PopoverContent}
                        trigger="hover"
                      >
                        <StyledIconWithLabel className="test-icon">
                          <img
                            src={RobotIcon}
                            alt="start chat"
                            onClick={toggleRightNav}
                          />
                          <StyledSmallRightNavIconLabel>
                            Test
                          </StyledSmallRightNavIconLabel>
                        </StyledIconWithLabel>
                      </Popover>
                    )}
                  </StyledTopIcons>
                  <StyledBottomIcons>
                    {rightNavOpen ? (
                      isSidebarChatExpanded ? (
                        <StepForwardFilled
                          onClick={toggleExpandSidebarChat}
                          title={'Reduce chat width'}
                        />
                      ) : (
                        <ColumnWidthOutlined
                          onClick={toggleExpandSidebarChat}
                          title={'Expand chat'}
                        />
                      )
                    ) : null}
                  </StyledBottomIcons>
                </Sider>
              ) : null}
            </AntdLayout>
          </StyledMainContent>

          {allAnswers.length && rightNavOpen && isChatVisible ? (
            <SidebarChat
              handleChatAskQuestion={handleChatAskQuestion}
              handleSeekAnswer={handleSeekAnswer}
              handleShowAddTestSuiteModal={handleShowAddTestSuiteModal}
              toggleRightNav={toggleRightNav}
              contextHistory={contextHistory}
              setContextHistory={setContextHistory}
              maxInteraction={maxInteraction}
              setMaxInteraction={setMaxInteraction}
            />
          ) : null}
        </StyledDoubleSidebar>
        {showFirstLoginPlan ? <FirstLoginPromoModal /> : null}
        {chatAnswerInModal.show ? (
          <Modal
            title="Dev Mode Viewer"
            onCancel={handleCloseJSONAnswerInModal}
            noOkButton
            cancelText="Close"
            visible={true}
            width="70vw"
          >
            <StyledFlexColumn>
              <HRWithCenteredText text="Question" />
              <StyledFlexRowCenter>
                <CodeBlock codeContent={chatAnswerInModal.question} />
              </StyledFlexRowCenter>
              <p />
              <StyledFlexColumn>
                {!isEmpty(omit(chatAnswerInModal.answer, 'error')) ? (
                  <StyledFlexColumn>
                    <HRWithCenteredText text="Answer" />
                    <CodeBlock
                      codeContent={omit(chatAnswerInModal.answer, 'error')}
                    />
                  </StyledFlexColumn>
                ) : null}
                {chatAnswerInModal.answer?.error ? (
                  <StyledFlexColumn>
                    <HRWithCenteredText text="Error" />
                    <CodeBlock codeContent={chatAnswerInModal.answer?.error} />
                  </StyledFlexColumn>
                ) : null}
              </StyledFlexColumn>
            </StyledFlexColumn>
          </Modal>
        ) : null}
        {chatSequenceInModal.show ? (
          <Modal
            title="Sequence Viewer"
            onCancel={handleCloseSequenceInModal}
            noOkButton
            cancelText="Close"
            visible={true}
            width="70vw"
          >
            <StyledFlexColumn>
              <HRWithCenteredText text="Question" />
              <StyledFlexRowCenter>
                <CodeBlock codeContent={chatSequenceInModal.question} />
              </StyledFlexRowCenter>
              <p />
              {chatSequenceInModal.answer.sequence.map(sequence => (
                <>
                  <HRWithCenteredText
                    text={
                      sequence?.type?.charAt(0).toUpperCase() +
                      sequence?.type?.slice(1)
                    }
                  />
                  <StyledFlexRowCenter>
                    <CodeBlock codeContent={sequence} />
                  </StyledFlexRowCenter>
                  <p />
                </>
              ))}
            </StyledFlexColumn>
          </Modal>
        ) : null}
        {chatSelectedSequenceInModal.show ? (
          <SequenceViewerModal onCancel={handleCloseSelectedSequenceInModal} />
        ) : null}
      </FallbackBoundary>
    </Spin>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  isChatVisible: PropTypes.bool,
  handleChatAskQuestion: PropTypes.func,
  handleSeekAnswer: PropTypes.func,
  handleShowAddTestSuiteModal: PropTypes.func,
  loading: PropTypes.bool,
  setRunTour: PropTypes.func,
  runTour: PropTypes.bool,
  SidebarComponent: PropTypes.func,
  setContextHistory: PropTypes.func,
  contextHistory: PropTypes.bool,
  maxInteraction: PropTypes.any,
  setMaxInteraction: PropTypes.func,
};

export default Layout;
