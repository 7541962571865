import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  ReconciliationOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Checkbox, Progress } from 'antd';
import TextArea from 'components/TextArea';
import Tooltip from 'components/ToolTips/BaseToolTip';
import Button from 'components/Button';
import ImportAlertMessage from './ImportAlertMessage';
import {
  EditableAnswerWrapper,
  TextAreaWrapper,
  VerifiedTag,
  ActionContainer,
  ActionButtons,
} from './ImportAnswerModal.style';

export const AnswerList = ({
  selectedAnswers,
  parsedFileAnswers,
  deleteAnswers,
  answersAllowed,
  fileType,
  similarAnswers,
  verifiedAnswers,
  verifyAnswers,
  handleFindFAQquestion,
  toggleListCheckbox,
  handleCheckAnswer,
  handleShowAnswerEditorModal,
  handleDisplaySimilarAnswer,
  handleDuplicates,
  handleAnswerIsVerified,
  handleToggleAnswerEditor,
  selectedSimilarAnswers,
  handleVerifyAnswer,
  handleRemoveAnswer,
  getSimilarAnswer,
  toggleCheckBox,
  verifyAnswersProgress,
  importErrorMsg,
  importWarnMsg,
  similarAnswerWarnMsg,
  finalAnswerWarnMsg,
}) => {
  return (
    <>
      <ActionContainer>
        {verifyAnswersProgress > 0 ? (
          <Progress
            percent={verifyAnswersProgress}
            showInfo={true}
            strokeColor="#108ee9"
            trailColor="#E8F0FD"
            size="small"
          />
        ) : null}
      </ActionContainer>
      <ActionContainer>
        <Checkbox
          onChange={toggleCheckBox}
          checked={selectedAnswers.length === parsedFileAnswers.length}
        >
          Check All
        </Checkbox>
        {selectedAnswers.length > 0 ? (
          <ActionButtons>
            <Button
              onClick={verifyAnswers}
              value={
                selectedAnswers.length === parsedFileAnswers.length
                  ? 'Verify All'
                  : 'Verify'
              }
              size="small"
            />
            <Button
              onClick={deleteAnswers}
              value={
                selectedAnswers.length === parsedFileAnswers.length
                  ? 'Delete All'
                  : 'Delete'
              }
              size="small"
            />
          </ActionButtons>
        ) : null}
      </ActionContainer>
      <ImportAlertMessage
        importErrorMsg={importErrorMsg}
        importWarnMsg={importWarnMsg}
        similarAnswerWarnMsg={similarAnswerWarnMsg}
        finalAnswerWarnMsg={finalAnswerWarnMsg}
      />
      {parsedFileAnswers &&
        parsedFileAnswers.map((answer, idx) => {
          return (
            <div key={answer.uuid}>
              {fileType === 'generate-faq' && (
                <Tooltip title={() => handleFindFAQquestion(answer)}>
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
              <EditableAnswerWrapper>
                <Checkbox
                  value={answer}
                  onChange={toggleListCheckbox}
                  checked={selectedAnswers.find(
                    selectedAnswer => selectedAnswer.uuid === answer.uuid
                  )}
                ></Checkbox>
                <TextAreaWrapper>
                  <TextArea
                    required
                    defaultValue={handleCheckAnswer(answer)}
                    full
                    size="small"
                    autoSize={fileType === 'text-generate'}
                    onBlur={e => handleShowAnswerEditorModal(e, idx)}
                    disabled={answersAllowed <= idx}
                    className={
                      (handleDuplicates(answer) > 0 &&
                        'text-area-with-duplicate') ||
                      (similarAnswers.filter(
                        item => item.answer === handleCheckAnswer(answer)
                      ).length > 0 &&
                        'text-area-similar-answer-validated')
                    }
                    disableSpan={true}
                  />
                  {answersAllowed > idx && (
                    <VerifiedTag
                      isVerified={handleAnswerIsVerified(
                        handleCheckAnswer(answer)
                      )}
                    >
                      {handleAnswerIsVerified(handleCheckAnswer(answer)) ? (
                        <>
                          Final
                          <CheckCircleOutlined />
                        </>
                      ) : (
                        <>
                          Draft
                          <EditOutlined />
                        </>
                      )}
                    </VerifiedTag>
                  )}
                </TextAreaWrapper>
                {fileType === 'json' ? (
                  <EditOutlined
                    onClick={() => handleToggleAnswerEditor(answer, idx)}
                  />
                ) : null}
                {answersAllowed > idx &&
                  handleDuplicates(handleCheckAnswer(answer)) === 0 &&
                  handleAnswerIsVerified(handleCheckAnswer(answer)) === false &&
                  handleCheckAnswer(answer) !==
                    selectedSimilarAnswers[idx]?.answer && (
                    <Tooltip title="Verify your answer.">
                      <ReconciliationOutlined
                        size="large"
                        style={{ marginLeft: 5 }}
                        onClick={() =>
                          handleDisplaySimilarAnswer(handleCheckAnswer(answer))
                        }
                        className="text-ingest-delete"
                      />
                    </Tooltip>
                  )}
                {handleCheckAnswer(answer) ===
                  selectedSimilarAnswers[idx]?.answer && (
                  <Tooltip title="Save">
                    <SaveOutlined
                      size="large"
                      style={{ marginLeft: 5 }}
                      onClick={() =>
                        handleVerifyAnswer(handleCheckAnswer(answer))
                      }
                    />
                  </Tooltip>
                )}
                {verifiedAnswers &&
                  handleAnswerIsVerified(handleCheckAnswer(answer)) ===
                    true && (
                    <Tooltip title="Answer is Verified.">
                      <CheckCircleOutlined
                        size="large"
                        style={{ marginLeft: 5, color: 'green' }}
                      />
                    </Tooltip>
                  )}
                <Tooltip title="Delete answer.">
                  <DeleteOutlined
                    size="large"
                    style={{
                      marginLeft: answersAllowed <= idx ? 22 : 5,
                      marginRight: answersAllowed <= idx ? 8 : 0,
                    }}
                    onClick={() => handleRemoveAnswer(answer)}
                    className={`text-ingest-delete 
                        ${handleDuplicates(answer) > 0 && 'mrgn-left'}
                        `}
                  />
                </Tooltip>
              </EditableAnswerWrapper>
              {getSimilarAnswer(answer)}
            </div>
          );
        })}
    </>
  );
};

AnswerList.propTypes = {
  parsedFileAnswers: PropTypes.array,
  selectedAnswers: PropTypes.array,
  answersAllowed: PropTypes.number,
  fileType: PropTypes.string,
  similarAnswers: PropTypes.array,
  verifiedAnswers: PropTypes.array,
  selectedSimilarAnswers: PropTypes.array,
  handleFindFAQquestion: PropTypes.func,
  toggleListCheckbox: PropTypes.func,
  handleCheckAnswer: PropTypes.func,
  handleShowAnswerEditorModal: PropTypes.func,
  handleDisplaySimilarAnswer: PropTypes.func,
  handleDuplicates: PropTypes.func,
  handleAnswerIsVerified: PropTypes.func,
  handleToggleAnswerEditor: PropTypes.func,
  handleVerifyAnswer: PropTypes.func,
  handleRemoveAnswer: PropTypes.func,
  getSimilarAnswer: PropTypes.func,
  toggleCheckBox: PropTypes.func,
  deleteAnswers: PropTypes.func,
  verifyAnswers: PropTypes.func,
  verifyAnswersProgress: PropTypes.number,
  importErrorMsg: PropTypes.string,
  importWarnMsg: PropTypes.string,
  similarAnswerWarnMsg: PropTypes.string,
  finalAnswerWarnMsg: PropTypes.string,
};
