import { DEFAULT_ANSWER_VERSION } from 'constants/answerbank/defaults';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { stripUUID } from 'utils';

const { apiService } = require('./api.service');

export const setQuestionLink = async (
  itemToChange,
  answer,
  type,
  sentinel,
  token
) => {
  const { idx, question } = itemToChange;
  const userAsked = typeof question === 'object' ? question.question : question;
  try {
    const res = await apiService.linkQuestion(
      sentinel,
      answer,
      userAsked,
      token
    );

    if (res.data.report[0][0] && res.data.report[0][0][0] === 0) {
      throw new Error('Linking question failed. Too many similar answers.');
    }
  } catch (err) {
    throw typeof err === 'string' ? err : err.message;
  }
};

export const getRelatedAnswers = async (
  question,
  answers,
  defaultAnswer,
  sentinel,
  botJID,
  token,
  type
) => {
  try {
    const num_answers = answers.length > 0 ? answers.length : 3;
    const res = await apiService.getMatches(
      sentinel,
      botJID,
      question,
      num_answers,
      token
    );
    const matchedAnswers = res.data.report.map((answerInfo, idx) => {
      const answer = answers.find(
        answerItem => answerItem.jid === answerInfo[1]
      );

      // data structure of default answer
      if (defaultAnswer) {
        return {
          jid: defaultAnswer.jid,
          text: defaultAnswer.text,
          show_text: defaultAnswer.show_text,
          score: defaultAnswer.score,
          type: defaultAnswer.type,
          hitcount: defaultAnswer.hitcount,
        };
      } else if (answer) {
        return {
          jid: answerInfo[1],
          score: answerInfo[0].toFixed(3),
          show_text: answer.show_text || answer.text,
          html: answer.show_text || answer.text,
          text: answer.text,
          hitcount: answer.hitcount,
          lastEdited: answer.lastEdited,
          answerLength: answer.answerLength,
          categoryId: stripUUID(answer.categoryId),
          type: answer.type,
          version: answer.version || DEFAULT_ANSWER_VERSION,
          qlinks:
            idx === 0 && type && answer.qlinks.length
              ? [...answer.qlinks, question]
              : idx === 0 && type && !answer.qlinks.length
              ? [question]
              : answer.qlinks,
        };
      } else {
        return {};
      }
    });
    return matchedAnswers;
  } catch (error) {
    throw new Error(error.message || DEFAULT_ERROR_MESSAGE);
  }
};

export const deleteQuestionLink = async (
  answerLinked,
  sentinel,
  token,
  index
) => {
  const linkedIndex = index || 0;
  try {
    await apiService.unlinkQuestion(
      sentinel,
      answerLinked.jid,
      linkedIndex,
      token
    );
  } catch (error) {
    throw new Error(error.message || DEFAULT_ERROR_MESSAGE);
  }
};
