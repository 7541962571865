import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { DEFAULT_EDITOR } from './defaults';

export const CHAT_ERROR_REPLY = {
  jid: 'error-reply',
  show_text: DEFAULT_ERROR_MESSAGE,
  text: DEFAULT_ERROR_MESSAGE,
  qlinks: null,
  editor: DEFAULT_EDITOR,
  hitcount: 0,
  type: 'default_answer',
  categoryId: null,
  score: 0,
  quickReply: false,
  quickReplyOptions: null,
  requestAgent: false,
};
