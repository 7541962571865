import { Button } from 'antd';
import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledButton = styled(Button)`
  border-radius: ${props => (props.rounded ? '50px' : '5px')};
  align-items: center;
  justify-content: center;
  width: ${props => (props.$full ? '100%' : 'auto')};
  border: ${props => (props.bordered ? `1px solid ${props.color}` : 'none')};
  box-shadow: none;
  background-color: ${props =>
    props.bordered || props.variant === 'link'
      ? 'transparent'
      : props.color} !important;
  color: ${props =>
    props.disabled
      ? cssVariables.gray1
      : props.bordered || props.variant === 'link'
      ? props.color || cssVariables.primaryBlueHover
      : props.variant.includes('secondary') || props.variant === 'white'
      ? cssVariables.primaryColor
      : '#fff'} !important;
  .ant-btn-loading,
  :hover,
  :focus,
  :active {
    background-color: ${props =>
      props.variant !== 'link' ? props.hover : cssVariables.blue1} !important;
    color: ${props =>
      props.variant !== 'link' && props.variant !== 'error'
        ? props.color
        : props.variant === 'error'
        ? '#fff'
        : cssVariables.primaryBlue} !important;
  }
`;
