import { message } from 'antd';
import { useState, useContext, useEffect } from 'react';
import { csvToArray } from 'utils';
import { Context } from 'store/store';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { apiService } from 'services/api.service';
import {
  SET_ONBOARDING_FLAG,
  SET_SHOW_BELOW_THRESHOLD_RESPONSES,
  SET_SHOW_DRAFT_RESPONSES,
} from 'store/action';
import { importExportEnabledSelector } from 'selectors/plan';
import {
  showBelowThresholdResponsesSelector,
  showDraftResponsesSelector,
} from 'selectors/bot';
import useSelector from 'store/useSelector';

const useValidationQuestionModal = ({
  totalTestCases,
  testCase,
  responses,
}) => {
  const [state, dispatch] = useContext(Context);
  const {
    sentinel,
    token,
    graph,
    plan: { max_test_cases, onboarding_flag },
  } = state;
  const showDraftResponses = useSelector(showDraftResponsesSelector);
  const showBelowThresholdResponses = useSelector(
    showBelowThresholdResponsesSelector
  );
  const importExportEnabled = useSelector(importExportEnabledSelector);

  const [shouldUseDefaultAnswer, setShouldUseDefaultAnswer] = useState(false);
  const [questionsArr, setQuestionsArr] = useState(null);
  const [importWarnMsg, setImportWarnMsg] = useState(null);
  const [importErrorMsg, setImportErrorMsg] = useState(null);
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [showDisplayAnswer, setShowDisplayAnswer] = useState(true);
  const [question, setQuestion] = useState(testCase ? testCase.question : '');
  const [addQuestionType, setAddQuestionType] = useState('input');
  const testCasesAllowed = max_test_cases - totalTestCases;
  const csvFile = [
    '.csv',
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  // new question every `comma` or `newline`
  const csvDelimiters = [',', '\n'];

  const handleReadCSV = file => {
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    let questionsToUpload = [];
    if (!file || !csvFile.includes(file.type)) {
      return message.error('Invalid CSV file.');
    }
    const reader = new FileReader();
    reader.onload = e => {
      try {
        const csvData = csvToArray(e.target.result, csvDelimiters);
        if (testCasesAllowed < csvData.length) {
          for (let index = 0; index < testCasesAllowed; index++) {
            questionsToUpload.push(csvData[index]);
          }
          setImportWarnMsg(
            `Max number of test cases reached. System will only upload ${testCasesAllowed} test case(s).`
          );
        } else {
          questionsToUpload = csvData;
        }
        setQuestionsArr(questionsToUpload);
      } catch (error) {
        throw new TypeError(
          'Unexpected error encountered while processing the file.'
        );
      }
    };
    reader.readAsText(file);
    return false;
  };

  const handleChangeAnswerType = e => {
    if (e.target.value === 'default') {
      setShouldUseDefaultAnswer(true);
    } else {
      setShouldUseDefaultAnswer(false);
    }
  };

  useEffect(() => {
    if (
      addQuestionType === 'input' &&
      responses &&
      responses.length &&
      !onboarding_flag.includes('FirstSuite')
    ) {
      setTimeout(() => {
        setRunTour(true);
      }, 500);
    }
    return () => setRunTour(false);
  }, [addQuestionType, onboarding_flag, responses]);

  const onClickClose = async ({ action, index, type, status }) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      setStepIndex(0);
      if (!onboarding_flag.includes('FirstSuite')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'FirstSuite'
        );
        dispatch({
          type: SET_ONBOARDING_FLAG,
          payload: 'FirstSuite',
        });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
    }
  };

  const handleToggleShowDraft = () => {
    dispatch({
      type: SET_SHOW_DRAFT_RESPONSES,
      payload: null,
    });
  };

  const handleToggleShowBelowThreshold = () => {
    dispatch({
      type: SET_SHOW_BELOW_THRESHOLD_RESPONSES,
      payload: null,
    });
  };

  return {
    addQuestionType,
    showDraftResponses,
    handleToggleShowDraft,
    handleReadCSV,
    handleChangeAnswerType,
    shouldUseDefaultAnswer,
    importErrorMsg,
    importWarnMsg,
    importExportEnabled,
    onClickClose,
    question,
    questionsArr,
    runTour,
    setAddQuestionType,
    setShowDisplayAnswer,
    setQuestion,
    showDisplayAnswer,
    stepIndex,
    handleToggleShowBelowThreshold,
    showBelowThresholdResponses,
  };
};

export default useValidationQuestionModal;
