import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { importExportEnabledSelector } from 'selectors/plan';

import { StyledMenu } from './SettingsDropdownMenu.style';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';

const SettingsDropdownMenu = ({
  questionsWithAnswer,
  handleOpenExportModal,
}) => {
  const [_, dispatch] = useContext(Context);
  const isExportImportEnabled = useSelector(importExportEnabledSelector);

  return (
    <StyledMenu>
      <Menu.Item
        disabled={!questionsWithAnswer?.length || !isExportImportEnabled}
        key={'export'}
        icon={<DownloadOutlined />}
        onClick={handleOpenExportModal}
      >
        Export
      </Menu.Item>
    </StyledMenu>
  );
};

SettingsDropdownMenu.propTypes = {
  questionsWithAnswer: PropTypes.any,
  handleOpenExportModal: PropTypes.func,
};

export default SettingsDropdownMenu;
