import React, { useContext, useEffect, useRef } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';

import { Context } from 'store/store';
import Auth from 'pages/Auth';
import Bots from 'pages/Bots';
import BotDetails from 'pages/BotDetails';
import UserProfile from 'pages/UserProfile';
import VerifyEmail from 'pages/VerifyEmail';
import Admin from 'pages/Admin';
import ConfirmResetPassword from 'pages/ConfirmResetPassword';
import PrivateRoute from 'components/PrivateRoute';
import AdminRoute from 'components/AdminRoute';
import IdleTimerCountdown from 'components/IdleTimerCountdown';
import ROUTES from 'constants/routes';
import { setToken } from 'services/auth.service';
import { cssVariables } from 'styles/root';
import Logout from 'pages/Logout';
import './App.css';

const useUnload = fn => {
  const cb = useRef(fn);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [cb]);
};

const App = () => {
  const [state] = useContext(Context);
  const history = createBrowserHistory();

  const { isAuthenticated, token } = state;

  useUnload(e => {
    setToken(token, 1000 * 60 * 60 * 2);
  });

  return (
    <ThemeProvider theme={cssVariables}>
      <div className="app-container">
        <Router history={history}>
          {isAuthenticated && <IdleTimerCountdown />}
          <Switch>
            <Route exact path={ROUTES.HOME} component={Auth} />
            <Route
              path={[
                ROUTES.LOGIN,
                ROUTES.SIGNIN,
                ROUTES.SIGNUP,
                ROUTES.RESET_PASSWORD,
                ROUTES.EXTERNAL_LOGIN,
                ROUTES.EXTERNAL_SIGNUP,
              ]}
              component={Auth}
            />
            <Route path={ROUTES.VERIFY_EMAIL} component={VerifyEmail} />
            <Route
              path={ROUTES.CONFIRM_NEW_PASSWORD}
              component={ConfirmResetPassword}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path={ROUTES.BOT_DETAILS}
            >
              <BotDetails />
            </PrivateRoute>
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path={ROUTES.USER_PROFILE}
            >
              <UserProfile />
            </PrivateRoute>
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path={ROUTES.BOTS_PAGE}
            >
              <Bots />
            </PrivateRoute>
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path={ROUTES.LOGOUT}
            >
              <Logout />
            </PrivateRoute>
            <AdminRoute
              isAuthenticated={isAuthenticated}
              isAdmin={true}
              path={ROUTES.ADMIN_PAGE}
            >
              <Admin />
            </AdminRoute>
            <Route path={ROUTES.BOT_DETAILS} component={BotDetails} />
            <Route component={Bots} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
