import React, { createContext, useReducer } from 'react';
import { combinedReducers } from 'store/reducers';
import { initialState } from './initialState';

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(combinedReducers, initialState);
  const store = React.useMemo(() => [state, dispatch], [state]);

  return <Context.Provider value={store}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);
export default Store;
