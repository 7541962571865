import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.full ? '100%' : 'auto')};

  label {
    font-weight: ${cssVariables.font.bold};
  }
  .ant-input {
    padding: 10px;
    width: ${props => (props.full ? '100%' : 'auto')};
  }

  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 7px;
  }

  .ant-input-affix-wrapper {
    width: ${props => (props.full ? '100%' : 'auto')} !important;
  }

  .ant-input-password-large {
    padding-left: 10px;
    height: 45px;
  }

  &.ant-input-sm {
    padding: 5px;
  }
`;
