import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { StyledMenuItem } from './MenuItem.styles';
import { Popover } from 'antd';
import JiraForm from 'components/JiraForm';

const MenuItem = ({ title, path, index }) => {
  const history = useHistory();

  const handleMenuClick = () => {
    if (path.includes('https://')) {
      window.open(path);
    } else {
      history.push(path);
    }
  };

  return path === 'report a bug' ? (
    <StyledMenuItem key={index}>
      <Popover content={<JiraForm />} trigger="click">
        {title}
      </Popover>
    </StyledMenuItem>
  ) : (
    <StyledMenuItem onClick={handleMenuClick} key={index}>
      {title}
    </StyledMenuItem>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export { MenuItem };
