import PropTypes from 'prop-types';
import { Input, Radio } from 'antd';
import TextArea from 'components/TextArea';
import {
  MaxSentenceCountWrapper,
  StyledRadioGroup,
} from './ImportAnswerModal.style';

const TextIngestStep = ({
  ingestSelection,
  handleRadioChange,
  setIngestionValue,
  ingestionValue,
  maxSentences,
  setMaxSentences,
}) => (
  <>
    <StyledRadioGroup
      onChange={e => handleRadioChange(e.target.value)}
      value={ingestSelection}
      className="text-ingest-inputs"
    >
      <Radio value="url">
        <span>{'URL'}</span>
        {ingestSelection === 'url' ? (
          <Input
            autoFocus
            onChange={e => setIngestionValue(e.target.value)}
            size="large"
            placeholder="Enter a valid URL/webpage to summarize"
            type="url"
            defaultValue={ingestSelection === 'url' ? ingestionValue : null}
            required
          />
        ) : null}
      </Radio>
      <Radio value="text">
        <span>{'Text'}</span>
        {ingestSelection === 'text' ? (
          <TextArea
            autoFocus
            defaultValue={ingestSelection === 'text' ? ingestionValue : null}
            onChange={e => setIngestionValue(e.target.value)}
            placeholder="Enter text to summarize"
            type="url"
            required
          />
        ) : null}
      </Radio>
    </StyledRadioGroup>
    <MaxSentenceCountWrapper>
      <label htmlFor="max-sentences">{'Max sentences to summarize: '}</label>
      <Input
        name="max-sentences"
        min={2}
        max={100}
        defaultValue={maxSentences}
        onChange={e => setMaxSentences(e.target.value)}
        type="number"
        size="middle"
      />
    </MaxSentenceCountWrapper>
  </>
);

export default TextIngestStep;

TextIngestStep.propTypes = {
  ingestSelection: PropTypes.string,
  handleRadioChange: PropTypes.func,
  setIngestionValue: PropTypes.func,
  ingestionValue: PropTypes.string,
  maxSentences: PropTypes.string,
  setMaxSentences: PropTypes.func,
};
