export const cssVariables = {
  maxHeight: '100vh',
  width: '100%',
  primaryColor: '#202225',
  secondaryColor: '#f3f3f3',
  cardShadow:
    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  itemListShadow: '0 1px 4px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 20%)',
  primaryTransparent: 'rgba(33, 35, 38, 0.774)',
  primaryHighlight: 'rgba(230, 232, 235)',

  // the higher the number the light the color
  blueishGray: '#454b57',
  primaryGray: '#202225',
  gray0: '#66696F',
  gray1: '#d1d1d1',
  gray2: '#e5e5e5',
  gray3: '#f4f6f8',
  gray4: '#f0f2f5',
  gray5: '#F8F9FA',
  primaryBlue: '#167BE7',
  primaryBlueHover: '#daecfd',
  primaryCyanHover: '#dbfafd',
  blue1: '#daecfd',
  blue2: '#1F6BE4',
  red1: '#FFF6F5',
  red2: '#ffeaea',
  red10: '#ff4d4f',
  cyan: '#6AC8BC',
  primaryCyan: '#6AC8BC',
  zsbCyanGradient: 'linear-gradient(180deg, #141414 0%, #43726e 100%)',

  // Error message colors
  errorText: '#f5222d',
  errorButtonColor: '#ff4d4f',
  errorBgColor: '#fff1f0',
  errorBorder: '#ffa39e',

  green0: '#52c41a',
  green1: '#23b576',

  success: '#23b576',
  successHover: '#cce6db',
  warning: '#faad14',

  // font styles
  font: {
    bold: 700,
    normal: 500,
    fontFamily: `'Roboto', sans-serif`,
  },
};
