import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ImportOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Spin, Steps, Typography, Upload } from 'antd';
import TextArea from 'components/TextArea';
import Tooltip from 'components/ToolTips/BaseToolTip';
import Button from 'components/Button';
import Modal from 'components/Modals/GenericModal';
import JoyrideTooltip from 'components/ToolTips/JoyrideToolTip';
import {
  EditableAnswerWrapper,
  StyledFooterWrapper,
  StyledAntUploadContent,
  StyledUploadTip,
  StyledUploadTipTitle,
  StyledModalErrorAlert,
  TextAreaWrapper,
  VerifiedTag,
} from './ImportQuestionModal.style';
import useImportQuestionModal from './hooks';
import { IMPORT_QUESTION } from 'constants/joyride_steps/import_question_modal';
const { Dragger } = Upload;
const { Paragraph } = Typography;
const { Step } = Steps;

const SAMPLE_JSON_FORMAT = (
  <Paragraph>
    <pre>
      {`[
  {
      "text": "Sample plain answer text",
      "show_text": "New <b>answer</b> again for testing"
      "qlinks": ['hello', 'test question'],
      "category": 'test answers',
      "category_color": "#d3d3d3",
  },
  {
      "text": "Another sample answer text",
      "show_text": "<i>Another<i/> <b>answer</b> for testing"
      "qlinks": ['question link', 'show me another answer'],
      "category": 'test answers 2',
      "category_color": "#167be7",
  },
]`}
    </pre>
  </Paragraph>
);

const ImportQuestionModal = ({ fileType, onClose, show }) => {
  const {
    answersAllowed,
    currentStep,
    importErrorMsg,
    importWarnMsg,
    similarAnswerWarnMsg,
    finalAnswerWarnMsg,
    isImportBtnDisabled,
    modalBtnLoading,
    parsedFileQuestions,
    duplicatesFound,
    duplicatesFoundInFile,
    handleCancelImport,
    handleEditAnswer,
    handleRemoveAnswer,
    handleRemoveFile,
    handleToggleAnswerEditor,
    handleStepChange,
    handleUploadFile,
    handleSubmitFile,
    handleClearData,
    handleCheckValidations,
    setShowImportTip,
    showImportTip,
    visibility,
    verifiedQuestions,
    verificationBtnLoading,
    runTour,
    stepIndex,
    onClickCallback,
  } = useImportQuestionModal({ fileType, onClose, show });

  const handleQuestionIsVerified = answer => {
    return (
      verifiedQuestions?.filter(verifiedAnswer => verifiedAnswer === answer)
        ?.length > 0
    );
  };

  const handleDuplicates = question => {
    const questionDetails =
      fileType === 'json' ? question.text : question.replace('\r', '');
    return (
      duplicatesFound.filter(
        item => item?.question.text?.replace('\r', '') === question.text
      )?.length ||
      duplicatesFoundInFile?.filter(
        item =>
          (fileType === 'json'
            ? item?.question.text?.replace('\r', '')
            : item?.replace('\r', '')) === questionDetails
      ).length
    );
  };

  const handleCheckQuestion = question => {
    return fileType === 'json' ? question.text : question;
  };

  const ImportAlertMessage = () => {
    return (
      <>
        {importErrorMsg ? (
          <StyledModalErrorAlert message={importErrorMsg} type="error" />
        ) : null}
        {importWarnMsg ? (
          <StyledModalErrorAlert
            message={importWarnMsg}
            type="warning"
            closable
          />
        ) : null}
        {similarAnswerWarnMsg ? (
          <StyledModalErrorAlert
            message={similarAnswerWarnMsg}
            type="warning"
            closable
          />
        ) : null}
        {finalAnswerWarnMsg ? (
          <StyledModalErrorAlert
            message={finalAnswerWarnMsg}
            type="warning"
            closable
          />
        ) : null}
      </>
    );
  };

  const csvOrJsonStep = (
    <Dragger
      headers={{ 'Content-Type': 'multipart/form-data' }}
      beforeUpload={handleUploadFile}
      accept={
        fileType === 'json'
          ? 'application/json'
          : 'text/plain, .csv, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
      listType="picture"
      onRemove={handleRemoveFile}
      maxCount={1}
    >
      <StyledAntUploadContent className="import-question-button">
        <ImportOutlined />
        Drag files here or select file
      </StyledAntUploadContent>
    </Dragger>
  );

  const stepContent = {
    json: csvOrJsonStep,
  };

  const ImportStep = () => {
    return (
      <>
        <ImportAlertMessage />
        <StyledUploadTip>
          <StyledUploadTipTitle>
            {fileType === 'json' ? (
              <Tooltip
                title={
                  showImportTip
                    ? 'Hide file format'
                    : 'Show accepted file format'
                }
              >
                {showImportTip ? (
                  <CloseCircleOutlined
                    alt="Hide file format"
                    onClick={() => setShowImportTip(false)}
                  />
                ) : (
                  <InfoCircleOutlined
                    alt="Show accepted file format"
                    onClick={() => setShowImportTip(true)}
                  />
                )}
              </Tooltip>
            ) : null}
          </StyledUploadTipTitle>
          {showImportTip && fileType === 'json' && SAMPLE_JSON_FORMAT}
        </StyledUploadTip>
        {stepContent[fileType]}
      </>
    );
  };

  const AnswerList = ({ parsedFileQuestions }) => {
    return (
      <>
        {ImportAlertMessage()}
        {parsedFileQuestions &&
          parsedFileQuestions.map((question, idx) => {
            return (
              <>
                <EditableAnswerWrapper>
                  <TextAreaWrapper>
                    <TextArea
                      required
                      defaultValue={handleCheckQuestion(question)}
                      full
                      size="small"
                      autoSize={fileType === 'text-generate'}
                      onBlur={e => handleEditAnswer(e, idx)}
                      disabled={answersAllowed <= idx}
                      className={
                        handleDuplicates(question) > 0 &&
                        'text-area-with-duplicate'
                      }
                      disableSpan={true}
                    />
                    {answersAllowed > idx && (
                      <VerifiedTag
                        isVerified={handleQuestionIsVerified(
                          handleCheckQuestion(question)
                        )}
                      >
                        {handleQuestionIsVerified(
                          handleCheckQuestion(question)
                        ) ? (
                          <>
                            Final
                            <CheckCircleOutlined />
                          </>
                        ) : (
                          <>
                            Draft
                            <EditOutlined />
                          </>
                        )}
                      </VerifiedTag>
                    )}
                  </TextAreaWrapper>
                  {fileType === 'json' ? (
                    <EditOutlined
                      onClick={() => handleToggleAnswerEditor(question, idx)}
                    />
                  ) : null}
                  <Tooltip title="Delete Question.">
                    <DeleteOutlined
                      size="large"
                      style={{
                        marginLeft: answersAllowed <= idx ? 22 : 5,
                        marginRight: answersAllowed <= idx ? 8 : 0,
                      }}
                      onClick={() => handleRemoveAnswer(idx)}
                      className={`text-ingest-delete 
                          ${handleDuplicates(question) > 0 && 'mrgn-left'}
                          `}
                    />
                  </Tooltip>
                </EditableAnswerWrapper>
              </>
            );
          })}
      </>
    );
  };

  const stepTitle = {
    json: 'Import',
  };

  const modalTitle = {
    json: 'Import Question (.json)',
  };

  const steps = [
    {
      title: stepTitle[fileType],
      content: ImportStep,
    },
    {
      title: 'Verify your Question',
      // eslint-disable-next-line react/display-name
      content: () => <AnswerList parsedFileQuestions={parsedFileQuestions} />,
    },
  ];

  AnswerList.propTypes = {
    parsedFileQuestions: PropTypes.array,
  };

  const handleNextClick = () => {
    const importActions = {
      json: handleCheckValidations,
    };

    importActions[fileType]();
  };

  const getActionButtons = () => {
    return (
      <>
        {currentStep ? (
          <Button
            variant={'link'}
            value="Cancel"
            onClick={handleCancelImport}
          />
        ) : null}
        {!currentStep ? (
          <>
            <Button
              className="next-step-importquestion-button"
              variant="primary-btn-v2"
              value="Next"
              onClick={handleNextClick}
              loading={modalBtnLoading}
            />
          </>
        ) : (
          <Button
            disabled={
              isImportBtnDisabled ||
              !answersAllowed ||
              duplicatesFound.length > 0 ||
              verificationBtnLoading ||
              duplicatesFoundInFile.length > 0
            }
            value="Save as Draft"
            variant="gray"
            onClick={() => {
              handleSubmitFile(fileType);
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        visible={visibility}
        onCancel={handleCancelImport}
        onOk={() => handleSubmitFile(fileType)}
        okText="Submit"
        title={<h4>{modalTitle[fileType]}</h4>}
        okButtonProps={{
          disabled: isImportBtnDisabled || !parsedFileQuestions?.length,
        }}
        cancelText={modalBtnLoading ? 'Close' : 'Cancel'}
        confirmLoading={modalBtnLoading}
        destroyOnClose
        width="700px"
        footer={
          <StyledFooterWrapper>
            {currentStep ? (
              <Button value="Back" onClick={handleClearData} />
            ) : null}
            <div className="action-buttons">{getActionButtons()}</div>
            <Joyride
              run={runTour}
              steps={IMPORT_QUESTION}
              stepIndex={stepIndex}
              tooltipComponent={JoyrideTooltip}
              continuous
              debug
              spotlightClicks
              showProgress={true}
              showSkipButton={true}
              callback={onClickCallback}
              isFirstStep
              scrollToFirstStep={true}
              disableScrollParentFix={true}
            />
          </StyledFooterWrapper>
        }
      >
        <Spin
          spinning={modalBtnLoading || verificationBtnLoading}
          tip="Just a moment..."
        >
          <Steps current={currentStep} onChange={handleStepChange}>
            {steps.map((step, idx) => (
              <Step key={idx} title={step.title} />
            ))}
          </Steps>
          <div className="steps-content" style={{ margin: '10px 0' }}>
            {steps[currentStep].content()}
          </div>
        </Spin>
      </Modal>
    </>
  );
};

ImportQuestionModal.propTypes = {
  fileType: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

export default ImportQuestionModal;
