import styled from 'styled-components';

export const StyledFileEditor = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 20vh;
  margin-top: 4%;

  @media (max-width: 600px) {
    min-width: 80%;
  }

  .ant-descriptions-title {
    display: flex;
    align-items: flex-end;
    right: 0;
    flex-direction: column;
  }
`;
