import { createSelector } from 'reselect';
import { currentBotSelector } from '.';
import { parseBoolean } from 'utils/dataTypes';
import {
  initialAIToolModalState,
  initialChatAnswerInModal,
  initialChatSelectedSequenceInModal,
  initialChatSequenceInModal,
  initialUIState,
} from 'store/initialState';
import { allAnswersSelector } from './answers';
import { allCategoriesSelector } from './categories';
import { getTimeDifference } from 'utils/dates';

export const uiStateSelector = createSelector(
  currentBotSelector,
  bot => bot?.ui || initialUIState
);
export const modalStateSelector = createSelector(
  uiStateSelector,
  uiState => uiState.modals
);

export const responsePickerModalSelector = createSelector(
  modalStateSelector,
  modalState => modalState?.responsePickerModal || {}
);

export const similarQuestionsModalSelector = createSelector(
  modalStateSelector,
  modalState => modalState?.similarQuestionsModal || {}
);

export const aiToolModalSelector = createSelector(
  modalStateSelector,
  modalState => modalState?.aiToolModal || initialAIToolModalState
);

export const isChatOnDevModeSelector = createSelector(uiStateSelector, ui =>
  parseBoolean(ui.isChatOnDevMode)
);

export const chatAnswerInModalSelector = createSelector(
  uiStateSelector,
  ui => ui.chatAnswerInModal || initialChatAnswerInModal
);

export const chatSequenceInModalSelector = createSelector(
  uiStateSelector,
  ui => ui.chatSequenceInModal || initialChatSequenceInModal
);

export const chatSelectedSequenceInModalSelector = createSelector(
  uiStateSelector,
  ui => ui.chatSelectedSequenceInModal || initialChatSelectedSequenceInModal
);

export const isSidebarChatExpandedSelector = createSelector(
  uiStateSelector,
  ui => parseBoolean(ui.isSidebarChatExpanded)
);

export const isAnswerBankReadySelector = createSelector(uiStateSelector, ui =>
  Boolean(ui.isAnswerBankReady)
);

export const avatarPickerModalSelector = createSelector(
  modalStateSelector,
  modals => modals.avatarPickerModal
);

export const apiKeyModalSelector = createSelector(
  modalStateSelector,
  modals => modals.apiKeyModal
);

export const isAvatarPickerModalOpenSelector = createSelector(
  avatarPickerModalSelector,
  avatarPicker => Boolean(avatarPicker.isOpen)
);

export const imageViewerModalSelector = createSelector(
  modalStateSelector,
  modals => modals.imageViewerModal
);

export const isImageViewerModalOpenSelector = createSelector(
  imageViewerModalSelector,
  imageViewerModal => Boolean(imageViewerModal.isOpen)
);

export const imageViewerModalSourceSelector = createSelector(
  imageViewerModalSelector,
  imageViewerModal => imageViewerModal.source
);

export const imageViewerModalTitleSelector = createSelector(
  imageViewerModalSelector,
  imageViewerModal => Boolean(imageViewerModal.title)
);

export const isANewBotSelector = createSelector(
  currentBotSelector,
  allAnswersSelector,
  allCategoriesSelector,
  (bot, allAnswers, allCategories) => {
    const oldBotAges = [
      'd ago',
      'days ago',
      'day',
      'days',
      'yr',
      'year',
      'yr ago',
      'years ago',
    ];

    const isNotANewBot = oldBotAges.some(oldAge =>
      getTimeDifference(bot?.createdAt)?.endsWith(oldAge)
    );

    if (isNotANewBot || allAnswers.length || allCategories.length) {
      return false;
    }
    return true;
  }
);

export const isApiKeyModalOpenSelector = createSelector(
  apiKeyModalSelector,
  apiKey => Boolean(apiKey.isOpen)
);
