import {
  DEFAULT_AUTH_FIELDS,
  DEFAULT_URL_OUTPUT_FIElDS,
  DEFAULT_URL_OUTPUT_KEYS,
  DEFAULT_WALKER_OUTPUT_KEYS,
  REQUEST_METHODS,
  RESPONSE_OPTIONS,
  SELECTABLE_FIELDS,
} from 'constants/aiTools';
import { orderBy } from 'lodash';
import { stripUUID } from 'utils';
import { isAnObject } from 'utils/dataTypes';

const createObjectFromArrayOfNameAndValues = (data = []) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.reduce((acc, currentItem) => {
    acc[currentItem.name] = currentItem.value;
    return acc;
  }, {});
};

export const extractAIToolData = toolData => {
  return toolData
    .map(tool => {
      const {
        name,
        description,
        created_time,
        last_updated_time,
        parameters,
        output,
        ver,
        auth,
        ...restOfContext
      } = tool?.context;

      const { properties, ...restParamProps } = parameters || {};
      // transform paramProps to array
      // to map easily
      const paramProps = properties
        ? Object.entries(properties)?.map(([key, obj], idx) => {
            const { position, name, ...restOfProps } = obj;
            // transform to an object that the function modal can map the param props
            return {
              // 'properties' are stored in `restOfProps`
              ...restOfProps,
              position: position || idx + 1,
              name: key,
            };
          })
        : null;

      const getOutputOptions = (type, key) => {
        if (type === 'url') {
          if (key.toLowerCase() === 'method') {
            return REQUEST_METHODS;
          } else if (
            key.toLowerCase() === 'body' ||
            key.toLowerCase() === 'query' ||
            key.toLowerCase() === 'path' ||
            key.toLowerCase() === 'formatter'
          ) {
            return RESPONSE_OPTIONS;
          }
          return undefined;
        }
        return undefined;
      };

      const authItems = auth => {
        const authKey = 'auth';

        if (!auth || !auth.request) {
          return {
            ...DEFAULT_URL_OUTPUT_FIElDS.find(
              authDetails => authDetails.name === authKey
            ),
            value: auth?.type || null,
            request: DEFAULT_AUTH_FIELDS,
          };
        }

        const authRequest = Object?.entries(auth.request)?.map(([key, obj]) => {
          const authObjProps = isAnObject(obj)
            ? Object.entries(obj).map(([key, val], idx) => {
                // create key/value pair fields
                // for object type
                // to be mapped inside the modal
                return {
                  id: idx,
                  name: key,
                  value: val,
                  position: idx + 1,
                };
              })
            : undefined;

          return {
            ...DEFAULT_AUTH_FIELDS.find(auth => auth.name === key),
            value: isAnObject(obj)
              ? authObjProps.length > 0
                ? 'manual'
                : null
              : !obj
              ? 'null'
              : obj,
            properties: authObjProps
              ? authObjProps
              : SELECTABLE_FIELDS.includes(key)
              ? [{ id: 0, name: null, value: null }]
              : [],
          };
        });

        return {
          ...DEFAULT_URL_OUTPUT_FIElDS.find(
            authDetails => authDetails.name === authKey
          ),
          value: auth.type,
          request:
            [{ ...DEFAULT_AUTH_FIELDS[0], value: auth.type }, ...authRequest] ||
            DEFAULT_AUTH_FIELDS,
        };
      };

      const outputItems = Object.entries(output).map(([key, obj], idx) => {
        // transform to an object that the function modal can map/handle the output props

        // add objectProps if the current item is typeof === 'object'
        const objectProps = isAnObject(obj)
          ? Object.entries(obj).map(([key, val], idx) => {
              // create key/value pair fields
              // for object type
              // to be mapped inside the modal
              return { id: idx, name: key, value: val, position: idx + 1 };
            })
          : // no object props for non-object
            undefined;
        // add name, value, position, type

        if (key === 'auth') {
          return authItems(obj);
        } else {
          return {
            ...DEFAULT_URL_OUTPUT_FIElDS.find(
              urlDefaultDetails => urlDefaultDetails.name === key
            ),
            value: isAnObject(obj)
              ? objectProps.length > 0
                ? 'manual'
                : null
              : !obj
              ? 'null'
              : obj,
            type: obj ? (typeof obj).toLowerCase() : 'string',
            options: getOutputOptions(output['type'], key),
            // add properties if object
            properties: objectProps
              ? objectProps
              : SELECTABLE_FIELDS.includes(key)
              ? [{ id: 0, name: null, value: null }]
              : [],
            default: DEFAULT_URL_OUTPUT_KEYS.includes(key.toLowerCase())
              ? true
              : DEFAULT_WALKER_OUTPUT_KEYS.includes(key.toLowerCase())
              ? true
              : false,
          };
        }
      });

      const toolParameters = {
        ...restParamProps,
        properties: paramProps,
      };

      // transform param props to object
      // for table display proposes
      const getParamsForTable = () => {
        const { properties, ...rest } = toolParameters;
        const paramProps = properties
          ? Object.values(properties).reduce((acc, obj) => {
              const { name, position, ...rest } = obj;
              acc[name] = rest;
              return acc;
            }, {})
          : null;

        return {
          properties: paramProps,
          ...rest,
        };
      };

      const getOutputForTable = () => {
        return outputItems.reduce((acc, obj) => {
          const { name, value, type, position, ...rest } = obj;
          // only display the properties if object
          // or value, if string
          acc[name] =
            type === 'object'
              ? rest?.properties
                ? { ...createObjectFromArrayOfNameAndValues(rest.properties) }
                : undefined
              : value;
          return acc;
        }, {});
      };

      const getOutputItems = () => {
        const pathProperty = outputItems?.find(
          output => output.name === 'path' && output?.properties?.length
        );

        if (pathProperty) {
          const urlProperty = outputItems?.find(
            output => output.name === 'url'
          );
          const urlValue = pathProperty?.properties?.reduce(
            (acc, property) =>
              acc?.replace(property.value, `{${property.name}}`),
            urlProperty.value
          );
          return orderBy(
            [
              ...outputItems?.filter(output => output.name !== 'url'),
              {
                ...urlProperty,
                pathValue: urlValue,
              },
            ],
            'position',
            'asc'
          );
        } else {
          return outputItems;
        }
      };

      return {
        jid: stripUUID(tool.jid),
        name,
        description,
        createdAt: created_time,
        lastUpdatedAt: last_updated_time,
        parameters: toolParameters,
        parametersInTable: getParamsForTable(),
        outputInTable: getOutputForTable(),
        output: getOutputItems(),
        version: ver,
        ...restOfContext,
      };
    })
    .filter(Boolean);
};

export const urlAiToolValidator = (
  _,
  value,
  name,
  requiredFields,
  outputProps
) => {
  if (name === 'url') {
    const matches = value
      ?.match(/\{([^}]+)\}/g)
      ?.map(item => item.replace('{', '').replace('}', ''));
    const pathVariableMethod = outputProps?.find(
      output => output.name === 'path'
    )?.value;

    if (
      requiredFields?.filter(field => matches?.includes(field))?.length === 0 &&
      matches?.length > 0 &&
      (pathVariableMethod === 'manual' || pathVariableMethod === '{{$}}')
    ) {
      return Promise.reject(
        'Property key should be selected in the required field.'
      );
    } else if (
      pathVariableMethod === '{{$}}' &&
      requiredFields?.filter(field => value?.includes(field))?.length === 0
    ) {
      return Promise.reject(
        'Path Variable is tagged as OpenAI. URL should include required fields.'
      );
    }

    try {
      if (!value) {
        return Promise.reject('Please enter url.');
      }
      new URL(value);
      return true;
    } catch (error) {
      return Promise.reject('Please enter a valid url.');
    }
  }
};

export const keyPropertyValidator = (_, value, name, requiredFields) => {
  if (!value) {
    return Promise.reject('Please enter key.');
  }

  if (!requiredFields?.includes(value) && name === 'query') {
    return Promise.reject(
      'Property key should be selected in the required field.'
    );
  }
};

export const methodPropertyValidator = (
  _,
  value,
  name,
  requiredFields,
  outputProps
) => {
  const urlValue = outputProps?.find(output => output.name === 'url')?.value;

  const matches = urlValue
    ?.match(/\{([^}]+)\}/g)
    ?.map(item => item.replace('{', '').replace('}', ''));

  if (
    requiredFields?.filter(field => matches?.includes(field))?.length === 0 &&
    matches?.length > 0 &&
    name === 'query' &&
    value !== 'null'
  ) {
    return Promise.reject(
      'Property key should be selected in the required field.'
    );
  }
};
