import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiService } from 'services/api.service';
import ROUTES from 'constants/routes';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { isExternalPageSelector } from 'selectors/user';
import useSelector from 'store/useSelector';

const useResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const isExternalPage = useSelector(isExternalPageSelector);
  const { token } = history.location.state;

  const onChangePassword = e => {
    setPassword(e.target.value);
  };

  const onChangeConfirmPassword = e => {
    setConfirmPassword(e.target.value);
  };

  const hasErrors = () => {
    const hasEnoughCharacters = password.length >= 8;

    if (password !== confirmPassword) {
      setError({ error: 'Password did not match.' });
      return true;
    }

    if (!hasEnoughCharacters) {
      setError({ error: 'Password did not meet the required characters.' });
      return true;
    }

    return false;
  };

  const onSubmit = async evt => {
    evt.preventDefault();
    setLoading(true);
    setError(null);

    if (hasErrors()) {
      setLoading(false);
      return;
    }

    try {
      const res = await apiService.confirmPassword(password, token);
      if (res && res.status === 200) {
        setLoading(false);
        history.push({
          pathname: isExternalPage ? ROUTES.EXTERNAL_LOGIN : ROUTES.LOGIN,
          state: { success: true, action: 'update' },
        });
        setPassword('');
        setConfirmPassword('');
        setError(null);
      }
    } catch (err) {
      setLoading(false);
      setConfirmPassword('');
      return setError(err.message || DEFAULT_ERROR_MESSAGE);
    }
    return setLoading(false);
  };

  return {
    error,
    loading,
    onChangePassword,
    onChangeConfirmPassword,
    onSubmit,
  };
};

export default useResetPassword;
