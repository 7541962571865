import { AES } from 'crypto-js';
import { cssVariables } from 'styles/root';
import { generateIcon } from 'components/BotCustomizer/iconUtils';

const ZSB_JS_FILE = process.env.REACT_APP_AZURE_BOTCLIENT_FILE;
const ZSB_JS_FILE_V2 = process.env.REACT_APP_AZURE_BOTCLIENT_FILE_V2;
const ZSB_JS_URI = process.env.REACT_APP_AZURE_BOTCLIENT_URI;
const ZSB_JS_TOKEN = process.env.REACT_APP_AZURE_BOTCLIENT_SAS_TOKEN;
const AZURE_STORAGE_URI = process.env.REACT_APP_AZURE_STORAGE_URI;
const INTEGRATIONS_CONTAINER_NAME = 'integrations';

const COPY_ICON = 'copy-outline.svg';
export const COPY_TO_CLIPBOARD_SCRIPT = `
  <script type="text/javascript">
    function copyToClipboard(message) {
      var textArea = document.createElement("textarea");
      textArea.value = message;
      textArea.style.opacity = "0"; 
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          alert('Demo link copied');
      } catch (err) {
          alert('Unable to copy, error : ' + err.message);
      }

      document.body.removeChild(textArea);
    }
  </script>`;

const GREETING_MSG = `This is a demo page created by Zero Shot Bot platform.`;
const DEFAULT_HEADER = 'zeroshotbot-header-white-logo.svg';
const DEFAULT_HEADER_LIGHT = 'zeroshotbot-header-black-logo.svg';

export const BOT_SCRIPT = `<script src="${ZSB_JS_URI}/${ZSB_JS_FILE}?${ZSB_JS_TOKEN}"></script>`;
export const BOT_SCRIPT_V2 = `<script src="${ZSB_JS_URI}/${ZSB_JS_FILE_V2}?${ZSB_JS_TOKEN}"></script>`;

export const generateBotHTML = (
  bgImgURL,
  zsbComponent,
  headTag,
  isCustomBg,
  isDarkBg,
  header,
  body
) => {
  let demoHTML;

  const demoBodyStyle = `
  ${isCustomBg ? '' : COPY_TO_CLIPBOARD_SCRIPT}
  <style>
  body {
    overflow: hidden !important;
    background-image: url('${bgImgURL}');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
  
  
  .demo-header {
    z-index: 1;
    top: 0;
    padding-left: 38px;
    padding-top: 12px;
  
  }
  
  .demo-body {
    font-family: 'Arial';
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: revert;
    margin: 15% 0px 0px 20%;
  }

  .bot-name {
    font-size: 40px;
    font-weight: ${cssVariables.font.bold};
  }

  .demo-greeting-message {
    color: ${isDarkBg ? '#d3d3d3' : '#000'};
    margin-top: 15px;
  }

  .demo-link {
    flex-direction: row;
    margin: 32px 0;
    background-color: #d3d3d3;
    border-radius: 5px;
    max-width: 70% !important;
    align-self: start;
    align-items: center;
    justify-content: space-between;
    overflow-wrap: break-word;
  }

  .demo-body, .bot-name, .demo-greeting-message, .demo-link {
    display: flex;
    position: relative;
  }

  a {
    text-decoration: none;
    max-width: 80%;
    color: #333;
  }

  .copy-icon {
    height: 25px;
    padding: 5px;
    position: absolute;
    right: 0;

  }

  .copy-icon:hover {
    cursor: pointer;
  }

  .custom-close svg, .custom-close img {
    width: 24px !important;
  }

  .custom-launcher .avatar svg {
    height: 40px !important;
    width: 40px !important;
  }
  </style>
  `;
  demoHTML = headTag.replace('</script>', `$& ${demoBodyStyle}`);
  demoHTML = demoHTML.replace(
    '</head>',
    `$& <body>
      ${isCustomBg ? zsbComponent : header + body + zsbComponent}
    </body>`
  );
  return demoHTML;
};

export const generateBody = (demoLink, botName, isDarkBg) => `
<div class="demo-body">
  <div class="bot-name" style="color: ${isDarkBg ? '#FFFFFF' : '#000000'};">
    ${botName} Demo Website
  </div>
  <div class="demo-greeting-message">${GREETING_MSG}</div>
  <div class="demo-link">
    <a href="${demoLink}" target="_blank" rel="noreferrer">
      ${demoLink}
    </a>
    <img class="copy-icon" src="${COPY_ICON}" alt="copy-icon" title="copy-icon" onclick="copyToClipboard('${demoLink}')"/>
  </div>
</div>`;

export const generateHeader = isDarkBg =>
  `<div class="demo-header">
  <img src="${
    isDarkBg ? DEFAULT_HEADER : DEFAULT_HEADER_LIGHT
  }" alt="zeroshotbot" />
  </div>`;

export const generateEncryptedBot = (
  jid,
  sentinel,
  url,
  publicKey,
  pubAskedQuestion
) => {
  const botData = {
    url,
    pubAskedQuestion: {
      key: publicKey,
      nd: jid,
      wlk: pubAskedQuestion,
      snt: sentinel,
    },
  };

  return AES.encrypt(JSON.stringify(botData), '').toString();
};

export const generateEncryptedWordPressBot = (
  jid,
  sentinel,
  url,
  publicKey,
  pubAskedQuestion,
  pubInfo
) => {
  const botData = {
    url: `${AZURE_STORAGE_URI}${INTEGRATIONS_CONTAINER_NAME}/${url}`,
    key: publicKey,
    nd: jid,
    snt: sentinel,
    // CREDENTIALS FOR WIDGET ASK QUESTION
    pubAskedQuestion: {
      wlk: pubAskedQuestion,
    },
    // CREDENTIALS FOR PUBLIC APIS URL
    pubInfo: {
      wlk: pubInfo,
    },
  };

  return AES.encrypt(JSON.stringify(botData), '').toString();
};

export const generateBotComponent = (
  jid,
  sentinel,
  publicKey,
  pubAskedQuestion,
  integrationData,
  integrationConfigURL,
  height,
  isMobile,
  isDemo,
  botVersion
) => {
  const encryptedBot = generateEncryptedBot(
    jid,
    sentinel,
    integrationConfigURL,
    publicKey,
    pubAskedQuestion
  );
  const authenticatedHTMLTag = !!integrationData.settings.authenticatedUser
    ? `visitorid=$visitorID`
    : ``;
  const botComponent = isMobile
    ? `WebViewPlus(
      javascriptMode: JavascriptMode.unrestricted,
      onWebViewCreated: (controller) {
        ${
          !!integrationData.settings.authenticatedUser
            ? `final String visitorID = ModalRoute.of(context)!.settings.arguments as String;`
            : ``
        }
        final String botIntegration='${
          botVersion === 'v2' ? BOT_SCRIPT_V2 : BOT_SCRIPT
        }<zeroshot-bot textColor="${
        integrationData.settings.textColor || '#fff'
      }" color="${integrationData.settings.color}" height="${
        integrationData.settings.height?.includes('px')
          ? integrationData.settings.height
          : integrationData.settings.height + 'px'
      }" bot="${encryptedBot}" showCloseButton="${
        integrationData.settings.showCloseButton
      } " autoOpen="${
        integrationData.settings.autoOpen
      } " ${authenticatedHTMLTag}></zeroshot-bot>';
        _controller = controller;
        controller.loadString(botIntegration);
              }
            )`
    : `<zeroshot-bot textColor="${
        integrationData.settings.textColor || '#fff'
      }" color="${integrationData.settings.color}" height="${
        integrationData.settings.height?.includes('px')
          ? integrationData.settings.height
          : integrationData.settings.height + 'px'
      }" bot="${encryptedBot}"></zeroshot-bot>`;

  return botComponent;
};

export const generateMobileHTMLTagHeader = integrationData => {
  return `FloatingActionButton(
        ${
          !!integrationData?.authenticatedUser
            ? ` onPressed: () => _redirectToWebViewPage(context, <visitorIDHERE>),`
            : ` onPressed: () => _redirectToWebViewPage(context),`
        }
        child: SvgPicture.string('''<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  ${generateIcon(integrationData.avatar, integrationData.icon)}
                  </svg>''')
        )
        ${
          !!integrationData?.authenticatedUser
            ? `  void _redirectToWebViewPage(BuildContext context, visitorID) {`
            : ` void _redirectToWebViewPage(BuildContext context) {`
        }
          Navigator.push(context,
              MaterialPageRoute(
                  builder: (context) => WebViewContainer(),
                  ${
                    !!integrationData?.authenticatedUser
                      ? `settings: RouteSettings(arguments: visitorID)`
                      : ``
                  }
              ));
        }`;
};

export const generateWebHTMLTagHeader = (authenticatedUser, botVersion) => {
  return `<!DOCTYPE html>
    <html lang='en'>
      <head>
      <meta charset='utf-8'>
      ${botVersion === 'v2' ? BOT_SCRIPT_V2 : BOT_SCRIPT}
      ${
        !!authenticatedUser
          ? `<script>
          window.onload = () => {
            const zsbComponent = document.querySelector('zeroshot-bot')
            zsbComponent.setAttribute('visitorID', '<include visitor ID here>')
          }
        </script>`
          : ``
      }
      </head>
    </html>`;
};

export const generateAuthenticatedUserNPMHTMLTag = authenticatedUser => {
  return authenticatedUser
    ? `useEffect(() => {
            const zsbComponent = document.querySelector('zeroshot-bot')
            zsbComponent.setAttribute('visitorID', '<include visitor ID here>')
          }, []);
          
        `
    : ``;
};
