import { flattenDeep } from 'lodash';
import {
  CLEAR_CALLBACK_MODAL_SESSION_DETAILS,
  SET_CALLBACK_LOGS_TABLE_DATA,
  SET_CALLBACK_MODAL_SESSION_DETAILS,
} from 'store/action';
import { createESAnalyticsTableData } from '../helpers/bot/analytics';
import { parseBoolean } from 'utils/dataTypes';

export const callbackLogsReducer = (state, action) => {
  switch (action.type) {
    case SET_CALLBACK_MODAL_SESSION_DETAILS: {
      const { data, callbackInteractionIds, sessionId, isFromAPIResponse } =
        action.payload;

      const sessionData = (
        parseBoolean(isFromAPIResponse)
          ? createESAnalyticsTableData(data).allQuestions
          : Array.isArray(data)
          ? data
          : [data]
      )
        .map(question => {
          if (
            question.interactionID &&
            question.sessionID === sessionId &&
            !!callbackInteractionIds &&
            Array.isArray(callbackInteractionIds) &&
            callbackInteractionIds.includes(question.interactionID)
          ) {
            question.isCallback = true;
            return {
              ...question,
              isCallback: true,
            };
          }

          return {
            ...question,
            isCallback: false,
          };
        })
        .filter(question => question.sessionID === sessionId);

      return {
        ...state,
        bot: {
          ...state.bot,
          callbackLogs: {
            ...state.bot.callbackLogs,
            sessionDetails: {
              sessionId,
              callbackInteractionIds: (Array.isArray(callbackInteractionIds)
                ? callbackInteractionIds
                : [callbackInteractionIds]
              ).filter(Boolean),
              data: sessionData,
            },
          },
        },
      };
    }

    case CLEAR_CALLBACK_MODAL_SESSION_DETAILS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          callbackLogs: {
            ...state.bot.callbackLogs,
            sessionDetails: {
              data: [],
            },
          },
        },
      };
    }

    case SET_CALLBACK_LOGS_TABLE_DATA: {
      const { data, startDate, endDate } = action.payload;
      const callbackData = flattenDeep(data);

      const callbackLogsTableData = callbackData.map((item, key) => {
        return {
          key,
          sessionID: item.session_id || '',
          name: item.customer_info?.name || '',
          email:
            item.customer_info?.email || item.customer_info?.emailAddress || '',
          mobile:
            item.customer_info?.mobile ||
            item.customer_info?.mobileNumber ||
            item.customer_info?.phoneNumber ||
            '',
          date: item.datetime,
          others: { ...item.customer_info },
          interactionID:
            item?.interaction_id && Array.isArray(item.interaction_id)
              ? item.interaction_id
              : typeof item?.interaction_id === 'string'
              ? [item?.interaction_id]
              : [],
        };
      });

      return {
        ...state,
        bot: {
          ...state.bot,
          callbackLogs: {
            ...state.bot.callbackLogs,
            table: callbackLogsTableData,
            startDate,
            endDate,
          },
        },
      };
    }

    default:
      return state;
  }
};
