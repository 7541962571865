import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Context } from 'store/store';
import ROUTES from 'constants/routes';

const useSignUp = () => {
  const [state] = useContext(Context);
  const history = useHistory();
  const { pathname } = useLocation();
  const { isAuthenticated } = state;
  const isSignup = pathname.includes('signup') ? true : false;

  useEffect(() => {
    if (isAuthenticated) {
      history.push(ROUTES.BOTS_PAGE);
    }
  }, []);

  return {
    isSignup,
    pathname,
  };
};

export default useSignUp;
