import { message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Context } from 'store/store';
import { csvToArray } from 'utils';
import { strippedString } from 'utils/stringManipulation';
import { validateQuestionJSONSchema } from 'utils/validator';
import { apiService } from 'services/api.service';
import {
  ADD_MULTIPLE_DRAFT_QUESTIONS,
  SET_ONBOARDING_FLAG,
} from 'store/action';
import {
  getTotalAnswersLeftSelector,
  isMaxAnswersSelector,
} from 'selectors/bot/answers';
import useSelector from 'store/useSelector';
import { EMPTY_ANSWER_OBJECT } from 'constants/answerbank/defaults';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const useImportQuestionModal = ({ fileType, onClose, show }) => {
  const [state, dispatch] = useContext(Context);
  const {
    bot: { jid, questions },
    sentinel,
    token,
    graph,
    plan,
  } = state;
  const { plan_type, onboarding_flag } = plan;
  const limitReached = useSelector(isMaxAnswersSelector);
  const answersAllowed = useSelector(getTotalAnswersLeftSelector);
  const [questionFile, setQuestionFile] = useState(null);
  const [importErrorMsg, setImportErrorMsg] = useState(null);
  const [importWarnMsg, setImportWarnMsg] = useState(null);
  const [similarAnswerWarnMsg, setSimilarAnswerWarnMsg] = useState(null);
  const [finalAnswerWarnMsg, setFinalAnswerWarnMsg] = useState(null);
  const [isImportBtnDisabled, setIsImportBtnDisabled] = useState(true);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [modalBtnLoading, setModalBtnLoading] = useState(false);
  const [verificationBtnLoading, setVerificationBtnLoading] = useState(false);
  const [parsedFileQuestions, setParsedFileQuestions] = useState([]);
  const [duplicatesFound, setDuplicatesFound] = useState([]);
  const [duplicatesFoundInFile, setDuplicatesFoundInFile] = useState([]);
  const [showImportTip, setShowImportTip] = useState(false);
  const [showCancelReqModal, setShowCancelReqModal] = useState(false);
  const [visibility, setVisibility] = useState(show);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [showAnswerEditor, setShowAnswerEditor] = useState(false);
  const [fileName, setFileName] = useState('');
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const controller = new AbortController();

  const getStringQuestionDuplicate = input => {
    const strippedInput = strippedString(input);
    return questions.find(
      question => strippedString(question.question.text) === strippedInput
    );
  };

  const getDuplicatesFromFile = questions => {
    const uniqQuestions = new Set(
      questions.map(question => {
        if (typeof question === 'string') {
          return question;
        } else {
          return question.text;
        }
      })
    );

    return uniqQuestions.size < questions.length;
  };

  const getDuplicatesFromFileList = questions => {
    const newSetQuestion = new Set(
      questions.map(question =>
        fileType === 'json' ? question?.question?.text : question.text
      )
    );
    return questions.filter(question => {
      const questionDetails =
        fileType === 'json' ? question?.question?.text : question;
      if (newSetQuestion.has(questionDetails)) {
        newSetQuestion.delete(questionDetails);
      } else {
        return questionDetails;
      }
    });
  };

  const sources = {
    json: {
      source: fileName,
      source_type: 'file_upload',
    },
  };

  const handleSubmitFile = async fileType => {
    setModalBtnLoading(true);
    if (fileType === 'json') {
      const dataValidation = validateQuestionJSONSchema(parsedFileQuestions);

      if (dataValidation.status === 'missing_fields') {
        let errorMsg =
          'Following fields are missing for some of the data in the json file: ';
        errorMsg += dataValidation.data.join(', ');

        setImportErrorMsg(errorMsg);
        return setModalBtnLoading(false);
      }

      if (dataValidation.status === 'invalid_fields') {
        let errorMsg =
          'Following fields have invalid data type for some of the data in the json file: ';
        errorMsg += dataValidation.data.join(', ');

        setModalBtnLoading(false);
        return setImportErrorMsg(errorMsg);
      }
    }

    handleDuplicateQuestions(parsedFileQuestions);
    const extraQuestions = parsedFileQuestions.length - answersAllowed;
    const splicedQuestion = parsedFileQuestions.filter(
      (item, idx) => idx + 1 <= answersAllowed
    );

    try {
      const question = [];
      const questionToSubmit = extraQuestions
        ? splicedQuestion
        : parsedFileQuestions;

      questionToSubmit.map(questionToSubmit => {
        question.push({
          ...questionToSubmit,
          text: questionToSubmit.text,
          question_version: 'draft',
        });
      });

      const response = await apiService.importer(sentinel, jid, token, {
        question,
      });

      const resQuestion = response?.data?.report[0]?.question;
      const newQuestions = [];

      if (!resQuestion?.length) {
        setModalBtnLoading(false);
        setIsImportBtnDisabled(true);
        return message.error(
          `No valid question found in ${questionFile?.name}`
        );
      } else {
        if (resQuestion?.length > 0) {
          for (const questionItem of resQuestion) {
            newQuestions.push({
              question: {
                ...questionItem.context,
                version: questionItem.context.question_version,
                linkType: questionItem.context.link_type,
                type: questionItem.name,
                jid: questionItem.jid,
              },
              answer: EMPTY_ANSWER_OBJECT,
            });
          }
        }
        if (!newQuestions?.length) {
          setModalBtnLoading(false);
          setIsImportBtnDisabled(true);
          return message.error(
            `No valid question found in ${questionFile.name}`
          );
        }

        dispatch({
          type: ADD_MULTIPLE_DRAFT_QUESTIONS,
          payload: newQuestions,
        });

        setIsUploadSuccess(true);
        setShowCancelReqModal(false);
        message.success(`Question(s) added successfully.`);
        setModalBtnLoading(false);
        handleCloseUploadModal(true, true);
        return onClose();
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setShowCancelReqModal(false);
        setIsImportBtnDisabled(false);
        setIsUploadSuccess(true);
        onClose();
        return message.error(error.message || 'Operation aborted by the user.');
      } else {
        setModalBtnLoading(false);
        setIsImportBtnDisabled(false);
        onClose();
        return message.error(
          error.message || `Something went wrong. File upload failed.`
        );
      }
    }
  };

  const handleCheckValidations = () => {
    setCurrentStep(currentStep + 1);
    handleDuplicateQuestions(parsedFileQuestions);

    if (answersAllowed < parsedFileQuestions.length) {
      setImportWarnMsg(
        `Maximum number of answers reached. System will only upload ${
          answersAllowed <= 0 ? 0 : answersAllowed
        } answer(s).`
      );
    }
  };

  const handleStepChange = curr => {
    if (!parsedFileQuestions || !parsedFileQuestions.length) {
      return;
    } else {
      setCurrentStep(curr);
    }
  };

  const handleToggleAnswerEditor = (answer, idx) => {
    if (showAnswerEditor && !answer) {
      setSelectedQuestion({});
      setShowAnswerEditor(false);
    } else {
      setSelectedQuestion({ ...answer, idx });
      setShowAnswerEditor(true);
    }
  };

  const handleUploadFile = info => {
    const isJson = info.type === 'application/json';
    const isCSV = info.type.includes('csv');
    setFileName(info.name);

    setImportErrorMsg(null);
    setImportWarnMsg(null);
    const reader = new FileReader();
    reader.onload = e => {
      const question = e.target.result;
      let parsedQuestions = [];
      let delimiters = ['\n'];
      setQuestionFile(info.name);

      if (isCSV) {
        delimiters.push(',');
      }

      if (!isJson) {
        parsedQuestions = csvToArray(question, delimiters, true);
      } else {
        parsedQuestions = JSON.parse(question);
      }

      if (!isJson && question.length) {
        setParsedFileQuestions(parsedQuestions);
        setIsImportBtnDisabled(false);
      } else if (isJson && parsedQuestions && parsedQuestions.length) {
        setParsedFileQuestions(parsedQuestions);
        setIsImportBtnDisabled(false);
      } else if (
        isJson &&
        parsedQuestions?.bot[0]?.question &&
        parsedQuestions?.bot[0]?.question?.length
      ) {
        setParsedFileQuestions(parsedQuestions?.bot[0]?.question);
        setIsImportBtnDisabled(false);
      } else if (
        isJson &&
        Array.isArray(parsedQuestions) &&
        !parsedQuestions.length
      ) {
        setImportErrorMsg(`No valid question found from the file.`);
      } else {
        setImportErrorMsg('Your json data format is not correct');
      }
    };
    reader.readAsText(info);
    return false;
  };

  const handleRemoveFile = () => {
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setParsedFileQuestions([]);
    setIsImportBtnDisabled(true);
  };

  const handleEditAnswer = (e, idx) => {
    if (fileType === 'json') {
      parsedFileQuestions[idx].text = e.target.value;
    } else {
      parsedFileQuestions[idx] = e.target.value;
    }
    handleDuplicateQuestions(parsedFileQuestions);
    return setParsedFileQuestions([...parsedFileQuestions]);
  };

  const handleDuplicateQuestions = questionList => {
    const textQuestions = questionList.map(question => {
      if (fileType === 'json') {
        return getStringQuestionDuplicate(question.text);
      }
    });
    setDuplicatesFound(textQuestions.filter(item => !!item));
    setDuplicatesFoundInFile(getDuplicatesFromFileList(questionList));
    const hasFileDuplicate = getDuplicatesFromFile(questionList);
    const duplicateFound = textQuestions.filter(item => !!item);
    if (duplicateFound.length > 0 || hasFileDuplicate) {
      setImportErrorMsg(
        'Duplicate Questions Found. Please review the questions.'
      );
    } else {
      setImportErrorMsg(null);
    }
  };

  const handleRemoveAnswer = id => {
    const filteredAnswers = parsedFileQuestions.filter(
      (answer, idx) => id !== idx
    );

    setParsedFileQuestions([...filteredAnswers]);
    handleDuplicateQuestions(filteredAnswers);
    if (!filteredAnswers.length) {
      setCurrentStep(currentStep - 1);
    }

    if (answersAllowed >= filteredAnswers.length && importWarnMsg) {
      setImportWarnMsg(null);
    }
  };

  useEffect(() => {
    if (!visibility && parsedFileQuestions.length) {
      handleCancelImport();
    }
  }, [parsedFileQuestions]);

  const handleCloseUploadModal = (closeAll, isAddedAnswer) => {
    if (!closeAll && modalBtnLoading) {
      setCurrentStep(0);
      message.info('Import process will continue in the background', 10);
      setVisibility(false);
      return onClose();
    } else if (closeAll) {
      setCurrentStep(0);
      setShowImportTip(false);
      setImportErrorMsg(null);
      setImportWarnMsg(null);
      setFinalAnswerWarnMsg(null);
      setSimilarAnswerWarnMsg(null);
      setParsedFileQuestions([]);
      setVisibility(false);

      if (!isAddedAnswer) {
        return onClose();
      }
    }
  };

  const handleCancelImport = () => {
    controller.abort();
    setModalBtnLoading(false);
    return handleCloseUploadModal(true);
  };

  const handleUpdateAnswerEditor = val => {
    const updatedAnswers = parsedFileQuestions.map((answer, key) => {
      if (key === val.idx) {
        setSelectedQuestion(val);
        return val;
      }
      return answer;
    });
    return setParsedFileQuestions(updatedAnswers);
  };

  const handleClearData = () => {
    setCurrentStep(currentStep + -1);
    setImportErrorMsg(null);
    setImportWarnMsg(null);
    setFinalAnswerWarnMsg(null);
    setSimilarAnswerWarnMsg(null);
  };

  const handleCloseConfirmationModal = () => {
    handleClearData();
    setCurrentStep(0);
    return onClose();
  };

  const onClickCallback = async data => {
    const { action, index, type, status } = data;
    if (STATUS.FINISHED === status || STATUS.SKIPPED === status) {
      setRunTour(false);
      setStepIndex(0);
      if (!onboarding_flag.includes('ImportQuestionButton')) {
        await apiService.setOnboardingFlag(
          sentinel,
          token,
          graph,
          'ImportQuestionButton'
        );
        dispatch({
          type: SET_ONBOARDING_FLAG,
          payload: 'ImportQuestionButton',
        });
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex);
    }
  };

  useEffect(() => {
    if (!onboarding_flag.includes('ImportQuestionButton')) {
      setTimeout(() => {
        setRunTour(true);
      }, 1000);
    }
    return () => setRunTour(false);
  }, [onboarding_flag]);

  return {
    answersAllowed,
    questionFile,
    currentStep,
    fileType,
    handleRemoveAnswer,
    importErrorMsg,
    importWarnMsg,
    isImportBtnDisabled,
    isUploadSuccess,
    limitReached,
    modalBtnLoading,
    parsedFileQuestions,
    duplicatesFound,
    duplicatesFoundInFile,
    handleCancelImport,
    handleCloseUploadModal,
    handleEditAnswer,
    handleRemoveFile,
    handleToggleAnswerEditor,
    handleStepChange,
    handleSubmitFile,
    handleUpdateAnswerEditor,
    handleUploadFile,
    handleCheckValidations,
    selectedQuestion,
    questions,
    sentinel,
    setCurrentStep,
    setImportErrorMsg,
    setParsedFileQuestions,
    setSelectedQuestion,
    setShowImportTip,
    setShowCancelReqModal,
    handleClearData,
    handleCloseConfirmationModal,
    showCancelReqModal,
    showImportTip,
    token,
    visibility,
    onboarding_flag,
    dispatch,
    graph,
    verificationBtnLoading,
    similarAnswerWarnMsg,
    finalAnswerWarnMsg,
    runTour,
    stepIndex,
    onClickCallback,
  };
};

export default useImportQuestionModal;
