import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import ROUTES from 'constants/routes';
import { useGoogleAuthentication } from 'utils/socialAuth/google/useGoogleAuthentication';
import { SIGN_UP_SUCCESS } from 'store/action';
import useSelector from 'store/useSelector';
import { isExternalPageSelector } from 'selectors/user';

const useSignUp = () => {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const url = new URL(window.location.href);
  const urlName = url.searchParams.get('name') || '';
  const urlEmail = url.searchParams.get('email') || '';
  const [name, setName] = useState(urlName);
  const [email, setEmail] = useState(urlEmail);
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const { isAuthenticated } = state;
  const [signInToGoogle, setSignInToGoogle] = useState(false);
  const [spinnerLabel, setSpinnerLabel] = useState('Processing...');
  const googleAUth = useGoogleAuthentication(
    setSignInToGoogle,
    setEmail,
    setPassword,
    setError,
    setSpinnerLabel
  );
  const isExternalPage = useSelector(isExternalPageSelector);

  const onChangeName = e => {
    setName(e.target.value);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const onChangePassword = e => {
    setPassword(e.target.value);
  };

  const onSubmit = async evt => {
    evt.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await apiService.userCreate(email, password, name);
      await dispatch({
        type: SIGN_UP_SUCCESS,
      });
      if (res.data?.is_activated) {
        setIsActivated(true);
      }
      setSignupSuccess(true);
      return setLoading(false);
    } catch (error) {
      setError(
        error.message ||
          'We encountered unexpected error while creating your account.'
      );
      return setLoading(false);
    }
  };

  const requestGoogleAccess = async () => {
    setLoading(true);
    setEmail(null);
    setPassword(null);
    if (Object.values(googleAUth.tokenClient).length > 0) {
      return await googleAUth.tokenClient.requestCode();
    } else {
      return googleAUth.refreshAuth();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push(ROUTES.BOTS_PAGE);
    }
  }, []);

  return {
    email,
    error,
    isActivated,
    loading,
    name,
    onChangeName,
    onChangeEmail,
    onChangePassword,
    onSubmit,
    signupSuccess,
    signInToGoogle,
    requestGoogleAccess,
    isExternalPage,
  };
};

export default useSignUp;
