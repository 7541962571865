import React from 'react';
import PropTypes from 'prop-types';
import { Col, Tag } from 'antd';

import Modal from 'components/Modals/GenericModal';
import { getLocalTimeString } from 'utils/dates';
import useCallBackModal from './hooks';
import {
  StyledItem,
  StyledContent,
  StyledQuestionContainer,
  StyledQuestion,
  StyledDateTime,
  StyledCallbackModalTitle,
  StyledLegend,
  StyleCustomerInforWrapper,
} from './CallbackModal.styles';
import Select from 'components/Select';
import { cssVariables } from 'styles/root';
import { AnswerRenderer } from 'components/AnswerRenderer';

const CallbackModal = ({ onClose, show, loading, customerInfo }) => {
  const {
    allInteractionIDs,
    data,
    handleSelectInteractionID,
    selectedInteractionIDs,
    callBackInteractionIds,
  } = useCallBackModal();
  return (
    <Modal
      visible={show}
      title={
        <StyledCallbackModalTitle>
          <h4>{'Session Details'}</h4>
          {customerInfo ? (
            <StyleCustomerInforWrapper>
              <Col span={12}>Email:</Col>
              <Col span={12}>{customerInfo?.email || 'N/A'}</Col>
              <Col span={12}>Phone Number:</Col>
              <Col span={12}>{customerInfo?.mobile || 'N/A'}</Col>
            </StyleCustomerInforWrapper>
          ) : null}
          {callBackInteractionIds?.length ? (
            <>
              <Select
                allowClear
                mode="multiple"
                value={selectedInteractionIDs}
                onChange={handleSelectInteractionID}
                width={'100%'}
                placeholder={'Callback Interaction ID filter'}
                options={allInteractionIDs}
                size="medium"
              />
              <StyledLegend>
                <Tag color={cssVariables.blue1}>&nbsp;</Tag>
                <span>{' Callback Requested'}</span>
              </StyledLegend>
            </>
          ) : null}
        </StyledCallbackModalTitle>
      }
      onCancel={onClose}
      cancelText={`Close`}
      spinning={loading}
      style={{ minHeight: '16vh', maxHeight: '80vh' }}
      width={'50%'}
      closable={false}
    >
      <>
        {data.map((item, key) => {
          return (
            <StyledItem
              key={`${key}-${item.id}`}
              title={item.interactionID}
              isHighlighted={item.isCallback}
            >
              <StyledContent>
                <StyledQuestionContainer>
                  <StyledQuestion>{item.question}</StyledQuestion>
                </StyledQuestionContainer>
                <AnswerRenderer
                  answer={item.answer}
                  showDisplayAnswer
                  showInChatBubble
                />
              </StyledContent>
              <StyledDateTime>
                {item.interactionID ? (
                  <>
                    <div>{getLocalTimeString(item.date)}</div>
                    <div>{'Interaction ID:'}</div>
                    <div>{item.interactionID}</div>
                  </>
                ) : null}
              </StyledDateTime>
            </StyledItem>
          );
        })}
      </>
    </Modal>
  );
};

CallbackModal.propTypes = {
  sessionID: PropTypes.any,
  callbackInteractionIDs: PropTypes.array,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  questions: PropTypes.array,
  show: PropTypes.bool.isRequired,
  customerInfo: PropTypes.object,
};

export default CallbackModal;
