import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { PrinterOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import { StyledTitle } from './Title.styles';

const Title = props => {
  const {
    text,
    type,
    rightEl,
    subHeader,
    hasPrintBtn,
    onPrint,
    buttonText,
    ...rest
  } = props;

  if (rightEl) {
    return (
      <StyledTitle hasRightEl>
        <div className={`title ${type}`}>{text}</div>
        <div className="right-el">{rightEl}</div>
      </StyledTitle>
    );
  }

  return (
    <StyledTitle hasPrintBtn={hasPrintBtn}>
      <div className={`title ${type}`}>{text}</div>
      {!!subHeader && <span>{subHeader}</span>}
      {hasPrintBtn ? (
        <Button
          value={buttonText || 'Print Page'}
          startIcon={<PrinterOutlined />}
          onClick={onPrint}
        />
      ) : null}
    </StyledTitle>
  );
};

Title.propTypes = {
  text: PropTypes.any.isRequired,
  rightEl: PropTypes.any,
  type: PropTypes.oneOf(['primary', 'secondary']),
  subHeader: PropTypes.any,
  hasPrintBtn: PropTypes.bool,
  onPrint: PropTypes.func,
  buttonText: PropTypes.string,
};

export { Title };
