import { message } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { useContext } from 'react';
import { useState } from 'react';

import { similarQuestionsModalSelector } from 'selectors/bot/ui';
import { getTokenSelector } from 'selectors/user';
import { apiService } from 'services/api.service';
import {
  CLOSE_SIMILAR_QUESTIONS_MODAL,
  SHOW_QUESTION_EDITOR,
  SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD,
} from 'store/action';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';

const useSimilarQuestionsModal = () => {
  const [state, dispatch] = useContext(Context);
  const {
    sentinel,
    bot: { jid, name },
  } = state;
  const token = useSelector(getTokenSelector);
  const similarQuestionsModal = useSelector(similarQuestionsModalSelector);

  const [loading, setLoading] = useState(false);

  const handleCloseSimilarQuestionsModal = () => {
    dispatch({
      type: CLOSE_SIMILAR_QUESTIONS_MODAL,
    });
  };

  const openResponseModalPicker = async (text, answer, idx) => {
    setLoading(true);
    if (
      text === 'string' &&
      !text.length &&
      !answer &&
      !Object.keys(similarQuestionsModal?.itemToChange)?.length
    ) {
      return false;
    }
    const question =
      typeof text === 'string' && text.length
        ? text
        : similarQuestionsModal.itemToChange?.question;
    const responsePickerSelectedAnswer =
      typeof answer === 'object' && Object.keys(answer).length
        ? answer
        : // answer came from similarAnswersModal
          similarQuestionsModal.itemToChange?.answer;

    const title = 'Hard Link Question to Answer';
    const res = await apiService.getQuestionMatchesWithAnswer(
      question,
      null,
      sentinel,
      jid,
      token
    );

    if (!res.data?.report[0]) {
      loading(false);
      return message.error(DEFAULT_ERROR_MESSAGE);
    }

    handleCloseSimilarQuestionsModal();
    await dispatch({
      type: SHOW_RESPONSE_PICKER_MODAL_WITH_ANSWERSCORE_PAYLOAD,
      payload: {
        answers: res.data.report[0],
        botName: name,
        withQuestion: true,
        editableQuestion: true,
        title,
        action: similarQuestionsModal.action,
        isHardLinked: responsePickerSelectedAnswer?.isHardLinked,
        answerId: responsePickerSelectedAnswer?.jid,
        itemToChange: {
          question: { text: question },
          answer: responsePickerSelectedAnswer,
          idx: idx || similarQuestionsModal?.itemToChange?.idx || 0,
        },
      },
    });
    setLoading(false);
  };

  const openQuestionEditor = async (text, answer, idx) => {
    if (
      text === 'string' &&
      !text.length &&
      !answer &&
      !Object.keys(similarQuestionsModal?.itemToChange)?.length
    ) {
      return false;
    }
    const question =
      typeof text === 'string' && text.length
        ? text
        : similarQuestionsModal.itemToChange?.question;
    const responsePickerSelectedAnswer =
      typeof answer === 'object' && Object.keys(answer).length
        ? answer
        : // answer came from similarAnswersModal
          similarQuestionsModal.itemToChange?.answer;
    const res = await apiService.getSimilarQuestions(
      question,
      sentinel,
      jid,
      token
    );

    if (!res.data?.report[0]) {
      setLoading(false);
      return message.error(DEFAULT_ERROR_MESSAGE);
    }

    await dispatch({
      type: CLOSE_SIMILAR_QUESTIONS_MODAL,
    });
    await dispatch({
      type: SHOW_QUESTION_EDITOR,
      payload: {
        answersWithScore: res.data.report[0],
        action: similarQuestionsModal?.action || 'add',
        isHardLinked: responsePickerSelectedAnswer?.isHardLinked,
        answerId: responsePickerSelectedAnswer?.jid,
        text: question,
        jid: responsePickerSelectedAnswer?.isHardLinked
          ? responsePickerSelectedAnswer.questionId
          : null,
      },
    });
  };

  return {
    handleCloseSimilarQuestionsModal,
    loading,
    openResponseModalPicker,
    openQuestionEditor,
    similarQuestionsModal,
  };
};

export default useSimilarQuestionsModal;
