import React from 'react';
import { Switch } from 'antd';

import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import {
  StyledErrorBottomText,
  StyledSwitchWrapper,
} from '../../CustomizeBot.style';
import FormBuilder from '../../FormBuilder';
import useFeedbackAndHandOff from './hooks';

const FeedbackAndHandOff = props => {
  const {
    authenticatedUser,
    callbackEmail,
    cancelledFormMessage,
    displayFormFields,
    handoffLabel,
    isSendCallbackLog,
    submittedFormMessage,
    tempFormFields,
    handleAddFormField,
    handleBlurFormField,
    handleBlurHandOffFields,
    handleChangeFormFields,
    handleChangeHandOffField,
    handleDeleteFormRow,
    handlePanelChange,
    handleToggleCallbackEmail,
    handleToggleDisplayFormFields,
  } = useFeedbackAndHandOff();

  return (
    <StyledFlexLeftColumn>
      <Input
        value={handoffLabel}
        label="Hand-off Label"
        placeholder="Enter hand-off label"
        onChange={handleChangeHandOffField('label')}
        onBlur={handleBlurHandOffFields('label')}
        full
      />
      {!handoffLabel ? (
        <StyledErrorBottomText>
          Hand-off Label is Required.
        </StyledErrorBottomText>
      ) : null}
      <TextArea
        value={submittedFormMessage}
        label="Hand-off Response"
        placeholder="Enter hand-off Response"
        onChange={handleChangeHandOffField('messageSent')}
        onBlur={handleBlurHandOffFields('messageSent')}
      />

      {!submittedFormMessage ? (
        <StyledErrorBottomText>
          Hand-off Cancelled Response is Required.
        </StyledErrorBottomText>
      ) : null}

      <TextArea
        value={cancelledFormMessage}
        label="Hand-off Cancelled Response"
        placeholder="Enter hand-off Response"
        onChange={handleChangeHandOffField('cancelMessage')}
        onBlur={handleBlurHandOffFields('cancelMessage')}
      />

      {!cancelledFormMessage ? (
        <StyledErrorBottomText>
          Hand-off Cancelled Response is Required.
        </StyledErrorBottomText>
      ) : null}

      {authenticatedUser === true && (
        <StyledSwitchWrapper>
          <div>
            <b>{'Display Feedback Form'}</b>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={displayFormFields}
              onChange={handleToggleDisplayFormFields}
            />
          </div>
        </StyledSwitchWrapper>
      )}
      {displayFormFields === true && (
        <StyledFlexLeftColumn>
          <FormBuilder
            tempFormFields={tempFormFields}
            onAddFormField={handleAddFormField}
            onChangeFormFields={handleChangeFormFields}
            onDeleteFormRow={handleDeleteFormRow}
            onBlurFormField={handleBlurFormField}
          />
          {isSendCallbackLog && (
            <StyledSwitchWrapper>
              <div>
                <b>{'Allow Callback Email'}</b>
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={callbackEmail}
                  onChange={handleToggleCallbackEmail}
                />
              </div>
            </StyledSwitchWrapper>
          )}
        </StyledFlexLeftColumn>
      )}
    </StyledFlexLeftColumn>
  );
};

FeedbackAndHandOff.propTypes = {};

export default FeedbackAndHandOff;
