export const TEXT_INGEST_DROPDOWN_BUTTON_STEPS = [
  {
    content: (
      <div>
        <h3
          style={{
            display: 'inline-block',
          }}
        >
          Import Answer
        </h3>
        <p>
          This Import button will allow you to import multiple answers from a
          file. Supported formats are .csv, .txt, and .json file.
        </p>
      </div>
    ),
    target: '.import-button-dropdown',
    disableBeacon: true,
    hideFooter: true,
    placement: 'left',
  },
  {
    content: (
      <div>
        <h3
          style={{
            display: 'inline-block',
          }}
        >
          Import Answer
        </h3>
        <p>
          Use this feature to import an answers from a website (URL) or from a
          section in an article.
        </p>
      </div>
    ),
    target: '.text-ingest',
    disableBeacon: true,
    hideFooter: true,
    placement: 'left',
  },
];
