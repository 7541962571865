import styled from 'styled-components';
import { cssVariables } from 'styles/root';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  width: ${props => (props.isFullScreen ? '100%' : '70%')};
  max-height: ${props => (props.isFullScreen ? '100vh' : '70vh')};
  top: ${props => (props.isFullScreen ? '0px' : 'null')};
  max-width: ${props => (props.isFullScreen ? '100%' : 'inherit')};

  &.ant-modal {
    height: ${props => (props.isFullScreen ? '100% !important' : 'auto')};
    padding-bottom: ${props => (props.isFullScreen ? '0px' : '24px')};
  }
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : 'auto')};

  img {
    width: 100%;
  }

  @media (max-width: 1024px) {
    &.ant-modal {
      width: 100%;
    }
  }

  @media (max-width: 521px) {
    &.ant-modal {
      width: 100% !important;
    }
  }

  @media (max-width: 521px) {
    &.ant-modal {
      width: 100% !important;
    }
  }

  @media (max-width: 768px) {
    &.ant-modal {
      width: ${props =>
        props.isFormModal ? '100% !important' : 'fit-content'};
    }
  }

  .ant-modal-title {
    display: flex;
    margin-right: 20px;
    align-items: center;
    justify-content: space-between;

    :last-child {
      padding: 5px 5px 0 0;
    }

    .anticon:hover {
      color: ${cssVariables.primaryBlue};
      cursor: pointer;
    }
  }
  .ant-modal-close {
    max-width: 50px;
    margin-right: 0.4rem;
  }

  & .ant-modal-wrap {
    overflow-y: hidden;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 24px;
    row-gap: 10px;
    overflow-y: auto !important;
    max-height: ${props => (props.isFullScreen ? '100vh' : '65vh')};
    align-items: ${props => (props.isFullScreen ? 'center' : 'inherit')};
    height: ${props => (props.isFullScreen ? '80vh' : '70%')} !important;

    form {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .ant-input {
      border-radius: 5px;
    }

    .ant-upload-list-item-card-actions button {
      background-color: transparent;
    }

    .ant-spin-nested-loading,
    .ant-spin-container {
      display: contents;
    }
  }

  .ant-modal-content {
    border-radius: 5px;
    height: ${props => (props.isFullScreen ? '100% !important' : 'auto')};
  }

  .ant-modal-header,
  .ant-modal-footer {
    height: ${props => (props.isFullScreen ? '10vh' : '10%')};
    border-radius: 5px;
    border: none;
    padding: 10px 24px;

    .ant-modal-title {
      font-weight: ${cssVariables.font.normal};
      font-size: 16px;
      font-family: Roboto, Helvetica, Arial, sans- serif;
      line-height: 1.6;
      h4 {
        margin-top: 5;
      }
    }
  }

  .ant-modal-header {
    padding-bottom: 0;
  }

  .ant-modal-footer {
    .ant-btn {
      background-color: ${props =>
        props?.variant && props?.variant.includes('primary')
          ? cssVariables.primaryBlue
          : cssVariables.secondaryColor};
      color: ${props =>
        props.okButtonProps && props.okButtonProps.danger
          ? cssVariables.errorText
          : cssVariables.primaryBlue};
      border: none;
      padding: 8px 14px;
      align-self: center;
      height: ${props => (props.isFullScreen ? '5vh' : '100%')};
      text-transform: capitalize;
      border-radius: 5px;

      :hover {
        background-color: ${props =>
          props.okButtonProps && props.okButtonProps.danger
            ? cssVariables.red1
            : cssVariables.blue1};
        color: ${props =>
          props.okButtonProps && props.okButtonProps.danger
            ? cssVariables.errorText
            : cssVariables.primaryBlue};
      }
    }
    .ant-btn-primary {
      background-color: ${props =>
        props.okButtonProps && props.okButtonProps.danger
          ? cssVariables.errorButtonColor
          : cssVariables.primaryBlue};
      color: ${cssVariables.secondaryColor};
      border: none;
    }
  }
`;
