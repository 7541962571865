import { useState, useEffect, useContext, useMemo } from 'react';

import { allCategoriesSelector } from 'selectors/bot/categories';
import { selectedCategorySelector } from 'selectors/bot';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import { categoryTemplatesSelector } from 'selectors/categoryTemplates';
import { SET_SELECTED_CATEGORY_DETAILS } from 'store/action';

const useCategoryForm = () => {
  const [_, dispatch] = useContext(Context);
  const allCategories = useSelector(allCategoriesSelector);
  const categoryTemplates = useSelector(categoryTemplatesSelector);
  const selectedCategory = useSelector(selectedCategorySelector);

  const [categoryName, setCategoryName] = useState(
    !selectedCategory.action || selectedCategory.action === 'add'
      ? null
      : selectedCategory.name
  );
  const [categoryColor, setCategoryColor] = useState(selectedCategory?.color);
  const [searchInput, setSearchInput] = useState(null);

  const handleSidebarClick = ({ key }) => {
    if (key === 'template') {
      dispatch({
        type: SET_SELECTED_CATEGORY_DETAILS,
        payload: { isTemplate: true },
      });
    } else {
      dispatch({
        type: SET_SELECTED_CATEGORY_DETAILS,
        payload: { isTemplate: false },
      });
    }
  };

  const onChangeSearchInput = e => {
    const searchInput = e.target.value;
    setSearchInput(searchInput);
  };

  const filteredTemplates = useMemo(() => {
    if (searchInput) {
      const normalizedSearchInput = searchInput.toLowerCase();
      return categoryTemplates.filter(template => {
        const normalizedTemplateName = template.name.toLowerCase();
        return normalizedTemplateName.includes(normalizedSearchInput);
      });
    }
    return categoryTemplates;
  }, [categoryTemplates, searchInput]);

  const onAddTemplateCategory = template => {
    const isTemplateSelected = selectedCategory.templates.some(
      selectedTemplate =>
        selectedTemplate.name.toLowerCase() === template.name.toLowerCase()
    );
    if (!isTemplateSelected) {
      dispatch({
        type: SET_SELECTED_CATEGORY_DETAILS,
        payload: { templates: [...selectedCategory.templates, template] },
      });
    }
  };

  const onRemoveTemplateCategory = template => {
    const newSelectedTemplate = selectedCategory.templates.filter(
      selectedTemplate =>
        selectedTemplate.name.toLowerCase() !== template.name.toLowerCase()
    );
    dispatch({
      type: SET_SELECTED_CATEGORY_DETAILS,
      payload: { templates: newSelectedTemplate },
    });
  };

  const handleChangeName = evt => {
    const category = evt.target.value;
    setCategoryName(category);
    dispatch({
      type: SET_SELECTED_CATEGORY_DETAILS,
      payload: {
        name: category,
      },
    });
  };

  const handleChangeColor = color => {
    setCategoryColor(color);
    dispatch({
      type: SET_SELECTED_CATEGORY_DETAILS,
      payload: {
        color,
      },
    });
  };

  useEffect(() => {
    return () => {
      setCategoryColor('');
      setCategoryName('');
    };
  }, [selectedCategory]);

  return {
    categoryColor,
    categoryName,
    allCategories,
    filteredTemplates,
    searchInput,
    selectedCategory,
    handleSidebarClick,
    handleChangeColor,
    handleChangeName,
    onAddTemplateCategory,
    onChangeSearchInput,
    onRemoveTemplateCategory,
  };
};

export default useCategoryForm;
