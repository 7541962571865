import { useState, useContext, useEffect } from 'react';
import { message } from 'antd';

import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { generateRandomString, strippedString } from 'utils/stringManipulation';
import {
  currentIntegrationIDSelector,
  integrationDataSelector,
  currentIntegrationActivePanelSelector,
  integrationPanelPermitSelector,
} from 'selectors/bot/integration';
import {
  SET_INTEGRATION_ACTIVE_PANEL,
  SPAWN_CREATE,
  ADD_INTEGRATION,
  UPDATE_INTEGRATION,
  RESET_INTEGRATION_SETTINGS,
  SET_CURRENT_INTEGRATION_DATA,
  SET_MESSENGER_API,
} from 'store/action';
import { useLocation } from 'react-router-dom';
import { INTEGRATION_DATA } from 'constants/localStorage';
import useSelector from 'store/useSelector';
import { messengerApiSelector } from 'selectors/messenger';
import { MESSENGER_API_VERSION } from 'constants/integration';

const isDevelopment = process.env.NODE_ENV === 'development';
const testToken = process.env.REACT_APP_MESSENGER_ACCESS_TOKEN;

const useFacebookMessenger = () => {
  const [state, dispatch] = useContext(Context);
  const permit = useSelector(integrationPanelPermitSelector);
  const activePanel = useSelector(currentIntegrationActivePanelSelector);
  const currentIntegrationID = useSelector(currentIntegrationIDSelector);
  const messengerAPIs = useSelector(messengerApiSelector);
  const currentIntegrationSettings = useSelector(integrationDataSelector);

  const { state: locationState } = useLocation();

  const {
    sentinel,
    messengerCallback,
    token,
    bot: { name, jid, integrations },
  } = state;

  const [loading, setLoading] = useState(false);
  const defaultSettings = {
    type: 'facebook-messenger',
    settings: {
      identifier: name,
      label: `Ask ${name}`,
    },
  };
  const localIntegration =
    JSON.parse(localStorage.getItem(INTEGRATION_DATA)) || locationState;
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [integrationData, setIntegrationData] = useState(
    localIntegration || defaultSettings
  );
  const [confirmEditModal, setConfirmEditModal] = useState({
    visible: false,
    panel: 1,
  });
  const [enableEdit, setEnableEdit] = useState(false);
  const MAX_PANEL = 2;

  const getDuplicateName = (input, id) => {
    const strippedInput = strippedString(input);
    return integrations.find(
      i => strippedString(i.identifier) === strippedInput && i.id !== id
    );
  };

  useEffect(() => {
    if (!messengerAPIs?.latest) {
      getMessengerConfig();
    }

    if (integrationData) {
      dispatch({
        type: SET_CURRENT_INTEGRATION_DATA,
        payload: integrationData,
      });
    }

    return () => {
      setIntegrationData(defaultSettings);
      localStorage.removeItem(INTEGRATION_DATA);
      dispatch({
        type: RESET_INTEGRATION_SETTINGS,
      });
    };
  }, []);

  useEffect(() => {
    if (currentIntegrationSettings?.id) {
      setIntegrationData(currentIntegrationSettings);
    }
  }, [currentIntegrationSettings]);

  useEffect(() => {
    if (activePanel > MAX_PANEL) {
      dispatch({
        type: SET_INTEGRATION_ACTIVE_PANEL,
        payload: {
          panel: MAX_PANEL,
          permit: MAX_PANEL,
        },
      });
    }
  }, [activePanel]);

  const spawnCreatePublicKey = async () => {
    const apiName = 'zsb_public_api';
    try {
      const res = await apiService.spawnCreate(apiName, token);
      const pkRes = await apiService.getPublicKey(token);
      const pK = pkRes.data.anyone;
      const pAQ = res.data.jid;
      dispatch({
        type: SPAWN_CREATE,
        payload: {
          publicKey: pK,
          pubAskedQuestion: pAQ,
        },
      });
      return pK;
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  const spawnCreate = async () => {
    const apiName = 'messenger_callback';

    if (messengerCallback) {
      return messengerCallback;
    }

    try {
      const res = await apiService.spawnCreate(apiName, token);
      // no report prop for spawn create
      const jid = res.data.jid;
      dispatch({
        type: SPAWN_CREATE,
        payload: {
          messengerCallback: jid,
        },
      });

      return jid;
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  const getMessengerConfig = async () => {
    const apiName = 'facebook_api';
    try {
      const res = await apiService.globalVarGetter(apiName, sentinel, token);
      dispatch({
        type: SET_MESSENGER_API,
        payload: res.data.report[0],
      });
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  const onSave = async data => {
    setLoading(true);
    const isNameExisting = getDuplicateName(data.identifier);
    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('Channel name already exists.');
      }

      await spawnCreatePublicKey();
      await spawnCreate();
      const res = await apiService.createIntegration(
        sentinel,
        jid,
        data,
        'facebook',
        token
      );
      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.int_type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };

      // setIntegrationData({
      //   ...integrationData,
      //   ...payload,
      // });
      await dispatch({ type: ADD_INTEGRATION, payload });
      localStorage.setItem(
        INTEGRATION_DATA,
        JSON.stringify({
          ...data,
          ...payload,
        })
      );
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      throw error.message;
    }
  };

  const onUpdate = async (data, id) => {
    setLoading(true);
    const isNameExisting = getDuplicateName(data.identifier, id);
    try {
      if (isNameExisting) {
        setLoading(false);
        throw new TypeError('A channel with the same name already exists.');
      }
      const res = await apiService.editIntegration(
        sentinel,
        id,
        data,
        'facebook',
        token
      );
      const payload = {
        identifier: res.data.report[0].context.identifier,
        type: res.data.report[0].context.int_type,
        path: res.data.report[0].context.path,
        id: res.data.report[0].jid,
        settings: res.data.report[0].context.settingsobj,
      };

      setIntegrationData({
        ...integrationData,
        ...payload,
      });

      localStorage.setItem(
        INTEGRATION_DATA,
        JSON.stringify({
          ...data,
          ...payload,
        })
      );
      dispatch({ type: UPDATE_INTEGRATION, payload });
      setLoading(false);
      return payload;
    } catch (error) {
      setLoading(false);
      throw error.message;
    }
  };

  const handleChangeFields = (name, value) => {
    switch (name) {
      case 'identifier':
        setIntegrationData({ ...integrationData, identifier: value });
        break;
      case 'path':
        setIntegrationData({ ...integrationData, path: value });
        break;
      case 'type':
        setIntegrationData({ ...integrationData, type: value });
        break;
      case 'fbMessengerToken':
        setIntegrationData({
          ...integrationData,
          settings: { ...integrationData.settings, fbMessengerToken: value },
        });
        break;
      case 'accessToken':
        setIntegrationData({
          ...integrationData,
          settings: { ...integrationData.settings, fb_access_token: value },
        });
        break;
      case 'messengerAPIVersion':
        setIntegrationData({
          ...integrationData,
          settings: { ...integrationData.settings, fb_version: value },
        });
        break;
      case 'clear':
        if (showColorPicker) {
          setShowColorPicker(!showColorPicker);
        }
        setIntegrationData(defaultSettings);
        break;
      default:
        break;
    }
  };

  const handleSaveChannelInfo = async evt => {
    setLoading(true);
    evt.preventDefault();
    const integrationDataPayload = {
      ...integrationData,
      settings: {
        ...integrationData.settings,
        fb_version:
          integrationData.settings?.fb_version || MESSENGER_API_VERSION,
        fb_access_token:
          !integrationData.settings?.fb_access_token && isDevelopment
            ? testToken
            : integrationData.settings?.fb_access_token,
        // generates new verify token on save/update integration
        fb_verify_token: generateRandomString(),
      },
    };
    try {
      if (
        !localIntegration ||
        !localIntegration.id ||
        !currentIntegrationID ||
        !integrationData.id
      ) {
        const res = await onSave(integrationDataPayload);
        if (res.id) {
          dispatch({
            type: SET_CURRENT_INTEGRATION_DATA,
            payload: res,
          });
          message.success(`Integration channel added`);
        } else {
          return;
        }
      } else {
        const res = await onUpdate(
          integrationDataPayload,
          currentIntegrationID
        );
        if (res.id) {
          dispatch({
            type: SET_CURRENT_INTEGRATION_DATA,
            payload: res,
          });
          message.success(`Successfully updated integration channel`);
        } else {
          return;
        }
      }
      if (!messengerAPIs?.latest) {
        await getMessengerConfig();
      }

      dispatch({
        type: SET_INTEGRATION_ACTIVE_PANEL,
        payload: {
          panel: activePanel + 1,
          permit: permit <= activePanel ? permit + 1 : permit,
        },
      });
    } catch (error) {
      return message.error(
        error || `An error encountred while adding the integration channel.`
      );
    }
    setLoading(false);
  };

  const handlePanelChange = panel => {
    const panelNumber = panel?.split('-').pop();
    if (permit >= 2 && panelNumber < permit && !enableEdit) {
      setConfirmEditModal({ visible: true, panel: panelNumber });
    } else if (panelNumber <= permit) {
      dispatch({
        type: SET_INTEGRATION_ACTIVE_PANEL,
        payload: { panel: Number(panelNumber) },
      });
    } else if (permit < panelNumber) {
      return message.error(
        `Please complete the current step before you proceed to step ${panelNumber}.`
      );
    }
  };

  return {
    activePanel,
    confirmEditModal,
    currentIntegrationID,
    dispatch,
    integrationData,
    loading,
    handleChangeFields,
    handlePanelChange,
    handleSaveChannelInfo,
    messengerAPIs,
    setConfirmEditModal,
    setEnableEdit,
  };
};

export default useFacebookMessenger;
