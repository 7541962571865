import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Tag } from 'antd';
import Modal from 'components/Modals/GenericModal';
import Button from 'components/Button';
import { cssVariables } from 'styles/root';
import { StyledFlexRowRight } from 'styles/GenericStyledComponents';

const ActivityLogsModal = ({
  selectedActivityLogs,
  showDetailsModal,
  onOk,
  onCancel,
  status,
  renderNewBotName,
}) => {
  return (
    <Modal
      visible={showDetailsModal}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button type="button" size="small" value="Close" onClick={onOk} />,
      ]}
      closable={false}
      title={<strong>Activity Details</strong>}
    >
      <StyledFlexRowRight>
        <Tag color={status === 'Active Bot' ? 'green' : 'red'}>{status}</Tag>
      </StyledFlexRowRight>
      <Descriptions layout="horizontal" bordered span={1}>
        <Descriptions.Item
          label="Action Date"
          labelStyle={{ fontWeight: cssVariables.font.bold }}
          span={3}
        >
          {selectedActivityLogs?.activityDate}
        </Descriptions.Item>

        {selectedActivityLogs?.user && (
          <Descriptions.Item
            label="User"
            labelStyle={{ fontWeight: cssVariables.font.bold }}
            span={3}
          >
            {selectedActivityLogs?.user}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label="Action"
          labelStyle={{ fontWeight: cssVariables.font.bold }}
          span={3}
        >
          {selectedActivityLogs?.activityAction}
        </Descriptions.Item>

        {selectedActivityLogs?.bot && selectedActivityLogs?.bot !== 'N/A' && (
          <Descriptions.Item
            label="Bot Name"
            labelStyle={{ fontWeight: cssVariables.font.bold }}
            span={3}
          >
            {selectedActivityLogs?.bot}{' '}
            {renderNewBotName(
              selectedActivityLogs.id,
              selectedActivityLogs.bot
            )}
          </Descriptions.Item>
        )}

        {!selectedActivityLogs?.message ||
          (selectedActivityLogs?.message !==
            ('null' || null || 'undefined' || undefined) && (
            <Descriptions.Item
              label="Message"
              labelStyle={{ fontWeight: cssVariables.font.bold }}
              span={3}
            >
              <p>
                <strong>{selectedActivityLogs?.activityAction}</strong>
              </p>
              {selectedActivityLogs?.message && (
                <>
                  <p>Value:</p>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {selectedActivityLogs?.message}
                  </span>
                </>
              )}
            </Descriptions.Item>
          ))}

        {selectedActivityLogs?.planType && (
          <Descriptions.Item
            label="Plan Type"
            labelStyle={{ fontWeight: cssVariables.font.bold }}
            span={3}
          >
            {selectedActivityLogs?.planType}
          </Descriptions.Item>
        )}
      </Descriptions>
    </Modal>
  );
};

ActivityLogsModal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  selectedActivityLogs: PropTypes.object,
  showDetailsModal: PropTypes.bool,
  status: PropTypes.string,
  renderNewBotName: PropTypes.func,
};

export default ActivityLogsModal;
