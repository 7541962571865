import { Modal, Switch } from 'antd';
import PropTypes from 'prop-types';

import TextArea from 'components/TextArea';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
import Button from 'components/Button';

const AddAnswerModal = ({
  visibility,
  onAddAnswer,
  sending,
  newAnswer,
  onChangeNewAnswer,
  answerVersion,
  setAnswerVersion,
  onClose,
}) => {
  return (
    <Modal
      visible={visibility}
      title="Add Answer"
      closable={false}
      width="70%"
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <StyledFlexRowLeft style={{ width: '50%' }}>
            {newAnswer && (
              <Switch
                style={{ alignSelf: 'center' }}
                checkedChildren={'Final'}
                unCheckedChildren={'Draft'}
                defaultChecked={answerVersion === 'final'}
                size="default"
                onChange={evt => setAnswerVersion(evt ? 'final' : 'draft')}
                disabled={sending}
              />
            )}
          </StyledFlexRowLeft>

          <div>
            <Button value="Cancel" variant="link" onClick={onClose} />
            <Button
              value="Add Answer"
              variant="primary"
              onClick={onAddAnswer}
              loading={sending}
            />
          </div>
        </div>
      }
      cancelText="Cancel"
    >
      <TextArea
        style={{
          paddingRight: 0,
        }}
        disabled={sending}
        required
        placeholder="Type your answer"
        value={newAnswer}
        onChange={onChangeNewAnswer}
        autoSize={{ minRows: 1, maxRows: 4 }}
        allowClear
        noPaddingTop
        disableSpan
        full
      />
    </Modal>
  );
};

AddAnswerModal.prototypes = {
  visibility: PropTypes.bool,
  onAddAnswer: PropTypes.func,
  sending: PropTypes.bool,
  newAnswer: PropTypes.string,
  onChangeNewAnswer: PropTypes.func,
  setAnswerVersion: PropTypes.func,
  answerVersion: PropTypes.string,
  onClose: PropTypes.bool,
};
export default AddAnswerModal;
