import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu, Tag } from 'antd';
import { LinkOutlined, CheckCircleFilled } from '@ant-design/icons';
import {
  StyledRoot,
  StyledAnswerToLink,
  StyledAnswerLinks,
  StyledAnswerContainer,
  StyledAnswerScore,
} from './AnswerWithScore.styles';
import { AnswerRenderer } from 'components/AnswerRenderer';
import AnchorButton from 'components/Button/AnchorButton';
import { responsePickerModalSelector } from 'selectors/bot/ui';
import ToolTip from 'components/ToolTips/BaseToolTip';
import useSelector from 'store/useSelector';

const AnswerWithScore = ({
  answer,
  chatAnswerType,
  className,
  handleSelect,
  handleUnlinkQuestion,
  isCurrentAnswer,
  isCurrentAnswerHasLink,
  showDisplayAnswer,
  toolTipText,
}) => {
  const responsePickerModal = useSelector(responsePickerModalSelector);
  const { type, version } = answer;
  const isDefaultAnswer = type?.includes('default');
  const { softLink } = responsePickerModal;

  const moreMenu = (
    <Menu>
      <Menu.Item onClick={() => handleSelect(answer.jid)}>
        Use this response
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledRoot>
      <StyledAnswerContainer
        className={classNames({
          selected:
            (className && className.includes('selected')) || isCurrentAnswer,
          firstAnswer: className && className.includes('firstAnswer'),
        })}
      >
        <ToolTip title={'Relevance Score to current question'}>
          <StyledAnswerScore
            className={
              isCurrentAnswer && !type?.includes('default')
                ? classNames(
                    'currentAnswer',
                    'answerScore',
                    'answerScoreSuccess'
                  )
                : isCurrentAnswer &&
                  (chatAnswerType?.includes('default') ||
                    type?.includes('default'))
                ? classNames(
                    'currentAnswer',
                    'answerScore',
                    'answerScoreDanger'
                  )
                : classNames(
                    'answerScore',
                    className && className.includes('defaultAnswer')
                      ? 'answerScoreDanger'
                      : ''
                  )
            }
          >
            {answer?.score || '0.00'}
          </StyledAnswerScore>
        </ToolTip>
        {isDefaultAnswer || !softLink ? null : (
          <Tag color={version === 'draft' ? 'red' : 'green'}>{version}</Tag>
        )}
        <StyledAnswerToLink>
          <AnswerRenderer
            showDisplayAnswer={showDisplayAnswer || isDefaultAnswer}
            answer={answer}
          />
        </StyledAnswerToLink>
        {answer.type === 'answer' && (
          <Tag color={answer.version === 'draft' ? 'red' : 'green'}>
            {answer.version}
          </Tag>
        )}
        {isDefaultAnswer && <Tag color="orange">Default Answer</Tag>}
        {isCurrentAnswerHasLink ? null : (
          <ToolTip title={'Select Answer'}>
            <CheckCircleFilled
              onClick={() => {
                if (!isCurrentAnswer) {
                  handleSelect(answer.jid);
                } else {
                  return false;
                }
              }}
            />
          </ToolTip>
        )}
      </StyledAnswerContainer>
      {isCurrentAnswerHasLink && isCurrentAnswer ? (
        <StyledAnswerLinks className="disabled">
          <LinkOutlined />
          <i>{`Hard Linked`}</i>
          <AnchorButton
            onClick={handleUnlinkQuestion}
            title={'Remove Hard Link'}
          />
        </StyledAnswerLinks>
      ) : null}
    </StyledRoot>
  );
};

AnswerWithScore.propTypes = {
  answer: PropTypes.object.isRequired,
  chatAnswerType: PropTypes.string,
  className: PropTypes.any,
  handleSelect: PropTypes.func,
  handleUnlinkQuestion: PropTypes.func,
  isCurrentAnswer: PropTypes.bool,
  isCurrentAnswerHasLink: PropTypes.bool,
  showDisplayAnswer: PropTypes.bool,
  toolTipText: PropTypes.string,
};

export { AnswerWithScore };
