import styled from 'styled-components';
import { Table, Layout, Input, Modal } from 'antd';
import Button from 'components/Button';
import { cssVariables } from 'styles/root';

const { Search } = Input;

export const StyledWrapper = styled(Layout)`
  display: flex;
  width: 100%;
`;

export const StyledModal = styled(Modal)`
  & .ant-modal-close {
    display: none;
  }
  > .ant-modal-close {
    display: none;
  }
  color: blue;
  font-size: 24px;
`;

export const StyledTable = styled(Table)`
  border-radius: 16px;

  & td {
    border-right: 1px solid #33363c1a;
  }

  & tr {
    border-top: 1.5px solid #33363c1a;
    border-bottom: 1.5px solid #33363c1a;
  }
`;

export const StyledTableContainer = styled.div`
  padding: 3%;
  width: 100%;
  overflow-y: auto;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 10px;
    margin-right: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
    margin-right: 15px;
    border: 2px solid #f9f9f9;
  }
`;

export const StyledNavigationContainer = styled.div`
  display: flex;
  padding: 0 0 2rem 0;
  gap: 1rem;
`;

export const StyledButton = styled(Button)`
  background-color: ${cssVariables.primaryBlueHover};
  color: ${cssVariables.primaryBlue};
  width: 97px;

  :hover {
    background-color: ${cssVariables.primaryBlue};
    color: ${cssVariables.primaryBlueHover};
    width: 97px;
  }
`;

export const StyledSearch = styled(Search)`
  margin: 20px 0;

  > .ant-input-search
    .ant-input-group
    .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &
    .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 12px 12px 0;
  }
`;

export const StyledTitle = styled.h2`
  font-weight: 600;
  margin: auto;
`;
