import { MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const StyledMoreMenu = styled(MoreOutlined)`
  transform: rotate(-90deg);
  top: 16px;
  right: 16px;
  padding: 5px;

  &:hover {
    background: ${props => props.theme.gray2};
    border-radius: 50%;
  }

}`;
