import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, Alert } from 'antd';
import { isInteger } from 'lodash';

import { withFeatureFlag } from 'components/hoc/withFeatureFlags';
import {
  StyledBotInfoDescription,
  StyledColumnSections,
  StyledLabel,
} from './Settings.styles';
import { OPEN_AI } from 'constants/featureFlags';
import useSelector from 'store/useSelector';
import { botModeSelector } from 'selectors/bot';
import {
  StyledFlexLeftColumn,
  StyledFlexRowLeft,
} from 'styles/GenericStyledComponents';
import Select from 'components/Select';
import { BOT_MODES } from 'constants/bot';
import Input from 'components/Input';
import ToolTip from 'components/ToolTips/BaseToolTip';
import { InfoCircleTwoTone } from '@ant-design/icons';

const PERSONA_SELECT_OPTIONS = [
  {
    value: 'professional',
    label: 'Professional',
  },
  {
    value: 'funny',
    label: 'Funny',
  },
];

const OpenAIResponseSettings = ({
  onToggleUseOpenAI,
  onToggleTranslateLanguage,
  onChangeRetrievalCount,
  onChangeBotPersona,
  useOpenAI,
  translateLanguage,
  retrievalCount,
  botPersona,
}) => {
  const botMode = useSelector(botModeSelector);
  const handleToggleUseOpenAI = value => {
    onToggleUseOpenAI(value);
  };

  const handleToggleTranslateLanguage = value => {
    onToggleTranslateLanguage(value);
  };

  const hasFormError = useMemo(
    () =>
      retrievalCount > 10 ||
      retrievalCount < 2 ||
      !retrievalCount ||
      !isInteger(Number(retrievalCount)),
    [retrievalCount]
  );

  const OpenAIComponent = withFeatureFlag(
    OPEN_AI,
    <StyledFlexLeftColumn>
      <StyledBotInfoDescription>
        <span>{'Use OpenAI based on bot response '}</span>
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={Boolean(useOpenAI)}
          onChange={handleToggleUseOpenAI}
        />
      </StyledBotInfoDescription>

      {useOpenAI && (
        <StyledBotInfoDescription>
          <span>{'Translate response to user language '}</span>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={Boolean(translateLanguage)}
            onChange={handleToggleTranslateLanguage}
          />
        </StyledBotInfoDescription>
      )}
    </StyledFlexLeftColumn>
  );

  const getErrorMessage = () => {
    if (!retrievalCount) {
      return 'Retrieval count is required.';
    } else if (retrievalCount > 10 || retrievalCount < 2) {
      return 'Retrieval count range is 2-10';
    } else if (!isInteger(Number(retrievalCount))) {
      return 'Please input an integer';
    }
  };

  const PersonaComponent = useCallback(
    () => (
      <StyledColumnSections>
        <StyledFlexRowLeft>
          <StyledLabel>
            {'Persona'}
            {': '}{' '}
            <ToolTip title="Select your bot persona">
              <InfoCircleTwoTone />
            </ToolTip>
          </StyledLabel>

          <Select
            defaultValue={botPersona}
            options={PERSONA_SELECT_OPTIONS}
            placeholder="Select AI persona"
            onChange={e => onChangeBotPersona(e)}
            style={{ width: '100%' }}
            allowClear={true}
          />
        </StyledFlexRowLeft>
        <StyledFlexRowLeft>
          <StyledLabel>
            {'Retrieval Count'}
            {': '}{' '}
            <ToolTip title="Minimum 2, maximum 10 retrieval count">
              <InfoCircleTwoTone />
            </ToolTip>
          </StyledLabel>

          <StyledFlexRowLeft style={{ width: 'auto' }}>
            <Input
              type="number"
              defaultValue={retrievalCount}
              min={2}
              max={10}
              size="small"
              onChange={e => onChangeRetrievalCount(Number(e.target?.value))}
              style={{ width: '100%' }}
              status={hasFormError ? 'error' : ''}
              autofocus
            />
            {hasFormError ? (
              <Alert
                type={'error'}
                message={getErrorMessage()}
                style={{ width: 'auto', marginLeft: 5 }}
              />
            ) : null}
          </StyledFlexRowLeft>
        </StyledFlexRowLeft>
      </StyledColumnSections>
    ),
    [botPersona, retrievalCount, onChangeBotPersona, onChangeRetrievalCount]
  );

  return (
    <StyledFlexRowLeft style={{ width: '100%' }}>
      {botMode === BOT_MODES.OPENAI ? PersonaComponent() : <OpenAIComponent />}
    </StyledFlexRowLeft>
  );
};

OpenAIResponseSettings.propTypes = {
  onToggleUseOpenAI: PropTypes.func.isRequired,
  onToggleTranslateLanguage: PropTypes.func.isRequired,
  onChangeBotPersona: PropTypes.func.isRequired,
  onChangeRetrievalCount: PropTypes.func.isRequired,
  useOpenAI: PropTypes.bool,
  translateLanguage: PropTypes.bool,
  botPersona: PropTypes.string,
  retrievalCount: PropTypes.number,
};

export default OpenAIResponseSettings;
