import {
  CANCEL_SUBSCRIPTION,
  CLOSE_CANCEL_SUBSCRIPTION_MODAL,
  CLOSE_SUCCESS_CANCEL_SUBSCRIPTION_MODAL,
  CLOSE_SWITCH_PLAN_SUBSCRIPTION_MODAL,
  SET_PAYMENT_METHODS,
  SET_STRIPE_STATUS,
  SET_USER_SUBSCRIPTION,
  SHOW_CANCEL_SUBSCRIPTION_MODAL,
  SHOW_SWITCH_PLAN_SUBSCRIPTION_MODAL,
} from 'store/action';
import { initialBotState, initialPlanState } from 'store/initialState';

export const subscriptionReducer = (state, action) => {
  switch (action.type) {
    case SET_STRIPE_STATUS: {
      try {
        const { context } = action.payload;
        const { subscription: subscriptionData, ...restOfSubscriptionObj } =
          context;

        return {
          ...state,
          subscription: {
            ...state.subscription,
            isProcessing: subscriptionData?.is_processing,
            ...restOfSubscriptionObj,
          },
        };
      } catch (error) {
        return state;
      }
    }
    case SET_USER_SUBSCRIPTION: {
      try {
        const {
          id,
          created,
          cancel_at_period_end,
          metadata,
          status,
          current_period_end,
          customer,
          trial_end,
        } = action.payload;

        return {
          ...state,
          subscription: {
            ...state.subscription,
            id,
            autoRenew: !cancel_at_period_end,
            isCancelled: cancel_at_period_end,
            trial: status === 'trialing',
            customer,
            current_period_end,
            trial_end,
            created,
            showSwitchPlanSubscriptionModal: false,
            switchPlan: null,
          },
          plan: {
            ...state.plan,
            plan_type: metadata.plan_type,
          },
        };
      } catch (error) {
        return state;
      }
    }

    case SET_PAYMENT_METHODS: {
      try {
        const paymentMethods = action.payload?.map(pm => {
          const { id, billing_details, card, customer, ...rest } = pm;
          return {
            id,
            billingDetails: billing_details,
            card,
            customer,
            ...rest,
          };
        });

        return {
          ...state,
          subscription: {
            ...state.subscription,
            paymentMethods: paymentMethods,
          },
        };
      } catch (error) {
        return state;
      }
    }

    case CANCEL_SUBSCRIPTION: {
      try {
        const { metadata, status, cancel_at_period_end } = action.payload;
        const { plan_type } = metadata;
        return {
          ...state,
          plan: {
            ...initialPlanState,
            plansAndFeatures: state.plan.plansAndFeatures,
            plan_type,
          },
          subscription: {
            ...state.subscription,
            trial: status === 'trialing',
            isCancelled: cancel_at_period_end,
            showCancelledSubscriptionSuccessMessage: true,
          },
          bot: initialBotState,
        };
      } catch (error) {
        return state;
      }
    }

    case CLOSE_SUCCESS_CANCEL_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          showCancelledSubscriptionSuccessMessage: false,
          showCancelSubscriptionModal: false,
        },
      };
    }

    case SHOW_CANCEL_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          showCancelSubscriptionModal: true,
        },
      };
    }

    case SHOW_SWITCH_PLAN_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          showSwitchPlanSubscriptionModal: true,
          switchPlan: action.payload,
        },
      };
    }

    case CLOSE_CANCEL_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          showCancelledSubscriptionSuccessMessage: false,
          showCancelSubscriptionModal: false,
        },
      };
    }

    case CLOSE_SWITCH_PLAN_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          showUpgradedSubscriptionSuccessMessage: false,
          showSwitchPlanSubscriptionModal: false,
          switchPlan: null,
        },
      };
    }

    default:
      return state;
  }
};
