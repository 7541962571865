import React, { useContext } from 'react';
import { Col, Radio, Row } from 'antd';

import useSelector from 'store/useSelector';
import {
  widgetVersionSelector,
  isWidgetV1Selector,
  widgetV2TypeSelector,
} from 'selectors/bot/integration';
import { Context } from 'store/store';
import {
  SET_INTEGRATION_BOT_VERSION,
  SET_INTEGRATION_WIDGET_TYPE,
} from 'store/action';
import { StyledWidgetVersionWrapper } from './CustomizeBot.style';
import { withOpenAI } from 'components/hoc/withOpenAI';
import ToolTip from 'components/ToolTips/BaseToolTip';

const WidgetVersion = () => {
  const [, dispatch] = useContext(Context);

  const isWidgetV1 = useSelector(isWidgetV1Selector);
  const widgetType = useSelector(widgetV2TypeSelector);
  const widgetVersion = useSelector(widgetVersionSelector);

  const handleChangeBotVersion = evt => {
    dispatch({ type: SET_INTEGRATION_BOT_VERSION, payload: evt.target.value });
  };

  const handleChangeWidgetType = evt => {
    dispatch({ type: SET_INTEGRATION_WIDGET_TYPE, payload: evt.target.value });
  };
  const SearchRadio = isWidgetV1
    ? () => (
        <ToolTip title="Only available in OpenAI bot and Version 2 Widget">
          <Radio disabled value={'search'}>
            {'Search Widget'}
          </Radio>
        </ToolTip>
      )
    : withOpenAI(<Radio value={'search'}>{'Search Widget'}</Radio>);

  return (
    <StyledWidgetVersionWrapper>
      <Radio.Group
        optionType="button"
        defaultValue={widgetVersion}
        buttonStyle="solid"
        onChange={handleChangeBotVersion}
      >
        <Radio value={'v1'}>{'Version 1'}</Radio>
        <Radio value={'v2'}>{'Version 2'}</Radio>
      </Radio.Group>
      <Radio.Group onChange={handleChangeWidgetType} value={widgetType}>
        <Row>
          <Col>
            <Radio value={'chat'}>{'Chat Widget'}</Radio>
          </Col>
          <Col>
            <SearchRadio />
          </Col>
        </Row>
      </Radio.Group>
    </StyledWidgetVersionWrapper>
  );
};

export default WidgetVersion;
