import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { showLessTextCharacters } from 'utils';
import AnchorButton from './Button/AnchorButton';
import { extractDisplayAnswer } from 'utils/answers';
import ChatBubble from './ChatBubble';

const StyledComponent = styled.div`
  img {
    width: 100%;
  }

  p:last-child {
    margin-bottom: 0;
  }

  ${props =>
    props.isWidgetAnswer
      ? css`
          padding: 10px;
        `
      : ``}
`;

export const AnswerRenderer = props => {
  const {
    showDisplayAnswer,
    showInChatBubble,
    answer,
    showJSONAnswerInModal,
    isWidgetAnswer,
    ...rest
  } = props;
  const [showFullText, setShowFullText] = useState(false);

  const answerRendered = useMemo(() => {
    const maxTextLength = 190;
    return showLessTextCharacters(
      answer?.text || answer,
      showFullText,
      maxTextLength
    );
  }, [answer, showFullText]);

  const renderShowMore = () => {
    if (showDisplayAnswer || answer?.type?.includes('default')) {
      return null;
    } else if (!showFullText && answer.text?.length > 200) {
      return ' More';
    } else if (showFullText && answer.text?.length > 200) {
      return ' Less';
    } else {
      return null;
    }
  };

  if (showDisplayAnswer) {
    if (showInChatBubble) {
      return <ChatBubble answer={answer} showDisplayAnswer />;
    } else if (
      answer?.type?.includes('default') &&
      answer.show_html &&
      Array.isArray(answer.show_html) &&
      (answer.show_html.length === 1 || answer.show_text.length === 1)
    ) {
      return (
        <StyledComponent
          isWidgetAnswer={isWidgetAnswer}
          dangerouslySetInnerHTML={{
            __html: answer.show_html || answer.show_text,
          }}
          {...rest}
        ></StyledComponent>
      );
    } else {
      return (
        <StyledComponent
          isWidgetAnswer={isWidgetAnswer}
          dangerouslySetInnerHTML={{
            __html: extractDisplayAnswer(answer),
          }}
          {...rest}
        ></StyledComponent>
      );
    }
  }

  return (
    <StyledComponent {...rest} isWidgetAnswer={isWidgetAnswer}>
      <span>
        {answerRendered}
        <AnchorButton
          style={{ marginLeft: 5 }}
          onClick={() => setShowFullText(!showFullText)}
          title={renderShowMore()}
        />
      </span>
    </StyledComponent>
  );
};

AnswerRenderer.propTypes = {
  showDisplayAnswer: PropTypes.bool.isRequired,
  showInChatBubble: PropTypes.bool.isRequired,
  isWidgetAnswer: PropTypes.bool,
  showFullText: PropTypes.bool,
  setShowFullText: PropTypes.func,
  showJSONAnswerInModal: PropTypes.func,
  answer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};
