import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import { Spin, Switch } from 'antd';
import Button from 'components/Button';
import Modal from 'components/Modals/GenericModal';
import JoyrideTooltip from 'components/ToolTips/JoyrideToolTip';
import useImportWebsiteModal from './hooks';
import { TEXT_INGEST_STEP_ONE } from 'constants/joyride_steps/import_answer_modal';
import { StyledFlexRowLeft } from 'styles/GenericStyledComponents';
import { SPINNER_LABEL } from 'constants/answerbank/website';
import ImportUrlForm from 'components/ImportUrlForm';

const ImportWebsiteModal = ({
  fileType,
  onClose,
  visibility,
  setShowUploadModal,
  notificationAPI,
  parsedFileAnswers,
  setParsedFileAnswers,
  isImportBtnDisabled,
  setIsImportBtnDisabled,
}) => {
  const {
    modalBtnLoading,
    handleCancelImport,
    handleAddFile,
    setVersion,
    version,
    currentStep,
    handleScrapedUrl,
    selectionState,
    handleBackward,
    webSocket,
    allUrlIsValid,
    handleCloseElementSelecting,
    handleSaveElement,
    selectedTemplateDetails,
    handleBackToPagesList,
    onClickCallback,
    runTour,
    stepIndex,
    handleStopScrapping,
    setModalBtnLoading,
    crawlerParams,
    setCrawlerParams,
    setWebsiteFormState,
    websiteFormState,
    handelWebsiteFormStateChanges,
  } = useImportWebsiteModal({
    fileType,
    onClose,
    visibility,
    setShowUploadModal,
    notificationAPI,
    parsedFileAnswers,
    setParsedFileAnswers,
    isImportBtnDisabled,
    setIsImportBtnDisabled,
  });

  const displayStopButton = useMemo(
    () =>
      !webSocket?.scannedTableDataSource &&
      webSocket?.activeAction === 'scrape',
    [webSocket?.activeAction, webSocket?.scannedTableDataSource]
  );

  return (
    <>
      <Modal
        closable={!websiteFormState?.isTargetElementOpen}
        visible={visibility}
        onCancel={handleCancelImport}
        onOk={handleAddFile}
        okText="Submit"
        okButtonProps={{
          disabled: isImportBtnDisabled || !parsedFileAnswers?.length,
        }}
        title={<h4>Import Website</h4>}
        cancelText={modalBtnLoading ? 'Close' : 'Cancel'}
        confirmLoading={modalBtnLoading}
        destroyOnClose={false}
        maskClosable={false}
        width={
          websiteFormState?.isTargetElementOpen
            ? '95%'
            : currentStep === 0
            ? '60%'
            : '85%'
        }
        isFullScreen={websiteFormState?.isTargetElementOpen}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent:
                websiteFormState?.isTargetElementOpen === true
                  ? 'end'
                  : 'space-between',
            }}
          >
            {websiteFormState?.isTargetElementOpen === false && (
              <StyledFlexRowLeft style={{ width: '50%' }}>
                <Switch
                  style={{ alignSelf: 'center' }}
                  checkedChildren={'Final'}
                  unCheckedChildren={'Draft'}
                  size="default"
                  defaultChecked={version}
                  onChange={value => setVersion(value ? 'final' : 'draft')}
                  disabled={
                    modalBtnLoading || webSocket?.activeAction === 'add_file'
                  }
                />
              </StyledFlexRowLeft>
            )}
            <div>
              {displayStopButton ? (
                <Button
                  variant="red"
                  value="Stop"
                  onClick={handleStopScrapping}
                />
              ) : (
                <Button
                  variant={'link'}
                  value={currentStep === 0 ? 'Cancel' : 'Back'}
                  onClick={
                    websiteFormState?.isTargetElementOpen === true
                      ? handleCloseElementSelecting
                      : currentStep === 0
                      ? handleCancelImport
                      : websiteFormState?.selectedPageList?.urls?.length > 0
                      ? handleBackToPagesList
                      : handleBackward
                  }
                />
              )}
              <Button
                variant="primary-btn-v2"
                value={
                  websiteFormState?.isTargetElementOpen === true
                    ? 'Save Element'
                    : currentStep === 0
                    ? websiteFormState?.selectedTemplate === 'single'
                      ? 'Import Website'
                      : 'Next'
                    : 'Import Website'
                }
                loading={
                  modalBtnLoading &&
                  websiteFormState?.isTargetElementOpen === false
                }
                onClick={() => {
                  websiteFormState?.isTargetElementOpen === true
                    ? handleSaveElement()
                    : currentStep === 0
                    ? selectedTemplateDetails?.crawlerParams === null
                      ? handleAddFile()
                      : handleScrapedUrl()
                    : handleAddFile();
                }}
                disabled={
                  currentStep === 0
                    ? !allUrlIsValid || !websiteFormState?.selectedTemplate
                    : selectionState?.urlSelected?.length === 0 ||
                      webSocket?.sending === true
                }
              />
            </div>
          </div>
        }
      >
        <Spin spinning={modalBtnLoading} tip={SPINNER_LABEL}>
          <ImportUrlForm
            currentStep={currentStep}
            setModalBtnLoading={setModalBtnLoading}
            crawlerParams={crawlerParams}
            setCrawlerParams={setCrawlerParams}
            websiteFormState={websiteFormState}
            handelWebsiteFormStateChanges={handelWebsiteFormStateChanges}
            setWebsiteFormState={setWebsiteFormState}
          />
        </Spin>
      </Modal>
      <Joyride
        run={runTour}
        steps={TEXT_INGEST_STEP_ONE}
        stepIndex={stepIndex}
        tooltipComponent={JoyrideTooltip}
        continuous
        debug
        spotlightClicks
        showProgress={true}
        showSkipButton={true}
        callback={onClickCallback}
        disableOverlay={false}
        isFirstStep
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
    </>
  );
};

ImportWebsiteModal.propTypes = {
  fileType: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

export default ImportWebsiteModal;
