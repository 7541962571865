import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { Context } from 'store/store';
import Modal from 'components/Modals/GenericModal';
import ROUTES from 'constants/routes';
import { LOGOUT_EXTERNAL_USER, LOGOUT_USER } from 'store/action';
import { isExternalPageSelector } from 'selectors/user';
import useSelector from 'store/useSelector';

const IdleTimerCountdown = () => {
  const [isUserIdle, setUserAsIdle] = useState(false);
  const [countdown, setCountdown] = useState(60 * 5); // 5 minutes warning
  const isExternalPage = useSelector(isExternalPageSelector);
  const [, dispatch] = useContext(Context);
  const history = useHistory();

  const handleOnIdle = () => {
    setUserAsIdle(true);
  };

  useEffect(() => {
    if (isUserIdle) {
      const countdownTimer = setInterval(() => {
        setCountdown(countdown => countdown - 1);
      }, 1000);

      return () => clearInterval(countdownTimer);
    }
  }, [isUserIdle]);

  useEffect(() => {
    if (countdown === 0) {
      dispatch({
        type: isExternalPage ? LOGOUT_EXTERNAL_USER : LOGOUT_USER,
      });
      history.push(ROUTES.LOGOUT);
    }
  }, [countdown, dispatch, history]);

  const onOkButtonClick = () => {
    setUserAsIdle(false);
    setCountdown(60 * 5);
    reset();
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 10, // ten minutes idle limit
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: true,
  });

  const seconds = countdown % 60;
  const minutes = Math.floor(countdown / 60);

  return (
    <Modal
      title="Are you still there?"
      visible={isUserIdle}
      closable={false}
      onOk={onOkButtonClick}
      okText={'Keep Active'}
    >
      <p>You've been inactive for 10 minutes.</p>
      <p>Logging you out in {`${minutes}:${seconds}`}</p>
    </Modal>
  );
};

export default IdleTimerCountdown;
