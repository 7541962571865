import { isEqual } from 'lodash';
import { SET_WS_TOKEN, DISCONNECT_WEBSOCKET } from '../action';
import { initialWSState } from 'store/initialState';

export const websocketReducer = (state, action) => {
  switch (action.type) {
    case SET_WS_TOKEN: {
      const { target, authenticated } = action.payload;

      return {
        ...state,
        webSocket: {
          channel: authenticated ? target : null,
          askQuestionActions: [],
          askQuestionActiveAction: null,
        },
      };
    }

    case DISCONNECT_WEBSOCKET: {
      // dont change if state is already same as initialWSstate
      if (isEqual(state.webSocket, initialWSState)) {
        return state;
      }
      return {
        ...state,
        webSocket: {
          channel: null,
          askQuestionActions: [],
          askQuestionActiveAction: null,
        },
      };
    }

    default:
      return state;
  }
};
