import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  addResponseMessage,
  dropMessages,
  isWidgetOpened,
  renderCustomComponent,
  toggleWidget,
} from 'react-chat-widget';
import { message } from 'antd';
import { BlobServiceClient } from '@azure/storage-blob';

import { apiService } from 'services/api.service';
import { Context } from 'store/store';
import { getBase64 } from 'utils';
import { StyledTempImage } from './BotCustomizer.styles';
import {
  RESET_INTEGRATION_SETTINGS,
  SET_BOT_CUSTOMIZER_ACTIVE_PANEL,
  SET_CURRENT_INTEGRATION_DATA,
  SET_DEMO_BACKGROUND,
  SET_DEMO_BACKGROUND_TO_CLOUD_PATH,
  SET_HEADER_AVATAR_TO_CLOUD_PATH,
  SET_INTEGRATION_ACTIVE_PANEL,
  SET_LAUNCHER_AVATAR_TO_CLOUD_PATH,
  SPAWN_CREATE,
} from 'store/action';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import { INTEGRATION_DATA } from 'constants/localStorage';
import {
  AVATAR_ICON_OPTIONS,
  LAUNCHER_ONLY_AVATAR,
} from 'constants/botCustomizer';
import { allAnswersSelector } from 'selectors/bot/answers';
import useSelector from 'store/useSelector';
import {
  isBotMailConfigEnabledSelector,
  isBotOpenAIEnabledSelector,
} from 'selectors/bot';
import {
  avatarPositionSelector,
  botTitleSelector,
  currentIntegrationIDSelector,
  integrationDataSelector,
  currentIntegrationSettingsSettingPropSelector,
  customizerActivePanelSelector,
  demoCustomBackgroundSelector,
  demoIntegrationBackgroundSelector,
  encryptedBotSelector,
  initialIntegrationSettingsSelector,
  integrationConfigURLSelector,
  integrationFolderPathSelector,
  isPostCustomizationEditModalEditableSelector,
  isDemoCustomBgSelectedSelector,
  isDemoHasCustomBackgroundSelector,
  isDemoIntegrationSelector,
  isIntegrationDataNotLoadedSelector,
  isMobileIntegrationSelector,
  widgetHeightSelector,
  widgetHeightWithPixelSelector,
  widgetIconColorSelector,
  widgetThemeColorSelector,
  isPostCustomizationEditModalOpenSelector,
} from 'selectors/bot/integration';
import {
  defaultReplyBubbleColorSelector,
  isHeaderAvatarSvgIconSelector,
  isHeaderAvatarCantApplySelector,
  isUseWidgetIconRadioDisabledSelector,
  launcherAvatarSelector,
  launcherAvatarTypeSelector,
  welcomeMessageSelector,
  widgetHeaderAvatarSelector,
  widgetHeaderAvatarTypeSelector,
  integrationConfigDataSelector,
  demoHTMLSelector,
} from 'selectors/bot/integration/settings';
import { generateIcon, injectToDOM } from './iconUtils';
import { isAvatarPickerModalOpenSelector } from 'selectors/bot/ui';
import { useQuery } from 'pages/useQuery';
import V1CustomComponent from './V1CustomComponent';
import { hexToRGBA } from './utils';
import { BlobStorageService } from 'services/azureBlobStorage.service';

const DEMO_CONTAINER_NAME = '$web';
const SAS_TOKEN = process.env.REACT_APP_AZURE_SAS_TOKEN;
const AZURE_STORAGE_URI = process.env.REACT_APP_AZURE_STORAGE_URI;
const AZURE_DEMO_URI = process.env.REACT_APP_AZURE_DEMO_URI;
const LAST_PANEL_AND_PERMIT_KEY = 3;

const useBotCustomizer = ({ onSave, onUpdate }) => {
  const query = useQuery();
  const [state, dispatch] = useContext(Context);
  const platform = query.get('platform');

  const allAnswers = useSelector(allAnswersSelector);
  const isBotOpenAIEnabled = useSelector(isBotOpenAIEnabledSelector);
  const isBotMailConfigEnabled = useSelector(isBotMailConfigEnabledSelector);
  const currentIntegrationID = useSelector(currentIntegrationIDSelector);
  const headerAvatarType = useSelector(widgetHeaderAvatarTypeSelector);
  const isHeaderIconTypeAvatar = useSelector(isHeaderAvatarSvgIconSelector);
  const headerAvatar = useSelector(widgetHeaderAvatarSelector);
  const iconColor = useSelector(widgetIconColorSelector);
  const launcherAvatar = useSelector(launcherAvatarSelector);
  const launcherAvatarType = useSelector(launcherAvatarTypeSelector);
  const isAvatarPickerModalOpen = useSelector(isAvatarPickerModalOpenSelector);
  const widgetThemeColor = useSelector(widgetThemeColorSelector);
  const avatarPosition = useSelector(avatarPositionSelector);
  const isPostCustomizationEditModalOpen = useSelector(
    isPostCustomizationEditModalOpenSelector
  );
  const widgetHeight = useSelector(widgetHeightSelector);
  const widgetHeightWithPixel = useSelector(widgetHeightWithPixelSelector);
  const customizerActivePanel = useSelector(customizerActivePanelSelector);
  const isMobileIntegration = useSelector(isMobileIntegrationSelector);
  const demoCustomBackground = useSelector(demoCustomBackgroundSelector);
  const encryptedBot = useSelector(encryptedBotSelector);
  const folderPath = useSelector(integrationFolderPathSelector);
  const isCustomBgSelected = useSelector(isDemoCustomBgSelectedSelector);
  const isHeaderAvatarNotAllowed = useSelector(isHeaderAvatarCantApplySelector);
  const integrationConfigURL = useSelector(integrationConfigURLSelector);
  const defaultReplyBubbleColor = useSelector(defaultReplyBubbleColorSelector);
  const welcomeMessage = useSelector(welcomeMessageSelector);
  const botTitle = useSelector(botTitleSelector);
  const isUseWidgetIconRadioDisabled = useSelector(
    isUseWidgetIconRadioDisabledSelector
  );
  const isDemo =
    useSelector(isDemoIntegrationSelector) || platform?.includes('demo');
  const isDemoHasCustomBackground = useSelector(
    isDemoHasCustomBackgroundSelector
  );
  const demoBackground = useSelector(demoIntegrationBackgroundSelector);
  const currentIntegrationSettingsObject = useSelector(
    currentIntegrationSettingsSettingPropSelector
  );
  const isPostCustomizationEditable = useSelector(
    isPostCustomizationEditModalEditableSelector
  );
  const currentIntegrationSettings = useSelector(integrationDataSelector);
  const initialIntegrationSettings = useSelector(
    initialIntegrationSettingsSelector
  );
  const isIntegrationDataNotLoaded = useSelector(
    isIntegrationDataNotLoadedSelector
  );
  const integrationConfigData = useSelector(integrationConfigDataSelector);
  const demoHTML = useSelector(demoHTMLSelector);

  const {
    sentinel,
    publicKey,
    token,
    bot: { jid, name },
  } = state;
  const [showUserBg, setShowUserBg] = useState(false);
  const blobServiceClient = new BlobServiceClient(
    `${AZURE_STORAGE_URI}?${SAS_TOKEN}`
  );
  const containerClient =
    blobServiceClient.getContainerClient(DEMO_CONTAINER_NAME);

  const blobHTMLName = folderPath + '/index.html';

  const [loading, setLoading] = useState(false);

  const localIntegration = JSON.parse(localStorage.getItem(INTEGRATION_DATA));
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showColorPickerBubble, setShowColorPickerBubble] = useState(false);
  const [showColorPickerIcon, setShowColorPickerIcon] = useState(false);

  // `setIntegrationData` => to change integrationData on component state level
  const [integrationData, setIntegrationData] = useState(
    localIntegration || currentIntegrationSettings
  );
  // Not sure where we need this specific state
  const [onLoadIntegrationData, setOnloadIntegrationData] = useState(
    localIntegration || currentIntegrationSettings
  );

  const chatWidget = document.getElementsByClassName('chatBot');
  const [setEnableEdit] = useState(false);
  const [bgImage, setBgImage] = useState(null);
  const [widgetImage, setWidgetImage] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [uploadedBgImage, setUploadedBgImage] = useState(true);
  const [uploadedWidgetImage, setUploadedWidgetImage] = useState(false);
  const [uploadedHeaderImage, setUploadedHeaderImage] = useState(false);
  const [demoLink, setDemoLink] = useState(null);
  const [tempFormFields, setTempFormFields] = useState(
    integrationData?.settings?.formFields
  );

  // To be removed ;)

  const [viewWidgetImage, setViewWidgetImage] = useState({
    isVisible: false,
    previewImage: '',
    previewTitle: '',
  });

  const [viewHeaderImage, setViewHeaderImage] = useState({
    isVisible: false,
    previewImage: '',
    previewTitle: '',
  });

  const applyheight = useCallback(
    chatWidgetEl => {
      const chatWidgetActive = chatWidgetEl.querySelector(
        '.rcw-messages-container'
      );
      if (chatWidgetEl && !!chatWidgetActive) {
        chatWidgetEl.querySelector('.rcw-messages-container').style.height =
          widgetHeightWithPixel;
        chatWidgetEl.querySelector('.rcw-messages-container').style.maxHeight =
          widgetHeightWithPixel;
      }
      return false;
    },
    [widgetHeightWithPixel]
  );

  const applyMessageColor = useCallback(
    (elem, color, textColor, fontSize) => {
      let chatWidgetEl = elem;
      if (chatWidgetEl) {
        const messages = [...chatWidgetEl.querySelectorAll('.rcw-message')];
        if (messages) {
          messages.map(elem => {
            if (elem.querySelector('.rcw-message-text')) {
              elem.querySelector('.rcw-message-text').style.backgroundColor =
                color;
              elem.querySelector('.rcw-message-text').style.color = textColor;
              elem.querySelector('.rcw-message-text').style.fontSize = fontSize;
            }
            return null;
          });
        }
        if (chatWidgetEl.querySelector('.rcw-header')) {
          chatWidgetEl.querySelector('.rcw-header h4').style.color = textColor;
          chatWidgetEl.querySelector('.rcw-header').style.color = textColor;
        }
      }
    },
    [currentIntegrationSettings.settings.textColor]
  );

  const leftAlignHeader = (chatWidgetEl, headerTitle) => {
    const header = chatWidgetEl.querySelector('.rcw-header');
    if (header) {
      header.style.textAlign = 'left';
    }
    headerTitle.style.padding = '3px';
  };

  const applyAndAlignHeaderAvatar = (chatWidgetEl, headerTitle) => {
    if (headerTitle) {
      const header = chatWidgetEl.querySelector('.rcw-header');
      if (header) {
        header.style.display = 'revert';
      }
      if (headerAvatarType === 'custom') {
        setHeaderImg(headerTitle);
      } else if (headerAvatarType === 'icon') {
        setHeaderIcon(headerTitle);
      }
      // Align-to-left ChatHeader contents if avatar is applied
      leftAlignHeader(chatWidgetEl, headerTitle);
    }
  };

  const setHeaderImg = useCallback(
    headerTitle => {
      const createdDiv = document.createElement('div');
      const createdImg = document.createElement('img');
      createdImg.classList.add('headerLogo');
      createdImg.style.marginRight = '2%';
      createdImg.style.float = 'left';
      createdImg.style.height = '70px';
      createdImg.style.width = 'auto';
      if (headerAvatar.preview) {
        createdImg.setAttribute('src', headerAvatar.preview);
      } else {
        // expecting it to be a cloudpath
        createdImg.setAttribute('src', headerAvatar);
      }
      createdDiv.appendChild(createdImg);
      createdDiv.setAttribute('id', 'headerLogo');
      headerTitle.parentNode.insertBefore(createdDiv, headerTitle);
    },
    [headerAvatar, headerAvatarType, launcherAvatar, launcherAvatarType]
  );

  const setHeaderIcon = useCallback(
    headerTitle => {
      switch (headerAvatar) {
        case 'icon1':
          injectToDOM.icon1(iconColor, headerTitle);
          break;
        case 'icon2':
          injectToDOM.icon2(iconColor, headerTitle);
          break;
        case 'icon3':
          injectToDOM.icon3(iconColor, headerTitle);
          break;
        case 'icon4':
          injectToDOM.icon4(iconColor, headerTitle);
          break;
        default:
          injectToDOM.defaultIcon(iconColor, headerTitle);
          break;
      }
    },
    [headerAvatar, launcherAvatar, launcherAvatarType, headerAvatarType]
  );

  const removeHeaderIcon = useCallback((chatWidgetEl, logoParentEl) => {
    logoParentEl?.parentNode?.removeChild(logoParentEl);
    const header = chatWidgetEl.querySelector('.rcw-header');
    if (header) {
      chatWidgetEl.querySelector('.rcw-header').style.textAlign = 'center';
      chatWidgetEl.querySelector('.rcw-header').style.padding = '15px';
    }
  }, []);

  const applyHeaderLogo = useCallback(
    chatWidgetEl => {
      if (chatWidgetEl) {
        const headerLogoElement = document.getElementById('headerLogo');
        const headerTitle = chatWidgetEl.querySelector(
          '.rcw-header .rcw-title'
        );

        // checks if current avatar is ALLOWED to be displayed
        if (
          (isUseWidgetIconRadioDisabled &&
            LAUNCHER_ONLY_AVATAR.includes(headerAvatar)) ||
          (headerAvatarType === 'custom' &&
            (!headerAvatar || AVATAR_ICON_OPTIONS.includes(headerAvatar)))
        ) {
          return removeHeaderIcon(chatWidgetEl, headerLogoElement);
        }
        // checks if avatar is EMPTY
        else if (
          !headerAvatar ||
          (headerAvatarType === 'icon' && isHeaderAvatarNotAllowed)
        ) {
          return removeHeaderIcon(chatWidgetEl, headerLogoElement);
        }
        // NON-EMPTY avatar goes here
        else {
          if (headerAvatarType === 'custom' || headerAvatarType === 'icon') {
            if (!headerLogoElement) {
              applyAndAlignHeaderAvatar(chatWidgetEl, headerTitle);
            } else {
              headerLogoElement.parentNode.removeChild(headerLogoElement);
              applyAndAlignHeaderAvatar(chatWidgetEl, headerTitle);
            }
          } else {
            return removeHeaderIcon(chatWidgetEl, headerLogoElement);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      headerAvatar,
      isHeaderIconTypeAvatar,
      setHeaderIcon,
      setHeaderImg,
      // include launcherAvatar as dependecy
      // cuz we'll inject current launcher icon value
      // if launcherAvatar and avatar both use widget icons
      launcherAvatar,
      headerAvatarType,
      isUseWidgetIconRadioDisabled,
      removeHeaderIcon,
      botTitle,
    ]
  );

  const applyWelcomeMessage = useCallback(
    elem => {
      let chatWidgetEl = elem;
      if (chatWidgetEl && currentIntegrationSettings.settings) {
        const chatWidgetActive = chatWidgetEl.querySelector(
          '.rcw-messages-container'
        );
        if (chatWidgetEl && !!chatWidgetActive) {
          setTimeout(() => {
            dropMessages();
            renderCustomComponent(V1CustomComponent, {
              message: welcomeMessage,
              time: new Date().toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
              }),
            });
            applyReplyColor(chatWidget[0]);
          }, 500);
        }
      }
    },
    [currentIntegrationSettingsObject]
  );

  const applyReplyColor = useCallback(
    elem => {
      const { color, replyBubbleGradient, fontSize } =
        currentIntegrationSettingsObject;
      let chatWidgetEl = elem;
      if (chatWidgetEl) {
        const replies = [
          ...chatWidgetEl.getElementsByClassName('parsedAnswer'),
        ];
        if (replies) {
          const bgColor = hexToRGBA(color, replyBubbleGradient);
          replies.map(elem => (elem.style.backgroundColor = bgColor));
          replies.map(elem => (elem.style.fontSize = fontSize));
        }
      }
    },
    [currentIntegrationSettingsObject, defaultReplyBubbleColor]
  );

  const applyThemeColor = useCallback(
    (elem, color) => {
      let chatWidgetEl = elem;
      if (chatWidgetEl) {
        if (
          chatWidgetEl.getElementsByClassName('.rcw-conversation-container') &&
          chatWidgetEl.querySelector('.rcw-header')
        ) {
          chatWidgetEl.querySelector('.rcw-header').style.backgroundColor =
            color;
        }
        if (chatWidgetEl.getElementsByClassName('chatBotWidgetLauncher')[0]) {
          chatWidgetEl.getElementsByClassName(
            'chatBotWidgetLauncher'
          )[0].style.backgroundColor = color + 260;
        }
        if (chatWidgetEl.querySelector('.rcw-sender')) {
          chatWidgetEl.querySelector('.rcw-sender').style.backgroundColor =
            color + 260;
          chatWidgetEl.querySelector('.rcw-new-message').style.backgroundColor =
            color + 260;
          chatWidgetEl.querySelector('.rcw-send').style.backgroundColor =
            color + 260;
        }
      }
    },
    [currentIntegrationSettings?.settings?.color]
  );

  const applyTime = useCallback(
    (elem, today) => {
      let chatWidgetEl = elem;
      if (chatWidgetEl) {
        const messagesCount = chatWidgetEl.querySelectorAll(
          '.rcw-messages-container .rcw-message'
        );
        if (messagesCount) {
          const timeStampElem = messagesCount[
            messagesCount.length - 1
          ].querySelector('.rcw-client .rcw-timestamp');
          if (timeStampElem) {
            timeStampElem.innerHTML = today;
          }
        }
      }
    },
    [integrationData]
  );

  const handleChangeWidgetImage = async ({ file }) => {
    setUploadedWidgetImage(true);
    if (file.status === 'removed') {
      setWidgetImage(null);
      handleChangeFields('widgetIconUrl', null);
      setViewWidgetImage({ ...viewWidgetImage, isVisible: false });
    } else {
      setWidgetImage([file]);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file);
      }
      setViewWidgetImage({
        isVisible: true,
        previewImage: file.url || file.preview,
        previewTitle:
          file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      });
      handleChangeFields(
        'widgetIconUrl',
        AZURE_DEMO_URI +
          `${folderPath}/widget${file.name.substr(file.name.indexOf('.'))}`
      );
    }
  };

  const handleChangeFields = (name, value) => {
    const key = { [name]: value };
    const { settings } = integrationData;
    setUploadedBgImage(true);
    if (
      name === 'shape' &&
      value !== 'rectangle' &&
      settings?.position?.includes('mid')
    ) {
      setIntegrationData({
        ...integrationData,
        settings: { ...settings, position: 'bottom-right', shape: value },
      });
    } else if (name === 'shape' && value === 'rectangle') {
      setIntegrationData({
        ...integrationData,
        settings: {
          ...settings,
          label: settings?.label || `Ask ${settings.botTitle || 'Me'}`,
          shape: value,
        },
      });
    } else if (
      name === 'avatar' &&
      (value === 'default' || value === 'icon1')
    ) {
      setIntegrationData({
        ...integrationData,
        settings: { ...settings, avatar: value, avatarPosition: null },
      });
    } else if (name === 'undo') {
      handleUndoFields(value, settings);
    } else if (name === 'undoIcon') {
      if (onLoadIntegrationData.settings.botAvatar === 'icon') {
        handleUndoIcon(settings);
      } else {
        setWidgetImage(null);
        handleUndoWidget(settings);
      }
    } else if (name === 'undoHeaderIcon') {
      setHeaderImage(null);
      handleUndoHeader(settings);
    } else if (name === 'removedHeader') {
      setHeaderImage(null);
      delete integrationData.settings.headerResponseImgUrl;
    } else if (name === 'clear') {
      clearIntegrationData();
    } else if (name === 'background' && value !== 'light' && value !== 'dark') {
      setUploadedBgImage(false);
      if (!bgImage) {
        setIntegrationData({
          ...integrationData,
          settings: { ...settings, background: demoBackground || 'custom' },
        });
      } else {
        setBgImage(bgImage);
        setIntegrationData({
          ...integrationData,
          settings: { ...settings, ...key },
        });
      }
    } else if (name === 'fontSize') {
      setIntegrationData({
        ...integrationData,
        settings: { ...settings, fontSize: value + 'px' },
      });
    } else if (name === 'color') {
      setIntegrationData({
        ...integrationData,
        settings: {
          ...settings,
          ...key,
          bubbleColor: value,
        },
      });
    } else if (
      (name === 'widgetIconUrl' && value === null) ||
      (name === 'botAvatar' && value === 'icon')
    ) {
      setIntegrationData({
        ...integrationData,
        settings: { ...settings, ...key },
      });
      delete integrationData.settings.widgetIconUrl;
    } else if (name === 'authenticatedUser') {
      if (value === true) {
        setIntegrationData({
          ...integrationData,
          settings: { ...settings, ...key, displayFormFields: false },
        });
      } else {
        setIntegrationData({
          ...integrationData,
          settings: { ...settings, ...key, displayFormFields: true },
        });
      }
    } else if (name === 'displayFormFields' && value === false) {
      setIntegrationData({
        ...integrationData,
        settings: { ...settings, ...key, callbackEmail: false },
      });

      delete integrationData.settings.formFields;
    } else {
      setIntegrationData({
        ...integrationData,
        settings: { ...settings, ...key },
      });
    }
  };

  const clearIntegrationData = () => {
    if (showColorPicker) {
      setShowColorPicker(!showColorPicker);
    }
    if (showColorPickerBubble) {
      setShowColorPickerBubble(!showColorPickerBubble);
    }
    if (showColorPickerIcon) {
      setShowColorPickerIcon(!showColorPickerIcon);
    }
    setIntegrationData(initialIntegrationSettings);
    setBgImage(null);
    setWidgetImage(null);
    setHeaderImage(null);
  };

  const handleChangeDemoBackground = val => {
    dispatch({ type: SET_DEMO_BACKGROUND, payload: val });
  };

  useEffect(() => {
    setIntegrationData(currentIntegrationSettings);
  }, [currentIntegrationSettings]);

  const handleUndoIcon = settings => {
    setIntegrationData({
      ...integrationData,
      settings: {
        ...settings,
        botAvatar: onLoadIntegrationData.settings.botAvatar,
        headerAvatar: onLoadIntegrationData.settings.headerAvatar,
        responseAvatar: onLoadIntegrationData.settings.responseAvatar,
        iconColor: onLoadIntegrationData.settings.iconColor,
      },
    });
  };

  const handleUndoHeader = settings => {
    setUploadedHeaderImage(false);
    setViewHeaderImage({
      isVisible: false,
      previewImage: '',
      previewTitle: '',
    });
    setIntegrationData({
      ...integrationData,
      settings: {
        ...settings,
        headerResponseImgUrl:
          onLoadIntegrationData.settings.headerResponseImgUrl,
      },
    });
  };

  const handleUndoWidget = settings => {
    setUploadedWidgetImage(false);
    setUploadedHeaderImage(false);
    setViewWidgetImage({
      isVisible: false,
      previewImage: '',
      previewTitle: '',
    });
    setIntegrationData({
      ...integrationData,
      settings: {
        ...settings,
        widgetIconUrl: onLoadIntegrationData.settings.widgetIconUrl,
        botAvatar: onLoadIntegrationData.settings.botAvatar,
      },
    });
  };

  const handleUndoFields = (value, settings) => {
    if (value === 'botPanelConfig') {
      setIntegrationData({
        ...integrationData,
        settings: {
          ...settings,
          botTitle: onLoadIntegrationData.settings.botTitle,
          subtitle: onLoadIntegrationData.settings.subtitle,
          placeholder: onLoadIntegrationData.settings.placeholder,
          welcomeMessage,
        },
      });
    } else if (value === 'botAppearance') {
      setWidgetImage(null);
      setHeaderImage(null);
      setIntegrationData({
        ...integrationData,
        settings: {
          ...settings,
          ...onLoadIntegrationData.settings,
        },
      });
    } else if (value === 'botPlacement') {
      setBgImage(null);
      setIntegrationData({
        ...integrationData,
        settings: {
          ...settings,
          background: onLoadIntegrationData.settings.background,
          shape: onLoadIntegrationData.settings.shape,
          src: onLoadIntegrationData.settings.src,
          position: onLoadIntegrationData.settings.position,
          label: onLoadIntegrationData.settings.label,
        },
      });
    }
  };

  const handleNewUserMessage = async (
    newMessage,
    channel,
    theme,
    textColor,
    fontSize
  ) => {
    const chatWidget = document.getElementsByClassName('chatBot');
    const today = new Date().toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });
    try {
      const res = await apiService.askQuestion(
        sentinel,
        jid,
        newMessage,
        token,
        channel
      );
      if (chatWidget && chatWidget[0]) {
        await applyTime(chatWidget[0], today);
        applyMessageColor(chatWidget[0], theme, textColor, fontSize);
        const answerMessage = res.data.report[0].context;
        if (
          answerMessage &&
          answerMessage.show_text &&
          answerMessage.show_html
        ) {
          const answerList = answerMessage.show_html || answerMessage.show_text;
          const displayAnswer = Array.isArray(answerList)
            ? answerList
            : [answerList];
          displayAnswer?.map(answer =>
            renderCustomComponent(V1CustomComponent, {
              message: answer,
              time: today,
            })
          );
        } else if (answerMessage && answerMessage.text) {
          addResponseMessage(answerMessage.text);
        } else {
          renderCustomComponent(V1CustomComponent, {
            message: `Error encountered, try reloading the page or contact the Admin.`,
            time: today,
            hasError: true,
          });
        }
        await applyReplyColor(chatWidget[0]);
      }
    } catch (err) {
      throw typeof err === 'string' ? err : err.message;
    }
  };

  const spawnCreate = async () => {
    const apiName = 'zsb_public_api';
    try {
      const res = await apiService.spawnCreate(apiName, token);
      const pkRes = await apiService.getPublicKey(token);
      const pK = pkRes.data.anyone;
      const pAQ = res.data.jid;
      dispatch({
        type: SPAWN_CREATE,
        payload: {
          publicKey: pK,
          pubAskedQuestion: pAQ,
        },
      });

      return [pK, pAQ];
    } catch (error) {
      throw new Error('Something went wrong while creating the integration');
    }
  };

  const handleSubmitCustomizeBot = async evt => {
    if (evt) {
      evt.preventDefault();
    }
    setLoading(true);
    setShowColorPicker(false);
    setShowColorPickerBubble(false);
    setShowColorPickerIcon(false);

    if (!publicKey) {
      try {
        await spawnCreate();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        return message.error(error.message || DEFAULT_ERROR_MESSAGE);
      }
    }

    try {
      if (launcherAvatarType === 'custom' && launcherAvatar instanceof File) {
        await BlobStorageService.uploadIntegrationCustomAvatar(
          folderPath,
          launcherAvatar,
          'launcherAvatar'
        );
        dispatch({
          type: SET_LAUNCHER_AVATAR_TO_CLOUD_PATH,
        });
      }
      if (headerAvatarType === 'custom' && headerAvatar instanceof File) {
        await BlobStorageService.uploadIntegrationCustomAvatar(
          folderPath,
          headerAvatar,
          'headerAvatar'
        );
        dispatch({
          type: SET_HEADER_AVATAR_TO_CLOUD_PATH,
        });
      }

      if (isDemo) {
        await BlobStorageService.uploadDemoHTMLAndImageFiles(
          folderPath,
          integrationConfigData,
          isDemo,
          integrationConfigURL,
          demoHTML,
          isDemoHasCustomBackground,
          demoCustomBackground
        );

        if (isDemoHasCustomBackground) {
          dispatch({
            type: SET_DEMO_BACKGROUND_TO_CLOUD_PATH,
          });
        }
      }

      if (isDemo) {
        setDemoLink(`${AZURE_DEMO_URI}${folderPath}`);
      }
    } catch (error) {
      message.error(error.message || DEFAULT_ERROR_MESSAGE);
    }

    if (!isDemo) {
      await handleSendToWebIntegration();
    }

    // ???
    // renderPreviewImgBackground(currentIntegrationSettings.settings.background);

    dispatch({
      type: SET_INTEGRATION_ACTIVE_PANEL,
      payload: {
        panel: LAST_PANEL_AND_PERMIT_KEY,
        permit: LAST_PANEL_AND_PERMIT_KEY,
      },
    });
    setIntegrationData(currentIntegrationSettings);
    setOnloadIntegrationData(currentIntegrationSettings);
    setLoading(false);
  };

  const handleSendToWebIntegration = async () => {
    try {
      const res = !currentIntegrationID ? await onSave() : await onUpdate();

      if (!currentIntegrationID) {
        message.success(`Integration channel added`);
      } else {
        message.success(`Successfully updated integration channel`);
      }

      dispatch({
        type: SET_CURRENT_INTEGRATION_DATA,
        payload: res,
      });
    } catch (error) {
      setLoading(false);
      return message.error(
        error || `An error encountred while adding the integration channel.`
      );
    }
  };

  const getButtonValue = () => {
    if (currentIntegrationID) {
      return 'Update';
    } else if (loading) {
      return 'Processing';
    } else if (isDemo && demoLink) {
      return 'Update Demo URL';
    } else if (isDemo) {
      return 'Get Demo URL';
    }
    return 'Save';
  };

  useEffect(() => {
    const imgContainer = document.getElementsByClassName(
      'ant-upload-list-picture-card-container'
    );

    if (!bgImage && imgContainer && imgContainer[0]) {
      imgContainer[0].classList.add('hidden');
    }
  }, [bgImage]);

  useEffect(() => {
    // on component load/update
    async function getExistingDemo() {
      setLoading(true);
      try {
        const demoHTMLFile = containerClient.getBlobClient(blobHTMLName);
        const demoConfigfile = `${AZURE_DEMO_URI}${folderPath}/config.json`;
        const isDemoExists = await demoHTMLFile.exists();
        if (isDemoExists) {
          setDemoLink(`${AZURE_DEMO_URI}${folderPath}`);
        }
        const demoConfigJSON = containerClient.getBlobClient(
          `${folderPath}/config.json`
        );
        const isConfigExists = await demoConfigJSON.exists();

        // Addtional check so previous users w/o config file but has demo wont break
        if (isConfigExists) {
          const configFile = await fetch(demoConfigfile);
          const configData = await configFile.json();
          // TODO: create separate reducer for demos
          dispatch({
            type: SET_CURRENT_INTEGRATION_DATA,
            payload: { settings: configData, type: 'demo' },
          });
        } else {
          dispatch({
            type: SET_CURRENT_INTEGRATION_DATA,
            payload: { type: 'demo' },
          });
        }
        setLoading(false);
      } catch (error) {
        if (error.message) {
          message.error(error.message);
        }
        setLoading(false);
      }
    }
    if (isDemo && isIntegrationDataNotLoaded) {
      getExistingDemo();
    }
  }, []);

  useEffect(() => {
    if (chatWidget && chatWidget[0] && integrationData.settings) {
      applyheight(chatWidget[0]);
      applyThemeColor(chatWidget[0], integrationData.settings.color);
      applyMessageColor(
        chatWidget[0],
        integrationData.settings.bubbleColor || integrationData.settings.color,
        integrationData.settings.textColor,
        integrationData.settings.fontSize
      );
      applyReplyColor(chatWidget[0]);
      applyHeaderLogo(chatWidget[0]);
      applyWelcomeMessage(chatWidget[0]);
    }
  }, [
    applyMessageColor,
    applyReplyColor,
    applyThemeColor,
    applyheight,
    applyHeaderLogo,
    applyWelcomeMessage,
    chatWidget,
    integrationData,
    headerAvatar,
    headerAvatarType,
    avatarPosition,
    launcherAvatar,
    widgetHeight,
  ]);

  useEffect(() => {
    if (currentIntegrationID) {
      setIntegrationData(currentIntegrationSettings);
    }
  }, [currentIntegrationSettings]);

  useEffect(() => {
    dropMessages();
    if (!isWidgetOpened()) {
      toggleWidget();
    }
  }, [integrationData, sentinel]);

  useEffect(() => {
    return () => {
      if (isDemo) {
        dispatch({
          type: RESET_INTEGRATION_SETTINGS,
        });
        localStorage.removeItem(INTEGRATION_DATA);
      }
    };
  }, []);

  const renderPreviewImgBackground = () => {
    return (
      <StyledTempImage onClick={() => setShowUserBg(true)}>
        <img
          src={AZURE_DEMO_URI + demoCustomBackground + '?date=' + new Date()}
          alt="demo background"
        />
      </StyledTempImage>
    );
  };

  const handleChangeCustomizerActivePanel = val => {
    dispatch({ type: SET_BOT_CUSTOMIZER_ACTIVE_PANEL, payload: val });
  };

  return {
    allAnswers,
    bgImage,
    name,
    uploadedBgImage,
    uploadedWidgetImage,
    uploadedHeaderImage,
    currentIntegrationID,
    currentIntegrationSettings,
    demoLink,
    integrationData,
    onLoadIntegrationData,
    isDemo,
    isCustomBgSelected,
    isMobileIntegration,
    getButtonValue,
    loading,
    viewWidgetImage,
    viewHeaderImage,
    showColorPicker,
    showColorPickerBubble,
    showColorPickerIcon,
    showUserBg,
    handleChangeFields,
    handleChangeWidgetImage,
    handleNewUserMessage,
    handleSubmitCustomizeBot,
    setEnableEdit,
    setShowColorPicker,
    setShowColorPickerBubble,
    setShowColorPickerIcon,
    renderPreviewImgBackground,
    setShowUserBg,
    generateIcon,
    setUploadedWidgetImage,
    setUploadedHeaderImage,
    tempFormFields,
    isBotMailConfigEnabled,
    isBotOpenAIEnabled,
    isAvatarPickerModalOpen,
    headerAvatarType,
    headerAvatar,
    launcherAvatar,
    launcherAvatarType,
    widgetThemeColor,
    avatarPosition,
    isHeaderIconTypeAvatar,
    handleUndoFields,
    isPostCustomizationEditable,
    isPostCustomizationEditModalOpen,
    handleChangeDemoBackground,
    currentIntegrationSettingsObject,
    isDemoHasCustomBackground,
    encryptedBot,
    handleChangeCustomizerActivePanel,
    customizerActivePanel,
    demoCustomBackground,
    defaultReplyBubbleColor,
  };
};

export default useBotCustomizer;
