import ROUTES from 'constants/routes';
import Users from '../Users';
import GlobalVars from '../GlobalVars';
import ActivityLogs from 'components/ActivityLogs';
import Versions from '../Versions';

export const dashboardRoutes = [
  {
    name: 'User List',
    alias: 'Users',
    path: ROUTES.ADMIN_USERS,
    component: Users,
    key: 'admin_users',
  },
  {
    name: 'Global Vars',
    alias: 'Vars',
    path: ROUTES.ADMIN_GLOBAL_VARS,
    component: GlobalVars,
    key: 'global_vars',
  },
  {
    name: 'Admin Bot',
    alias: 'Bots',
    path: ROUTES.BOTS_PAGE,
    key: 'bots',
  },
  {
    name: 'All Users Activity Logs',
    alias: 'Logs',
    path: ROUTES.ADMIN_ACTIVITY_LOGS,
    component: ActivityLogs,
    key: 'activity_logs',
  },
  {
    name: 'Version Management',
    alias: 'Versions',
    path: ROUTES.ADMIN_VERSIONS,
    component: Versions,
    key: 'versions',
  },
];
