import styled from 'styled-components';
import { cssVariables } from 'styles/root';

export const StyledPageTitle = styled.div`
  text-align: left;
  width: 100%;
  margin: 1% 2%;
  & h3 {
    display: flex;
    width: 100%;
    font-size: 24px;
    font-weight: ${cssVariables.font.normal};
  }
`;

export const StyledContent = styled.div`
  display: flex;
  height: auto;
  background: #fff;
  padding: 30px;
  width: 100%;
  flex-direction: column;

  & .ant-alert {
    margin: 20px;
  }

  & h2 {
    font-size: 18px;
    font-weight: ${cssVariables.font.normal};
  }
`;

export const StyledFormButtons = styled.div`
  display: flex;
  column-gap: 10px;

  & button {
    width: 100%;
  }

  &:first-child {
    margin: 0 !important;
  }
`;

export const StyledVersionDetailsContainer = styled.div`
  display: flex;
  height: auto;
  padding: 5px 0;
  width: 100%;
  flex-direction: column;
  font-weight: 700;
`;
