import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import useBotCustomizer from './hooks';
import CustomizeBot from './CustomizeBot';
import useSelector from 'store/useSelector';
import { shouldShowWidgetSelector } from 'selectors/bot/integration';

const BotCustomizer = ({ onSave, onUpdate }) => {
  const shouldShowWidget = useSelector(shouldShowWidgetSelector);
  const { loading, isSendCallbackLog } = useBotCustomizer({
    onSave,
    onUpdate,
  });

  return (
    <Spin spinning={loading} tip="Just a moment...">
      <div>
        {shouldShowWidget ? (
          <CustomizeBot
            onSave={onSave}
            onUpdate={onUpdate}
            isSendCallbackLog={isSendCallbackLog}
          />
        ) : null}
      </div>
    </Spin>
  );
};

BotCustomizer.propTypes = {
  onSave: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default BotCustomizer;
