import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'services/api.service';
import {
  StyledButtonContainer,
  StyledTable,
  StyledWrapper,
} from './VersionsTable.style';
import Button from 'components/Button';
import VersionModal from '../VersionsModal/VersionModal';
import { useEffect } from 'react';
import { Col, Row } from 'antd';

const VersionsTable = ({
  totalDatas,
  loading,
  pageSize,
  setInitLoading,
  datatable,
  getAllVersions,
  sentinel,
  token,
  setCurrentData,
}) => {
  const [currentSize, setCurrentSize] = useState(pageSize);
  const [showVersionsModal, setShowVersionsModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [currentVersion, setCurrentVersion] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState([]);

  const handlePagination = (current, pageSize) => {
    setInitLoading(true);
    current * pageSize < totalDatas
      ? setCurrentSize(current * pageSize)
      : setCurrentSize(totalDatas);
    datatable.slice((current - 1) * pageSize, current * pageSize);
    setInitLoading(false);
  };

  const handleAddVersions = () => {
    setModalTitle('Add Version');
    setShowVersionsModal(true);
  };

  const handleUpdateVersion = versionSelected => {
    setSelectedVersion(
      datatable.find(
        versionDetails => versionDetails.version === versionSelected
      )
    );
    setModalTitle('Update Version');
    setShowVersionsModal(true);
  };

  const addVersion = async versionDetails => {
    try {
      const res = await apiService.addVersions(sentinel, token, versionDetails);
      if (res.data.success) {
        setCurrentData([]);
        const refetchVersions = async () => {
          setInitLoading(true);
          await getAllVersions();
        };
        refetchVersions();
        setInitLoading(false);
      }
    } catch (err) {
      throw err;
    }
  };

  const updateVersion = async version => {
    try {
      const res = await apiService.addGlobalVars('features', version, token);
      if (res.data.success) {
        setCurrentData([]);
        const refetchVersions = async () => {
          setInitLoading(true);
          await getAllVersions();
        };
        refetchVersions();
        setInitLoading(false);
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    const currentVersion = datatable.find(
      version => version.status === 'current'
    );
    setCurrentVersion(currentVersion);
  }, [datatable]);
  return (
    <StyledWrapper>
      <StyledTable
        size="small"
        loading={loading}
        columns={[
          {
            title: 'Version',
            dataIndex: 'version',
          },
          {
            title: 'Features',
            dataIndex: 'features_string',
            responsive: ['md'],
          },
          {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.current.localeCompare(b.current),
          },
          {
            title: 'Actions',
            dataIndex: 'status',
            render: (value, row) => (
              <Row gutter={[8, 8]}>
                {value === 'current' && (
                  <Col xs={24} sm={24} md={16} xl={12}>
                    <Button
                      value="Create New"
                      variant="primary"
                      onClick={handleAddVersions}
                      full
                    />
                  </Col>
                )}
                <Col xs={24} sm={24} md={8} xl={12}>
                  <Button
                    value="Edit"
                    variant="primary"
                    onClick={() => handleUpdateVersion(row.version)}
                    full
                  />
                </Col>
              </Row>
            ),
          },
        ]}
        dataSource={datatable}
        pagination={{
          onChange: (page, pageSize) => {
            handlePagination(page, pageSize);
          },
          pageSize: pageSize,
          total: totalDatas,
          position: ['topRight'],
        }}
        footer={() =>
          `Showing ${
            totalDatas >= currentSize ? currentSize : 1
          } of ${totalDatas} Versions.`
        }
        showSorterTooltip={{
          title: 'Click to sort',
          placement: 'topRight',
        }}
      />
      <VersionModal
        setShowVersionsModal={setShowVersionsModal}
        showVersionsModal={showVersionsModal}
        title={modalTitle}
        selectedVersion={
          modalTitle === 'Update Version' ? selectedVersion : currentVersion
        }
        addVersion={addVersion}
        updateVersion={updateVersion}
        datatable={datatable}
      />
    </StyledWrapper>
  );
};

VersionsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  setInitLoading: PropTypes.bool,
  getAllVersions: PropTypes.func.isRequired,
};

export default VersionsTable;
