import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { omit } from 'lodash';

import { extractDisplayAnswer } from 'utils/answers';
import { cssVariables } from 'styles/root';
import CodeBlock from 'components/CodeBlock';
import { StyledZoomIn } from 'components/SidebarChat/StyledComponents';
import {
  StyledFlexLeftColumn,
  StyledFlexRowLeft,
} from 'styles/GenericStyledComponents';
import { ZSB_CHAT_BREAKER_ENCONDING } from 'constants/answerbank/defaults';
import { isAnObject, isImageOrDocFileType } from 'utils/dataTypes';
import { FileImageTwoTone, FileTextTwoTone } from '@ant-design/icons';
import { showLessTextCharacters } from 'utils';
import AnchorButton from 'components/Button/AnchorButton';

const StyledChatBubble = styled.div`
  text-align: left;
  overflow-x: auto;
  box-shadow: 1px 1px 2px 0px #b5b5b5;
  border-radius: 10px;
  font-family: Calibri, sans-serif;
  line-height: 1.5715;
  background-color: #f0f2f5;
  margin: 10px;
  padding: 10px;
  max-width: ${props => (props.noMaxWidth ? '100%' : '80%')};

  & p:first-child,
  & p:last-child {
    margin: 0;
  }

  & img {
    width: 100% !important;
  }

  & p:empty {
    display: none;
    margin: 0;
  }

  ${props =>
    props.isDefaultAnswer
      ? css`
          background-color: ${cssVariables.red1};
          box-sizing: border-box;
          box-shadow: 1px 2px 1px 0px ${cssVariables.red10};
        `
      : css``}
`;

const ChatBubble = props => {
  const {
    answer,
    showDisplayAnswer,
    isDevMode,
    showJSONAnswerInModal,
    isOtherPlatformPreview,
    width,
    isDefaultAnswer,
    hideFullAnswer,
    ...rest
  } = props;
  const [showFullText, setShowFullText] = useState(false);

  const renderSeeLessCharacters = useCallback(
    answerRendered => {
      const maxTextLength = 190;
      return showLessTextCharacters(
        answerRendered,
        showFullText,
        maxTextLength
      );
    },
    [showFullText]
  );

  const renderAnchorButton = displayAnswer => (
    <AnchorButton
      style={{ marginLeft: 5 }}
      onClick={() => setShowFullText(!showFullText)}
      title={renderShowMoreButton(displayAnswer)}
    />
  );

  const renderShowMoreButton = useCallback(
    answerRendered => {
      if (showDisplayAnswer || answer?.type?.includes('default')) {
        return null;
      } else if (!showFullText && answerRendered?.length > 200) {
        return ' See More';
      } else if (showFullText && answerRendered?.length > 200) {
        return ' See Less';
      } else {
        return null;
      }
    },
    [showFullText]
  );

  const renderChatBubble = (displayAnswer, answerType, idx) => {
    if (hideFullAnswer) {
      const renderedDisplayAnswer = renderSeeLessCharacters(
        extractDisplayAnswer(displayAnswer)
      );

      return (
        <StyledChatBubble
          key={`chat-bubble-${idx || 0}`}
          {...rest}
          isDefaultAnswer={isDefaultAnswer || answerType?.includes('default')}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: renderedDisplayAnswer,
            }}
          />
          {renderShowMoreButton(extractDisplayAnswer(displayAnswer)) &&
            renderAnchorButton(extractDisplayAnswer(displayAnswer))}
        </StyledChatBubble>
      );
    } else {
      return (
        <StyledChatBubble
          key={`chat-bubble-${idx || 0}`}
          {...rest}
          isDefaultAnswer={isDefaultAnswer || answerType?.includes('default')}
          dangerouslySetInnerHTML={{
            __html: extractDisplayAnswer(displayAnswer),
          }}
        />
      );
    }
  };

  const renderSplittedBubble = value => {
    if (hasZSBChatBreakerEncoding(value)) {
      const splitted = value?.split(ZSB_CHAT_BREAKER_ENCONDING);
      return splitted.map((answer, idx) => {
        return renderChatBubble(answer, answer.type, idx);
      });
    }
  };

  const hasZSBChatBreakerEncoding = value => {
    if (value && typeof value === 'string') {
      return value.includes(ZSB_CHAT_BREAKER_ENCONDING) ? true : false;
    }
    return false;
  };

  const renderShowHTML = () => {
    if (Array.isArray(answer.show_html)) {
      return answer.show_html.map((html, idx) => {
        if (hasZSBChatBreakerEncoding(html)) {
          return renderSplittedBubble(html);
        }
        return renderChatBubble(html, answer.type, idx);
      });
    } else if (typeof answer.show_html === 'string') {
      if (hasZSBChatBreakerEncoding(answer.show_html)) {
        return renderSplittedBubble(answer.show_html);
      }
      return renderChatBubble(answer.show_html, answer.type);
    }
  };

  const renderShowText = () => {
    if (Array.isArray(answer.show_text)) {
      return answer.show_text.map((showText, idx) => {
        if (hasZSBChatBreakerEncoding(showText)) {
          return renderSplittedBubble(showText);
        }
        return renderChatBubble(showText, answer.type, idx);
      });
    } else if (typeof answer.show_text === 'string') {
      if (hasZSBChatBreakerEncoding(answer.show_text)) {
        return renderSplittedBubble(answer.show_text);
      }
      return renderChatBubble(answer.show_text, answer.type);
    }
  };

  const renderTextOnly = () => {
    if (typeof answer === 'string') {
      const renderedDisplayAnswer = renderSeeLessCharacters(answer);
      return (
        <StyledChatBubble {...rest}>
          {renderedDisplayAnswer}
          {renderShowMoreButton(answer) && renderAnchorButton(answer)}
        </StyledChatBubble>
      );
    } else if (isAnObject(answer)) {
      return renderChatBubble(answer.text, answer.type);
    }
  };

  const renderFallback = () => {
    const splittedAnswer = answer?.split(ZSB_CHAT_BREAKER_ENCONDING);
    return splittedAnswer.map((splittedAns, idx) => {
      return renderChatBubble(splittedAns, answer.type, idx);
    });
  };

  const renderDevMode = () => {
    return (
      <>
        <CodeBlock codeContent={omit(answer, 'error')} />
        {isDevMode ? (
          <StyledZoomIn
            onClick={showJSONAnswerInModal}
            title="View full response"
            style={{
              alignSelf: 'flex-end',
              marginBottom: -20,
              position: 'relative',
              display: 'inline-block',
            }}
          />
        ) : null}
      </>
    );
  };

  const renderFileAnswer = () => {
    if (isImageOrDocFileType(answer.text) === 'image') {
      return (
        <StyledFlexRowLeft {...rest}>
          <FileImageTwoTone style={{ fontSize: 30 }} />
          <span>{answer.text}</span>
        </StyledFlexRowLeft>
      );
    }
    return (
      <StyledFlexRowLeft {...rest}>
        <FileTextTwoTone style={{ fontSize: 30 }} /> <span>{answer.text}</span>
      </StyledFlexRowLeft>
    );
  };

  const renderOtherPlatformPreview = () => {
    if (Array.isArray(answer)) {
      return answer?.map((answer, key) => {
        return renderChatBubble(answer, answer.type, key);
      });
    } else {
      const splittedAnswer = answer?.split(ZSB_CHAT_BREAKER_ENCONDING);
      return splittedAnswer?.map(answer => {
        const splittedImageAndText = answer?.split(/(?=<img )/g);
        return splittedImageAndText?.map(splittedAnswer => {
          return renderChatBubble(splittedAnswer, splittedAnswer.type);
        });
      });
    }
  };

  return (
    <StyledFlexLeftColumn width={width}>
      {isOtherPlatformPreview
        ? renderOtherPlatformPreview()
        : typeof answer === 'string'
        ? renderFallback()
        : isDevMode
        ? renderDevMode()
        : showDisplayAnswer && typeof answer?.show_html !== 'undefined'
        ? renderShowHTML(answer.show_html)
        : showDisplayAnswer && typeof answer?.show_text !== 'undefined'
        ? renderShowText(answer.show_text)
        : !showDisplayAnswer
        ? renderTextOnly()
        : showDisplayAnswer && ['file', 'website'].includes(answer?.type)
        ? renderFileAnswer()
        : renderFallback()}
    </StyledFlexLeftColumn>
  );
};

ChatBubble.propTypes = {
  answer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  showDisplayAnswer: PropTypes.bool,
  isDefaultAnswer: PropTypes.bool,
  isDevMode: PropTypes.bool,
  showJSONAnswerInModal: PropTypes.func,
  width: PropTypes.string,
  hideFullAnswer: PropTypes.bool,
};

export default ChatBubble;
