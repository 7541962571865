import { useRef, useState, useEffect, useContext } from 'react';
import { message } from 'antd';
import { isEmpty } from 'lodash';

import { validateBotJSONSchema } from 'utils/validator';
import { getTotalAnswersLeftSelector } from 'selectors/bot/answers';
import useSelector from 'store/useSelector';
import { allBotsSelector, botModesSelector } from 'selectors/bots';
import { DEFAULT_BOT_MODE_ON_BOTMODAL } from 'constants/bot';
import {
  importExportEnabledSelector,
  isMaxBotCountReachedSelector,
  maxBotCountSelector,
  planTypeSelector,
} from 'selectors/plan';
import { Context } from 'store/store';
import {
  ADD_BOT,
  CLOSE_BOT_MODAL,
  IMPORT_BOT,
  SET_TEMP_BOT,
  UPDATE_BOT,
} from 'store/action';
import { apiService } from 'services/api.service';
import { createBot, editBot } from 'services/bots.service';
import {
  botModalStateSelector,
  isBotModalVisibleSelector,
} from 'selectors/layout';
import { GET_DATA_ERROR, PLAN_LIMIT_ERROR } from 'constants/error';
import { makeKeywordAsAnchorText } from 'utils';
import { isBotModeEnabledSelector } from 'selectors/featureFlags';
import { appendWithParenthesesIfDuplicate } from 'utils/stringManipulation';

const useBotModal = () => {
  const [state, dispatch] = useContext(Context);
  const { graph, sentinel, token } = state;
  const totalAnswersLeft = useSelector(getTotalAnswersLeftSelector);
  const botModes = useSelector(botModesSelector);
  const isMaxBotCountReached = useSelector(isMaxBotCountReachedSelector);
  const maxBotCount = useSelector(maxBotCountSelector);
  const isBotModalVisible = useSelector(isBotModalVisibleSelector);
  const planType = useSelector(planTypeSelector);
  const botModalState = useSelector(botModalStateSelector);
  const allBots = useSelector(allBotsSelector);
  const importExportEnabled = useSelector(importExportEnabledSelector);
  const isBotModeEnabled = useSelector(isBotModeEnabledSelector);

  const nameRef = useRef();
  const descriptionRef = useRef();

  const [botName, setBotName] = useState(botModalState?.name);
  const [botDesc, setBotDesc] = useState(botModalState?.description);
  const [botMetaData, setBotMetaData] = useState(null);
  const [botAnswerBank, setBotAnswerBank] = useState([]);
  const [botQuestionList, setBotQuestionList] = useState([]);
  const [botTestSuites, setBotTestSuites] = useState([]);
  const [botCategoryList, setBotCategoryList] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showImportOption, setShowImportOption] = useState(false);
  const [isImportBtnDisabled, setIsImportBtnDisabled] = useState(true);
  const [isImportSaveBtnDisabled, setIsImportSaveBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [botInfo, setBotInfo] = useState({});
  const [warnMsg, setWarnMsg] = useState('');
  const [selectExportType, setSelectExportType] = useState([]);
  const [exportOptions, setExportOptions] = useState([]);
  const [botDetails, setBotDetails] = useState([]);
  const [botMode, setBotMode] = useState(DEFAULT_BOT_MODE_ON_BOTMODAL);
  const [importStep, setImportStep] = useState(0);
  const isImporting = showImportOption && botModalState.action === 'add';
  const isImportReady = Boolean(
    isImporting && (!isEmpty(botInfo) || !isEmpty(botDetails))
  );

  const isCreatingNewBot = botModalState.action === 'add';
  const isEditingBot = botModalState.action === 'edit';

  useEffect(() => {
    return () => {
      handleClearBotData();
    };
  }, []);

  useEffect(() => {
    setBotName(botModalState?.name);
    setBotMode(botModalState?.mode || DEFAULT_BOT_MODE_ON_BOTMODAL);
    setBotDesc(botModalState?.description);
  }, [botModalState?.name, botModalState?.mode, botModalState?.description]);

  const handleClearBotData = () => {
    setBotName('');
    setBotDesc('');
    setBotMetaData(null);
    setBotAnswerBank(null);
    setBotQuestionList(null);
    setBotTestSuites(null);
    setBotCategoryList(null);
    setBotDetails();
    setBotInfo({});
    setWarnMsg('');
    setExportOptions([]);
    setSelectExportType(null);
    setShowImportModal(false);
    setImportStep(0);
    setShowImportOption(false);
    setBotMode('');
    setLoading(false);
    setIsImportSaveBtnDisabled(false);
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    if (botName) {
      const parameter =
        botDetails?.bot?.length > 0
          ? {
              ...botDetails?.bot[0],
              answer: selectExportType.includes('answer') ? botAnswerBank : [],
              answerCount: selectExportType.includes('answer')
                ? botAnswerBank?.length
                : 0,
              question: selectExportType.includes('question')
                ? botQuestionList
                : [],
              testsuite: selectExportType.includes('testsuite')
                ? botTestSuites
                : [],
              category: selectExportType.includes('category')
                ? botCategoryList
                : [],
            }
          : {
              answer: botAnswerBank,
            };

      if (isEditingBot) {
        return handleUpdateBot();
      } else {
        addNewBot(
          {
            bot: [
              {
                ...parameter,
                name: botName,
                description: botDesc || null,
                mode: botMode || DEFAULT_BOT_MODE_ON_BOTMODAL,
                metadata: botMetaData,
              },
            ],
          },
          botDetails?.bot?.length > 0
        );
      }
    }
  };

  const handleCloseBotModal = () => {
    handleClearBotData();
    dispatch({
      type: CLOSE_BOT_MODAL,
    });
  };

  const handleToggleImportModal = () => {
    setShowImportOption(!showImportOption);
  };

  const handleCloseImportModal = () => {
    setShowImportModal(false);
  };

  const handleReadBot = file => {
    const reader = new FileReader();
    reader.onload = e => {
      const botString = JSON.parse(e.target.result);
      if (botString.bot?.length) {
        setBotDetails(botString);
        setBotInfo(botString.bot[0]);
      } else {
        setBotInfo(botString);
      }
      setIsImportBtnDisabled(false);
    };
    reader.readAsText(file);
    return false;
  };

  const handleImportBot = () => {
    const validation = validateBotJSONSchema(botInfo);
    const answersAllowed = totalAnswersLeft;
    const answerList = botInfo.answerbank || botInfo.answer;

    let tempWarnMsg = [];

    if (validation.status === 'missing_fields') {
      let errorMsg =
        'Following fields are missing for the data in the json file: ';
      errorMsg += validation.data.join(', ');

      message.error(errorMsg, 5);

      return;
    }

    if (validation.status === 'invalid_fields') {
      let errorMsg =
        'Following fields have invalid data type for the data in the json file: ';
      errorMsg += validation.data.join(', ');

      message.error(errorMsg, 5);

      return;
    }

    if (validation.status === 'answerbank_invalid_fields') {
      let errorMsg =
        'Following fields have invalid data type for the answerbank data in the json file: ';
      errorMsg += validation.data.join(', ');

      message.error(errorMsg, 5);

      return;
    }

    if (answerList?.length > answersAllowed) {
      tempWarnMsg.push(
        `Maximum number of answers reached. System will only upload ${answersAllowed} answer(s).`
      );
    }

    if (tempWarnMsg.length) {
      setWarnMsg(tempWarnMsg);
    }

    const options = [
      botInfo?.answer?.length > 0 && {
        value: 'answer',
        label: 'Answer',
      },
      botInfo?.category?.length > 0 && {
        value: 'category',
        label: 'Category',
      },
      botInfo?.testsuite?.length > 0 && {
        value: 'testsuite',
        label: 'Testsuite',
      },
      botInfo?.question?.length > 0 && {
        value: 'question',
        label: 'Question',
      },
    ];

    setSelectExportType(
      options.filter(option => option !== false).map(a => a.value)
    );
    setExportOptions(options.filter(option => option !== false));
    setBotName(botInfo.name);
    setBotDesc(botInfo.description || botInfo.desc);
    setBotMode(botInfo?.mode || botMode);
    setBotAnswerBank(answerList);
    setBotQuestionList(botInfo.question);
    setBotTestSuites(botInfo.testsuite);
    setBotCategoryList(botInfo.category);
    setBotMetaData(botInfo.metadata);
    setBotInfo(null);
    setImportStep(importStep + 1);
    setIsImportSaveBtnDisabled(false);
  };

  const handleChangeExportType = e => {
    const filtered = selectExportType.filter(
      value => value !== e.target?.value
    );
    if (!e.target.checked) {
      e.target.value === 'answer'
        ? setSelectExportType([])
        : setSelectExportType(filtered);
      setIsImportSaveBtnDisabled(true);
    } else {
      setIsImportSaveBtnDisabled(false);
      setSelectExportType([...selectExportType, e.target.value]);
    }
  };

  const handleModalSubmit = evt => {
    evt.preventDefault();
    const noBotModes = !botModes?.length;
    if (isImportReady && isImporting && !importStep) {
      return handleImportBot();
    }
    if (
      (!isImporting && botName) ||
      (isImporting && isImportReady && importStep)
    ) {
      if (isMaxBotCountReached && isCreatingNewBot) {
        return false;
      } else if (
        !botName ||
        // warn if botModes data is not empty and no value is selected
        // otherwise
        // continue save if no botModes were returned from api
        (!botMode && !noBotModes && isBotModeEnabled)
      ) {
        setWarnMsg('Bot name and bot mode fields are required.');
      }
      // create new bot without import
      else if (
        botName &&
        (botMode || (!botMode && !botModes)) &&
        !isImporting &&
        isCreatingNewBot
      ) {
        addNewBot({
          bot: [{ name: botName, description: botDesc, mode: botMode }],
        });
      } else {
        handleSubmit(evt);
      }
    } else {
      return;
    }
  };

  const handleUpdateBot = async () => {
    setLoading(true);
    const botJID = botModalState.jid;

    let botNameToSubmit = appendWithParenthesesIfDuplicate(
      allBots.map(bot => bot.name && bot.jid === botJID),
      botName
    );

    try {
      const res = await editBot(
        sentinel,
        botJID,
        botNameToSubmit,
        botModalState.mode || DEFAULT_BOT_MODE_ON_BOTMODAL,
        botDesc,
        token
      );
      if (!res.data || !res.data.report[0] || !res.data.report[0].context) {
        throw new Error(GET_DATA_ERROR);
      }
      const updatedBot = res.data.report[0].context;
      dispatch({ type: UPDATE_BOT, payload: { jid: botJID, ...updatedBot } });
      setLoading(false);
      handleCloseBotModal();
      message.success('Bot updated.');
    } catch (error) {
      setLoading(false);
      handleCloseBotModal();
      return message.error({
        content: makeKeywordAsAnchorText(PLAN_LIMIT_ERROR),
        duration: 10,
      });
    }
  };

  const addNewBot = async (params, newImportFormat) => {
    setLoading(true);
    const botParameter = params?.bot[0];

    let botNameToSubmit = appendWithParenthesesIfDuplicate(
      allBots.map(bot => bot.name),
      botParameter.name
    );

    if (isImporting) {
      try {
        let newBotRes;
        dispatch({
          type: SET_TEMP_BOT,
          payload: {
            jid: 'new-temp-bot',
            name: botNameToSubmit,
            description: botParameter.description,
          },
        });
        handleCloseBotModal();
        if (newImportFormat) {
          newBotRes = await apiService.importer(sentinel, graph, token, params);
        } else {
          newBotRes = await apiService.importBot(
            sentinel,
            graph,
            botNameToSubmit,
            botParameter.description,
            botParameter.metadata,
            botParameter.answer,
            botParameter.mode,
            token
          );
        }
        const newBot = newBotRes.data.report;
        dispatch({ type: SET_TEMP_BOT, payload: null });
        dispatch({
          type: IMPORT_BOT,
          payload: {
            ...newBot,
            answerCount: newImportFormat
              ? newBot[0].context.answer_count
              : newBot.length - 1 || 0,
          },
        });
        message.success('A new bot is created!');
      } catch (err) {
        dispatch({ type: SET_TEMP_BOT, payload: null });
        handleCloseBotModal();
        setLoading(false);
        return message.error('Error while creating a new bot');
      }
    } else {
      const botDetails = {
        name: botNameToSubmit,
        desc: botParameter.description,
        mode: botParameter.mode || DEFAULT_BOT_MODE_ON_BOTMODAL,
      };
      handleCloseBotModal();
      try {
        dispatch({
          type: SET_TEMP_BOT,
          payload: {
            jid: 'new-temp-bot',
            name: botNameToSubmit,
            description: botParameter.description,
            mode: botParameter.mode || DEFAULT_BOT_MODE_ON_BOTMODAL,
          },
        });

        const newBot = await createBot(sentinel, graph, botDetails, token);
        dispatch({ type: SET_TEMP_BOT, payload: null });
        dispatch({ type: ADD_BOT, payload: newBot });
        message.success('A new bot is created!');
        setLoading(false);
      } catch (err) {
        dispatch({ type: SET_TEMP_BOT, payload: null });
        setLoading(false);
        return message.error('Error while creating a new bot');
      }
    }
  };

  const handleChangeStep = step => {
    if (!step) {
      setBotInfo({});
      handleClearBotData();
      setShowImportOption(true);
    } else {
      setImportStep(step);
    }
  };

  return {
    nameRef,
    descriptionRef,
    botModes,
    botMode,
    botName,
    botDesc,
    botDetails,
    botInfo,
    botMetaData,
    botModalState,
    botAnswerBank,
    warnMsg,
    showImportModal,
    showImportOption,
    importStep,
    isImportBtnDisabled,
    isImportReady,
    isImporting,
    isCreatingNewBot,
    isEditingBot,
    exportOptions,
    maxBotCount,
    planType,
    selectExportType,
    setBotMode,
    setBotName,
    setBotDesc,
    setShowImportOption,
    setImportStep,
    setWarnMsg,
    handleChangeStep,
    handleToggleImportModal,
    handleCloseBotModal,
    handleCloseImportModal,
    handleReadBot,
    handleImportBot,
    handleSubmit,
    handleChangeExportType,
    handleModalSubmit,
    isMaxBotCountReached,
    isBotModalVisible,
    importExportEnabled,
    loading,
    isImportSaveBtnDisabled,
  };
};

export default useBotModal;
