import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';

import { Context } from 'store/store';
import Button from 'components/Button';
import Modal from 'components/Modals/GenericModal';
import {
  StyledCategoryModalFooter,
  StyledMoveCategoryWrapper,
  StyledCategoryPicker,
  StyledCategoryName,
} from './MoveCategoryModal.style';
import { SET_CATEGORY_MODAL_OPEN } from 'store/action';

const MoveCategoryModal = ({
  categories,
  confirmModalLoading,
  handleBulkMoveCategory,
  moveToCategory,
  showBulkMoveCategoryModal,
  selectedAnswers,
  setShowBulkMoveCategoryModal,
  setShowAddCategoryModal,
  setMoveToCategory,
}) => {
  const [, dispatch] = useContext(Context);
  return (
    <Modal
      visible={showBulkMoveCategoryModal}
      title={`Move ${selectedAnswers.length} answer(s) to`}
      onCancel={() => setShowBulkMoveCategoryModal(false)}
      destroyOnClose
      isFormModal={true}
      width={'60%'}
      footer={
        <StyledCategoryModalFooter>
          <Button
            startIcon={<PlusOutlined />}
            variant="link"
            value="Category"
            onClick={() => {
              dispatch({
                type: SET_CATEGORY_MODAL_OPEN,
                payload: { action: 'add' },
              });
            }}
          />
          <div className="modal-action-buttons">
            <Button
              onClick={() => setShowBulkMoveCategoryModal(false)}
              value="Cancel"
              variant="secondary-btn-v2"
              disabled={confirmModalLoading}
            />
            <Button
              disabled={!moveToCategory}
              value="Move"
              onClick={handleBulkMoveCategory}
              variant="primary"
              loading={confirmModalLoading}
            />
          </div>
        </StyledCategoryModalFooter>
      }
    >
      <StyledMoveCategoryWrapper>
        <span>{`Select Category`}</span>
        {selectedAnswers.length ? (
          <StyledCategoryPicker>
            {categories.length
              ? categories.map(item => {
                  return (
                    <StyledCategoryName
                      onClick={() => setMoveToCategory(item.jid)}
                      isSelected={item.jid === moveToCategory}
                      key={item.jid}
                    >
                      {item.name}
                    </StyledCategoryName>
                  );
                })
              : null}
          </StyledCategoryPicker>
        ) : null}
      </StyledMoveCategoryWrapper>
    </Modal>
  );
};
MoveCategoryModal.propTypes = {
  categories: PropTypes.any,
  confirmModalLoading: PropTypes.bool,
  handleBulkMoveCategory: PropTypes.func,
  moveToCategory: PropTypes.any,
  showBulkMoveCategoryModal: PropTypes.bool,
  selectedAnswers: PropTypes.any,
  setShowBulkMoveCategoryModal: PropTypes.func,
  setShowAddCategoryModal: PropTypes.func,
  setMoveToCategory: PropTypes.func,
};
export default MoveCategoryModal;
