import { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { allCategoriesSelector } from 'selectors/bot/categories';
import {
  SET_ANSWER_EDITOR_SELECTED_ANSWER,
  SET_FILE_EDITOR_SELECTED_FILE,
  SET_WEBSITE_EDITOR_SELECTED_WEBSITE,
} from 'store/action';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import { stripUUID } from 'utils';
import { getAnalyticsCategoryFilterLink } from 'utils/analytics';

const useAnswerItem = ({ categoryId }) => {
  const [state, dispatch] = useContext(Context);
  const allCategories = useSelector(allCategoriesSelector);
  const {
    bot: { showAnswersVersions },
  } = state;
  const { push } = useHistory();

  const [showMore, setShowMore] = useState(false);
  const [showAnswerVersionDropdown, setShowAnswerVersionDropdown] =
    useState(false);

  const currentCategory = useMemo(() => {
    return categoryId
      ? allCategories?.find(
          category => stripUUID(category.jid) === stripUUID(categoryId)
        )
      : null;
  }, [categoryId, allCategories]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleClickCategoryLink = () => {
    push(getAnalyticsCategoryFilterLink(currentCategory.name));
  };

  const handleAnswerDropdownVisibilityChange = flag => {
    setShowAnswerVersionDropdown(flag);
  };

  const handleShowAnswerEditorModal = (answer, args) => {
    dispatch({
      type: SET_ANSWER_EDITOR_SELECTED_ANSWER,
      payload: { answer, args },
    });
  };

  const handleShowFileEditorModal = (file, args) => {
    dispatch({
      type: SET_FILE_EDITOR_SELECTED_FILE,
      payload: { file, args },
    });
  };

  const handleShowWebsiteEditorModal = (website, args) => {
    dispatch({
      type: SET_WEBSITE_EDITOR_SELECTED_WEBSITE,
      payload: { website, args },
    });
  };

  return {
    allCategories,
    currentCategory,
    handleClickCategoryLink,
    handleAnswerDropdownVisibilityChange,
    handleShowAnswerEditorModal,
    handleShowFileEditorModal,
    handleShowWebsiteEditorModal,
    showAnswerVersionDropdown,
    showMore,
    showAnswersVersions,
    toggleShowMore,
  };
};

export default useAnswerItem;
