import React from 'react';
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  FrownOutlined,
  LockOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Dropdown, Menu, Spin, Tag } from 'antd';

import { StyledBotCard } from './BotCard.style';

import Button from 'components/Button';
import { default as ToolTip } from 'components/ToolTips/BaseToolTip';
import ConfirmDelete from 'components/Modals/ConfirmDelete';
import { getTimeDifference } from 'utils/dates';
import { WeakStatPopover } from 'components/WeakStatPopover';

import LimitationTooltip from 'components/ToolTips/LimitationToolTip';
import useBotCard from './hooks';
import { parseBoolean } from 'utils/dataTypes';
import { StyledFlexLeftColumn } from 'styles/GenericStyledComponents';
import { cssVariables } from 'styles/root';
import { BOT_MODES } from 'constants/bot';

const BotCard = props => {
  const { bot, viewBot, isList, loading, ...rest } = props;
  const { answerCount, fileCount, mode, websiteCount } = bot;
  const {
    botsExceedsLimit,
    handleEditBot,
    totalExceedingBots,
    handleDelete,
    isConfirmModalOpen,
    loading: componentLoading,
    showWeakBotStat,
    setIsConfirmModalOpen,
  } = useBotCard({ bot });

  const totalAnswersText =
    !!answerCount && answerCount > 0
      ? `${answerCount} Answer${
          answerCount > 1 && fileCount && isList ? 's' : ''
        }`
      : !answerCount && !fileCount && !websiteCount
      ? `No Answer`
      : null;

  const totalFilesText =
    !!fileCount && fileCount > 0
      ? `${fileCount} File${fileCount > 1 ? 's' : ''}`
      : null;

  const totalWebsiteCount =
    !!websiteCount && websiteCount > 0
      ? `${websiteCount} Website${websiteCount > 1 ? 's' : ''}`
      : null;

  const cardSettings = (
    <Menu>
      {botsExceedsLimit ? (
        <Menu.Item
          key={1}
          icon={<EditOutlined />}
          disabled
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <LimitationTooltip
            isBordered={false}
            disabledFeat={'Edit'}
            style={{ padding: 0 }}
            title="Upgrade to Edit bot"
          />
        </Menu.Item>
      ) : (
        <Menu.Item icon={<EditOutlined />} onClick={handleEditBot} key={1}>
          {'Edit'}
        </Menu.Item>
      )}
      <Menu.Item
        key={2}
        icon={<DeleteOutlined />}
        onClick={() => setIsConfirmModalOpen(true)}
      >
        {'Delete'}
      </Menu.Item>
    </Menu>
  );

  return (
    <Spin
      spinning={componentLoading || Boolean(loading)}
      indicator={<LoadingOutlined />}
    >
      <StyledBotCard isList={isList} {...rest}>
        <div className="info" onClick={() => (isList ? viewBot() : false)}>
          <div className="bot-avatar">{bot?.name[0]}</div>
          <div className="bot-info">
            <StyledFlexLeftColumn>
              <ToolTip title={bot?.name}>
                <span className="bot-name">{bot?.name}</span>
              </ToolTip>
              <span className="answers">{totalAnswersText}</span>
              <span className="answers">{totalFilesText}</span>
              <span className="answers">{totalWebsiteCount}</span>
              {isList ? (
                <StyledFlexLeftColumn
                  style={{ fontStyle: 'italic', color: cssVariables.gray0 }}
                >
                  <Tag color={mode === BOT_MODES.ZSB ? 'geekblue' : 'cyan'}>
                    {mode?.toUpperCase()}
                  </Tag>
                </StyledFlexLeftColumn>
              ) : null}
            </StyledFlexLeftColumn>
          </div>
        </div>
        <div className="bot-actions">
          {showWeakBotStat ? (
            <div className="weak-bot-button">
              <WeakStatPopover
                location="bots"
                dataStatName="bot"
                weakData={bot}
              >
                <div>
                  <FrownOutlined /> {'Weak Bot'}
                </div>
              </WeakStatPopover>
            </div>
          ) : null}
          <div className="bot-footer-actions">
            <div onClick={() => (isList ? viewBot() : false)}>
              {bot.lastUpdatedAt && (
                <StyledFlexLeftColumn>
                  {!isList ? (
                    <Tag color={mode === BOT_MODES.ZSB ? 'geekblue' : 'cyan'}>
                      {mode?.toUpperCase()}
                    </Tag>
                  ) : null}
                  <span>
                    {'Modified'} {getTimeDifference(bot.lastUpdatedAt)}
                  </span>
                </StyledFlexLeftColumn>
              )}
            </div>
            {!botsExceedsLimit ? (
              <Button
                onClick={viewBot}
                value={botsExceedsLimit ? `Locked` : `View bot`}
                variant="secondary"
                className="view-bot-button"
              />
            ) : (
              <LimitationTooltip
                disabledFeat={
                  <>
                    <LockOutlined />
                    <div>{'Locked'}</div>
                  </>
                }
                style={{ padding: '8px 16px' }}
                title={`Upgrade or delete at least ${totalExceedingBots} bot(s) to unlock.`}
              />
            )}
          </div>
        </div>
        <Dropdown overlay={cardSettings} trigger={['click']}>
          <MoreOutlined className="more" />
        </Dropdown>

        <ConfirmDelete
          onCancel={() => setIsConfirmModalOpen(false)}
          modalTitle="Delete bot"
          show={isConfirmModalOpen}
          loading={componentLoading || parseBoolean(loading)}
          onOk={handleDelete}
          itemTobeDeleted={bot?.name}
        />
      </StyledBotCard>
    </Spin>
  );
};

BotCard.propTypes = {
  bot: PropTypes.object.isRequired,
  isList: PropTypes.bool,
  loading: PropTypes.bool,
  viewBot: PropTypes.func.isRequired,
};

export { BotCard };
