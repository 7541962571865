import { useState, useContext } from 'react';
import { message } from 'antd';

import {
  showCancelSubscriptionModalSelector,
  showCancelledSubscriptionSuccessMessageSelector,
  trialDaysLeftSelector,
} from 'selectors/subscription';
import { getTokenSelector } from 'selectors/user';
import {
  CANCEL_SUBSCRIPTION,
  CLOSE_CANCEL_SUBSCRIPTION_MODAL,
  CLOSE_SUCCESS_CANCEL_SUBSCRIPTION_MODAL,
  SET_USER_SUBSCRIPTION,
} from 'store/action';
import { Context } from 'store/store';
import useSelector from 'store/useSelector';
import { stripeAPIService } from 'services/stripeAPI.service';
import { DEFAULT_ERROR_MESSAGE } from 'constants/error';
import ROUTES from 'constants/routes';

const useCancelSubscriptionModal = () => {
  const [state, dispatch] = useContext(Context);
  const trialDaysLeft = useSelector(trialDaysLeftSelector);
  const showCancelledSubscriptionSuccessMessage = useSelector(
    showCancelledSubscriptionSuccessMessageSelector
  );
  const showCancelSubscriptionModal = useSelector(
    showCancelSubscriptionModalSelector
  );
  const token = useSelector(getTokenSelector);
  const { graph, sentinel } = state;

  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    if (showCancelledSubscriptionSuccessMessage) {
      dispatch({
        type: CLOSE_SUCCESS_CANCEL_SUBSCRIPTION_MODAL,
      });
      message.success('Successfully cancelled your subscription!');
      window.location.href = ROUTES.HOME;
    } else {
      dispatch({
        type: CLOSE_CANCEL_SUBSCRIPTION_MODAL,
      });
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const res = await stripeAPIService.cancelSubscription(
        graph,
        sentinel,
        token
      );

      if (res.data?.report[0]) {
        await dispatch({
          type: CANCEL_SUBSCRIPTION,
          payload: res.data.report[0],
        });
      }

      const newsubscriptionRes = await stripeAPIService.getSubscriptionStatus(
        graph,
        sentinel,
        token
      );
      if (res.data?.report) {
        await dispatch({
          type: SET_USER_SUBSCRIPTION,
          payload: newsubscriptionRes.data.report[0],
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return message.error(error?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  return {
    handleCloseModal,
    handleCancelSubscription,
    loading,
    showCancelSubscriptionModal,
    showCancelledSubscriptionSuccessMessage,
    trialDaysLeft,
  };
};

export default useCancelSubscriptionModal;
