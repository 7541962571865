import { useState, useContext, useCallback } from 'react';
import { useNodesState, useEdgesState } from 'react-flow-renderer';
import dagre from 'dagre';
import { uuid } from 'uuidv4';

import { Context } from 'store/store';
import { apiService } from 'services/api.service';
import { getTokenSelector } from 'selectors/user';
import useSelector from 'store/useSelector';

const nodeWidth = 172;
const nodeHeight = 36;
const dagreGraph = new dagre.graphlib.Graph();

dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, direction = 'LR') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach(node => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });
  return { nodes, edges };
};

const useConversationSimulator = () => {
  const [state] = useContext(Context);
  const token = useSelector(getTokenSelector);
  const {
    bot: { jid },
  } = state;
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [initialNodes, setNodes, onNodesChange] = useNodesState([]);
  const [initialEdges, setEdges, onEdgesChange] = useEdgesState([]);

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges
  );
  const { sentinel } = state;

  const handleFindAnswer = async () => {
    setLoading(true);
    try {
      const res = await apiService.askQuestion(
        sentinel,
        jid,
        question,
        token,
        'ZSB Platform'
      );
      const answer = res.data.report[0];
      const nodeId = uuid();
      composeNodes({
        id: nodeId,
        text: question,
        answer,
      });
      composeEdges(nodeId, answer?.jid);
      getNodesAndEdgesFromQuestion(answer, answer?.jid);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw typeof err === 'string' ? err : err.message;
    }
  };

  const composeNodes = useCallback(
    ({ id, text, answer }) => {
      setNodes(prevNodes =>
        prevNodes.concat({
          id,
          data: { label: text, answer },
        })
      );
    },
    [setNodes]
  );

  const composeEdges = useCallback(
    (source, target) => {
      setEdges(prevEdges =>
        prevEdges.concat({
          id: `element-${target}`,
          source,
          target,
        })
      );
    },
    [setEdges]
  );

  const getNodesAndEdgesFromQuestion = useCallback(
    (answer, sourceId) => {
      if (typeof answer === 'object') {
        composeNodes({
          id: answer?.context?.id || answer.jid,
          text: answer?.context?.text,
          answer,
        });
        if (answer.context.quick_reply) {
          if (answer.context.quick_reply.replies) {
            const replies = answer.context.quick_reply.replies;
            for (const reply of replies) {
              const tempId = uuid();
              composeNodes({
                id: tempId,
                text: reply.reply,
                answer: reply.answer,
              });

              // if answer has quick reply, build the edges
              composeEdges(sourceId, tempId);
            }
          }
        } else {
          // else, build the edge from the original question and answer
          composeEdges(sourceId, answer?.context?.id || answer.jid);
        }
      }
    },
    [composeEdges, composeNodes]
  );

  const getNodesAndEdgesFromAnswer = useCallback(
    (answer, sourceId) => {
      if (typeof answer === 'object') {
        composeNodes({
          id: answer?.context?.id || answer.jid,
          text: answer?.context?.text,
          answer,
        });
        composeEdges(sourceId, answer?.context?.id || answer.jid);
        if (answer.context.quick_reply) {
          if (answer.context.quick_reply.replies) {
            const replies = answer.context.quick_reply.replies;
            for (const reply of replies) {
              const tempId = uuid();
              composeNodes({
                id: tempId,
                text: reply.reply,
                answer: reply.answer,
              });
              composeEdges(answer?.context?.id || answer.jid, tempId);
            }
          }
        }
      }
    },
    [composeEdges, composeNodes]
  );

  const getAnswerFromNode = useCallback(
    async (nodeData, sourceId) => {
      const nodeJid = nodeData.answer.jid;
      if (nodeJid) {
        try {
          const res = await apiService.getAnswer(
            sentinel,
            nodeJid,
            token,
            'ZSB Platform'
          );
          const answer = res.data.report[0];
          getNodesAndEdgesFromAnswer(answer, sourceId);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          throw typeof err === 'string' ? err : err.message;
        }
      } else {
        setLoading(true);
        try {
          const { label: text } = nodeData;
          const res = await apiService.askQuestion(
            sentinel,
            jid,
            text,
            token,
            'ZSB Platform'
          );
          const answer = res.data.report[0];
          getNodesAndEdgesFromQuestion(answer, sourceId);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          throw typeof err === 'string' ? err : err.message;
        }
      }
    },
    [
      getNodesAndEdgesFromAnswer,
      getNodesAndEdgesFromQuestion,
      jid,
      sentinel,
      token,
    ]
  );

  const onNodeClick = (event, node) => {
    const { id, data } = node;
    const source = layoutedEdges.find(edge => edge.source === id);

    // if source already exist, don't fetch data
    if (!source) {
      getAnswerFromNode(data, id);
    }
  };

  return {
    loading,
    question,
    layoutedNodes,
    layoutedEdges,
    handleFindAnswer,
    setQuestion,
    onNodesChange,
    onEdgesChange,
    onNodeClick,
  };
};

export default useConversationSimulator;
