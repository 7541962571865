import { isExternalPageSelector } from 'selectors/user';
import useSelector from 'store/useSelector';

function useGlobalGoogle(callback) {
  const isExternalPage = useSelector(isExternalPageSelector);
  const loadGoogleScript = () => {
    const googleScript = document.getElementById('global-google-script');
    if (window.google) {
      if (isExternalPage) {
        localStorage.setItem('external', isExternalPage);
      }

      callback();
    } else {
      googleScript.addEventListener('load', callback);
    }
  };

  // Load the Google script when the page has finished loading
  document.addEventListener('DOMContentLoaded', loadGoogleScript);

  // Clean up the event listener when the page is unloaded
  window.addEventListener('beforeunload', () => {
    const googleScript = document.getElementById('global-google-script');
    googleScript.removeEventListener('load', callback);
  });
}

export { useGlobalGoogle };
