import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../GenericModal';

const ConfirmQuestionValidation = ({
  validationDetails,
  loading,
  modalTitle,
  show,
  ...rest
}) => {
  return (
    <Modal
      cancelButtonProps={{
        disabled: loading,
      }}
      cancelText="Cancel"
      confirmLoading={loading}
      destroyOnClose
      okText="Confirm"
      title={modalTitle ? modalTitle : 'Confirm Validation'}
      visible={show}
      onOk={rest.onOk}
      onCancel={rest.onCancel}
      {...rest}
    >
      <div>{`Are you sure you want to mark this as  ${
        validationDetails?.validation === 0 ? 'incorrect' : 'correct'
      }?`}</div>
    </Modal>
  );
};

ConfirmQuestionValidation.propTypes = {
  itemTobeDeleted: PropTypes.string,
  loading: PropTypes.bool,
  modalTitle: PropTypes.string,
  show: PropTypes.bool.isRequired,
};

export default ConfirmQuestionValidation;
